import React, { Component } from "react";
import { connect } from 'react-redux';

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SnackbarAlert from "@library/core/alerts/SnackbarAlert";
import { 
    toggleTourRun,
    initiateTour,
    changeTourSection,
    changeTourStep,
    finishTour
 } from "../actions/tourActions";
import './tour.css';

class OrderBuilderTour extends Component {

  state = {
    tourSteps: [
      {
        content: <><h3>Welcome to Order Builder!</h3><br/><h6>let's show you around</h6></>,
        locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
        placement: 'center',
        target: 'body',
      },
      {
        target: '.tour-orderbuilder-cartBtn',
        title: 'Cart',
        content: <p>Display's how many items are in your cart and when clicked will direct you to the Product selection step</p>,
        placement: 'bottom',
      },
      {
        target: '.tour-orderbuilder-wishlistBtn',
        title: 'Wishlist',
        content: <p>Click here to open the wishlist selection menu</p>,
        placement: 'bottom',
        spotlightClicks: true,
        hideFooter: false,
        disableScrolling: true
      },
      {
        target: '.tour-orderbuilder-wishlistMenu',
        title: 'Wishlist Selection',
        content: <p>This menu let's you select a wishlist that will pre-populate your order</p>,
        placement: 'right',
        styles: {
          options: {
            zIndex: 10000,
          },
        },
        spotlightClicks: true,
        disableBeacon: true
      },
      {
        target: '#tour-orderbuilder-orderTable .tour-library-breadTable-tableBody',
        title: 'Order Table',
        content: <p>This is where you can select required service's to add to your cart</p>,
        placement: 'top',
      },
      {
        target: '#tour-orderbuilder-tableFilter',
        title: 'Filter',
        content: <p>Table filter menu, this allows available items to be filtered by their service descriptions</p>,
        placement: 'right',
        disableOverlay: true,
      },
      {
        target: '.tour-library-stepper-nextBtn',
        title: 'Move to Customer Input step',
        content: <p>Once finished you can move on to the next step by clicking here</p>,
        placement: 'right',
        disableOverlay: true,
      },
      {
        target: '#tour-orderbuilder-teamSelect',
        title: 'Team Select',
        content: <p>select the team this order is for</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: true,
        hideBackButton: true
      },
      {
        target: '#tour-orderbuilder-envSelect',
        title: 'Environment Select',
        content: <p>select the target environment for this order</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: true,
      },
      {
        target: '#tour-orderbuilder-sprintSelect',
        title: 'Sprint Select',
        content: <p>select the target sprint for this order</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: true,
      },
      {
        target: '#tour-orderbuilder-irNumberSelect',
        title: 'IR Number Select',
        content: <p>select the target IR Number for this order</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: true,
      },
      {
        target: '#tour-orderbuilder-wishlistToggle',
        title: 'Wishlist',
        content: <p>If enabled will save current order as a wishlist that can be loaded for future orders</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: true,
      },
      {
        target: '#tour-orderbuilder-wishlistNameInput',
        title: 'Wishlist Name',
        content: <p>To save a wishlist a name is required</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: true,
      },
      {
        target: '#tour-orderbuilder-additionalComment',
        title: 'Comments',
        content: <p>If necessary please add any additional comments for this order here</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: true,
      },
      {
        target: '#tour-orderbuilder-customerInputSummary',
        title: 'Customer Input Summary',
        content: <p>This shows the summary of customer input section</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: false,
        hideBackButton: true
      },
      {
        target: '#tour-orderbuilder-cartSummary',
        title: 'Cart Summary',
        content: <p>This shows the summary of items added to your cart</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: false,
      },
      {
        target: '#tour-orderbuilder-openOrders',
        title: 'Open orders',
        content: <p>This shows a list of already open orders</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: false,
      },
      {
        target: '#tour-orderbuilder-availableDataAssets',
        title: 'Available data assets',
        content: <p>This shows a list of already available data assets</p>,
        placement: 'right',
        disableOverlay: false,
        spotlightClicks: false,
      },
    ],
    tourSections: [0,7,14]
  }

  handleClickStart = async (event) => {
    event.preventDefault();
    const step = this.props.tourSections && this.props.tourSections.length > 0 ? this.props.tourSections[this.props.tourSection] : 0;
    await this.props.initiateTour(step, this.state.tourSections);
  };

  handleJoyrideCallback = async (data) => {
    const { action, index, status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      this.props.finishTour();
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (!this.props.openWishlistModal && index === 2 && action === ACTIONS.CLOSE) {
        this.props.changeTourStep(true, index);
      } else if (this.props.openWishlistModal && index === 2) {
        setTimeout(() => {
          this.props.toggleTourRun(true);
        }, 800);
      } else if (this.props.openWishlistModal && index === 3) {
        await this.props.changeTourStep(false, nextStepIndex);
        this.props.setOpenWishlistModal(false);
        setTimeout(() => {
          this.props.toggleTourRun(true);
        }, 800);
      } else if (!this.props.openWishlistModal && index === 3 && action === ACTIONS.CLOSE) {
        this.props.toggleTourRun(false);
      } else if (!this.props.openWishlistModal && index === 3 && action === ACTIONS.NEXT) {
        await this.props.changeTourStep(false, nextStepIndex);
        setTimeout(() => {
            this.props.toggleTourRun(true);
        }, 800);
      } else if (index === 4 && action === ACTIONS.PREV) {
        await this.props.changeTourStep(false, nextStepIndex-1);
        setTimeout(() => {
          this.props.toggleTourRun(true);
        }, 400);
      } else if (index === 4) {
        window.scrollTo(0, 0);
        await this.props.changeTourStep(false, nextStepIndex);
        setTimeout(() => {
            this.props.toggleTourRun(true);
        }, 800);
      } else if (index === 5 && action === ACTIONS.NEXT) {
        await this.props.changeTourStep(false, nextStepIndex);
        setTimeout(() => {
            this.props.toggleTourRun(true);
        }, 800);
      } else if (index === 6 && (action === ACTIONS.CLOSE || action === ACTIONS.NEXT)) {
        await this.props.changeTourStep(false, nextStepIndex);
      } else if (index === 11 && (action === ACTIONS.CLOSE || action === ACTIONS.NEXT)) {
        let step = 13;
        if (this.props.wishlistActive) {
          step = 12;
        }
        await this.props.changeTourStep(false, step);
        setTimeout(() => {
            this.props.toggleTourRun(true);
        }, 100);
      } else if (index === 13 && action === ACTIONS.PREV) { 
        let step = 11;
        if (this.props.wishlistActive) {
          step = 12;
        }
        await this.props.changeTourStep(false, step);
        setTimeout(() => {
            this.props.toggleTourRun(true);
        }, 100);
      } else if (index === 13 && (action === ACTIONS.CLOSE || action === ACTIONS.NEXT)) {
        await this.props.changeTourStep(false, nextStepIndex);
      } else {
        if (nextStepIndex >= this.props.tourSections[this.props.tourSection]) {
          window.scrollTo(0, 0);
          await this.props.changeTourStep(this.props.tourRun, nextStepIndex);
          this.props.setOpenWishlistModal(false);
        }
      }
      if (document.getElementsByClassName("glass")[0]) {
        document.getElementsByClassName("glass")[0].click();
      }
    }
  };

  render() {
    return (
        <>
            <Joyride 
                steps={this.state.tourSteps} 
                run={this.props.tourRun && this.props.tourEngaged && !this.props.isCustomOrder}
                stepIndex={this.props.tourStepIndex}
                callback={this.handleJoyrideCallback}
                showProgress={false}
                showSkipButton
                continuous
            />
            <SnackbarAlert
                isOpen={!this.props.tourEngaged && !this.props.isCustomOrder}
                severity={"info"}
                message={"Need Help? Take a tour!"}
                position={{ horizontal: 'right', vertical: 'bottom' }}
                icon={<HelpOutlineIcon/>}
                handleClick={this.handleClickStart}
            />
        </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tourRun: state.tour.tourRun,
    tourEngaged: state.tour.tourEngaged,
    tourStepIndex: state.tour.tourStepIndex,
    tourSection: state.tour.tourSection,
    tourSections: state.tour.tourSections,
    isCustomOrder: state.orderBuilder.isCustomOrder
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleTourRun: (bool) => dispatch(toggleTourRun(bool)),
    initiateTour: (index, sections) => dispatch(initiateTour(index, sections)),
    changeTourSection: (section) => dispatch(changeTourSection(section)),
    changeTourStep: (run, stepIndex) => dispatch(changeTourStep(run, stepIndex)),
    finishTour: () => dispatch(finishTour()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderBuilderTour);