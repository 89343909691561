import { Button, Input, InputGroup } from "reactstrap";
import React, { Component } from 'react'
import _, { forEach, range, uniq } from 'lodash';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import {
    addCIDN,
    fetchDataS2POptions,
    removeProductType,
    resetSelectedFilters,
    saveTableView,
    selectS2PFilter,
    submitRequest,
    submitCIDNDeleteRequest,
    submitServiceIdDeleteRequest,
    generateServiceId,
    updateDealerPremiseCode,
    fetchProductTypePlan,
    fetchProductRepayments
    
} from '../../../actions/replicatorActions';
import { selectCosmosSelectFilter, setSearchValue } from "../../../actions/cosmosActions"
import {isRole} from '../../auth/functions';
import DataTilesFrame  from '../../Data/accordion-frames/replicator/DataTilesFrame';
import NewProductModal from "../../replicator/NewProductModal";
import NewCosmosProductModal from "../../replicator/NewCosmosProductModal";
import { SubHeader } from '../../common/custom/SubHeader';
import { displayLoading } from '../../common/alerts/alerts';
import '../../replicator/Data.css';
import ActionTiles from "./ActionTiles";
import DeleteTiles from "./DeleteTiles";
import CustomerSection from './CustomerSection'
import DeleteInputAction from './DeleteInputAction';
import SubmitAction from './SubmitAction';
import SalesForceUpdateSection from './SaleforceUpdateSection';
import CosmosRecords from './CosmosRecords';

class ReplicatorUtility extends Component {
    state = {       
        product: []       
    }
    componentDidMount() {        
        // fetch checkout options
        this.props.resetFilters();
        this.props.fetchOptions(); // Currently no backend
        this.accordionEnd = React.createRef();
        this.props.fetchProductTypePlan();
        this.props.fetchProductRepayments();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        this.accordionEnd.current.scrollIntoView({ behavior: 'smooth' });
    }

    // Reset fields downwards from a starting frame
    resetViewFromFrame = (startingFilter) => {
        switch (startingFilter) {
            case "action": 
                this.selectDeletionLevel({ label: null }); 
                this.selectCIDN(undefined); 
                this.updateDealerPremiseCode(undefined); 
            case "level":
                this.selectCidnDelete(undefined);
                this.selectServiceIdDelete(undefined);
            case "cidn":
                this.selectProductType({ label: null });                          
                break;
            default:
                break;
        }
    }

    selectActionType=(obj) => { 
        this.resetViewFromFrame("action")    
        this.props.selectFilter("action",obj.label);
    }

    selectDataType = (obj) => {
        this.resetViewFromFrame("type")
        this.props.selectFilter("type", obj.label);
    }
    selectCIDN = (value) => {
        this.resetViewFromFrame("cidn")
        this.props.addCIDN(value)
    }
    updateDealerPremiseCode =(value)=>{
        this.resetViewFromFrame("dealerPremiseCode")
        this.props.updateDealerPremiseCode(value)
    }

    selectDeleteAction = (obj) => {       
        if(obj.name === "CIDN") {           
            this.selectCidnDelete(obj.value);
        } else if(obj.name === "Service Id") {      
            this.selectServiceIdDelete(obj.value);                
        }
    }

    selectCidnDelete =(value) => {      
        this.props.selectFilter("deleteCIDN", value)
    }

    selectServiceIdDelete =(value) => {       
        this.props.selectFilter("deleteServiceId", value);
    }

    selectDeletionLevel =(obj) => {    
        this.resetViewFromFrame("level")   
        this.props.selectFilter("level",obj.label);
    }

    resetProduct() {
        let productData = []
        let products = []

        this.props.selectFilter("productData", productData)
        this.props.selectFilter("products", products);
    }

    selectProductType(obj) {
        if (obj.label == null) return this.resetProduct();
        let products = this.props.selectedFilters.products || [];
        var productData = [...this.props.selectedFilters.productData];
        var selectedProd = obj.label;

        let idx = productData.findIndex(product => product.name === selectedProd)
        let tData = { name: selectedProd, adborFnns: [], mobileData: [] , tiptSipcProduct: []}
        let isNew = true;
        if (idx < 0) {
            products.push(selectedProd)
            productData.push(tData)
        }
        else {
            products.splice(idx, 1)
            productData.splice(idx, 1)
            isNew = false
        }

        this.props.selectFilter("productData", productData)
        this.props.selectFilter("products", products);
        if (isNew) this.productModal.open(selectedProd)
    }

    editProductType(obj) {
        this.productModal.open(obj.label)
    }

    createServiceAssociations = (product, prod) => {
        let huntGroupItems = []
        let finalItems = []
        let isPrime = false;
        product.huntGroup.forEach((group, index) => {
            if (group.linkedFnn === prod.fnn || group.fnn === prod.fnn) {
                if (prod.fnn === group.linkedFnn && group.role === "prime") {
                    isPrime = true;
                }
                huntGroupItems.push({
                    "linkedServiceRole" : group.role,
                    "linkedServiceId" : group.linkedFnn
                })
            }
        });
        huntGroupItems.forEach((item) => {
            if (isPrime) {
                if (item.linkedServiceRole !== "prime") {
                    finalItems.push(item)
                }
            } else {
                if (item.linkedServiceRole === "prime") {
                    finalItems.push(item)
                }
            }
        });
        return finalItems;
    }

    createIsdnServiceAssociations = (product, prod) => {
        let huntGroupItems = []
        product.huntGroup.forEach((group, index) => {
            if (prod.fnn === group.fnn) {
                huntGroupItems.push({
                    "linkedServiceRole" : group.role,
                    "linkedServiceId" : group.linkedFnn
                })
            }
        })
        return huntGroupItems;
    }

    checkIsPrime = (product, prod) => {
        let value = "auxiliary";
        product.huntGroup.forEach((group) => {
            if (prod.fnn === group.linkedFnn && group.role === "prime") {
                value = "prime"
            }
        });
        return value;
    }

    createPstnPayload = (product) => {
        let payloadItems = []
        if (product.name === 'PSTN') {
            product.adborFnns.forEach((prod, index) => {
                
                payloadItems.push({
                    "cidn" : product.cidn,
                    "fnn" : prod.fnn,
                    "siteId" : prod.adborId,
                    "faxValue" : prod.fax ? prod.fax : false,
                    "accountNumber" : prod.accountNumber,
                    "associationType" : prod.optionalAssociation ? prod.optionalAssociation : "",
                    "serviceCharacteristics" : [
                        {
                            "name" : "source_site",
                            "value" : prod.adborId
                        },
                        {
                            "name" : "inflight_order",
                            "value" : prod.inflightOrder ? "Y" : "N"
                        },
                        {
                            "name" : "service_association_role",
                            "value" : this.checkIsPrime(product, prod)
                        }
                    ],
                    "serviceAssociations":[
                        ...(this.createServiceAssociations(product, prod))
                    ]    
                })
            })
            
        }
        return payloadItems;
    }

    createIsdnServiceExtensionList = (ranges, fnn) => {
        let list = [];
        if (ranges) {
            ranges.forEach((range) => {
                if (range.serviceId === fnn) {
                    list.push({
                        "extensionFrom": range.from,
                        "extensionTo": range.to
                    })
                }
            });
        }
        return list;
    }

    createIsdnPayload = (product) => {
        let payloadItems = []
        if (product.name.startsWith("ISDN")) {
            product.adborFnns.forEach((prod, index) => {
                payloadItems.push({
                    "productFamily": product.name,
                    "fnn" : prod.fnn,
                    "siteId" : prod.adborId,
                    "linkedSiteId" : prod.address,
                    "accountNumber" : prod.accountNumber,
                    "associationType" : prod.optionalAssociation ? prod.optionalAssociation : "",
                    "serviceCharacteristics" : [
                        {
                            "name" : "source_site",
                            "value" : prod.adborId
                        },
                        {
                            "name" : "inflight_order",
                            "value" : prod.inflightOrder ? "Y" : "N"
                        },
                        {
                            "name" : "service_association_role",
                            "value" : prod.associationRole
                        }
                    ],
                    "serviceAssociations":[
                        ...(this.createIsdnServiceAssociations(product, prod))
                    ],
                    "serviceExtensions":[
                        ...(this.createIsdnServiceExtensionList(product.ranges, prod.fnn))
                    ]
                })
            })
        }
 
        return payloadItems;
    }

    // Excuse this method, needs big time refactoring
    handleOnSubmit = (cidn, env, productData, dealerPremiseCode ,action, name) => {
        var replicatorCheckoutRequest = {};
        if(action === "Cosmos Insert")
        {
        let cosmosProducts = {
            "mobileProduct" : [] ,
            "tiptSipcProduct" : [],
            "pstnProduct" : [],
            "isdnProduct" : []
        }
       // cosmosProducts = JSON.parse(cosmosProducts)
        productData.map((product) =>
        {
            cosmosProducts['mobileProduct'].push(product.mobileData);
            cosmosProducts['tiptSipcProduct'].push(product.tiptSipcProduct);
            cosmosProducts['mobileProduct'] = cosmosProducts['mobileProduct'][0];
            cosmosProducts['tiptSipcProduct'] = cosmosProducts['tiptSipcProduct'][0];
            cosmosProducts.pstnProduct.push(...(this.createPstnPayload(product)));
            cosmosProducts.isdnProduct.push(...(this.createIsdnPayload(product)));
        })
      //  cosmosProducts = JSON.stringify(cosmosProducts);

        cosmosProducts.tiptSipcProduct.map((tiptSipcData)=>{
               delete tiptSipcData.groupEligibility;
               delete tiptSipcData.showDeviceType;
               delete tiptSipcData.inflightOrder;
               tiptSipcData.groupRows.map((groupRow) => {
                    delete groupRow.showServices;
                    delete groupRow.index;
                    delete groupRow.groupIndex;
                    groupRow.serviceRows.map((serviceRow) => {
                        delete serviceRow.index;
                        delete serviceRow.number;
                        delete serviceRow.showServiceExtensions;
                    serviceRow.serviceExtension.map((ext) => {
                        delete ext.extIndex;
                    })
            })
            }) })
        cosmosProducts.mobileProduct.map((innerData)=>{
                    delete innerData.showDeviceType;
                    delete innerData.repaymentsRows;
                    delete innerData[0];
        })
        if(cosmosProducts.mobileProduct.length == 0)
            delete cosmosProducts.mobileProduct;
        
        if(cosmosProducts.tiptSipcProduct.length == 0)
            delete cosmosProducts.tiptSipcProduct;

       replicatorCheckoutRequest = {  cidn: cidn,            
        dealerPremiseCode:dealerPremiseCode,
        cosmosProducts :cosmosProducts,
        insertRequests:[],
        profile: cidn !== "GENERATED" ? null : this.props.activeProfile,
        environment: this.props.env === 'INT2' ? "SIT" : this.props.env }
    }
        else
        {
            productData = productData.map((data)=>{
                const adborFnns  =  data.adborFnns.map((adborFnn)=>{
                   const updatedAdborFnn = adborFnn;
                   updatedAdborFnn.inflightOrder = adborFnn.inflightOrder ? "Y": "N";
                  return updatedAdborFnn;
               })
               delete data.cidn;
               delete data.environment;
               return {...data, adborFnns };
           });
            replicatorCheckoutRequest = {
                cidn: cidn,            
                dealerPremiseCode:dealerPremiseCode,
                products: [...productData],
                insertRequests:[],
                profile: cidn !== "GENERATED" ? null : this.props.activeProfile,
                environment: this.props.env === 'INT2' ? "SIT" : this.props.env
            }
        }
       // console.log("JSON :")
       // console.log(JSON.stringify(replicatorCheckoutRequest));
       this.submitReplicatorData(replicatorCheckoutRequest, action === "Cosmos Insert");
    }

    submitReplicatorData = (replicatorCheckoutRequest, isCosmos) => {
        displayLoading({ title: "Submitting S2P Data", bodyText: "Please standby whilst data is being inserted" });
        this.props.submitRequest(replicatorCheckoutRequest).then(result => {
            if (result) {
                swal.fire("Success", ` Submitted`, "success").then(() => {
                    isCosmos ? this.cosmosSuccessRedirect() : this.props.history.push(`tdm/checkout/history/${result.checkoutId}`)
                });
            }
        })
    }

    cosmosSuccessRedirect = () => {
        const cidn = this.props.selectedFilters.cidn ? this.props.selectedFilters.cidn : "";
        this.selectActionType({label: 'Get Records'})
        this.props.setCosmosSearchValue(cidn);
        this.props.selectCosmosFilter("searchBy", "CIDN");
    }

    handleDeleteOnSubmit = (action, deleteData) => {
        if(action === 'CIDN') {
            this.handleDeleteCIDNOnSubmit(deleteData);
        } else {
            this.handleDeleteServiceIdOnSubmit(deleteData);
        }
    }

    handleDeleteCIDNOnSubmit = (deleteCIDN) => {
        let deleteReplicatorRequest = {
            cidn : deleteCIDN,
            environment: this.props.env === 'INT2' ? "SIT" : this.props.env
        }
        displayLoading({ title: "Submitting CIDN for Deletion", bodyText: "Please standby whilst customer is being deleted" });
        this.props.submitCIDNDeleteRequest(deleteReplicatorRequest).then(result => {
            if(result){
                swal.fire("Success", `Deleted`, "success").then(() => {
                    this.props.history.push(`tdm/checkout/history/${result.checkoutId}`);
                })                 
            }          
        })
    }

    handleDeleteServiceIdOnSubmit = (deleteServiceId) => {
        let deleteReplicatorRequest = {
            serviceId: deleteServiceId,
            environment: this.props.env === 'INT2' ? "SIT" : this.props.env
        }
        displayLoading({ title: "Submitting Service Id for Deletion", bodyText: "Please standby whilst service id is being deleted" });
        this.props.submitServiceIdDeleteRequest(deleteReplicatorRequest).then(result => {
            if(result){
                swal.fire("Success", `Deleted`, "success").then(() => {
                    this.props.history.push(`tdm/checkout/history/${result.checkoutId}`);
                })                 
            }
           
        })
    }

    getProductTypeIcon(product) {
        switch (product) {
            case "PSTN":
                return "icon-mobile-modem";
            case "ISDN2":
                return "icon-api";
            case "ISDN2DID":
                return "icon-technologies2";
            case "MOBILE":
                return "icon-mobile";
            case "ISDN30":
                return "icon-technologies";
            case "TIPT-SIPC":
                return "icon-telepres";
            default:
                break;
        }
    }

    getActionTypeIcon(action){
        switch(action){
            case "Insert":
                return "icon-transfer-data"
            case "Delete":
                return "icon-round-cross"
            case "Cosmos Insert":
                return "icon-transfer-data"
            case "Salesforce Update":
                return "icon-transfer-data"
            case "Get Records":
                return "icon-data-insights"
            default:
                break;

        }
    }
    getdeletionLevelIcon(level){
        switch(level){
            case "CIDN" :
                return "icon-technologies2";
            case "Service Id" :
                return "icon-mobile-modem";
            default:
                break;
        }
    }

    cidnIsValid = cidn => cidn === "GENERATED" || (cidn && cidn.length === 10 && /^-{0,1}\d+$/.test(cidn));
    
    productEditRenderFunc = (tile, isSelected) => {
        if (isSelected)
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        id="productEditButton"
                        outline color="success"
                        onClick={() => { this.editProductType(tile) }}
                    >Edit</Button>
                </div >
            )
    }

    cosmosProductEditRenderFunc = (tile, isSelected) => {
        if (isSelected)
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                        id="cosmosEditButton"
                        outline color="success"
                        onClick={() => { this.editProductType(tile) }}
                    >Edit</Button>
                </div >
            )
    }
    isOptionSelected = ({ teamId }) => { return teamId == this.props.auth.team; }
    teamsOptions = (team) => { if (!team) return null;
        return { value: team.teamId, label: team.teamName, isSelected: this.isOptionSelected(team) };
      }

    render() {
        const teams = this.props.listOfTeams.map(teams => this.teamsOptions(teams));
        const activeTeam = teams.filter(option => option.isSelected);

        const handleModalClose = product => {
          let modalProd = this.props.selectedFilters.productData.find(
            obj => obj.name === product.toUpperCase()
          );
          if (!modalProd || modalProd.name === "MOBILE") {
            if (!modalProd.mobileData){
                return this.props.removeProductType(product);
            } else if(modalProd.mobileData.length === 0){
                return this.props.removeProductType(product);
            }
            return;
          }

          if (!modalProd || modalProd.adborFnns.length === 0)
            this.props.removeProductType(product);
        };

        const { type, action, cidn, products, level = [], productData = [] } = this.props.selectedFilters;
        const { saveTableView, serviceIdField, generateServiceId, serviceIDs } = this.props; //remove productArray
        let { prodFilters, actionFilters, delLevelFilters,cosmosProdFilters } = this.props.filters;
        let selectedCosmosProduct = products[0];
        
        // Build tiles and map icons
        prodFilters = prodFilters.map(product => ({ label: product, icon: this.getProductTypeIcon(product) }))    
        cosmosProdFilters = cosmosProdFilters.map(product => ({label:product, icon: this.getProductTypeIcon(product)}))     
        actionFilters = actionFilters.filter(action => action === 'Delete'? (this.props.isAdmin) : true).map(action =>({label: action, icon:this.getActionTypeIcon(action)}));
        delLevelFilters = delLevelFilters.map(level =>({label: level, icon: this.getdeletionLevelIcon(level)}));
        
       /*  if(activeTeam[0].label !== "TDM OPS")
        { let allTeamsFilter = [];
            actionFilters.map((actionFilter) => { 
            if(actionFilter.label !== "Cosmos Insert")
                {allTeamsFilter.push(actionFilter)}
           })
           actionFilters = allTeamsFilter;
        } */
        return (
          <div className="white">
            <SubHeader
              text={`S2P Replicator`}
              iconClass="td-icon-md icon-drag-and-drop-form mainIcon position-static"
              iconColor="green"
              h1
            />

            <ActionTiles 
                actionFilters = {actionFilters} 
                selectActionType = {this.selectActionType} 
                action = {action} 
            />

            {action === 'Delete' && (
                <DeleteTiles
                    delLevelFilters = {delLevelFilters}
                    selectDeletionLevel = {this.selectDeletionLevel}
                    level = {level}
                />
            )}

            {level === "CIDN" && (            
              <DeleteInputAction 
                selectedFilters = { this.props.selectedFilters }
                selectDeleteAction = { this.selectDeleteAction }
                handleDeleteOnSubmit = { this.handleDeleteOnSubmit}                
                header={"CIDN"}
              />
            )}

            {level === "Service Id" && (            
              <DeleteInputAction 
                selectedFilters = {this.props.selectedFilters} 
                selectDeleteAction = { this.selectDeleteAction }
                handleDeleteOnSubmit = { this.handleDeleteOnSubmit }                 
                header={"Service Id"}
              />
            )}

            {(action === "Insert" || action =="Cosmos Insert") && (
              <CustomerSection 
                selectedFilters = {this.props.selectedFilters}
                selectCIDN = {this.selectCIDN}
                updateDealerPremiseCode = { this.updateDealerPremiseCode }
              />
            )}
             {action === "Salesforce Update" && (
              <SalesForceUpdateSection/>
            )}
             {action === "Get Records" && (
              <CosmosRecords/>
            )}

            {this.cidnIsValid(cidn) && action =="Insert" &&(
              <DataTilesFrame
                heading="Products"
                color="orange"
                data={prodFilters}
                select={this.selectProductType.bind(this)}
                selected={products}
                isMulti={true}
                underRenderFunc={this.productEditRenderFunc.bind(this)}
              />
            )}
            {this.cidnIsValid(cidn) && action =="Cosmos Insert" &&(
              <DataTilesFrame
                heading="Products"
                color="orange"
                data={cosmosProdFilters}
                select={this.selectProductType.bind(this)}
                selected={products}
                isMulti={true}
                underRenderFunc={this.cosmosProductEditRenderFunc.bind(this)}
              />
            )}
            {action =="Insert" &&
            <NewProductModal
                onRef={ref => {
                  this.productModal = ref;
                }}
                type={type}
                cidn={cidn}
                formData={this.props.productModalForm}
                saveTableView={saveTableView}
                selectedProducts={products}
                productData={productData}
                handleModalClose={handleModalClose}
                generateServiceId={generateServiceId}
                serviceIdField={serviceIdField}
                serviceIDs={serviceIDs}
            /> }
         {action =="Cosmos Insert" && 
            <NewCosmosProductModal
                onRef={ref => {
                  this.productModal = ref;
                }}
                type={type}
                cidn={cidn}
                formData={this.props.productModalForm}
                saveTableView={saveTableView}
                selectedProducts={products}
                productData={productData}
                handleModalClose={handleModalClose}
                generateServiceId={generateServiceId}
                serviceIdField={serviceIdField}
                serviceIDs={serviceIDs}
            /> }

            {productData.filter( 
              product =>
                (product.adborFnns !== undefined &&
                  product.adborFnns.length > 0) ||
                (product.mobileData !== undefined && product.mobileData.length) || 
                (product.tiptSipcProduct !== undefined && product.tiptSipcProduct.length)
            ).length > 0 && (
              <SubmitAction 
                    handleOnSubmit = { this.handleOnSubmit }
                    selectedFilters = { this.props.selectedFilters }
              ></SubmitAction>
            )}
            <div ref={this.accordionEnd} />
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        listOfTeams: state.auth.myAvailableTeams,
        isAdmin: state.auth.isAdmin, 
        isSuperAdmin: state.auth.isSuperAdmin, 
        filters: state.replicatorData.filters,
        selectedFilters: state.replicatorData.selectedFilters,
        view: state.replicatorData.view,
        productModalForm: state.form.s2pProductModalForm,
        serviceIdField: state.replicatorData.serviceIdField || {},
        serviceIDs: state.form.s2pProductModalForm &&state.form.s2pProductModalForm.values && state.form.s2pProductModalForm.values.adborFnns || [],
        env: state.auth.selectedEnvValue,
        activeProfile: state.profiles.active,
        auth: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOptions: () => dispatch(fetchDataS2POptions()),
        selectFilter: (key, value, isMulti) => dispatch(selectS2PFilter(key, value, isMulti)),
        removeProductType: (product) => dispatch(removeProductType(product)),
        saveTableView: (table) => { dispatch(saveTableView(table)) },
        addCIDN: (cidn) => { dispatch(addCIDN(cidn)) },     
        updateDealerPremiseCode:(dealerPremiseCode)=>{dispatch(updateDealerPremiseCode(dealerPremiseCode))},  
        resetFilters: () => dispatch(resetSelectedFilters()),
        submitRequest: (replicatorCheckoutRequest) => dispatch(submitRequest(replicatorCheckoutRequest)),
        submitCIDNDeleteRequest  : (deleteReplicatorRequest) => dispatch(submitCIDNDeleteRequest(deleteReplicatorRequest)),
        submitServiceIdDeleteRequest : (deleteReplicatorRequest) => dispatch(submitServiceIdDeleteRequest(deleteReplicatorRequest)),
        generateServiceId: () => dispatch(generateServiceId()),
        fetchProductTypePlan: () => dispatch(fetchProductTypePlan()),
        fetchProductRepayments: () => dispatch(fetchProductRepayments()),

        selectCosmosFilter: (key, value) => dispatch(selectCosmosSelectFilter(key, value)),
        setCosmosSearchValue: (value) => dispatch(setSearchValue(value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplicatorUtility);
