const dev = {
  api: {
    uri: "http://W08D8EC48736DB.wg.dir.telstra.com:8085",
    brokerUrl: "ws://W08D8EC48736DB.wg.dir.telstra.com:8085/stomp",
  },
  tdm_gateway_api: {
    uri: "http://W046C66CBF0F24.wg.dir.telstra.com:9762",
    brokenUrl: "ws://W046C66CBF0F24.wg.dir.telstra.com:9762/stomp",
    // uri: "http://localhost:9762",
    //brokenUrl: "ws://localhost:9762/stomp",

  },
  tdm_epic_gateway_api: {
    uri: "http://W046C66CBF0F24.wg.dir.telstra.com:9762"
  },
  recaptcha: {
    siteKey: "6LcNXnIUAAAAAAIKyrCRhcBxvV4wPgZQR9U5t_Se",
    siteKeyInvisible: "6LfuXnIUAAAAAIlDKVEnysIH0vogS8xnFC9Vb0bh",
  },
  salesforce: {
    uri: "https://telstrab2b--tb2bqa2.lightning.force.com",
  },
  Salesforce_sqni_env: {
    uri: "http://telstra-retail--b2csqi26.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csqi26.lightning.force.com/stomp",
  },
  Salesforce_SIT_env: {
    uri: "http://telstra-retail--b2csit01.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csit01.lightning.force.com/stomp",
  },
  Custom_Data: {
    uri: "https://api-stag-tdm1.tdm-stag.tdm.telstra-cave.com/customdatabank/datasets/"
  },
  Custom_Data_Root: {
    uri: "https://api-stag-tdm1.tdm-stag.tdm.telstra-cave.com/customdatabank"
  },
  establish_customer_api: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/stag/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  establish_customer_api_osit3: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/osit3/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  title: "TDM Portal - DEV",
  dataBank_existing_request: {
    key: [44, 200, 201],
    value: [21, 3, 4],
  },
  currentEnv: "dev",
  is1TDM: false
};

const cloud = {
  api: {
    uri: "http://W08D8EC48736DB.wg.dir.telstra.com:8085",
    brokerUrl: "ws://W08D8EC48736DB.wg.dir.telstra.com:8085/stomp",
  },
  tdm_gateway_api: {
    uri: "http://w0889a4f9a3da9.wg.dir.telstra.com:9762",
    brokenUrl: "ws://w0889a4f9a3da9.wg.dir.telstra.com:9762/stomp",
  },
  tdm_epic_gateway_api: {
    uri: "http://w0889a4f9a3da9.wg.dir.telstra.com:9762"
  },
  recaptcha: {
    siteKey: "6LcNXnIUAAAAAAIKyrCRhcBxvV4wPgZQR9U5t_Se",
    siteKeyInvisible: "6LfuXnIUAAAAAIlDKVEnysIH0vogS8xnFC9Vb0bh",
  },
  salesforce: {
    uri: "https://telstrab2b--tb2bqa2.lightning.force.com",
  },
  Salesforce_sqni_env: {
    uri: "http://telstra-retail--b2csqi26.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csqi26.lightning.force.com/stomp",
  },
  Salesforce_SIT_env: {
    uri: "https://telstra-retail--b2csitnew.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csitnew.lightning.force.com/stomp",
  },
  title: "TDM Portal",
  dataBank_existing_request: {
    key: [44, 200, 201],
    value: [61, 3, 4],
  },
  currentEnv: "1tdmPrd",
  is1TDM: false
};

const prod = {
  api: {
    uri: "http://W08D8EC48736DB.wg.dir.telstra.com:8085",
    brokerUrl: "ws://W08D8EC48736DB.wg.dir.telstra.com:8085/stomp",
  },
  tdm_gateway_api: {
    uri: "http://w0889a4f9a3da9.wg.dir.telstra.com:9762",
    brokenUrl: "ws://w0889a4f9a3da9.wg.dir.telstra.com:9762/stomp",
  },
  tdm_epic_gateway_api: {
    uri: "http://w0889a4f9a3da9.wg.dir.telstra.com:9762",
  },
  recaptcha: {
    siteKey: "6LcNXnIUAAAAAAIKyrCRhcBxvV4wPgZQR9U5t_Se",
    siteKeyInvisible: "6LfuXnIUAAAAAIlDKVEnysIH0vogS8xnFC9Vb0bh",
  },
  salesforce: {
    uri: "https://telstrab2b--tb2bqa2.lightning.force.com",
  },
  Salesforce_sqni_env: {
    uri: "http://telstra-retail--b2csqi26.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csqi26.lightning.force.com/stomp",
  },
  Salesforce_SIT_env: {
    uri: "http://telstra-retail--b2csit01.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csit01.lightning.force.com/stomp",
  },
  Custom_Data: {
    uri: "https://api-prod-tdm1.tdm-prod.tdm.telstra-cave.com/customdatabank/datasets/"
  },
  Custom_Data_Root: {
    uri: "https://api-prod-tdm1.tdm-prod.tdm.telstra-cave.com/customdatabank"
  },
  establish_customer_api: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  establish_customer_api_osit3: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/osit3/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  title: "TDM Portal",
  dataBank_existing_request: {
    key: [44, 200, 201],
    value: [61, 3, 4],
  },
  currentEnv: "prod",
  is1TDM: false
};

const _1tdmPrd = {
  api: {
    uri: "http://W08D8EC48736DB.wg.dir.telstra.com:8085",
    brokerUrl: "ws://W08D8EC48736DB.wg.dir.telstra.com:8085/stomp",
  },
  tdm_gateway_api: {
    uri: "https://api.tdm-prod.tdm.telstra-cave.com",
    brokenUrl: "ws://api.tdm.in.telstra.com.au/stomp",
  },
  tdm_epic_gateway_api: {
    uri: "https://api.tdm-prod.tdm.telstra-cave.com",
  },
  recaptcha: {
    siteKey: "6LcNXnIUAAAAAAIKyrCRhcBxvV4wPgZQR9U5t_Se",
    siteKeyInvisible: "6LfuXnIUAAAAAIlDKVEnysIH0vogS8xnFC9Vb0bh",
  },
  salesforce: {
    uri: "https://telstrab2b--tb2bqa2.lightning.force.com",
  },
  Salesforce_sqni_env: {
    uri: "http://telstra-retail--b2csqi26.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csqi26.lightning.force.com/stomp",
  },
  Salesforce_SIT_env: {
    uri: "http://telstra-retail--b2csit01.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csit01.lightning.force.com/stomp",
  },
  Custom_Data: {
    uri: "https://customdatabank.tdm-prod.tdm.telstra-cave.com/customdatabank/datasets/"
  },
  Custom_Data_Root: {
    uri: "https://customdatabank.tdm-prod.tdm.telstra-cave.com/customdatabank"
  },
  establish_customer_api: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  establish_customer_api_osit3: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/osit3/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  title: "TDM Portal",
  dataBank_existing_request: {
    key: [44, 200, 201],
    value: [61, 3, 4],
  },
  currentEnv: "1tdmPrd",
  taasEnv: "taas-prod",
  is1TDM: true,
  ssoUrl : "http://testcoe.in.telstra.com.au/auth?clientId=75ae850c-fb07-11eb-9a03-0242ac130003",
  redirectUrl : "https://portal.tdm-prod.tdm.telstra-cave.com/",
  taas_sso_url: "https://auth.sso-prod.taas.telstra-cave.com/saml/login",
  taas_user_base: "https://user.taas-prod.taas.telstra-cave.com",
  taas_oauth: "https://oauth.sso-prod.taas.telstra-cave.com"
};

const _1tdmStaging = {
  api: {
    uri: "http://W08D8EC48736DB.wg.dir.telstra.com:8085",
    brokerUrl: "ws://W08D8EC48736DB.wg.dir.telstra.com:8085/stomp",
  },
  tdm_gateway_api: {
    uri: "https://api.tdm-stag.tdm.telstra-cave.com",
    brokenUrl: "ws://api.pp.tdm.in.telstra.com.au/stomp",
  },
  tdm_epic_gateway_api: {
    uri: "https://api.tdm-stag.tdm.telstra-cave.com",
  },
  recaptcha: {
    siteKey: "6LcNXnIUAAAAAAIKyrCRhcBxvV4wPgZQR9U5t_Se",
    siteKeyInvisible: "6LfuXnIUAAAAAIlDKVEnysIH0vogS8xnFC9Vb0bh",
  },
  salesforce: {
    uri: "https://telstrab2b--tb2bqa2.lightning.force.com",
  },
  Salesforce_sqni_env: {
    uri: "http://telstra-retail--b2csqi26.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csqi26.lightning.force.com/stomp",
  },
  Salesforce_SIT_env: {
    uri: "http://telstra-retail--b2csit01.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csit01.lightning.force.com/stomp",
  },
  Custom_Data: {
    uri: "https://customdatabank.tdm-stag.tdm.telstra-cave.com/customdatabank/datasets/"
  },
  Custom_Data_Root: {
    uri: "https://customdatabank.tdm-stag.tdm.telstra-cave.com/customdatabank"
  },
  establish_customer_api: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/stag/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  establish_customer_api_osit3: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/osit3/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  title: "TDM Portal",
  dataBank_existing_request: {
    key: [44, 200, 201],
    value: [61, 3, 4],
  },
  is1TDM: true,
  currentEnv: "1tdmStg",
  // taasEnv: "taas-prod",
  // ssoUrl : "http://testcoe.in.telstra.com.au/authStg?clientId=75ae850c-fb07-11eb-9a03-0242ac130003",
  taasEnv: "taas-dev",
  ssoUrl : "http://testcoe.in.telstra.com.au/authQa?clientId=75ae850c-fb07-11eb-9a03-0242ac130003",
  redirectUrl : "https://portal.tdm-stag.tdm.telstra-cave.com/",
  taas_sso_url: "https://auth.sso-dev.taas.telstra-cave.com/saml/login",
  taas_user_base: "https://user.taas-dev.taas.telstra-cave.com",
  taas_oauth: "https://oauth.sso-dev.taas.telstra-cave.com"
};

const _1tdmQA = {
  api: {
    uri: "http://W08D8EC48736DB.wg.dir.telstra.com:8085",
    brokerUrl: "ws://W08D8EC48736DB.wg.dir.telstra.com:8085/stomp",
  },
  tdm_gateway_api: {
    uri: "https://api.tdm-dev.tdm.telstra-cave.com",
    brokenUrl: "ws://api.tdm-qa.telstra-cave.com/stomp",
  },
  tdm_epic_gateway_api: {
    uri: "https://api.tdm-dev.tdm.telstra-cave.com",
  },
  recaptcha: {
    siteKey: "6LcNXnIUAAAAAAIKyrCRhcBxvV4wPgZQR9U5t_Se",
    siteKeyInvisible: "6LfuXnIUAAAAAIlDKVEnysIH0vogS8xnFC9Vb0bh",
  },
  salesforce: {
    uri: "https://telstrab2b--tb2bqa2.lightning.force.com",
  },
  Salesforce_sqni_env: {
    uri: "http://telstra-retail--b2csqi26.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csqi26.lightning.force.com/stomp",
  },
  Salesforce_SIT_env: {
    uri: "http://telstra-retail--b2csit01.lightning.force.com",
    brokenUrl: "ws://telstra-retail--b2csit01.lightning.force.com/stomp",
  },
  Custom_Data: {
    uri: "https://customdatabank.tdm-dev.tdm.telstra-cave.com/customdatabank/datasets/"
  },
  Custom_Data_Root: {
    uri: "https://customdatabank.tdm-dev.tdm.telstra-cave.com/customdatabank"
  },
  establish_customer_api: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/stag/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  establish_customer_api_osit3: {
    uri: "https://ctlyst-tdm-tdm-omp.sv.telstra-cave.com/osit3/billing/v2/billing/billing-accounts/customer-account-code/"
  },
  title: "TDM Portal-QA",
  dataBank_existing_request: {
    key: [44, 200, 201],
    value: [61, 3, 4],
  },
  is1TDM: true,
  currentEnv: "1tdmQa",
  taasEnv: "taas-dev",
  ssoUrl : "http://testcoe.in.telstra.com.au/authQa?clientId=75ae850c-fb07-11eb-9a03-0242ac130003",
  redirectUrl : "https://portal.tdm-dev.tdm.telstra-cave.com/",
  taas_sso_url: "https://auth.sso-dev.taas.telstra-cave.com/saml/login",
  taas_user_base: "https://user.taas-dev.taas.telstra-cave.com",
  taas_oauth: "https://oauth.sso-dev.taas.telstra-cave.com"
};

const saveOauthEnvVar = (value) => {
  window._env_ = {
    ...window._env_,
    OAUTH_URL: value || ""
  }
}

const buildConfig = () => {
  switch (process.env.REACT_APP_STAGE) {
    case "production":
      return prod;
    case "dev":
      return dev;
    case "1tdm-prd":
      return _1tdmPrd;
    case "1tdm-stg":
      return _1tdmStaging;
    case "1tdm-qa":
      return _1tdmQA;
    default:
      return _1tdmQA;
  }
};

const config = buildConfig();
saveOauthEnvVar(`${config.taas_oauth}/`);

export default {
  ...config,
};
