import React, { Component } from "react";
import { connect } from "react-redux";
import { customerConsoleSelectRow } from '../../../actions/utilityActions';

class ContactDataTableRow extends Component {

    render(){
        const link = "https://telstra-retail--b2csitnew.lightning.force.com/lightning/r/Contact/"+this.props.tableRowData.salesforceContactId+"/view";
        return (
            <tr id="contact-detail-row">
                <td style={{"textAlign":"center"}}>
                    {<input type="radio" value={this.props.tableRowData} onClick={()=>this.props.customerConsoleSelectRow(this.props.tableRowData)} checked={this.props.selectedRow === this.props.tableRowData}/>}
                </td>
                <td style={{"minWidth":"116px"}}>
                    {this.props.tableRowData.contactId}
                </td>
                <td>
                    <a href={link} target="_blank">{this.props.tableRowData.salesforceContactId}</a>
                </td>
                <td>
                    {this.props.tableRowData.contactType}
                </td>
                <td>
                    {this.props.tableRowData.firstName}
                </td>
                <td>
                    {this.props.tableRowData.lastName}
                </td>
                <td style={{"minWidth":"100px"}}>
                    {this.props.tableRowData.dob}
                </td>
                <td>
                    {this.props.tableRowData.phoneNo}
                </td>
                <td>
                    {this.props.tableRowData.email}
                </td>
                <td>
                    {this.props.tableRowData.contactAddress}
                </td>
                <td>
                    {this.props.tableRowData.identityProfileId && <i class="td-icon-sm icon-tick text-success" aria-hidden="true"></i>}
                </td>
            </tr>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedRow: state.utility.siebelCustomer.selectedRow,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        customerConsoleSelectRow : (customer) => dispatch(customerConsoleSelectRow(customer)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDataTableRow);