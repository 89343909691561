import {axiosInstance as axios} from '../axios.config';
import * as types from './actionTypes';
import config from '../config';
import { authHeaders } from '../utils/api';
import _ from "lodash";

const API_URL_GATEWAY = config.tdm_gateway_api.uri;

export const addToCart = (key, value) => async dispatch => {
    dispatch({ type: types.ADD_TO_ORDER_BUILDER_CART, key, value});
}

export const removeFromCart = (key) => async dispatch => {
    dispatch({ type: types.REMOVE_FROM_ORDER_BUILDER_CART, key});
}

export const removeCustomOrder = () => async dispatch => {
    dispatch({ type: types.REMOVE_FROM_CUSTOM_ORDER_BUILDER_CART});
}

export const updateCustomerInput = (input) => async dispatch => {
    dispatch({ type: types.UPDATE_CUSTOMER_INPUT_ORDER_BUILDER, input });
}

export const resetOrderBuilder = () => async dispatch => {
    dispatch({ type: types.RESET_ORDER_BUILDER });
}
export const resetOrderBuilderForm = () => async dispatch => {
    dispatch({ type: types.FORM_RESET_ORDER_BUILDER });
}

export const sendOrder = (payload) => async dispatch => {
    try {
        dispatch({ type: types.SEND_ORDER_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/tdm/orderbuilder/tickets`, payload, { headers: authHeaders()});
        if (response.status === 200) {
            dispatch({ type: types.SEND_ORDER_SUCCESS, payload: response.data.message });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.SEND_ORDER_FAILED, payload: "Failed to complete order" });
        }
    } catch (error) {
        dispatch({ type: types.SEND_ORDER_FAILED, payload: `Error while sending order - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}` });
    }
}

export const saveWishlist = (payload) => async dispatch => {
    try {
        dispatch({ type: types.SAVE_WISHLIST_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/tdm/orderbuilder/wishlist/add`, payload, { headers: authHeaders()});
        if (response.status === 200) {
            dispatch({ type: types.SAVE_WISHLIST_SUCCESS, payload: "Wishlist saved successfully" });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.SAVE_WISHLIST_FAILED, payload: "Failed to save wishlist" });
        }
    } catch (error) {
        dispatch({ type: types.SAVE_WISHLIST_FAILED, payload: `Error while saving wishlist - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}` });
    }
}

export const fetchOrderFilters = () => async (dispatch) => {
    try {
        dispatch({ type: types.FETCH_ORDER_FILTERS_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/orderbuilder/filter?type=FILTER`, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            dispatch({ type: types.FETCH_ORDER_FILTERS_SUCCESS, payload: response.data });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.FETCH_ORDER_FILTERS_FAILED, payload: "Failed to retrieve order filters" });
        }
    } catch (error) {
        dispatch({ type: types.FETCH_ORDER_FILTERS_FAILED, payload: `Error while retrieving order filters - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}` });
    }
}

export const fetchOrderSprints = () => async (dispatch) => {
    try {
        dispatch({ type: types.FETCH_ORDER_SPRINTS_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/orderbuilder/field?type=SPRINT`, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            dispatch({ type: types.FETCH_ORDER_SPRINTS_SUCCESS, payload: response.data });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.FETCH_ORDER_SPRINTS_FAILED, payload: "Failed to retrieve order sprints" });
        }
    } catch (error) {
        dispatch({ type: types.FETCH_ORDER_SPRINTS_FAILED, payload: `Error while retrieving order sprints - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}` });
    }
}

export const fetchOrderEnvs = () => async (dispatch) => {
    try {
        dispatch({ type: types.FETCH_ORDER_ENVS_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/orderbuilder/field?type=ENV`, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            dispatch({ type: types.FETCH_ORDER_ENVS_SUCCESS, payload: response.data });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.FETCH_ORDER_ENVS_FAILED, payload: "Failed to retrieve order envs" });
        }
    } catch (error) {
        dispatch({ type: types.FETCH_ORDER_ENVS_FAILED, payload: `Error while retrieving order envs - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}` });
    }
}

export const fetchOpenOrders = (teamId,teamName,env,serviceIds) => async (dispatch) => {
    try {
        dispatch({ type: types.FETCH_OPEN_ORDERS_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/orderbuilder/tickets?teamName=${teamId}-${teamName}&env=${env}&ids=${serviceIds}`, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            dispatch({ type: types.FETCH_OPEN_ORDERS_SUCCESS, payload: response.data.tickets });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.FETCH_OPEN_ORDERS_FAILED, payload: "Failed to retrieve open orders" });
        }
    } catch (error) {
        if (error.response.status === 404) {
            dispatch({ type: types.FETCH_OPEN_ORDERS_FAILED, payload: "No open orders for given team" });
        } else {
            let errorResp = "Failed to retrieve open orders";
            if (error.response.data) {
                if (error.response.data.error && error.response.data.error.details && error.response.data.error.details.length > 0) {
                    errorResp = error.response.data.error.details[0];
                }
            }
            dispatch({ type: types.FETCH_OPEN_ORDERS_FAILED, payload: errorResp });
        }
    }
}

export const fetchAvailableDataAssets = (teamId,env,serviceIds) => async (dispatch) => {
    try {
        const envVal = env === "QA" ? "INT1" : env === "INT2" ? "SIT" : "OSIT3";
        dispatch({ type: types.FETCH_AVAILABLE_DATA_ASSETS_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/orderbuilder/assets/team?teamId=${teamId}&env=${envVal}&serviceIds=${serviceIds}`, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            dispatch({ type: types.FETCH_AVAILABLE_DATA_ASSETS_SUCCESS, payload: response.data.asset });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.FETCH_AVAILABLE_DATA_ASSETS_FAILED, payload: "Failed to retrieve available data assets" });
        }
    } catch (error) {
        if (error.response.status === 404) {
            dispatch({ type: types.FETCH_AVAILABLE_DATA_ASSETS_FAILED, payload: "No existing data assets for given team" });
        } else {
            let errorResp = "Failed to retrieve data assets";
            if (error.response.data) {
                if (error.response.data.error && error.response.data.error.details && error.response.data.error.details.length > 0) {
                    errorResp = error.response.data.error.details[0];
                }
            }
            dispatch({ type: types.FETCH_AVAILABLE_DATA_ASSETS_FAILED, payload: errorResp });
        }
    }
}

export const resetMyOrders = () => async (dispatch) => {
    dispatch({ type: types.FETCH_MY_ORDERS_RESET });
}

export const fetchMyOrders = (teamId, teamName, size, page, prevContent) => async (dispatch) => {
    try {
        dispatch({ type: types.FETCH_MY_ORDERS_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/orderbuilder/orders?teamId=${teamId}&teamName=${teamName}&size=${size}&page=${page}`, { headers: authHeaders()});
        if (response.status === 200 && response.data && response.data.content) {
            let content = prevContent ? 
            [...prevContent, ...response.data.content] : response.data.content;
            response.data.content = content;
            dispatch({ type: types.FETCH_MY_ORDERS_SUCCESS, payload: response.data });
            return response.data;
        } else if (response.status !== 200) {
            dispatch({ type: types.FETCH_MY_ORDERS_FAILED, payload: "Failed to retrieve orders" });
            return false;
        }
    } catch (error) { 
        if (error.response.status === 404) {
            dispatch({ type: types.FETCH_MY_ORDERS_FAILED, payload: "No existing orders for given team" });
            return false;
        } else {
            let errorResp = "Failed to retrieve orders";
            if (error.response.data) {
                if (error.response.data.error && error.response.data.error.details && error.response.data.error.details.length > 0) {
                    errorResp = error.response.data.error.details[0];
                }
            }
            dispatch({ type: types.FETCH_MY_ORDERS_FAILED, payload: errorResp });
            return false;
        }
    }
}

export const fetchServiceList = () => async (dispatch) => {
    try {
        dispatch({ type: types.FETCH_SERVICE_LIST_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/orderbuilder/service/list`, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            dispatch({ type: types.FETCH_SERVICE_LIST_SUCCESS, payload: response.data.service });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.FETCH_SERVICE_LIST_FAILED, payload: "Failed to retrieve service list" });
        }
    } catch (error) {
        dispatch({ type: types.FETCH_SERVICE_LIST_FAILED, payload: `Error while retrieving service list - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}` });
    }
}

export const fetchWishlists = (teamId) => async (dispatch) => {
    try {
        dispatch({ type: types.FETCH_WISHLISTS_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/orderbuilder/wishlist/list?teamId=${teamId}`, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            dispatch({ type: types.FETCH_WISHLISTS_SUCCESS, payload: response.data });
            return response.data;
        } else if (response.status !== 200) {
            dispatch({ type: types.FETCH_WISHLISTS_FAILED, payload: "Failed to retrieve wishlists" });
            return false;
        }
    } catch (error) {
        if (error.response.status === 404) {
            dispatch({ type: types.FETCH_WISHLISTS_FAILED, payload: "No Available Wishlists" });
            return false;
        }
        dispatch({ type: types.FETCH_WISHLISTS_FAILED, payload: "Error while retrieving wishlists" });
        return false;
    }
}

export const setCustomOrder = (value) => async (dispatch) => {
    try{
        dispatch({ type: types.IS_CUSTOM_ORDER , payload : value });
    }
    catch (error) {
        console.error(error)
    }
}

export const setStatelessSimType = (value) => async (dispatch) => {
    try{
       console.log(value)
    }
    catch (error) {
        console.error(error)
    }
}
export const prodData = (value) => async (dispatch) => {
    try{
       console.log(value)
    }
    catch (error) {
        console.error(error)
    }
}

export const setOrderType = (value) => async (dispatch) => {
    try{
        dispatch({ type: types.CUSTOM_ORDER_STATE , payload : value });
    }
    catch (error) {
        console.error(error)
    }
}
export const setSpecificRequirement = (values,sID) => async (dispatch) => {
    try{
        let key = Object.keys(values);
        let value =1 ;
        dispatch({ type: types.CUSTOM_SPECIFIC_REQ , payload : values , serviceID : sID});  
        dispatch({ type: types.ADD_TO_CUSTOM_ORDER_BUILDER_CART,key,value });
    }
    catch (error) {
        console.error(error)
    }
}

export const createCustomOrder = (value) => async (dispatch) => {
    try{
        const response = await axios.post(`${API_URL_GATEWAY}/tdm/orderbuilder/tickets`, value, { headers: authHeaders()});
        if (response.status === 200) {
            dispatch({ type: types.SEND_ORDER_SUCCESS, payload: response.data.message });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.SEND_ORDER_FAILED, payload: "Failed to complete order" });
        }
        return true;
    }
    catch(error)
    {
        dispatch({ type: types.SEND_ORDER_FAILED, payload: `Error while sending order - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}` });
        console.error(error)
    }
}

export const validateDigitNumber = (value) => async (dispatch) => {
    try{
        const response = await axios.post(`${API_URL_GATEWAY}/tdm/orderbuilder/validate-digits-numbers`, value, { headers: authHeaders()});
        return response;
    }
    catch(error)
    {
        dispatch({ type: types.FETCH_IRNUMBERS_FAILED, payload: `Error while retrieving digNumbers` });
    }
}