import * as types from "../actions/actionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  filters: {
    searchTypes: [
      { label: "CIDN", icon: "icon-research-analytics" },
      { label: "MSISDN", icon: "icon-research-analytics" },
      { label: "FNN", icon: "icon-research-analytics" },
      { label: "IMSI", icon: "icon-research-analytics" },
      { label: "SIM", icon: "icon-research-analytics" },
    ],
  },
  selectedFilters: {},
  searchValue: "",
  cosmosRecords: {data: [], errorResponse: null ,noRecords: false}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SELECT_COSMOS_SEARCH_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.key]: action.value
        }
      };
    case types.RESET_COSMOS_SEARCH_FILTERS:
      return { ...INITIAL_STATE };
    case types.SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.value
      };
    case types.FETCH_COSMOS_RECORDS_PENDING:
      return {
        ...state,
        cosmosRecords: {
          data: action.payload, 
          errorResponse: null ,
          noRecords : false
        }
      };
    case types.FETCH_COSMOS_RECORDS_SUCCESS:
      return {
        ...state,
        cosmosRecords: {
          data: action.payload, 
          errorResponse: null,
          noRecords : action.noRecords }
      };
      case types.FETCH_COSMOS_RECORDS_FAILURE:
        return {
          ...state,
          cosmosRecords: {
            data: [], 
            errorResponse: action.payload,
            noRecords: false }
        };
    default:
      return state;
  }

};
