import React from "react";

export default ({ payment }) => (
  <tr>
    <th>{payment.transactionId}</th>
    <td>{payment.paymentAmount}</td>
    <td>{payment.paymentDate}</td>
    <td>{payment.paymentUnappliedAmount}</td>
    <td>{payment.invoiceNumber}</td>
  </tr>
);
