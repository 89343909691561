import {axiosInstance as axios} from '../axios.config';
import { TIM_MIGRATION_LOADING, EXISTING_RECORD_FALIURE, GET_FETCH_EXISTING_RECORD_LOAD, GET_FETCH_EXISTING_RECORD, TIM_MIGRATION_SUCCESS,TIM_MIGRATION_FALIURE, TIM_MIGRATION_URL, FETCH_EXISTING_RECORD, REFERENCE_RECORD_FALIURE, GET_FETCH_REFERENCE_RECORD_LOAD,GET_FETCH_REFERENCE_RECORD, FETCH_REFERENCE_RECORD, ALL_REFERENCE_RECORD_FALIURE, GET_FETCH_ALL_REFERENCE_RECORD_LOAD, GET_FETCH_ALL_REFERENCE_RECORD, FETCH_ALL_REFERENCE_RECORD, UPDATE_REFERENCE_LOADING } from "./actionTypes";
import config from "../config";
import {authHeaders} from "../utils/api";
import swal from 'sweetalert2';

const API_URL_GATEWAY = config.tdm_gateway_api.uri;


export const submitBulkOrder = (req)=> async (dispatch)=>{
    try{
        dispatch({type: TIM_MIGRATION_LOADING});
        const res = await axios.post(`${API_URL_GATEWAY}${TIM_MIGRATION_URL}`, req,{ headers: authHeaders() })
        dispatch({type: TIM_MIGRATION_SUCCESS});
        return res.data;
    }
    catch(err){
        dispatch({type: TIM_MIGRATION_FALIURE, payload: err});
        return err.response.data;
    }

}

export const submitTimMigration = (req)=> async ()=>{
  try{
      const res = await axios.post(`${API_URL_GATEWAY}/tdm/migration/customer/service`, req,{ headers: authHeaders() })
      return res.data;
  }
  catch(err){
      return err.response.data;
  }
}

export const fetchExistingRecord=({page})=> async (dispatch)=>{
    try{
        dispatch({type: GET_FETCH_EXISTING_RECORD_LOAD,payload:null});
        const res = await axios.get(`${API_URL_GATEWAY}/${FETCH_EXISTING_RECORD}?page=${page}&size=10`,{ headers: authHeaders() });
        dispatch({type: GET_FETCH_EXISTING_RECORD,payload:res.data});
    }
    catch(err){
        dispatch({type: EXISTING_RECORD_FALIURE, payload: "Network connectivity issues, please try again later."});
    }
}

export const fetchAllReferenceData=()=> async (dispatch)=>{
    try{
        dispatch({type: GET_FETCH_ALL_REFERENCE_RECORD_LOAD});
        const res = await axios.get(`${API_URL_GATEWAY}/${FETCH_ALL_REFERENCE_RECORD}`,{ headers: authHeaders() });
        dispatch({type: GET_FETCH_ALL_REFERENCE_RECORD,payload:res.data});
    }
    catch(err){
        dispatch({type: ALL_REFERENCE_RECORD_FALIURE});
        return swal.fire("Failed","Network connectivity issues, please try again later.",'error');
    }
}

export const fetchReferenceData=()=> async (dispatch)=>{
    try{
        dispatch({type: GET_FETCH_REFERENCE_RECORD_LOAD});
        const res = await axios.get(`${API_URL_GATEWAY}/${FETCH_REFERENCE_RECORD}`,{ headers: authHeaders() });
        dispatch({type: GET_FETCH_REFERENCE_RECORD,payload:res.data});
    }
    catch(err){
        dispatch({type: REFERENCE_RECORD_FALIURE});
        return swal.fire("Failed","Network connectivity issues, please try again later.",'error');
    }
}

export const updateReference=(request)=> async (dispatch)=>{
    try {
      dispatch({ type: UPDATE_REFERENCE_LOADING });
      const response = await axios.post(`${API_URL_GATEWAY}/${FETCH_REFERENCE_RECORD}`, request, { headers: authHeaders() });
      if (response.status && response.status === 200) {
        fetchAllReferenceData();
        return swal.fire("Success",`${response.data.id} Tim Referene updated successfully`,'success');
      } else {
        return swal.fire("Failed",`${request.id} Tim Referene update failed`,'error');
      }
    } catch (e) {
      let message = "";
      if (!e.response) { message = "Network connectivity issues, please try again later." }
      else {
        if (e.response.data.error && e.response.data.error.messages && e.response.data.error.messages[0]) {
          message = e.response.data.error.messages[0];
        } else {
          message = e.response.data.message;
        }
      }
      return swal.fire("Failed", message, 'error');
    }
  }