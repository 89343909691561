import React, { Component } from 'react'
import { Fade } from 'reactstrap';
import DataTile from './DataTile';
export default (props) => {

    const {
        subSelected,
        heading,
        dataBankVacant,
        handleSelect
    } = props;
    const tileSelected = subSelected !== "";

    return (
        <Fade in={true}><span className="dataview-header">
            <p className="sub-leader font-weight-light">{heading}{tileSelected
                ? ` - `
                : ''}{subSelected ? ` (${subSelected})` : ''}</p>
            <i
                class={`${tileSelected
                    ? 'td-icon-sm icon-tick text-success'
                    : ''}`}
                aria-hidden="true"></i>
        </span>

            <div className="content">
                <div className={`tiles-grid base-${'magenta'}`}>
                    {dataBankVacant.map(dataObj => <DataTile
                        dataObj={dataObj}
                        handleSelect={handleSelect}
                        subSelected={subSelected}
                        icon='icon-sim-card'
                        size= {tileSelected ? 'small' : 'medium'}
                    />)}
                </div></div></Fade>
    )
}