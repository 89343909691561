import React from "react";
import PropTypes from "prop-types";

import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useCentralAlignStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignContent: "space-between",
    color: theme.palette.text.primary,
  },
}));

const defaultState = false;
const ActivationSwitch = (props) => {
  const { 
    isActive = defaultState, 
    onChange,
    activeLabel = "Active",
    inactiveLabel = "Deactivated",
    color = "primary",
    tooltipText,
    disabled = false,
    ...rest
  } = props;

  const formControlLabelStyle = useCentralAlignStyle();

  return (
    <FormControlLabel
      classes={formControlLabelStyle}
      control={
        tooltipText ?
        <Tooltip arrow title={tooltipText}>
          <span>
            <Switch color={color} checked={isActive} onChange={onChange} disabled={disabled}/>
          </span>
        </Tooltip> :
        <Switch color={color} checked={isActive} onChange={onChange} />
      }
      label={isActive ? activeLabel : inactiveLabel}
      {...rest}
    />
  );
};

ActivationSwitch.propTypes = {
  isActive: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  activeLabel: PropTypes.string,
  inactiveLabel: PropTypes.string,
  color: PropTypes.oneOf(['default','primary','secondary']),
};

export default ActivationSwitch;
