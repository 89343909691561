import React, { Component } from 'react'
import { Fade } from 'reactstrap';

export const DataFilterTileNew = ({
    label,
    icon,
    selectedTypes,
    selectedSubtypes,
    hover,
    selectType,
    bgColor,
    classBadge,
    classId,
    name: className,
    obj,
    qty,
    prevSelected,
    size,
    fromFeedbackModal
}) => {
    // console.log("selectedType : ", selectedTypes," fromFeedbackModal : ", fromFeedbackModal);
    // console.log("label: ", label,"  classid : ", classId );
    // console.log(" selectedSubtypes : ", selectedSubtypes ," className : ");
    // console.log(className ," classid: ", classId, "  qty : ", qty);
    // console.log("hover : ", hover)
    const selected = ((selectedTypes == label) || (((selectedTypes == classId && selectedSubtypes == className) && classId) || (((selectedTypes == qty) && qty)))); // can be modified later to check within array if using multiple selections?
    // console.log("selected: ", selected);
    return (

        <div
            data-size={(selectedTypes && !hover)
                ? 'small'
                : (prevSelected === "Postpaid" ? 'wide' : (size ? size : 'medium'))}
            data-role="hint"
            data-hint-text={label}
            data-cls-hint="drop-shadow"
            data-hint-hide="1000"
            data-cls-hint="bg-cyan fg-white drop-shadow"
            className={`bg-${bgColor} featured-gradient tile-${((selectedTypes && !hover))
                ? 'small'
                : (prevSelected === "Postpaid" ? 'wide' : (size ? size : 'medium'))} ${selected
                    ? 'selected'
                    : ''}`}
            style={{
                "opacity": (selected || !selectedTypes)
                    ? "1"
                    : ".5"
            }}
            key="{label}"
            onClick={() => {
                console.log("tile clicked: ", selectType, "  obj : ", obj)
                if (selectType) selectType(obj)
            }}>
            {(classBadge && !selectedTypes) && <span class="badge-top"><b>{classBadge}</b></span>}
            {(qty)
                && <h1 class="pos-absolute pos-center">{qty}</h1>}
            {((classBadge || qty) && selectedTypes)
                ? (
                    <h1 class="pos-absolute pos-center">{classId}{qty}</h1>
                )
                : (
                    <React.Fragment>
                        {(!qty) && <span className={`td-icon-md ${icon} icon`}></span>}
                        <span className="branding-bar">{label}</span>
                    </React.Fragment>
                )}
        </div>)
}