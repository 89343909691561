import config from '../config';
export const getSFConf = auth => {
    const currentEnvironemt = auth.selectedEnvValue;
    const currentRoles = auth.roles;

    return { currentEnvironemt, currentRoles };
}

export const is1TdmUser = () =>{
    return config.is1TDM === true;
}

export const isB2bAdmin = (roles) => {
    return roles.includes('B2B_ADMIN');
}

export const isB2bUser = (roles) => {
    return roles.some(r => ['B2B_ADMIN', 'B2B_USER'].includes(r));
}