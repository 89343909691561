import React, {useState} from 'react';
import {Table} from "reactstrap";
import _ from 'lodash';
import {SubHeader} from "../../../../common/custom/SubHeader";
import { is1TdmUser } from '../../../../../utils/userProfile';

const fillNa = (objectValue) => {
    if (!objectValue)
        return "NA";
    return objectValue;
}
export default ({simImsiDetails, msisdnDetails, mobileItems, prepaidDetails, isReplicatorDelete, assetDetails}) => {
    let sim, imsi, msisdn, source  = '';
    let orderSimImsiMsisdns = [];
    let assetShowCheck = (assetDetails && assetDetails.length>1) && ((simImsiDetails && simImsiDetails.length>1) || (msisdnDetails && msisdnDetails.length>1));
     const [showAssetId, setShowAssetId] = useState(assetShowCheck);
    if (!_.isEmpty(prepaidDetails)) {
        ({sim, imsi, msisdn} = prepaidDetails);
    }

    if(!_.isEmpty(simImsiDetails) && !_.isEmpty(msisdnDetails)) {
        simImsiDetails.map((data, index) =>      
            orderSimImsiMsisdns.push({
                imsi: data.imsi,
                sim: data.sim,
                msisdn: msisdnDetails[index].msisdn
            })
        )
    }

    if (is1TdmUser() && source === 'REPLICATOR' && !_.isEmpty(mobileItems[0])) {
        ({simImsiDetails, msisdnDetails} = mobileItems[0]);
    }

    return (
        <React.Fragment>
            <SubHeader text={"Mobile"} iconClass={"td-icon-md icon-mobile"} iconColor={"orange"} data={mobileItems} filename={`MOBILE-DATA`}/>
            <Table>
                <thead>
                    <tr>
                        {showAssetId?<th>Asset Id</th>:null}                        
                        <th>MSISDN</th>
                        <th>IMSI</th>
                        <th>SIM</th>
                    </tr>
                </thead>
                <tbody>
                {
                    (is1TdmUser() && source === 'REPLICATOR') ?
                    simImsiDetails.map((data, index) => 
                        <tr key={index}>
                            <td className={!isReplicatorDelete ? "green" : "red"}>{fillNa(msisdnDetails[index].msisdn)}</td>
                            <td className={!isReplicatorDelete ? "green" : "red"}>{fillNa(data.imsi)}</td>
                            <td className={!isReplicatorDelete ? "green" : "red"}>{fillNa(data.sim)} </td>
                        </tr>
                    )
                    :
                    orderSimImsiMsisdns.length > 0  && 
                    orderSimImsiMsisdns.map((data, index) => 
                    <tr key={index}>
                        <td>{fillNa(data.msisdn)}</td>
                        <td>{fillNa(data.imsi)}</td>
                        <td>{fillNa(data.sim)} </td>
                    </tr>
                    ) ||
                    simImsiDetails.length>0 &&
                    simImsiDetails.map((data, index) => 
                    <tr key={index}>
                        {assetShowCheck?<td>{assetDetails[index] && assetDetails[index].id}</td>:null
                        }
                        <td>NA</td>
                        <td>{fillNa(data.imsi)}</td>
                        <td>{fillNa(data.sim)} </td>
                    </tr>
                    ) ||
                    msisdnDetails.length>0 && 
                    msisdnDetails.map((data, index) => 
                    <tr key={index}>
                        {assetShowCheck?<td>{assetDetails[index] && assetDetails[index].id}</td>:null
                        }                        
                        <td>{fillNa(data.msisdn)}</td>
                        <td>NA</td>
                        <td>NA</td>
                    </tr>
                    ) ||
                    !_.isEmpty(prepaidDetails) && 
                    <tr key='prepaidDetials'>
                        <td>{fillNa(msisdn)}</td>
                        <td>{fillNa(imsi)}</td>
                        <td>{fillNa(sim)}</td>
                    </tr>
                    
                }
                </tbody>
            </Table>
        </React.Fragment>
)


}