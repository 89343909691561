import React from 'react'
import { Table } from 'reactstrap'
import { RowIconItem } from '../common/tables/RowIconItem';
import { getDataTypeIcon } from '../../utils/ui'

export default function TConnectDataViewCustomerTable(props) {

    const { customerId, cidn, name, type } = props.customer
    const env=props.env
    return (
        <Table className="checkout-history-table">
            <thead>
                <tr>
                    <th>CIDN</th>
                    <th>Customer ID</th>
                    <th>Environment</th>
                    <th>Name</th>
                    <th>Type</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><RowIconItem icon={"icon-my-details"} color={"purple"} text={cidn} /></td>
                    <td><RowIconItem text={customerId} icon={"manage-services"} /></td>
                    <td><RowIconItem text={env} icon={"icon-home"} color="turquoise" /></td>
                    <td><RowIconItem text={name} icon={"icon-my-profile"} color="green" /></td>
                    <td><RowIconItem text={type} icon={getDataTypeIcon(type)} color={"orange"} /></td>
                </tr>
            </tbody>
        </Table>
    )
}
