import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './physicalSim.css'

const useStyles = makeStyles((theme) => ({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    }
}));

const ExpandableInfoSection = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            
            <CardHeader
                title={<Fragment><InfoOutlinedIcon fontSize="large" color="primary"/> Setting-up My Physical SIM</Fragment>}
            />
            <CardContent>
                <Typography id="initialParagraph" gutterBottom={false}>
                    TDM team has developed Utility which will automate setting up Physical SIM provided by MiLAbs team in SIT/INT2 environment.
                </Typography>
                <Typography paragraph>
                    By using this Utility You will be creating entries against the data in SNRM, & UNICO Systems, UNICO in turn will pre-seed required entries in Network elements like HLR AUC, CUDB etc. (Model Network)
                </Typography>
                <Typography paragraph>
                    <b>Few considerations before you go ahead and use this utility:</b>
                </Typography>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent style={{"padding":"0px 16px 0px 16px"}}>
                        <Typography paragraph gutterBottom={false} style={{"marginBottom":"0px"}}>
                            <b>1.</b> Make sure your SIMs are not Present in SNRM & Network elements.
                        </Typography>
                        <CardContent style={{"padding":"0px 16px 0px 16px"}}>
                            <Typography style={{"padding":"0px 30px"}} paragraph gutterBottom={false}>
                                <ul><li>ideally New MiLAB sims will not be present in IT or network SIM though its best to verify same. TDM provide Utility to check same in SNRM. For Network elements you might need to reach out to network teams.</li></ul>
                            </Typography>
                        </CardContent>
                        <Typography gutterBottom={true}>
                            <b>2.</b> IT systems have two SIT (System integration Test) environments though Model Network has only one instance of Integrated testing environment. Unico team manually points their Jobs to required environments.
                        </Typography>
                        <Typography gutterBottom={true}>
                            <b>3.</b> Please make sure UNICO is pointed to INT2 environment before proceeding with this utility. This can be done by sending email to Network team.
                        </Typography>
                        <Typography gutterBottom={true}>
                            <b>4.</b> Make sure you are connected to INT2 Environment in TDM.
                        </Typography>
                        <Typography gutterBottom={false}>
                            <b>5.</b> This is a Asynchronous activity and will take around 20+ minutes. This operation will provide tracking Id which can be used to check status of your preseeding request.
                        </Typography>
                        <CardContent style={{"padding":"0px 16px 0px 16px"}}>
                            <Typography style={{"padding":"0px 30px"}} paragraph gutterBottom={false}>
                                <ul><li>Please take note of the batch Ids, as we are not adding these batch ids under Check out History.</li></ul>
                            </Typography>
                        </CardContent>
                        <div style={{"width":"100%", "textAlign":"center", "color":"#e44d13"}}>
                            <FormControlLabel control={<Checkbox checked={props.terms} onChange={(event) => props.readTerms(event.target.checked)} name="terms" />} labelPlacement="end" label="I have read all of the above and agree that I have taken necessary steps"/>
                        </div>
                    </CardContent>
                </Collapse>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    id="expandInfoBtn"
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                <ExpandMoreIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
}

export default ExpandableInfoSection;