import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Component } from 'react';
import React from 'react'
import { SubHeader } from '../../common/custom/SubHeader';
import styles from './TConnectOrderModal.css';
import OrderRequestForm from '../OrderCreateComponents/OrderRequestForm';

class TConnectCreateOrderModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    open = () => {
        this.toggleModal();
    }

    toggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

   
    render(){
        return(
              <Modal isOpen={this.state.isOpen} toggle={this.toggleModal} centered className={styles.TConnectOrderModal} size="lg" style={{maxWidth: '1600px', width: '60%'}}>
                    <ModalHeader className="TConOrderModal--header">
                        <SubHeader
                            text="TConnect - Order Create"
                            iconLeft={true}
                            small={true}
                            iconClass="td-icon-sm icon-telstra"
                            iconColor="blue" />
                    </ModalHeader>
                    <ModalBody>
                        <OrderRequestForm toggleModal={this.toggleModal} 
                        cidn={this.props.cidn} 
                        customerName={this.props.customerName} 
                        fnn ={this.props.fnn}
                        submitOrderDetails ={this.props.submitOrderDetails}
                        />
                    </ModalBody>
            </Modal>
        )
    }

}

export default  TConnectCreateOrderModal;
