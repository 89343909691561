import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Header from "@library/core/surfaces/Header";
import BreadTable from "./salesforceTable/SalesForceBreadTable";
import TabBar from "@library/core/surfaces/TabBar";
import SnackbarAlert from "@library/core/alerts/SnackbarAlert";
import { Typography, Link, makeStyles, IconButton } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SalesforceSearchForm from "./SalesforceSearchForm";
import QueryBuilderWidget from "./QueryBuilderWidget";
import { salesforceSearch, fetchLovs, salesforceReset, resetLov } from "../../../actions/salesforceSearchActions";
import { grey } from "@material-ui/core/colors";
import { Button } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import ContentPageComponent from './ContentPageComponent';
import { orderFormFieldTypes, serviceFormFieldTypes, orderLiterals, serviceLiterals } from "./constants";
import moment from 'moment';
import { CSVDownloadTile } from '../../Data/tiles/CSVDownloadTile';
import HeaderContent from '../../common/Header/HeaderContent';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const borderColor = grey[300];
const useStyles = makeStyles((theme) => ({
    tabs: (props) => ({
        border: `1px solid ${borderColor}`,
        borderBottom: "none"
    }),
    lastNonExpandTableColumn: {
      minWidth: "150px", 
      margin: "auto" 
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    }
}));

const formatCustomerSearchData = (data) => {
  return data?.records || [];
}
const formatContactSearchData = (data) => {
  let formattedData = [];
  for (const record of data?.records || []) {
    const resultData = record?.Contacts?.records?.reduce((result, data) => {
      const acr = record?.AccountContactRelations?.records?.find(acr => acr.AccountId === data.AccountId);
      if (acr) {
        let mergedContact = renameAccountContactRelationKeys(data, acr);
        result.push(mergedContact);
      } else {
        result.push({ ...data })
      }
      return result;
    }, []);
    if (resultData) {
      formattedData = [...formattedData, ...resultData];
    }
  }
  return formattedData;
}
const formatOrderSearchData = (data) => {
  let formattedData = [];
  for (const record of data?.records || []) { 
    formattedData.push(record?.Order ? {...record?.Order}: record);
  }
  return formattedData;
}
const formatServiceSearchData = (data) => {
  let formattedData = [];
  for (const record of data?.records || []) { 
    const formattedRecord = {
      OrderReferenceNumber: record?.Order?.OrderReferenceNumber,
      orderRef: record?.Order?.orderRef,
      TLS_PriorityAssist__c:record?.TLS_PriorityAssist__c,
      TLS_ChargeModel__c:record?.Order?.orderItem?.TLS_ChargeModel__c,
      techType:record?.Order?.orderItem?.techType,
      AccountId:record?.Order?.AccountId,
      vlocity_cmt__SerialNumber__c: record?.Order?.orderItem?.vlocity_cmt__SerialNumber__c,
      ...record?.Order?.orderItem?.Product2,
    }
    record?.Order && formattedRecord && formattedData.push(formattedRecord);
  }
  return formattedData;
}

const renameAccountContactRelationKeys = (data, acr) => {
  let mergedContact = { ...data };
  for (const key in acr) {
    mergedContact[`ACR__${key}`] = (acr[key] !== null && acr[key] !== undefined) ? `${acr[key]}` : '';
  }
  return mergedContact;
}

const SalesforceSearchUtility = (props) => {
  const {history}=props;
  const classes = useStyles();
  const {
    selectedEnvValue,
    salesforceSearch, 
    searchPending,
    searchSuccess,
    searchFailMsg,
    searchResultData,
    searchRequestPayload,
    fetchLovs,
    resetLov,
    fetchLovData,
    salesforceReset
  } = props;
  const [currentTab, setCurrentTab] = useState(0);
  const [tabPayloads, setTabPayloads] = useState(null);
  const [searchSectionOpen, setSearchSectionOpen] = useState(false);
  const [moreDataWasRetrieved, setMoreDataWasRetrieved] = useState(false);

  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertType, setAlertType] = useState("info");
  const [alertLoading, setAlertLoading] = useState(false);
  const [alertAutoHide, setAlertAutoHide] = useState(10000);
  const [contentPage, setContentPage] = useState(true);
  const [enablePage,setEnablePage]=useState(false);
  const [isTechType,setIsTechType]=useState(false);
  const [isChargeCycle,setIsChargeCycle]=useState(false);

  const prevProps = usePrevious({selectedEnvValue});
    useEffect(() => {
        if(prevProps?.selectedEnvValue !== selectedEnvValue) {
          (async () => {
            await salesforceReset();
            await search(true, null, true);
          })();
        }
      
    }, [selectedEnvValue])
    
  useEffect(() => {
    (async () => {
      await salesforceReset();
      await search(true, null, true);
    })();
  }, []);

  useEffect(() => {
    if (!moreDataWasRetrieved) {
      const msg = !searchSuccess && searchFailMsg || "Failed to complete salesforce search!";
      updateAlertMsg("error", msg, false, 12000);
      !searchSuccess && setDisplayAlert(true);
    }
  }, [moreDataWasRetrieved]);

  useEffect(() => {
    (async () => {
      validationTrigger(tabPayloads?.[tabOptions?.[currentTab]?.name]);
      if (
        tabOptions?.[currentTab].name !== 'customer' &&
        (tabOptions?.[currentTab].name === 'contact' && !tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.contacts) ||
        (tabOptions?.[currentTab].name === 'order' && !tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.orders?.filters) ||
        (tabOptions?.[currentTab].name === 'services' && !tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.orders?.product)
      ) {
        const editResult = tabOptions?.[currentTab].payloadEdit();
        await search(true, editResult, false);
      }
    })();
  }, [currentTab]);

  const updateAlertMsg = (type, msg, loading, hideDuration) => {
    setAlertType(type);
    setAlertMsg(msg);
    setAlertLoading(loading);
    setAlertAutoHide(hideDuration);
  }

  const customerPayloadEdit = (fieldName, fieldValue, format) => {
    let newPayload = {
      account: {filters: {...tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.filters}}
    };
    delete newPayload.account["orders"];
    if (fieldName) {
      if (!fieldValue || fieldValue.toLowerCase() === 'all') {
        delete newPayload["account"].filters[fieldName];
      } else {
        newPayload["account"].filters[fieldName] = format === 'bare' ? fieldValue : `'${fieldValue}'`;
      }
    }
    tabOptions?.[currentTab]?.name && setTabPayloads({
      ...tabPayloads, 
      customer: newPayload,
      contact: {account:{...tabPayloads?.contact?.account, ...newPayload?.account}}
    });
    return newPayload;
  }

  const contactPayloadEdit = (fieldName, fieldValue, format, isAdditionalFilter) => {
    let newPayload = {
      account: {
        ...tabPayloads?.customer?.account,
        contacts: {
          filters: tabPayloads?.contact?.account?.contacts?.filters ? {...tabPayloads?.contact?.account?.contacts?.filters} : {},
          addlFilters: tabPayloads?.contact?.account?.contacts?.addlFilters ? {...tabPayloads?.contact?.account?.contacts?.addlFilters} : {}
        }
      }
    }
    if (fieldName) {
      const filterType = isAdditionalFilter ? "addlFilters" : "filters";
      if (typeof fieldValue !== 'boolean' && !fieldValue || fieldValue === 'All') {
        delete newPayload?.account?.contacts?.[filterType][fieldName];
      } else {
        newPayload["account"].contacts[filterType][fieldName] = format === 'bare' ? fieldValue : `'${fieldValue}'`;
      }
    }
    tabOptions?.[currentTab]?.name && setTabPayloads({...tabPayloads, [tabOptions[currentTab].name]: newPayload});
    return newPayload;
  };

  const orderPayloadEdit = (fieldName, fieldValue, format, isAdditionalFilter, childFields=[]) => {
    let newPayload = {
      account: {
        orders: {
          filters: tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.orders?.filters ? {...tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.orders?.filters} : {}
        }
      }
    }
    if (fieldName) {
      if (typeof fieldValue !== 'boolean' && !fieldValue || fieldValue === 'All') {
          delete newPayload.account.orders?.filters[fieldName];
      } else {
          newPayload.account.orders.filters = { 
              ...newPayload.account.orders.filters,
              [fieldName]: format === 'bare' ? fieldValue : `'${fieldValue}'`
          }
      }
      // reset children
      childFields.forEach(v => {
        delete newPayload.account.orders?.filters[v];
        resetLov(orderFormFieldTypes[v].id, orderLiterals.orderEntityName);
      });
    }
    tabOptions?.[currentTab]?.name && setTabPayloads({
      ...tabPayloads, 
      [tabOptions[currentTab].name]: newPayload,
      services: {account:{orders:{...tabPayloads?.services?.account?.orders, ...newPayload?.account?.orders}}}
    });
    return newPayload;
  };

  const servicePayloadEdit = (fieldName, fieldValue, format, isAdditionalFilter, childFields=[]) => {
    let newPayload = {
      account: {
        orders: {
          ...tabPayloads?.order?.account?.orders,
          product: {
            filters: tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.orders?.product?.filters ? {...tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.orders?.product?.filters} : {}
          }
        }
      }
    }
    if(tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.orders?.product?.additionalFilters){
      newPayload["account"]["orders"]["product"]["additionalFilters"]=tabPayloads?.[tabOptions?.[currentTab]?.name]?.account?.orders?.product?.additionalFilters;
    }
    if (fieldName) {
      const filterType = isAdditionalFilter ? "additionalFilters" : "filters";
      if (typeof fieldValue !== 'boolean' && !fieldValue || fieldValue === 'All') {
          delete newPayload.account.orders?.product?.[filterType][fieldName];
      } else {
          newPayload.account.orders.product[filterType] = { 
              ...newPayload.account.orders.product[filterType],
              [fieldName]: format === 'bare' ? fieldValue : `'${fieldValue}'`
          }
      }
      // reset children
      childFields.forEach(v => {
        delete newPayload.account.orders?.product?.[filterType][v];
        resetLov(serviceFormFieldTypes[v].id, serviceLiterals.productEntityName);
      });
    }
    tabOptions?.[currentTab]?.name && setTabPayloads({...tabPayloads, [tabOptions[currentTab].name]: newPayload});
    return newPayload;
  }

  const checkIfChargeCyclePresent = (payload)=>{
    const verifyChargeCycle = payload && payload?.account?.orders?.product?.additionalFilters?.['OrderItem.TLS_ChargeModel__c'];
    setIsChargeCycle(verifyChargeCycle);
  }

  const checkIfTechTypeEnable = (payload)=>{
    const verifyTechType = payload && payload?.account?.orders?.product?.filters?.Family ==="'Fixed'" && payload?.account?.orders?.product?.filters?.Name ==="'Fixed Access'" &&(!payload?.account?.orders?.product?.filters?.Product_Type__c || payload?.account?.orders?.product?.filters?.Product_Type__c ==="'Service'");
    setIsTechType(verifyTechType);
  }

  const validationTrigger =(payload)=>{
    checkIfTechTypeEnable(payload);
    checkIfChargeCyclePresent(payload);
  }

  const search = async (reset, localPayload, resetStatus=false, next=false) => {
    setMoreDataWasRetrieved(true);
    setDisplayAlert(false);
    const reqPayload = localPayload || tabPayloads?.[tabOptions?.[currentTab]?.name] ||searchRequestPayload;
    const payload = {
      environment: selectedEnvValue,
      ...reqPayload
    }
    validationTrigger(payload);
    const size = searchResultData?.[tabOptions?.[currentTab]?.name]?.totalSize || 0;
    const resp = await salesforceSearch(
      payload, 
      !reset ? searchResultData?.[tabOptions?.[currentTab]?.name] || [] : undefined, 
      resetStatus, 
      tabOptions?.[currentTab]?.name, 
      tabOptions?.[currentTab]?.formatSearchData,
      next
    );
    resp && setSearchSectionOpen(false);
    const moreData = resp?.totalSize > size;
    setMoreDataWasRetrieved(moreData);
    return moreData;
  }
  const textCompDateChg = (text, last) => {
    const textContent = (text !== null && text !== undefined) ? `${text}` : '';
    const textContentDateChg = moment(textContent).format('MM/DD/YYYY');
    return (
      <Typography classes={last && {root: classes.lastNonExpandTableColumn}} noWrap variant="body2">
        {textContentDateChg}
      </Typography>
    )
  }

  const textComp = (text, last) => {
    const textContent = (text !== null && text !== undefined) ? `${text}` : '';
    return (
      <Typography classes={last && {root: classes.lastNonExpandTableColumn}} noWrap variant="body2">
        {textContent}
      </Typography>
    )
  }
  const linkComp = (text, url, last) => {
    const textContent = (text !== null && text !== undefined) ? `${text}` : '';
    return (
      <Link 
        href={url} 
        rel="noopener" 
        classes={last && {root: classes.lastNonExpandTableColumn}} 
        noWrap 
        variant="body2"
        aria-label="Open this search value in salesforce, opens a new window"
        target="_blank"
      >
        {textContent}
      </Link>
    )
  }

  const customerMetaData = [
    {
      order: 1,
      title: "ID",
      field: ["Id","accountRef"],
      component: (id,ref) => linkComp(id,ref)
    },
    {
      order: 2,
      title: "Name",
      field: ["Name"],
      component: (field) => textComp(field)
    },
    {
      order: 3,
      title: "CustomerId",
      field: ["CustomerId__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 4,
      title: "CIDN",
      field: ["CIDN__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 5,
      title: "Type",
      field: ["Type"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 6,
      title: "TCM ID",
      field: ["TCMMasterId__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 7,
      title: "CreatedDate",
      field: ["CreatedDate"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 8,
      title: "Status",
      field: ["vlocity_cmt__Status__c"],
      component: (field) => textComp(field)
    },
    {
      order: 9,
      title: "OwnerId",
      field: ["OwnerId"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 10,
      title: "Account UID",
      field: ["AccountUUID__c"],
      component: (field) => textComp(field, true)
    },
  ];
  const contactMetaData = [
    {
      order: 1,
      title: "ID",
      field: ["Id","contactRef"],
      component: (id, ref) => linkComp(id, ref)
    },
    {
      order: 2,
      title: "AccountId",
      field: ["AccountId"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 3,
      title: "FirstName",
      field: ["FirstName"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 4,
      title: "LastName",
      field: ["LastName"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 5,
      title: "CreatedDate",
      field: ["CreatedDate"],
      component: (field) => textCompDateChg(field)
    },
    {
      order: 6,
      title: "OwnerId",
      field: ["OwnerId"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 7,
      title: "Status",
      field: ["vlocity_cmt__Status__c"],
      component: (field) => textComp(field)
    },
    {
      order: 8,
      title: "Contact UID",
      field: ["ContactUUID__c"],
      component: (field) => textComp(field)
    },
    {
      order: 9,
      title: "CommunicationMethod",
      field: ["PreferredCommunicationMethod__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 10,
      title: "HomePhone",
      field: ["HomePhone"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 11,
      title: "Email",
      field: ["Email"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 12,
      title: "Title",
      field: ["Title"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 13,
      title: "RCRMContactID",
      field: ["RCRMContactId__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 14,
      title: "DOB",
      field: ["DOB__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textCompDateChg(field)
    },
    {
      order: 15,
      title: "AccountContactActive",
      field: ["ACR__IsActive"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 16,
      title: "Role",
      field: ["ACR__Role__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 17,
      title: "MobilePhone",
      field: ["MobilePhone"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
  ];
  const orderMetaData = [
    {
      order: 1,
      title: "ID",
      field: ["Id","orderRef"],
      component: (id, ref) => linkComp(id, ref)
    },
    {
      order: 2,
      title: "AccountId",
      field: ["AccountId"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 3,
      title: "Order Reference Number",
      field: ["OrderReferenceNumber"],
      component: (field) => textComp(field)
    },
    {
      order: 4,
      title: "Order Status",
      field: ["vlocity_cmt__OrderStatus__c"],
      expandable: false,
      expandableOnly: false,
      component: (field) => textComp(field)
    },
    {
      order: 5,
      title: "Fulfillment Status",
      field: ["vlocity_cmt__FulfilmentStatus__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 6,
      title: "Activated Date",
      field: ["ActivatedDate"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 7,
      title: "CreatedDate",
      field: ["CreatedDate"],
      component: (field) => textCompDateChg(field)
    },
    {
      order: 8,
      title: "OrderReason",
      field: ["OrderReason__c"],
      component: (field) => textComp(field),
      hidden: true
    },
    {
      order: 9,
      title: "OrderReasonType",
      field: ["OrderReasonType__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field),
      hidden: true
    },
  ];

  const serviceMetaData = [
    {
      order: 1,
      title: "Product Name",
      field: ["Name"],
      component: (field) => textComp(field)
    },
    {
      order: 2,
      title: "Order Reference Number",
      field: ["OrderReferenceNumber","orderRef"],
      component: (field,field2) => {
        return linkComp(field,field2)
      }
    },
    {
      order: 3,
      title: "Product Family",
      field: ["Family"],
      component: (field) => textComp(field)
    },    
    {
      order: 5,
      title: "Product Subtype",
      field: ["Product_Sub_Type__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 6,
      title: "Serial Number",
      field: ["vlocity_cmt__SerialNumber__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 7,
      title: "Product Code",
      field: ["ProductCode"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 8,
      title: "Priority Assist",
      field: ["TLS_PriorityAssist__c"],
      expandable: false,
      expandableOnly: false,
      component: (field) => textComp(field)
    },  
    {
      order: 9,
      title: "Account Id",
      field: ["AccountId"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    }, 
    {
      order: 10,
      title: "Product Type",
      field: ["Product_Type__c"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    },
    {
      order: 11,
      title: "Tech Type",
      field: ["techType"],
      expandable: true,
      expandableOnly: true,
      component: (field) => textComp(field)
    }
  ];

  const addTechType = {
    order: 11,
    title: "Tech Type",
    field: ["techType"],
    expandable: false,
    expandableOnly: false,
    component: (field) => textComp(field)
  };
  const addChargeCycle = {
    order: 4,
    title: "Charge Cycle",
    field: ["TLS_ChargeModel__c"],
    component: (field) => textComp(field)
  }

  isChargeCycle && serviceMetaData.push(addChargeCycle);
  isTechType && serviceMetaData.push(addTechType);
  const tabOptions = [
    {name: 'customer', icon: <EmojiPeopleIcon/>, metaData: customerMetaData, payloadEdit: customerPayloadEdit, formatSearchData: formatCustomerSearchData },
    {name: 'contact', icon: <LocalPhoneIcon/>, metaData: contactMetaData, payloadEdit: contactPayloadEdit, formatSearchData: formatContactSearchData},
    {name: 'order', icon: <LocalShippingIcon/>, metaData: orderMetaData, payloadEdit: orderPayloadEdit, formatSearchData: formatOrderSearchData},
    {name: 'services', icon: <RoomServiceIcon/>, metaData: serviceMetaData, payloadEdit: servicePayloadEdit, formatSearchData: formatServiceSearchData}
  ];

  const updatePayload = async (fieldId, fieldValue, format, isAdditionalFilter, childFields) => {
    const formatVal = (['false','true'].includes(fieldValue) ? JSON.parse(fieldValue) : fieldValue);
    tabOptions?.[currentTab].payloadEdit(fieldId, formatVal, format, isAdditionalFilter, childFields);
  }

  const noDataMsg = () => {
    if (!searchSuccess) {
      return "Failed to retrieve Salesforce data";
    } else if (searchSuccess) {
      return "No Salesforce data available for this search request";
    } else {
      return "Search available data above";
    }
  }

  return (
    <div className="white">
       <HeaderContent
                title={`Salesforce Search`}
                icon="cogs ops-icon"
                empty={true}
                redirectUtility={true}
                push={history?.push}></HeaderContent>
     {!enablePage ? <ContentPageComponent setContentPage={setContentPage} contentPage={contentPage}/> : 
     <>
      <TabBar tabs={tabOptions} tabUpdate={setCurrentTab} className={classes.tabs}/>
      <QueryBuilderWidget
        tab={tabOptions?.[currentTab]}
        payload={tabPayloads?.[tabOptions?.[currentTab]?.name]}
        searchSectionOpen={searchSectionOpen}
        toggleSearchSection={()=>setSearchSectionOpen(!searchSectionOpen)}
        totalSize={props.searchResultData?.[tabOptions?.[currentTab]?.name]?.totalSize || 0}
      />
      {
        searchSectionOpen &&           
          <SalesforceSearchForm 
            formName={tabOptions?.[currentTab]?.name}
            updatePayload={updatePayload}
            payload={tabPayloads?.[tabOptions?.[currentTab]?.name]}
            submitSearch={
              () => {
                search(true,null,false);
              }
            }
            loading={searchPending}
            fetchLovs={fetchLovs}
            fetchLovData={fetchLovData}
            selectedEnvValue={selectedEnvValue}
            searchSectionOpen={searchSectionOpen}
          />
      }
      
      {tabOptions.map((opt) => ( tabOptions?.[currentTab]?.name === opt.name &&
      <>
      <CSVDownloadTile items={searchResultData?.[opt.name]?.data || []} />  
      <BreadTable
        key={opt.name}
        data={searchResultData?.[opt.name]?.data || []}
        size={"medium"}
        isExpandable={true}
        hideEmptyExpandOptions={false}
        expandTitle={"Details"}
        columnMetadata={opt.metaData}
        headerPadding={{tb: 0, lr: 33}}
        isLoading={searchPending}
        rowsPerPage={25}
        rowsPerPageOptions={[25,50,100,250]}
        noDataMsg={noDataMsg()}
        totalRows={props.searchResultData?.[opt.name]?.totalSize || 0}
        paginateByApi={{
          getPageData: async () => {
            return await search(false, null, false, true);
          },
          isLoading: searchPending,
        }}
      /></>))}
      <SnackbarAlert 
        isOpen={Boolean(alertMsg && displayAlert)} 
        severity={alertType}
        message={alertMsg} 
        position={{horizontal:'center', vertical:'bottom'}}
        autoCloseDuration={alertAutoHide}
        loading={alertLoading}
        handleClose={()=>{
          setDisplayAlert(false);
        }}
      />
     </>
     }
     {!enablePage && <div className="text-right pageBtn">
                                <Tooltip title={contentPage ? "Please read & agree to recommendations above" : "Continue to Setup"}>
                                    <span>
                                   
                                        <Button disabled={contentPage} class="text-right pageBtn" type="button" color="primary" onClick={()=>setEnablePage(!enablePage)} >Continue</Button>
                                    </span>
                                </Tooltip>
                                </div>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedEnvValue: state.auth.selectedEnvValue,
    searchPending: state.salesforceSearch.searchPending,
    searchSuccess: state.salesforceSearch.searchSuccess,
    searchFailMsg: state.salesforceSearch.searchFailMsg,
    searchResultData: state.salesforceSearch.searchResultData,
    searchRequestPayload: state.salesforceSearch.searchRequestPayload,
    fetchLovData: state.salesforceSearch.fetchLovData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    salesforceSearch:(payload, prevContent, resetStatus, tab, formatFunc, next) => dispatch(salesforceSearch(payload, prevContent,resetStatus, tab, formatFunc, next)),
    fetchLovs:(field, fieldName, env, entity, condition) => dispatch(fetchLovs(field, fieldName, env, entity, condition)),
    resetLov:(field, entity) => dispatch(resetLov(field, entity)),
    salesforceReset:() => dispatch(salesforceReset())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesforceSearchUtility);
