import * as types from './CONSTANTS';
import {axiosTaasInstance as axios, axiosInstance} from "../../../../axios.config";

import { authHeaders } from "../../../../utils/api";

export const clearAllAssetsCheckedout = () => async dispatch => {
    dispatch({ type: types.ASSET_CLEAR_CHECKOUT })
}
//Variables.

export const retrieveCheckOutData = (id) => async dispatch => {
    dispatch({ type: types.FETCH_DATA_BANK_CHECKOUT_DATA });
    const postData = {
        "id": id
    }

    await axios.post(types.TDM_DATA_BANK_RETRIEVE_CHECKOUT_URL, postData, { headers: authHeaders() })
        .then(response => {
            dispatch({ type: types.FETCH_DATA_BANK_CHECKOUT_DATA_FULFILLED, payload: response.data })
            console.log(response.data)
        })
        .catch((error) => {
            dispatch({ type: types.FETCH_DATA_BANK_CHECKOUT_DATA_REJECTED, payload: error })
            // console.log(error.response.data);
        })
}

export const serviceMigration = (req) => async (dispatch) => {
    try {
        dispatch({ type: types.SERVICE_MIGRATION_LOADING });
        const res = await axios.post(types.TDM_SERVICE_MIGRATION_URL, req, { headers: authHeaders() })
        dispatch({ type: types.SERVICE_MIGRATION_SUCCESS, payload: res.data });
        return res.data;
    }
    catch (err) {
        dispatch({ type: types.SERVICE_MIGRATION_FALIURE, payload: err });
        return err.response.data;
    }

}

export const smCustomer = async (req) => {
    return await axiosInstance.post(types.TDM_SERVICE_MIGRATION_URL, req, { headers: authHeaders() }).then((result) => result);
}