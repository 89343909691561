// Root component that wraps children within redux provider tags
// Reusable whilst in browser as well as with testing.
import React from 'react';
import { Provider } from 'react-redux';
import throttle from 'lodash/throttle';
import configureStore from './store/configureStore';
import { loadState, saveState } from './store/localStorage';

export default ( {children, initialState = {} } ) => {
    const persistedState = loadState();
    const store = configureStore(persistedState);
    
    store.subscribe(throttle(() => {
        saveState({
            ...store.getState()
        })
    }, 1000))
    
    return (
        <Provider store={store}>
            {children}
        </Provider>
    )
}

// if (process.env.NODE_ENV !== 'production') {
//     const {whyDidYouUpdate} = require('why-did-you-update')
//     whyDidYouUpdate(React)
// }