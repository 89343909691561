import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    submitBulkOrderLoading : false,
    fetchExistingRecord:null,
    totalPages:null,
    status:null,
    fetchReferenceData:null,
    fetchAllReferenceData:null,
    isReferenceUpdated:true,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
       case types.TIM_MIGRATION_LOADING: 
            return {
                ...state,
                submitBulkOrderLoading: true
            }
        case types.TIM_MIGRATION_SUCCESS: 
            return {
                ...state,
                submitBulkOrderLoading: false
            }
        case types.TIM_MIGRATION_FALIURE: 
            return {
                ...state,
                submitBulkOrderLoading: false
            }    
        case types.GET_FETCH_EXISTING_RECORD:
            return {
                ...state,
                fetchExistingRecord:action?.payload?.content,
                totalPages:action?.payload?.totalPages,
                status:true
            }
        case types.GET_FETCH_EXISTING_RECORD_LOAD:
            return {
                ...state,
                fetchExistingRecord:null,
                status:null
            }
        case types.EXISTING_RECORD_FALIURE:
            return {
                ...state,
                fetchExistingRecord:action?.payload,
                totalPages:null,
                status:false
            }
        case types.GET_FETCH_REFERENCE_RECORD:
            return {
                ...state,
                fetchReferenceData:action?.payload
            }
        case types.GET_FETCH_REFERENCE_RECORD_LOAD:
            return {
                ...state,
                fetchReferenceData:null
            }
        case types.REFERENCE_RECORD_FALIURE:
            return {
                ...state,
                fetchReferenceData:null
            }
        case types.GET_FETCH_ALL_REFERENCE_RECORD:
            return {
                ...state,
                fetchAllReferenceData:action?.payload,
            }
        case types.GET_FETCH_ALL_REFERENCE_RECORD_LOAD:
            return {
                ...state,
                fetchAllReferenceData:null,
            }
        case types.UPDATE_REFERENCE_LOADING:
            return {
                ...state,
                isReferenceUpdated:false,
            }        
        default:
            return state;
    }
}