import React from "react";
import _ from "lodash";
import { generateServiceId, fetchProductTypePlan, fetchProductRepayments } from "../../actions/replicatorActions";
import { connect } from "react-redux";
import { Field, change,arrayRemove } from "redux-form";
import DropdownComponent from "./DropdownComponent";
import { renderField } from "../common/fields/fields";
import Switch from '@material-ui/core/Switch'
import {
  cosmosMsisdnValidator,
  imsiValidator,
  simValidator,
  deviceIDValidator,
  monthlyAmountValidator,
  remainingTermValidator,
  remainingAmountValidator
} from "../common/form/fieldValidators";
import "./Product.css";

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


class CosmosMobileComponent extends React.Component {
  state = {
    autoGeneratedRows: [],
    productFamily: "CMPDP*05|Corp Mobile Plus Data $5 Plan", //default value
    numRows: 0,
    dlc: [{value:false,index:0}],
    telstraOneNumber: false,
    productPlanAndTypes: "",
    numberRepaymentRows: [],
  };

  componentDidMount() {        
    this.props.fetchProductRepayments();
}


  handleProductFamily = (option, index) => {
    const productFamilyPlanName = this.props.cosmosPlans.filter((data) => data.planName == option)
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].planName`, productFamilyPlanName[0].planName
      )
    );
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].planType`, productFamilyPlanName[0].planType
      )
    );
  };
  handleDeviceID = (e, index, item) => {
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].replicatorData[${item}]deviceID`, e.target.value
      ))
  }
  handleMonthlyAmnt = (e, index, item) => {
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].replicatorData[${item}]monthlyAmount`, e.target.value
      ))
  }
  handleRemainingTerm = (e, index, item) => {
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].replicatorData[${item}]remainingTerm`, e.target.value
      ))
  }

  handleRemainingAmnt = (e, index, item) => {
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].replicatorData[${item}]remainingAmount`, e.target.value
      ))
  }

  handleDeviceType = (e, index, item) => {
    const repaymentDevice = this.props.cosmosRepayments.filter((data) => data.repaymentID == e.target.value)
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].replicatorData[${item}]deviceType`, repaymentDevice[0].deviceType
      )
    );
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].replicatorData[${item}]billLiteral`, repaymentDevice[0].deviceName
      )
    );
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].replicatorData[${item}]mroWithNoImei`, repaymentDevice[0].mro_with_no_imei
      )
    );
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].replicatorData[${item}]contractType`, repaymentDevice[0].contactType
      )
    );
    if (repaymentDevice[0].contactType == "ARO") {
      this.props.passdownprops.dispatch(change(this.props.passdownprops.formName, `mobileData[${index}].aroIndicator`, "Y"
      )
      );
    }
    if (repaymentDevice[0].mroWithNoImei == "Y") {
      this.props.passdownprops.dispatch(change(this.props.passdownprops.formName, `mobileData[${index}].mroWithNoImei`, "Y"
      )
      );
    }
  }
  handleDLC = (field) => {
   return(
    <Switch checked={field.input.value} onChange={field.input.onChange} icons={false} />)
  }


  handleTelstraOneNumer = (field) => {
    return(
    <Switch checked={field.input.value} onChange={field.input.onChange} icons={false} />)
  };

  addRepayments = (index, fields) => {
    let rowsRepayment = [];
    if(fields.get(index).repaymentsRows)
        rowsRepayment = fields.get(index).repaymentsRows
    rowsRepayment.push({ index: index, repaymentIndex: rowsRepayment.length })
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].showDeviceType`, true
      ))
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].repaymentsRows`, rowsRepayment
      )
    );
  };
  removeRepayments = (e, index, fields) => {
    let rowsRepayment = [...fields.get(index).repaymentsRows];
    let rowIndex = Number(e.target.id);
    let removedRows = rowsRepayment.filter((data) => rowIndex !== Number(data.repaymentIndex))
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `mobileData[${index}].repaymentsRows`, removedRows
      ))
    
  }

  handleRowChange = (numRows, newNumRows, fields) => {
    this.setState(preState => ({ ...preState, numRows: newNumRows }));

    let diff = newNumRows - numRows;
    if (diff > 0) {
      for (let i = 0; i < diff; i++)
        fields.push({
          msisdn: null, showDeviceType: false, repaymentsRows: [],
          dlc: true, telstraOneNumber: false, mroWithNoImei: "N", aroIndicator: "N"
        });
    } else if (diff < 0) {
      diff *= -1;

      const limit = fields.length - diff - 1;
      for (let i = 0; i < diff; i++) fields.pop();
      this.setState(preState => {
        return {
          ...preState,
          autoGeneratedRows: preState.autoGeneratedRows.filter(
            data => data <= limit
          )
        };
      });
    }
  };
  handleGenerateServiceId = async (passdownprops, index) => {
    try {
      const { serviceId } = await this.props.generateServiceId(1, index);

      this.setState(preState => ({
        ...preState,
        autoGeneratedRows: preState.autoGeneratedRows.concat(index)
      }));

      passdownprops.dispatch(
        change(passdownprops.formName, `mobileData[${index}].msisdn`, serviceId)
      );
    } catch (e) {
      console.log("error generating MSISDN: ", e);
    }
  };



  render() {

    const { quantity } = this.props;
    const cosmosPlansOptions = this.props.cosmosPlans.map((cosmos) => {
      return (cosmos.planName)
    })
    const cosmosRepaymentOptions = this.props.cosmosRepayments.map((repays) => {
      return ({
        key: repays.repaymentID,
        value: { "deviceName": repays.deviceName, "deviceType": repays.deviceType, "deviceContract": repays.contactType, "mroWithNoImei": repays.mroWithNoImei }
      });
    })
    const { fields, passdownprops, productType, serviceIdField } = this.props;
    return (
      <div>
        <div id="header-mobile">
          <div id="mobile-item">
            {" "}
            <span className="product-header">Product:</span>
            <span>{productType.toUpperCase()}</span>
          </div>
          <div id="mobile-item">
            {" "}
          </div>
          <div id="mobile-item">
            <span className="product-header">Quantity: </span>
            <span id="qty-dropdown" className="mobile-dorpdown">
              <DropdownComponent
                options={quantity}
                selectInput={qty =>
                  this.handleRowChange(fields.length, qty, fields)
                }
                length={fields.length}
              />
            </span>
          </div>
        </div>
        <div id="s2p-product-modal-input-head" className="row">
          <Grid container spacing={3} className="mobileDataGrid">
            <Grid item xs={2}>
              <Paper>MSISDN</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper>IMSI</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper>SIM</Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper>TYPE</Paper>
            </Grid>
            <Grid item xs={1} className="marginLeft_Negative15">
              <Paper>Account</Paper>
            </Grid>
            <Grid item xs={1} className="dlcLabel">
              <Paper>DLC</Paper>
            </Grid>
            <Grid item xs={1} className="telstraNumberLabel">
              <Paper>One Number</Paper>
            </Grid></Grid>


          {fields.map((mobileData, index) => {
            const placeholder =
              serviceIdField.rowNumber === index && serviceIdField.pending
                ? "loading..."
                : "";
            return (
              <Grid container spacing={3} className="mobileDataRowGrid">
                <Grid item xs={2} className="marginRight_Negative10">
                  <Field
                    name={`mobileData[${index}].msisdn`}
                    customDiv="adborFnnTableInputDiv"
                    customStyleClassName="msisdn"
                    tabIndex={index}
                    disabled={
                      passdownprops.asyncValidating ||
                      this.state.autoGeneratedRows.includes(index) ||
                      serviceIdField.pending
                    }
                    component={renderField}
                    validate={cosmosMsisdnValidator}
                    touched={true}
                    invalid={this.props.errorMessage}
                    type={"textWithCustomStyles"}
                    showValidMsg={false}
                    placeholder={placeholder}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={2} className="marginRight_Negative10">
                  <Field
                    name={`mobileData[${index}].devices[0].networkId`}
                    tabIndex={index}
                    disabled={
                      passdownprops.asyncValidating ||
                      serviceIdField.pending
                    }
                    autoFocus={index === 0}
                    component={renderField}
                    customDiv="adborFnnTableInputDiv"
                    validate={imsiValidator}
                    invalid={this.props.errorMessage}
                    type={"textWithCustomStyles"}
                    showValidMsg={false}
                    touchOnChange={true}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name={`mobileData[${index}].devices[0].physicalId`}
                    component={renderField}
                    tabIndex={index}
                    touched={true}
                    invalid={this.props.errorMessage}
                    type={"textWithCustomStyles"}
                    index={index}
                    showValidMsg={false}
                    validate={simValidator}
                    disabled={
                      passdownprops.asyncValidating ||
                      serviceIdField.pending
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <span id="qty-dropdown" className="mobile-dorpdown">
                    <select name={`mobileData[${index}].productPlanName`}
                      id="select-quantity"
                      className="cosmos-product-family"
                      onChange={(e) => {
                        this.handleProductFamily(e.target.value, index)
                      }}>
                      {cosmosPlansOptions.map((option, index) => (<option key={index}>{option}</option>))}
                    </select>
                  </span>
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name={`mobileData[${index}].acntNumber`}
                    component={renderField}
                    tabIndex={index}
                    touched={true}
                    invalid={this.props.errorMessage}
                    type={"textWithCustomStyles"}
                    index={index}
                    showValidMsg={false}
                    disabled={
                      passdownprops.asyncValidating ||
                      serviceIdField.pending
                    }
                  />
                </Grid>
                <Grid>
                <Field
                  name={`mobileData[${index}].dlc`}
                  id={`mobileData[${index}].dlc`}
                    component={this.handleDLC}
                  />
                  {/* <Switch
                    checked={`mobileData[${index}].dlc`}
                    name={`mobileData[${index}].dlc`}
                    value={`mobileData[${index}].dlc`}
                    onChange={(e) => {
                      this.handleDLC(e, index)
                    }} color="primary"
                  /> */}</Grid>
                <Grid>
                <Field
                  name={`mobileData[${index}].telstraOneNumber`}
                  id={`mobileData[${index}].telstraOneNumber`}
                    component={this.handleTelstraOneNumer}
                  />
                </Grid>
                <Grid className="addRepaymentGrid"><div className="fa fa-plus fa-sm addRepayments" aria-hidden="true" onClick={(e) => this.addRepayments(index, fields)}></div>
                </Grid>{fields.get(index).repaymentsRows &&
                    fields.get(index).repaymentsRows.map((item, number) => {
                  return (
                    <React.Fragment>
                       <div id="header-mobile">
                          <div id="mobile-item">
                            {" "}
                            <span className="group-header">Repayment {item.repaymentIndex} : </span>
                          </div></div>

                      {fields.get(index).showDeviceType &&
                        <Paper className="repaymentPaper" key={number}>
                          <Grid container spacing={3} className="repaymentDataGrid">
                            <Grid item xs={2}>
                              <Paper>Device ID(IMEI)</Paper>
                            </Grid>
                            <Grid item xs={2}>
                              <Paper>Device Type</Paper>
                            </Grid>
                            <Grid item xs={2}>
                              <Paper>Monthly Amount</Paper>
                            </Grid>
                            <Grid item xs={2}>
                              <Paper>Remaining Term</Paper>
                            </Grid>
                            <Grid item xs={2}>
                              <Paper>Remaining Amount</Paper>
                            </Grid>
                            <Grid item xs={2}> <Paper><div className="fa fa-minus fa-sm removeRepayments " aria-hidden="true" id={number} onClick={(e) => this.removeRepayments(e, index, fields)}></div></Paper>  </Grid>
                          </Grid>
                          <Grid container spacing={3} className="repaymentDataRowGrid">
                            <Grid item xs={2}>  
                              <Field
                                name={`${mobileData}[${index}].replicatorData[${item.repaymentIndex}]deviceID`}
                                type="text"
                                autoComplete="off"
                                component={renderField}
                                onChange={(e) => this.handleDeviceID(e, index, number)}
                                touched={true}
                                invalid={this.props.errorMessage}
                                index={index}
                                showValidMsg={false}
                                validate={deviceIDValidator} /></Grid>
                            <Grid item xs={2}>
                              <span id="qty-dropdown" className="mobile-dorpdown">
                                <select name={`${mobileData}[${index}].replicatorData[${item.repaymentIndex}]replicator`}
                                  id="select-quantity"
                                  className="cosmos-product-family"
                                  onChange={(e) => {
                                    this.handleDeviceType(e, index, number)
                                  }}>
                                  {cosmosRepaymentOptions.map((option, key) => (
                                    <option key={option.key} value={option.key}>{option.value.deviceContract + "-" + option.value.deviceType + "-   mro_with_no_imei   :" + option.value.mroWithNoImei + " -" + option.value.deviceName}</option>))}
                                </select>
                              </span></Grid>
                            <Grid item xs={2}> <Field
                              name={`${mobileData}[${index}].replicatorData[${item.repaymentIndex}]monthlyAmnt`}
                              type="text"
                              autoComplete="off"
                              component={renderField}
                              onChange={(e) => this.handleMonthlyAmnt(e, index, number)}
                              touched={true}
                              invalid={this.props.errorMessage}
                              index={index}
                              showValidMsg={false}
                              validate={monthlyAmountValidator}
                            /></Grid>
                            <Grid item xs={2}> <Field
                              name={`${mobileData}[${index}].replicatorData[${item.repaymentIndex}]remainingTerm`}
                              type="text"
                              autoComplete="off"
                              component={renderField}
                              onChange={(e) => this.handleRemainingTerm(e, index, number)}
                              touched={true}
                              invalid={this.props.errorMessage}
                              index={index}
                              showValidMsg={false}
                              validate={remainingTermValidator} /></Grid>
                            <Grid item xs={2}>   <Field
                              name={`${mobileData}[${index}].replicatorData[${item.repaymentIndex}]remainingAmnt`}
                              type="text"
                              autoComplete="off"
                              component={renderField}
                              onChange={(e) => this.handleRemainingAmnt(e, index, number)}
                              touched={true}
                              invalid={this.props.errorMessage}
                              index={index}
                              showValidMsg={false}
                              validate={remainingAmountValidator}
                            /></Grid>
                          </Grid>
                        </Paper>}</React.Fragment>)

                })}
              </Grid>)
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    productFamilies: state.replicatorData.options.productFamily,
    quantity: state.replicatorData.options.quantity,
    productPlanNames: state.replicatorData.options.productPlanNames,
    cosmosPlans: state.replicatorData.cosmos.plans,
    cosmosRepayments: state.replicatorData.cosmos.repayments
  };
};

const mapDispatchToProps = dispatch => {
  return {
    generateServiceId: (qty, index) => dispatch(generateServiceId(qty, index)),
    fetchProductRepayments: () => dispatch(fetchProductRepayments()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CosmosMobileComponent);

