import React, { useState } from "react";
import TilesFrame from "../Data/accordion-frames/replicator/DataTilesFrame";
import FormComponent from "./FormComponent";
import HeaderContent from '../common/Header/HeaderContent';

const NewronData = (props) => {
    const [nuronDataType, setnuronDataType] = useState(null);
    return (
        <div className="white">
            <div className="custom-header">
                <HeaderContent
                    title="Nuron Data Setup"
                    icon="users team-icon"
                    redirectUtility={true}
                    push={props?.history?.push}
                />
                <FormComponent />
            </div>
        </div>
    )
}

export default NewronData;