import React, { useState } from "react";
import { connect } from "react-redux";
import { FieldArray } from "redux-form";
import * as unmsConstants from "../cleanup/b2b/util/constants";
import { fetchValidResources, applicationCleanup } from "../../actions/b2bCleanUpActions";
import { displayErrorAlert } from "../common/alerts/alerts";
import RangeInput from "../cleanup/b2b/util/RangeInput";
const asyncBlurFields = ["ranges[].startRange", "ranges[].endRange"];
import ReduxFormContainer from "../common/form/redux/ReduxFormContainer";
import swal from "sweetalert2";
import TilesFrame from "../Data/accordion-frames/replicator/DataTilesFrame";
import { useEffect } from "react";
import { displayLoading } from "../common/alerts/alerts";
import { Table } from 'reactstrap';
import { statusBtobDataCleanup } from '../../actions/b2bCleanUpActions'
import PaginationController from "../../utils/PaginationController";
import _ from "lodash";

import './b2b.css';

const PaginationSearch = ({handlePaginationSearch}) => {
    return <div className="paginationBtn">
        <TilesFrame
            heading={"Action"}
            color="green"
            data={[
                {
                    title: "Submit",
                    value: 61,
                    length: 9,
                    label: "Submit",
                    icon:'icon-tick'
                }
            ]}
            select={handlePaginationSearch}
        />
    </div>
}

const B2BDataCleanApplication = (props) => {
    const [applicationName, setApplicationName] = useState(null);
    const [searchResult, setSearchResult] = useState(null);
    const [nassSearch, setNassSearch] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePaginationSearch = () => {
        const { startRange, endRange } = props.updateRange[0];
        displayLoading({ title: "Loading", bodyText: "Please wait for some second" })
        const reqBody = {
            "environment": props.selectedEnvValue,
            "range": startRange + "-" + endRange,
            "resourceType": "MSISDN",
            applicationName: applicationName.title
        }
        props.applicationCleanup(reqBody).then((response) => {
            if (response.data.responseStatus === "FAIL" || response.data.responseStatus === "Fail") {
                displayErrorAlert({
                    title: "Error",
                    errorMsg: response.data.responseDescription
                });
            } else {
                let message = response.data.responseDescription
                swal.fire(
                    "Success",
                    message,
                    "success"
                );
            }
        });
    }
    const handleSubmitNumber = (primaryButton) => {
        const { startRange, endRange } = props.updateRange[0];
        displayLoading({ title: "Loading", bodyText: "Please wait as we search your data" })
        if (primaryButton === "OCS" || primaryButton === "CMP") {
            displayLoading({ title: "Loading", bodyText: "Please wait as we search your data" })
            const reqBody = {
                "environment": props.selectedEnvValue,
                "range": startRange + "-" + endRange,
                "resourceType": "MSISDN",
                applicationName: applicationName.title
            }
            props.applicationCleanup(reqBody).then((response) => {
                if (response.data.responseStatus === "FAIL" || response.data.responseStatus === "Fail") {
                    displayErrorAlert({
                        title: "Error",
                        errorMsg: response.data.responseDescription
                    });
                } else {
                    let message = response.data.responseDescription
                    swal.fire(
                        "Success",
                        message,
                        "success"
                    );
                }
            });
        } else {
            let req = {
                "environment": applicationName.title === "B2B_UNMS" ? "INT2" : 'QA2',
                "resource": startRange + "-" + endRange,
                "resourceType": applicationName.title,
                "pageIndex": 0,
                "pageSize": 5
            }
            props.statusBtobDataCleanup(req).then((response) => {
                if (response.data.responseStatus === "Fail") {
                    return displayErrorAlert({
                        title: "Error",
                        errorMsg: response.data.responseDescription
                    });
                } else {
                    let arrayOfPages = _.chunk(response.data.content, pageSize);
                    swal.fire(
                        "Success",
                        '',
                        "success"
                    );
                    if (applicationName.title === "B2B_NAAS") {
                        return setNassSearch(arrayOfPages)
                    } return setSearchResult(arrayOfPages)
                }
            });
        }
    }

    useEffect(() => {
        return () => {
            setApplicationName(null);
            setSearchResult(null);
            setNassSearch(null)
        }
    }, []);

    useEffect(() => {
        setSearchResult(null);
        setNassSearch(null);
    }, [applicationName]);

    const gotoPage = (page) => {
        setCurrentPage(page);
    }

    const pageSize = 5;

   
    return (
        <div className="white pb-20">
            <nav className="applicationList">
                <TilesFrame
                    heading={"List of Applications"}
                    color="green"
                    data={[
                        {
                            title: "B2B_SALESFORCE",
                            value: 61,
                            length: 9,
                            label: "Salesforce",
                            icon: "icon-mobile-messaging"
                        },
                        {
                            title: "B2B_OCS",
                            value: 61,
                            length: 9,
                            label: "OCS",
                            icon: "icon-mobile-messaging"
                        },
                        {
                            title: "B2B_CMP",
                            value: 61,
                            length: 9,
                            label: "CMP",
                            icon: "icon-mobile-messaging"
                        },
                        {
                            title: "B2B_NAAS",
                            value: 61,
                            length: 9,
                            label: "NAAS",
                            icon: "icon-mobile-messaging"
                        },
                        {
                            title: "B2B_UNMS",
                            value: 61,
                            length: 9,
                            label: "UNMS",
                            icon: "icon-mobile-messaging"
                        },
                    ]}
                    selected={applicationName?.label}
                    select={(data) => { setApplicationName(data) }}
                />
            </nav>
            {applicationName ? <ReduxFormContainer
                initialValues={unmsConstants.INITIAL_VALUE}
                formName={unmsConstants.B2B_CLEANUP_APPLICATION}
                asyncBlurFields={asyncBlurFields}
                propsDepth={4}
                editType={"Submit"}
                recordLimit={50}
            >
                <FieldArray
                    assignProps
                    name="ranges"
                    handlePrimary={handleSubmitNumber}
                    primaryButton={applicationName.label}
                    serviceIdField={{}}
                    component={RangeInput}
                    searchWithBtn={true}
                />
                {nassSearch ? <div id="resource-table">
                    <Table>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Resource</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                nassSearch && nassSearch.length && nassSearch[currentPage] && nassSearch[currentPage].map((resource, index) => {
                                    return (
                                        <tr>
                                            <td style={{ fontWeight: "bold" }}>
                                                <span className={`gradient-icon base-default`}>
                                                    <i className={`td-icon-sm icon-manage-services theme-text-primary `}></i>
                                                </span>
                                                {index + 1}
                                            </td>
                                            <td>{resource.resource}</td>
                                            <td>{resource.status}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <PaginationController
                        totalPages={Math.ceil(nassSearch.length / 5)}
                        gotoPage={gotoPage}
                    />
                    <PaginationSearch handlePaginationSearch={handlePaginationSearch}/>
                </div> : null}
                {searchResult ?
                    <>
                        <Table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Resource Value</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    searchResult && searchResult.length && searchResult[currentPage] && searchResult[currentPage].map((resource, index) => {
                                        return (
                                            <tr key={((currentPage) * pageSize) + (index + 1)}>
                                                <td style={{ fontWeight: "bold" }}>
                                                    <span className={`gradient-icon base-default`}>
                                                        <i className={`td-icon-sm icon-manage-services theme-text-primary `}></i>
                                                    </span>
                                                    {((currentPage) * pageSize) + (index + 1)}
                                                </td>
                                                <td>{resource.resource}</td>
                                                <td>
                                                    <span className={`gradient-icon`}>
                                                        <i className={`td-icon-sm theme-text-primary`}></i>
                                                    </span>
                                                    {resource.status}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        <PaginationController
                            totalPages={searchResult.length}
                            gotoPage={gotoPage}
                        />
                        <PaginationSearch handlePaginationSearch={handlePaginationSearch}/>
                    </> : null}
            </ReduxFormContainer> : null}

        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        updateRange: state.form && state.form[unmsConstants.B2B_CLEANUP_APPLICATION] && state.form[unmsConstants.B2B_CLEANUP_APPLICATION].values && state.form[unmsConstants.B2B_CLEANUP_APPLICATION].values.ranges || [],
        selectedEnvValue: state.auth.selectedEnvValue,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchValidResources: (resourceRangeData) => dispatch(fetchValidResources(resourceRangeData)),
        applicationCleanup: (reqBody) => dispatch(applicationCleanup(reqBody)),
        statusBtobDataCleanup: (req) => dispatch(statusBtobDataCleanup(req))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(B2BDataCleanApplication);
