import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import TextField from '@mui/material/TextField';
import {
  displayLoading,
  displaySuccess,
  displayErrorAlert,
} from "../common/alerts/alerts";
import Box from '@mui/material/Box';
import { searchRcrRequestDataId,triggerRcrProcess } from "../../actions/utilityActions";
import swal from "sweetalert2";
import { Button, makeStyles, Typography, Tooltip } from '@material-ui/core';
import BreadTable from "./Rcr/RcrTable/RcrBreadTable";
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import ReplyIcon from '@mui/icons-material/Reply';
import _ from 'lodash';

const checkBoxProps = { inputProps: { 'aria-label': 'Checkbox' } };

const RcrSubmitForm = (props) => {
  const [rcrInputValue, setRcrInputValue] = useState(null);
  const [rcrErrorMessage, setRcrErrorMessage] = useState(null);
  const [rcrData, setRcrData] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [triggerPayload, setTriggerPayload] = useState([]);
  const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string)

  const hanldeRcrOptionChange = (e) => {
    if (e.target.value === "") {
      setRcrErrorMessage("Input cannot be empty");
      setRcrInputValue(null)
    } else {
      if (isNumeric(e.target.value)) {
        setRcrInputValue(e.target.value)
        setRcrErrorMessage(null)
      } else {
        setRcrErrorMessage("Please enter Numberic value");
      }
    }
  };

  const searchRcrRequestIdSubmit = () => {
    let request = {
      environment: props.selectedEnvValue,
      cac: rcrInputValue
    };
    props.searchRcrRequestDataId(request).then((response) => {
      const { orders } = response;
      if (orders) {
        setRcrData(orders);
        setAccountData(response.account)
      } else {
        return swal.fire({
          type: 'error',
          text: "No activated orders present for the input CAC"
        })
      }
    });
  };

  const HandleChecked = (orderReferenceNo, field2) => {
    let newrcrData = rcrData.map((_item) => {
      if (_item.orderReferenceNo === orderReferenceNo) {
        _item.checked = !field2;
        return _item
      } return _item
    })
    setRcrData(newrcrData);
  }

  useEffect(() => {
    let getSelectedValue = _.filter(rcrData, (_item) => {
      _item["orderRefNumber"] = _item.orderReferenceNo;
      _item["products"] = _item.product;
      return _item.checked === true;
    });
    setTriggerPayload(getSelectedValue);
  }, [rcrData])

  const CreateRcrRequestIdSubmit = () => {
    let filterSelectedValue = triggerPayload.map((_item) => {
      delete _item.checked;
      delete _item.orderReferenceNo;
      delete _item.product;
      return _item;
    });
    let request = {
      customerId: accountData.cac,
      environment: props.selectedEnvValue,
      type: accountData.type,
      acctNumber: accountData.accountNo,
      orders: filterSelectedValue,
    }
    props.triggerRcrProcess(request).then((response) => {
      const {message,status,batchId}=response;
        return swal.fire({
          type: 'success',
          title:status,
          text: `${message} (${batchId})`
        })
    });
  }

  const dataArgs = {
    size: "large",
    isLoading: false,
    isExpandable: true,
    hideEmptyExpandOptions: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: 'All', value: -1 }],
    // expandable: true,
    columnMetadata: [
      {
        dataset: 1,
        title: "Order Reference Number",
        field: ["orderReferenceNo"],
        isExpandable: false,
        component: (field) => <Typography noWrap style={{ "margin": "auto" }} variant="body2">
          {field}
        </Typography>,
      },
      {
        dataset: 2,
        title: "Charge Model",
        field: ["chargeModel"],
        isExpandable: false,
        component: (field) => <Typography noWrap style={{ "margin": "auto" }} variant="body2">
          {field}
        </Typography>,
      },
      {
        dataset: 3,
        title: "Group",
        field: ["product"],
        isExpandable: false,
        component: (field) => {
          let obj_to_locate = { 'productType': "Group" };
          const result = _.find(field, obj_to_locate);
          return <Typography noWrap style={{ "margin": "auto" }} variant="body2">
            {result?.productName || ""}
          </Typography>
        },
      },
      {
        dataset: 4,
        title: "product",
        field: ["product"],
        expandable: true,
        expandableOnly: true,
      },
      {
        dataset: 5,
        title: "Action",
        field: ["orderReferenceNo", "checked"],
        expandable: false,
        expandableOnly: false,
        component: (orderReferenceNo, checked = false) => {
          return <Checkbox {...checkBoxProps} checked={checked} onChange={() => HandleChecked(orderReferenceNo, checked)} />
        },
      },
    ]
  }

  const RcrBlur = () => {
    if (!rcrInputValue) {
      setRcrErrorMessage("Input cannot be empty")
    }
  }

  const Goback = () => {
    setRcrData(null)
  }

  const AccountComponenet = () => {
    if (accountData) {
      return <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 3, md: -50 }} style={{ height: 80, marginLeft: 0, marginBottom: 25, float: "left" }}>
        <Grid item className="createRcrChild" xs={3}>
          <p><b>CAC</b><br /> {accountData.cac} </p>
        </Grid>
        <Grid item className="createRcrChild" xs={3}>
          <b><p>Type</p> </b>
          <p>{accountData.type}</p>
        </Grid>
        <Grid item className="createRcrChild" xs={3}>
          <b><p>Status</p> </b>
          <p>{accountData.status}</p>
        </Grid>
        <Grid item className="createRcrChild" xs={3}>
          <b><p>Account No</p> </b>
          <p>{accountData.accountNo}</p>
        </Grid>
      </Grid>
    } return null
  }

  return (
    <>

      {rcrData ? <div className="createRcr" style={{ marginTop: 20 }}>
        <AccountComponenet />
        <BreadTable {...dataArgs} data={rcrData} />
        <Button variant="contained" endIcon={<ReplyIcon />} style={{
          color: "white",
          background: "rgb(25, 118, 210)",
          // width: 140,
          marginTop: -20
        }} onClick={Goback}>
          Back
        </Button>
        <Button variant="contained"
          style={{
            color: "white",
            background: "rgb(25, 118, 210)",
            marginLeft: 1055,
            // width: 140,
            marginTop: -66
          }}
          disabled={!triggerPayload.length}
          onClick={CreateRcrRequestIdSubmit}
        >Submit</Button>
      </div> : <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{
        marginTop: 50,
        marginLeft: 115
      }}>
        <Grid item xs={6}>
          <TextField
            error={rcrErrorMessage ? true : false}
            label="CAC"
            style={{
              width: "490"
            }}
            onBlur={RcrBlur}
            helperText={rcrErrorMessage}
            onChange={hanldeRcrOptionChange}
            required
            autoFocus
          />
        </Grid><br />
        <Grid item xs={6}>
        </Grid>
        <Grid item xs={6}>
          <Button variant="contained" style={{
            width: "110",
            marginTop: 8,
            color: "white",
            background: "rgb(25, 118, 210)",
            marginLeft: 130
          }}
            disabled={!rcrInputValue || rcrErrorMessage}
            onClick={searchRcrRequestIdSubmit}
          >Search</Button>
        </Grid>
      </Grid>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: state.utility.filters,
    selectedFilters: state.utility.selectedFilters,
    createRcrRequestId: state.utility.createRcrRequestId,
    selectedEnvValue: state.auth.selectedEnvValue,
    formValues: state.form && state.form.utility && state.form.utility.values,
    syncErrors:
      state.form && state.form.utility && state.form.utility.syncErrors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchRcrRequestDataId: (request) =>
      dispatch(searchRcrRequestDataId(request)),
    triggerRcrProcess: (request) =>
      dispatch(triggerRcrProcess(request)),
    resetForm: () => dispatch(reset("utility")),
  };
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(RcrSubmitForm);