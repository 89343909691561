import React from "react";
import { css } from "@emotion/core";
import {CircleLoader} from "react-spinners";
 
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
 
class SpinnerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
 
  render() {
    
    return (
  
        <div className="sweet-loading" style={{left : "50%" , marginTop : "25px"}}>
          <CircleLoader
            css={override}
            size={60}
            color={"#1ba1e2"}
            loading={this.state.loading}
          />
        </div>
     
    );
  }
}
export default SpinnerComponent;