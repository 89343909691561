import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(2),
      backgroundColor: '#3f51b5'
      
    },
  },
}));

export default function PaginationControlled({gotoPage,totalPages, isClearPagination}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    gotoPage(value-1);
  };

  if (isClearPagination && page !== 1) {
    setPage(1);
  }
  
  return (
    <div className={classes.root}>
      <Pagination count={totalPages} page={page} onChange={handleChange} color="secondary" variant="outlined" shape="rounded" />
    </div>
  );
}