import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import { FilterList as FilterListIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useFilterIconStyle = makeStyles((theme) => ({
  button: {
    width: (props) =>
      props.size === "medium" ? 40 : props.size === "small" ? 30 : null,
    height: (props) =>
      props.size === "medium" ? 40 : props.size === "small" ? 30 : null,
    background: theme.palette.background.paper,
    borderRadius: (props) => (props.variant === "round" ? null : 0),
    // border: `2px solid ${theme.palette.icon.border}`,
    border: `2px solid #115293`,
    "&:hover": {
      // background: theme.palette.icon.hover.backgroundColor,
      background: "#115293",
      transform: "translateY(-2px)",
      "& $icon": {
        color: theme.palette.background.paper,
      },
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      border: theme.palette.action.disabled,
      "& $icon": {
        color: theme.palette.action.disabled,
      }
    }
  },
  icon: {
    // color: theme.palette.icon.color,
    color: "#1976d2",
  },
}));

const defaultSize = "medium";
const defaultVariant = "square";

const FilterIcon = (props) => {
  const { size = defaultSize, variant = defaultVariant, ...rest } = props;
  const filterIconStyle = useFilterIconStyle(props);

  return (
    <IconButton
      size={size}
      variant={variant}
      className={filterIconStyle.button}
      {...rest}
    >
      <FilterListIcon fontSize="inherit" className={filterIconStyle.icon} />
    </IconButton>
  );
};

FilterIcon.propTypes = {
  size: PropTypes.oneOf(["medium", "small"]),
  variant: PropTypes.oneOf(["round", "square"]),
};

export default FilterIcon;
