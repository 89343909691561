import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import ReactTooltip from 'react-tooltip'
import {checkProfileThenCallAction} from './DataViewCustomerSf';

class DataViewCustomerSfBtn extends React.Component {
  render() {
        const {customer, collapseClick, getSalesForceDetails, sfCustomerStatus,collapseSfView } = this.props;
        const {firstName,lastName, customerId, sfImporting} = customer;
        //const incomplete = !(firstName && lastName);
        //TODO had to be done after completing all Salesforce Items
        const incomplete = false;
        const activeSfCustomer = (sfCustomerStatus === "success");
        return (
            <span
                data-tip={"Would be available soon"}
                data-tip-disable={!incomplete}
                data-for={customerId}>
                <button
                    disabled={false}
                    onClick={() => {
                    if (!activeSfCustomer) {
                        getSalesForceDetails(customerId)
                    } else {
                        collapseClick(customerId)
                    }
                }}
                    className={classNames("btn", "btn-sm", "btn-block", `btn-outline-${incomplete
                    ? 'secondary'
                    : activeSfCustomer
                        ? 'success'
                        : 'primary'}`, "ld-over", {running: sfImporting})}>
                    <i
                        className={`fa fa-${activeSfCustomer
                        ? (collapseSfView
                            ? 'minus'
                            : 'eye')
                        : 'cloud'}`}
                        aria-hidden="true"></i>{` `}{sfImporting
                        ? 'Importing'
                        : (collapseSfView
                            ? 'View Less'
                            : 'View More')}
                    <div className="ld ld-ring ld-spin"></div>
                </button>
                <ReactTooltip type="error" effect="solid" place="bottom" id={customerId}/>
            </span>
            )
  }
}

const mapStateToProps = (state, ownProps) => {
    const {active, available} = state.profiles;
    const activeProfileId = active[ownProps.customer.environment];
    const activeProfile = available[activeProfileId];
    return {
        activeProfile,
        isProfileSelected: (activeProfile != null)
    }
}

export default connect(mapStateToProps, null)(DataViewCustomerSfBtn);