import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, submit } from 'redux-form';
import { Row, Col } from 'reactstrap';
import { renderField } from '../common/fields/fields';
import ContentFrame from "../Data/accordion-frames/OptionalFrame"
import { utilityFormValidator as validate } from '../common/form/syncValidators';
import {
    Table,
    Button
  } from 'reactstrap';

class UtilityOptionalContent extends Component {
    state = {
        customer: false,
        mobile: false
    }
    handleOptionalArea = (area) => {
        this.setState((preState) => ({ ...preState, [area]: true }))
    }
    
    getByoCustomerFormContent=(isNbn,submitting,isCompleteOrder)=>{
        return(
            <div className="utility-input-area-content">
                {!isCompleteOrder && isNbn && (
                    <Row>
                    <Col xs="4">
                            <Field
                                label="Customer ID"
                                name="nbnCustomerId"
                                tabIndex="1"
                                disabled={submitting}
                                component={renderField}
                                autoComplete="off" 
                                required/>
                        </Col>
                        <Col xs="4">
                            <Field
                                label="Billing Account No."
                                name="nbnBan"
                                tabIndex="3"
                                disabled={submitting}
                                component={renderField}
                                autoFocus
                                autoComplete="off"
                                required />
                        </Col>
                        <Col xs="4">
                            <Field
                                label="Service Account No."
                                name="nbnSan"
                                tabIndex="4"
                                disabled={submitting}
                                component={renderField}
                                autoComplete="off" 
                                required/>
                        </Col>
                    </Row>
                )}
                {!isCompleteOrder && !isNbn && (<Row>
                                    <Col xs="4">
                                        <Field
                                            label="Customer ID"
                                            name="customerId"
                                            tabIndex="1"
                                            disabled={submitting}
                                            component={renderField}
                                            autoComplete="off" />
                                    </Col>
                                    <Col xs="4">
                                        <Field
                                            label="Billing Account No."
                                            name="ban"
                                            tabIndex="3"
                                            disabled={submitting}
                                            component={renderField}
                                            autoFocus
                                            autoComplete="off" />
                                    </Col>
                                </Row>)}
                </div>
        )
    }
    
    render() {
        const { submitting, handleSubmit, isNbn, isCompleteOrder, completeOrderSubmit={},isEstablishedStub,isSearchDisabled} = this.props;
        const { customer, mobile } = this.state;
        return (
            <div>
                <form onSubmit={handleSubmit(completeOrderSubmit)}>
                {isCompleteOrder && (<div className="utility-input-area">
                            <div className="utility-input-area-content">
                                <Row>
                                    <Col xs="4">
                                        <Field
                                            label="Order ID"
                                            name="orderId"
                                            tabIndex="2"
                                            disabled={submitting}
                                            component={renderField}
                                            autoComplete="off" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="4">
                                        <Button  color="primary" tabIndex="2"  className="submitButton" type="submit"
                                        >Submit</Button>
                                    </Col>
                                </Row>
                            </div>
                    </div>)}


                    {!isCompleteOrder && !isEstablishedStub && (<div className="utility-input-area">
                    <ContentFrame
                        key={1}
                        color={"green"}
                        collapsible
                        collapsed={false}
                        collapseIcon={
                            mobile ? "icon-ui-up-arrow" : "icon-ui-down-arrow"
                        }
                        collapseColor={!mobile ? "purple" : "blue"}
                        collapseDisabled={false}

                        heading= {(isNbn === true)? "Customer Details" : "BYO Customer"}
                        icon={true}
                        collapseCanClick={true}
                        handleOnClick={() => this.handleOptionalArea("mobile")}
                        optionalView={mobile}
                    >
                        {this.getByoCustomerFormContent(isNbn, submitting, isCompleteOrder)}
                        </ContentFrame>
                    </div>)}

                    {isEstablishedStub && (<div className="utility-input-area">
                        <div className="utility-input-area-content">
                            <Row>
                                <Col xs="4">
                                    <Field
                                        label="Customer ID"
                                        name="customerId"
                                        tabIndex="1"
                                        disabled={submitting}
                                        component={renderField}
                                        autoComplete="off"
                                         required/>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="4">
                                    <Button color={isSearchDisabled ? 'secondary' : 'primary'} disabled={isSearchDisabled}  tabIndex="2" className="submitButton" type="submit"

                                    > {isSearchDisabled? (<span> Search <i class="fa fa-spinner fa-pulse fa-fw"></i> </span>)  :'Search'}
                                    </Button>
                                </Col>
                            </Row>                                                     
                        </div>
                    </div>)}
                    {!isCompleteOrder && ! isEstablishedStub && !isNbn && (<div className="utility-input-area">
                        <ContentFrame
                            key={1}
                            color={"green"}
                            collapsible
                            collapsed={false}
                            collapseIcon={
                                mobile ? "icon-ui-up-arrow" : "icon-ui-down-arrow"
                            }
                            collapseColor={!mobile ? "purple" : "blue"}
                            collapseDisabled={false}

                            heading={"BYO Mobile Vacant Data"}
                            icon={true}
                            collapseCanClick={true}
                            handleOnClick={() => this.handleOptionalArea("mobile")}
                            optionalView={mobile}
                        >
                            <div className="utility-input-area-content">
                                <Row>
                                    <Col xs="4">
                                        <Field
                                            label="MSISDN"
                                            name="msisdn"
                                            tabIndex="2"
                                            disabled={submitting}
                                            component={renderField}
                                            autoComplete="off" />
                                    </Col>
                                    <Col xs="4">
                                        <Field
                                            label="IMSI"
                                            name="imsi"
                                            tabIndex="4"
                                            disabled={submitting}

                                            component={renderField}
                                            autoComplete="off" />
                                    </Col>
                                    <Col xs="4">
                                        <Field
                                            label="SIM"
                                            name="sim"
                                            tabIndex="4"
                                            disabled={submitting}
                                            component={renderField}
                                            autoComplete="off" />
                                    </Col>
                                </Row>
                            </div>
                        </ContentFrame>
                    </div>)}
                    
    
                </form>
            </div>
        )
    }
}

export default compose(connect(null, null), reduxForm({
    validate,
    form: 'utility',
    onSubmit: submit
}))(UtilityOptionalContent);
