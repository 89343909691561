import React from "react";
import { UserSwitch } from '../common/custom/ToggleSwitch';
import './myTeams.css'
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {  
    minWidth: "200px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const dropdownMenuProps={
  menuStyle:{
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: 'lightgrey',
  },
}

const ExchangeServicePanel = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className="row mb-3 exchangeServicesPanel">
        <div className="col-1">
          <UserSwitch
            checked={props.checked}
            onChange={props.onChangeSwitchHandler}
          />
        </div>
        <div className="col-4">{props.helperText}
        </div></div>
      {props.checked &&
        <div className="row mb-3 exchangeServicesPanel">
          <div className="col-4 ml-1">
             {(props.exchanges !== undefined && props.exchanges.length > 0) ? 
            <FormControl className={classes.formControl}>
              <Select
              native value={props.exchangeId}
                onChange={props.onChangeHandler}
                name="exchangeData"
                className={classes.selectEmpty}
                dropDownMenuProps={dropdownMenuProps}
              ><option value="">None</option>
                {props.exchanges.map((data) => {
                  return (
                    <option value={data.value} data-id={data.count}>{data.value}</option>
                  )
                })}
              </Select>
              <FormHelperText>{props.helperText}</FormHelperText>
            </FormControl> : (
              <h6>
              No {props.helperText} for this service
              </h6>
            )}
          </div></div>}</React.Fragment>
  )
}
export default (ExchangeServicePanel);