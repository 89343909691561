import React, {Component, Fragment} from 'react'
import {Fade} from 'reactstrap';
import classnames from 'classnames';
import {DataFilterTileNew} from '../tiles/DataFilterTileNew';
import './dataFilesFrame.css'
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import Button from '@mui/material/Button';

export default(props) => {
    const {
        loading,
        selected,
        subSelected,
        select,
        heading,
        headingRef,
        data,
        color,
        prevSelected,
        size,
        feedBackCount,
        style,
        dataViewStyle,
        backButton,
        redirectUtility=false,
        push,
        backBtnColor
    } = props;
    const disabledFunc = () => { }
    const BackButtonComponent=()=>{
        if(redirectUtility){
            return <Button style={{marginLeft:"430px"}} onClick={()=>{push('/data/utility')}} variant="contained" startIcon={<ReplyAllIcon />}>Back to Utility</Button>
        }else{
            if(backButton && selected){
                return <><Button style={{marginLeft:"365px"}} onClick={()=>select(null)} variant="contained" startIcon={<ReplyAllIcon />}>Back</Button>
                </>
            }else{
                return <i
                class={`${selected
                ? 'td-icon-sm icon-tick text-success'
                : ''}`}
                aria-hidden="true"></i>
            }
        }
    }
    return (
        <Fade in={true} style={style}>
            <div className="dataview-header" style={dataViewStyle}>
            <div className="container">
                <div class="row marginRight_Ninety">
                <div class="col-7">
                <p className="sub-leader font-weight-light" style={{float:"left"}}>{heading}{selected
                        ? ` - ${selected}`
                        : ''}{subSelected ? ` (${subSelected})`: ''}</p>
                        </div>
                <div class="col-3">
                   <BackButtonComponent/>
                </div>
                <div >
                    {feedBackCount !== 0 &&
            <Fragment class="d-inline">
                {feedBackCount !== 0 ? <p class="d-inline">{feedBackCount}</p> :  <p class="d-inline">0</p>}
                </Fragment>
            }
                    </div>
                
                    
                    </div>
            </div></div>
            
            <div className="content">
                {/* <div className={`tiles-grid base-${color}`}> */}
                <div className="ml-3 content tile-box">
                    {data.map((tile, index) => {
                        return(
                            <div key={index}>
                                 <div className={`tiles-grid flex base-${(tile.disabled) ? "grey" : color}`}><DataFilterTileNew
                            {...tile}
                            obj={tile}
                            selectType={tile.disabled ? disabledFunc : select}
                            selectedTypes={selected}
                            selectedSubtypes={subSelected}
                            prevSelected={prevSelected}
                            size={size}></DataFilterTileNew>
                            </div>
                            </div>)
                    })}
                </div> 
                <div className={`tiles-grid base-grey`}>
                    {(data.length === 0) && <DataFilterTileNew
                        icon={"icon-ui-cross"}
                        label={"No Data"}/>}
                </div>
            </div>
        </Fade>
    )
}