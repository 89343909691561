import { isAlphanumeric, isEmail, isNumeric } from 'validator';

const isValidAusLandline = value => value.match(new RegExp("^0(?:2|3|7|8|4)[0-9]{8}$")) !== null

export const adborIdValidator = value => {
    if (!value)
        return "Cannot be null"
    if (!isNumeric(value))
        return "Adbor ID must be a number"
    if (value.length < 7)
        return "Adbor ID is too short"
    if (value.length > 9)
        return "Adbor ID is too long"
}

export const notNullOrUndefined = value => {
    if((!value) || (value === undefined))
        return "Cannot be null"
}

export const eligibilityReasonValidation = value => {
    if((!value) || (value === undefined))
        return "Cannot be null"
}

export const extensionFromValidator = value => {
    if(!value)  return "Cannot be null"
    if (!value.startsWith('61')) return "Starts with 61";
    else if (value.length !== 11) return "Does not match length format";
}
export const extensionToValidator = value => {
    if(!value)  return "Cannot be null"
    if (!value.startsWith('61')) return "Starts with 61";
    else if (value.length !== 11) return "Does not match length format";
}
export const fnnCosmosValidator = value => {
    if (!value)
        return "Cannot be null"
    if(value.length !=9)
        return "FNN does not match length format"
    if(!value.startsWith("N"))
        return "FNN starts with N"
    if(!value.endsWith("R"))
        return "FNN ends with R"
}
export const fnnCosmosServiceValidator = value => {
    if(!value)
        return "Cannot be null"
    if(value.length !=11)
        return "Service FNN does not match length format"
    if(!value.startsWith("61"))
        return "Service FNN starts with 61"
}

export const servicesTiptValidator = value => {
    if (value === undefined || value === null) return; // Don't card supplied fnn
    if (!isNumeric(value))
        return "TIPT COUNT must be a number"
}

export const accountNumberValidator = value => {
    if(!value)
        return "Cannot be null"
}

export const addressValidator = value => {
    if(!value)
    return "Cannot be null"
}

export const fnnValidator = value => {
    if (value === undefined || value === null) return; // Don't card supplied fnn
    if (!isNumeric(value))
        return "FNN must be a number"
    if (value.length !== 10)
        return "FNN does not match length format"
    if (!isValidAusLandline(value))
        return "FNN is not valid Aus landline"
}

export const fnnValidatorForNewReplicator = value => {
    if (!value)
        return "Cannot be null";
    if (!isNumeric(value))
        return "FNN must be a number"
    if (value.length !== 10)
        return "does not match length format"
    if (!isValidAusLandline(value))
        return "FNN is not valid Aus landline"
}

export const rangeValidator = value => {
    if (!value)
        return "Cannot be null"
    if (!isNumeric(value))
        return "Range must be a number"
    if (value.length <= 0 || value.length > 3)
        return "Range does not match length format"
}

export const huntGroupFnnValidator = (value) => {
    if (!value)
        return "Cannot be null"
}

export const linkedFnnValidator = (value, allValues, props, name) => {

    const rowValue = allValues.huntGroup.filter((huntGroup) => huntGroup.linkedFnn === value);
    const fnn = rowValue.length > 0 && rowValue[0].fnn;
    if (!value)
        return "Cannot be null"
    if (!fnn)
        return "Select service id First"
}



export const roleValidator = (value, allValues, props, name) => {
    if (!value)
        return "Cannot be null"
}


export const imsiValidator = (value) => {
    if (!value) return "Cannot be null";
    else if (!isNumeric(value)) return "IMSI must be a number";
    else if (!value.startsWith('5050')) return "IMSI starts with 5050";
    else if (value.length !== 15) return "IMSI does not match length format";
};

export const simValidator = (value) => {
    if (!value) return "Cannot be null";
    else if (!isNumeric(value)) return "SIM must be a number";
    else if (!value.startsWith('8961')) return "SIM starts with 8961";
    else if (value.length !== 20) return "SIM does not match length format";
};

export const ekiValidator = (value) => {
    if (!value) return "Cannot be null";
    else if (!isAlphanumeric(value)) return "EKI must be alphanumeric";
    else if (value.length !== 32) return "EKI does not match length format";
};

export const pukValidator = (value) => {
    if (!value) return "Cannot be null";
    else if (!isNumeric(value)) return "PUK must be a number";
    else if (value.length !== 8) return "PUK does not match length format";
};

export const kindValidator = (value) => {
    if (!value) return "Cannot be null";
    else if (!isNumeric(value)) return "KIND must be a number";
    else if (value.length !== 3) return "KIND does not match length format";
};

export const a4indValidator = (value) => {
    if (!value) return "Cannot be null";
    else if (!isNumeric(value)) return "A4IND must be a number";
    else if (value.length > 2) return "A4IND does not match length format";
};

export const fsetindValidator = (value) => {
    if (!value) return "Cannot be null";
    else if (!isNumeric(value)) return "FSETIND must be a number";
    else if (value.length > 2) return "FSETIND does not match length format";
};

export const pinValidator = (value) => {
    if (!value) return "Cannot be null";
    else if (!isNumeric(value)) return "PIN must be a number";
    else if (value.length !== 4) return "PIN does not match length format";
};

export const deviceIDValidator = (value) => {
    if (!value) return "Cannot be null";
}
export const monthlyAmountValidator = (value) => {
    if (!value) return "Cannot be null";
}
export const remainingTermValidator = (value) => {
    if (!value) return "Cannot be null";
}
export const remainingAmountValidator = (value) => {
    if (!value) return "Cannot be null";
}

export const msisdnValidator = value => {
    if (!value)
        return "Cannot be null";
    if (!isNumeric(value))
        return "MSISDN must be a number"
    if (!value.startsWith('04')) return "MSISDN starts with 04";
    else if (value.length !== 10) return "MSISDN does not match length format";
}

export const cosmosMsisdnValidator = value => {
    if (!value)
        return "Cannot be null";
    if (!isNumeric(value))
        return "MSISDN must be a number"
    if (!value.startsWith('614')) return "MSISDN starts with 614";
    else if (value.length !== 11) return "MSISDN does not match length format";
}

function endsWithAlphabet(inputString) {
    // Regular expression to match only alphabetic character
    let endCharacter=inputString.slice(-1);
    const regex = /^[A-Z]+$/;
    return regex.test(endCharacter);
}

export const cleanupRangeValidator = (value, allValues, props, name) => {
    const cleanUpType = props.cleanUpType;
    const resourceType = props.snrmResourceType;
    const unmsResourceType = props.unmsResourceType;
    const editType = props.editType;
    const recordLimit = props.recordLimit;
    const b2bCleanupResourceType = props.b2bCleanupResourceType;
    const fieldName = "Number";

    if(unmsResourceType==="InfraCo FNN"){
        if (!value) {
            return "Cannot be null"
        }else{
            if ((!value.startsWith('N'))) {
                return `${unmsResourceType} should start with N`;
            }
            var removeFirstLastChar = value.substring(1, value.length-1);
            if (!isNumeric(removeFirstLastChar))
            return "Resource value must be a number"
            if (value.length !== 9) {
                return 'Resource value does not match length format';
            }
            if(!endsWithAlphabet(value)){
                return `The input string does not end with an alphabet character`
            }
        }
    }else{
        if (!value)
        return "Cannot be null"
    else {
        if (!isNumeric(value))
        return "Resource value must be a number"
    else if (b2bCleanupResourceType === 'MSISDN' || unmsResourceType === 'MSISDN') {
        if (value.length !== 11) {
            return "Resource value does not match length format";
        }
        else if ((!value.startsWith('61'))) {
            return "MSISDN should start with 61";
        }
    }}
    if (b2bCleanupResourceType === 'FNN' || unmsResourceType === 'FNN') {
        if (value.startsWith('614')) {
            return "FNN starts with 61 not 614";
        }
        else if (!value.startsWith('61')) {
            return "FNN starts with 61";
        }
        if (value.length !== 11) {
            return 'Resource value does not match length format';
        }
    } else if (unmsResourceType === 'SIM/IMSI') {
        if (!value) {
            return "Cannot be null"
        }
        else {
            if (!isNumeric(value)) {

                return "Resource value must be a number"
            }
        }
    }

    else if (resourceType === 'MSISDN') {
        if (value.length !== 11) {
            return "Resource value does not match length format";
        }
    } else if (resourceType === 'IMSI') {
        if (!value.startsWith('505')) {
            return 'Resource value should starts with 505';
        } else if (value.length !== 15) {
            return 'Resource value does not match length format';
        }
    } else if (resourceType === 'FNN') {
        if (cleanUpType === 'SNRM') {
            if (value.startsWith('614')) {
                return "FNN starts with 61 not 614";
            }
            else if (!value.startsWith('61')) {
                return "FNN starts with 61";
            }
            else if (value.length !== 11) {
                return 'Resource value does not match length format';
            }
        } else if (cleanUpType === 'UNMS') {
            if (value.length !== 11) {
                return 'Resource value does not match length format';
            }
        }
    }
}
    if (name.includes("startRange")) {
        if (allValues.ranges.filter(data => data.startRange === value).length > 1)
            return `You are already using this ${fieldName}`
        else if (unmsResourceType=='FNN' ? startRangeLimit(allValues, value, editType).length > 0 : startRangeLimit(allValues, value, editType).length > 0)
            return `Start Range must be smaller than End Range`
    }
    else if (name.includes("endRange")) {
        if (allValues.ranges.filter(data => data.endRange === value).length > 1)
            return `You are already using this ${fieldName}`
        else if (unmsResourceType=='FNN' ? endRangeLimit(allValues, value, editType).length > 0 : endRangeLimit(allValues, value, editType).length > 0)
            return `End Range must be greater than Start Range`
        else if (rangeLimit(allValues, recordLimit)) {
            return `Range must be ${recordLimit ? recordLimit : 50} or less`
        }
    }
}

export const somResourceStubRangeValidator = (value, allValues, props, name, type) => {
    const resourceType = props.resourceType;
    const editType = props.editType;
    const recordLimit = props.recordLimit;
    const fieldName = "Number";
    if (!value)
        return "Cannot be null"
    else {
        if (!isNumeric(value)) {
            return "Resource value must be a number"
        } else if (resourceType === 'MSISDN') {
            if (value.length !== 11) {
                return "Resource value does not match length format";
            } else if ((!value.startsWith('614'))) {
                return "MSISDN starts 614";
            }
        } else if (resourceType === 'FNN') {
            return "Stub creation is not established for FNN";
        }
    }
    if (type === "individual") {
        if (allValues.ranges.filter(data => data === value).length > 1) {
            return `You are already using this ${fieldName}`
        }
    }
    if (name.includes("startRange")) {
        if (allValues.ranges.filter(data => data.startRange === value).length > 1)
            return `You are already using this ${fieldName}`
        else if (startRangeLimit(allValues, value, editType).length > 0)
            return `Start Range must be smaller than End Range`
    } else if (name.includes("endRange")) {
        if (allValues.ranges.filter(data => data.endRange === value).length > 1)
            return `You are already using this ${fieldName}`
        else if (endRangeLimit(allValues, value, editType).length > 0)
            return `End Range must be greater than Start Range`
        else if (rangeLimit(allValues, recordLimit)) {
            return `Range must be ${recordLimit ? recordLimit : 50} or less`
        }
    }
}

export const cidnIsValid = cidn => cidn === "GENERATED" || (cidn && cidn.length === 10 && /^-{0,1}\d+$/.test(cidn));

const rangeLimit = (allValues, recordLimit) => {
    return ((allValues.ranges[0].endRange !== '' && allValues.ranges[0].startRange !== '') && (allValues.ranges[0].endRange - allValues.ranges[0].startRange) > (recordLimit ? recordLimit : 50))
}


  console.log("ResourceType in validator ====> ")

  const startRangeLimit = (allValues, value, editType = null) => allValues.ranges
      .filter((data) => data.startRange === value)
      .filter((data) => data.endRange && (editType !== "Insert" ? data.startRange > data.endRange : data.startRange >= data.endRange));

  const endRangeLimit = (allValues, value, editType = null) => allValues.ranges
      .filter((data) => data.endRange == value)
      .filter((data) =>data.startRange && (editType !== "Insert" ? data.startRange > data.endRange : data.startRange >= data.endRange));

  const msisdnStartRangeLimit = (allValues, value, editType = null) => allValues.ranges
      .filter((data) => data.startRange === value)
      .filter((data) => data.endRange && (editType !== "Insert" ? data.startRange !== data.endRange : data.startRange >= data.endRange));

  const msisdnEndRangeLimit = (allValues, value, editType = null) => allValues.ranges
      .filter((data) => data.endRange == value)
      .filter((data) =>data.startRange && (editType !== "Insert" ? data.startRange !== data.endRange : data.startRange >= data.endRange));

  const fnnStartRangeLimit = (allValues, value, editType = null) => allValues.ranges
      .filter((data) => data.startRange === value)
      .filter((data) => data.endRange && (editType !== "Insert" ? data.startRange !== data.endRange : data.startRange >= data.endRange));

  const fnnEndRangeLimit = (allValues, value, editType = null) => allValues.ranges
      .filter((data) => data.endRange == value)
      .filter((data) =>data.startRange && (editType !== "Insert" ? data.startRange !== data.endRange : data.startRange >= data.endRange));

    export const linkedCidnValidator = value => {
        if (value === undefined || value === null) return " CIDN Cannot be null";
        if (!isNumeric(value))
            return "CIDN must be a 10 digit number";
        else if (value.length !== 10) return "CIDN must be 10 digits";
    }
