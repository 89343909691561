import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const PasmatYTDBarGraph = (props) => {
    return (
        <div style={{ width: '100%' }}>
            <div className='header'>
                <h6 className='title'>{props.graphName}</h6>
            </div>
            <BarChart width={900} height={400} data={props.ytdGraphData}>
          <CartesianGrid strokeDasharray="6 6" />
          <XAxis dataKey="trendItem" allowDuplicatedCategory={false} />
          <YAxis dataKey={props.yData} allowDuplicatedPeriod={true} 
          label={{ value: props.label, position: "outsideRight", angle: -90,   dy: -5 ,dx: -30}}/>
          <Tooltip />
          <Legend />
          {props.ytdGraphData.map((s) => (
            <Bar dataKey={props.yData} data={s.data} name={s.period} 
            key={s.period} fill={s.color} strokeWidth={3}/>
          ))}
        </BarChart></div>
             );
}


export default PasmatYTDBarGraph;