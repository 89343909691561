import BreadTable from "@library/core/tables/BreadTable";
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { getBillStatus } from "./action";
import { connect } from "react-redux";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { RoundBtn } from "@library/core/buttons";

const RequestFileStatus = (props) => {
    const { totalElements, number, fileStatusLoader } = props;
    useEffect(() => {
        let request = {
            team: props.team,
            pageNumber: 0,
            pageSize: 10
        }
        props.getBillStatus(request);
    }, [])


    const StepDetailsCom = ({ props }) => {
        return <div className="requestFileStatus">
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>NUMBER</TableCell>
                            <TableCell>NAME</TableCell>
                            <TableCell>STATUS</TableCell>
                            <TableCell>LOGS</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.map((row) => (
                            <TableRow
                                key={row.stepLogs}
                            >
                                <TableCell style={{ paddingLeft: "40px" }}>{row.stepNumber}</TableCell>
                                <TableCell>{row.stepName}</TableCell>
                                <TableCell>{row.stepStatus}</TableCell>
                                <TableCell>
                                    {row.stepLogs}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    }

    const dataArgs = {
        expandTitle: "Step Details",
        size: "large",
        isExpandable: true,
        rowsPerPage: 10,
        totalRows: totalElements,
        rowsPerPageOptions: [10],
        columnMetadata: [
            {
                dataset: 1,
                title: "Request Id",
                field: ["requestId"],
                expandable: false,
                component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "135px", "margin": "auto" }} variant="body2">
                    <RoundBtn>
                        {field}
                    </RoundBtn>
                </Typography></Tooltip>,
            },
            {
                dataset: 2,
                title: "File Type",
                field: ["fileType"],
                expandable: false,
                component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "135px", "margin": "auto" }} variant="body2">
                    {field}
                </Typography></Tooltip>,
            },
            {
                dataset: 3,
                title: "USER ID",
                field: ["createdBy"],
                expandable: false,
                component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "135px", "margin": "auto" }} variant="body2">
                    {field}
                </Typography></Tooltip>,
            },
            {
                dataset: 4,
                title: "CREATED DATE",
                field: ["createdAt"],
                expandable: false,
                component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "135px", "margin": "auto" }} variant="body2">
                    {moment(field).format("DD-MM-YYYY")}
                </Typography></Tooltip>,
            },
            {
                dataset: 5,
                title: "Status",
                field: ["status"],
                expandable: false,
                component: (field) => <Tooltip title={field}><Typography noWrap style={{ "width": "100px", "margin": "auto" }} variant="body2">
                    {field}
                </Typography></Tooltip>,
            },
            {
                order: 6,
                title: "process Details",
                field: ["processSteps"],
                expandable: true,
                hidden: true,
                component: (field) => <StepDetailsCom props={field} />
            }
        ],
        paginateByApi: {
            getPageData: async () => {
                let pageNumber = number + 1;
                let request = {
                    team: props.team,
                    pageNumber,
                    pageSize: 10
                }
                await props.getBillStatus(request, props.ocsUsageLoad);
            }
        },
        isLoading: fileStatusLoader
    }
    return <div className="requestFill">
        <BreadTable {...dataArgs} data={props.ocsUsageLoad} />
    </div>
}

const mapStateToProps = (state) => {
    return {
        environment: state.auth.selectedEnvValue,
        team: state.auth.team,
        dNumber: state.auth.user.username,
        ocsUsageLoad: state.ocsUsageLoad.available,
        totalElements: state.ocsUsageLoad.totalElements,
        number: state.ocsUsageLoad.number,
        fileStatusLoader: state.ocsUsageLoad.fileStatusLoader
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getBillStatus: (request, prevContent) => dispatch(getBillStatus(request, prevContent))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RequestFileStatus);
