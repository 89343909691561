import React, { useEffect, useState } from "react";
import HeaderContent from '../common/Header/HeaderContent';
import { connect } from "react-redux";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Button, makeStyles, Typography, Tooltip } from '@material-ui/core';
import RangeInput from "../cleanup/b2b/util/RangeInput";
import * as unmsConstants from "../cleanup/b2b/util/constants";
import './b2b.css'
import { FieldArray } from "redux-form";
import ReduxFormContainer from "../common/form/redux/ReduxFormContainer";
import { resetData, cleanUpB2B, fetchValidResources } from "../../actions/b2bCleanUpActions";
import { fetchSnrmResources, fetchB2BCleanupBatchStatus, fetchB2BDataCleanupResourceLevelStatus } from "../../actions/dataCleanUpActions";
import SendIcon from '@mui/icons-material/Send';
import swal from "sweetalert2";
import { displayLoading, displaySuccess, displayErrorNew, displayErrorAlert } from "../common/alerts/alerts";
import BreadTable from "@library/core/tables/BreadTable";
import failed from '../../assets/images/b2bCleanUpBatchStatus/B2BBATCHSTATUSFAILED.png';
import completed from '../../assets/images/b2bCleanUpBatchStatus/B2BBATCHSTATUSCOMPLETED.png';
import notStarted from '../../assets/images/b2bCleanUpBatchStatus/B2BBATCHSTATUSNOTSTARTED.png';
import moment from "moment";
import { DateRangePicker } from 'react-dates';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import { Skeleton, Avatar, Box } from '@mui/material';
import B2BBatchLevelData from "./B2BBatchLevelData";
import B2BDataCleanApplication from "./B2BDataCleanApplication";
import TilesFrame from "../Data/accordion-frames/replicator/DataTilesFrame";

const useStyles = makeStyles({
    trainingLabel: {
        marginRight: '10px',
        marginLeft: '10px',
        fontSize: '30px',
        color: '#7251bc'
    },
    selectedData: {
        color: '#244389',
        fontSize: '20px',
        marginTop: '20px'
    },
    headerLabel: {
        color: '#666f73',
        fontWeight: '400',
        opacity: '0.9',
        fontSize: '15px'
    },
    headerToggleGroup: {
        marginTop: '5px',
    },
    breadTableTitle: {
        color: '#455a64',
        fontWeight: '700',
        opacity: '0.9',
        fontSize: '18px'
    },
    batchId: {
        height: "45px",
        width: "55px",
        borderRadius: "50%",
        fontWeight: "600",
        textAlign: "center",
        verticalAlign: "middle",
        fontSize: "larger",
        display: "inline-block",
        lineHeight: "45px",
        color: "#3114bb",
        border: "0.3px solid #3114bb;"
    },
    iconColor: {
        color: "#3114bb"
    }
});

const B2BDataCleanUp = (props) => {
    const {history}=props;
    const classes = useStyles();
    const [cleanUpType, setCleanUpType] = useState('');
    const [resourceType, setResourceType] = useState('');
    const [rangeType, setRangeType] = useState('');
    const [showTabel, setshowTabel] = useState(false);
    const asyncBlurFields = ["ranges[].startRange", "ranges[].endRange"];
    const [notStartedBatch, setNotStartedBatch] = useState([]);
    const [completedBatch, setCompletedBatch] = useState([]);
    const [failedBatch, setFailedBatch] = useState([]);
    const [batchStatusList, setBatchStatusList] = useState([]);
    const [viewResourceDetails, setViewResourceDetails] = useState(false);
    const [batchId, setBatchId] = useState("");
    const [resourceStatusList, setResourceStatusList] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [focusedInput, setFocusedInput] = useState(null);
    const [allowNumberCleanUp, setAllowNumberCleanUp] = useState(false);
    const [cleanUpDataType, setCleanUpDataType] = useState(null);

    useEffect(() => {
        setCleanUpType("New Request");
        setResourceType("MSISDN");
        setRangeType("Number");
        props.resetData();
    }, []);

    useEffect(() => {
        if (props.b2bDataCleanUpBatchStatus && props.b2bDataCleanUpBatchStatus.batchStatusResponse && props.b2bDataCleanUpBatchStatus.batchStatusResponse.length > 0) {
            const batchStatusListContent = props.b2bDataCleanUpBatchStatus.batchStatusResponse;
            setBatchStatusList(batchStatusListContent);
            setNotStartedBatch(batchStatusListContent.filter((batch) => batch.status !== 'COMPLETED' && batch.status !== 'FAILED').sort(function (a, b) { return b.id - a.id }));
            setCompletedBatch(batchStatusListContent.filter((batch) => batch.status === 'COMPLETED').sort(function (a, b) { return b.id - a.id }));
            setFailedBatch(batchStatusListContent.filter((batch) => batch.status === 'FAILED').sort(function (a, b) { return b.id - a.id }));
        }
    }, [props.b2bDataCleanUpBatchStatus]);

    useEffect(() => {
        props.b2bDataCleanUpResourceStatus
        if (props.b2bDataCleanUpResourceStatus && props.b2bDataCleanUpResourceStatus.appStatusResponse && props.b2bDataCleanUpResourceStatus.appStatusResponse.length > 0) {
            setResourceStatusList(props.b2bDataCleanUpResourceStatus.appStatusResponse);
        }
    }, [props.b2bDataCleanUpResourceStatus]);

    const onChangeDateRange = (startDate, endDate) => {
        if (startDate) {
            setStartDate(startDate);
        }
        if (endDate) {
            setEndDate(endDate);
        }
    }

    const handleOnSearch = () => {
        displayLoading({ title: "Checking range...", bodyText: "Please standby whilst your given range is checked" });
        const { startRange, endRange } = props.updateRange[0];
        const resourceRangeData = {
            "resourceRange": {
                "startRange": startRange,
                "endRange": endRange
            },
            "teamId": props.teamId
        }
        props.fetchValidResources(resourceRangeData).then((res) => {
            if (res === true) {
                if (props.validResourcelist.length > 0) {
                    displaySuccess({ title: props.validMessage, text: props.validResourcelist }).then((rep) => {
                        if (rep.value === true) {
                            setAllowNumberCleanUp(true);
                        } else {
                            setAllowNumberCleanUp(false);
                        }
                    });
                } else {
                    setAllowNumberCleanUp(false);
                }
            } else {
                if (res === false) {
                    displayErrorNew(
                        {
                            title: "FAIL",
                            errorMsg: props.validMessage !== "" ? props.validMessage : "No valid resources found , COUNT : 0"
                        }
                    );
                } else {
                    displayErrorNew(
                        {
                            title: "FAIL",
                            errorMsg: res
                        }
                    );
                }
                setAllowNumberCleanUp(false);
            }
        })
    }

    const handleSubmitNumber = () => {
        displayLoading({ title: "Cleaning range...", bodyText: "Please standby whilst your given range is cleaned" });
        if (allowNumberCleanUp) {
            const reqBody = {
                "environment": props.selectedEnvValue,
                "range": startRange + "-" + endRange,
                "resourceType": resourceType
            }
            props.cleanUpB2B(reqBody).then((response) => {
                if (response.status != 200) {
                    displayErrorAlert({
                        title: "Error",
                        subTitle: "B2B postpaid " + resourceType + " cleanup failed",
                        errorMsg: response.data.responseDescription
                    });
                } else {
                    let message = "B2B postpaid " + resourceType + " cleanup process is initiated for batch id :" + response.data.batchId
                    swal.fire(
                        "Success",
                        message,
                        "success"
                    );
                }
            });
        }
    }

    const handleSubmit = () => {
        displayLoading({ title: "Cleaning range...", bodyText: "Please standby whilst your given range is cleaned" });
        const reqBody = {
            "environment": props.selectedEnvValue,
            "dateRange": moment(startDate).format('DD/MM/YYYY') + "-" + moment(endDate).format('DD/MM/YYYY'),
            "resourceType": resourceType
        }
        props.cleanUpB2B(reqBody).then((response) => {
            if (!response.status || response.status != 200) {
                displayErrorAlert({
                    title: "Error",
                    subTitle: "B2B postpaid " + resourceType + " cleanup failed",
                    errorMsg: response && response.data && response.data.responseDescription ? response.data.responseDescription : response ? response : "Cleanup Failed"
                });
            } else {
                let message = "B2B postpaid " + resourceType + " cleanup process is initiated for batch id :" + response.data.batchId
                swal.fire(
                    "Success",
                    message,
                    "success"
                );
            }
        });
    }

    const handleCleanUpType = (event, selectedCleanUpType) => {
        setCleanUpType(selectedCleanUpType);
        if (selectedCleanUpType === "New Request") {
            setResourceType("MSISDN")
        } else {
            setResourceType('')
        }
        props.resetData();
    };
    const handleResourceType = (event, selectedResourceType) => {
        setResourceType(selectedResourceType);
        props.resetData();
    };
    const handleRangeType = (event, selectedRangeType) => {
        setRangeType(selectedRangeType);
        props.resetData();
    };

    const submitb2bData = (e) => {
        const { startRange, endRange } = props.updateRange[0];
        let page = 0;
        props.fetchSnrmResources({ startRange, endRange, page }).then((response) => {
            swal.fire(
                "Success",
                `Clean up process is initiated for batch id :  ${props.batchID}`,
                "success",
                setshowTabel(true)
            );
        });
    }

    const closeResourceDetails = () => {
        setBatchId(batchId);
        setViewResourceDetails(false);
    }
    const handleView = (data) => {
        setBatchId(data);
        const batchId = data
        props.fetchB2BDataCleanupResourceLevelStatus(batchId)
        setViewResourceDetails(true)
    }
    const fetchB2BCleanupBatchStatus = () => {
        const teamId = props.teamId
        props.fetchB2BCleanupBatchStatus(teamId);
    }

    const completedStyle = {
        color: '#28da03'
    };

    const notStartedStyle = {
        color: '#ffe500'
    };

    const failedStyle = {
        color: '#da0303'
    };

    const dataArgs = {
        size: "large",
        isLoading: false,
        isExpandable: true,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 15, { label: 'All', value: -1 }],
        columnMetadata: [
            {
                dataset: 1,
                title: "Batch ID",
                field: ["id"],
                expandable: false,
                component: (field) => <Typography noWrap classes={{ root: classes.batchId }} style={{ "margin": "auto" }} variant="body2">
                    {field}
                </Typography>,
            },
            {
                dataset: 2,
                title: "Resource Type",
                field: ["resourceType"],
                expandable: false,
                component: (field) => <Typography noWrap style={{ "width": "135px", "margin": "auto" }} variant="body2">
                    {field}
                </Typography>,
            },
            {
                dataset: 3,
                title: "Resource Range",
                field: ["payloadType", "payload"],
                expandable: false,
                component: (field1, field2) => <div class="ml-1 d-inline" >
                    {field1 === "ARRAY" ?
                        <Typography noWrap style={{ "width": "200px", "margin": "auto" }} variant="body2">
                            {field2.replace('[', '').replace(']', '').split(",")[0]}-{field2.replace('[', '').replace(']', '').split(",")[field2.replace('[', '').replace(']', '').split(",").length - 1]}
                        </Typography> :
                        <Typography noWrap style={{ "width": "200px", "margin": "auto" }} variant="body2">
                            {field2}
                        </Typography>
                    }
                </div>,
            },
            {
                dataset: 4,
                title: "Requested By",
                field: ["lastModifiedBy"],
                expandable: false,
                component: (field) =>
                    <Typography noWrap style={{ "width": "135px", "margin": "auto" }} variant="body2">
                        <PersonIcon classes={{ root: classes.iconColor }} /> {field}
                    </Typography>,
            },
            {
                dataset: 5,
                title: "Requested At",
                field: ["lastModifiedAt"],
                expandable: false,
                component: (field) => <Typography noWrap style={{ "width": "135px", "margin": "auto" }} variant="body2">
                    <EventIcon classes={{ root: classes.iconColor }} />
                    {moment(field).format("DD-MM-YYYY")}
                </Typography>,
            },
            {
                dataset: 6,
                field: ["id"],
                expandable: true,
                hidden: true,
                component: (field) =>
                    <B2BBatchLevelData batchId={field} handleView={e => handleView(field)} />
            }
        ]

    }

    const cleanUpData = (type) => {
        setCleanUpDataType(type)
    }
    return (
        <div className="white pb-20">
            <HeaderContent
                title={`B2B Data Clean Up`}
                icon="cogs ops-icon"
                empty={true}
                loadingText="Loading B2B Data Clean Up..."></HeaderContent>
            <TilesFrame
                heading={"B2B Data Clean Up Type"}
                color="magenta"
                data={[
                    {
                        label: "Application",
                        value: 61,
                        length: 9,
                        title: "Application",
                        icon: "icon-aggregating-info"
                    },
                    {
                        label: "Orchestration",
                        value: 61,
                        length: 9,
                        title: "Orchestration",
                        icon: "icon-aggregating-info"
                    },
                ]}
                selected={cleanUpDataType}
                select={(data) => { cleanUpData(data.label) }}
                redirectUtility={true}
                push={history?.push}
            />

            {cleanUpDataType === "Orchestration" ?
                <div class="container b2bContainer">
                    <div class="row b2bRow">
                        <div class="col-4">
                            {cleanUpType !== "" &&
                                <Typography classes={{ root: classes.selectedData }}>Clean Up Type</Typography>
                            }
                        </div>
                        <div class="col-4">
                            {resourceType !== "" &&
                                <Typography classes={{ root: classes.selectedData }}>Resource Type</Typography>
                            }
                        </div>
                        <div class="col-4">
                            {resourceType !== "" &&
                                <Typography classes={{ root: classes.selectedData }}>Range Type</Typography>
                            }
                        </div>
                    </div>
                    <div class="row b2bRow">
                        <div class="col-4 mt-3">
                            <Typography classes={{ root: classes.headerLabel }}>Please select the type of request
                            </Typography>
                            <div className="toggleButtonPanel">
                                <ToggleButtonGroup
                                    value={cleanUpType}
                                    exclusive
                                    onChange={handleCleanUpType}
                                    aria-label="cleanUpType"
                                    classes={{ root: classes.headerToggleGroup }}
                                    className="headerToggleButtonGroup">
                                    <ToggleButton value="New Request" aria-label="left aligned">
                                        <Typography classes={{ root: classes.headerLabel }}>New Request </Typography>
                                    </ToggleButton>
                                    <ToggleButton onClick={fetchB2BCleanupBatchStatus} value="My Request" aria-label="centered">
                                        <Typography classes={{ root: classes.headerLabel }}>My Request </Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </div>
                        {cleanUpType == "New Request" &&
                            <div class="col-4 mt-3">
                                <Typography classes={{ root: classes.headerLabel }}>Please select the resource type
                                </Typography>
                                <div className="toggleButtonPanel">
                                    <ToggleButtonGroup
                                        value={resourceType}
                                        exclusive
                                        onChange={handleResourceType}
                                        aria-label="resourceTypes"
                                        classes={{ root: classes.headerToggleGroup }}
                                        className="headerToggleButtonGroup"
                                    >
                                        <ToggleButton value="MSISDN" aria-label="left aligned">
                                            <Typography classes={{ root: classes.headerLabel }}>MSISDN
                                            </Typography>
                                        </ToggleButton>
                                        <ToggleButton value="FNN" aria-label="centered">
                                            <Typography classes={{ root: classes.headerLabel }}>FNN
                                            </Typography>
                                        </ToggleButton>

                                    </ToggleButtonGroup>

                                </div>

                            </div>}
                        {cleanUpType == "New Request" &&
                            <div class="col-4 mt-3">
                                <Typography classes={{ root: classes.headerLabel }}>Please select the range type</Typography>
                                <div className="toggleButtonPanel">
                                    <ToggleButtonGroup
                                        value={rangeType}
                                        exclusive
                                        onChange={handleRangeType}
                                        aria-label="rangeType"
                                        classes={{ root: classes.headerToggleGroup }}
                                        className="headerToggleButtonGroup">
                                        <ToggleButton value="Number" aria-label="left aligned">
                                            <Typography classes={{ root: classes.headerLabel }}>Number</Typography>
                                        </ToggleButton>
                                        <ToggleButton value="Date" aria-label="centered">
                                            <Typography classes={{ root: classes.headerLabel }}>Date</Typography>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            </div>}
                    </div>

                    {
                        resourceType !== "" &&
                        <div class="mt-5 ml-n3">
                            <ReduxFormContainer
                                initialValues={unmsConstants.INITIAL_VALUE}
                                formName={unmsConstants.B2B_CLEANUP_FORM}
                                asyncBlurFields={asyncBlurFields}
                                b2bCleanupResourceType={resourceType}
                                propsDepth={4}
                                editType={"Submit"}
                                recordLimit={50}
                            >
                                {
                                    rangeType === "Date" ?
                                        <FieldArray
                                            assignProps
                                            name="ranges"
                                            handlePrimary={handleSubmit}
                                            primaryButton={"Submit"}
                                            serviceIdField={{}}
                                            component={(props) => (
                                                <div id="unms-range">
                                                    <div className="row" id="unms-range-header">
                                                        <div className="col-3">
                                                            <span>Date Range</span>
                                                        </div>
                                                    </div>
                                                    <DateRangePicker
                                                        startDatePlaceholderText={"start date"}
                                                        endDatePlaceholderText={"end date"}
                                                        startDate={startDate || undefined}
                                                        endDate={endDate || undefined}
                                                        startDateId="startId"
                                                        endDateId="endId"
                                                        focusedInput={focusedInput}
                                                        onDatesChange={({ startDate, endDate }) => onChangeDateRange(startDate, endDate)}
                                                        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                                        isOutsideRange={(day) => day.isAfter(moment()) || day.isBefore(moment().subtract(6, 'months'))} // Allows selection of date range in the last 6 months only
                                                        keepOpenOnDateSelect={false}
                                                        displayFormat="DD-MM-YYYY"
                                                        withPortal={false}
                                                    />
                                                    <div className="unms-button-area" id="unms-button-add">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary unms-button"
                                                            onClick={props.handlePrimary}
                                                            endIcon={<SendIcon />}
                                                            disabled={!startDate || !endDate}
                                                        >
                                                            {props.primaryButton}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        />
                                        :
                                        <FieldArray
                                            assignProps
                                            name="ranges"
                                            handlePrimary={allowNumberCleanUp ? handleSubmitNumber : handleOnSearch}
                                            primaryButton={allowNumberCleanUp ? "Submit" : "Search"}
                                            serviceIdField={{}}
                                            component={RangeInput}
                                            searchWithBtn={true}
                                        />
                                }
                            </ReduxFormContainer>
                        </div>
                    }
                    {props.batchID !== "" &&
                        <Button variant="contained" className="b2bSubmitButton ml-2 mt-3"
                            onClick={e => submitb2bData()}
                            endIcon={<SendIcon />}>Submit</Button>
                    }
                    {
                        props.cleanUpBatchStatusLoading ?
                            b2bBatchStatusSkeleton() :
                            <div>
                                {
                                    cleanUpType === "My Request" && batchStatusList && batchStatusList.length > 0 &&
                                    <div>
                                        &nbsp;
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-1 mt-n3">
                                                    <img style={{ width: '100%' }} src={completed} />
                                                </div>
                                                <div class="col-4 mt-4">
                                                    <Typography style={completedStyle} classes={{ root: classes.breadTableTitle }}>Batch Status -  Completed</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <BreadTable  {...dataArgs} data={completedBatch}></BreadTable>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-1 mt-n3">
                                                    <img width='100%' src={notStarted} />
                                                </div>
                                                <div class="col-4 mt-4">
                                                    <Typography style={notStartedStyle} classes={{ root: classes.breadTableTitle }}>Batch Status - Not Started </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <BreadTable  {...dataArgs} data={notStartedBatch}></BreadTable>
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-1 mt-n3">
                                                    <img style={{ width: '100%' }} src={failed} />
                                                </div>
                                                <div class="col-4 mt-4">
                                                    <Typography style={failedStyle} classes={{ root: classes.breadTableTitle }}>Batch Status - Failed </Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <BreadTable  {...dataArgs} data={failedBatch}></BreadTable>
                                    </div>

                                }
                            </div>
                    }


                </div> : cleanUpDataType === "Application" ? <B2BDataCleanApplication /> : null}


        </div>
    )

    function b2bBatchStatusSkeleton() {
        return <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ margin: 1 }}>
                    <Skeleton variant="circular">
                        <Avatar />
                    </Skeleton>
                </Box>
                <Box width="100%">
                    <Skeleton width="30%">
                        <Typography>.</Typography>
                    </Skeleton>
                </Box>
            </Box>
            <Skeleton variant="rect" height="200px" width="100%">
                <div style={{ paddingTop: '57%' }} />
            </Skeleton>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ margin: 1 }}>
                    <Skeleton variant="circular">
                        <Avatar />
                    </Skeleton>
                </Box>
                <Box width="100%">
                    <Skeleton width="30%">
                        <Typography>.</Typography>
                    </Skeleton>
                </Box>
            </Box>
            <Skeleton variant="rect" height="200px" width="100%">
                <div style={{ paddingTop: '57%' }} />
            </Skeleton>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ margin: 1 }}>
                    <Skeleton variant="circular">
                        <Avatar />
                    </Skeleton>
                </Box>
                <Box width="100%">
                    <Skeleton width="30%">
                        <Typography>.</Typography>
                    </Skeleton>
                </Box>
            </Box>
            <Skeleton variant="rect" height="200px" width="100%">
                <div style={{ paddingTop: '57%' }} />
            </Skeleton>
        </div>;
    }
}


const mapStateToProps = (state) => {
    return {
        cleanUpTypes: state.b2bDataCleanUp.cleanUpTypes,
        updateRange: state.form && state.form[unmsConstants.B2B_CLEANUP_FORM] && state.form[unmsConstants.B2B_CLEANUP_FORM].values && state.form[unmsConstants.B2B_CLEANUP_FORM].values.ranges || [],
        selectedEnvValue: state.auth.selectedEnvValue,
        batchID: state.b2bDataCleanUp.batchID,
        teamId: state.auth.team,
        b2bDataCleanUpBatchStatus: state.cleanup.b2bDataCleanUpBatchStatus,
        cleanUpBatchStatusLoading: state.cleanup.cleanUpBatchStatusLoading,
        b2bDataCleanUpResourceStatus: state.cleanup.b2bDataCleanUpResourceStatus,
        cleanUpBatchStatusLoading: state.cleanup.cleanUpBatchStatusLoading,
        validResourcelist: state.b2bDataCleanUp.validResourcelist,
        validMessage: state.b2bDataCleanUp.validMessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        cleanUpB2B: (requestBody) => dispatch(cleanUpB2B(requestBody)),
        resetData: () => dispatch(resetData()),
        fetchSnrmResources: (pageNum) => dispatch(fetchSnrmResources(pageNum)),
        fetchB2BCleanupBatchStatus: (teamId) => dispatch(fetchB2BCleanupBatchStatus(teamId)),
        fetchB2BDataCleanupResourceLevelStatus: (batchId) => dispatch(fetchB2BDataCleanupResourceLevelStatus(batchId)),
        fetchValidResources: (resourceRangeData) => dispatch(fetchValidResources(resourceRangeData))

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(B2BDataCleanUp);
