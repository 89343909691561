import React from "react";
import _ from "lodash";
import { Field } from "redux-form";
import { renderField } from "../../../common/fields/fields";
import { cleanupRangeValidator } from "../../../common/form/fieldValidators";
import SendIcon from '@mui/icons-material/Send';

const SubmitTextChanges = ({ primaryButton }) => {
  if (primaryButton === "OCS" || primaryButton === "CMP") {
    return 'Submit'
  } return 'Search'
}

class RangeInput extends React.Component {

  state = {
    autoGeneratedRows: [],
    rangeInvalid: true,
  };
  handleRowChange = (numRows, newNumRows, fields) => {
    let diff = newNumRows - numRows;
    if (diff > 0) {
      for (let rowIdx = 0; rowIdx < diff; rowIdx++)
        fields.push({ adborId: null, fnn: null, inflightOrder: null });
    } else if (diff < 0) {
      diff *= -1;

      const limit = fields.length - diff - 1;
      for (let rowIdx = 0; rowIdx < diff; rowIdx++) fields.pop();
      this.setState(preState => {
        return {
          ...preState,
          autoGeneratedRows: preState.autoGeneratedRows.filter(
            data => data <= limit
          )
        };
      });
    }
  };
  handleAddColumn = (fields, index, onRemove) => {
    if (index != undefined) {
      fields.splice(index, 1)
      if (onRemove) {
        onRemove(index);
      }
    } else {
      fields.push({ startRange: null, endRange: null });
    }
  }

  validateRange = (value, allValues, props, name) => {
    let resp = null;
    if (this.props.rangeValidator) {
      resp = this.props.rangeValidator(value, allValues, props, name);
    } else {
      resp = cleanupRangeValidator(value, allValues, props, name);
    }
    (allValues.ranges[0].startRange !== '' && allValues.ranges[0].endRange !== '' && resp === undefined) ?
      this.setState(prevState => ({...prevState, rangeInvalid: false})) :
      this.setState(prevState => ({...prevState, rangeInvalid: true}));
    return resp;
  }

  render() {
    const { fields, primaryButton, secondaryButton, handlePrimary, disablePrevRows, onRemove, searchWithBtn } = this.props;
    return (
      <div id="unms-range">
        <div className="row" id="unms-range-header">
          <div className="col-3">
            <span>Start Range</span>
          </div>
          <div className="col-3">
            <span>End Range</span>
          </div>
        </div>
        {fields.map((range, index) => {
          return (
            <div key={index} className="row">
              <div className="col-3 cleanup-text-margin">
                <Field
                  name={`ranges[${index}].startRange`}
                  tabIndex={index}
                  component={renderField}
                  validate={this.validateRange}
                  invalid={this.props.errorMessage}
                  type={"text"}
                  showValidMsg={false}
                  touchOnChange={true}
                  autoComplete="off"
                  disabled={disablePrevRows ? (fields.length === 0 || index+1 < fields.length) : false }
                />
              </div>
              <div className="col-3 cleanup-text-margin">
                <Field
                  name={`ranges[${index}].endRange`}
                  validate={this.validateRange}
                  tabIndex={index}
                  component={renderField}
                  touched={true}
                  invalid={this.props.errorMessage}
                  type={"text"}
                  showValidMsg={false}
                  autoComplete="off"
                  disabled={disablePrevRows ? (fields.length === 0 || index+1 < fields.length) : false }
                />
              </div>
              {secondaryButton &&
                <div className="unms-button-area">
                  {fields.length !== 1 && index !== fields.length-1
                    && <button
                      type="button"
                      className="btn btn-warning unms-button"
                      onClick={() => this.handleAddColumn(fields, index, onRemove)}>
                      {secondaryButton}
                    </button>}</div>}
              {primaryButton
                && <div className="unms-button-area" id="unms-button-add">
                  {index === fields.length - 1
                    && <button
                      type="button"
                      className="btn btn-primary unms-button"
                      onClick={handlePrimary ? ()=>handlePrimary(primaryButton) : () => this.handleAddColumn(fields, undefined, onRemove)}
                      disabled={this.state.rangeInvalid}
                      endIcon={<SendIcon/>}
                    >
                      {searchWithBtn ? <SubmitTextChanges primaryButton={primaryButton} /> : primaryButton}
                    </button>}</div>}

            </div>
          );
        })}

      </div>
    );
  }
}


export default RangeInput;
