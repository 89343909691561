import React, { Component } from 'react'
export default (props) => {
    return (
        <div>
            <span className="dataview-header">
                <p className="sub-leader font-weight-light">Overview</p>
            </span>
            <div class="content">
                <div className="tiles-grid base-green">
                    <div
                        data-size={'medium'}
                        data-role="tile"
                        className={`bg-green theme-gradient-secondary-primary`}
                        onClick={props.checkout}>
                        {/* <span className={`td-icon-md icon-loading-2 icon`}></span> */}
                        {props.loading && <span class="td-icon-md icon mif-spinner4 ani-spin"></span>}
                        {!props.loading && props.errMsg === "" && <span className={`td-icon-md icon-tick icon`}></span>}
                        {!props.loading && props.errMsg != "" && <span className={`td-icon-md mif-refresh icon`}></span>}
                        <span className="branding-bar">{props.loading ? 'Loading...' : 'Checkout'}</span>
                    </div>
                </div>
            </div>

        </div>
    )
}