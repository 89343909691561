import React from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableRow } from "@material-ui/core";
import TableVerticalCell from "@library/core/tables/TableVerticalCell";

const ExpandInfoTable = (props) => {
  const { columns, row, hideEmptyExpandOptions } = props;

  return (
    <Table size="small">
        <TableBody>
            {(hideEmptyExpandOptions ? columns.filter(v => row[v.field]) : columns).map((column, index) => {
            return (
                <TableRow key={index}>
                  {column.expandable && (!column.hidden
                      ? <TableVerticalCell header={column.title} body={[row[column.field]]}/>
                      : column.component(
                        ...column.field.map((field) => row[field]),
                      ))
                    }
      
                </TableRow>
            );
            })}
        </TableBody>
    </Table>
  );
};

ExpandInfoTable.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  row: PropTypes.object.isRequired,
  hideEmptyExpandOptions: PropTypes.bool,
};

export default ExpandInfoTable;
