import React from 'react';

import {Pie} from 'react-chartjs-2';

export default({methods, backgroundColors}) => {
    const labels = Object
        .keys(methods).map(label => label.replace("METHOD_", ""));
    const data = Object
        .values(methods);

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Total Checkouts',
                data,
                backgroundColor: backgroundColors,
                datalabels: {
                    display: function(context) {
                    //    return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
                        return false;
                    }
                }
            }
        ]
    }
    return (<Pie data={chartData}/>);
}