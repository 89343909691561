import React from "react";

import {
  Box,
  Grid,
  IconButton,
  Button,
  Popover,
  TextField,
  MenuItem,
  makeStyles,
  Typography,
  Tooltip,
} from "@material-ui/core";

import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  closeBtn: {
    position: "absolute", 
    right: "4px", 
    top: "4px"
  },
  header: {
    fontWeight: 500, 
    marginLeft: "7px"
  }
}));

const FilterPopover = (props) => {
  const {
    fields,
    updateFilter,
    getFilterValue,
    clearFilters,
    disabled = false,
  } = props;

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={disabled ? "Filter Disabled" : "Filter"}>
        <span>
          <IconButton disabled={disabled} onClick={handleClick}>
            <FilterListOutlinedIcon color="primary"/>
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
      >
        <Box flexGrow={1} width="350px" padding="24px 24px 36px 24px">
          <IconButton onClick={handleClose} className={classes.closeBtn}>
            <CloseOutlinedIcon />
          </IconButton>
          <Grid container spacing={2} direction="row" alignItems="center">
            <Grid item>
              <Typography className={classes.header} variant="body2">FILTERS</Typography>
            </Grid>
            <Grid item>
              <Button onClick={clearFilters} color="primary" variant="text">RESET</Button>
            </Grid>
          </Grid>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid container spacing={2}>
              {
                fields.map((field) => {
                  return <Grid item xs={6} key={field.name}>
                    <TextField
                      select
                      label={field.name}
                      name={field.name}
                      value={getFilterValue(field.name)}
                      onChange={(event)=>updateFilter(event.target.value, field.name)}
                      defaultValue={field.options[0].label}
                      fullWidth
                    >
                      {
                        field.options.map((option) => {
                          return <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                        })
                      }
                    </TextField>
                  </Grid>
                })
              }
            </Grid>
          </form>
        </Box>
      </Popover>
    </>
  );
};

export default FilterPopover;
