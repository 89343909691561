export const emailValid = (emailId) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(emailId);
};

export const nameValid = (name) => {
  return /^[a-zA-Z.,-_ ]*$/.test(name);
};

export const numericValid = (number) => {
  return /^[0-9\b]+$/.test(number);
}