import React from 'react';
import { Button } from "reactstrap";
export default function SubmitAction ({ handleOnSubmit, selectedFilters}) {
    const { cidn, env, productData, dealerPremiseCode ,action} = selectedFilters;
    return (
        <div className="submitS2P">
            <Button
                color="success"
                type="submit"
                onClick={() =>
                    handleOnSubmit( cidn, env, productData, dealerPremiseCode,action )
                }
                size="lg"
                style={{ marginTop: "30px" }}
            >
                Submit
            </Button>
        </div>
    )
}