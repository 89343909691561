import React from "react";
import { Button } from "reactstrap";
import _ from "lodash";
import { connect } from "react-redux";
import { Field, change } from "redux-form";
import { generateServiceId } from "../../actions/replicatorActions";
import DropdownComponent from "./DropdownComponent";
import { renderField } from "../common/fields/fields";
import {
  adborIdValidator,
  fnnValidatorForNewReplicator,
  accountNumberValidator
} from "../common/form/fieldValidators";
import "./Product.css";
import "./Data.css";

class AdborComponent extends React.Component {
  state = {
    autoGeneratedRows: []
  };
  handleRowChange = (numRows, newNumRows, fields) => {
    let diff = newNumRows - numRows;
    if (diff > 0) {
      for (let i = 0; i < diff; i++)
        fields.push({ adborId: null, fnn: null, optionalAssociation: null, address: null, inflightOrder: null });
    } else if (diff < 0) {
      diff *= -1;

      const limit = fields.length - diff - 1;
      for (let i = 0; i < diff; i++) fields.pop();
      this.setState(preState => {
        return {
          ...preState,
          autoGeneratedRows: preState.autoGeneratedRows.filter(
            data => data <= limit
          )
        };
      });
    }
  };

  render() {
    const { fields, passdownprops, productType, serviceIdField, quantity } = this.props;
    const assocOptions = [];
    const assocRoleOptions = [
      { label: "Empty", value: ""},
      { label: "prime", value: "prime" },
      { label: "auxiliary", value: "auxiliary"},
      { label: "gdn", value: "gdn"}
    ]
    if (productType === 'isdn2') {
      assocOptions.push(...[
        { label: "Empty", value: "" },
        { label: "Hunt Group", value: "Hunt Group" },
        { label: "Multi Number", value: "Multi Number" },
        { label: "Line Hunt", value: "Line Hunt"}
      ]);
    } else if (productType === 'isdn2did') {
      assocOptions.push({ label: "In Dial", value: "In Dial" });
    } else if (productType === 'isdn30') {
      assocOptions.push({ label: "Empty", value: "" });
    }
    return (
      <div>
        <div className="row" id="product-input-header">
          <div className="col col1">
            {" "}
            <span className="product-header">Product:</span>
            <span>{productType.toUpperCase()}</span>
          </div>
          <div className="col col2">
            <span className="product-header">Quantity: </span>
            <span id="qty-dropdown">
              <DropdownComponent
              options = {quantity}
                selectInput={qty =>
                  this.handleRowChange(fields.length, qty, fields)
                }
                length={fields.length}
              />
            </span>
          </div>
        </div>
        <div id="s2p-product-modal-input-head" className="row">
          <table className="table table-sm adbor-fnn-table">
            <thead className="thead-light adbor-fnn-head">
              <tr>
                <th className="verticle-middle">
                  <span>Adbor ID</span>
                  <span>
                    <Button
                      color="link"
                      onClick={() =>
                        fields.forEach((d, i) =>
                          passdownprops.dispatch(
                            change(
                              passdownprops.formName,
                              `adborFnns[${i}].adborId`,
                              fields.get(0).adborId
                            )
                          )
                        )
                      }
                    >
                      <i className="fa fa-plus fa-sm" aria-hidden="true"></i>
                    </Button>
                  </span>
                </th>
                <th className="verticle-middle">
                  <span>Service ID</span>                  
                </th>
                <th className="verticle-middle">
                  <span>Association Type</span>
                </th>
                <th  className="verticle-middle">
                  <span>Association Role</span>
                </th>
                <th className="verticle-middle">
                  <span>Account Number</span>
                </th>
                <th className="verticle-middle">
                  <span>Address</span>
                </th>
                <th className="verticle-middle" >
                  <span >Inflight</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {fields.map((adborFnn, index) => {
                let adborId = 2 * index + 1,
                  fnn = adborId + 1,
                  optionalAssociation = fnn + 1,
                  associationRole = optionalAssociation + 1,
                  accountNumber = associationRole + 1,
                  address = accountNumber + 1,
                  inflightOrder = address + 1;
                const placeholder =
                  serviceIdField.rowNumber === index && serviceIdField.pending
                    ? "loading..."
                    : "";
                return (
                  <tr key={index}>
                    <td style={{ padding: "10px 5px 0px 0px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].adborId`}
                        tabIndex={adborId}
                        disabled={passdownprops.asyncValidating || serviceIdField.pending}
                        autoFocus={adborId === 0}
                        component={renderField}
                        customDiv="adborFnnTableInputDiv"
                        validate={adborIdValidator}
                        invalid={this.props.errorMessage}
                        type={"textWithCustomStyles"}
                        showValidMsg={false}
                        touchOnChange={true}
                        autoComplete="off"
                      />
                    </td>
                    <td style={{ padding: "10px 0px 0px 5px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].fnn`}
                        customDiv="adborFnnTableInputDiv"
                        customStyleClassName="generate-serviceId-input"
                        tabIndex={fnn}
                        disabled={
                          passdownprops.asyncValidating ||
                          this.state.autoGeneratedRows.includes(index) ||
                          (serviceIdField.pending)
                        }
                        component={renderField}
                        validate={fnnValidatorForNewReplicator}
                        touched={true}
                        invalid={this.props.errorMessage}
                        type={"textWithCustomStyles"}
                        showValidMsg={false}
                        placeholder={placeholder}
                        autoComplete="off"
                      />
                    </td>
                    <td style={{ padding: "10px 0px 0px 5px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].optionalAssociation`}
                        component={renderField}
                        tabIndex={optionalAssociation}
                        type="selectWithCustomHeight"
                        options={assocOptions}
                        defaultValue={assocOptions[0]}
                        index={index}
                      />
                    </td>
                    <td style={{ padding: "10px 0px 0px 5px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].associationRole`}
                        component={renderField}
                        tabIndex={associationRole}
                        type="selectWithCustomHeight"
                        options={assocRoleOptions}
                        defaultValue={assocRoleOptions[0]}
                        index={index}
                      />
                    </td>
                    <td style={{ padding: "10px 0px 0px 10px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].accountNumber`}
                        component={renderField}
                        customDiv="adborFnnTableInputDiv"
                        tabIndex={accountNumber}
                        type="textWithCustomStyles"
                        index={index}
                        validate={accountNumberValidator}
                        invalid={this.props.errorMessage}
                        showValidMsg={false}
                        touchOnChange={true}
                        autoComplete="off"
                      />
                    </td>
                    <td style={{ padding: "10px 0px 0px 5px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].address`}
                        tabIndex={address}
                        component={renderField}
                        customDiv="adborFnnTableInputDiv"
                        invalid={this.props.errorMessage}
                        type={"textWithCustomStyles"}
                        showValidMsg={false}
                        touchOnChange={true}
                        autoComplete="off"
                      />
                    </td>
                    <td style={{ padding: "13px 0px 0px 0px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].inflightOrder`}
                        component={renderField}
                        tabIndex={inflightOrder}
                        touched={true}
                        invalid={this.props.errorMessage}
                        type={"checkbox"}
                        index={index}
                        showValidMsg={false}
                        showIcon={fields.get(index).inflightOrder}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    quantity: state.replicatorData.options.quantity,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    generateServiceId: (qty, index) => dispatch(generateServiceId(qty, index))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdborComponent);
