import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";




const useStyles = makeStyles({
    stateLabel: {
        backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
        color: "#ffffff  !important",
        marginBottom: "20px !important",
        marginLeft: "20px !important"
    },
    stateSelectedLabel: {
        marginBottom: "20px !important",
        backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
        color: "#ffffff !important",
        marginLeft: "20px !important"
    },
    productHeaderLabel: {
        fontSize: "24px !important",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingLeft: "20px !important",
    },
    yesSpecification: {
        width: "650px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    }

});


const StatelessNBNProduct = (props) => {


    const classes = useStyles();

    const [nbnProducts] = useState(["Normal", "Transition", "PORT-IN", "4G", "5G", "UNITI" ,"LEO SATELLITE", "Others"]);
    const [selectedNBNProduct, setSelectedNBNProduct] = useState("");

    const [nbnNormalTypes] = useState(["FNN", "Address"]);
    const [selectedNBNNormalType, setSelectedNBNNormalType] = useState("");
    const [nbnOtherTypes] = useState(["FNN", "Address"]);
    const [selectedNBNOtherType, setSelectedNBNOtherType] = useState("");
    const [nbnNormalFNNData, setNbnNormalFNNData] = useState("");
    const [nbnNormalAddressData, setNbnNormalAddressData] = useState("");

    const [nbnTransitionFNNData, setNbnTransitionFNNData] = useState("");

    const [selectedNBNClass, setSelectedNBNClass] = useState("");

    const [nbnTransitionAddressData, setNbnTransitionAddressData] = useState("");
    const [nbnPortFNNData, setNbnPortFNNData] = useState("");
    const [nbnPortAddressData, setNbnPortAddressData] = useState("");
    const [nbn4GFNNData, setNbn4GFNNData] = useState("");
    const [nbn4GAddressData, setNbn4GAddressData] = useState("");
    const [labelText, setLableText] = useState("")
    const [nbnClasses] = React.useState(["4-FW","5-FW","6-FW"]);
    const [unitNbnClasses] = React.useState(["92-FTTP","93-FTTP"]);
    
    const clearNBNData = () => {
        setNbnNormalFNNData("");
        setSelectedNBNClass("");

    }

    const clearSubProducts = () => {
        setSelectedNBNNormalType("");
        setSelectedNBNOtherType("");
        setNbnNormalFNNData("");
        setNbnNormalAddressData("");
        setSelectedNBNClass("");
    }

    useEffect(() => {
        clearSubProducts();
    }, [props.clearNBN]);

    const setSpecReqData = (key, value) => {
        setLableText(key)
        props.setSpecificRequirement({ [key]: value }, "");
    }
    return (
        <>
            {/* Product */}
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the NBN Product</Typography>
                    {nbnProducts.map((nbn, index) => {
                        return (
                            <Button variant="contained" classes={nbn !== selectedNBNProduct ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                sx={{ minWidth: 150, minHeight: 150 }} value={nbn} onClick={e => {
                                    let value = e.target.value;
                                     setSelectedNBNProduct(e.target.value); 
                                     clearSubProducts(); 
                                     let key = `CUSTOM VACANT NBN ${value.toUpperCase()} DATA`;
                                     setSpecReqData(key, "");
                                     }}>
                                {nbn}
                            </Button>
                        )
                    })}</Grid>
            </Grid>
            {/*NBN Products*/}
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    {selectedNBNProduct === "4G" || selectedNBNProduct === "5G" ? <Autocomplete
                                    disablePortal
                                    id="nbn-normal-address-nbn-class"
                                    options={nbnClasses}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) => option}
                                    helperText="select the NBN class"
                                    value={selectedNBNClass}
                                    onChange={(e, value) => {
                                        setSelectedNBNClass(value);
                                        let key = `CUSTOM VACANT NBN ${selectedNBNProduct} ${value} DATA`;
                                        setSpecReqData(key, e.target.value);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="NBN class"
                                        classes={{ root: classes.yesSpecification }} />}
                                />
                     : selectedNBNProduct === "UNITI" ? <Autocomplete
                     disablePortal
                     id="nbn-normal-address-nbn-class"
                     options={unitNbnClasses}
                     sx={{ width: 300 }}
                     getOptionLabel={(option) => option}
                     helperText="select the UNITI class"
                     value={selectedNBNClass}
                     onChange={(e, value) => {
                         setSelectedNBNClass(value);
                         let key = `CUSTOM VACANT NBN ${selectedNBNProduct} ${value} DATA`;
                         setSpecReqData(key, e.target.value);
                     }}
                     renderInput={(params) => <TextField {...params} label="UNITI class"
                         classes={{ root: classes.yesSpecification }} />}
                 /> : selectedNBNProduct === "LEO SATELLITE" || selectedNBNProduct === "Others" ? <TextField
                 id="NBN-NORMAL-FNN"
                 multiline
                 rows={2}
                 helperText="Enter the specific requirement"
                 label={labelText}
                 classes={{ root: classes.yesSpecification }}
                 value={nbnNormalFNNData}
                 onChange={e => {
                    let value = e.target.value;
                     setNbnNormalFNNData(e.target.value);
                     let key = `CUSTOM VACANT NBN ${selectedNBNProduct.toUpperCase()} DATA`;
                     setSpecReqData(key, e.target.value);
                 }}
             /> : null}
                    {selectedNBNProduct === "Normal" ?
                        <>
                            <Typography classes={{ root: classes.productHeaderLabel }}>Please select the NBN Sub Product- {selectedNBNProduct}</Typography>
                            {nbnNormalTypes.map((nbnNormal, index) => {
                                return (
                                    <Button variant="contained" classes={nbnNormal !== selectedNBNNormalType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                        sx={{ minWidth: 150, minHeight: 150 }} value={nbnNormal}
                                        onClick={e => {
                                            setSelectedNBNNormalType(e.target.value);
                                            clearNBNData();
                                            let key = "CUSTOM VACANT NBN NORMAL " + e.target.value + " DATA";
                                            setSpecReqData(key, "");
                                        }}>
                                        {nbnNormal}
                                    </Button>
                                )
                            })}</> :
                        <>
                            {((selectedNBNProduct === "Transition") ||
                                (selectedNBNProduct === "PORT-IN") || (selectedNBNProduct === "4GFW")) ?
                                <>
                                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the NBN- {selectedNBNProduct} Product</Typography>
                                    {nbnOtherTypes.map((nbnOther, index) => {
                                        return (
                                            <Button variant="contained" classes={nbnOther !== selectedNBNOtherType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                                sx={{ minWidth: 150, minHeight: 150 }} value={nbnOther}
                                                onClick={e => {
                                                    setSelectedNBNOtherType(e.target.value);
                                                    let key = "CUSTOM VACANT NBN " + selectedNBNProduct.toUpperCase(); +" " + e.target.value + " DATA";
                                                    setSpecReqData(key, "");
                                                }}>
                                                {nbnOther}
                                            </Button>)
                                    })}
                                </> : <></>}

                        </>}
                </Grid>
            </Grid>

            {selectedNBNClass && (selectedNBNProduct === "UNITI" || selectedNBNProduct === "4G" || selectedNBNProduct === "5G") ?
                <Grid container spacing={3}>
                     <Grid item xs={1} />
                        <Grid item xs={10}>
                        <TextField
                            id="NBN-NORMAL-FNN"
                            multiline
                            rows={2}
                            helperText="Enter the specific requirement"
                            label={labelText}
                            classes={{ root: classes.yesSpecification }}
                            value={nbnNormalFNNData}
                            onChange={e => {
                                setNbnNormalFNNData(e.target.value);
                                let key = `CUSTOM VACANT NBN ${selectedNBNProduct} ${selectedNBNClass.toUpperCase()} DATA`;
                                setSpecReqData(key, e.target.value);
                            }}
                        />
                        </Grid>
                </Grid>
                : null}

            {/* NBN -NORMAL */}
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    {selectedNBNNormalType === "FNN" ? <>
                        <TextField
                            id="NBN-NORMAL-FNN"
                            multiline
                            rows={2}
                            helperText="Enter the specific requirement"
                            label={labelText}
                            classes={{ root: classes.yesSpecification }}
                            value={nbnNormalFNNData}
                            onChange={e => {
                                setNbnNormalFNNData(e.target.value);
                                let key = "CUSTOM VACANT NBN NORMAL FNN DATA";
                                setSpecReqData(key, e.target.value);
                            }}
                        />
                    </> :
                        <>
                            {selectedNBNNormalType === "Address" ? <>
                                <Autocomplete
                                    disablePortal
                                    id="nbn-normal-address-nbn-class"
                                    options={props.nbnClasses}
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) => option}
                                    helperText="select the NBN class"
                                    value={selectedNBNClass}
                                    onChange={(event, value) => {
                                        setSelectedNBNClass(value);
                                        let key = "CUSTOM VACANT NBN NORMAL ADDRESS "+value+" DATA";
                                        setSpecReqData(key, "");
                                    }}
                                    renderInput={(params) => <TextField {...params} label="NBN class"
                                        classes={{ root: classes.yesSpecification }} />}
                                />
                                {(selectedNBNClass !== "" && selectedNBNClass !== "None" && selectedNBNClass !== null) ? <TextField
                                    id="NBN-NORMAL-ADDRESS-DATA"
                                    multiline
                                    rows={2}
                                    label={labelText}
                                    helperText="Enter the specific requirement"
                                    classes={{ root: classes.yesSpecification }}
                                    value={nbnNormalAddressData}
                                    onChange={e => {
                                        setNbnNormalAddressData(e.target.value);
                                        let key = "CUSTOM VACANT NBN NORMAL ADDRESS " + selectedNBNClass.toUpperCase(); +" DATA";
                                        setSpecReqData(key, e.target.value);
                                    }}
                                /> : <></>}
                            </> : <></>}
                        </>}
                </Grid>
            </Grid>
            {/* NBN Normal Address specification */}

            {/* NBN Normal Address specification-Yes */}

            {/* NBN-Transition*/}
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    {selectedNBNProduct === "Transition" ?
                        <>
                            {selectedNBNOtherType === "FNN" ?
                                <TextField
                                    id="NBN-TRANSITION-DATA-FNN"
                                    multiline
                                    rows={2}
                                    helperText="Enter the specific requirement"
                                    label={labelText}
                                    classes={{ root: classes.yesSpecification }}
                                    value={nbnTransitionFNNData}
                                    onChange={e => {
                                        setNbnTransitionFNNData(e.target.value);
                                        let key = "CUSTOM VACANT NBN TRANSITION FNN DATA";
                                        setSpecReqData(key, e.target.value);
                                    }}
                                /> : <> {selectedNBNOtherType === "Address" ?
                                    <><Autocomplete
                                        disablePortal
                                        id="nbn-transition-address-nbn-class"
                                        options={props.nbnClasses}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(option) => option}
                                        helperText="select the NBN class"
                                        value={selectedNBNClass}
                                        onChange={(event, value) => {
                                            setSelectedNBNClass(value);
                                            let key = "CUSTOM VACANT NBN TRANSITION ADDRESS DATA";
                                            setSpecReqData(key, "");
                                        }}
                                        onChangeCapture={e => { setSpecReqData(e.target.value, "") }}
                                        renderInput={(params) => <TextField {...params} label="NBN class"
                                            classes={{ root: classes.yesSpecification }} />}
                                    /> {(selectedNBNClass !== "" && selectedNBNClass !== "None" && selectedNBNClass !== null && selectedNBNClass !== undefined) ? <TextField
                                        id="NBN-ADDRESS-DATA"
                                        multiline
                                        rows={2}
                                        helperText="Enter the specific requirement"
                                        label={labelText}
                                        classes={{ root: classes.yesSpecification }}
                                        value={nbnTransitionAddressData}
                                        onChange={e => {
                                            setNbnTransitionAddressData(e.target.value);
                                            let key = "CUSTOM VACANT NBN TRANSITION ADDRESS " + selectedNBNClass.toUpperCase() + " DATA";
                                            setSpecReqData(key, e.target.value);
                                        }}
                                    /> : <></>}

                                    </> : <></>}</>}</> :
                        <>
                            {selectedNBNProduct === "PORT-IN" ?
                                <>
                                    {selectedNBNOtherType === "FNN" ?
                                        <TextField
                                            id="NBN-PORT-IN-DATA-FNN"
                                            multiline
                                            rows={2}
                                            helperText="Enter the specific requirement"
                                            label={labelText}
                                            classes={{ root: classes.yesSpecification }}
                                            value={nbnPortFNNData}
                                            onChange={e => {
                                                setNbnPortFNNData(e.target.value);
                                                let key = "CUSTOM VACANT NBN PORT-IN FNN DATA";
                                                setSpecReqData(key, "");
                                            }}
                                        /> :
                                        <>
                                            {selectedNBNOtherType === "Address" ?
                                                <><Autocomplete
                                                    disablePortal
                                                    id="nbn-transition-address-nbn-class"
                                                    options={props.nbnClasses}
                                                    sx={{ width: 300 }}
                                                    getOptionLabel={(option) => option}
                                                    helperText="select the NBN class"
                                                    value={selectedNBNClass}
                                                    onChange={(event, value) => {
                                                        setSelectedNBNClass(value);
                                                        let key = "CUSTOM VACANT NBN PORT-IN ADDRESS" + value.toUpperCase() + " DATA";
                                                        setSpecReqData(key, "");
                                                    }}
                                                    onChangeCapture={e => setSpecReqData("Address", "")}
                                                    renderInput={(params) => <TextField {...params} label="NBN class"
                                                        classes={{ root: classes.yesSpecification }} />} />
                                                    {(selectedNBNClass !== "" && selectedNBNClass !== "None" && selectedNBNClass !== null && selectedNBNClass !== undefined) ? <TextField
                                                        id="NBN-PORT-IN-ADDRESS-DATA"
                                                        multiline
                                                        rows={2}
                                                        helperText="Enter the specific requirement"
                                                        label={labelText}
                                                        classes={{ root: classes.yesSpecification }}
                                                        value={nbnPortAddressData}
                                                        onChange={e => {
                                                            setNbnPortAddressData(e.target.value);
                                                            let key = "CUSTOM VACANT NBN PORT-IN ADDRESS" + selectedNBNClass.toUpperCase() + " DATA";
                                                            setSpecReqData(key, e.target.value);
                                                        }}
                                                    /> : <></>}
                                                </> : <></>}
                                        </>}
                                </> :
                                <>
                                    {selectedNBNProduct === "4GFW" ?
                                        <>
                                            {selectedNBNOtherType === "FNN" ?
                                                <TextField
                                                    id="NBN-4GFW-DATA-FNN"
                                                    multiline
                                                    rows={2}
                                                    helperText="Enter the specific requirement"
                                                    label={labelText}
                                                    classes={{ root: classes.yesSpecification }}
                                                    value={nbn4GFNNData}
                                                    onChange={e => {
                                                        setNbn4GFNNData(e.target.value);
                                                        let key = "CUSTOM VACANT NBN 4GFW FNN DATA";
                                                        setSpecReqData(key, e.target.value);
                                                    }}
                                                /> :
                                                <>
                                                    {selectedNBNOtherType === "Address" ?
                                                        <><Autocomplete
                                                            disablePortal
                                                            id="nbn-4GFW-address-nbn-class"
                                                            options={props.nbnClasses}
                                                            sx={{ width: 300 }}
                                                            getOptionLabel={(option) => option}
                                                            helperText="select the NBN class"
                                                            value={selectedNBNClass}
                                                            onChange={(event, value) => {
                                                                setSelectedNBNClass(value);
                                                                let key = "CUSTOM VACANT NBN 4GFW ADDRESS " + value.toUpperCase() + " DATA";
                                                                setSpecReqData(key, "");
                                                            }}
                                                            renderInput={(params) => <TextField {...params} label="NBN class"
                                                                classes={{ root: classes.yesSpecification }} />} />
                                                            {(selectedNBNClass !== "" && selectedNBNClass !== "None" && selectedNBNClass !== null && selectedNBNClass !== undefined)? <TextField
                                                                id="NBN-4GFW-ADDRESS-DATA"
                                                                multiline
                                                                rows={2}
                                                                label={labelText}
                                                                helperText="Enter the specific requirement"
                                                                classes={{ root: classes.yesSpecification }}
                                                                value={nbn4GAddressData}
                                                                onChange={e => {
                                                                    setNbn4GAddressData(e.target.value);
                                                                    let key = "CUSTOM VACANT NBN 4GFW ADDRESS " + selectedNBNClass.toUpperCase() + " DATA";
                                                                    setSpecReqData(key, e.target.value);
                                                                }}
                                                            /> : <></>}
                                                        </> : <></>}
                                                </>}
                                        </> :
                                        <></>}
                                </>}
                        </>}
                </Grid>
            </Grid>

        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
        prodData: (value) => dispatch(prodData(value)),
        setSpecificRequirement: (value, sID) => dispatch(setSpecificRequirement(value, sID))
    }
}

export default connect(null, mapDispatchToProps)(StatelessNBNProduct);