import React, { useEffect, Fragment, useState } from "react";
import HeaderContent from '../common/Header/HeaderContent';
import {Input } from 'reactstrap';
import { connect } from "react-redux";
import { Button, makeStyles, MenuItem, Select, Tooltip } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './DDC.css'
import { updateBillingDetails } from '../../actions/ddcActions'
import EditIcon from '@material-ui/icons/Edit';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';



const useStyles = makeStyles({
    updateButton: {
        marginLeft: '12px',
        fontSize: '15px',
        paddingBottom: '3px',
        float: 'right',
        marginRight: '32px',
        backgroundColor: '#3f51b5',
        color: 'white'
    },
    container: {
        maxHeight: 440,
        padding : '0px 20px 10px 0px',
        overflowY : 'scroll',
        overflowX : 'hidden',
        '& .MuiTableCell-stickyHeader' : {
            backgroundColor : '#dae8f1 !important'
        }
      },
});

const BillingDetailsDDC = (props) => {
    const { billingColumns,billingDetails } = props;
    const d = new Date();
    let year = d.getFullYear();
    let month = (1 + d.getMonth()).toString().padStart(2, '0');
    let day = d.getDate().toString().padStart(2, '0');
    const dString = day + "/" + month + "/" + year;
    const classes = useStyles();
    const [billingInputValue, setBillingInputValue] = useState(["MICA", "TDM", "", "Active",
        dString, dString, "", "",
        "", "", "", "", "", "Monthly",
        "", "28 BELLVIEW PL", "", "PARKINSON QLD 4115"]);
    const [billingInputValue1, setBillingInputValue1] = useState(["MICA", "TDM", "", "Active",
        dString, dString, "", "",
        "", "", "", "", "", "Monthly",
        "", "28 BELLVIEW PL", "", "PARKINSON QLD 4115"]);
    const [billingName2, setBillingName2] = useState("");
    const [billingAcntStatus, setBillingAcntStatus] = useState("");
    const [startDate, setStartDate] = useState(dString);
    const [endDate, setEndDate] = useState(dString);
    const [businessName, setBusinessName] = useState("");
    const [inCollections, setInCollections] = useState("");
    const [iCMS, setIcms] = useState("");
    const [singleBill, setSingleBill] = useState("");
    const [onlineBill, setOnlineBill] = useState("");
    const [directDebit, setDirectDebit] = useState("");
    const [billFrequency, setBillFrequency] = useState("");
    const [billPeriodicity, setBillPeriodicity] = useState("Monthly");
    const [billCycle, setBillCycle] = useState("");
    const [addressLine1, setAddLine1] = useState("28 BELLVIEW PL");
    const [addressLine2, setAddLine2] = useState("");
    const [addressLine3, setAddLine3] = useState("PARKINSON QLD 4115");

    useEffect(() => {
        handleBillingUpdate();
    }, [])


    const handleBillingInputChange = (e, val, index) => {
        let billingInput = billingInputValue;
        let billingInput1 = billingInputValue1;
        if (val.key === "Source System") { billingInput[0] = (e.target.value)}
        if (val.key === "Billing Name Line 1") { billingInput[1] = (e.target.value)}
        if (val.key === "Billing Name Line 2") { billingInput[2] = (e.target.value), setBillingName2({ inputValue: e.target.value }) }
        if (val.key === "Billing Account Status") { billingInput[3] = (e.target.value), setBillingAcntStatus({ inputValue: e.target.value }) }
        if (val.key === "Start Date") { 
            let mydate = e
            let d = new Date(mydate);
            let year = d.getFullYear();
            let month = (1 + d.getMonth()).toString().padStart(2, '0');
            let day = d.getDate().toString().padStart(2, '0');
            const dString = month + "/" + day + "/" + year;            
            const dString1 = day + "/" + month + "/" + year;            
            billingInput[4] = dString;
            billingInput1[4] = dString1
            setStartDate({ inputValue: e }) 
        }
        if (val.key === "End Date") { 
            let mydate = e
            let d = new Date(mydate);
            let year = d.getFullYear();
            let month = (1 + d.getMonth()).toString().padStart(2, '0');
            let day = d.getDate().toString().padStart(2, '0');
            const dString = month + "/" + day + "/" + year;
            const dString1 = day + "/" + month + "/" + year;
            billingInput[5] = (dString), 
            billingInput1[5] = dString1
            setEndDate({ inputValue: e })
         }
        if (val.key === "Business Name") { billingInput[6] = (e.target.value), setBusinessName({ inputValue: e.target.value }) }
        if (val.key === "In Collections") { billingInput[7] = (e.target.value), setInCollections({ inputValue: e.target.value }) }
        if (val.key === "ICMS Indicator") { billingInput[8] = (e.target.value), setIcms({ inputValue: e.target.value }) }
        if (val.key === "Single Bill Indicator") { billingInput[9] = (e.target.value), setSingleBill({ inputValue: e.target.value }) }
        if (val.key === "Online billingDetail indicator") { billingInput[10] = (e.target.value), setOnlineBill({ inputValue: e.target.value }) }
        if (val.key === "Direct Debit indicator") { billingInput[11] = (e.target.value), setDirectDebit({ inputValue: e.target.value }) }
        if (val.key === "Bill Frequency") { billingInput[12] = (e.target.value), setBillFrequency({ inputValue: e.target.value }) }
        if (val.key === "Bill Periodicity") { billingInput[13] = (e.target.value), setBillPeriodicity({ inputValue: e.target.value }) }
        if (val.key === "Bill Cycle") { billingInput[14] = (e.target.value), setBillCycle({ inputValue: e.target.value }) }
        if (val.key === "Address Line1") { billingInput[15] = (e.target.value), setAddLine1({ inputValue: e.target.value }) }
        if (val.key === "Address Line2") { billingInput[16] = (e.target.value), setAddLine2({ inputValue: e.target.value }) }
        if (val.key === "Address Line3") { billingInput[17] = (e.target.value), setAddLine3({ inputValue: e.target.value }) }
        setBillingInputValue(billingInput);
        setBillingInputValue1(billingInput1);
        handleBillingUpdate();
    }

    const handleBillingUpdate = () => {
        let billingDetail = [];
        let bInput = [...billingInputValue]; let msisdnList = []; let simList = []; let cidnList = []; let banList = []; let pcList = []; let plList = [];
        billingDetails.map((ddc, index) => {
            const bDetails = { "addressLine2": "", "addressLine2": "", "addressLine1": "PARKINSON QLD 4115", 
            "billCycle": "", "billPeriodicity": "Monthly", "billFrequency": "", 
            "directDebtIndicator": "", "onlineBillIndicator": "", 
            "singleBillIndicator": "", "iCMSIndicator": "", "inCollections": "", 
            "businessName": "", "endDate": endDate, "startDate": startDate, "status": "", 
            "customerId" : "", "ban": "","sourceSystem": "", "billingNameLine1": "TDM", "billingNameLine2": "" };
            
            bDetails['customerId'] = (ddc.customerId);
            cidnList.push(ddc.customerId);
            bDetails['ban'] = (ddc.ban);
            banList.push(ddc.ban);
                bDetails['sourceSystem'] = billingInputValue[0];
                bDetails['billingNameLine1'] = billingInputValue[1];
                bDetails['billingNameLine2'] = billingInputValue[2];
                bDetails['status'] = billingInputValue[3];
                bDetails['startDate'] = billingInputValue1[4];
                bDetails['endDate'] = billingInputValue1[5];
                bDetails['businessName'] = billingInputValue[6];
                bDetails['inCollections'] = billingInputValue[7];
                bDetails['iCMSIndicator'] = billingInputValue[8];
                bDetails['singleBillIndicator'] = billingInputValue[9];
                bDetails['onlineBillIndicator'] = billingInputValue[10];
                bDetails['directDebtIndicator'] = billingInputValue[11];
                bDetails['billFrequency'] = billingInputValue[12];
                bDetails['billPeriodicity'] = billingInputValue[13];
                bDetails['billCycle'] = billingInputValue[14];
                bDetails['addressLine1'] = billingInputValue[15];
                bDetails['addressLine2'] = billingInputValue[16];
                bDetails['addressLine3'] = billingInputValue[17];
                billingDetail.push(bDetails);
                bInput[18] = cidnList;
                bInput[19] = banList;
                })
        setBillingInputValue(bInput);
        props.updateBillingDetails(billingDetail);
    }


    return (
        <Fragment>
                <div class = "container" className={classes.container}>
                <div class = "row rowHeader">
                    <div class = "col-4">Name</div>
                    <div class = "col-6">Value</div>
                    <div class = "col-1">Action</div>
                </div>
        {billingColumns && billingColumns.map((val, index) => {
            return (
                <div class = "row">
         <div class = "col-4">{val.key}</div>
        <div class = "col-6">
        {val.key == "Start Date" || val.key == "End Date" ?
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat="dd/MM/yyyy"
          value={billingInputValue[index]}
          onChange={e => handleBillingInputChange(e, val, index)}
          renderInput={(params) => <TextField {...params} />}
        /></LocalizationProvider> : <>{val.key !== "Source System" ?
        <TextField
        name={index}
        id={index}
        value={billingInputValue[index]}
        disabled={val.edit == true ? false : true}
        onChange={e => handleBillingInputChange(e, val, index)}
        variant="outlined"
        className="reviewInputField" />  :
        <Select
        value={billingInputValue[index]}
        onChange={e => handleBillingInputChange(e, val, index)}
        className="reviewInputField"
        variant="outlined"
      >
        <MenuItem value="MICA">MICA</MenuItem>
        <MenuItem value="FLEXCAB">FLEXCAB</MenuItem>
      </Select>
    }</>}
        </div>
        <div class = "col-1">
        {val.edit == true ?     <Button color="primary" id="editButton"
                                    startIcon={<EditIcon />}></Button> :
                                <></>}</div>
        </div>
            )
        })}
        </div>
        </Fragment>
        )
}


const mapStateToProps = (state) => {
    return {
        billingColumns: state.ddc.billingColumns,
        billingDetails: state.ddc.billingDetails
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateBillingDetails: (billingInput) => dispatch(updateBillingDetails(billingInput)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(BillingDetailsDDC);
