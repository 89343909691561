import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { Button, Col, Container, Row, Spinner } from 'reactstrap';
import {     
    fetchCustomerData,
    onboardTConContact,
    updateCapabilities,
    fetchOrdersByCidn,
    submitOrderDetails,
    submitEditOrderDetails,
    generateServiceId,
    salesforceCreateContact
} from '../../actions/tconnectActions';
import { SubHeader } from './modals/HeaderContent';
import TConnectCapabilityModal from './modals/TConnectCapabilityModal';
import TConnectContactView from './TConnectContactView';
import TConnectDataViewCustomerTable from './TConnectDataViewCustomerTable';
import TConnectOnboardModal from './modals/TConnectOnboardModal';
import TconnectDataHubOrders from './TconnectDataHubOrders'
import TConnectEnvModal from './modals/TConnectEnvModal';
import './TConnectDataView.css';
import TConnectCreateOrderModal from './modals/TConnectCreateOrderModal';
import TConnectOrderModal from './modals/TConnectOrderModal'

class TConnectDataView extends Component {

    constructor(props) {
        super(props)
        this.tConnectOrderModalRef = React.createRef();
        this.tConnectEditOrderModalRef = React.createRef();
        this.tconOnboardRef = React.createRef();
        this.tconCapabilityRef = React.createRef();
        this.state = {
            selectedContactIdx: 0,
            env : ''
        }       
    }
    
    setSelectedContact = index => {
        this.setState({ selectedContactIdx: index })
    }

    componentDidMount() {
        if(!this.props.dataview.status) {
            this.props.history.push(`/data/utility`);
        }
    }

    handleOnboardPress = (data) => {
        return this.props.onboardUser(data)
    }

    handleSubmitEnv = (env) =>{
        this.setState({
            env : env
        })
    }

    handleOnTileClick = (e,cidn) => {
        this.props.fetchOrdersByCidn(cidn)
    }

    openTconnectOrderModal = (tconOrderRef) => {
        this.props.generateServiceId(1).then(result =>{
            if(result){
               let fnn=result.serviceId;
               this.setState({
                orderFnn : fnn
               })
            }
        });
        tconOrderRef.current.open()
    }

    render() {
     
        const customer = this.props.dataview.tconCustomer;
        const contacts = customer && customer.contacts;
        const capabilities = this.props.capabilities || [];
        const detailsHaveLoaded = this.props.dataview.tconCustomer && this.props.dataview.tconCustomer.cidn != null;
        const datahubOrders = this.props.datahubOrders;
        const cidn = this.props.dataview.tconCustomer && this.props.dataview.tconCustomer.cidn;
        const profile = this.props.profile;
        const addContact = this.props.addContact;
        return (
            <React.Fragment>
              <TConnectEnvModal modalOpen={true} handleSubmitEnv={this.handleSubmitEnv}/>
                <div className="white">
                    <SubHeader
                        text={`T-Connect Dashboard`}
                        iconClass="icon-telstra"
                        iconColor="blue"
                        loading={this.props.dataview.isLoading}
                        loadingText="Loading..."
                        error={this.props.dataview.inError && !detailsHaveLoaded}
                        errorText="An error occurred while building T-Connect dashboard"
                        h1
                    />
                   
                    {detailsHaveLoaded &&
                        <Container>
                            <SubHeader
                                text={"Customer"}
                                iconClass={"td-icon-md icon-crowd-support"}
                                iconColor="turquoise"
                                iconLeft />
                            <Row>
                                <Col>
                                    <TConnectDataViewCustomerTable customer={customer} env={this.state.env} />
                                </Col>
                            </Row>
                            <div style={{ paddingTop: "40px" }}></div>
                            <Row>
                                <Col lg={6} sm={12} className="tconnect-contact-table-wrap">
                                    
                                    <TConnectContactView contacts={contacts} isLoading={this.props.dataview.isLoading}
                                    tconCustomer = {customer}
                                    addContact = {addContact}
                                    profile = {profile} 
                                    userEmail = {this.props.userEmail}
                                    env={this.props.env}
                                    fetchCustomerData = {this.props.fetchCustomerData} 
                                    salesforceCreateContact = {this.props.salesforceCreateContact}
                                    tconOnboardRef={this.tconOnboardRef} 
                                    tconCapabilityRef={this.tconCapabilityRef} 
                                    onSelect={this.setSelectedContact} 
                                    selected={this.state.selectedContactIdx} />
                                </Col>
                                <Col lg={6} sm={12}>                                      
                                    <div className={`tiles-grid base-purple grid-flex`}>                                       
                                            <div
                                                data-size='medium'
                                                data-role="tile"
                                                className="featured-gradient tile-medium"
                                                key='Data-Hub Legacy Orders' onClick={(e) => this.handleOnTileClick(e, cidn)}>
                                                 {!this.props.dataview.isOrdersLoding ?
                                                <span className={`td-icon-md icon-shop-online icon`}></span>:
                                                <span className={`td-icon-md icon`}>
                                                    <Spinner
                                                        style={{ margin: "0.60rem", width: "2.5rem", height: "2.5rem" }}
                                                        color='turquoise'
                                                    />                                                 
                                                </span>}
                                                <span className="branding-bar">Data-Hub Legacy Orders</span>
                                            </div> 
                                    </div>                                    
                                </Col>
                            </Row>
                            <div style={{ paddingTop: "40px" }}></div>
                            <Row>
                                {
                                    datahubOrders && datahubOrders.rows &&
                                    <div>
                                        <div>                                        
                                            <Button color='info' onClick={() => this.openTconnectOrderModal(this.tConnectOrderModalRef)}>
                                                <i  aria-hidden="true"></i>{` Create Orders`}
                                            </Button>                                        
                                        </div>
                                        <div>
                                            <TconnectDataHubOrders orders={datahubOrders.rows} tconOrderRef={this.tConnectEditOrderModalRef} />
                                        </div>
                                    </div>
                                }
                            </Row>
                        </Container>
                    }                  
                    
                </div>
                <TConnectOnboardModal ref={this.tconOnboardRef} cidn={cidn} handleOnboard={this.handleOnboardPress} />
                <TConnectCapabilityModal ref={this.tconCapabilityRef} capabilities={capabilities} env ={this.state.env} customer={customer} updateCapabilities={this.props.updateCapabilities} />
                <TConnectCreateOrderModal cidn={this.props.dataview.tconCustomer.cidn} fnn={this.state.orderFnn} customerName={this.props.dataview.tconCustomer.name} ref={this.tConnectOrderModalRef} submitOrderDetails={this.props.submitOrderDetails}/>
                <TConnectOrderModal cidn={this.props.dataview.tconCustomer.cidn} customerName={this.props.dataview.tconCustomer.name} ref={this.tConnectEditOrderModalRef} submitOrderDetails={this.props.submitEditOrderDetails}/>                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    dataview: state.tconnect.dataview,
    capabilities: state.tconnect.capabilities,
    datahubOrders: state.tconnect.dataHubOrders,
    env: state.profiles.active.env,
    profile: state.profiles.active,
    addContact: state.tconnect.addContact,
    userEmail: state.auth.user.email
})

const mapDispatchToProps = dispatch => {
    return {
        fetchCustomerData: (env, cidn) => dispatch(fetchCustomerData(env, cidn)),
        onboardUser: contact => dispatch(onboardTConContact(contact)),
        updateCapabilities: data => dispatch(updateCapabilities(data)),
        fetchOrdersByCidn: (cidn) => dispatch(fetchOrdersByCidn(cidn)),
        submitOrderDetails: (obj) => dispatch(submitOrderDetails(obj)),
        submitEditOrderDetails: (obj) => dispatch(submitEditOrderDetails(obj)),
        generateServiceId : (qty) => dispatch(generateServiceId(qty)),
        salesforceCreateContact :(accountId, customerId, cidn, env, email, role, profile) => 
        dispatch(salesforceCreateContact(accountId, customerId, cidn, env, email, role, profile)) 
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TConnectDataView));
