import {
    Button,
    Media,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import React, { Component } from 'react'

import Magnifier from "react-magnifier";

export default class DiagramViewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this
            .toggle
            .bind(this);
    }

    componentDidMount() {
        this
            .props
            .onRef(this)
    }
    componentWillUnmount() {
        this
            .props
            .onRef(undefined)
    }

    open(diagram) {
        this.setState({
            modal: true,
            diagram,
        })
    }


    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        if (!this.state.diagram)
            return <span></span>
        const { img, title, subtitle, content } = this.state.diagram;
        return (
            <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                style={{ width: "70vw", maxWidth: "70vw" }}
                centered>
                <ModalHeader toggle={this.toggle}>
                    {title}
                </ModalHeader>
                <ModalBody style={{ height: "60vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <Magnifier src={img.url} zoomImgSrc={`"${img.url}"`} width={"90%"} height={"100%"} mgWidth={300} mgHeight={300} zoomFactor={.5} />
                    {/* <Media src={img.url}  /> */}
                </ModalBody>
                <ModalFooter style={{ display: "flex", justifyContent: "space-between" }}>
                    <h4>{content}</h4>
                    <Button color="secondary" onClick={this.toggle}>Close</Button>
                </ModalFooter>
            </Modal >
        )
    }
}
