import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from "@material-ui/core/styles";

const useButtonStyle = makeStyles({
  root: {
    padding: "4px",
    minWidth: "10px",
  },
  contained: {
    "&:hover": {
      transform: "translateY(-2px)",
    },
  },
});

const defaultContent = <AddIcon/>;

const SmallSquareBtn = (props) => {
  const { children = defaultContent, onBtnClick, ...rest } = props;

  const buttonStyle = useButtonStyle();

  return (
    <Button
      variant="contained"
      onClick={onBtnClick}
      color="primary"
      classes={buttonStyle}
      {...rest}
    >
      {children}
    </Button>
  );
};

SmallSquareBtn.propTypes = {
  children: PropTypes.node.isRequired,
  onBtnClick: PropTypes.func.isRequired,
  color: PropTypes.oneOf(["default","inherit","primary","secondary"])
};

export default SmallSquareBtn;
