import React from "react";
import DataTilesFrame from "../../Data/accordion-frames/replicator/DataTilesFrame";

export default function ActionTiles({ actionFilters, selectActionType, action }) {

    return (
        <DataTilesFrame
            heading="Action Types"
            color="magenta"
            data={actionFilters}
            select={selectActionType}
            isMulti={false}
            selected={action}
        />
    )

}