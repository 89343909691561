import React from "react";
import { connect } from "react-redux";
import { Field, change } from "redux-form";
import { renderField } from "../common/fields/fields";
import {
  roleValidator,
  linkedFnnValidator,
  huntGroupFnnValidator
} from "../common/form/fieldValidators";
import "./Product.css";
import "./Data.css";

class HuntGroupForm extends React.Component {
  render() {
    const { options, index, selectedFnn, productType, cosmos } = this.props;
    const linkedFnnOptions = options.filter(data => data.value !== selectedFnn);
    const roleOptions = [];
    !cosmos ? (!cosmos ? roleOptions.push(
      { label: "prime", value: "prime" },
      { label: "auxiliary", value: "auxiliary" },
      { label: "gdn", value: "gdn" }
    ) : null) : null;
    productType === 'pstn' ? roleOptions.push(
      { label: "prime", value: "prime" },
      { label: "auxiliary", value: "auxiliary" },
    ) : null;
    productType === 'isdn2' ? roleOptions.push(
      { label: "prime", value: "prime" },
      { label: "auxiliary", value: "auxiliary" },
      { label: "gdn", value: "gdn" }
    ) : null;
    productType === 'isdn2did' ? roleOptions.push(
      { label: "prime", value: "prime" },
    ) : null;
    productType === 'isdn30' ? roleOptions.push(
      { label: "Empty", value: "" },
    ) : null;
    return (
      <tr className="">
        <td className="">
          <Field
            name={`huntGroup[${index}].fnn`}
            validate={huntGroupFnnValidator}
            component={renderField}
            type="selectWithCustomHeight"
            customDiv="adborFnnTableInputDiv"
            onChange={() =>
              this.props.resetLinkedFnn(
                "s2pProductModalForm",
                `huntGroup[${index}].linkedFnn`,
                ""
              )
            }
            options={options}
          />
        </td>
        <td className="">
          <Field
            touchOnChange={true}
            name={`huntGroup[${index}].linkedFnn`}
            validate={linkedFnnValidator}
            component={renderField}
            type="selectWithCustomHeight"
            options={linkedFnnOptions}
            customDiv="adborFnnTableInputDiv"
          />
        </td>
        <td className="">
          <Field
            name={`huntGroup[${index}].role`}
            validate={roleValidator}
            component={renderField}
            type="selectWithCustomHeight"
            options={roleOptions}
            customDiv="adborFnnTableInputDiv"
          />
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetLinkedFnn: (formName, fieldName, value) => {
      dispatch(change(formName, fieldName, value));
    }
  };
};

export default connect(null, mapDispatchToProps)(HuntGroupForm);
