import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import Select from "react-select";
import {connect} from 'react-redux';
import { fetchUsersInTeam} from "../../actions/userActions";
import {is1TdmUser} from  '../../utils/userProfile';

class UsersToTeam extends React.Component{
   
     state = {
        modal: false,
        users : {},
        errors :{},
        success:{},
        selectedOption : {},
        selectedRow : null
      };

      componentDidMount() {
        this.props.onRef(this);
      }

      componentWillUnmount() {
        this.props.onRef(undefined); 
      }
    
      toggle = () => {
        this.setState(prevState => ({
          modal: !prevState.modal,
          users : {},
          selectedOption : {}
        }));
        this.resetMessageHandler();
      };
    
      resetMessageHandler = () => {
        this.setState({
          success:{},
          errors:{}
        })
      }

      updateSuccessMessageHandler = (success) =>{       
        this.setState({
          success:success,
          errors:{}
        })
      }

      updateErrorMessageHandler = (errors) =>{
        this.setState({
          errors:errors,
          success:{}
        })
      }

      open = () => {
        if (!this.state.modal) {
          this.setState(prevState => ({
            ...prevState,
            modal: true
          }));
          this.props.fetchUsersInTeam(this.props.currentTeamId);
        }
      };

      fetchTeamOptions = teams =>{
       //let uniqueTeams = this.filterUniqueteams(teams);        
        return Array.isArray(teams) ? teams.map(team => ({ value: team.teamId, label: team.teamName })) : [];
      }

      fetchCurrentTeam = (teams,cteamId)=>{
     //  let uniqueTeams = this.filterUniqueteams(teams);
       return Array.isArray(teams) ? teams.filter(team => team.teamId === cteamId).map(team => ({ value: team.teamId, label: team.teamName })) : [];
      }

      onChange = (team) => {
        this.setState({
          selectedOption : { value: team.value, label: team.label }
        })
        this.props.fetchUsersInTeam(team.value);
      }

      isRoleUser = (roles) => roles && roles.includes('USER')

      render() {
        const { className, filters,users,isAdmin,currentTeamId, myTeamOptions, roles} = this.props;
        const isUser = this.isRoleUser(roles); 
        const usersList = users ? Object.entries(users).filter(([k,v], i) => k !== "loading") : [];
        const { modal,selectedOption} = this.state;
        return (
            <React.Fragment>
              <Modal isOpen={modal}  style={{width: "45vw" ,maxWidth: "60vw"}} toggle={this.toggle} className={className}>
                <ModalHeader toggle={this.toggle}>{isAdmin ? "TDM Users" : "My Team Members" }</ModalHeader>
                <ModalBody>
                  <section>
                      <div className="row">
                      <div className="col-4">
                      <Select
                      options={isUser && is1TdmUser()? this.fetchTeamOptions(myTeamOptions):this.fetchTeamOptions(filters)}
                      styles={this.customStyles}
                      placeholder={"Select"}
                      onChange={this.onChange}
                      value={selectedOption.value === undefined ? this.fetchCurrentTeam(filters,currentTeamId) : selectedOption}
                      />
                      </div>
                      </div>
                      <div className="row">
                        {(() => {
                              if (usersList && usersList.length > 0 ) {
                                return (
                                  <div className="col">
                                  <div style={{ maxHeight: "280px",
                                    overflowY: "auto", margin : "0px 10px 20px 10px"}}>
                                    <Table hover responsive>
                                    <thead>
                                    <tr>
                                      <th> Name</th>
                                      <th> Email</th>
                                      <th>UserName</th>
                                      <th>  </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {usersList.map(([key, user], i) => (
                                            <tr key={i}>
                                              <td>{`${user.lastName}, ${user.firstName}`}</td>
                                              <td>{user.email}</td>
                                              <td>{user.userId}</td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                  </Table>
                                </div>
                                </div>
                                
                                )
                              } else if (users && users === false) {
                                return (
                                  <div className="col">
                                    <div>Couldn't find any users for the selected team</div>
                                    <div>
                                    </div>
                                  </div>
                                  
                                )
                              } else {
                                return (
                                  <div className="col">Please select a team to get list of users</div>
                                )
                              }
                        }
                        )()}
                      </div>
                      <div className="row">  
                        <div className="col">               
                          <span style={{color: "red"}}>{this.state.errors["message"]}</span>
                          <span style={{color: "green"}}>{this.state.success["message"]}</span>
                        </div>
                      </div> 
                  </section>
                </ModalBody>
                <ModalFooter>
                  <Button color="info" className={"btn-team"} onClick={this.toggle}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </React.Fragment>
          );
      }
}
const mapStateToProps = state => {
  return { 
    users : state.users.users
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUsersInTeam : (teamId) => dispatch(fetchUsersInTeam(teamId))
  };
};
export default connect(mapStateToProps, mapDispatchToProps) (UsersToTeam);