import React, { Component } from 'react'
import { Fade } from 'reactstrap';

export default (props) => {
    const {
        loading,
        isMulti = false,
        isComplete,
        subSelected,
        select,
        heading,
        renderContent,
        headingRef,
        data,
        color,
    } = props;

    return (
        <Fade in={true}>
            <span className="dataview-header">
                <p className="sub-leader font-weight-light">{heading}</p>
                <i className={`${isComplete ? 'td-icon-sm icon-tick text-success' : ''}`} aria-hidden="true">
                </i>
            </span>
            <div className="content">
                {renderContent()}
            </div>
        </Fade>
    )
}