import React, { useEffect, Fragment, useState } from "react";
import HeaderContent from '../common/Header/HeaderContent';
import { Input } from 'reactstrap';
import { connect } from "react-redux";
import { Button, makeStyles, MenuItem, Select } from "@material-ui/core";
import './DDC.css'
import { updateAssetsDetails } from '../../actions/ddcActions'
import EditIcon from '@material-ui/icons/Edit';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import UpdateIcon from '@material-ui/icons/Update';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


const useStyles = makeStyles({
    updateButton: {
        marginLeft: '12px',
        fontSize: '15px',
        paddingBottom: '3px',
        float: 'right',
        marginRight: '32px',
        backgroundColor: '#3f51b5',
        color: 'white'
    },
    container: {
        maxHeight: 440,
        padding: '0px 0px 0px 10px',
        overflowY : 'scroll',
        overflowX : 'hidden',
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: '#dae8f1 !important'
        }
    },
});

const AssetDetailsDDC = (props) => {
    const { assetsColumns, assetsDetails } = props;
    const d = new Date();
    let year = d.getFullYear();
    let month = (1 + d.getMonth()).toString().padStart(2, '0');
    let day = d.getDate().toString().padStart(2, '0');
    const dString = day + "/" + month + "/" + year;
    const classes = useStyles();
    const [assetsInputValue, setAssetsInputValue] = useState([
        "MICA", "MICA", "Mobile Service", "Active", dString,
        dString, "", dString, "", dString, "", "", ""]);
        const [assetsInputValue1, setAssetsInputValue1] = useState([
            "MICA", "MICA", "Mobile Service", "Active", dString,
            dString, "", dString, "", dString, "", "", ""]);
    const [startDate, setStartDate] = useState(dString);


    useEffect(() => {
        handleAssetsUpdate();
    }, [])

    const handleAssetsInputChange = (e, val, index) => {
        let assetsInput = [];
        let assetsInput1 = [];
        assetsInput = assetsInputValue;
        assetsInput1 = assetsInputValue1;
        if (val.key == "Parent Source System") { assetsInput[0] = (e.target.value) }
        if (val.key == "Source System") { assetsInput[1] = (e.target.value) }
        if (val.key == "Service Type") { assetsInput[2] = (e.target.value) }
        if (val.key == "Status") { assetsInput[3] = (e.target.value) }
        if (val.key == "Service Start Date") {
            let mydate = e
            let d = new Date(mydate);
            let year = d.getFullYear();
            let month = (1 + d.getMonth()).toString().padStart(2, '0');
            let day = d.getDate().toString().padStart(2, '0');
            const dString = month + "/" + day + "/" + year;  
            const dString1 = day + "/" + month + "/" + year;  
            assetsInput[4] = (dString)
            assetsInput1[4] = (dString1)
        }
        if (val.key == "Service End Date") {
            let mydate = e
            let d = new Date(mydate);
            let year = d.getFullYear();
            let month = (1 + d.getMonth()).toString().padStart(2, '0');
            let day = d.getDate().toString().padStart(2, '0');
            const dString = month + "/" + day + "/" + year; 
            const dString1 = day + "/" + month + "/" + year;  
            assetsInput[5] = (dString)
            assetsInput1[5] = (dString1) 
            
        }
        if (val.key == "Contract Name") { assetsInput[6] = (e.target.value) }
        if (val.key == "Contract End Date") {
            let mydate = e
            let d = new Date(mydate);
            let year = d.getFullYear();
            let month = (1 + d.getMonth()).toString().padStart(2, '0');
            let day = d.getDate().toString().padStart(2, '0');
            const dString = month + "/" + day + "/" + year;  
            const dString1 = day + "/" + month + "/" + year;  
            assetsInput[7] = (dString)
            assetsInput1[7] = (dString1) 
        }
        if (val.key == "MRO NAME") { assetsInput[8] = (e.target.value) }
        if (val.key == "MRO End Date") {
            let mydate = e
            let d = new Date(mydate);
            let year = d.getFullYear();
            let month = (1 + d.getMonth()).toString().padStart(2, '0');
            let day = d.getDate().toString().padStart(2, '0');
            const dString = month + "/" + day + "/" + year;  
            const dString1 = day + "/" + month + "/" + year;  
            assetsInput[9] = (dString)
            assetsInput1[9] = (dString1) 
        }
        if (val.key == "Password") { assetsInput[10] = (e.target.value) }
        if (val.key == "Reason for Suspension") { assetsInput[11] = (e.target.value) }
        if (val.key == "Parent Asset Integration Id") { assetsInput[12] = (e.target.value) }
        setAssetsInputValue(assetsInput);
        setAssetsInputValue1(assetsInput1);
        handleAssetsUpdate();
    }

    const handleAssetsUpdate = () => {
        let assetDetail = [];
        let aInput = [...assetsInputValue]; let msisdnList = []; let simList = []; let customerIdList = []; let banList = []; let pcList = []; let plList = [];

        assetsDetails.map((ddc, index) => {
            const aDetails = {
                "parentSourceSystem": "MICA", "sourceSystem": "MICA", "serviceType": "Mobile Service",
                "status": "active", "serviceStartDate": startDate, "serviceEndDate":startDate, "contractName": "", "contractEndDate": startDate, "mroName": "",
                "mroEndDate": startDate, "password": "", "reasonForSuspension": "", "parentAssetIntegrationId": "", "msisdn": "", "sim": "", "customerId": "", "ban": "", "productName": "", "productCode": ""
            };
            aDetails['msisdn'] = (ddc.msisdn);
            msisdnList.push(ddc.msisdn)
            aDetails['sim'] = (ddc.sim);
            simList.push(ddc.sim);
            aDetails['customerId'] = (ddc.customerId);
            customerIdList.push(ddc.customerId);
            aDetails['ban'] = (ddc.ban);
            banList.push(ddc.ban);
            aDetails['productName'] = (ddc.productName);
            pcList.push(ddc.productName);
            aDetails['productCode'] = (ddc.productCode);
            plList.push(ddc.productCode);
            aDetails['parentSourceSystem'] = assetsInputValue[0];
            aDetails['sourceSystem'] = assetsInputValue[1];
            aDetails['serviceType'] = assetsInputValue[2];
            aDetails['status'] = assetsInputValue[3];
            aDetails['serviceStartDate'] = assetsInputValue1[4];
            aDetails['serviceEndDate'] = assetsInputValue1[5];
            aDetails['contractName'] = assetsInputValue[6];
            aDetails['contractEndDate'] = assetsInputValue1[7];
            aDetails['mroName'] = assetsInputValue[8];
            aDetails['mroEndDate'] = assetsInputValue1[9];
            aDetails['password'] = assetsInputValue[10];
            aDetails['reasonForSuspension'] = assetsInputValue[11];
            aDetails['parentAssetIntegrationId'] = assetsInputValue[12];
            aInput[13] = msisdnList;
            aInput[14] = simList;
            aInput[15] = customerIdList;
            aInput[16] = banList;
            aInput[17] = pcList;
            aInput[18] = plList
            assetDetail.push(aDetails)

        })
        setAssetsInputValue(aInput)
        props.updateAssetsDetails(assetDetail);
    }


    return (
        <Fragment>
                <div class = "container" className={classes.container}>
                <div class = "row rowHeader">
                    <div class = "col-4">Name</div>
                    <div class = "col-6">Value</div>
                    <div class = "col-1">Action</div>
                </div>
        {assetsColumns && assetsColumns.map((val, index) => {
            return (
                <div class = "row">
         <div class = "col-4">{val.key}</div>
        <div class = "col-6">
        {val.key == "Service Start Date" || val.key == "Service End Date" ||
        val.key == "Contract End Date" || val.key == "MRO End Date" ?
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          inputFormat="dd/MM/yyyy"
          value={assetsInputValue[index]}
         onChange={e => handleAssetsInputChange(e, val, index)}
          renderInput={(params) => <TextField {...params} />}
        /></LocalizationProvider> : <>
       {val.key !== "Parent Source System" && val.key !== "Source System" ?
                                            <TextField
                                                id={index}
                                                value={assetsInputValue[index]}
                                                disabled={val.edit == true ? false : true}
                                                onChange={e => handleAssetsInputChange(e, val, index)}
                                                variant="outlined"
                                                className="reviewInputField"
                                            /> :
                                            <Select
                                                value={assetsInputValue[index]}
                                                onChange={e => handleAssetsInputChange(e, val, index)}
                                                className="reviewInputField"
                                                variant="outlined"
                                            >
                                                <MenuItem value="MICA">MICA</MenuItem>
                                                <MenuItem value="FLEXCAB">FLEXCAB</MenuItem>
                                            </Select>
                                        }</>}
        </div>
        <div class = "col-1">
        {val.edit == true ?     <Button color="primary" id="editButton"
                                    startIcon={<EditIcon />}></Button> :
                                <></>}</div>
        </div>
            )
        })}
        </div>
        </Fragment>
        
        )
}


const mapStateToProps = (state) => {
    return {
        assetsDetails: state.ddc.assetsDetails,
        assetsColumns: state.ddc.assetsColumns
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateAssetsDetails: (assetsInput) => dispatch(updateAssetsDetails(assetsInput))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(AssetDetailsDDC);
