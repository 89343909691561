import React, {Component} from 'react'

export const FaBoolean = (props) => {
    const trueClass = props.trueIcon ? props.trueIcon : 'fa-check text-success';
    const falseClass = props.falseIcon ? props.falseIcon : 'fa-times text-warning';
    return (
        <i
            className={`fa ${props.boolean
            ? trueClass
            : falseClass}`}
            aria-hidden="true"></i>
    )
}