import React, { Fragment, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import ChipInput from 'material-ui-chip-input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'reactstrap';
import './batchStatus.css';

const StyledChipInput = withStyles(theme => ({
    root: {
      border: 'none',
    },
    input: {
      border: 'none !important',
    }
}))(ChipInput);

const BatchStatusForm = (props) => {
    const [batchIdChips, setBatchIdChips] = React.useState([]);
    
    useEffect(() => {
        setBatchIdChips([...props.initialBatchIds])
    }, props.initialBatchIds);

    const handleSubmit = () => {
        const requestJSON = {
            type:"SIM or MSISDN",
            batchids: batchIdChips
        }
        props.submit(requestJSON);
    }
    
    const handleAddChip = (chip) => {
        setBatchIdChips([...batchIdChips, ...chip.split(/[.,\s\t\|\;]/).filter(v=>v!=='')]);
    }

    const handleDeleteChip = (chip) => {
        setBatchIdChips(prevChips => prevChips.filter(id => id !== chip));
    }

    return (
        <Fragment>
            <Paper elevation={1} className="FormWrapper">
                <div style={{"width":"85%"}}>
                    <InputLabel htmlFor="batchids">Add Batch IDs</InputLabel>
                    <StyledChipInput
                        value={batchIdChips.map(chip => chip)}
                        onAdd={(chip) => handleAddChip(chip)}
                        onDelete={(chip) => handleDeleteChip(chip)}
                        blurBehavior='add'
                        fullWidth={true}
                        placeholder={'Enter Batch IDs seperated by comma or space'}
                        allowDuplicates={false}
                    />
                </div>
                <div class="text-right pageBtn" style={{"width":"15%"}}>
                    <Button disabled={batchIdChips.length === 0} class="text-right pageBtn" type="button" color="primary" onClick={() => {handleSubmit()}}>Check Status</Button>
                </div>
            </Paper>
           
        </Fragment>
    ); 
}

export default BatchStatusForm;