import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import './SidePanel.css';
import './BroadCast.css'
import { setBroadCastMessagePanel, sendBroadCastMessage, setBroadCastList } from '../../../../actions/broadCastMessageAction';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Button, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import swal from "sweetalert2";
import {getTokenFromCookie} from "../../../../utils/browser";
import { isTaasTokenValid } from "../../../auth/functions";

const useStyles = makeStyles({
  recipientLabel: {
    marginRight: '10px',
    marginLeft: '10px',
    fontSize: '15px',
    color: '#3f51b5'
  },
  maxChars: {
    marginRight: '10px',
    marginLeft: '10px',
    fontSize: '12px',
    color: 'grey'
  },
  allUsersLabel: {
    marginRight: '10px',
    marginLeft: '10px',
    fontSize: '15px',
    color: 'red'
  },
  attachFile: {
    backgroundColor: 'transparent  !important',
    color: '#3f51b5 !important',
    boxShadow: 'none',
    marginLeft: '-27px !important',
    borderRight: '1px solid grey',
    borderRadius: '0px',
    paddingRight: '0px !important'
  }

});

const BroadCastMessagePanel = (props) => {
  const classes = useStyles();

  const [recipientMessage, setRecMsg] = useState("");
  const [msgTitle, setMsgTitle] = useState("");
  const [msgLink, setMsgLink] = useState();
  const [teamRecipients, setTeamRecipients] = useState([]);
  const [userRecipients, setUserRecipients] = useState([]);


  useEffect(() => {
    (async () => {
      const taasToken = getTokenFromCookie('TaaSAccessToken');
      const accessToken = localStorage.getItem('access_token') || getTokenFromCookie("authorization_token");
      (taasToken && (await isTaasTokenValid(taasToken)).verified && 
      accessToken && (await isTaasTokenValid(accessToken)).verified) && props.setBroadCastList();
    })();
  }, []);

  const { openBroadCastPanel } = props;

  const onCloseBroadCastPanel = () => {
    props.setBroadCastMessagePanel(false);
    setRecMsg("");
    setTeamRecipients([]);
    setUserRecipients([]);
    setTitle("");
    setMsgLink("")
  };

  const getSelectedTeams = (e, value) => {
    let broadCastRecLists = [...value]
    let recTeam = [];
    let recUser = [];
    recTeam = broadCastRecLists.filter((rec) => rec.recType == "team");
    recUser = broadCastRecLists.filter((rec) => rec.recType == "user");
    setTeamRecipients(recTeam);
    setUserRecipients(recUser);
  }

  const recMessage = (e) => {
    setRecMsg(e.target.value)
  }


  const setTitle = (e) => {
    let title = "";
    if (e !== undefined && e.target !== undefined  && e.target.value !== undefined && e.target.value !== "")
      title = e.target.value
    setMsgTitle(title)
  }
  const setMessageLink = (e) => {
    let link = "";
    if (e !== undefined && e.target !== undefined  && e.target.value !== undefined && e.target.value !== "")
      link = e.target.value
    setMsgLink(link)
  }
  const sendMessage = () => {
    let msgAll = false;
    let BroadCastMessageBody = [];
    if (!recipientMessage) {
      swal.fire("Message cannot be empty",);
    }
    else {
      BroadCastMessageBody = JSON.stringify(BroadCastMessageBody = {
        "message": recipientMessage,
        "link": msgLink,
        "title": msgTitle,
        "userRecipients": userRecipients,
        "teamRecipients": teamRecipients,
        "allUsersFlag": (teamRecipients.length == 0 && userRecipients.length == 0),
        "postedBy": props.adminTeams.user.UserName
      })
    }
    props.sendBroadCastMessage(BroadCastMessageBody).then((response) => {
      if (response) {
        if (response.data.status == "OK") {
          swal.fire(
            "Success",
            `BroadCast Message Sent`,
            "success"
          );
          onCloseBroadCastPanel();
        }
      }
      else {
        swal.fire(
          "Error",
          `ERROR , UNABLE TO BROADCAST`,
          "error"
        );
      }

    })
  }
  return (


    <Fragment>
      <Modal isOpen={openBroadCastPanel} toggle={onCloseBroadCastPanel} id="broadCastMessageModal" size="lg">
        <ModalHeader>BroadCast Message</ModalHeader>
        <ModalBody>
          <div id="Recipients" className="row">
            <div className="col-12">
              <Typography variant='span' classes={{ root: classes.recipientLabel }}>Recipients</Typography>

              <Autocomplete
                multiple
                id="BroadCastAutoComplete"
                options={props.broadCastList}
                getOptionLabel={(option) => option.name + option.broadCastIDNumber}
                onChange={(event, value) => getSelectedTeams(event, value)}
                placeholder="Enter user name or team name"
                loading={props.broadCastLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Enter user name or team name"
                  />
                )}
              />
            </div>


          </div>
          <div className="row">
            <div className="col-6">
              <div className="col-12">
                <Typography variant='span' classes={{ root: classes.recipientLabel }}>Title</Typography>
              </div>
              <div className="col-12">
                <TextField id="outlined-basic"
                  variant="outlined"
                  className="subjectText"
                  value={msgTitle}
                  onChange={e => setTitle(e)}
                  placeholder="Enter the title for the message"
                /></div></div>
            <div className="col-6">
              <div className="col-12">
                <Typography variant='span' classes={{ root: classes.recipientLabel }}>Add Link</Typography>

              </div>
              <div className="col-12">

                <TextField id="outlined-basic"
                  variant="outlined"
                  className="subjectText"
                  value={msgLink}
                  onChange={e => setMessageLink(e)}
                  placeholder="Please append http or https along with the link"
                /></div></div>

          </div>
          <div className="row my-l">
          <div className="col-12">
                <Typography variant='span' classes={{ root: classes.recipientLabel }}>Message</Typography>
              </div>

            <div className="col-12">
              <textarea maxlength="500"
                className="form-control"
                id="team-comment"
                rows="3"
                value={recipientMessage}
                onChange={(e) => recMessage(e)}
                placeholder="Start your message"></textarea>
              <Typography variant='span' classes={{ root: classes.maxChars }}>
                Max 500 chars
              </Typography>
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
          {teamRecipients.length == 0 && userRecipients.length == 0 && recipientMessage &&
            <Typography variant='span' classes={{ root: classes.allUsersLabel }}>
              This message will sent to all users
            </Typography>
          }
          <Button variant="contained" color="primary" onClick={sendMessage} endIcon={<SendIcon />}>Send</Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  )
}


const mapStateToProps = (state) => {
  return {
    openBroadCastPanel: state.auth.openBroadCastPanel,
    broadCastList: state.broadCastReducer.broadCastDetails.broadCastList,
    broadCastMessage: state.auth.broadCastMessage,
    broadCastLoading: state.broadCastReducer.broadCastDetails.pending
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setBroadCastMessagePanel: (value) => dispatch(setBroadCastMessagePanel(value)),
    sendBroadCastMessage: (value) => dispatch(sendBroadCastMessage(value)),
    setBroadCastList: () => dispatch(setBroadCastList())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BroadCastMessagePanel);