import _ from 'lodash';
import * as types from '../actions/actionTypes';
import { updateObjectReadFlag } from '../utils/notification';

const INITIAL_STATE = {
    notifications: [],
    summaryList: [],
    totalMessageCount: 0,
    totalUnreadMessageCount: 0,
    notificationFetchFail: false,
    notificationFetchLoading: false,
    notificationMessageDetails : {
        postedBy : "",
        postedAt : "",
        message : "",
        teamFlag : false,
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_NOTIFICATIONS_LOADING :
            return {
                ...state,
                notificationFetchLoading: true
            }
        
        case types.FETCH_NOTIFICATIONS_SUCCESS :
            return {
                ...state,
                notifications: action.payload.notifications,
                totalMessageCount: action.payload.totalMessageCount,
                totalUnreadMessageCount: action.payload.totalUnreadMessageCount,
                notificationFetchFail: false,
                notificationFetchLoading: false
            }
        
        case types.FETCH_NOTIFICATIONS_ERROR : 
            return {
                ...state,
                notificationFetchFail: true,
                notificationFetchLoading: false
            }

        case types.SET_SUMMARY_NOTIFICATIONS :
            return {
                ...state,
                summaryList: action.payload
            }

        case types.TOGGLE_READ_FLAG :
            return {
                ...state,
                notifications: updateObjectReadFlag(state.notifications, action, true)
            }

        case types.SET_UNREAD_NOTIFICATION_COUNT :
            return {
                ...state,
                totalUnreadMessageCount : action.payload
            }
        case types.VIEW_NOTIFICATION_MESSAGES : 
            return {
                ...state,
                notificationMessageDetails: {
                    postedBy : action.payload.postedBy,
                    postedAt : action.payload.postedAt,
                    message : action.payload.message,
                    teamFlag : action.payload.teamFlag
                },
            }
        case types.CLOSE_NOTIFICATION_MESSAGES : 
            return {
                ...state,
                notificationMessageDetails: {
                    postedBy : "",
                    postedAt : "",
                    message : "",
                    teamFlag : false
                },
            }
        
        default:
            return state;
    }
}