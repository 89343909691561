import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
// TODO: clean project to only use Metro tile styles - avoid conflicts with Bootstrap, have fun :-)
import 'metro4/build/css/metro.min.css';
import 'metro4/build/css/metro-colors.min.css';
import 'metro4/build/css/metro-icons.min.css';
import config from './config';

import Root from './Root';

ReactDOM.render(
    <Root>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Root>
, document.getElementById('root'));
{if(!config.is1TDM) registerServiceWorker();}