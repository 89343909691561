import React from 'react';
import Fab from '@material-ui/core/Fab';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    fabu: {
        position: 'fixed',
        bottom: '30px',
        right: '130px',
        zIndex: 5,
        width: 46,
        height: 46
    }
}));

function scrollTop() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}
export default function FloatingScroll (){
    const classes = useStyles();
    return (<Fab onClick={scrollTop} className={classes.fabu} color="primary" aria-label="add">
    <ArrowUpward />
</Fab>)

}