import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    editor: {},
    view: {}
}

// Salesforce basket reducer
export default(state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CREATE_SALESFORCE_BASKET_SUCCESS:
            return {
                ...state,
                editor: {basket: action.payload}
            };
        default:
            return state;
    }
}