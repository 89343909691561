import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import "./CustomerConsole.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { closeCaiman } from '../../../actions/utilityActions';
import {Button} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';

class CaimanSuccess extends Component {

    closeUpdateSuccess = () => {
        this.props.closeCaiman();
    }
      render() {
       return (
        <Modal isOpen={true} className="customerMailModal">
        <div className="modalcontent">
            <Fragment>
                <ModalHeader style={{ 'justifyContent': 'center' }}>
                    <strong>Caiman Register Succesfull</strong>
                </ModalHeader>
                <ModalBody className="modalbody" style={{ 'textAlign': 'center' }}>
                    <div className="row">
                        <div className="col-12" style={{ 'textAlign': 'center' }}>
                            <CheckIcon style={{ color: green[500] ,fontSize: 90 }}/>
                        </div>
                        <div className="col-12" style={{ 'textAlign': 'center' }}>
                            <label><strong>UserName : </strong></label>
                            <label>{this.props.userName}</label></div>
                            <div className="col-12" style={{ 'textAlign': 'center' }}>
                            <label><strong>Password : </strong></label>
                            <label>{this.props.password}</label></div>
                            <div className="col-12" style={{ 'textAlign': 'center' }}> <Button variant="contained" color="primary" onClick={e=>this.closeUpdateSuccess()}>OK</Button>
                        </div>
                    </div>
                </ModalBody>
            </Fragment>
        </div></Modal>   
       )
    }

}

const mapStateToProps = (state) => {
    return {
        userName : state.utility.siebelCustomer.caimanUserName,
        password : state.utility.siebelCustomer.caimanPassword
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeCaiman: () => dispatch(closeCaiman())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaimanSuccess);