import React from 'react'
import { Table } from 'reactstrap';
import { CheckoutHistoryRow } from './CheckoutHistoryRow';
import TableFilterController from '../common/filter/TableFilterController';

export const CheckoutHistoryTable = ({ tableData, detailView = false, dataBankCheckoutView = false, filters }) => {
    return (
        <React.Fragment>
        <Table hover responsive>
            <thead>
                <tr>
                    {(detailView && <th>Checkout ID</th>)}
                    <th>Environment</th>
                    <th>Type</th>
                    <th>Product</th>
                    <th>Class</th>
                    <th>Qty</th>
                    <th>Date</th>
                    {/* {dataBankCheckoutView &&
                        <th>Identifier</th>
                    } */}
                    <th>Success</th>
                    {(detailView && <th>Action</th>)}
                    {/* {dataBankCheckoutView &&
                        <th>Extra Info</th>
                    } */}
                </tr>
                {filters && <TableFilterController filters={filters} />}
            </thead>
            <tbody>
                {(tableData).map((checkout) => (
                    <CheckoutHistoryRow {...checkout} key={checkout.id} dataBankCheckoutView={dataBankCheckoutView} detailView={detailView} />
                ))}
            </tbody>
        </Table>
        </React.Fragment>
    )
}