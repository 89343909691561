import {fetchSnrmResourcesNoErrorDisplay as imsiAvailabilityCheck} from '../../../utils/asyncValidationAPI';

export const asyncFormImsiAvailabilityValidator = async(values, dispatch, props, currentFieldName) => {
    let errors = {};
    let startRange = values.imsi;
    let endRange = values.imsi;
    const page = 0;
    const env = props.auth.selectedEnvValue;
    try {
        if (values.imsi) {
            const response = await imsiAvailabilityCheck(startRange, endRange, page, env)
            if (response.totalElements && response.totalElements > 0) {
                errors['imsi'] = 'IMSI already exists in SNRM';
            }
        }
    } catch (e) {
        errors['imsi'] = `Failed to check IMSI - ${e}`;
        if (Object.keys(errors).length > 0) {
            throw errors;
        }
    }

    if (Object.keys(errors).length > 0) {
        throw errors;
    }
}
