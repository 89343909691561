import React, {Component} from 'react'
import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #00aaf3 0, #1964c8 100%)'
}

const UserTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-blue"
        data-size="large"
        to="/user/profile"
        data-effect="hover-zoom-down">

        <div class="slide-front">
            <span className="mif-user icon"></span>
        </div>
        <div class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
            <p class="text-center">
                View and manage your user profile
            </p>
        </div>
        <span className="branding-bar user">{props.name}</span>
        <span className="badge-bottom">{props.role}</span>
    </LinkTile>
)

UserTile.propTypes = {
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
}

export default UserTile;