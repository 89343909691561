import React from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, submit } from 'redux-form';
import { Row, Col } from 'reactstrap';
import { renderField } from '../common/fields/fields';
import { utilityCustomerCreditFormValidator as validate } from '../common/form/syncValidators';
import { Button } from 'reactstrap';
import {customerCreditUpdate as customerCreditUpdateSubmit} from '../../actions/utilityActions';
import swal from 'sweetalert2';

const UtilityCustomerCreditUpdate = (props) =>{
    const { handleSubmit, reset, customerCreditUpdate} = props;
    const formatDate = (date) =>{
        const d = new Date( date );
        return d.getDate() + "-" + d.toLocaleString('default', { month: 'short' }) + "-" + d.getFullYear();
    }
    const customerCreditUpdateSubmit = (formValues) => {
        let request = {
            'environment':props.selectedEnvValue
        };
        if(formValues.hasOwnProperty('customerId')){
            request['customerIds'] = [formValues.customerId];
        }
        if(formValues.hasOwnProperty('creditLimit')){
            request['creditLimit'] = formValues.creditLimit
        }
        if(formValues.hasOwnProperty('applicationDate')){
            let date = formatDate(formValues.applicationDate);
            request['applicationDate'] = date
        }
        props.customerCreditUpdateSubmit(request)
    }

    return (
        <form onSubmit={handleSubmit((formValues)=>{
            customerCreditUpdateSubmit(formValues)
                reset();
        })}>

            <div className="utility-input-area">
                <div className="utility-input-area-content">
                    <Row>
                        <Col xs="2">
                            <Field
                                label="Customer ID"
                                name="customerId"
                                tabIndex="1"
                                component={renderField}
                                autoComplete="off"
                                    required/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="2">
                            <Field
                                type="date"
                                label="Application Date"
                                name="applicationDate"
                                tabIndex="2"
                                component={renderField}
                                autoComplete="off"
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="2">
                            <Field
                                type = "text"
                                label="creditLimit"
                                name="creditLimit"
                                tabIndex="3"
                                component={renderField}
                                autoComplete="off"
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="6" sm="6">
                    <div>
                        { customerCreditUpdate.isCustomerCreditSuccess &&  customerCreditUpdate.message ? 
                        customerCreditUpdate.message
                        :null}
                    </div>
                    <div>
                        { customerCreditUpdate.isCustomerCreditError && customerCreditUpdate.message ?
                        customerCreditUpdate.message
                        :null}
                    </div>    
                        </Col>
                    </Row>

                    <Row>
                        <Col xs="4">
                            <Button 
                            color={customerCreditUpdate.isCustomerCreditPending ? 'secondary' : 'primary'} 
                            disabled={customerCreditUpdate.isCustomerCreditPending}  
                            tabIndex="4" 
                            className="submitButton" 
                            type="submit"
                            > 
                            {customerCreditUpdate.isCustomerCreditPending? (<span> Update <i class="fa fa-spinner fa-pulse fa-fw"></i> </span>)  :'Update'}
                            </Button>
                        </Col>
                    </Row>                                                     
                </div>
            </div>
        </form>
    )
}


const mapStateToProps = state =>{
    return {
        filters: state.utility.filters,
        selectedFilters: state.utility.selectedFilters,
        customerCreditUpdate: state.utility.customerCreditUpdate,
        selectedEnvValue: state.auth.selectedEnvValue,
        formValues: state.form && state.form.utility && state.form.utility.values,
        syncErrors: state.form && state.form.utility && state.form.utility.syncErrors,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        customerCreditUpdateSubmit : (request) => dispatch(customerCreditUpdateSubmit(request)),
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps), reduxForm({
    validate,
    form:'utility'
}))(UtilityCustomerCreditUpdate);