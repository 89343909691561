import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";

const useStyles = makeStyles({
    stateLabel: {
        backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
        color: "#ffffff  !important",
        marginBottom: "20px !important",
        marginLeft: "20px !important"
    },
    stateSelectedLabel: {
        marginBottom: "20px !important",
        backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
        color: "#ffffff !important",
        marginLeft: "20px !important"
    },
    productHeaderLabel: {
        fontSize: "24px !important",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingLeft: "20px !important"
    },
    yesSpecification: {
        width: "650px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    },
    rangeSpecification: {
        width: "300px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    },
    radioLabel: {
        marginLeft: "11px !important"
    }

});


const StatelessPostpaid = (props) => {

    const classes = useStyles();
    const [simTypes] = React.useState(["PHYSICAL SIM", "HYBRID SIM", "4G MSISDN/SIM", "5G SIM", "ESIM"])
    const [selectedSimType, setSelectedSimType] = React.useState("");
    const [postpaidType] = useState(["MSISDN", "SIM/IMSI"]);
    const [selectedPostpaidType, setSelectedPostpaidType] = useState("");
    const [postpaidSubType] = useState(["NETWORK", "LOOPBACK"]);
    const [selectedPostpaidSubType, setSelectedPostpaidSubType] = useState("");
    const [simSubTypes] = useState(["LOGICAL","PHYSICAL"]);
    const [selectedSimSubTypes , setSelectedSimSubTypes] = useState("");

    const [postpaidTextData,setPostpaidTextData]= useState("");

    const [labelText, setLableText] = useState("");


    const clearSubProducts = () => {    
        setPostpaidTextData("");
        setSelectedSimSubTypes("");
    }

    useEffect(() => {
        clearSubProducts();
    }, [props.clearPrepaid]);

    const setSpecReqData = (key, value) => {
        setLableText(key);
        props.setSpecificRequirement({ [key]: value }, "")
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Sim type </Typography>
                    {simTypes.map((post, index) => {
                        return (
                            <Button variant="contained" classes={post !== selectedSimType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                sx={{ minWidth: 150, minHeight: 150 }} value={post}
                                onClick={e => {
                                    setSelectedPostpaidSubType("");
                                    setSelectedPostpaidType("");
                                    setSelectedSimType(e.target.value); clearSubProducts();
                                    let key = "CUSTOM: VACANT POSTPAID " + (e.target.value).toUpperCase() +" DATA";
                                    setLableText(key);
                                    setSpecReqData(key, "");   
                                }}>
                                {post}
                            </Button>
                        )
                    })}
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={1} />
                {selectedSimType === "POSTPAID" ?
                    <Grid item xs={10}>
                        <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Sim Type </Typography>
                        {postpaidType.map((pp, index) => {
                            return (
                                <Button variant="contained" classes={pp !== selectedPostpaidType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                    sx={{ minWidth: 150, minHeight: 150 }} value={pp}
                                    onClick={e => {
                                        setSelectedPostpaidType(e.target.value);
                                        setSelectedPostpaidSubType("");
                                        setPostpaidTextData("");
                                    }}>
                                    {pp}
                                </Button>
                            )
                        })}

                    </Grid> : <>
                        {selectedSimType ? 
                        <TextField
                                id="selectedPostPaidType"
                                multiline
                                rows={2}
                                helperText="Enter the specific requirement"
                                label={labelText}
                                classes={{ root: classes.yesSpecification }}
                                value={postpaidTextData}
                                onChange={e => {
                                    setPostpaidTextData(e.target.value);
                                    let key = "CUSTOM: VACANT POSTPAID " + selectedSimType.toUpperCase() + " " +" DATA";
                                    setLableText(key);
                                    setSpecReqData(key, e.target.value)
                                }}
                            /> : <></>}
                    </>}
            </Grid>

          

            <Grid container spacing={3}>
                <Grid item xs={1} />
                {(selectedSimType !== "PHYSICAL SIM" &&( selectedSimType === "POSTPAID" && selectedPostpaidType !== "")) ?
                    <Grid item xs={10}>
                        <Typography classes={{ root: classes.productHeaderLabel }}>Please select the {selectedPostpaidType} Type </Typography>
                        {postpaidSubType.map((ppS, index) => {
                            return (
                                <Button variant="contained" classes={ppS !== selectedPostpaidSubType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                    sx={{ minWidth: 150, minHeight: 150 }} value={ppS}
                                    onClick={e => {
                                        setSelectedPostpaidSubType(e.target.value);
                                        let key = "CUSTOM: VACANT POSTPAID " + selectedPostpaidType.toUpperCase() + " " + (e.target.value).toUpperCase() + " DATA"
                                        setLableText(key);
                                        setSpecReqData(key, "");
                                        setPostpaidTextData("");
                                    }}>
                                    {ppS}
                                </Button>
                            )
                        })}

                    </Grid> : <></>}
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={1} />
                {selectedSimType !== "PHYSICAL SIM" && (selectedSimType !== "" && 
                selectedPostpaidType !== "" && 
                selectedPostpaidSubType !== "") ?
                    <Grid item xs={10}>
                        <TextField
                                id="selectedPostPaidType"
                                multiline
                                rows={2}
                                helperText="Enter the specific requirement"
                                label={labelText}
                                classes={{ root: classes.yesSpecification }}
                                value={postpaidTextData}
                                onChange={e => {
                                    setPostpaidTextData(e.target.value);
                                    let key = "CUSTOM: VACANT POSTPAID " + selectedSimType.toUpperCase() + " " + selectedPostpaidType.toUpperCase() + " "+selectedPostpaidSubType.toUpperCase()+" DATA";
                                    setLableText(key);
                                    setSpecReqData(key, e.target.value)
                                }}
                            />

                    </Grid> : <>
                    {selectedSimType !== "PHYSICAL SIM" && (selectedSimType !== "POSTPAID" && selectedSimType !== "") ?
                    <Grid item xs={10}>
                        {selectedSimSubTypes === "PHYSICAL" ?
                        <TextField
                                id="selectedPostPaidType"
                                multiline
                                rows={2}
                                helperText="Enter the raw ranges"
                                label={labelText}
                                classes={{ root: classes.yesSpecification }}
                                value={postpaidTextData}
                                onChange={e => {
                                    setPostpaidTextData(e.target.value);
                                    let key = "CUSTOM: VACANT POSTPAID " + selectedSimType.toUpperCase() + " " 
                                    + selectedSimSubTypes.toUpperCase() +" DATA";
                                    setLableText(key);
                                    setSpecReqData(key, e.target.value)
                                }}
                            /> : <>
                            {selectedSimSubTypes === "LOGICAL" ?
                        <TextField
                                id="selectedPostPaidType"
                                multiline
                                rows={2}
                                helperText="Enter specific requirement"
                                label={labelText}
                                classes={{ root: classes.yesSpecification }}
                                value={postpaidTextData}
                                onChange={e => {
                                    setPostpaidTextData(e.target.value);
                                    let key = "CUSTOM: VACANT POSTPAID " + selectedSimType.toUpperCase() + " " 
                                    + selectedSimSubTypes.toUpperCase() +" DATA";
                                    setLableText(key);
                                    setSpecReqData(key, e.target.value)
                                }}
                            /> : <></>}
                            </>}

                    </Grid> : <></>}
                    </>}
            </Grid>


        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
        prodData: (value) => dispatch(prodData(value)),
        setSpecificRequirement: (value, sID) => dispatch(setSpecificRequirement(value, sID))
    }
}

export default connect(null, mapDispatchToProps)(StatelessPostpaid);