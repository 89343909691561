import React, { Fragment, useState, useEffect } from 'react';
import { Box, Button, TextField, Paper, Grid, makeStyles, Tooltip } from "@material-ui/core";
import { connect } from 'react-redux';
import { selectCosmosSelectFilter, resetCosmosRecordsForm, setSearchValue, fetchCosmosRecords } from "../../../actions/cosmosActions"
import TilesFrame from "../../Data/accordion-frames/replicator/DataTilesFrame";
import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import swal from "sweetalert2";
import { displayLoading, displaySuccess } from "../../common/alerts/alerts";
import './cosmosRecords.css';
import ReactJson from 'react-json-view'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        marginLeft: '9px',
        marginTop: '30px'
    },
    button: {
        margin: theme.spacing(0.5),
    },
    teamTextInput : {
        "& .MuiInputBase-input.MuiOutlinedInput-input" :{
          height: "38px",
            lineHeight: "38px",
          padding : "0px 7px 0px 12.5px !important",
          backgroundColor : "white" 
         }
      }

}));

const CosmosRecords = (props) => {
    const classes = useStyles();
    useEffect(() => {
        props.resetCosmosRecordsForm();
    }, []);


    const searchByLabel = "Enter " + props.selectedFilters.searchBy;
    const filename = props.selectedFilters.searchBy;

    const handleSubmit = () => {
        let searchTypeLabel = props.selectedFilters.searchBy.toLowerCase();
        if (searchTypeLabel === "fnn")
            searchTypeLabel = "msisdn"
        displayLoading({ title: "Fetching Records", bodyText: "Please standby while we fetch Cosmos Records." })
        props.fetchCosmosRecords(searchTypeLabel, props.searchValue).then((response) => {
            swal.close();
            if (!response) {
                swal.fire(
                    "Error",
                    `ERROR WHILE FETCHING RECORDS`,
                    "error"
                    );
                }
            });
            props.setSearchValue("");

    }

    const selectSearchType = obj => {
        props.setSearchValue("");
        props.selectFilter("searchBy", obj.label);
    }
    return (
        <Fragment>
            <div className="row my-l">
                <Box>
                    <TilesFrame
                        heading={"Search By"}
                        color="green"
                        data={props.searchTypes}
                        select={selectSearchType}
                        selected={props.selectedFilters.searchBy}
                    />
                    {props.selectedFilters.searchBy !== undefined &&
                        <form className={classes.root} noValidate autoComplete="off">
                            <TextField
                                id="searchByText"
                                label={searchByLabel}
                                variant="outlined"
                                value={props.searchValue}
                                onInput={e => props.setSearchValue(e.target.value)}
                                className={classes.teamTextInput}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                startIcon={<SearchIcon />}
                                onClick={() => handleSubmit()}>
                                Search
      </Button>
                        </form>
                    }
                    {props.cosmosData.data.length == 0 && props.cosmosData.data !== "loading" && 
                    props.cosmosData.noRecords &&
                    <h3 className="mt-5 ml-5">
                               No Records Found
                                </h3>}
                    {props.cosmosData.data.length > 0 && props.cosmosData.data !== "loading" &&
                        <div className="container">
                            <h3 className="mt-5 ml-5">
                                Cosmos Records
                                </h3>
                            <div class="row cosmosRow mt-n5">
                                <div class="col-sm-6"></div>
                                <div class="col-sm-4"></div>
                                <div class="col-sm-2">
                                    <Tooltip title="Download Json">
                                        <span className="base-purple">

                                            <a
                                                href={`data: text/json;charset=utf-8, ${encodeURIComponent(JSON.stringify(props.cosmosData.data, null, '\t'))}`}
                                                download={`${filename}.json`}>
                                                <i className={"fa fa-file-code-o fa-2x theme-text-primary"} aria-hidden="true"></i>
                                            </a>
                                        </span></Tooltip>
                                </div></div>
                            <div id="cosmosJson" className="cosmosJson ml-5 p-5"><ReactJson src={props.cosmosData.data} /></div>
                        </div>}
                </Box></div>
        </Fragment>
    )

}
const mapStateToProps = (state) => {
    console.log("state")
    console.log(state.cosmosReport.cosmosRecords)
    return {
        searchTypes: state.cosmosReport.filters.searchTypes,
        selectedFilters: state.cosmosReport.selectedFilters,
        searchValue: state.cosmosReport.searchValue,
        cosmosData: state.cosmosReport.cosmosRecords
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectFilter: (key, value) => dispatch(selectCosmosSelectFilter(key, value)),
        resetCosmosRecordsForm: () => dispatch(resetCosmosRecordsForm()),
        setSearchValue: (value) => dispatch(setSearchValue(value)),
        fetchCosmosRecords: (type, value) => dispatch(fetchCosmosRecords(type, value))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CosmosRecords);

