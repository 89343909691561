import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import swal from "sweetalert2";
import { displayLoading,displayErrorNew,displaySuccess } from "../../common/alerts/alerts";
import { Grid } from "@mui/material";
import {submitChargeCycle} from "../../../actions/utilityActions"

const useStyles = makeStyles({

    yesSpecification: {
        width: "400px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    },
    ccGrid: {
        paddingLeft: "40px !important"
    },
    submitButton : {
        borderColor: "#007bff",
        marginTop: "35px !important",
        color : "white !important"
    }

});
const ChangeCycleUpdate = (props) => {
    const classes = useStyles();
    const [orderId, setOrderId] = useState("");
    const [ccValue, setCCValue] = useState("");
    const [orderError, setOrderError] = useState(false);
    const [ccError, setCCError] = useState(false);
    const [ccText, setCCText] = useState("Enter a date numerical (0-31)")

    const handleCCChange = (e) => {
        if (e.target.value === undefined || e.target.value === "" || e.target.value === null) {
            setCCError(true);
            setCCText("Value cannot be null")
        }
        else {
            if (isNaN(e.target.value)) {
                setCCError(true);
                setCCText("Please enter a numerical value")
            }
            else {
                if (parseInt(e.target.value) > 31) {
                    setCCError(true);
                    setCCText("Value cannot be higher than 31")

                }
                else {
                    if (parseInt(e.target.value) < 1) {
                        setCCError(true);
                        setCCText("Value cannot be lesser than 1")

                    }
                    else {
                        setCCError(false);
                        setCCText("Enter a date numerical (0-31) ")
                    }
                }
            }
        }
        setCCValue(e.target.value)
    }

    const handleSubmit = () => {
        displayLoading({ title: "Updating Charge Cycle", bodyText: "Please standby" });
        const data = {
            "orderNumber": orderId,
            "environment": props.selectedenv,
            "chargeCycleValue": ccValue
        }
        props.submitChargeCycle(data).then((response) => 
        {
            if(response !== undefined  && response.status == 200)
            {
                displaySuccess({ title: "Numbers Updated", text: `${response.data.responseDescription}` });
 
            }
            else
            {
                displayErrorNew({
                    title: "Error",
                    subTitle: response.data.responseDescription,
                  })
            }
            setOrderId("");
            setOrderError(false);
            setCCValue("");
            setCCError(false);
            setCCText("Enter a date numerical (0-31)");

        })
    }

    const ChargeCycleUpdateBtn=()=>{
            return <Button variant="contained" 
                        color="primary"
                        classes={{ root: classes.submitButton }}
                        disabled={!(ccValue !== "" && orderId !== "" && !ccError && !orderError)}
                        onClick={e => handleSubmit()}>Submit</Button>
    }
    return (
        <> <div className="dataview-header pl-9">
            <div className="container">
                <div class="row">
                    <div class="col-10">
                        {props.changeStyle ? "Bill Cycle Update" :  "Charge Cycle Update"}</div></div></div></div>

            <Grid container spacing={3} classes={{ root: classes.ccGrid }}>
                <Grid item xs={5}>
                    <TextField
                        id="orderId"
                        helperText={orderError ? "Order Id cannot be empty" : "Enter the order id"}
                        error={orderError}
                        label="Order Id"
                        classes={{ root: classes.yesSpecification }}
                        value={orderId}
                        onChange={e => {
                            if (e.target.value === undefined || e.target.value === "" || e.target.value === null) { setOrderId(e.target.value); setOrderError(true) }
                            else { setOrderId(e.target.value); setOrderError(false) }
                        }}
                    /></Grid>
                <Grid item xs={5}>
                    <TextField
                        id="ChargeCycleValue"
                        helperText={ccText}
                        error={ccError}
                        label="Charge Cycle Value"
                        classes={{ root: classes.yesSpecification }}
                        value={ccValue}
                        onChange={e => {
                            handleCCChange(e)
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <ChargeCycleUpdateBtn/>
                </Grid>
            </Grid>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
      selectedenv: state.auth.selectedEnvValue,
      user: state.auth.userName
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      submitChargeCycle: (value) => dispatch(submitChargeCycle(value))
    }
  }


export default connect(mapStateToProps, mapDispatchToProps)(ChangeCycleUpdate);