import React from 'react';

import {Line} from 'react-chartjs-2';

export default({labels, data, label}) => {
    const chartData = {
        labels,
        datasets: [
            {
                label: label,
                data,
                backgroundColor: "rgba(93, 188, 210, 0.51)",
                borderColor: "#3e95cd",
                datalabels: {
                    backgroundColor: function (context) {
                        return context.dataset.borderColor;
                    },
                    borderRadius: 4,
                    color: 'white',
                    font: {
                        weight: 'bold'
                    },
                    formatter: Math.round
                }
            }
        ]
    }
    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        }
    }
    // if (checkouts) {     const labels = Object.keys(checkouts);     const rawData
    // = Object.values(checkouts);     chartData = {         labels,
    // datasets: [             {                 label: 'Total Checkouts',
    //       rawData,                 borderColor: "#3e95cd",
    // backgroundColor: "transparent"             }         ]     } }

    return (<Line data={chartData} options={options}/>);
}