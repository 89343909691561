
import React from 'react'
import { Fade, Collapse } from 'reactstrap';
import { TelstraIcon } from '../../common/icons/GradientIcons';
import './optionalFrame.css';

const OptionalFrame = (props) => {
    const {
        children,
        heading,
        displayHeading = true,
        icon,
        collapsed = true,
        collapseIcon = `ui-${collapsed ? 'down' : 'right'}-arrow-thick`,
        collapseColor = 'blue',
        handleOnClick,
        optionalView,
    } = props;
    const isOpen = optionalView;
    return (
        <Fade in={true}>
            {displayHeading && <span
                data-testid="heading"
                className={"dataview-header collapsible"}
                style={isOpen ? { cursor: 'pointer' } : {}}
                onClick={handleOnClick} >
                <p className="ml-2 sub-leader font-weight-light">{heading}</p>
                {icon}
                <TelstraIcon data-testid="collapseIcon" primary icon={collapseIcon} color={collapseColor} />
            </span>}
            <Collapse isOpen={isOpen} data-testid="collapse">
                {children}
            </Collapse>
        </Fade>
    )
}

export default OptionalFrame;