import React from 'react';
import {SubHeader} from "../../../../common/custom/SubHeader";
import {Table} from "reactstrap";

export default function OrderTable({ orderDetails, getValueOfName,customerId, ban,  serviceMigLoading, smBtnInfo, smReq, handleServiceMigration, assetDetails }) {
   const smBtnProps = {serviceMigLoading, smBtnInfo, smReq, handleServiceMigration};
    return(
        <React.Fragment>
        {(orderDetails !== null && orderDetails !== undefined  && orderDetails.length > 0 &&
            <React.Fragment>
                <SubHeader smBtnProps={smBtnProps} text={"Orders"} iconClass={"td-icon-md icon-shop-online"} iconColor={"purple"} filename={`SomeFileName-ORDER`} />
                <Table responsive hover>
                    <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Customer ID</th>
                        <th>Plan</th>
                        <th>Billing Account ID</th>
                    </tr>
                    </thead>
                    <tbody>
                    {orderDetails.map(eachOrder =>
                        <tr key={ eachOrder.orderId }>
                            <td scope="row">{eachOrder.orderId}</td>
                            <td>{customerId || getValueOfName(eachOrder.additionalDetails, "Customer")}</td>
                            <td>{assetDetails.serviceId.id}, {assetDetails.serviceId.serviceDescription}</td>
                            <td>{ban || getValueOfName(eachOrder.additionalDetails, "BILLING_ACCOUNT")}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </React.Fragment>

        )}
        </React.Fragment>
    );
}