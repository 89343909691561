import * as types from './actionTypes';
import {axiosTaasInstance as axios} from "../axios.config";

import config from '../config';
import { authHeaders } from "../utils/api";

const API_URL = config.api.uri;
const GATEWAY_URL = config.tdm_gateway_api.uri + '/tdm';

export const addDDCColumns = (value) => async dispatch => {
    try {
        dispatch({ type: types.ADD_DDC_COLUMNS, payload: value });
    } catch (e) { console.error(e); }
};

export const deleteDDCColumns = (value) => async dispatch => {
    try {
        dispatch({ type: types.UPDATE_DDC_COLUMNS, payload: value })
    }
    catch (e) { console.error(e); }
}
export const resetColumns = () => async dispatch => {
    try {
        dispatch({ type: types.RESET_DDC_COLUMNS })
    }
    catch (e) { console.error(e); }
}
export const setDDCValues = (msisdnList, simList, cidnList,banList, planNameList, pcList) => async dispatch => {
    try {
        dispatch({ type: types.RESET_BILLING_COLUMNS })
        dispatch({ type: types.RESET_ASSETS_COLUMNS })
        dispatch({ type: types.SET_DDC_COLUMNS, msisdnList: msisdnList, simList: simList, cidnList: cidnList,banList: banList, planNameList: planNameList, pcList: pcList })
    }
    catch (e) { console.error(e); }
}
export const updateBillingDetails = (billingInput) => async dispatch => {
    try {
        dispatch({ type: types.SET_BILLING_DATA, payload: billingInput })
    }
    catch (e) { console.error(e); }
}
export const updateAssetsDetails = (assetsInput) => async dispatch => {
    try {
        dispatch({ type: types.SET_ASSETS_DATA, payload: assetsInput })
    }
    catch (e) { console.error(e) }
}

export const createDDC = (ddcJson) => async dispatch => {
    try{
        const response = await axios.post(`${GATEWAY_URL}/ddc/tddc/create`, ddcJson, { headers: authHeaders() })
        if(response.status === 200)
        {dispatch({type : types.RESET_BILLING_ASSET_DATA})
            return true;}
    }
    catch(e) {
        console.error(e);
        return false
    }
}
