import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './RcrFileProcess.css'

const useStyles = makeStyles((theme) => ({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    }
}));

const RcrExpandableInfoSection = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>

            <CardHeader
                title={<Fragment><InfoOutlinedIcon fontSize="large" color="primary"/> RCR Processing Utility (v1)</Fragment>}
            />
            <CardContent>
                <Typography id="initialParagraph" gutterBottom={false}>
                    TDM team has developed Utility which will automate processing RCR (Recurring charges) for B2C orders/services.
                </Typography>
                <Typography paragraph>
                    By using this Utility Recurring charges records will be processed automatically every 2 hours for submitted requests.
                </Typography>
                <Typography paragraph>
                    TDM team has achieved this by Mocking records created by OCS and processing them via Bamboo job created by (Team Marina) which will in turn run RCR lambda jobs in SIT environment.
                </Typography>
                <Typography paragraph>
                    <b>This utility will process below recurring charges :</b>
                </Typography>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent style={{"padding":"0px 16px 0px 16px"}}>
                        <Typography paragraph gutterBottom={false} style={{"marginBottom":"0px"}}>
                            <b>1.</b> This utility will process Mobile Postpaid orders triggered by B2C salesforce.
                        </Typography>
                        <Typography paragraph gutterBottom={false} style={{"marginBottom":"0px"}}>
                            <b>2.</b> TDM will be processing records corresponding to below charging specs
                        </Typography>
                        <CardContent style={{"padding":"0px 16px 0px 16px"}}>
                            <Typography style={{"padding":"0px 30px"}} paragraph gutterBottom={false}>
                                <ul><li>Mobile Subscription (Mobile Plan) recurring charges (Mobile plan i.e. chargingSpecType = MobileSubscription)</li></ul>
                                <ul><li>Mobile Device repayment recurring charges (Mobile plan i.e. chargingSpecType = RepaymentOption)</li></ul>
                            </Typography>
                        </CardContent>
                        <Typography gutterBottom={true}>
                            <b>3.</b> Known Limitations: This version of RCR processing utility will be limited to below conditions. These limitations can be implemented as enhancement in future if there is requirement with significant returns. if your testing includes below or has additional requirement other than above products then please follow current manual approach of reaching U2C test team for RCR processing.
                        </Typography>
                        <CardContent style={{"padding":"0px 16px 0px 16px"}}>
                            <Typography style={{"padding":"0px 30px"}} paragraph gutterBottom={false}>
                                <ul><li>There should be no Disconnect/Modify orders triggered for Mobile Plan/ Device repayments. as we get records from WTC APIs & Subscription APIs, sometimes there will be discrepancies due to modify disconnect orders.</li></ul>
                                <ul><li>This utility will ignore recurring charges for below products (add on subscriptions)</li>
                                    <ul><li>International Roaming Day pass</li></ul>
                                    <ul><li>Telstra One Number</li></ul>
                                </ul>
                            </Typography>
                        </CardContent>

                        <Typography gutterBottom={true}>
                            <b>4.</b> Any issue in bamboo job or functionality will be still supported by Team Marina/ U2C test team.
                        </Typography>
                        <div style={{"width":"100%", "textAlign":"center", "color":"#e44d13"}}>
                            <FormControlLabel control={<Checkbox checked={props.terms} onChange={(event) => props.readTerms(event.target.checked)} name="terms" />} labelPlacement="end" label="I have read all of the above and agree that I have taken necessary steps"/>
                        </div>
                    </CardContent>
                </Collapse>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    id="expandInfoBtn"
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                <ExpandMoreIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
}

export default RcrExpandableInfoSection;
