import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip'
import _ from 'lodash';
import { checkProfileThenCallAction } from './DataViewCustomerSf';

class DataViewCustomerSfBtn extends React.Component {
    render() {
        const { props } = this;
        const { uniqueId, salesforceCustomerDetails, masterId, cidn, sfImporting } = props.customer;
        const activeProfileEnv = props.activeProfile.env;
        const { salesforceId, environment } = salesforceCustomerDetails ? salesforceCustomerDetails : {};
        const incomplete = !(masterId && cidn);
        const detailsShowing = props.active;
        const collapseClick = props.click;
        const importFn = props.import;
        const isNosqlImport = true;
        return (
            <span
                data-tip={"Master ID and CIDN must be available for Salesforce import"}
                data-tip-disable={!incomplete}
                data-for={uniqueId}>
                <button
                    disabled={(incomplete) || sfImporting}
                    onClick={() => {
                        if (!salesforceId) {
                            checkProfileThenCallAction(() => importFn(props.customer, props.assetId, activeProfileEnv, this.props.activeProfile,isNosqlImport), this.props.isProfileSelected, isNosqlImport);
                        } else {
                            collapseClick(uniqueId, salesforceId, environment)
                        }
                    }}
                    className={classNames("btn", "btn-sm", "btn-block",
                        `btn-outline-${incomplete ? 'secondary' : salesforceId ? 'success' : 'primary'}`,
                        "ld-over", { running: sfImporting })}>
                    <i className={`fa fa-${salesforceId ? (detailsShowing ? 'minus' : 'eye') : 'cloud'}`}
                        aria-hidden="true"></i>{` `}{sfImporting ? 'Importing' : (salesforceId ? 'View' : 'Import')}
                    <div className="ld ld-ring ld-spin"></div>
                </button>
                <ReactTooltip type="error" effect="solid" place="bottom" id={uniqueId} />
            </span>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const activeProfile = state.profiles.active;
    const isProfileSelected = _.isEmpty(activeProfile) || (activeProfile == null);
    return {
        activeProfile,
        isProfileSelected: !isProfileSelected
    }
}

export default connect(mapStateToProps, null)(DataViewCustomerSfBtn);
