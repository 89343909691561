import React from "react";

import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { Badge } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const NotificationBell = (props) => {
  const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 24,
      top: 20,
      padding: "0 4px",
    },
  }))(Badge);

  return (
    <StyledBadge badgeContent={props.unreadMsgCount} color={"secondary"}>
      <a className="nav-link icon-link featured-gradient active notifications" aria-current="page">
        <i className="td-icon-sm icon-notification" />
      </a>
    </StyledBadge>
  );
};

export default NotificationBell;
