import React from "react";
import PropTypes from "prop-types";

import { Avatar, Box, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledAvatar = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    width: (props) =>
      props.size === "small" ? theme.spacing(5) : theme.spacing(7),
    height: (props) =>
      props.size === "small" ? theme.spacing(5) : theme.spacing(7),
  },
}))(Avatar);

const getImageAvatar = (firstName, lastName, profilePic, size) =>
  lastName ? (
    <StyledAvatar alt={`${firstName} ${lastName}`} src={profilePic} size={size} />
  ) : (
    <StyledAvatar alt={`${firstName}`} src={profilePic} size={size}/>
  );

const getLetterAvatar = (firstName, lastName, size) => (
  <StyledAvatar size={size}>
    {firstName[0]}
    {lastName ? lastName[0] : null}
  </StyledAvatar>
);

const defaultSize = "small";
const UserNameWithAvatar = (props) => {
  const { firstName, lastName, profilePic, size = defaultSize } = props;

  return (
    <Box px={5} display="flex" justifyContent="flex-start" alignItems="center">
      {profilePic
        ? getImageAvatar(firstName, lastName, profilePic, size)
        : getLetterAvatar(firstName, lastName, size)}
      <Typography>&nbsp; &nbsp;</Typography>
      <Typography variant={ size === "small"? "body2" : "h6" } color="textPrimary">
        {lastName ? `${lastName}, ` : null}
        {firstName}
      </Typography>
    </Box>
  );
};

UserNameWithAvatar.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  profilePic: PropTypes.string,
};

export default UserNameWithAvatar;
