import './Dashboard.css';

import React, { Component } from 'react';

import ApiDocsTile from '../common/tiles/ApiDocsTile';
import CheckoutHistoryTile from '../common/tiles/CheckoutHistoryTile';
import LoginTile from '../common/tiles/LoginTile';
import { Redirect } from 'react-router-dom';
import RegisterTile from '../common/tiles/RegisterTile';
//import SettingsTile from '../common/tiles/SettingsTile';
import StatsTile from '../common/tiles/DataStatsTile';
import MyOrdersTile from '../common/tiles/MyOrdersTile';
import UserTile from '../common/tiles/UserTile';
import { connect } from 'react-redux';
import { fetchFeedbacks } from '../../actions/feedbackAction';
import { fetchAllTeams } from '../../actions/myTeamsActions'
import { setLocation } from "../../actions/userFeedbackActions";
import UtilityTile from "../common/tiles/UtilityTile";
import OrderBuilderTile from "../common/tiles/OrderBuilderTile";
import MyTeamTile from "../common/tiles/MyTeamTile";
import OpsTile from '../common/tiles/OpsTile';
import { fetchSfProfiles } from '../../actions/profileActions';

import { setUserTrainingBadge } from '../../actions/trainingBadgesAction.js'
import MyTeamNoSqlTile from "../common/tiles/MyTeamNoSqlTile";
import StandardDataCheckoutHistoryTile from "../common/tiles/StandardDataCheckoutHistoryTile"
import config from "../../config";
import QuestionnaireTile from '../common/tiles/QuestionnaireTile';
import PasmatTrendTile from '../common/tiles/PasmatTrendTile';
import UserTrainingTile from '../common/tiles/UserTrainingTile'
import CustomDataTile from '../common/tiles/CustomDataTile'
import TestimonialTile from '../common/tiles/TestimonialTile'
import CarouselMsg from './Carousal/Carousal';
import StatisticsTile from "../common/tiles/Statistics";
import CustomCheckoutHistoryTile from "../common/tiles/CustomCheckoutHistoryTile"
import Testimonials from '../Testimonials'
import SyntheticDataGeneratorTile from '../common/tiles/SyntheticDataGeneratorTile';

class Dashboard extends Component {

    componentDidMount() {
        document.title = "TDM Portal - Dashboard"
        //Load salesforce profiles
        if (this.props.profiles.active && !this.props.profiles.active.id) {
            this.props.fetchProfiles();
            this.props.fetchAllTeams();
        }
        this.props.setLocation("Dashboard");
    }
    render() {

        let carousalItems = this.props.notifications.filter((itm) => itm.title !== null
            && itm.readFlag == false && itm.title !== undefined)
        carousalItems.map((itm, index) => {
            itm["userId"] = this.props.userId;
        }
        )
        return (
            <React.Fragment>
                <Testimonials />
                <div className="row black">
                    <div className="col-12">
                        <h1 className="page-header">{this.props.auth.isAuthenticated
                            ? 'Dashboard'
                            : <Redirect to="/"></Redirect>}</h1>
                    </div>
                    {this.props.totalUnreadMessageCount > 0 && carousalItems.length > 0 &&
                        <CarouselMsg name={this.props.auth.name.replace(/,(?=[^\s])/g, ", ")} carousalItems={carousalItems} />}
                    <div
                        className={this.props.isAuthenticated && 'col-6'}>
                        <div className="tiles-grid">
                            {this.props.auth.isAuthenticated && (
                                <UserTile name={this.props.auth.name.replace(/,(?=[^\s])/g, ", ")} role={this.props.auth.primaryRole}/>
                            )}
                            {this.props.isAdmin && <StatsTile />}
                            {this.props.auth.isAuthenticated && <MyOrdersTile />}
                            {this.props.isAdmin && <OpsTile/>}
                            {this.props.auth.isAuthenticated && <MyTeamNoSqlTile/>}
                            {this.props.auth.isAuthenticated && <OrderBuilderTile />}
                             {/* {this.props.auth.isAuthenticated && this.props.roles.some((_item)=>(_item==="ADMIN" || _item==="OPS")) &&(
                                <MyTeamTile isAdmin={this.props.isAdmin}></MyTeamTile>
                            )} */}
                        </div>
                    </div>
                    {this.props.auth.isAuthenticated && (
                        <div className="col-6">
                            <div className="tiles-grid">
                                <StandardDataCheckoutHistoryTile />
                                <ApiDocsTile />
                                <UserTrainingTile />
                                {this.props.isUser && <UtilityTile />}
                                {this.props.isAdmin && (
                                    <>
                                        <QuestionnaireTile disabled/>
                                        <PasmatTrendTile disabled/>
                                    </>
                                )}
                                <CustomDataTile />
                                    <CustomCheckoutHistoryTile/>
                                {this.props.isAdmin && (
                                    <TestimonialTile />
                                )}
                                <StatisticsTile disabled/>
                                    <SyntheticDataGeneratorTile/>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        isAdmin: state.auth.isAdmin,
        isUser: state.auth.isUser,
        isAuthenticated: state.auth.isAuthenticated,
        profiles: state.profiles,
        userId: state.auth.userName,
        dashboardNotificationReadStatus: state.dashboardCarousel.dashboardNotificationReadStatus,
        notifications: state.notification.notifications,
        totalUnreadMessageCount: state.notification.totalUnreadMessageCount
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFeedbacks: () => dispatch(fetchFeedbacks()),
        fetchProfiles: () => dispatch(fetchSfProfiles()),
        fetchAllTeams: () => dispatch(fetchAllTeams()),
        setLocation: (path) => dispatch(setLocation(path)),
        setUserTrainingBadge: (badge) => dispatch(setUserTrainingBadge(badge))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
