import * as types from './CONSTANTS';

const initialState = {
    fetching: false,
    fetched: false,
    checkOutHistory:{},
    errors:null,
    teamId:'',
    isUserToggle: false,
    isFilterByUserStory: false,
    filter: {
        fdata: {},
        floading: {},
        factive: []
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_BANK_CHECKOUT_HISTORY:
            return {
                ...state,
                fetching: true
            }
        case types.FETCH_DATA_BANK_CHECKOUT_HISTORY_FULFILLED:
            const { filters, ...restData } = action.payload;
            return {
                ...state,
                fetching: false,
                fetched: true,
                checkOutHistory: action.payload,
                errors: null,
                filter: {
                    ...state.filter,
                    factive: filters,
                    fdata: {}
                }
            }
        case types.FETCH_DATA_BANK_CHECKOUT_HISTORY_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched : false,
                errors: action.payload
            }
            case types.SELECT_TABLE_FILTER:
                const { key, value } = action.payload;
                return {
                    ...state,
                    selectedFilter: {
                        ...state.selectedFilter,
                        [key]: value
                    }
        }

        case types.DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_SUCCESS:
            const filterKey = action.payload.key;
            const filterData = action.payload.data;
            return {
                ...state,
                isFilterByUserStory: action.isFilterByUserStory,
                filter: {
                    ...state.filter,
                    fdata: {
                        ...state.filter.fdata,
                        [filterKey]: filterData
                    },
                    floading: {
                        ...state.filter.floading,
                        [filterKey]: false
                    }
                }
            }
        case types.DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_LOADING:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    floading: {
                        ...state.filter.floading,
                        [action.key]: action.loading
                    }
                }
            }
        case types.DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_ERROR:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    fdata: {
                        ...state.filter.fdata,
                        [action.key]: []
                    },
                    floading: {
                        ...state.filter.floading,
                        [action.key]: false
                    }
                }
        }
        case types.DATA_BANK_CHECKOUT_TEAM_ID:
            return {
                ...state,
                teamId: action.payload
            }
        case types.DATA_BANK_CHECKOUT_IS_TOGGLE_USER:
            return {
                ...state,
                isUserToggle: action.payload
            }
        default:
            return state
    }
}


