import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    somResourceStubDelete: {
        isDeleteFailed: false,
        isDeleteSuccess: false,
        message: null,
        response: {}
    },
    somResourceStubAddition: {
        isAddFailed: false,
        isAddSuccess: false,
        message: null,
        response: {}
    },
    somResourceTypeFilters : [
        { label: "MSISDN", icon: "icon-mobile" },
        { label: "FNN", icon: "icon-mobile-device-mgmt" }
    ],
    somInputTypeFilters : [
        { label: "Individual", icon: "icon-mobile" },
        { label: "Range", icon: "icon-mobile-device-mgmt" }
    ],
    fetchSearchPending: false,
    fetchSearchFailed: false,
    searchResources: {}
}


export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case types.SOM_RESOURCE_STUB_CLEAR:
            return {
                ...INITIAL_STATE
            }
        case types.SOM_RESOURCE_STUB_ADDITION_SUCCESS:
            return {
              ...state,
              somResourceStubAddition: {
                isAddFailed: false,
                isAddSuccess: true,
                message: action.payload,
                response: action.payload
              }
            }
        case types.SOM_RESOURCE_STUB_ADDITION_FAIL:
            return {
                ...state,
                somResourceStubAddition: {
                    isAddFailed: true,
                    isAddSuccess: false,
                    message: action.payload,
                    response: action.payload
                }
            }
        case types.SOM_RESOURCE_STUB_DELETE_SUCCESS:
            return {
              ...state,
              somResourceStubDelete: {
                isDeleteFailed: false,
                isDeleteSuccess: true,
                message: action.payload,
                response: action.payload
              },
            }
        case types.SOM_RESOURCE_STUB_DELETE_FAIL:
            return {
                ...state,
                somResourceStubDelete: {
                    isDeleteFailed: true,
                    isDeleteSuccess: false,
                    message: action.payload,
                    response: action.payload
                },
            }
        case types.SOM_RESOURCE_STUB_SEARCH_SUCCESS:
            return {
                ...state, 
                  fetchSearchPending: false,
                  fetchSearchFailed: false,
                  searchResources: {
                    success: true,
                    content: action.payload.content || action.payload,
                    isFirst: action.payload.first,
                    isLast: action.payload.last,
                    currentPage: (action.payload.pageable && action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber + 1 : 1),
                    currentPageSize: action.payload.pageable && action.payload.pageable.pageSize ? action.payload.pageable.pageSize : 0,
                    totalItems: action.payload.totalElements,
                    totalPages: action.payload.totalPages,
                    loading: false
                  }
            }
        case types.SOM_RESOURCE_STUB_SEARCH_FAIL:
            return {
                ...state, 
                  fetchSearchPending: false,
                  fetchSearchFailed: true,
                  searchResources: {}
            }
        case types.SOM_RESOURCE_STUB_SEARCH_PENDING:
            return {
                ...state, 
                  fetchSearchPending: true,
                  fetchSearchFailed: false,
                  searchResources: {}
            }
        default:
            return state;
    }
}
