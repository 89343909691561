import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import swal from 'sweetalert2';
import _ from "lodash";
import { Table } from 'reactstrap';
import TableFilterController from '../common/filter/TableFilterController';
import { retrieveServiceIds, selectFilter, selectCustomTeamFilter, checkoutTeam, checkoutServiceFilters, checkoutServiceFilterValue } from "../../actions/standardDataMyTeamsActions";
import { checkoutTeam as sqlCheckoutTeam } from "../../actions/myTeamsActions";
import TeamDataTableBody from "./TeamDataTableBody";
import { ToggleButtonLabel } from "./StandardDataBankMyTeam";
import { displayLoading } from "../common/alerts/alerts";

class MyTeamTable extends Component {
  state = {
    selectedFilters: {},
    buttonName: "Sort",
    selectedCustomFilters: {},
  }
  componentDidMount() {
    this.setState({
      selectedFilters: this.props.selectedFilters,
      selectedCustomFilters: this.props.selectedCustomFilter    });
  }
  handleAllocateToTeamModal = (teamRowData) => {
    this.allocateModal.open(teamRowData);
  }
  selectFiltersOption = (obj, selectedFiltersArrayForm = []) => {
    this.setState((preState) => {
      const reducedSelectedFilter = selectedFiltersArrayForm.reduce((acc, current) => ({ ...acc, [current.field]: current.value || null }), {});
      const isCustomToggle = this.props.selectedToggleButton === ToggleButtonLabel.CUSTOM
      const selectedFilters = isCustomToggle ? this.props.selectedCustomFilter : this.props.selectedFilter;
      const selectedFilterLocal = !_.isEmpty(selectedFiltersArrayForm) ? _.merge(selectedFilters, reducedSelectedFilter) : []
      const selectedFilterToReturn = !_.isEmpty(selectedFilterLocal) ? selectedFilterLocal : {};
      if (this.props.selectedToggleButton === ToggleButtonLabel.CUSTOM) {
        this.props.selectCustomTeamFilter(selectedFilterToReturn);
        return { ...preState, selectedCustomFilters: selectedFilterToReturn };
      } else {
        this.props.selectFilter(selectedFilterToReturn);
        return { ...preState, selectedFilters: selectedFilterToReturn }
      }
    })
  }
  prepareFilterData = (filterData, selectedFilters) => {
    const filteredDataBasedOnField = filterData.filter((data) => {
      const isTeamNameEqual = !!selectedFilters.teamName ? selectedFilters.teamName === data.teamName : true;
      const isServiceId = !!selectedFilters.serviceId ? selectedFilters.serviceId === data.serviceId : true;
      const isProductCode = !!selectedFilters.serviceProductCode ? selectedFilters.serviceProductCode === data.serviceProductCode : true;
      const isDescription = !!selectedFilters.description ? selectedFilters.description === data.description : true;
      const isQuantity = !!selectedFilters.quantity ? selectedFilters.quantity === data.quantity : true;
      return isTeamNameEqual        && isServiceId        && isProductCode        && isDescription        && isQuantity;
    })
    const teamNameOptions = _.uniq(filteredDataBasedOnField.map(data => data.teamName)).filter(n => n);
    const serviceIdOptions = _.uniq(filteredDataBasedOnField.map(data => data.serviceId)).filter(n => n);
    const descriptionOptions = _.uniq(filteredDataBasedOnField.map(data => data.description)).filter(n => n);
    const quantityOptions = _.uniq(filteredDataBasedOnField.map(data => data.quantity)).filter(n => n);
    const prodCodeOptions = _.uniq(filteredDataBasedOnField.map(data => data.serviceProductCode)).filter(n => n);
    const filters = [
      { field: "teamName", type: "dropdown", onChange: this.onChange, onSelect: this.selectFiltersOption, options: teamNameOptions },
      { field: "serviceId", type: "dropdown", onChange: this.onChange, onSelect: this.selectFiltersOption, options: serviceIdOptions },
      { field: "serviceProductCode", type: "dropdown", onChange: this.onChange, onSelect: this.selectFiltersOption, options: prodCodeOptions },
      { field: "description", type: "dropdown", onChange: this.onChange, onSelect: this.selectFiltersOption, options: descriptionOptions },
      { field: "quantity", type: "dropdown", onChange: this.onChange, onSelect: this.selectFiltersOption, options: quantityOptions },
    ]
    return filters;
  }
  prepareTableData = (filterData, selectedFilters, buttonName) => {
    const filteredDataBasedOnField = filterData.filter((data) => {
      const isTeamNameEqual = !!selectedFilters.teamName ? selectedFilters.teamName === data.teamName : true;
      const isServiceId = !!selectedFilters.serviceId ? selectedFilters.serviceId === data.serviceId : true;
      const isProductCode = !!selectedFilters.serviceProductCode ? selectedFilters.serviceProductCode === data.serviceProductCode : true;
      const isDescription = !!selectedFilters.description ? selectedFilters.description === data.description : true;
      const isQuantity = !!selectedFilters.quantity ? selectedFilters.quantity === data.quantity : true;
      return isTeamNameEqual        && isServiceId        && isProductCode        && isDescription        && isQuantity;
    })
    return buttonName === "Sort"      ? filteredDataBasedOnField      : filteredDataBasedOnField        .sort((team1, team2) => team1.teamName > team2.teamName ? 1 : -1)
  }

  handleSortClick = (buttonName) => {
    buttonName = buttonName === "Sort" ? "Undo" : "Sort";
    this.setState((preState) => ({ ...preState, buttonName }))
  }

  handleCheckout = (team, isUser, quantity = 1, filterData, comment='') => {
     let filter = [];
    let request = {};
   let dNumber=this.props.dNumber;
    if (filterData && filterData.length > 0) {
      filter = filterData.map(data => (
        {
          "operator": "equals",
          "resourceName": data.resourceName,
          "searchParameterName": data.searchParameterName,
          "searchParameterValue": data.filterValue,
          // "searchParameterName": data.filterName,
          // "sortParameterName": data.filterName,
          // "sortParameterDerection": "DESC"
        }
      ));
      request = {
        dNumber,
        quantity,
        comment,
        environment: this.props.selectedEnvValue,
        scope: isUser ? "PRIVATE" : "TEAM",
        filter      }
    }
    else {
      request = {
        dNumber,
        quantity,
        comment,
        environment: this.props.selectedEnvValue,
        scope: isUser ? "PRIVATE" : "TEAM",
      }
    }
    if (this.props.selectedToggleButton === ToggleButtonLabel.STANDARD) {
      request.serviceId = team.serviceId;
    } else if (this.props.selectedToggleButton === ToggleButtonLabel.CUSTOM) {
      request.userStory = team.serviceId;
      request.scenarioName = team.description;
    }
    request.team = team.teamId;
    displayLoading({ title: "Checking out", bodyText: "Please standby" });

    if (this.props.selectedToggleButton !== ToggleButtonLabel.CUSTOM) {
      this.props.checkoutTeam(request).then((result) => {
        if (result) {
          swal.fire("Success", ` `, "success").then(() => {
            this.props.history.push(`/tdm/nosql/checkout/history/${result.checkoutId}`);
          });
        }
      });
    } else {
      this.props.sqlCheckoutTeam(request).then((result) => {
        if (result) {
          swal.fire("Success", ` `, "success").then(() => {
            this.props.history.push(`/tdm/nosql/checkout/history/${result.checkoutId}`);
          });
        }
      });
    }
  }
  render() {
    const { selectedToggleButton, customFilters, teamServicesPending, filters,
      currentTeamId, loggedInUser, filterServiceIds, serviceFilterData} = this.props;
    const { selectedFilters, selectedCustomFilters, buttonName } = this.state;
    const isStandardToggle = selectedToggleButton === ToggleButtonLabel.STANDARD;
    const selectedFilterRender = isStandardToggle ? selectedFilters : selectedCustomFilters || {};
    const selectedFilterOption = isStandardToggle ? filters : customFilters;
    return (
      <Fragment>
        <Table hover responsive>
          <thead>
            <tr>
                <th>Team</th>
                { isStandardToggle ? <th>Service Id</th> : <th>Request Id</th> }
                <th>Product Code</th>
                  { isStandardToggle ? <th>Service Description</th> : <th>Request Description</th> }
                <th>Quantity</th>
                <th>Action</th>
            </tr>
          </thead>
          <tbody id="team-details-table-body">
                {
                  teamServicesPending &&
                  <Fragment>
                    <TableFilterController
                      filters={this.prepareFilterData(selectedFilterOption, selectedFilterRender)}
                      optionalButtonName={buttonName}
                      optionalButtonType={buttonName === "Sort" ? "btn-warning" : "btn-primary"}
                      handleOptionalButtonClick={this.handleSortClick}
                      selectedFilters={selectedFilterRender}
                      isClearFilterState={true}
                    />
                    <TeamDataTableBody
                      handleAllocateToTeamModal={this.handleAllocateToTeamModal}
                      teamData={this.prepareTableData(selectedFilterOption, selectedFilterRender, buttonName)}
                      handleCheckout={this.handleCheckout}
                      handleServiceFilterCheckout={this.props.checkoutServiceFilters}
                      handleCheckoutServiceFilterValue={this.props.checkoutServiceFilterValue}
                      currentTeamId={currentTeamId}
                      isShowCircleInServiceId={isStandardToggle}
                      loggedInUser={loggedInUser}
                      filterServiceIds={filterServiceIds}
                      serviceFilterData={serviceFilterData}
                    />
                  </Fragment>
                }
          </tbody>
        </Table>
      </Fragment>
    )
  }
}
const mapStateToProps = state => {
  return {
    teamServicesPending: state.teams.teamData,
    filters: state.teams.teamFilters.filters,
    selectedFilters: state.teams.selectedFilters,
    currentTeamId: state.auth.team,
    selectedCustomFilter: state.teams.selectedCustomFilter,
    customFilters: state.teams.customTeamFilters.customFilters,
    selectedEnvValue: state.auth.selectedEnvValue,
    loggedInUser: state.auth.userName,
    filterServiceIds : state.teams.filterServiceIds,
    serviceFilterData: state.teams.serviceFilterData,
    dNumber:state.auth.userName
  };
};
const mapDispatchToProps = dispatch => {
  return {
    retrieveServiceIds: (request) => dispatch(retrieveServiceIds(request)),
    selectFilter: (filter) => dispatch(selectFilter(filter)),
    selectCustomTeamFilter: (filter) => dispatch(selectCustomTeamFilter(filter)),
    checkoutTeam: (request) => dispatch(checkoutTeam(request)),
    sqlCheckoutTeam: (request) => dispatch(sqlCheckoutTeam(request)),
    checkoutServiceFilters: (request) => dispatch(checkoutServiceFilters(request)),
    checkoutServiceFilterValue: (request) => dispatch(checkoutServiceFilterValue(request)),
  };
};
export default  connect(
    mapStateToProps,
    mapDispatchToProps  )(MyTeamTable);
