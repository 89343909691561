import React, { useState } from "react";
import { connect } from "react-redux";
import { Tooltip, TextField } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './customDataset.css'
import {configureFilters,fetchDataSummary} from "../../actions/retrieveDataActions";

const ConfigureFilter = (props) => {
    const [filterPath, setFilterPath] = useState("");
    const [filterName, setFilterName] = useState("");
    return (
        <div class="container white">
            <div class="container filterDataContainer">
            <div class="row">
                <div class="col-1" />
                <div class="col-4 colHeader">Configure filters</div></div>
            <div class="row">
                <div class="col-1" />
                <div class="col-4">
                    <TextField variant="outlined" label="Filter Name"
                        value={filterName} className="checkinFilterNameLabel" 
                        onChange={e=> setFilterName(e.target.value)}/>
                </div>
                <div class="col-4">
                    <TextField variant="outlined" label="Filter Path"
                        value={filterPath} className="checkinFilterNameLabel" 
                        onChange={e=> setFilterPath(e.target.value)}/>
                </div>
                <div class="col-2 mt-2"><Tooltip title="Add More">
                    <AddCircleIcon className="addCircleTwoToneIcon"
                    disabled={filterName !== ""  && filterPath !== "" ? false : true}
                        onClick={()=>{
                            setFilterPath("");
                            setFilterName("");
                            props.handlesubmitFilters({name:filterName,path:filterPath},true);
                        }}
                    /></Tooltip>
                </div>
            </div>
                <div class="row">
                    <div class="col-1" />
                        <div class="col-4 colHeader pt-2">Filters added :</div>
                    </div>
                    <div class="row">
                        <div class="col-1" />
                        {props.duplicateFilterCheck ? 
                        <div class="col-7 errorHeader pt-2">Duplicate Entries exist , please remove them</div>
                        : null}
                    </div> 
                {props?.filters.map((data,index) => {
                    return(
                        <div class="row pt-3">
                        <div class="col-1" />
                        <div class="col-4">
                            <TextField variant="outlined"
                                value={data.name} 
                                className= {data.showError ? "errorFilterLabel" : "checkinFilterNameLabel"}
                               disabled/>
                        </div>
                        <div class="col-4">
                            <TextField variant="outlined" 
                              value={data.path} 
                              className={data.showError ? "errorFilterLabel" : "checkinFilterNameLabel"}
                            disabled/>
                        </div>
                        <div class="col-2 mt-2">
                        <CancelIcon className="cancelIcon" 
                            onClick={() => props.handleDeleteFilter(data.name)}/>
                        </div>
                    </div>
                    )
                })}          
        </div>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
      configureFilters: (data) => dispatch(configureFilters(data)),
      fetchDataSummary : ([]) => dispatch(fetchDataSummary([]))
    };
  };

  const mapStateToProps = (state) => {
     return {
      checkedOutData: state.retrieveData.checkedOutData,
      checkedInData: state.retrieveData.checkedInData,
      customDataset: state.retrieveData.customDataset,
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(ConfigureFilter);
  