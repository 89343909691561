import React, {useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './slider.css'

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
    },
    formControlLabel: {
      minWidth: 120,
      paddingRight:'20px!important'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    formInput: {
        margin: '8px 0px',
        minWidth: 54, 
    },
    formInputValue: {
        margin: '8px 0px 0px 12px',
        minWidth: 65, 
    },
    formInputLabel: {
        fontWeight : 700
    }
  }));

  
  const SurveyPeriod = (props) =>
  {    
    const [graphPeriod, setGraphPeriod] = useState("Quarter");
    const [graphYear, setGraphYear] = useState("2021");
    let reversedQuarter = [];
    if(props.value)
      reversedQuarter =  props.value.map(item => item).reverse();

    const handlePeriodChange = (e) => {
       setGraphPeriod(e.target.value)
      props.handlePeriod(e.target.value);
    }
  const classes = useStyles();
      return (
        <div className={props.float + " " +props.pr}>
            <FormControl className={classes.formControlLabel}>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={graphPeriod}
          onChange = {e=> handlePeriodChange(e)}
        >
          <MenuItem value="Quarter">Quarter</MenuItem>
          <MenuItem value="YTD">Year to Date</MenuItem>
          </Select>
      </FormControl>
    {graphPeriod === "YTD" ? 
        (< FormControl className={classes.formControl +" " +props.float}>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
        value={graphYear}
        >
          <MenuItem value="2021">2021</MenuItem>
         
        </Select>
      </FormControl>) : 
      (
< FormControl className={classes.formControl +" " +props.float}>
        <InputLabel id="demo-simple-select-label"></InputLabel>
        <Select
        value={props.quarterValue}
        onChange={e=> props.handleQuarterChange(e)}
        >
           {reversedQuarter && reversedQuarter.map((item,index)=>(
           <MenuItem value={item} key={index}>{item}</MenuItem>
           ))}
         
        </Select>
      </FormControl>
      )}
        </div>
    )
}

const mapStateToProps = (state) => {
  return {
      displayReport: state.pasmatTrend.displayReport,

  }
}


export default connect(mapStateToProps, null)(SurveyPeriod);