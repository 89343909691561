import { Table, Button } from 'reactstrap'
import React from 'react'

const TconnectDataHubOrders = (props) => {
    return (
        <Table className="tconnect-datahuborder-table" >
            <thead>
                <tr>
                    <th>Sales Order Number</th>
                    <th>Site Project ID</th>
                    <th>Order TCD</th>
                    <th>Order FDD</th>
                    <th>Order Completion Date</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                { props.orders.length >0 ?
                    props.orders.map((order, index) => (
                        <tr key={index}>
                            <td>{order.salesOrderNumber}</td>
                            <td>{order.siteProjectID}</td>
                            <td>{order.orderTCD}</td>
                            <td>{order.orderFDD}</td>
                            <td>{order.orderCompletionDate}</td>
                            <td>
                                <Button color='info' onClick={() => props.tconOrderRef.current.open(order)}>
                                    <i  aria-hidden="true"></i>{` Edit`}
                                </Button>
                            </td>
                        </tr>
                    )) : <tr>No Orders Found</tr>

                }
            </tbody>
        </Table>)
}
export default TconnectDataHubOrders;
