import React from "react";
import { Button } from "reactstrap";
import _ from "lodash";
import { connect } from "react-redux";
import { Field, change } from "redux-form";
import { generateServiceId } from "../../actions/replicatorActions";
import DropdownComponent from "./DropdownComponent";
import { renderField } from "../common/fields/fields";
import {
  adborIdValidator,
  fnnValidatorForNewReplicator
} from "../common/form/fieldValidators";
import "./Product.css";
import "./Data.css";

class AdborComponent extends React.Component {
  state = {
    autoGeneratedRows: []
  };
  handleRowChange = (numRows, newNumRows, fields) => {
    let diff = newNumRows - numRows;
    if (diff > 0) {
      for (let i = 0; i < diff; i++)
        fields.push({ adborId: null, fnn: null, inflightOrder: null });
    } else if (diff < 0) {
      diff *= -1;

      const limit = fields.length - diff - 1;
      for (let i = 0; i < diff; i++) fields.pop();
      this.setState(preState => {
        return {
          ...preState,
          autoGeneratedRows: preState.autoGeneratedRows.filter(
            data => data <= limit
          )
        };
      });
    }
  };
  handleGenerateServiceId = async (passdownprops, index) => {
    try {
      const { serviceId } = await this.props.generateServiceId(1, index);

      this.setState(preState => ({
        ...preState,
        autoGeneratedRows: preState.autoGeneratedRows.concat(index)
      }));

      passdownprops.dispatch(
        change(passdownprops.formName, `adborFnns[${index}].fnn`, serviceId)
      );
    } catch (e) {
      console.log("error generating fnn: ", e);
    }
  };
  render() {
    const { fields, passdownprops, productType, serviceIdField, quantity } = this.props;
    return (
      <div>
        <div className="row" id="product-input-header">
          <div className="col col1">
            {" "}
            <span className="product-header">Product:</span>
            <span>{productType.toUpperCase()}</span>
          </div>
          <div className="col col2">
            <span className="product-header">Quantity: </span>
            <span id="qty-dropdown">
              <DropdownComponent
              options = {quantity}
                selectInput={qty =>
                  this.handleRowChange(fields.length, qty, fields)
                }
                length={fields.length}
              />
            </span>
          </div>
        </div>
        <div id="s2p-product-modal-input-head" className="row">
          <table className="table table-sm adbor-fnn-table">
            <thead className="thead-light adbor-fnn-head">
              <tr>
                <th className="verticle-middle">
                  <span>Adbor ID</span>
                  <span>
                    <Button
                      color="link"
                      onClick={() =>
                        fields.forEach((d, i) =>
                          passdownprops.dispatch(
                            change(
                              passdownprops.formName,
                              `adborFnns[${i}].adborId`,
                              fields.get(0).adborId
                            )
                          )
                        )
                      }
                    >
                      <i className="fa fa-plus fa-sm" aria-hidden="true"></i>
                    </Button>
                  </span>
                </th>
                <th className="verticle-middle">
                  <span>Service ID</span>                  
                </th>
                <th></th>
                <th className="verticle-middle" >
                  <span >Inflight</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {fields.map((adborFnn, index) => {
                let adborId = 2 * index + 1,
                  fnn = adborId + 1,
                  inflightOrder = fnn + 1;
                const placeholder =
                  serviceIdField.rowNumber === index && serviceIdField.pending
                    ? "loading..."
                    : "";
                return (
                  <tr key={index}>
                    <td style={{ padding: "10px 5px 0px 0px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].adborId`}
                        tabIndex={adborId}
                        disabled={passdownprops.asyncValidating || serviceIdField.pending}
                        autoFocus={adborId === 0}
                        component={renderField}
                        customDiv="adborFnnTableInputDiv"
                        validate={adborIdValidator}
                        invalid={this.props.errorMessage}
                        type={"textWithCustomStyles"}
                        showValidMsg={false}
                        touchOnChange={true}
                        autoComplete="off"
                      />
                    </td>
                    <td style={{ padding: "10px 0px 0px 5px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].fnn`}
                        customDiv="adborFnnTableInputDiv"
                        customStyleClassName="generate-serviceId-input"
                        tabIndex={fnn}
                        disabled={
                          passdownprops.asyncValidating ||
                          this.state.autoGeneratedRows.includes(index) ||
                          (serviceIdField.pending)
                        }
                        component={renderField}
                        validate={fnnValidatorForNewReplicator}
                        touched={true}
                        invalid={this.props.errorMessage}
                        type={"textWithCustomStyles"}
                        showValidMsg={false}
                        placeholder={placeholder}
                        autoComplete="off"
                      />
                    </td>
                    <td style={{ padding: "10px 0px 0px 0px", verticalAlign: "top" }}>
                      <Button
                        outline
                        color="success"
                        className={"generate-serviceId-button"}
                        disabled={
                          !!(
                            (serviceIdField.pending) ||
                            this.state.autoGeneratedRows.includes(index) ||
                            passdownprops.asyncValidating
                          )
                        }
                        onClick={() =>
                          this.handleGenerateServiceId(passdownprops, index)
                        }
                      >
                        {serviceIdField.rowNumber === index &&
                        serviceIdField.pending ? (
                          <span
                            className="spinner-grow spinner-grow-sm text-success"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <i
                            className="fa fa-plus fa-sm"
                            aria-hidden="true"
                          ></i>
                        )}
                      </Button>
                    </td>
                    <td style={{ padding: "13px 0px 0px 0px", verticalAlign: "top" }}>
                      <Field
                        name={`adborFnns[${index}].inflightOrder`}
                        component={renderField}
                        tabIndex={inflightOrder}
                        touched={true}
                        invalid={this.props.errorMessage}
                        type={"checkbox"}
                        index={index}
                        showValidMsg={false}
                        showIcon={fields.get(index).inflightOrder}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    quantity: state.replicatorData.options.quantity,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    generateServiceId: (qty, index) => dispatch(generateServiceId(qty, index))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdborComponent);
