import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import SlidingPanel from "react-sliding-side-panel";

import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import {
  IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: "10px 0px 20px 0px !important",
  },
  heading: {
    paddingLeft: '10px',
    fontSize: "25px",
    textAlign: "center",
    fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Ubuntu,Helvetica Neue,sans-serif"
  },
  closeBtn: {
    marginTop:"5px",
  }
}));

const LeftSidePanel = (props) => {
  const classes = useStyles();
  const { panelIconComponent, panelTitle, children, closePanel, disable, size=18 } = props;
  const [isPanelOpen, openPanel] = useState(false);

  const handlePanel = (bool) => {
    if (!disable) {
      openPanel(bool);
    }
  }

  return (
    <>
      <span onClick={() => handlePanel(true)}>{panelIconComponent}</span>
      <SlidingPanel
        type={"left"}
        isOpen={isPanelOpen}
        size={size}
        backdropClicked={()=>openPanel(false)}
      >
        <div className={classes.header}>
          <h5 className={classes.heading}>{panelTitle}</h5>
          <IconButton size="small" aria-label="close" onClick={()=>openPanel(false)} className={classes.closeBtn}>
            <CloseRoundedIcon />
          </IconButton>
        </div>
        {children}
      </SlidingPanel>
    </>
  );
};

LeftSidePanel.propTypes = {
  children: PropTypes.any.isRequired
}

export default LeftSidePanel;