import {axiosInstance as axios} from "../axios.config";
import _ from "lodash";
import * as types from "./actionTypes";
import config from "../config";
import { authHeaders } from "../utils/api";
import { displayErrorNew, successAlert } from "../components/common/alerts/alerts";

const Custom_Data = config.Custom_Data.uri;
const Custom_Data_Root = config.Custom_Data_Root.uri;
const API_URL_GATEWAY = config.tdm_gateway_api.uri;

export const addDataToCart = (key, value) => async dispatch => {
    dispatch({ type: types.ADD_TO_SYNTHETIC_DATA_CART, key, value});
}

export const removeDataFromCart = (key) => async dispatch => {
    dispatch({ type: types.REMOVE_FROM_SYNTHETIC_DATA_CART, key});
}

export const clearDataFromCart = () => async dispatch => {
    dispatch({ type: types.CLEAR_FROM_SYNTHETIC_DATA_CART });
}

export const saveSyntheticTypeData = (data) => async dispatch => {
    dispatch({ type: types.SAVE_SYNTHETIC_TYPE_INFO, payload: data });
};

export const saveSyntheticCartQty = (qty) => async dispatch => {
    dispatch({ type: types.SAVE_SYNTHETIC_CART_QTY, qty });
};

export const chooseCreditCardType = (payload) => async dispatch => {
    dispatch({ type: types.CHOOSE_CREDIT_CARD_TYPE, payload });
};

export const resetCreditCard=()=>async dispatch=>{
    dispatch({type:types.RESET_CREDIT_CARD_TYPE})
}

export const setSyntheticDatasetToEdit = (data) => async dispatch => {
    dispatch({ type: types.SET_SYNTHETIC_DATASET_TO_EDIT, data });
};

export const clearSyntheticTypeData = () => async dispatch => {
    dispatch({ type: types.CLEAR_SYNTHETIC_INFO });
};

export const sendSyntheticOrder = (payload, id) => async (dispatch,getState) => {
  let stateValues=getState();
  let userId = stateValues.auth.user.UserName;
  payload.userId=userId;
  let syntheticData=stateValues.syntheticData;
  let syntheticDataTypeInfo=syntheticData.syntheticDataTypeInfo;
  let scope=syntheticDataTypeInfo.scope;
  if(scope==="TEAM"){
    let team=syntheticDataTypeInfo.team;
    let teamId=syntheticDataTypeInfo.teamId;
    payload["team"]={
      id:teamId,
      name:team
    }
  }
  payload["cart"]["cartItems"].map((_item)=>{
    if(_item.dataType==="ID CHECK"){
      _item.dataType="IDENTITY";
    }
    if(_item.dataType==="BILLING PREFERENCES"){
      _item.dataType="BILLING";
    }return _item;
  });
    try {
        dispatch({ type: types.SEND_SYNTHETIC_ORDER_PENDING });
        const response = await axios.post(`${Custom_Data_Root}/syntheticdata/${id}/generate`, payload, { headers: authHeaders()});
        if (response.status === 200) {
            dispatch({ type: types.SEND_SYNTHETIC_ORDER_SUCCESS, payload: "Order submitted successfully" });
            return {success: true, response: response};
        }
        else if (response.status !== 200) {
            dispatch({ type: types.SEND_SYNTHETIC_ORDER_FAILED, payload: "Failed to complete order" });
            return {success: false, response: response};
        }
    } catch (error) {
        dispatch({ type: types.SEND_SYNTHETIC_ORDER_FAILED, payload: `Error while sending order - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}` });
        return {success: false, response: `Error while sending order - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}`};
    }
};

export const resetSyntheticDataset = () => async (dispatch) => {
    dispatch({ type: types.CREATE_SYNTHETIC_DATA_SET_RESET });
}
export const resetSyntheticDataOrder = () => async (dispatch) => {
    dispatch({ type: types.SEND_SYNTHETIC_ORDER_RESET });
}

export const createSyntheticDataset = (data) => async (dispatch) => {
    try {
      dispatch({ type: types.CREATE_SYNTHETIC_DATA_SET_PENDING });
      const response = await axios.post(`${Custom_Data}`,data, {headers: authHeaders()});
       if (response && response.status === 200) {
         if (response.data.httpStatus === "CONFLICT") {
            const errorMsg = "The given Synthetic Data name already exists";
            dispatch({ type: types.CREATE_SYNTHETIC_DATA_SET_FAILED, payload: errorMsg });
         } else {
            dispatch({ type: types.CREATE_SYNTHETIC_DATA_SET_SUCCESS, payload: response.data });
            return response.data;
         }
      }
    } catch (e) {
      let errorMsg = "";
      if (typeof e === "string") errorMsg = e;
      else if (e.response) {
        if (e.response.status === 408) {
          errorMsg = "Unable to create dataset. Please try again after some time";
        } else {
          errorMsg = e.response.data.responseDescription || e.response.data.message;
        }
      } else {
        errorMsg = "An error occurred while creating the dataset. Please try again after some time.";
      }
      dispatch({ type: types.CREATE_SYNTHETIC_DATA_SET_FAILED, payload: errorMsg });
    }
  };

  export const fetchSyntheticDataSummary = () => async (dispatch,getState) => {
    try {
      dispatch({ type: types.SYNTHETIC_DATA_PENDING });
       const reqBody = {
        "environment": getState().auth.selectedEnvValue,
        "datasetType": "SYNTHETIC"
      }
      const response = await axios.post(`${Custom_Data_Root}/syntheticdata/datasets/retrieve?pageIndex=0&batchSize=-1`,reqBody, {headers: authHeaders()});
      if (response && response.status === 200) {
        dispatch({ type: types.SYNTHETIC_DATA_RETRIEVED, payload: response.data });
      }
      return response;
    }
    catch (e) {
      let errorMsg = "";
      if (typeof e === "string") errorMsg = e;
      else if (e.response) {
        errorMsg = e.response.data.responseDescription || e.response.data.message;
      } else {
        errorMsg =
        "An error occurred while retrieving the dataset. Please try again after some time.";
      }
      dispatch({ type: types.SYNTHETIC_DATA_FAILED });
      displayErrorNew({
        title: "Error",
        errorMsg,
      });
    }
  };
