import * as types from '../actions/actionTypes';
import _ from 'lodash';

const INITIAL_STATE = {
    available: [],
    active: {},
    currentEnvProfiles: [],
    envProfiles:{}
}

// Salesforce profiles reducer
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOAD_USER_SF_PROFILES_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case types.LOAD_USER_SF_PROFILES_SUCCESS:
            let activeProfiles;
            const keyedProfiles = _.keyBy(action.payload.sfProfiles, "id");            
            const groupedEnvProfiles = _.groupBy(action.payload.sfProfiles, 'env');
            const groupedProfileEntries = Object.entries(groupedEnvProfiles);
            let defaultSelectedProfile = {};
            if (groupedProfileEntries.length > 0) {
                const groupedProfileEntry = groupedProfileEntries[0];
                const groupedProfileKey = groupedProfileEntry[0];
                const groupedProfileValue = groupedProfileEntry[1][0];
                defaultSelectedProfile = { [groupedProfileKey]: groupedProfileValue.id };
                activeProfiles = _.keyBy(groupedEnvProfiles[groupedProfileKey], "id")[groupedProfileValue.id];
            }
            return {
                ...state,
                loading: false,
                envProfiles: {...groupedEnvProfiles},                
                available: { ...keyedProfiles },                
                active: { ...activeProfiles },
                defaultSelectedProfile:{...defaultSelectedProfile}
            }
        case types.LOAD_USER_SF_PROFILES_FAIL:
            return {
                ...state,
                loading: false
            }
        case types.SELECT_USER_SF_PROFILE:
            return {
                ...state,
                active: action.payload                
            }
        case types.CHANGE_USER_SF_PROFILE:
            const activeProfile = _.keyBy(action.envProfiles[action.env], "id")[action.id];
            return {
                ...state,
                defaultSelectedProfile: {
                    [action.env]: action.id
                },
                active: activeProfile
            }

        case types.CHANGE_SIDEPANEL_ENVIRNOMENT:
            const { env, envProfiles } = action.payload;
            const selectedEnvProfiles = envProfiles && envProfiles[env];
            const defaultSelectedProfiles = selectedEnvProfiles && selectedEnvProfiles[0];
            return {
                ...state,
                currentEnvProfiles : _.values(selectedEnvProfiles),
                active: { ...defaultSelectedProfiles }
            }
        default:
            return state;
    }
}