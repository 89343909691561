import { Button, Col, Fade, Row, Collapse } from 'reactstrap';
import React, { Component } from 'react'

import { DataFilterTileNew } from '../../tiles/DataFilterTileNew';
import _ from "lodash";
import { TelstraIcon } from '../../../common/icons/GradientIcons';
import { TilePose } from './pose';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ButtonMUI from '@mui/material/Button';

export default (props) => {
    const {
        isMulti = false,
        selected,
        subSelected,
        selectedKey,
        subSelectedKey,
        select,
        heading,
        data,
        displayHeading=true,
        color,
        minimize = true,
        collapsed = false,
        collapsible = false,
        collapseIcon = `ui-${collapsed ? 'down' : 'right'}-arrow-thick`,
        collapseColor = 'blue',
        collapseDisabled = false,
        onCollapse = undefined, // callback called when collapse button is clicked, intended collapsed boolean is passed
        underRenderFunc = undefined, // This is used for rendering buttons / text / elements underneath a tile - NOTE: passes in isSelected
        redirectUtility=false,
        push,
        backBtnColor
            } = props;
    const selectedItem = (isMulti && selected.length == 0) ? undefined : selected;
    const isOpen = collapsible ? collapsed : true
    const collapsibleDisplayUi = (collapsible && !collapseDisabled);
    const collapsibleCanClick = (collapsible && !collapseDisabled && !selectedItem);
    const disabledFunc = () => { }
    return (
        <Fade in={true}>
            {displayHeading &&<span 
                    className={`dataview-header ${collapsibleDisplayUi && 'collapsible'}`}
                    style={collapsibleDisplayUi ? {cursor:'pointer'} : {}} 
                    onClick={() => (collapsibleCanClick && onCollapse && onCollapse instanceof Function) && onCollapse(!collapsed)} >
                <p className="ml-2 sub-leader font-weight-light">{heading}{selectedItem
                    ? ` - ${selectedItem}`
                    : ''}{subSelected ? ` (${subSelected})` : ''}</p>
                {selectedItem && <TelstraIcon primary icon="tick" color="green"/>}
                {(collapsibleCanClick && collapsibleDisplayUi) && <TelstraIcon primary icon={collapseIcon} color={collapseColor}/>}
        {redirectUtility && <ButtonMUI style={{background:backBtnColor}} onClick={()=>{push('/data/utility')}} variant="contained" startIcon={<ReplyAllIcon />}>Back to Utilities</ButtonMUI>}
            </span>}
            <Collapse isOpen={isOpen}>
                <div className="ml-3 content tile-box">
                    {data.map((tile, index) => {
                        const isSelected = isMulti && selectedItem ? selectedItem.includes(tile.label) : (
                            (selectedItem === tile.label) ||
                            (selectedKey && (tile[selectedKey] == selected && 
                                (subSelectedKey ? (tile[subSelectedKey] == subSelected) : true)))
                        );
                        return (
                            <div key={index}>
                                <div className={`tiles-grid flex base-${(tile.disabled) ? "grey" : color}`}>
                                    <TilePose pose={isSelected ? 'selected' : 'init'}>
                                        <DataFilterTileNew
                                            {...tile}
                                            obj={tile}
                                            minimize={minimize}
                                            isSelected={isSelected} // If this is undefined handle as usual
                                            selectType={tile.disabled ? disabledFunc : select}
                                            selectedTypes={selectedItem}
                                            selectedSubtypes={subSelected}>
                                        </DataFilterTileNew>
                                    </TilePose>
                                </div>

                                {underRenderFunc && underRenderFunc instanceof Function ? underRenderFunc(tile, isSelected) : undefined}
                            </div>
                        )
                    })
                    }

                    {/* No data to display */}
                    {(data.length === 0) && <div className={`tiles-grid base-grey`}>
                        <DataFilterTileNew
                            icon={"icon-ui-cross"}
                            label={"No Data"}
                        />
                    </div>}
                </div>
            </Collapse>
        </Fade>
    )
}