import { LOAD_FILTERS_SUCCESS, LOAD_FILTERS_LOADING } from '../actions/actionTypes';

export default (state = {}, action) => {
    switch(action.type) {
        case LOAD_FILTERS_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case LOAD_FILTERS_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state
    }
}