import React from 'react';
import {Table, Row, Col} from 'reactstrap';

import DataViewRow from './DataViewRow';
import {SubHeader} from '../../../common/custom/SubHeader';

export const DataViewAddress = (props) => {
    const {addressItems, filename, displayClass} = props;
    return (
        <React.Fragment>
            <SubHeader text={"Address"} iconClass={"td-icon-md icon-home"} iconColor={"orange"} data={addressItems} filename={`${filename}-ADDRESS`}/>
            <Table>
                <thead>
                    <tr>
                        <th>Tag ID</th>
                        <th>Environment</th>
                        <th>Realm</th>
                        <th>Exchange</th>
                        <th>Locality</th>
                        <th>Street</th>
                        <th>FNN</th>
                        {displayClass && <th>Class</th>}
                    </tr>
                </thead>
                <tbody>
                    {addressItems.map((item) => {
                        return <DataViewRow item={item} key={item.id} techFilter={displayClass}></DataViewRow>
                    })}
                </tbody>
            </Table>
        </React.Fragment>
    )

}