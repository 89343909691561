import React from 'react';
import {Collapse,Button } from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form'
import { renderField } from '../../common/fields/fields';
import CommentDetailsComponent from './CommentDetailsComponent';

class SubStageDetailsComponent extends React.Component {
  constructor(props) {
    super(props);     
    this.toggleComments = this.toggleComments.bind(this);
  }
 
  state = {
    commentCollapse : false,
    subStageIndex : null
  }

  toggleComments = (index) => {
    this.setState({ 
        subStageIndex : index,
        commentCollapse: !this.state.commentCollapse
      });
  }

  render() {
    let {fields, meta: { touched, error, submitFailed }} = this.props;
    const buttonStyle = {
      marginBottom: '1rem',
      width: '100%',
      textAlign: 'left'
  }
  const stateOptions = [
    { label: "In Progress", value: "In Progress" },
    { label: "Skipped", value: "Skipped" },
    { label: "Completed", value: "Completed" },
    { label: "Reworked", value: "Reworked" },
    { label: "Hold In Error", value: "Hold In Error" },
    { label: "Hold In Progress", value: "Hold In Progress" },
    { label: "In Error", value: "In Error" },
    { label: "On Hold", value: "On Hold" },
    { label: "Release In Error", value: "Release In Error" },
    { label: "Release In Progress", value: "Release In Progress" },
    { label: "Rework In Error", value: "Rework In Error" },
    { label: "Rework In Progress", value: "Rework In Progress" },
    { label: "Not Started", value: "Not Started" },
    { label: "Pending", value: "Pending" },
    { label: "Pending In Error", value: "Pending In Error" },
    { label: "Rework Pending", value: "Rework Pending" },
    { label: "Optional", value: "Optional" }
  ];

  const subStageOptions = [
    { label: "Customer Order Acceptance", value: "Customer Order Acceptance" },
    { label: "Checking your Requirements", value: "Checking your Requirements" },
    { label: "Equipment Ordered", value: "Equipment Ordered" },
    { label: "Product Preparation", value: "Product Preparation" },
    { label: "Equipment Delivered", value: "Equipment Delivered" },
    { label: "Site visit and Installation", value: "Site visit and Installation" },
    { label: "Product Activation and Testing", value: "Product Activation and Testing" },
    { label: "Product Completion Notice", value: "Product Completion Notice" },
    { label: "Product Requirements Validation", value: "Product Requirements Validation" },
    { label: "Final Cancellation Activities", value: "Final Cancellation Activities" },
    { label: "Product Finalization and Testing", value: "Product Finalization and Testing" },
    { label: "Picking & Packing", value: "Picking & Packing" },
    { label: "Delivery Tracking", value: "Delivery Tracking" }
  ];
  const stageOptions = [
    { label: "Received", value: "Received" },
    { label: "Being Fulfilled", value: "Being Fulfilled" },
    { label: "Ready for Use", value: "Ready for Use" },
    { label: "Closed", value: "Closed" }
  ];

    return (
        <ul style={{background:"aliceblue"}}>
          {fields.map((subStage,index) => (
              <li key={index}>    
                      <div className="row">
                          <div className="col-sm-6" style={{fontWeight : "bold"}}>SubStage - {index+1}</div>
                          <div className="col-sm-6" style={{textAlign : "right"}}><Button color="danger" title="Remove Substage"
                            onClick={() => fields.remove(index)}><i className="fa fa-minus-circle fa-sm" alt="Remove" aria-hidden="true"></i></Button></div>
                          <div className="col-sm-3">
                          <Field
                              name={`${subStage}.customerStage`}
                              label="Customer Stage"
                              type="selectWithCustomHeight"
                              autoComplete="off"
                              component={renderField} 
                              options={stageOptions}  
                          />
                          </div>
                          <div className="col-sm-3">
                          <Field
                              name={`${subStage}.customerSubStage`}
                              label="Customer Sub Stage"
                              type="selectWithCustomHeight"
                              autoComplete="off"
                              component={renderField}  
                              options={subStageOptions}   
                          /> </div>
                          <div className="col-sm-3">
                          <Field
                              name={`${subStage}.currentEstimatedEndDate`}
                              label="End Date"
                              type="date"
                              autoComplete="off"
                              component={renderField}   
                          /></div>
                          <div className="col-sm-3">
                          <Field
                                  name={`${subStage}.state`} 
                                  label="State"
                                  type="selectWithCustomHeight"
                                  autoComplete="off"
                                  component={renderField}   
                                  options={stateOptions}
                              />
                          </div>
                          <div className="col-sm-12" style={{paddingRight : "8%", paddingLeft : "8%"}}>
                          <Button color="primary" onClick={() => this.toggleComments(index)} style={buttonStyle}>Comment Details</Button>
                              <Collapse isOpen={this.state.commentCollapse && index=== this.state.subStageIndex}>
                                  <FieldArray
                                    name={`${subStage}.datahubOrderCommentsDetailsDto`}
                                    component={CommentDetailsComponent}
                                  />
                                </Collapse>
                          </div>
                      </div>                
              </li>
          ))}
           <li style={{marginBottom : "10px"}}>
               <Button color="info" onClick={() => fields.push(
                 {customerStage : "Received", customerSubStage: "Customer Order Acceptance",  currentEstimatedEndDate: new Date(),  state: "In Progress" }
               )}>
                 Add SubStage
               </Button>
               {(touched || submitFailed) && error && <span>{error}</span>}
            </li>
        </ul>
    );
  }
}

export default SubStageDetailsComponent