import {axiosInstance as axios} from '../axios.config';
import _ from "lodash";
import * as types from "./actionTypes";
import config from "../config";
import { authHeaders } from "../utils/api";

const API_URL_GATEWAY = config.tdm_gateway_api.uri;


export const addThresholdValuesToServiceIds = request => async dispatch => {
    try {
      dispatch({ type: types.TEAM_SERVICE_FILTERS_VALUE_PENDING, payload: true });
      const response = await axios.post(`${API_URL_GATEWAY}/tdm/standarddatabank/threshold`,request,{ headers: authHeaders() });
  
      if (response.status === 200) {
        dispatch({ type: types.ADD_THRESHOLD_SUCCESS, payload: response.data.thresholdDetails});
        return response;
      } else {
        const errorMsg = "Adding threshold to serviceIds Failed";
        dispatch({ type: types.ADD_THRESHOLD_FAILED, payload: errorMsg });
        return response;
      }
    } catch (exception) {
      const payload =  _.get(exception, 'response.data.responseDescription', exception.response?.data?.error);
      dispatch({ type: types.ADD_THRESHOLD_FAILED, payload });
      console.error(exception);
    return payload;
    }
  }

  export const fetchThresholdDetails = request => async dispatch => {
    try {
      dispatch({ type: types.FETCH_THRESHOLD_LOADING, payload: true });
      const response = await axios.post(`${API_URL_GATEWAY}/tdm/standarddatabank/threshold/retrieve`,request,{ headers: authHeaders() });
      if (response.status === 200) {
        dispatch({ type: types.FETCH_THRESHOLD_SUCCESS, payload: response.data.thresholdsData});
        return response;
      } else {
        const errorMsg = "Failed to fetch thresholdvalues";
        dispatch({ type: types.FETCH_THRESHOLD_FAILED, payload: errorMsg });
        return response;
      }
    } catch (exception) {
      const payload =  _.get(exception, 'response.data.responseDescription', exception.response.data.error);
      dispatch({ type: types.FETCH_THRESHOLD_FAILED, payload });
      console.error(exception);
      return payload;
    }
  }

  export const deleteThresholdDetails = request => async dispatch => {
    try {
      dispatch({ type: types.FETCH_THRESHOLD_LOADING, payload: true });
      const response = await axios.delete(`${API_URL_GATEWAY}/tdm/standarddatabank/threshold`,{ headers: authHeaders(), data: request});
  
      if (response.status === 200) {
        dispatch({ type: types.FETCH_THRESHOLD_SUCCESS, payload: response.data.thresholdDetails});
        return response;
      } else {
        const errorMsg = "Failed to fetch thresholdvalues";
        dispatch({ type: types.FETCH_THRESHOLD_FAILED, payload: errorMsg });
        return response;
      }
    } catch (e) {
      const payload =  _.get(e, 'response.data.responseDescription', 'response.data.status');
      dispatch({ type: types.FETCH_THRESHOLD_FAILED, payload });
      console.error(e);
    }
  }