import React, {Fragment} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {connect} from 'react-redux';
import Select from "react-select";
import swal from 'sweetalert2';
import { isNumeric } from 'validator';
import { displayLoading } from "../common/alerts/alerts";
import {ToggleButtonLabel} from "./MyTeam"
import _ from "lodash";
import config from "../../config";
import {UserSwitch} from '../common/custom/ToggleSwitch';
import FailedView from "./FailedView";
import { fetchClassTechOptions, allocateToTeam, oldGetTeamSource, getUserSource, getTeamUserTarget,fetchExchangeIDs,
  checkoutServiceFilters, checkoutServiceFilterValue
} from '../../actions/myTeamsActions';
import ExchangeServicePanel from './ExchangeServices.js'
import AllocateFilterRow from './AllocateFilterRow';
import { Grid } from '@material-ui/core';

 class AllocateTeamModal extends React.Component {
  state = {
    modal: false,
    quantity: 0,
    targetTeam: null,
    isQtyInvalid: false,
    allocationComments: '',
    sourceTeam: null,
    classTeam: null,
    techTeam: null,
    sourceTeamOptions: [],
    sourceUserToggle:false,
    targetUserToggle:false,
    selectedServiceId:null,
    exchangeServicesID:false,
    exchangeServiceToggle:false,
    exchangeId: null,
    serviceQty : 0,
    getExchangeIds : [],
    serviceFilters: [],
    serviceFilterRows: [],
    filterRequestBody: {},
    prevFilterData: [],
    filterCheckoutData: []
  };

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }

  exchangeToggle = () => {
    this.setState(prevState => ({
      exchangeServiceToggle:false,
      exchangeId: null,
      exchangeServicesID: false,
      getExchangeIds : [],
    }))
  }

  toggle = () => {
    //this.props.setShowModal(false);
    this.setState(prevState => ({
      ...prevState,
      quantity: 0,
      sourceTeam: null,
      targetTeam: null,
      modal: !prevState.modal,
      isQtyInvalid: false,
      allocationComments: '',
      sourceTeamOptions: [],
      classTeam: null,
      techTeam: null,
      selectedServiceId:null,
      sourceUserToggle:false,
      targetUserToggle:false,
      exchangeServicesID:false,
      exchangeServiceToggle:false,
      exchangeId: null,
      serviceQty: 0,
      getExchangeIds : [],
      errorMsg: '',
      serviceFilters: [],
      serviceFilterRows: [],
      filterRequestBody: {},
      prevFilterData: [],
      filterCheckoutData: []
    }));
  };
  isValidServiceIdForClassTech = (serviceId) => {
    const {selectedToggleButton} = this.props;
    if (selectedToggleButton === ToggleButtonLabel.STANDARD && (serviceId === 6 || (serviceId >= 104 && serviceId <= 130))) {
        return true;
    } else {
        return false;
    }
  }

    open = () => {
        const {selectedToggleButton, selectedEnvValue} = this.props;
        this.setState({modal: true});
        this.props.getTeamSource(selectedEnvValue, selectedToggleButton);
    };
    onAddNewFilter = (tempFilterArray) => {

      this.setState(prevState => ({
        ...prevState,
        serviceFilterRows: [...prevState.serviceFilterRows,
        <AllocateFilterRow tempFilterArray={tempFilterArray}
         onRemoveRow={this.onRemoveRow}
         onAddNewFilter={this.onAddNewFilter}
           onChangeFilterField={this.onChangeFilterField}
           onChangeFilterFieldValue={this.onChangeFilterFieldValue}
           arrayPosition={prevState.serviceFilterRows.length}/>]
      }));
    }

    onChangeFilterFieldValue = (value) => {
      let newPrevFilterEntry = {
        resourceName: value.table,
        filterName: value.fieldColumnName,
        filterValue: value.value,
      }
      let filterCheckoutData = {
        resourceName: value.fieldResourceName,
        tableName: value.table,
        filterName: value.fieldColumnName,
        filterValue: value.value,
        searchParameterName : value.parameterName
      }
      this.setState(prevState => ({
        ...prevState,
        prevFilterData: [...prevState.prevFilterData.filter(e => (e.resourceName+e.filterName != value.table+value.fieldColumnName)), newPrevFilterEntry],
        filterCheckoutData: [...prevState.filterCheckoutData.filter(e => (e.resourceName+e.filterName != value.table+value.fieldColumnName)), filterCheckoutData]
      }));
    }
    onRemoveRow = (position) => {
      this.setState({
        serviceFilterRows : [...this.state.serviceFilterRows.slice(0,position)],
        filterCheckoutData : [...this.state.filterCheckoutData.slice(0,position)]
      })
    }

    createFilterPayload = (value) => {
      let newEntry = {
        resourceName: value.table,
        filterName: value.value
      }
      let payload = {
        teamId: this.state.sourceTeam.value,
        serviceId: this.state.selectedServiceId.value,
        environment: this.props.selectedEnvValue,
        previousFiltersData: [
          ...this.state.prevFilterData
        ],
        filterColumn: newEntry
      }
      this.setState(prevState => ({
        ...prevState,
        filterRequestBody: payload
      }));
      return payload;
    }
    onChangeFilterField = (value, {action}) => {
       if (action === "select-option") {
        this.props.checkoutServiceFilterValue(this.createFilterPayload(value));
      }
    }
    onServiceIdChange = (selectedServiceId) => {
      this.exchangeToggle();
      const {allocateSourceData} = this.props;
      let exchangeQty = 0;
      const prepareOptions =  selectedServiceId ? allocateSourceData.source.service.filter((sd)=> sd.serviceId === selectedServiceId.value):[];
      const sourceTeamOptions = prepareOptions.map(option=>({ value: option.teamId, label: option.teamName, quantity: option.quantity }));
      const sourceTeam = sourceTeamOptions.find(option => option.value === allocateSourceData.source.defaultSourceId);
      const selectedSource = sourceTeam && sourceTeam.value && sourceTeam.label;
      exchangeQty = sourceTeam && sourceTeam.quantity || 0;
      this.props.getTeamUserTarget(this.state.targetUserToggle, selectedSource?sourceTeam:null, this.props.userData?.content);
      if(this.isValidServiceIdForClassTech(selectedServiceId && selectedServiceId.value)) {
        this.props.fetchClassTechOptions();
      }
      this.filtersToggle();
      let tempFilterArray = [];

      if(selectedServiceId !== null && selectedServiceId !== this.state.selectedServiceId)
      {
        this.props.handleServiceFilterCheckout(selectedServiceId).then((response) => {
          response !== undefined && response.forEach(resource => {
            tempFilterArray = [...tempFilterArray ,{parameterName :resource.parameterName,
              column: resource.column,
              filterName: resource.filterName,
              tableName: resource.resourceTableName,
              resourceName: resource.resourceName }];

            })
             this.setState({
               serviceFilterRows : [...this.state.serviceFilterRows.slice(0,0)]
             })
            this.onAddNewFilter(tempFilterArray);

        })}
        else {
          if(selectedServiceId == null)
          {
            this.onRemoveRow(1)
            tempFilterArray = []
            this.setState({
              serviceFilterRows : [...this.state.serviceFilterRows.slice(0,0)]
            })
          }
        }
      this.setState({
        selectedServiceId:selectedServiceId,
        serviceFilters: tempFilterArray,
        sourceTeam, sourceTeamOptions, targetTeam:null, exchangeId:null,
            serviceQty:exchangeQty,classTeam: null, techTeam: null,
            targetUserToggle:false
      })

    }
      setExchangeId = (sourceTeamOptions,selectedServiceId) => {
        this.props.fetchExchangeIDs(this.props.selectedEnvValue);
        let exchangeTeam = {exchangeId:[]};
        let serviceExchgData = this.props.exchangeData.exchngData
        serviceExchgData.map((teams) => {
          sourceTeamOptions.map((sourceTeam) => {
            if(sourceTeam.value === parseInt(teams.id))
            { teams.exchangeData.map((teamExchData)=>{
                if(parseInt(teamExchData.serviceId) === selectedServiceId.value)
                { let teamEchgID = teamExchData.exchangeId
                  exchangeTeam.exchangeId.push(teamEchgID[0])
                }  })  }  })  })
      return exchangeTeam;
    }

    onChangeSourceTeam = sourceTeam => {
      this.exchangeToggle();
      let exchangeServiceIDPanel = false;
      let exchangeTeam = {exchangeId:[]};
      let selectedServiceId = this.state.selectedServiceId;
      const popUpServiceIDs = this.props.filterServiceIds;
      let serviceQty = 0;
      serviceQty = sourceTeam && sourceTeam.quantity;
      let selectedSerID = selectedServiceId? popUpServiceIDs.filter((serviceID) =>  serviceID === selectedServiceId.value): []
      if(selectedSerID[0] !== undefined && selectedSerID[0] !== 3 && selectedSerID[0] !==4){
        exchangeServiceIDPanel = true;
        exchangeTeam = sourceTeam && this.setExchangeId([sourceTeam],selectedServiceId);
      }
      else {exchangeServiceIDPanel = false;  }

      this.setState({sourceTeam, targetTeam:null,serviceQty:sourceTeam.quantity,exchangeServicesID:exchangeServiceIDPanel,
        getExchangeIds:exchangeTeam.exchangeId});
      const selectedSource = sourceTeam && sourceTeam.value && sourceTeam.label;
      this.props.getTeamUserTarget(this.state.targetUserToggle, selectedSource?sourceTeam:null, this.props.userData?.content);
    };

    onChangeTargetTeam = targetTeam => {
      this.setState({targetTeam});
    };

    onSourceToggleChange = (event) =>{
      this.exchangeToggle();
      const {selectedToggleButton, selectedEnvValue} = this.props;
      if(event.target.checked){
        this.props.getUserSource(selectedEnvValue, selectedToggleButton, this.props.userData?.content);
      }else{
        this.props.getTeamSource(selectedEnvValue, selectedToggleButton);
      }
      this.setState({serviceQty:0,sourceUserToggle:event.target.checked, selectedServiceId:null, sourceTeam:null, targetTeam:null, targetUserToggle:false});
    }

    onTargetToggleChange = (event) =>{
      const {sourceTeam} = this.state
      const selectedSource = sourceTeam && sourceTeam.value && sourceTeam.label;
      this.props.getTeamUserTarget(event.target.checked, selectedSource?sourceTeam:console.error("select source team"), this.props.userData?.content);
      this.setState({ targetUserToggle: event.target.checked , targetTeam:null});
    }

    filtersToggle = () =>
    {
      this.setState({
        serviceFilters : []
      })
    }

    onExchangeToggleChange = (event) =>{
      let exchangeQty = this.state.serviceQty;
      let selectedServiceId = this.state.selectedServiceId;
      let sourceTeam = this.state.sourceTeam;
      const {allocateSourceData} = this.props;
      const prepareOptions =  selectedServiceId ? allocateSourceData.source.service.filter((sd)=> sd.serviceId === selectedServiceId.value):[];
      const sourceTeamOptions = prepareOptions.map(option=>({ value: option.teamId, label: option.teamName, quantity: option.quantity }));
      const sourceTeamSelected = sourceTeamOptions.find(option => option.value === sourceTeam.value);
      exchangeQty = sourceTeamSelected && sourceTeamSelected.quantity || 0;
      this.setState({ exchangeServiceToggle: event.target.checked, serviceQty:exchangeQty,exchangeId: [] })
    }

  onChangeClass = classTeam => {
    this.setState((preState) => ({ ...preState, classTeam, techTeam: null }))
  };

  onChangeTech = techTeam => {
    this.exchangeToggle();
    this.setState((preState) => ({ ...preState, techTeam }))
  };

  onChangeExchangeId = event => {
    let serviceQty = this.state.serviceQty;
    let selectedServiceId = this.state.selectedServiceId;
    var index = event.target.selectedIndex;
    let sourceTeam = this.state.sourceTeam;
    var optionElement = event.target.childNodes[index]
    var option =  optionElement.getAttribute('data-id');
    if(option !== null && parseInt(option) < serviceQty)
      serviceQty = parseInt(option)
    else
    {
      const {allocateSourceData} = this.props;
      const prepareOptions =  selectedServiceId ? allocateSourceData.source.service.filter((sd)=> sd.serviceId === selectedServiceId.value):[];
      const sourceTeamOptions = prepareOptions.map(option=>({ value: option.teamId, label: option.teamName, quantity: option.quantity }));
      const sourceTeamSelected = sourceTeamOptions.find(option => option.value === sourceTeam.value);
      serviceQty = sourceTeamSelected && sourceTeamSelected.quantity;
    }
    this.setState({exchangeId : event.target.value, serviceQty: serviceQty})
  }

  customStyles = {
    valueContainer: provided => ({
      ...provided,
      display: "block",
      width: "100px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: "0.8rem",
      height: "43px"
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: state.hasValue ? "none" : "inherit",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: state.hasValue ? "none" : "inherit"
    })
  }

  onQtyChange = (e) => {
    const maxQty = this.state.serviceQty
    const currentQty = e.target.value;

    let isQtyInvalid = false;
    if (!isNumeric(currentQty) || currentQty > maxQty || !currentQty) {
      isQtyInvalid = true;
    }

    this.setState((preState) => {
      return { ...preState, quantity: currentQty, isQtyInvalid }
    })
  }

  onAllocationCommentsChange = (e) => {
    this.setState({allocationComments:e.target.value});
  }

  handleAllocateToTeam = () => {
    displayLoading({ title: "Allocating to team", bodyText: "Please standby" });
    const {loggedInUser} = this.props;
    const { classTeam, techTeam, sourceTeam, selectedServiceId, quantity, targetTeam,
      allocationComments , sourceUserToggle, targetUserToggle,exchangeId,filterCheckoutData} = this.state;
    let sourceReq = sourceUserToggle?{sourceUser:sourceTeam.value} : {sourceTeam: sourceTeam.value}
    let targetReq = targetUserToggle?{targetUser:targetTeam.value} : {targetTeam: targetTeam.value}
    let filter = [];
    if(filterCheckoutData && filterCheckoutData.length>0){
      filter = filterCheckoutData.map(data=>(
        {
          "operator": "equals",
            "resourceName": data.resourceName,
            "searchParameterName": data.searchParameterName,
            "searchParameterValue": (data.searchParameterName === "postCode") ? "{"+data.filterValue+"}" : data.filterValue,

        }

      ));
}
    const CURRENT_ENV = config.currentEnv;
    const scope = "TEAM";
    let logInUser = {loggedInUser};
    const dNumber = logInUser.loggedInUser.userName;
    const team = logInUser.loggedInUser.team;
    const noSqlRequest={
      ...sourceReq,
      ...targetReq,
      quantity,
      exchangeId,
      allocationComments: allocationComments.trim(),
      filter,
      scope,
      dNumber,
      team
    }
    const sqlRequest = {
      ...sourceReq,
      ...targetReq,
      quantity,
      exchangeId,
      allocationComments: allocationComments.trim(),
      filter
    }
    const request = (CURRENT_ENV === "1tdmPrd") ? sqlRequest : noSqlRequest;
    if (!_.isEmpty(classTeam) && !_.isEmpty(techTeam)) {
      request.classId = classTeam.value;
      request.technology = techTeam.value;
    }

    if (this.props.selectedToggleButton === ToggleButtonLabel.STANDARD) {
        request.serviceId = selectedServiceId && selectedServiceId.value;
        request.environment = this.props.selectedEnvValue;
    } else if (this.props.selectedToggleButton === ToggleButtonLabel.CUSTOM) {
        request.userStory = selectedServiceId && selectedServiceId.value;
    }
    const selectedToggleButtonValue = this.props.selectedToggleButton;

    this.props.allocateToTeam(request, selectedToggleButtonValue).then((result) => {
      if (result) {
        this.toggle();
        swal.fire("Success", result.responseDescription, "success").then(() => {
          // window.location.reload(); Note: for complete page refresh
          if (this.props.selectedToggleButton === ToggleButtonLabel.STANDARD) {
              this.props.myTeamUserToggle? this.props.loadUserFilterDetails({userList: this.props.userData?.content}):this.props.loadTeamFilterDetails();
          } else if (this.props.selectedToggleButton === ToggleButtonLabel.CUSTOM) {
              this.props.myTeamUserToggle? this.props.loadCustomUserFilterDetails(this.props.userData?.content):this.props.loadCustomTeamFilterDetails();
          }
        })
      }
    });
  }

  getClassOptions = () => {
    const {classDetails} = this.props;
    return _.map(classDetails, (c) => ({value: c.classId, label: c.classId})) || [];
  }

  getTechOptions = () => {
    const {classDetails} = this.props;
    const {classTeam} = this.state;
    const selectedClass = _.find(classDetails, (c) => classTeam && c && c.classId === classTeam.value);
    return _.map(selectedClass && selectedClass.technology || [], (c) => ({value: c, label: c})) || [];
  }

  isValidData = () => {
    const {quantity, selectedServiceId, classTeam, techTeam, sourceTeam, targetTeam} = this.state;
    let isInValid = false;
    if (this.isValidServiceIdForClassTech(selectedServiceId && selectedServiceId.value)) {
        isInValid = _.isEmpty(classTeam) || _.isEmpty(techTeam)
    }

    if (_.isEmpty(sourceTeam) || _.isEmpty(targetTeam) || _.isEmpty(quantity) || parseInt(quantity) === 0 ) {
        isInValid = true;
    }
    return isInValid;
  }

  render() {
    const {className, allocateSourceData, allocateTargetData, showModal,selectedRow,selectedEnvValue} = this.props;
    const { sourceTeamOptions, sourceUserToggle, targetUserToggle, techTeam, classTeam, sourceTeam, modal,
       quantity, targetTeam, isQtyInvalid, allocationComments, selectedServiceId,exchangeServicesID ,exchangeServiceToggle,exchangeId,serviceQty,getExchangeIds} = this.state;
    return (
      <Fragment>
        <Modal size="lg" isOpen={modal} toggle={this.toggle} backdrop="static" className={className}>
          <ModalHeader toggle={this.toggle}>
            <Grid container direction="row" spacing={10}>
              <Grid item>
                Allocate to Teams
              </Grid>
              <Grid item>
                <p className='showEnvSection' style={{fontSize: "16px", lineHeight: "30px"}}><b>Environment:</b> {selectedEnvValue}</p>
              </Grid>
            </Grid>
          </ModalHeader>
          <ModalBody>
            <section>
                <div className="row">
                  <div className="offset-1 col-4">
                    <Select
                        options={allocateSourceData.source.serviceIdOptions}
                        isClearable={true}
                        styles={this.customStyles}
                        placeholder={this.props.selectedToggleButton==="Standard" ? "Service Id" : "User Story"}
                        onChange={this.onServiceIdChange}
                        isLoading={allocateSourceData.pending}
                        value={selectedServiceId}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                  </div>
                  <div className="col-4">
                    <Select
                      key={`sourceTeamOptions${sourceTeam && sourceTeam.value}`}
                      options={sourceTeamOptions || []}
                      isClearable={true}
                      styles={this.customStyles}
                      placeholder={`Source ${sourceUserToggle?"User":"Team"}`}
                      onChange={this.onChangeSourceTeam}
                      value={sourceTeam}
                      isLoading={allocateSourceData.pending}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      />
                  <div className="pull-right">Qty:{serviceQty}</div>
                  </div>
                  <div className="col-1" style={{paddingTop:'21px', textAlign:'right', textAlign:'-webkit-right'}}>
                    <UserSwitch
                    checked={sourceUserToggle}
                    onChange={this.onSourceToggleChange}
                    />
                  </div>
                  <div className="col-1" style={{paddingTop:'19px', paddingLeft:'0px'}}>
                    {sourceUserToggle ? "User":"Team"}
                  </div>
                </div>
                <div className="row">
                    <div className="offset-1 col-4">
                      <input
                        required
                        type="text"
                        id="team-qty"
                        className={`form-control ${isQtyInvalid && "team-allocate-qty-invalid"}`}
                        placeholder="qty"
                        title="qty"
                        value={quantity}
                        onChange={this.onQtyChange}
                      />
                    </div>
                    <div className="col-4">
                      <Select
                        key={`targetTeamOptions${targetTeam && targetTeam.value}`}
                        options={allocateTargetData.target.targetOptions}
                        isClearable={true}
                        styles={this.customStyles}
                        placeholder={`Target ${targetUserToggle?"User":"Team"}` }
                        onChange={this.onChangeTargetTeam}
                        value={targetTeam}
                        isLoading={allocateTargetData.pending}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                    <div className="col-1" style={{paddingTop:'21px',textAlign:'right', textAlign:'-webkit-right'}}>
                        <UserSwitch
                        checked={targetUserToggle}
                        onChange={this.onTargetToggleChange}
                        />
                    </div>
                    <div className="col-1" style={{paddingTop:'19px', paddingLeft:'0px'}}>
                      {targetUserToggle ? "User":"Team"}
                    </div>
                  </div>
                  {this.isValidServiceIdForClassTech(selectedServiceId && selectedServiceId.value) &&
                  <div className="row my-l">
                    <div className="offset-1 col-4">
                      <Select
                        key={`classTeam${classTeam && classTeam.value}`}
                        options={this.getClassOptions()}
                        isClearable={true}
                        styles={this.customStyles}
                        placeholder={"Class"}
                        onChange={this.onChangeClass}
                        value={classTeam}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <Select
                        key={`techTeam${techTeam && techTeam.value}`}
                        options={this.getTechOptions()}
                        isClearable={true}
                        styles={this.customStyles}
                        placeholder={"Tech"}
                        onChange={this.onChangeTech}
                        value={techTeam}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                  </div>
                }
                {
                  allocateSourceData.failed && <FailedView message={allocateSourceData.errorMessage || "Could not load Service Id / Source Team or User Details"} />
                }
                {
                  allocateTargetData.failed && <FailedView message={allocateTargetData.errorMessage || "Could not load Target Team/User Details"} />
                }
                 {exchangeServicesID && sourceTeam !== undefined &&
                 <div className="ml-17">
                 <ExchangeServicePanel exchangeId={exchangeId}
                      onChangeHandler={this.onChangeExchangeId} exchanges={[...getExchangeIds]}
                      checked={exchangeServiceToggle} onChangeSwitchHandler={this.onExchangeToggleChange}
                      customStyles={this.customStyles}
                      helperText="Exchange Id"
                      displayToggle="true"/></div>
                  }
                <section>
                  {this.props.allocateServiceFilterData != undefined &&
                  this.props.allocateServiceFilterData.data &&
                  this.props.allocateServiceFilterData.success &&
                  this.state.serviceFilterRows.length > 0 ?
                   <><div className="row">
                    <div className="col-5">
                        <b>Filter</b>
                    </div>
                    <div className="col-5">
                        <b>Value</b>
                    </div>
                  </div>
                  <div>
                 {
                    this.state.serviceFilterRows.map((row, index) =>
                  {  return (
                  <div key={index}>{row}</div>
                  )
                    }
                  )
                  }</div></> : <></>}
                </section>
                <div className="row my-l">
                  <div className="col">
                    <textarea
                      className="form-control"
                      id="team-comment"
                      placeholder="Allocation Comment"
                      value={allocationComments || ''}
                      onChange={this.onAllocationCommentsChange}
                      rows="4"
                    ></textarea>
                  </div>
                </div>

            </section>

          </ModalBody>
          <ModalFooter>
            <Button
              className={"btn-team"}
              color="success"
              onClick={this.handleAllocateToTeam}
              disabled={isQtyInvalid || this.isValidData() }
            >
              <span className={"pxr-s"}>Allocate</span>
              {targetTeam && quantity && !isQtyInvalid && <i className="fa fa-check"></i>}
            </Button>
            <Button color="danger" className={"btn-team"} onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {

  return {
    classDetails: state.teams.classDetails,
    allocateSourceData: state.teams.allocateSourceData,
    allocateTargetData: state.teams.allocateTargetData,
    exchangeData: state.teams.allocateExchangeData,
    selectedEnvValue: state.auth.selectedEnvValue,
    filterServiceIds: state.teams.filterServiceIds,
    allocateServiceFilterData: state.teams.allocateServiceFilterData,
    serviceFilterDataValues: state.teams.serviceFilterDataValues,
    loggedInUser: state.auth,
    userData: state.users.mgmt
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getTeamSource:(selectedEnvValue, selectedToggleButton) =>dispatch(oldGetTeamSource(selectedEnvValue, selectedToggleButton)),
    getUserSource:(selectedEnvValue, selectedToggleButton, userList) =>dispatch(getUserSource(selectedEnvValue, selectedToggleButton, userList)),
    getTeamUserTarget:(targetUserToggle, selectedSource, userList) =>dispatch(getTeamUserTarget(targetUserToggle, selectedSource, userList)),
    fetchClassTechOptions: () => dispatch(fetchClassTechOptions()),
    allocateToTeam: (request, selectedToggleButtonValue) => dispatch(allocateToTeam(request, selectedToggleButtonValue)),
    fetchExchangeIDs: (selectedEnvValue) => dispatch(fetchExchangeIDs(selectedEnvValue)),
    checkoutServiceFilterValue: (request) => dispatch(checkoutServiceFilterValue(request)),
    checkoutServiceFilters: (request) => dispatch(checkoutServiceFilters(request)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AllocateTeamModal);
