import React from "react";
import { 
  makeStyles, 
  Grid, 
  Typography, 
  Dialog,
  DialogContent, 
  DialogActions,
  Button,
  Tooltip
} from '@material-ui/core';
import {FormTextArea} from "@library/core/form-elements";
import { blueGrey } from "@material-ui/core/colors";
import Rating from '@mui/material/Rating';

const useStyles = makeStyles({
  modalHeader: {
    backgroundColor: "#3f51b5",
    color: "white",
    marginBottom: "10px"
  },
  modalBody: {
    height: "450px",
    margin: "15px",
    overflowY: "scroll",
    scroll: "hidden",
  },
  errMsg: {
    color: blueGrey[700],
  },
  modalTitle: {
      width: "100% !important",
      '& .modal-title': {
        width: "100% !important"
      }
    }
});

const AddFeedBackModal = (props) => {
  const classes = useStyles();

  const { 
    openModal,
    onCloseModal = ()=>{},
    rating,
    setRating,
    feedbackComment,
    addFeedbackComment,
    feedbackErrorMsg,
    submitComment,
    disableProvideLater
  } = props;

  return (
    <Dialog open={openModal} onClose={onCloseModal} fullWidth maxWidth={"sm"} PaperProps={{style: { borderRadius: "10px"}}}>
      <DialogContent>
        <Grid container direction="column">
          <Grid item xs={12} style={{"textAlign":"center"}}>
             <Typography align="center" variant="h5" style={{"fontWeight":"600"}}>
              Rate Us!
            </Typography>
          </Grid>
          <Grid item xs={12} style={{"textAlign":"center"}}>
            <Rating 
              name="simple-controlled"
              size="large"
              style={{"fontSize":"3.875rem"}}
              value={rating}
              onChange={(event, newValue) => {
                  setRating(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea 
              label="Add your Feedback"
              id="feedback"
              fullWidth
              value={feedbackComment}
              onChange={(e) => addFeedbackComment(e.target.value)}
              helperText={feedbackErrorMsg ? "Please provide your feedback" : `${feedbackComment.length}/200`}
              autoComplete="off"
              inputProps={{ maxLength: 200 }}
              rows={7}
              error={feedbackErrorMsg}
              placeholder={"What did or didn't you like?"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Tooltip title={disableProvideLater ? "Skip limit reached must provide feedback!" : ""} placement="top">
          <span>
            <Button onClick={onCloseModal} disabled={disableProvideLater}>Provide Later</Button>
          </span>
        </Tooltip>
        <Button color="primary" onClick={submitComment}>Submit Feedback</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddFeedBackModal;