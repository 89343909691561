import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import { setStatelessSimType, prodData,setSpecificRequirement } from "../../../actions/orderBuilderActions";
import StatelessCableProduct from './Stateless/StatelessCableProduct';
import StatelessNBNProduct from './Stateless/StatelessNBNProduct';
import StatelessPSTNProduct from "./Stateless/StatelessPSTNProduct";
import StatelessFoxtelProduct from "./Stateless/StatelessFoxtelProduct";
import StatelessPhysical from "./Stateless/StatelessPhysical";
import StatelessPrepaid from "./Stateless/StatelessPrepaid";
import StatelessPostpaid from "./Stateless/StatelessPostpaid";




const useStyles = makeStyles({
  stateLabel: {
    backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
    color: "#ffffff  !important",
    marginBottom: "20px !important",
    marginLeft: "20px !important"
  },
  stateSelectedLabel: {
    marginBottom: "20px !important",
    backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
    color: "#ffffff !important",
    marginLeft: "20px !important"
  },
  productHeaderLabel: {
    fontSize: "24px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    paddingLeft: "20px !important"
  },
  yesSpecification: {
    width: "650px !important",
    marginLeft: "23px !important",
    marginTop: "20px !important"
  }

});


const StatelessOrder = (props) => {

  const classes = useStyles();

  const [statelessProducts, setStatelessProducts] = React.useState(["NBN", "PSTN-ADSL", "Cable", "Foxtel","Prepaid", "Postpaid","Others"]);

  const [nbnClasses, setNBNClasses] = React.useState([ "None","0-FTTP","1-FTTP","2-FTTP","3-FTTP",
    "4-FW","5-FW","6-FW","10-FTTN","10-FTTB","11-FTTN","12-FTTN","12-FTTB","13-FTTN","13-FTTB","20-HFC",
      "21-HFC","22-HFC","23-HFC","24-HFC","30-FTTC","31-FTTC","32-FTTC","33-FTTC","34-FTTC"]);
 const [clearNBN , setClearNBN] = React.useState(false);
 const [clearPSTN , setClearPSTN] = React.useState(false);
 const [clearCable , setClearCable] = React.useState(false);
 const [clearFoxtel , setClearFoxtel] = React.useState(false);
 const [clearPhysicalSim , setClearPhysicalSim] = React.useState(false);
 const [clearPrepaid , setClearPrepaid] = React.useState(false);
 const [clearPostPaid , setClearPostPaid] = React.useState(false);


 const [selectedStatelessProduct, setSelectedStatelessProduct] = React.useState("");
  const [simTypes, setSimTypes] = React.useState(["Physical", "Logical"])
  const [selectedSimType, setSelectedSimType] = React.useState("");
  const [otherTypeData , setOtherTypeData] = React.useState("");



  const clearAll = () => {
  }

  useEffect(() => {
    setClearNBN(true);
    setClearPSTN(true);
    setClearCable(true);
    setClearFoxtel(true);
    setClearPhysicalSim(true);
    setClearPrepaid(true);
    setClearPostPaid(true);
      }, [statelessProducts]);

      const setSpecReqData= (ppData,value) => 
      {
          let key = "CUSTOM: VACANT OTHER "+ppData.toUpperCase()+" DATA"
          props.setSpecificRequirement({[key]: value }, "");
      }

  return (
    <div className='pt-10 pb-5'>
      {/* Product */}
      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          {statelessProducts.map((statelessProduct, index) => {
            return (
              <Button variant="contained" classes={statelessProduct !== selectedStatelessProduct ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                sx={{ minWidth: 150, minHeight: 150 }} value={statelessProduct} onClick={e => { setSelectedStatelessProduct(e.target.value); clearAll(); }}>
                {statelessProduct}
              </Button>
            )
          })}
        </Grid>
      </Grid>
      {/* Sub-Product-1 */}
      <Grid container spacing={3}>
        <Grid item xs={1} />
        {/* NBN */}
        {selectedStatelessProduct === "NBN" ?
          <StatelessNBNProduct nbnClasses={nbnClasses} clearNBN={clearNBN}/> : <></>}
        {/* PSTN */}
        {selectedStatelessProduct === "PSTN-ADSL" ?
          <StatelessPSTNProduct nbnClasses={nbnClasses} clearPSTN={clearPSTN}/>: <></>}
        {/* Cable */}
        {selectedStatelessProduct === "Cable" ?
           <StatelessCableProduct nbnClasses={nbnClasses} clearCable={clearCable}/>: <></>}
        {/* Cable */}
        {selectedStatelessProduct === "Foxtel" ?
          <StatelessFoxtelProduct nbnClasses={nbnClasses} clearFoxtel={clearFoxtel}/>: <></>}
        {selectedStatelessProduct === "Physical Sim" ?
          <StatelessPhysical nbnClasses={nbnClasses} clearPhysicalSim={clearPhysicalSim}/>: <></>}
        {selectedStatelessProduct === "Prepaid" ?
          <StatelessPrepaid nbnClasses={nbnClasses} clearPrepaid={clearPrepaid}/>: <></>}
        {/* "Physical Sim", "Hybrid Sim", "4GFW", "eSIM"*/}
        {((selectedStatelessProduct === "Hybrid Sim") ||
          (selectedStatelessProduct === "4GFW") ||
          (selectedStatelessProduct === "eSIM")) ?
          <>
            <Grid item xs={10}>
              <Typography classes={{ root: classes.productHeaderLabel }}>Please select the {selectedStatelessProduct} Type</Typography>
              {simTypes.map((sim, index) => {
                return (
                  <Button variant="contained" classes={sim !== selectedSimType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                    sx={{ minWidth: 150, minHeight: 150 }} value={sim} onClick={e => { setSelectedSimType(e.target.value); }}>
                    {sim}
                  </Button>
                )
              })}</Grid>
          </> : <></>}
        {/* "Prepaid Sim"*/}
        
        {/* "Postpaid Sim"*/}
        {selectedStatelessProduct === "Postpaid" ?
         <StatelessPostpaid  nbnClasses={nbnClasses} clearPostPaid={clearPostPaid}/> : <></>}

      {selectedStatelessProduct === "Others" ?
         <TextField
         id="others data"
         multiline
         rows={2}
         label="CUSTOM VACANT OTHERS DATA"
         helperText="Enter the specific requirement"
         classes={{ root: classes.yesSpecification }}
         value={otherTypeData}
         onChange={e => {
             setOtherTypeData(e.target.value);
             setSpecReqData(e.target.value,e.target.value)
         }}
     /> : <></>}
      </Grid>
     
     
     

     

   
     
     


    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    openBroadCastPanel: state.auth.openBroadCastPanel,
    broadCastList: state.broadCastReducer.broadCastDetails.broadCastList,
    broadCastMessage: state.auth.broadCastMessage,
    broadCastLoading: state.broadCastReducer.broadCastDetails.pending
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
    prodData: (value) => dispatch(prodData(value)),
    setSpecificRequirement : (value,sID) => dispatch(setSpecificRequirement(value,sID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatelessOrder);