import React from 'react';
import {SubHeader} from "../../../common/custom/SubHeader";
import {Table} from "reactstrap";
import _ from 'lodash';

export default function CustomTable ({ customDetails }) {
    if (_.isEmpty(customDetails)) {
        return null;
    }

    const allCustomDetails = _.uniq(_.flattenDeep(_.map(customDetails, (_customDetail) => _.keys(_customDetail))));

    return(
        <React.Fragment>
                <React.Fragment>
                    <SubHeader
                        text={"Custom Details"}
                        />
                    <Table responsive hover>
                        <thead>
                            <tr>
                                {_.map(allCustomDetails, (name, index) =>
                                    <th key={index}>{name}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {customDetails.map((customDetailObj, index) =>
                                <tr key={index}>
                                    {_.map(allCustomDetails, (value, index) =>
                                        <td scope="row" key={index}>{customDetailObj[value] || ''}</td>
                                    )}
                                </tr>
                        )}
                        </tbody>
                    </Table>
                </React.Fragment>
        </React.Fragment>
    );
}
