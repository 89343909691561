import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableContainer } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    width: '24rem',
    marginTop: '90px',
    marginLeft: '390px'
  },
  adhoc: {
    backgroundColor: "#FF0000",
    
  },
  repeatable: {
    backgroundColor: "#d07505",
    
  },
  consistent: {
    backgroundColor: "#ffab01",
    
  },
  optimised: {
    backgroundColor: "#87c115"
    
  },
  leading: {
  backgroundColor: "#6fa009"

}
});

const gunnarStyle       = { height: "10px", padding: "0px"};
function SimpleTable(props) {
  const { classes } = props;

  return (
    <TableContainer component={Paper}  className={classes.root}>
    
      <Table className={classes.table} size="small" aria-label="a dense table">
         <TableBody>
         
              <TableRow>
                <TableCell className={classes.adhoc}>
                0-20%
                </TableCell>
                <TableCell className={classes.adhoc} align="center">Adhoc</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.repeatable}>
                20-40%
                </TableCell>
                <TableCell className={classes.repeatable} align="center">Repeatable</TableCell>
                </TableRow>
                 <TableRow>
                <TableCell className={classes.consistent} >
                40-60%
                </TableCell>
                <TableCell  className={classes.consistent} align="center">Consistent</TableCell>
              </TableRow>
              <TableRow>
                <TableCell  className={classes.optimised}>
                60-80%
                </TableCell>
                <TableCell  className={classes.optimised} align="center">Optimised</TableCell>
              </TableRow>
              <TableRow> 
                <TableCell  className={classes.leading}>
                80-100%
                </TableCell>
                <TableCell className={classes.leading} align="center">Leading</TableCell>
              </TableRow> 
            
          
        </TableBody>
      </Table>
      
    </TableContainer>
  );
}

SimpleTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SimpleTable);