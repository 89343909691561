import React, {Component, Fragment} from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { Button } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import CustomerDataTableRow from './CustomerDataTableRow';
import swal from 'sweetalert2';
import { displayLoading, displaySuccess, displayErrorAlert } from "../../common/alerts/alerts";
import { customerSync, customerSyncStatus, findSiebelCustomer } from '../../../actions/utilityActions';
import "./CustomerConsole.css";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import ButtonMUI from '@mui/material/Button';

class CustomerDataTable extends Component {

    componentWillUnmount() {
        this.props.customerDataPending || 
        this.props.customerSyncPending || 
        this.props.custSyncStatusPending ? swal.close() : null;
    }

    handleCustSync = () => {
        if (this.props.customerSyncResponse && this.props.customerSyncResponse[this.props.customerData.customerID] && this.props.customerSyncResponse[this.props.customerData.customerID].jobId && this.props.customerSyncResponse[this.props.customerData.customerID].status != 'FAILURE') {
            displayLoading({ title: "Customer Sync", bodyText: "Please standby while we retrieve customer sync status" });
            this.props.customerSyncStatus(this.props.customerData.customerID, this.props.customerSyncResponse[this.props.customerData.customerID].jobId).then(() => {
                this.props.findSiebelCustomer(this.props.selectedEnvValue, (this.props.customerData.customerID + '/' + this.props.selectedEnvValue)).then(() => {
                    if (this.props.customerDataSuccess && !this.props.customerDataPending) {
                        swal.close();
                        if (this.props.custSyncStatusSuccess) {
                            displaySuccess({ title: "Customer Sync Status", 
                                            html: `<b><b>Job ID:</b> ${this.props.customerSyncResponse[this.props.customerData.customerID].jobId}, <b>Status:</b> ${this.props.customerSyncResponse[this.props.customerData.customerID].status}`});
                        } else if (this.props.custSyncStatusFailed) {
                            displayErrorAlert({
                                title: "Retrieve Status Failed",
                                subTitle: "Failed to retrieve status, please try again later",
                                errorMsg: this.props.customerSyncResponse[this.props.customerData.customerID].statusError
                            });
                        }
                    }
                });
            });
        } else {
            displayLoading({ title: "Customer Sync", bodyText: "Please standby while we submit Customer Sync request" });
            this.props.customerSync(this.props.customerData.customerID).then(() => {
                swal.close();
                if (this.props.customerSyncSuccess) {
                    displaySuccess({ title: "Customer Sync",
                                     html: `Request submitted, usually takes 3 min to fulfill.<br/><br/>
                                            <b><b>Job Id: </b>${this.props.customerSyncResponse[this.props.customerData.customerID].jobId}, <b>Status: </b>${this.props.customerSyncResponse[this.props.customerData.customerID].status}` });
                } else if (this.props.customerSyncFailed) {
                    displayErrorAlert({
                        title: "Customer Sync Failed",
                        subTitle: "Failed to Sync Customer, please try again later",
                        errorMsg: this.props.customerSyncResponse[this.props.customerData.customerID].error
                    });
                }
            });
        }
    }

    render() {
        const customerData = this.props.customerData;
        const {push} = this.props;
        if (this.props.customerDataFailed) {
            swal.close();
            displayErrorAlert({
                title: "Refresh Customer Details Failed",
                subTitle: "Failed to refresh customer details, please try again later",
                errorMsg: this.props.customerDataMsg
            });
            return <Redirect to="/data/utility/"/>
        }
        return (
            <div className="white contact-view-page" style={{"minHeight":"280px"}}>
                <span class="gradient-icon base-green ">
                    <h2 style={{float:"left"}}>Customer <i class="td-icon-sm icon-crowd-support theme-text-primary"></i></h2>
                </span>
                <ButtonMUI
                style={{marginLeft: "885px"}}
                onClick={()=>{push('/data/utility')}} 
                variant="contained"
                startIcon={<ReplyAllIcon />}>Back to Utilities</ButtonMUI>
                <section id="contact-table-area">
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>Customer ID</th>
                                <th>Type</th>
                                <th>SF ID</th>
                                <th>CIDN</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>PHONE</th>
                            </tr>
                        </thead>
                        <tbody id="contact-details-table-body">
                            {
                                [customerData].map((tableRowData, index) => {
                                    return (
                                        <Fragment>
                                            <CustomerDataTableRow
                                                key={index}
                                                tableRowData={tableRowData}
                                            />
                                        </Fragment>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </section>
                <div style={{"display":"flex","marginLeft":"20px"}}>
                    <Tooltip title={this.props.customerSyncResponse && 
                                    this.props.customerSyncResponse[this.props.customerData.customerID] && 
                                    this.props.customerSyncResponse[this.props.customerData.customerID].jobId && 
                                    this.props.customerSyncResponse[this.props.customerData.customerID].status != 'FAILURE' && 
                                    !this.props.customerData.accountId ? 
                                    "Refresh Customer Sync Status" : customerData.accountId ? 
                                    "Available only to customers with no SF ID" : "Start Customer Sync"} placement="top" color='primary' arrow>
                        <span>
                            <Button disabled={customerData.accountId} 
                                    color="primary" 
                                    onClick={() => this.handleCustSync()}>
                                {
                                    this.props.customerSyncResponse && 
                                    this.props.customerSyncResponse[this.props.customerData.customerID] && 
                                    this.props.customerSyncResponse[this.props.customerData.customerID].jobId && 
                                    this.props.customerSyncResponse[this.props.customerData.customerID].status != 'FAILURE' && 
                                    !this.props.customerData.accountId ? "Refresh Status":"Cust Sync"
                                }
                            </Button>
                        </span>
                    </Tooltip>
                    {
                        this.props.customerSyncResponse && 
                        this.props.customerSyncResponse[this.props.customerData.customerID] && 
                        this.props.customerSyncResponse[this.props.customerData.customerID].status && 
                        !this.props.customerData.accountId ? <p style={{"paddingLeft":"15px","lineHeight":"10px"}}><strong>Status: </strong>{this.props.customerSyncResponse[this.props.customerData.customerID].status}</p> : null
                    }
                </div>
            </div>
        )
    }
    
}

const mapStateToProps = (state) => {
    return {
        customerData: state.utility.siebelCustomer.customer,
        customerDataPending: state.utility.siebelCustomer.isSearchPending,
        customerDataFailed: state.utility.siebelCustomer.isSearchError,
        customerDataSuccess: state.utility.siebelCustomer.isSearchSuccess,
        customerDataMsg: state.utility.siebelCustomer.message,

        customerSyncSuccess: state.utility.customerSync.isCustomerSyncSuccess,
        customerSyncPending: state.utility.customerSync.isCustomerSyncPending,
        customerSyncFailed: state.utility.customerSync.isCustomerSyncFailed,
        customerSyncResponse: state.utility.customerSync.responses,

        selectedEnvValue: state.auth.selectedEnvValue,
        custSyncStatusSuccess: state.utility.customerSync.isCustomerSyncStatusSuccess,
        custSyncStatusPending: state.utility.customerSync.isCustomerSyncStatusPending,
        custSyncStatusFailed: state.utility.customerSync.isCustomerSyncStatusFailed,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        customerSync: (custId) => dispatch(customerSync(custId)),
        customerSyncStatus: (custId, jobId) => dispatch(customerSyncStatus(custId, jobId)),

        findSiebelCustomer : (env, request) => dispatch(findSiebelCustomer(env, request)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CustomerDataTable);