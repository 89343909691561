import _ from 'lodash';
import * as types from '../actions/actionTypes';


const d = new Date();
let year = d.getFullYear();
let month = (1 + d.getMonth()).toString().padStart(2, '0');
let day = d.getDate().toString().padStart(2, '0');
const dString = day+"/"+month+"/"+year


const INITIAL_STATE = {
    tableHeaderKeys: ["MSISDN", "SIM", "CIDN" ,"BAN", "Plan Name", "Product Code"],
    ddcColumns: [{
        "index": 0, "colValue": [{ "key": "MSISDN", "default": false, "value": " " },
        { "key": "SIM", "default": false, "value": " " },
        { "key": "Customer Id", "default": false, "value": " " },
        { "key": "BAN", "default": false, "value": " " },
        { "key": "Plan Name", "default": false, "value": " " },
        { "key": "Product Code", "default": false, "value": " " }]
    }],

    billingColumns: [      
        { "key": "Source System", "value": ["MICA","FLEXCAB"], "edit": false },
        { "key": "Billing Name Line 1", "value": "TDM", "edit": true },
        { "key": "Billing Name Line 2", "value": " ", "edit": true },
        { "key": "Billing Account Status", "value": "Active", "edit": true },
        { "key": "Start Date", "value": dString, "edit": true },
        { "key": "End Date", "value": " ", "edit": true },
        { "key": "Business Name", "value": " ", "edit": true },
        { "key": "In Collections", "value": " ", "edit": true },
        { "key": "ICMS Indicator", "value": " ", "edit": true },
        { "key": "Single Bill Indicator", "value": " ", "edit": true },
        { "key": "Online billing indicator", "value": " ", "edit": true },
        { "key": "Direct Debit indicator", "value": " ", "edit": true },
        { "key": "Bill Frequency", "value": " ", "edit": true },
        { "key": "Bill Periodicity", "value": "Monthly", "edit": true },
        { "key": "Bill Cycle", "value": " ", "edit": true },
        { "key": "Address Line1", "value": "28 BELLVIEW PL", "edit": true },
        { "key": "Address Line2", "value": "", "edit": true },
        { "key": "Address Line3", "value": "PARKINSON QLD 4115", "edit": true }],

    assetsColumns: [
        { "key": "Parent Source System", "value": "MICA", "edit": false },
        { "key": "Source System", "value": "MICA", "edit": false },
        { "key": "Service Type", "value": "Mobile Service", "edit": true },
        { "key": "Status", "value": "Active ", "edit": true },
        { "key": "Service Start Date", "value": dString, "edit": true },
        { "key": "Service End Date", "value": " ", "edit": true },
        { "key": "Contract Name", "value": " ", "edit": true },
        { "key": "Contract End Date", "value": " ", "edit": true },
        { "key": "MRO NAME", "value": "Monthly", "edit": true },
        { "key": "MRO End Date", "value": " ", "edit": true },
        { "key": "Password", "value": " ", "edit": true },
        { "key": "Reason for Suspension", "value": " ", "edit": true },
        { "key": "Parent Asset Integration Id", "value": " ", "edit": true }],



    msisdnList: [],
    simList: [],
    banList: [],
    planNameList: [],
    pcList: [],
    billingDetails: [],
    assetsDetails: [],
    billingInput: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.ADD_DDC_COLUMNS:
            return {
                ...state,
                ddcColumns: action.payload,
            }
        case types.UPDATE_DDC_COLUMNS:
            return {
                ...state,
                ddcColumns: action.payload
            }
        case types.RESET_DDC_COLUMNS:
            return {
                ...state,
                ddcColumns: [{
                    "index": 0, "colValue": [{ "key": "MSISDN", "default": false, "value": " " },
                    { "key": "SIM", "default": false, "value": " " },
                    { "key": "Customer Id", "default": false, "value": " " },
                    { "key": "BAN", "default": false, "value": " " },
                    { "key": "Plan Name", "default": false, "value": " " },
                    { "key": "Product Code", "default": false, "value": " " }]
                }],
                msisdnList: [],
                simList: [],
                banList: [],
                planNameList: [],
                pcList: [],
                billingDetails: [],
                assetsDetails: []
            }
        case types.SET_DDC_COLUMNS:
            return {
                ...state,
                msisdnList: action.msisdnList,
                simList: action.simList,
                cidnList : action.cidnList,
                banList: action.banList,
                planNameList: action.planNameList,
                pcList: action.pcList,
                billingColumns: [...state.billingColumns,
                { "key": "Customer Id", "value": [...action.cidnList], "edit": false },
                { "key": "BAN", "value": [...action.banList], "edit": false }],
                assetsColumns: [...state.assetsColumns,
                { "key": "MSISDN", "value": [...action.msisdnList], "edit": false },
                { "key": "SIM", "value": [...action.simList], "edit": false },
                { "key": "Customer Id", "value": [...action.cidnList], "edit": false },
                { "key": "BAN", "value": [...action.banList], "edit": false },
                { "key": "Plan Name", "value": [...action.planNameList], "edit": false },
                { "key": "Product Code", "value": [...action.pcList], "edit": false }],
                billingInput: ["MICA", "UniqueId", "TDM", "", "Active",
                    dString, "", "", "",
                    "", "", "", "", "", "Monthly",
                    "", "28 BELLVIEW PL", "", "PARKINSON QLD 4115", [...action.cidnList],[...action.banList]]
            }
        case types.RESET_BILLING_COLUMNS:
            return {
                ...state,
                billingColumns: [
                    { "key": "Source System", "value": "MICA", "edit": true },
                    { "key": "Billing Name Line 1", "value": "TDM", "edit": true },
                    { "key": "Billing Name Line 2", "value": " ", "edit": true },
                    { "key": "Billing Account Status", "value": "Active", "edit": true },
                    { "key": "Start Date", "value": dString, "edit": true },
                    { "key": "End Date", "value": " ", "edit": true },
                    { "key": "Business Name", "value": " ", "edit": true },
                    { "key": "In Collections", "value": " ", "edit": true },
                    { "key": "ICMS Indicator", "value": " ", "edit": true },
                    { "key": "Single Bill Indicator", "value": " ", "edit": true },
                    { "key": "Online billing indicator", "value": " ", "edit": true },
                    { "key": "Direct Debit indicator", "value": " ", "edit": true },
                    { "key": "Bill Frequency", "value": " ", "edit": true },
                    { "key": "Bill Periodicity", "value": "Monthly", "edit": true },
                    { "key": "Bill Cycle", "value": " ", "edit": true },
                    { "key": "Address Line1", "value": "28 BELLVIEW PL", "edit": true },
                    { "key": "Address Line2", "value": "", "edit": true },
                    { "key": "Address Line3", "value": "PARKINSON QLD 4115", "edit": true }]
            }
        case types.RESET_ASSETS_COLUMNS:
            return {
                ...state,
                assetsColumns: [
                    { "key": "Parent Source System", "value": "MICA", "edit": true },
                    { "key": "Source System", "value": "MICA", "edit": true },
                    { "key": "Service Type", "value": "Mobile Service", "edit": true },
                    { "key": "Status", "value": "Active ", "edit": true },
                    { "key": "Service Start Date", "value": dString, "edit": true },
                    { "key": "Service End Date", "value": " ", "edit": true },
                    { "key": "Contract Name", "value": " ", "edit": true },
                    { "key": "Contract End Date", "value": " ", "edit": true },
                    { "key": "MRO NAME", "value": " ", "edit": true },
                    { "key": "MRO End Date", "value": " ", "edit": true },
                    { "key": "Password", "value": " ", "edit": true },
                    { "key": "Reason for Suspension", "value": " ", "edit": true },
                    { "key": "Parent Asset Integration Id", "value": " ", "edit": true }]

            }
        case types.RESET_BILLING_ASSET_DATA : 
            return {
                ...state,
                billingDetails : [],
                assetsDetails : []

            }
        case types.SET_BILLING_DATA:
            return {
                ...state,
                billingDetails: action.payload
            }
        case types.SET_ASSETS_DATA:
            return {
                ...state,
                assetsDetails: action.payload
            }
        default:
            return state;
    }
}