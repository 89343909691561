import _ from 'lodash';
import * as types from '../actions/actionTypes';
import { updateObjectReadFlag } from '../utils/notification';

const INITIAL_STATE = {
    parentProgram : [],
    graphTeams : [],
    graphTeamMaturity : [],
    trendAnalysisData : [],
    trendProgram : [],
    trendTeam : [],
    trendType : "Quarter",
    ytdData : {},
    survey : {},
    qualmetric : {},
    displayReport : false,
    quarters : []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_PARENT_PROGRAMS:
            return {
                ...state,
                parentProgram: action.payload,
                graphTeams : [],
                graphTeamMaturity: [],
                trendProgram: []
            }  
        case types.GRAPH_TEAMS:
            return {
                ...state,
                graphTeams: action.payload,
                graphTeamMaturity: action.streamMaturity,
                trendProgram : []

            }    
            
        case types.FETCH_TRENDS:
            return {
                ...state,
                graphTeams : [],
                graphTeamMaturity: [],
                trendAnalysisData :action.payload,
                trendProgram: [],
                ytdData : {}

            }
        case types.FETCH_YTD_TRENDS: 
            return {
                ...state,
                ytdData :{
                    period : action.payload.period
                }
            }
        case types.SET_PROGRAMS:
            return {
                ...state,
                trendProgram : action.payload,
            }
        case types.SET_GRAPH_TEAM:
            return {
                ...state,
                trendTeam : action.payload
            }
        case types.SET_TRENDTYPE: 
            return {
                ...state,
                trendType : action.payload,
            }
            case types.LOAD_PASMAT_LOADING:
                return {
                    ...state,
                    survey: {
                        ...state.survey,
                        loading: action.loading,
                        errorMessage : false
                    }
                } 
        case types.LOAD_PASMAT_SUCCESS:
                return {
                    ...state,
                    survey: {
                        ...action.payload,
                        loading: false,
                        errorMessage : false
                    },
                }
        case types.LOAD_PASMAT_FAILED:
                return {
                    ...state,
                    surevy : {
                        ...state.survey,
                        loading: false,
                        errorMessage : true
                    },
                }

                case types.LOAD_QUAL_METRIC_LOADING:
                    return {
                        ...state,
                        qualmetric: {
                            ...state.qualmetric,
                            loading: action.loading,
                        },
                    } 
                case types.DISPLAY_REPORT:
                    return {
                        ...state,
                        displayReport: action.payload
                    }
            case types.LOAD_QUAL_METRIC_SUCCESS:
                    return {
                        ...state,
                        qualmetric: {
                            ...action.payload,
                            loading: false
                        },
                    }  
            case types.LOAD_QUARTER_LOADING:
                return {
                    ...state,
                    quarters : []
                } 
                case types.LOAD_QUARTER_SUCCESS:
                    return {
                        ...state,
                        quarters : action.payload
                    }     
        default:
            return state;
    }
}