import React from "react";

export default () => {
  return (
    <div className="row my-account-detail">
      <div className="col-sm-1 ">
        <span className="float-left base-white ">
          <i className="td-icon-sm custom-icon-size icon-my-details theme-text-primary" />
        </span>
      </div>
      <div className="col-sm-11 zero-padding">
        <span style={{ fontSize: "x-large" }}>My Account Details</span>
      </div>
    </div>
  );
};
