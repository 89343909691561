import React, { Component } from 'react';

import DiagramGrid from './DiagramGrid';
import DiagramViewModal from './modals/DiagramViewModal';
import { SubHeader } from '../common/custom/SubHeader';
import { connect } from 'react-redux';
import { fetchDiagramImages } from '../../actions/diagramActions';

export class Diagrams extends Component {

  componentDidMount() {
    this.props.fetchDiagrams();
  }

  render() {
    return (
      <div className="black">
        <SubHeader text="System Flow Diagrams" />
        <DiagramGrid diagrams={this.props.diagrams} modalRef={this.diagramViewModal}></DiagramGrid>
        <DiagramViewModal onRef={(ref) => this.diagramViewModal = ref} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.diagrams.view
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchDiagrams: () => dispatch(fetchDiagramImages())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Diagrams)
