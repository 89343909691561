import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Typography, Tooltip, Table, TableBody, TableRow, Paper, Box, Grid, TableHead, TableCell, Button } from '@material-ui/core';
import BreadTable from "@library/core/tables/BreadTable";
import Quantity from "@library/core/form-elements/Quantity";
import TableVerticalCell from "./SyntheticDataVertical";
import { SubmitButton } from "@library/core/buttons";
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import { addDataToCart, removeDataFromCart, saveSyntheticCartQty, chooseCreditCardType, resetCreditCard } from '../../actions/syntheticDataActions';
import CheckboxItem from "@library/core/form-elements/CheckboxItem";
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash";
import SnackbarAlert from "@library/core/alerts/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  qtyTitle: {
      margin: '0px 0px 5px 0px !important',
      fontSize: '15px',
      fontWeight: '400',
      color: "#455a64"
  }
}))

const CustomiseSyntheticStep = (props) => {
    const classes = useStyles();
    const [cartItems, setCartItems] = useState({});
    const [tableData, setTableData] = useState([]);
    const [dataQuantity, setDataQuantity] = useState(0);
    const [checkboxes, setCheckboxes] = useState({});
    const [alertMsg, setAlertMsg] = useState("");
    const [alertType, setAlertType] = useState("info");
    const [alertLoading, setAlertLoading] = useState(false);
    const [alertAutoHide, setAlertAutoHide] = useState(10000);
    useEffect(() => {
      props.setFirstLoad(false);
      setUpTable(true);
    }, []);

    useEffect(()=>{
      console.log(checkboxes)
      if(checkboxes){
        if(!_.isEmpty(checkboxes)){
          if(!checkboxes['Billing Preferences']['Credit Card']){
            props.resetCreditCard()
          }
        }
      }
    },[checkboxes])

    const setUpTable = async (bool, type) => {
      const datasetType = (props.syntheticDataTypeInfo.dataSetType ? props.syntheticDataTypeInfo.dataSetType.toUpperCase() : props.syntheticDatasetToEdit.typeOfData.toUpperCase());
      if (datasetType === "BASIC") {
        setTableData(props.syntheticBasicOptions);
        initCheckboxes(props.syntheticBasicOptions, bool, type);
      } else if (datasetType === "BUNDLE") {
        setTableData(props.syntheticBundleOptions);
        initCheckboxes(props.syntheticBundleOptions, bool, type);
      }
      setCartItems({...props.syntheticDataCart});
    }

    const initCheckboxes = (td, bool, type) => {
      let cbObj = {};
      td.forEach(v => {
          cbObj[v.type] = {};
          v.details.forEach(d => {
              cbObj[v.type][d.name] = ((!type || v.type === type) ? bool : checkboxes[v.type][d.name]);
          });
      });
      if (!_.isEmpty(props.syntheticDataCart)) {
        setCheckboxes(Object.assign(cbObj, props.syntheticDataCart));
        setDataQuantity(props.syntheticDataCartQty);
      }
      setCheckboxes(cbObj);
    }

    const deselectAllCheckboxes = async (type) => {
      if(type==="Billing Preferences"){
       props.resetCreditCard()
      }
      await onRemoveFromCart(type);
      setUpTable(false, type);
    }

    const selectAllCheckboxes = async (type) => {
      await onRemoveFromCart(type);
      setUpTable(true, type);
    }

    const onChangeQty = (qty) => {
      setDataQuantity(qty);
      props.saveSyntheticCartQty(qty);
    }
    
    const onAddToCart = async (id) => {
      const creditCardStatus=checkboxes['Billing Preferences']['Credit Card'];
      if(id==="Billing Preferences" && creditCardStatus && !props.creditCardBrand){
          setAlertMsg("Please select card type available in billing cart")
          setAlertType("warning")
          setAlertLoading(false)
          setAlertAutoHide(10000);
          return;
      }
      if (id != null) {
        setCartItems({
          ...cartItems,
          [id]: checkboxes[id],
        });
      }
      props.addDataToCart(id, checkboxes[id] ? checkboxes[id] : cartItems[id]);
    }
    
    const onRemoveFromCart = async (id) => {
        if (cartItems[id]) {
          let newCart = _.filter(cartItems, (item) => item.key === id);
          setCartItems({
            ...newCart
          });
        }
        if (props.syntheticDataCart && props.syntheticDataCart[id]) {
          props.removeDataFromCart(id);
        }
    }

    let args = {
        expandTitle: "Details",
        size: "medium",
        isExpandable: true,
        columnMetadata: [
          {
            order: 1,
            title: "Synthetic Data Type",
            field: ["type"],
            expandable: false,
            component: (field) => (
              textArgComp(field)
            ),
          },
          {
            order: 2,
            title: "Description",
            field: ["type"],
            expandable: false,
            component: (field) => {
              let descriptionValue;
              if(field==="Customer"){
                descriptionValue="Generates Customer details in valid format. Expand to modify your selection."
              }else if(field==="Contact"){
                descriptionValue="Generates contact details in valid format. Expand to modify your selection.";
              }else if(field==="Identity"){
                descriptionValue="Generates Identity numbers in valid format. Expand to modify your selection.";
              }else if(field==="Billing Preferences"){
                descriptionValue="Generates Credit card, ACN, ARBN and ABN numbers in valid format. Expand to modify your selection";
              }
              return textArgComp(descriptionValue)
            },
            className:"syntheticData"
          },
          {
            order: 3,
            title: "",
            field: ["type"],
            expandable: false,
            component: (field) => (
              cartBtns(field)
            )
          },
          {
            order: 4,
            title: "",
            field: ["details","type"],
            expandable: true,
            hidden: true,
            component: (field,type) => (
              detailsComp(field, type)
            )
          }
        ]
    }

    const handleCheckboxGroupChange = (status, name, type) => {
      let temp = {...checkboxes};
      temp[type][name] = status;
      setCheckboxes(temp);
    }

    const textArgComp = (field, variant="body2") => {
      return (
        <Typography style={{ "margin": "auto" }} noWrap variant={variant}>
          {field}
        </Typography>
      )
    }

    const changeCreditCard=(e)=>{
      handleCheckboxGroupChange(true,"Credit Card","Billing Preferences");
      props.chooseCreditCardType(e.target.value)
    }

    const detailsComp = (field, type) => {
      const allTrue = Object.values(checkboxes[type]).every(item => item === true);
      return (
        <>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell style={{"textAlign":"center","minWidth":"270px"}}>
                <Tooltip title={"Clear all data inclusions \n This will remove item from Cart."} style={{"paddingRight": "5px"}}>
                  <span>
                    <Button 
                      variant="outlined" 
                      color="secondary"  
                      onClick={()=>deselectAllCheckboxes(type)}
                    >
                      Clear All
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title={"Select all data inclusions  \n This will remove item from Cart."}>
                  <span>
                    <Button 
                      variant="outlined" 
                      color="primary"  
                      onClick={()=>selectAllCheckboxes(type)}
                      disabled={allTrue}
                    >
                      Select All
                    </Button>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              field.map(item => {
                  return (
                    <TableRow key={item.name}>
                        <TableVerticalCell
                          style={{"textAlign":"center"}}
                          header={item.name} 
                          body={[item.description, {align: "center", value: valueCheckbox(item.name, type)}]}
                          bodyCellValueAlignment={"left"}
                          typeAdded={item.typeAdded ? true : false}
                          cardTypes={props.creditCardTypes}
                          creditCardBrand={props.creditCardBrand}
                          changeCreditCard={changeCreditCard}
                          checkboxes={checkboxes}
                          cartItems={cartItems}
                        />
                    </TableRow>
                  )
              })
            }
          </TableBody>
        </Table>
        </>
      )
    }

    const valueCheckbox = (name, type) => {
      return (
        <CheckboxItem 
          key={name}
          checkboxLabel={"Include: "}
          labelPlacement="start"
          checked={checkboxes[type][name]}
          checkboxValue={name}
          checkboxChangeCallback={(checkStatus) => (
            handleCheckboxGroupChange(checkStatus, name, type)
          )}
          disabled={ (props.syntheticDataCart && props.syntheticDataCart[type]) ? true : false}
        />
      )
    }

    const cartBtns = (field) => {
      const isT = Object.values(checkboxes[field]).includes(true);
      return (
        <div style={{ 'minWidth': '150px' }}>
          <Tooltip title={!cartItems[field] ? "Qty Required" : "Add to Cart"}>
            <span>
              <SubmitButton style={{ "width": "45%", "marginRight": "10px" }}
                disabled={(props.syntheticDataCart && props.syntheticDataCart[field] || !isT) ? true : false}
                onSubmit={props.syntheticDataCart && !props.syntheticDataCart[field] ? () => onAddToCart(field) : ()=>{}}
              >
                <AddShoppingCartOutlinedIcon />
              </SubmitButton>
            </span>
          </Tooltip>
          <Tooltip title={"Remove from Cart"}>
            <span>
              <SubmitButton style={{ "width": "45%" }}
                disabled={(props.syntheticDataCart && !props.syntheticDataCart[field]) ? true : false}
                onSubmit={() => onRemoveFromCart(field)}
                color={'secondary'}
              >
                <RemoveShoppingCartOutlinedIcon />
              </SubmitButton>
            </span>
          </Tooltip>
        </div>
      )
    }

    return (
      <>
        <BreadTable
          {...args}
          data={tableData}
        />
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Box p={3} pb={2} mb={3} width="280px" height="75px" textAlign="center" alignSelf={"right"} component={Paper}>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={4}>
                  <Grid container direction="row">
                    <Typography gutterBottom variant="h6" className={classes.qtyTitle}>
                      {"Quantity:"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Quantity 
                    qty={dataQuantity}
                    onChangeQty={onChangeQty}
                    maxQty={50}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <SnackbarAlert
              isOpen={alertMsg}
              severity={alertType}
              message={alertMsg}
              position={{ horizontal: 'center', vertical: 'bottom' }}
              autoCloseDuration={alertAutoHide}
              loading={alertLoading}
              handleClose={() => {
                setAlertMsg('');
              }}
            />  
        </Grid>
      </>
    )
}
const mapStateToProps = (state) => {
  return {
    userName: state.auth.userName,
    environment: state.auth.selectedEnvValue,
    syntheticDataTypeInfo: state.syntheticData.syntheticDataTypeInfo,
    syntheticDataCart: state.syntheticData.syntheticDataCart,
    syntheticBasicOptions: state.syntheticData.syntheticBasicOptions,
    syntheticBundleOptions: state.syntheticData.syntheticBundleOptions,
    syntheticDataCartQty: state.syntheticData.syntheticDataCartQty,
    syntheticDatasetToEdit: state.syntheticData.syntheticDatasetToEdit,
    creditCardTypes:state.syntheticData.creditCardTypes,
    creditCardBrand:state.syntheticData.creditCardType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addDataToCart: (key, value) => dispatch(addDataToCart(key, value)),
    removeDataFromCart: (key) => dispatch(removeDataFromCart(key)),
    saveSyntheticCartQty: (qty) => dispatch(saveSyntheticCartQty(qty)),
    chooseCreditCardType:(cardType)=>dispatch(chooseCreditCardType(cardType)),
    resetCreditCard:()=>dispatch(resetCreditCard())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomiseSyntheticStep);
