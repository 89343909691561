import React, { Component } from "react";
import { SubHeader } from "../../common/custom/SubHeader";
import { connect } from "react-redux";
import * as fnnActions from "../../../actions/dataCleanUpActions";
import { setTitle } from "../../../utils/browser";
import TilesFrame from "../../Data/accordion-frames/replicator/DataTilesFrame";
import SnrmResourceView from "./snrm/SnrmUpdateView";
import UnmsView from "./unms/UnmsView";
import "./dataCleanUp.css";
import { reset, change } from "redux-form";
import * as constants from "./util/constants";

class DataCleanUp extends Component {
  state = {
    changeStatus: false
  };

  componentDidMount() {
    setTitle("Data clean up");
    this.props.resetDataCleanUpFIlter();
  }
  
  selectCleanUpType = obj => {
    this.props.selectFilter("unmsRscType", null);
    this.props.selectFilter("snrmResourceType", null)
    this.props.selectFilter("resourceType", null);
    this.props.selectFilter("snrmStatus", null);
    this.props.selectFilter("snrmIMSIStatus", null);
    this.props.selectFilter("snrmSIMStatus", null);
    this.props.selectFilter("view", null);    
    this.resetViewFromFrame("cleanUpType")
    this.props.selectFilter("cleanUpType", obj.label)
  }

  selectActionType = obj => {
    this.props.selectFilter("snrmResourceType", null);
    this.props.selectFilter("snrmStatus", null);
    this.props.selectFilter("snrmIMSIStatus", null);
    this.props.selectFilter("snrmSIMStatus", null);
    this.props.resetSnrmResources();
    this.resetViewFromFrame("view")
    this.props.selectFilter("view", obj.label);
  };

  selectUnmsResourceType = obj => {
    this.props.selectFilter("view", null);
    this.resetViewFromFrame("unmsRscType")
    this.props.selectFilter("unmsRscType", obj.label)
    this.selectActionType({label:"Update"})
  };

  selectUpdateType = obj => {
    this.props.selectFilter("update", obj.label);
  };

  selectSnrmUpdateType = obj => {
    this.props.selectFilter("snrmStatus", obj.label);
  }

  selectSnrmIMSIUpdateType = obj => {
    this.props.selectFilter("snrmIMSIStatus", obj.label);
  }

  selectSnrmSIMUpdateType = obj => {
    this.props.selectFilter("snrmSIMStatus", obj.label)
  }

  selectResourceType = (obj) => {
    this.resetViewFromFrame("resourceType")
    this.props.selectFilter("resourceType", obj.label)
  }

  selectSnrmResourceType = (obj) => {  
    this.props.selectFilter("snrmStatus", null);
    this.props.selectFilter("snrmIMSIStatus", null);
    this.props.selectFilter("snrmSIMStatus", null)
    this.resetViewFromFrame("snrmResourceType")
    this.props.selectFilter("snrmResourceType", obj.label)
  }

  resetUpdateTypes = () => {
    this.props.selectFilter("update", null);
    this.props.selectFilter("updateSim", null);
    this.props.selectFilter("updateImsi", null);
  }

  resetViewFromFrame = (startingFilter) => {
    switch (startingFilter) {
      case "cleanUpType":
        this.selectActionType({ label: null });
        break;
      case "unmsRscType":
        this.selectActionType({ label: null });
        break;
      case "snrmResourceType":
        this.props.resetForm(constants.SNRM_UPDATE_FORM);
        this.selectResourceType({ label: null });
        break;
      case "view":
        this.props.resetResources();
        this.props.resetSnrmResources();
        break;
      case "resourceType":
        this.resetUpdateTypes();
        this.props.resetForm(constants.UNMS_UPDATE_FORM);
        this.props.resetForm(constants.UNMS_INSERT_FORM);
        break;
    }
  }
  isOptionSelected = ({ teamId }) => {
    return teamId == this.props.auth.team;
  }
  teamsOptions = (team) => {
    if (!team) return null;
    return { value: team.teamId, label: team.teamName, isSelected: this.isOptionSelected(team) };
  }

  render() {
    const { filters, selectedFilters, auth, history } = this.props;
    const { selectedEnvValue } = auth;
    console.log("selectedEnvValue----------")
    console.log(selectedEnvValue)
    let { cleanUpTypes, unmsResourceType,resourceTypeFilters, resourceTypeFiltersInINT2, resourceTypeFiltersAllEnv } = filters;
    const { view, cleanUpType, unmsRscType, snrmResourceType } = selectedFilters;
    const teams = this.props.listOfTeams.map(teams => this.teamsOptions(teams));
    const activeTeam = teams.filter(option => option.isSelected);
    // const resourceFilters = resourceTypeFilters.map(({ label }, index) => ({
    //   label,
    //   icon: `icon-mobile${
    //     index === 1 ? "-messaging" : index == 2 ? "-device-mgmt" : ""
    //     }`
    // }));

    return (
      <div className="white">
        <SubHeader
          text={`NUMBERS-MGR`}
          iconClass="td-icon-md icon-managed-phone mainIcon position-static"
          iconColor="magenta"
          loading={false}
          loadingText="Loading..."
          featured
          h1
        />

        {/* <TilesFrame
          heading={"Clean up type "}
          color="orange"
          data={cleanUpTypes}
          select={this.selectCleanUpType}
          selected={cleanUpType}
          redirectUtility={true}
          push={history?.push}
          backBtnColor={"#c31b96"}
        />

        {cleanUpType === "SNRM" &&
          <React.Fragment>
              <TilesFrame
                 heading={"Resource Type"}
                color="green"
                data={selectedEnvValue==="INT2" ? resourceTypeFiltersInINT2 : resourceTypeFiltersAllEnv}
                select={this.selectSnrmResourceType}
                selected={snrmResourceType}
              />
            {snrmResourceType && (
              <SnrmResourceView
                view={view}
                selectSnrmUpdateType={this.selectSnrmUpdateType}
                snrmResourceType={snrmResourceType}     
                selectSnrmIMSIUpdateType={this.selectSnrmIMSIUpdateType}         
                selectSnrmSIMUpdateType={this.selectSnrmSIMUpdateType}  
              />
            )}
          </React.Fragment>
        } */}

      {/* {cleanUpType === "NUMBERS-MGR" && */}
          <React.Fragment>
            <TilesFrame
              heading={"Resource type "}
              color="green"
              data={unmsResourceType}
              select={this.selectUnmsResourceType}
              selected={unmsRscType}
              redirectUtility={true}
              push={history?.push}
              backBtnColor={"#c31b96"}
            />
            {view && <UnmsView
              view={view}
              selectUpdateType={this.selectUpdateType}
              selectResourceType={this.selectResourceType}
            />
            }
          </React.Fragment>
        {/* } */}


      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.cleanup.filters,
    selectedFilters: state.cleanup.selectedFilters,
    listOfTeams : state.auth.myAvailableTeams,
    auth: state.auth,
    resourceTypeFiltersInINT2:state.resourceTypeFiltersInINT2,
    resourceTypeFiltersAllEnv:state.resourceTypeFiltersAllEnv
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectFilter: (key, value) => dispatch(fnnActions.selectDataCleanUpFilter(key, value)),
    resetDataCleanUpFIlter: () => dispatch(fnnActions.resetDataCleanUpFIlter()),
    resetForm: (formName) => dispatch(reset(formName)),
    resetResources: () => dispatch(fnnActions.resetResources()),
    resetSnrmResources: () => dispatch(fnnActions.resetSnrmResources()),
    changefield: (value) => dispatch(change(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataCleanUp);
