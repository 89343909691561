import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import SwitchSelector from "react-switch-selector";
import { Switch,FormControlLabel } from '@material-ui/core';
import './myTeams.css'
// common imports
import HeaderContent from '../common/Header/HeaderContent';
import TableContentLoader from "../common/loader/TableContentLoader";
import { UserSwitch } from "../common/custom/ToggleSwitch";
//specific imports
import FailedView from "./FailedView";
import AllocateTeam from "./AllocateTeam";
import MyTeamTable from "./MyTeamtable";
import UsersToTeam from "../teams/UsersToTeam";
// actions and reducers
import { setLocation } from "../../actions/userFeedbackActions";
import { loadTeamFilterDetails, selectFilter, loadUserFilterDetails,
checkoutServiceFilters, allocateServiceFilters} from "../../actions/standardDataMyTeamsActions";
import { selectCustomTeamFilter, loadCustomUserFilterDetails,loadCustomTeamFilterDetails } from "../../actions/myTeamsActions";
import { setSideBarPanel } from '../../actions/authActions';
import { ToggleButton, styled } from "@mui/material";
import { transform } from "lodash";

const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: "black",
  backgroundColor: "white !important",
  borderRadius: 7,
  borderColor: "rgb(19, 161, 41) !important",
  fontSize: 16,
  height: "36px",
  fontFamily: "TelstraAkkuratWeb-Regular, sans-serif !important",
  "&.Mui-selected": {
    color: "#ffff",
    backgroundColor: "rgb(19, 161, 41) !important",
    "&:hover": {
      color: "#ffff",
      backgroundColor: "rgb(19, 161, 41) !important",
      }
  },
  "&:hover": {
    color: "#ffff",
    backgroundColor: "rgb(19, 161, 41) !important",
  }
}));

export const ToggleButtonLabel = {
  STANDARD: 'Standard',
  CUSTOM: 'Custom'
}
const toggleButtonOptions = [
  {
    label: ToggleButtonLabel.STANDARD,
    value: ToggleButtonLabel.STANDARD,
    selectedBackgroundColor: "white",
    selectedFontColor: "black"
  },
  {
    label: ToggleButtonLabel.CUSTOM,
    value: ToggleButtonLabel.CUSTOM,
    selectedBackgroundColor: "white",
    selectedFontColor: "black"
  }
];

class StandardDataBankMyTeam extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedToggleButton: ToggleButtonLabel.STANDARD ,
      isUserToggle:false,
      allocateLoading:false,
      showAllTeamsData:false,
      allocationDetail:{}
    }
  }

  componentDidMount = () => {
    this.loadTeamFilterDetails();
    this.props.setLocation("MyTeams")
  }

  componentWillUnmount() {
    // reset filters
    this.props.selectFilter({});
    this.props.selectCustomTeamFilter({});
  }

  componentDidUpdate(prevProps, prevState) {
      if(!_.isEqual(prevProps.selectedEnvValue, this.props.selectedEnvValue) || !_.isEqual(prevProps.loggedInUser.team, this.props.loggedInUser.team))
      {
        if (this.state.selectedToggleButton === ToggleButtonLabel.STANDARD)
        {
          this.loadTeamFilterDetails();
          this.props.selectFilter({});
        }
        else if(this.state.selectedToggleButton === ToggleButtonLabel.CUSTOM)
        {
          this.loadCustomTeamFilterDetails();
          this.props.selectCustomTeamFilter({});
        }
        this.setState({isUserToggle:false})
      }

  }

  loadUserFilterDetails = () =>{
    const {loggedInUser, currentTeamId } = this.props;
    this.props.loadUserFilterDetails({loggedInUser, currentTeamId, userList: this.props.userData?.content});
  }

  loadCustomUserFilterDetails = () =>{
    this.props.loadCustomUserFilterDetails(this.props.userData?.content);
  }

  loadTeamFilterDetails=()=>{
    const { scopes, email, isAdmin, loggedInUser, myTeamOptions } = this.props;
    this.props.loadTeamFilterDetails({ scopes, email, isAdmin, loggedInUser, myTeamOptions }, this.state.showAllTeamsData);
  }

  loadCustomTeamFilterDetails=()=>{
    const { scopes, email, isAdmin, loggedInUser, allTeams } = this.props;
    this.props.loadCustomTeamFilterDetails({ scopes, email, isAdmin, loggedInUser, allTeams}, this.state.showAllTeamsData);
  }

  handleUsersToTeamModal = () => {
    this.usersToTeamModalRef.open();
  }

  handleThresholdToTeamServiceIdModal=() =>{
    this.props.history.push(`/tdm/threshold/summary`);
  }

tileStyle = {
    background: 'linear-gradient(to right, #13a129 0, #1d5f27 100%)'
}


 thresholdModal = () => (
    <LinkTile
        className="bg-green"
        data-size="medium"
        to="/tdm/threshold/summary"
        data-effect="hover-zoom-up">
    </LinkTile>
)

  handleAllocateToTeamModal = () => {
    this.allocateModalRef.open();
  }

  onChangeToggleButton = newValue => {
    if(newValue===ToggleButtonLabel.STANDARD){
      this.state.isUserToggle ? this.loadUserFilterDetails() : this.loadTeamFilterDetails();
    }
    else {
      this.state.isUserToggle ? this.loadCustomUserFilterDetails(): this.loadCustomTeamFilterDetails();
    }
    this.setState({selectedToggleButton: newValue});
  };
  
  renderSwitchToggle = () => {
    return (
      <span style={{ width: 180, height: 38, marginRight: 50 }}>
      <SwitchSelector
        onChange={this.onChangeToggleButton}
        options={toggleButtonOptions}
        backgroundColor={"rgb(19, 161, 41) !important"}
        fontColor={"white"}
        optionBorderRadius= {9}
        wrapperBorderRadius={7}
        fontSize={16}
      />
      </span>
    )
}

renderShowAllTeamDataToggle  = () => {
  return (
    <span style={{ width: 180, height: 38, marginRight: -30 }}>
    <CustomToggleButton style={{textTransform: 'none'}}
      selected={this.state.showAllTeamsData}
      onChange={this.handleShowAllTeamsToggle}
      optionBorderRadius= {9}
      wrapperBorderRadius={7}
    >
    Admin View
  </CustomToggleButton>
    </span>
  )
}

  handleShowAllTeamsToggle=() =>{
    const isChecked = !this.state.showAllTeamsData;
    this.setState({showAllTeamsData: isChecked},()=>{
      let selectedToggle = this.state.selectedToggleButton;
      if(selectedToggle === ToggleButtonLabel.STANDARD){
        this.loadTeamFilterDetails();  
      }else if(selectedToggle === ToggleButtonLabel.CUSTOM){
        this.loadCustomTeamFilterDetails();
      }
    });
  }

  handleContentLoader = (filterPending) => {
      return filterPending ? <TableContentLoader /> : null;
  }

  handleNoTeamData = (filterFailed, filterPending, filter) => {
    return !filterFailed && !filterPending && filter.length === 0 ? "No Team Data" : null;
  }

  handleFailedViewError = (filterFailed, errorMessage) => {
    return filterFailed ? <FailedView message={errorMessage || "Could not load Team Details"} /> : null;
  }

  loadMyTeamTable = (filterPending, filter ) => {
    const {history} = this.props;
    return (!filterPending && filter.length > 0 &&
      <section id="team-table-area">
      <MyTeamTable history={history}
      loadTeamFilterDetails = {this.state.isUserToggle? this.loadUserFilterDetails: this.loadTeamFilterDetails}
      loadCustomTeamFilterDetails = {this.state.isUserToggle? this.loadCustomUserFilterDetails: this.loadCustomTeamFilterDetails}
      selectedToggleButton={this.state.selectedToggleButton}
      /></section>
        )
  }
  renderAllMethods = () => {
    const {fetchFilterPending, customFilterPending, fetchFilterFailed, filters, customFilterFailed,
       customFilters, customFilerErrorMessage, failedErrorMessage } = this.props;

    if (this.state.selectedToggleButton === ToggleButtonLabel.STANDARD) {
       return(
        <Fragment>
        {this.handleContentLoader(fetchFilterPending)}
        {this.handleNoTeamData(fetchFilterFailed, fetchFilterPending, filters)}
        {this.loadMyTeamTable(fetchFilterPending, filters)}
        {this.handleFailedViewError(fetchFilterFailed, failedErrorMessage)}
        </Fragment>
        );
    } else if (this.state.selectedToggleButton === ToggleButtonLabel.CUSTOM) {
      return(
        <Fragment>
          {this.handleContentLoader(customFilterPending)}
          {this.handleNoTeamData(customFilterFailed, customFilterPending, customFilters)}
          {this.loadMyTeamTable(customFilterPending, customFilters)}
          {this.handleFailedViewError(customFilterFailed, customFilerErrorMessage)}
      </Fragment>
      )
    }
  }

  render() {
    const { filters, fetchFilterPending ,isAdmin,isAuthenticated, currentTeamId, selectedEnvValue, myTeamOptions, roles,setSideBarPanel,openSidePanel} = this.props;
    return (
      <div className="white" id="team-view-page">
        <HeaderContent
          title="My Team"
          icon="users team-icon"
          loading={fetchFilterPending}
          loadingText="Loading your Team data.."
          click={!fetchFilterPending}
          onClick={this.handleUsersToTeamModal}
          // alertModal={this.usersToTeamModalRef}
          extraComponent={this.renderSwitchToggle}
          extraComponentClassName="myteam-switch-selector"
          allocateModal = {this.allocateModalRef}
          onClickAllocate = {this.handleAllocateToTeamModal}
          allocateLoading = {this.state.allocateLoading}
          thresholdModal={this.thresholdModal}
          onClickThreshold={this.handleThresholdToTeamServiceIdModal}
          isAdmin = {isAdmin}
          isAuthenticated={isAuthenticated}
          cssClass= "allocate-btn"
          showEnv={true}
          setSideBarPanel={setSideBarPanel}
          selectedEnvValue = {selectedEnvValue}
          openSidePanel={openSidePanel}
          showAllTeamsDataComponent = {this.renderShowAllTeamDataToggle}
          selectedToggleButton={this.state.selectedToggleButton}
        />
        <UsersToTeam onRef={(ref) => this.usersToTeamModalRef = ref}
        filters={myTeamOptions}
        roles = {roles}
        myTeamOptions = {myTeamOptions}
        isAdmin={isAdmin} currentTeamId={currentTeamId}/>
        <AllocateTeam
            onRef = {ref => {this.allocateModalRef = ref}}
            selectedToggleButton = {this.state.selectedToggleButton}
            myTeamUserToggle = {this.state.isUserToggle}
            selectedEnvValue = {selectedEnvValue}
            loadTeamFilterDetails = {this.loadTeamFilterDetails}
            loadCustomTeamFilterDetails = {this.loadCustomTeamFilterDetails}
            loadUserFilterDetails = {this.loadUserFilterDetails}
            loadCustomUserFilterDetails = {this.loadCustomUserFilterDetails}
            handleServiceFilterCheckout={this.props.allocateServiceFilters}
            />
            {this.renderAllMethods()}
      </div>
    )
  }
}

const mapStateToProps = state => {

  return {
    myTeamOptions: state.auth.myAvailableTeams,
    teamData: state.noSqlTeams.teamData,
    scopes: state.auth.roles,
    email: state.auth.user && state.auth.user.email,
    isAdmin: state.auth.isAdmin,
    isAuthenticated: state.auth.isAuthenticated,
    filters: state.noSqlTeams.teamFilters.filters || [],
    selectedFilters: state.noSqlTeams.selectedFilters,
    fetchFilterPending: state.noSqlTeams.teamFilters.pending,
    fetchFilterFailed: state.noSqlTeams.teamFilters.failed,
    currentTeamId: state.auth.team,
    failedErrorMessage: state.noSqlTeams.teamFilters.errorMessage,
    customFilters: state.teams.customTeamFilters.customFilters || [],
    customFilerErrorMessage: state.teams.customTeamFilters.customFilerErrorMessage,
    customFilterFailed: state.teams.customTeamFilters.customFilterFailed,
    customFilterPending: state.teams.customTeamFilters.customFilterPending,
    selectedEnvValue: state.auth.selectedEnvValue,
    loggedInUser: state.auth,
    openSidePanel:state.auth.openSidePanel,
    userData: state.users.mgmt,
    allTeams: state.teams.teams.allTeams || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadTeamFilterDetails: (request,showAllTeamDetails) => dispatch(loadTeamFilterDetails(request,showAllTeamDetails)),
    selectFilter: (filter) => dispatch(selectFilter(filter)),
    loadCustomTeamFilterDetails: (request,showAllTeamDetails) => dispatch(loadCustomTeamFilterDetails(request,showAllTeamDetails)),
    selectCustomTeamFilter: (filter) => dispatch(selectCustomTeamFilter(filter)),
    loadUserFilterDetails:(details)=> dispatch(loadUserFilterDetails(details)),
    loadCustomUserFilterDetails:(userList)=> dispatch(loadCustomUserFilterDetails(userList)),
    checkoutServiceFilters: (request) => dispatch(checkoutServiceFilters(request)),
    allocateServiceFilters: (request) => dispatch(allocateServiceFilters(request)),
    setLocation:(path) => dispatch(setLocation(path)),
    setSideBarPanel: (value) => dispatch(setSideBarPanel(value)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(StandardDataBankMyTeam);
