import React, {Component} from 'react';
import { Button } from "reactstrap";
import classNames from "classnames";
import config from '../../config';
import { connect } from "react-redux";
import {saveTaasUserid} from "../../actions/ssoAuthActions";
import MuiAlert from "../common/muialerts/alerts";
import {getTokenFromCookie} from "../../utils/browser";
import { isTaasTokenValid } from '../auth/functions';
import jwtDecode from 'jwt-decode';
import tdm_logo from '../../assets/images/tdm_logo.png';

class SSOLogin extends Component {
  state={
    taasErr: false,
    taasAuthLoading: false,     
  }
  async componentDidMount() {
    this.setState({...this.state, taasErr: false, taasAuthLoading: false});
    const existingTaasToken = getTokenFromCookie('TaaSAccessToken');
    const existingUserId = getTokenFromCookie('UserId');

    if ((existingTaasToken && (await isTaasTokenValid(existingTaasToken)).verified) && 
        ((this.props.user && (this.props.user.userName || this.props.user.UserName)) || existingUserId)
    ) {
      this.setState({...this.state, taasErr: false});
      this.props.saveTaasUserid(existingUserId, jwtDecode(existingTaasToken).email);
        this.props.history.push({
          pathname: '/login'
        });
    } else {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params.login === 'complete') {
        const token = getTokenFromCookie('TaaSAccessToken');
        const userId = getTokenFromCookie('UserId');
        if (token === '' || !token || !userId) {
          this.setState({...this.state, taasErr: true});
        } else {
          this.setState({...this.state, taasErr: false});
          this.props.saveTaasUserid(userId);
          this.props.history.push({
            pathname: '/login'
          });
        }
      }
    }
  }
   
    render() {
      const REDIRECT_URL = config.redirectUrl;
      const TAAS_SSO_URL = config.taas_sso_url;
        const telstraLogin = () => {
            console.log ("Redirecting to SSO");
            this.setState({...this.state, taasErr: false, taasAuthLoading: true});
            this.props.history.push({pathname: '/'});
            window.location.href =`${TAAS_SSO_URL}?redirect_uri=${REDIRECT_URL}?login=complete&token_type=RSA`;
          }
          return (
            <div>
              <form
                className="ssoForm-signin text-center white" >

<div class="mb-4" style={{"display": "inlineFlex","justifyContent": "center", "width": "100%","alignItems": "center", "padding": "5px"}}>
                    <img src={tdm_logo} height={140} width={140} style={{marginBottom:"-10px"}}/>  
                </div>
                    <div className="col">
                      {this.state.taasErr && <MuiAlert title="Error" text={"Taas Auth Error - Failed to retrieve Taas token"} />}
                    </div>
                      <Button
                        className={classNames(
                          "btn",
                          "btn-lg",
                          "btn-block",
                          "ld-ext-right",
                          {running: this.state.taasAuthLoading}
                        )}
                        style={{background:"#6bcbfe",color:"#fff"}}
                        color="secondary"
                        disabled={this.state.taasAuthLoading}
                        onClick = {telstraLogin}
                        >
                        {this.state.taasAuthLoading ? `Account-01 Signing in...` : `Account-01 Sign In `}
                        {this.state.taasAuthLoading && <div class="ld ld-ring ld-spin"/>}
                      </Button>
              </form>
              </div>
          )      
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      stats: state.stats,
      user: state.auth.user,
    }
}
const mapDispatchToProps = dispatch => {
  return {
      saveTaasUserid : (id,email) => dispatch(saveTaasUserid(id, email))
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(SSOLogin);