 import React,{ useState, useEffect } from 'react'
import { connect} from "react-redux";
import { Button, Typography } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BreadTable from "@library/core/tables/BreadTable";
import Header from "@library/core/surfaces/Header";
import { tableRowFuzzySearch } from "@library/core/filters/filter";
import { fetchThresholdDetails, deleteThresholdDetails } from '../../../actions/serviceIdThresholdActions';
import { fetchAllActiveServiceIds } from '../../../actions/standardDataMyTeamsActions';
import swal from 'sweetalert2';
import { displayLoading,displayErrorAlert } from "../../common/alerts/alerts";
import UpdateThresholdModal from './UpdateThresholdModal';
import AddThresholdModal from './AddThresholdModal';

const ThresholdDetailsSummary=(props)=>{
    const [filtered, setFiltered] = useState([]);
    const [unfiltered, setUnFiltered] = useState([]);
    const[openEditThreshold, setOpenEditThreshold] = useState(false);
    const[openThreshold, setOpenThreshold] = useState(false);
    const[oldThresholdData, setOldThresholdData]=useState({});

    useEffect(() => {
       fetchThresholdDetailsMethod();
    }, [])

    const fetchThresholdDetailsMethod=()=>{
      displayLoading({ title: "Fetching Threshold Details", bodyText: "Please standby" });
       props.fetchAllActiveServiceIds().then((response) => {
        let req = {
          "teamIds": fetchTeams(props.myTeamOptions),
          "serviceIds": fetchServiceIds(response),
          "environment":props.selectedEnvValue
      }
      props.fetchThresholdDetails(req).then((response) =>{
        swal.close();
          if(response?.data?.thresholdsData){
              setFiltered(response.data.thresholdsData);
              setUnFiltered(response.data.thresholdsData);
          }else{
            displayErrorAlert({
              title: "Error",
              subTitle: response?.httpStatus,
              errorMsg: response?.message
          });
          }
      });  
       });
    }
    const fetchServiceIds=(serviceIdData)=>{
        return serviceIdData.map(service => service.id);
    }
    const fetchTeams=(teams)=>{
        return teams.map(team => team.teamId);
    }
    const textTableData = (field) => {
        return <Typography noWrap variant="body2">{field}</Typography>;
    }
    const arrayTableData = (field) => {
        return <Typography noWrap variant="body2">{field.join(",")}</Typography>;
    }
    const args = {
        size: "medium",
        isExpandable: false,
        rowsPerPage: 5,
        rowsPerPageOptions: [5, 10, 15, { label: 'All', value: -1 }],
        columnMetadata: [
          {
            order: 1,
            title: "Team",
            field: ["teamName"],
            expandable: false,
            component: textTableData
          },
          {
            order: 2,
            title: "ServiceId",
            field: ["serviceId"],
            expandable: false,
            component: textTableData
          },
          {
            order: 3,
            title: "ThresholdValues",
            field: ["thresholdValues"],
            expandable: false,
            component: arrayTableData
          },
          {
            order: 4,
            title: "Env",
            field: ["environment"],
            expandable: false,
            component: textTableData
          }
        ]
      }
    const searchFilter = (value) => {
        let filtered = [];
        for (const row of unfiltered) {
          if (tableRowFuzzySearch([row.teamId, row.serviceId, row.thresholdValues, row.environment], value)) {
            filtered.push(row);
          }
        }
        setFiltered(filtered);
      }

      const handleEditThresholdData = async (menuItem, data) => {
        setOpenEditThreshold(!openEditThreshold);
        setOldThresholdData({
          "serviceId": data.serviceId,
          "teamId": data.teamId,
          "teamName":data.teamName,
          "thresholdValues": data.thresholdValues,
          "environment": data.environment
      }
      )
      }
      const handleDeleteThresholdData = async (menuItem, data) => {
        displayLoading({ title: "Deleting thresholds", bodyText: "Please standby" });
        let req = {
          "serviceId": data.serviceId,
          "teamId": data.teamId,
          "thresholdValues": data.thresholdValues,
          "environment": data.environment
        }
        props.deleteThresholdDetails(req).then((response) =>{
            swal.close();
            if(response && response.data){
              swal.fire("Success", response.data.message, "success").then(() => {
                fetchThresholdDetailsMethod();
              })  
            }else{
              displayErrorAlert({
                title: "Error",
                subTitle: "Delete threshold values failed",
                errorMsg: "Internal Server Error"
            });
            }
        });

      }
      const handleOpenThresholdDataCreation = async () => {
        setOpenThreshold(true);
      }
  return (
    <React.Fragment>
    <div className="white">
        <Header
            title={`Threshold Details Summary`}
            topRightIcon={<i className={`fa fa-database training-icon fa-3x`}/>}
        />
    <div>
        <BreadTable
         {...args}
         data={filtered}
         searchFilter={searchFilter}
         searchPermOpen
         noDataMsg={"No Threshold Details Available"}
         actionItems={()=> [
          {
            title: "Edit Threshold",
            onClick: (menuItem, data) => {
              handleEditThresholdData(menuItem, data)
            },
            isDisabled: false
          },
          {
            title: "Delete Threshold",
            onClick: (menuItem, data) => {
              handleDeleteThresholdData(menuItem, data)
            },
            isDisabled: false
          }
        ]}
        titleBtnArea={
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenThresholdDataCreation}
            startIcon={<AddCircleIcon />}
          >
            Add Threshold Values
          </Button>
        }
        />
       {openThreshold && (
          <AddThresholdModal
            history={history}
            open = {openThreshold}
            selectedToggleButton = {props.selectedToggleButton}
            selectedEnvValue = {props.selectedEnvValue}
            currentTeamId={props.currentTeamId}
            fetchThresholdDetailsMethod={fetchThresholdDetailsMethod}
            setOpenThreshold={setOpenThreshold}
            />
       )}
    </div>
    <div>
        {openEditThreshold && (
           <UpdateThresholdModal
           history={history}
           selectedToggleButton = {props.selectedToggleButton}
           myTeamUserToggle = {props.isUserToggle}
           selectedEnvValue = {props.selectedEnvValue}
           currentTeamId={props.currentTeamId}
           oldThresholdData={oldThresholdData}
           editModal={openEditThreshold}
           fetchThresholdDetailsMethod={fetchThresholdDetailsMethod}
           setOpenThreshold={setOpenThreshold}
           />
        )
        }
    </div>
    </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
    return {
        thresholdDetailsSummary: state.serviceIdThresholdData,
        myTeamOptions: state.auth.myAvailableTeams,
        allTeams: state.teams.teams.allTeams || [],
        allActiveServiceIds: state.noSqlTeams.serviceIdData.allServiceIds || [],
        selectedEnvValue: state.auth.selectedEnvValue,
        loggedInUser: state.auth.user
    
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchThresholdDetails: (req) => dispatch(fetchThresholdDetails(req)),
        fetchAllActiveServiceIds: () => dispatch(fetchAllActiveServiceIds()),
        deleteThresholdDetails:(req) => dispatch(deleteThresholdDetails(req)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ThresholdDetailsSummary);