import React, { Fragment } from 'react';
import { Table } from 'reactstrap';

const SimRow = ({
    object,
}) => {
    return (
        <Fragment>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>SIM</th>
                        <th>IMSI</th>
                        <th>EKI</th>
                        <th>PUK</th>
                        <th>KIND</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        <tr>
                            <td>{object.sim}</td>
                            <td>{object.imsi}</td>
                            <td>{object.eki}</td>
                            <td>{object.puk}</td>
                            <td>{object.kind}</td>
                        </tr>
                    }
                </tbody>
            </Table>
            <Table  hover responsive>
                <thead>
                    <tr>
                        <th>A4IND</th>
                        <th>FSETIND</th>
                        <th>PIN</th>
                        <th>SIM ORDER TYPE</th>
                        <th>PROFILE ID</th> 
                        <th>SIM ARTWORK</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        <tr>
                            <td>{object.a4ind}</td>
                            <td>{object.fsetind}</td>
                            <td>{object.pin}</td>
                            <td>{object['sim order type']}</td>
                            <td>{object['profile id']}</td>
                            <td>{object['sim artwork']}</td>
                        </tr>
                    }
                </tbody>
            </Table>
        </Fragment>
    )
}

export default SimRow;