import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    userMetaData: {
        trainingBadge: ""
    },
    userMetaDataLoading: false,
    userMetaDataFailed: false,
    userMetaDataError: "",
    userTaasMetaData: {
        data: null,
        fetchLoading: false,
        fetchSuccess: false,
        fetchFailed: false,
        fetchFailTime: null,
        fetchError: "",
        updateLoading: false,
        updateFailed: false,
        updateFailTime: null,
        updateError: ""
    }
}

export default(state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOAD_USERS_SUCCESS:
            return {
                ...state,
                mgmt: {
                    ...state.mgmt,
                    content: action.payload,
                    loading: false,
                    failed: false,
                    failedTime: null
                }
            }
        case types.LOAD_USERS_LOADING:
            return {
                ...state,
                mgmt: {
                    ...state.mgmt,
                    loading: action.loading,
                    failed: false,
                    failedTime: null
                }
            }
        case types.LOAD_USERS_FAILED:
            return {
                ...state,
                mgmt: {
                    ...state.mgmt,
                    loading: false,
                    failed: true,
                    failedTime: Date.now()
                }
            }
        case types.LOAD_USER_PROFILE_LOADING:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    loading: action.loading
                }
            } 
        case types.LOAD_USER_PROFILE_SUCCESS:
            return {
                ...state,
                profile: {
                    ...action.payload,
                    loading: false
                }
            }
            case types.LOAD_USERS_IN_TEAM_LOADING:
                return {
                    ...state,
                    users: {
                        ...state.users,
                        loading: action.loading
                    }
                }
            case types.LOAD_USERS_IN_TEAM_SUCCESS:
                return {
                    ...state,
                    users: {
                        ...action.payload,
                        loading: false
                    }  
                }
            case types.REMOVE_USERS_FROM_TEAM_LOADING:
                return {
                    ...state,
                    removeLoading: action.loading
                }
            case types.REMOVE_USERS_FROM_TEAM_SUCCESS:
                return {
                    ...state,
                    removeLoading: false
                }
            case types.ADD_USERS_TO_TEAM_LOADING:
                return {
                    ...state,
                    loading: action.loading
                }
            case types.ADD_USERS_TO_TEAM_SUCCESS:
                return {
                    ...state,
                    loading: false
                }                  
            case types.FETCH_USER_META_DATA_SUCCESS:
                return {
                    ...state,
                    userMetaData: action.payload,
                    userMetaDataLoading: false,
                    userMetaDataFailed: false,
                    userMetaDataFailedTime: null,
                    userMetaDataError: ""
                }
            case types.FETCH_USER_META_DATA_FAILED:
                return {
                    ...state,
                    userMetaData: {
                        trainingBadge: ""
                    },
                    userMetaDataLoading: false,
                    userMetaDataFailed: true,
                    userMetaDataFailedTime: Date.now(),
                    userMetaDataError: action.payload
                }
            case types.FETCH_USER_META_DATA_LOADING:
                return {
                    ...state,
                    userMetaData: {
                        trainingBadge: ""
                    },
                    userMetaDataLoading: true,
                    userMetaDataFailed: false,
                    userMetaDataFailedTime: null,
                    userMetaDataError: ""
                }
            case types.FETCH_USER_TAAS_META_DATA_SUCCESS:
                return {
                    ...state,
                    userTaasMetaData: {
                        ...state.userTaasMetaData,
                        data: action.payload,
                        fetchLoading: false,
                        fetchSuccess: true,
                        fetchFailed: false,
                        fetchFailTime: null,
                        fetchError: ""
                    }
                }
            case types.FETCH_USER_TAAS_META_DATA_FAILED:
                return {
                    ...state,
                    userTaasMetaData: {
                        ...state.userTaasMetaData,
                        data: null,
                        fetchLoading: false,
                        fetchSuccess: false,
                        fetchFailed: true,
                        fetchFailTime: Date.now(),
                        fetchError: action.payload
                    }
                }
            case types.FETCH_USER_TAAS_META_DATA_LOADING:
                return {
                    ...state,
                    userTaasMetaData: {
                        ...state.userTaasMetaData,
                        fetchLoading: true,
                        fetchSuccess: false,
                        fetchFailed: false,
                        fetchFailTime: null,
                        fetchError: ""
                    }
                }
            case types.UPDATE_USER_TAAS_META_DATA_SUCCESS:
                return {
                    ...state,
                    userTaasMetaData: {
                        ...state.userTaasMetaData,
                        data: action.payload,
                        updateLoading: false,
                        updateFailed: false,
                        updateFailTime: null,
                        updateError: ""
                    }
                }
            case types.UPDATE_USER_TAAS_META_DATA_FAILED:
                return {
                    ...state,
                    userTaasMetaData: {
                        ...state.userTaasMetaData,
                        updateLoading: false,
                        updateFailed: true,
                        updateFailTime: Date.now(),
                        updateError: action.payload
                    }
                }
            case types.UPDATE_USER_TAAS_META_DATA_LOADING:
                return {
                    ...state,
                    userTaasMetaData: {
                        ...state.userTaasMetaData,
                        updateLoading: true,
                        updateFailed: false,
                        updateFailTime: null,
                        updateError: ""
                    }
                }
        default:
            return state;
    }
}