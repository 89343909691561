import {axiosInstance as axios} from "../axios.config";
import * as types from './actionTypes';
import config from '../config';
import { authHeaders } from '../utils/api';

const API_URL_GATEWAY = config.tdm_gateway_api.uri;

export const salesforceReset = () => async dispatch => {
    dispatch({ type: types.SALESFORCE_RESET });
}

export const salesforceSearch = (payload, prevContent,reset, tab, formatFunc, next) => async (dispatch,getState) => {
    let salesforceSearchUrl;
    if(reset){
        dispatch({ type: types.RESET_NEXT_RECORDS_URL });
    }
    const {salesforceSearch}=getState();
    const {nextRecordsUrls}=salesforceSearch;
    const nextR = nextRecordsUrls?.[tab];
    next && (payload["nextRecordsUrl"]=nextR);
    try {
        dispatch({ type: types.SALESFORCE_SEARCH_PENDING });
        let response = await axios.post(`${API_URL_GATEWAY}/tdm/salesforce/b2c/customer/search`, payload, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            const formatted = await formatFunc(response.data);
            let content = prevContent ? [...prevContent.data, ...formatted] : formatted;
            response.data.records = content;
            salesforceSearchUrl=response.data.nextRecordsUrl;
            dispatch({ type: types.SALESFORCE_SEARCH_SUCCESS, payload: {data: {data: response.data.records, totalSize: response.data?.totalSize}, name: tab} });
            dispatch({ type: types.UPDATE_NEXT_RECORDS_URL, payload: {salesforceSearchUrl: salesforceSearchUrl, name: tab} });
            return response.data;
        }
        else if (response.status !== 200) {
            dispatch({ type: types.SALESFORCE_SEARCH_FAILED, payload: {msg: "Failed to complete salesforce search", clear: Boolean(!prevContent), name: tab} });
            return false;
        }
    } catch (error) {
        dispatch({ type: types.SALESFORCE_SEARCH_FAILED, payload: {msg: `Error while searching salesforce  - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}`, clear: Boolean(!prevContent), name: tab} });
        return false;
    }
}

export const resetNextRecordsUrl= () => async (dispatch) => {
    dispatch({ type: types.RESET_NEXT_RECORDS_URL });
}

export const resetLov= (field, entity) => async (dispatch) => {
    dispatch({ type: types.SALESFORCE_LOV_RESET, payload: {field: field, entity: entity} });
}

export const fetchLovs = (field, fieldName, env, entity, condition) => async dispatch => {
    try {
        dispatch({ type: types.SALESFORCE_FETCH_LOV_PENDING, payload: {field, entity} });
        const response = await axios.get(`${API_URL_GATEWAY}/tdm/salesforce/b2c/customer/lov?field=${field}&env=${env}&entity=${entity}${condition ? "&conditions="+condition : ""}`, { headers: authHeaders()});
        if (response.status === 200) {
            dispatch({ type: types.SALESFORCE_FETCH_LOV_SUCCESS, payload: {field: field, entity: entity, response: response.data.loes} });
            return response?.data?.loes ? [...(response.data.loes)] : [];
        }
        else if (response.status !== 200) {
            dispatch({ type: types.SALESFORCE_FETCH_LOV_FAILED, payload: {field: field, entity: entity, msg: `Failed to fetch salesforce ${fieldName} options`} });
            return false;
        }
    } catch (error) {
        dispatch({ type: types.SALESFORCE_FETCH_LOV_FAILED, payload: {field: field, entity: entity, msg: `Error while fetching ${fieldName} options`}});
        return false;
    }
}