import {axiosInstance as axios} from "../axios.config";
import _ from "lodash";
import config from "../config";
import * as types from "./actionTypes";
import { authHeaders } from "../utils/api";

const API_URL = config.tdm_gateway_api.uri + '/tdm';

export const selectCosmosSelectFilter = (key, value) => ({
    type: types.SELECT_COSMOS_SEARCH_FILTER, key, value
  });

export const resetCosmosRecordsForm = () => ({
    type: types.RESET_COSMOS_SEARCH_FILTERS
})

export const setSearchValue = (value) => ({
  type: types.SET_SEARCH_VALUE, value
})


export const fetchCosmosRecords = (type,value) => async dispatch => {
 /*  type = "fnn" */
  dispatch({ type: types.FETCH_COSMOS_RECORDS_PENDING, payload: "loading"});
  try {
    const response = await axios.get(`${API_URL}/replicator/cosmos/${type}?${type}=${value}`,{ headers: authHeaders() })
    if((response.data.services).length > 0)
    dispatch({ type: types.FETCH_COSMOS_RECORDS_SUCCESS, payload: response.data.services, noRecords: false});
    else
    {
        dispatch({ type: types.FETCH_COSMOS_RECORDS_SUCCESS, payload: response.data.services, noRecords: true});
    }
    return true;
  } catch (e) { 
    dispatch({ type: types.FETCH_COSMOS_RECORDS_FAILURE, payload: e});
    console.error("Error while fetching cosmosRecords : fetchCosmosRecords" ,e);
    return false
   }
  };