import React, { Component, Fragment } from 'react'
import { SubHeader } from '../../common/custom/SubHeader';
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field, reset, change } from 'redux-form';
import { checkBatchIdStatus } from '../../../actions/utilityActions';
import { displayLoading, displaySuccess } from "../../common/alerts/alerts";
import BatchStatusForm from './BatchStatusForm';
import BatchResultTable from './BatchResultTable';
import swal from 'sweetalert2';
import { Button } from "@material-ui/core";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

class BatchStatusUtility extends Component {
    state = {
        currentPage: 'form',
        showTable: false,
    };

    showTable = (bool) => {
        this.setState(prevState => ({
            ...prevState,
            showTable: bool,
        }));
    }

    handleSubmit = (batchIdRequest) => {
        displayLoading({ title: "Retrieiving Batch Status", bodyText: "Please standby while request is performed" });
        this.props.checkBatchIdStatus(batchIdRequest).then(() => {
            if (this.props.batchSuccess) {
                swal.close();
                this.showTable(true);
            } else if (this.props.batchFailed) {
                this.showTable(false);
                swal.close();
                swal.fire({
                    type: 'error',
                    title: 'Error',
                    html: `${this.props.batchResponse}`,
                    confirmButtonText: 'OK',
                });
            }
        });
    }

    render() {
        const {history} =this.props;
        return (
            <div className="white"> 
                <SubHeader
                    text={`Batch Status Checker`}
                    iconClass="td-icon-md icon-search-find mainIcon position-static"
                    iconColor="magenta"
                    featured
                    h1 
                />
                <div className="content">
                    <form>
                        { this.state.currentPage === 'form' ?
                            <Fragment>
                                <BatchStatusForm submit={this.handleSubmit} initialBatchIds={
                                    this.props.msisdnSuccess && this.props.simSuccess ? [this.props.msisdnResponse.toString(), this.props.simResponse.toString()] :
                                    this.props.msisdnSuccess ? [this.props.msisdnResponse.toString()] :
                                    this.props.simSuccess ? [this.props.simResponse.toString()] : []
                                }/>
                            </Fragment> : null
                        }
                        { this.state.showTable ?
                            <Fragment>
                                <BatchResultTable status={this.props.batchResponse}/>
                            </Fragment> : null
                        }
                    </form>
                </div>
                <Button variant="contained" color="primary" style={{marginTop:"20px",marginLeft:"30px"}}
        onClick={()=>history.push("/data/utility")}
        startIcon={<ReplyAllIcon />}
        >Back to Utilities</Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        batchPending: state.utility.batchIdStatus.isCheckStatusPending,
        batchFailed: state.utility.batchIdStatus.isCheckStatusFailed,
        batchSuccess: state.utility.batchIdStatus.isCheckStatusSuccess,
        batchResponse: state.utility.batchIdStatus.statusResponse,

        msisdnSuccess: state.utility.physicalSimPreceeding.isSubmitMsisdnSuccess,
        msisdnResponse: state.utility.physicalSimPreceeding.msisdnResponse,
        simSuccess: state.utility.physicalSimPreceeding.isSubmitSimSuccess,
        simResponse: state.utility.physicalSimPreceeding.simResponse,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkBatchIdStatus: (request) => dispatch(checkBatchIdStatus(request)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps),reduxForm({form: "BatchStatusForm"}))(BatchStatusUtility);