import React, { Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Button } from 'reactstrap';
import './rcrStatus.css';


const RcrStatusForm = (props) => {
    const {customerId}=props;
    const [requestId, setRequestId] = React.useState(null);


    const handleSubmit = () => {
        props.submit(requestId);
    }

    return (
        <Fragment>
            <Paper elevation={1} className="FormWrapper">
                <div style={{ "width": "50%" }}>
                    <TextField
                        id="filled-search"
                        label="Add Batch ID"
                        type="search"
                        fullWidth={true}
                        onChange={(e) => setRequestId(e.target.value)}
                    />
                </div>
                <div class="text-right pageBtn" style={{ "width": "15%" }}>
                    <Button disabled={!requestId} class="text-right pageBtn" type="button" color="primary" onClick={handleSubmit}>Check Status</Button>
                </div>
                {customerId ?
                    <div className='createRcrChild' style={{ borderLeft: "6px solid #007bff", marginLeft: "20px" }}>
                        <p style={{ marginLeft: "20px" }}><b>Customer Id</b><br /> {customerId} </p>
                    </div>
                    : null}
               
            </Paper>

        </Fragment>
    );
}

export default RcrStatusForm;
