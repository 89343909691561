import React from "react";
import { Table } from 'reactstrap';
import { submit } from 'redux-form'
import { connect } from "react-redux";
import { FieldArray } from "redux-form";
import ReduxFormContainer from "../../../common/form/redux/ReduxFormContainer";
import PaginationController from "../../../../utils/PaginationController";
import * as fnnActions from "../../../../actions/dataCleanUpActions";
import TDMHeader from "../util/TDMHeading";
import TilesFrame from "../../../Data/accordion-frames/replicator/DataTilesFrame";
import RangeInput from "../util/RangeInput";
import TableContentLoader from "../../../common/loader/TableContentLoader";
import * as unmsConstants from "../util/constants";
import FailedView from "../util/FailedView";

class UnmsUpdateView extends React.Component {

    selectSimUpdateType = obj => {
        this.props.selectFilter("updateSim", obj.label);
    };

    selectImsiUpdateType = obj => {
        this.props.selectFilter("updateImsi", obj.label);
    };

    gotoPage = (page) => {
        const { startRange, endRange } = this.props.updateRange[0];
        const { resourceType } = this.props;
        this.props.fetchResources({ startRange, endRange, page, resourceType});
    }

    componentDidMount = () => {
        this.props.getMsisdnSimUpdateTypes();
    }

    render() {
        const { handleOnSearch, updateSim, updateImsi, unmsResourceType,selectUpdateType, update, msisdnUpdateTypes, responseMll,fnnUpdateTypes, imsiUpdateTypes, simUpdateTypes, unmsUpdateType, fetchPending, fetchFailed, initialValues, resourceType, updateRange } = this.props
        const { isFirst, isLast, totalPages, currentPage, content = [], passdownprops, success, totalItems, currentPageSize, searchRequest } = this.props.resources;
        const asyncBlurFields = ["ranges[].startRange", "ranges[].endRange"];
        const { cleanUpType } = this.props.selectedFilters;
        return (
            <div>
                <TDMHeader heading="Enter Range" />
                <ReduxFormContainer
                    initialValues={unmsConstants.INITIAL_VALUE}
                    formName={unmsConstants.UNMS_UPDATE_FORM}
                    onSubmit={() => { }}
                    asyncBlurFields={asyncBlurFields}
                    propsDepth={4}
                    unmsResourceType={unmsResourceType}
                    cleanUpType={cleanUpType}
                >
                    <FieldArray
                        assignProps
                        name="ranges"
                        passdownprops={passdownprops}
                        handlePrimary={handleOnSearch}
                        primaryButton={"Search"}
                        serviceIdField={{}}
                        component={RangeInput}
                    />
                </ReduxFormContainer>
                {
                    fetchPending && <TableContentLoader />
                }
                {fetchFailed && <FailedView message={"Could not load Numbers"} />}
                {success && content.length === 0
                    && <div id="empty-content">
                        <i className="fa fa-exclamation-circle fa-2x"></i>
                        <span>No Numbers Available for this Range.</span>

                    </div>}
                {content.length > 0 &&
                    <React.Fragment>
                        <div id="resource-table">
                            <Table>
                                <thead>
                                    {
                                        resourceType === "SIM/IMSI" ? 
                                            <tr>
                                                <th>S.No </th>
                                                <th>IMSI Value</th>
                                                <th>IMSI Status</th>
                                                <th>SIM Value</th>
                                                <th>SIM Status</th>
                                            </tr> :
                                            resourceType === "InfraCo FNN" ? 
                                            <tr>
                                                 <th>S.No </th>
                                                 <th>SERVICE ID</th>
                                                 <th>Status</th>
                                        </tr>
                                        :
                                            <tr>
                                                <th>S.No </th>
                                                <th>Resource Value </th>
                                                <th>Status</th>
                                                <th> Pool Type</th>
                                            </tr>
                                    }
                                </thead>
                                <tbody>
                                    {
                                        content.map((resource, index) => {
                                            return (<tr key= {((currentPage - 1) * currentPageSize ) + (index+1)}>
                                                <td style={{ fontWeight: "bold" }}>
                                                    <span className={`gradient-icon base-default`}>
                                                        <i className={`td-icon-sm icon-manage-services theme-text-primary `}></i>

                                                    </span>
                                                    {((currentPage - 1) * currentPageSize ) + (index+1)}
                                                </td>
                                                <td>
                                                {resourceType !== "SIM/IMSI" ? resource.resourceValue : resource.imsi}
                                                    {resourceType === "InfraCo FNN" ? resource.service_id : null}
                                                </td>
                                                <td>
                                                    <span className={`gradient-icon ${unmsUpdateType.filter(data => data.label === resource.statusName).length > 0 && unmsUpdateType.filter(data => data.label === resource.statusName)[0].color}`}>

                                                        <i className={`td-icon-sm ${unmsUpdateType.filter(data => data.label === resource.statusName).length > 0 && unmsUpdateType.filter(data => data.label === resource.statusName)[0].icon} theme-text-primary`}></i>

                                                    </span>
                                                    {resourceType !== "SIM/IMSI"? resource.statusName : resource.imsiStatus}
                                                    {resourceType === "InfraCo FNN" ? resource.status : null}

                                                </td>
                                                <td>
                                                    {resourceType !== "SIM/IMSI" && resourceType !== "InfraCo FNN" &&
                                                        <span className={`gradient-icon ${resource.poolName == "Contiguous Number Pool" ? "base-green" : "base-orange"} `}>

                                                            <i className={`td-icon-sm ${resource.poolName == "Contiguous Number Pool" ? "icon-wan-accelerate" : "icon-wireless-router"} theme-text-primary`}></i>

                                                        </span>
                                                     }
                                                    {resourceType !== "SIM/IMSI" ? resource.poolName : resource.sim}
                                                </td>
                                                {resourceType === "SIM/IMSI" ? <td>{resource.simStatus}</td> : null}
                                            </tr>)
                                        })
                                    }
                                </tbody>
                            </Table>
                            
                        </div>
                    </React.Fragment>
                }
                {
                    (fetchPending || success && content.length > 0) &&
                    <PaginationController
                        isClearPagination={searchRequest ? searchRequest : false}
                        totalPages={totalPages}
                        gotoPage={this.gotoPage}
                    /> 
                }
                {content.length > 0 &&
                    <React.Fragment>
                        {
                              resourceType === "InfraCo FNN" ? 
                              <TilesFrame
                              heading={"Update"}
                              color="purple"
                              data={responseMll}
                               select={selectUpdateType}
                               selected={update}
                          /> :
                            resourceType !== 'SIM/IMSI' ? 
                            <TilesFrame
                            heading={"Update"}
                            color="purple"
                            data={resourceType === 'FNN' ? fnnUpdateTypes : (content[0].poolName ==='UNMS Network MSISDN' || content[0].poolName ==='UNMS Hybrid MSISDN' ?msisdnUpdateTypes.filter(data => 'Held Presentation'!= data.label):msisdnUpdateTypes)}
                            select={selectUpdateType}
                            selected={update}
                        /> :
                      
                        <div>
                            <TilesFrame
                                heading={"IMSI Update"}
                                color="purple"
                                data={imsiUpdateTypes}
                                select={this.selectImsiUpdateType}
                                selected={updateImsi}
                            />
                            <TilesFrame
                                heading={"SIM Update"}
                                color="purple"
                                data={simUpdateTypes}
                                select={this.selectSimUpdateType}
                                selected={updateSim}
                            />
                        </div>
                        }
                    </React.Fragment>
                }

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedFilters: state.cleanup && state.cleanup.selectedFilters,
        msisdnUpdateTypes:state.cleanup.filters.unmsMsisdnUpdateType,
        simUpdateTypes:state.cleanup.filters.unmsSimUpdateType,
        imsiUpdateTypes:state.cleanup.filters.unmsImsiUpdateType,
        fnnUpdateTypes:state.cleanup.filters.unmsFnnUpdateType,
        resourceType: state.cleanup && state.cleanup.selectedFilters && state.cleanup.selectedFilters.unmsRscType,
        resources: state.cleanup.unms.resources || {},
        syncErrors: state.form && state.form[unmsConstants.UNMS_UPDATE_FORM] && state.form[unmsConstants.UNMS_UPDATE_FORM].syncErrors && state.form[unmsConstants.UNMS_UPDATE_FORM].syncErrors.ranges || [],
        fetchPending: state.cleanup.unms.fetchPending,
        fetchFailed: state.cleanup.unms.fetchFailed,
        updateRange: state.form
            && state.form[unmsConstants.UNMS_UPDATE_FORM]
            && state.form[unmsConstants.UNMS_UPDATE_FORM].values
            && state.form[unmsConstants.UNMS_UPDATE_FORM].values.ranges
            || [],
        responseMll:state.cleanup.filters.responseMll
    };
};
const mapDispatchToProps = dispatch => {
    return {
        selectFilter: (key, value) => dispatch(fnnActions.selectDataCleanUpFilter(key, value)),
        fetchResources: (pageNum) => dispatch(fnnActions.fetchResources(pageNum)),
        getMsisdnSimUpdateTypes: () => dispatch(fnnActions.getMsisdnSimUpdateTypes())
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnmsUpdateView);