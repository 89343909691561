import React, { useEffect, useState } from "react";
import { Button } from 'reactstrap';
import HeaderContent from '../../common/Header/HeaderContent';
import TilesFrame from "../../Data/accordion-frames/replicator/DataTilesFrame";
import CreateUsageFile from "./CreateUsageFile";
import RequestFileStatus from "./RequestFileStatus";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core';

const OcsUsageLoad = (props) => {
    const {history} = props;
    const { OcsCreatedStatus } = props;
    const [orderActionType, setOrderActionType] = useState(null);
    useEffect(() => {
        if (OcsCreatedStatus) {
            setOrderActionType("Request File status");
        }
    }, [OcsCreatedStatus])
    return (
        <div className="white pb-20">
            <HeaderContent
                title={`OCS Usage Load`}
                icon="cogs ops-icon"
                empty={true}
                redirectUtility={true}
                push={history?.push}
                loadingText="Loading OCS Usage Load..."></HeaderContent>
            <p className="ml-2 sub-leader font-weight-light">{orderActionType}</p>
            <TilesFrame
                color="magenta"
                data={[
                    {
                        label: 'Create Usage File',
                        icon: "icon-sim-card"
                    },
                    {
                        label: 'Request File status',
                        icon: "icon-search-find"
                    }
                ]}
                selected={orderActionType}
                select={(data) => { setOrderActionType(data.label) }}
                displayHeading={false}
            />
            {orderActionType === "Create Usage File" &&
                <CreateUsageFile />}
            {orderActionType === "Request File status" &&
                <RequestFileStatus />}
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        OcsCreatedStatus: state.ocsUsageLoad.OcsCreatedStatus
    }
}

export default connect(mapStateToProps, null)(OcsUsageLoad);