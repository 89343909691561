import moment from "moment";

export default (dataToSort = [], sortType = "") => {
  if (sortType.includes("date")) {
    return dataToSort.sort((date1, date2) => {
      return moment(date1).isAfter(moment(date2)) ? 1 : -1;
    });
  }
  return dataToSort.sort(
    (item1, item2) =>
      item1[sortType] > item2[sortType]
        ? 1
        : item1[sortType] < item2[sortType] ? -1 : 0
  );
};
