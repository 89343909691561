import * as types from '../actions/actionTypes';

import { getClassTypeIcon, getDataTypeIcon, getIcon } from '../utils/ui.js';
import { markSelectedState, markSelectedStates } from '../utils/state';

const INITIAL_STATE = {
    filters: {
        dataTypes: [
        ],
        classTypes: [
        ],
        envTypes: [
        ],
        productTypes: [],
        foreignCarrierTypes: [],
        resourceTypes:[],
        qtyTypes: [
            {
                label: '1 Record',
                qty: 1,
                selected: false
            },
            {
                label: '2 Records',
                qty: 2,
                selected: false
            },
            {
                label: '3 Records',
                qty: 3,
                selected: false
            },
            {
                label: '4 Records',
                qty: 4,
                selected: false
            },
            {
                label: '5 Records',
                qty: 5,
                selected: false
            },
            {
                label: '10 Records',
                qty: 10
            },
            {
                label: '20 Records',
                qty: 20
            }
        ]       
    },
    view: {
        items: [],
        fnnItems: []
    },
    activeStep: 0,
    selectedFilters: {}
}

const getStep = (filterKey) => {
    switch (filterKey) {
        case "dataTypes":
            return 2;
        case "classTypes":
            return 3;
        case "envTypes":
            return 1;
        case "qtyTypes":
            return 4;
        case "productTypes":
            return 3;   
        default:
            return 0;
    }
}

export default(state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SELECT_DATA_FILTER_DATA_TYPE:
            return markSelectedState("dataTypes", state, action.dataType);
        case types.SELECT_DATA_FILTER_CLASS_TYPE:
            return markSelectedStates({"classTypes":action.classType,"classNames":action.className,"classRequired":action.required}, state);
        case types.SELECT_DATA_FILTER_ENV_TYPE:
            return markSelectedState("envTypes", state, action.envType);
        case types.SELECT_DATA_FILTER_QTY_TYPE:
            return markSelectedState("qtyTypes", state, action.qtyType);
        case types.SELECT_DATA_FILTER_PRODUCT_TYPE:
            return markSelectedState("productTypes", state, action.productType);
        case types.SELECT_DATA_FILTER_FOREIGN_CARRIER_TYPE:
            return markSelectedState("foreignCarrierType", state, action.carrierType);
        case types.SELECT_DATA_FILTER_RESOURCE_TYPE:
            return markSelectedState("resourceType", state, action.resourceType);     
        case types.SELECT_DATA_FILTER_RESET:
            return {
                ...INITIAL_STATE
            };
        case types.LOAD_DATA_FILTER_SUCCESS:
            const {envFilter, dataFilter, classFilter, products, foreignCarrier} = action.payload;
            return {
                ...state, 
                filters: {
                    ...state.filters,
                    envTypes: envFilter.map(env => ({label: env, icon: 'icon-home'})),
                    dataTypes: dataFilter.map(data => ({label: data, icon: getDataTypeIcon(data)})),
                    // classTypes: classFilter['distinctClassNames'].map(className => ({label: className, icon: getClassTypeIcon(className)})),
                    classTypes: classFilter['individualClasses'].map(classObj => ({label: `Class ${classObj.classId}`, classBadge: `${classObj.name}`, icon: getClassTypeIcon(classObj.name), ...classObj})),
                   // productTypes: products.map(product => ({ label: product,  icon: 'icon-mobile'})), 
                    productTypes: products.map(product => ({ label: product,  icon: getDataTypeIcon(product), selected: false})), 
                    //hard coded MSISDN, SIM values
                    resourceTypes: ['MSISDN' , 'SIM' , 'MSISDN and SIM'].map(resource => ({label: resource,  icon: getDataTypeIcon(resource), selected: false})),
                    foreignCarrierTypes: foreignCarrier.map(foreignCarrier=> ({label:foreignCarrier, icon:getIcon(foreignCarrier)})),
                    loading: false
                }
            }
        case types.LOAD_DATA_FILTER_LOADING:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    loading: true
                }
            }
        case types.LOAD_DATA_LOADING:
            return {
                ...state,
                data: {
                    ...state.view,
                    loading: true
                }
            }
        case types.LOAD_DATA_SUCCESS:
            return {
                ...state,
                view: {
                    ...action.payload,
                    loading: false
                }
            }
        default:
            return state
    }
}