import config from "../../../config";

const TDM_GATEWAY_API = config.tdm_gateway_api.uri;


export const FETCH_DATA_BANK_CHECKOUT_DATA =  "FETCH_DATA_BANK_CHECKOUT_DATA";
export const FETCH_DATA_BANK_CHECKOUT_DATA_FULFILLED =  "FETCH_DATA_BANK_CHECKOUT_DATA_FULFILLED";
export const FETCH_DATA_BANK_CHECKOUT_DATA_REJECTED =  "FETCH_DATA_BANK_CHECKOUT_DATA_REJECTED";

export const TDM_DATA_BANK_RETRIEVE_CHECKOUT_URL = `https://api.tdm-dev.tdm.telstra-cave.com/tdm/standarddatabank/checkout/retrieve`;
export const TDM_SERVICE_MIGRATION_URL =  `${TDM_GATEWAY_API}/tdm/data-enrich/migration/analytics/customer`;

export const ASSET_CLEAR_CHECKOUT= "ASSET_CLEAR_CHECKOUT"

export const SERVICE_MIGRATION_LOADING = 'SERVICE_MIGRATION_LOADING';
export const SERVICE_MIGRATION_SUCCESS = 'SERVICE_MIGRATION_SUCCESS';
export const SERVICE_MIGRATION_FALIURE = 'SERVICE_MIGRATION_FAILURE';

export const ADD_BILL_PERIOD='ADD_BILL_PERIOD';
export const BILL_STATUS_CHECK='BILL_STATUS_CHECK';