import React from 'react';
import { Card, CardTitle } from "reactstrap";

export default (props) => {
    const {
        adjustmentId,
        description,
        dateApplied,
        amount,
        amountAplied,
        amountUnapplied,
        addAdjustment,
    } = props;
    return (
        <div className="d-inline-block">
            <Card body color="info" style={{ height: "200px", width: "200px" }}>
                {!addAdjustment && <div>
                    <CardTitle><p><strong>{adjustmentId}</strong></p></CardTitle>
                    <div>
                        <span><i class="mif-description" aria-hidden="true"></i></span>
                        {" "}
                        <span>{description}</span>
                    </div>
                    <div>
                        <span><i class="fa fa-calendar" aria-hidden="true"></i></span>
                        {" "}
                        <span>{dateApplied}</span>
                    </div>
                    <div>
                        <span><i class="fa fa-usd" aria-hidden="true"></i></span>
                        {" "}
                        <span>{amount}</span>
                    </div>
                    <div>
                        <span> <i class="fa fa-usd" aria-hidden="true"></i>
                            <i class="fa fa-check" aria-hidden="true"></i></span>
                        {" "}
                        <span>{amountAplied}</span>
                    </div>
                    <div>
                        <span><i class="fa fa-usd" aria-hidden="true"></i>
                            <i class="fa fa-times-circle" aria-hidden="true"></i></span>
                        <span>{amountUnapplied}</span>
                    </div>
                </div>}
            </Card>
        </div>
    )
}
