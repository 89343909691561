import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from "react-redux";
import {setTeams,setParentProgram,setGraphTeam} from '../../actions/surveyAction'


const PasmatTrendGraph = (props) => {
  const genData = (props) => ({
    labels: [...props.xData],
    datasets: [
      {
        type: props.type,
        label: props.graphtype,
        borderColor: [...props.colors],
        backgroundColor : [...props.colors],
        borderWidth: props.radius,
        radius : props.radius,
        data: [...props.yData],
        tension : props.tension,
        fill : props.fill,
        borderAlign : 'center'
      }
    ],
  });

 
  const [data, setData] = useState(genData(props));

 
  
  const options = {
    legend: {
        display: false,
    },
    plugins: {
        datalabels: {
          color: 'black'
        }
      },
      
  scales: {
    yAxes: [
      {
        scaleLabel: {
            display: true,
            labelString: props.graphtype
          },
        animate : true,
        ticks: {
            stepSize: props.stepSize,
            suggestedMin: 0,    // minimum will be -1, unless there is a lower value.
            beginAtZero: true,
            suggestedMax: props.suggestedMax,
            fontColor: "black",
            fontSize: 12,
      },
      gridLines: {
        zeroLineColor: '#000',
      }
        
      },
      
    ],
    xAxes: [
        {
          scaleLabel: {
              display: true,
              labelString: props.graphtype,
              barThickness: 1
            },
          animate : true,
          ticks: {
           fontColor: "black",
            fontSize: 12,
      },
          gridLines: {
            zeroLineColor: '#000',
          }
        },
        
      ],
  },
  onClick: function(_points, _event) {
    _event.map((evt) => {
      if(props.graphtype === "Programs")
        props.setParentProgram(props.trendAnalysisData[evt._index]);
      else
        {
          props.setGraphTeam(props.trendProgram.teams[evt._index])}
    })
  }
};

  return (
    <React.Fragment>
      <div className='header'>
        <h6 className='title'>{props.graphName}</h6>
      </div>
      {props.xData.length > 0 && <Bar data={genData(props)} options={options} />}
    </React.Fragment>
  );
};


 const mapStateToProps = (state) => {  
     return {
      trendAnalysisData : state.pasmatTrend.trendAnalysisData,
      trendProgram : state.pasmatTrend.trendProgram,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      setTeams: (teams,streamMaturity) => dispatch(setTeams(teams,streamMaturity)),
      setParentProgram : (parentProgram) => dispatch(setParentProgram(parentProgram)),
      setGraphTeam : (graphTeam) => dispatch(setGraphTeam(graphTeam))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasmatTrendGraph);