import React from 'react'
import { Table } from 'reactstrap';
import { SimasRow } from './SimasRow';

export const SimasTable = ({ tableData, page }) => {
    const rangeStart = page * 10;
    const rangeEnd = rangeStart + 10;
    const displayData = tableData.slice(rangeStart, rangeEnd)
    return (
        <React.Fragment>
        <Table hover responsive>
            <thead>
                <tr>
                    <th>SIM</th>
                    <th>IMSI</th>
                </tr>
            </thead>
            <tbody>
                {
                    displayData.map((simas, index) => (
                        <SimasRow key={index} tableRowData={simas} />
                    ))
                }
            </tbody>
        </Table>
        </React.Fragment>
    )
}