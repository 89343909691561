import React, { useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import { Button, Typography, TextField, IconButton, DialogActions, Tooltip } from '@material-ui/core';
import {Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { editDatasetTags } from '../../actions/retrieveDataActions';
import { displayLoading,displayErrorNew } from "../common/alerts/alerts";
import swal from 'sweetalert2';
import EditTag from "./EditTag";

const EditTagModal = (props) => {

    const [currData, setCurrData] = useState(props.modalData);
    const [currDataTags, setCurrDataTags] = useState(
    { "data": [] });
    const [isError, setIsError] = useState(false);
    const handleEditTags = (tags) => {
      let dataTags = { "data": tags }
      setCurrDataTags(dataTags);
      }

    const handleEditTagsSubmit = () =>
    {
      const checkedInId = currData.id !== undefined ? currData.id : "";
      displayLoading({ title: "Updating Tags", bodyText: "Please standby" });
      var updateTagsData = { "userName" : props.userName,"tags" : currDataTags.data};
      props.editDatasetTags(updateTagsData).then((response) => {
      if(response)
      {
        swal.close();
        swal.fire("Success", response.message, "success").then((res) => {
        setCurrData({});
        setCurrDataTags( {"data": []});
        setIsError(false);
        props.closeModal();
      })}
      })
    }
    return (
        <Modal size="lg" isOpen={props.openPanel} backdrop="static"
        style={{maxWidth: '600px', width: '100%'}}>
            <ModalHeader toggle={() => props.closeModal()} className="checkoutModal"
        cssModule={{'modal-title': 'w-100 text-center'}}><strong>Edit Tags</strong></ModalHeader>
            <ModalBody>
            <div class="container">
                <div class="row filterRow pb-4">
                    <div class="col-1" />
                    <div class="col-11">
                        <div class="row ml-2"></div>
                            <EditTag dataSetfilters={currData?.tags}
                                modalData={currData}
                                handleEditTags={handleEditTags}></EditTag>
                    </div>
                    </div>
                <ModalFooter>
                    <div class="pt-1">
                        <Button variant="contained" color="primary"
                            onClick={e=> handleEditTagsSubmit()}>Submit</Button>
                    </div>
                </ModalFooter>
            </div>
            </ModalBody>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        userName : state.auth.userName,
        customDataBankDataset: state.retrieveData.customDataBankDataset,
        tags: state.retrieveData?.tags
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        editDatasetTags: (updateTagsData) => dispatch(editDatasetTags(updateTagsData))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditTagModal);
