import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { TableHead } from "@mui/material";
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { updateApproval,fetchTestimonials } from '../../actions/userFeedbackActions';
import { tableRowFuzzySearch } from "@library/core/filters/filter";
import { TableHeader } from "@library/core/tables/";

import './testimonials.css'
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const Testimonials = (props) => {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filteredRows, setFilteredRows] = useState([]);
  const [unfilteredRows, setUnfilteredRows] = useState([]);

  let rows = [];

  useEffect(()=>{
      props.pageFeedBack.map((data, index) => {
        rows.push({
          "id": data.id, 
          "addedBy": data.addedBy,
          "AdminAprroval": data.adminApproved,
          "comments": data.comments, 
          "time": data.time,
          "rating": data.rating
        })
      });
      rows.sort();
      rows.sort(function(a, b) { 
        return b.id - a.id
      });
      setFilteredRows(rows);
      setUnfilteredRows(rows);
  },[props.pageFeedBack]);

  const searchFilter = (value) => {
    let filteredData = [];
    for (const row of unfilteredRows) {
      if (tableRowFuzzySearch([row.AdminAprroval, row.addedBy, row.comments, row.rating, row.time], value)) {
        filteredData.push(row);
      }
    }
    setFilteredRows(filteredData);
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateApproval = async (e) => {
    await props.updateApproval(e)
    props.fetchTestimonials();
  }
  
  return (
    <div className="white">
      <div class="container  testimonialCarousal">
        <div class="row customerReview pb-5">
          <div class="col-4" />
          <div class="ml-10 col-4 customerReviewText">
            Customer Reviews
          </div>
        </div>
      </div>
      <div class="pt-10">
        <TableContainer component={Paper}>
        <TableHeader title={""} searchFilter={searchFilter} searchPermOpen={true} darkHeader={false}/>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 160 }} align="center">
                  Added By
                </TableCell>
                <TableCell style={{ width: 160 }} align="center">
                  Added at
                </TableCell>
                {props.auth.isAdmin ?
                  <TableCell style={{ width: 160 }} align="center">
                   Comments
                  </TableCell> : <></>}
                {props.auth.isAdmin ?
                  <TableCell style={{ width: 160 }} align="center">
                   Rating
                  </TableCell> : <></>}
                {props.auth.isAdmin ?
                  <TableCell style={{ width: 160 }} align="center">
                    Admin Approval
                  </TableCell> : <></>}
                  
              </TableRow>

            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : filteredRows
              ).map((row) => (
                <TableRow key = {row.id}>
                  <TableCell align="center" style={{ width: 160 }}>
                    {row.addedBy}
                  </TableCell>
                  <TableCell align="center" style={{ width: 160 }}>
                    {row.time}
                  </TableCell>
                  {props.auth.isAdmin ?
                  <TableCell style={{ width: 160 }} align="center">
                   {row.comments}
                  </TableCell> : <></>}
                  {props.auth.isAdmin ?
                  <TableCell style={{ width: 160 }} align="center">
                   {row.rating}
                  </TableCell> : <></>}
                  {props.auth.isAdmin ?
                    <TableCell style={{ width: 160 }} align="center">
                      <Button variant="contained" color="primary" fullWidth style={{"maxWidth":"160px"}} onClick={e => updateApproval(row)}>
                        {row.AdminAprroval} 
                      </Button>
                    </TableCell> : <></>}
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
                </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          colSpan={3}
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.userFeedback.currentPage,
    pageFeedBack: state.userFeedback.pageFeedBack,
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateApproval: (value) => dispatch(updateApproval(value)),
    fetchTestimonials : () => dispatch(fetchTestimonials())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);