import { Button } from "reactstrap";
import React, { Component } from "react";
import { CardBody } from "reactstrap";
import "./CreateBillingAccount.css";
import CreateBillingAdjustmentModal from "./CreateBillingAdjustmentModal.js"
import AdjustmentTile from "./AdjustmentTile.js"

class Adjustment extends Component {
  render() {
    return (
      <div>
        {this.props.billingAdjListSize > 0 &&
          <div className="adjustment-body">
            <CreateBillingAdjustmentModal onRef={(ref) => {
              this.createBillingAdjustmentModal = ref
            }
            }
            />
            <CardBody>
              {this.props.billingAdjustments.map((adjustment, key) =>
                <AdjustmentTile key={key}
                  adjustmentId={adjustment.adjustmentId}
                  description={adjustment.description}
                  dateApplied={adjustment.dateApplied}
                  amount={adjustment.adjustmentAmount}
                  amountAplied={adjustment.amountApplied}
                  amountUnapplied={adjustment.amountUnapplied}
                  addAdjustment={false}
                />)}
              <div class="d-inline-block">
                <span> <Button style={{ marginBottom: "90px" }} color="link" onClick={() => this.createBillingAdjustmentModal.open(this.props.billingAccountNumber)}><i class="td-icon-sm icon-data-add-on"></i></Button></span>
                <span></span>
              </div>
            </CardBody>
          </div>
        }

        {this.props.billingAdjListSize == 0 && <CardBody>
          <div>
            <CreateBillingAdjustmentModal onRef={(ref) => {
              this.createBillingAdjustmentModal = ref
            }
            }
            />
            <span> <Button color="link" onClick={() => this.createBillingAdjustmentModal.open(this.props.billingAccountNumber)}><i class="td-icon-sm icon-data-add-on"></i></Button></span>
            <span></span>
          </div>
        </CardBody>}
      </div>
    );
  }
}
export default Adjustment;
