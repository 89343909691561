import './diagram-grid.css';

import { CardDeck, CardGroup, Col, Row } from 'reactstrap';

import DiagramCard from './DiagramCard';
import React from 'react';

export default function DiagramGrid({ diagrams, modalRef }) {
    return (
        <CardGroup className="diagram-grid">
            {diagrams.map((diagram, i) => <Col key={i} sm="3"><DiagramCard diagram={diagram} modalRef={modalRef} ></DiagramCard></Col>)}
        </CardGroup>
    )
}
