import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Field, change, reset, touch } from "redux-form";
import { renderField } from "../../common/fields/fields";
import Paper from '@material-ui/core/Paper';
import { 
    simValidator,
    imsiValidator,
    ekiValidator,
    pukValidator,
    kindValidator,
    a4indValidator,
    fsetindValidator,
    pinValidator,
} from "../../common/form/fieldValidators";
import { validateMsisdnArray } from "../../../utils/validation";
import { updatePhysicalSimMisdnList, updatePhysicalSimList } from '../../../actions/utilityActions';
import { fetchSnrmResourcesNoErrorDisplay } from "../../../actions/dataCleanUpActions";

import {uploadAndParsePhysicalSimImsiFile} from '../../../actions/utilityActions';

import { displayLoading, displayErrorNew, displaySuccess } from "../../common/alerts/alerts";
import PaginationController from "../../../utils/PaginationController";
import ChipInput from 'material-ui-chip-input';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from 'reactstrap';
import _ from 'lodash';
import './physicalSim.css';

import SimRow from './SimRow';

const StyledChipInput = withStyles(theme => ({
    root: {
      border: 'none',
    },
    input: {
      border: 'none !important',
    }
}))(ChipInput);

class PhysicalSimForm extends Component {
    state = {
        invalidMsisdnsChips: [],
        msisdns: [],
        validationComplete: false,
        loading: false,
        simEntry: {},
        simEntries: [],
        currentPage: 0,
        totalPages: 0,
    }

    componentDidMount() {
        if (this.props.msisdnList) {
            this.setState({
                ...this.state,
                msisdns: [...this.props.msisdnList],
                simEntries: [...this.props.simList],
                totalPages: Math.ceil(([...this.props.simList].length) / 10),
            });
        }
    }

    saveArrayState = (name, array) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: [...array],
        }));
    }

    handleAddChip = (chip) => {
        this.setState(prevState => ({
            ...prevState,
            validationComplete: false,
            loading: true,
        }), () => {
            const filteredChips = _.uniqBy([...this.state.msisdns, ...chip.split(/[.,\s\t\|\;]/).filter(v=>v!=='')]);
            const { validMsisdns, invalidMsisdns } = validateMsisdnArray(filteredChips);
            
            this.saveArrayState('invalidMsisdnsChips', [...invalidMsisdns])
            if (validMsisdns.length > 0) {
                this.checkMsisdnExist(validMsisdns);
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    validationComplete: true,
                    loading: false,
                }));
            }
        });
    }

    handleDeleteChip = (chip) => {
        let filteredChips = this.state.msisdns.filter(id => id !== chip);
        let filteredInvalidChips = this.state.invalidMsisdnsChips.filter(id => id !== chip && filteredChips.includes(id));
        this.saveArrayState('msisdns', filteredChips);
        this.props.updatePhysicalSimMisdnList(filteredChips);
        this.saveArrayState('invalidMsisdnsChips', [...filteredInvalidChips]);
    }

    checkMsisdnExist = (msisdns) => {
        const page = 0;
        let loopPos = 0;
        for (let msisdn of msisdns) {
            let startRange = msisdn;
            let endRange = msisdn;
            this.props.fetchSnrmResourcesNoErrorDisplay({ startRange, endRange, page })
             .then(()=> {
                if (!this.props.fetchFailed) {
                    if (this.props.resources.totalItems === 0) {
                        this.saveArrayState('msisdns', _.uniqBy([...this.state.msisdns, msisdn]))
                    } else {
                        this.saveArrayState('invalidMsisdnsChips', _.uniqBy([...this.state.invalidMsisdnsChips, msisdn]))
                    }
                }
                loopPos++;
            }).then(() => {
                if (loopPos === msisdns.length) {
                    this.props.updatePhysicalSimMisdnList(this.state.msisdns).then(()=> {
                        this.setState(prevState => ({
                            ...prevState,
                            validationComplete: true,
                            loading: false,
                        }));
                    });
                }
            });
        }
    }

    checkImsiExist = async (imsi) => {
        const page = 0;
        let startRange = imsi;
        let endRange = imsi;
        let exist = true;
        await this.props.fetchSnrmResourcesNoErrorDisplay({ startRange, endRange, page }).then(()=> {
            if (!this.props.fetchFailed) {
                if (this.props.resources.totalItems === 0) {
                    exist = false;
                } else {
                    exist = true;
                }
            }
        });
        return exist;
    }

    saveToSimList = () => {
        let entry = {};
        if (this.state.simEntries.length < 51) {
            for (const [field, value] of Object.entries(this.props.formValues.values)) {
                entry[field] = value;
            }
            this.setState(prevState => ({
                ...prevState,
                simEntry: entry,
                simEntries: [..._.uniqBy([...prevState.simEntries, entry], 'sim')],
                totalPages: Math.ceil(([..._.uniqBy([...prevState.simEntries, entry], 'sim')].length) / 10),
            }), () => {
                this.props.updatePhysicalSimList(this.state.simEntries).then(()=> {
                    this.props.passdownprops.dispatch(reset('PhysicalSimForm'));
                });
            });
        }
    }

    removeFromSimList = (obj, edit) => {
        let newArray = this.state.simEntries.filter(entry => entry.sim !== obj.sim && entry.imsi !== obj.imsi);
        this.setState(prevState => ({
            ...prevState,
            simEntries: [...newArray],
            totalPages: Math.ceil(([...newArray].length) / 10),
        }), () => {
            this.props.updatePhysicalSimList(this.state.simEntries).then(()=>{
                if (edit) {
                    this.editSimEntry(obj);
                }
            });
        });
    }

    saveToSimListFromFile = async (simImsiRecord) => {
        let entry = {};
        for (const [field, value] of Object.entries(simImsiRecord)) {
            entry[field.toLowerCase()] = value;
        }
        if ([..._.uniqBy([entry, ...this.state.simEntries], 'sim')].length < 51) {
            this.setState(prevState => ({
                ...prevState,
                simEntry: entry,
                simEntries: [..._.uniqBy([entry, ...prevState.simEntries], 'sim')],
                totalPages: Math.ceil(([..._.uniqBy([entry, ...prevState.simEntries], 'sim')].length) / 10),
            }), () => {
                this.props.updatePhysicalSimList(this.state.simEntries).then(()=> {
                    this.props.passdownprops.dispatch(reset('PhysicalSimForm'));
                });
            });
        }
    }

    editSimEntry = (obj) => {
        for (const [field, value] of Object.entries(this.props.formValues.registeredFields)) {
            this.props.passdownprops.dispatch(change('PhysicalSimForm', field, obj[field]));
            this.props.passdownprops.dispatch(touch('PhysicalSimForm', field));
        }
    }

    simValidate = (value) => {
        if (this.props.simList.some(sim => (sim.sim === value))) {
            return "Duplicate Sim, another entry is already using this SIM";
        } else {
            return simValidator(value);
        }
    }

    imsiValidate = (value) => {
        if (value !== undefined && this.props.simList.some(sim => (sim.imsi === value))) {
            return "Duplicate Imsi, another entry is already using this IMSI";
        } else {
            return imsiValidator(value);
        }
    }

    handleSelectFileClick = () => {
        const input = document.getElementById('hidden-upload-physical-sim-excel-fileInput');
        if (input) {
            input.click();
        }
    }

    fileSelectedHandler = (event) => {
        displayLoading({ title: "Processing File", bodyText: "Please standby whilst file is being processed" });
        const file = event.target.files[0];
        this.setState(prevState => ({
            ...prevState,
            selectedFile: file
        }), () => this.processSpreadsheet());
    }

    processSpreadsheet = async () => {
        const data = new FormData();
        let simImsi = [];
        let failCount = 0;
        const noOfExistingEntries = this.state.simEntries.length;
        data.append('file', this.state.selectedFile)
        this.props.uploadAndParseFile(data).then(result =>{
            if (this.props.fileTableData) {
                let count = 0;
                let simsAdded = 0;
                let imsiExists = 0;
                for (const simImsiRecord of this.props.fileTableData) {
                    if (!simImsi.includes(simImsiRecord.SIM) && !simImsi.includes(simImsiRecord.IMSI)) {
                        let found = this.props.fileTableData.filter( i => simImsiRecord.SIM === i.SIM) + this.props.fileTableData.filter( i => simImsiRecord.IMSI === i.IMSI);
                        found.length > 1 ? simImsi.push(simImsiRecord.SIM, simImsiRecord.IMSI) : null;
                        this.checkImsiExist(simImsiRecord.IMSI).then(result => {
                            count++;
                            if (!result && noOfExistingEntries+count < 50) {
                                this.saveToSimListFromFile(simImsiRecord).then(() => {
                                    simsAdded++;
                                    if ((count+failCount) === this.props.fileTableData.length) {
                                        if (imsiExists === 0) {
                                            displaySuccess({ title: `Physical SIM Record added Successfully`, text: `${simsAdded} Physical SIM record/s added successfully!`});
                                        } else {
                                            displaySuccess({ title: `Physical SIM Record added Successfully`, text: `${simsAdded} Physical SIM record/s added successfully! and ${imsiExists} skipped as IMSI already exists`});
                                        }
                                    }
                                });
                            } else {
                                result ? imsiExists++ : null;
                                if ((count+failCount) === this.props.fileTableData.length) {
                                    if (!this.props.fetchFailed) {
                                        if (imsiExists === 0) {
                                            displaySuccess({ title: `Physical SIM Record added Successfully`, text: `${simsAdded} Physical SIM record/s added successfully!`});
                                        } else {
                                            displaySuccess({ title: `Physical SIM Record added Successfully`, text: `${simsAdded} Physical SIM record/s added successfully! and ${imsiExists} skipped as IMSI already exists`});
                                        }
                                    } else {
                                        displayErrorNew({
                                            title: "Physical SIM Bulk Upload Failed",
                                            subTitle: "Failed to validate physical sim/imsi data, please try again later",
                                            errorMsg: this.props.fetchFailedMsg
                                        });
                                    }
                                }
                            }
                        });
                    } else {
                        failCount++
                    }
                }
            } else if (result.errorDescription) {
                return displayErrorNew({
                    title: "Physical SIM Bulk Upload Failed",
                    subTitle: "Failed to bulk upload physical sim/imsi data, please try again later",
                    errorMsg: result.errorDescription
                  })
            }
        });
    }

    gotoPage = (pageNum) => {
        this.setState(prevState => ({
            ...prevState,
            currentPage: pageNum
        }));
    }

    render() {
        const removeWhiteSpace = value => value && value.replace(/\s+/g,'');
        const rangeStart = this.state.currentPage * 10;
        const rangeEnd = rangeStart + 10;
        const displayData = this.state.simEntries.slice(rangeStart, rangeEnd)
        return (
            <Fragment>
                <Paper elevation={1} className="msisdnFormWrapper">
                    <div class="simImsiRow">
                        <div className="simImsiColumn" style={{"marginBottom":"5px"}}>
                            <label>MSISDNs <span className="optionalSpanText">(optional)</span></label>
                            <StyledChipInput
                                value={this.state.msisdns.map(chip => chip)}
                                onAdd={(chip) => this.handleAddChip(chip)}
                                onDelete={(chip) => this.handleDeleteChip(chip)}
                                blurBehavior='add'
                                fullWidth={true}
                                error={this.state.invalidMsisdnsChips.length > 0 || this.props.fetchFailed}
                                helperText={
                                    this.state.validationComplete ?
                                    this.state.invalidMsisdnsChips.length > 0 ? 
                                    'Incorrect format (start with \'614\' 11 digits long) or already exists:'+this.state.invalidMsisdnsChips : 
                                    this.props.fetchFailed ? 'Failed to validate MSISDN data: '+this.props.fetchFailedMsg : '' : ''
                                }
                                placeholder={'Enter MSISDNs seperated by comma or space'}
                                allowDuplicates={false}
                                disabled={this.state.loading}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {this.state.loading && <CircularProgress style={{ height: '20px', width: '20px' }} color={'secondary'} />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </Paper>
                <Paper elevation={1} className="simFormWrapper">
                    <div class="simImsiRow">
                        <div className="simImsiColumn">
                            <Field
                                label="SIM*"
                                name="sim"
                                height="25px"
                                type="number"
                                inputMode="numeric"
                                component={renderField}
                                autoComplete={false}
                                validate={this.simValidate}
                                feedback={true}
                            />
                        </div>
                        <div className="simImsiColumn">
                            <Field
                                label="IMSI*"
                                name="imsi"
                                height="25px"
                                type="number"
                                inputMode="numeric"
                                component={renderField}
                                autoComplete={false}
                                validate={this.imsiValidate}
                                feedback={true}
                            />
                        </div>
                    </div>
                    <div class="simImsiRow">
                        <div className="simImsiColumn">
                            <Field  
                                label="EKI*"
                                name="eki"
                                height="25px"
                                type="text"
                                inputMode="text"
                                component={renderField}
                                autoComplete={false}
                                validate={ekiValidator}
                                feedback={true}
                                normalize={removeWhiteSpace}
                            />
                        </div>
                        <div className="simImsiColumn">
                            <Field
                                label="PUK*"
                                name="puk"
                                height="25px"
                                type="number"
                                inputMode="numeric"
                                component={renderField}
                                autoComplete={false}
                                validate={pukValidator}
                                feedback={true}
                            />
                        </div>
                    </div>
                    <div class="simImsiRow">
                        <div className="simImsiColumn">
                            <Field
                                label="KIND*"
                                name="kind"
                                height="25px"
                                type="number"
                                inputMode="numeric"
                                component={renderField}
                                autoComplete={false}
                                validate={kindValidator}
                                feedback={true}
                            />
                        </div>
                        <div className="simImsiColumn">
                            <Field
                                label="A4IND*"
                                name="a4ind"
                                height="25px"
                                type="number"
                                inputMode="numeric"
                                component={renderField}
                                autoComplete={false}
                                validate={a4indValidator}
                                feedback={true}
                            />
                        </div>
                        <div className="simImsiColumn">
                            <Field
                                label="FSETIND*"
                                name="fsetind"
                                height="25px"
                                type="number"
                                inputMode="numeric"
                                component={renderField}
                                autoComplete={false}
                                validate={fsetindValidator}
                                feedback={true}
                            />
                        </div>
                        <div className="simImsiColumn">
                            <Field
                                label="PIN*"
                                name="pin"
                                height="25px"
                                type="number"
                                inputMode="numeric"
                                component={renderField}
                                autoComplete={false}
                                validate={pinValidator}
                                feedback={true}
                            />
                        </div>
                    </div>
                    <div class="simImsiRow">
                        <div className="simImsiColumn">
                            <Field
                                label="SIM ORDER TYPE"
                                name="sim order type"
                                height="25px"
                                type="text"
                                inputMode="text"
                                component={renderField}
                                autoComplete={false}
                                feedback={true}
                            />
                        </div>
                        <div className="simImsiColumn">
                            <Field
                                label="PROFILE ID"
                                name="profile id"
                                height="25px"
                                type="number"
                                inputMode="decimal"
                                component={renderField}
                                autoComplete={false}
                                feedback={true}
                            />
                        </div>
                        <div className="simImsiColumn">
                            <Field
                                label="SIM ARTWORK"
                                name="sim artwork"
                                height="25px"
                                type="text"
                                inputMode="text"
                                component={renderField}
                                autoComplete={false}
                                feedback={true}
                            />
                        </div>
                    </div>
                    <div class="simImsiRow" style={{"margin":"0px auto","maxWidth":"315px","justifyContent":"space-between"}}>
                        <div class="savePhysicalSimBtn">
                            <Tooltip title={this.props.invalid ? "Please fill in necessary fields" : this.state.simEntries.length > 49 ? "50 Physical Sim limit" : "Add Physical SIM"}>
                                <span>
                                    <Button disabled={this.props.invalid || this.props.simList.some(sim => (sim.sim === this.props.formValues.values.sim || sim.imsi === this.props.formValues.values.imsi)) || this.state.simEntries.length > 49} 
                                            onClick={this.saveToSimList} 
                                            type="button" color="primary" size="sm">
                                        Add Physical SIM
                                    </Button>
                                </span>
                            </Tooltip>
                        </div>
                        <div class="savePhysicalSimBtn">
                            <Tooltip title={this.state.simEntries.length > 49 ? "50 Physical Sim limit" : <div>Add Physical Sims from Excel file<br/> Format:<br/> - Headers must be identical to form field names to work!<br/> - When adding numbers in excel start with an asterisk to maintain format like so *896100000000</div>}>
                                <span>
                                    <Button disabled={this.state.simEntries.length > 49} type='file' onClick={this.handleSelectFileClick} type="button" color="secondary" size="sm">
                                        Bulk add Physical SIM
                                    </Button>
                                    <input 
                                        type="file" 
                                        accept=".csv,.xlsx,.xls"
                                        id="hidden-upload-physical-sim-excel-fileInput"
                                        onChange={this.fileSelectedHandler}
                                        onClick={(event) => {
                                            event.target.value =""
                                            this.setState(prevState => ({
                                                ...prevState,
                                                selectedFile: ''
                                            }));
                                        }}
                                        style={{display: "none"}}/>
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                </Paper>
                <div className="simImsiBody">
                    {
                        (displayData).map((obj, index) => (
                            <Paper elevation={1} key={index} className="reviewFormWrapper">
                                <SimRow object={obj}/> 
                                <div style={{"width":"100%"}}>
                                    <div style={{"margin":"0px auto","maxWidth":"150px","display":"flex","justifyContent":"space-between"}}>
                                        <span style={{"width":"65px"}}>
                                            <Button onClick={()=>this.removeFromSimList(obj, true)} className="w-100" type="button" color="primary" size="sm">Edit</Button>
                                        </span>
                                        <span style={{"width":"65px"}}>
                                            <Button onClick={()=>this.removeFromSimList(obj, false)} className="w-100" type="button" color="danger" size="sm">Remove</Button>
                                        </span>
                                    </div>
                                </div>
                            </Paper>
                        ))
                    }
                </div>
                <br/>
                {this.state.totalPages !== 0 ? <PaginationController totalPages={this.state.totalPages} gotoPage={this.gotoPage}/> : null}
            </Fragment>
        ); 
    }
}

const mapStateToProps = state => {
    return {
        formValues: state.form.PhysicalSimForm,
        resources: state.cleanup.snrm.resources || {},
        fetchPending: state.cleanup.snrm.fetchPending,
        fetchFailed: state.cleanup.snrm.fetchFailed,
        fetchFailedMsg: state.cleanup.snrm.fetchFailedMsg,
        msisdnList: state.utility.physicalSimPreceeding.msisdnList,
        simList: state.utility.physicalSimPreceeding.simList,

        fileTableData : state.utility.physicalSimPreceeding.fileContents.customDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchSnrmResourcesNoErrorDisplay: (startRange, endRange) => dispatch(fetchSnrmResourcesNoErrorDisplay(startRange, endRange)),
        updatePhysicalSimMisdnList: (list) => dispatch(updatePhysicalSimMisdnList(list)),
        updatePhysicalSimList: (list) => dispatch(updatePhysicalSimList(list)),

        uploadAndParseFile: (data) => dispatch(uploadAndParsePhysicalSimImsiFile(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalSimForm);