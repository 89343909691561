//T-Connect
export const TCONNECT_GET_VIEW_DATA_LOADING = "TCONNECT_GET_VIEW_DATA_LOADING";
export const TCONNECT_GET_VIEW_DATA_SUCCESS = "TCONNECT_GET_VIEW_DATA_SUCCESS";
export const TCONNECT_GET_VIEW_DATA_ERROR = "TCONNECT_GET_VIEW_DATA_ERROR";

export const TCONNECT_UPDATE_CAPABILITIES_LOADING = "TCONNECT_UPDATE_CAPABILITIES_LOADING";
export const TCONNECT_UPDATE_CAPABILITIES_SUCCESS = "TCONNECT_UPDATE_CAPABILITIES_SUCCESS";
export const TCONNECT_UPDATE_CAPABILITIES_ERROR = "TCONNECT_UPDATE_CAPABILITIES_ERROR";

export const TCONNECT_GET_ORDERS_LOADING = "TCONNECT_GET_ORDERS_LOADING";
export const TCONNECT_GET_ORDERS_SUCCESS = "TCONNECT_GET_ORDERS_SUCCESS";
export const TCONNECT_GET_ORDERS_ERROR = "TCONNECT_GET_ORDERS_ERROR";

export const TCONNECT_ONBOARD_USER_LOADING = "TCONNECT_ONBOARD_USER_LOADING";
export const TCONNECT_ONBOARD_USER_SUCCESS = "TCONNECT_ONBOARD_USER_SUCCESS";
export const TCONNECT_ONBOARD_USER_ERROR = "TCONNECT_ONBOARD_USER_ERROR";

export const TCONNECT_VERIFY_CIDN_SUCCESS = "TCONNECT_VERIFY_CIDN_SUCCESS";

export const TCONNECT_SUBMIT_ORDER_ERROR = "TCONNECT_SUBMIT_ORDER_ERROR";
export const TCONNECT_SUBMIT_ORDER_SUCCESS = "TCONNECT_SUBMIT_ORDER_SUCCESS";
export const TCONNECT_SUBMIT_ORDER_LOADING = "TCONNECT_SUBMIT_ORDER_LOADING";
export const TCONNECT_GET_ORDERFNN_LOADING = "TCONNECT_GET_ORDERFNN_LOADING";
export const TCONNECT_GET_ORDERFNN_SUCCESS = "TCONNECT_GET_ORDERFNN_SUCCESS";
export const TCONNECT_GET_ORDERFNN_ERROR = "TCONNECT_GET_ORDERFNN_ERROR";

export const TCONNECT_ADD_CONTACT_LOADING = "TCONNECT_ADD_CONTACT_LOADING";
export const TCONNECT_ADD_CONTACT_SUCCESS = "TCONNECT_ADD_CONTACT_SUCCESS";
export const TCONNECT_ADD_CONTACT_ERROR = "TCONNECT_ADD_CONTACT_ERROR";