import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isTaasTokenValid } from './functions';

// authorization higher order component, only show component if not logged in, useful for signup, login
export default (ChildComponent) => {
    class ComposedComponent extends Component {
        // component just got rendered
        componentDidMount() {
            this.shouldNavigateAway();
        }

        // component just got updated
        componentDidUpdate() {
            this.shouldNavigateAway();
        }

        async shouldNavigateAway() {
            if (this.props.auth && (await isTaasTokenValid(this.props.auth)).verified && this.props.listOfTeams && this.props.listOfTeams.length > 0) {
                this.props.history.push('/dashboard');
            }
        }

        render() {
            return <ChildComponent {...this.props} />
        }
    }

    const mapStateToProps = (state) => {
        return {
            auth: state.auth.authenticated,
            listOfTeams: state.auth.myAvailableTeams
        }
    }

    return connect(mapStateToProps)(ComposedComponent);
}