import React from 'react';
import {SubHeader} from "../../../../common/custom/SubHeader";
import {Table} from "reactstrap";
import _ from 'lodash';

export const FnnDetails = (fnnDetails) => {
    return(
        <React.Fragment>
            {(!_.isEmpty(fnnDetails) &&
                <React.Fragment>
                    <SubHeader
                        text={"NBN FNN"}
                        iconClass={"td-icon-md icon-crowd-support"}
                        iconColor="turquoise"
                        filename={`someFile-CUSTOMER`}/>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>Environment</th>
                            <th>Type</th>
                            <th>Resource Value</th>
                            <th>Exchange Id</th>
                            <th>Locality</th>
                            <th>Realm</th>
                            <th>PostCode</th>
                        </tr>
                        </thead>
                        <tbody>
                        {fnnDetails.fnnDetails.map(eachFnn => 
                            <tr key={eachFnn.fnnDetails[0].exchangeId}>
                                <th scope="row">{eachFnn.environment}</th>
                                <td>{eachFnn.fnnDetails[0].type}</td>
                                <td>{eachFnn.fnnDetails[0].resourceValue || 'Not Available'}</td>
                                <td>{eachFnn.fnnDetails[0].exchangeId}</td>
                                <td>{eachFnn.fnnDetails[0].locality}</td>
                                <td>{eachFnn.fnnDetails[0].realm}</td>
                                <td>{eachFnn.fnnDetails[0].postCode}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}