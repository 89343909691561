import React, {Fragment, useState } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';


const FilterDataset = (props) => {
    const [datasetFilters, setDataFilters] = useState(props.dataSetfilters);
    const [filterName, setFilterName] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [appliedFilters , setAppliedFilters] = useState([]);

    const handleAddFilter = () => {
        let list  = [...appliedFilters];
        list.push({ "name": filterName.name, "value": filterValue })
        console.log(list)
        let remainingFilters = datasetFilters.filter((data) => data.name !== filterName.name)
        setFilterName("");
        setFilterValue("");
        setDataFilters(remainingFilters)
        setAppliedFilters(list);
        props.handleDatasetFilters(list)
    }
    const handleRemoveFilter = (data) => 
    {
        let appliedDataFilters = [...appliedFilters];
        let filtersAddedBack = appliedDataFilters.filter((fil) => fil.name !== data.name)
        setAppliedFilters(filtersAddedBack);
        let removedFilter = [...datasetFilters];
        removedFilter.push({"name":data.name})
        setDataFilters(removedFilter);
        props.handleDatasetFilters(filtersAddedBack)
    }
    return (
        <div class="container mt-5">

           <Fragment>
               
                    <div class="row">
                        <div class="col-5 customHeaders">Filters</div>
                        <div class="col-5 ml-3 customHeaders">Value</div>
                        </div>
                        {appliedFilters.length > 0 ? 
                            <div class="row">
                            {appliedFilters.map((filterData, index) => {
                                return (
                                    <div class="row" key={index}>
                                    {filterData.name !== "" && filterData.name !== undefined ? 
                                    <><div class="col-5 ml-1">
                                        <TextField variant="outlined" disabled
                                            value={filterData.name} className="filterNameLabel" />
                                    </div>
                                    <div class="col-5 ml-2">
                                        <TextField variant="outlined" disabled
                                            value={filterData.value} className="filterValueLabel" />
                                    </div>
                                    <div class="col-1 ml-n1 mt-1">
                                        <CancelIcon className="cancelIcon"
                                            onClick={e => handleRemoveFilter(filterData)} />
                                    </div></> :<></>}
                                    </div>
                                )})}

                            </div>:<></>}
                        <div class="row">
                        <div class="col-5">
                            <Autocomplete
                                options={datasetFilters}
                                getOptionLabel={(option) => option.name}
                                id="customdataFiltersAutoComplete"
                                value={filterName}
                                onChange={(event, value) => setFilterName(value)}
                                autoComplete
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        className="customAutoComplete"
                                        placeholder="Select filter name"
                                    />
                                )}
                            /></div>
                            <div class="col-5 ml-3">
                               <TextField variant="outlined" placeholder="Enter filter value" 
                                            value={filterValue} className="filterValueLabel" 
                                            onChange={(e) => setFilterValue(e.target.value)}/>
                            </div>
                            <div class="col-1 mt-2 ml-n1">
                               <AddIcon className="addCircleTwoToneIcon"
                                        onClick={e => handleAddFilter(e)}
                                        disabled={(filterValue !== "" && filterName !== "" && datasetFilters.length >0) ? false : true}
                                    /> 
                            </div>
                            </div>
                           
                            </Fragment></div>
    )

}

export default FilterDataset