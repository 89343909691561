import * as types from '../actions/actionTypes';

const INITIATE_STATE = {

    thresholdDetails: {
        failed: false,
        pending: false,
        success: false,
        data: [],
        errorMessage:''
    }
}

export default(state=INITIATE_STATE, action) => {
    switch(action.type) {
        case types.ADD_THRESHOLD_LOADING:
            return {
                ...state,
                thresholdDetails: {
                    failed: false,
                    pending: true,
                    success: false,
                    data: [],
                    errorMessage:''
                }
            }
        case types.ADD_THRESHOLD_SUCCESS:
            
            return {
                ...state,
                thresholdDetails: {
                    failed: false,
                    pending: false,
                    success: true,
                    data: action.payload,
                    errorMessage:''
                }
            }
        case types.ADD_THRESHOLD_FAILED:
            return {
                ...state,
                thresholdDetails: {
                    failed: true,
                    pending: false,
                    success: false,
                    data: [],
                    errorMessage:action.payload
                }
            }
        case types.FETCH_THRESHOLD_FAILED:
            return {
                ...state,
                thresholdDetails: {
                    failed: true,
                    pending: false,
                    success: false,
                    data:[],
                    errorMessage:action.payload
                }
            }
        default:
            return state;
    }
}