import React, { Component, Fragment } from 'react'
import { SubHeader } from '../../common/custom/SubHeader';
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field, reset, change } from 'redux-form';
import { checkRcrStatus } from '../../../actions/utilityActions';
import { displayLoading, displaySuccess } from "../../common/alerts/alerts";
import RcrStatusForm from './RcrStatusForm';
import RcrStatusResultTable from './RcrStatusResultTable';
import swal from 'sweetalert2';
import { Button } from "@material-ui/core";
import HeaderContent from '../../common/Header/HeaderContent';

class RcrStatusUtility extends Component {
    state = {
        currentPage: 'form',
        showTable: false,
        customerId:null
    };

    showTable = (bool) => {
        this.setState(prevState => ({
            ...prevState,
            showTable: bool,
        }));
    }

    handleSubmit = (batchId) => {
        let requestParams={};
        requestParams.environment=this.props.environment;
        requestParams.teamId=this.props.teamId;
        displayLoading({ title: "Retrieiving RCR Status", bodyText: "Please standby while request is performed" });
        this.props.checkRcrStatus(batchId,requestParams).then((rep) => {
            if (this.props.rcrStatusSuccess) {
                swal.close();
                this.showTable(true);
                this.setState(prevState => ({
                    ...prevState,
                    customerId: rep?.customerId,
                }));
            } else if (this.props.rcrStatusFailed) {
                this.showTable(false);
                swal.close();
              
                swal.fire({
                    type: 'error',
                    title: 'Error',
                    html: rep.responseDescription,
                    confirmButtonText: 'OK',
                });
            }
        });
    }

    render() {
        const {history} =this.props;
        return (
            <div className="white">
                  <HeaderContent
                title={`RCR Status Checker`}
                icon="cogs ops-icon"
                empty={true}
                redirectUtility={true}
                push={history?.push}
               ></HeaderContent>
                <div className="content">
                    <form>
                        { this.state.currentPage === 'form' ?
                            <Fragment>
                                <RcrStatusForm submit={this.handleSubmit} initialRequestIds={
                                    this.props.msisdnSuccess && this.props.simSuccess ? [this.props.msisdnResponse.toString(), this.props.simResponse.toString()] :
                                    this.props.msisdnSuccess ? [this.props.msisdnResponse.toString()] :
                                    this.props.simSuccess ? [this.props.simResponse.toString()] : []
                                }
                                customerId={this.state.customerId}/>
                            </Fragment> : null
                        }
                        { this.state.showTable ?
                            <Fragment>
                                <RcrStatusResultTable status={this.props.rcrStatusResponse}/>
                            </Fragment> : null
                        }
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        rcrStatusPending: state.utility.checkRcrStatus.isCheckRcrStatusPending,
        rcrStatusFailed: state.utility.checkRcrStatus.isCheckRcrStatusFailed,
        rcrStatusSuccess: state.utility.checkRcrStatus.isCheckRcrStatusSuccess,
        rcrStatusResponse: state.utility.rcrRequestStatusData,

        msisdnSuccess: state.utility.physicalSimPreceeding.isSubmitMsisdnSuccess,
        msisdnResponse: state.utility.physicalSimPreceeding.msisdnResponse,
        simSuccess: state.utility.physicalSimPreceeding.isSubmitSimSuccess,
        simResponse: state.utility.physicalSimPreceeding.simResponse,
        environment: state.auth.selectedEnvValue,
        teamId: state.auth.team
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkRcrStatus: (request,request2) => dispatch(checkRcrStatus(request,request2)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps),reduxForm({form: "RcrStatusForm"}))(RcrStatusUtility);
