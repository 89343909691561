import React from 'react';
import _ from 'lodash';
import ServiceMigration from '../../Data/dataviews/DataBankCheckout/sub-component/ServiceMigration';
import {CSVLink} from 'react-csv/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSalesforce} from '@fortawesome/free-brands-svg-icons';
import {faUser} from '@fortawesome/free-regular-svg-icons';
import {faIdCard, faArchive} from '@fortawesome/free-solid-svg-icons';

const getIconContent = (iconClass,iconSize)=>{
    switch(iconClass){
        case 'salesforce':
            return <FontAwesomeIcon icon={faSalesforce} style={{'height':'auto','width':iconSize}} color={'#4C6EF5'} />;
            break;
        case 'account':
            return <FontAwesomeIcon icon={faUser} style={{'height':'auto','width':iconSize}} color={'#4C6EF5'} />;
            break;
        case 'contact':
            return <FontAwesomeIcon icon={faIdCard} style={{'height':'auto','width':iconSize}} color={'#4C6EF5'} />;
            break;
        case 'order':
            return <FontAwesomeIcon icon={faArchive} style={{'height':'auto','width':iconSize}} color={'#4C6EF5'} />;
            break;
        default:
            return <i className={iconClass + " theme-text-primary mainIcon"} aria-hidden="true"></i>
    }

}
const renderIcon = (iconColor, iconClass,iconSize) => {
    return (
        <span className={`base-${iconColor}`} style={{display:'inline-block', marginLeft:'0.5rem'}}>
            {getIconContent(iconClass,iconSize)}
        </span>
    )
}

export const SubHeader = ({text,smBtnProps, subComponent, iconClass, data, iconColor, filename, small, iconLeft,iconSize,addStyle}) => (
    <span className="dataview-header" style={addStyle}>
        <span>
            <span>
                <div>
                    <p className={`sub-${small ? "header":"leader"} font-weight-light`}>{text}</p>
                    {subComponent}
                </div>
            </span>
            {(data || iconLeft) && renderIcon(iconColor, iconClass,iconSize)}
        </span>
        {!_.isEmpty(smBtnProps) && smBtnProps.smReq && smBtnProps.smReq.customerId && smBtnProps.smReq.ban && smBtnProps.smReq.msisdn && smBtnProps.smReq.env && smBtnProps.smReq.cidn && (<ServiceMigration handleServiceMigration={smBtnProps.handleServiceMigration}
                                serviceMigLoading={smBtnProps.serviceMigLoading}
                                smReq={smBtnProps.smReq}
                                smBtnInfo={smBtnProps.smBtnInfo}/>) }
        <span>
            {(!data && !iconLeft) && renderIcon(iconColor, iconClass,iconSize)}
            {data && <React.Fragment>
                <span className="base-purple">
                    <a
                        href={`data: text/json;charset=utf-8, ${encodeURIComponent(JSON.stringify(data))}`}
                        download={`${filename}.json`}>
                        <i className={"fa fa-file-code-o fa-2x theme-text-primary"} aria-hidden="true"></i>
                    </a>
                </span>
                <span className="base-green">
                    <CSVLink
                        data={data}
                        filename={filename
                        ? `${filename}.csv`
                        : "tdm-data.csv"}
                        style={{
                        "textDecoration": "none!important"
                    }}>
                        <i className={"fa fa-file-excel-o fa-2x theme-text-primary"} aria-hidden="true"></i>
                    </CSVLink>
                </span>
            </React.Fragment>}
        </span>
    </span>
)