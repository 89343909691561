import React, {useState, Fragment} from "react";
import TeamDataTableRow from "./TeamDataTableRow";
import CheckoutPopUp from "./CheckoutPopUp";
import EdgarCheckoutPopUp from "./EdgarCheckoutPopUp";

export default ({ teamData, handleAllocateToTeamModal, handleCheckout, currentTeamId, isShowCircleInServiceId, loggedInUser,exchangeData,filterServiceIds, handleServiceFilterCheckout,handleCheckoutServiceFilterValue }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const enableModal = (tableRowData) =>{
        setShowModal(true);
        setSelectedRow(tableRowData);
    }
    return (
        <React.Fragment>
            {
                teamData.map((tableRowData, index) => {
                    return (
                        <Fragment>
                            <TeamDataTableRow
                                key={index}
                                tableRowData={tableRowData}
                                handleAllocateToTeamModal={handleAllocateToTeamModal}
                                handleCheckout={handleCheckout}
                                currentTeamId={currentTeamId}
                                isShowCircleInServiceId={isShowCircleInServiceId}
                                loggedInUser={loggedInUser}
                                enableModal = {enableModal}
                                exchangeData = {exchangeData.exchngData}
                                filterServiceIds = {filterServiceIds}
                                handleServiceFilterCheckout = {handleServiceFilterCheckout}
                            />
                        </Fragment>
                    )
                })
            }
            {
                showModal ? selectedRow.serviceId != 504 ?
                <CheckoutPopUp
                    showModal = {showModal}
                    setShowModal = {setShowModal}
                    selectedRow = {selectedRow}
                    loggedInUser = {loggedInUser}
                    handleCheckout = {handleCheckout}
                    exchangeData =  {exchangeData.exchngData}
                    handleCheckoutServiceFilterValue = {handleCheckoutServiceFilterValue}
                /> :
                    <EdgarCheckoutPopUp
                        showModal = {showModal}
                        setShowModal = {setShowModal}
                        selectedRow = {selectedRow}
                        loggedInUser = {loggedInUser}
                        handleCheckout = {handleCheckout}
                        exchangeData =  {exchangeData.exchngData}
                        handleCheckoutServiceFilterValue = {handleCheckoutServiceFilterValue}
                    />
                    : null
            }
        </React.Fragment>
    )
}