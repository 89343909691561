import React from 'react';
import {Collapse,Button } from 'reactstrap';
import { Field } from 'redux-form'
import { renderField } from '../../common/fields/fields';

class ProductDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    const { fields } = this.props;
    if (!fields.length) fields.push({productName : "IPWAN ADSL TYPE 3 PORT", productTcd: new Date(),  productFdd: new Date(),  productCompletionDate: new Date(), productOrderStatus :"In progress" });
  }
  
  render() {
    let {fields, meta: { touched, error, submitFailed }} = this.props;
    const buttonStyle = {
      marginBottom: '1rem',
      width: '100%',
      textAlign: 'left'
  }
  const statusOptions = [
    { label: "In progress", value: "In progress" },
    { label: "Completed", value: "Completed" },
    { label: "Cancel in progress", value: "Cancel in progress" },
    { label: "Cancelled", value: "Cancelled" }
  ];
    return (
      <ul style={{background:"antiquewhite"}}>
        {fields.map((product,index) => (
              <li key={index}>
                      <div className="row">
                          <div className="col-sm-6" style={{fontWeight : "bold"}}>Product - {index+1}</div>
                          <div className="col-sm-6" style={{textAlign : "right"}}><Button color="danger" title="Remove Product"
                            onClick={() => fields.remove(index)}><i className="fa fa-minus-circle fa-sm" alt="Remove" aria-hidden="true"></i></Button></div>
                          <div className="col-sm-8">
                            <Field
                                name={`${product}.productName`} 
                                label="Product Name"
                                type="text"
                                autoComplete="off"
                                component={renderField}   
                            />
                          </div>
                          <div className="col-sm-4">
                              <Field
                                  name={`${product}.productOrderStatus`} 
                                  label="Product Order Status"
                                  type="selectWithCustomHeight"
                                  autoComplete="off"
                                  component={renderField}   
                                  options={statusOptions}
                              />  </div> 
                          <div className="col-sm-4">
                              <Field
                                  name={`${product}.productTcd`} 
                                  label="Product TCD"
                                  type="date"
                                  autoComplete="off"
                                  component={renderField}   
                              /> </div>
                          <div className="col-sm-4">
                              <Field
                                  name={`${product}.productFdd`} 
                                  label="Product FDD"
                                  type="date"
                                  autoComplete="off"
                                  component={renderField}   
                              /></div>
                          <div className="col-sm-4">
                              <Field
                                  name={`${product}.productCompletionDate`} 
                                  label="Completion Date"
                                  type="date"
                                  autoComplete="off"
                                  component={renderField}   
                              /></div>
                        </div>           
              </li>
          ))}
           <li style={{marginBottom : "10px"}}>
               <Button color="primary" onClick={() => fields.push(
                 {productName : "IPWAN ADSL TYPE 3 PORT", productTcd: new Date(),  productFdd: new Date(),  productCompletionDate: new Date(), productOrderStatus :"In progress" }
               )}>
                 Add Product
               </Button>
               {(touched || submitFailed) && error && <span>{error}</span>}
            </li>
     </ul>
    );
  }
}

export default ProductDetailsComponent