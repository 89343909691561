import {axiosInstance as axios} from "../axios.config";
import _ from "lodash";
import * as types from "./actionTypes";
import config from "../config";
import { authHeaders } from "../utils/api";
import { displayErrorNew } from "../components/common/alerts/alerts";
import {EnvirnomentLabels} from '../components/auth/functions';

const API_URL_GATEWAY = config.tdm_gateway_api.uri;
const CURRENT_ENV = config.currentEnv;
const TAAS_USER_BASE_URL = config.taas_user_base;

export const retrieveServiceIds = teams => async dispatch => {
  try {
    dispatch({ type: types.FETCH_SERVICE_IDS_PENDING, payload: true });

    const response = await axios.post(
      `${API_URL_GATEWAY}/tdm/data/team/fetch/services`,
      teams,
      { headers: authHeaders() }
    );

    if (response.data.responseStatus === "Success") {
      dispatch({
        type: types.FETCH_SERVICE_IDS_SUCCESS,
        payload: response.data.services
      });
    } else {
      dispatch({ type: types.FETCH_SERVICE_IDS_FAILED });
    }
  } catch (e) {
    console.error(e);
    dispatch({ type: types.FETCH_SERVICE_IDS_FAILED });
  }
};

export const fetchAllTeams = () => async dispatch => {
  try {
    dispatch({ type: types.FETCH_ALL_TEAMS_PENDING, payload: true });
    const response = await axios.get(
      `${API_URL_GATEWAY}/tdm/data/team/active/details`,
      { headers: authHeaders() }
    );
    if (response.status === 200) {
      dispatch({
        type: types.FETCH_ALL_TEAMS_SUCCESS,
        payload: response.data.teams
      });
      return response.data.teams;
    } else {
      dispatch({ type: types.FETCH_ALL_TEAMS_FAILED });
      throw new Error(response.data.responseDescription);
    }
  } catch (e) {
    console.error(e);
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.responseDescription;
    else errorMsg = "Network connectivity issues, please try again later.";
    dispatch({ type: types.FETCH_ALL_TEAMS_FAILED });
    return {
        responseDescription: errorMsg,
        responseStatus: "Fail"
      };
  }
};

const fetchTeams = ({ email, isAdmin, myTeamOptions }) => async dispatch => {
  if (myTeamOptions && myTeamOptions.length > 0) {
    return myTeamOptions;
  } else {
    const response = await axios.get(`${TAAS_USER_BASE_URL}/tools/teams?toolName=TDM`, {
      headers: authHeaders({},true)
    });
    return response.data.teamDtos;
  }
};
const oldFetchTeams = ({ email, isAdmin }) => async dispatch => {
  if (isAdmin) {
    return dispatch(fetchAllTeams());
  } else {
    return userTeams;
  }
};

const loadGenericTeamDetails = async (email, isAdmin, dispatch, myTeamOptions) => {
  const teamDetails = await dispatch(fetchTeams({ isAdmin, email, myTeamOptions })) || [];
  const tDetails = Array.isArray(teamDetails) ? teamDetails : [];
  const teams = tDetails.map(teamDetail => teamDetail.teamId || teamDetail.id);
  return {teams, teamDetails: tDetails};
}
const oldLoadGenericTeamDetails = async (email, isAdmin, dispatch) => {
  const teamDetails = await dispatch(oldFetchTeams({ isAdmin, email })) || [];
  const teams = teamDetails.map(teamDetail => teamDetail.teamId);
  return {teams, teamDetails};
}

const loadGenericStoryDetails = async (teamDetails, url, request, dataField, serviceId, description, maxCheckoutQuantity) => {
  const response = await axios.post(url, request, { headers: authHeaders() });

const teamUserStoryDetails=response.data.userStoryScenarioDetails;

if (dataField === 'teamUserStoryScenarioDetails' && _.isEmpty(teamUserStoryDetails)) {
  const errorMessage =  _.get(response, 'data.responseDescription', '');
  return {payload: [], errorMessage};
}
  let payload = [];
  if (teamUserStoryDetails) {
    for (const team in teamUserStoryDetails) {
      let teamData = teamUserStoryDetails[team].map((_item)=>{
        return {
          teamId: _item.teamId,
          teamName: _item.teamName,
          serviceId: _item[serviceId],
          description: _item[description],
          serviceProductCode: _item.serviceProductCode ? _item.serviceProductCode : '',
          maxCheckoutQuantity : _item[maxCheckoutQuantity],
          quantity: _item.quantity
        }
      });
      payload = [...payload, ...teamData];
    }

  }
return {payload, errorMessage : ''};
}

const oldLoadGenericStoryDetails = async (teamDetails, url, request, dataField, serviceId, description, maxCheckoutQuantity) => {
  const response = await axios.post(url, request, { headers: authHeaders() });

const teamUserStoryDetails = _.get(response, `data.${dataField}`, []);

if (dataField === 'teamUserStoryScenarioDetails' && _.isEmpty(teamUserStoryDetails)) {
  const errorMessage =  _.get(response, 'data.responseDescription', '');
  return {payload: [], errorMessage};
}

const payload = _.flatten(
  teamDetails.map(data => {
    if (teamUserStoryDetails[data.teamId]) {
      return teamUserStoryDetails[data.teamId].map(team => {
        return {
          teamId: data.teamId,
          teamName: data.teamName,
          serviceId: team[serviceId],
          description: team[description],
          serviceProductCode: team.serviceProductCode ? team.serviceProductCode : '',
          maxCheckoutQuantity : team[maxCheckoutQuantity],
          quantity: team.quantity

        };
      });
    } else {
      return {
        teamId: data.teamId,
        teamName: data.teamName,
        serviceId: "",
        description: "",
        serviceProductCode: "",
        maxCheckoutQuantity : "",
        quantity: ""
      };
    }
  })
);
return {payload, errorMessage : ''};
}


export const loadTeamFilterDetails = ({
  scopes,
  email,
  isAdmin,
  loggedInUser
}) => async  (dispatch, getState)  => {
  dispatch({ type: types.LOAD_TEAM_FILTERS_PENDING, payload: true });
  try {
      let {teams, teamDetails} = await loadGenericTeamDetails(loggedInUser.userName, isAdmin, dispatch, loggedInUser.myTeamOptions);
      const selectedEnvValue = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
       //logged in user modified key value as teamDetails
      let logInUser = {loggedInUser}
      logInUser['teamId'] = loggedInUser.userName
      logInUser["teamName"] = loggedInUser.name
      teamDetails = [logInUser, ...teamDetails]
      teams = [...teams]
      const request = { teamIds: teams ,environment: selectedEnvValue };
      const url = `${API_URL_GATEWAY}/tdm/data/team/fetch/services`
      let {payload} = await loadGenericStoryDetails(teamDetails, url, request, 'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
      let userIds = [];
      userIds.push(loggedInUser.userName);
      try{
        const userPayload = await loadGenericStoryDetails(teamDetails, url, { userIds , 'environment': selectedEnvValue} ,'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
        if(!userPayload.errorMessage){
          const userService = userPayload.payload.filter(user=> user.teamId === loggedInUser.userName);
          if(payload.length>0){
            if(payload[0].teamId === loggedInUser.userName){
              payload.shift();
              userService.push(...payload)
              payload = userService;
            }
          }
        }else{
          console.error("userPayload loadTeamFilterDetails", userPayload);
        }
      }catch (error){
        console.error("error loadTeamFilterDetails", error);
      }
      dispatch({ type: types.LOAD_TEAM_FILTERS_SUCCESS, payload });
    } catch (error) {
      const payload = _.get(error, 'response.data.responseDescription', '');
      dispatch({ type: types.LOAD_TEAM_FILTERS_FAILED, payload });
      console.error(error);
    }
};

export const oldLoadTeamFilterDetails = ({
  scopes,
  email,
  isAdmin,
  loggedInUser
}) => async  (dispatch, getState)  => {
  dispatch({ type: types.LOAD_TEAM_FILTERS_PENDING, payload: true });
  try {
      let {teams, teamDetails} = await oldLoadGenericTeamDetails(loggedInUser.userName, isAdmin, dispatch);
      const selectedEnvValue = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
       //logged in user modified key value as teamDetails
      let logInUser = {loggedInUser}
      logInUser['teamId'] = loggedInUser.userName
      logInUser["teamName"] = loggedInUser.name
      teamDetails = [logInUser, ...teamDetails]
      teams = [...teams]
      const request = { teamIds: teams ,environment: selectedEnvValue };
      const url = `${API_URL_GATEWAY}/tdm/data/team/fetch/services`
      let {payload} = await oldLoadGenericStoryDetails(teamDetails, url, request, 'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
      let userIds = [];
      userIds.push(loggedInUser.userName);
      try{
        const userPayload = await oldLoadGenericStoryDetails(teamDetails, url, { userIds , 'environment': selectedEnvValue} ,'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
        if(!userPayload.errorMessage){
          const userService = userPayload.payload.filter(user=> user.teamId === loggedInUser.userName);
          if(payload.length>0){
            if(payload[0].teamId === loggedInUser.userName){
              payload.shift();
              userService.push(...payload)
              payload = userService;
            }
          }
        }else{
          console.error("userPayload loadTeamFilterDetails", userPayload);
        }
      }catch (error){
        console.error("error loadTeamFilterDetails", error);
      }
      dispatch({ type: types.LOAD_TEAM_FILTERS_SUCCESS, payload });
    } catch (error) {
      const payload = _.get(error, 'response.data.responseDescription', '');
      dispatch({ type: types.LOAD_TEAM_FILTERS_FAILED, payload });
      console.error(error);
    }
};

export const loadCustomTeamFilterDetails = ({
  scopes,
  email,
  isAdmin,
  loggedInUser,
  allTeams
},showAllTeamDetails) => async (dispatch,getState) => {
  dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_PENDING, payload: true });
  try {
      let teamDetails = allTeams;
      const selectedEnvironment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
      const url = `${API_URL_GATEWAY}/tdm/standarddatabank/team/fetch/userstories`;
      let userIds = [];
      userIds.push(loggedInUser.userName);
      const teamIds = _.map(teamDetails, 'teamId');
      let request;
      if(showAllTeamDetails){
        request = { teamIds , 'environment': selectedEnvironment};
      }else{
       request= { userIds , 'environment': selectedEnvironment};
      }
      const {payload, errorMessage} = await loadGenericStoryDetails([...teamDetails], url, request ,'userStoryScenarioDetails', 'userStory', 'scenarioName');
      if (!_.isEmpty(errorMessage) && _.isEmpty(payload)) {
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload: errorMessage });
        return;
      }
      dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_SUCCESS, payload: payload });
    } catch(error) {
        const payload =  _.get(error, 'response.data.responseDescription', '');
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload });
        console.error(error);
    }
};

export const oldLoadCustomTeamFilterDetails = ({
  scopes,
  email,
  isAdmin,
  loggedInUser
}) => async (dispatch,getState) => {
  dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_PENDING, payload: true });
  try {
      let {teams, teamDetails} = await oldLoadGenericTeamDetails(loggedInUser.userName, isAdmin, dispatch,);
       //logged in user modified key value as teamDetails
      let logInUser = {loggedInUser}
      logInUser['teamId'] = loggedInUser.userName
      logInUser["teamName"] = loggedInUser.name
      teamDetails = [logInUser, ...teamDetails]
      teams = [...teams]
      const selectedEnvironment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
      const request = { teamIds: teams , environment: selectedEnvironment};
      const url = `${API_URL_GATEWAY}/tdm/data/team/fetch/userstories`
      const {payload, errorMessage }= await oldLoadGenericStoryDetails(teamDetails, url, request, 'userStoryScenarioDetails', 'userStory', 'scenarioName');
      let userIds = [];
      userIds.push(loggedInUser.userName);
      try{
        const userPayload = await oldLoadGenericStoryDetails(teamDetails, url, { userIds , 'environment': selectedEnvironment} ,'userStoryScenarioDetails', 'userStory', 'scenarioName');
        if(!userPayload.errorMessage){
          const userService = userPayload.payload.filter(user=> user.teamId === loggedInUser.userName);
          if(payload.length>0){
            if(payload[0].teamId === loggedInUser.userName){
              payload[0] = userService[0];
            }
          }
        }else{
          console.error("userPayload loadCustomTeamFilterDetails", userPayload);
        }
      }catch (error){
        console.error("error loadCustomTeamFilterDetails", error);
      }
      if (!_.isEmpty(errorMessage) && _.isEmpty(payload)) {
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload: errorMessage });
        return;
      }

      dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_SUCCESS, payload });
    } catch(error) {
        const payload =  _.get(error, 'response.data.responseDescription', '');
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload });
        console.error(error);
    }
};

export const selectCustomTeamFilter = (selectedCustomFilter) => async dispatch => {
  try {
    dispatch({ type: types.SELECT_CUSTOM_TEAM_FILTER, payload: selectedCustomFilter });
  } catch (e) {
    console.error(e);
  }
};

export const selectFilter = (selectedFilters) => async dispatch => {
  try {
    dispatch({ type: types.SELECT_TEAM_FILTER,  payload: selectedFilters });
  } catch (e) {
    console.error(e);
  }
};

export const checkoutTeam = request => async dispatch => {
  try {
    dispatch({ type: types.TEAM_CHECKOUT_PENDING, payload: true });
    dispatch({ type: types.FORCE_FEEDBACK, payload: false });
    const response = await axios.post(
      `${API_URL_GATEWAY}/tdm/standarddatabank/checkout/assets`,
      request,
      { headers: authHeaders() }
    );

    if (response.data.responseStatus === "Success") {
      dispatch({ type: types.TEAM_CHECKOUT_SUCCESS });
      dispatch({ type: types.FORCE_FEEDBACK, payload: true });
      return { checkoutId: response.data.checkoutId, }
    } else {
      dispatch({ type: types.TEAM_CHECKOUT_FAILED });
      dispatch({ type: types.FORCE_FEEDBACK, payload: false });
      const errorMsg = response.data.responseDescription
      displayErrorNew({
        title: "Checkout Failed",
        subTitle: "An error occured while Checking out!",
        errorMsg
      });
    }
  } catch (e) {
    console.error(e);
    dispatch({ type: types.TEAM_CHECKOUT_FAILED });
    dispatch({ type: types.FORCE_FEEDBACK, payload: false });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.responseDescription;
    else errorMsg = "Network connectivity issues, please try again later.";

    displayErrorNew({
      title: "Checkout Error",
      subTitle: "An error occured while Checking Out.",
      errorMsg

    });
  }
};

export const oldCheckoutTeam = request => async dispatch => {
  try {
    dispatch({ type: types.TEAM_CHECKOUT_PENDING, payload: true });
    dispatch({ type: types.FORCE_FEEDBACK, payload: false });
    const response = await axios.post(
      `${API_URL_GATEWAY}/tdm/checkout/asset`,
      request,
      { headers: authHeaders() }
    );

    if (response.data.responseStatus === "Success") {
      dispatch({ type: types.TEAM_CHECKOUT_SUCCESS });
      dispatch({ type: types.FORCE_FEEDBACK, payload: true });
      return { checkoutId: response.data.checkoutId, }
    } else {
      dispatch({ type: types.TEAM_CHECKOUT_FAILED });
      dispatch({ type: types.FORCE_FEEDBACK, payload: false });
      const errorMsg = response.data.responseDescription
      displayErrorNew({
        title: "Checkout Failed",
        subTitle: "An error occured while Checking out!",
        errorMsg
      });
    }
  } catch (e) {
    console.error(e);
    dispatch({ type: types.TEAM_CHECKOUT_FAILED });
    dispatch({ type: types.FORCE_FEEDBACK, payload: false });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.responseDescription;
    else errorMsg = "Network connectivity issues, please try again later.";

    displayErrorNew({
      title: "Checkout Error",
      subTitle: "An error occured while Checking Out.",
      errorMsg

    });
  }
};

export const resetCheckout = () => async dispatch => {
  try {
    dispatch({ type: types.TEAM_CHECKOUT_RESET });
  } catch (e) { console.error(e); }
}

export const checkoutServiceFilters = request => async (dispatch,getState) => {
  try {
    const environment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
    dispatch({ type: types.TEAM_SERVICE_FILTERS_PENDING, payload: true });
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/checkout/filter?serviceId=${request}&env=${environment}`,{ headers: authHeaders() });

    if (response.data.responseStatus === "Success") {
      dispatch({ type: types.TEAM_SERVICE_FILTERS_SUCCESS, payload: response.data.resourceFilterData });
      return response.data.resourceFilterData;
    } else {
      const errorMsg = response.data.responseDescription
      dispatch({ type: types.TEAM_SERVICE_FILTERS_FAILED, payload: errorMsg });
    }
  } catch (e) {
    const payload =  _.get(e, 'response.data.responseDescription', '');
    dispatch({ type: types.TEAM_SERVICE_FILTERS_FAILED, payload });
    console.error(e);
  }
}
export const allocateServiceFilters = request => async (dispatch,getState) => {
  try {
    const environment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
    dispatch({ type: types.TEAM_ALLOCATE_SERVICE_FILTERS_PENDING, payload: true });
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/checkout/filter?serviceId=${request.value}&env=${environment}`,{ headers: authHeaders() });

    if (response.data.responseStatus === "Success") {
      dispatch({ type: types.TEAM_ALLOCATE_SERVICE_FILTERS_SUCCESS, payload: response.data.resourceFilterData });
      return response.data.resourceFilterData;
    } else {
      const errorMsg = response.data.responseDescription
      dispatch({ type: types.TEAM_ALLOCATE_SERVICE_FILTERS_FAILED, payload: errorMsg });
    }
  } catch (e) {
    const payload =  _.get(e, 'response.data.responseDescription', '');
    dispatch({ type: types.TEAM_ALLOCATE_SERVICE_FILTERS_FAILED, payload });
    console.error(e);
  }
}
export const checkoutServiceFilterValue = request => async dispatch => {
  try {
    dispatch({ type: types.TEAM_SERVICE_FILTERS_VALUE_PENDING, payload: true });
    const response = await axios.post(`${API_URL_GATEWAY}/tdm/checkout/filter/value`,request,{ headers: authHeaders() });

    if (response.data.responseStatus === "Success") {
      dispatch({ type: types.TEAM_SERVICE_FILTERS_VALUE_SUCCESS, payload: response.data});
    } else {
      const errorMsg = response.data.message ? response.data.message : response.data.responseDescription;
      dispatch({ type: types.TEAM_SERVICE_FILTERS_VALUE_FAILED, payload: errorMsg });
    }
  } catch (e) {
    const payload =  _.get(e, 'response.data.responseDescription', '');
    dispatch({ type: types.TEAM_SERVICE_FILTERS_VALUE_FAILED, payload });
    console.error(e);
  }
}

export const allocateToTeam = (request,selectedToggleButtonValue)=> async dispatch => {
  try {
    dispatch({ type: types.TEAM_ALLOCATION_PENDING, payload: true });
      const url = ( selectedToggleButtonValue ==="Custom")?`${API_URL_GATEWAY}/tdm/user/team/allocation` : `${API_URL_GATEWAY}/tdm/allocation/dcp/team/allocation`;
    const response = await axios.post(
      url,
      request,
      { headers: authHeaders() }
    );
    const status = response.data.responseStatus;
    const responseDescription = response.data.responseDescription;
    if (status == "Success") {
      dispatch({ type: types.TEAM_ALLOCATION_SUCCESS });
      return { responseDescription };
    } else {
      const errorMsg = (CURRENT_ENV === "1tdmPrd") ? responseDescription : error.message[0];
      dispatch({ type: types.TEAM_ALLOCATION_FAILED });
      displayErrorNew({
        title: "Allocation Error",
        subTitle: "An error occured while allocating to team ",
        errorMsg
      });
    }

  } catch (e) {
    console.log("e----> ", e.response)
    console.log("e----> ", e.response.data)
    console.error(e);
    dispatch({ type: types.TEAM_ALLOCATION_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.responseDescription;
    else errorMsg = "Network connectivity issues, please try again later.";
    displayErrorNew({
      title: "Allocation Error",
      subTitle: "An error occured while allocating to team ",
      errorMsg
    });
  }
};

/*
 Author : Sourav
 getUsers: get all active users used teams action type bcz,
          using it for same component where team data will be changed by user data myteamtable.
 start of getUsers
*/
export const getUsers = (userList) => async dispatch => {
  try {
    dispatch({ type: types.FETCH_ALL_TEAMS_PENDING, payload: true });
    if (userList?.length) {
      const updatedUsers = userList.map((value) => {
          value['teamName'] = `${value.lastName}, ${value.firstName}`; // changing key "name" to "teamName" to reuse loadGenricStoryDetails method
          value['teamDisplayName'] = `${value.lastName}, ${value.firstName}`;// to load user name in checkout history page dropdown list make adding
          //teamDisplayName key same as name property to getting loaded in dropdown.
          return value;
      });
      dispatch({
        type: types.FETCH_ALL_TEAMS_SUCCESS,
        payload: updatedUsers
      });
      return updatedUsers;
    } else {
      dispatch({ type: types.FETCH_ALL_TEAMS_FAILED, payload: "Failed to retrieve users" });
      console.error("Failed to retrieve users");
    }
  } catch (error) {
    dispatch({ type: types.FETCH_ALL_TEAMS_FAILED, error });
    console.error(error);
  }
};
//End of getUsers

/*
 Author : Sourav
 loadUserFilterDetails:
*/
export const loadUserFilterDetails = ({userList}) => async  (dispatch,getState)  => {

  try {
      dispatch({ type: types.LOAD_TEAM_FILTERS_PENDING, payload: true });
      const users = await dispatch(getUsers(userList));
      const userIds = _.map(users, 'userId');
      const url = `${API_URL_GATEWAY}/tdm/data/team/fetch/services`;
      const environment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
      const {payload, errorMessage} = await loadGenericStoryDetails(users, url, { userIds , environment} ,'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
      dispatch({ type: types.LOAD_TEAM_FILTERS_SUCCESS, payload });
    } catch (error) {
      const payload = _.get(error, 'response.data.responseDescription', '');
      dispatch({ type: types.LOAD_TEAM_FILTERS_FAILED, payload });
      console.error(error);
    }
};
//End of loadUserFilterDetails

/*
 Author : Sourav
 loadCustomUserFilterDetails:
*/
export const loadCustomUserFilterDetails = (userList) => async (dispatch,getState) => {

  try {
      dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_PENDING, payload: true });
      const users = await dispatch(getUsers(userList));
      const userIds = _.map(users, 'userId');
      const selectedEnvironment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
      const url = `${API_URL_GATEWAY}/tdm/data/team/fetch/userstories`
      const request = { userIds: userIds , environment: selectedEnvironment};
      const {payload, errorMessage }= await loadGenericStoryDetails(users, url, request, 'userStoryScenarioDetails', 'userStory', 'scenarioName');

      if (!_.isEmpty(errorMessage) && _.isEmpty(payload)) {
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload: errorMessage });
        return;
      }

      dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_SUCCESS, payload });
    } catch(error) {
        const payload =  _.get(error, 'response.data.responseDescription', '');
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload });
        console.error(error);
    }
};
export const oldLoadCustomUserFilterDetails = () => async (dispatch,getState) => {

  try {
      dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_PENDING, payload: true });
      const users = await dispatch(getUsers());
      const userIds = _.map(users, 'id');
      const selectedEnvironment = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
      const url = `${API_URL_GATEWAY}/tdm/data/team/fetch/userstories`
      const request = { userIds: userIds , environment: selectedEnvironment};
      const {payload, errorMessage }= await oldLoadGenericStoryDetails(users, url, request, 'userStoryScenarioDetails', 'userStory', 'scenarioName');

      if (!_.isEmpty(errorMessage) && _.isEmpty(payload)) {
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload: errorMessage });
        return;
      }

      dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_SUCCESS, payload });
    } catch(error) {
        const payload =  _.get(error, 'response.data.responseDescription', '');
        dispatch({ type: types.LOAD_CUSTOM_TEAM_FILTERS_FAILED, payload });
        console.error(error);
    }
};
// End of loadCustomUserFilterDetails

export const fetchClassTechOptions = () => async dispatch => {
  try {
    const url = `${API_URL_GATEWAY}/tdm/data/fetch/nbn/class/technology/details`
    const response = await axios.get(url, { headers: authHeaders()});

    const payload = _.sortBy(response.data.classDetails, 'classId');
    dispatch({ type: types.TEAM_CLASS_TECH_SUCCESS, payload });
    return payload;
    } catch (e) {
    console.error(e);
    dispatch({ type: types.TEAM_CLASS_TECH_FAILED });
  }
};

export const getTeamsForAllocation = () => async dispatch  => {
  try {
    let teams = [];
    let e = null;
    const response = await axios.get(
      `${API_URL_GATEWAY}/tdm/data/team/active/details`,
      { headers: authHeaders() }
    );

    if (response.data.status === 200) {
      teams = response.data.teams;
    } else {
      e = response.data.responseDescription;
      console.error(response.data.responseDescription);
    }
    return {teams, e};
  } catch (e) {
    console.error(e);
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.responseDescription;
    else errorMsg = "Network connectivity issues, please try again later.";
    console.error("getTeamsForAllocation myteam",errorMsg);
  }
};

export const getUsersForAllocation = (usersList) => async dispatch => {
  try {
    let users = [];
    let error = null;
    if (usersList?.length) {
      // changing key "name" to "teamName" to reuse
      users = usersList.map((value) => {
          value['teamName'] = `${value.lastName}, ${value.firstName}`;
          value['teamDisplayName'] = `${value.lastName}, ${value.firstName}`;
          return value;
      });
    } else {
      error = "Failed to retrieve users";
      console.error("No users for allocation");
    }
    return {users, error};
  } catch (error) {
    console.error("getUsersForAllocation myteam",error);
  }
};

export const getTeamSource = (selectedEnvValue, selectedToggle) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_PENDING});
    let url = null;
    let id = null;
    let teamService = {};
    const environment = selectedEnvValue || EnvirnomentLabels.INT2;
    const {teams, error} = await dispatch(getTeamsForAllocation());
    if(teams.length === 0){
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
      return;
    }
    const teamIds = _.map(teams, 'id');
    const request = {teamIds, environment};
    if(selectedToggle === 'Standard'){
      url = `${API_URL_GATEWAY}/tdm/data/team/fetch/services`;
      teamService = await loadGenericStoryDetails(teams, url, request, 'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
      id = teams.find((team)=>team.teamName === 'TEAM_CATALYST');
    }else if(selectedToggle === 'Custom'){
      url = `${API_URL_GATEWAY}/tdm/data/team/fetch/userstories`;
      teamService = await loadGenericStoryDetails(teams, url, request, 'userStoryScenarioDetails', 'userStory', 'scenarioName');
      id = teams.find((team)=>team.teamName === 'TDM OPS');
    }

    if(teamService.payload.length>0){
      let serviceIds = _.uniq(_.map(teamService.payload, 'serviceId'))
      serviceIds.map((sId)=>typeof(sId) === 'number'? serviceIds.sort((id1, id2)=>id1 - id2):serviceIds.sort());
      const serviceIdOptions = serviceIds.map(serviceId=>({value: serviceId, label: serviceId}));
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_SUCCESS, payload: {service:teamService.payload, serviceIdOptions, defaultSourceId:id.id}});
    }else{
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: teamService.errorMessage});
    }
  } catch (error) {
    const payload =  _.get(error, 'response.data.responseDescription', '');
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload});
    console.error(error);
  }
}

export const oldGetTeamSource = (selectedEnvValue, selectedToggle) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_PENDING});
    let url = null;
    let id = null;
    let teamService = {};
    const environment = selectedEnvValue || EnvirnomentLabels.INT2;
    const {teams, error} = await dispatch(getTeamsForAllocation());
    if(teams.length === 0){
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
      return;
    }
    const teamIds = _.map(teams, 'id');
    const request = {teamIds, environment};
    if(selectedToggle === 'Standard'){
      url = `${API_URL_GATEWAY}/tdm/data/team/fetch/services`;
      teamService = await oldLoadGenericStoryDetails(teams, url, request, 'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
      id = teams.find((team)=>team.teamName === 'TEAM_CATALYST');
    }else if(selectedToggle === 'Custom'){
      url = `${API_URL_GATEWAY}/tdm/data/team/fetch/userstories`;
      teamService = await oldLoadGenericStoryDetails(teams, url, request, 'userStoryScenarioDetails', 'userStory', 'scenarioName');
      id = teams.find((team)=>team.teamName === 'TDM OPS');
    }

    if(teamService.payload.length>0){
      let serviceIds = _.uniq(_.map(teamService.payload, 'serviceId'))
      serviceIds.map((sId)=>typeof(sId) === 'number'? serviceIds.sort((id1, id2)=>id1 - id2):serviceIds.sort());
      const serviceIdOptions = serviceIds.map(serviceId=>({value: serviceId, label: serviceId}));
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_SUCCESS, payload: {service:teamService.payload, serviceIdOptions, defaultSourceId:id.id}});
    }else{
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: teamService.errorMessage});
    }
  } catch (error) {
    const payload =  _.get(error, 'response.data.responseDescription', '');
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload});
    console.error(error);
  }
}

export const getUserSource = (selectedEnvValue, selectedToggle, userList) => async (dispatch) => {
  let userService = {};
  try {
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_PENDING});
    let url = null;

    const environment = selectedEnvValue || EnvirnomentLabels.INT2;
    const {users, error} = await dispatch(getUsersForAllocation(userList));
    if(users.length === 0){
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
      return;
    }
    const userIds = _.map(users, 'userId');
    const request = {userIds, environment};
    if(selectedToggle === 'Standard'){
      url = `${API_URL_GATEWAY}/tdm/data/team/fetch/services`;
      userService = await loadGenericStoryDetails(users, url, request,'services', 'id', 'serviceDescription', 'maxCheckoutQuantity');
    }else if(selectedToggle === 'Custom'){
      url = `${API_URL_GATEWAY}/tdm/data/team/fetch/userstories`;
      userService = await loadGenericStoryDetails(users, url, request, 'userStoryScenarioDetails', 'userStory', 'scenarioName');
    }
    if(userService.payload.length>0 ){
      let serviceIds = _.uniq(_.map(userService.payload, 'serviceId'))
      serviceIds.map((sId)=>typeof(sId) === 'number'? serviceIds.sort((id1, id2)=>id1 - id2):serviceIds.sort());
      const serviceIdOptions = serviceIds.map(serviceId=>({value: serviceId, label: serviceId}));
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_SUCCESS, payload: {service:userService.payload, serviceIdOptions}});
    }else{
      dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: userService.errorMessage });
    }
  } catch (error) {
    const payload =  _.get(error, 'response.data.responseDescription', '');
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload });
    console.error(error);
  }
}

export const fetchExchangeIDs = (env) => async (dispatch) => {
  try{
    dispatch({ type: types.LOAD_ALLOCATE_EXCHANGE_ID_PENDING, payload: [] });
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/user/exchanges/${env}`, { headers: authHeaders() });
    dispatch({ type: types.LOAD_ALLOCATE_EXCHANGE_ID_SUCCESS, payload: response.data});
  }
  catch(e)
  {
    dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: [] });
  }

}
export const getTeamUserTarget = (targetUserToggle, selectedSource, userList) => async (dispatch) => {
  try {
    dispatch({ type: types.LOAD_ALLOCATE_TARGET_PENDING});
    let targetOptions = [];
    if(targetUserToggle){
      const {users, error} = await dispatch(getUsersForAllocation(userList));
      const prepareOptions =  selectedSource?.value && selectedSource.label ? users.filter((sd)=> sd.userId !== selectedSource.value && sd.teamName !== selectedSource.label):[];
      targetOptions = prepareOptions.map(option=>({ value: option.userId, label: option.teamName }));
      if(users.length === 0){
        dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
        return;
      }
    }else{
      const {teams, error} = await dispatch(getTeamsForAllocation());
      const prepareOptions = selectedSource?.value && selectedSource.label ? teams.filter((sd)=> sd.teamId !== selectedSource.value && sd.teamName !== selectedSource.label):[];
      targetOptions = prepareOptions.map(option=>({ value: option.teamId, label: option.teamName }));
      if(teams.length === 0){
        dispatch({ type: types.LOAD_ALLOCATE_SOURCE_FAILED, payload: error})
        return;
      }
    }
    dispatch({ type: types.LOAD_ALLOCATE_TARGET_SUCCESS, payload: {targetOptions}});
  } catch (error) {
    const payload =  _.get(error, 'response.data.responseDescription', '');
    dispatch({ type: types.LOAD_ALLOCATE_TARGET_FAILED, payload});
    console.error(error);
  }
}
