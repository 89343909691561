import React, {Component} from 'react'
import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #fa6800 0px, #c75707 100%)',
}
const UtilityTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-green"
        data-size="medium"
        to="/data/utility"
        data-effect="hover-zoom-up">
        <div class="slide-front">
            <span className="td-icon-md icon-leave-it-with-us icon"></span>
            <span className="branding-bar">Utilities</span>
        </div>
        <div
            class="slide-back d-flex flex-justify-center flex-align-center p-4 op-orange">
            <p class="text-center">
                Utilities
            </p>
        </div>
    </LinkTile>
)

UtilityTile.propTypes = {}

export default UtilityTile;