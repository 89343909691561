import { isLength, isNumeric } from 'validator';
import swal from 'sweetalert2';

export function tConnectModel(navCallback, fetchCustomerData, env) {
    swal.fire({
        title: 'T-Connect User',
        text: 'Please enter a valid CIDN',
        input: 'text',
        showCancelButton: true,
        inputValidator: (value) => {
            if (!value) {
                return 'Please enter a valid CIDN!'
            } else if (!isNumeric(value)) {
                return 'CIDN must contain numbers only'
            } else if (!isLength(value, { min: 9, max: 11 })) {
                return 'Length of CIDN must be between 9 - 11 numbers'
            }
        },
        preConfirm: (cidn) => {
            swal.showLoading()
            return fetchCustomerData(env, cidn)
                .then(response => {
                    if ('Fail' === response.responseStatus) {
                        swal.showValidationMessage("Unable to find account with given id");
                      }else{
                          navCallback();
                      }
                })
                .catch(err => {
                    console.log(err)
                    swal.showValidationMessage("Unable to find account with given id");
                })
                .finally(() => {
                    swal.hideLoading();
                })
        }
    })
}