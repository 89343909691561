import React, { useEffect, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import {
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    TextField
} from '@material-ui/core';

const CheckoutModal = (props) => {
    const [syntheticDataList, setSyntheticDataList] = useState([]);
    const [isQtyInvalid, setIsQtyInvalid] = useState(false);
    const [listDataTypes, setListDataTypes] = useState([]);
    const [qtyValue, setQtyValue] = useState('');
    const [datatype, setDatatype] = useState('');
    const {
        open = false,
        checkoutData,
        checkoutQty,
        handleClose,
        handleSubmit,
        handleCheckoutQty,
    } = props;
    const { tagQtys = [], quantity = 0 } = checkoutData;
    const tags = tagQtys.map(tag => tag?.dataType);

    useEffect(() => {
        setListDataTypes([...tags])
        return () => {
            setSyntheticDataList([]);
        }
    }, []);

    const handleAddData = () => {
        if (qtyValue && datatype) {
            setSyntheticDataList([...syntheticDataList, { name: datatype, value: qtyValue }])
        }
    }

    useEffect(() => {
        if (qtyValue && datatype) {
            let removeDataType = listDataTypes.filter((_item) => _item != datatype);
            setListDataTypes(removeDataType);
        }
    }, [syntheticDataList]);

    useEffect(() => {
        setQtyValue('');
        setDatatype('');
    }, [listDataTypes]);

    const maxQty = tagQtys?.find(t => t?.dataType === datatype)?.quantity || 0;

    const handleRemoveData = (data) => {
        let cpySyntheticDataList = [...syntheticDataList];
        let removeData = cpySyntheticDataList.filter((_item) => _item.name != data.name);
        setSyntheticDataList(removeData);
        setListDataTypes([...listDataTypes, data.name])
    }

    const checkQtyValidation = (data) => {
        setIsQtyInvalid(data > maxQty);
    }

    return (
        <Dialog maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle style={{ "textAlign": "center" }}>Checkout Synthetic Data</DialogTitle>
            <Divider />
            <DialogContent style={{ width: "640px" }}>
                <div class="container mt-5">
                    <div class={quantity === 0 ? "col-5 mt-2 customQtyErrorHeader" : "col-5 mt-2 customQtyHeader"}>
                        Available Datatype Quantity : {maxQty}
                    </div>
                    <div class="row">
                        <div class="col-5 customHeaders">Datatypes</div>
                        <div class="col-5 customHeaders" style={{"marginLeft": "-12px"}}>Quantity</div>
                    </div>
                    {syntheticDataList.length > 0 ?
                        <div class="row">
                            {syntheticDataList.map((filterData, index) => {
                                return (
                                    <div class="row" key={index}>
                                        {filterData.name !== "" && filterData.name !== undefined ?
                                            <><div class="col-5 ml-1">
                                                <TextField variant="outlined" disabled
                                                    value={filterData.name} className="filterNameLabel" />
                                            </div>
                                                <div class="col-5 ml-2">
                                                    <TextField variant="outlined" disabled
                                                        value={filterData.value} className="filterValueLabel" />
                                                </div>
                                                <div class="col-1 ml-n1">
                                                    <IconButton 
                                                        color="secondary" 
                                                        onClick={()=>handleRemoveData(filterData)} 
                                                        size="medium"
                                                        style={{height: '42px', width: "42px"}}
                                                    > 
                                                        <CancelIcon style={{fontSize: '30px'}}/>
                                                    </IconButton>
                                                </div></> : <></>}
                                    </div>
                                )
                            })}

                        </div> : <></>}
                    {listDataTypes.length > 0 &&
                        <div class="row" style={{ "width": "512px" }}>
                            <div class="col-5">
                                <Autocomplete
                                    options={listDataTypes}
                                    getOptionLabel={(option) => option}
                                    id="customdataFiltersAutoComplete"
                                    value={datatype}
                                    fullWidth
                                    onChange={(event, value) => setDatatype(value)}
                                    autoComplete
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            className="customAutoComplete"
                                            placeholder="Select data type"
                                        />
                                    )}
                                /></div>
                            <div class="col-5 ml-3">
                                <TextField variant="outlined" placeholder="Enter quantity"
                                    type="number"
                                    value={qtyValue}
                                    className="filterValueLabel"
                                    fullWidth
                                    onChange={(e) => {
                                        setQtyValue(e.target.value);
                                        checkQtyValidation(e.target.value);
                                    }}
                                    inputProps={{min: 1, max: maxQty }}
                                    helperText={isQtyInvalid ? `Please select a valid quantity between 1 and ${maxQty}` : null}
                                    error={isQtyInvalid}
                                />
                            </div>
                            <div class="col-1 ml-n1">
                                <IconButton color="primary" onClick={handleAddData} disabled={isQtyInvalid || !qtyValue || !datatype} size="medium" style={{height: '42px', width: "42px"}}> 
                                    <AddIcon style={{fontSize: '30px'}}/>
                                </IconButton>
                            </div>
                        </div>
                    }
                </div>
                <div style={{ marginLeft: "330px", marginTop: "12px" }}>
                    <Button color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            let syntheticData = syntheticDataList.map((_item) => {
                                return {
                                    dataType: _item.name,
                                    quantity: _item.value
                                }
                            });
                            handleSubmit(syntheticData)
                        }}
                        disabled={!syntheticDataList.length > 0 || isQtyInvalid}
                    >
                        Checkout
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default CheckoutModal;