//to be removed after integrating
import React, {Component} from 'react'
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import config from '../../../../config';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip'

import { withRouter } from 'react-router';

import {Button} from 'reactstrap';

import {createCustomerBktSalesforce} from '../../../../actions/dataActions';

import {checkProfileThenCallAction} from './DataViewCustomerSf';

class DataViewCustomerSfRow extends Component {
    static propTypes = {
        customer: PropTypes.object.isRequired
    }

    containsItems = (items) => (items && items.length > 0);

    flexStyles = {
        display: "inline-flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        padding: "5px"
    }

    activeProfile = ({environment}) => {
        const {available, active} = this.props;
        return available[active[environment]];
    }

    render() {
        const {
            salesforceId,
            salesforceUrl,
            sfContacts,
            sfBillingAccounts,
            sfSites,
            sfOpps
        } = this.props.customer;
        return (
            <tr>
                <td>
                    <a
                        href={`${salesforceUrl}/lightning/r/Account/${salesforceId}/view`}
                        target="_blank"
                        title="View in Salesforce">{salesforceId}</a>
                </td>
                <td>
                    {(this.containsItems(sfContacts))
                        ? sfContacts.map(contact => {
                            const {id, type, firstName, lastName} = contact;
                            return <React.Fragment key={id}>
                                <span
                                    style={{
                                    "display": "inline-flex",
                                    "alignItems": "center",
                                    "cursor": "default",
                                    "width": "100%"
                                }}>
                                    <span
                                        style={{
                                        "width": "60%"
                                    }}>
                                        {firstName}{' '}{lastName}
                                    </span>
                                    <span
                                        style={{
                                        "width": "40%"
                                    }}>
                                        <span data-tip={type} data-for={id} data-tip-disable={false}>
                                            <Avatar
                                                name={type}
                                                style={{
                                                "margin": "10px"
                                            }}
                                                size={25}
                                                round={false}
                                                textSizeRatio={2}></Avatar><ReactTooltip type="dark" effect="solid" place="right" id={id}/>
                                        </span>
                                    </span>
                                </span>

                            </React.Fragment>
                        })
                        : <p className="text-muted">No contacts exist</p>}
                </td>
                <td>
                    {(this.containsItems(sfBillingAccounts))
                        ? sfBillingAccounts.map(ba => {
                            return (
                                <React.Fragment key={ba}>
                                    <p>{ba}</p>
                                    {/* <Avatar style={{"margin":"10px"}} size={25} round={false} value={`${<i class="fa fa-building-o" aria-hidden="true"></i>}`}textSizeRatio={2}></Avatar> */}
                                </React.Fragment>
                            )
                        })
                        : <p className="text-muted">No billing accounts exist</p>}
                </td>
                <td>
                    {(this.containsItems(sfSites))
                        ? sfSites.map(site => {
                            const {id, name, addressId, addressName, adborId} = site;
                            return (
                                <span key={id}>
                                    <p>{`${name} (${adborId})`}</p>
                                </span>
                            )
                        })
                        : <p className="text-muted">No sites exist</p>}
                </td>
                <td>
                    {(this.containsItems(sfOpps))
                        ? sfOpps.map(opp => {
                            const {id, name} = opp;
                            return (
                                <div style={this.flexStyles}>
                                    <a
                                        key={id}
                                        href={`${salesforceUrl}/lightning/r/Opportunity/${id}/view`}
                                        target="_blank"
                                        title="View in Salesforce">
                                        {`${name}`}
                                    </a>
                                    {(true) && (
                                        <React.Fragment>{' '}
                                            <span data-tip={"Create Basket"} data-for={id} data-tip-disable={false}>
                                                <Button color="info" alt="Create Basket" size="sm"><i
                                                    className="fa fa-shopping-basket"
                                                    onClick={() => {
                                            checkProfileThenCallAction(async() => {
                                                await this
                                                    .props
                                                    .createBasket(id, this.activeProfile(this.props.customer));
                                                this
                                                    .props
                                                    .history
                                                    .push(`/salesforce/basket/editor`);
                                            }, this.activeProfile(this.props.customer))
                                        }}/></Button>
                                                <ReactTooltip type="dark" effect="solid" place="right" id={id}/>
                                            </span>
                                        </React.Fragment>
                                    )}
                                </div>
                            )
                        })
                        : <p className="text-muted">No opportunities exist</p>}
                </td>
            </tr>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const {active, available} = state.profiles;
    return {active, available}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        createBasket: (oppId, profile) => {
            dispatch(createCustomerBktSalesforce(ownProps.customer.customerId, oppId, profile))
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataViewCustomerSfRow));
