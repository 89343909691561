import * as types from '../../../actions/actionTypes';
import _ from 'lodash';

const INITIAL_STATE = {
    OcsCreatedStatus:false,
    available: [],
    fileStatusLoader: false,
    number:0,
    totalElements:0
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_OCS_FILE:
            return {
                ...state,
                available: action.payload,
                number:action.payload2.number,
                totalElements:action.payload2.totalElements,
            }
        case types.HANDLE_LOADER:
            return {
                ...state,
                fileStatusLoader:action.payload
            }
        case types.HANDLE_OCS_STATUS:
            return {
                ...state,
                OcsCreatedStatus:action.payload
            }
        default:
            return state;
    }
}