import * as types from './sfCustomerActionTypes';
import { authHeaders } from "../utils/api";
import {axiosTaasInstance as axios} from "../axios.config";
import config from '../config';
import { displayError, displayErrorResopnse } from '../components/common/alerts/alerts';

const API_URL_GATEWAY = config.tdm_gateway_api.uri + "/tdm";

export const importCustomerSalesforce = (customer, assetId, env, profile,isNosqlImport) => async dispatch => {
    const id = customer.uniqueId;
    try {
        const requestBody = {
            assetId,
            customerRequestDto: {
                environment: env,
                type: customer.type,
                firstName: customer.firstName,
                lastName: customer.lastName,
                dob: customer.dob,
                uniqueId: customer.uniqueId,
                masterId: customer.masterId,
                cidn: customer.cidn
            },
            isNoSqlMigration: isNosqlImport,
            profile: profile
        }
        dispatch({ type: types.IMPORT_LOADING, id });
        const response = await axios.post(`${API_URL_GATEWAY}/salesforce/b2b/customer/import`, requestBody, { headers: authHeaders() });
        const customerResponse = {
            ...customer,
            salesforceCustomerDetails: {
                salesforceId: response.data.sfCustomerId,
                environment: env,
                profileId: profile.id
            }
        }
        dispatch({ type: types.IMPORT_SUCCESS, payload: customerResponse });
        return true;
    } catch (e) {
        dispatch({ type: types.IMPORT_FAIL, id })
        console.error(e);
        displayErrorResopnse(e);
    }
    return false;
}
export const contsctSyncWithTcon=(contactId, environment, cidn)=> async dispatch =>{
     dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "LOADING", syncStatusFlag: false});
    try {
        let body={
            cidn,
            environment,
            contactId
        }
        const response =await axios.post(`${API_URL_GATEWAY}/telstra-connect/sync/contact`, body, { headers: authHeaders() });
        if(response.data.code==="02"){
            dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "SYNC INITIATED", syncStatusFlag: false});
        }else if(response.data.code==="03"){
            dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "CIDN NOT FOUND",syncStatusFlag: false});
		}else{
            dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "", syncStatusFlag: false});
        }
    } catch (e) {
        dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "", syncStatusFlag: false});
    }
}

export const fetchContactSyncStatus=  (cidn,contactId)=> async dispatch =>{
    try{
        const response =await axios.get(`${API_URL_GATEWAY}/telstra-connect/sync/contact/status/${cidn}/${contactId}`, { headers: authHeaders() });       
        if(response.data.syncStatusCode==="01"){
           dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "SYNC SUCESS", syncStatusFlag: true});
        }else if(response.data.syncStatusCode==="02"){
           dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "SYNC INITIATED", syncStatusFlag: true});
        }else if(response.data.syncStatusCode==="03"){
           dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "SYNC FAIL", syncStatusFlag: true});
        }else{
           dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "", syncStatusFlag: true});
        }
    }catch (e) {
        dispatch({type: types.CONTACT_SYNC_STATUS, contactId: contactId, cidn: cidn, syncStatus: "", syncStatusFlag: true});
    }
   
        
       
}

export const sfContactsLoading = (id, loading = true) => ({ type: types.FETCH_SF_LOADING, id, key: "sfContactsLoading", loading });
export const sfBaLoading = (id, loading = true) => ({ type: types.FETCH_SF_LOADING, id, key: "sfBaLoading", loading });
export const sfOppsLoading = (id, loading = true) => ({ type: types.FETCH_SF_LOADING, id, key: "sfOppsLoading", loading });
export const sfSitesLoading = (id, loading = true) => ({ type: types.FETCH_SF_LOADING, id, key: "sfSitesLoading", loading });

export const createCustomerContactSalesforce = (id, salesforceId, env, email, role, profile) => async dispatch => {
    try {
        const requestBody = {
            sfAccountId: salesforceId,
            customerId: id,
            env,
            email,
            role,
            profile
        }
        dispatch(sfContactsLoading(id));
        const response = await axios.post(`${API_URL_GATEWAY}/salesforce/b2b/customer/create/contact`, requestBody, { headers: authHeaders() });
        const customer = {
            ...response.data,
            billingAccounts: [],
            sites: [],
            opportunities: []
        }
        dispatch({ type: types.FETCH_SF_SUCCESS, payload: customer, customerId: id, update: true });
        return true;
    } catch (e) {
        console.error(e);
        if (e.message === "Network Error") {
            e = {
                ...e,
                "timestamp": "",
                "responseStatus": "ERROR",
                "responseDescription": "Facing Network Issues, There was no response received",
            };
        }
        displayErrorResopnse(e);
    } finally {
        dispatch(sfContactsLoading(id, false));
    }
    return false;
}

export const createCustomerBaSalesforce = (id, salesforceId, contactId, env, profile) => async dispatch => {
    try {
        const requestBody = {
            contactId,
            sfAccountId: salesforceId,
            env,
            profile
        }
        dispatch(sfBaLoading(id));
        const response = await axios.post(`${API_URL_GATEWAY}/salesforce/b2b/customer/create/billing/account`, requestBody, { headers: authHeaders() });
        const customer = {
            ...response.data,
            contacts: [],
            sites: [],
            opportunities: []
        }
        dispatch({ type: types.FETCH_SF_SUCCESS, payload: customer, customerId: id, update: true });
        return true;
    } catch (error) {
        console.error(error);
        displayErrorResopnse(error);
    } finally {
        dispatch(sfBaLoading(id, false));
    }
    return false;
}

export const createCustomerSiteSalesforce = (id, salesforceId, adborId, env, profile) => async dispatch => {
    try {
        const requestBody = {
            adborId,
            sfAccountId: salesforceId,
            env,
            profile
        }
        dispatch(sfSitesLoading(id));
        const response = await axios.post(`${API_URL_GATEWAY}/salesforce/b2b/customer/create/site`, requestBody, { headers: authHeaders() });
        const customer = {
            ...response.data,
            contacts: [],
            billingAccounts: [],
            opportunities: []
        }
        dispatch({ type: types.FETCH_SF_SUCCESS, payload: customer, customerId: id, update: true });
        return true;
    } catch (e) {
        displayErrorResopnse(e);
    } finally {
        dispatch(sfSitesLoading(id, false));
    }
    return false;
}

export const createCustomerOppSalesforce = (id, salesforceId, contactId, env, profile) => async dispatch => {
    try {
        const requestBody = {
            contactId,
            sfAccountId: salesforceId,
            env,
            profile
        }
        dispatch(sfOppsLoading(id));
        const response = await axios.post(`${API_URL_GATEWAY}/salesforce/b2b/customer/create/opportunity`, requestBody, { headers: authHeaders() });
        const customer = {
            ...response.data,
            contacts: [],
            billingAccounts: [],
            sites: []
        }
        dispatch({ type: types.FETCH_SF_SUCCESS, payload: customer, customerId: id, update: true });
        return true;
    } catch (e) {
        displayErrorResopnse(e)
    } finally {
        dispatch(sfOppsLoading(id, false));
    }
    return false;
}

export const selectCustomerSalesforce = (custId, sfAccountId, env) => async dispatch => {
    dispatch({ type: types.SELECT_CUSTOMER_SALESFORCE, custId });
    if (custId) {
        try {
            dispatch(sfContactsLoading(custId));
            dispatch(sfBaLoading(custId));
            dispatch(sfOppsLoading(custId));
            dispatch(sfSitesLoading(custId));
            const requestBody = {
                sfAccountId,
                env
            }
            const response = await axios.post(`${API_URL_GATEWAY}/salesforce/b2b/customer/view/data`, requestBody, { headers: authHeaders() });
            const customerData = response.data;
            dispatch({ type: types.FETCH_SF_SUCCESS, payload: customerData, customerId: custId, update: false });
        } catch (e) {
            displayError(e)
        } finally {
            dispatch(sfContactsLoading(custId, false));
            dispatch(sfBaLoading(custId, false));
            dispatch(sfOppsLoading(custId, false));
            dispatch(sfSitesLoading(custId, false));
        }
        return false;
    }
}

export const addCustomerToSalesforceView = (customer) => async dispatch => {
    dispatch({ type: types.ADD_CUSTOMER_SF_VIEW, payload: customer })
}
