import React from "react";
import { connect } from "react-redux";
import { submit } from 'redux-form'
import _ from "lodash";
import { displayLoading } from "../../../common/alerts/alerts";
import * as fnnActions from "../../../../actions/dataCleanUpActions";
import * as constants from "../util/constants";
import convertFnnFormat from "../util/snrmUtils";
import SnrmUpdate from "./SnrmUpdate";
import CheckoutFrame from "../util/CheckoutFrame";
import "../util/cleanup.css";

class SnrmUpdateView extends React.Component {
  state = {};
  confirmModal = () => {
    this.props.onCheckout(constants.SNRM_UPDATE_FORM);
    const errors = this.props.syncErrors.filter((data) => {
      return !_.isNull(data) && !_.isEmpty(data);
    })
    if (errors.length === 0) {
      this.updateCheckout();
    }
  }

  updateCheckout = () => {
    displayLoading({ title: "Updating numbers", bodyText: "Please standby whilst data is being updated" });
    let fnnConvert = this.props.selectedFilters.snrmResourceType === 'FNN' ? true : false;
    const { startRange, endRange } = this.props.updateRange[0];
    const { finalStartRange, finalEndRange } = convertFnnFormat(fnnConvert, startRange, endRange);
    if(this.props.selectedFilters.snrmResourceType !== "SIM/IMSI")
  {  const updateData = {
      status: this.props.selectedFilters.snrmStatus,
      startRange: finalStartRange,
      endRange: finalEndRange,
      environment: this.props.environment,
    }
    this.props.updateResource(updateData);}
    else
    {
      const updateData = {
        status: this.props.selectedFilters.snrmIMSIStatus,
        startRange: finalStartRange,
        endRange: finalEndRange,
        environment: this.props.environment,
        simStatus: this.props.selectedFilters.snrmSIMStatus
      }
      this.props.update_IMSI_SIM_Resource(updateData);

    }
  }

  render() {
    const { view, selectedFilters, filters, selectSnrmUpdateType,selectSnrmIMSIUpdateType,selectSnrmSIMUpdateType } = this.props;
    const snrmStatus = selectedFilters.snrmStatus;
    const snrmIMSIStatus = selectedFilters.snrmIMSIStatus;
    const snrmSIMStatus = selectedFilters.snrmSIMStatus;
    const { snrmResourceType } = selectedFilters || {};
    const { statusFilters } = filters;
    const disableCheckout = !snrmStatus && (!snrmIMSIStatus || !snrmSIMStatus);
    return (
      <section>
        {snrmResourceType &&
          <SnrmUpdate
            snrmUpdateType={statusFilters}
            selectSnrmUpdateType={selectSnrmUpdateType}
            snrmStatus={snrmStatus}
            snrmIMSIStatus={snrmIMSIStatus}
            snrmSIMStatus={snrmSIMStatus}
            snrmResourceType={snrmResourceType}
            selectSnrmIMSIUpdateType={selectSnrmIMSIUpdateType}
            selectSnrmSIMUpdateType={selectSnrmSIMUpdateType}
          />
        }
        <CheckoutFrame
          title={view}
          key={view}
          disabled={disableCheckout}
          checkout={this.confirmModal}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedFilters: state.cleanup && state.cleanup.selectedFilters || {},
    snrmResourceType: state.cleanup && state.cleanup.selectedFilters && state.cleanup.selectedFilters.snrmResourceType,
    filters: state.cleanup.filters,
    updateRange: state.form && state.form[constants.SNRM_UPDATE_FORM] && state.form[constants.SNRM_UPDATE_FORM].values && state.form[constants.SNRM_UPDATE_FORM].values.ranges || [],
    syncErrors: state.form && state.form[constants.SNRM_UPDATE_FORM] && state.form[constants.SNRM_UPDATE_FORM].syncErrors && state.form[constants.SNRM_UPDATE_FORM].syncErrors.ranges || [],
    environment: state.auth.selectedEnvValue
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateResource: (requestBody) => dispatch(fnnActions.updateSnrmResource(requestBody)),
    update_IMSI_SIM_Resource: (requestBody) => dispatch(fnnActions.updateSnrmImsiSimResource(requestBody)),
    onCheckout: (formName) => { dispatch(submit(formName)) }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SnrmUpdateView);
