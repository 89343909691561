import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import blue from '@material-ui/core/colors/blue';

const styles = {
  root: {
    color: blue[600],
    '&$checked': {
      color: blue[500],
    },
  },
  checked: {},
};

const CheckboxItem = (props) => {
    const { classes, checkboxValue, checkboxLabel, labelPlacement = "end", checked, checkboxChangeCallback, disabled = false } = props;

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(event) => checkboxChangeCallback(event.target.checked)}
            value={checkboxValue}
            disabled={disabled}
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
          />
        }
        label={checkboxLabel}
        labelPlacement={labelPlacement}
      />
    )
}

export default withStyles(styles)(CheckboxItem);

CheckboxItem.propTypes = {
  classes: PropTypes.object.isRequired,
  checkboxLabel: PropTypes.string.isRequired,
  checkboxValue: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  checkboxChangeCallback: PropTypes.func,
};
CheckboxItem.defaultProps = {
  checkboxChangeCallback: null,
};