import * as types from './actionTypes';

export const toggleTourRun = (bool) => async dispatch => {
    dispatch({ type: types.TOGGLE_TOUR_RUN, bool });
}

export const resumeTour = (step, section) => async dispatch => {
    toggleTourRun(false);
    setTimeout(() => {
        dispatch({ type: types.RESUME_TOUR, step, section });
    }, 800);
}

export const initiateTour = (index, sections) => async dispatch => {
    dispatch({ type: types.INITIATE_TOUR, index, sections });
}

export const changeTourSection = (section) => async dispatch => {
    dispatch({ type: types.CHANGE_TOUR_SECTION, section });
}

export const changeTourStep = (run, stepIndex) => async dispatch => {
    dispatch({ type: types.CHANGE_TOUR_STEP, run, stepIndex });
}
export const finishTour = () => async dispatch => {
    dispatch({ type: types.FINISH_TOUR });
}