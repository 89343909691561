
import { validateService, validateSite, validateNetworkId, validatePhysicalId } from "../../actions/replicatorActions";

import _ from "lodash";

function composeAsyncValidators(validatorFns) {
  return async (values, dispatch, props, field) => {
    let errors = props.asyncErrors || {};
    if (!field) return errors;
    let fieldType = field.split(".")[1]; // Split between adbor / fnn val
    const validatorFn = validatorFns[fieldType];
    throw _.set(
      errors,
      field,
      await validatorFn(values, dispatch, field, errors)
    );
  };
}

const adborIdValidate = (values, dispatch, field, errors) => {
  return dispatch(
    validateSite(values.cidn, eval(`values.${field}`))
  )
    .then(isValid => {
      if (!isValid) return "Adbor ID taken for CIDN";
    })
    .catch(err => {
      return "There was an error processing this request";
    });
};

const fnnValidate = (values, dispatch, field, errors) => {
  const val = eval(`values.${field}`);

  if (val === undefined || val === "") return;
  const serviceName =  values.name === "MOBILE"? "MSISDN" : "service ID";
  if (values.adborFnns.filter(adborFnn => adborFnn.fnn === val).length > 1)
    throw _.set(errors, field, `You are already using this ${serviceName}`);

  if (values.mobileData.filter(mobile => mobile.msisdn === val).length > 1)
    throw _.set(errors, field, `You are already using this ${serviceName}`);
    
  return dispatch(validateService(val, values.type))
    .then(isValid => {
      if (!isValid) return `${serviceName} is not unique`;
    })
    .catch(err => {
      return "There was an error processing this request";
    });
};


const networkValidate = (values, dispatch, field, errors) => {
  const val = eval(`values.${field}`);

  if (val === undefined || val === "") return;
  
  if (values.mobileData.filter(mobile => mobile.imsi === val).length > 1)
    throw _.set(errors, field, "You are already using this IMSI");


  return dispatch(validateNetworkId(val, values.type))
    .then(isValid => {
      if (!isValid) return "IMSI is not unique";
    })
    .catch(err => {
      return "There was an error processing this request";
    });
};

const physicalIdValidate = (values, dispatch, field, errors) => {
  const val = eval(`values.${field}`);

  if (val === undefined || val === "") return;

  if (values.mobileData.filter(mobile => mobile.sim === val).length > 1)
    throw _.set(errors, field, "You are already using this SIM");

  return dispatch(validatePhysicalId(val))
    .then(isValid => {
      if (!isValid) return "SIM is not unique";
    })
    .catch(err => {
      return "There was an error processing this request";
    });
};


const s2pSiteAsyncVal = composeAsyncValidators({
  adborId: adborIdValidate,
  fnn: fnnValidate,
  msisdn: fnnValidate, // msisdn == fnn == service id
  imsi: networkValidate,
  sim: physicalIdValidate
});

export default s2pSiteAsyncVal;
