import React, { Component } from 'react'
import HeaderNav from './HeaderNav/HeaderNav';

import { connect } from 'react-redux';

import {withRouter} from 'react-router-dom';

class Header extends Component {
 
  render() {
    return (
      <HeaderNav location={this.props.location} />
    )
  }
}



export default withRouter((Header));