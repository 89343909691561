import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    assets : {
        assetsLoading : false,
        assetsError : {}
    },
    fileContents : {
        contentsLoading : false,
        contentsError : {}
    },
    requestIdFilters : {
        filterLoading : false
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
       case types.LOAD_CUSTOM_CHECKIN_ASSETS_LOADING : 
            return {
                ...state,
                assets : {
                    assetsLoading : true
                }
            }
        case types.LOAD_CUSTOM_CHECKIN_ASSETS_SUCCESS : 
        return {
            ...state,
            assets : {
                assetsLoading : false,
                ...action.payload
            }
        }
        case types.LOAD_CUSTOM_CHECKIN_ASSETS_FAILED : 
        return {
            ...state,
            assets : {
                assetsLoading : false,
                assetsError : action.payload
            }
        }
        case types.RESET_CUSTOM_CHECKIN_ASSETS_SUCCESS :
            return{
                ...INITIAL_STATE
            }

        case types.CUSTOM_CHECKIN_FILE_UPLOAD_LOADING :
            return {
                ...state,
                fileContents : {
                    contentsLoading : true
                }
            }

        case types.CUSTOM_CHECKIN_FILE_UPLOAD_SUCCESS :
            return {
                ...state,
                fileContents : {
                    ...action.payload,
                    contentsLoading : false
                }
            }
        case types.CUSTOM_CHECKIN_FILE_UPLOAD_ERROR :
            return {
                ...state,
                fileContents : {
                    contentsError : action.payload,
                    contentsLoading : false
                }
            }

            case types.LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_LOADING :
                return {
                    ...state,
                    requestIdFilters : {
                        filterLoading : true
                    }
                }
    
            case types.LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_SUCCESS :
                return {
                    ...state,
                    requestIdFilters : {
                        ...action.payload,
                        filterLoading : false
                    }
                }
            case types.LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_ERROR :
                return {
                    ...state,
                    requestIdFilters : {
                        filterError : action.payload,
                        filterLoading : false
                    }
                }
            
        default:
            return state;
    }
}