import React, { Component } from "react";
import CreateSubscription from "./CreateSubscription";

export default class SubscriptionModule extends Component {
    render() {
        return (
            <div>
                <CreateSubscription />
            </div>
        )
    }
}