import React, {Component} from 'react'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
    Table,
    Col,
    Row,
    Card,
    CardColumns,
    CardHeader,
    Collapse
} from 'reactstrap';

import _ from 'lodash';

import {fetchPublicStats} from '../../actions/statsActions';
import {SubHeader} from '../common/custom/SubHeader';

import {getColor, getDataTypeIcon, renderIcon, getClassTypeIcon} from '../../utils/ui';
import StatsCard from './StatsCardPublic';
import StatsCardPublic from './StatsCardPublic';

class DataStatisticsPublic extends Component {
    static propTypes = {
        prop: PropTypes
    }

    componentDidMount() {
        document.title = 'TDM - Availability Statistics'
        this
            .props
            .fetchStats();
    }

    renderStatsCard = (name, type, envCountStats) => {
        return <StatsCardPublic name={name} type={type} envCountStats={envCountStats}/>
    }

    render() {
        const statsArray = this.props.statsData;
        return (
            <CardColumns>
                {statsArray.map(({name, type, envCountStats}) => {
                    return (this.renderStatsCard(name, type, envCountStats))
                })}
            </CardColumns>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {statsData: state.stats.public}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchStats: () => {
            dispatch(fetchPublicStats())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataStatisticsPublic);
