import React from 'react';
import {SubHeader} from "../../../../common/custom/SubHeader";
import {Table} from "reactstrap";
import {renderIcon} from '../../../../../utils/ui';

export default function TDI_Table ({tdiDetails}) {
    return(
        <React.Fragment>
            {(tdiDetails !== null && tdiDetails !== undefined  && tdiDetails.length > 0 &&
                <React.Fragment>
                    <SubHeader text={"TDI"} iconClass={"td-icon-md icon-crowd-support"} iconColor={"purple"}/>
                    <Table>
                        <thead>
                        <tr>
                            <th>Email</th>
                            <th>Password</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tdiDetails.map((item) => {
                        const success = item.userName != null;
                        return (
                            <tr>
                                <td>{item.userName}</td>
                                <td>{item.password}</td>
                                <td
                   className={success
                    ? "base-green"
                    : "base-orange"}>{renderIcon(success
                        ? "icon-round-tick"
                        : "icon-round-cross")}</td>
                            </tr>
                        )
                    })}
                        </tbody>
                    </Table>

                </React.Fragment>
            )}
        </React.Fragment>
    );
}