import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    COUNT: 0,
    realm: [],
    env: []
}

export function itemsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.LOAD_ITEMS_SUCCESS:
            const isFirst = action.data.offset == 0;
            const itemsArray = action.data.items;
            return {
                ...state,
                content: (!isFirst ? [...state.content, ...itemsArray] : [...itemsArray] )
            }
        case types.LOAD_ITEMS_LOADING:
            return {
                ...state
            }
        case types.LOAD_ITEMS_ERROR:
            return {
                ...state
            }
        default:
            return state;
    }
}