import * as types from '../actions/actionTypes';

import {getClassTypeIcon, getDataTypeIcon} from '../utils/ui.js';
import { markSelectedState } from '../utils/state';

const INITIAL_STATE = {
    filters: {
        options: [],
        environments: [],
        systemTypes:[],
        qty: [{
            label: '1 Record',
            qty: 1,
            selected: false
        },
        {
            label: '2 Records',
            qty: 2,
            selected: false
        },
        {
            label: '3 Records',
            qty: 3,
            selected: false
        },
        {
            label: '4 Records',
            qty: 4,
            selected: false
        },
        {
            label: '5 Records',
            qty: 5,
            selected: false
        },
        {
            label: '10 Records',
            qty: 10
        },
        {
            label: '20 Records',
            qty: 20
        }]
    },
    view: {
        items: []
    },
    checkOutData : {},
    activeStep: 0,
    selectedFilters: {},
    selectedOption: {},
    sfProfiles: []
}

export default(state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOAD_DATA_ACTIVE_FILTER_SUCCESS:
            return {
                ...state,
                filters: {qty: state.filters.qty,systemTypes: state.filters.systemTypes,...action.payload}
            }
        case types.SELECT_DATA_ACTIVE_FILTER:
            return markSelectedState(action.key, state, action.value);
        case types.SELECT_DATA_ACTIVE_OPTION:
            return {...state, selectedOption: action.option}
        case types.CHECKOUT_DATA_BANK_DATA_ACTIVE_SUCCESS:
            return {...state, checkOutData: action.payload };
        case types.CHECKOUT_DATA_ACTIVE_SUCCESS:
            return {...state, view: {...action.payload}}
        case types.SELECT_DATA_FILTER_RESET:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
}