import React from 'react';
import {uploadAndParseFile,checkinCustomAsset} from '../../actions/customCheckinActions';
import { connect } from "react-redux";
import { Table} from 'reactstrap';
import swal from 'sweetalert2';
import { displayLoading } from "../common/alerts/alerts";

class EmptyAssetsTableComponent extends React.Component {
    
    submitCheckinAssets =(customDetails) => {
        if(this.props.scenarioName === '') {
            this.props.inValidScenarioname()
        }
      let requestArray =[];
      let assetIds=[];
        for(let i=0;i<customDetails.length;i++){
            const request = {
                "description": "TDM VACANT DATA - CUSTOM",
                "serviceId": null,
                "source": "TDM",
                "scope": "TEAM",
                "assetVersion": 0,
                "environment": this.props.selectedEnvValue,
                "userStory":this.props.requestId,
                "scenarioName" :this.props.scenarioName,
                "enrichmentStatus": "COMPLETED",
                "custom":[customDetails[i]]
            }
            requestArray.push(request)
        }
       
        this.props.scenarioName && swal({
            title: 'Custom Asset Checkin Information',
            html: `<b>User Story:</b> ${this.props.requestId}<br />
            <b>Scenario Name:</b> ${this.props.scenarioName}<br />
            <b>Quantity:</b>  ${customDetails.length}`,
            type: 'info',
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> Cancel',
            confirmButtonColor: '#13a129',
            confirmButtonText: '<i class="fa fa-check" aria-hidden="true"></i> Checkin',
            reverseButtons: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return Promise.all(requestArray.map(request=>{
                      this.props.checkinCustomAsset(request)
                        .then(result => {
                            if (result.errorDescription) {
                                    swal.fire({
                                        type: 'error',
                                        title: 'Custom Checkin Error',
                                        text: result.errorDescription,
                                    })
                            } else if(result.assetId){  
                                assetIds.push(result.assetId);                                            
                                if(assetIds.length === requestArray.length){
                                    swal("Checkin Complete", "You may add more data to checkin", "success");
                                    this.props.refreshTableData();
                                }
                            }
                           
                    }).catch(error => {
                        console.log(error)
                        swal.showValidationError(`Custom Checkin failed, Error please try again`);
					})
                }
                ))
            },
            allowOutsideClick: () => !swal.isLoading()
        }).then( (result) => {
            if(!result.dismiss){ displayLoading({ title: "Checking in", bodyText: "Please standby" });      }
        })
    }


    savedTable = (customDetails) =>{
        return(
            <div className="col-sm-12">
               <Table hover responsive>
                        <thead>
                            <tr>
                                {Object.entries(customDetails[0]).map(([key,val]) =>
                                <td key={key}>{key}</td>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                                {customDetails.map((customdetail,index) => 
                                <tr key={index}>   
                                    {Object.entries(customdetail).map(([key,val]) => 
                                        <td key={key}>{val}</td> 
                                    )} 
                                </tr>                         
                                )}
                        </tbody>
                    </Table>
            </div>
        )
    }

    render(){
        const {tableData} = this.props;
        return(
            <div className="col-sm-12">
                <div className="row">
                    {tableData && tableData.customDetails && 
                    <div className="col-sm-12" style={{textAlign : "right", marginTop:"8px"}}><button type="button" className="btn btn-info" onClick={() => this.submitCheckinAssets(tableData.customDetails)}>Checkin</button> </div>}
                    {tableData && tableData.customDetails && this.savedTable(tableData.customDetails)}
                </div>
               
            </div>


        )
    }
}

const mapStateToProps = state => {
    return {
        tableData : state.customCheckin.fileContents,
        selectedEnvValue: state.auth.selectedEnvValue,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        uploadAndParseFile: (data) => dispatch(uploadAndParseFile(data)),
        checkinCustomAsset: (request) => dispatch(checkinCustomAsset(request))
    };
  };
export default connect(mapStateToProps,mapDispatchToProps)(EmptyAssetsTableComponent);