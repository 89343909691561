import React from "react";
import PropTypes from "prop-types";

import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { TextField } from "@material-ui/core";
import SmallSquareBtn from "@library/core/buttons/SmallSquareBtn";
import { numericValid } from "@library/core/form-elements/validator";
import { withStyles } from "@material-ui/core/styles";

const CustomTextField = withStyles({
    root: {
      width: 75,
      paddingLeft: "3px",
      paddingRight: "3px",
    },
})(TextField);

const Quantity = (props) => {
    
    const reduceQty = () => {
        if (qty > 0) {
            onChangeQty(qty-1, id);
        }
    }

    const increaseQty = () => {
        if (maxQty && qty < props.maxQty) {
            onChangeQty(qty+1, id);
        } else if (!maxQty) {
            onChangeQty(qty+1, id);
        }
    }

    const onChangeHandler = (e) => {
        if (numericValid(e.target.value) || e.target.value === '') {
            if (maxQty && (Number(e.target.value) > Number(maxQty))) {
                onChangeQty(maxQty, id);
            } else {
                onChangeQty(Number(e.target.value), id);
            }
        }
    }

    const { maxQty, onChangeQty, id = null, disabled=false, ...rest } = props;
    const qty = props.qty ? props.qty : 0;
    return (
        <div style={{"minWidth":"150px"}}>
            <SmallSquareBtn
                variant="contained" 
                color="secondary"
                onBtnClick={reduceQty}
                disabled={qty === 0 || qty === '' || disabled}
            >
                <RemoveIcon/>
            </SmallSquareBtn>
            <CustomTextField
                value={qty}
                error={maxQty && qty > maxQty}
                helperText={maxQty && qty > maxQty ? "Max: "+maxQty : null}
                required={true}
                type="string"
                onChange={(e)=> onChangeHandler(e) }
                inputProps={{ style: {textAlign: 'center', fontSize: 20} }}
                InputProps={{ disableUnderline: true }}
                disabled={disabled}
                {...rest}
            />
            <SmallSquareBtn
                variant="contained" 
                color="primary"
                onBtnClick={increaseQty}
                disabled={maxQty && qty >= maxQty || disabled}
            >
                <AddIcon/>
            </SmallSquareBtn>
        </div>
    );
};

Quantity.propTypes = {
    qty: PropTypes.number,
    maxQty: PropTypes.number,
    onChangeQty: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
};

export default Quantity;
