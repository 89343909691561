import React, { Component, Fragment } from 'react';
import TelegramIcon from '@material-ui/icons/Telegram';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import { IconButton, Card,CardMedia,Typography,Switch,FormControlLabel } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import Button from '@mui/material/Button';

export default ({selectedToggleButton, icon, loading, title, alertModal, loadingText, empty,
    extraComponent,showAllTeamsData, switchHandler, onClick,click=false, extraComponentClassName, showAllTeamsDataComponent,
    onClickAllocate, onClickThreshold,isAdmin,isAuthenticated,isPasmat,image,tooltip,showEnv,selectedEnvValue,setSideBarPanel,openSidePanel,redirectUtility,push}) => {
    const allocateButton = (<div className={'allocate-btn'}
        onClick = {(event) => {onClickAllocate && onClickAllocate(event)}}>
        <TelegramIcon className = 'icon' fontSize = 'inherit' />
    </div>)

const notificationButton = (<div className={'allocate-btn'}
        onClick = {(event) => {onClickThreshold && onClickThreshold(event)}}>
        <NotificationAddOutlinedIcon color="secondary" className = 'icon' fontSize = 'inherit' />
    </div>)
        return (
        <span className="dataview-header">
                    <div>
                    {isPasmat?<h3 className="ml-4 mt-6">{title}</h3>:<h1>{title}</h1>}
                    {showEnv ? <>
                        <p className='showEnvSection'><b>Environment : </b> {selectedEnvValue}</p>
                        <FormControlLabel className='showEnvToggle'
                         control={<Switch checked={openSidePanel} onChange={()=>setSideBarPanel(true)} name="gilad" />}/>
                        </>: null}
                    </div>
                   
                    <div className={extraComponentClassName || ""}>
                        {isAdmin ? showAllTeamsDataComponent():null}
                        {extraComponent && extraComponent()}
                        {isAuthenticated && notificationButton}
                        {isAdmin?allocateButton:null}
                        {/* {isPasmat?surveyResult:null} */}
                       {/*  {(isPasmat && !loading )?pasmatMetric:null}   */}
                    { image !== "" && tooltip !== undefined  ?
                    <Tooltip title={tooltip}>
                    <Card sx={{ maxWidth: 345 }} className="msgCard">
                    <CardMedia
                        className=""
                        component="img"
                        height="140"
                        image={image}
                        alt="msgIcon"
                    />
                </Card></Tooltip> : 
                <> 
        {redirectUtility && <Button 
        onClick={()=>{push('/data/utility')}}
         variant="contained" startIcon={<ReplyAllIcon />}>Back to Utilities</Button>} 
                      {/* {click ===true ?  
                    <i className={`fa ${ loading ? 'fa-refresh fa-spin fa-fw' : `fa-${icon}` } fa-3x`} 
                    aria-hidden="true" 
                    onClick={(event) => {
                        onClick && onClick(event);
                        if(alertModal){
                            alertModal.open();
                        }  }} 
                        style={{ marginRight: "25px",padding: "20px",borderRadius: "10px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                        }}>
                        </i> : 
                    <i className={`fa ${
                        loading ? 'fa-refresh fa-spin fa-fw' : `fa-${icon}`
                    } fa-3x`} aria-hidden="true" ></i> } */}
                </>
                    }
                    </div>
                   
                    
        </span>
        

    )
}