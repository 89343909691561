import * as types from './actionTypes';
import {axiosTaasInstance as axios} from "../axios.config";

import config from '../config';
import { authHeaders } from "../utils/api";

const GATEWAY_URL = config.tdm_gateway_api.uri;


export const resetData = () => async dispatch => {
  dispatch({ type: types.CLEAR_CLEAN_UP })
}
export const cleanUpB2B = (reqBody) => async dispatch => {
  try {
    const response = await axios.post(`${GATEWAY_URL}/tdm/orchestration/b2b/postpaid/cleanup`, reqBody, { headers: authHeaders() })
    if (response.data.responseStatus === "Success") {
      dispatch({ type: types.B2B_CLEAN_UP_SUCCESS, payload: response.data });
      return response;
    } else {
      dispatch({ type: types.B2B_CLEAN_UP_FAIL, payload: response.data.responseDescription })
      return response.data.responseDescription;
    }
  } catch (e) {
    let message;
    if (!e.response) {
      message = "Network connectivity issues, please try again later.";
    } else {
      message = e.response.data.responseDescription;
    }
    dispatch({ type: types.B2B_CLEAN_UP_FAIL, payload: message })
    return message;
  }
}

export const fetchValidResources = (resourceRangeData) => async dispatch => {
  try {
    const response = await axios.post(`${GATEWAY_URL}/tdm/cleanup/b2b/cleanup/valid/resources`, resourceRangeData, { headers: authHeaders() })
    if (response.data.responseStatus === "Success") {
      dispatch({
        type: types.B2B_VALID_RESOURCES, payload: response.data.validResourcesList,
        message: response.data.responseDescription
      });
      return true;
    }
    if (response.data.responseStatus === "Fail") {
      dispatch({
        type: types.B2B_INVALID_RESOURCES, payload: response.data.validResourcesList,
        message: response.data.responseDescription
      });
      return false;
    }
  }
  catch (e) {
    let message;
    if (!e.response) {
      message = "Network connectivity issues, please try again later.";
    } else {
      message = e.response.data.responseDescription;
    }
    dispatch({ type: types.B2B_INVALID_RESOURCES, payload: message })
    return message;
  }
}

export const applicationCleanup = (reqBody) => async dispatch => {
  try {
    const response = await axios.post(`${GATEWAY_URL}/tdm/orchestration/b2b/postpaid/application/cleanup`, reqBody, { headers: authHeaders() })
    return response;
  } catch (e) {
    let message;
    if (!e.response) {
      message = "Network connectivity issues, please try again later.";
    } else {
      message = e.response.data.responseDescription;
    }
    dispatch({ type: types.B2B_CLEAN_UP_FAIL, payload: message })
    return message;
  }
}

export const statusBtobDataCleanup = (reqBody) => async () => {
    const response = await axios.post(`${GATEWAY_URL}/tdm/cleanup/b2b/resource/status`, reqBody, { headers: authHeaders() })
    return response;
}