import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

const LinkTile = (props) => {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    disabled,
    onClick,
    feedbackModal,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props

  const onClickFunc = (onClick, event) => {
    if (!disabled) {
      if (onClick) {
        onClick(event);
      }
      !feedbackModal && history.push(to)
    }
  }

  return (
    <>
      {
        props.redirect !== undefined  ? 
        <div data-role="tile">
          <a href={props.url} target="_blank" rel={'external'}>
            <div tabIndex="0" data-role="tile" {...rest} onClick={(event) => onClickFunc(onClick, event)}/>
          </a>
        </div> :
        <div tabIndex="0" data-role="tile" {...rest} onClick={(event) => onClickFunc(onClick, event)}/>
      } 
    </>
  )
}

LinkTile.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool
}

export default withRouter(LinkTile)