import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { grey } from "@material-ui/core/colors";

const borderColor = grey[300];
const useStyles = makeStyles((theme)=>({
    root: (props) => ({
        padding: theme.spacing(props.padding || 6),
        backgroundColor: '#FFF',
        border: `1px solid ${borderColor}`,
        borderTopLeftRadius: props.borderTopLeft || '4px',
        borderTopRightRadius: props.borderTopRight || '4px',
        borderBottomLeftRadius: props.borderBottomLeft || '4px',
        borderBottomRightRadius: props.borderBottomRight || '4px',
        borderTop: props.disableTopBorder ? 'none' : ''
    })
}));

const ContentBody = (props) => {
  const {
    children,
    borderTopLeft,
    borderTopRight,
    borderBottomLeft,
    borderBottomRight,
    disableTopBorder,
    ...rest
  } = props;

  const classes = useStyles(props);

  return (
    <Box flexGrow={1} className={classes.root} {...rest}>
        {children}
    </Box>
  );
};

ContentBody.propTypes = {
  children: PropTypes.any,
};

export default ContentBody;
