import React, {Component} from 'react'
import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #00aaf3 0, #1964c8 100%)',
}
const OrderBuilderTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-green"
        data-size="medium"
        to="/data/order"
        data-effect="hover-zoom-up">
        <div class="slide-front">
            <span className="td-icon-md icon-productivity-gains icon"></span> {/**change icon */}
            <span className="branding-bar">Order Builder</span>
        </div>
        <div
            class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
            <p class="text-center">
                Order Builder
            </p>
        </div>
    </LinkTile>
)

OrderBuilderTile.propTypes = {}

export default OrderBuilderTile;