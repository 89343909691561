import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  Grid,
} from "@material-ui/core";

import { Title } from "@library/core/text";

const Header = (props) => {
  const {
    title,
    topRightIcon,
    underTitle
  } = props;

  return (
    <>
        <Box m={1}>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
            >
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Title>{title}</Title>
                    </Grid>
                    {
                      underTitle && 
                      <Grid item>
                        {underTitle}
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid item>
                    {topRightIcon}
                </Grid>
            </Grid>
        </Box>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  topRightIcon: PropTypes.any,
};

export default Header;
