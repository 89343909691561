import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Client } from '@stomp/stompjs';
import './DataView.css';

import { Table, Row, Col } from 'reactstrap';

import { JSONDownloadTile } from '../tiles/DownloadTile';
import { CSVDownloadTile } from '../tiles/CSVDownloadTile';

import { fetchCheckoutHistoryDetail, registerTDI } from '../../../actions/dataActions';
import { isNullOrUndefined, isNull } from 'util';
import DataViewCustomer from './customer/DataViewCustomer';
import { DataViewOrder } from './order/DataViewOrder';
import DataViewTDI from './TDI/DataViewTDI';
import { CheckoutHistoryTable } from '../../Checkout/CheckoutHistoryTable';
import { DataViewAddress } from './address/DataViewAddress';

import config from '../../../config';
import { DataViewMobile } from './mobile/DataViewMobile';

const BROKER_URL = config.api.brokerUrl;

class DataView extends Component {
    componentDidMount() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        console.log(this.props.location, 'location Object');
        this.refresh();
    }
    componentWillUnmount() {
        this.refreshUnsubscribe();
    }

    // function that child components can call to form a websocket connection to
    // listen to refresh events.
    refreshSubscribe = () => {
        if (this.client != null) {
            console.log("A websocket refresh subscription is already formed..");
        } else {
            console.log("Creating websocket refresh subscription...");
        }
        // The compat mode syntax is totally different, converting to v5 syntax Client
        // is imported from '@stomp/stompjs'
        this.client = new Client();

        this
            .client
            .configure({
                brokerURL: `${BROKER_URL}`,
                onConnect: () => {
                    const { id: checkoutId } = this.props.view;
                    this
                        .client
                        .subscribe(`/topic/checkout/history/${checkoutId}`, message => {
                            console.log(message);
                            this.setState({ serverTime: message.body });
                            this.refresh(false);
                        });
                },
                // Helps during debugging, remove in production
                debug: (str) => {
                    console.log(new Date(), str);
                }
            });

        this
            .client
            .activate();
    }

    refreshUnsubscribe = () => {
        if (this.client == null)
            return;
        console.log("Closing websocket refresh subscription...");
        this
            .client
            .deactivate();
    }

    refresh = (checkId = true) => {
        const paramMatchesState = this.isParamMatchingState();
        if (checkId && paramMatchesState)
            return;

        const { id: paramId } = this.props.match.params;
        this
            .props
            .fetchDetail(paramId);
    }

    isParamMatchingState = () => {
        // does the parameter checkoutId match is currently in the state?
        const { id: paramId } = this.props.match.params;
        const { id: stateId } = this.props.view;
        return (paramId == stateId);
    }

    mapMsidnToMobileItem = (msisdnItems) => {
        return msisdnItems.map(msisdnItem => ({ "mobileId": msisdnItem.msisdnId, "msisdn": msisdnItem.msisdn }));
    }

    render() {
        const { match, loading, registerTDIHandler } = this.props;
        const { prevState } = this.props.location;



        // is the current checkout performing a refresh? e.g used for UI purposes.
        const sameCheckoutLoading = (loading && this.isParamMatchingState());

        const differentCheckoutLoading = (loading && !this.isParamMatchingState());

        console.log("LOADING: " + loading);
        const {
            id,
            loadingId,
            usedDate,
            envFilter,
            dataFilter,
            techFilter,
            qtyRequested,
            requestedTagids,
            requestedFnns,
            //items
            addressItems,
            msisdnItems,
            customerItems,
            orderItems,
            tdiItems,
            tdiErrorMessage,
            tdiRegisterLoading,
            message,
            assetActionsMessage,
            tdiCheckOutLoading
        } = this.props.view;

        const custFirstName = customerItems && customerItems.length !== 0 && customerItems[0].firstName;
        const filteredTdiItems = tdiItems ? tdiItems.filter(data => data.firstName === custFirstName) : [];
        const tdiBtnDisabled = filteredTdiItems.length > 0 || (tdiErrorMessage && tdiErrorMessage !== "") || (customerItems && customerItems.length === 0);
        const showTdiBtn = tdiItems.length === 0;


        const displayMsgColor = "text-primary";

        const filename = `${`TDM${id}`}-${envFilter}-${dataFilter}${techFilter != "N/A"
            ? `-${`C${techFilter}`}`
            : ''}-${`Q${qtyRequested}`}`;
        let fnnItems;
        if (this.props.fnnItems) {
            fnnItems = this
                .props
                .fnnItems
                .map(fnnItem => ({ "fnn": fnnItem }));
        } else
            fnnItems = [];

        let { mobileItems } = this.props.view;
        if (mobileItems && !mobileItems.length > 0 && msisdnItems && msisdnItems.length > 0) {
            mobileItems = this.mapMsidnToMobileItem(msisdnItems);
        }
        return (
            <div className="white">
                {/* <div id="custom-count-circle"></div> */}
                <span className="dataview-header">
                    <div>
                        <h1>Checkout ID: {loadingId || id}</h1>
                        {(differentCheckoutLoading && <p className="lead">Loading checkout data...</p>)}
                    </div>
                    <i
                    className={`fa ${differentCheckoutLoading
                        ? 'fa-refresh fa-spin fa-fw'
                        : 'fa-history'} fa-3x`}
                    aria-hidden="true"/>
                </span>
                {(!differentCheckoutLoading) && <React.Fragment>
                    <Row>
                        <span className="dataview-header">
                            {(message && message.length > 0) && (
                                <h2 className={displayMsgColor}>{message}</h2>
                            )}
                        </span>
                    </Row>
                    <CheckoutHistoryTable tableData={[this.props.view]} detailView={false} />
                    {(!isNullOrUndefined(orderItems) && orderItems.length > 0) && (
                        <DataViewOrder tdiBtnDisabled={tdiBtnDisabled} showTdiBtn={showTdiBtn} tdiCheckOutLoading={tdiCheckOutLoading} tdiRegisterLoading={tdiRegisterLoading} tdiRegisterHandler={registerTDIHandler} tdiErrorMessage={tdiErrorMessage} orderItems={orderItems} customerItems={customerItems} filename={filename}></DataViewOrder>
                    )}
                    {(!isNullOrUndefined(filteredTdiItems) && filteredTdiItems.length > 0) && (
                        <DataViewTDI tdiItems={filteredTdiItems} assetActionsMessage={assetActionsMessage} />
                    )}
                    {(!isNullOrUndefined(addressItems) && addressItems.length > 0) && (<DataViewAddress
                        addressItems={addressItems}
                        filename={filename}
                        displayClass={techFilter != "N/A"} />)}
                    {(!isNullOrUndefined(mobileItems) && mobileItems.length > 0) && (
                        <DataViewMobile mobileItems={mobileItems} classFilter={techFilter} filename={filename}></DataViewMobile>
                    )}
                    {(!isNullOrUndefined(customerItems) && customerItems.length > 0) && (
                        <DataViewCustomer
                            customerItems={customerItems}
                            filename={filename}
                            refresh={() => this.refresh(false)}
                            subscribe={this.refreshSubscribe}
                            unsubscribe={this.refreshUnsubscribe}
                            loading={sameCheckoutLoading}></DataViewCustomer>
                    )}
                </React.Fragment>}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        view: state.checkout.view,
        nbnItems: state.checkout.view.nbnItems,
        mobileItems: state.checkout.view.mobileItems,
        message: state.checkout.view.message,
        fnnItems: state.checkout.view.fnnItems,
        loading: state.checkout.view.loading,
        assetActionsMessage: state.checkout.view.assetActionsMessage
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchDetail: (id) => dispatch(fetchCheckoutHistoryDetail(id)),
        registerTDIHandler: (requestFormProps) => dispatch(registerTDI(requestFormProps))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);
