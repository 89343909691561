import React from 'react';
import { Input, InputGroup } from "reactstrap";
import DataCustomFrame from '../../Data/accordion-frames/replicator/DataCustomFrame';
import CheckoutFrame from '../../Data/accordion-frames/CheckoutFrame';
import { fnnValidator, cidnIsValid } from "../../common/form/fieldValidators";
import FlexSpan from '../../../utils/FlexSpan';

export default function DeleteInputAction({
    selectedFilters,
    selectDeleteAction,
    handleDeleteOnSubmit,
    header }) {

    const SpanWidth = ({ children, width }) => <span style={{ "width": width }}>{children}</span>

    const renderServiceIdForDelete = () => {
        const deleteServiceId = selectedFilters.deleteServiceId;
        return (
            <div>
                <FlexSpan>
                    <SpanWidth width="25%">
                        <InputGroup>
                            <Input placeholder="Service Id for deletion"
                                name="Service Id"
                                onBlur={e => selectDeleteAction(e.currentTarget)}
                                defaultValue={deleteServiceId} />
                        </InputGroup>
                        {deleteServiceId && fnnValidator(deleteServiceId) !== undefined &&
                            <div style={{ position: "absolute", top: "35px", display: "block" }} className="invalid-feedback">FNN must be 10 digits starting 0</div>
                        }
                    </SpanWidth>
                </FlexSpan>
                {deleteServiceId && fnnValidator(deleteServiceId) === undefined && <CheckoutFrame
                    checkout={() => handleDeleteOnSubmit("Service Id", deleteServiceId)}
                    title="Delete"
                    color="bg-danger" />}
            </div>
        )
    }

    const renderCIDNForDelete = () => {
        const deleteCIDN = selectedFilters.deleteCIDN;
        return (
            <div>
                <FlexSpan>
                    <SpanWidth width="30%">
                        <InputGroup>
                            <Input placeholder="CIDN for deletion"
                                name="CIDN"
                                onBlur={e => selectDeleteAction(e.currentTarget)}
                                defaultValue={deleteCIDN} />
                        </InputGroup>
                        {deleteCIDN && !cidnIsValid(deleteCIDN) &&
                            <div style={{ position: "absolute", top: "35px", display: "block" }} className="invalid-feedback">CIDN must be 10 digits</div>
                        }
                    </SpanWidth>
                </FlexSpan>
                {deleteCIDN && cidnIsValid(deleteCIDN) &&
                    <CheckoutFrame checkout={() => handleDeleteOnSubmit("CIDN", deleteCIDN)}
                        title="Delete"
                        color="bg-danger" />
                }
            </div>
        )
    }

    const renderFunc = (header === 'CIDN') ? renderCIDNForDelete : renderServiceIdForDelete;

    return (
        <DataCustomFrame
            renderContent={renderFunc}
            heading={header}
        />
    )
}