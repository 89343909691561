import * as types from '../actions/actionTypes';

export function settingsReducer(state = {}, action) {
    switch (action.type) {
        case types.LOAD_SETTINGS_DATA_THRESHOLDS_SUCCESS:
            return {
                ...state,
                dataThresholds: action.payload,
                dataThresholdsLoading: false
            };
        case types.LOAD_SETTINGS_DATA_THRESHOLDS_LOADING:
            return {
                ...state,
                dataThresholdsLoading: action.loading
            }
        default:
            return state;
    }
}