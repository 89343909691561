import React from "react";
import _ from "lodash";
import { Table } from 'reactstrap';
import TeamDataTableBody from "./TeamDataTableBody";
import TableFilterController from '../../../../components/common/filter/TableFilterController';
import TableContentLoader from "../../../../components/common/loader/TableContentLoader";
import MuiAlert from '@material-ui/lab/Alert';

export default function DataCheckoutTable({ tableData, isAdmin, teamId, currentTeamId, filters, fetching, errors, isClearFilter, clearFilter}) {
  function TableHeaderCol(props){
  return (<th style={{textAlign: `${props.textAlign || 'center'}` , minWidth: `${props.minWidth}`, width: `${props.width || '10%'}`} }>{props.children}</th>)
  }
  return (
    <React.Fragment>
      <Table hover responsive size="sm">
        <thead>
          <tr>
            <TableHeaderCol width="10%" minWidth="138px">Checkout Id</TableHeaderCol>
            <TableHeaderCol width="10%" minWidth="132px">Service Id</TableHeaderCol>
            <TableHeaderCol width="13%" minWidth="132px">Product Code</TableHeaderCol>
            <TableHeaderCol width="20%" textAlign="left">Service Description</TableHeaderCol>
            <TableHeaderCol width="12%">Quantity</TableHeaderCol>
            <TableHeaderCol width="15%">Date</TableHeaderCol>
            <TableHeaderCol width="10%">{teamId ? 'Users' : 'Status'}</TableHeaderCol>
            <TableHeaderCol width="10%">Action</TableHeaderCol>
          </tr>
        </thead>
        <tbody id="team-details-table-body">
          <React.Fragment>
          {filters && 
            <TableFilterController filters={filters} isClearFilter={isClearFilter} clearFilter={clearFilter} />
          }
          { !errors && !fetching && !_.isEmpty(tableData) && 
            <TeamDataTableBody 
              teamData={tableData}
              isAdmin={isAdmin}
              currentTeamId={currentTeamId}
              teamId={teamId}
            />
          }
          </React.Fragment>
        </tbody>
      </Table>
      {fetching && (<TableContentLoader />)}
      {_.isEmpty(tableData) && !fetching && !errors &&<MuiAlert elevation={6} variant='filled' severity="info">Sorry, No checkouts have been made under this selection</MuiAlert>}
    {errors && <MuiAlert elevation={6} variant='filled' severity="error">{errors.message}</MuiAlert>}
    </React.Fragment>
  )
}