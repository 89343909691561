import React, { Component } from 'react'
import { connect } from 'react-redux';
import { fetchItems } from '../../actions/itemActions';

import { Table } from 'reactstrap';
import ItemRow from './ItemRow';

class Items extends Component {
  componentDidMount() {
    this.props.fetchItemData();
  }

  render() {
    if (!this.props.items) return <div><h1>Items Page</h1></div>
    return (
      <Table>
        <thead>
            <tr>
                <th>Tag ID</th>
                <th>FNN</th>
                <th>Realm</th>
                <th>Environment</th>
                <th>Exchange ID</th>
                <th>ADSL</th>
                <th>Fibre</th>
                <th>NBN</th>
                <th>Locality</th>
            </tr>
        </thead>
        <tbody>
        {
            this.props.items.map((item) => {
                return <ItemRow item={item}></ItemRow>
            })
        }
        </tbody>
      </Table>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        items: state.items.content
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchItemData: () => dispatch(fetchItems())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Items);