import * as types from './actionTypes';
import {axiosTaasInstance as axios} from "../axios.config";
import { authHeaders } from '../utils/api';
import config from '../config';

const API_URL_GATEWAY = config.tdm_gateway_api.uri;

export const setUserTrainingBadge = (badge) => async dispatch => {
    dispatch ({type: types.SET_USER_TRAINING_BADGE, payload:badge})
}

export const setUserBadges = (value) => async dispatch => {
  dispatch ({type: types.SET_BADGE , badge:value.badge , data:value.data , tooltip:value.userProfiletooltip})
}

export const fetchAllUserTrainingBadges = () => async dispatch => {
  try {
    dispatch({ type: types.FETCH_BADGES_FOR_ALL_USERS_LOADING });
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/data/users/trainingbadges`, { headers: authHeaders() });
    if (response.status === 200) {
      dispatch({ type: types.FETCH_BADGES_FOR_ALL_USERS_SUCCESS, payload: response.data });
    } else if (response.status !== 200) {
      dispatch({ type: types.FETCH_BADGES_FOR_ALL_USERS_FAILED, payload: "Failed to retrieve Championship Training data" });
    }
  }
  catch (e) {
    console.error(e);
    dispatch({ type: types.FETCH_BADGES_FOR_ALL_USERS_FAILED, payload: "Failed to retrieve Championship Training data" });
  }
}