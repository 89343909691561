import * as types from './actionTypes';
import { authHeaders } from "../utils/api";
import {axiosTaasInstance as axios} from "../axios.config";
import config from '../config';
import { displayErrorNew } from '../components/common/alerts/alerts';
import _ from 'lodash';

const API_URL_GATEWAY = config.tdm_gateway_api.uri+"/tdm";

const loadDataS2POptionsSuccess = (payload) => ({ type: types.LOAD_DATA_S2P_FILTER_SUCCESS, payload });
const loadDataS2PSuccess = (payload) => ({ type: types.CHECKOUT_DATA_S2P_SUCCESS, payload });
const getGeneratedFnnsSuccess = (payload) => ({ type: types.REPLICATOR_GET_FNNS_SUCCESS, payload });

export const fetchDataS2POptions = () => async (dispatch) => {
    try {
        const data = {
            prodFilters: ["PSTN", "ISDN2", "ISDN2DID","ISDN30","MOBILE"],
            cosmosProdFilters: ["PSTN","MOBILE","TIPT-SIPC", "ISDN2", "ISDN2DID","ISDN30"],
            actionFilters: ["Insert","Delete","Salesforce Update","Cosmos Insert","Get Records"],
            delLevelFilters: ["CIDN","Service Id"]
        }
       dispatch(loadDataS2POptionsSuccess(data));
    } catch (e) {
        console.error(e);
    }
};

export const selectS2PFilter = (key, value) => dispatch => {
    dispatch({ type: types.SELECT_DATA_S2P_FILTER, key, value });
};

export const saveTableView = (table) => dispatch => {
    dispatch({ type: types.S2P_SAVE_TABLE_VIEW, table });
}

export const addCIDN = (cidn) => dispatch => {
    const payload = { cidn }
    dispatch({
        type: types.S2P_ADD_CIDN,
        payload
    })
}

export const updateDealerPremiseCode = (dealerPremiseCode) => dispatch =>{
    const payload = { dealerPremiseCode }
    dispatch({
        type: types.S2P_ADD_DEALERPREMISECODE,
        payload
    })
}
export const resetSelectedFilters = () => async dispatch => {
    try {
        dispatch({ type: types.LOAD_DATA_FILTER_LOADING });
        dispatch({ type: types.SELECT_DATA_S2P_FILTER_RESET });
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
};

export const removeProductType = (product) => dispatch => {
    dispatch({
        type: types.REMOVE_SELECTED_PRODUCT_TYPE,
        payload: product.toUpperCase()
    })

}

export const submitRequest = (replicatorCheckoutRequest) => async dispatch => {
    try {
        dispatch({ type: types.REPLICATOR_SUBMIT_REQUEST_LOADING, payload: true })
        const cidn = replicatorCheckoutRequest.cidn;
        if (cidn === "GENERATED") {
           replicatorCheckoutRequest.cidn = null;
           if(_.isEmpty(replicatorCheckoutRequest.profile)){
               displayErrorNew({
                    title: "Replicator Error",
                    subTitle: "Can't proceed replicator data insertion",
                    errorMsg: "No Active profile found"
               });  
             return false;
           }
        }
        if(replicatorCheckoutRequest.products !== undefined && replicatorCheckoutRequest.products[0].mobileData.length > 0)
        {
            let sim = replicatorCheckoutRequest.products[0].mobileData[0].sim;
            let updatedSim = sim.substring(6,18) + sim.charAt(sim.length-1);
            replicatorCheckoutRequest.products[0].mobileData[0].sim = updatedSim;
        }
        const response = await axios.post(`${API_URL_GATEWAY}/replicator/insert`, replicatorCheckoutRequest, { headers: authHeaders() });
        dispatch({ type: types.REPLICATOR_SUBMIT_REQUEST_SUCCESS, payload: replicatorCheckoutRequest })
        return response.data;
    } catch (e) {
        let errorMsg = "";
        if (typeof e === "string") errorMsg = e;
        else if (e.response) errorMsg = e.response.data.message;
        else errorMsg = "Network connectivity issues, please try again later.";
        displayErrorNew({
            title: "Replicator Error",
            subTitle: "An error occured while inserting your replicator data!",
            errorMsg
        })
    }
}

export const submitCIDNDeleteRequest = (deleteReplicatorRequest) => async dispatch =>{
    try {
        const response= await axios.post(`${API_URL_GATEWAY}/replicator/delete`, deleteReplicatorRequest, {headers: authHeaders() });           
        return response.data;
    } catch (e) {
        let errorMsg = ""
        if (typeof e === "string") errorMsg = e
        else errorMsg = e.response.data.message
        console.log(errorMsg);     
        displayErrorNew({
            title: "Replicator Error",
            subTitle: "An error occured while deleting your replicator data!",
            errorMsg
        });
    }
}

export const submitServiceIdDeleteRequest = (deleteReplicatorRequest) => async dispatch =>{
    try{
        const response= await axios.post(`${API_URL_GATEWAY}/replicator/delete`, deleteReplicatorRequest, {headers: authHeaders() });           
        return response.data;
    }catch (e) {
        console.log( e)
        let errorMsg = ""
        if(e.response)  errorMsg = e.response.data.message 
        else errorMsg = "Network connectivity issues, please try again later."      
        displayErrorNew({
            title: "Replicator Error",
            subTitle: "An error occured while deleting your replicator data!",
            errorMsg
        })
    }
}

export const validateSite = (cidn, adborId) => async () => {
    try {
        if (cidn === "GENERATED") return true;
        let response = await axios.get(`${API_URL_GATEWAY}/replicator/validate/site?siteId=${adborId}&cidn=${cidn}`, { headers: authHeaders() });
        return response.data;
    } catch (e) {
        throw e
    }

    return false;
}

export const validateService = (serviceId, type) => async () => {
    try {
        if (serviceId === undefined) return true; 
        let response = await axios.get(`${API_URL_GATEWAY}/replicator/validate/service?id=${serviceId}`, { headers: authHeaders() });
        return response.data
    } catch (e) {
        throw e
    }
}


export const validateNetworkId = (networkId, type) => async () => {
    try {
        if (networkId === undefined) return true; 
        let response = await axios.get(`${API_URL_GATEWAY}/replicator/validate/networkId?id=${networkId}`, { headers: authHeaders() });
        return response.data
    } catch (e) {
        throw e
    }
}


export const validatePhysicalId = (physicalId) => async () => {
    try {
        if (physicalId === undefined) return true;
        physicalId = physicalId.substring(6,18) + physicalId.charAt(physicalId.length-1);
        let response = await axios.get(`${API_URL_GATEWAY}/replicator/validate/physicalId?id=${physicalId}`, { headers: authHeaders() });
        return response.data
    } catch (e) {
        throw e
    }
}


export const generateServiceId = (qty, index) => async dispatch => {
    try {
        dispatch({type: types.GENERATE_SERVICE_ID_PENDING, payload: index})
        let response = await axios.get(`${API_URL_GATEWAY}/replicator/generate/serviceIds`, { headers: authHeaders(), params: {
            qty
        } });
        dispatch({type: types.GENERATE_SERVICE_ID_SUCCESS, payload: response.data[0]})
        return Promise.resolve({serviceId: response.data[0]});
    } catch (e) {
        console.error(e);
        dispatch({type: types.GENERATE_SERVICE_ID_FAILED})
        return Promise.reject(new Error("generate service id failed"));
    }
}

export const toggleFax = (faxList) => async dispatch => {
    dispatch({type: types.TOGGLE_FAX, payload: faxList});
}

export const fetchProductTypePlan = () => async dispatch => {
   try {
    const response = await axios.get(`${API_URL_GATEWAY}/replicator/planrefs`,{headers: authHeaders() })
    const productPlans = response.data.planRefs.map((plan) => {
            return { planName: plan.planName ,planType : plan.planType};
           });
    dispatch({type: types.FETCH_COSMOS_PLANS , payload:productPlans})
    }
    catch (e) {

    }
};

export const fetchProductRepayments = () => async dispatch => {
    try{
    const response = await axios.get(`${API_URL_GATEWAY}/replicator/repayments`,{headers: authHeaders() })
    const productRepayments = response.data.repayments.map ((repayments) => {
        return {repaymentID : repayments.id , 
                deviceName : repayments.deviceName ,
                deviceType : repayments.deviceType,
                mroWithNoImei : repayments.mroWithNoImei,
                contactType : repayments.contactType}
    })
    dispatch({type: types.FETCH_COSMOS_REPAYMENTS , payload:productRepayments})
}
catch (e) {}
    /* const productRepayments = response.data.map((repayments) => {
            return { planName: plan.name ,planType : plan.type};
           }); */

}
export const searchSalesForceData = (searchType,searchValue,env) => async dispatch => {
    try{
          let response = await axios.get(`${API_URL_GATEWAY}/salesforce/b2b/search/utility`,
            {headers : authHeaders(),params: {
                searchType,
                searchValue,
                env
             }}) 
            let noRecords = false;
            let serviceID = "";
            let serviceURL = "";
            let Final_Remaining_Amount__c = "";
            let Final_Remaining_Term__c = "";
            let Status__c = "";
            let monthlyAmount = "";
            let payload = [];
            let showSalesforcePanel = false;
            let payloadResponse = response.data;
            let salesforceResponse = {payload,noRecords}

            if((payloadResponse.data.records).length === 0)
            { 
                noRecords = true;
                showSalesforcePanel  = false;
                salesforceResponse = {payload,noRecords}
            }
            else
            {
                showSalesforcePanel = true;
                payload = payloadResponse.data.records[0];
                Final_Remaining_Amount__c = payloadResponse.data.records[0].Final_Remaining_Amount__c;
                Final_Remaining_Term__c = payloadResponse.data.records[0].Final_Remaining_Term__c;
                Status__c = payloadResponse.data.records[0].Status__c;
                monthlyAmount = payloadResponse.data.records[0].monthlyAmount;
                if(payloadResponse.data.records[0].Legacy_Service__r != undefined)
                     {serviceID = payloadResponse.data.records[0].Legacy_Service__r.Service_Id__c;
                     serviceURL = payloadResponse.data.records[0].attributes.url;}
                salesforceResponse = {payload,noRecords}
                
            }
            dispatch({type: types.SEARCH_SALESFORCE_DATA,showSalesforcePanel,payload ,serviceID,serviceURL,noRecords,Final_Remaining_Amount__c,Final_Remaining_Term__c,Status__c,monthlyAmount})
            return salesforceResponse;
    }
    catch (e)
    {
        console.error("Error fetching salesforce Data in searchSalesForceData",e)
    }
}

export const updateSalesForceData = (data) => async dispatch => {
    try{
        let response = await axios.post(`${API_URL_GATEWAY}/salesforce/b2b/search/update`, data,{ headers: authHeaders()});
        if(response.status = "Success")
        return true;
    }
    catch (e)
    {
        console.log("Error Updating salesforce data in searchSalesForceData ",e)
    }
}
export const resetSalesforcePanel = () => async dispatch => {
    try{
        dispatch({type: types.SALESFORCE_SUCCESS})
    }
    catch (e) {
        console.log("Error in resetSalesforcePanel",e)
    }
}
