import React from "react";
import PropTypes from "prop-types";

import { Box, TableRow } from "@material-ui/core";
import TableBodyCell from "./TableBodyCell";

const TableBodyNoDataRow = (props) => {
  const { children, columnLength, fixedNumRows, fixedHeight } = props;
  return (
    <TableRow>
      <TableBodyCell colSpan={columnLength < 6 ? 6 : columnLength}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minHeight={fixedNumRows ? `${fixedHeight+20}px` : '300px'}
        >
          {children}
        </Box>
      </TableBodyCell>
    </TableRow>
  );
};

TableBodyNoDataRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  columnLength: PropTypes.number.isRequired,
  fixedNumRows: PropTypes.bool,
  fixedHeight: PropTypes.number,
};

export default TableBodyNoDataRow;
