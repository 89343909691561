import React, { Component, useRef } from "react";
import _ from 'lodash';
import swal from "sweetalert2";
import { displayLoading } from "../common/alerts/alerts";
import BreadTable from "@library/core/tables/BreadTable";
import { Quantity, Stepper } from "@library/core/form-elements/";
import LeftSidePanel from "@library/core/surfaces/LeftSidePanel";
import Header from "@library/core/surfaces/Header";
import FilterForm from "@library/core/filters/FilterForm";
import SnackbarAlert from "@library/core/alerts/SnackbarAlert";
import { FilterIcon } from "@library/core/icons";
import { CartIconBtn, RoundBtn, SubmitButton } from "@library/core/buttons";
import { stringContainsAllFromArrayFilter, findStringInObjectArrayAndValidate, filterObjectArray } from "@library/core/filters/filter";
import { setLocation } from "../../actions/userFeedbackActions";
import {
  addToCart,
  removeFromCart,
  resetOrderBuilder,
  resetOrderBuilderForm,
  fetchOrderFilters,
  fetchOrderSprints,
  fetchOrderEnvs,
  sendOrder,
  fetchOpenOrders,
  fetchServiceList,
  saveWishlist,
  updateCustomerInput,
  createCustomOrder,
  setCustomOrder,
  removeCustomOrder,
  setSpecificRequirement
} from '../../actions/orderBuilderActions';
import { connect } from 'react-redux';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import RemoveShoppingCartOutlinedIcon from '@material-ui/icons/RemoveShoppingCartOutlined';
import { infoAlert } from "../common/alerts/alerts";
import {
  Typography,
  Tooltip,
  Button,
  Grid,
} from "@material-ui/core";
import CustomerInputForm from "./CustomerInputForm";
import OrderSummary from "./OrderSummary";
import WishlistModal from "./WishlistModal";
import WishlistBtn from "./WishlistBtn";
import OrderScroll from "./OrderScroll";
import CustomOrderStates from "./Custom/CustomOrderStates";
import OrderBuilderTour from "../../tours/OrderBuilderTour";
import { 
  toggleTourRun,
  resumeTour,
  changeTourSection,
  changeTourStep  
} from "../../actions/tourActions";
import OrderBuilderToggle from "./OrderBuilderToggle";
class Order extends Component {

  state = {
    qtys: {},
    checkboxes: [],
    filteredOrderItems: [],
    filterKeywords: [],
    displayAlert: false,
    alertMsg: "",
    alertType: "info",
    alertLoading: false,
    alertAutoHide: 10000,
    reset: false,
    openWishlistModal: false,
    firstLoad: false,
    wishlistValid: true,
    wishlistActive: false,
    customSteps: ["Product selection", "Customer Input", "Order Summary"],
    stateToggled: false,
    orderBuilderType:"Standard"
  }

  stepList = ['Product Selection', 'Customer Input', 'Order Summary'];

  
  currentStepSection = async (stepSection) => {
    if (stepSection === 0) {
        await this.props.toggleTourRun(false);
        setTimeout(() => {
            this.props.changeTourSection(stepSection);
        }, 200);
    }
  }

  validateCart = () => {
    if (_.size(this.props.cart) > 0) {
      this.setAlertMsg("info", "", false, null);
      this.setDisplayAlert(false);
      return true;
    } else {
      this.setAlertMsg("warning", "Need at least 1 item in Cart to continue!", false, 10000);
      this.setDisplayAlert(true);
      return false;
    }
  }

  validateCustomerInput = () => {
    if (this.props.customerInput) {
      if(!this.props.customerInput.irNumber){
        this.setAlertMsg("warning", "Please select a capability", false, 10000);
        this.setDisplayAlert(true);
        return false;
      }
      if (this.props.customerInput.team && this.props.customerInput.reqBy && this.props.customerInput.env) {
        // DISABLED WHILE WE FIND WAY OF HANDLING INDEP FOR TAAS CHANGES
        // if (this.props.customerInput.team.program === "INDEP") {
        //   if (this.props.customerInput.irNumber) {
        //     if (this.state.wishlistValid) {
        //       this.setAlertMsg("", "", false, null);
        //       this.setDisplayAlert(false);
        //       return true;
        //     } else {
        //       this.setAlertMsg("warning", "Require valid wishlist name to proceed", false, 10000);
        //       this.setDisplayAlert(true);
        //       return false;
        //     }
        //   } else {
        //     this.setAlertMsg("warning", "Please Enter IR Number to proceed", false, 10000);
        //     this.setDisplayAlert(true);
        //     return false;
        //   }
        // } else {
          if(this.props.isCustomOrder && !this.props.customerInput.quantityValue){
            this.setAlertMsg("warning", "Require valid quantity value to proceed", false, 10000);
            this.setDisplayAlert(true);
            return false;
          }
          if (this.state.wishlistValid) {
            this.setAlertMsg("info", "", false, null);
            this.setDisplayAlert(false);
            return true;
          } else {
            this.setAlertMsg("warning", "Require valid wishlist name to proceed", false, 10000);
            this.setDisplayAlert(true);
            return false;
          }
        // }
      }
      this.setAlertMsg("warning", "Please select input for team, required by and Environment", false, 10000);
      this.setDisplayAlert(true);
      return false;
    } else {
      this.setAlertMsg("error", "Failed to access customer input!", false, 10000);
      this.setDisplayAlert(true);
      return false;
    }
  }

  resetForm = () => {
    this.props.resetOrderBuilderForm();
    this.setState(prevState => ({
      ...prevState,
      qtys: {},
      openWishlistModal: false,
      reset: true,
    }));
  }

  checkQtyOfExisting = (obj, validateValue) => {
    if (obj.quantity) {
      return obj.quantity >= validateValue;
    } else if (obj.quantityReq && obj.quantityAlct) {
      return (obj.quantityAlct >= validateValue) || (obj.quantityReq >= validateValue);
    } else {
      return false;
    }
  }

  checkIfFulfilledByExisting = async () => {
    let continueSubmission = false;
    const fulfilled = _.map(this.props.cart, (value, key) => {
      const openOrder = findStringInObjectArrayAndValidate(this.props.openOrders, "id", key, this.checkQtyOfExisting, value);
      const existingAsset = findStringInObjectArrayAndValidate(this.props.availableDataAssets, "serviceId", key, this.checkQtyOfExisting, value);
      if ((openOrder || existingAsset) ? true : false) {
        return { id: key, order: openOrder, asset: existingAsset }
      }
    }).filter(val => val);
    if (fulfilled && fulfilled.length > 0) {
      const info = (
        <div>
          <div id="utility-alert-text">
            <p>Existing Order / Asset Data already fulfills some or all of cart do you still wish to continue?</p>
          </div>
        </div>
      );
      await infoAlert({ title: "Warning", confirmBtn: "Agree", info }).then(result => {
        if (result.value) {
          continueSubmission = true;
        } else {
          continueSubmission = false;
        }
      });
    } else {
      continueSubmission = true;
    }
    return continueSubmission;
  }

  redirectToMyOrders = () => {
    this.setDisplayAlert(false);
    this.props.history.push({ pathname: '/data/myOrder' });
  }

  stepValidationList = [this.validateCart, this.validateCustomerInput];

  setAlertMsg = (type, msg, loading, hideDuration) => {
    this.setState(prevState => ({
      ...prevState,
      alertMsg: msg,
      alertType: type,
      alertLoading: loading,
      alertAutoHide: hideDuration,
    }));
  }

  setDisplayAlert = (bool) => {
    this.setState(prevState => ({
      ...prevState,
      displayAlert: bool,
    }));
  }

  componentDidMount() {
    this.props.resetOrderBuilder().then(() => {
      this.setFirstLoad(true);
      this.props.fetchOrderFilters().then(() => {
        this.props.filterOptionsSuccess ?
          this.createFilterOptions() : null;
      });
      this.props.fetchServiceList().then(() => {
        this.filterRows();
      });
      this.props.fetchOrderSprints();
      this.props.fetchOrderEnvs();
    });
    this.props.setLocation("OrderBuilder")
  }

  setFirstLoad = (bool) => {
    this.setState(prevState => ({
      ...prevState,
      firstLoad: bool,
    }));
  }

  onChangeQty = (newQty, id) => {
    if (id != null) {
      this.setState(prevState => ({
        ...prevState,
        qtys: {
          ...prevState.qtys,
          [id]: newQty,
        }
      }));
    }
  }

  onAddToCart = (serviceId, qauntity) => {
    let qty = qauntity ? qauntity : (this.state.qtys[serviceId] ? this.state.qtys[serviceId] : 0);
    this.props.addToCart(serviceId, qty).then(() => {
      this.filterRows();
    });
  }

  onRemoveFromCart = (serviceId) => {
    if (this.state.qtys[serviceId]) {
      this.onChangeQty(0, serviceId);
    }
    if (this.props.cart[serviceId]) {
      this.props.removeFromCart(serviceId).then(() => {
        this.filterRows();
      });
    }
  }

  handleCheckboxGroupChange = (checkStatus, index) => {
    let prevCheckboxes = this.state.checkboxes;
    let checkbox = prevCheckboxes[index];
    checkbox.checked = checkStatus;
    prevCheckboxes[index] = checkbox;
    this.setState(prevState => ({
      ...prevState,
      checkboxes: [
        ...prevCheckboxes,
      ],
    }));
  }

  clearFilters = () => {
    let prevCheckboxes = this.state.checkboxes;

    prevCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    this.setState(prevState => ({
      ...prevState,
      filterKeywords: [],
      checkboxes: [
        ...prevCheckboxes,
      ],
    }));
    this.filterRows();
  }

  createFilterList = () => {
    let filterWords = [];
    this.state.checkboxes.forEach((checkbox) => {
      checkbox.checked ? filterWords.push(checkbox.value) : null;
    });
    this.setState(prevState => ({
      ...prevState,
      filterKeywords: filterWords,
    }));
    return filterWords;
  }

  createFilterOptions = () => {
    let formattedFilters = [];
    this.props.filterOptions.map(filter => {
      formattedFilters.push({
        label: filter,
        value: filter,
        checked: false,
      })
    });
    this.setState(prevState => ({
      ...prevState,
      checkboxes: formattedFilters,
    }));
  }

  filterRows = () => {
    let filterWords = this.createFilterList();
    let filteredItems = [];
    let filteredCartItems = [];
    this.props.orderItems.forEach((item) => {
      if (filterWords.length > 0) {
        if (stringContainsAllFromArrayFilter(item.serviceDescription, filterWords)) {
          if (this.props.cart[item.id]) {
            filteredCartItems.push(item);
          } else {
            filteredItems.push(item);
          }
        }
      } else {
        if (this.props.cart[item.id]) {
          filteredCartItems.push(item);
        } else {
          filteredItems.push(item);
        }
      }
    });
    this.setState(prevState => ({
      ...prevState,
      filteredOrderItems: [...filteredCartItems, ...filteredItems],
    }));
  }

  onCartClick = () => {
    this.setState(prevState => ({
      ...prevState,
      reset: !prevState.reset
    }));
  }

  showWishListModal = async (bool) => {
    const changeStep = (step) => {
      if (step === 2 || step === 3) {
        return ++step
      } else {
        return step
      }
    }
    if (bool) {
      this.setState(prevState => ({
        ...prevState,
        openWishlistModal: bool
      }));
      setTimeout(() => {
        this.props.changeTourStep(this.props.tourRun, changeStep(this.props.tourStepIndex));
      }, 800);
    } else {
      await this.props.changeTourStep(this.props.tourRun, changeStep(this.props.tourStepIndex));
      this.setState(prevState => ({
        ...prevState,
        openWishlistModal: bool
      }));
    }
  }

  setOpenWishlistModal = (bool) => {
    this.setState(prevState => ({
      ...prevState,
      openWishlistModal: bool
    }));
  }

  prepopOrder = (wishlistName) => {
    const filteredWishlists = filterObjectArray(this.props.wishlists, 'wishlistName', wishlistName);
    const orderDetails = filteredWishlists[0];
    const cart = filteredWishlists[0].cartDetails;
    this.resetForm();
    if (orderDetails) {
      let programTeamObj = filterObjectArray(this.props.listOfTeams, 'teamId', orderDetails.teamId);
      let envNameObj = _.map(this.props.envOptions, (value, key) => {
        if (key && value && value === orderDetails.environment) {
          return key;
        }
      }).filter(val => val);
      let team = orderDetails.teamName && orderDetails.teamId ?
        {
          label: orderDetails.teamName,
          value: orderDetails.teamId,
          program: (this.props.listOfTeams && this.props.auth && this.props.auth.team && programTeamObj) ? programTeamObj.teamParentProgram : ""
        } : "";
      let env = orderDetails.environment && envNameObj && envNameObj.length > 0 ? { label: envNameObj[0], value: orderDetails.environment } : "";
      let customerInputData = {
        team: team,
        env: env,
      }
      this.props.updateCustomerInput(customerInputData);
    }
    if (cart) {
      this.prepopCart(cart);
    }
    this.showWishListModal(false);
  }

  prepopCart = (cart) => {
    for (const item of cart) {
      this.onChangeQty(item.quantity, item.serviceId);
      this.onAddToCart(item.serviceId, item.quantity);
    }
  }

  isWishlistValid = (isValid) => {
    this.setState(prevState => ({
      ...prevState,
      wishlistValid: isValid,
    }));
  }
  isWishlistActive = (isActive) => {
    this.setState(prevState => ({
      ...prevState,
      wishlistActive: isActive,
    }));
  }

  args = {
    expandTitle: "Details",
    size: "medium",
    isExpandable: true,
    columnMetadata: [
      {
        order: 1,
        title: "Service Id",
        field: ["id"],
        expandable: false,
        component: (field) => <RoundBtn>{field}</RoundBtn>,
      },
      {
        order: 2,
        title: "Service Description",
        field: ["serviceDescription"],
        expandable: true,
        component: (field) => (
          <Typography style={{ "width": "190px", "margin": "auto" }} noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 3,
        title: "Acceptance Criteria",
        field: ["acceptanceCriteria"],
        expandable: true,
        component: (field) => (
          <Typography style={{ "width": "295px", "margin": "auto" }} noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 4,
        title: "Quantity",
        field: ["id"],
        expandable: false,
        component: (id) => (
          <Quantity qty={this.state.qtys[id]}
            onChangeQty={this.onChangeQty}
            id={id}
            disabled={this.props.cart[id] ? true : false}
          />
        ),
      },
      {
        order: 5,
        title: "",
        field: ["id"],
        expandable: false,
        component: (field) => (
          <div style={{ 'minWidth': '150px' }}>
            <Tooltip title={!this.state.qtys[field] ? "Qty Required" : "Add to Cart"}>
              <span>
                <SubmitButton style={{ "width": "45%", "marginRight": "10px" }}
                  disabled={(!this.props.cart[field] && !this.state.qtys[field] || this.props.cart[field]) ? true : false}
                  onSubmit={!this.props.cart[field] ? () => this.onAddToCart(field) : () => this.onCartClick()}
                >
                  <AddShoppingCartOutlinedIcon />
                </SubmitButton>
              </span>
            </Tooltip>
            <Tooltip title={"Remove from Cart"}>
              <span>
                <SubmitButton style={{ "width": "45%" }}
                  disabled={!this.props.cart[field]}
                  onSubmit={() => this.onRemoveFromCart(field)}
                  color={'secondary'}
                >
                  <RemoveShoppingCartOutlinedIcon />
                </SubmitButton>
              </span>
            </Tooltip>
          </div>
        )
      },
    ],
    filterComponent: () => <LeftSidePanel
      panelTitle={"Filter Products"}
      disable={this.props.filterOptionsFailed || (this.state.checkboxes && this.state.checkboxes.length === 0)}
      panelIconComponent={
        <Tooltip arrow placement="right"
          title={
            this.props.filterOptionsPending ? "Loading filters..." :
              this.props.filterOptionsSuccess && (this.state.checkboxes && this.state.checkboxes.length > 0) ? "Open filter menu" : "Filters unavailable"
          }
        >
          <span>
            <FilterIcon id="tour-orderbuilder-tableFilter" size="medium" disabled={this.props.filterOptionsFailed || this.props.filterOptionsPending || (this.state.checkboxes && this.state.checkboxes.length === 0)} />
          </span>
        </Tooltip>
      }
    >
      <FilterForm
        checkBoxFilterData={this.state.checkboxes}
        handleCheckboxGroupChange={this.handleCheckboxGroupChange}
        checkBoxSectionTitle={"Products"}
        handleSubmit={this.filterRows}
        handleClear={this.clearFilters}
      />
    </LeftSidePanel>
  }

  orderSubmission = async () => {
    displayLoading({ title: "Submitting your order", bodyText: "Please standby..." });
    const cart = this.props.cart;
    const custInput = this.props.customerInput;
    this.checkIfFulfilledByExisting().then((continueSubmit) => {
      if (continueSubmit) {
        this.resetForm();
        let wishlist = {
          cartDetails: []
        };
        if (custInput && custInput.env) {
          wishlist.environment = custInput.env.value;
        }
        if (custInput && custInput.team && custInput.team.label && custInput.team.value) {
          wishlist.teamName = custInput.team.label;
        }
        if (custInput && custInput.team && custInput.team.label && custInput.team.value) {
          wishlist.teamId = custInput.team.value;
        }
        if (custInput && custInput.wishlist) {
          wishlist.wishlistName = custInput.wishlist;
        }
        const cartItems = _.map(cart, (value, key) => {
          let respObj = {
            requestedQty: value,
            serviceId: key,
            reporter : this.props.user
          }

          wishlist.cartDetails.push({ serviceId: key, quantity: value });
          let description;
          if (this.props.orderItems && this.props.orderItems.length > 0) {
            const orderDesc = filterObjectArray(this.props.orderItems, 'id', key);
            description = orderDesc.length > 0 && orderDesc[0].serviceDescription ? orderDesc[0].serviceDescription : "";
          }
          if (custInput && custInput.comment) {
            respObj.description=`${description} \n ___ \n ${custInput.comment}`;
          } else{
            respObj.description=description;
          }
          if (custInput && custInput.env) {
            respObj.environment = custInput.env.value;
          }
          if (custInput && custInput.team && custInput.team.label && custInput.team.value) {
            respObj.teamName = `${custInput.team.value}-${custInput.team.label}`;
          }
          if (custInput && custInput.irNumber) {
            respObj.irNumber = custInput.irNumber;
          }
          if (custInput && custInput.reqBy && custInput.reqBy.value) {
            respObj.sprint = custInput.reqBy.value;
          }
          if (custInput && custInput.quantityValue) {
            respObj.requestedQty = custInput.quantityValue;
          }
          return respObj;
        });
        const payload = {
          tickets: [
            ...cartItems,
          ]
        }
        this.props.sendOrder(payload).then(() => {
          if (this.props.orderSuccess) {
            if (custInput.wishlist) {
              this.props.saveWishlist(wishlist).then(() => {
                if (this.props.wishlistSuccess) {
                  swal.fire("Success", 'Successfully submitted order!', "success");
                  this.redirectToMyOrders();
                } else {
                  swal.fire("Success", 'Successfully submitted order!', "success");
                  this.redirectToMyOrders();
                }
              });
            } else {
                swal.fire("Success", 'Successfully submitted order!', "success");
                this.redirectToMyOrders();
            }
          } else {
            this.setAlertMsg("error", "Failed to submit order!", false, 15000);
            this.setDisplayAlert(true);
          }
        });
      }
    });
  }

  submitCustomOrder = () => {
    displayLoading({ title: "Submitting your order", bodyText: "Please standby..." });
    const custInput = this.props.customerInput;
    document.getElementsByClassName('tour-library-stepper-nextBtn')[0].disabled=true;
    let stateLessTypeKey = Object.keys(this.props.custom_spec_req);
    let stateLessTypeValue = Object.values(this.props.custom_spec_req);
    let description='';
    if(stateLessTypeValue[0] && custInput.comment[0]){
      if(stateLessTypeValue[0]===custInput.comment[0]){
        description=stateLessTypeValue[0];
      }else{
        description=`${stateLessTypeValue[0]} \n ___ \n ${custInput.comment}`;
      }
    }else{
      if(stateLessTypeValue[0]){
        description=stateLessTypeValue[0];
      } else{
        if(Array.isArray(custInput.comment)){
          description=custInput.comment[0];
        }else{
          description=custInput.comment;
        }
      } 
    }
    const ticketData = {
      "tickets": [
        {
          "requestedQty": custInput.quantityValue,
          "serviceId": null,
          description,
          "environment": this.props.customerInput.env.value,
          "teamName": this.props.customerInput.team.value + "-" + this.props.customerInput.team.label,
          "summary": stateLessTypeKey[0],
          "sprint": this.props.customerInput.reqBy.value,
          "irNumber": this.props.customerInput.irNumber,
          "reporter" : this.props.user
        }
      ]
    }
    let wishlist = {
      cartDetails: []
    };
    if (custInput && custInput.env) {
      wishlist.environment = custInput.env.value;
    }
    if (custInput && custInput.team && custInput.team.label && custInput.team.value) {
      wishlist.teamName = custInput.team.label;
    }
    if (custInput && custInput.team && custInput.team.label && custInput.team.value) {
      wishlist.teamId = custInput.team.value;
    }
    if (custInput && custInput.wishlist) {
      wishlist.wishlistName = custInput.wishlist;
    }

    this.props.createCustomOrder(ticketData).then((response) => {
      if (response === true) {
        if (custInput.wishlist) {
          this.props.saveWishlist(wishlist).then(() => {
            if (this.props.wishlistSuccess) {
                swal.fire("Success", 'Successfully submitted order!', "success");
                this.redirectToMyOrders();
              document.getElementsByClassName('tour-library-stepper-nextBtn')[0].disabled=false;
            } else {
              swal.fire("Success", 'Successfully submitted order!', "success");
              this.redirectToMyOrders();
              document.getElementsByClassName('tour-library-stepper-nextBtn')[0].disabled=false;
            }
          });
        }
        else {
          swal.fire("Success", 'Successfully submitted order!', "success");
          this.redirectToMyOrders();
          document.getElementsByClassName('tour-library-stepper-nextBtn')[0].disabled=false;
        }

      }
      else {
        swal.fire("Error", 'Failed to submit order!', "error");
        this.redirectToMyOrders();
        document.getElementsByClassName('tour-library-stepper-nextBtn')[0].disabled=false;
      }
    });
  }

  enableBackHandler = () => {
    this.props.setSpecificRequirement({}, "")
    this.props.setCustomOrder(false);
    this.props.removeCustomOrder();
    this.props.history.push({ pathname: '/data/order' });
  }

  changeOrderBuilder=()=>{
    this.props.setCustomOrder(!this.props.isCustomOrder);
    let type=this.state.orderBuilderType === "Custom" ? "Standard" : "Custom";
    this.setState({...this.state,orderBuilderType:type})
  }
  
  render() {
    return (
      <>
      <div className="white footer">
            <OrderBuilderTour 
              openWishlistModal={this.state.openWishlistModal}
              wishlistActive={this.state.wishlistActive}
              setOpenWishlistModal={this.setOpenWishlistModal}
            />
            <Header
              title={"Order Builder"}
              topRightIcon={
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item>
                    <div className="tour-orderbuilder-cartBtn">
                      <CartIconBtn onBtnClick={this.onCartClick} badgeCount={this.props.cart ? Object.keys(this.props.cart).length : 0} />
                    </div>
                  </Grid>
                  <Grid item className="tour-orderbuilder-wishlistBtn">
                    <Tooltip
                      title={(this.props.orderItemsPending || this.props.orderItemsFailed) ? "No items to add to cart!" :
                        (this.props.envOptionsPending || this.props.envOptionsFailed) ? "Failed to retrieve necessary data" : "Wishlists"}>
                      <span>
                        <WishlistBtn
                          onBtnClick={() => this.showWishListModal(true)}
                          disabled={this.props.orderItemsPending || this.props.orderItemsFailed || this.props.envOptionsPending || this.props.envOptionsFailed}
                        />
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
              }
            />
            <OrderBuilderToggle orderBuilderType={this.state.orderBuilderType} changeOrderBuilder={this.changeOrderBuilder}/>
        {this.props.isCustomOrder ?
          <>
            <Stepper
              resetCallBack={this.onCartClick}
              resetSteps={this.state.reset}
              steps={this.state.customSteps}
              stepValidationList={this.stepValidationList}
              setDisplayErrorOnNext={this.setDisplayAlert}
              error={this.state.alertType === "error" || this.state.alertType === "warning"}
              submitFunction={this.submitCustomOrder}
              enableBack={true}
              enableBackHandler={this.enableBackHandler}
              finalBtnText={'Submit'}
              stepsComponents={[<div><CustomOrderStates /></div>,
              <CustomerInputForm
                setError={this.setAlertMsg}
                firstLoad={this.state.firstLoad}
                setFirstLoad={this.setFirstLoad}
                wishlistValid={this.isWishlistValid}
              />, <OrderSummary orderBuilderType={this.state.orderBuilderType}/>]} />
              <SnackbarAlert
                isOpen={(this.state.alertMsg && this.state.displayAlert) ? true : false}
                severity={this.state.alertType}
                message={this.state.alertMsg}
                position={{ horizontal: 'center', vertical: 'bottom' }}
                autoCloseDuration={this.state.alertAutoHide}
                loading={this.state.alertLoading}
                handleClose={() => {
                  this.setAlertMsg("info", "", false, 5000);
                  this.setDisplayAlert(false);
                }}
                actions={
                  (this.props.wishlistSuccess || this.props.wishlistFailed) && this.props.orderSuccess ||
                    (!this.props.wishlistSuccess && !this.props.wishlistFailed && !this.props.wishlistPending) && this.props.orderSuccess ?
                    <>
                      <Button color="inherit" size="small" onClick={() => this.redirectToMyOrders()}>
                        View Orders
                      </Button>
                    </> : <></>
                }
            />
          </>
          :
          <>
            <Stepper
              resetCallBack={this.onCartClick}
              resetSteps={this.state.reset}
              steps={this.stepList}
              stepValidationList={this.stepValidationList}
              setDisplayErrorOnNext={this.setDisplayAlert}
              error={this.state.alertType === "error" || this.state.alertType === "warning"}
              submitFunction={this.orderSubmission}
              finalBtnText={'Submit'}
              currentStepCallback={this.currentStepSection}
              stepsComponents={[
                <> 
                  <div id="tour-orderbuilder-orderTable">
                    <BreadTable
                      {...this.args}
                      data={this.state.filteredOrderItems}
                      isLoading={this.props.orderItemsPending}
                      noDataMsg={this.props.orderItemsFailed ? "Failed to retrieve products" : "No Products Available"}
                    />
                  </div>
                </>, // Step 1. Item selection / cart
                <CustomerInputForm
                  key={'custInput'}
                  setError={this.setAlertMsg}
                  firstLoad={this.state.firstLoad}
                  setFirstLoad={this.setFirstLoad}
                  wishlistValid={this.isWishlistValid}
                  resumeTour={this.props.resumeTour}
                  isWishlistActive={this.isWishlistActive}
                  setDisplayAlert={this.setDisplayAlert}
                />, // Step 2. Customer Information                    
                <OrderSummary orderBuilderType={this.state.orderBuilderType} key={'orderSummary'} resumeTour={this.props.resumeTour}/>, // Step 3. Order Summary
              ]}
            />
            <SnackbarAlert
              isOpen={(this.state.alertMsg && this.state.displayAlert) ? true : false}
              severity={this.state.alertType}
              message={this.state.alertMsg}
              position={{ horizontal: 'center', vertical: 'bottom' }}
              autoCloseDuration={this.state.alertAutoHide}
              loading={this.state.alertLoading}
              handleClose={() => {
                this.setAlertMsg("info", "", false, 5000);
                this.setDisplayAlert(false);
              }}
              actions={
                (this.props.wishlistSuccess || this.props.wishlistFailed) && this.props.orderSuccess ||
                  (!this.props.wishlistSuccess && !this.props.wishlistFailed && !this.props.wishlistPending) && this.props.orderSuccess ?
                  <>
                    <Button color="inherit" size="small" onClick={() => this.redirectToMyOrders()}>
                      View Orders
                    </Button>
                  </> : <></>
              }
            />
            <WishlistModal openWishlistModal={this.state.openWishlistModal} showWishListModal={this.showWishListModal} prepopOrder={this.prepopOrder} />
          </>}</div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    listOfTeams: state.auth.myAvailableTeams,
    auth: state.auth,
    cart: state.orderBuilder.cart,
    customerInput: state.orderBuilder.customerInput,
    user: state.auth.userName,

    orderItems: state.orderBuilder.orderItems,
    orderItemsPending: state.orderBuilder.orderItemsPending,
    orderItemsFailed: state.orderBuilder.orderItemsFailed,
    orderItemsFailedMsg: state.orderBuilder.orderItemsFailedMsg,

    filterOptions: state.orderBuilder.filterOptions,
    filterOptionsPending: state.orderBuilder.filterOptionsPending,
    filterOptionsFailed: state.orderBuilder.filterOptionsFailed,
    filterOptionsFailedMsg: state.orderBuilder.filterOptionsFailedMsg,
    filterOptionsSuccess: state.orderBuilder.filterOptionsSuccess,

    orderPending: state.orderBuilder.orderPending,
    orderFailed: state.orderBuilder.orderFailed,
    orderFailedMsg: state.orderBuilder.orderFailedMsg,
    orderSuccess: state.orderBuilder.orderSuccess,

    openOrdersPending: state.orderBuilder.openOrdersPending,
    openOrders: state.orderBuilder.openOrders,
    availableDataAssetsPending: state.orderBuilder.availableDataAssetsPending,
    availableDataAssets: state.orderBuilder.availableDataAssets,

    wishlists: state.orderBuilder.wishlists,
    wishlistPending: state.orderBuilder.wishlistPending,
    wishlistFailed: state.orderBuilder.wishlistFailed,
    wishlistFailedMsg: state.orderBuilder.wishlistFailedMsg,
    wishlistSuccess: state.orderBuilder.wishlistSuccess,

    envOptions: state.orderBuilder.envOptions,
    envOptionsPending: state.orderBuilder.envOptionsPending,
    envOptionsFailed: state.orderBuilder.envOptionsFailed,
    isCustomOrder: state.orderBuilder.isCustomOrder,
    custom_spec_req: state.orderBuilder.custom_spec_req,
    custom_spec_sID: state.orderBuilder.custom_spec_sID,

    tourRun: state.tour.tourRun,
    tourStepIndex: state.tour.tourStepIndex,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLocation: (path) => dispatch(setLocation(path)),
    addToCart: (key, value) => dispatch(addToCart(key, value)),
    removeFromCart: (key) => dispatch(removeFromCart(key)),
    resetOrderBuilder: () => dispatch(resetOrderBuilder()),
    resetOrderBuilderForm: () => dispatch(resetOrderBuilderForm()),
    fetchServiceList: () => dispatch(fetchServiceList()),
    fetchOrderFilters: () => dispatch(fetchOrderFilters()),
    fetchOrderSprints: () => dispatch(fetchOrderSprints()),
    fetchOrderEnvs: () => dispatch(fetchOrderEnvs()),
    sendOrder: (payload) => dispatch(sendOrder(payload)),
    saveWishlist: (payload) => dispatch(saveWishlist(payload)),
    fetchOpenOrders: (teamId, teamName, env, serviceIds) => dispatch(fetchOpenOrders(teamId, teamName, env, serviceIds)),
    updateCustomerInput: (input) => dispatch(updateCustomerInput(input)),
    createCustomOrder: (input) => dispatch(createCustomOrder(input)),
    setCustomOrder: (input) => dispatch(setCustomOrder(input)),
    removeCustomOrder: () => dispatch(removeCustomOrder()),
    setSpecificRequirement: (value, sID) => dispatch(setSpecificRequirement(value, sID)),

    toggleTourRun: (bool) => dispatch(toggleTourRun(bool)),
    resumeTour: (step, section) => dispatch(resumeTour(step, section)),
    changeTourSection: (section) => dispatch(changeTourSection(section)),
    changeTourStep: (run, stepIndex) => dispatch(changeTourStep(run, stepIndex)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);