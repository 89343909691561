import React, {Fragment} from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {connect} from 'react-redux';
import Select from "react-select";
import swal from 'sweetalert2';
import { isNumeric } from 'validator';
import { displayLoading } from "../common/alerts/alerts";
import {ToggleButtonLabel} from "./StandardDataBankMyTeam"
import _ from "lodash";
import FailedView from "./FailedView";
import { fetchClassTechOptions, allocateToTeam, getTeamSource, getUserSource, getTeamUserTarget,fetchExchangeIDs,
  checkoutServiceFilters, checkoutServiceFilterValue, getSourceTeam, getCustomSourceTeam
} from '../../actions/standardDataMyTeamsActions';
import AllocateFilterRow from './AllocateFilterRow';
import { Grid } from '@material-ui/core';

 class AllocateTeamModal extends React.Component {
  state = {
    modal: false,
    quantity: 0,
    inputUserStory:'',
    isUserStoryInvalid: false,
    targetTeam: null,
    isQtyInvalid: false,
    allocationComments: '',
    sourceTeam: null,
    classTeam: null,
    techTeam: null,
    sourceTeamOptions: [],
    sourceUserToggle:false,
    targetUserToggle:false,
    selectedServiceId:null,
    exchangeId: null,
    serviceQty : 0,
    serviceFilters: [],
    serviceFilterRows: [],
    filterRequestBody: {},
    prevFilterData: [],
    filterCheckoutData: []
  };

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }

  exchangeToggle = () => {
    this.setState(prevState => ({
      exchangeId: null,
    }))
  }

  toggle = () => {
    this.setState(prevState => ({
      ...prevState,
      quantity: 0,
      inputUserStory:'',
      isUserStoryInvalid: false,
      sourceTeam: null,
      targetTeam: null,
      modal: !prevState.modal,
      isQtyInvalid: false,
      allocationComments: '',
      sourceTeamOptions: [],
      classTeam: null,
      techTeam: null,
      selectedServiceId:null,
      sourceUserToggle:false,
      targetUserToggle:false,
      exchangeId: null,
      serviceQty: 0,
      errorMsg: '',
      serviceFilters: [],
      serviceFilterRows: [],
      filterRequestBody: {},
      prevFilterData: [],
      filterCheckoutData: []
    }));
  };
  isValidServiceIdForClassTech = (serviceId) => {
    const {selectedToggleButton} = this.props;
    return selectedToggleButton === ToggleButtonLabel.STANDARD && (serviceId === 6 || (serviceId >= 104 && serviceId <= 130));
  }

    open = () => {
        const {selectedToggleButton, selectedEnvValue, loggedInUser, allTeams} = this.props;
        this.setState({modal: true});
        this.props.getTeamSource(selectedEnvValue, selectedToggleButton, loggedInUser, allTeams);
    };
    onAddNewFilter = (tempFilterArray) => {

      this.setState(prevState => ({
        ...prevState,
        serviceFilterRows: [...prevState.serviceFilterRows,
        <AllocateFilterRow tempFilterArray={tempFilterArray}
         onRemoveRow={this.onRemoveRow}
         filterCheckoutData={this.state.filterCheckoutData}
         onAddNewFilter={this.onAddNewFilter}
           onChangeFilterField={this.onChangeFilterField}
           onChangeFilterFieldValue={this.onChangeFilterFieldValue}
           arrayPosition={prevState.serviceFilterRows.length}/>]
      }));
    }

    onChangeFilterFieldValue = (value) => {
      let newPrevFilterEntry = {
        resourceName: value.fieldResourceName,
        filterName: value.parameterName,
        filterValue: value.value,
      }
      let filterCheckoutData = {
        resourceName: value.fieldResourceName,
        filterName: value.parameterName,
        filterValue: value.value,
        searchParameterName : value.parameterName
      }
      this.setState(prevState => ({
        ...prevState,
        prevFilterData: [...prevState.prevFilterData.filter(e => (e.resourceName+e.filterName != value.table+value.fieldColumnName)), newPrevFilterEntry],
        filterCheckoutData: [...prevState.filterCheckoutData.filter(e => (e.resourceName+e.filterName != value.table+value.fieldColumnName)), filterCheckoutData]
      }));
    }
    onRemoveRow = (position) => {
      this.setState({
        serviceFilterRows : [...this.state.serviceFilterRows.slice(0,position)],
        filterCheckoutData : [...this.state.filterCheckoutData.slice(0,position)]
      })
    }

    createFilterPayload = (value) => {

      let newEntry = {
        resourceName: value.resource,
        filterName: value.parameterName
      }
      let payload = {
        teamId: this.state.sourceTeam?.value,
        serviceId: this.state.selectedServiceId.value,
        environment: this.props.selectedEnvValue,
        previousFiltersData: [
          ...this.state.prevFilterData
        ],
        filterColumn: newEntry
      }
      this.setState(prevState => ({
        ...prevState,
        filterRequestBody: payload
      }));

      return payload;
    }
    onChangeFilterField = (value, {action}) => {
       if (action === "select-option") {
        this.props.checkoutServiceFilterValue(this.createFilterPayload(value));
      }
    }
    onServiceIdChange = (selectedServiceId) => {
      this.exchangeToggle();
      const {allocateSourceData, allTeams} = this.props;
      if(selectedServiceId){
        this.props.getSourceTeam(selectedServiceId.value,this.state.targetUserToggle,allTeams,this.props.selectedToggleButton);
      }
      let exchangeQty = 0;
      const prepareOptions =  selectedServiceId ? allocateSourceData.source.service.filter((sd)=> sd.serviceId === selectedServiceId.value):[];
      const sourceTeamOptions = prepareOptions.map(option=>({ value: option.teamId, label: option.teamName, quantity: option.quantity }));
      const sourceTeam = sourceTeamOptions.find(option => option.value === allocateSourceData.source.defaultSourceId);
      exchangeQty = sourceTeam && sourceTeam.quantity || 0;
      if(this.isValidServiceIdForClassTech(selectedServiceId && selectedServiceId.value)) {
        this.props.fetchClassTechOptions();
      }
      
      /* Filter Functionality is Start */
      this.filtersToggle();
      let tempFilterArray = [];

      if(selectedServiceId !== null && selectedServiceId !== this.state.selectedServiceId)
      {
        if(this.props.selectedToggleButton==="Standard"){
          this.props.handleServiceFilterCheckout(selectedServiceId).then((response) => {
            response !== undefined && response.forEach(resource => {
              tempFilterArray = [...tempFilterArray ,{parameterName :resource.parameterName,
                column: resource.column,
                filterName: resource.filterName,
                tableName: resource.resourceTableName,
                resourceName: resource.resourceName }];
  
              })
               this.setState({
                 serviceFilterRows : [...this.state.serviceFilterRows.slice(0,0)]
               })
              this.onAddNewFilter(tempFilterArray);
  
          })}
        }
        else {
          if(selectedServiceId == null)
          {
            this.onRemoveRow(1)
            tempFilterArray = []
            this.setState({
              serviceFilterRows : [...this.state.serviceFilterRows.slice(0,0)]
            })
          }
        }
/* Filter Functionality is End */
      this.setState({
        selectedServiceId:selectedServiceId,
        serviceFilters: tempFilterArray,
        sourceTeam, sourceTeamOptions, targetTeam:null, exchangeId:null,
            serviceQty:exchangeQty,classTeam: null, techTeam: null,
            targetUserToggle:false
      })

    }
    dt=_.debounce(function() {
      const {allTeams} = this.props;
      if(this.state.inputUserStory){
          if(this.state.inputUserStory){
            this.props.getSourceTeam(this.state.inputUserStory,this.state.targetUserToggle,allTeams,this.props.selectedToggleButton);
          }
        }  
      }, 1000)
    
    onUserStoryChange = (e) => {
      const userStory = e.target.value;
      let isUserStoryInvalid = false;
      if(userStory && !_.isEmpty(userStory)){
        isUserStoryInvalid = true;
      }
      this.setState(
        (preState) => {
        return { ...preState, inputUserStory:userStory, isUserStoryInvalid }
      },
      ()=>{
       this.dt();
      }
      )
    }

    
    setExchangeId = () => {
      this.props.fetchExchangeIDs(this.props.selectedEnvValue);
  }
    onChangeSourceTeam = sourceTeam => {
      this.exchangeToggle();
      let selectedServiceId = this.state.selectedServiceId;
      const popUpServiceIDs = this.props.filterServiceIds;
      let serviceQty = 0;
      serviceQty = sourceTeam && sourceTeam.quantity;

      let selectedSerID = selectedServiceId? popUpServiceIDs.filter((serviceID) =>  serviceID === selectedServiceId.value): []
      if (selectedSerID[0] !== undefined && selectedSerID[0] !== 3 && selectedSerID[0] !==4 && sourceTeam) {
        this.setExchangeId();
      }
      this.setState({sourceTeam, targetTeam: null, serviceQty: sourceTeam?.quantity});
      const selectedSource = sourceTeam?.value && sourceTeam?.label;
      if(this.props.allTeams.length===0){
        this.props.getTeamUserTarget(this.state.targetUserToggle, selectedSource?sourceTeam:null, this.props.userData?.content, this.props.allTeams);
      }
    };

    onChangeTargetTeam = targetTeam => {
      this.setState({targetTeam});
    };

    filtersToggle = () =>
    {
      this.setState({
        serviceFilters : []
      })
    }

  onChangeClass = classTeam => {
    this.setState((preState) => ({ ...preState, classTeam, techTeam: null }))
  };

  onChangeTech = techTeam => {
    this.exchangeToggle();
    this.setState((preState) => ({ ...preState, techTeam }))
  };

  customStyles = {
    valueContainer: provided => ({
      ...provided,
      display: "block",
      width: "100px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: "0.8rem",
      height: "43px"
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: state.hasValue ? "none" : "inherit",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: state.hasValue ? "none" : "inherit"
    })
  }

  onQtyChange = (e) => {
    const maxQty = this.state.serviceQty
    const currentQty = e.target.value;

    let isQtyInvalid = false;
    if (!isNumeric(currentQty) || currentQty > maxQty || !currentQty || (currentQty === "0")) {
      isQtyInvalid = true;
    }
    this.setState((preState) => {
      return { ...preState, quantity: currentQty, isQtyInvalid }
    })
  }

  onAllocationCommentsChange = (e) => {
    this.setState({allocationComments:e.target.value});
  }

  handleAllocateToTeam = () => {
    displayLoading({ title: "Allocating to team", bodyText: "Please standby" });
    const {loggedInUser} = this.props;
    const { classTeam, techTeam, sourceTeam, selectedServiceId, quantity, targetTeam,
      allocationComments , sourceUserToggle, targetUserToggle,exchangeId,filterCheckoutData, inputUserStory} = this.state;
    let sourceReq = sourceUserToggle?{sourceUser:sourceTeam.value} : {sourceTeam: sourceTeam?.value}
    let targetReq = targetUserToggle?{targetUser:targetTeam.value} : {targetTeam: targetTeam?.value}
    let filter = [];
    if (filterCheckoutData && filterCheckoutData.length>0) {
      filter = filterCheckoutData.map(data=>(
        {
          "operator": "equals",
            "resourceName": data.resourceName,
            "searchParameterName": data.searchParameterName,
            "searchParameterValue": data.filterValue,
        }
      ));
    }
    const scope = "TEAM";
    let logInUser = {loggedInUser};
    const dNumber = logInUser.loggedInUser.userName;
    const team = parseInt(logInUser.loggedInUser.team);
    let service=this.props.allocateSourceData.source.service;
    const request = {
      ...sourceReq,
      ...targetReq,
      quantity,
      scope,
      dNumber,
      team,
      exchangeId,
      environment:this.props.selectedEnvValue,
      allocationComments: allocationComments.trim(),
      filter
    }
    let scenarioName=''
    let scenarioNameIteam=service.find((_item)=> request.teamId ===_item.sourceTeam);
    if(scenarioNameIteam){
      scenarioName=scenarioNameIteam.scenarioName
    }
    request.scenarioName=scenarioName;
    if (!_.isEmpty(classTeam) && !_.isEmpty(techTeam)) {
      request.classId = classTeam.value;
      request.technology = techTeam.value;
    }

    if (this.props.selectedToggleButton === ToggleButtonLabel.STANDARD) {
        request.serviceId = selectedServiceId?.value;
        request.environment = this.props.selectedEnvValue;
    } else if (this.props.selectedToggleButton === ToggleButtonLabel.CUSTOM) {
        request.userStory = inputUserStory;
        request.isTdiExist=false;
        request.serviceId=null;
    }
    this.props.allocateToTeam(request).then((result) => {
      if (result) {
        this.toggle();
        swal.fire("Success", result.responseDescription, "success").then(() => {
          // window.location.reload(); Note: for complete page refresh
          if (this.props.selectedToggleButton === ToggleButtonLabel.STANDARD) {
              this.props.myTeamUserToggle? this.props.loadUserFilterDetails({userList: this.props.userData?.content}):this.props.loadTeamFilterDetails();
          } else if (this.props.selectedToggleButton === ToggleButtonLabel.CUSTOM) {
              this.props.myTeamUserToggle? this.props.loadCustomUserFilterDetails(this.props.userData?.content):this.props.loadCustomTeamFilterDetails();
          }
        })
      }
    });
  }

  getClassOptions = () => {
    const {classDetails} = this.props;
    return _.map(classDetails, (c) => ({value: c.classId, label: c.classId})) || [];
  }

  getTechOptions = () => {
    const {classDetails} = this.props;
    const {classTeam} = this.state;
    const selectedClass = _.find(classDetails, (c) => classTeam && c && c.classId === classTeam.value);
    return _.map(selectedClass?.technology || [], (c) => ({value: c, label: c})) || [];
  }

  isValidData = () => {
    const {quantity, selectedServiceId, classTeam, techTeam, sourceTeam, targetTeam} = this.state;
    let isInValid = false;
    if (this.isValidServiceIdForClassTech(selectedServiceId?.value)) {
        isInValid = _.isEmpty(classTeam) || _.isEmpty(techTeam)
    }

    if (_.isEmpty(sourceTeam) || _.isEmpty(targetTeam) || _.isEmpty(quantity) || parseInt(quantity) === 0 ) {
        isInValid = true;
    }
    return isInValid;
  }

  render() {
    const {className, allocateSourceData, allocateTargetData, selectedEnvValue, sourceTeamData} = this.props;
    const { sourceUserToggle, targetUserToggle, techTeam, classTeam, sourceTeam, modal,
       quantity, targetTeam, isQtyInvalid, allocationComments, selectedServiceId,serviceQty, inputUserStory,isUserStoryInvalid} = this.state;
    let targetOption=allocateTargetData.target.targetOptions;
    if(sourceTeam){
      if(targetOption){
        targetOption=targetOption.filter((_item)=>_item.value!=sourceTeam.value)
      }
    }
       return (
      <Fragment>
        <Modal size="lg" isOpen={modal} toggle={this.toggle} backdrop="static" className={className} >
          <ModalHeader toggle={this.toggle}>
            <Grid container direction="row" spacing={10}>
              <Grid item>
                Allocate to Teams
              </Grid>
              <Grid item>
                <p className='showEnvSection' style={{fontSize: "16px", lineHeight: "30px"}}><b>Environment:</b> {selectedEnvValue}</p>
              </Grid>
            </Grid>
          </ModalHeader>
          <ModalBody>
            <section>
                <div className="row">
                  <div className="offset-1 col-4">
                    {
                    this.props.selectedToggleButton === "Standard" ?
                     <Select
                        options={allocateSourceData.source.serviceIdOptions}
                        isClearable={true}
                        styles={this.customStyles}
                        placeholder={this.props.selectedToggleButton==="Standard" ? "Service Id" : "User Story"}
                        onChange={this.onServiceIdChange}
                        isLoading={allocateSourceData.pending}
                        value={selectedServiceId}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      /> :
                      <input
                      required
                      type="text"
                      id="team-usr-story"
                      className={`form-control ${!isUserStoryInvalid && "team-usr-story-invalid"}`}
                      placeholder="User Story"
                      title="User Story"
                      value={inputUserStory}
                      onChange={this.onUserStoryChange}
                    />
                    }
                  </div>
                  <div className="col-4">
                    <Select
                      key={`sourceTeamOptions${sourceTeam && sourceTeam.value}`}
                      options={sourceTeamData || []}
                      isClearable={true}
                      styles={this.customStyles}
                      placeholder={`Source ${sourceUserToggle?"User":"Team"}`}
                      onChange={this.onChangeSourceTeam}
                      value={sourceTeam}
                      isLoading={allocateSourceData.pending}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      />
                  <div className="pull-right">Qty:{serviceQty}</div>
                  </div>
                </div>
                <div className="row">
                    <div className="offset-1 col-4">
                      <input
                        required
                        type="text"
                        id="team-qty"
                        className={`form-control ${isQtyInvalid && "team-allocate-qty-invalid"}`}
                        placeholder="qty"
                        title="qty"
                        value={quantity}
                        onChange={this.onQtyChange}
                      />
                    </div>
                    <div className="col-4">
                      <Select
                        key={`targetTeamOptions${targetTeam?.value}`}
                        options={sourceTeam? targetOption: []}
                        isClearable={true}
                        styles={this.customStyles}
                        placeholder={`Target ${targetUserToggle?"User":"Team"}` }
                        onChange={this.onChangeTargetTeam}
                        value={targetTeam}
                        isLoading={allocateTargetData.pending}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                  </div>
                  {this.isValidServiceIdForClassTech(selectedServiceId?.value) &&
                  <div className="row my-l">
                    <div className="offset-1 col-4">
                      <Select
                        key={`classTeam${classTeam?.value}`}
                        options={this.getClassOptions()}
                        isClearable={true}
                        styles={this.customStyles}
                        placeholder={"Class"}
                        onChange={this.onChangeClass}
                        value={classTeam}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                    <div className="col-4">
                      <Select
                        key={`techTeam${techTeam?.value}`}
                        options={this.getTechOptions()}
                        isClearable={true}
                        styles={this.customStyles}
                        placeholder={"Tech"}
                        onChange={this.onChangeTech}
                        value={techTeam}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                      />
                    </div>
                  </div>
                }
                {
                  allocateSourceData.failed && <FailedView message={allocateSourceData.errorMessage || "Could not load Service Id / Source Team or User Details"} />
                }
                {
                  allocateTargetData.failed && <FailedView message={allocateTargetData.errorMessage || "Could not load Target Team/User Details"} />
                }
                <section>
                  {this.props.allocateServiceFilterData != undefined && 
                  this.props.allocateServiceFilterData.data &&
                  this.props.allocateServiceFilterData.success &&
                  this.state.serviceFilterRows.length > 0 ?
                   <><div className="row">
                    <div className="col-5">
                        <b>Filter</b>
                    </div>
                    <div className="col-5">
                        <b>Value</b>
                    </div>
                  </div>
                  <div>
                 {
                    this.state.serviceFilterRows.map((row, index) =>
                  {  return (
                  <div key={index}>{row}</div>
                  )
                    }
                  )
                  }</div></> : <></>}
                </section>
                <div className="row my-l">
                  <div className="col">
                    <textarea
                      className="form-control"
                      id="team-comment"
                      placeholder="Allocation Comment"
                      value={allocationComments || ''}
                      onChange={this.onAllocationCommentsChange}
                      rows="4"
                    ></textarea>
                  </div>
                </div>

            </section>

          </ModalBody>
          <ModalFooter>
            <Button
              className={"btn-team"}
              color="success"
              onClick={this.handleAllocateToTeam}
              disabled={isQtyInvalid || this.isValidData() }
            >
              <span className={"pxr-s"}>Allocate</span>
              {targetTeam && quantity && !isUserStoryInvalid && !isQtyInvalid && <i className="fa fa-check"></i>}
            </Button>
            <Button color="danger" className={"btn-team"} onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    allTeams: state.teams.teams.allTeams || [],
    classDetails: state.teams.classDetails,
    allocateSourceData: state.teams.allocateSourceData,
    allocateTargetData: state.teams.allocateTargetData,
    exchangeData: state.teams.allocateExchangeData,
    selectedEnvValue: state.auth.selectedEnvValue,
    filterServiceIds: state.teams.filterServiceIds,
    allocateServiceFilterData: state.teams.allocateServiceFilterData,
    serviceFilterDataValues: state.teams.serviceFilterDataValues,
      loggedInUser: state.auth.user,
      sourceTeamData:state.teams.sourceTeamData
  }
  }
  const mapDispatchToProps = dispatch => {
  return {
    getTeamSource:(selectedEnvValue, selectedToggleButton, loggedInUser) =>dispatch(getTeamSource(selectedEnvValue, selectedToggleButton, loggedInUser)),
    getUserSource:(selectedEnvValue, selectedToggleButton, loggedInUser, userList) =>dispatch(getUserSource(selectedEnvValue, selectedToggleButton, loggedInUser, userList)),
    getTeamUserTarget:(targetUserToggle, selectedSource, userList, allTeams) =>dispatch(getTeamUserTarget(targetUserToggle, selectedSource, userList, allTeams)),
    fetchClassTechOptions: () => dispatch(fetchClassTechOptions()),
    allocateToTeam: (request) => dispatch(allocateToTeam(request)),
    fetchExchangeIDs: (selectedEnvValue) => dispatch(fetchExchangeIDs(selectedEnvValue)),
    checkoutServiceFilterValue: (request) => dispatch(checkoutServiceFilterValue(request)),
    checkoutServiceFilters: (request) => dispatch(checkoutServiceFilters(request)),
    getSourceTeam:(request,targetUserToggle,allTeams,selectedToggleButton)=>dispatch(getSourceTeam(request,targetUserToggle,allTeams,selectedToggleButton)),
    getCustomSourceTeam:(inputUserStory,targetUserToggle,allTeams,selectedToggleButton)=>dispatch(getCustomSourceTeam(inputUserStory,targetUserToggle,allTeams,selectedToggleButton))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AllocateTeamModal);
