import _ from 'lodash';


const getDateObj = (dateStr) => {
    // getting the dateObj after sending in dd/mm/yyyy input
    return new Date(dateStr.replace( /(\d{2})+[/]+(\d{2})+[/]+(\d{4})/, "$2/$1/$3"));
}

const dateSortAlgorithm = (a,b) =>{
    if(getDateObj(a) < getDateObj(b))
    return -1;
    if(getDateObj(a) > getDateObj(b))
    return 1;

    return 0;
}
export const getSortedDateValues = (dateSets) => {
    let sortedDates = {};
    Object.keys(dateSets).sort(dateSortAlgorithm).map(v => {
        sortedDates[v] = dateSets[v];
    })
    return sortedDates;
}



export const prepareDatasets = (datasets) => {
    // adjust datasets to have same keys to present on chart. if keys don't exist
    let allLabels = [];

    datasets.forEach(({ data }) => {
        allLabels.push(...data.map(d => d.key)); // aggregate keys
    });

    // remove duplicates from all labels.
    allLabels = [...new Set(allLabels)];

    console.log(allLabels);

    // assign zero values to datasets that key does not exist on.
    return datasets.map((dataset) => {
        const { data } = dataset;
        allLabels.forEach(key => {
            if (!data.map(d => d.key).includes(key)) {
                data.push({ y: 0, key })
            }
        });
        return {
            ...dataset,
            data: _.sortBy(data, "key")
        };
    });
};