import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import BreadTable from "@library/core/tables/BreadTable";
import Header from "@library/core/surfaces/Header";
import { tableRowFuzzySearch } from "@library/core/filters/filter";
import { Button, Tooltip, Typography, Box, Table, TableBody, TableRow } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckoutModal from './CheckoutModal';
import { syntheticCheckoutDataset } from '../../actions/retrieveDataActions';
import { setSyntheticDatasetToEdit, clearSyntheticTypeData, fetchSyntheticDataSummary } from '../../actions/syntheticDataActions';
import swal from 'sweetalert2';
import { displayLoading } from "../common/alerts/alerts";
import TableVerticalCell from "@library/core/tables/TableVerticalCell";

const SyntheticDataSummary = (props) => {
  const [filtered, setFiltered] = useState([]);
  const [unfiltered, setUnFiltered] = useState([]);
  const [openCheckout, setOpenCheckout] = useState(false);
  const [selectedCheckoutItem, setSelectedCheckoutItem] = useState(null);
  const [checkoutQty, setCheckoutQty] = useState(0);

  useEffect(() => {
    props.fetchSyntheticDataSummary().then((response) => {
      if (response?.data) {
        setFiltered(response.data);
        setUnFiltered(response.data);
      }
    });
  }, []);

  const redirectToSummary = () => {
    props.history.push('/dataset/syntheticCheckout');
  }

  const textTableData = (field) => {
    return <Typography noWrap variant="body2">{field}</Typography>;
  }

  const arrayTableData = (field) => {
    if (!field || field?.length === 0) {
      return (
      <Typography variant="h6">
        <Box fontWeight="fontWeightBold" fontSize={14}>
          {"No Data available for this dataset"}
        </Box>
      </Typography>
    );
    }
    return (
      <Table size="small" style={{width: '100%'}}>
        <TableBody>
          {
            field.map(item => {
                return (
                  <TableRow key={item.dataType}>
                      <TableVerticalCell 
                        header={item.dataType} 
                        body={[item.quantity]}
                      />
                  </TableRow>
                )
            })
          }
        </TableBody>
      </Table>
    )
  }

  const scopeCol = (field) => {
    return (
      <Tooltip title={field}>
        <div className="ml-1 d-inline">
          <Box display="block">
            {field === "TEAM" ?
              <i className="fa fa-users teamIcon ml-2"></i> : <>
                {field === "PRIVATE" ? <i className="fa fa-user userIcon ml-2"></i> : <></>}
              </>
            }
          </Box>
        </div>
      </Tooltip>
    )
  }

  const args = {
    size: "medium",
    expandTitle: "Quantities",
    isExpandable: true,
    hideEmptyExpandOptions: true,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: 'All', value: -1 }],
    columnMetadata: [
      {
        order: 1,
        title: "Name",
        field: ["name"],
        expandable: false,
        component: textTableData
      },
      {
        order: 2,
        title: "Description",
        field: ["description"],
        expandable: false,
        component: textTableData
      },
      {
        order: 3,
        title: "Type",
        field: ["typeOfData"],
        expandable: false,
        component: textTableData
      },
      {
        order: 4,
        title: "Scope",
        field: ["scope"],
        expandable: false,
        component: scopeCol
      },
      {
        order: 5,
        title: "Owner",
        field: ["userId"],
        expandable: false,
        component: textTableData
      },
      {
        order: 6,
        title: "Env",
        field: ["environment"],
        expandable: false,
        component: textTableData
      },
      {
        order: 7,
        title: "Dataset Qty",
        field: ["quantity"],
        expandable: false,
        component: textTableData
      },
      {
        order: 8,
        title: "",
        field: ["tagQtys"],
        expandable: true,
        expandableOnly: true,
        hidden: true,
        component: arrayTableData
      }
    ]
  }

  const searchFilter = (value) => {
    let filtered = [];
    for (const row of unfiltered) {
      if (tableRowFuzzySearch([row.name, row.description, row.typeOfData, row.scope, row.userId, row.environment, row.quantity], value)) {
        filtered.push(row);
      }
    }
    setFiltered(filtered);
  }

  const handleOpenCheckoutModal = (menuItem, row) => {
    setOpenCheckout(true);
    setSelectedCheckoutItem(row);
    setCheckoutQty(0);
  }

  const handleCloseCheckoutModal = () => {
    setOpenCheckout(false);
    setSelectedCheckoutItem(null);
  }

  const handleSubmitCheckout = (syntheticDataList) => {
    let filterNoQuantity=syntheticDataList.filter((_item)=>_item.quantity!=0);
    let obj={
      bundle:filterNoQuantity,
      userId:props.userName
    }
    setOpenCheckout(false);
    displayLoading({title: "Checking out data", bodyText: "Please standby"});
    const checkedInId = selectedCheckoutItem.id !== undefined ? selectedCheckoutItem.id : "";
    props.syntheticCheckoutDataset(obj,checkedInId,selectedCheckoutItem).then((response) => {
      if (response) {
          swal.close();
          swal.fire("Success", ` Data Checked Out`, "success")
          redirectToSummary();
      }
   });
  }

  const handleOpenSyntheticDataCreation = async () => {
    await props.clearSyntheticTypeData().then(async () => {
      await props.setSyntheticDatasetToEdit({}).then(() => {
        props.history.push(`/dataset/synthetic-generate`);
      });
    });
  }

  const handleEditSyntheticDataset = async (menuItem, data) => {
    await props.setSyntheticDatasetToEdit(data);
    props.history.push(`/dataset/synthetic-generate`);
  }

  return (
    <div className="white">
      <Header
        title={`Synthetic Data Summary`}
        topRightIcon={<i className={`fa fa-database training-icon fa-3x`}/>}
      />
      <div style={{'paddingTop': '25px'}}/>
      <BreadTable
        {...args} 
        data={filtered}
        searchFilter={searchFilter}
        searchPermOpen
        isLoading={props.syntheticDataBankDatasetPending}
        noDataMsg={"No Synthetic Data Available"}
        actionItems={()=>{
          return [
            {
              title: "Checkout",
              onClick: (menuItem, data) => {
                handleOpenCheckoutModal(menuItem, data)
              },
              isDisabled: (data)=>{return data.quantity === 0}
            },
            {
              title: "Add data",
              onClick: (menuItem, data) => {
                handleEditSyntheticDataset(menuItem, data)
              },
              isDisabled: (data)=>{return data.userId !== props.userName}
            }
          ]
        }}
        titleBtnArea={
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={handleOpenSyntheticDataCreation}
            startIcon={<AddCircleIcon/>}
          >
            Create Synthetic Data
          </Button>
        }
      />
      {openCheckout ? <CheckoutModal 
        open={openCheckout} 
        checkoutData={selectedCheckoutItem} 
        handleClose={handleCloseCheckoutModal} 
        handleSubmit={handleSubmitCheckout}
        handleCheckoutQty={setCheckoutQty}
        checkoutQty={checkoutQty}
      /> : null}
      
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    userName: state.auth.userName,
    environment: state.auth.selectedEnvValue,
    syntheticDataBankDatasetPending: state.syntheticData.syntheticDataBankDatasetPending,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSyntheticDataSummary: () => dispatch(fetchSyntheticDataSummary()),
    syntheticCheckoutDataset: (checkoutData,id,currData) => dispatch(syntheticCheckoutDataset(checkoutData,id,currData)),
    setSyntheticDatasetToEdit: (data) => dispatch(setSyntheticDatasetToEdit(data)),
    clearSyntheticTypeData: () => dispatch(clearSyntheticTypeData())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SyntheticDataSummary);
