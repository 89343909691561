import { Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle } from 'reactstrap';

import React from 'react';

const defaultCard = () => {
    let card = {}
    card.title = "Generic Title"
    card.subtitle = "Generic Subtitle"
    card.content = "Generic Content";

    card.img = { url: "https://picsum.photos/640/360", alt: "No Image" }
    return card
}

export default function DiagramCard(props) {

    var { diagram: card, modalRef } = props
    if (!card) card = defaultCard();
    return (
        <Card onClick={() => modalRef.open(card)}>
            <CardImg top width="100%" src={card.img.url} alt={card.img.alt} />
            <CardBody>
                <CardTitle>{card.title}</CardTitle>
                <CardSubtitle>{card.subtitle}</CardSubtitle>
                <CardText>{card.content}</CardText>
            </CardBody>
        </Card>
    )
}
