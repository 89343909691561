import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Field, reduxForm } from "redux-form";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateBillingAccountAddress } from "../../../actions/u2cActions";
import swal from "sweetalert2";
import { renderField } from "../../common/fields/fields";
import { billingAddressValidator } from "../../common/form/syncValidators";

class AddressForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  open = () => {
    this.setState({
      modal: true
    });
  };

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    this.props.reset();
  };

  onSubmit = formProps => {
    const updateAddressData = {
      billingAccount: {
        billingAccountNumber: this.props.billingAccountNumber,
        billingAddress: formProps
      }
    };
    return this.props
      .updateBillingAccountAddress(
        updateAddressData,
        this.props.billingAccountNumber
      )
      .then(submitted => {
        submitted ? this.onSubmitSuccess() : this.onSubmitFailed();
      });
  };

  onSubmitSuccess = result => {
    this.toggle();
    swal.fire({
      type: "success",
      title: "Successfully updated."
    });
  };
  onSubmitFailed = result => {
    swal.fire({
      type: "error",
      text: "Unable to update."
    });
  };

  render() {
    const { valid, handleSubmit, pristine, reset, submitting } = this.props;
    const options = [
      { label: "NSW", value: "NSW" },
      { label: "QLD", value: "QLD" },
      { label: "SA", value: "SA" },
      { label: "TAS", value: "TAS" },
      { label: "VIC", value: "VIC" },
      { label: "WA", value: "WA" }
    ];
    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggle}
        className={this.props.className}
      >
        <div className="popupcontent">
          <div>
            <ModalHeader>
              <p>Change Address</p>
            </ModalHeader>
          </div>
          <div className="form-component">
            <form
              className="form-horizontal"
              onSubmit={handleSubmit(this.onSubmit)}
              autoComplete="off"
            >
              <ModalBody className="modalbody">
                <div className="form-group">
                  <div class="row">
                    <div class="col">
                      <Field
                        name="address"
                        label="Address:"
                        autoComplete="off"
                        component={renderField}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div class="row">
                    <div class="col">
                      <Field
                        name="locality"
                        label="Locality:"
                        autoComplete="off"
                        component={renderField}
                      />

                    </div>
                    <div class="col">
                      <Field
                        name="postcode"
                        label="Postcode:"
                        autoComplete="off"
                        component={renderField}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div class="row">
                    <div class="col">
                      <Field
                        name="state"
                        label="State:"
                        autoComplete="off"
                        type="selectWithCustomHeight"
                        options={options}
                        component={renderField}
                        height={"35px"}
                      />
                    </div>
                    <div class="col">
                      <Field
                        name="country"
                        label="Country:"
                        autoComplete="off"
                        component={renderField}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

              </ModalBody>
              <ModalFooter>
                <Button
                  className={classNames("custom-buttom", "ld-ext-right", {
                    running: submitting
                  })}
                  color="primary"
                  disabled={submitting}
                >
                  {" "}
                  {submitting ? "Updating.." : "Confirm"}
                  <span className="ld ld-ring ld-spin" />
                </Button>
                <Button
                  className="custom-buttom"
                  color="warning"
                  onClick={this.toggle}
                  disabled={submitting}
                >
                  Cancel
              </Button>
              </ModalFooter>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const initialValues = state.u2c.billingAccount.billingAddress;
  const billingAccountNumber = state.u2c.billingAccount.billingAccountNumber;
  return {
    initialValues,
    savingPending: state.u2c.savingPending,
    billingAccountNumber
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBillingAccountAddress: (billingAccount, billingAccountNumber) =>
      dispatch(
        updateBillingAccountAddress(billingAccount, billingAccountNumber)
      )
  };
};

AddressForm = reduxForm({
  form: "billingAddress",
  destroyOnUnmount: false, //to not destroy from data on unmount
  validate: billingAddressValidator,
  enableReinitialize: true
})(AddressForm);

export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
