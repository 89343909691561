import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    userData: {},
    userTrainingBadge : "",
    badge : "",
    data : "",
    badgesByUser: [],
    badgesByUserLoading: false,
    badgesByUserFailed: false,
    badgesByUserError: ""
}
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_USER_TRAINING_BADGE:
            return {
                ...state,
                userTrainingBadge: action.payload
            }
        case types.SET_BADGE:
            return {
                ...state,
                badge : action.badge,
                data : action.data,
                tooltip : action.tooltip
            }
        case types.FETCH_BADGES_FOR_ALL_USERS_SUCCESS:
            return {
                ...state,
                badgesByUser: action.payload,
                badgesByUserLoading: false,
                badgesByUserFailed: false,
                badgesByUserError: ""
            }
        case types.FETCH_BADGES_FOR_ALL_USERS_FAILED:
            return {
                ...state,
                badgesByUser: [],
                badgesByUserLoading: false,
                badgesByUserFailed: true,
                badgesByUserError: action.payload
            }
        case types.FETCH_BADGES_FOR_ALL_USERS_LOADING:
            return {
                ...state,
                badgesByUser: [],
                badgesByUserLoading: true,
                badgesByUserFailed: false,
                badgesByUserError: ""
            }
        default:
            return state;
    }
}