import * as types from "../actions/dataCleanupActionTypes";
import _ from "lodash";

const INITIAL_STATE = {
  filters: {
    cleanUpTypes: [
      { label: "NUMBERS-MGR", icon: "icon-keep-information" },
    ],
    viewFilters: [
      { label: "Resource Update", icon: "icon-note", title: "SNRM" },
    ],
    unmsViewFilter: [
      { label: "Update", icon: "icon-product-upgrade", title: "UNMS" },
      { label: "Insert", icon: "icon-data-add-on", title: "UNMS" },
    ],
    unmsResourceType: [
      { label: "FNN", icon: "icon-mobile-device-mgmt" },
      { label: "MSISDN", icon: "icon-mobile" },
      { label: "SIM/IMSI", icon: "icon-sim-card"},
      { label: "InfraCo FNN", icon: "icon-mobile-device-mgmt"},
    ],
    unmsResourceTypes: [
      { label: "CONTIGUOUS", icon: "icon-wan-accelerate" },
      { label: "NON_CONTIGUOUS", icon: "icon-wireless-router" },
    ],

    unmsMsisdnResourceTypes: [
      { label: "NON_CONTIGUOUS_MOBILE_POSTPAID", icon: "icon-wan-accelerate" }
    ],

    unmsUpdateType: [
      { label: "Available", icon: "icon-round-tick", color: "base-green" },
      { label: "Active", icon: "icon-ui-play", color: "base-turquoise" },
      { label: "Held Order", icon: "icon-ui-pause", color: "base-orange" },
      { label: "Quarantine", icon: "icon-security-shield", color: "base-blue" },
      { label: "Held Presentation", icon: "icon-ui-stop", color: "base-magenta" },
      { label: "Internal Use", icon: "icon-modem", color: "base-purple" },
      { label: "Legacy", icon: "icon-data-insights", color: "base-purple" },
      { label: "Not For Use", icon: "icon-operations-centre", color: "base-orange" },
      { label: "CONTIGUOUS", icon: "icon-wan-accelerate", color: "base-green" },
      { label: "NON_CONTIGUOUS", icon: "icon-wireless-router", color: "base-orange" },
      { label: "Pending Connect", icon: "icon-sip-connect", color: "base-purple"}
    ],

    unmsMsisdnUpdateType: [
      { label: "Available", icon: "icon-round-tick", color: "base-green" },
      { label: "Active", icon: "icon-ui-play", color: "base-turquoise" },
      { label: "Held Order", icon: "icon-ui-pause", color: "base-orange" },
      { label: "Quarantine", icon: "icon-security-shield", color: "base-blue" },
      { label: "Held Presentation", icon: "icon-ui-stop", color: "base-magenta" },
    ],

    unmsSimUpdateType: [],
    unmsImsiUpdateType: [],
    unmsFnnUpdateType: [],

    resourceTypeFiltersInINT2: [
      {
        label: "SIM/IMSI",
        value: 505,
        length: 12,
        title: "only 12 digits are allowed for IMSI",
        icon:'icon-mobile-messaging'
      }
    ],
    resourceTypeFiltersAllEnv:[
      {
        label: "SIM/IMSI",
        value: 505,
        length: 12,
        title: "only 12 digits are allowed for IMSI",
        icon:'icon-mobile-messaging'
      },
      {
        icon: "icon-mobile",
        label: "MSISDN"
      }
    ],
    statusFilters: [
      { label: "ACTIVE", icon: "icon-volume-on" },
      { label: "AVAILABLE", icon: "icon-wan-accelerate" },
      { label: "SIM ALLOCATED", icon: "icon-wan-accelerate" },
      { label: "IN PROGRESS", icon: "icon-service-status" },
      { label: "HELD PRESENTATION", icon: "icon-operations-centre" },
      { label: "HELD ORDER", icon: "icon-no-excess" },
      { label: "ASSIGNED", icon: "icon-thick-tick" }
    ],
    statusFiltersFnn: [
      { label: "ACTIVE", icon: "icon-volume-on" },
      { label: "AVAILABLE", icon: "icon-wan-accelerate" },
      { label: "SIM ALLOCATED", icon: "icon-wan-accelerate" },
      { label: "IN PROGRESS", icon: "icon-service-status" },
      { label: "HELD PRESENTATION", icon: "icon-operations-centre" },
      { label: "HELD ORDER", icon: "icon-no-excess" },
      { label: "ASSIGNED", icon: "icon-thick-tick" },
      { label: "FOREIGN CARRIER", icon: "icon-technologies" },
      { label: "QUARANTINE", icon: "icon-teg-portal" },
      { label: "PSTN", icon: "icon-telstra-firewall" },
      { label: "PENDING PORT IN", icon: "icon-ui-stop" },
      { label: "PENDING CONNECT", icon: "icon-connect-ip" },
      { label: "PENDING DISCONNECT", icon: "icon-ui-stop" }
    ],
    statusIMSIFilters: [
      { label: "ACTIVE", icon: "icon-volume-on" },
      { label: "AVAILABLE", icon: "icon-wan-accelerate" },
      { label: "SIM ALLOCATED", icon: "icon-wan-accelerate" },
      { label: "IN PROGRESS", icon: "icon-service-status" },
      { label: "HELD PRESENTATION", icon: "icon-operations-centre" },
      { label: "HELD ORDER", icon: "icon-no-excess" },
      { label: "ASSIGNED", icon: "icon-thick-tick" }
    ],
    statusIMSI_SIMFilters: [
      { label: "ACTIVE", icon: "icon-volume-on" },
      { label: "AVAILABLE", icon: "icon-wan-accelerate" },
      { label: "IN PROGRESS", icon: "icon-service-status" },
      { label: "HELD PRESENTATION", icon: "icon-operations-centre" },
      { label: "RESERVED", icon: "icon-no-excess" }
    ]
  },
  b2bDataCleanUpBatchStatus:{},
  b2bDataCleanUpResourceStatus:{},
  cleanUpBatchStatusLoading:false,
  selectedFilters: {},  
  unms: {fetchPending: false,},
  snrm: {fetchPending: false,},
  searchedResourceType : "",
  cleanUpResourceStatusLoading: false
  
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case types.RESET_CURRENT_STATUS:
      return {
        ...state,
        resourceStatus: {}
      };

    case types.SELECT_DATA_CLEANUP_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.key]: action.value
        }
      };
    case types.RESET_DATA_CLEAN_UP_FILTERS:
      return { ...INITIAL_STATE };

    case types.FETCH_UNMS_RESOURCE_PENDING:
      return { ...state, unms: { fetchPending: true } };

    case types.FETCH_UNMS_RESOURCE_SUCCESS:
      return {
        ...state, unms: {
          fetchPending: false,
          resources: {
            success: true,
            content: action.payload.content,
            isFirst: action.payload.first,
            isLast: action.payload.last,
            currentPage: (action.payload.pageable.pageNumber + 1),
            currentPageSize: action.payload.pageable.pageSize,
            totalItems: action.payload.totalElements,
            totalPages: action.payload.totalPages,
            searchRequest: action.payload.searchRequest,
            loading: false
          }
        }
      };

    case types.FETCH_SNRM_RESOURCE_FAILED:
      return {
        ...state,
        snrm: {
          fetchPending: false,
          fetchFailed: true,
          fetchFailedMsg: action.payload
        }
      };
    case types.FETCH_SNRM_IMSI_RESOURCE_FAILED:
      return {
        ...state,
        snrm: {
          fetchPending: false,
          fetchFailed: true,
          fetchFailedMsg: action.payload
        }
      };

    case types.FETCH_SNRM_RESOURCE_PENDING:
      return {
        ...state,
        snrm: {
          fetchPending: true,
          resources: {},
          searchedResourceType : ""
        }
      };
    case types.FETCH_SNRM_IMSI_RESOURCE_PENDING:
      return {
        ...state,
        snrm: {
          fetchPending: true,
          resources: {},
          searchedResourceType : ""
        }
      };

    case types.FETCH_SNRM_RESOURCE_SUCCESS:
      return {
        ...state, snrm: {
          fetchPending: false,
          resources: {
            success: true,
            content: action.payload.content,
            isFirst: action.payload.first,
            isLast: action.payload.last,
            currentPage: (action.payload.pageable.pageNumber + 1),
            currentPageSize: action.payload.pageable.pageSize,
            totalItems: action.payload.totalElements,
            totalPages: action.payload.totalPages,
            searchRequest: action.payload.searchRequest,
            loading: false
          }
        }
      };
      case types.FETCH_SNRM_IMSI_RESOURCE_SUCCESS:
      return {
        ...state, snrm: {
          fetchPending: false,
          searchedResourceType : action.searchedResourceTypePayload,
          resources: {
            success: true,
            content: action.payload.content,
            isFirst: action.payload.first,
            isLast: action.payload.last,
            currentPage: (action.payload.pageable.pageNumber + 1),
            currentPageSize: action.payload.pageable.pageSize,
            totalItems: action.payload.totalElements,
            totalPages: action.payload.totalPages,
            searchRequest: action.payload.searchRequest,
            loading: false
          }
        }
      };
    case types.RESET_SNRM_RESOURCES:
      return {
        ...state,
        snrm: {
          resources: {}
        }
      };
    case types.FETCH_UNMS_RESOURCE_FAILED:
      return { ...state, unms: { fetchPending: false, fetchFailed: true } };

    case types.RESET_RESOURCES:
      return { ...state, unms: { resources: {} } };
    case types.FETCH_B2B_CLEANUP_BATCH_STATUS_SUCCESS:
      return {
        ...state,
        b2bDataCleanUpBatchStatus : action.payload,
        cleanUpBatchStatusLoading :false
      };
    case types.FETCH_B2B_CLEANUP_BATCH_STATUS_LOADING:
      return {
        ...state,
        cleanUpBatchStatusLoading:true,
        b2bDataCleanUpBatchStatus:{}

      };
    case types.FETCH_B2B_CLEANUP_BATCH_STATUS_FAILED:
      return {
        ...state,
        cleanUpBatchStatusLoading:false,
        b2bDataCleanUpBatchStatus:{}

      };
    case types.FETCH_B2B_CLEANUP_RESOURCE_STATUS_SUCCESS:
      return {
        ...state,
        b2bDataCleanUpResourceStatus : action.payload,
        cleanUpResourceStatusLoading :false
      };
    case types.FETCH_B2B_CLEANUP_RESOURCE_STATUS_LOADING:
      return {
        ...state,
        cleanUpResourceStatusLoading:true,
        b2bDataCleanUpResourceStatus:{}

      };
    case types.FETCH_B2B_CLEANUP_RESOURCE_STATUS_FAILED:
      return {
        ...state,
        cleanUpResourceStatusLoading:false,
        b2bDataCleanUpResourceStatus:{}
      };  

    default:
      return state;

    case types.UPDATE_SIM_IMSI_UPDATE_TYPES:
      return {
        ...state,
        filters: {
          ...state.filters,
          unmsSimUpdateType: action.payload.responseSimFormat,
          unmsImsiUpdateType: action.payload.responseImsiFormat,
          unmsFnnUpdateType: action.payload.responseFnnFormat,
          responseMll:action.payload.responseMllFormat
        }
      }
  }
};
