import React from "react";

export const getStartsWithIcon = label => {
  if (label == null) return null;
  if (label.startsWith("BYO")) return "icon-sim-card";
  return null;
};

export const getIcon = label => {
  switch (label) {
    case "NBN":
      return "icon-network-coverage";
    case "Cable":
      return "icon-modem";
    case "Mobile":
      return "icon-mobile";
    case "Postpaid":
      return "icon-mobile";
    case "Prepaid":
      return "icon-mobile";
    case "ADSL":
      return "icon-internet";
    case "Customer":
      return "icon-my-profile";
    case "Order":
      return "icon-shop-online";
    case "Transition":
      return "icon-network-australia-wide";
    case "FTTP":
      return "icon-home";
    case "FTTN":
      return "icon-network-australia-wide";
    case "FTTB":
      return "icon-business-building";
    case "FTTN/B":
      return "icon-business-building";
    case "FTTC":
      return "icon-road-map";
    case "FW":
      return "icon-wireless-access-point";
    case "Residential":
      return "icon-home";
    case "Sole Trader":
      return "icon-profile-suit";
    case "Company":
      return "icon-industries";
    case "Organisation":
      return "icon-business-building";
    case "Existing":
      return "icon-hosted-server-pos-old";
    case "Realtime":
      return "icon-network-australia-wide";
    case "Site":
      return "icon-business-building";
    case "Billing Account":
      return "icon-bill-paper";
    case "Contact":
      return "icon-profile-suit";
    case "Opportunity":
      return "icon-bundle";
    case "Salesforce":
      return "icon-profile-suit";
    case "Siebel":
      return "icon-network-australia-wide";
    case "Mica":
      return "icon-bill-paper";
    case "MSISDN":
      return "icon-sim-card";
    case "SIM":
      return "icon-sim-card";
    case "MSISDN and SIM":
      return "icon-sim-card";
    case "VODAFONE":
    case "OPTUS":
      return "icon-sim-card";
    case "down-arrow":
      return "icon-ui-down-arrow-thick";
    case "up-arrow":
      return "icon-ui-up-arrow-thick"
    default:
      return getStartsWithIcon(label)
        ? getStartsWithIcon(label)
        : "icon-internet";
  }
};

export const getDataTypeIcon = label => {
  return getIcon(label);
};

export const getClassTypeIcon = label => {
  return getIcon(label);
};

export const getNumberIcon = num => {
  switch (num) {
    case "1":
      return "icon-number-one";
    case "2":
      return "icon-number-two";
    case "3":
      return "icon-number-three";
    case "4":
      return "icon-number-four";
    case "5":
      return "icon-number-five";
    case "6":
      return "icon-number-six";
    case "7":
      return "icon-number-seven";
    case "8":
      return "icon-number-eight";
    case "9":
      return "icon-number-nine";
    case "10":
      return "icon-number-ten";
    default:
      return null;
  }
};

export const getColor = label => {
  if (label.startsWith("Customer")) return "turquoise";
  if (label.startsWith("Address")) return "blue";
  if (label.startsWith("Mobile")) return "orange";
  return "orange";
};

export const renderIcon = (icon, classes) => {
  return (
    <React.Fragment>
      <i className={`td-icon-sm ${icon} theme-text-primary ${classes}` } />
      {"  "}
    </React.Fragment>
  );
};

export const renderQtyIcon = qty => {
  return <div id="custom-count-circle" data-value={qty}></div>;
};

export const flexStyles = {
  display: "inline-flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  padding: "5px"
};

export const flexStylesNoPadding = {
  ...flexStyles,
  padding: "0"
};

export const flexStylesAround = {
  ...flexStyles,
  justifyContent: "space-around"
};

export const flexStylesCenter = {
  ...flexStyles,
  justifyContent: "center"
};

export const flexStylesStart = {
  ...flexStyles,
  justifyContent: "flex-start"
};

export const flexStylesColumn = {
  ...flexStyles,
  flexDirection: "column"
};

export const flexStylesNoWidth = {
  ...flexStyles,
  width: "unset"
};

export function getTenColorPallete(colorIndex){
  switch(Number(colorIndex)){
    case 0:
      return '#3fb0ac';
    case 1:
      return '#c2dde6';
    case 2:
      return '#e6e910';
    case 3:
      return '#cdd422';
    case 4:
      return '#173e43';
    case 5:
      return '#fae546';
    case 6:
      return '#fae596';
    case 7:
      return '#dddfd4';
    case 8:
      return '#f8e5e5';
    case 9:
      return '#c39EA0';
    default:
      return '#c39EA0';
  }
}

export function getAcronym(displayName) {
  if(typeof(displayName)==='string'){
    let splitted = displayName.split(' ');
      if (splitted.length > 1) {
          let firstLetter = splitted[0].slice(0, 1); let secondLetter = splitted[1].slice(0, 1)
          return firstLetter+secondLetter;
      }
      else
          return splitted[0].slice(0, 2);
  }
  return "NA";
}

export function getReorderedDate(displayDate) {
  if(typeof(displayDate)==='string'){
    let splitted = displayDate.split('-');
    return [splitted[2], splitted[1], splitted[0]].join('-');
  }
  return "NA";
}