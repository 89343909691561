import React, { Component, Fragment } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { TextField } from '@material-ui/core';
import { isNumeric } from 'validator';
import { findSiebelCustomer } from '../../../actions/utilityActions';
import { displayLoading } from "../../common/alerts/alerts";
import swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import { compose } from "redux";


const useStyles = theme => ({
      teamTextInput : {
        "& .MuiInputBase-input.MuiOutlinedInput-input" :{
          height: "40px",
            lineHeight: "40px",
          padding : "0px 7px 0px 12.5px !important",
         }
      }

  });


class CustomerIdModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: '',
            errorMsg: '',
        };
    }

    validateId = (e) => {
        const options = {
            no_symbols: true
        }
        var err = '';
        if (e) {
            if (e.length == 10) {
                err = ''
            } else {
                err = 'Customer ID must be 10 digits'
            }
        } else {
            err = 'Customer ID is Required'
        }
        if (isNumeric(e, options) || e == ''){
            this.setState({
                ...this.state,
                customerId: e,
                errorMsg: err
            })
        }
    }

    findSiebelCustomerInfo = (e, value) => {
        e.preventDefault();
        let request = value + '/' + this.props.selectedEnvValue
        displayLoading({ title: "Customer Console", bodyText: "Please standby while we fetch your customer details." })
        this.props.findSiebelCustomer(this.props.selectedEnvValue, request)
    }

    render() {
        const { classes } = this.props;
        if (this.props.siebelCustomer.isSearchSuccess)  {
            swal.close();
            return <Redirect to="/data/utility/customerconsole"/>
        }
        return (
            <Fragment>
                <Modal isOpen={this.props.showModal} style={{ width: "30vw", minWidth: "350px", maxWidth: "400px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <div className="modalcontent">
                        <form onSubmit={(e) => this.findSiebelCustomerInfo(e, this.state.customerId)}>
                            <ModalHeader style={{'justifyContent':'center'}}>
                                <strong>Enter your siebel customer ID</strong>
                            </ModalHeader>
                            <ModalBody className="modalbody" style={{'textAlign':'center'}}>
                                <div className="row">
                                    <div className="col" style={{'textAlign':'center'}}>
                                        <TextField
                                            type='text'
                                            name='customerId'
                                            variant='outlined'
                                            value={this.state.customerId}
                                            onChange = {e => {
                                                this.validateId(e.target.value);
                                            }}
                                            error = {this.state.errorMsg ? true : false}
                                            helperText = {this.state.errorMsg}
                                            className={classes.teamTextInput}
                                            inputProps={{style:{'textAlign':'center'}}}
                                        />
                                        {this.props.siebelCustomer.isSearchError ? <p style={{'color':'red'}}>{this.props.siebelCustomer.message}</p> : null}
                                        {this.props.siebelCustomer.isSearchError ? swal.close() : null}
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter style={{'justifyContent':'center'}}>
                                <Button color="primary" type="submit" 
                                    disabled={this.state.errorMsg ? true : this.state.customerId == '' ? true : false } >Submit</Button>
                                {/* <Button disabled type="button" color="primary" onClick={() => {this.props.openModal('showCreateNewCustomerModal'); this.props.closeModal('showSiebelModal');}}>Create New</Button> */}
                                <Button color="secondary" onClick={() => this.props.closeModal('showSiebelModal')}>Cancel</Button>
                            </ModalFooter>
                        </form>
                    </div>
                </Modal>
            </Fragment>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        siebelCustomer: state.utility.siebelCustomer,
        selectedEnvValue: state.auth.selectedEnvValue,
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        findSiebelCustomer : (env, request) => dispatch(findSiebelCustomer(env, request)),
    };
};



export default compose(
    withStyles(useStyles, {
        name: 'CustomerIdModal',
      }),
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
      )(CustomerIdModal);