import React, { useEffect, useState } from 'react';
import BreadTable from "@library/core/tables/BreadTable";
import { RoundBtn } from "@library/core/buttons";
import { connect } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import { fetchAllReferenceData, updateReference } from '../../../actions/timMigrationActions'
import _ from "lodash";
import {
    Typography,
    TextField,
  } from "@material-ui/core";
  import Autocomplete from '@material-ui/lab/Autocomplete';


const queryReferenceServiceStyle = makeStyles({
    CircularProgressStyle: {
        width: "70px !important",
        height: "70px !important",
        marginLeft: "600px",
        marginBottom: "100px",
        marginTop: "40px"
    }
});

const QueryReferenceService = (props) => {
    
    const { fetchAllReferenceRecordData, userName, isUserAdmin} = props;
    const [editIdx, setEditIdx] = useState(undefined);
    const statusOptions = ['Active', 'In-Active'];
    const classes = queryReferenceServiceStyle();
    const [data,setData] = useState(fetchAllReferenceRecordData);
    const [serviceCodeError, setServiceCodeError]= useState({invalid: false, error: "Please enter a valid Service Code"})
    const [serviceNameError, setServiceNameError]= useState({invalid: false, error: "Please enter a valid Service Name"})
    const [planCodeError, setPlanCodeError]= useState({invalid: false, error: "Please enter a valid Plan Code"})
    const [planNameError, setPlanNameError]= useState({invalid: false, error: "Please enter a valid Plan Name"})
    const [statusError, setStatusError]= useState({invalid: false, error: "Please enter a valid Status"})
    useEffect(() => {
        props.fetchAllReferenceData()
    }, []);

    useEffect(() => {
      if(fetchAllReferenceRecordData){
        setData(fetchAllReferenceRecordData.sort((a,b)=> a.id-b.id))
      }
    }, [fetchAllReferenceRecordData]);

    const addUser = () => {
      let tempRefData = _.filter(data, (v) => v.id !== undefined);
      
      tempRefData.unshift({
      serviceCode: '',
      serviceCodeName: '', 
      planCode: '', 
      planCodeName: '',
      status: '',
      createAt: '',
      createdBy: '',
      });
      setData(tempRefData);
      setEditIdx(0);
    }

    const updateUser = (menuItem, row) => {
        const requestData= {
            id: row.id,
            serviceCode: row.serviceCode,
            serviceCodeName: row.serviceCodeName, 
            planCode: row.planCode, 
            planCodeName: row.planCodeName,
            status: row.status,
            createAt: new Date().toISOString().slice(0, 10),
            createdBy: userName,
          }
        let tempRefData = _.filter(data, (v) => v.serviceCode === row.serviceCode && v.planCode === row.planCode);
        if(tempRefData.length >1){
          const update = {invalid: true, error: "Service Code And Plan Code have duplicate entries, Please update."};
          setServiceCodeError(update)
          setPlanCodeError(update)
        }else{
          props.updateReference(requestData); 
          setEditIdx(undefined); 
          props.fetchAllReferenceData();
        } 
    }

    const editRef = (menuItem, row) => {
        setEditIdx(row.id);
    }

    const fieldUpdate = (value, fieldName, addingNew) => {
        validateField(value, fieldName);
        data.forEach(function(result, index) {
            if(result.id === editIdx || (result.id === undefined && addingNew)) {
              result[fieldName]=value;
            }    
        });
    }

    const validateField = (value, fieldName) =>{
      let update = {valid: false, error: ""};
      if (value.length >= 3) {
        update = {invalid: false, error: ""};
      } else if (fieldName === 'status'){
        update = {invalid: true, error: "Status can not be empty"};
      }  else {
        update = {invalid: true, error: "Min 3 characters"};
      }
      if(fieldName === 'serviceCode'){
        setServiceCodeError(update)
      } else if( fieldName === 'serviceCodeName'){
        setServiceNameError(update)
      } else if( fieldName === 'planCode'){
        setPlanCodeError(update)
      } else if( fieldName === 'planCodeName'){
        setPlanNameError(update)
      } else {
        setStatusError(update)
      }
    }

    const args = {
        expandTitle: "Details",
        size: "medium",
        isExpandable: false,
        columnMetadata: [
          {
            order: 1,
            title: "Service Id",
            field: ["id"],
            expandable: false,
            component: (field) => <RoundBtn>{field}</RoundBtn>,
          },
          {
            order: 2,
            title: "Service Code",
            field: ["serviceCode"],
            expandable: false,
            component: (field, currentlyEditing, addingNew) => (
                <>
                    {   !currentlyEditing && !addingNew ?
                              <Typography variant="body2">{field}</Typography> :              
                    <TextField
                            label="serviceCode"
                            required={true}
                            id="serviceCode"
                            fullWidth 
                            defaultValue={field}
                            error={serviceCodeError.invalid}
                            helperText={serviceCodeError.invalid ? serviceCodeError.error : ''}
                            placeholder="serviceCode..."
                            autoComplete="off"
                            onChange={(e)=>fieldUpdate(e.target.value,"serviceCode", addingNew)}
                          />
                }
                </> 
            ),
          },
          {
            order: 3,
            title: "Service Name",
            field: ["serviceCodeName"],
            expandable: false,
            component: (field, currentlyEditing, addingNew) => (
                <>
                {   !currentlyEditing && !addingNew ?
                              <Typography variant="body2">{field}</Typography> :
                    <TextField
                            label="serviceCodeName"
                            required={true}
                            id="serviceCodeName"
                            fullWidth 
                            defaultValue={field}
                            error={serviceNameError.invalid}
                            helperText={serviceNameError.invalid ? serviceNameError.error : ''}
                            placeholder="serviceCodeName..."
                            autoComplete="off"
                            onChange={(e)=>fieldUpdate(e.target.value,"serviceCodeName", addingNew)}
                          />
                }
                </> 
            ),
          },
          {
            order: 4,
            title: "Plan Code",
            field: ["planCode"],
            expandable: false,
            component: (field, currentlyEditing, addingNew) => (
                <>
                {  !currentlyEditing && !addingNew ?
                              <Typography variant="body2">{field}</Typography> :
                    <TextField
                            label="planCode"
                            required={true}
                            id="planCode"
                            fullWidth 
                            defaultValue={field}
                            error={planCodeError.invalid}
                            helperText={planCodeError.invalid ? planCodeError.error : ''}
                            placeholder="planCode..."
                            autoComplete="off"
                            onChange={(e)=>fieldUpdate(e.target.value, "planCode", addingNew)}
                          />
                }
                </> 
            ),
          },
          {
            order: 5,
            title: "Plan Name",
            field: ["planCodeName"],
            expandable: false,
            component: (field, currentlyEditing, addingNew) => (
                <>
                {    !currentlyEditing && !addingNew ?
                              <Typography variant="body2">{field}</Typography> :
                    <TextField
                            label="planCodeName"
                            required={true}
                            id="planCodeName"
                            fullWidth 
                            defaultValue={field}
                            error={planNameError.invalid}
                            helperText={planNameError.invalid ? planNameError.error : ''}
                            placeholder="planCodeName..."
                            autoComplete="off"
                            onChange={(e)=>fieldUpdate(e.target.value,"planCodeName", addingNew)}
                          />
                }
                </> 
            ),
          },
          {
            order: 6,
            title: "Status",
            field: ["status"],
            expandable: false,
            component: (field, currentlyEditing, addingNew) => (
                <>
                {!currentlyEditing && !addingNew ?
                    <Typography variant="body2">{field}</Typography> :
                    <Autocomplete
                                freeSolo
                                key="status"
                                name="status"
                                required={true}
                                error={statusError.invalid}
                                helperText={statusError.invalid ? statusError.error : ''}
                                onChange={(e, v)=>fieldUpdate(v,"status", addingNew)}
                                options={statusOptions}
                                renderInput={(params) => 
                                  <TextField 
                                      {...params}
                                      fullWidth
                                      InputProps={{
                                        ...params.InputProps,
                                      }}
                                  />
                                }
                              />
                    
                    
                }
                </> 
            ),
          },
        ],
    }  

    const menuItemToggle = (currentlyEditing, addingNew) => {
        return (
            (currentlyEditing || addingNew) ?
            [       
                {  
                    title: "Save",
                    isDisabled: () => {return( serviceCodeError.invalid  )},
                    onClick: (menuItem, row) => {
                      updateUser(menuItem, row)
                    },   
                }
            ]:
            [
                {  
                    title: "Edit",
                    onClick: (menuItem, row) => {
                    editRef(menuItem, row)
                },   
            },
            ]
        )
    }

    return <>
        {!isUserAdmin? `Reference data has been desibled (not allowed) for view!!!` : data ? 
        <BreadTable
            {...args}
            title="Reference Data"
            size="medium"
            data={data}
            disableNextByApiOnLastPage= {false}
            addingNew={(row) => row.id === undefined}
            currentlyEditing={(row) =>{
                return row.id === editIdx
            }}
            addNew={addUser}
            actionItems= {menuItemToggle}
        />
        : <CircularProgress classes={{ root: classes.CircularProgressStyle }} />}
        
    </>
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllReferenceData: () => dispatch(fetchAllReferenceData()),
        updateReference: (value) => dispatch(updateReference(value)),
    };
};

const mapStateToProps = state => {
    return {
        fetchAllReferenceRecordData: state.timMigration?.fetchAllReferenceData,
        userName: state.auth.userName,
        isUserAdmin: state.auth.isUserAdmin,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryReferenceService);