import React from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, Collapse, Box, Typography } from "@material-ui/core";

const TableBodyRow = (props) => {
  const { children, open, title, ...rest } = props;
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "none" }} colSpan={6} {...rest}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div" style={{ marginLeft: "50px" }}>
              {title}
            </Typography>
            {children}
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

TableBodyRow.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default TableBodyRow;
