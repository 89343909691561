import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

class DataActiveView extends Component {
    render() {
        console.log(this.props.data);
        if (!this.props.data.orderId) return <Redirect to="/data/active"></Redirect>
        const {orderId, fnn, serviceId, activeCustomerPool} = this.props.data;
        const {customerid, billingaccount } = activeCustomerPool;
        return (
            <div className="white">
                <h1>Order Details: </h1>
                <p>
                    Customer ID: {customerid}
                </p>
                <p>
                    Billing Account ID: {billingaccount}
                </p>
                <p>
                    Order ID: {orderId}
                </p>
                <p>
                    Service ID: {serviceId}
                </p>
                <p>
                    FNN: {fnn}
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.dataActive.view
    }
}

export default connect(mapStateToProps, null)(DataActiveView);
