import * as types from './actionTypes';

export const fetchDiagramImages = () => dispatch => {
    dispatch({ type: types.LOAD_DIAGRAM_IMAGES_LOADING })
    try {
        // API call here
        const response = { data: [] }
        dispatch({ type: types.LOAD_DIAGRAM_IMAGES_SUCCESS, payload: response.data })
        return true;
    } catch (e) {
        console.log(e);
        dispatch({ type: types.LOAD_DIAGRAM_IMAGES_ERROR })
    }
    return false
}