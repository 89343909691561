import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Avatar from 'react-avatar';
import { TelstraIcon } from '../../../../common/icons/SolidIcon';
import { flexStyles, flexStylesCenter } from '../../../../../utils/ui';
import { containsItems } from '../../../../../utils/validation';

class DataViewCustomerSfRow extends Component {

    static propTypes = {
        customer: PropTypes.object.isRequired
    }

    flexStyles = {
        display: "inline-flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
        padding: "5px"
    }

    activeProfile = ({ environment }) => {
        const { available, active } = this.props;
        return available[active[environment]];
    }

    render() {
        const {
            salesforceUrl,
            sfContacts,
            sfBillingAccounts,
            sfSites,
            sfOpps
        } = this.props.customer;
        const { salesforceId } = this.props.customer.salesforceCustomerDetails != undefined ? this.props.customer.salesforceCustomerDetails : {}
        return (
            <tr>
                <td>
                    <a
                        href={`${salesforceUrl}/lightning/r/Account/${salesforceId}/view`}
                        target="_blank"
                        title="View in Salesforce">{salesforceId}</a>
                </td>
                <td>
                    {(containsItems(sfContacts))
                        ? sfContacts.map(contact => {

                            const { id, type, firstName, lastName } = contact;
                            return <React.Fragment key={id}>
                                <span
                                    style={{
                                        "display": "inline-flex",
                                        "alignItems": "center",
                                        "justifyContent": "space-between",
                                        "cursor": "default",
                                        "width": "100%"
                                    }}>
                                    <span>{firstName}{' '}{lastName}</span>
                                    <span
                                        style={{
                                            "width": "40%"
                                        }}>
                                        <span data-tip={type} data-for={id} data-tip-disable={false}>
                                            <Avatar
                                                name={type}
                                                style={{
                                                    "margin": "10px"
                                                }}
                                                size={25}
                                                round={false}
                                                textSizeRatio={2} />
                                            <ReactTooltip type="dark" effect="solid" place="right" id={id} />
                                        </span>
                                    </span>
                                </span>

                            </React.Fragment>
                        })
                        : <p className="text-muted">No contacts exist</p>}
                </td>
                <td>
                    {(containsItems(sfBillingAccounts))
                        ? sfBillingAccounts.map(({ id, name, bdsNum, generated }) => {
                            return (
                                <span key={id} style={flexStylesCenter}>
                                    <a
                                        href={`/billing/account/${bdsNum}`}
                                        target="_blank"
                                        title="View in U2C">{name} ({bdsNum})</a>
                                    <div data-tip={`BDS was ${generated ? 'un' : ''}available during creation`}
                                        data-type={generated ? "error" : "success"}
                                        data-for={id}>
                                        {generated ? <TelstraIcon icon="alert-round" color="magenta" size="xs" /> : <TelstraIcon icon="tick" color="green" size="xs" />}
                                        <ReactTooltip id={id} effect="solid" />
                                    </div>
                                </span>
                            )
                        })
                        : <p className="text-muted">No billing accounts exist</p>}
                </td>
                <td>
                    {(containsItems(sfSites))
                        ? sfSites.map(site => {
                            const { id, name, addressId, addressName, adborId } = site;
                            return (
                                <span key={id}>
                                    <p>{`${name} (${adborId})`}</p>
                                </span>
                            )
                        })
                        : <p className="text-muted">No sites exist</p>}
                </td>
                <td>
                    {(containsItems(sfOpps))
                        ? sfOpps.map(opp => {
                            const { id, name } = opp;
                            return (
                                <div style={flexStyles} key={id}>
                                    <a
                                        key={id}
                                        href={`${salesforceUrl}/lightning/r/Opportunity/${id}/view`}
                                        target="_blank"
                                        title="View in Salesforce">
                                        {`${name}`}
                                    </a>
                                    {(true) && (
                                        <React.Fragment>{' '}</React.Fragment>
                                    )}
                                </div>
                            )
                        })
                        : <p className="text-muted">No opportunities exist</p>
                    }
                </td>
            </tr>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const { active, available } = state.profiles;
    return { active, available }
}

export default withRouter(connect(mapStateToProps, null)(DataViewCustomerSfRow));
