import React from "react";
import { connect } from "react-redux";
import {Table,Card,Tooltip} from '@material-ui/core';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import TrendingDownRoundedIcon from '@material-ui/icons/TrendingDownRounded';

import './slider.css'


const StickyHeadTable = (props) => {
  let checkoutTotal = 0;
  if(props.data !== undefined && props.data.quantitativeScore.standardAsset !== undefined &&
     props.data.quantitativeScore.standardAsset !== null && props.data.quantitativeScore.standardAsset.checkouts !== null)
   props.data.quantitativeScore.standardAsset.checkouts.map((data,index) =>{
      checkoutTotal = checkoutTotal + data.quantity;
  })
  let num = new Number(props.data.qualitativeScore);
  num = num.toPrecision(4);

  let teamName = "";
  props.myAvailableTeams.map((teams) =>
  {
    if(teams.teamId === props.data.teamId)
    {
       teamName = teams.teamName;
    }
  })
  return (
    <Card className="card-box mb-spacing-6-x2 ml-10">
      <div className="card-header d-flex align-items-center justify-content-between card-header-alt p-4">
        <div class="container">
          <h6 className="font-weight-bold font-size-lg mb-1 text-black">
            Quantitative Score
            </h6>
            <div className="headerDivider" />
          <div class="row headerRows">
            <div class="col-9">Team ID :</div>
            <div class="col-3">{props.data.teamId}</div></div>
            <div class="row"> 
            <div class="col-9">Team Name :</div>
            <div class="col-3"><strong>{teamName}</strong></div></div>
            <div class="row"> 
            <div class="col-9">Users :</div>
            <div class="col-3"><strong>{props.data.users}</strong></div></div>
            <div class="row"> 
            <div class="col-9">Qualitative Metric:</div>
            <div class="col-1">{props.data.qualitative}</div>
            {props.data.qualitative == "LEADING" &&<div class=" col-2 leadingIcon"><TrendingUpRoundedIcon/> </div>}
            {props.data.qualitative == "OPTIMISED" &&<div class=" col-2 optimisedIcon"><TrendingUpRoundedIcon/> </div>}
            {props.data.qualitative == "CONSISTENT" &&<div class=" col-2 consistentIcon"><TrendingUpRoundedIcon/> </div>}
            {props.data.qualitative == "REPEATABLE" &&<div class=" col-2 repeatableIcon"><TrendingUpRoundedIcon/> </div>}
            {props.data.qualitative == "ADHOC" &&<div class=" col-2 adhocIcon"><TrendingDownRoundedIcon/> </div>}
            
            </div>
              <div class="row"> 
            <div class="col-9">Qualitative Score  :</div>
            <div class="col-3">{num}%</div></div>
        </div>
      </div>
      <div className="divider" />
      <h6 className="font-weight-medium font-size-md mb-1 ml-5 text-grey">
           Total Quantity:   {checkoutTotal }
            </h6>
      <div className="pt-4 px-4">
        <Table className="table table-alternate-spaced mb-0">
          <thead className="thead-light text-capitalize font-size-sm font-weight-bold">
            <tr>
              <th className="">Service ID</th>
              <th className="px-4" align="center">Service Description</th>
              <th className="px-2" align="center">Qty</th>
              <th className="px-4" align="center">Effort Saved(minutes)</th>
              <th align="right">
                <div className= "row">
                  <div className="col-5"></div><div className="col-7 px-0">Cost Saved($)</div></div>
                </th>
            </tr>
          </thead>
          <tbody>
            {props.data.quantitativeScore.standardAsset.checkouts !== null && props.data.quantitativeScore.standardAsset.checkouts.map((services,index) =>
            {
              return (
                <tr key={index}>
                 
                  <td className="px-4">
                  {(services.effortSaved == 0 || services.costSaved == 0  
                  || services.effortSaved < 0 || services.costSaved < 0 ) ?
                    (<div className ="trendingDown"><TrendingDownRoundedIcon/>{services.serviceId}</div>) :
                    (<div className="trendingUp"><TrendingUpRoundedIcon/>{services.serviceId}</div>)
                  }
                 </td>
                  <td className="pr-4" align="left"><Tooltip title={services.serviceDescription}><div className="servDescRow">
                    {services.serviceDescription}
                    </div></Tooltip></td>
                  <td className="px-2 pl-4" align="left">{services.quantity}</td>
                  <td className="px-4" align="left">
                    <div className="effortCol">{services.effortSaved}</div></td>
                  <td className="px-16" align="right">${services.costSaved}</td>
                </tr>
              )
            }
            )}</tbody>
            
        </Table>
      </div>

    </Card>

  );
}

const mapStateToProps = (state) => {
 return {
      displayReport: state.pasmatTrend.displayReport,
      myAvailableTeams: state.teams.teams.allTeams
  }
}



export default connect(mapStateToProps, null)(StickyHeadTable);