import * as types from "./CONSTANTS";
import {axiosInstance as axios} from "../../axios.config.js";
import {authHeaders, getDataBankRequestParam} from "../../utils/api";
import _ from 'lodash';
import moment from 'moment';
import {EnvirnomentLabels} from '../../components/auth/functions';
import config from '../../config';

const API_URL_GATEWAY = config.tdm_gateway_api.uri;

const parseSucessContent = (content = []) => {
  return _.map(content, (c) => {
      if (c.success === 'True') {
          c.success = true;
      } else if (c.success === 'False') {
          c.success = false;
      }
      return c;
  })
}

const buildUsedDate = (usedDate) => {
  return `usedDate>${usedDate.startDate && usedDate.startDate.startOf('day').unix()},usedDate<${usedDate.endDate && usedDate.endDate.startOf('day').unix()},`
}

 const addEnvFilters = (filterString, envValue) => {
  return filterString += `envFilter:${envValue}`
  }
export const retrieveStandardDataBankCheckOutHistory = (pageNum, filters=[],isUser, request) => async (dispatch, getState)  => {
    dispatch({type: types.FETCH_DATA_BANK_CHECKOUT_HISTORY});
    const isFilterByUserStory = getState().dataBankCheckoutHistory.isFilterByUserStory || false;
let req = request;
        await axios.post(`${API_URL_GATEWAY}/tdm/standarddatabank/checkout/history/retrieve`, req, { headers: authHeaders() })
        .then(response => {
            response.data.filters = filters;
            response.data.content = parseSucessContent(_.get(response, 'data.content', []));
            dispatch({type: types.FETCH_DATA_BANK_CHECKOUT_HISTORY_FULFILLED, payload: response.data})
        })
        .catch((error) => {
            console.error(error);
            dispatch({type: types.FETCH_DATA_BANK_CHECKOUT_HISTORY_REJECTED, payload: _.get(error, 'response.data', {})})
        });
}

export const selectFilter = ({ key, value }) => async dispatch => {
    try {
      const payload = { key, value };
      dispatch({ type: types.SELECT_TABLE_FILTER, payload });
    } catch (e) {
      console.error(e);
    }
  };

  export const fetchCheckoutFilterByFieldAndValue = (field, filter,isUser, request) => async (dispatch, getState) => {
    try {
        const teamId = getState().dataBankCheckoutHistory.teamId || '';
        const isUserToggle = getState().dataBankCheckoutHistory.isUserToggle || false;
		    const selectedEnvValue = getState().auth.selectedEnvValue || EnvirnomentLabels.INT2;
        let req = request;
        dispatch({ type: types.DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_LOADING, loading: true, key: field });
        let response;
        if (field === 'serviceProductCode'){
          response = await axios.post(`${API_URL_GATEWAY}/tdm/standarddatabank/checkout/history/retrieve/filter`, req, { headers: authHeaders() });
        } else {
          response = await axios.post(`${API_URL_GATEWAY}/tdm/standarddatabank/checkout/history/retrieve/filter`, req, { headers: authHeaders() });
        }

        // Added logic to handle userStory and serviceId at same column in case of checkout filter
        const isFilterUserStory = getState().dataBankCheckoutHistory.isFilterByUserStory || false;
        const filterTemp = getState().dataBankCheckoutHistory.filter || {};
        const isServiceId = _.find(filterTemp && filterTemp.factive || [], (fa) => fa.field === 'serviceId' && fa.value !== "");
        let isFilterByUserStory = isServiceId && isFilterUserStory ? true : false;
        if (field === 'serviceId' && _.isEmpty(_.get(response, 'data.data', []))) {
          response = await axios.post(`${API_URL_GATEWAY}/tdm/standarddatabank/checkout/history/retrieve/filter`, req, { headers: authHeaders() });
        }
        if (field === 'serviceId' && _.isEmpty(_.get(response, 'data.data', []))) {
          response = await axios.post(`${API_URL_GATEWAY}/tdm/standarddatabank/checkout/history/retrieve/filter`, req, { headers: authHeaders() });
          if (response && response.data) {
            response.data.key = 'serviceId';
            isFilterByUserStory = true;
          }
        }
        dispatch({ type: types.DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_SUCCESS, payload: response.data,  isFilterByUserStory: isFilterByUserStory });
        return true;
    } catch (e) {
        console.error(e);
        dispatch({ type: types.DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_ERROR, key: field });
    }
    return false;
}


export const setTeamId = (teamId = '') => async dispatch => {
    try {
      dispatch({ type: types.DATA_BANK_CHECKOUT_TEAM_ID, payload: teamId });
    } catch (e) {
      console.error(e);
    }
};

export const setUserToggle = (isUserToggle = false) => async dispatch => {
  try {
    dispatch({ type: types.DATA_BANK_CHECKOUT_IS_TOGGLE_USER, payload: isUserToggle });
  } catch (e) {
    console.error(e);
  }
};
