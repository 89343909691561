import React, { Fragment, useState,useEffect} from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Box, Button,TextField,Paper, Grid} from "@material-ui/core";
import { reduxForm, Field} from 'redux-form';
import { renderField } from '../../common/fields/fields';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { salesForceServiceIdValidator,salesForceDeviceIdValidator } from '../../common/form/syncValidators'
import SearchIcon from '@material-ui/icons/Search';
import {searchSalesForceData ,updateSalesForceData,resetSalesforcePanel
} from '../../../actions/replicatorActions';
import "./salesforceSection.css";
import swal from "sweetalert2";

const SalesForceUpdateSection = (props) => {
  const [searchType, setSearchType] = useState('CUSTOMER_ID');
  const [customerValue, setCustomerId] = useState("");
  const [deviceValue , setDeviceValue] = useState("");
  const [finalRemainingAmnt , setFinalRemainingAmnt] = useState(0);
  const [finalRemainingTerm ,setFinalRemainingTerm] = useState(0);
  const [salesStatus , setSalesStatus] = useState("")
  const [contractType , setContractType] = useState("");
  const [deviceType , setDeviceType] = useState("");
  const [deviceID , setDeviceID] = useState("");
  const [emptyRecords , setEmptyRecords] = useState(false);

  const handleChange = (event) => {
    setSearchType(event.target.value);
  };

  const handleSubmit = () => {
    let searchValue = customerValue;
    const env = props.selectedEnv;
    if(!getSearchType())
    searchValue = deviceValue;
    props.searchSalesForceData(searchType,searchValue,env).then((response) =>
    {
      if(response != undefined){
      setFinalRemainingAmnt(response.payload.Final_Remaining_Amount__c);
      setFinalRemainingTerm(response.payload.Final_Remaining_Term__c);
      setSalesStatus(response.payload.Status__c) 
      setDeviceType(response.payload.Device_Type__c)
      setDeviceID(response.payload.Device_Id__c)
      setContractType(response.payload.Contract_Type__c)
      setEmptyRecords(response.noRecords)}
    });
  }

  const updateSalesForce = () =>
  {
    const env = props.selectedEnv;
    const serviceURL = props.serviceURL;
    const data = {"Final_Remaining_Amount__c":finalRemainingAmnt,
    "Final_Remaining_Term__c":finalRemainingTerm,
    "Status__c":salesStatus,
    "Environment":env,
    "Service_Add_On__c_Url" : serviceURL}
    props.updateSalesForceData(data).then((response) =>
    {
      if(response == true)
      {
        swal.fire(
          "Success",
          `Salesforce Data Updated`,
          "success"
        );
        props.resetSalesforcePanel();
      }
      else
      {
        swal.fire(
          "Error",
          `Error while updating Salesforce Data`,
          "error"
        );
      }
    }
    )

  }

  const getSearchType = () => {
    if (searchType === "CUSTOMER_ID")
      return true;
    else
      return false
  }
  return (
    <Fragment>
      <div className="row my-l">
        <Box>
        <form>
          <FormControl component="fieldset">
            <FormLabel component="legend">Search </FormLabel>
            <RadioGroup aria-label="Customer Id" name="searchTypeCustomerId" value={searchType} onChange={handleChange}>
              <FormControlLabel value="CUSTOMER_ID" control={<Radio />} label="Service Id" />
              <FormControlLabel value="DEVICE_ID" control={<Radio />} label="Device Id" />
            </RadioGroup>
              {getSearchType() ?
                (<div className="col-12">
                  <Field
                    label="Service ID"
                    name="customerId"
                    tabIndex="1"
                    component={renderField}
                    autoComplete="off"
                    required
                    validate={salesForceServiceIdValidator}
                    onChange={(e) => setCustomerId(e.target.value)}/>
                </div>) :
                (<div className="col-12">
                  <Field
                    label="Device ID"
                    name="deviceId"
                    tabIndex="1"
                    component={renderField}
                    autoComplete="off"
                    required 
                    onChange={(e) => setDeviceValue(e.target.value)}
                    validate={salesForceDeviceIdValidator}/></div>)}
            <Button variant="contained" color="primary" endIcon={<SearchIcon />}
            onClick={() => handleSubmit()}>Search</Button>
          </FormControl></form>
        </Box></div>
        {props.showSalesforcePanel &&
               <div id="sales-force-panel" className="row">
               <Grid container spacing={3} className="showSalesForceLabel">
                 <Grid item xs={2}>
                   <Paper>Service Id</Paper>
                 </Grid>
                 <Grid item xs={1} className="contractLabel">
                   <Paper>Contract</Paper>
                 </Grid>
                 <Grid item xs={2}>
                   <Paper>Device Type</Paper>
                 </Grid>
                
                 <Grid item xs={2} className="imeiLabel">
                   <Paper>IMEI</Paper>
                 </Grid>
                 <Grid item xs={2} className="finalAmntLabel">
                   <Paper>Final Amt</Paper>
                 </Grid>
                 <Grid item xs={2} className="finalTermLabel">
                   <Paper>Final Term</Paper>
                 </Grid>
                 <Grid item xs={2} className="salesforceStatus">
                   <Paper>Status</Paper>
                 </Grid>
                 <Grid item xs={1} className="monthlyAmnt">
                   <Paper>Monthly Amt</Paper>
                 </Grid>
                 </Grid>
                 <Grid container spacing={3} className="repaymentDataGrid">
                 <Grid item xs={2}>
                 <Paper>{props.salesServiceID}</Paper>
                 </Grid>
                   <Grid item xs={1} className="contractLabel">
                   <Paper>{contractType}</Paper>
                 </Grid>
                 <Grid item xs={2}>
                   <Paper>{deviceType}</Paper>
                 </Grid>
                 <Grid item xs={2} className="imeiLabel">
                   <Paper>{deviceID}</Paper>
                 </Grid>
                 
                 <Grid item xs={2} className="finalAmntLabel">
                   <Paper>
                   <TextField id="standard-basic" value={finalRemainingAmnt}
                   onChange={(e) => setFinalRemainingAmnt(e.target.value)}/></Paper>
                 </Grid>
                 <Grid item xs={2} className="finalTermLabel">
                   <Paper><TextField id="standard-basic" value={finalRemainingTerm}
                   onChange={(e) => setFinalRemainingTerm(e.target.value)}/></Paper>
                 </Grid>
                 <Grid item xs={2} className="salesforceStatus">
            <Paper>
              <select class="form-control" style={{ WebkitAppearance: 'menulist' }} value={salesStatus}
                onChange={e=>setSalesStatus(e.target.value)}>
                <option value="Ready for Sync">Ready for Sync</option>
                <option value="Sync Completed">Sync Completed</option>
                <option value="Sync Removal Pending">Sync Removal Pending</option>
                <option value="Sync Removal Completed">Sync Removal Completed</option>
                <option value="Processed">Processed</option>
                <option value="Complete">Complete</option>
                <option value="Failure">Failure</option>
              </select>
            </Paper>
                 </Grid>
                 <Grid item xs={1} className="monthlyAmnt">
                   <Paper>62.5</Paper>
                 </Grid>
                 <Grid item xs={1} className="monthlyAmnt">
                 <Button variant="contained" color="primary" className="salesForceUpdateButton"
            onClick={updateSalesForce}>Update</Button>
                 </Grid> 
                 </Grid></div>}
                 {emptyRecords && 
                <div id = "noRecords" className="noRecords">
                 No Details found 
               </div>}
    </Fragment>
  )

}
const mapStateToProps = (state) => {
  return {
      selectedEnv: state.profiles.active.env, 
      showSalesforcePanel : state.replicatorData.salesforceData.showSalesforcePanel,
      salesForceData : state.replicatorData.salesforceData.salesData,
      salesServiceID : state.replicatorData.salesforceData.serviceID,
      remainingAmount :state.replicatorData.salesforceData.remainingAmount,
      remainingTerm : state.replicatorData.salesforceData.remainingTerm,
      status : state.replicatorData.salesforceData.status,
      serviceURL : state.replicatorData.salesforceData.serviceURL,
      noRecords : state.replicatorData.salesforceData.noRecords
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      searchSalesForceData: (searchType,searchValue,selectedEnv) => dispatch(searchSalesForceData(searchType,searchValue,selectedEnv)),
      updateSalesForceData: (data) => dispatch(updateSalesForceData(data)),
      resetSalesforcePanel: () => dispatch(resetSalesforcePanel()),
  }
}
export default compose(connect(mapStateToProps,mapDispatchToProps),reduxForm({
  form: 'salesforceUpdate'
}))(SalesForceUpdateSection);
