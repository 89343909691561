import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";
import SiebelService from "./SiebelService";
import Salesforce from "./Salesforce";

const useStyles = makeStyles({
  stateLabel: {
    backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
    color: "#ffffff  !important",
    marginBottom: "20px !important",
    marginLeft: "20px !important"
  },
  stateSelectedLabel: {
    marginBottom: "20px !important",
    backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
    color: "#ffffff !important",
    marginLeft: "20px !important"
  },
  productHeaderLabel: {
    fontSize: "24px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    paddingLeft: "20px !important"
  },
  yesSpecification: {
    width: "650px !important",
    marginLeft: "23px !important",
    marginTop: "20px !important"
  }

});


const ActiveService = (props) => {

  const classes = useStyles();

  const [activeServices,setActiveServices] = useState(["Siebel","MICA","SALESFORCE"]);
  const [selectedActiveService,setSelectedActiveService] = useState("");

  const [activeServiceData, setActiveServiceData] = useState("");
  const [labelText, setLableText] = useState("");

   const setSpecReqData = (prod,val) => {
    let key = "CUSTOM: ACTIVE "+prod+" DATA";
    setLableText(key)
    props.setSpecificRequirement({[key]: val},"");
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Active Service</Typography>
          {activeServices.map((cable, index) => {
            return (
              <Button variant="contained" classes={cable !== selectedActiveService ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                sx={{ minWidth: 150, minHeight: 150 }} value={cable} onClick={e =>
                 { setSelectedActiveService(e.target.value);
                  setSpecReqData(e.target.value,"");}}>
                {cable}
              </Button>
            )
          })}
        </Grid>
      </Grid>{/* //siebel */}
      <Grid container spacing={3}>
        <Grid item xs={1} />
        {selectedActiveService === "Siebel" ?
        <SiebelService/>: <>
        {selectedActiveService === "MICA" ?
        <TextField
        id="active-service-Data"
        multiline
        rows={2}
        helperText="Enter the specific requirement"
        label={labelText}
        classes={{ root: classes.yesSpecification }}
        value={activeServiceData}
        onChange={e => {setActiveServiceData(e.target.value);
          setSpecReqData(selectedActiveService,e.target.value)}}
      />: <>
      {selectedActiveService === "SALESFORCE" ?
        <Salesforce/>: <>
        
        </>}
        
        </>}
        </>}
      </Grid>

    
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
    prodData: (value) => dispatch(prodData(value)),
    setSpecificRequirement: (value,sID) => dispatch(setSpecificRequirement(value,sID))
  }
}

export default connect(null, mapDispatchToProps)(ActiveService);