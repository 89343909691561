import {axiosTaasInstance as axios} from "../axios.config";
import _ from "lodash";
import {
OPEN_BROADCAST_PANEL,
SET_BROADCAST_LIST_SUCCESS,
SET_BROADCAST_LIST_PENDING,
SET_BROADCAST_LIST_FAILED,
SET_BROADCAST_MESSAGE,

} from "./actionTypes";
import config from "../config";
import { authHeaders } from "../utils/api";


const GATEWAY_URL = config.tdm_gateway_api.uri;
const TAAS_USER_BASE_URL = config.taas_user_base;

export const setBroadCastMessagePanel = (value) => async dispatch => {
try {
dispatch({ type: OPEN_BROADCAST_PANEL, payload: value });
} catch (e) { console.error(e); }
};


export const fetchAllTeamsinBroadcastList = () => async dispatch => {
  let teamList = [];
  try {
    const response = await axios.get(`${GATEWAY_URL}/tdm/data/team/active/details`, { headers: authHeaders() })
    teamList = response.data.teams.map((team) => {
    return { id: team.teamId, name: team.teamName, recType: "team",  broadCastIDNumber:""};
  });
  return teamList;
} catch (e) {
  let errorMsg = "";
  if (typeof e === "string") errorMsg = e;
  else if (e.response) errorMsg = e.response.data.responseDescription;
  else errorMsg = "Network connectivity issues, please try again later.";
  console.error("Error in fetchAllTeamsinBroadcastList", errorMsg);
  return teamList;
}
};

export const fetchAllUsersinBroadcastList = () => async dispatch => {
  let userList = [];
  try {
    const response = await axios.get(`${TAAS_USER_BASE_URL}/users/team?toolName=TDM`, {
      headers: authHeaders()
    });
    userList = response.data.map((user) => {
      const name = `${user.lastName}, ${user.firstName}`;
      return { id: user.userId, name: name, recType: "user" , broadCastIDNumber: " ("+ user.userId + ")"}
    });

    const activeUserList = userList.filter((users) => users.name != null);
    return activeUserList;
  } catch (e) {
    let message;
    if (!e.response) {
      message = "Network connectivity issues, please try again later.";
    } else {
      message = e.response.data.responseDescription;
    }
    console.log(message);
    return userList;
}
};

export const setBroadCastList = () => async dispatch => {
try {
dispatch({ type : SET_BROADCAST_LIST_PENDING })
const teams = await dispatch(fetchAllTeamsinBroadcastList());
const users = await dispatch(fetchAllUsersinBroadcastList());
const broadCastList = [...teams, ...users];
dispatch({ type : SET_BROADCAST_LIST_SUCCESS, payload:broadCastList })
}
catch (error) {
const payload = _.get(error, 'response.data.responseDescription', '');
dispatch({ type: SET_BROADCAST_LIST_FAILED, payload });
console.error("Error in setBroadCastList" ,error);

}


}

export const sendBroadCastMessage = (data) => async dispatch => {
try {
dispatch({ type: SET_BROADCAST_MESSAGE, payload: data });
const response = await axios.post(`${GATEWAY_URL}/tdm/notification/notifications/broadcast`, data, { headers: authHeaders() })
return response
} catch (e) { console.error(e); }
};
