export default [{
    environment: "INT2",
    dataType: "Mobile",
    resourceType: "MSISDN",
    serviceId: 200,
    scope: "PUBLIC"
}, {
    environment: "INT2",
    dataType: "Mobile",
    resourceType: "SIM",
    serviceId: 201,
    scope: "PUBLIC"
}
    // ,
    // {
    //     environment: "INT2",
    //     dataType: "Mobile",
    //     resourceType: "MSISDN and SIM",
    //     serviceId:  50,
    //     scope:"PUBLIC"
    // }
]