import * as actionTypes from './actionTypes';
import mockDataVacantCheckout from '../../../mockFolder/mockDataBankVacantData';

const initialState = {
    checkoutOptions: mockDataVacantCheckout,
    selectedOption: "",
    checkedOutAsset: {},
    assetCheckOutLoading: false,
    assetCheckedOutError: ''
}

export default (state=initialState,action)=>{
    switch (action.type){
        case actionTypes.ASSET_CHECKOUT_LOADING:
            return {...state,assetCheckOutLoading: true,assetCheckedOutError:""} ;
        case actionTypes.ASSET_CHECKOUT_SUCCESS:
            return {...state, checkedOutAsset: action.payload, assetCheckOutLoading: false,assetCheckedOutError:""}
        case actionTypes.ASSET_CHECKOUT_FAILURE:
            return {...state, assetCheckedOutError: action.payload, assetCheckOutLoading: false}
        case actionTypes.ASSET_CLEAR_CHECKOUT:
            return {...state, ...initialState}
        default:
            return state
    }
}