import { Col, Container, Label, Row, Table, Tooltip } from 'reactstrap';
import React, { Component, useRef } from 'react';
import { isEmail } from 'validator';
import PropTypes from 'prop-types'
import ReactDOMServer from 'react-dom/server';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import { withRouter } from 'react-router';
import {
    createCustomerBaSalesforce,
    createCustomerContactSalesforce,
    createCustomerOppSalesforce,
    createCustomerSiteSalesforce
} from '../../../../../actions/sfCustomerActions';
import { SubHeader } from '../../../../tconnect/modals/HeaderContent';
import { TemplateHeader } from './header/TemplateHeader';
import DataViewCustomerSfRow from './DataViewCustomerSfRow';
import './SfCustomer.css';

export const checkProfileThenCallAction = (action, selected) => {
    const isProfileSelected = selected;
    if (!isProfileSelected) {
        // alert user they need a salesforce profile
        swal.fire({
            type: 'warning',
            title: 'Salesforce Profile Required',
            text: 'You must select a salesforce profile for this environment in order to perform this action',
            footer: '<p class="text-primary text-center">Please select profile on top right of portal, if you are unable to select a profile for this environment, you will need to request access<' +
                '/p>'
        })
        return;
    }
    // call action
    action();
}

class DataViewCustomerSf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {}
        }
    }
    static propTypes = {
        customer: PropTypes.object.isRequired
    }

    renderSalesforceAddButton = (action, loading, disabled, disabledMsg) => {
        const disableButton = (loading || disabled);
        const { isProfileSelected } = this.props;
        return (
            <span
                data-tip={disabledMsg}
                data-tip-disable={(!(disableButton && disabledMsg))}
                style={{}}>
                <button
                    className={classNames("btn btn-sm btn-outline-success ld-over m-1", { running: loading })}
                    onClick={() => checkProfileThenCallAction(action, isProfileSelected)}
                    disabled={disableButton}>
                    {< i className="fa fa-plus" aria-hidden="true" > </i>}
                    {loading && <div className="ld ld-ring ld-spin"></div>}
                    <ReactTooltip type={"error"} effect="solid" place="bottom" />
                </button>
            </span>
        )
    }

    renderTConnectRedirectButton = () => {
        const { salesforceId } = this.props.customer.salesforceCustomerDetails != undefined ? this.props.customer.salesforceCustomerDetails : {}
       
        return (
            <React.Fragment>
                <span data-tip={"T-Connect"}>
                    <button
                        className={classNames("btn btn-sm btn-outline-primary ld-over m-1")}
                        onClick={() => this.props.history.push(`/tconnect/${salesforceId}`)}>
                        {< i className="td-icon-sm-tconnect icon-telstra" aria-hidden="true" ></i>}
                        <ReactTooltip type={"info"} effect="solid" place="bottom" id="tconnectToolip" />
                    </button>
                </span>
            </React.Fragment>
        )
    }

    handleNewContactModal = (item) => {
        const newContactHtml = (
            <Container>
                <Row>
                    <Col sm="3" style={{ display: "flex", alignItems: "center" }}>
                        <div className="swal2-header">Email:</div>
                    </Col>
                    <Col sm="9">
                        <input id="new-contact-email" className="swal2-input" value={this.props.email} />
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" style={{ display: "flex", alignItems: "center" }}>
                        <div className="swal2-header">Role:</div>
                    </Col>
                    <Col sm="9">
                        <select id="new-contact-role" class="swal2-input">
                            <option>Full Authority</option>
                            <option>Limited Authority</option>
                            <option>Non-Authorised</option>
                            <option>Legal Lessee</option>
                            <option>3rd Party</option>
                            <option>Billing Contact</option>
                            <option>Asset User</option>
                            <option>Advocate</option>
                            <option>Nominated Serv Cust</option>
                        </select>
                    </Col>
                </Row>
            </Container>
        )

        const { salesforceId, environment, profileId } = item.salesforceCustomerDetails ? item.salesforceCustomerDetails : {}
        swal.fire({
            title: "Add Contact",
            html: ReactDOMServer.renderToStaticMarkup(newContactHtml),
            showCancelButton: true,
            preConfirm: () => {
                return {
                    email: document.getElementById('new-contact-email').value,
                    role: document.getElementById('new-contact-role').value
                }
            }
        }).then((response) => {
            const values = { ...response.value };
            const isValid = values.email && isEmail(values.email) && values.role;
            if (!isValid)
                swal.showValidationMessage(`Contact email not failed, please try again`);
            else
                this.props.salesforceCreateContact(item.uniqueId, salesforceId, environment, values.email, values.role, this.activeProfile(profileId))
        })
    }

    activeProfile = (profileId) => {
        const { available } = this.props;
        return available[profileId];        
    }

    render() {
        const item = this.props.customer;
        const { salesforceId, environment, profileId } = this.props.customer.salesforceCustomerDetails ? this.props.customer.salesforceCustomerDetails : {}
        return (
            <React.Fragment>
                <SubHeader
                    text={"Salesforce"}
                    iconLeft={true}
                    small={true}
                    iconClass={"td-icon-sm icon-cloud"}
                    iconColor="blue" />
                <Table>
                    <thead>
                        <tr>
                            <th style={{ "width": "23%", "padding": "0px 5px 18px"  }}>
                                <TemplateHeader icon="fa-building-o" title="Account ID"> </TemplateHeader>
                            </th>
                            <th style={{ "width": "18%" }}>
                                <TemplateHeader icon="fa-address-card-o" title="Contacts">
                                    {this.renderSalesforceAddButton(() => { this.handleNewContactModal(item) },
                                        item.sfContactsLoading, false, "")
                                    }
                                    {environment === "INT2" && this.renderTConnectRedirectButton()}
                                </TemplateHeader>
                            </th>
                            <th style={{ "width": "20%" }}>
                                <TemplateHeader icon="fa-credit-card" title="Billing Accounts">
                                    {this.renderSalesforceAddButton(() => {
                                        if (!item.sfContacts)
                                            return;
                                        this.props.salesforceCreateBa(item.uniqueId, salesforceId, item.sfContacts[Math.floor(Math.random() * item.sfContacts.length)].id, environment, this.activeProfile(profileId))
                                    }, item.sfBaLoading, !((item.sfContacts) && (item.sfContacts.length > 0)), "You must have at least one contact created")}
                                </TemplateHeader>
                            </th>
                            <th style={{ "width": "23%" }}>
                                <TemplateHeader icon="fa-building-o" title="Sites">
                                    <input
                                        className="form-control m-1"
                                        type="text"
                                        placeholder="Address ID"
                                        maxLength="10"
                                        name="siteAddress"
                                        ref={input => this.siteAddressRef = input}
                                        style={{ "height": "31px", "lineHeight": "31px" }}
                                    />
                                    {this.renderSalesforceAddButton(() => {                                        
                                        this.props.salesforceCreateSite(item.uniqueId, salesforceId, this.siteAddressRef.value, environment, this.activeProfile(profileId))
                                    }, item.sfSitesLoading, false, "")}
                                </TemplateHeader>
                            </th>
                            <th style={{ "width": "16%" }}>
                                <TemplateHeader icon="fa-tags" title="Opportunities">
                                    {this.renderSalesforceAddButton(() => {
                                        if (!item.sfContacts)
                                            return;
                                        this.props.salesforceCreateOpp(item.uniqueId, salesforceId, item.sfContacts[Math.floor(Math.random() * item.sfContacts.length)].id, environment, this.activeProfile(profileId));
                                    }, item.sfOppsLoading, !((item.sfContacts) && (item.sfContacts.length > 0)), "You must have at least one contact created")}
                                </TemplateHeader>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <DataViewCustomerSfRow customer={item} isAdmin={this.props.isAdmin} />
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        salesforceCreateContact: (custId, salesforceId, env, email, role, profile) => { dispatch(createCustomerContactSalesforce(custId, salesforceId, env, email, role, profile)) },
        salesforceCreateBa: (custId, salesforceId, contactId, env, profile) => { dispatch(createCustomerBaSalesforce(custId, salesforceId, contactId, env, profile)) },
        salesforceCreateSite: (custId, salesforceId, adborId, env, profile) => { dispatch(createCustomerSiteSalesforce(custId, salesforceId, adborId, env, profile)) },
        salesforceCreateOpp: (custId, salesforceId, contactId, env, profile) => { dispatch(createCustomerOppSalesforce(custId, salesforceId, contactId,  env, profile)) }
    }
}

const mapStateToProps = (state, ownProps) => {
    const { active, available } = state.profiles;
    return {
        isProfileSelected: (active != null),
        active,
        available,
        email: active.email,
        isAdmin: state.auth.isAdmin
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DataViewCustomerSf));