import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Typography,
  Box,
  makeStyles
} from "@material-ui/core";
import { Quantity, FormTextField } from "@library/core/form-elements/";
import { RoundBtn } from "@library/core/buttons";
import ContentBody from "@library/core/surfaces/ContentBody";
import BreadTable from "@library/core/tables/BreadTable";
import {
  fetchOpenOrders,
  fetchAvailableDataAssets,
} from '../../actions/orderBuilderActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
  stateLabel: {
      backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
      color: "#ffffff  !important",
      marginBottom: "20px !important",
      marginLeft: "20px !important"
  },
  stateSelectedLabel: {
      marginBottom: "20px !important",
      backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
      color: "#ffffff !important",
      marginLeft: "20px !important"
  },
  productHeaderLabel: {
      fontSize: "24px !important",
      paddingTop: "5px !important",
      paddingBottom: "5px !important",
      paddingLeft: "20px !important"
  },
  yesSpecification: {
      width: "950px !important",
      marginLeft: "23px !important",
      marginTop: "20px !important"
  }

});


const OrderSummary = (props) => {
  const classes = useStyles();

  const {
    customerInput,
    orderItems,
    cart,

    openOrders = [],
    openOrdersPending,
    openOrdersFailed,
    openOrdersFailedMsg,

    availableDataAssets = [],
    availableDataAssetsPending,
    availableDataAssetsFailed,
    availableDataAssetsFailedMsg,

    fetchOpenOrders,
    fetchAvailableDataAssets,
    resumeTour = () => {},
    orderBuilderType
  } = props;

  const [team, setTeam] = useState("");
  const [reqBy, setReqBy] = useState("");
  const [irNumber, setIrNumber] = useState("");
  const [env, setEnv] = useState("");

  const [formattedCart, setFormattedCart] = useState([]);

  useEffect(() => {
    resumeTour(14,2);
    if (cart) {
        formatCartSummaryViewData();
    }
    if (customerInput) {
        customerInput.team ? setTeam(customerInput.team.label) : null;
        customerInput.reqBy ? setReqBy(customerInput.reqBy.label) : null;
        customerInput.irNumber ? setIrNumber(customerInput.irNumber) : null;
        customerInput.env ? setEnv(customerInput.env.label) : null;
    }
  }, []);

  useEffect(() => {
    if(orderBuilderType==="Standard"){
      const serviceIds = _.map(cart, (value, key) => key);
      if (serviceIds && customerInput && customerInput.team && customerInput.team.label && customerInput.team.value) {
        fetchOpenOrders(customerInput.team.value, customerInput.team.label, customerInput.env.label, serviceIds);
      }
      if (serviceIds && customerInput && customerInput.env) {
        fetchAvailableDataAssets(customerInput.team.value, customerInput.env.label, serviceIds);
      }
    }
  }, [customerInput, cart]);

  const formatCartSummaryViewData = () => {
    const data = _.map(cart, (val, key) => {
        let item = orderItems.find((obj => obj['id'] == key));
        if (item) {
            item.quantity = val;
            return item;
        }
    }).filter(val => val);
    setFormattedCart(data);
  }

  const cartArgs = {
    expandTitle: "Details",
    size: "medium",
    isLoading: false,
    isExpandable: true,
    columnMetadata: [
      {
        order: 1,
        title: "Service Id",
        field: ["id"],
        expandable: false,
        component: (field) => <RoundBtn>{field}</RoundBtn>,
      },
      {
        order: 2,
        title: "Service Description",
        field: ["serviceDescription"],
        expandable: true,
        component: (field) => (
          <Typography style={{"width":"190px","margin":"auto"}} noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 3,
        title: "Acceptance Criteria",
        field: ["acceptanceCriteria"],
        expandable: true,
        component: (field) => (
          <Typography style={{"width":"295px","margin":"auto"}} noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 4,
        title: "Quantity",
        field: ["quantity"],
        expandable: false,
        component: (quantity) => (
          <Quantity qty={quantity} 
                    id={quantity}
                    onChangeQty={()=>{}}
                    disabled
          />
        ),
      },
    ],
  }

  const customCartArgs = {
    expandTitle: "Details",
    size: "medium",
    isLoading: false,
    isExpandable: true,
    noWrap: true,
    columnMetadata: [
      {
        order: 1,
        title: "Service Id",
        field: ["id"],
        expandable: false,
        component: (field) => <RoundBtn>{field}</RoundBtn>,
      },
      {
        order: 2,
        title: "Service Description",
        field: ["serviceDescription"],
        expandable: true,
        component: (field) => (
          <Typography style={{"width":"190px","margin":"auto"}} noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 3,
        title: "Acceptance Criteria",
        field: ["acceptanceCriteria"],
        expandable: true,
        component: (field) => (
          <Typography style={{"width":"295px","margin":"auto"}} noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 4,
        title: "Quantity",
        field: ["quantity"],
        expandable: false,
        component: (quantity) => (
          <Quantity qty={quantity} 
                    id={quantity}
                    onChangeQty={()=>{}}
                    disabled
          />
        ),
      },
    ],
  }

  const openOrderArgs = {
    size: "medium",
    isLoading: openOrdersPending,
    rowsPerPage: 5,
    rowsPerPageOptions: [5],
    heightPerRowForEmptyLoad: 42.5,
    fixedNumRows: true,
    noDataMsg: openOrdersFailed ? openOrdersFailedMsg : "",
    noWrap: true,
    columnMetadata: [
      {
        order: 1,
        title: "Jira Id",
        field: ["jiraId"],
        component: (field) => <Box height={"20px"}><Typography noWrap variant="body2">{field}</Typography></Box>,
      },
      {
        order: 2,
        title: "Serv Id",
        field: ["id"],
        component: (field) => (
          <Typography noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 3,
        title: "Qty Req",
        field: ["quantityReq"],
        component: (field) => (
          <Typography noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 4,
        title: "Qty Alct",
        field: ["quantityAlct"],
        component: (field) => (
          <Typography noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 5,
        title: "Status",
        field: ["status"],
        component: (field) => (
          <Typography noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
    ],
  }
  const availableDataAssetArgs = {
    size: "medium",
    isLoading: availableDataAssetsPending,
    rowsPerPage: 5,
    rowsPerPageOptions: [5],
    heightPerRowForEmptyLoad: 42.5,
    fixedNumRows: true,
    noDataMsg: availableDataAssetsFailed ? availableDataAssetsFailedMsg : "",
    noWrap: true,
    columnMetadata: [
      {
        order: 1,
        title: "Team",
        field: ["serviceId"],
        component: (field) => <Box height={"20px"}><Typography noWrap variant="body2">{field ? team : ""}</Typography></Box>,
      },
      {
        order: 2,
        title: "Service ID",
        field: ["serviceId"],
        component: (field) => (
          <Typography noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        order: 3,
        title: "Quantity",
        field: ["quantity"],
        component: (field) => (
          <Typography noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
    ],
  }

  return (
    <>
        <ContentBody marginTop={1} marginBottom={5}>
            <Grid container direction="column" id="tour-orderbuilder-customerInputSummary">
                <Grid container direction="row" spacing={4} style={{paddingBottom: "4.5px"}}>
                    <Grid item sm={12} md={3}>
                        <FormTextField 
                          label="Team"
                          id="team"
                          fullWidth
                          value={team}
                          readOnly
                        />
                    </Grid>
                    <Grid item sm={12} md={2}>
                        <FormTextField 
                          label="Environment"
                          id="environment"
                          fullWidth
                          value={env}
                          readOnly
                        />
                    </Grid>
                    <Grid item sm={12} md={4}>
                        <FormTextField 
                          label="Required by"
                          id="requiredForTestingBy"
                          fullWidth
                          value={reqBy}
                          readOnly
                        />
                    </Grid>
                    <Grid item sm={12} md={3}>
                        <FormTextField 
                          label="Capability"
                          id="Capability"
                          fullWidth
                          value={irNumber}
                          readOnly
                        />
                    </Grid>
                </Grid>
            </Grid>
        </ContentBody>  
        {props.isCustomOrder ?
         <Grid container spacing={3}>
                <Grid item xs={12}>
                <Typography classes={{ root: classes.productHeaderLabel }}>The Selected Custom Order</Typography>
                <TextField
                            id="customkey"
                            multiline
                            rows={2}
                            disabled
                            classes={{ root: classes.yesSpecification }}
                            value={Object.keys(props.cart)}/>
                </Grid>
           </Grid> : 
          <>
          <div id="tour-orderbuilder-cartSummary">
            <BreadTable {...customCartArgs} title="Cart" titleVariant="h5" data={formattedCart}/>
          </div>
          <Grid container direction="row" spacing={2}>
            <Grid item sm={12} md={6} id="tour-orderbuilder-openOrders">
              <BreadTable {...openOrderArgs} noWrap title="Open Orders" titleVariant="h5" data={openOrders}/>
            </Grid>
            <Grid item sm={12} md={6} id="tour-orderbuilder-availableDataAssets">
              <BreadTable {...availableDataAssetArgs} title="Available Data Assets" titleVariant="h5" data={availableDataAssets}/>
            </Grid>
          </Grid>
          </>
        }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isCustomOrder: state.orderBuilder.isCustomOrder,
    cart: state.orderBuilder.cart,
    orderItems: state.orderBuilder.orderItems,
    customerInput: state.orderBuilder.customerInput,
    isCustomOrder: state.orderBuilder.isCustomOrder,
    openOrders: state.orderBuilder.openOrders,
    openOrdersPending: state.orderBuilder.openOrdersPending,
    openOrdersFailed: state.orderBuilder.openOrdersFailed,
    openOrdersFailedMsg: state.orderBuilder.openOrdersFailedMsg,
    openOrdersSuccess: state.orderBuilder.openOrdersSuccess,

    availableDataAssets: state.orderBuilder.availableDataAssets,
    availableDataAssetsPending: state.orderBuilder.availableDataAssetsPending,
    availableDataAssetsFailed: state.orderBuilder.availableDataAssetsFailed,
    availableDataAssetsFailedMsg: state.orderBuilder.availableDataAssetsFailedMsg,
    availableDataAssetsSuccess: state.orderBuilder.availableDataAssetsSuccess,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchOpenOrders:(teamId,teamName,env,serviceIds) => dispatch(fetchOpenOrders(teamId,teamName,env,serviceIds)),
    fetchAvailableDataAssets:(teamId,env,serviceIds) => dispatch(fetchAvailableDataAssets(teamId,env,serviceIds)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
