import React from 'react'
import { Field, reduxForm, FieldArray,change } from 'redux-form'
import { renderField } from '../../common/fields/fields';
import { connect } from "react-redux";
import { compose } from 'redux';
import { Button, Collapse } from 'reactstrap';
import '../modals/TConnectOrderModal.css';
import SiteDetailsComponent from './SiteDetailsComponent';
import SubStageDetailsComponent from './SubStageDetailsComponent';
import swal from 'sweetalert2';
import moment from 'moment';
class OrderRequestForm extends React.Component {
    constructor(props){
        super(props)
        this.toggleCollapse = this.toggleCollapse.bind(this);
    }
    state = {
        collapse : [true, false, false],
        orderDetails: {},
        finalRequest: {},
        cidn: '',
        customerName:'',
        fnn:'',
        submitting : false
    }

    toggleCollapse = (i) =>{
        const prevState = this.state.collapse;
        const state = prevState.map((x, index) => i === index ? !x : false);
        this.setState({
            collapse: state,
        });
    }
    
    onSubmit = formProps => {
        formProps.datahubOrderDetailsDto.orderTcd = this.formatDate(formProps.datahubOrderDetailsDto.orderTcd);
        formProps.datahubOrderDetailsDto.orderFdd = this.formatDate(formProps.datahubOrderDetailsDto.orderFdd);
        formProps.datahubOrderDetailsDto.orderCompletionDate = this.formatDate(formProps.datahubOrderDetailsDto.orderCompletionDate);
        formProps.datahubOrderSiteDetailsDto && formProps.datahubOrderSiteDetailsDto.map(site => {
            site.siteTcd=this.formatDate(site.siteTcd);
            site.siteFdd=this.formatDate(site.siteFdd);
            site.siteCompletionDate=this.formatDate(site.siteCompletionDate);
            site.datahubOrderProductDetailsDto && site.datahubOrderProductDetailsDto.map(product =>{
                    product.productTcd=this.formatDate(product.productTcd);
                    product.productFdd=this.formatDate(product.productFdd);
                    product.productCompletionDate=this.formatDate(product.productCompletionDate);
           })
        })
        formProps.datahubOrderSubstagesDetailsDto && formProps.datahubOrderSubstagesDetailsDto.map(subStage => {
            subStage.currentEstimatedEndDate=this.formatDate(subStage.currentEstimatedEndDate);
            subStage.datahubOrderCommentsDetailsDto && subStage.datahubOrderCommentsDetailsDto.map(comment =>{
                comment.commentsUpdatedDate=this.formatDate(comment.commentsUpdatedDate);
           })
        })
        let request = {
            cidn : this.props.cidn,
            customerName : this.props.customerName,
            ...formProps
        }
        console.log(request)
        this.setState({ submitting: true })
        this.props.submitOrderDetails(request)
        .then(response => {
            swal.fire({
                type: 'success',
                title: 'TConnect - Order Create',
                text: 'Order  created Successfully.',
            }).then(() => {
                this.toggleFormModal();
            })

        })
        .catch(error => {
            swal.fire({
                type: 'error',
                title: 'TConnect - Order Create',
                onOpen: () => {
                    swal.showValidationMessage(error.response.data.message || "Unknown error has occured")
                }
            }).then(() => {
                this.toggleFormModal();
            })
        })
        .finally(() => {
            this.setState({ submitting: false })
        })
    }

    formatDate = (dateValue) =>{
        return (moment(dateValue)).toISOString().split("T")[0];
    }
    componentDidUpdate = (prevProps) => {
        if (this.props !== prevProps) {
            this.props.loadFnnToField('orderCreateForm','datahubOrderDetailsDto.fnn', this.props.fnn)
        }
      };
    toggleFormModal =() =>{
        this.props.reset();
        this.props.toggleModal();
        this.setState({
            siteCollapse : false,
            subStageCollapse : false
        })
    }
  render(){
    const { handleSubmit, pristine, reset } = this.props
    const {submitting} = this.state;
    const statusOptions = [
        { label: "IN PROGRESS", value: "IN PROGRESS" },
        { label: "ACTIVE", value: "ACTIVE" }
      ];

      const typeOptions = [
        { label: "MODIFY", value: "MODIFY" },
        { label: "ACTIVE", value: "ACTIVE" }
      ];
    const buttonStyle = {
        marginBottom: '1rem',
        width: '100%',
        textAlign: 'left'
    }
    return (
          <form onSubmit={handleSubmit(this.onSubmit)}>
               <Button color="info" onClick={ () => this.toggleCollapse(0)} style={buttonStyle}>Order Details</Button>
               <Collapse isOpen={this.state.collapse[0]}>
                <div className='row'>
                <div className='col-sm-4'>
                    <div>
                        Order TCD
                    </div>
                    <Field
                        name={`datahubOrderDetailsDto.orderTcd`}
                        label=""
                        type="date"
                        autoComplete="off"
                        component={renderField}   
                    /> 
                    </div>
                    <div className='col-sm-4'>
                        <div>
                            Order FDD
                        </div>
                    <Field
                        name={`datahubOrderDetailsDto.orderFdd`}
                        label=""
                        type="date"
                        autoComplete="off"
                        component={renderField}   
                    />
                    </div>
                    <div className='col-sm-4'>
                        <div>
                            Order Completion Date
                        </div>
                    <Field
                        name={`datahubOrderDetailsDto.orderCompletionDate`}
                        label=""
                        type="date"
                        autoComplete="off"
                        component={renderField}   
                    />
                    </div>
                    <div className='col-sm-4'>
                    <Field
                        name={`datahubOrderDetailsDto.ncp`}
                        label="NCP"
                        type="text"
                        autoComplete="off"
                        component={renderField}   
                    />
                    </div>
                    <div className='col-sm-4'>
                    <Field
                        name={`datahubOrderDetailsDto.fnn`}
                        label="FNN"
                        type="text"
                        autoComplete="off"
                        component={renderField}   
                    />
                    </div>
                    <div className='col-sm-4'>
                    <Field
                        name={`datahubOrderDetailsDto.status`}
                        label="Status"
                        type="selectWithCustomHeight"
                        autoComplete="off"
                        component={renderField}   
                        options={statusOptions}
                    />
                    </div>
                    <div className='col-sm-8'>
                        <Field
                        name={`datahubOrderDetailsDto.title`}
                        label="Title"
                        type="text"
                        autoComplete="off"
                        component={renderField}   
                    />
                    </div>
                    <div className='col-sm-4'>
                        <Field
                        name={`datahubOrderDetailsDto.orderType`}
                        label="Order Type"
                        type="selectWithCustomHeight"
                        autoComplete="off"
                        component={renderField}   
                        options={typeOptions}
                    />
                    </div>
                </div>
                </Collapse>
                <Button color="info" onClick={ () => this.toggleCollapse(1)} style={buttonStyle}>Site Details</Button>
                    <Collapse isOpen={this.state.collapse[1]}>
                        <FieldArray
                            name="datahubOrderSiteDetailsDto"
                            component={SiteDetailsComponent}
                            />
                    </Collapse>
                <Button color="info" onClick={ () => this.toggleCollapse(2)} style={buttonStyle}>SubStage Details</Button>
                    <Collapse isOpen={this.state.collapse[2]}>
                        <FieldArray
                            name="datahubOrderSubstagesDetailsDto"
                            component={SubStageDetailsComponent}
                            />
                    </Collapse>
                <div style={{textAlign: 'right'}}>
                    <Button color="primary" style={{margin : '20px'}}  type="submit" disabled={submitting}>
                        {submitting && <i className="fa fa-spinner fa-pulse fa-fw"></i>}Create</Button>
                    <Button color="danger"  disabled={submitting} onClick={this.toggleFormModal}>Cancel</Button>
                </div>
          </form>
      ) 
  }
}

const mapStateToProps = (state, ownprops) =>{
    return{
        initialValues : { 
            datahubOrderDetailsDto : {
                orderTcd: new Date(),
                orderFdd: new Date(),
                orderCompletionDate: new Date(),
                status: 'ACTIVE',
                orderType :'MODIFY',
                ncp :'NCP-00853149',
                title : 'ACC_ORCH_PROD_PLAN_NEW_03',
                fnn : state.tconnect.dataview.fnn
            },
            datahubOrderSiteDetailsDto : [
                {
                    siteName : "Ingleburn Town Centre O/S",
                    siteTcd: new Date(),
                    siteFdd: new Date(),
                    siteCompletionDate: new Date()
                }
              ],
              datahubOrderSubstagesDetailsDto :[
                {
                    customerStage : "Received", 
                    customerSubStage: "Customer Order Acceptance",  
                    currentEstimatedEndDate: new Date(),  
                    state: "In Progress"
                }
            ]
        }
    }
  }

  const validate = values => {
    let errors = {}
    if (values && values.datahubOrderDetailsDto && !values.datahubOrderDetailsDto.fnn)  {
        errors.datahubOrderDetailsDto = {fnn : 'Required'}
    }
    if (values && values.datahubOrderDetailsDto && values.datahubOrderDetailsDto.fnn && values.datahubOrderDetailsDto.fnn === "Error")  {
        errors.datahubOrderDetailsDto = {fnn : 'Error fetching fnn, try again'}
    }
    return errors;
}
  
const mapDispatchToProps = dispatch => {
    return {
      loadFnnToField: (formName, fieldName, value) => {
        dispatch(change(formName, fieldName, value));
      }
    };
  };
  
export default compose(connect(mapStateToProps, mapDispatchToProps),reduxForm({
    validate,
    form: 'orderCreateForm',                 
    destroyOnUnmount: true
  },mapStateToProps))(OrderRequestForm);
