import React, {Component} from 'react';
import {connect} from 'react-redux';
import LinkTile from "../custom/LinkTile";
const tileStyle = {
    background: 'linear-gradient(to right, #cc6699 0, #cc6699 100%)',
}
class DataStatsTile extends Component {
    render() {
        const color = "bg-pink";
        if (Object.keys(this.props.stats).length == 0) {
            return (
                <LinkTile
                    style={tileStyle}
                    className="bg-purple"
                    data-size="medium"
                    data-effect="hover-zoom-up">
                    <div class="slide-front">
                        <span className="fa fa-address-card-o icon"></span>
                        <span className="branding-bar">Data Stats</span>
                    </div>
                    <div
                        class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
                        <p class="text-center">
                            Coming Soon
                        </p>
                    </div>
                </LinkTile>
            )
        }
        const {vacantData, activeData, usedToday, used7days, threshold} = this.props.stats;
        return (
            <LinkTile className={color} data-size="medium" data-effect="animate-slide-down" to="/stats/data">
                <div class="slide">
                    <h1 class="pos-absolute pos-center">{vacantData}</h1>
                    <span class="branding-bar">Vacant Data</span>
                    <span class="badge-top">
                        <i className="fa fa-bar-chart" aria-hidden="true" />
                    </span>
                </div><div class="slide">
                    <h1 class="pos-absolute pos-center">{usedToday}</h1>
                    <span class="branding-bar">Data Used</span>
                    <span class="badge-top">
                        Today
                    </span>
                </div>
                <div class="slide">
                    <h1 class="pos-absolute pos-center">{used7days}</h1>
                    <span class="branding-bar">Data Used</span>
                    <span class="badge-top">
                        This Week
                    </span>
                </div>
            </LinkTile>
        )
    }
}

const mapStateToProps = (state) => {
    return {stats: state.stats.items}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataStatsTile);