import React from "react";
import { Field } from "redux-form";
import { renderField } from "../common/fields/fields";
import { cleanupRangeValidator } from "../common/form/fieldValidators";

class SomRangeInput extends React.Component {
  state = {
    autoGeneratedRows: [],
    rangeInvalid: true,
  };

  validateRange = (value, allValues, props, name) => {
    let resp = null;
    if (this.props.rangeValidator) {
      resp = this.props.rangeValidator(value, allValues, props, name);
    } else {
      resp = cleanupRangeValidator(value, allValues, props, name);
    }
    (allValues.ranges && allValues.ranges[0].startRange !== '' && allValues.ranges[0].endRange !== '' && resp === undefined) ?
    this.setState(prevState => ({...prevState, rangeInvalid: false})) :
    this.setState(prevState => ({...prevState, rangeInvalid: true}));
    return resp;
  }

  render() {
    const { primaryButton, handlePrimary } = this.props;
    return (
      <div id="unms-range">
        <div className="row" id="unms-range-header">
          <div className="col-3">
            <span>Start Range</span>
          </div>
          <div className="col-3">
            <span>End Range</span>
          </div>
        </div>
            <div className="row">
              <div className="col-3 cleanup-text-margin">
                <Field
                  name={`ranges[0].startRange`}
                  component={renderField}
                  validate={this.validateRange}
                  invalid={this.props.errorMessage}
                  type={"text"}
                  showValidMsg={false}
                  touchOnChange={true}
                  autoComplete="off"
                />
              </div>
              <div className="col-3 cleanup-text-margin">
                <Field
                  name={`ranges[0].endRange`}
                  validate={this.validateRange}
                  component={renderField}
                  touched={true}
                  invalid={this.props.errorMessage}
                  type={"text"}
                  showValidMsg={false}
                  autoComplete="off"
                />
              </div>
              {primaryButton && handlePrimary 
                && <div className="unms-button-area" id="unms-button-add">
                      <button
                        type="button"
                        className="btn btn-primary unms-button"
                        onClick={handlePrimary}
                        disabled={this.state.rangeInvalid}
                      >
                        {primaryButton}
                      </button>
                    </div>
              }
            </div>
      </div>
    );
  }
}


export default SomRangeInput;
