import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, Field, change } from "redux-form";
import { renderField } from "../../common/fields/fields";
import { Button } from 'reactstrap';
import HeaderContent from '../../common/Header/HeaderContent';
import VerticalTabs from './VerticalTabs';
import Paper from '@material-ui/core/Paper';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import { displayLoading, displaySuccess, displayLoadingWithPercent, updateLoadingWithPercent } from "../../common/alerts/alerts";
import { fetchAryaFiles, submitAryaFiles } from '../../../actions/utilityActions';
import swal from 'sweetalert2';
import './s2p.css';

class S2pArya extends Component {

    state = {
        navItems: [],
        bodyItems: [],
        payloads: [],
        submitDisabled: true,
        submitFailList: [],
        allFilesRetrieved: false,
        selectedFiles: {},
    }

    componentDidMount() {
        this.getAryaFiles();
    }

    throwError = (error) => {
        swal.fire({
            type: 'error',
            title: 'Error',
            html: error,
            confirmButtonText: 'OK',
        }).then(() => {
            this.props.history.replace(`/data/utility/`);
        })
    }

    downloadStringAsTextFile = (fileText, fileName) => {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileText));
        element.setAttribute('download', fileName);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    changeHandler = event => {
        let file = event.type !== 'drop' ? event.target.files[0] : event.dataTransfer.files[0];
        let name = event.type !== 'drop' ? event.target.name : event.currentTarget.firstElementChild.name;

        this.setState(prevState => ({
            ...prevState,
            selectedFiles: {
                ...this.state.selectedFiles,
                [name]: file,
            },
        }), () => {
            this.props.dispatch(change('S2pArya', name, this.state.selectedFiles[name]))
            this.createPayload(name);
        });
	};

    createPayload = (fileChanged) => {
        for (const [name, file] of Object.entries(this.state.selectedFiles)) {
            if (fileChanged === name) {
                const index = this.state.payloads.findIndex(v => v.fileName === fileChanged);
                this.fileReader = new FileReader();
                this.fileReader.onloadend = () => {
                    this.result = this.fileReader.result;
                    if (index === -1) {
                        this.setState(prevState => ({
                            ...prevState,
                            payloads: [...prevState.payloads, { "fileName": name, "fileContent": this.result }],
                            submitDisabled: Object.entries(this.state.selectedFiles).length !== 0 ? false : true,
                        }));
                    } else {
                        const newPayloads = [...this.state.payloads];
                        newPayloads[index].fileContent = this.result;
                        this.setState(prevState => ({
                            ...prevState,
                            payloads: [...newPayloads],
                            submitDisabled: Object.entries(this.state.selectedFiles).length !== 0 ? false : true,
                        }));
                    }
                };
                this.fileReader.readAsText(file);
            }
        }
    };

    createBodyItem = (file) => {
        return (
            <Fragment>
                <h4>{file.name}</h4>
                <br/>
                <Fragment key={file.name}>
                    <Paper className='s2pArya-download-section'>
                        <p>Please download provided template to alter and re-upload with required content</p>
                        <p>Please note responsibility for any changes made is with the user.</p>
                        <br/>
                        <Button color="secondary" type="button" size="sm" onClick={() => this.downloadStringAsTextFile(file.data.fileHeader+'\n'+file.data.fileColumn+'\n'+file.data.fileFooter, `${file.name}_template.txt`)}>Download Template File</Button>
                    </Paper>
                </Fragment>
                <Fragment>
                    <Field
                        label={<span><strong>Choose a file</strong> or drag it here.</span>}
                        name={file.name}
                        component={(field)=>renderField(field, this.state.selectedFiles[file.name] ? this.state.selectedFiles[file.name] : '' )}
                        type='file'
                        onChange={this.changeHandler}
                        {...{
                            onDragOverClass: 'fileUploadDropZone fileUploadDropZone--over',
                            onDragLeaveClass: 'fileUploadDropZone',
                            icon: <span className={`td-icon-sm icon-download fileUploadDropZoneIcon`} />
                        }}
                    />
                </Fragment>
            </Fragment>
        )
    }

    async getAryaFiles() {
        let nameList = [];
        let fileList = [];
        displayLoading({ title: "S2P - Arya", bodyText: "Please standby while we fetch necessary files..." });
        await this.props.fetchAryaFiles();
        if (this.props.s2pArya.isRetrieveFilesFailed) {
            return this.throwError(this.props.s2pArya.response);
        } else if (this.props.s2pArya.isRetrieveFilesSuccess) {
            for (const template of this.props.s2pArya.response.ftemplate) {
                nameList.push(<Fragment key={template.fileName.split('-')[0]}><InputOutlinedIcon color="secondary"/>{template.fileName.split('-')[0]}</Fragment>);
                fileList.push(this.createBodyItem({ name: template.fileName.split('-')[0], data: template }));
            }
        }
        swal.close();
        this.setState({ 
            ...this.state,
            navItems: [...this.state.navItems, ...nameList],
            bodyItems: [...this.state.bodyItems, ...fileList],
            allFilesRetrieved: true,
        });
    }

    async onSubmit() {
        let count = 1;
        let total = this.state.payloads.length;
        this.setState({
            ...this.state,
            submitDisabled: false
        });

        displayLoadingWithPercent({ title: "S2P - Arya", bodyText: "Please standby while files are submitted...", num: count-1, total: total});

        for (const payload of this.state.payloads) {
            await this.props.submitAryaFiles(payload, total, count).then(result => {
                updateLoadingWithPercent(count, total);
                count++;
                let message = '';
                if (result === false) {
                    this.setState({
                        ...this.state,
                        submitFailList: [...this.state.submitFailList, ...[payload.fileName]]
                    });
                }
                if (this.props.s2pArya.isSubmitFilesFinished) {
                    if (this.state.payloads.length === this.state.submitFailList.length) {
                        swal.close();
                        return this.throwError('Failed to Submit all Files');
                    } else if (this.state.submitFailList.length === 0) {
                        message = 'Submitted all Files Successfully';
                    } else {
                        message = 'The following Failed to submit: ' + this.state.submitFailList.join(', ');
                    }
                    swal.close();
                    displaySuccess({ title: "S2P - Arya", html: message}).then(() => {
                        this.props.history.push(`tdm/utility/`);
                    })
                }
            })
        }
    }

    render() {
        return (
            <Fragment>
                <div className="white">
                    <HeaderContent title='S2P - Arya' icon={'cogs cog-icon'} empty={true} loadingText='Loading forms...'/>
                    {this.state.allFilesRetrieved ? <VerticalTabs navItemList={this.state.navItems} bodyItemList={this.state.bodyItems} isForm={true} formSubmitBtnText={'Submit all Files'} formOnSubmit={this.onSubmit.bind(this)} submitDisabled={this.state.submitDisabled}/> : null}
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        s2pArya: state.utility.s2pArya,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchAryaFiles : (request) => dispatch(fetchAryaFiles(request)),
        submitAryaFiles : (type, request, total, count) => dispatch(submitAryaFiles(type, request, total, count)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps),reduxForm({form: "S2pArya"}))(S2pArya);