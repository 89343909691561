import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Component, useState } from 'react';
import React, { forwardRef, useImperativeHandle } from 'react'

import { SubHeader } from '../../common/custom/SubHeader';
import styles from './TConnectOrderModal.css';
import swal from 'sweetalert2'
import { faAssistiveListeningSystems } from '@fortawesome/free-solid-svg-icons';

class TConnectOrderModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            submitting: false,
            isSiteDetailsOpen: false,
            isOrderDetailsOpen: false,
            isProductDetailsOpen: false,
            orderDetails: {},
            initialOrderDetails: {},
            finalRequest: {},
            isNewOrder: false,
            cidn: '',
            customerName:''
        }
    }

    open = data => {
        if (data) {
            this.setState({ orderDetails: data, initialOrderDetails: data, isNewOrder: false,cidn:this.props.cidn,customerName:this.props.customerName })
        } else {
            this.setState({ isNewOrder: true, orderDetails: {}, initialOrderDetails: {}, cidn:this.props.cidn,customerName:this.props.customerName })
        }
        this.toggleModal();
        this.toggle('site');
    }

    toggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen, isSiteDetailsOpen: false, isOrderDetailsOpen: false, isProductDetailsOpen: false })
    }

    toggle = data => {
        if (data === 'site') {
            this.setState({ isSiteDetailsOpen: !this.state.isSiteDetailsOpen })
        } else if (data === 'order') {
            this.setState({ isOrderDetailsOpen: !this.state.isOrderDetailsOpen })
        } else if (data == 'product') {
            this.setState({ isProductDetailsOpen: !this.state.isProductDetailsOpen })
        }
    }

    updateOrderDetails = (e) => {
        let values = this.state.orderDetails;
        let name = e.target.name;
        let value = e.target.value;
        values[name] = value;
        this.setState({ values });
        // this.props.handleOnChange(this.state.orderDetails)
    }

    submitOrderDetails = (e) => {
        e.preventDefault();
        this.setState({ submitting: true })
        let values = this.state.orderDetails;
        values['cidn'] = this.state.cidn;
        values['customerName'] = this.state.customerName;
        this.setState({ values });
        let obj = this.state.finalRequest;
        obj['dataHubOrderUpdObj'] = this.state.orderDetails;
        if(!this.state.isNewOrder){
            obj['dataHubOrderInitObj'] = this.state.initialOrderDetails;
        }
        this.setState({obj});        
        this.props.submitOrderDetails(this.state.finalRequest)
            .then(response => {
                swal.fire({
                    type: 'success',
                    title: 'TConnect - Order Update',
                    text: 'Order updated/ created Successfully.',
                }).then(() => {
                    this.setState({ isOpen: false })
                })

            })
            .catch(error => {
                swal.fire({
                    type: 'error',
                    title: 'TConnect - Order Update',
                    onOpen: () => {
                        swal.showValidationMessage(error.response.data.message || "Unknown error has occured")
                    }
                }).then(() => {
                    this.setState({ isOpen: false })
                })
            })
            .finally(() => {
                this.setState({ submitting: false })
            })
    }

    render() {
        const { orderDetails } = this.state
        const buttonStyle = {
            marginBottom: '1rem',
            width: '100%',
            textAlign: 'left'
        }

        return (
            <Modal isOpen={this.state.isOpen} toggle={this.toggleModal} centered className={styles.TConnectOrderModal} size="lg">
                <form onSubmit={(e) => this.submitOrderDetails(e)}>
                    <ModalHeader className="TConOrderModal--header">
                        <SubHeader
                            text="TConnect - Order Create"
                            iconLeft={true}
                            small={true}
                            iconClass="td-icon-sm icon-telstra"
                            iconColor="blue" />
                    </ModalHeader>

                    <ModalBody>
                        <Button color="primary" onClick={() => this.toggle('site')} style={buttonStyle}>Site Details</Button>
                        {this.state.isSiteDetailsOpen &&

                            <div class="form-row">
                                {/* <div class="form-group col-sm-4">
                                    <label for="name">Name</label>
                                    <input type="text" name="siteName" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.siteName : ''} class="form-control" id="name" maxLength="100" />
                                </div> */}
                                <div class="form-group col-sm-4">
                                    <label for="addr1">Address 1</label>
                                    <input type="text" name="address1" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.address1 : ''} class="form-control" id="addr1" maxLength="200" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="addr2">Address 2</label>
                                    <input type="text" name="address2" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.address2 : ''} class="form-control" id="addr2" maxLength="100" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="zip">Zip Code</label>
                                    <input type="text" name="postCode" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.postCode : ''} class="form-control" id="zip" maxLength="30" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="adborId">Address ID</label>
                                    <input type="text" name="adborId" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.adborId : ''} class="form-control" id="adborId" maxLength="30" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="city">City</label>
                                    <input type="text" name="siteSuburb" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.siteSuburb : ''} class="form-control" id="city" maxLength="50" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="State">State</label>
                                    <input type="text" name="siteState" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.siteState : ''} class="form-control" id="state" maxLength="20" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="country">Country</label>
                                    <input type="text" name="siteCountry" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.siteCountry : ''} class="form-control" id="country" maxLength="30" />
                                </div>
                                {/* <div class="form-group col-sm-4">
                                    <label for="cname">Contact Name</label>
                                    <input type="text" name="contactName" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.contactName : ''} class="form-control" id="cname" maxLength="100" />
                                </div> */}
                                {/* <div class="form-group col-sm-4">
                                    <label for="contactNumb">Contact Number</label>
                                    <input type="text" name="contactPhone" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.contactPhone : ''} class="form-control" id="contactNumb" maxLength="40" />
                                </div> */}

                            </div>
                        }
                        {/* Order Details */}

                        <Button color="primary" onClick={() => this.toggle('order')} style={buttonStyle}>Order Details</Button>
                        {this.state.isOrderDetailsOpen &&

                            <div class="form-row">
                                <div class="form-group col-sm-4">
                                    <label for="orderTCD">Order TCD</label>
                                    <input type="date" name="orderTCD" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.orderTCD : ''} class="form-control" id="orderTCD" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="orderFDD">Order FDD</label>
                                    <input type="date" name="orderFDD" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.orderFDD : ''} class="form-control" id="orderFDD" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="orderCompletionDate">Order Completion Date</label>
                                    <input type="date" name="orderCompletionDate" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.orderCompletionDate : ''} class="form-control" id="orderCompletionDate" />
                                </div>
                                {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="salesOrderNumber">Sales Order Number</label>
                                        <input type="text" name="salesOrderNumber" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.salesOrderNumber : ''} class="form-control" id="salesOrderNumber" />
                                    </div>}
                                {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="siteProjectID">Site Project Id</label>
                                        <input type="text" name="siteProjectID" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.siteProjectID : ''} class="form-control" id="siteProjectID" />
                                    </div>
                                }                    
                                {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="salesProjectID">Order Project Id</label>
                                        <input type="text" name="salesProjectID" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.salesProjectID : ''} class="form-control" id="salesProjectID" />
                                    </div>
                                }

                                {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="productProjectId">Product Project Id</label>
                                        <input type="text" name="productProjectId" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.productProjectId : ''} class="form-control" id="productProjectId" />
                                    </div>
                                }

                                {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="sitePlan">Site Plan</label>
                                        <input type="text" name="sitePlan" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.sitePlan : ''} class="form-control" id="sitePlan" maxLength="100" />
                                    </div>
                                }
                                 {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="productPlan">Product Plan</label>
                                        <input type="text" name="productPlan" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.productPlan : ''} class="form-control" id="productPlan" maxLength="100" />
                                    </div>
                                }

                                {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="salesPlan">Sales Plan</label>
                                        <input type="text" name="salesPlan" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.salesPlan : ''} class="form-control" id="salesPlan" maxLength="100" />
                                    </div>
                                }

                                {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="siteSourceId">Site Source Id</label>
                                        <input type="text" name="siteSourceId" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.siteSourceId : ''} class="form-control" id="siteSourceId" maxLength="50"/>
                                    </div>
                                }
                                {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="orderSourceId">Order Source Id</label>
                                        <input type="text" name="orderSourceId" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.orderSourceId : ''} class="form-control" id="orderSourceId" maxLength="50"/>
                                    </div>
                                }

                                {this.state.isNewOrder &&
                                    <div class="form-group col-sm-4">
                                        <label for="productSourceId">Product Source Id</label>
                                        <input type="text" name="productSourceId" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.productSourceId : ''} class="form-control" id="productSourceId" maxLength="50"/>
                                    </div>
                                }
                            </div>
                        }

                        {/* Product Details */}

                        <Button color="primary" onClick={() => this.toggle('product')} style={buttonStyle}>Product Details</Button>
                        {this.state.isProductDetailsOpen &&
                            <div class="form-row">
                                <div class="form-group col-sm-4">
                                    <label for="productName">Product Name</label>
                                    <input type="text" name="productName" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.productName : ''} class="form-control" id="productName" maxLength="100" />
                                </div>
                                <div class="form-group col-sm-4">
                                    <label for="productOrderType">Product Order Type</label>
                                    <select name="productOrderType" class="form-control" onChange={(e) => this.updateOrderDetails(e, this)} value={orderDetails ? orderDetails.productOrderType : " "} id="productOrderType">
                                        <option value=" ">--Select--</option>
                                        <option value="Cease">Cease</option>
                                        <option value="Modify">Modify</option>
                                        <option value="Provide">Provide</option>
                                    </select>
                                </div>
                            </div>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" className="submitButton" type="submit" disabled={this.state.submitting}>{this.state.submitting ? <i className="fa fa-spinner fa-pulse fa-fw"></i> : this.state.isNewOrder?"Create" : "Update"}</Button>
                        <Button color="danger" className="cancelButton" disabled={this.state.submitting} onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </form >
            </Modal >
        )
    }
}
export default TConnectOrderModal;
