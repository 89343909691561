import React from "react";
import { Table } from "reactstrap";
import "./Product.css";

export default ({ mobileData }) => {
  return (
    <Table id="adbor-fnn-table">
      <thead>
        <tr className="heading-table-row">
          <th>MSISDN</th>
          <th>IMSI</th>
          <th>SIM</th>
        </tr>
      </thead>
      <tbody>
        {mobileData.map((mobile, index) => {
          const { msisdn, imsi, sim } = mobile;
          return (
            <tr key={index}>
              <td>{msisdn}</td>
              <td>{imsi}</td>
              <td>{sim}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
