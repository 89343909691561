import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {Table, Collapse} from 'reactstrap';

import _ from 'lodash';
import {SubHeader} from '../common/custom/SubHeader';

import {getColor, getDataTypeIcon, renderIcon, getClassTypeIcon} from '../../utils/ui';

export default class StatsCardPublic extends Component {
    static propTypes = {
        name: PropTypes.string,
        type: PropTypes.string,
        envCountStats: PropTypes.array
    }

    constructor() {
        super();
        this.state = {
            collapse: null
        };
    }

    collapseClick = (envWithType) => {
        if (this.isCollapsed(envWithType)) {
            this.setState({collapse: null});
            return;
        }

        this.setState({collapse: envWithType});
    }

    isCollapsed = (envWithType) => {
        return ((this.state.collapse == envWithType));
    }

    render() {
        const {name, type, envCountStats} = this.props;
        // group by env, data type
        const groupedEnvTypes = _.groupBy(envCountStats, (stat) => stat.env + "-" + stat.type);
        console.log(groupedEnvTypes);
        // split data by class types
        return (
            <div className="card white">
                <SubHeader
                    text={`${name} Data`}
                    iconClass={`td-icon-md ${getDataTypeIcon(name)}`}
                    iconColor={getColor(name)}></SubHeader>
                {<Table>
                    <thead>
                        <tr>
                            <th>Environment</th>
                            <th>Data Type</th>
                            <th>Available</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object
                            .values(groupedEnvTypes)
                            .map(groupedEnvType => {
                                console.log(groupedEnvType);
                                const totalEnvType = groupedEnvType.reduce((previous, current) => {
                                    return {
                                        ...previous,
                                        count: previous.count + current.count
                                    };
                                });
                                const {env, count, type} = totalEnvType;
                                const parentRowId = (env + type);
                                const displayClasses = (groupedEnvType.length > 1);
                                return (
                                    <React.Fragment key={parentRowId}>
                                        <tr onClick={() => this.collapseClick(parentRowId)} className={`${displayClasses?'row-hover':''}`}>
                                            <td className="base-magenta">{renderIcon("icon-home")}{env}</td>
                                            <td className="base-orange">{renderIcon(getClassTypeIcon(type))}{type}</td>
                                            <td>
                                                <b>{count}</b>{'  '}
                                            </td>
                                            {<td>
                                                {(displayClasses) && <i className={`fa fa-chevron-${this.isCollapsed(parentRowId) ? 'up':'down'}`}/>}
                                            </td>}
                                        </tr>
                                        {(displayClasses) && <tr>
                                            <td
                                                colspan="4"
                                                style={{
                                                "borderTop": "none",
                                                "padding": 0
                                            }}>
                                                <Collapse isOpen={this.isCollapsed(parentRowId)}>
                                                    <Table>
                                                        <tbody>{groupedEnvType.map(({classId, className, count}) => {
                                                                const childRowId = env + type + classId + className;
                                                                return (
                                                                    <tr key={childRowId}>
                                                                        <td className="base-orange">{renderIcon(getClassTypeIcon(type))}{env}{' '}-{' '}{type}</td>
                                                                        <td className="base-turquoise">{renderIcon(getClassTypeIcon(className))}{classId}{' '}({className})</td>
                                                                        <td>
                                                                            <b>{count}</b>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}</tbody>
                                                    </Table>
                                                </Collapse>

                                            </td>
                                        </tr>}
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </Table>}
            </div>
        )
    }
}
