import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Select from 'react-select'
import { Button as MuiButton } from '@material-ui/core';
import { Add as AddIcon, EditTwoTone as EditTwoToneIcon, Clear as ClearIcon } from "@material-ui/icons";
import Tooltip from '@material-ui/core/Tooltip';

class AllocateFilterRow extends Component {
    state = {
        addDisabled: false,
        valueDisabled: true,
        filterChanged: false,
        valueChanged: false,
        currentFilterTable: "",
        currentFilterColumn: "",
        currentFilterParameterName : "",
        currentFilterResource: "",
        filterFieldValue: null,
        valueFieldValue: null,
        clearIcon: true,
    }
    disableAdd = (bool) => {
        this.setState(prevState => ({
            ...prevState,
            addDisabled: bool,
            clearIcon: !bool,
            filterChanged: false
        }))
    }
    filterChanged = (value) => {
        this.setState(prevState => ({
            ...prevState,
            filterChanged: true,
            currentFilterTable: value.table,
            currentFilterColumn: value.value,
            currentFilterResource: value.resource,
          currentFilterParameterName : value.parameterName,
            valueDisabled: false,
            filterFieldValue: value,
            valueFieldValue: null,
        }))
    }
    valueChanged = (value) => {
        this.setState(prevState => ({
            ...prevState,
            valueChanged: true,
            valueFieldValue: value,
        }))
    }
    clearRow = () => {
        this.setState(prevState => ({
            ...prevState,
            filterFieldValue: null,
            valueFieldValue: null,
            valueDisabled: true,
            filterChanged: false,
            valueChanged: false,
        }));
    }
  render() {
    const { tempFilterArray, onChangeFilterField, onChangeFilterFieldValue, onAddNewFilter, onRemoveRow, arrayPosition } = this.props;
    return (
        <Fragment>
            <div className="row">
                <div className="col-5">
                    <Select
                        isLoading={false}
                        placeholder={"select..."}
                        onChange={(value, action) => {onChangeFilterField(value, action); this.filterChanged(value);}}
                        options={tempFilterArray.map(field =>{return{ value: field.column, label: field.filterName, table: field.tableName, resource: field.resourceName, parameterName: field.parameterName  }})}
                        isClearable={false}
                        isDisabled={this.state.addDisabled}
                        value={this.state.filterFieldValue}
                    />
                </div>
                <div className="col-5">
                    <Select
                        isLoading={this.state.filterChanged && this.props.serviceFilterDataValues.pending}
                        placeholder={"select..."}
                        onChange={(value, action) => {onChangeFilterFieldValue(value, action); this.valueChanged(value);}}
                        options={this.props.serviceFilterDataValues.success &&
                            this.props.serviceFilterDataValues.data.filterValue &&
                            this.props.serviceFilterDataValues.data.filterValue.length > 0 ?
                                this.props.serviceFilterDataValues.data.filterValue.filter(filter => filter).map(val => ({ value: val, label: val, table: this.state.currentFilterTable, fieldColumnName: this.state.currentFilterColumn, fieldResourceName: this.state.currentFilterResource, parameterName : this.state.currentFilterParameterName  })) : []}
                        isClearable={false}
                        isDisabled={this.state.addDisabled ||
                            this.state.valueDisabled ||
                            this.props.serviceFilterDataValues.pending ||
                            !this.props.serviceFilterDataValues.data.filterValue}
                        value={this.state.valueFieldValue}
                    />
                </div>
                <div className="col-2">
                    <Tooltip title={this.state.valueChanged ? "Add new filter" : "Select a filter & value to add further filters"}>
                        <span>
                            <MuiButton size="small" style={{"minWidth":"0px","padding":"4px","height":"100%","width":"50%"}}
                                hidden={this.state.addDisabled || tempFilterArray.length < 2}
                                disabled={!this.state.filterChanged || !this.state.valueChanged}
                                onClick={()=>{onAddNewFilter(tempFilterArray.filter(filter => (filter.tableName+filter.column != this.state.currentFilterTable+this.state.currentFilterColumn))); this.disableAdd(true);}}
                            >
                                <AddIcon color="primary" fontSize="medium"/>
                            </MuiButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={tempFilterArray.length === 1 || this.state.clearIcon ? "Clear filter":"Edit filter"}>
                        <span>
                            <MuiButton size="small" style={{"minWidth":"0px","padding":"4px","height":"100%","width":"50%"}}
                                onClick={()=>{this.disableAdd(false); onRemoveRow(arrayPosition); this.clearRow()}}
                            >
                                {tempFilterArray.length === 1 || this.state.clearIcon ? <ClearIcon color="secondary" fontSize="medium"/> : <EditTwoToneIcon color="secondary" fontSize="small"/>}
                            </MuiButton>
                        </span>
                    </Tooltip>
                </div>
            </div>
            {
                (this.state.filterChanged && (this.props.serviceFilterDataValues.failed || !this.props.serviceFilterDataValues.data.filterValue)) && (
                <div className="row">
                    {<small style={{"color":"red","padding":"0px 6px"}}>
                        {this.props.serviceFilterDataValues.errorMessage||this.props.serviceFilterDataValues.data.responseDescription}
                    </small>}
                </div>)
            }
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    serviceFilterData: state.teams.serviceFilterData,
    serviceFilterDataValues: state.teams.serviceFilterDataValues,
  };
};

export default connect(mapStateToProps)(AllocateFilterRow);
