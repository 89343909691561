import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { connect } from 'react-redux';
import { Grid } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { 
  ActivationSwitch,
  FormTextField,
  FormTextArea,
  FormAutoComplete,
} from "@library/core/form-elements";
import ContentBody from "@library/core/surfaces/ContentBody";
import { updateCustomerInput, validateDigitNumber} from '../../actions/orderBuilderActions';
import { findStringInObjectArray } from '@library/core/filters/filter';
import Input from '@mui/material/Input';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/material/CircularProgress';

function useDebounce(cb, delay) {
  const [debounceValue, setDebounceValue] = useState(cb);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceValue(cb);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [cb, delay]);
  return debounceValue;
}

const CustomerInputForm = (props) => {
  const {
    listOfTeams,
    auth,
    setError,
    customerInput,
    sprintOptions = [],
    sprintOptionsPending,
    envOptions = [],
    envOptionsPending,
    setFirstLoad,
    firstLoad,
    
    wishlists = [],
    wishlistsPending,
    wishlistsSuccess,
    wishlistValid,
    isWishlistActive = () => false,

    digNumbers,
    digNumbersPending,
    digNumbersFailed,
    digNumbersFailedMsg,
    resumeTour = () => {}
  } = props;


  const [wishActiveValue, setWishActiveValue] = useState(false);
  const [formattedTeamList, setFormattedTeamList] = useState([]);

  const [teamValue, setTeamValue] = useState(null);
  const [teamInputValue, setTeamInputValue] = useState("");

  const [envList, setEnvList] = useState([]);
  const [envValue, setEnvValue] = useState(null);
  const [envInputValue, setEnvInputValue] = useState("");

  const [reqByList, setReqByList] = useState([]);
  const [reqByValue, setReqByValue] = useState(null);
  const [reqByInputValue, setReqByInputValue] = useState("");

  const [irNumberValue, setIrNumberValue] = useState(null);
  const [irNumberInputValue, setIrNumberInputValue] = useState("");
  const [irNumberError, setIrNumberError] = useState(false);

  const [wishlistNameValue, setWishlistNameValue] = useState("");
  const [quantityValue , setquantity ] = useState(null);
  const [wishlistNameError, setWishlistNameError] = useState(false);
  const [wishlistNameErrorMsg, setWishlistNameMsgError] = useState(" ");

  const [additionalCommentValue, setAdditionalCommentValue] = useState((props.custom_spec_req !== "" && Object.values(props.custom_spec_req).length !== 0)? Object.values(props.custom_spec_req) : "");
  const [capabilityType]=useState([
    {
      value: 'B2C',
      label: 'B2C',
    },
    {
      value: 'B2B',
      label: 'B2B',
    },
    {
      value: 'Others',
      label: 'Others',
    },
  ]);
  const [selectedCapablity,setSelectedCapablity]=useState("");
  const [debounceDig, setDebounceDig] = useState("");
  const [digNumberStatus, setDigNumberStatus] = useState(null);

  const debounceValue = useDebounce(debounceDig, 1000);

  function startsWithDIG(digNumber) {
    const prefix = "DIG-";
    if (digNumber.startsWith(prefix)) {
        const numericPart = digNumber.slice(prefix.length);
        return /^\d+$/.test(numericPart);
    }
    return false;
}
  useEffect(() => {
    if(selectedCapablity?.value==="B2C"){
      if(debounceDig){
        if (startsWithDIG(debounceDig)) {
            props.validateDigitNumber({digitNumber:debounceDig}).then((result)=>{
              if(result){        
                let response=result.data;
                if(response.status){
                  setDigNumberStatus("Valid")
                  setDebounceDig(debounceDig);
                  setIrNumberValue(debounceDig)
                }else{
                  setDigNumberStatus("InValid")
                }
              }else{
                setDigNumberStatus("InValid")
              }
            }).catch((error)=>{
              console.log(error)
            });
        }else{
          setDigNumberStatus("InValid")
        }
      }
    }
  }, [debounceValue]);


  useEffect(()=>{
    if(selectedCapablity?.value==="Others" || selectedCapablity?.value==="B2B"){
      setIrNumberValue(selectedCapablity?.value);
    }else if(selectedCapablity?.value==="B2C"){
      if(debounceDig){
        setDebounceDig(debounceDig);
        setIrNumberValue(debounceDig);
      }else{
        setDebounceDig(null);
        setIrNumberValue(null);
      }
    }
  },[selectedCapablity]);


  const changeDigNumber=(e)=>{
    let digNumber =e.target.value;
    setDigNumberStatus("loading")
    let convertUppercase=digNumber.toUpperCase();
    setDebounceDig(convertUppercase)
  }

  useEffect(()=>{
    if(!irNumberValue){
      if(debounceDig){
        setDigNumberStatus("loading")
      }
    }
    if(debounceDig===""){
      setDigNumberStatus(null);
    }
  },[debounceDig])

  useEffect(() => {
    let irNumberprop=props.customerInput.irNumber;
    if (irNumberprop === "B2B" || irNumberprop === "Others") {
      setSelectedCapablity({label:irNumberprop,value:irNumberprop})
    } 
    else {
      setSelectedCapablity({label:"B2C",value:"B2C"});
      setIrNumberValue(irNumberprop);
      setDebounceDig(irNumberprop);
    }
  }, []);

  const handleChangeTeam = (newTeam) => {
    if (newTeam) {
      setTeamValue(newTeam);
    }
  }
  const handleTeamInputChange = (newInput) => {
    setTeamInputValue(newInput.trimStart());
  }

  const handleChangeEnv = (newEnv) => {
    if (newEnv) {
      setEnvValue(newEnv);
    }
  }
  const handleEnvInputChange = (newInput) => {
    setEnvInputValue(newInput.trimStart());
  }
  
  const handleChangeReqBy = (newReqByList) => {
    if (newReqByList) {
      setReqByValue(newReqByList);
    }
  }
  const handleReqByInputChange = (newInput) => {
    setReqByInputValue(newInput.trimStart());
  }

  const handleChangeIrNumber = (newInput) => {
      setIrNumberValue(newInput);
  }

  const handleIrNumberInputChange = (newInput) => {
    setIrNumberInputValue(newInput.trimStart());
  }
  
  const handleIrNumberError = (bool) => {
    setIrNumberError(bool);
  }

  const handleChangeWishlistActiveStatus = (checked) => {
    if (checked) {
      wishlistValid(false);
    } else {
      wishlistValid(true);
      let custInput = customerInput;
      delete custInput.wishlist;
      props.updateCustomerInput(custInput);
    }
    setWishActiveValue(checked);
    isWishlistActive(checked);
  }
  const handleChangeWishlistName = (newInput) => {
    setWishlistNameValue(newInput.trimStart());
  }
  const handleWishlistNameError = (value) => {
    if (!value) {
      setWishlistNameMsgError("Please enter name of wishlist");
      setWishlistNameError(true);
      wishlistValid(false);
    } else {
      const found = findStringInObjectArray(wishlists, "wishlistName", value);
      if (found) {
        setWishlistNameMsgError("Wishlist with this name already exists");
        setWishlistNameError(true);
        wishlistValid(false);
      } else {
        wishlistValid(true);
        setWishlistNameError(false);
        setWishlistNameMsgError(" ");
      }
    }
  }

  const handleChangeAdditionalComment = (newInput) => {
    setAdditionalCommentValue(newInput.trimStart());
  }

  const formatTeamList = (teams) => {
    let formatted = teams.map(team => {
      let program = team.teamParentProgram ? team.teamParentProgram : "";
      if (team && team.teamId && team.teamId === auth.team) {
        if (customerInput && customerInput.team) {
          handleChangeTeam(customerInput.team);
        } else {
          handleChangeTeam({label: team.teamName, value: team.teamId, program: program});
        }
      }
      return {label: team.teamName, value: team.teamId, program: program}
    });
    setFormattedTeamList(formatted);
  }

  const formatEnvList = (envs) => {
    let formatted = _.map(envs, (val, key) => {
      return {label: key, value: val};
    });
    if (customerInput && customerInput.env && (customerInput.env !== envValue)) {
      handleChangeEnv(customerInput.env);
    } else {
      if (firstLoad && formatted && formatted.length > 0) {
        handleChangeEnv(formatted[0]);
      }
    }
    if (!_.isEqual(formatted, envList)) {
      setEnvList(formatted);
    }

    setFirstLoad(false);
  }

  const getCurrentDate=()=>{
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = dd + '/' + mm + '/' + yyyy;
    return today;    
  }

  const checkDateWithinRange=(sprint_end_date)=>{
    var start = moment(getCurrentDate(), "DD/MM/YYYY");
    var end = moment(sprint_end_date, "DD/MM/YYYY");
    var diffDate=moment.duration(start.diff(end)).asDays();
    if(Math.sign(diffDate)===-1){
      return true;
    } return false
  }
  const formatReqByList = (reqs) => {
  let formate_formattedReqList;
  let filter_date;
    if (reqs && !_.isEmpty(reqs)) {
      let formattedReqList = _.map(reqs, (val, key) => {
        const [sprint_start_date,sprint_end_date]= key.split(" to ");
        return {label: key, value: val, sprint_start_date , sprint_end_date}
      });
  
      let sort_formattedReqList= formattedReqList.map((_item)=>{
        const parts=_item.sprint_start_date.split("/");
        return {..._item, sprint_start_date : new Date(parts[2],parts[1]-1,parts[0])}
      }).sort((a,b)=>a.sprint_start_date.getTime()-b.sprint_start_date.getTime());

        formate_formattedReqList=sort_formattedReqList.map((_item)=>{
        let sprint_start_date=_item.sprint_start_date;
        sprint_start_date= sprint_start_date.toLocaleDateString("en-GB");
        return {..._item,sprint_start_date}
       })
        
      if (customerInput) {
         filter_date=formate_formattedReqList.filter((_item)=> checkDateWithinRange(_item.sprint_end_date))
        handleChangeReqBy(filter_date[0]);
      }
      setReqByList(filter_date);
    }
  }

  useEffect(() => {
    resumeTour(7,1);
    if (listOfTeams && auth && auth.team) {
      formatTeamList(listOfTeams);
    }
    formatEnvList(envOptions);
    formatReqByList(sprintOptions);
    if (customerInput && customerInput.irNumber) {
      setIrNumberValue(customerInput.irNumber);
    }
    if (customerInput && customerInput.quantityValue) {
      setquantity(customerInput.quantityValue);
    }
    if (customerInput && customerInput.comment) {
      setAdditionalCommentValue(customerInput.comment);
    }
    if (customerInput && customerInput.wishlist) {
      setWishActiveValue(true);
      isWishlistActive(true);
      setWishlistNameValue(customerInput.wishlist);
      handleWishlistNameError(customerInput.wishlist);
    } else {
      setWishActiveValue(false);
      isWishlistActive(false);
    }
  }, []);
  
  useEffect(() => {
    formatReqByList(sprintOptions);
  }, [sprintOptions]);

  useEffect(() => {
    formatEnvList(envOptions);
  }, [envOptions]);

  // DISABLED WHILE WE FIND WAY OF HANDLING INDEP FOR TAAS CHANGES
  // useEffect(() => {
  //   if (!irNumberValue && teamValue && teamValue.program && teamValue.program.toLowerCase() === 'indep') {
  //     handleIrNumberError(true);
  //    setError("warning", "Teams that are in program INDEP require IR Number",false,10000);
  //   } else {
  //     handleIrNumberError(false);
  //    setError("","",false,null);
  //   }
  // }, [irNumberValue, envValue]);

  useEffect(() => {
    let team = teamValue ? teamValue : "";
    let reqBy = reqByValue ? reqByValue : "";
    let env = envValue ? envValue : "";
    let customerInputData = {
      team: team,
      reqBy: reqBy,
      irNumber: irNumberValue,
      env: env,
      comment: additionalCommentValue,
      quantityValue
    }
    if (wishActiveValue && wishlistNameValue) {
      customerInputData.wishlist = wishlistNameValue;
    }
    props.updateCustomerInput(customerInputData);
  }, [teamValue, reqByValue, irNumberValue, envValue, additionalCommentValue, wishlistNameValue,quantityValue]);

  const DigStatusComponent=()=>{
    if(digNumberStatus === "Valid"){
      return <CheckCircleOutlined color="success"/>
    }else if(digNumberStatus === "InValid"){
      return <HighlightOffIcon color="error"/>
    }else if (digNumberStatus === "loading"){
      return <CircularProgress color="inherit" style={{height:"30px",width:"30px"}} />
    }
    return null;
  }
  return (
    <ContentBody marginTop={1} marginBottom={2}>
      <Grid container direction="column">
        <Grid container direction="row" spacing={4}>
          <Grid item sm={12} md={5} id="tour-orderbuilder-teamSelect">
            <FormAutoComplete 
              label="Team"
              required={true}
              id="team"
              fullWidth
              value={teamValue}
              onChange={(e, newValue)=> handleChangeTeam(newValue)}
              inputValue={teamInputValue}
              onInputChange={(e, newInputValue) => {
                handleTeamInputChange(newInputValue);
              }}
              objectOptions={formattedTeamList}
            />
          </Grid>
          <Grid item sm={12} md={3} id="tour-orderbuilder-envSelect">
            <FormAutoComplete 
              label="Environment"
              required={true}
              id="environment"
              fullWidth
              value={envValue}
              onChange={(e, newValue)=> handleChangeEnv(newValue)}
              inputValue={envInputValue}
              onInputChange={(e, newInputValue) => {
                handleEnvInputChange(newInputValue);
              }}
              objectOptions={envList}
              loading={envOptionsPending}
            />
          </Grid>
          <Grid item sm={12} md={4} id="tour-orderbuilder-sprintSelect">
            <FormAutoComplete 
              label="Required by"
              required={true}
              id="requiredForTestingBy"
              fullWidth
              helperText="Please select the latest sprint"
              value={reqByValue}
              onChange={(e, newValue)=> handleChangeReqBy(newValue)}
              inputValue={reqByInputValue}
              onInputChange={(e, newInputValue) => {
                handleReqByInputChange(newInputValue);
              }}
              objectOptions={reqByList}
              disabled={!teamValue || !envValue}
              loading={sprintOptionsPending}
            />
          </Grid>
          <Grid item sm={12} md={6} id="tour-orderbuilder-irNumberSelect">
          <FormAutoComplete 
              label="Capability"
              required={true}
              id="Capability"
              fullWidth
              value={selectedCapablity}
              onChange={(e,newInputValue)=>{
                setSelectedCapablity(newInputValue)
              }}
              objectOptions={capabilityType}
              style={{width:"195px"}}
            />
            {selectedCapablity?.value === "B2C" ?
              <div className="validDigField" style={{marginLeft:"220px" , marginTop:"-78px"}}>
                {digNumberStatus === "InValid" ? 
                <span style={{fontSize:"12px", color : "red"}}>Dig-Number is Invalid</span> :
                digNumberStatus === "Valid" ?
                <span style={{fontSize:"12px", color : "green"}}>Dig-Number is Valid</span> : 
                <span style={{fontSize:"12px", color : "#5849b7"}}>Enter the Dig-Number   *</span>}<br/>
                <Input
                  endAdornment={<DigStatusComponent/> }
                  sx={{
                    '--Input-minHeight': '56px',
                    '--Input-radius': '6px',
                  }}
                  placeholder="Example: DIG-1234"
                  onChange={changeDigNumber}
                  value={debounceDig}
                  color={digNumberStatus === "Valid" ? "success" : digNumberStatus === "InValid" ? "error" : ""}
                />
              </div>
              : null
            }
          </Grid>
          {props.isCustomOrder ?   <Grid item sm={12} md={4} id="tour-orderbuilder-sprintSelect" style={{marginLeft: "-95px", maxWidth: "300px"}}>
            <FormTextField 
                label="Quantity" 
                required={true}
                id="quantity"
                fullWidth
                type="number"
                value={quantityValue}
                onChange={(e) => setquantity(e.target.value)}
                inputProps={{ min: 1 }}
              />
          </Grid> : null}
          <Grid item sm={7} md={4} id="tour-orderbuilder-wishlistToggle" style={props.isCustomOrder ? {marginLeft:"95px"} : {marginLeft:"0px"}}>
            <ActivationSwitch
              activeLabel="Add to Wishlist"
              inactiveLabel="Add to Wishlist"
              isActive={wishActiveValue}
              onChange={(e) => handleChangeWishlistActiveStatus(e.target.checked)}
              color="primary"
              style={{"marginLeft":"-95px",'paddingTop':"35px"}} 
            />
          </Grid>
          {
              wishActiveValue ? <Grid item sm={7} md={4} id="tour-orderbuilder-wishlistNameInput" style={props.isCustomOrder ? {paddingBottom:"0px", marginLeft:"-285px", maxWidth:"245px"} : {paddingBottom:"0px", marginLeft:"-200px", maxWidth:"245px"} }>
              <FormTextField 
                label="Wishlist Name" 
                required={wishActiveValue}
                id="wishlistName"
                fullWidth
                value={wishlistNameValue} 
                error={wishlistNameError}
                onChange={(e) => handleChangeWishlistName(e.target.value)}
                onBlurCapture={(e) => handleWishlistNameError(e.target.value)}
                helperText={wishlistNameErrorMsg}
                autoComplete="off"
                inputProps={{ maxLength: 35 }}
              />
          </Grid>: null
          }
          <Grid item sm={12} md={12} id="tour-orderbuilder-additionalComment">
            <FormTextArea 
              label="Additional Comment"
              id="additionalComment"
              fullWidth
              value={additionalCommentValue}
              onChange={(e) => handleChangeAdditionalComment(e.target.value)}
              helperText={" "}
              autoComplete="off"
              inputProps={{ maxLength: 300 }}
              rows={2}
              style={{paddingBottom:"0px"}}
            />
          </Grid>
        </Grid>
      </Grid>
    </ContentBody>
  );
};

const mapStateToProps = (state) => {
  return {
    listOfTeams: state.auth.myAvailableTeams,
    auth: state.auth,
    customerInput: state.orderBuilder.customerInput,

    sprintOptions: state.orderBuilder.sprintOptions,
    sprintOptionsPending: state.orderBuilder.sprintOptionsPending,
    
    wishlists: state.orderBuilder.wishlists,
    wishlistsPending: state.orderBuilder.wishlistsPending,
    wishlistsSuccess: state.orderBuilder.wishlistsSuccess,

    digNumbers: state.orderBuilder.irNumbers,
    digNumbersPending: state.orderBuilder.irNumbersPending,
    digNumbersFailed: state.orderBuilder.irNumbersFailed,
    digNumbersFailedMsg: state.orderBuilder.irNumbersFailedMsg,
    isCustomOrder: state.orderBuilder.isCustomOrder,
    envOptions: state.orderBuilder.envOptions,
    envOptionsPending: state.orderBuilder.envOptionsPending,
    custom_spec_req : state.orderBuilder.custom_spec_req
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateCustomerInput:(input) => dispatch(updateCustomerInput(input)),
    validateDigitNumber:(input)=> dispatch(validateDigitNumber(input))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInputForm);
