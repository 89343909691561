import React from "react";
import PropTypes from "prop-types";

import { IconButton, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from "@material-ui/lab";
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import CloseIcon from '@material-ui/icons/Close';
import clsx from "clsx";

const defaultAutoCloseDuration = 5000;
const defaultSeverity = "success";
const defaultPosition = {
  horizontal: "right",
  vertical: "top",
};

const useStyles = makeStyles((theme) => ({
  load: {
    "&.spin": {
      animation: "$spin 1.5s linear infinite",
      pointerEvents:'none'
    }
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)"
    },
    "100%": {
      transform: "rotate(-360deg)"
    }
  },
  clickable: {
    cursor: "pointer"
  }
}));

const SnackbarAlert = (props) => {
  const classes = useStyles();
  const {
    isOpen = false,
    loading = false,
    severity = defaultSeverity,
    message,
    autoCloseDuration = defaultAutoCloseDuration,
    position = defaultPosition,
    handleClose,
    actions,
    icon = null,
    handleClick,
    style
  } = props;

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={severity === "error" ? null : autoCloseDuration}
      anchorOrigin={position}
      onClose={handleClose}
      classes={{ root: handleClick ? classes.clickable : null}}
      onClick={handleClick}
      style={style}
    >
      <Alert 
        variant="filled" 
        severity={severity}
        icon={loading ? <LoopOutlinedIcon className={clsx({[classes.load]: true, spin: loading})}/> : icon}
        action={
          <>
            {actions}
            {
              handleClose ? 
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleClose();
                }}
              >
                  <CloseIcon fontSize="inherit" />
              </IconButton> : <></>
            }
          </>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

SnackbarAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  severity: PropTypes.string,
  message: PropTypes.string,
  autoCloseDuration: PropTypes.number,
  position: PropTypes.shape({
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
  }),
  handleClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  actions: PropTypes.any,
};

export default SnackbarAlert;
