
export default (data = [], pageNum = 0) => {
  const currentPageSize = 6;
  const totalItems = data.length;
  const totalPages = Math.ceil(data.length / currentPageSize);
  const currentPage = pageNum + 1;
  const isFirst = pageNum === 0,
    isLast = currentPage * currentPageSize >= totalItems;
  const content = data.slice(
    pageNum * currentPageSize,
    pageNum * currentPageSize + currentPageSize
  );

  const paginationDetails = {
    isFirst,
    isLast,
    currentPage,
    totalPages,
    totalItems,
    content,
    currentPageSize
  };
  return paginationDetails;
};
