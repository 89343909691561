import React from "react";
import moment from "moment";

export default ({
  billingAccountNumber,
  startDate,
  endDate,
  companyName,
  billCycleDay,
  paymentTerms,
  rouCode
}) => {

  return (
    <React.Fragment>
      <div
        className="card"
        style={{
          boxShadow: "-1px 0px 20px -2px rgba(0,0,0,0.15)",
          height: "auto",
          minHeight: "15rem"
        }}
      >
        <div className="card-body">
          <div className="inner-block">
            <span style={{
              display: "block",
              marginBottom: "20px"
            }}>
              <strong className="card-heading">Billing Account</strong>{" "}
              <span className="float-right base-turquoise ">
                <i className="td-icon-sm icon-profitable theme-text-primary" />
              </span>{" "}
            </span>
            <div className="date-block">
              <div className="">
                <p style={{ marginBottom: "auto" }}>
                  {" "}
                  <strong style={{ marginRight: "5px" }}>Account: </strong>
                  {billingAccountNumber}
                </p>
                <hr
                  style={{
                    backgroundColor: "black",
                    margin: "auto"
                  }}
                />
                <p style={{ marginTop: "auto" }}>
                  <strong style={{ marginRight: "5px" }}>Start Date:</strong>
                  {moment(startDate).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
            <div className="box-inside-card-row">
              <div className="c6 company-details">

                <div className=" base-blue">
                  <div className="icon-block">
                    <i className="fa fa-building-o fa-3x theme-text-primary" />
                  </div>
                  <div className="icon-block">
                    <div ><strong>Company Name:</strong></div>

                    <div >
                      {companyName}
                    </div>
                  </div>
                </div>



                <div className="base-blue">
                  <div className="icon-block">
                    <i
                      class="fa fa-map-pin fa-3x theme-text-primary"
                      aria-hidden="true"
                    />
                  </div>


                  <div className="icon-block">
                    <div>
                      <strong >
                        Rou Code:
                      </strong>{" "}
                    </div>
                    <div >{rouCode}</div>
                  </div>
                </div>
              </div>


              <div className="c6 small-box">
                <div
                  class="card"
                  style={{
                    height: "auto",
                    backgroundColor: "ghostwhite"
                  }}
                >
                  <div class="card-body">
                    <p>
                      <strong style={{ paddingRight: "5px" }}>
                        Payment Terms:
                        </strong>
                      {paymentTerms}
                    </p>
                    <p>
                      {" "}
                      <strong style={{ paddingRight: "5px" }}>
                        Bill Cycle Day:
                        </strong>
                      {billCycleDay}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*</div>*/}
    </React.Fragment>
  );
};
