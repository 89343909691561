import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  recipientLabel: {
    marginRight: '10px',
    marginLeft: '10px',
    fontSize: '15px',
    color: 'black'
  },
  maxChars: {
    marginRight: '10px',
    marginLeft: '10px',
    fontSize: '12px',
    color: 'grey'
  },
  allUsersLabel: {
    marginRight: '10px',
    marginLeft: '10px',
    fontSize: '15px',
    color: 'red'
  }
});

const TrainingDetailsDialog = (props) => {
  const classes = useStyles();
  console.log("props")
  console.log(props)

  return (


    <Fragment>
      <Modal isOpen={true} id="trainingDetailsModal" size="md" toggle={props.closeDialog}>
        <ModalHeader>TDM training programs - Level of Expertise</ModalHeader>
        <ModalBody>
          <Typography className="cardName mt-n2">
            {props.cardDetails.name}
          </Typography>
          <Typography className = "cardSkillsExpecationsHeader">
            Skill sets required to gain this badge are : 
          </Typography>
          {props.cardDetails.skills.map((skill,index)=> {
            return(

              <li className = "skillsData">
                {skill}</li>
            )
          })}
          <Typography className= "cardSkillsExpecationsHeader">
            Expectations of a {props.cardDetails.name} are : 
          </Typography>
          {props.cardDetails.expectations.map((expectation,index)=> {
            return(

              <li className = "skillsData">
                {expectation}</li>
            )
          })}
          
          </ModalBody>
          </Modal>
    </Fragment>
  )
}




export default connect(null, null)(TrainingDetailsDialog);