import React from "react";
import PropTypes from "prop-types";
import { TableRow } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

import { makeStyles } from "@material-ui/core/styles";

const useTableBodyRowStyle = makeStyles((theme) => ({
  hover: {
    "&$hover:hover": {
      // backgroundColor: theme.palette.tableRow.hover.color,
      backgroundColor: blue[30],
    },
  },
}));

const TableBodyRow = (props) => {
  const { children, ...rest } = props;

  const tableRowStyle = useTableBodyRowStyle();

  return (
    <TableRow hover classes={tableRowStyle} {...rest}>
      {children}
    </TableRow>
  );
};

TableBodyRow.propTypes = {
  children: PropTypes.any.isRequired,
};

export default TableBodyRow;
