import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";

const useStyles = makeStyles({
  stateLabel: {
    backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
    color: "#ffffff  !important",
    marginBottom: "20px !important",
    marginLeft: "20px !important"
  },
  stateSelectedLabel: {
    marginBottom: "20px !important",
    backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
    color: "#ffffff !important",
    marginLeft: "20px !important"
  },
  productHeaderLabel: {
    fontSize: "24px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    paddingLeft: "20px !important"
  },
  yesSpecification: {
    width: "650px !important",
    marginLeft: "23px !important",
    marginTop: "20px !important"
  },
  spacedDiv : {
    marginTop: "10px !important",
    marginLeft: "3px !important"
}

});


const SiebelService = (props) => {

  const classes = useStyles();

  const [siebelTypes, setSiebelTypes] = useState(["ESTABLISHED", "UN-ESTABLISHED"]);
  const [selectedSiebel, setSelectedSiebel] = useState("");

  const [siebelServices, setSiebelServices] = useState(["SOLE-TRADER", "RESIDENTIAL", "COMPANY", "ORGANIZATION", "OTHERS"]);
  const [selectedSiebelService, setSelectedSiebelService] = useState("");
  const [bilingAccounts, setBillingAccounts] = useState(["POSTPAID"]);
  const [selectedBillingAccount, setSelectedBillingAccount] = useState("");
  const [siebelData, setSiebelData] = useState("");
  const [contractType, setContractType] = useState(["PLAN ONLY", "PLAN WITH DEVICE"]);
  const [selectedContractType, setSelectedContractType] = useState("");
  const [contractProducts, setContractProducts] = useState(["MOBILE", "NBN", "PSTN-ADSL", "FOXTEL", "CABLE", "OTHERS"])
  const [noContractProducts, setNoContractProducts] = useState(["MOBILE","NBN", "PSTN-ADSL", "PSTN-CABLE", "STANDALONE-CABLE", "FOXTEL", "OTHERS"])
  const [selectedProduct, setSelectedProduct] = useState("");
  
  const [labelText, setLableText] = useState("");
  const [textHelper,setTextHelper] = useState("");


  const setSpecData = (key, value) => {
    setLableText(key)
    props.setSpecificRequirement({ [key]: value }, "")
  }

  const setTextHelperFunc = (data) => {
   if(data === "WITH-CONTRACT")
    {setTextHelper("Order specifications for Mobile with contract")}
    else
    {setTextHelper("Order specifications for Non-Mobile")}
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Siebel Service</Typography>
          {siebelTypes.map((siebel, index) => {
            return (
              <Button variant="contained" classes={siebel !== selectedSiebel ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                sx={{ minWidth: 150, minHeight: 150 }} value={siebel}
                onClick={e => { setSelectedSiebel(siebel);
                  setSelectedSiebelService();
                  setSelectedBillingAccount(); 
                  setSelectedContractType();
                  setSelectedProduct();}}>
                {siebel}
              </Button>
            )
          })}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={1} />
        {selectedSiebel !== "" ?
          <Grid item xs={10}>
            <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Customer Type for :{selectedSiebel}</Typography>
            {siebelServices.map((cable, index) => {
              return (
                <Button variant="contained" classes={cable !== selectedSiebelService ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                  sx={{ minWidth: 150, minHeight: 150 }} value={cable}
                  onClick={e => { setSelectedSiebelService(e.target.value); }}>
                  {cable}
                </Button>
              )
            })}
          </Grid> : <></>}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={1} />
        {selectedSiebelService !== "" ?
          <Grid item xs={10}>
            <Typography classes={{ root: classes.productHeaderLabel }}>Please select the {selectedSiebelService} Billing Account</Typography>
            {bilingAccounts.map((cable, index) => {
              return (
                <Button variant="contained" classes={cable !== selectedBillingAccount ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                  sx={{ minWidth: 150, minHeight: 150 }} value={cable}
                  onClick={e => {
                    setSelectedBillingAccount(e.target.value);
                    let key = "CUSTOM:ACTIVE SERVICE SIEBEL " + selectedSiebel + " " + selectedSiebelService + " " + e.target.value + " DATA";
                    setSpecData(key, "");
                  }
                  }>
                  {cable}
                </Button>
              )
            })}
          </Grid> : <></>}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={1} />
        {selectedBillingAccount === "PREPAID" ?
          <Grid item xs={10}>
            <TextField
              id="SiebelData"
              multiline
              rows={4}
              helperText="Specify Prepaid Mobile Order Details"
              label={labelText}
              classes={{ root: classes.yesSpecification }}
              value={siebelData}
              onChange={e => {
                setSiebelData(e.target.value);
                let key = "CUSTOM:ACTIVE SERVICE SIEBEL " + selectedSiebel + " " + selectedSiebelService + " " + selectedBillingAccount + " DATA";
                setSpecData(key, e.target.value);
              }}
            />

          </Grid> :
          <>
            {selectedBillingAccount === "POSTPAID" ?
              <Grid item xs={10}>
                <Typography classes={{ root: classes.productHeaderLabel }}>Please select the {selectedBillingAccount} Contract type</Typography>
                {contractType.map((cont, index) => {
                  return (
                    <Button variant="contained" classes={cont !== selectedContractType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                      sx={{ minWidth: 150, minHeight: 150 }} value={cont}
                      onClick={e => { setSelectedContractType(e.target.value);
                        setTextHelperFunc(e.target.value);
                        setSelectedProduct(); }}>
                      {cont}
                    </Button>
                  )
                })}

              </Grid> :
              <>

              </>}

          </>}
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={1} />
        {selectedBillingAccount === "POSTPAID" && (selectedContractType === "PLAN ONLY" || selectedContractType === "PLAN WITH DEVICE") ?
          <Grid item xs={10}>
            <Autocomplete
              disablePortal
              id="with-contract-products"
              options={contractProducts}
              sx={{ width: 300 }}
              getOptionLabel={(option) => option}
              placeholder="Select the Product"
              value={selectedProduct}
              onChange={(event, value) => {
                setSelectedProduct(value);
                let key = "CUSTOM:ACTIVE SERVICE SIEBEL " + selectedSiebel + " " + selectedSiebelService + " " + selectedBillingAccount + " " + value + " DATA";
                setSpecData(key, "");
              }}
              renderInput={(params) => <TextField {...params} label="Products"
                classes={{ root: classes.yesSpecification }} />}
            />
          </Grid> : <>
            {selectedBillingAccount === "POSTPAID" && selectedContractType === "WITHOUT-CONTRACT" ?
              <Grid item xs={10}>
                <Autocomplete
                  disablePortal
                  id="with-contract-products"
                  options={noContractProducts}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => option}
                  placeholder="Select the Product"
                  value={selectedProduct}
                  onChange={(event, value) => {
                    setSelectedProduct(value);
                    let key = "CUSTOM:ACTIVE SERVICE SIEBEL " + selectedSiebel + " " + selectedSiebelService + " " + selectedBillingAccount + " " + value + " DATA";
                    setSpecData(key, "");
                  }}
                  renderInput={(params) => <TextField {...params} label="Products"
                    classes={{ root: classes.yesSpecification }} />}
                />
              </Grid> : <>
              </>
            }
          </>
        }
      </Grid>
      <Grid container spacing={3} classes={{root: classes.spacedDiv}}>
        <Grid item xs={1} />
        {selectedBillingAccount === "POSTPAID" && selectedContractType !== "" &&
          selectedProduct !== undefined && selectedProduct !== "" && selectedProduct !== null ?
          <Grid items xs={10}>
            <TextField
              id="SiebelData"
              multiline
              rows={4}
              helperText="Enter the specific requirement"
              label={labelText}
              classes={{ root: classes.yesSpecification }}
              value={siebelData}
              onChange={e => {
                setSiebelData(e.target.value);
                let key = "CUSTOM:ACTIVE SERVICE SIEBEL " + selectedSiebel + " "
                  + selectedSiebelService + " " + selectedBillingAccount + " " +selectedContractType+" "+ selectedProduct + " DATA";
                setSpecData(key, e.target.value);
              }}
            />

          </Grid> : <></>}
      </Grid>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
    prodData: (value) => dispatch(prodData(value)),
    setSpecificRequirement: (value, sID) => dispatch(setSpecificRequirement(value, sID))
  }
}

export default connect(null, mapDispatchToProps)(SiebelService);