import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Switch from 'react-switch'
import swal from 'sweetalert2'
import { SubHeader } from '../../common/custom/SubHeader';
import styles from './TConnectOnboardModal.css';

class TConnectOnboardModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                id: "placeholderId",
                type: "placeholderType",
                firstName: "placeholderFirstName",
                lastName: "placeholderLastName",
                email: "placeholderEmail",
            },
            consenting:false,
            legacyBilling:false,
            strategic:false,
            cidn: this.props.cidn,
            isOpen: false,
            submitting: false,
        }
    }
    handleChange=(e,fieldName)=>{
        if(fieldName){
            if(fieldName === 'consenting'){
                this.setState({consenting:!this.state.consenting})
            }else if(fieldName ==='legacyBilling'){
                this.setState({legacyBilling:!this.state.legacyBilling})
            }else if(fieldName ==='strategic'){
                this.setState({strategic:!this.state.strategic})
            }
        }
    }
    handleSubmitClick = (e, contact) => {
        e.preventDefault();
        this.setState({ submitting: true })
        this.props.handleOnboard(contact)
            .then(response => {
                console.log("onboard success")
                swal.fire({
                    type: 'success',
                    title: 'TConnect - Onboarding',
                    text: 'User onboarded successfully',
                }).then(() => {
                    this.setState({ isOpen: false })
                })

            })
            .catch(error => {
                swal.fire({
                    type: 'error',
                    title: 'TConnect - Onboarding',
                    text: 'There was an error onboarding this user to T Connect',
                    onOpen: () => {
                        swal.showValidationMessage(error.response.data.message || "Unknown error has occured")
                    }
                }).then(() => {
                    this.setState({ isOpen: false })
                })
            })
            .finally(() => {
                this.setState({ submitting: false })
            })
    }

    open = data => {
        this.setState({ data: data })
        this.toggleModal();
    }

    toggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    render() {
        const { cidn, data } = this.state

        return (
            <Modal isOpen={this.state.isOpen} toggle={this.toggleModal} centered className={styles.TConnectOnboardModal}>
                <form onSubmit={(e) => this.handleSubmitClick(e, { cidn: cidn || this.props.cidn,consenting:this.state.consenting,legacyBilling:this.state.legacyBilling,strategic:this.state.strategic, ...data })}>
                    <ModalHeader className="TConModal--header">
                        <SubHeader
                            text="TConnect - Onboard"
                            iconLeft={true}
                            small={true}
                            iconClass="td-icon-sm icon-telstra"
                            iconColor="blue" />
                    </ModalHeader>

                    <ModalBody className="TConModal--data-grid">
                        <div className="TConModal--data-label">Company ID:</div>
                        <div className="TConModal--data-val">{cidn || this.props.cidn}</div>

                        <div className="TConModal--data-label">Contact ID:</div>
                        <div className="TConModal--data-val">{data.id}</div>

                        <div className="TConModal--data-label">Type:</div>
                        <div className="TConModal--data-val">{data.type}</div>

                        <div className="TConModal--data-label">FirstName:</div>
                        <div className="TConModal--data-val">{data.firstName}</div>

                        <div className="TConModal--data-label">LastName:</div>
                        <div className="TConModal--data-val">{data.lastName}</div>

                        <div className="TConModal--data-label">Email:</div>
                        <div className="TConModal--data-val">{data.email}</div>
                        
                        <div className="TConModal--data-label">Consenting:
                           <Switch className="react-switch TConModal--switch" onChange={(e) =>this.handleChange(e,'consenting')} checked={this.state.consenting}></Switch>
                        </div>
                        
                        <div className="TConModal--data-label">Legacy Billing:
                            <Switch  className="react-switch TConModal--switch" onChange={(e)=>this.handleChange(e,'legacyBilling')} checked={this.state.legacyBilling}></Switch>
                        </div>
                        <div className="TConModal--data-label">Strategic:
                            <Switch  className="react-switch TConModal--switch" onChange={(e)=>this.handleChange(e,'strategic')} checked={this.state.strategic} ></Switch>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" className="submitButton" type="submit" disabled={this.state.submitting}>{this.state.submitting ? <i className="fa fa-spinner fa-pulse fa-fw"></i> : "Onboard"}</Button>
                        <Button color="danger" className="cancelButton" disabled={this.state.submitting} onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </form >
            </Modal >
        )
    }
}

export default TConnectOnboardModal