import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    filters: {
        dataTypes: [
            {
                label: 'Order',
                icon: "icon-shop-online"
            },
            {
                label: 'Customer',
                icon: "icon-my-profile",
                disabled: true
            },
        ],
        customerTypes: [
            {
                label: 'Company',
                icon: "icon-industries",
                disabled: true
            },
            {
                label: 'Organisation',
                icon: "icon-business-building",
                disabled: true
            },
            {
                label: 'Residential',
                icon: "icon-home",
            },
            {
                label: 'Sole Trader',
                icon: "icon-profile-suit",
            },
        ],
        orderTypes: [
            {
                label: 'Postpaid-siebel',
                icon: "icon-mobile-data-pack"
            },

        ],
        orderActionTypes: [
            /* Currently we are hide some of the tiles */
            // {
            //     label: 'Create Order',
            //     icon: "icon-shop-online"
            // },
            // {
            //     label: 'Complete Order',
            //     icon: "icon-tick",
            // },
            // {
            //     label: 'Replicator',
            //     icon: "icon-drag-and-drop-form",
            // },
            // {
            //     label: 'TConnect',
            //     icon: "td-icon-md icon-telstra icon",
            // },
            {
                label: "Bill Cycle Update",
                icon: "icon-drag-and-drop-form"
            },
            {
                label: 'Customer Console',
                icon: "icon-my-profile-tick-presence",
            },
            {
                label: "DDC Alignment",
                icon: "icon-road-map"
            },
            {
                label: 'Established Customer Stub',
                icon: "icon-home",
            },
            {
                label: "NUMBERS-MGR",
                icon: "icon-aggregating-info",
            },
            {
                label: "Physical SIM",
                icon: "icon-sim-card",
            },
            {
                label: "Address Lookup",
                icon: "icon-profitable",
            },
            {
                label: 'Nuron Data Setup',
                icon: "icon-optional-inclusions"
            },
            {
                label: "B2C SM Eligibility",
                icon: 'icon-optional-inclusions'
            },
            {
                label: "B2C TIM Migration",
                icon: "icon-service-management"
            },
            {
                label: "B2B Data Clean Up",
                icon: "icon-optional-inclusions"
            },
            {
                label: "B2C Salesforce Search",
                icon: "icon-search-find"
            },
            {
                label: "OCS Usage Load",
                icon: "icon-profitable"
            },
            {
                label: "RCR Process",
                icon: "icon-road-map"
            },
            // {
            //     label: 'Customer Credit check update',
            //     icon: "icon-home",
            // },
            // {
            //     label: "U2C",
            //     icon: "icon-profitable",
            // },
            // {
            //     label: "S2P",
            //     icon: "icon-tick",
            // },

            {
                label: "SOM-Resource-Stub",
                icon: "icon-optional-inclusions"
            },
            // {
            //     label: "Checkout History",
            //     icon: 'icon-optional-inclusions'
            // }
        ],
        timNewMigrationScenarios: [
            {
                label: ""
            },
            {
                label: 'New service without discount'
            },
            {
                label: 'New service with discount',
            }
        ],
        timExistingMigrationScenarios: [
            {
                label: ""
            },
            {
                label: 'Existing service without discount'
            },
            {
                label: 'Existing service with discount'
            },
        ],
        timMigrationGroupType: [
            {
                label: 'New',
                icon: "icon-service-management"
            },
            {
                label: 'Existing',
                icon: "icon-service-management"
            }
        ],
        timMigration: [
            {
                label: 'Create TIM migration service',
                icon: "icon-hosted-cloud-services"
            },
            {
                label: 'Fetch existing record',
                icon: "icon-hosted-cloud-services"
            },
        ],
        productTypes: [{
            label: 'CABLE',
            icon: "icon-modem",
            disabled: true
        },
        {
            label: 'MOBILE',
            icon: "icon-mobile"
        },
            // {
            //     label: 'NBN',
            //     icon: "icon-network-coverage",
            // }
        ],
        NBNTypes: [{
            label: 'FTTP Class 3',
            icon: "icon-network-coverage",
        },
        {
            label: 'HFC Class 22',
            icon: "icon-network-coverage"
        },
        {
            label: 'Wireless Class 6',
            icon: "icon-network-coverage",
        }
        ],
        orderSubtypes: [
            {
                label: 'BYO Plan $29',
                icon: "icon-sim-card"
            },
            {
                label: 'Large Plan',
                icon: "icon-sim-card",
            },
            {
                label: 'BYO Plan $49',
                icon: "icon-sim-card",
                disabled: true
            }
        ],
        s2pTypes: [
            {
                label: 'Salesforce Commision',
                icon: "icon-industries",
            },
            {
                label: 'S2P Salesforce',
                icon: "icon-industries",
            },
            {
                label: 'Arya',
                icon: "icon-industries", // change icon
            }
        ],
        physicalSimTypes: [
            {
                label: 'Physical SIM Preceeding',
                icon: "icon-sim-card"
            },
            {
                label: 'Request Status',
                icon: "icon-search-find"
            }
        ],
        rcrProcessTypes: [
            {
                label: 'Create Request ID',
                icon: "icon-sim-card"
            },
            {
                label: 'Request Status',
                icon: "icon-search-find"
            },
            {
                label: 'ChargeCycleUpdate',
                icon: "icon-search-find"
            }
        ],
        somResourceStubTypes: [
            {
                label: 'MSISDN',
                icon: "icon-sim-card"
            },
            {
                label: 'FNN',
                icon: "icon-search-find"
            }
        ]
    },
    view: {
        items: [],
        fnnItems: [],

    },
    activeStep: 0,
    selectedFilters: {},
    estCustumorLoading: false,
    isAddingCustomer: false,
    isDeletingCustomer: false,
    customerCreditUpdate: {
        isCustomerCreditPending: false,
        isCustomerCreditError: false,
        isCustomerCreditSuccess: false,
        message: null,
        response: {}
    },
    createRcrRequestId: {
        isCreateRcrRequestIdPending: false,
        isCreateRcrRequestIdError: false,
        isCreateRcrRequestIdSuccess: false,
        message: null,
        response: {}
    },
    checkRcrStatus: {
        isCheckRcrStatusPending: false,
        isCheckRcrStatusFailed: false,
        isCheckRcrStatusSuccess: false,
        message: null,
        response: {}
    },
    rcrRequestStatusData: [],
    identityProfileUpdate: {
        isIdentityProfileUpdatePending: false,
        isIdentityProfileUpdateError: false,
        isIdentityProfileUpdateSuccess: false,
        message: null,
        response: {}
    },
    siebelCustomer: {
        isSearchPending: false,
        isSearchError: false,
        isSearchSuccess: false,
        message: null,
        customer: {},
        customerContact: [],
        selectedRow: {},
        displayUserModal: false,
        isUserUpdatePending: false,
        isUserUpdateFailed: false,
        isUserUpdatesuccess: false,
        isCaimanRegistrationPending: false,
        isCaimanRegistrationFailed: false,
        isCaimanRegistrationSuccess: false,
        caimanUserName: "",
        caimanPassword: "",
        caimanModal: false,
        errorResponse: "",
        caimanErrorResponse: "",
    },
    customerSync: {
        isCustomerSyncSuccess: false,
        isCustomerSyncPending: false,
        isCustomerSyncFailed: false,
        responses: null,
        isCustomerSyncStatusSuccess: false,
        isCustomerSyncStatusPending: false,
        isCustomerSyncStatusFailed: false,
    },
    siebelContacts: {
        isUpdateContactsPending: false,
        isUpdateContactsFailed: false,
        isUpdateContactsSuccess: false,
        response: null,
    },
    createCustomer: {
        isCustomerCreationPending: false,
        isCustomerCreationFailed: false,
        isCustomerCreationSuccess: false,
        response: null,
    },
    s2pSalesForceCommision: {
        isRetrieveTablesPending: false,
        isRetrieveTablesFailed: false,
        isRetrieveTablesSuccess: false,
        responseList: [],
        response: null,
        isSubmitTablesPending: false,
        isSubmitTablesFailed: false,
        isSubmitTablesSuccess: false,
        submitResponse: null,
    },
    s2pArya: {
        isRetrieveFilesPending: false,
        isRetrieveFilesFailed: false,
        isRetrieveFilesSuccess: false,
        response: null,
        isSubmitFilesPending: false,
        isSubmitFilesFailed: false,
        isSubmitFilesSuccess: false,
        isSubmitFilesFinished: false,
        submitResponse: null,
    },
    physicalSimPreceeding: {
        isSubmitMsisdnPending: false,
        isSubmitMsisdnFailed: false,
        isSubmitMsisdnSuccess: false,
        msisdnResponse: null,
        isSubmitSimPending: false,
        isSubmitSimFailed: false,
        isSubmitSimSuccess: false,
        simResponse: null,
        msisdnList: [],
        simList: [],

        fileContents: {
            contentsLoading: false,
            contentsError: {}
        },
    },
    batchIdStatus: {
        isCheckStatusPending: false,
        isCheckStatusFailed: false,
        isCheckStatusSuccess: false,
        statusResponse: null,
    },

    address: {
        isRetrievePending: false,
        isRetrieveFailed: false,
        isRetrieveSuccess: false,
        response: null
    }
}


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SELECT_UTILITY_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [action.key]: action.value
                }
            }
        case types.UTILITY_GET_ESTABLISHED_CUSTOMERS_SUCCESS:
            return {
                ...state,
                estCustumorLoading: false
            }

        case types.UTILITY_GET_ESTABLISHED_CUSTOMERS_PENDING:
            return {
                ...state,
                estCustumorLoading: true
            }
        case types.UTILITY_ADD_ESTABLISHED_CUSTOMERS_PENDING:
            return {
                ...state,
                isAddingCustomer: true
            }

        case types.UTILITY_ADD_ESTABLISHED_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isAddingCustomer: false
            }
        case types.UTILITY_DELETE_ESTABLISHED_CUSTOMERS_PENDING:
            return {
                ...state,
                isDeletingCustomer: true
            }

        case types.UTILITY_DELETE_ESTABLISHED_CUSTOMERS_SUCCESS:
            return {
                ...state,
                isDeletingCustomer: false
            }
        case types.UTILITY_CREDIT_PENDING:
            return {
                ...state,
                customerCreditUpdate: {
                    isCustomerCreditPending: true,
                    isCustomerCreditError: false,
                    isCustomerCreditSuccess: false
                }

            }
        case types.UTILITY_CREDIT_FAILED:
            return {
                ...state,
                customerCreditUpdate: {
                    isCustomerCreditPending: false,
                    isCustomerCreditError: true,
                    isCustomerCreditSuccess: false,
                    message: action.payload,
                    response: action.payload
                }
            }
        case types.UTILITY_CREDIT_SUCCESS:
            return {
                ...state,
                customerCreditUpdate: {
                    isCustomerCreditPending: false,
                    isCustomerCreditError: false,
                    isCustomerCreditSuccess: true,
                    message: action.payload,
                    response: action.payload
                }
            }
        case types.CREATE_RCR_REQUEST_ID_PENDING:
            return {
                ...state,
                createRcrRequestId: {
                    isCreateRcrRequestIdPending: true,
                    isCreateRcrRequestIdError: false,
                    isCreateRcrRequestIdSuccess: false,
                }
            }
        case types.CREATE_RCR_REQUEST_ID_FAILED:
            return {
                ...state,
                createRcrRequestId: {
                    isCreateRcrRequestIdPending: false,
                    isCreateRcrRequestIdError: true,
                    isCreateRcrRequestIdSuccess: false,
                    message: action.payload,
                    response: action.payload
                }
            }
        case types.CREATE_RCR_REQUEST_ID_SUCCESS:
            return {
                ...state,
                createRcrRequestId: {
                    isCreateRcrRequestIdPending: false,
                    isCreateRcrRequestIdError: false,
                    isCreateRcrRequestIdSuccess: true,
                    message: action.payload,
                    response: action.payload
                }
            }

        case types.CHECK_RCR_STATUS_PENDING:
            return {
                ...state,
                rcrRequestStatusData: [],
                checkRcrStatus: {
                    isCheckRcrStatusPending: true,
                    isCheckRcrStatusFailed: false,
                    isCheckRcrStatusSuccess: false,
                }
            }
        case types.CHECK_RCR_STATUS_FAILURE:
            return {
                ...state,
                rcrRequestStatusData: [],
                checkRcrStatus: {
                    isCheckRcrStatusPending: false,
                    isCheckRcrStatusFailed: true,
                    isCheckRcrStatusSuccess: false,
                }
            }
        case types.CHECK_RCR_STATUS_SUCCESS:
            return {
                ...state,
                rcrRequestStatusData: action.payload,
                checkRcrStatus: {
                    isCheckRcrStatusPending: false,
                    isCheckRcrStatusFailed: false,
                    isCheckRcrStatusSuccess: true,
                }
            }
        case types.UTILITY_IDENTITY_PROFILE_UPDATE_PENDING:
            return {
                ...state,
                identityProfileUpdate: {
                    isIdentityProfileUpdatePending: true,
                    isIdentityProfileUpdateError: false,
                    isIdentityProfileUpdateSuccess: false,
                    message: "",
                    response: ""
                }
            }
        case types.UTILITY_IDENTITY_PROFILE_UPDATE_FAILED:
            return {
                ...state,
                identityProfileUpdate: {
                    isIdentityProfileUpdatePending: false,
                    isIdentityProfileUpdateError: true,
                    isIdentityProfileUpdateSuccess: false,
                    message: action.payload,
                    response: action.payload
                }
            }
        case types.UTILITY_IDENTITY_PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                identityProfileUpdate: {
                    isIdentityProfileUpdatePending: false,
                    isIdentityProfileUpdateError: false,
                    isIdentityProfileUpdateSuccess: true,
                    message: action.payload,
                    response: action.payload
                }
            }
        case types.UTILITY_SIEBEL_CUSTOMER_CONSOLE_PENDING:
            return {
                ...state,
                siebelCustomer: {
                    isSearchPending: true,
                    isSearchError: false,
                    isSearchSuccess: false,
                    message: null,
                    customer: {},
                    customerContact: [],
                    selectedRow: {}
                }
            }
        case types.UTILITY_SIEBEL_CUSTOMER_CONSOLE_FAILED:
            return {
                ...state,
                siebelCustomer: {
                    isSearchPending: false,
                    isSearchError: true,
                    isSearchSuccess: false,
                    message: action.payload,
                    customer: {},
                    customerContact: [],
                    selectedRow: {}
                }
            }
        case types.UTILITY_SIEBEL_CUSTOMER_CONSOLE_SUCCESS:
            return {
                ...state,
                siebelCustomer: {
                    isSearchPending: false,
                    isSearchError: false,
                    isSearchSuccess: true,
                    message: action.payload.responseDescription,
                    customer: action.payload.customer,
                    customerContact: action.payload.contacts,
                    selectedRow: {}
                }
            }
        case types.UTILITY_SIEBEL_CUSTOMER_CONSOLE_CLEAR:
            return {
                ...state,
                siebelCustomer: {
                    isSearchPending: false,
                    isSearchError: false,
                    isSearchSuccess: false,
                    message: null,
                    customer: {},
                    customerContact: [],
                    selectedRow: {}
                }
            }
        case types.CUSTOMER_CONSOLE_SELECT_ROW:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    selectedRow: action.payload
                }
            }
        case types.DISPLAY_USER_MODAL:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    displayUserModal: true

                }
            }
        case types.UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_PENDING:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    isUserUpdatePending: true,
                    isUserUpdateFailed: false,
                    isUserUpdatesuccess: false,
                    displayUserModal: false
                }
            }
        case types.UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_SUCCESS:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    isUserUpdatePending: false,
                    isUserUpdateFailed: false,
                    isUserUpdatesuccess: true,
                    displayUserModal: false
                }
            }
        case types.UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_FAILED:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    isUserUpdatePending: false,
                    isUserUpdateFailed: true,
                    isUserUpdatesuccess: false,
                    displayUserModal: false,
                    errorResponse: action.errorResponse
                }
            }
        case types.UTILITY_SIEBEL_CUSTOMER_IDENTITY_MODAL_CLOSE:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    isUserUpdatePending: false,
                    isUserUpdateFailed: false,
                    isUserUpdatesuccess: false,
                    displayUserModal: false
                }
            }
        case types.CLOSE_CAIMAN_MODAL:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    caimanModal: false,
                    isCaimanRegistrationPending: false,
                    isCaimanRegistrationFailed: false,
                    isCaimanRegistrationSuccess: false,
                }
            }
        case types.UTILITY_SIEBEL_REGISTER_CAIMAN_SUCCESS:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    caimanUserName: action.userName,
                    caimanPassword: action.password,
                    caimanModal: false,
                    isCaimanRegistrationPending: false,
                    isCaimanRegistrationFailed: false,
                    isCaimanRegistrationSuccess: true,

                }
            }
        case types.UTILITY_SIEBEL_REGISTER_CAIMAN_PENDING:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    caimanModal: false,
                    isCaimanRegistrationPending: true,
                    isCaimanRegistrationFailed: false,
                    isCaimanRegistrationSuccess: false,

                }
            }
        case types.UTILITY_SIEBEL_REGISTER_CAIMAN_FAILED:
            return {
                ...state,
                siebelCustomer: {
                    ...state.siebelCustomer,
                    caimanModal: false,
                    isCaimanRegistrationPending: false,
                    isCaimanRegistrationFailed: true,
                    isCaimanRegistrationSuccess: false,
                    caimanErrorResponse: action.errorResponse
                }
            }
        case types.UTILITY_SIEBEL_CONTACT_UPDATE_PENDING:
            return {
                ...state,
                siebelContacts: {
                    ...state.siebelContacts,
                    isUpdateContactsPending: true,
                    isUpdateContactsFailed: false,
                    isUpdateContactsSuccess: false,
                }
            }
        case types.UTILITY_SIEBEL_CONTACT_UPDATE_SUCCESS:
            return {
                ...state,
                siebelContacts: {
                    ...state.siebelContacts,
                    isUpdateContactsPending: false,
                    isUpdateContactsFailed: false,
                    isUpdateContactsSuccess: true,
                    response: action.payload,
                }
            }
        case types.UTILITY_SIEBEL_CONTACT_UPDATE_FAILED:
            return {
                ...state,
                siebelContacts: {
                    ...state.siebelContacts,
                    isUpdateContactsPending: false,
                    isUpdateContactsFailed: true,
                    isUpdateContactsSuccess: false,
                    response: action.payload,
                }
            }
        case types.UTILITY_CUSTOMER_CREATION_PENDING:
            return {
                ...state,
                createCustomer: {
                    ...state.createCustomer,
                    isCustomerCreationPending: true,
                    isCustomerCreationFailed: false,
                    isCustomerCreationSuccess: false
                }
            }
        case types.UTILITY_CUSTOMER_CREATION_SUCCESS:
            return {
                ...state,
                createCustomer: {
                    ...state.createCustomer,
                    isCustomerCreationPending: false,
                    isCustomerCreationFailed: false,
                    isCustomerCreationSuccess: true,
                    response: action.payload
                }
            }
        case types.UTILITY_CUSTOMER_CREATION_FAILED:
            return {
                ...state,
                createCustomer: {
                    ...state.createCustomer,
                    isCustomerCreationPending: false,
                    isCustomerCreationFailed: true,
                    isCustomerCreationSuccess: false,
                    response: action.payload
                }
            }
        case types.UTILITY_S2P_RETRIEVE_TABLES_PENDING:
            return {
                ...state,
                s2pSalesForceCommision: {
                    isRetrieveTablesPending: true,
                    isRetrieveTablesFailed: false,
                    isRetrieveTablesSuccess: false,
                    response: action.payload
                }
            }
        case types.UTILITY_S2P_RETRIEVE_TABLES_SUCCESS:
            return {
                ...state,
                s2pSalesForceCommision: {
                    isRetrieveTablesPending: false,
                    isRetrieveTablesFailed: false,
                    isRetrieveTablesSuccess: true,
                    responseList: [...[action.payload]],
                    response: action.payload
                }
            }
        case types.UTILITY_S2P_RETRIEVE_TABLES_FAILED:
            return {
                ...state,
                s2pSalesForceCommision: {
                    isRetrieveTablesPending: false,
                    isRetrieveTablesFailed: true,
                    isRetrieveTablesSuccess: false,
                    response: action.payload
                }
            }
        case types.UTILITY_S2P_SUBMIT_TABLES_PENDING:
            return {
                ...state,
                s2pSalesForceCommision: {
                    isSubmitTablesPending: true,
                    isSubmitTablesFinished: false
                }
            }
        case types.UTILITY_S2P_SUBMIT_TABLES_SUCCESS:
            return {
                ...state,
                s2pSalesForceCommision: {
                    ...state.s2pSalesForceCommision,
                    isSubmitTablesSuccess: true,
                    submitSuccessResponse: action.payload
                }
            }
        case types.UTILITY_S2P_SUBMIT_TABLES_FAILED:
            return {
                ...state,
                s2pSalesForceCommision: {
                    ...state.s2pSalesForceCommision,
                    isSubmitTablesFailed: true,
                    submitFailResponse: action.payload
                }
            }
        case types.UTILITY_S2P_SUBMIT_TABLES_FINISHED:
            return {
                ...state,
                s2pSalesForceCommision: {
                    ...state.s2pSalesForceCommision,
                    isSubmitTablesFinished: true,
                    isSubmitTablesPending: false
                }
            }
        case types.UTILITY_S2P_ARYA_FETCH_FILES_PENDING:
            return {
                ...state,
                s2pArya: {
                    ...state.s2pArya,
                    isRetrieveFilesPending: true,
                    isRetrieveFilesFailed: false,
                    isRetrieveFilesSuccess: false,
                    response: null
                }
            }
        case types.UTILITY_S2P_ARYA_FETCH_FILES_SUCCESS:
            return {
                ...state,
                s2pArya: {
                    ...state.s2pArya,
                    isRetrieveFilesPending: false,
                    isRetrieveFilesFailed: false,
                    isRetrieveFilesSuccess: true,
                    response: action.payload
                }
            }
        case types.UTILITY_S2P_ARYA_FETCH_FILES_FAILED:
            return {
                ...state,
                s2pArya: {
                    ...state.s2pArya,
                    isRetrieveFilesPending: false,
                    isRetrieveFilesFailed: true,
                    isRetrieveFilesSuccess: false,
                    response: action.payload
                }
            }
        case types.UTILITY_S2P_ARYA_SUBMIT_FILES_PENDING:
            return {
                ...state,
                s2pArya: {
                    isSubmitFilesPending: true,
                    isSubmitFilesFinished: false
                }
            }
        case types.UTILITY_S2P_ARYA_SUBMIT_FILES_SUCCESS:
            return {
                ...state,
                s2pArya: {
                    ...state.s2pArya,
                    isSubmitFilesSuccess: true,
                    submitSuccessResponse: action.payload
                }
            }
        case types.UTILITY_S2P_ARYA_SUBMIT_FILES_FAILED:
            return {
                ...state,
                s2pArya: {
                    ...state.s2pArya,
                    isSubmitFilesFailed: true,
                    submitFailResponse: action.payload
                }
            }
        case types.UTILITY_S2P_ARYA_SUBMIT_FILES_FINISHED:
            return {
                ...state,
                s2pArya: {
                    ...state.s2pArya,
                    isSubmitFilesFinished: true,
                    isSubmitFilesPending: false
                }
            }
        case types.MSISDN_LOAD_PENDING:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    isSubmitMsisdnPending: true,
                    isSubmitMsisdnFailed: false,
                    isSubmitMsisdnSuccess: false,
                    msisdnResponse: null,
                }
            }
        case types.MSISDN_LOAD_SUCCESS:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    isSubmitMsisdnPending: false,
                    isSubmitMsisdnFailed: false,
                    isSubmitMsisdnSuccess: true,
                    msisdnResponse: action.payload,
                }
            }
        case types.MSISDN_LOAD_FAILURE:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    isSubmitMsisdnPending: false,
                    isSubmitMsisdnFailed: true,
                    isSubmitMsisdnSuccess: false,
                    msisdnResponse: action.payload,
                }
            }
        case types.MSISDN_CLEAR:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    isSubmitMsisdnPending: false,
                    isSubmitMsisdnFailed: false,
                    isSubmitMsisdnSuccess: false,
                    msisdnResponse: null,
                }
            }
        case types.SET_MSISDN_LIST:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    msisdnList: action.list,
                }
            }
        case types.SET_SIM_LIST:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    simList: action.list,
                }
            }
        case types.SIM_IMSI_PRESEEDING_PENDING:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    isSubmitSimPending: true,
                    isSubmitSimFailed: false,
                    isSubmitSimSuccess: false,
                    simResponse: null,
                }
            }
        case types.SIM_IMSI_PRESEEDING_SUCCESS:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    isSubmitSimPending: false,
                    isSubmitSimFailed: false,
                    isSubmitSimSuccess: true,
                    simResponse: action.payload,
                }
            }
        case types.SIM_IMSI_PRESEEDING_FAILURE:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    isSubmitSimPending: false,
                    isSubmitSimFailed: true,
                    isSubmitSimSuccess: false,
                    simResponse: action.payload,
                }
            }

        case types.PHYSICAL_SIM_FILE_UPLOAD_LOADING:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    fileContents: {
                        contentsLoading: true
                    }
                }
            }

        case types.PHYSICAL_SIM_FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    fileContents: {
                        ...action.payload,
                        contentsLoading: false
                    }
                }
            }
        case types.PHYSICAL_SIM_FILE_UPLOAD_ERROR:
            return {
                ...state,
                physicalSimPreceeding: {
                    ...state.physicalSimPreceeding,
                    fileContents: {
                        contentsError: action.payload,
                        contentsLoading: false
                    }
                }
            }

        case types.CHECK_BATCH_ID_STATUS_PENDING:
            return {
                ...state,
                batchIdStatus: {
                    ...state.batchIdStatus,
                    isCheckStatusPending: true,
                    isCheckStatusFailed: false,
                    isCheckStatusSuccess: false,
                    statusResponse: null,
                }
            }
        case types.CHECK_BATCH_ID_STATUS_SUCCESS:
            return {
                ...state,
                batchIdStatus: {
                    ...state.batchIdStatus,
                    isCheckStatusPending: false,
                    isCheckStatusFailed: false,
                    isCheckStatusSuccess: true,
                    statusResponse: action.payload,
                }
            }
        case types.CHECK_BATCH_ID_STATUS_FAILURE:
            return {
                ...state,
                batchIdStatus: {
                    ...state.batchIdStatus,
                    isCheckStatusPending: false,
                    isCheckStatusFailed: true,
                    isCheckStatusSuccess: false,
                    statusResponse: action.payload,
                }
            }

        case types.UTILITY_FETCH_ADDRESS_RESET:
            return {
                ...state,
                address: {
                    isRetrievePending: false,
                    isRetrieveFailed: false,
                    isRetrieveSuccess: false,
                    response: action.payload
                }
            }
        case types.UTILITY_FETCH_ADDRESS_PENDING:
            return {
                ...state,
                address: {
                    isRetrievePending: true,
                    isRetrieveFailed: false,
                    isRetrieveSuccess: false,
                    response: action.payload
                }
            }
        case types.UTILITY_FETCH_ADDRESS_SUCCESS:
            return {
                ...state,
                address: {
                    isRetrievePending: false,
                    isRetrieveFailed: false,
                    isRetrieveSuccess: true,
                    response: action.payload
                }
            }
        case types.UTILITY_FETCH_ADDRESS_FAILED:
            return {
                ...state,
                address: {
                    isRetrievePending: false,
                    isRetrieveFailed: true,
                    isRetrieveSuccess: false,
                    response: action.payload
                }
            }
        case types.CUSTOMER_SYNC_PENDING:
            return {
                ...state,
                customerSync: {
                    ...state.customerSync,
                    isCustomerSyncSuccess: false,
                    isCustomerSyncPending: true,
                    isCustomerSyncFailed: false,
                    isCustomerSyncStatusSuccess: false,
                    isCustomerSyncStatusPending: false,
                    isCustomerSyncStatusFailed: false,
                }
            }
        case types.CUSTOMER_SYNC_FAILED:
            return {
                ...state,
                customerSync: {
                    isCustomerSyncSuccess: false,
                    isCustomerSyncPending: false,
                    isCustomerSyncFailed: true,
                    responses: {
                        ...state.customerSync.responses,
                        [action.payload.custId]: action.payload,
                    },
                    isCustomerSyncStatusSuccess: false,
                    isCustomerSyncStatusPending: false,
                    isCustomerSyncStatusFailed: false,
                }
            }
        case types.CUSTOMER_SYNC_SUCCESS:
            return {
                ...state,
                customerSync: {
                    isCustomerSyncSuccess: true,
                    isCustomerSyncPending: false,
                    isCustomerSyncFailed: false,
                    responses: {
                        ...state.customerSync.responses,
                        [action.payload.custId]: action.payload,
                    },
                    isCustomerSyncStatusSuccess: false,
                    isCustomerSyncStatusPending: false,
                    isCustomerSyncStatusFailed: false,
                }
            }
        case types.CUSTOMER_SYNC_STATUS_PENDING:
            return {
                ...state,
                customerSync: {
                    ...state.customerSync,
                    isCustomerSyncStatusSuccess: false,
                    isCustomerSyncStatusPending: true,
                    isCustomerSyncStatusFailed: false,
                }
            }
        case types.CUSTOMER_SYNC_STATUS_FAILED:
            return {
                ...state,
                customerSync: {
                    ...state.customerSync,
                    isCustomerSyncStatusSuccess: false,
                    isCustomerSyncStatusPending: false,
                    isCustomerSyncStatusFailed: true,
                    responses: {
                        ...state.customerSync.responses,
                        [action.payload.custId]: {
                            ...state.customerSync.responses[action.payload.custId],
                            statusError: action.payload.error,
                        }
                    }
                }
            }
        case types.CUSTOMER_SYNC_STATUS_SUCCESS:
            return {
                ...state,
                customerSync: {
                    ...state.customerSync,
                    isCustomerSyncStatusSuccess: true,
                    isCustomerSyncStatusPending: false,
                    isCustomerSyncStatusFailed: false,
                    responses: {
                        ...state.customerSync.responses,
                        [action.payload.custId]: {
                            ...state.customerSync.responses[action.payload.custId],
                            ...action.payload,
                        }
                    }
                }
            }
        default:
            return state;
    }
}
