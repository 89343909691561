import React from 'react';
import {Table} from "reactstrap";
import {getClassTypeIcon, renderIcon, renderQtyIcon} from "../../../../utils/ui";
import Moment from "react-moment";
import _ from 'lodash';

export default function CheckoutTable({id, assetDetails, quantity, getValueOfName,timestamp}){

    const { success = true, message = "" } = assetDetails === undefined ? {} : assetDetails;
    const serviceFilterDefinitions = _.get(assetDetails, 'serviceId.serviceFilterDefinitions', []);

    return(
        <React.Fragment>
            {(assetDetails !== null && assetDetails !== undefined  &&
                <Table key={assetDetails.id} hover responsive>
                    <thead>
                    <tr>
                        <th>Checkout ID</th>
                        <th>Environment</th>
                        <th>Type</th>
                        <th>Product</th>
                        <th>Class</th>
                        <th>Qty</th>
                        <th>Date</th>
                        <th>Success</th>
                    </tr>
                    {/*<TableFilterController  />*/}
                    {/* end <TableFilterController  />*/}
                    </thead>

                    <tbody>
                    {/*<CheckoutHistoryRow />*/}
                    <tr>

                        <td className="base-default">{renderIcon("icon-manage-services")}{id}</td>
                        <td className="base-magenta">{renderIcon("icon-home")}{assetDetails.environment}</td>
                        <td className="base-green">{renderIcon(assetDetails.type === "ACTIVE ASSET"
                            ? "icon-laptop-phone"
                            : "icon-register")}{assetDetails.type}</td>
                        <td className="base-purple">{renderIcon(getClassTypeIcon(getValueOfName(serviceFilterDefinitions, "PRODUCT_TYPE")))}{getValueOfName(serviceFilterDefinitions, "PRODUCT_TYPE")}</td>
                        <td className="base-orange">{renderIcon(getClassTypeIcon(getValueOfName(serviceFilterDefinitions, "ORDER_SERVICE_TYPE")))}{getValueOfName(serviceFilterDefinitions, "ORDER_SERVICE_TYPE")}</td>
                        <td className="base-grey">
                            {renderQtyIcon(quantity)}
                        </td>
                        <td className="base-turquoise">
            {renderIcon("icon-time")}<Moment fromNow>{timestamp}</Moment>
                        </td>
                        <td
                            className={success
                                ? "base-green"
                                : "base-orange"}>{message ?
                            <i className="fa fa-exclamation-triangle text-warning fa-3x"
                               aria-hidden="true"/> : renderIcon(success
                                ? "icon-round-tick"
                                : "icon-round-cross")}</td>
                    </tr>
                    {/* end <CheckoutHistoryRow />*/}

                    </tbody>
                </Table>
            )}
        </React.Fragment>
    );
}
