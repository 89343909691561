import React from "react";
import ContentBody from "@library/core/surfaces/ContentBody";
import { Title } from "@library/core/text";
import {
    FormAutoComplete,
    Quantity
} from "@library/core/form-elements";
import { SubmitButton } from "@library/core/buttons";
import { Grid, Typography, Box, makeStyles } from "@material-ui/core";
import { customerLiterals, contactLiterals, customerFormFieldTypes, contactFormFieldTypes, orderFormFieldTypes, serviceFormFieldTypes, 
    dateLiterals, orderLiterals, serviceLiterals, orderItemLiterals } from "./constants";

const useStyles = makeStyles((theme) => ({
    section: {
        margin: '10px 0px 11px 0px !important',
        fontSize: '15px',
        fontWeight: '400'
    },
}))

const SalesforceSearchForm = (props) => {
    const classes = useStyles();
    const { 
        formName, 
        updatePayload, 
        submitSearch,
        fetchLovs, 
        loading, 
        payload, 
        selectedEnvValue,
        fetchLovData
    } = props;

    const getLovs = (type, fieldName, entity, condition) => {
        if (!fetchLovData?.[entity]?.[type]?.success && (!fetchLovData?.[entity]?.[type]?.list || fetchLovData?.[entity]?.[type]?.list?.length === 0)) {
            fetchLovs(type, fieldName, selectedEnvValue, entity, condition);
        }
    }
    // Customer Form types:
    const type = customerFormFieldTypes.Type;
    const status = customerFormFieldTypes.vlocity_cmt__Status__c;
    const date = customerFormFieldTypes.CreatedDate;

    const customerForm = (
        <Grid container direction="column" id="customerForm">
            <Grid container direction="row" spacing={4}>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={type.label}
                        id={type.id}
                        fullWidth
                        value={payload?.account?.filters?.Type?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(type.id, value, type.format)}
                        options={["All", ...(fetchLovData?.[customerLiterals.entityName]?.[type.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(type.id, type.label, customerLiterals.entityName)}
                        loading={fetchLovData?.[customerLiterals.entityName]?.[type.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[customerLiterals.entityName]?.[type.id]?.success) && fetchLovData?.[customerLiterals.entityName]?.[type.id]?.failMsg && fetchLovData?.[customerLiterals.entityName]?.[type.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[customerLiterals.entityName]?.[type.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                    <FormAutoComplete 
                        label={status.label}
                        id={status.id}
                        fullWidth
                        value={payload?.account?.filters?.vlocity_cmt__Status__c?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        defaultChecked={"All"}
                        onChange={(e,value) => updatePayload(status.id, value, status.format)}
                        options={["All", ...(fetchLovData?.[customerLiterals.entityName]?.[status.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(status.id, status.label, customerLiterals.entityName)}
                        loading={fetchLovData?.[customerLiterals.entityName]?.[status.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[customerLiterals.entityName]?.[status.id]?.success) && fetchLovData?.[customerLiterals.entityName]?.[status.id]?.failMsg && fetchLovData?.[customerLiterals.entityName]?.[status.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[customerLiterals.entityName]?.[status.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                    <FormAutoComplete 
                        label={date.label}
                        id={date.id}
                        fullWidth
                        value={payload?.account?.filters?.CreatedDate || 'All'}
                        onChange={(e,value) => updatePayload(date.id, value, date.format)}
                        options={dateLiterals.dateOptions}
                        autoComplete={false}
                    />
                </Grid>
                <Grid item sm={12} md={2}>
                    {
                        payload?.account?.filters?.CreatedDate?.split(':')[0] === "LAST_N_DAYS" &&
                        <>
                            <Typography 
                                variant="h6" 
                                color="textSecondary" 
                                gutterBottom
                                className={classes.section}
                            >
                                Number of days
                            </Typography>
                            <Box display="flex">
                                <Quantity
                                    id={date.id}
                                    qty={Number(payload?.account?.filters?.CreatedDate?.split(':')[1] || 0)}
                                    onChangeQty={(qty,id)=>updatePayload(id, `${payload?.account?.filters?.CreatedDate?.split(':')[0]}:${qty}`, date.format)}
                                    maxQty={365}
                                />
                            </Box>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    );

    // Contact Form types:
    const CommMethod = contactFormFieldTypes.PreferredCommunicationMethod__c;
    const isActive = contactFormFieldTypes.IsActive;
    const title = contactFormFieldTypes.Title;
    const role = contactFormFieldTypes.Role__c;
    const contactStatus = contactFormFieldTypes.vlocity_cmt__Status__c;
    const contactCreatedDate = contactFormFieldTypes.CreatedDate;

    const contactForm = (
        <Grid container direction="column" id="contactForm">
           <Grid container direction="row" spacing={4}>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={CommMethod.label}
                        id={CommMethod.id}
                        fullWidth
                        value={payload?.account?.contacts?.filters?.[CommMethod.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(CommMethod.id, value, CommMethod.format)}
                        options={["All", ...(fetchLovData?.[contactLiterals.entityName]?.[CommMethod.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(CommMethod.id, CommMethod.label, contactLiterals.entityName)}
                        loading={fetchLovData?.[contactLiterals.entityName]?.[CommMethod.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[contactLiterals.entityName]?.[CommMethod.id]?.success) && fetchLovData?.[contactLiterals.entityName]?.[CommMethod.id]?.failMsg && fetchLovData?.[contactLiterals.entityName]?.[CommMethod.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[contactLiterals.entityName]?.[CommMethod.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={isActive.label}
                        id={isActive.id}
                        fullWidth
                        value={payload?.account?.contacts?.addlFilters?.[isActive.id]?.toString() || contactLiterals.isActive[0]}
                        onChange={(e,value) => updatePayload(isActive.id, value, isActive.format, true)}
                        options={contactLiterals.isActive}
                        autoComplete={false}
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={title.label}
                        id={title.id}
                        fullWidth
                        value={payload?.account?.contacts?.filters?.Title?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(title.id, value, title.format)}
                        options={["All", ...(fetchLovData?.[contactLiterals.entityName]?.[title.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(title.id, title.label, contactLiterals.entityName)}
                        loading={fetchLovData?.[contactLiterals.entityName]?.[title.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[contactLiterals.entityName]?.[title.id]?.success) && fetchLovData?.[contactLiterals.entityName]?.[title.id]?.failMsg && fetchLovData?.[contactLiterals.entityName]?.[title.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[contactLiterals.entityName]?.[title.id]?.failMsg}
                    />
                </Grid>
           </Grid>
           <Grid container direction="row" spacing={4}>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={role.label}
                        id={role.id}
                        fullWidth
                        value={payload?.account?.contacts?.addlFilters?.[role.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(role.id, value, role.format, true)}
                        options={["All", ...(fetchLovData?.[contactLiterals.addlEntityName]?.[role.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(role.id, role.label, contactLiterals.addlEntityName)}
                        loading={fetchLovData?.[contactLiterals.addlEntityName]?.[role.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[contactLiterals.addlEntityName]?.[role.id]?.success) && fetchLovData?.[contactLiterals.addlEntityName]?.[role.id]?.failMsg && fetchLovData?.[contactLiterals.addlEntityName]?.[role.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[contactLiterals.addlEntityName]?.[role.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                    <FormAutoComplete 
                        label={contactStatus.label}
                        id={contactStatus.id}
                        fullWidth
                        value={payload?.account?.contacts?.filters?.vlocity_cmt__Status__c?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(contactStatus.id, value, contactStatus.format)}
                        options={["All", ...(fetchLovData?.[contactLiterals.entityName]?.[contactStatus.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(contactStatus.id, contactStatus.label, contactLiterals.entityName)}
                        loading={fetchLovData?.[contactLiterals.entityName]?.[contactStatus.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[contactLiterals.entityName]?.[contactStatus.id]?.success) && fetchLovData?.[contactLiterals.entityName]?.[contactStatus.id]?.failMsg && fetchLovData?.[contactLiterals.entityName]?.[contactStatus.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[contactLiterals.entityName]?.[contactStatus.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                    <FormAutoComplete 
                        label={contactCreatedDate.label}
                        id={contactCreatedDate.id}
                        fullWidth
                        value={payload?.account?.contacts?.filters?.[contactCreatedDate.id] || 'All'}
                        onChange={(e,value) => updatePayload(contactCreatedDate.id, value, contactCreatedDate.format)}
                        options={dateLiterals.dateOptions}
                        autoComplete={false}
                    />
                </Grid>
                <Grid item sm={12} md={2}>
                    {
                        payload?.account?.contacts?.filters?.[contactCreatedDate.id]?.split(':')[0] === "LAST_N_DAYS" &&
                        <>
                            <Typography 
                                variant="h6" 
                                color="textSecondary" 
                                gutterBottom
                                className={classes.section}
                            >
                                Number of days
                            </Typography>
                            <Box display="flex">
                                <Quantity
                                    id={contactCreatedDate.id}
                                    qty={Number(payload?.account?.contacts?.filters?.[contactCreatedDate.id]?.split(':')[1] || 0)}
                                    onChangeQty={(qty,id)=>updatePayload(id, `${payload?.account?.contacts?.filters?.[contactCreatedDate.id]?.split(':')[0]}:${qty}`, contactCreatedDate.format)}
                                    maxQty={365}
                                />
                            </Box>
                        </>
                    }
                </Grid>
           </Grid>
        </Grid>
    );

    // Order Form types:
    const orderStatus = orderFormFieldTypes.OrderStatus;
    const orderCreatedDate = orderFormFieldTypes.CreatedDate;
    const TLS_PriorityAssist = serviceFormFieldTypes.TLS_PriorityAssist;
    const TLS_PriorityAssist__c="TLS_PriorityAssist__c";
    const TLS_ChargeModel__c = "TLS_ChargeModel__c";
    const orderType = orderFormFieldTypes.Type;
    const orderReason = orderFormFieldTypes.OrderReason__c;
    const orderReasonType = orderFormFieldTypes.OrderReasonType__c;

    const orderForm = (
        <Grid container direction="column" id="orderForm">
            <Grid container direction="row" spacing={4}>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={orderStatus.label}
                        id={orderStatus.id}
                        fullWidth
                        value={payload?.account?.orders?.filters?.[orderStatus.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(orderStatus.id, value, orderStatus.format)}
                        options={["All", ...(fetchLovData?.[orderLiterals.orderEntityName]?.[orderStatus.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(orderStatus.id, orderStatus.label, orderLiterals.orderEntityName)}
                        loading={fetchLovData?.[orderLiterals.orderEntityName]?.[orderStatus.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[orderLiterals.orderEntityName]?.[orderStatus.id]?.success) && fetchLovData?.[orderLiterals.orderEntityName]?.[orderStatus.id]?.failMsg && fetchLovData?.[orderLiterals.orderEntityName]?.[orderStatus.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[orderLiterals.orderEntityName]?.[orderStatus.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={orderType.label}
                        id={orderType.id}
                        fullWidth
                        value={payload?.account?.orders?.filters?.[orderType.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(orderType.id, value, orderType.format)}
                        options={["All", ...(fetchLovData?.[orderLiterals.orderEntityName]?.[orderType.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(orderType.id, orderType.label, orderLiterals.orderEntityName)}
                        loading={fetchLovData?.[orderLiterals.orderEntityName]?.[orderType.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[orderLiterals.orderEntityName]?.[orderType.id]?.success) && 
                            fetchLovData?.[orderLiterals.orderEntityName]?.[orderType.id]?.failMsg && 
                            fetchLovData?.[orderLiterals.orderEntityName]?.[orderType.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[orderLiterals.orderEntityName]?.[orderType.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={orderReason.label}
                        id={orderReason.id}
                        fullWidth
                        value={payload?.account?.orders?.filters?.[orderReason.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(orderReason.id, value, orderReason.format, null, orderReason.childFields)}
                        options={["All", ...(fetchLovData?.[orderLiterals.orderEntityName]?.[orderReason.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(orderReason.id, orderReason.label, orderLiterals.orderEntityName)}
                        loading={fetchLovData?.[orderLiterals.orderEntityName]?.[orderReason.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[orderLiterals.orderEntityName]?.[orderReason.id]?.success) && 
                            fetchLovData?.[orderLiterals.orderEntityName]?.[orderReason.id]?.failMsg && 
                            fetchLovData?.[orderLiterals.orderEntityName]?.[orderReason.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[orderLiterals.orderEntityName]?.[orderReason.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={4}> 
                    <FormAutoComplete 
                        label={orderReasonType.label}
                        id={orderReasonType.id}
                        fullWidth
                        value={payload?.account?.orders?.filters?.[orderReasonType.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(orderReasonType.id, value, orderReasonType.format)}
                        options={["All", ...(fetchLovData?.[orderLiterals.orderEntityName]?.[orderReasonType.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(
                            orderReasonType.id, 
                            orderReasonType.label, 
                            orderLiterals.orderEntityName,
                            payload?.account?.orders?.filters?.[orderReason.id] && 
                            `OrderReason__c:${payload?.account?.orders?.filters?.[orderReason.id]}`
                        )}
                        loading={fetchLovData?.[orderLiterals.orderEntityName]?.[orderReasonType.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[orderLiterals.orderEntityName]?.[orderReasonType.id]?.success) && 
                            fetchLovData?.[orderLiterals.orderEntityName]?.[orderReasonType.id]?.failMsg && 
                            fetchLovData?.[orderLiterals.orderEntityName]?.[orderReasonType.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[orderLiterals.orderEntityName]?.[orderReasonType.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                    <FormAutoComplete 
                        label={orderCreatedDate.label}
                        id={orderCreatedDate.id}
                        fullWidth
                        value={payload?.account?.orders?.filters?.[orderCreatedDate.id] || 'All'}
                        onChange={(e,value) => updatePayload(orderCreatedDate.id, value, orderCreatedDate.format)}
                        options={dateLiterals.dateOptions}
                        autoComplete={false}
                    />
                </Grid>
                <Grid item sm={12} md={2}>
                    {
                        payload?.account?.orders?.filters?.[orderCreatedDate.id]?.split(':')[0] === "LAST_N_DAYS" &&
                        <>
                            <Typography 
                                variant="h6" 
                                color="textSecondary" 
                                gutterBottom
                                className={classes.section}
                            >
                                Number of days
                            </Typography>
                            <Box display="flex">
                                <Quantity
                                    id={orderCreatedDate.id}
                                    qty={Number(payload?.account?.orders?.filters?.[orderCreatedDate.id]?.split(':')[1] || 0)}
                                    onChangeQty={(qty,id)=>updatePayload(id, `${payload?.account?.orders?.filters?.[orderCreatedDate.id]?.split(':')[0]}:${qty}`, orderCreatedDate.format)}
                                    maxQty={365}
                                />
                            </Box>
                        </>
                    }
                </Grid>
            </Grid>
        </Grid>
    );

    // Service Form types:
    const servicefamily = serviceFormFieldTypes.Family;
    const serviceName = serviceFormFieldTypes.Name;
    const serviceProductType = serviceFormFieldTypes.Product_Type__c;
    const TLS_ChargeModel = serviceFormFieldTypes.TLS_ChargeModel;

    const serviceNameConditionBuilder = () => {
        if (payload?.account?.orders?.product?.filters?.[servicefamily.id] && payload?.account?.orders?.product?.filters?.[serviceProductType.id]) {
            return `${servicefamily.id}:${payload?.account?.orders?.product?.filters?.[servicefamily.id]},${serviceProductType.id}:${payload?.account?.orders?.product?.filters?.[serviceProductType.id]}`;
        } else if (payload?.account?.orders?.product?.filters?.[servicefamily.id]) {
            return `${servicefamily.id}:${payload?.account?.orders?.product?.filters?.[servicefamily.id]}`;
        } else if (payload?.account?.orders?.product?.filters?.[serviceProductType.id]) {
            return `${serviceProductType.id}:${payload?.account?.orders?.product?.filters?.[serviceProductType.id]}`;
        }
         return "";
    }

    const serviceForm = (
        <Grid container direction="column" id="orderForm">
            <Grid container direction="row" spacing={4}>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={servicefamily.label}
                        id={servicefamily.id}
                        fullWidth
                        value={payload?.account?.orders?.product?.filters?.[servicefamily.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(servicefamily.id, value, servicefamily.format, null, servicefamily.childFields)}
                        options={["All", ...(fetchLovData?.[serviceLiterals.productEntityName]?.[servicefamily.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(
                            servicefamily.id, 
                            servicefamily.label, 
                            serviceLiterals.productEntityName
                        )}
                        loading={fetchLovData?.[serviceLiterals.productEntityName]?.[servicefamily.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[serviceLiterals.productEntityName]?.[servicefamily.id]?.success) && 
                            fetchLovData?.[serviceLiterals.productEntityName]?.[servicefamily.id]?.failMsg && 
                            fetchLovData?.[serviceLiterals.productEntityName]?.[servicefamily.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[serviceLiterals.productEntityName]?.[servicefamily.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={serviceProductType.label}
                        id={serviceProductType.id}
                        fullWidth
                        value={payload?.account?.orders?.product?.filters?.[serviceProductType.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(serviceProductType.id, value, serviceProductType.format, null, serviceProductType.childFields)}
                        options={["All", ...(fetchLovData?.[serviceLiterals.productEntityName]?.[serviceProductType.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(
                            serviceProductType.id, 
                            serviceProductType.label, 
                            serviceLiterals.productEntityName,
                            payload?.account?.orders?.product?.filters?.[servicefamily.id] && 
                            `${servicefamily.id}:${payload?.account?.orders?.product?.filters?.[servicefamily.id]}`
                        )}
                        loading={fetchLovData?.[serviceLiterals.productEntityName]?.[serviceProductType.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[serviceLiterals.productEntityName]?.[serviceProductType.id]?.success) && 
                            fetchLovData?.[serviceLiterals.productEntityName]?.[serviceProductType.id]?.failMsg && 
                            fetchLovData?.[serviceLiterals.productEntityName]?.[serviceProductType.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[serviceLiterals.productEntityName]?.[serviceProductType.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={4}>
                    <FormAutoComplete 
                        label={serviceName.label}
                        id={serviceName.id}
                        fullWidth
                        value={payload?.account?.orders?.product?.filters?.[serviceName.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => updatePayload(serviceName.id, value, serviceName.format)}
                        options={["All", ...(fetchLovData?.[serviceLiterals.productEntityName]?.[serviceName.id]?.list || [])]}
                        loadOptionFunc={()=>getLovs(
                            serviceName.id, 
                            serviceName.label, 
                            serviceLiterals.productEntityName,
                            serviceNameConditionBuilder()
                        )}
                        loading={fetchLovData?.[serviceLiterals.productEntityName]?.[serviceName.id]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[serviceLiterals.productEntityName]?.[serviceName.id]?.success) && 
                            fetchLovData?.[serviceLiterals.productEntityName]?.[serviceName.id]?.failMsg && 
                            fetchLovData?.[serviceLiterals.productEntityName]?.[serviceName.id]?.failMsg !== ''}
                        helperText={fetchLovData?.[serviceLiterals.productEntityName]?.[serviceName.id]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                <FormAutoComplete 
                        label={TLS_PriorityAssist.label}
                        id={TLS_PriorityAssist.id}
                        fullWidth
                        value={payload?.account?.orders?.product?.additionalFilters?.[TLS_PriorityAssist.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => {
                            updatePayload(TLS_PriorityAssist.id, value, TLS_PriorityAssist.format, true )
                        }}
                        options={["All", ...(fetchLovData?.[customerLiterals.entityName]?.[TLS_PriorityAssist__c]?.list || [])]}
                        loadOptionFunc={()=>getLovs(
                            TLS_PriorityAssist__c, 
                            TLS_PriorityAssist.label, 
                            customerLiterals.entityName,
                        )}
                        loading={fetchLovData?.[customerLiterals.entityName]?.[TLS_PriorityAssist__c]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[customerLiterals.entityName]?.[TLS_PriorityAssist__c]?.success) && 
                            fetchLovData?.[customerLiterals.entityName]?.[TLS_PriorityAssist__c]?.failMsg && 
                            fetchLovData?.[customerLiterals.entityName]?.[TLS_PriorityAssist__c]?.failMsg !== ''}
                        helperText={fetchLovData?.[customerLiterals.entityName]?.[TLS_PriorityAssist__c]?.failMsg}
                    />
                </Grid>
                <Grid item sm={12} md={3}>
                <FormAutoComplete 
                        label={TLS_ChargeModel.label}
                        id={TLS_ChargeModel.id}
                        fullWidth
                        value={payload?.account?.orders?.product?.additionalFilters?.[TLS_ChargeModel.id]?.replaceAll("'", '') || 'All'}
                        defaultValue={"All"}
                        onChange={(e,value) => {
                            updatePayload(TLS_ChargeModel.id, value, TLS_ChargeModel.format, true )
                        }}
                        options={["All", ...(fetchLovData?.[orderItemLiterals.entityName]?.[TLS_ChargeModel__c]?.list || [])]}
                        loadOptionFunc={()=>getLovs(
                            TLS_ChargeModel__c, 
                            TLS_ChargeModel.label, 
                            orderItemLiterals.entityName,
                        )}
                        loading={fetchLovData?.[orderItemLiterals.entityName]?.[TLS_ChargeModel__c]?.pending}
                        autoComplete={false}
                        error={!(fetchLovData?.[orderItemLiterals.entityName]?.[TLS_ChargeModel__c]?.success) && 
                            fetchLovData?.[orderItemLiterals.entityName]?.[TLS_ChargeModel__c]?.failMsg && 
                            fetchLovData?.[orderItemLiterals.entityName]?.[TLS_ChargeModel__c]?.failMsg !== ''}
                        helperText={fetchLovData?.[orderItemLiterals.entityName]?.[TLS_ChargeModel__c]?.failMsg}
                    />
                </Grid>
                
            </Grid>
        </Grid>
    );

    const forms = {customer: customerForm, contact: contactForm, order: orderForm, services: serviceForm};

    return (
        <ContentBody marginTop={2} marginBottom={2} padding={4}>
            <Title variant="h5">{`${formName} Search`}</Title>
            <div style={{marginTop: "10px"}}>
                {forms[formName]}
            </div>
            <Grid container direction="row" justifyContent="center" style={{marginTop:"16px"}}>
                <Grid item sm={3}>
                    <SubmitButton fullWidth size="large" onSubmit={submitSearch} disabled={loading}>Search</SubmitButton>
                </Grid>
            </Grid>
        </ContentBody>
    );
}

export default SalesforceSearchForm;