import React from "react";

import { Box, Grid, Divider, Typography, Avatar, IconButton, Tooltip, withStyles, makeStyles } from "@material-ui/core";
import "./Notification.css";

import {
  Person as PersonIcon,
  DraftsOutlined as ReadIcon,
  EmailOutlined as UnreadIcon,
  People as PeopleIcon
} from "@material-ui/icons";



const useStyles = makeStyles({
  body1: {
    color: "#6c6c6c"
  }
});

const Notification = (props) => {
  const classes = useStyles();
  let bColor, icon, textStyle;

  if (props.readFlag === true) {
    bColor = "#ffffff";
    icon = <ReadIcon />;
    textStyle = classes.body1;
    
  } else {
    // bColor = "#ebf1f6";
    bColor = "#e0eaf1";
    icon = <UnreadIcon />;
    textStyle = null;
  }


  const viewMessages = (postedBy, postedAt, message, teamFlag) => {
    const messageDetails = { postedBy, postedAt, message , teamFlag}
    if(!props.readFlag)
       props.toggleRead(props);
    props.viewMessages(messageDetails);
    props.setNotificationsMessageOpen();
  }

  return (
    <React.Fragment>
      <Box width={395} bgcolor={bColor}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xm={2}>
            {props.teamFlag ?
              (<PeopleIcon fontSize="small" color="primary" />) :
              (
                <PersonIcon fontSize="small" color="primary" />)}
          </Grid>
          <Grid item xm={9} className="clickable" onClick={() => viewMessages(props.postedBy, props.postedAt, props.message, props.teamFlag)}>
            <Box width={300}>
              <Typography variant="body1" noWrap>
                {props.message}
              </Typography>
            </Box>
          </Grid>
          <Grid item xm={1}>
            <IconButton className="IconBtn" onClick={() =>  props.toggleRead(props)} color = "primary">
              {icon}
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Notification;
