import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import "./CustomerConsole.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { closeUpdateSuccess } from '../../../actions/utilityActions';
import {Button} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';

class CustomerUpdateSuccess extends Component {

    closeUpdateSuccess = () => {
        this.props.closeUpdateSuccess();
    }
      render() {
       return (
        <Modal isOpen={true} className="customerMailModal">
        <div className="modalcontent">
            <Fragment>
                <ModalHeader style={{ 'justifyContent': 'center' }}>
                    <strong>Update Identity</strong>
                </ModalHeader>
                <ModalBody className="modalbody" style={{ 'textAlign': 'center' }}>
                    <div className="row">
                        <div className="col-12" style={{ 'textAlign': 'center' }}>
                            <CheckIcon style={{ color: green[500] ,fontSize: 90 }}/>
                        </div>
                        <div className="col-12" style={{ 'textAlign': 'center' }}>
                            <label>Update Identity Successfull</label></div>
                            <div className="col-12" style={{ 'textAlign': 'center' }}> <Button variant="contained" color="primary" onClick={e=>this.closeUpdateSuccess()}>OK</Button>
                        </div>
                    </div>
                </ModalBody>
            </Fragment>
        </div></Modal>   
        )
    }

}

const mapStateToProps = (state) => {
    return {
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeUpdateSuccess: () => dispatch(closeUpdateSuccess())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerUpdateSuccess);