export default {
    'seperator':'divider',
    'account':{
        accountId:'Account-Id',
        accountName:'Name',
        accountType:'Type',
        accountUUID:'UUID',
        vlocityCmtStatus:'Status',
        url:'url'
    },
    'contacts':{
        contactId:'Contact-Id',
        accountId:'Account-Id',
        firstName:'First-Name',
        lastName:'Last-Name',
        email:'Email'
        // contactName:'Name',
        // contactType:'Type',
        // vlocityCmtPersonContactId:'P-Contact-ID',
        // vlocityCmtStatus:'Status'
    },
    'Order':{
        OrderId:'Order-Id',
        OrderStatus:'Status',
        vlocityCmtPersonContactId:'Person-Contact-ID',
        accountUUID:'UUID'
    }
}