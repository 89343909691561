import React, {Fragment } from "react";
import { connect } from "react-redux";
import { showSurvey } from "../../actions/surveyAction.js"
import {makeStyles, Typography, Avatar, Box,colors,Tooltip } from '@material-ui/core';
import GaugeChart from 'react-gauge-chart'
import Grid from '@material-ui/core/Grid';
import IndicatorTable from './IndicatorTable';
import TotalProfit from './TotalCost';
import TotalCustomers from './TotalUsers';
import Effort from './Effort';

import StickyHeadTable from './QuantativeTable.js';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';

const useStyles = makeStyles((theme) => ({
    avatar: {
      backgroundColor: colors.indigo[600],
      height: 56,
      width: 56
    }
}))  

const QuantativeReport = (props) => {
    const classes = useStyles();
    const boxProps = {
        bgcolor: 'background.paper',
        borderColor: 'text.primary',
        m: "40px 0px 40px 35px",
        p: "15px 0px 15px 0px",
        border: 1.5,
        style: { width: '32rem', height: '15.5rem' },
    };
    const hideSurvey = () => {
            let hideReport = !(props.displayReport);
            props.showSurvey(hideReport);
    }

    return (
        <Fragment>{props.displayReport &&
                <div className="qReportPanel">
         <div class="container">
         <div class="row reportRowWidth" >
         <div class="col-11">
         <Typography variant="h7" color="primary" align="left">
                        The PASMAT Assessment is calculated successfully.Below is the summary
                        and the adoption maturity level of your team.
          </Typography>
         </div>
          <div class="col-1">
          <Tooltip title="Back to Services"><CloseTwoToneIcon onClick={e => hideSurvey()} variant="contained" color="primary"/></Tooltip>
                  </div></div></div>
                  <div class="container"><div class="row"><div class="col-4"></div> <div class="col-6">
                       <h3>Adoption Maturity Level</h3> </div></div></div>
                    <Grid container spacing={4} className="mt-n5"> 
                        <Grid item xs={2}>
                            <Box borderRadius={4} {...boxProps} >

                                <GaugeChart id="gauge-chart1"
                                    textColor="#000000"
                                    needleColor="#808080"
                                    nrOfLevels={5}
                                    percent={(props.metric.qualitativeScore / 100)}
                                    colors={["#FF0000", "#d07505", "#ffab01", "#87c115", "#6fa009"]}/>

                            </Box>
                        </Grid>
                        <Grid xs={6} className="ml-10"><IndicatorTable /></Grid>
                    </Grid>
                    {props.metric.quantitativeScore !== undefined && props.metric.quantitativeScore.standardAsset !== undefined && props.metric.quantitativeScore.standardAsset !== null && <div style={{ padding: 10 }}>
                        <Grid>
                            <TotalCustomers users={props.metric.users} />
                        </Grid>
                    </div>}
                    <div style={{ padding: 10 }}>
                        <Grid>
                            {props.metric.quantitativeScore !== undefined && 
                            props.metric.quantitativeScore.standardAsset !== undefined && 
                            props.metric.quantitativeScore.standardAsset !== null &&  
                            <Effort effort={props.metric.quantitativeScore.standardAsset.fte} 
                            minutes={props.metric.quantitativeScore.standardAsset.totalEffortSaved}/>
                            }</Grid>
                    </div>
                    {props.metric.quantitativeScore !== undefined &&  
                    props.metric.quantitativeScore.standardAsset !== undefined && 
                    props.metric.quantitativeScore.standardAsset !== null && 
                    <div class="ml-3"><Accordion id="quantativeAccordian">
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                               
                            {props.metric.quantitativeScore !== undefined &&  props.metric.quantitativeScore.standardAsset !== undefined && props.metric.quantitativeScore.standardAsset !== null && <TotalProfit cost={props.metric.quantitativeScore.standardAsset.totalCostSaved} primaryRole={props.primaryRole}/>}
                            {props.metric.quantitativeScore !== undefined &&  props.metric.quantitativeScore.standardAsset !== undefined && props.metric.quantitativeScore.standardAsset !== null && <div class="ml-n3 mt-4"><Avatar className={classes.avatar}>
                                <AttachMoneyIcon />
                            </Avatar></div>}
                            
                        </AccordionSummary>
                        {(props.primaryRole == "OPS" || props.primaryRole == "ADMIN")&& 
                         <AccordionDetails>
                         {props.metric &&   <StickyHeadTable data={props.metric} /> }
                        </AccordionDetails>}
                    </Accordion></div>}
                  {/*   <Button variant="contained" color="primary" onClick={e => hideSurvey()}>Click here</Button>
                 */}</div>
            }
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        displayReport: state.pasmatTrend.displayReport
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        showSurvey: (value) => dispatch(showSurvey(value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuantativeReport);