import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Typography } from "@material-ui/core";
import SendIcon from '@mui/icons-material/Send';
import { useState } from 'react';
import { submitTimMigration } from '../../actions/timMigrationActions';
import { connect } from "react-redux";
import { displayErrorAlert, displayLoading } from "../common/alerts/alerts";
import swal from "sweetalert2";

const TimMigrationFreeText = (props) => {
    const [timFreeText, setTimMigration] = useState(null);
    const [timTypeInputError, setTimTypeInputError] = useState(null);

    const handleTimTypeInputBlur = (e) => setTimTypeInputError(!e.target.value)
    const submitTimMigration = () => {
        displayLoading({ title: "Loading", bodyText: "Please standby" })
        var payload = JSON.parse(timFreeText);
        props.submitTimMigration(payload).then((response) => {
            if (response?.message) {
                swal.fire(
                    "Success",
                    response.message,
                    "success"
                );
            } else {
                displayErrorAlert({
                    title: "Error",
                    errorMsg: response?.responseDescription
                });
            }
        });

    }
    return <>
        <Accordion expanded={true}>
            <AccordionSummary sx={{ backgroundColor: '#1976d2', height: '0px !important' }}>
                <Typography style={{ color: 'white' }}>Free Text Input</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="checkinContainer container">
                    <div className="row">
                        {" "}
                        <div className="col-1"></div>
                        <div className="col-12">
                            <TextField
                                id="fullWidth"
                                label="Input String"
                                variant="filled"
                                color="primary"
                                multiline
                                rows={6}
                                required={true}
                                value={timFreeText || ""}
                                error={timTypeInputError}
                                onBlur={(e) => handleTimTypeInputBlur(e)}
                                onChange={(e) => setTimMigration(e.target.value)}
                                fullWidth
                                helperText={
                                    !timTypeInputError
                                        ? ""
                                        : "Input cannot be empty"
                                }
                            />
                        </div>
                    </div>
                    <br />
                </div>
            </AccordionDetails>
        </Accordion >
        <br />
        <Button variant="contained" onClick={submitTimMigration} disabled={!timFreeText} sx={{ float: 'right' }} endIcon={<SendIcon />}>Submit</Button>
    </>

}

const mapDispatchToProps = (dispatch) => {
    return {
        submitTimMigration: (req) => dispatch(submitTimMigration(req))
    };
};

export default connect(null, mapDispatchToProps)(TimMigrationFreeText);