import {axiosTaasInstance as axios} from "../axios.config";
import _ from "lodash";
import * as types from './actionTypes';
import config from "../config";
import { authHeaders } from "../utils/api";

const GATEWAY_URL = config.tdm_gateway_api.uri;

export const fetchNotifications = (reqBody) => async dispatch => {
    try {
        dispatch({ type: types.FETCH_NOTIFICATIONS_LOADING });
        const response = await axios.post(`${GATEWAY_URL}/tdm/notification/notifications/fetch`, reqBody, { headers: authHeaders() });
        dispatch({ type: types.FETCH_NOTIFICATIONS_SUCCESS, payload: response.data});
        return response.data;
    } catch (e) {
        let message;
        if (!e.response) {
          message = "Network connectivity issues, please try again later.";
        } else {
          message = e.response.data.responseDescription;
        }
        dispatch({ type: types.FETCH_NOTIFICATIONS_ERROR });
        return message;
    }
};

export const setSummaryNotifications = (summaryList) => {
  return dispatch => {
      dispatch({ type: types.SET_SUMMARY_NOTIFICATIONS, payload: summaryList });
  }
};

export const setUnreadNotificationCount = (count) => {
  return dispatch => {
    dispatch({ type: types.SET_UNREAD_NOTIFICATION_COUNT, payload: count});
  }
};

export const toggleNotificationReadFlag = (reqBody) => async dispatch => {
  try {
    await axios.put(`${GATEWAY_URL}/tdm/notification/notifications/${reqBody.userId}/${reqBody.id}/${!reqBody.readFlag}`, null, { headers: authHeaders() });
    dispatch({ type: types.TOGGLE_READ_FLAG, payload: reqBody.id});
  } catch (e) {
    let message;
    if (!e.response) {
      message = "Network connectivity issues, please try again later.";
    } else {
      message = e.response.data.responseDescription;
    }
    console.log("Notification ReadFlag Error Msg: " + message);
    return message;
  }
};
export const markAllAsRead = (reqBody) => async dispatch => {
  try {
    return await axios.put(`${GATEWAY_URL}/tdm/notification/notifications/user/mark-all-read`, reqBody, { headers: authHeaders() });
  } catch (e) {
    let message;
    if (!e.response) {
      message = "Network connectivity issues, please try again later.";
    } else {
      message = e.response.data.responseDescription;
    }
    console.log("Notification ReadFlag Error Msg: " + message);
    return message;
  }
};

  export const viewMessages = (payload) => async dispatch => {
    try {
        dispatch({type : types.VIEW_NOTIFICATION_MESSAGES , payload})
    }
    catch (e)
    {
      console.log("Notification viewMessages Error Msg: " ,e);
    }
  }

  export const closeNotificationMessageDetails = () => async dispatch => {
    try{
      dispatch({type : types.CLOSE_NOTIFICATION_MESSAGES})
    }
    catch(e){
      console.log("Error in closeNotificationMessageDetails" ,e)
    }
  }