import React, { Component } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import DualListBox from 'react-dual-listbox';
import Switch from 'react-switch'
import swal from 'sweetalert2'
import { SubHeader } from '../../common/custom/SubHeader';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import styles from './TConnectOnboardModal.css';

class TConnectCapabilityModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contact: {},
            isOpen: false,
            submitting: false,
            selected: [],
            consenting:false,
            legacyBilling:false,
            strategic:false,
        }
    }

    handleChange=(e,fieldName)=>{
        if(fieldName){
            if(fieldName === 'consenting'){
                this.setState({consenting:!this.state.consenting})
            }else if(fieldName ==='legacyBilling'){
                this.setState({legacyBilling:!this.state.legacyBilling})
            }else if(fieldName ==='strategic'){
                this.setState({strategic:!this.state.strategic})
            }
        }
    }

    handleSubmitClick = (e) => {
        e.preventDefault();
        this.setState({ submitting: true })
        const { cidn, name } = this.props.customer;
        const env= this.props.env;
        const { email, firstName, lastName, type } = this.state.contact;
        const request = {
            env : env,
            config:{
                createCaimanSyncEvent:false 
            },
            user: {
                username: email,
                firstName: firstName,
                lastName: lastName,
                admin: type == "Full Authority",
                userServices: this.state.selected.map(service => { return { service: service } })
            },
            customers: [{
                cidn: cidn,
                consenting: this.state.consenting,
                legacyBilling: this.state.legacyBilling,
                strategic: this.state.strategic,
                name: name,
                serviceInstances: this.state.selected.map(service => { return { service: service } })
            }]
        }

        this.props.updateCapabilities(request)
            .then(response => {
                swal.fire({
                    type: 'success',
                    title: 'TConnect - Onboarding',
                    text: 'User capabilities updated successfully! Please check tconnect portal for updates.',
                }).then(() => {
                    this.setState({ isOpen: false })
                })

            })
            .catch(error => {
                swal.fire({
                    type: 'error',
                    title: 'TConnect - Onboarding',
                    text: 'There was an error updating capabilities for user',
                    onOpen: () => {
                        swal.showValidationMessage(error.response.data.message || "Unknown error has occured")
                    }
                }).then(() => {
                    this.setState({ isOpen: false })
                })
            })
            .finally(() => {
                this.setState({ submitting: false })
            })
    }

    open = contact => {
        this.setState({ contact })
        this.toggleModal();
    }

    toggleModal = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    onChange = (selected) => {
        this.setState({ selected });
    };

    render() {
        const { cidn, data } = this.state
        const capabilitiesExist = this.props.capabilities.length > 0;
        return (
            <Modal isOpen={this.state.isOpen} toggle={this.toggleModal} centered className={styles.TConnectOnboardModal}>
                <form onSubmit={(e) => this.handleSubmitClick(e)}>
                    <ModalHeader className="TConModal--header">
                        <SubHeader
                            text="TConnect - Onboard"
                            iconLeft={true}
                            small={true}
                            iconClass="td-icon-sm icon-telstra"
                            iconColor="blue" />
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col><h5>Please select user capabilities</h5></Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                {!capabilitiesExist && <h5>There was an error loading capabilities!</h5>}
                                {capabilitiesExist &&
                                    <DualListBox
                                        options={this.props.capabilities.map(c => { return { label: c, value: c } })}
                                        selected={this.state.selected}
                                        onChange={this.onChange}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="TConModal--data-label">Consenting:
                           <Switch className="react-switch TConModal--switch" onChange={(e) => this.handleChange(e, 'consenting')} checked={this.state.consenting}></Switch>
                                </div>

                                <div className="TConModal--data-label">Legacy Billing:
                            <Switch className="react-switch TConModal--switch" onChange={(e) => this.handleChange(e, 'legacyBilling')} checked={this.state.legacyBilling}></Switch>
                                </div>
                            </Col>
                            <Col>
                                <div className="TConModal--data-label">Strategic:
                            <Switch className="react-switch TConModal--switch" onChange={(e) => this.handleChange(e, 'strategic')} checked={this.state.strategic} ></Switch>
                                </div>

                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="primary" className="submitButton" type="submit" disabled={!capabilitiesExist || this.state.submitting}>{this.state.submitting ? <i className="fa fa-spinner fa-pulse fa-fw"></i> : "Update"}</Button>
                        <Button color="danger" className="cancelButton" disabled={this.state.submitting} onClick={this.toggleModal}>Cancel</Button>
                    </ModalFooter>
                </form >
            </Modal >
        )
    }
}

export default TConnectCapabilityModal