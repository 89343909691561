import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { Field } from "redux-form";
import { renderField } from "../common/fields/fields";
import { fnnValidatorForNewReplicator } from "../common/form/fieldValidators";
import "./Product.css";

export default ({ fields, options, errorMessage, passdownprops }) => {
  const [rowNumber, setRowNumber] = useState(0);
  return (<div>
      <div id="s2p-product-modal-input-head" className="row">
        <div className="col">
          <span>RANGES:</span>
        </div>
        <div className="col">
          <span>
            <Button
              color="link"
              onClick={() => fields.push({ from: null, to: null })}
            >
              <i className="fa fa-plus fa-sm" aria-hidden="true"></i>
            </Button>
          </span>
          <span>
            <Button
              color="link"
              style={{ color: "#dc3545" }}
              onClick={() => fields.pop()}
            >
              <i className="fa fa-minus fa-sm" aria-hidden="true"></i>
            </Button>
          </span>
        </div>
        <div className="col"></div>
      </div>
      <div id="s2p-product-modal-input-head table-responsive" className="row">
      <table className="table table-sm table-borderless">
        <thead className=" thead-light ranges-fnn-head">
          <tr>
        <th  style={{width: "33%", paddingTop: "5px"}} >Service Id:</th>
        <th  style={{width: "33%", paddingTop: "5px"}} >From:</th>
        <th  style={{width: "33%", paddingTop: "5px"}} >To:</th>
        </tr>
        </thead>
    <tbody>
      {fields.map((range, index) => {
        let from = 2 * index + 1,
          to = from + 1;
        return (
            <tr
              key={`rangeEditRow-${index} ${index === rowNumber && "rangeRow"}`}
              onClick={() => {
                setRowNumber(index);
              }}
            >
              <td className={`${index === rowNumber ? "rangeRow" : ""}`}  style={{width: "33%"}}>
                <Field
                  name={`ranges[${index}].serviceId`}
                  customStyles={{
                    overflow: "visible",
                    zIndex: "1"
                  }}
                  component={renderField}
                  validate={fnnValidatorForNewReplicator}
                  invalid={errorMessage}
                  type={"selectWithCustomHeight"}
                  options={options}
                  showValidMsg={false}
                  customDiv="adborFnnTableInputDiv"
                />
              </td>
              <td  style={{width: "33%"}} > 
                <Field
                  name={`ranges[${index}].from`}
                  disabled={passdownprops.asyncValidating}
                  component={renderField}
                  validate={fnnValidatorForNewReplicator}
                  invalid={errorMessage}
                  type={"textWithCustomStyles"}
                  showValidMsg={false}
                  autoComplete="off"
                  customDiv="adborFnnTableInputDiv"
                />
              </td>
              <td  style={{width: "33%"}}>
                <Field
                  name={`ranges[${index}].to`}
                  disabled={passdownprops.asyncValidating}
                  component={renderField}
                  validate={fnnValidatorForNewReplicator}
                  invalid={errorMessage}
                  type={"textWithCustomStyles"}
                  showValidMsg={false}
                  autoComplete="off"
                  customDiv="adborFnnTableInputDiv"
                />
              </td>
            </tr>
  
        );
      })}
      </tbody>
        </table>
    </div>
    </div>
  );
};
