import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
    Divider,
    Tab,
    Tabs,
    AppBar,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  bar: {
    minHeight: "60px",
    backgroundColor: "#ffffff",
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  }
}));
const defaultNumber = 0;

const TabBar = (props) => {
  const{tabId = defaultNumber,  tabIndex=defaultNumber, className} = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(tabId !== 0 && tabIndex !==0){
      setValue(tabIndex);
      props.tabUpdate(tabId || tabIndex)
    }
  },[tabId] )

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" color="default" className={`${classes.bar} ${className}`}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs"
          style={{minHeight:"60px"}}
        >
            {
                props.tabs.map((tab, index) => {
                    return (
                        <Tab key={index} icon={tab.icon} label={tab.name} onClick={()=>props.tabUpdate(tab.id || index)} style={{minHeight:"60px"}}/>
                    );
                })
            }
        </Tabs>
      </AppBar>
      <Divider></Divider>
    </div>
  );
}

export default TabBar;
