import React, {useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import { showSurvey } from "../../actions/surveyAction.js"
import HeaderContent from '../common/Header/HeaderContent';
import './slider.css';
import { fetchPrograms } from "../../actions/ssoAuthActions";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, makeStyles, TextField, Typography, colors, Card, CardContent } from '@material-ui/core';
import { fetchAllTeams } from "../../actions/myTeamsActions";
import { setLocation } from "../../actions/userFeedbackActions";
import { fetchQuarter, fetchMetrics, fetchTrends, setParentProgram, setGraphTeam } from "../../actions/surveyAction";
import SurveyPeriod from './SurveyPeriod.js'
import PasmatMetricCard from './PasmatCard.js'
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccessTime from '@material-ui/icons/AccessTime';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import PasmatRechart from './PasmatScatterMap';
import PasmatTreeMap from './PasmatTreeMap'
import PasmatLineMap from './PasmatLineMap'
import PasmatYearLineGraph from './PasmatYearLineGraph'
import PasmatYTDBarGraph from './PasmatYTDBarGraph'
import PasmatTrendGraph from './PasmatTrendGraph'

const useStyles = makeStyles({

    title: {
        fontSize: 18,
    },
    dataSize: {
        fontSize: 22
    },
    pos: {
        marginBottom: 12,
    },
    avatarGreen: {
        backgroundColor: colors.green[600],
        height: 56,
        width: 56,
        float: "right"
    },
    avatarRed: {
        backgroundColor: colors.red[600],
        height: 56,
        width: 56,
        float: "right"
    },
    avatarBlue: {
        backgroundColor: colors.blue[600],
        height: 56,
        width: 56,
        float: "right"
    },
});

const PasmatTrendAnalysis = (props) => {
    const classes = useStyles();
    const { userId, trendProgram, quarters, trendType, trendAnalysisData, trendTeam, ytdData } = props;
    const [selectedParentProgram, setSelectedParentProgram] = useState("");
    const [selectedParentProgramYTD, setSelectedParentProgramYTD] = useState("");
    const [ytdParentProgram, setYTDParentProgram] = useState([])
    const [ytdParentTeam, setYTDParentTeam] = useState([])
    const [parentProgram, setParent] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState("");
    const [selectedYTDTeam, setSelectedYTDTeam] = useState("");
    const [teamId, setTeamId] = useState("");
    const [periodValue, setPeriodValue] = useState();
    const [teams, setTeams] = useState([]);
    const [graphXData, setGraphXData] = useState([]);
    const [maturityPercentage, setMaturityPErcentage] = useState([]);
    const [effortsSaved, setEffortSaved] = useState([]);
    const [costSaved, setCostSaved] = useState([]);
    const [graphColors, setGraphColors] = useState([]);
    const [graphType, setGraphType] = useState("Programs");
    const [metricData, setMetricData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [graphPeriod, setGraphPeriod] = useState("");
    const [ytdGraphData, setYTDGraphData] = useState([]);

    useEffect(() => {
        props.fetchQuarter().then((response) => {
            if (response !== false) {
                setPeriodValue(response);
                props.fetchTrends("Quarter", response).then((rep) => {
                    let data = rep;
                    let graphPrograms = []; let graphMaturity = [];
                    let graphEfforts = []; let graphCosts = [];
                    let colors = [];
                    let metricData = [];
                    if (data !== undefined && data.length > 0) {
                        {
                            data.map((grPrograms) => {
                                let color = "#" + ((1 << 24) * Math.random() | 16).toString(16);
                                graphPrograms.push(grPrograms.program);
                                graphMaturity.push((grPrograms.maturity_percentage).toPrecision(3));
                                graphEfforts.push(grPrograms.effort_saved); graphCosts.push(grPrograms.cost_saved);
                                colors.push(color)

                            })
                        }
                    }
                    setGraphXData(graphPrograms); setMaturityPErcentage(graphMaturity);
                    setEffortSaved(graphEfforts); setCostSaved(graphCosts);
                    setGraphColors(colors);
                    setParent(graphPrograms);
                    setGraphType("Programs");
                    setMetricData(metricData);
                    setSelectedTeam("");
                    setLoading(false)
                    props.showSurvey(false);
                })
            }
        });
        props.fetchAllTeams();
        props.setLocation("pasmatTrendAnalysis")

    }, []);



    useEffect(() => {
        populateYearToDatePrograms();
    }, [ytdData])

    useEffect(() => {
        populateYearToDatePrograms();
        if (trendType === "Quarter") {
            setSelectedParentProgramYTD("");
            setSelectedYTDTeam("");
        }
    }, [trendType])


    const populateYearToDatePrograms = () => {
        let ytdPrgrms = [];
        if (trendType === "YTD" && ytdData !== undefined && ytdData.period !== undefined) {
            ytdData.period.map((periods) => {
                periods.trend_analysis_data.map((trend) => {
                    ytdPrgrms.push(trend.program)
                })
            })
        }
        const yearToDatePrograms = ytdPrgrms.filter((val, id, array) => array.indexOf(val) == id);
        setYTDParentProgram(yearToDatePrograms);
        let yearToDateParentGraph = generateYearToDateParentGraph();
        setYTDGraphData(yearToDateParentGraph);
    }
    const generateYearToDateParentGraph = () => {
        let period = [];
        var quarter = "";
        if (trendType === "YTD" && ytdData !== undefined && ytdData.period !== undefined) {
            ytdData.period.forEach((element,index) => {
                quarter = element.value;
                let color = "#" + ((1 << 24) * Math.random() | 16).toString(16);
                let color2 = "#" + ((1 << 24) * Math.random() | 16).toString(16);
                var data = [];
                element.trend_analysis_data.forEach(trend => {
                    var obj = {trendItem: trend.program, 
                        value: trend.maturity_percentage,
                        effort: trend.effort_saved*index,
                        cost: trend.cost_saved }
                    data.push(obj);
                });
                period.push({ period: quarter, data: data, color: color,color2: color2 })
            });
        }
        return period

    }


    useEffect(() => {
        if (trendProgram && trendProgram.program !== undefined && trendProgram.teams !== undefined) {
            setSelectedParentProgram(trendProgram.program)
            let graphTeams = []; let graphMaturity = [];
            let graphEfforts = []; let graphCosts = [];
            let colors = []; let metricData = [];

            metricData.push({
                'maturity_percentage': trendProgram.maturity_percentage,
                'effort_saved': trendProgram.effort_saved,
                'cost_saved': trendProgram.cost_saved,
                'total_users': trendProgram.total_users
            })

            trendProgram.teams.map((team) => {
                let color = "#" + ((1 << 24) * Math.random() | 16).toString(16);
                graphTeams.push(team.team_name);
                if (team.trend_analysis_metrics) {
                    graphMaturity.push((team.trend_analysis_metrics.maturity_percentage).toPrecision(3));
                    graphEfforts.push(team.trend_analysis_metrics.effort_saved);
                    graphCosts.push(team.trend_analysis_metrics.cost_saved);
                }
                colors.push(color)
            })
            setTeams(graphTeams);
            setGraphXData(graphTeams); setMaturityPErcentage(graphMaturity);
            setEffortSaved(graphEfforts); setCostSaved(graphCosts); setGraphColors(colors);
            setGraphType("Teams");
            setMetricData(metricData);
            props.showSurvey(true);
        }
    }, [trendProgram])

    useEffect(() => {
        let metricData = [];
        if (trendTeam !== undefined) {
            if (trendTeam.team_name !== undefined) {
                metricData.push({
                    'maturity_percentage': trendTeam.trend_analysis_metrics.maturity_percentage,
                    'effort_saved': trendTeam.trend_analysis_metrics.effort_saved,
                    'cost_saved': trendTeam.trend_analysis_metrics.cost_saved,
                    'total_users': trendTeam.total_users
                })

                setSelectedTeam(trendTeam.team_name);
                setTeamId(trendTeam.team_id);
                setMetricData(metricData);
                props.showSurvey(true);
            }
        }

    }, [trendTeam])

    useEffect(() => {
        if (selectedParentProgram === null)
            clearData();
    }, [selectedParentProgram])

    useEffect(() => {
        if (selectedTeam === null) {
            let programSelected = props.trendAnalysisData.filter((trends) => trends.program === selectedParentProgram)
            props.setParentProgram(programSelected[0]);
            setGraphData();
        }
    }, [selectedTeam])

    const handlePeriod = (value) => 
    {
        setLoading(true);
        if(value === "YTD")
        {props.fetchTrends(value , "2021").then((response)=>
        {setLoading(false)})}
        else
        {props.fetchTrends(value , periodValue).then((response)=>
        {setLoading(false)})}
    }
    const setGraphData = () => {
        let graphPrograms = []; let graphMaturity = [];
        let graphEfforts = []; let graphCosts = [];
        let colors = [];
        let metricData = [];
        if (trendAnalysisData !== undefined && trendAnalysisData.length > 0) {
            {
                trendAnalysisData.map((grPrograms) => {
                    let color = "#" + ((1 << 24) * Math.random() | 16).toString(16);
                    graphPrograms.push(grPrograms.program); graphMaturity.push(grPrograms.maturity_percentage);
                    graphEfforts.push(grPrograms.effort_saved); graphCosts.push(grPrograms.cost_saved);
                    colors.push(color)

                })
            }
        }
        setGraphXData(graphPrograms); setMaturityPErcentage(graphMaturity);
        setEffortSaved(graphEfforts); setCostSaved(graphCosts);
        setGraphColors(colors);
        setParent(graphPrograms);
        setGraphType("Programs");
        setMetricData(metricData);
        props.showSurvey(false)
    }

    const getSelectedParentProgram = (e, value) => {
        let graphTeams = []; let graphMaturity = [];
        let graphEfforts = []; let graphCosts = [];
        let colors = [];
        let metricData = [];

        if (trendAnalysisData !== undefined) {
            trendAnalysisData.map((trendData) => {
                if (trendData.program === value) {
                    metricData.push({
                        'maturity_percentage': trendData.maturity_percentage,
                        'effort_saved': trendData.effort_saved,
                        'cost_saved': trendData.cost_saved,
                        'total_users': trendData.total_users
                    })

                    trendData.teams.map((team) => {
                        let color = "#" + ((1 << 24) * Math.random() | 16).toString(16);
                        graphTeams.push(team.team_name);
                        graphMaturity.push((team.trend_analysis_metrics.maturity_percentage).toPrecision(3));
                        graphEfforts.push(team.trend_analysis_metrics.effort_saved);
                        graphCosts.push(team.trend_analysis_metrics.cost_saved)
                        colors.push(color);
                    })
                }
            })
        }
        setSelectedParentProgram(value);
        setTeams(graphTeams);
        setGraphXData(graphTeams); setMaturityPErcentage(graphMaturity);
        setEffortSaved(graphEfforts); setCostSaved(graphCosts); setGraphColors(colors);
        setGraphType("Teams");
        setMetricData(metricData)

    }

    const setSelectedYtdProgram = (e, value) => {
        let ytdTeams = [];
        let yearToDateGraph = [];
        if (trendType === "YTD" && ytdData !== undefined && ytdData.period !== undefined) {
            ytdData.period.map((periods) => {
                periods.trend_analysis_data.map((trend) => {
                    if (trend.program === value) {
                        ytdTeams.push(trend.teams)
                    }
                })
            })
        }
        yearToDateGraph = generateYearToDateParentGraph();
        if(value !== null)
        setSelectedParentProgramYTD(value);
        else
        {
            setSelectedParentProgramYTD("");
        }
        setSelectedYTDTeam("");
        setYTDParentTeam(ytdTeams[0])
        setYTDGraphData(yearToDateGraph);
    }
    const getSelectedYTDTeam = (e, value) => {
        let yearToDateTeamGraph = [];
        if (trendType === "YTD" && ytdData !== undefined && ytdData.period !== undefined) {
            if (value !== undefined && value !== null && value !== "")
                yearToDateTeamGraph = generateYearToDateTeamGraph(value);
            else
                yearToDateTeamGraph = generateYearToDateParentGraph();
        }
        setYTDGraphData(yearToDateTeamGraph);
        setSelectedYTDTeam(value);
    }

    const generateYearToDateTeamGraph = (teams) => {

        let period = [];
        let quarter = "";
        let teamName = teams.team_name;
        var data = [];
        let randomcolor = "#" + ((1 << 24) * Math.random() | 16).toString(16);
        let color = [];
        color.push(randomcolor)
        if (trendType === "YTD" && ytdData !== undefined && ytdData.period !== undefined) {
            ytdData.period.forEach(element => {
                quarter = element.value;
                element.trend_analysis_data.forEach(trend => {
                    let teamYtd = trend.teams.filter((tt) => tt.team_name === teamName)
                    if (trend.program === selectedParentProgramYTD) {
                        teamYtd.forEach((ytDTeam) => {
                            let obj = {};
                            obj = { trendItem: quarter, 
                                value: ytDTeam.trend_analysis_metrics.maturity_percentage,
                                effort: ytDTeam.trend_analysis_metrics.effort_saved,
                                cost: ytDTeam.trend_analysis_metrics.cost_saved }
                            data.push(obj);
                        })
                    }
                });
            });
            period.push({ period: teamName, data: data, color: color })
        }
        return period
    }
    const getSelectedTeam = (e, value) => {
        let teamSelected = [];
        let metricData = [];
        trendAnalysisData.map((trendData) => {
            if (trendData.program === selectedParentProgram) {
                if (value !== null && value !== undefined) {
                    teamSelected = trendData.teams.filter((team) => team.team_name === value)
                    console.log("trendData")
                    console.log(teamSelected[0])
                }
            }
        })
        if (teamSelected[0] !== undefined && teamSelected[0].team_id)
            {
                metricData.push({
                    'maturity_percentage': teamSelected[0].trend_analysis_metrics.maturity_percentage,
                    'effort_saved': teamSelected[0].trend_analysis_metrics.effort_saved,
                    'cost_saved': teamSelected[0].trend_analysis_metrics.cost_saved,
                    'total_users': teamSelected[0].total_users
                })
                setTeamId(teamSelected[0].team_id);
                setMetricData(metricData)
            }
        else { setGraphData(); }
        setSelectedTeam(value);
        if (teamSelected[0] !== undefined)
            props.setGraphTeam(props.setGraphTeam(teamSelected[0]))

    }
    const handleChange = (e) => {
        setPeriodValue(e.target.value)
        setLoading(true);
        setMetricData([]);
        props.fetchTrends(trendType, e.target.value).then((response) => {
            if (trendType === "Quarter") {
                let data = response;
                if(selectedParentProgram == "" && selectedTeam == "")
                {
                let graphPrograms = []; let graphMaturity = [];
                let graphEfforts = []; let graphCosts = [];
                let colors = [];
                let metricData = [];
                if (data !== undefined && data.length > 0) {
                    {
                        data.map((grPrograms) => {
                            let color = "#" + ((1 << 24) * Math.random() | 16).toString(16);
                            graphPrograms.push(grPrograms.program);
                            graphMaturity.push((grPrograms.maturity_percentage).toPrecision(3));
                            graphEfforts.push(grPrograms.effort_saved); graphCosts.push(grPrograms.cost_saved);
                            colors.push(color)

                        })
                    }
                }
                setGraphXData(graphPrograms); setMaturityPErcentage(graphMaturity);
                setEffortSaved(graphEfforts); setCostSaved(graphCosts);
                setGraphColors(colors);
                setParent(graphPrograms);
                setGraphType("Programs");
                setMetricData(metricData);
                setSelectedTeam("");
                setLoading(false)
                props.showSurvey(false);}
            else
            {
                    if(selectedParentProgram !== "" && selectedTeam == "")
                    {
                        let graphTeams = []; let graphMaturity = [];
                        let graphEfforts = []; let graphCosts = [];
                        let colors = [];
                        let metricData = [];
                        if (data !== undefined) {
                            data.map((trendData) => {
                                if (trendData.program === selectedParentProgram) {
                                    metricData.push({
                                        'maturity_percentage': trendData.maturity_percentage,
                                        'effort_saved': trendData.effort_saved,
                                        'cost_saved': trendData.cost_saved,
                                        'total_users': trendData.total_users
                                    })
                
                                    trendData.teams.map((team) => {
                                        let color = "#" + ((1 << 24) * Math.random() | 16).toString(16);
                                        graphTeams.push(team.team_name);
                                        graphMaturity.push((team.trend_analysis_metrics.maturity_percentage).toPrecision(3));
                                        graphEfforts.push(team.trend_analysis_metrics.effort_saved);
                                        graphCosts.push(team.trend_analysis_metrics.cost_saved)
                                        colors.push(color);
                                    })
                                }
                            })
                        }
                        setSelectedParentProgram(selectedParentProgram);
                        setTeams(graphTeams);
                        setGraphXData(graphTeams); setMaturityPErcentage(graphMaturity);
                        setEffortSaved(graphEfforts); setCostSaved(graphCosts); setGraphColors(colors);
                        setGraphType("Teams");
                        setMetricData(metricData);
                        setLoading(false)
                        props.showSurvey(false);
                    }
                    else
                    {
                        let teamSelected = [];
                        let metricData = [];
                        data.map((trendData) => {
                            if (trendData.program === selectedParentProgram) {
                                if (selectedTeam !== null && selectedTeam !== undefined) {
                                    teamSelected = trendData.teams.filter((team) => team.team_name === selectedTeam)
                             }
                            }
                        })
                        if (teamSelected[0] !== undefined && teamSelected[0].team_id)
                            {
                                metricData.push({
                                    'maturity_percentage': teamSelected[0].trend_analysis_metrics.maturity_percentage,
                                    'effort_saved': teamSelected[0].trend_analysis_metrics.effort_saved,
                                    'cost_saved': teamSelected[0].trend_analysis_metrics.cost_saved,
                                    'total_users': teamSelected[0].total_users
                                })
                                setTeamId(teamSelected[0].team_id);
                                setMetricData(metricData)
                            }
                        else { setGraphData(); }
                        setSelectedTeam(selectedTeam);
                        if (teamSelected[0] !== undefined)
                           { props.setGraphTeam(props.setGraphTeam(teamSelected[0]))
                            setLoading(false)
                            props.showSurvey(false);}
                    }
            }
        }
        })
    }
    const clearData = (e) => {
        let yearToDateGraph = [];
        yearToDateGraph = generateYearToDateParentGraph();
        setGraphData();
        setSelectedParentProgram("");
        setSelectedTeam("");
        setTeams([]);
        setTeamId();
        setGraphType("Programs");
        setSelectedParentProgramYTD("");
        setSelectedYTDTeam("");
        props.setParentProgram([]);
        props.setGraphTeam([]);
        props.showSurvey(false);
        setYTDGraphData(yearToDateGraph);
    }
    return (
        <div className="white">
            <div className="pasmatHeader mb-4">
                <HeaderContent
                    title={`Product Adoption Self-Maturity Assessment Report (PASMAT)`}
                    icon="handshake-o"
                    loading={loading}
                    isPasmat={true}
                    extraComponentClassName="survey-result"
                    loadingText="Loading your assessment values..."></HeaderContent></div>
            <div className="row">
                {trendType === "Quarter" ? (<div className="col-3">
                    <Autocomplete
                        id="parentProgram"
                        options={parentProgram}
                        className="parentProgram"
                        getOptionLabel={(option) => option}
                        value={selectedParentProgram}
                        placeholder="Enter parent program"
                        onChange={(event, value) => getSelectedParentProgram(event, value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="Enter parent program"
                            />
                        )}
                    /></div>) : (
                    <div className="col-3">
                        <Autocomplete
                            id="parentProgram"
                            options={ytdParentProgram}
                            className="parentProgram"
                            getOptionLabel={(option) => option}
                            value={selectedParentProgramYTD}
                            onChange={(event, value) => setSelectedYtdProgram(event, value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Enter program name"
                                />
                            )}
                        /></div>

                )}
                <div className="col-3">
                    {trendType === "Quarter" ?
                        (<Autocomplete
                            options={teams}
                            id="teamsDropdown"
                            className="parentProgram"
                            getOptionLabel={(option) => option}
                            value={selectedTeam}
                            onChange={(event, value) => getSelectedTeam(event, value)}
                            placeholder="Enter team name"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Enter team name"
                                    id={params.id}
                                />
                            )}
                        />) : (<Autocomplete
                            options={ytdParentTeam}
                            id="teamsDropdown"
                            className="parentProgram"
                            getOptionLabel={(option) => option.team_name}
                            value={selectedYTDTeam}
                            onChange={(event, value) => getSelectedYTDTeam(event, value)}
                            placeholder="Enter team name"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder="Enter Team name"
                                    id={params.id}
                                />
                            )}
                        />)}
                </div>
                <div class="col-3 mt-1">

                    <Button variant="contained" color="primary" onClick={e => clearData()}>
                        Clear
                    </Button>
                </div>
                <div class="col-3 mt-n2">

                    {periodValue !== undefined && <SurveyPeriod quarterValue={periodValue}
                        value={quarters} handleQuarterChange={handleChange}
                        handlePeriod={handlePeriod}
                        float="float-right"
                        pr="pr-0" />}
                </div>
            </div>
            {trendType === "Quarter" && metricData && metricData[0] && metricData[0].maturity_percentage !== undefined &&
                metricData[0].effort_saved !== undefined &&
                metricData[0].cost_saved !== undefined &&
                metricData[0].total_users !== undefined && <Card className="mt-5 pasmatMetricCard">
                    <CardContent>
                        <div className="row pl-2">
                            <div className="col-5"></div>
                            <div className="col-7">
                                {selectedTeam ? (<Typography>
                                    Report Metrics - Team {selectedTeam}
                                </Typography>) : (<Typography>
                                    Report Metrics - Program {selectedParentProgram}
                                </Typography>)}
                            </div> </div>
                        <div className="row pl-2">

                            <div className="col-3">
                                <PasmatMetricCard icon={PeopleIcon} title="Total Users"
                                    avatar={classes.avatarGreen} data={metricData[0].total_users} /></div>
                            <div className="col-3">
                                <PasmatMetricCard icon={DonutLargeIcon} title="Maturity Level"
                                    avatar={classes.avatarBlue} data={(metricData[0].maturity_percentage) + "%"}
                                    linear={true} linearData={(metricData[0].maturity_percentage)} />
                            </div>
                            <div className="col-3">
                                <PasmatMetricCard icon={AccessTime} title="Efforts Saved"
                                    avatar={classes.avatarRed} data={(metricData[0].effort_saved).toPrecision(2) + " FTE"} /></div>
                            <div className="col-3">
                                <PasmatMetricCard icon={AttachMoneyIcon} title="Cost Saved"
                                    avatar={classes.avatarBlue} data={metricData[0].cost_saved + "$"} />
                            </div>

                        </div>
                    </CardContent></Card>}
            <Card>
                <CardContent>
                    <div className="row pl-2">
                        <div className="col-1"></div>
                        <div className="col-10">
                            {(!loading && trendType === "Quarter" && graphXData.length > 0 && maturityPercentage.length > 0 &&
                                graphColors.length > 0) ? (<PasmatRechart xData={graphXData} yData={maturityPercentage}
                                    label="Maturity Percentage(%)"
                                    colors={graphColors}
                                    graphName="Adoption Maturity"
                                    graphtype={graphType} />) : (<Fragment>
                                        {!loading && ytdData !== undefined && ytdGraphData.length > 0 &&
                                            ytdData.period !== undefined &&
                                            <PasmatYearLineGraph 
                                            ytdGraphData={ytdGraphData} 
                                            graphName="Adoption Maturity" />} </Fragment>
                            )}   <div className="col-1"></div>
                        </div></div>


                    <div className="row pl-2">
                        <div className="col-1"></div>
                        <div className="col-10">
                            {!loading && trendType === "Quarter" && graphXData.length > 0 && effortsSaved.length > 0 &&
                                graphColors.length > 0 ?
                                <PasmatLineMap xData={graphXData} yData={effortsSaved}
                                    label="Efforts Saved"
                                    colors={graphColors}
                                    graphName="Effort Assesment"
                                    graphtype={graphType} /> : (
                                        <Fragment>
                                            
                                            {!loading && ytdData !== undefined && ytdGraphData.length > 0 &&
                                            ytdData.period !== undefined &&
                                            <PasmatYTDBarGraph ytdGraphData={ytdGraphData} 
                                            graphName="Cost Assesment" 
                                            yData="cost"
                                            label="Cost Assesment ($)"/>
                            }
                                        </Fragment>
                                    )}</div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row pl-2">
                        <div className="col-1"></div>
                        <div className="col-10">
                            {!loading && trendType === "Quarter" && graphXData.length > 0 && costSaved.length > 0 &&
                                graphColors.length > 0 ? <PasmatTrendGraph xData={graphXData} yData={costSaved}
                                    label="Cost Saved"
                                    colors={graphColors}
                                    graphName="Cost Assesment"
                                    graphtype={graphType} /> : (
                                        <Fragment>
                                            {!loading && ytdData !== undefined && ytdGraphData.length > 0 &&
                                            ytdData.period !== undefined &&
                                            <PasmatYTDBarGraph ytdGraphData={ytdGraphData} 
                                            graphName="Effort Assesment" 
                                            yData="effort"
                                            label="Effort Assesment-FTE"/>
                            }
                                        </Fragment>
                                    )}</div> <div className="col-1"></div>
                    </div></CardContent></Card>




        </div>);
}

const mapStateToProps = (state) => {
    return {
        myAvailableTeams: state.teams.teams.allTeams,
        parentProgram: state.pasmatTrend.parentProgram.teamParentList,
        userId: state.auth.userName,
        quarters: state.pasmatTrend.quarters,
        qualmetric: state.pasmatTrend.qualmetric,
        trendAnalysisData: state.pasmatTrend.trendAnalysisData,
        trendProgram: state.pasmatTrend.trendProgram,
        trendTeam: state.pasmatTrend.trendTeam,
        trendType: state.pasmatTrend.trendType,
        ytdData: state.pasmatTrend.ytdData,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchQuarter: () => dispatch(fetchQuarter()),
        fetchPrograms: () => dispatch(fetchPrograms()),
        fetchAllTeams: () => dispatch(fetchAllTeams()),
        fetchMetrics: (userId, teamId, period) => dispatch(fetchMetrics(userId, teamId, period)),
        showSurvey: (value) => dispatch(showSurvey(value)),
        fetchTrends: (period, quarter) => dispatch(fetchTrends(period, quarter)),
        setParentProgram: (data) => dispatch(setParentProgram(data)),
        setGraphTeam: (data) => dispatch(setGraphTeam(data)),
        setLocation:(path) => dispatch(setLocation(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasmatTrendAnalysis);