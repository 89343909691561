import React from "react";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useTextStyle = makeStyles({
  root: {
    maxWidth: "150px",
  },
});

const defaultText = "";

const NoOverflowText = (props) => {
  const { children = defaultText, ...rest } = props;

  const textStyle = useTextStyle();

  return (
    <Typography classes={textStyle} display="block" noWrap={true} {...rest}>
      {children}
    </Typography>
  );
};

export default NoOverflowText;
