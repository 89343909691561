import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Table, Row, Col, Card, CardBody, CardHeader, CardTitle, Button, CardFooter, CardText } from 'reactstrap';
export default (props) => {
    const { stateData } = props.location;
    if (!stateData) {
        return (<Redirect to='/' />);
    }
    else {
        const {status, responseDescription, responseConf} = stateData;
        const {data} = responseConf;

        const mailStr=`mailto:cc6fb024.teamtelstra.onmicrosoft.com@amer.teams.ms?Subject=Salesforce Error Details&body=Request:${data}  Response:${responseDescription}`;
        return (
            <div style={{ color: 'inherit', background: '#fffffff2' }} className="landing-box nav-glass">
                <Row>
                    <Col lg='6'><img style={{ width: '100%', height: '100%' }} src='/images/errorImage.png' /></Col>
                    <Col lg='6'><Card>
                        <CardHeader><h3>Ooops Sorry</h3></CardHeader>
                        <CardBody>
                            <CardTitle>{status}</CardTitle>
                            <CardText>{responseDescription}</CardText>
                            <Link to='/dashboard'><Button>Go Home Start Checking out</Button></Link>
                        </CardBody>
                        <CardFooter>
                            <a href={mailStr}>Report Team</a>
                        </CardFooter>
                    </Card></Col>

                </Row>
            </div>
        )
    }

    ;
}