import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { reduxForm, Field } from 'redux-form';
import { renderField } from '../../common/fields/fields';
import { compose } from 'redux';
import { tagIdValidator as validate } from '../../common/form/syncValidators';
import { fetchAddressDetails, resetAddressDetails } from '../../../actions/utilityActions';
import ReactJson from "react-json-view";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
class AddressLookup extends Component {

    handleSubmit = async (formValues) => {
        this.props.fetchAddressDetails(formValues.tagid, formValues.filter);
    }

    componentDidMount = () => {
        if (this.props.tagID) {
            this.props.fetchAddressDetails(this.props.tagID);
        }
    }

    render() {
        const { tagID = null} = this.props;
        const filterOptions = [
            { value: 'twoLineAUPost', label: 'twoLineAUPost' },
            { value: 'structured', label: 'structured' }
        ]
        return (
            <div className="utility-input-area">
                <div className="utility-input-area-content">
                        <div>
                        {tagID === null &&
                            <form action='#' onSubmit={(event) => {
                                event.preventDefault();
                                this.handleSubmit(this.props.formValues);
                                return false;
                            }}>
                                <div>
                                    <Row>
                                        <Col xs="2">
                                            <Field
                                                label="Tag ID"
                                                name="tagid"
                                                tabIndex="1"
                                                component={renderField}
                                                autoComplete="off"
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6">
                                            <Field
                                                label="Filter"
                                                name="filter"
                                                type="select"
                                                component={renderField}
                                                options={filterOptions}
                                                isMulti={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="2">
                                            <Button
                                                color={this.props.tagIdError ? 'secondary' : 'success'}
                                                disabled={this.props.tagIdError ? true : false}
                                                tabIndex="4"
                                                className="submitButton"
                                                type="submit"
                                            >
                                                Search
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        }
                        {this.props.address.isRetrieveSuccess &&
                        <div>
                            <div style={{"height":"75px"}}>
                                <div style={{"float":"left"}}>
                                    <h2>Address Records</h2>
                                </div>
                                <div style={{"float":"right", "padding-top":"10px"}}>
                                    <Tooltip title="Download Json">
                                        <span className="base-purple">
                                            <a
                                                href={`data: text/json;charset=utf-8, ${encodeURIComponent(JSON.stringify(this.props.address.response.data.data, null, '\t'))}`}
                                                download={`${this.props.tagID}.json`}>
                                                <i className={"fa fa-file-code-o fa-2x theme-text-primary"} aria-hidden="true"></i>
                                            </a>
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                            <div>
                                <Row>
                                    <Col>
                                        <ReactJson src={this.props.address.response.data.data} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        }
                        {this.props.address.isRetrieveFailed &&
                            <div>
                                {this.props.address.response}
                            </div>

                        }
                        {this.props.address.isRetrievePending &&
                            <CircularProgress style={{ display: 'block', height: '50px', width: '50px', margin: 'auto', marginTop: '20px' }} color={'primary'}/>
                        }
                        </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        tagIdError: state.form && state.form.utility && state.form.utility.syncErrors && state.form.utility.syncErrors.tagid,
        formValues: state.form && state.form.utility && state.form.utility.values,
        address: state.utility.address,
        sidePanelEnvironment: state.auth.sidePanelEnvironment,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchAddressDetails: (tagID, filter) => dispatch(fetchAddressDetails(tagID, filter)),
        resetAddressDetails: () => dispatch(resetAddressDetails())
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), reduxForm({
    validate,
    form: 'utility'
}))(AddressLookup)