import React, {Component} from 'react'
import {connect} from 'react-redux';
import HeaderContent from '../common/Header/HeaderContent';
import './userbadges.css'
import { setLocation } from "../../actions/userFeedbackActions";

import {
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText,
    Jumbotron,
    Button
} from 'reactstrap';
import Moment from 'react-moment';
import moment from 'moment';
import UserProfileStats from './UserProfileStats';
import LinkButton from '../common/custom/LinkButton';
import GenerateToken from './GenerateToken';
import { is1TdmUser } from '../../utils/userProfile';

class UserProfile extends Component {
    componentDidMount() {
    this.props.setLocation("UserProfile")
    }
    render() {
        const {
            auth,
            userTaasMetaData
        } = this.props;

        if (!auth) 
            return <div></div>;
        return (
            <div className="white">
                <HeaderContent
                    title={`${auth.name?.replace(/,(?=[^\s])/g, ", ")}'s User Profile`}
                    image={this.props.badge}
                    icon="user"
                    loading={false}
                    tooltip={this.props.tooltip}
                    empty={true}
                    loadingText="Loading your user profile..."></HeaderContent>
                {(<Row>
                    <Col xs="8">
                        <Row>
                            <Col xs="12">
                                <ListGroup style={{"width": "100%", "wordBreak": "break-word"}}>
                                    <ListGroupItem>
                                        <ListGroupItemHeading>Username</ListGroupItemHeading>
                                        <ListGroupItemText>{auth.userName}</ListGroupItemText>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <ListGroupItemHeading>Name</ListGroupItemHeading>
                                        <ListGroupItemText>{auth.name?.replace(/,(?=[^\s])/g, ", ")}</ListGroupItemText>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <ListGroupItemHeading>Email Address</ListGroupItemHeading>
                                        <ListGroupItemText>{auth.user.email}</ListGroupItemText>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <ListGroupItemHeading>Team ID</ListGroupItemHeading>
                                        <ListGroupItemText>{auth.team}</ListGroupItemText>
                                    </ListGroupItem>
                                    <ListGroupItem>
                                        <ListGroupItemHeading>Team Name</ListGroupItemHeading>
                                        <ListGroupItemText>{auth.teamName}</ListGroupItemText>
                                    </ListGroupItem>
                                    { 
                                        userTaasMetaData?.data?.metaData?.oldestLoginDate &&
                                        <ListGroupItem>
                                            <ListGroupItemHeading>Date of Joining</ListGroupItemHeading>
                                            <ListGroupItemText>
                                                { 
                                                    moment.unix(userTaasMetaData?.data?.metaData?.oldestLoginDate).format("YYYY-MM-DD")
                                                }
                                            </ListGroupItemText>
                                        </ListGroupItem>
                                    }
                                    {
                                        userTaasMetaData?.data?.metaData?.prevLoginDate &&
                                        <ListGroupItem>
                                            <ListGroupItemHeading>Last Activity</ListGroupItemHeading>
                                            <ListGroupItemText>
                                                {
                                                    <Moment fromNow>
                                                        {moment.unix(userTaasMetaData?.data?.metaData?.prevLoginDate).format("YYYY-MM-DD HH:mm:ss")}
                                                    </Moment>
                                                }
                                            </ListGroupItemText>
                                        </ListGroupItem>
                                    }
                                </ListGroup>
                            </Col>
                        </Row>
                        {is1TdmUser() && <GenerateToken userId={auth.userName} teamId={auth.team}/>}
                    </Col>
                    {/* <Col xs="6">
                        {(Object.keys(profile.checkoutsTotalByMonth).length > 0)
                            ? <UserProfileStats profile={profile}/>
                            : <Jumbotron>
                                <h2 className="display-3">Checkout Data</h2>
                                <p className="lead">Each piece of test data is assigned to a specific user once checked out, this guarantees the data received isn't going to be used by anyone else.</p>
                                <hr className="my-2"/>
                                <p>Once you checkout some test data, statistics will appear here!</p>
                                <p className="lead">
                                    <LinkButton to="/data" className="btn btn-info"><i className="fa fa-cloud-download"/> Vacant Data</LinkButton>
                                </p>
                            </Jumbotron>
}
                    </Col> */}
                    
                </Row>)}

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth,
        dNumber: state.auth.userName,
        badge: state.trainingBadges.badge,
        tooltip: state.trainingBadges.tooltip,
        userTaasMetaData: state.users.userTaasMetaData
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setLocation: (path) => dispatch(setLocation(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);