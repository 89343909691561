import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useTextStyle = makeStyles({
  root: (props) => ({
    textTransform: props.uppercase ? "uppercase" : null,
    '&::first-letter': {
      textTransform: "capitalize"
    }
  })
});

const defaultText = "";
const defaultVariant = "h4";
const defaultUppercase = false;

const Title = (props) => {
  const {
    children = defaultText,
    variant = defaultVariant,
    uppercase = defaultUppercase, // eslint-disable-line no-unused-vars
    ...rest
  } = props;

  const textStyle = useTextStyle(props);

  return (
    <Typography variant={variant} classes={textStyle} {...rest}>
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uppercase: PropTypes.bool,
};

export default Title;
