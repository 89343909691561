import React from 'react';
import { Table, Row, Col, Button } from 'reactstrap';

import { DownloadTile, JSONDownloadTile } from '../../tiles/DownloadTile';
import { CSVDownloadTile } from '../../tiles/CSVDownloadTile';
import { SubHeader } from '../../../common/custom/SubHeader';
import swal from 'sweetalert2';
const getDOB= (customerItem=[]) => {
    if(customerItem.hasOwnProperty('dateOfBirth'))
    return customerItem.dateOfBirth.split('/').reverse().join('-')

    return '-'
}
export const DataViewOrder = (props) => {
    const { orderItems, filename, customerItems, showTdiBtn, tdiBtnDisabled, tdiRegisterHandler, tdiErrorMessage, tdiRegisterLoading, tdiCheckOutLoading } = props;
    const { firstName='-', lastName='-', email='-' } = customerItems && customerItems.length > 0 ? customerItems[0] : {} ;
    const dob = customerItems && getDOB(customerItems[0]);
    const { billingAccountId='-' } = orderItems && orderItems.length > 0 ? orderItems[0] : {} ;
    const isMICAOrder = filename.includes("MICA");
    const handleRegisterClick = () => (swal({
        title: 'Register TDI',
        text: `Do you want to link your Telstra Digital Identity(TDI) with this Service`,
        type: "question",
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancelButton: true,
        showCloseButton: true
    }).then((result) => {
        console.log(result);
        const requestFormProps = {
            firstName,
            lastName,
            email,
            dob,
            type: "BAN",
            value: billingAccountId
        }
        if (result.value) {
            tdiRegisterHandler({ ...requestFormProps, accountLinking: true });
        }
        else if (result.dismiss === "cancel") {
            tdiRegisterHandler({ ...requestFormProps, accountLinking: false });
        }
    }
    ));
    console.log('the props of order are', props);
    return (
        <React.Fragment>
            <SubHeader text={"Orders"} iconClass={"td-icon-md icon-shop-online"} data={orderItems} iconColor={"purple"} filename={`${filename}-ORDER`} />
            <Table>
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Customer ID</th>
                        {isMICAOrder && <th>Plan</th>}
                        <th>Billing Account ID</th>
                        {showTdiBtn &&<th
                            style={{
                                "width": "20%"
                            }}><i class="fa fa-address-card-o theme-text-primary" aria-hidden="true"></i>{'   '}
                            TDI{' '}
                        </th>}
                    </tr>
                </thead>
                <tbody>
                    {orderItems.map((item) => {
                        return (
                            <tr>
                                <td scope="row">{item.orderId}</td>
                                <td>{item.customerId}</td>
                                {isMICAOrder && <td>{item.plan}</td>}
                                <td>{item.billingAccountId}</td>
                                {showTdiBtn && (<td>{(<Button className={tdiBtnDisabled ? 'disabled': 'tdiRegisterBtn'} onClick={handleRegisterClick} style={{
                                    "width": "100%"
                                }}>
                                    Register TDI
                                        {tdiRegisterLoading ? (<div class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>) : ''}
                                    {tdiCheckOutLoading ? (<div class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Checking for Existing TDI...</span>
                                    </div>) : ''}
                                </Button>)}</td>)}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            {tdiErrorMessage != '' && (<p className="text-danger" style={{ "color": 'red' }}>{tdiErrorMessage}</p>)}
        </React.Fragment>
    )
}