import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { TextField } from '@material-ui/core';
import { findSiebelCustomer,updateUserIdentity } from '../../../actions/utilityActions';
import { isEmail } from 'validator';
import "./CustomerConsole.css";
import swal from 'sweetalert2';
import { displayLoading, displaySuccess } from "../../common/alerts/alerts";

class CustomerMailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerMail: '',
            errorMsg: '',
            disableSubmit: true,
        };
    }

    validateEmail = (e) => {
        var err = '';
        if(!isEmail(e)){
            err = 'Invalid email ID'
        }
        this.setState({
            ...this.state,
            customerMail: e,
            errorMsg: err
        })
    }

    updateIdentity = () =>{
        const customerId = this.props.siebelCustomer.customer.customerID;
        const RCRMContactId = this.props.siebelCustomer.selectedRow.contactId;
        const selectedEnv = this.props.selectedEnvValue;
        const username = this.state.customerMail;
        displayLoading({ title: "Update Identity", bodyText: "Please standby while we update Identity" })
        const identityUpdateData = {"customerId" : customerId,"contactId":RCRMContactId,"username":username,"environment": selectedEnv}
        this.props.updateUserIdentity(identityUpdateData).then((result) => {
            swal.close();
        });
        this.props.closeModal();
    }
    findSiebelCustomerInfo = (e, value) => {
        e.preventDefault();
        let request = value + '/' + this.props.selectedEnvValue
        this.props.findSiebelCustomer(request)
    }

    render() {
        return (
                <Modal isOpen={this.props.showModal} className="customerMailModal">
                <div className="modalcontent">
                    <Fragment>
                        <ModalHeader style={{'justifyContent':'center'}}>
                            <strong>Enter the Username registered</strong>
                        </ModalHeader>
                        <ModalBody className="modalbody" style={{'textAlign':'center'}}>
                            <div className="row">
                                <div className="col" style={{'textAlign':'center','margin-top':'35px'}}>
                                    <TextField
                                        type='text'
                                        name='customerMail'
                                        variant='outlined'
                                        value={this.state.customerMail}
                                        onChangeCapture = {e => {
                                            this.validateEmail(e.target.value);
                                        }}
                                        error = {this.state.errorMsg ? true : false}
                                        helperText = {this.state.errorMsg}
                                        inputProps={{style:{'textAlign':'center','width': '215px'}}}
                                    />
                                    {this.props.siebelCustomer.isSearchError ? <p style={{'color':'red'}}>{this.props.siebelCustomer.message}</p> : <p></p>}
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter style={{'justifyContent':'center'}}>
                            <Button color="primary" onClick={e=> this.updateIdentity()}  disabled={this.state.errorMsg ? true : this.state.customerMail  == '' ? true : false }>Submit</Button>
                            <Button color="secondary" onClick={this.props.closeModal()}>Cancel</Button>
                        </ModalFooter>
                </Fragment></div>
            </Modal>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        siebelCustomer: state.utility.siebelCustomer,
        selectedEnvValue: state.auth.selectedEnvValue,
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        findSiebelCustomer : (request) => dispatch(findSiebelCustomer(request)),
        updateUserIdentity : (request) => dispatch(updateUserIdentity(request))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerMailModal);