import React, { Component } from 'react'
import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #cc6699 0, #cc6699 100%)',
   
}

const PasmatTrendTile = (props) => (
    <LinkTile
    className="bg-crimson"
    data-size="medium"
    to="/user/pasmatTrend"
    disabled={props.disabled}
    data-effect="hover-zoom-up">
    <div class="slide-front">
        <span className="fa fa-bar-chart icon"></span>
        <span className="branding-bar">Pasmat Trend Analysis</span>
    </div>
    <div
        class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
        <p class="text-center">
        {props.disabled ? "Coming Soon" : "Click here to analyse PASMAT trend"}
        </p>
    </div>
</LinkTile>
)

PasmatTrendTile.propTypes = {}

export default PasmatTrendTile;