import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";

const useStyles = makeStyles({
    stateLabel: {
        backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
        color: "#ffffff  !important",
        marginBottom: "20px !important",
        marginLeft: "20px !important"
    },
    stateSelectedLabel: {
        marginBottom: "20px !important",
        backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
        color: "#ffffff !important",
        marginLeft: "20px !important"
    },
    productHeaderLabel: {
        fontSize: "24px !important",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingLeft: "20px !important"
    },
    yesSpecification: {
        width: "650px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    }

});


const StatelessFoxtelProduct = (props) => {

    const classes = useStyles();

    const [foxtelProducts, setFoxtelProducts] = useState(["Satellite", "Cable"]);
    const [selectedFoxtel, setSelectedFoxtel] = useState("");
    const [foxtelAddresses, setFoxtelAddresses] = useState(["PIK", "SIK"])
    const [satelliteAddress, setSatelliteAddress] = useState("");

    const [satelliteAddressPIKSpecification, setSatelliteAddressPIKSpecification] = useState("");
    const [satelliteAddressSIKSpecification, setSatelliteAddressSIKSpecification] = useState("");

    const [cableAddressPIKSpecification, setCableAddressPIKSpecification] = useState("");
    const [cableAddressSIKSpecification, setCableAddressSIKSpecification] = useState("");

    const [cableAddress, setCableAddress] = useState("");
    const [foxtelSatAddPIKSpecData, setFoxtelSatAddPIKSpecData] = useState("");
    const [foxtelSatAddPIKNonSpecData, setFoxtelSatAddPIKNonSpecData] = useState("");

    const [foxtelSatAddSIKSpecData, setFoxtelSatAddSIKSpecData] = useState("");
    const [foxtelSatAddSIKNonSpecData, setFoxtelSatAddSIKNonSpecData] = useState("");

    const [foxtelCablePIKData, setFoxtelCablePIKData] = useState("");
    const [foxtelCableSIKData, setFoxtelCableSIKData] = useState("");

    const [cablePIK, setCablePIK] = useState("");
    const [cableSIK, setCableSIK] = useState("");
    const [labelText, setLableText] = useState("");


    const setSpecReqData = (product, value) => {
        let key = "";
        key = "CUSTOM: VACANT FOXTEL " + selectedFoxtel.toUpperCase() + " " + product + " DATA";
        setLableText(key);
        props.setSpecificRequirement({ [key]: value }, "");
    }

    const clearSubProducts = () => {
        setSatelliteAddress("");
        setCableAddress("");
        setFoxtelSatAddPIKSpecData("");
        setFoxtelSatAddPIKNonSpecData("");
        setFoxtelSatAddSIKNonSpecData("");
        setFoxtelSatAddSIKSpecData("");
        setCableAddressSIKSpecification("")
        setCableAddressPIKSpecification("");
        setSatelliteAddressSIKSpecification("");
        setSatelliteAddressPIKSpecification("")
    }

    useEffect(() => {
        clearSubProducts();
    }, [props.clearFoxtel]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Foxtel Product</Typography>
                    {foxtelProducts.map((foxtel, index) => {
                        return (
                            <Button variant="contained" classes={foxtel !== selectedFoxtel ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                sx={{ minWidth: 150, minHeight: 150 }} value={foxtel} onClick={e => { setSelectedFoxtel(e.target.value); clearSubProducts(); }}>
                                {foxtel}
                            </Button>
                        )
                    })}
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={1} />
                {selectedFoxtel === "Satellite" ?
                    <Grid item xs={10}>
                        <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Foxtel-{selectedFoxtel} Address</Typography>
                        {foxtelAddresses.map((foxADD, index) => {
                            return (
                                <Button variant="contained" classes={foxADD !== satelliteAddress ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                    sx={{ minWidth: 150, minHeight: 150 }} value={foxADD}
                                    onClick={e => {
                                        setSatelliteAddress(e.target.value);
                                        setCableAddress("");
                                        setCableAddressPIKSpecification("");
                                        setCableAddressSIKSpecification("");
                                        setSatelliteAddressPIKSpecification("");
                                        setSatelliteAddressSIKSpecification("");
                                        setSpecReqData(e.target.value, "")
                                    }}>
                                    {foxADD}
                                </Button>
                            )
                        })}
                    </Grid>
                    :
                    <>
                        {selectedFoxtel === "Cable" ?
                            <Grid item xs={10}>
                                <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Foxtel-{selectedFoxtel} Address</Typography>
                                {foxtelAddresses.map((foxADD, index) => {
                                    return (
                                        <Button variant="contained" classes={foxADD !== cableAddress ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                            sx={{ minWidth: 150, minHeight: 150 }} value={foxADD} onClick={e => {
                                                setSatelliteAddress("");
                                                setCableAddressPIKSpecification(""); setCableAddressSIKSpecification("");
                                                setSatelliteAddressPIKSpecification("");
                                                setSatelliteAddressSIKSpecification("");
                                                setCableAddress(e.target.value);
                                                setSpecReqData(e.target.value, "");

                                            }}>
                                            {foxADD}
                                        </Button>
                                    )
                                })}
                            </Grid>
                            :
                            <></>}
                    </>}
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={1} />
                {satelliteAddress === "PIK" ?
                    <Grid item xs={10}>
                        <TextField
                            id="FOXTEL-SATELLITE-PIK-SPECIFIC-DATA"
                            multiline
                            rows={4}
                            helperText="Enter the specific requirement"
                            label={labelText}
                            classes={{ root: classes.yesSpecification }}
                            value={foxtelSatAddPIKSpecData}
                            onChange={e => {
                                setFoxtelSatAddPIKSpecData(e.target.value);
                                setSpecReqData("PIK", e.target.value);
                            }}
                        />
                    </Grid> :
                    <>
                        {satelliteAddress === "SIK" ?
                            <Grid item xs={10}>
                                <TextField
                                    id="FOXTEL-SATELLITE-SIK-SPECIFIC-DATA"
                                    multiline
                                    rows={4}
                                    helperText="Enter the specific requirement"
                                    label={labelText}
                                    classes={{ root: classes.yesSpecification }}
                                    value={foxtelSatAddSIKSpecData}
                                    onChange={e => {
                                        setFoxtelSatAddSIKSpecData(e.target.value);
                                        setSpecReqData("SIK", e.target.value);
                                    }}
                                />
                            </Grid> :
                            <></>
                        }
                    </>
                }
                {cableAddress === "PIK" ?
                    <Grid item xs={10}>
                        <TextField
                            id="FOXTEL-CABLE-PIK-SPECIFIC-DATA"
                            multiline
                            rows={4}
                            helperText="Enter the specific requirement"
                            label={labelText}
                            classes={{ root: classes.yesSpecification }}
                            value={foxtelCablePIKData}
                            onChange={e => {
                                setFoxtelCablePIKData(e.target.value);
                                setSpecReqData("PIK", e.target.value);
                            }}
                        />
                    </Grid> :
                    <>
                        {cableAddress === "SIK" ?
                            <Grid item xs={10}>
                                <TextField
                                    id="FOXTEL-CABLE-SIK-SPECIFIC-DATA"
                                    multiline
                                    rows={4}
                                    helperText="Enter the specific requirement"
                                    label={labelText}
                                    classes={{ root: classes.yesSpecification }}
                                    value={foxtelCableSIKData}
                                    onChange={e => {
                                        setFoxtelCableSIKData(e.target.value);
                                        setSpecReqData("SIK", e.target.value);
                                    }}
                                />
                            </Grid> :
                            <></>
                        }
                    </>
                }
            </Grid>


        </>
    );
}

const mapStateToProps = (state) => {
    return {
        openBroadCastPanel: state.auth.openBroadCastPanel,
        broadCastList: state.broadCastReducer.broadCastDetails.broadCastList,
        broadCastMessage: state.auth.broadCastMessage,
        broadCastLoading: state.broadCastReducer.broadCastDetails.pending
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
        prodData: (value) => dispatch(prodData(value)),
        setSpecificRequirement: (value, sID) => dispatch(setSpecificRequirement(value, sID))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatelessFoxtelProduct);