import React, { Component } from 'react'
import { connect } from 'react-redux';
import DataTilesFrame from './accordion-frames/DataTilesFrame';
import CheckoutFrame from './accordion-frames/CheckoutFrame';

import { getClassTypeIcon, getDataTypeIcon } from '../../utils/ui.js';

import { fetchDataActiveOptions, selectActiveFilter, selectActiveOption, checkoutActive, resetSelectedFilters } from '../../actions/dataActions';

import _ from 'lodash';
import './Data.css';

import swal from 'sweetalert2';
import CheckoutModal from './modals/CheckoutModal';
import { isNullOrUndefined } from 'util';

class DataActive extends Component {
    componentDidMount() {
        // fetch checkout options
        this
            .props
            .fetchOptions();
        this.accordionEnd = React.createRef();
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        this
            .accordionEnd
            .current
            .scrollIntoView({ behavior: 'smooth' });
    }

    componentWillMount() {
        if (Object.entries(this.props.selectedFilters).length !== 0)
            this
                .props
                .resetFilters();
    }

    selectEnvType = (obj) => {
        this
            .props
            .selectFilter("envTypes", obj.label);
    }

    selectDataType = (obj) => {
        this
            .props
            .selectFilter("dataTypes", obj.label);
        this.selectProductType({ label: null });
        this.selectSystemType({ label: null });
        this.selectClassType({ label: null });
    }

    selectProductType = (obj) => {
        this
            .props
            .selectFilter("productTypes", obj.label);
        this.selectClassType({ label: null });
        this.selectSubType({ label: null });
        this.selectQtyType({ qty: null });
    }

    selectClassType = (obj) => {
        this
            .props
            .selectFilter("classTypes", obj.label);
        this.selectSubType({ label: null });
    }

    selectSystemType = (obj) => {
        this.props.selectFilter("systemType", obj.label);
        this.selectClassType({ label: null });
        this.selectSubType({ label: null });
    }
    selectSubType = (obj) => {
        this
            .props
            .selectFilter("subTypes", obj.label);
        this
            .props
            .selectOption(obj);
        if (obj.maxQty == 1) {
            this.selectQtyType({ qty: 1 });
        } else {
            this.selectQtyType({ qty: null });
        }

    }

    selectQtyType = (obj) => {
        this
            .props
            .selectFilter("qtyTypes", obj.qty);
    }

    checkoutConfirm = (env, id, qty) => {
        return this
            .props
            .checkout(env, id, qty)
            .then(async ({result,requestUrl}) => {
                await this
                    .refs
                    .checkoutModal
                    .close(); // wait for modal UI to finish up.

                if (result instanceof Error) {
                    const mailString = `ErrorResponse:    ${JSON.stringify(result.response.data)}
                    Request-Url :   ${requestUrl}`;

                    if (result.hasOwnProperty('response')) {
                        this.fireErrorModel(mailString);
                    }
                    else
                        swal.showValidationError(`Checkout failed, Timeout Error please try again`);
                } else {
                    console.log("NAVIGATE TO ACTIVE DATA VIEW PAGE");
                    if(result.hasOwnProperty('checkoutId'))
                    {
                        if(result.responseStatus.toUpperCase() !== "SUCCESS")
                        {
                            const mailString = `ErrorResponse:    ${JSON.stringify(result)}
                            Request-Url :   ${requestUrl}`
                            this.fireErrorModel(mailString);
                        }
                        else{
                            this.props.history.push(`/tdm/checkout/history/${result.checkoutId}`)
                        }
                    }
                    else{
                        this
                            .props
                            .history
                            .push(`/checkout/history/${result.id}`);
                    }
                }
            })
    }

    fireErrorModel = (mailString) => {
        swal.fire({
            type: 'error',
            title: 'Checkout Error',
            text: `Checkout Failed`,
            footer: `<a href='mailto:cc6fb024.teamtelstra.onmicrosoft.com@amer.teams.ms?Subject=Checkout Error Details&body=${mailString}' target="_top">Report Error</a>`
        })
    }

    checkout = () => {
        const { selectedOption } = this.props;
        const { envTypes, dataTypes, productTypes, classTypes, subTypes } = this.props.selectedFilters;
        let { qtyTypes } = this.props.selectedFilters;
        qtyTypes = qtyTypes ? qtyTypes : 1
        swal({
            title: 'Checkout Confirmation',
            html: `<b>Environment:</b> ${envTypes}<br />
                    <b>Data Type:</b> ${dataTypes}<br />
                    <b>${dataTypes} Type:</b> ${classTypes}<br />
                    <b>Quantity:</b> ${qtyTypes} Records`,
            type: 'info',
            showCancelButton: true,
            cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> Cancel',
            confirmButtonColor: '#13a129',
            confirmButtonText: '<i class="fa fa-check" aria-hidden="true"></i> Checkout',
            reverseButtons: true,
            allowOutsideClick: false
        }).then((result) => {
            if (result.value) {
                this
                    .refs
                    .checkoutModal
                    .openModal(envTypes, selectedOption, qtyTypes);
            }
        })
    }

    render() {
        const { envTypes, dataTypes, productTypes, classTypes, classNames, qtyTypes, subTypes, systemType } = this.props.selectedFilters;

        let { environments, options } = this.props.options;
        environments = environments.map(environment => ({ label: environment, icon: 'icon-home' }))
        const distinctDataTypes = [...new Set(options.map(option => option.dataType))].map(dataType => ({ label: dataType, icon: getDataTypeIcon(dataType) }));
        const distinctProductTypes = [...new Set(options.filter(option => option.dataType == dataTypes).map(option => option.productType))]
            .filter(productType => (productType != null))
            .map(productType => ({ label: productType, icon: getDataTypeIcon(productType) }));
        const distinctSystemTypes = [...new Set(options.map(data => data.source).filter(d => d != null))].map(sysType => ({ label: sysType, icon: getDataTypeIcon(sysType) }));

        const displayProducts = (dataTypes && distinctProductTypes && distinctProductTypes.length > 0);


        const distinctClassTypes = _.uniqBy(options.filter(option => systemType ? (option.dataType == dataTypes && option.source === systemType) : option.dataType == dataTypes)
        .filter(option => (displayProducts
            ? option.productType == productTypes
            : true)), 'className').map(classType => ({
            ...classType,
            label: classType.className,
            icon: getClassTypeIcon(classType.className),
            classBadge: (classType.classType)
                ? "Class " + classType.classType
                : null,
            classId: classType.classType
        }))

        const distinctSubTypes = [...new Set((options.filter(option => systemType ? (option.className == classTypes && option.source === systemType) : option.className == classTypes)))]
            .filter(classType => classType.subType != null)
            .map(classType => ({
                ...classType,
                label: classType.subType,
                icon: getClassTypeIcon(classType.subType)
            }));

        const displayTechnologies = (productTypes || (dataTypes && (!distinctProductTypes || distinctProductTypes.length == 0)));

        const displaySubtypes = (((displayTechnologies && !isNullOrUndefined(classTypes) && distinctSubTypes.length > 0)));
        const completedSubtypes = (!displaySubtypes && distinctSubTypes.length == 0) || (displaySubtypes && subTypes);

        // max qty for the checkout type provided by server
        const { maxQty } = this.props.selectedOption;

        // only display quantities that fall under the max qty
        const qtyFilters = this
            .props
            .options
            .qty
            .filter(({ qty }) => qty <= maxQty);

        const multipleQuantity = (maxQty > 1);
        const displayQuantities = (multipleQuantity) && (displayTechnologies && classTypes) && (completedSubtypes);
        const displayCheckout = (displayTechnologies &&
            (
                (completedSubtypes &&
                    ((displayQuantities || !multipleQuantity) && qtyTypes)
                )
            )
        );

        return (
            <div className="white">
                <span className="dataview-header">
                    <h1>Download Active Data</h1>
                    <i class="fa fa-cloud-download fa-3x" aria-hidden="true"></i>
                </span>
                <div style={{
                    "paddingTop": "20px"
                }}>
                    <DataTilesFrame
                        heading="Environment"
                        color="magenta"
                        data={environments}
                        select={this.selectEnvType}
                        selected={envTypes} /> {(envTypes) && <DataTilesFrame
                            heading="Data Type"
                            color="blue"
                            data={distinctDataTypes}
                            select={this.selectDataType}
                            selected={dataTypes} />}
                    {(displayProducts) && <DataTilesFrame
                        heading="Product Type"
                        color="turquoise"
                        data={distinctProductTypes}
                        select={this.selectProductType}
                        selected={productTypes} />}
                    {(displayTechnologies && dataTypes === 'Customer') && <DataTilesFrame
                        heading={`${dataTypes} - Source System Type`}
                        color="orange"
                        data={distinctSystemTypes}
                        select={this.selectSystemType}
                        selected={systemType} />}
                    {(displayTechnologies && dataTypes === "Order") && <DataTilesFrame
                        heading={`${dataTypes} - Type`}
                        color="orange"
                        data={distinctClassTypes}
                        select={this.selectClassType}
                        selected={classTypes}
                        subSelected={classNames} />}
                    {(systemType) && <DataTilesFrame
                        heading={`${systemType} Type`}
                        color="orange"
                        data={distinctClassTypes}
                        select={this.selectClassType}
                        selected={classTypes}
                        subSelected={classNames} />}
                    {(displaySubtypes) && <DataTilesFrame
                        heading={`${dataTypes} Subtype`}
                        color="turquoise"
                        data={distinctSubTypes}
                        select={this.selectSubType}
                        selected={subTypes}
                        prevSelected={classTypes || null} />}
                    {(displayQuantities) && <DataTilesFrame
                        heading="Quantity"
                        color="orange"
                        data={qtyFilters}
                        select={this.selectQtyType}
                        selected={qtyTypes} />}
                    {(displayCheckout) && <CheckoutFrame checkout={this.checkout} />}
                    <div ref={this.accordionEnd} />
                </div>
                <CheckoutModal
                ref="checkoutModal"
                title="Downloading Active Data"
                confirm={this.checkoutConfirm}/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return { options: state.dataActive.filters, userProfile: state.auth.user, selectedFilters: state.dataActive.selectedFilters, selectedOption: state.dataActive.selectedOption }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchOptions: () => {
            dispatch(fetchDataActiveOptions())
        },
        selectFilter: (key, value) => {
            dispatch(selectActiveFilter(key, value))
        },
        selectOption: (option) => {
            dispatch(selectActiveOption(option))
        },
        checkout: (env, optionId, qty) => dispatch(checkoutActive(env, optionId, qty)),
        resetFilters: () => dispatch(resetSelectedFilters())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataActive);