import React from "react";
import _ from "lodash";
import { Table } from 'reactstrap';
import TeamDataTableBody from "./TeamDataTableBody";
import TableFilterController from '../../../../components/common/filter/TableFilterController';
import TableContentLoader from "../../../../components/common/loader/TableContentLoader";
import MuiAlert from '@material-ui/lab/Alert';

export default function DataCheckoutTable({ resetTeams,tableData, isAdmin, teamId, currentTeamId, filters, fetching, errors, isClearFilter, clearFilter, isStandardToggle}) {
  function TableHeaderCol(props){
  return (<th style={{textAlign: `${props.textAlign || 'center'}` , minWidth: `${props.minWidth}`, width: `${props.width || '10%'}`} }>{props.children}</th>)
  }

  function getTableData(){
    if(isStandardToggle){
      return tableData && tableData.filter(data => data.serviceId && data.userStory === 'NA');
    }else{
      return tableData && tableData.filter(data => (!data.serviceId || data.serviceId === undefined) && data.userStory !== 'NA');
    }
  }
  return (
    <React.Fragment>
      <Table hover responsive size="sm">
        <thead>
          <tr>
            <TableHeaderCol width="10%" minWidth="138px">Checkout Id</TableHeaderCol>
            <TableHeaderCol width="10%" minWidth="132px">{ isStandardToggle ? <th>Service Id</th> : <th>Request Id</th> }</TableHeaderCol>
            <TableHeaderCol width="13%" minWidth="132px">{ isStandardToggle ? <th>Product Code</th> : <th>Scenario Name</th> }</TableHeaderCol>
            <TableHeaderCol width="20%" textAlign="left">{ isStandardToggle ? <th>Service Description</th> : <th>Request Description</th> }</TableHeaderCol>
            <TableHeaderCol width="12%">Quantity</TableHeaderCol>
            <TableHeaderCol width="15%">Date</TableHeaderCol>
            <TableHeaderCol width="10%">{teamId ? 'Users' : 'Status'}</TableHeaderCol>
            <TableHeaderCol width="10%">Action</TableHeaderCol>
          </tr>
        </thead>
        <tbody id="team-details-table-body">
          <React.Fragment>
          {filters && 
            <TableFilterController resetTeams={resetTeams} filters={filters} isClearFilter={isClearFilter} clearFilter={clearFilter} />
          }
          { !errors && !fetching && !_.isEmpty(getTableData()) && 
            <TeamDataTableBody 
              teamData={getTableData()}
              isAdmin={isAdmin}
              currentTeamId={currentTeamId}
              teamId={teamId}
              isStandardToggle={isStandardToggle}
            />
          }
          </React.Fragment>
        </tbody>
      </Table>
      {fetching && (<TableContentLoader />)}
      {_.isEmpty(tableData) && !fetching && !errors &&<MuiAlert elevation={6} variant='filled' severity="info">Sorry, No checkouts have been made under this selection</MuiAlert>}
    {errors && <MuiAlert elevation={6} variant='filled' severity="error">{errors.message}</MuiAlert>}
    </React.Fragment>
  )
}