import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { makeStyles, Grid, Typography, Box, CircularProgress, Tooltip, IconButton } from '@material-ui/core';
import { stringContainsAllFromArrayFilter } from "@library/core/filters/filter";
import { blueGrey } from "@material-ui/core/colors";
import { fetchWishlists } from "../../actions/orderBuilderActions";
import WishlistCard from "./WishlistCard";
import TableSearch from "@library/core/tables/TableSearch";
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

const useStyles = makeStyles({
  modalBody: {
    height: "450px",
    margin: "15px",
    overflowY: "scroll",
    scroll: "hidden",
  },
  errMsg: {
    color: blueGrey[700],
  },
  modalTitle: {
      width: "100% !important",
      '& .modal-title': {
        width: "100% !important"
      }
    }
});

const WishlistModal = (props) => {
  const classes = useStyles();

  const { 
    openWishlistModal, 
    showWishListModal, 
    wishlists = [],
    wishlistsPending,
    wishlistsFailed,
    wishlistsFailedMsg,
    wishlistsSuccess,
    prepopOrder,
    teamId
  } = props;

  useEffect(() => {
    props.fetchWishlists(teamId).then((res) => {
      formatWishlistArray(res);
    });
  }, []);

  const [formattedWishlists, setFormattedWishlists] = useState([]);
  const [search, setSearch] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const filteredList = [];
    for (const wishlist of wishlists) {
      if (wishlist && wishlist.wishlistName) {
        if (stringContainsAllFromArrayFilter(wishlist.wishlistName, filter.split(" "))) {
          filteredList.push(wishlist);
        }
      }
    }
    formatWishlistArray(filteredList);
  }, [filter]);

  const toggleSearch = () => {
    setSearch(!search);
    searchFilter('');
  }

  const searchFilter = (value) => {
    setFilter(value);
  }

  const formatWishlistArray = (filteredList) => {
    const filteredWishlists = filteredList ? filteredList : wishlists;
    let list = [];
    for (const wishlist of filteredWishlists) {
      if (wishlist && wishlist.wishlistName) {
        list.push(wishlist.wishlistName);
      }
    }
    setFormattedWishlists(list);
  }

  const onCloseModal = () => {
    setSearch(false);
    searchFilter('');
    showWishListModal(false);
  };

  const removeWishlist = () => {
    console.log("removeFunc Triggered")
  }

  return (
    <Modal isOpen={openWishlistModal} toggle={onCloseModal} size="lg" id="broadCastMessageModal" className="tour-orderbuilder-wishlistMenu">
      <ModalHeader className={classes.modalTitle}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item>
              {
                search ? 
                  <TableSearch outlined filter={searchFilter} toggle={toggleSearch}/>
                : 
                <Typography gutterBottom variant="h5">
                  Order Builder Wishlists
                </Typography>
              }
            </Grid>
            <Grid item>
              <Tooltip title={"Search Wishlists"}>
                <span>
                  <IconButton onClick={()=>toggleSearch()}>
                    {
                      <SearchOutlinedIcon style={{color: "white"}}/>
                    }
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
      </ModalHeader>
      <ModalBody className={classes.modalBody}>
        { wishlistsSuccess && wishlists && wishlists.length > 0 && formattedWishlists.length > 0 ?
          <Grid container direction="row" spacing={3}>
          {
            formattedWishlists.map(list => {
              return (
                <Grid item xs={12} md={6} key={list}>
                  <WishlistCard 
                    cardId={list} 
                    name={list} 
                    removeTooltip="Delete Wishlist"
                    removeFunc={()=>removeWishlist(list)}
                    onClickCard={()=>prepopOrder(list)}
                  />
                </Grid>
              )
            })
          }
          </Grid> : wishlistsPending ?
           <Box 
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight="415px"
              fontWeight="fontWeightBold" 
              fontSize={20}
            >
              <CircularProgress size="4rem" />
            </Box>
          : <Typography variant="h6" className={classes.errMsg}>
            <Box 
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight="415px"
              fontWeight="fontWeightBold" 
              fontSize={20}
            >
              {wishlistsFailed ? wishlistsFailedMsg : wishlistsSuccess && wishlists.length != 0 && formattedWishlists.length === 0 ? "No Wishlists match this search" : "No Available Wishlists"}
            </Box>
          </Typography>
        }
      </ModalBody>
    </Modal>
  )
}


const mapStateToProps = (state) => {
  return {
    wishlists: state.orderBuilder.wishlists,
    wishlistsPending: state.orderBuilder.wishlistsPending,
    wishlistsFailed: state.orderBuilder.wishlistsFailed,
    wishlistsFailedMsg: state.orderBuilder.wishlistsFailedMsg,
    wishlistsSuccess: state.orderBuilder.wishlistsSuccess,

    teamId: state.auth.team,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWishlists:(teamId) => dispatch(fetchWishlists(teamId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WishlistModal);