import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ReactJson from 'react-json-view';
import HeaderContent from '../common/Header/HeaderContent';
import { Tooltip, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  footerPaginate:{
    "& select": {
      marginTop: "15px",
      paddingRight: "0px",
    },
    "& svg":{
      display: "none"
    }
  }
});

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};


const SyntheticDatasetSummary = (props) =>  {
  const classes = useStyles();
  const checkedOutData =props.checkedOutData;
  const checkedOutDataRowCalculate = Object.keys(props.checkedOutData);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - checkedOutDataRowCalculate.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="white" id="">
      <HeaderContent
        title={`Custom Data Summary`}
        icon="wpforms training-icon "
        empty={true}
        loadingText="Retrieving Data..."></HeaderContent>
         <div class="metaDataContainer container">
         <div class="metaDataLabel p-5"><Typography>Checked out Data</Typography></div>
          <div class="row ml-3"> 
            <div class="col-1 metaDataLabel"><Typography>Name</Typography></div>
            <div class="col-2 metaDataValue pl-5"><Typography>{props.checkedDataSummary.name}</Typography></div>
            <div class="col-5"></div>
            <div class="col-1 metaDataLabel"><Typography>Scope</Typography></div>
            <div class="col-2 metaDataValue pl-5"><Typography>{props.checkedDataSummary.scope}</Typography></div>
         </div>
         <div class="row ml-3 pt-2"> 
         <div class="col-1 metaDataLabel"><Typography>Description</Typography></div>
            <div class="col-6 metaDataValue pl-5"><Typography>{props.checkedDataSummary.description}</Typography></div>
         
            <div class="col-1"></div>
            <div class="col-1 metaDataLabel "><Typography>Owner</Typography></div>
            <div class="col-2 metaDataValue pl-5">
            {props.checkedDataSummary.teamId !== undefined && props.checkedDataSummary.teamId !== null?
            <Typography>{props.checkedDataSummary.teamId}</Typography> :
            <>
            {props.checkedDataSummary.userId !== undefined ?
            <Typography>{props.checkedDataSummary.userId}</Typography> : 
            <Typography>NA</Typography>}
            </>}
            </div>
         </div>
         </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell style={{ "width": "100px", "margin": "auto" }}>ID</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Download</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
{ checkedOutData && Object.keys(checkedOutData).map((row, index) => (
              <TableRow key={index}>
                <TableCell><Tooltip title={checkedOutData[row][0]["id"]}>
                    <Typography noWrap style={{ "width": "250px", "display": "inline-block" ,"margin": "auto"}} variant="body2">
                      {checkedOutData[row][0]["id"]}
                    </Typography></Tooltip>
                    </TableCell>
                <TableCell>
                  <ReactJson src={checkedOutData[row]} collapsed={true} />
                </TableCell>
                <TableCell><a href={`data: text/json;charset=utf-8, ${encodeURIComponent(JSON.stringify(checkedOutData, null, '\t'))}`}
                  download={`${checkedOutData[row][0]["datasetId"]}.json`}>
                  <i className={"fa fa-file-code-o fa-2x theme-text-primary"} aria-hidden="true"></i>
                </a></TableCell>

              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={checkedOutDataRowCalculate.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                className={classes.footerPaginate}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer></div>
  );
}
const mapStateToProps = (state) => {
  return {
   checkedOutData : state.retrieveData.checkedOutData,
   checkedDataSummary : state.retrieveData.checkedDataSummary

  }
}

export default connect(mapStateToProps, null)(SyntheticDatasetSummary);