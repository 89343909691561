import React, {useState} from 'react';
import { SubHeader } from "../../../../common/custom/SubHeader";
import { Table } from "reactstrap";
import _ from 'lodash';
import AddressLookup from '../../../../utility/AddressLookup/AddressLookup';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const AddressDetails = (addressDetails) => {
    const [tagid, setTagIdHook] = useState(null);

    const setTagId = (tag) => {
        setTagIdHook(tag === tagid ? null : tag);
    }

    return (
        <React.Fragment>
            {(!_.isEmpty(addressDetails) &&
                <React.Fragment>
                    <SubHeader
                        text={"NBN Address"}
                        iconClass={"td-icon-md icon-crowd-support"}
                        iconColor="turquoise"
                        filename={`someFile-CUSTOMER`} />
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>Tag Id</th>
                                <th>Exchange Id</th>
                                <th>Realm</th>
                                <th>Nbn Location Id</th>
                                <th>Csa Id</th>
                                <th>Locality</th>
                                <th>Envirnoment</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                        {addressDetails.addressDetails.map(eachAddress =>
                            <tr key={eachAddress.addressDetails[0].tagId}>
                                <th scope="row">
                                        <div style={{float:"left"}}>
                                            <Tooltip title="Show full data">
                                                <IconButton
                                                    style={{"margin-right":"5px"}}
                                                    variant="contained"
                                                    color={tagid === eachAddress.addressDetails[0].tagId ? "secondary" : "primary"}
                                                    disabled={false}
                                                    tabIndex="4"
                                                    className="submitButton"
                                                    type="button"
                                                    size="small"
                                                    onClick={() => {
                                                        setTagId(eachAddress.addressDetails[0].tagId);
                                                    }}
                                                >
                                                    {tagid === eachAddress.addressDetails[0].tagId ? <ExpandMoreIcon/> : <ChevronRightIcon/>}
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{"overflow":"hidden", "textAlign":"center"}}>
                                            <div style={{paddingTop:"3px"}}>
                                                {eachAddress.addressDetails[0].tagId}
                                            </div>

                                        </div>

                                    </th>
                                <td>{eachAddress.addressDetails[0].exchangeId}</td>
                                <td>{eachAddress.addressDetails[0].realm}</td>
                                <td>{eachAddress.addressDetails[0].nbnLocationId}</td>
                                <td>{eachAddress.addressDetails[0].csaId}</td>
                                <td>{eachAddress.addressDetails[0].locality}</td>
                                <td>{eachAddress.addressDetails[0].environment}</td>
                                <td>{eachAddress.addressDetails[0].type}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    {tagid !== null &&
                    <div style={{transition:"all 4s linear"}}>
                        <AddressLookup tagID={tagid} />
                    </div>
                    }
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
