import React from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import swal from 'sweetalert2';
import * as billingaActions from "../../actions/u2cActions";

class CreateBillingAdjustmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      billingAccountNumber: '',
    };
    this.toggle = this.toggle.bind(this);
  }

  open = (billingAccountNumber) => {
    this.setState({
      modal: true, billingAccountNumber
    });
  };

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
    this.props.resetCreateAdjFormFields();
  }
  handleOnChange = (e) => {
    this.props.updateFields({ [e.target.name]: e.target.value });
  }
  createBillingAdjustment = async (e) => {
    e.preventDefault();
    const response = await this.props.createBillingAdjustment(this.props.adjustment, this.props.billingAccountNumber);
    this.toggle();
    if (response) {
      swal.fire({
        type: 'success',
        title: 'Billing Adjustment Created Successfully.',
      })
    } else {
      swal.fire({
        type: 'error',
        text: 'Failed to Create Billing Adjustment.',

      })
    }
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} style={{ maxWidth: "70vw" }} style={{
        marginTop: "150px"
      }}>
        <div className="modalcontent">
          <div>
            <ModalHeader>
              <p>New Adjustment</p>
            </ModalHeader>
          </div>
          <form onSubmit={this.createBillingAdjustment}>
            <ModalBody className="modalbody">
              <div className="row">
                <div className="col">
                  <label>Description</label>
                  <select required className="form-control" name="description" onChange={this.handleOnChange} autoFocus>
                    <option value=""></option>
                    <option value="Goodwill Credit">Goodwill Credit</option>
                    <option value="Telstra Issue">Telstra Issue</option>
                  </select>
                </div>

                <div className="col">
                  <label>Product ID</label>
                  <input required className="form-control" name="productId" type="text" onChange={this.handleOnChange} autoFocus></input>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Amount</label>
                  <input required className="form-control" name="amount" type="number" onChange={this.handleOnChange} autoFocus></input>
                </div>
                <div className="col">
                  <label>Reason Code</label>
                  <select required className="form-control" name="reasonCode" onChange={this.handleOnChange} autoFocus>
                    <option value=""></option>
                    <option value="2001">Goodwill Credits</option>
                    <option value="2002">Compensation Credits</option>
                  </select>
                </div>
              </div>
              <div className="row">
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" className="submitButton" type="submit">{this.props.savingPending
                ? <i class="fa fa-spinner fa-pulse fa-fw"></i> : "Create"}</Button>
              <Button color="secondary" className="cancelButton" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </form>
        </div>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    adjustment: state.u2c.adjustment,
    savingPending: state.u2c.savingPending,
    billingAccountNumber: state.u2c.billingAccount.billingAccountNumber
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createBillingAdjustment: (billingAdjustmentData, billingAccountNumber) => dispatch(billingaActions.createBillingAdjustment(billingAdjustmentData, billingAccountNumber)),
    updateFields: (data) => dispatch(billingaActions.updateFields(data)),
    resetCreateAdjFormFields: () => dispatch(billingaActions.resetCreateAdjFormFields()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBillingAdjustmentModal);
