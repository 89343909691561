import React from "react";
import { Button } from "reactstrap";
import swal from 'sweetalert2';
import { displayLoading } from "../common/alerts/alerts";

const popUpServicesCheck = async (tableRowData,loggedInUser,currentTeamId,handleCheckout,enableModal,filterServiceIds,handleServiceFilterCheckout) => {
  displayLoading({ title: "Checking out", bodyText: "Please standby" });    
  await handleServiceFilterCheckout(tableRowData.serviceId).then(result => {
        if (result && result.length > 0 || filterServiceIds.some(id => id === tableRowData.serviceId)) {
          swal.close();
          enableModal(tableRowData, loggedInUser, tableRowData.teamId, currentTeamId);
        } else {
          handleCheckout(tableRowData, (loggedInUser == tableRowData.teamId))
        }
      });
}
export default ({ tableRowData, handleCheckout, currentTeamId, 
  isShowCircleInServiceId=true, loggedInUser, enableModal,filterServiceIds,handleServiceFilterCheckout}) => (
  <tr id="team-detail-row">
    <td>
      <span className={`gradient-icon base-green`}>
        <i
          className={`td-icon-sm icon-crowd-support theme-text-primary `}
        ></i>
      </span>
      {tableRowData.teamName}
    </td>
    <td style={{textAlign: "center"}}>
      {
        tableRowData.serviceId &&
        <span className = {`${isShowCircleInServiceId ? 'team-circle' : ''} `} id = { `${isShowCircleInServiceId ? 'team-service-circle' : ''}`}>
          {tableRowData.serviceId}</span> 
      }
    </td>
    <td>
      {tableRowData.serviceProductCode ? tableRowData.serviceProductCode : ''}
    </td>
    <td>
      {tableRowData.description &&
        <span className={`gradient-icon base-default`}>
          <i
            className={`td-icon-sm icon-manage-services theme-text-primary `}
          ></i>
        </span>}
      {tableRowData.description}
    </td>
    <td style={{textAlign: "center"}}>
      { (!!tableRowData.quantity || tableRowData.quantity === 0) && 
        <span className = "team-circle" id = "team-qty-circle">
          {tableRowData.quantity}
        </span>
      }
    </td>
    <td>
    <Button
        className={`btn btn-success btn-block btn-team`}
        onClick={() =>{popUpServicesCheck(tableRowData,loggedInUser,currentTeamId,handleCheckout,enableModal,filterServiceIds,handleServiceFilterCheckout)}}
        disabled={loggedInUser == tableRowData.teamId ? (!(tableRowData.quantity >= 1) ? (true) : (false)) : (currentTeamId == tableRowData.teamId ? (!(tableRowData.quantity >= 1) ? (true) : (false)) : (true))}
      >
        Checkout
      </Button>
    </td>

  </tr>
);

