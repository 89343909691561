import React, {Fragment, Component} from "react";
import { Redirect } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {connect} from "react-redux";
import swal from "sweetalert2";
import { TextField, FormControl, InputLabel, MenuItem, FormHelperText } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { displayLoading, displaySuccess } from "../../common/alerts/alerts";
import { isEmail, isNumeric } from 'validator';
import { customerCreation, findSiebelCustomer } from '../../../actions/utilityActions';
import "./CustomerConsole.css"
import Tooltip from '@material-ui/core/Tooltip';

class CreateCustomerModal extends Component {

    state = {
        formInvalid: true,
        fields: {
            customerType: '',
            customerFirstName: '',
            customerLastName: '',
            customerDob: '',
            customerEmail: '',
            contactType: '',
            contactFirstName: '',
            contactLastName: '',
            contactDob: '',
            contactEmail: '',
            contactPhone: '',
        },
        errors: {
            customerType: '',
            customerFirstName: '',
            customerLastName: '',
            customerDob: '',
            customerEmail: '',
            contactType: '',
            contactFirstName: '',
            contactLastName: '',
            contactDob: '',
            contactEmail: '',
            contactPhone: '',
        }
    }

    handleCustomerCreation = () => {
        displayLoading({ title: "Creating Customer", bodyText: "Please standby while customer is created."})

        const request= {
            "customer": {
                "type": this.state.fields.customerType,
                "firstName": this.state.fields.customerFirstName,
                "lastName": this.state.fields.customerLastName,
                "dob": this.state.fields.customerDob,
                "email": this.state.fields.customerEmail
            },
            "contact": {
                "type": this.state.fields.contactType,
                "firstName": this.state.fields.contactFirstName,
                "lastName": this.state.fields.contactLastName,
                "dob": this.state.fields.contactDob,
                "email": this.state.fields.contactEmail,
                "phone": this.state.fields.contactPhone
            }
        }
        let formValid = this.validateForm();
        this.setState({
            ...this.state,
            formInvalid: formValid
        }, () => {
            if (!this.state.formInvalid) {
                this.props.customerCreation(request).then(() => {
                    swal.close();
                    if (this.props.createSuccess) {
                        displayLoading({ title: "Fetching New Customer", bodyText: "Please standby while we fetch your new customers details." });
                        this.props.findSiebelCustomer(this.props.selectedEnvValue, this.props.createResponse + '/' + this.props.selectedEnvValue);
                    } else if (this.props.createFailed) {
                        swal.fire({
                            type: 'error',
                            title: 'Error',
                            html: this.props.createResponse,
                            confirmButtonText: 'OK',
                        })
                    }
                })
            }
        })
    }

    handleChange = (event) => {
        if (event.target.name) {
            let valid = this.validateField(event.target.name, event.target.value)

            this.setState ({
                ...this.state,
                fields: {
                    ...this.state.fields,
                    [event.target.name]: event.target.value,
                },
                errors: {
                    ...this.state.errors,
                    [event.target.name]: valid
                }
            }, () => {
                let formValid = this.validateForm();
                this.setState({
                    ...this.state,
                    formInvalid: formValid
                })
            })
        }
    }

    validateForm = () => {
        let invalid = false;
        for (const [type, value] of Object.entries(this.state.fields)) {
            if (!value) {
                invalid = true;
                return invalid;
            }
        }
        if (!invalid) {
            for (const [type, value] of Object.entries(this.state.errors)) {
                if (value !== '') {
                    invalid = true;
                    return invalid;
                }
            }
        }
    }

    validatetext = (text, textMinLengthLimit, textMaxLengthLimit, lengthErrMsg, emptyErrMsg) => {
        if (text) {
            if (text.length > textMaxLengthLimit || text.length < textMinLengthLimit) {
                return lengthErrMsg
            } else {
                return ''
            }
        } else {
            return emptyErrMsg
        }
    }

    validateField = (fieldName, fieldValue) => {
        switch (fieldName) {
            case "customerType":
                return fieldValue ? '' : 'Please select a Customer Type';
            case "customerFirstName": 
                return this.validatetext(fieldValue, 3, 60, 'Must be between 3-60 characters', 'Please enter First Name');
            case "customerLastName":
                return this.validatetext(fieldValue, 3, 60, 'Must be between 3-60 characters', 'Please enter Last Name');
            case "customerDob":
                return fieldValue ? '' : 'Please select Date of Birth';
            case "customerEmail":
                return isEmail(fieldValue) ? '' : 'Please enter a valid Email address';
            case "contactType":
                return fieldValue ? '' : 'Please select a Contact Type';
            case "contactFirstName":
                return this.validatetext(fieldValue, 3, 60, 'Must be between 3-60 characters', 'Please enter First Name');
            case "contactLastName":
                return this.validatetext(fieldValue, 3, 60, 'Must be between 3-60 characters', 'Please enter Last Name');
            case "contactDob":
                return fieldValue ? '' : 'Please select Date of Birth';
            case "contactEmail":
                return isEmail(fieldValue) ? '' : 'Please enter a valid Email address';
            case "contactPhone":
                return (isNumeric(fieldValue) && fieldValue.length < 13) ? '' : 'Please enter a valid phone number'
        }
    }

    render() {
        if (this.props.siebelCustomer) {
            if (this.props.siebelCustomer.isSearchSuccess) {
                swal.close();
                return <Redirect to="/data/utility/customerconsole"/>
            }
            if (this.props.siebelCustomer.isSearchError) {
                swal.close();
                swal.fire({
                    type: 'error',
                    title: 'Error',
                    html: this.props.siebelCustomer.message,
                    confirmButtonText: 'OK',
                })
            }
        }
        return (
            <Fragment>
                <Modal size="lg" isOpen={this.props.showModal} backdrop="static" className="center-of-screen" id="create-customer-modal">
                    <ModalHeader>
                        <span className="form-modal-header">Create Customer</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="createCustomerForm">
                            <div className="createCustomerSubForm">
                                <span className="createCustomerSubFormHeader"><h4>Customer</h4></span>
                                <FormControl variant="outlined" className="field-and-label" error={this.state.errors.customerType}>
                                    <label>Type</label>
                                    <div style={{"width":"70%"}}>
                                        <Select
                                            name="customerType"
                                            fullWidth
                                            style={{"height":"41px","padding":"0px"}}
                                            defaultValue=''
                                            onChange={this.handleChange}
                                        >
                                            <MenuItem defaultValue value={'Residential'}>Residential</MenuItem>
                                            <MenuItem value={'Soletrader'}>Soletrader</MenuItem>
                                        </Select>
                                        <FormHelperText>{this.state.errors.customerType}</FormHelperText>
                                    </div>
                                </FormControl>
                                <FormControl className="field-and-label">
                                    <label>First Name</label>
                                    <div style={{"width":"70%"}}>
                                        <TextField
                                            margin='normal'
                                            type='text'
                                            name="customerFirstName"
                                            variant="outlined"
                                            fullWidth
                                            style={{"height":"44px"}}
                                            onChange={this.handleChange}
                                            error={this.state.errors.customerFirstName}
                                            helperText={this.state.errors.customerFirstName}
                                        />          
                                    </div>
                                </FormControl>
                                <FormControl className="field-and-label">
                                    <label>Last Name</label>
                                    <div style={{"width":"70%"}}>
                                        <TextField
                                            margin='normal'
                                            type='text'
                                            name="customerLastName"
                                            variant="outlined"
                                            fullWidth
                                            style={{"height":"44px"}}
                                            onChange={this.handleChange}
                                            error={this.state.errors.customerLastName}
                                            helperText={this.state.errors.customerLastName}
                                        />
                                    </div>
                                </FormControl>
                                <FormControl className="field-and-label" error={this.state.errors.customerDob}>
                                    <label>DOB</label>
                                    <div style={{"width":"70%"}}>
                                        <TextField
                                            margin='normal'
                                            type='date'
                                            name="customerDob"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            onChange={this.handleChange}
                                            error={this.state.errors.customerDob}
                                            helperText={this.state.errors.customerDob}
                                        />
                                        <FormHelperText>{this.state.errors.customerDob}</FormHelperText>
                                    </div>
                                </FormControl>
                                <FormControl className="field-and-label">
                                    <label>Email</label>
                                    <div style={{"width":"70%"}}>
                                        <TextField
                                            margin='normal'
                                            type='text'
                                            name="customerEmail"
                                            variant="outlined"
                                            fullWidth
                                            style={{"height":"44px"}}
                                            onChange={this.handleChange}
                                            error={this.state.errors.customerEmail}
                                            helperText={this.state.errors.customerEmail}
                                        />
                                    </div>
                                </FormControl>
                            </div>
                            <div className="createCustomerSubForm">
                                <span className="createCustomerSubFormHeader"><h4>Contact</h4></span>
                                <FormControl variant="outlined" className="field-and-label" error={this.state.errors.contactType}>
                                    <label>Type</label>
                                    <div style={{"width":"70%"}}>
                                        <Select
                                            name="contactType"
                                            fullWidth
                                            style={{"height":"41px","padding":"0px"}}
                                            defaultValue=''
                                            onChange={this.handleChange}
                                            error={this.state.errors.contactType}
                                        >
                                            <MenuItem value={'Legal Lessee'}>Legal Lessee</MenuItem>
                                            <MenuItem value={'Asset User'}>Asset User</MenuItem>
                                            <MenuItem value={'Limited Authority'}>Limited Authority</MenuItem>
                                            <MenuItem value={'3rd Party'}>3rd Party</MenuItem>
                                        </Select>
                                        <FormHelperText>{this.state.errors.contactType}</FormHelperText>
                                    </div>
                                </FormControl>
                                <FormControl className="field-and-label">
                                    <label>First Name</label>
                                    <div style={{"width":"70%"}}>
                                        <TextField
                                            margin='normal'
                                            type='text'
                                            name="contactFirstName"
                                            variant="outlined"
                                            fullWidth
                                            style={{"height":"44px"}}
                                            onChange={this.handleChange}
                                            error={this.state.errors.contactFirstName}
                                            helperText={this.state.errors.contactFirstName}
                                        />
                                    </div>
                                </FormControl>
                                <FormControl className="field-and-label">
                                    <label>Last Name</label>
                                    <div style={{"width":"70%"}}>
                                        <TextField
                                            margin='normal'
                                            type='text'
                                            name="contactLastName"
                                            variant="outlined"
                                            fullWidth
                                            style={{"height":"44px"}}
                                            onChange={this.handleChange}
                                            error={this.state.errors.contactLastName}
                                            helperText={this.state.errors.contactLastName}
                                        />
                                    </div>
                                </FormControl>
                                <FormControl className="field-and-label" error={this.state.errors.contactDob}>
                                    <label>DOB</label>
                                    <div style={{"width":"70%"}}>
                                        <TextField
                                            margin='normal'
                                            type='date'
                                            name="contactDob"
                                            variant="outlined"
                                            fullWidth
                                            size='small'
                                            onChange={this.handleChange}
                                            error={this.state.errors.contactDob}
                                            helperText={this.state.errors.contactDob}
                                        />
                                        <FormHelperText>{this.state.errors.contactDob}</FormHelperText>
                                    </div>
                                </FormControl>
                                <FormControl className="field-and-label">
                                    <label>Email</label>
                                    <div style={{"width":"70%"}}>
                                        <TextField
                                            margin='normal'
                                            type='text'
                                            name="contactEmail"
                                            variant="outlined"
                                            fullWidth
                                            style={{"height":"44px"}}
                                            onChange={this.handleChange}
                                            error={this.state.errors.contactEmail}
                                            helperText={this.state.errors.contactEmail}
                                        />
                                    </div>
                                </FormControl>
                                <FormControl className="field-and-label">
                                    <label>Phone</label>
                                    <div style={{"width":"70%"}}>
                                        <TextField
                                            margin='normal'
                                            type='text'
                                            name="contactPhone"
                                            variant="outlined"
                                            fullWidth
                                            style={{"height":"44px"}}
                                            onChange={this.handleChange}
                                            error={this.state.errors.contactPhone}
                                            helperText={this.state.errors.contactPhone}
                                        />
                                    </div>
                                </FormControl>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{'justifyContent':'flex-end'}}>
                        <Tooltip title={this.state.formInvalid ? "Please make sure all fields are filled and valid" : "Submit"}>
                            <span>
                                <Button color="primary" disabled={this.state.formInvalid} onClick={this.handleCustomerCreation}>Create</Button>
                            </span>
                        </Tooltip>
                        <Button color="secondary" onClick={() => this.props.closeModal('showCreateNewCustomerModal')}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        siebelCustomer: state.utility.siebelCustomer,
        createSuccess: state.utility.createCustomer.isCustomerCreationSuccess,
        createFailed: state.utility.createCustomer.isCustomerCreationFailed,
        createResponse: state.utility.createCustomer.response,
        selectedEnvValue: state.auth.selectedEnvValue,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        customerCreation: (request) => dispatch(customerCreation(request)),
        findSiebelCustomer : (env, request) => dispatch(findSiebelCustomer(env, request)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomerModal);