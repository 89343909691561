import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const RoundButton = withStyles({
  root: {
    height: 58,
    width: 58,
    borderRadius: "50%",
    fontWeight: 600,
    fontSize: "large",
    border: "2px solid",
  },
})(Button);

const defaultContent = "0";

const RoundBtn = (props) => {
  const { children = defaultContent, color = "primary", onSubmit, ...rest } = props;
  return (
    <RoundButton 
      variant="outlined" 
      color={color}
      onClick={onSubmit}
      {...rest}
    >
      {children}
    </RoundButton>
  );
};

RoundBtn.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  color: PropTypes.oneOf(["default","inherit","primary","secondary"]),
};

export default RoundBtn;
