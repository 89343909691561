import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select, {components} from "react-select";
import _ from 'lodash';
import { selectSfProfile,onChangeSfProfile } from '../../../../actions/profileActions';

class ProfileSelector extends Component {

    onChangeProfile = (event) => {
        this.props.selectSfProfile(this.props.profiles.available[event.value]);
    }

    selectProfiles = () => {
        return (
            this.props.profiles.currentEnvProfiles.map(p => {
                return { label: p.name, value: p.id }
            })
        )
    }
    onChangeSfProfile = ({label, value, env}) => {
        this.props.onChangeSfProfile(env, value);
    }

    isOptionSelected = ({env, id}) => {
        return this.props.defaultSelectedProfile[env] == id;
    }
    profileOption = (profile) => {
        if (!profile) return null;
        return {value: profile.id, label: profile.name, env: profile.env, isSelected: this.isOptionSelected(profile)};
    }

    GroupHeading = (props) => (
        <div style={this.flexStyles} className="base-magenta">
            <components.GroupHeading {...props} />
            <i className="td-icon-xs icon-home theme-text-primary" aria-hidden="true" style={this.iconStyle}></i>
        </div>
    );

    flexStyles = {
        padding: '5px',
        display: 'flex',
        alignItems: 'center'
    };
    render() {
        const profiles = this
            .props
            .profiles
            .map(profile => this.profileOption(profile));
        const groupedEnvProfiles = _.groupBy(profiles, 'env');
        const profileGroups = Object.entries((groupedEnvProfiles)).map(([key,value]) => ({label: `Environment - ${key}`, options: value}));
        const activeProfile = this.profileOption(this.props.selected);
        const profilesStyles = {
            valueContainer: (provided) => ({
                ...provided,
                display: "block",
                width: "180px",
                paddingLeft: 30,
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontSize: ".8rem",
            })
        };

        return (
            <React.Fragment>
                <Select
                    options={profileGroups}
                    placeholder={"Profiles"}
                    isLoading={this.props.loading}
                    value={activeProfile}
                    styles={this.profilesStyles}
                    components={{
                        GroupHeading: this.GroupHeading,
                    }}
                    isOptionSelected={(option) => option.isSelected}
                    onChange={this.onChangeSfProfile}
                    classNamePrefix="select"
                    isSearchable={false}
                    noOptionsMessage={() => "No profiles available"}
                    />
            </React.Fragment>
        )
    }
} 

const mapStateToProps = (state) => {
    const { available, defaultSelectedProfile, loading, email } = state.profiles;
    return {
        profiles: Object.values(available), 
        defaultSelectedProfile,
        selected: available[defaultSelectedProfile], 
        loading: loading,
        email
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        selectSfProfile: (selectedProfile) => dispatch(selectSfProfile(selectedProfile)),
        onChangeSfProfile: (env, id) => {
            dispatch(onChangeSfProfile(env, id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSelector);