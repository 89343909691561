import React, { Component } from 'react'
import { connect } from 'react-redux';
import DataTilesFrame from '../accordion-frames/DataTilesFrame';
import CheckoutFrame from './sub-components/CheckoutTile';
import { getClassTypeIcon, getDataTypeIcon } from '../../../utils/ui.js';
import { checkoutVacant, assetClearCheckout } from './action';
import _ from 'lodash';
import {Alert} from 'reactstrap';
import '../Data.css';
import DataTileGroup from './sub-components/DataTileGroup';
import mockDataBankVacantData from '../../../mockFolder/mockDataBankVacantData';
import swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';

class DataBankVacantCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = { subSelected: '', selectedDataObj: {} }
    }
    componentDidMount(){
        this.scrollToBottom();
    }
    mainContainer = React.createRef();
    scrollToBottom = () => {
        var self= this;
        setTimeout((self) => {
            window.scrollTo({ top: self.mainContainer.scrollHeight + 1080, behavior: "smooth" })
        }, 100);
    }
    componentDidUpdate(){
        this.scrollToBottom();
    }
    handleSelect = (dataObj) => {
        this.props.assetClearCheckout();
        this.setState({ subSelected: `${dataObj.environment}-${dataObj.dataType}-${dataObj.resourceType}`, selectedDataObj: dataObj });
    }
    handleCheckout(environment, serviceId, qty= 1){
        const {dataType, resourceType} = this.state.selectedDataObj;
        if(!this.props.assetCheckOutLoading)
        swal({
            title: 'Checkout Confirmation',
            html: `<b>Environment:</b> ${environment}<br />
            <b>Data Type:</b> ${dataType}<br />
            <b>Technology Type:</b> ${resourceType}</br>
            <b>Quantity:</b> ${qty} Record`,
            type: 'info',
            showCancelButton: true,
            cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> Cancel',
            confirmButtonColor: '#13a129',
            confirmButtonText: '<i class="fa fa-check" aria-hidden="true"></i> Checkout',
            reverseButtons: true,
            allowOutsideClick: false,
        }).then((result) => {
            console.log(result);
            if (result.value)
            this.props.checkoutVacant(environment, serviceId, qty);
            }
        )
  }

  handleError=(assetCheckedOutError, selectedDataObj)=>{
      const mailStr = 'The data  checkout failed in '+
                     `Environment : ${selectedDataObj.environment},`+
                     `DataType: ${selectedDataObj.dataType}, `+
                     `ResourceType: ${selectedDataObj.resourceType},`+
                     `Qty: 1`
   
    swal.fire({
        type: 'error',
        title: 'Checkout Error',
        text: assetCheckedOutError,
        footer: `<a href='mailto:cc6fb024.teamtelstra.onmicrosoft.com@amer.teams.ms?Subject=Checkout Error Details&body=${mailStr}' target="_top">Report Error</a>`
      })
  }
    render() {
        const { selectedOption, checkoutOptions = [], checkedOutAsset, checkoutVacant, assetCheckOutLoading, assetCheckedOutError } = this.props;
        const { subSelected, selectedDataObj } = this.state;
        const { environment, serviceId } = selectedDataObj;
        console.log('checkoutOptions', checkedOutAsset);
        return (
            <React.Fragment>
                
            {checkedOutAsset && checkedOutAsset.checkoutId && <Redirect to={`/tdm/checkout/history/${checkedOutAsset.checkoutId}`}></Redirect>}
            
            <div ref={el=> this.mainContainer=el} className="white" id="vacant-data-form-container">
                <span className="dataview-header">
                    <h1>Download Vacant Data</h1>
                    <i className="fa fa-cloud-download fa-3x" aria-hidden="true"></i>
                </span>
                <div
                    data-role="accordion"
                    data-one-frame="false"
                    data-material="true"
                    data-on-frame-before-close="return false;"
                    style={{
                        "paddingTop": "20px"
                    }}></div>

                {<DataTileGroup
                    heading='Available Checkout Options'
                    subSelected={subSelected}
                    dataBankVacant={mockDataBankVacantData}
                    handleSelect={this.handleSelect}
                    icon='icon-sim-card'
                />}

                {subSelected != '' && <CheckoutFrame errMsg={assetCheckedOutError} loading={assetCheckOutLoading} checkout={() => this.handleCheckout(environment, serviceId, 1)} />}
                {subSelected != '' && assetCheckedOutError != "" && this.handleError(assetCheckedOutError, selectedDataObj)}   
            </div>
            </React.Fragment>
            

        )
    }


}

const mapStateToProps = (state) => {
    const { checkoutOptions, checkedOutAsset, selectedOption, assetCheckedOutError, assetCheckOutLoading } = state.dataBankVacant;

    return {
        checkoutOptions,
        selectedOption,
        assetCheckedOutError,
        assetCheckOutLoading,
        checkedOutAsset
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkoutVacant: (environment, optionTypeId, qty = 1) => dispatch(checkoutVacant(environment, optionTypeId, qty)),
        assetClearCheckout: ()=> dispatch(assetClearCheckout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBankVacantCheckout);