import moment from 'moment'

export const stringContainsAllFromArrayFilter = (string, array) => {
    return array.every(v => string.toLowerCase().includes(v.toLowerCase()));
};

export const findStringInObjectArray = (objects, param, string) => {
    if (objects) {
        const obj = objects.find(obj => obj[param] && obj[param].toString().toLowerCase() === string.toString().toLowerCase());
        return obj;
    }
};

export const filterObjectArray = (objects, param, string) => {
    if (objects) {
        return objects.filter(obj => obj[param].toString().toLowerCase() === string.toString().toLowerCase());
    }
};

export const filterObjectArrayIsInDateRange = (objects, param, start, end) => {
    if (objects) {
        return objects.filter(obj => moment(obj[param].format('DD-MM-YYYY')).isBetween(start,end));
    }
};

export const findStringInObjectArrayAndValidate = (objects, param, string, validateFunc, validateValue) => {
    if (objects) {
        return objects.find(obj => (obj[param] ? obj[param].toString().toLowerCase() === string.toLowerCase() && validateFunc(obj, validateValue) : false));
    }
};

export const stringArrayContainsAllFromArrayFilter = (stringArray, array) => {
    for (const string of stringArray) {
        if (string) {
            let isMatch = stringContainsAllFromArrayFilter(string, array);
            if (isMatch) {
                return isMatch;
            }
        }
    }
    return false;
};

export const tableRowFuzzySearch = (rowValues, filterValues) => {
    if (rowValues && rowValues.length > 0) {
        let isMatch = stringContainsAllFromArrayFilter(rowValues.join(" "), filterValues.split(" "));
        if (isMatch) {
            return isMatch;
        }
    }
    return false;
};