import React, {useState} from 'react';
import { SubHeader } from "../../../../common/custom/SubHeader";
import {Col, Row, Table} from "reactstrap";
import _ from 'lodash';
import AddressLookup from '../../../../utility/AddressLookup/AddressLookup';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactJson from "react-json-view";

export const EdgarDetails = (edgarDetailsArr, data) => {
    const [edgarDetails, setEdgarDetailsHook] = useState(null);
    const [nmiRef, setNmiHook] = useState(null);

    const setNMI = (nmi, edgarDetailsObj) => {
        setNmiHook(nmi === nmiRef ? null : nmi);
        setEdgarDetailsHook(nmi === nmiRef ? null : edgarDetailsObj)
    }
    return (

        <React.Fragment>
            {(!_.isEmpty(edgarDetailsArr) &&
                <React.Fragment>
                    <SubHeader
                        text={"Edgar Details"}
                        iconClass={"td-icon-md "}
                        iconColor="turquoise"
                        data={edgarDetailsArr.edgarDetails}
                        filename={`EdgarDetails`} />
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th>NMI</th>
                                <th>Installation Type</th>
                                <th>Control Load</th>
                                <th>Solar Type</th>
                                <th>Customer Type</th>
                                <th>Jurisdiction</th>
                                <th>Comment</th>
                            </tr>
                        </thead>
                        <tbody>
                        {edgarDetailsArr.edgarDetails.map(eachEdgar =>
                            <tr key={eachEdgar.edgarDetails[0].nmi}>
                                 <th scope="row">
                                        <div style={{float:"left"}}>
                                            <Tooltip title="Show full data">
                                                <IconButton
                                                    style={{"margin-right":"5px"}}
                                                    variant="contained"
                                                    color={nmiRef === eachEdgar.edgarDetails[0].nmi ? "secondary" : "primary"}
                                                    disabled={false}
                                                    tabIndex="4"
                                                    className="submitButton"
                                                    type="button"
                                                    size="small"
                                                    onClick={() => {
                                                        setNMI(eachEdgar.edgarDetails[0].nmi, eachEdgar.edgarDetails[0]);
                                                    }}
                                                >
                                                    {nmiRef === eachEdgar.edgarDetails[0].nmi ? <ExpandMoreIcon/> : <ChevronRightIcon/>}
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div style={{"overflow":"hidden", "textAlign":"center"}}>
                                            <div style={{paddingTop:"3px"}}>
                                                {eachEdgar.edgarDetails[0].nmi}
                                            </div>

                                        </div>

                                    </th>
                                <td>{eachEdgar.edgarDetails[0].installationType}</td>
                                <td>{eachEdgar.edgarDetails[0].controlLoad}</td>
                                <td>{eachEdgar.edgarDetails[0].solarType}</td>
                                <td>{eachEdgar.edgarDetails[0].customerType}</td>
                                <td>{eachEdgar.edgarDetails[0].jurisdictionCode}</td>
                                <td>{eachEdgar.edgarDetails[0].comment}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                    {nmiRef !== null &&
                    <div style={{transition:"all 4s linear"}}>
                        <div>
                            <div style={{"height":"75px"}}>
                                <div style={{"float":"left"}}>
                                    <h2>Edgar Details</h2>
                                </div>
                                <div style={{"float":"right", "padding-top":"10px"}}>
                                    <Tooltip title="Download Json">
                                        <span className="base-purple">
                                            <a
                                                href={`data: text/json;charset=utf-8, ${encodeURIComponent(JSON.stringify(edgarDetails, null, '\t'))}`}
                                                download={`${nmiRef}.json`}>
                                                <i className={"fa fa-file-code-o fa-2x theme-text-primary"} aria-hidden="true"></i>
                                            </a>
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                            <div>
                                <Row>
                                    <Col>
                                        <ReactJson src={edgarDetails} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    }
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
