import React, { useEffect, useState } from "react";
import { SubHeader } from "../common/custom/SubHeader";
import TableContentLoader from "../common/loader/TableContentLoader";
import { Table } from 'reactstrap';
import TDMHeader from "../cleanup/b2b/util/TDMHeading";
import { connect } from "react-redux";
import { setTitle } from "../../utils/browser";
import PaginationController from "../../utils/PaginationController";
import TilesFrame from "../Data/accordion-frames/replicator/DataTilesFrame";
import ReduxFormContainer from "../common/form/redux/ReduxFormContainer";
import { FieldArray, reset } from "redux-form";
import SomRangeInput from "./SomRangeInput";
import SomIndividualInput from "./SomIndividualInput";
import { somResourceStubRangeValidator } from "../common/form/fieldValidators";
import CheckoutFrame from "../cleanup/b2b/util/CheckoutFrame";
import swal from "sweetalert2";
import ReactDOMServer from "react-dom/server";
import FailedView from "../cleanup/b2b/util/FailedView";
import { displayLoading, customAlert, displayErrorAlert } from '../common/alerts/alerts';
import { addSomResourceStubRange, deleteSomResourceStubRange, searchSomResourceStubRange, resetSomResourceStub } from "../../actions/somResourceStubActions";
import _ from "lodash";
import { findStringInObjectArray } from '@library/core/filters/filter';
import ChipInput from 'material-ui-chip-input';
import { withStyles } from '@material-ui/core/styles';
import { SubmitButton } from "@library/core/buttons";

const StyledChipInput = withStyles(() => ({
  root: {
    border: 'none',
  },
  input: {
    border: 'none !important',
  }
}))(ChipInput);
const SomResourceStub = (props) => {
  const {history}=props;
  const [selectedResourceType, setSelectedResourceType] = useState({});
  const [selectedStubType,setSelectedStubType]=useState(null);
  const [selectedInputType, setSelectedInputType] = useState({});
  const [startRange, setStartRange] = useState(0);
  const [endRange, setEndRange] = useState(0);
  const [rangeValid, setRangeValid] = useState(false);
  const [rangesToSubmit, setRangesToSubmit] = useState();
  const [checkoutAddActive, setCheckoutAddActive] = useState(false);
  const [checkoutDeleteActive, setCheckoutDeleteActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageContent, setPageContent] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const asyncBlurFields = ["ranges[].startRange", "ranges[].endRange"];
  const asyncBlurFieldsIndividual = ["resources[].input"];
  const [CIDNResources,SetCIDNResources]=useState([]);
  const [validationError,setValidationError]=useState(false);
  const [validationLength,setValidationLength]=useState(false);
  const [uniqueResource,setUniqueResource]=useState(false);
  const [validationMsgError,setValidationMsgError]=useState(null);
  
  const pageSize = 10;
useEffect(()=>{
  console.log(CIDNResources)
},[CIDNResources])
  useEffect(() => {
    setTitle("SOM-Resource-Stub");
    setRangesToSubmit();
    setSelectedInputType({});
    setSelectedResourceType({});
    props.resetSomResourceStub();
    props.resetForm('SomResourceStub');
  }, []);

  useEffect(() => {
    props.resetSomResourceStub();
  }, [startRange, endRange, selectedInputType]);

  useEffect(() => {
    setRangesToSubmit();
    setCheckoutAddActive(false);
    setCheckoutDeleteActive(false);
    props.resetForm('SomResourceStub');
    selectedInputType.label === "Range" ? setInitialValues({ranges: [{ startRange: "", endRange: "" },]}) : setInitialValues({resources: [{ input: "" },]});
  }, [selectedInputType]);

  useEffect(() => {
    setSelectedInputType({});
  }, [selectedResourceType]);

  const { somResourceTypeFilters, somInputTypeFilters } = props;
  const StubType = [
    { label: "SOM-NAAL", icon: "icon-mobile" },
    { label: "SOM-OE", icon: "icon-mobile-device-mgmt" }
  ]
  const [StubTypeAction,setStubTypeAction]=useState([
    { label: "Add", icon: "icon-mobile" ,disabled:true},
    { label: "Delete", icon: "icon-mobile-device-mgmt",disabled:true }
  ]);

  const gotoPage = (page) => {
    setCurrentPage(page);
  }

  const onRemove = (index) => {
    if (rangesToSubmit.ranges.length === 2) {
      let newArr = rangesToSubmit.ranges;
      newArr.splice(index,1);
      setRangesToSubmit({'ranges': newArr});
      setStartRange('');
      setEndRange('');
    }
  }

  const rangeValidCheck = (value, allValues, props, name) => {
    setCheckoutAddActive(false);
    setCheckoutDeleteActive(false);
    let index = name.split('[').pop().split(']')[0];
    if (value != undefined) {
      setStartRange(allValues.ranges[index].startRange);
      setEndRange(allValues.ranges[index].endRange);
      setRangesToSubmit({'ranges': allValues.ranges});
    }
    let error = somResourceStubRangeValidator(value, allValues, props, name, "range");
    if (error) {
      setRangeValid(false);
    } else {
      setRangeValid(true);
    }
    return error;
  }

  const resourceValidCheck = (value, allValues, props, name) => {
    let index = name.split('[').pop().split(']')[0];
    const submitData = {ranges: allValues.resources.map(resource => {return resource.input})};
    if (submitData.ranges.length === 1) {
      setCheckoutAddActive(false);
      setCheckoutDeleteActive(false);
    }
    if (value != undefined) {
      setStartRange(allValues.resources[index].input);
      setEndRange(allValues.resources[index].input);
      setRangesToSubmit(submitData);
    }
    let error = somResourceStubRangeValidator(value, submitData, props, name, "individual");
    if (error) {
      setRangeValid(false);
    } else {
      setRangeValid(true);
    }
    return error;
  }

  const findIfObjectArrayOnlyContainsValue = (objects, param, string) => {
    if (objects) {
      let ans = objects.filter(obj => obj[param].toString().toLowerCase() !== string.toString().toLowerCase());
      if (ans.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  const handleOnSearch = async () => {
    if (rangeValid) {
      props.searchSomResourceStubRange({startRange: startRange, endRange: endRange, page: 0, size: 50, searchType: "range"}).then((resp) => {
        let arrayOfPages = _.chunk(resp.content, pageSize);
        setPageContent(arrayOfPages);
        const success = resp && !resp.failed;
        if ((!rangesToSubmit || !rangesToSubmit.ranges || rangesToSubmit.ranges.length < 1 || !rangesToSubmit.ranges[0].startRange 
          || !success || resp.content === undefined || (resp.content.length !== 0 && endRange-startRange+1 === resp.content.length)) && selectedInputType.label === "Range" && !findStringInObjectArray(resp.content, "status", "DELETE")) 
        {
          setCheckoutAddActive(false);
        } else {
          setCheckoutAddActive(true);
        }
        if ((!rangesToSubmit || !rangesToSubmit.ranges || rangesToSubmit.ranges.length < 1 || !rangesToSubmit.ranges[0].startRange 
          || !success || resp.content === undefined || resp.content.length === 0 || findIfObjectArrayOnlyContainsValue(resp.content, "status", "DELETE")) && selectedInputType.label === "Range") 
        {
          setCheckoutDeleteActive(false);
        } else {
          setCheckoutDeleteActive(true);
        }
      });
    }
  }

  const handleFormatRangeList = () => {
    let arr = [];
    if (rangesToSubmit && rangesToSubmit.ranges) {
      rangesToSubmit.ranges.forEach((range, index) => {
        if (selectedInputType.label === "Range") {
          arr.push(`${range.startRange} - ${range.endRange}`);
        } else {
          if (index !== rangesToSubmit.ranges.length-1) {
            arr.push(`${range} - ${range}`);
          }
        }
      });
    }
    return arr;
  }

  const submit = (view) => {
    const builtJsx = (
      <div>
        <div><span ><strong className="checkout-key">Resource Type: </strong><span>{selectedResourceType.label}</span></span></div>
      </div>
    );
    swal.fire({
      title: `${view === "Add" ? "Addition" : view} Confirmation`,
      html: ReactDOMServer.renderToStaticMarkup(builtJsx),
      type: "info",
      showCancelButton: true,
      cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> Cancel',
      confirmButtonColor: "#13a129",
      cancelButtonColor: "btn btn-danger",
      confirmButtonText: `<i class="fa fa-check" aria-hidden="true"></i> Confirm`,
      reverseButtons: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
    }).then(result => {
      if (result.value) {
        displayLoading({ title: `${view === "Delete" ? "Deleting provided " + selectedResourceType.label + " Range" : "Adding provided " + selectedResourceType.label + " Range"}`, bodyText: `${"Please standby whilst data is being " + (view === "Delete" ? "Deleted" : "Added")}`});
        const requestBody = {
          dNumber: props.user.UserName,
          resourceList: handleFormatRangeList(),
          resourceType: selectedResourceType.label,
        }
        if (view === "Delete") {
          props.deleteSomResourceStubRange(requestBody).then((response) => {
            if (response && !response.failed) {
              customAlert({title: `${selectedResourceType.label} Range deletion complete`, text: response});
              selectedInputType.label === "Range" ? handleOnSearch() : searchAllIndividual();
            } else {
              displayErrorAlert({
                title: "Error",
                subTitle: "SOM Resource Stub Deletion Failed",
                errorMsg: response.message,
              }).then(() => {
                props.resetSomResourceStub();
              });
            }
          });
        }
        if (view === "Add") {
          props.addSomResourceStubRange(requestBody).then((response) => {
            if (response && !response.failed) {
              customAlert({title: `${selectedResourceType.label} Range addition complete`, text: response});
              selectedInputType.label === "Range" ? handleOnSearch() : searchAllIndividual();
            } else {
              displayErrorAlert({
                title: "Error",
                subTitle: "SOM Resource Stub Addition Failed",
                errorMsg: response.message,
              }).then(() => {
                props.resetSomResourceStub();
              });
            }
          });
        }
      }
    });
  }

  const searchAllIndividual = () => {
    const itemList = rangesToSubmit && rangesToSubmit.ranges ? rangesToSubmit.ranges.filter(n => n !== null):[];
    props.searchSomResourceStubRange({resourceList: itemList, searchType: "individual"}).then((resp) => {
      let arrayOfPages = _.chunk(resp.content, pageSize);
      setPageContent(arrayOfPages);
      const success = resp && !resp.failed;
      if (!rangesToSubmit || !rangesToSubmit.ranges || rangesToSubmit.ranges.length < 1 
        || !success || resp.content === undefined || (resp.content.length !== 0 && rangesToSubmit.ranges.length-1 === resp.content.length) && !findStringInObjectArray(resp.content, "status", "DELETE")) 
      {
        setCheckoutAddActive(false);
      } else {
        setCheckoutAddActive(true);
      }
      if (!rangesToSubmit || !rangesToSubmit.ranges || rangesToSubmit.ranges.length < 1 || !rangesToSubmit.ranges[0] 
        || !success || resp.content === undefined || resp.content.length === 0 || findIfObjectArrayOnlyContainsValue(resp.content, "status", "DELETE")) 
      {
        setCheckoutDeleteActive(false);
      } else {
        setCheckoutDeleteActive(true);
      }
    });
  }

  const isNumeric=(str) =>{
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  const addResources=(_item)=>{
    if(_item.length>10 && _item.length<21){
      setValidationMsgError(null)
      if(isNumeric(_item)){
        let convertResource= parseInt(_item);
        if(!CIDNResources.some((_item)=>_item===convertResource)){
          setValidationError(false);
          setValidationLength(false);
          setUniqueResource(false);
          return SetCIDNResources([...CIDNResources,convertResource])
        }return setUniqueResource(true)
      }return setValidationError(true); 
    } setValidationLength(true); 
     setValidationMsgError(_item.length<10 ? "Minimum 11 char is required" : _item.length>21 ? "Maximum 20 character" :"");
     return setValidationError(false); 
  }

  const searchResources=()=>{
    let reqBody={
      resourceList:CIDNResources,
      "page": 0,
      "size": 6,
      "searchType": "individual",
    }
    props.searchSomResourceStubRange(reqBody).then((resp) => {
      if(resp.content){
        let arrayOfPages = _.chunk(resp.content, pageSize);
        setPageContent(arrayOfPages);
      if(arrayOfPages.length===0){
        return setStubTypeAction([
          { label: "Add", icon: "icon-mobile" ,disabled:false},
          { label: "Delete", icon: "icon-mobile-device-mgmt",disabled:true }
        ])
      };
      let delete_btn_status=resp.content.some((_item)=> _item.status==='DELETE');
      let add_btn_status=resp.content.some((_item)=>_item.status==='ACTIVE');
      if(delete_btn_status){
        if(add_btn_status){
          return setStubTypeAction([
            { label: "Add", icon: "icon-mobile" ,disabled:false},
            { label: "Delete", icon: "icon-mobile-device-mgmt",disabled:false }
          ])
        }return setStubTypeAction([
          { label: "Add", icon: "icon-mobile" ,disabled:false},
          { label: "Delete", icon: "icon-mobile-device-mgmt",disabled:true }
        ])
      }
      if(add_btn_status){
        if(delete_btn_status){
          return setStubTypeAction([
            { label: "Add", icon: "icon-mobile" ,disabled:false},
            { label: "Delete", icon: "icon-mobile-device-mgmt",disabled:false }
          ])
        }return setStubTypeAction([
          { label: "Add", icon: "icon-mobile" ,disabled:true},
          { label: "Delete", icon: "icon-mobile-device-mgmt",disabled:false }
        ])
      }
      setStubTypeAction([
        { label: "Add", icon: "icon-mobile" ,disabled:false},
        { label: "Delete", icon: "icon-mobile-device-mgmt",disabled:true }
      ])
      }
    })
  }

  const clickStubType=(data)=>{
    const requestBody = {
      dNumber: props.user.username,
      resourceList:CIDNResources,
      resourceType: selectedResourceType.label,
    }
     if(data.label==="Delete"){
      console.log("delete Action")
    displayLoading({ title: "Loading", bodyText: "Please standby" });
    props.deleteSomResourceStubRange(requestBody).then((response)=>{
      searchResources()
      if (response && !response.failed) {
        customAlert({title: `Deleted complete`, text: response});
      } else {
        displayErrorAlert({
          title: "Error",
          subTitle: "SOM Resource Stub Addition Failed",
          errorMsg: response.message,
        }).then(() => {
          props.resetSomResourceStub();
          searchResources()
        });
      }
    })
    deleteSomResourceStubRange
     }else{
    displayLoading({ title: "Loading", bodyText: "Please standby" });
      console.log(requestBody)
      props.addSomResourceStubRange(requestBody).then((response)=>{
        searchResources()
        if (response && !response.failed) {
          customAlert({title: `Addition complete`, text: response});
        } else {
          displayErrorAlert({
            title: "Error",
            subTitle: "SOM Resource Stub Addition Failed",
            errorMsg: response.message,
          }).then(() => {
            props.resetSomResourceStub();
            searchResources()
          });
        }
      })
      console.log("add Action")

     }
  }
  
  return (
    <div className="white">
      {/* <SubHeader
        text={`Stub Type`}
        iconClass="td-icon-md icon-managed-phone mainIcon position-static"
        iconColor="magenta"
        loading={false}
        loadingText="Loading..."
        featured
        h1
      /> */}
      <>
      <TilesFrame
           heading={"Stub Type"}
          color="green"
          data={StubType}
          select={setSelectedStubType}
          selected={selectedStubType ? selectedStubType.label : ""}
          redirectUtility={true}
          push={history?.push}
        />
        {selectedStubType && selectedStubType.label === "SOM-NAAL" ?
          <>
             <TilesFrame
           heading={"Resource type"}
          color="green"
          data={somResourceTypeFilters}
          select={setSelectedResourceType}
          selected={selectedResourceType ? selectedResourceType.label : ""}
        />
        {
          selectedResourceType.label  === "MSISDN" && 
          <TilesFrame
            heading={"Input type"}
            color="green"
            data={somInputTypeFilters}
            select={setSelectedInputType}
            selected={selectedInputType ? selectedInputType.label : ""}
          />
        }
        {
          selectedResourceType.label === "MSISDN" && selectedInputType.label &&
          <>
            <TDMHeader heading={selectedInputType.label === "Range" ? "Enter Range" : "Enter Individual Resources"}/>
            <ReduxFormContainer
                initialValues={initialValues}
                formName={"SomResourceStub"}
                onSubmit={() => { }}
                asyncBlurFields={selectedInputType.label === "Range" ? asyncBlurFields : asyncBlurFieldsIndividual}
                propsDepth={4}
                resourceType = {selectedResourceType.label}
                recordLimit={50}
            >   
              {
                selectedInputType.label === "Range" ?
                  <FieldArray
                    assignProps
                    name="ranges"
                    props={{rangeValidator: rangeValidCheck}}
                    handlePrimary={handleOnSearch}
                    primaryButton={"Search"}
                    serviceIdField={{}}
                    component={SomRangeInput}
                  />
                : 
                <FieldArray
                    assignProps
                    name="resources"
                    props={
                      {
                        resourceValidator: resourceValidCheck,
                        disablePrevRows: true, 
                        onRemove: onRemove,
                        onSearch: searchAllIndividual,
                      }
                    }
                    handlePrimary={
                      ()=>{}
                    }
                    primaryButton={"Add"}
                    secondaryButton={'Remove'}
                    serviceIdField={{}}
                    component={SomIndividualInput}
                  />
              }
            </ReduxFormContainer>
              {props.searchPending && <TableContentLoader />}
              {props.searchFailed && <FailedView message={"Could not load Numbers"} />}
              {!props.searchFailed && !props.searchPending && props.searchResources && props.searchResources.content && props.searchResources.content.length === 0 &&
                <div id="empty-content">
                  <i className="fa fa-exclamation-circle fa-2x"></i>
                  <span>No Numbers Available for this Range.</span>
                </div>
              }
              {
                !props.searchFailed && !props.searchPending && props.searchResources && props.searchResources.content && props.searchResources.content.length !== 0 &&
                <>
                  <div id="resource-table">
                    <Table>
                        <thead>
                            <tr>
                                <th>S.No </th>
                                <th>Resource Value </th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pageContent && pageContent.length && pageContent[currentPage] && pageContent[currentPage].map((resource, index) => {
                                    return (
                                        <tr key={((currentPage) * pageSize ) + (index+1)}>
                                            <td style={{ fontWeight: "bold" }}>
                                                <span className={`gradient-icon base-default`}>
                                                    <i className={`td-icon-sm icon-manage-services theme-text-primary `}></i>
                                                </span>
                                                {((currentPage) * pageSize ) + (index+1)}
                                            </td>
                                            <td>{resource.resourceName}</td>
                                            <td>
                                                <span className={`gradient-icon`}>
                                                    <i className={`td-icon-sm theme-text-primary`}></i>
                                                </span>
                                                {resource.status}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                  </div>
                </>
              }
          </>
        }
        {
            selectedResourceType.label === "MSISDN" && (props.searchPending || !props.searchFailed && props.searchResources && props.searchResources.content && props.searchResources.content.length !== 0) &&
            <PaginationController
                totalPages={props.searchResources && props.searchResources.content && props.searchResources.content.length
                   ? Math.ceil(props.searchResources.content.length/pageSize) : 0}
                gotoPage={gotoPage}
            />
        }
        {
          selectedResourceType.label === "FNN" &&
          <>
            <h5>Stub creation is not established for FNN</h5>
          </>
        }
        {
          rangesToSubmit && rangesToSubmit.ranges && rangesToSubmit.ranges.length > 0 && selectedResourceType.label === "MSISDN" && selectedInputType.label &&
          <CheckoutFrame
            heading="Action"
            checkoutOptions={[
              {
                title: "Add",
                key: "Add",
                disabled: !checkoutAddActive,
                checkout: () => submit("Add")
              },
              {
                title: "Delete",
                key: "Delete",
                disabled: !checkoutDeleteActive,
                checkout: () => submit("Delete")
              }
            ]}
          />
        }
          </>
          : selectedStubType && selectedStubType.label === "SOM-OE" ? <>
          <label>CIDN Resources <span className="optionalSpanText">(*)</span></label>
           <StyledChipInput
                                value={CIDNResources.map(chip => chip)}
                                onAdd={(chip) => addResources(chip)}
                                onDelete={(chip) => {
                                  let filter_resource=CIDNResources.filter((_item) => (_item) !== chip);
                                  SetCIDNResources(filter_resource)
                                }}
                                blurBehavior='add'
                                fullWidth={true}
                                error={validationError || validationLength || uniqueResource}
                                helperText={
                                  validationError ?
                                    'Please enter number only' : validationLength ? validationMsgError : uniqueResource ? "CIDN Resources is should unique" :null
                                }
                                placeholder={'Enter CIDN Resources'}
                            />
                            <br/>
                            <br/>
                             <SubmitButton onSubmit={searchResources} disabled={!CIDNResources.length >0} style={{background:"#007bff",color:"#fff"}}> search </SubmitButton>
                             {props.searchPending && <TableContentLoader />}
              {props.searchFailed && <FailedView message={"Could not load Numbers"} />}
              {!props.searchFailed && !props.searchPending && props.searchResources && props.searchResources.content && props.searchResources.content.length === 0 &&
                <div id="empty-content">
                  <i className="fa fa-exclamation-circle fa-2x"></i>
                  <span>No Numbers Available for this Range.</span>
                </div>
              }
              {
                !props.searchFailed && !props.searchPending && props.searchResources && props.searchResources.content && props.searchResources.content.length !== 0 &&
                <>
                {CIDNResources.length > 0 ?  <div id="resource-table">
                    <Table>
                        <thead>
                            <tr>
                                <th>S.No </th>
                                <th>Resource Value </th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pageContent && pageContent.length && pageContent[currentPage] && pageContent[currentPage].map((resource, index) => {
                                    return (
                                        <tr key={((currentPage) * pageSize ) + (index+1)}>
                                            <td style={{ fontWeight: "bold" }}>
                                                <span className={`gradient-icon base-default`}>
                                                    <i className={`td-icon-sm icon-manage-services theme-text-primary `}></i>
                                                </span>
                                                {((currentPage) * pageSize ) + (index+1)}
                                            </td>
                                            <td>{resource.resourceName}</td>
                                            <td>
                                                <span className={`gradient-icon`}>
                                                    <i className={`td-icon-sm theme-text-primary`}></i>
                                                </span>
                                                {resource.status}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
               
                  </div>:null}
                 
                </>
              }
              {CIDNResources.length > 0 ? 
                      <TilesFrame
                        color="blue"
                        data={StubTypeAction}
                        select={clickStubType}
                      />  
                      :null}
          </> :null}
         
      </>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    somResourceTypeFilters: state.somResourceStub.somResourceTypeFilters,
    somInputTypeFilters: state.somResourceStub.somInputTypeFilters,
    searchResources: state.somResourceStub.searchResources,
    searchPending: state.somResourceStub.fetchSearchPending,
    searchFailed: state.somResourceStub.fetchSearchFailed,

    somResourceStubAddition: state.somResourceStub.somResourceStubAddition,
    somResourceStubDelete: state.somResourceStub.somResourceStubDelete,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetForm: (form) => dispatch(reset(form)),
    deleteSomResourceStubRange: (request) => dispatch(deleteSomResourceStubRange(request)),
    addSomResourceStubRange: (request) => dispatch(addSomResourceStubRange(request)),
    searchSomResourceStubRange: (request) => dispatch(searchSomResourceStubRange(request)),
    resetSomResourceStub: () => dispatch(resetSomResourceStub()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SomResourceStub);
