import _ from 'lodash';
export function getSmBtnInfo(orderDetails={},serviceDetails ={}){
        
        
    if(_.isEmpty(orderDetails) || _.isEmpty(serviceDetails)){
        return {showSmBtn: false,disabled: false, tooltip:'No button'};
    }else{
        const {serviceMigrationEligible} = serviceDetails;
         const {serviceMigration} = orderDetails.length>0 && orderDetails[0];
        if(serviceMigrationEligible)
        {
            if(serviceMigration === 0)
            return {showSmBtn: true, disabled: false, tooltip: 'Service Migration is available for this Asset'}
            if(serviceMigration === 1)
            return {showSmBtn: true, disabled: true, tooltip: 'Service Migration has been done already for this Asset'}
        }
    }
        
  
    return {showSmBtn: false,disabled: false, tooltip:'No button'}
}