import React, { Component } from "react";
import { setBillingAccountNumber, generateBAN } from "../../../actions/u2cActions";
import swal from 'sweetalert2';
import { connect } from "react-redux";

const tileStyle = {
  background: "linear-gradient(to right, #13a129 0, #1d5f27 100%)"
};
class U2CTile extends Component {

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  pushToNextPage = (billingNum) => {
    this.props.history.push(`/billing/account/${billingNum}`); // after submit success push to next page
  };

  onU2cTileClick = () => {
    swal.fire({
      title: 'Do you have a Billing Account?',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.value) {
        swal.queue([{
          title: 'Usage to Cash',
          input: 'text',
          text: 'Enter your billing account number',
          inputAttributes: {
            autocapitalize: 'off',
            maxlength: 12,
          },
          showCancelButton: true,
          confirmButtonText: "Search",
          showLoaderOnConfirm: true,
          preConfirm: (billingNumber) => {
            if (!Number(billingNumber) || billingNumber.length < 12) {
              swal.showValidationMessage("Please enter 12 digit number."
              )
            } else {
              return this.props.setBillingAccountNumber(billingNumber, this.pushToNextPage)
                .then(response => {
                  if (!response) {
                    swal.showValidationMessage("Unable to Retrieve."
                    )
                  }
                })
            }
          },
          allowOutsideClick: () => !swal.isLoading()
        }])
      } else {
        swal.queue([{
          title: 'Create New Billing Account?',
          showCancelButton: true,
          confirmButtonText: "Create New",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return this.props.generateBillingNumber(this.pushToNextPage)
              .then(response => {
                if (!response) {
                  swal.showValidationMessage("Unable to create. Plesae try after some time.")
                }
              })
          },
          allowOutsideClick: () => !swal.isLoading()
        }])
      }
    })
  }

  render() {
    return (<React.Fragment></React.Fragment>);
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setBillingAccountNumber: (billingAccountNumber, pushToNextPage) => dispatch(setBillingAccountNumber(billingAccountNumber, pushToNextPage)),
    generateBillingNumber: pushToNextPage => dispatch(generateBAN(pushToNextPage)),

  };
};
export default connect(null, mapDispatchToProps)(U2CTile);


