import React, {Fragment, useState } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";
import { useEffect } from "react";
import { fetchTags } from '../../actions/retrieveDataActions';

const EditTag = (props) => {
    const {modalData} =props;
    const [datasetTags, setDatasetTags] = useState(null);
    const [oldTagName, setOldTagName] = useState("");
    const [newTagName, setNewTagName] = useState("");
    const [tagsList , setTagsList] = useState([]);

    useEffect(()=>{
        props.fetchTags(modalData?.id).then((result)=>{
            setDatasetTags(result?.data);
        })
    },[])

  const handleAddTag = () => {
    let list = [...tagsList];
    list.push({ "oldValue": oldTagName, "newValue": newTagName })
    console.log(list)
    let remainingTags = datasetTags.filter((data) => data !== oldTagName)
    setOldTagName("");
    setNewTagName("");
    setDatasetTags(remainingTags)
    setTagsList(list);
    props.handleEditTags(list)
  }

  const handleRemoveTag = (data) =>
    {
        let appliedDataTags = [...tagsList];
        let tagsAddedBack = appliedDataTags.filter((fil) => fil !== data)
        setTagsList(tagsAddedBack);
        let removedTag = [...datasetTags];
        removedTag.push({"name":data.name})
        setDatasetTags(removedTag);
        props.handleEditTags(tagsAddedBack)
    }
    if(datasetTags){
    return (
        <div class="container mt-5">
           <Fragment>
                    <div class="row">
                        <div class="col-5 customHeaders">Existing Tags</div>
                        <div class="col-5 ml-3 customHeaders">New Value</div>
                        </div>
                        {tagsList?.length > 0 ?
                            <div class="row">
                            {tagsList.map((tagData, index) => {
                                return (
                                    <div class="row" key={index}>
                                    {tagData !== "" && tagData !== undefined ?
                                    <><div class="col-5 ml-1">
                                        <TextField variant="outlined" disabled
                                            value={tagData.oldValue} className="filterNameLabel" />
                                    </div>
                                    <div class="col-5 ml-2">
                                        <TextField variant="outlined" disabled
                                            value={tagData.newValue} className="filterValueLabel" />
                                    </div>
                                    <div class="col-1 ml-n1 mt-1">
                                        <CancelIcon className="cancelIcon"
                                            onClick={e => handleRemoveTag(tagData)} />
                                    </div></> :<></>}
                                    </div>
                                )})}
                            </div>:<></>}
                        <div class="row">
                        <div class="col-5">
                            <Autocomplete
                                options={datasetTags}
                                getOptionLabel={(option) => option}
                                id="customdataFiltersAutoComplete"
                                value={oldTagName}
                                onChange={(event, value) => setOldTagName(value)}
                                autoComplete
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        className="customAutoComplete"
                                        placeholder="Select Old Tag"
                                    />
                                )}
                            /></div>
                            <div class="col-5 ml-3">
                               <TextField variant="outlined" placeholder="Enter New Tag Name"
                                            value={newTagName} className="filterValueLabel"
                                            onChange={(e) => setNewTagName(e.target.value)}/>
                            </div>
                            <div class="col-1 mt-2 ml-n1">
                               <AddIcon className="addCircleTwoToneIcon"
                                        onClick={e => handleAddTag(e)}
                                        disabled={(newTagName !== "" && oldTagName !== "" && datasetTags.length >0) ? false : true}
                                    />
                            </div>
                            </div>
                            </Fragment></div>
    )
                                }return <CircularProgress style={{marginLeft:"185px"}}/>
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTags: (reqBody) => dispatch(fetchTags(reqBody)),
    }
}

export default connect(null, mapDispatchToProps)(EditTag);