import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../common/Tabs/TabPanel';
import { Button } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import './s2p.css';

function allProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '600px',
  },
  tab : {
    verticalAlign: 'middle',
    fontWeight:'bold',
    backgroundColor:"aliceblue",
    wordBreak: 'break-all'
  },
  tabpanel: {
        width : '100%',
  }
}));

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
            <div className="col-sm-2">
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs"
                    className={classes.tabs}>
                        { 
                            props.navItemList.map((tab, index) => {
                                return <Tab className = {classes.tab} 
                                            label={tab} 
                                            {...allProps(index)} 
                                            key={index}
                                        />
                            })
                        }           
                </Tabs>
            </div>
            <div className="col-sm-10">
              <form className="white"> 
                {
                  props.bodyItemList.map((tab, index) => {
                    return <TabPanel className={classes.tabpanel} value={value} index={index} key={index}>
                        {tab}
                    </TabPanel>
                  })
                }
                {
                  props.isForm ? (
                    <Tooltip title={props.submitDisabled ? "Can not submit empty forms" : "Submit forms"} style={{'width':'100%'}}>
                      <span style={{'margin': '0 auto'}}>
                        <Button className="s2p-form-btn" color="primary" type="button" disabled={props.submitDisabled} onClick={() => props.formOnSubmit()}>
                            {props.formSubmitBtnText ? props.formSubmitBtnText : 'Submit all forms'}
                        </Button>
                      </span>
                    </Tooltip>
                  ) : null
                }
              </form>
            </div>
    </div>
  );
}