import React, { Component, Fragment } from 'react'
import { submitMsisdnLoad, submitSimImsiPreseeding, updatePhysicalSimMisdnList, updatePhysicalSimList, clearMsisdnResponse } from '../../../actions/utilityActions';
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from 'redux-form';
import { displayLoading, displaySuccess, displayWarning } from "../../common/alerts/alerts";
import Tooltip from '@material-ui/core/Tooltip';
import RcrExpandableInfoSection from './RcrExpandableInfoSection';
import './RcrFileProcess.css';
import { asyncFormImsiAvailabilityValidator as asyncValidate } from '../../common/form/asyncValidators';
import swal from 'sweetalert2';
import RcrSubmitForm from '../../utility/RcrSubmitForm';
import Paper from '@material-ui/core/Paper';
import { Button } from "@material-ui/core";
import HeaderContent from '../../common/Header/HeaderContent';

class RcrProcessSetupUtility extends Component {
    state = {
        currentPage: 'setupPage',
        submitDisabled: true,
        readTerms: false,
    };
    switchScreen = (page) => {
        this.setState(prevState => ({
            ...prevState,
            currentPage: page,
            submitDisabled: true
        }));
    }

    enableSubmit = (bool) => {
        this.setState({
            ...this.state,
            submitDisabled: bool,
        });
    }

    setReadTerms = (bool) => {
        this.setState({
            ...this.state,
            readTerms: bool,
        });
    }

    render() {
        const {history} =this.props;
        return (
            <div className="white">
                 <HeaderContent
                title={`Rcr Processing Utility`}
                icon="cogs ops-icon"
                empty={true}
                redirectUtility={true}
                push={history?.push}
               ></HeaderContent>
                <div className="content">
                    <Paper elevation={1} className="FormWrapper">
                        <RcrSubmitForm />
                    </Paper>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: state.form.RcrForm,
        auth: state.auth,
        msisdnPending: state.utility.physicalSimPreceeding.isSubmitMsisdnPending,
        msisdnFailed: state.utility.physicalSimPreceeding.isSubmitMsisdnFailed,
        msisdnSuccess: state.utility.physicalSimPreceeding.isSubmitMsisdnSuccess,
        msisdnResponse: state.utility.physicalSimPreceeding.msisdnResponse,
        simPending: state.utility.physicalSimPreceeding.isSubmitSimPending,
        simFailed: state.utility.physicalSimPreceeding.isSubmitSimFailed,
        simSuccess: state.utility.physicalSimPreceeding.isSubmitSimSuccess,
        simResponse: state.utility.physicalSimPreceeding.simResponse,

        msisdnList: state.utility.physicalSimPreceeding.msisdnList,
        simList: state.utility.physicalSimPreceeding.simList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        submitMsisdnLoad: (request) => dispatch(submitMsisdnLoad(request)),
        submitSimImsiPreseeding: (request) => dispatch(submitSimImsiPreseeding(request)),
        clearMsisdnResponse: () => dispatch(clearMsisdnResponse()),

        updatePhysicalSimMisdnList: (list) => dispatch(updatePhysicalSimMisdnList(list)),
        updatePhysicalSimList: (list) => dispatch(updatePhysicalSimList(list)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: "RcrForm",
        initialValues: {
            'sim order type': "3GUniversal_BigPond",
            'profile id': "0250.5103",
            'sim artwork': "0061 0608",
        },
        asyncValidate,
}))(RcrProcessSetupUtility);
