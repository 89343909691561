import './App.css';
import './assets/css/loading.css';
import './assets/css/loading-btn.css';

import { Col, Container, Row } from 'reactstrap';
import React, { Component } from 'react';
import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import BackgroundSlideshow from 'react-background-slideshow'
import Header from './components/common/Header/Header';
import Routes from './routes';
import TelstraStyles from './telstraStyles';
import { library } from '@fortawesome/fontawesome-svg-core';
import oneTelstra from './assets/images/backgrounds/onetelstra.webp';
import telstraAir from './assets/images/backgrounds/telstraAir.jpg';
import telstraAir2 from './assets/images/backgrounds/telstraAir2.jpg';
import telstraAir3 from './assets/images/backgrounds/telstraAir3.jpg';
import telstraInsightCentre from './assets/images/backgrounds/telstraInsightCentre.jpg';
import telstraInsightCentre2 from './assets/images/backgrounds/telstraInsightCentre2.jpg';
import telstraOffice from './assets/images/backgrounds/telstraOffice.jpg';
import telstraOrange from './assets/images/backgrounds/telstraOrange.jpg';
import { withRouter } from 'react-router';
import './components/common/Footer/UserFeedbackDial.css'

library.add(faCheck, faTimesCircle)

class App extends Component {
    images = [
        oneTelstra,
        telstraAir,
        telstraAir2,
        telstraOffice,
        telstraInsightCentre,
        telstraOrange,
        telstraInsightCentre2,
        telstraAir3
    ];
    render() {
        return (
            <React.Fragment>
                <TelstraStyles />
                <BackgroundSlideshow
                    className
                    animationDelay={60000}
                    images={this.images} /> {/* <span className="spectrum" styles={{"height": "439px", "visibility": "visible"}}></span> */}
                <Container fluid={true}>
                    <Row>
                        <Col
                            xs="12"
                            style={{
                                "padding": "0px"
                            }}>
                            <Header></Header>
                        </Col>
                    </Row>
                </Container>
                <Container id="main-content">
                      <Row>
                        <Col xs="12">
                            <Routes></Routes>
                        </Col>
                    </Row>
                </Container>
                <Container fluid={true}>
                    <Row>
                        <Col xs="11">
                        </Col>
                        <Col xs="1">
                        </Col>
                    </Row>
                </Container>
                {/* <span
                    className="spectrum spectrum-bottom img-hor-vert"
                    styles={{
                    "visibility": "visible"
                }}></span> */}
                {/* <SweetAlert /> */}
                {/* <div className="landing-box testimonialBox">
                            <TestimonialCard/>
                        </div> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return { auth: state.auth }
}

export default (withRouter(App));