import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    tourRun: false,
    tourEngaged: false,
    tourStepIndex: 0,
    tourSection: 0,
    tourSections: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.TOGGLE_TOUR_RUN:
            return {
                ...state,
                tourRun: action.bool
            }
         case types.RESUME_TOUR:
                return {
                    ...state,
                    tourRun: true,
                    tourStepIndex: action.step,
                    tourSection: action.section
                }
        case types.INITIATE_TOUR:
           return {
                    ...state,
                    tourRun: true,
                    tourEngaged: true,
                    tourStepIndex: action.index,
                    tourSections: action.sections
                }
        case types.CHANGE_TOUR_SECTION:
           return {
                    ...state,
                    tourRun: false,
                    tourEngaged: false,
                    tourStepIndex: 0,
                    tourSection: action.section
                }
        case types.CHANGE_TOUR_STEP:
           return {
                    ...state,
                    tourRun: action.run,
                    tourStepIndex: action.stepIndex
                }
        case types.FINISH_TOUR:
           return {
                    ...state,
                    tourRun: false,
                    tourEngaged: false,
                    tourStepIndex: 0
                }
        default:
            return state;
    }
}
