import {axiosInstance as axios} from "../axios.config";
import * as types from './actionTypes';
import config from '../config';
import { authHeaders } from '../utils/api';
import { getSelectedEnvValue } from "../components/auth/functions";
import { getSFConf, isB2bUser } from '../utils/userProfile';

const GATEWAY_URL = config.tdm_gateway_api.uri;

export const fetchSfProfiles = () => async (dispatch, getState) => {
    dispatch({ type: types.LOAD_USER_SF_PROFILES_LOADING, loading: true });
    try {
        const {currentEnvironemt, currentRoles} = getSFConf(getState().auth);
        const userId = getState().auth.userName;
        const response = isB2bUser(currentRoles) ?
            await axios.get(`${GATEWAY_URL}/tdm/salesforce/b2b/profiles/${userId}`, { headers: authHeaders() }) :
            { data: { sfProfiles: [] } } //TODO B2C API needs to be added once backend implemented

        dispatch({ type: types.LOAD_USER_SF_PROFILES_SUCCESS, payload: { sfProfiles: response.data.sfProfiles, env: currentEnvironemt } });
        
    } catch (e) {
        console.error(e);
        dispatch({ type: types.LOAD_USER_SF_PROFILES_FAIL })
    }
    return false;
}

export const selectSfProfile = (selectedProfile) => dispatch => {
    dispatch({ type: types.SELECT_USER_SF_PROFILE, payload: selectedProfile });
}

export const onChangeEnvironment = (env) => (dispatch, getState) => {
    const environment = getSelectedEnvValue(env);
    dispatch({ type: types.CHANGE_SIDEPANEL_ENVIRNOMENT, payload: { env: environment , envProfiles: getState().profiles.envProfiles } })
}

export const onChangeSfProfile = (env, id) => async (dispatch, getState) => {
    dispatch({type: types.CHANGE_USER_SF_PROFILE, id, env, envProfiles: getState().profiles.envProfiles});
}