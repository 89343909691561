import config from '../config';
import {getTokenFromCookie} from "../utils/browser"
import { checkIfHasBeenXMillisecondsSinceTime } from './validation';

const API_URL = config.api.uri;
const GATEWAY_URL = config.tdm_gateway_api.uri;

export function getDataBankRequestParam(pageNum, teamId){
    let reqParam='';
    if(pageNum)
    reqParam=reqParam+`&page=${pageNum}`
    if(teamId)
    reqParam=reqParam+`&team=${teamId}`
  return reqParam;
}

export const authHeaders = (headers = {}, useTaasAlso = false) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("access_token") || getTokenFromCookie("authorization_token") || (useTaasAlso ? getTokenFromCookie("TaaSAccessToken") : "")}`,
        'X-Method-Type': 'METHOD_PORTAL',
        'Channel-Type' : 'UI',
        'Access-Control-Allow-Origin' : '*',
          ...headers 
    }
}

export const taasAuthSigninHeaders = () => {
    return {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic UnNhQXV0aG9yaXphdGlvbjo='
    } 
}

export const taasAuthHeaders = (token) => {
    return {
        'Content-Type': 'application/json',
        'tassToken': token,
        'X-Method-Type': 'METHOD_PORTAL',
        'Channel-Type' : 'UI'
    } 
}

export const periodicCheckAndCallIfFail = (data, loading, failed, failedTime, func, timeToCallAfterFailure) => {
    if ((!data || data === "") && !loading && (!failed || (failedTime && checkIfHasBeenXMillisecondsSinceTime(failedTime, timeToCallAfterFailure)))) {
        func();
    }
}
