import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HeaderContent from "../common/Header/HeaderContent";
import "./customDataset.css";
import {
  makeStyles,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  createDatasetData,
  checkInDataset,
  setEditableData,
  submitConfiguredfilters,
  checkInData,
  editDatasetData,
  clearFilters,
  setExcelData,setCheckinDocs
} from "../../actions/retrieveDataActions";
import { displayLoading,displayErrorNew } from "../common/alerts/alerts";
import swal from "sweetalert2";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../B2BDataCleanup/b2b.css";
import Stepper from "./stepper";
import CheckinDataSummary from "./CheckinDataSummary";
import ConfigureFilter from "./ConfigureFilter"
import { EnvirnomentLabels } from '../auth/functions';

const useStyles = makeStyles({
  headerLabel: {
    color: "#666f73",
    fontWeight: "400",
    opacity: "0.9",
    fontSize: "15px",
  },
  headerToggleGroup: {
    height: "65px !important",
    border: "1px solid #cfcfcf",
  },
});

const CheckinDataset = (props) => {
  const { history, ...rest } = props;
  const classes = useStyles();
  const [name, setName] = (props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? useState(props.editableDataset.name) : useState("");
  const [isNameError, setIsNameError] = useState(false);
  const [desc, setDesc] =  (props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? useState(props.editableDataset.description) : useState("");
  const [isDescError, setIsDescError] = useState(false);
  const [isTeamsError, setIsTeamsError] = useState(false);
  const [env, setEnv] = React.useState({name: Object.keys(EnvirnomentLabels).find(key => EnvirnomentLabels[key] === props.selectedEnvValue), value: props.selectedEnvValue});
  const [scope, setScope] = (props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? useState(props.editableDataset.scope) : useState("");
  const [team, setTeam] = React.useState("");
  const [teamId, setTeamId] = React.useState("");
  const [quantity, setQuantity] = useState(1);
  const [isQuantityError, setIsQuantityError] = useState(false);
  const [disableCheckin, setDisableCheckin] = (props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? useState(false) : useState(true);
  const [teams, setTeams] = useState([]);
  const [navigateToCheckin, setNavigateToCheckin] = useState();
  const [clearJSON ,setClearJSON] = useState(false);
  const [disableNext , setDisableNext] = useState(false)
  const [filters,setFilters]=useState([]);
  const [duplicateFilterCheck,setDuplicateFilter]=useState(false);

  useEffect(() => {
    props.myAvailableTeams.map((team) => teams.push(team.teamName));
  }, [props.myAvailableTeams]);

  useEffect(() => {
    if (props.editableDataset !== undefined && Object.keys(props.editableDataset).length == 0)
    setScope("PRIVATE");
    else {
      if (props.editableDataset.scope == "PRIVATE")
        setScope("PRIVATE")
      else { 
        if(props.editableDataset.scope == "TEAM")
        {
          setTeam(props.editableDataset.team.name);
          setTeamId(props.editableDataset.team.teamId)
        }
        setScope(props.editableDataset.scope) 
      }
    }
    props.setExcelData({});
    setEnv({name: Object.keys(EnvirnomentLabels).find(key => EnvirnomentLabels[key] === props.selectedEnvValue), value: props.selectedEnvValue});
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const names = ["INT2", "INT1", "PRE_PROD"];
  const stepList = [(props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ?  "Edit Data Set" : "Create Data Set",
  "Checkin Data", 
  (props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? "Re-Configure Filters" : "Configure Filters"];
  
  const handleScopeChange = (e) => {
    setScope(e.target.value);
    setTeam("");
    setTeamId("");
    setIsTeamsError(false);
    if (e.target.value === "TEAM") {
      setDisableCheckin(true);
    } else {
      if (name !== "" && desc !== "") {
        setDisableCheckin(false);
      } else {
        setDisableCheckin(true);
      }
    }
  };
  
  const resetDisableNext = () => {
    setDisableNext(true)
  }
  const setClearJsonData = () => {
    setClearJSON(!clearJSON)
  }
  const  resetData = () => {
    setNavigateToCheckin(false);
  }
  const handleTeamChange = (e, value) => {
    setTeam(value);
    const team = props.allTeams.filter((team) => team.teamName === value);
    setTeamId(team[0].teamId);
    if (
      scope === "TEAM" &&
      (value == undefined || value == null || value == "")
    ) {
      setIsTeamsError(true);
      setDisableCheckin(true);
    } else {
      setIsTeamsError(false);
      if (name !== "" && desc !== "") {
        setDisableCheckin(false);
      } else {
        setDisableCheckin(true);
      }
    }
  };

  const editData = (data) => {
    props.setCheckinDocs({})
    displayLoading({ title: "Editing dataSet", bodyText: "Please standby" });
    let checkInData = {};
    checkInData.datasetName= name;
    checkInData.description= desc;
    checkInData.environment= env.value;
    checkInData.scope =scope;
      if(scope === "TEAM")
      {
        checkInData.team = {
            id: teamId,
            name: team,
          }
        }
    
    const checkinId = props.customDataset.id !== undefined ? props.customDataset.id : "";
    props.editDatasetData(checkInData,checkinId).then((response) => {
      if(response == true)
      {
        var checkedDataset = [checkInData];
        props.checkInDataset(checkedDataset);
        swal.close();
        swal.fire({
          title: 'Dataset edited sucessfully',
          text: "Do you want to check-in?",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#3085d6',
          cancelButtonText: 'I will do it later',
          confirmButtonText: 'Continue'
        }).then((result) => {
          if (result.value) {
            setNavigateToCheckin(true);
            //history.push({ pathname: `/dataset/${response.dataSetId}/checkin` });
          }else{
            setNavigateToCheckin(false);
            history.push({ pathname: "/dataset/retrieve" });
          }
        })


      }})
  }
  const createData = (data) => {
    props.setCheckinDocs({})
    displayLoading({ title: "Creating a dataSet", bodyText: "Please standby" });
    setTimeout(() => {
      var checkInData = {
        name: name,
        description: desc,
        environment: env.value,
        scope: scope,
        team: {
          id: teamId,
          name: team,
        },
        datasetType:props.datasetPageType || "BYOD"
      };
      props.createDatasetData(checkInData).then((response) => {
        if(response)
        {
        var checkedDataset = [checkInData];
        props.checkInDataset(checkedDataset);
        if(props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0)
        {
          setName(props.editableDataset.name)
          setDesc(props.editableDataset.datasetDescription);
        }
        else
        {
          setName("");
          setDesc("");
          setEnv({name: Object.keys(EnvirnomentLabels).find(key => EnvirnomentLabels[key] === props.selectedEnvValue), value: props.selectedEnvValue});
          setQuantity(1);
          setScope("");
          setTeam(""),
          setTeamId("")
          setDisableCheckin(true);
        }
            swal.close();
            setNavigateToCheckin(true);
            setDisableCheckin(true);
        }
      });
    }, 1000);
  };

  const updateFilters = () => {
   setDuplicateFilter(false);
   if(filters !== {} && filters.length > 0){ 
    displayLoading({ title: "Configuring filters", bodyText: "Please standby" });
    const checkinId = props.customDataset.id !== undefined ? props.customDataset.id : "";
    let removeShowErrorFilter=filters.map((item)=>{
      delete item["showError"];
      return item;
    });
    var checkInData = {
        name: name,
        description: desc,
        environment: env.value,
        scope: scope,
        team: {
          teamId: teamId,
          teamName: team,
        },
        filters: removeShowErrorFilter,
        tags: [],
      };
      props.submitConfiguredfilters(checkInData,checkinId).then((response) => {
        if(response){
        var checkedDataset = [checkInData];
        props.checkInDataset(checkedDataset);
        setName("");
        setDesc("");
        setEnv({name: Object.keys(EnvirnomentLabels).find(key => EnvirnomentLabels[key] === props.selectedEnvValue), value: props.selectedEnvValue});
        setQuantity(1);
        setScope("");
        setTeam(""),
        setTeamId("")
        setDisableCheckin(true);
        swal.close();
        swal.fire({
          title: 'Dataset filters updated successfully..',
          showCancelButton: false,
        })
        props.setEditableData({});
        props.clearFilters();
        props.history.push(`/dataset/retrieve`);
        }
      });}
      else
      {
        setName("");
        setDesc("");
        setEnv({name: Object.keys(EnvirnomentLabels).find(key => EnvirnomentLabels[key] === props.selectedEnvValue), value: props.selectedEnvValue});
        setQuantity(1);
        setScope("");
        setTeam(""),
        setTeamId("")
        setDisableCheckin(true);
        setEditableData({});
        props.clearFilters()
        props.history.push(`/dataset/retrieve`);
      }
  

  };

  const handleOnNameFieldBlur = (e) => {
    if (
      e.target.value == undefined ||
      e.target.value == null ||
      e.target.value == ""
    ) {
      setIsNameError(true);
      setDisableCheckin(true);
    } else {
      setIsNameError(false);
      if (desc !== "" && ((scope === "TEAM" && team !== "") || (scope !== "TEAM" && team === ""))) {
        setDisableCheckin(false);
      } else {
        setDisableCheckin(true);
      }
    }
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDesc(e.target.value);
  };
  const handleQuantityChange = (e) => {
    if (
      e.target.value == undefined ||
      e.target.value == null ||
      e.target.value < 1
    ) {
      setIsQuantityError(true);
      setQuantity(1);
    } else {
      setIsQuantityError(false);
      setQuantity(e.target.value);
    }
  };
  const handleDescriptionFieldBlur = (e) => {
    if (
      e.target.value == undefined ||
      e.target.value == null ||
      e.target.value == ""
    ) {
      setIsDescError(true);
      setDisableCheckin(true);
    } else {
      setIsDescError(false);
      if (desc !== "" && ((scope === "TEAM" && team !== "") || (scope !== "TEAM" && team === ""))) {
        setDisableCheckin(false);
      } else {
        setDisableCheckin(true);
      }
    }
  };

  const handleTeamsFieldBlur = (e) => {
    if (
      scope === "TEAM" &&
      (e.target.value == undefined ||
        e.target.value == null ||
        e.target.value == "")
    ) {
      setIsTeamsError(true);
      setDisableCheckin(true);
    } else {
      setIsTeamsError(false);
      if (name !== "" && desc !== "") {
        setDisableCheckin(false);
      } else {
        setDisableCheckin(true);
      }
    }
  };

  const handleEnvChange = (e) => {
    setEnv(e.target);
  };
  
 
  const handleCheckin = (nextFn) => {
    let payload =  [...props.checkedInJsonDocs]
    const selectedTeam = props.myAvailableTeams.filter((team) => team.teamId === props.teamID)
    let checkinData = {
      "team": {
        "id": props.teamID,
        "name": selectedTeam[0].teamName
      },
      "payload": payload
    };
    const checkinId = props.customDataset.id !== undefined ? props.customDataset.id : "";

    displayLoading({ title: "Checking in Data", bodyText: "Please standby" });
    props.checkInData(checkinData, checkinId).then((response) => {
      if (response !== undefined && response.data !== undefined) {
              swal.close();
            nextFn()
        }
        else {
            let errorMessage = props.checkinErrorMsg;
            displayErrorNew({
              title: "Dataset Checkin Error",
              errorMessage
            })
        }
      });
  }

  const clearExcel = () => 
  {
    props.setExcelData({})
  }

  const handleDeleteFilter=(chip)=>{
    setDuplicateFilter(false);
    let newFilters = filters.filter(_item => _item.name !== chip);
    let removeShowErrorFilter=newFilters.map((item)=>{
      delete item["showError"];
      return item;
    });
    setFilters(removeShowErrorFilter);
  }

  const checkDuplicateFilter=(getFindIndex)=>{
     let newFilters=[...filters];
     newFilters[getFindIndex]["showError"]=true;
    setDuplicateFilter(true);
    return setFilters(newFilters);
  }

  const checkTagData=(selectedValue)=>/^-?\d+$/.test(selectedValue);
  
  const handlesubmitFilters= (selectedData,manuval)=>{
    if(manuval){
      let getFindIndex=_.findIndex(filters,selectedData);
      if(getFindIndex===-1){
        setDuplicateFilter(false);
        let newFilters = [...filters, selectedData];
        let removeShowErrorFilter=newFilters.map((item)=>{
          delete item["showError"];
          return item;
        });
        return setFilters(removeShowErrorFilter);
      } return checkDuplicateFilter(getFindIndex);
    }
    if(!checkTagData(selectedData.name)){
      selectedData.namespace.splice(0,2);
      let filterPath=selectedData.name;
      selectedData.namespace.push(filterPath);
      let filterPathWithDot=selectedData.namespace.join('.');
      let filterObject={name:filterPath,path:filterPathWithDot}
      let newFilters = [...filters, filterObject]
      let uniqueFilter=_.uniqBy(newFilters, function (e) {
        return e.name;
      });
      setFilters(uniqueFilter);
    }
    }
    const ConfigureFilterProps={
      updateFilters,
      filters,
      handleDeleteFilter,
      handlesubmitFilters,
      duplicateFilterCheck
    }
    const CheckinDataSummaryProps={
      handleCheckin,
      clearJSON,
      setClearJson:setClearJsonData,
      resetDisableNext,
      filters,
      setFilters,
      handleDeleteFilter,
      handlesubmitFilters
    }
  return (
    <div className="white" id="">
      <HeaderContent
        title={(props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? `Edit Dataset` : `Create Dataset`}
        icon="database training-icon "
        empty={true}
        loadingText="Loading Check-in Data..."
      ></HeaderContent>
      <Stepper
        steps={stepList}
        checkedInJsonDocs={props.checkedInJsonDocs}
        clearExcel={clearExcel}
        stepButtons={["Create","Checkin","Submit Filters"]}
        customDataStepper={true}
        updateFilters={updateFilters}
        createData={createData}
        editData={editData}
        disableCheckin={disableCheckin}
        disableNext = {disableNext}
        handleCheckin={handleCheckin}
        editableDataset={props.editableDataset}
        resetData={() => resetData()}
        navigateToCheckin = {navigateToCheckin}
        stepsComponents={[
          <div class="checkinContainer container">
            <div class="row">
              {" "}
              <div class="col-1"></div>
              <div class="col-5">
                <TextField
                  label="Name"
                  value={name}
                  required={true}
                  fullWidth
                  error={isNameError}
                  helperText={
                    !isNameError ? "" : "Name of the dataset cannot be empty"
                  }
                  onBlur={(e) => handleOnNameFieldBlur(e)}
                  onChange={(e) => handleNameChange(e)}
                  variant="outlined"
                />{" "}
              </div>
              <div class="col-5">
                <FormControl fullWidth>
                  <InputLabel id="environment">Environment</InputLabel>
                  <Select
                    labelId="environment"
                    required={true}
                    value={env.value}
                    name={env.name}
                    label="Environment"
                    onChange={handleEnvChange}
                    disabled={(props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? true : false}
                  >
                    {Object.keys(EnvirnomentLabels).map((env) => (
                      <MenuItem key={env.name} value={EnvirnomentLabels[env]}>
                        {env}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-1"></div>
              <div class="col-5">
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  value={desc}
                  required={true}
                  error={isDescError}
                  multiline
                  fullWidth
                  rows={6}
                  helperText={
                    !isDescError
                      ? ""
                      : "Description for the dataset cannot be empty"
                  }
                  onChange={(e) => handleDescriptionChange(e)}
                  onBlur={(e) => handleDescriptionFieldBlur(e)}
                  variant="outlined"
                />
              </div>

              <div class="col-6">
              <><b>DatasetType :</b>{props.datasetPageType}</><br/><br/>
                <ToggleButtonGroup
                  value={scope}
                  color="primary"
                  exclusive
                  onChange={(e) => handleScopeChange(e)}
                  aria-label="scope"
                  classes={{ root: classes.headerToggleGroup }}
                  className="scopeToggleButtonGroup"
                  disabled={(props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? true : false}
                >
                  <ToggleButton value="PRIVATE" aria-label="centered"
                  classes={{ root: classes.headerLabel }}>
                      PRIVATE
                  </ToggleButton>
                  <ToggleButton value="TEAM" aria-label="left aligned"
                  classes={{ root: classes.headerLabel }}>
                    TEAM{" "}
                  </ToggleButton>
                </ToggleButtonGroup>
                <Autocomplete
                style={{width:"325px"}}
                  id="teams"
                  disabled={scope !== "TEAM" || (props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0)}
                  options={teams}
                  getOptionLabel={(option) => option}
                  value={team}
                  onChange={(event, value) => handleTeamChange(event, value)}
                  onBlur={(e) => handleTeamsFieldBlur(e)}
                  renderInput={(params) => (
                    <TextField
                      error={isTeamsError}
                      helperText={
                        !isTeamsError
                          ? ""
                          : "Team cannot be empty for TEAM scope"
                      }
                      {...params}
                      variant="standard"
                      placeholder="Select Team name"
                    />
                  )}
                />
              </div>
            </div>
            </div>,
          <div class="checkinContainer container">
            <CheckinDataSummary {...CheckinDataSummaryProps}/>
          </div>,
          <ConfigureFilter {...ConfigureFilterProps}/>
        ]}
        push={props?.history?.push}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
   return {
    checkedOutData: state.retrieveData.checkedOutData,
    checkedInData: state.retrieveData.checkedInData,
    allTeams: state.teams.teams.allTeams,
    isAdmin : state.auth.isAdmin,
    selectedEnvValue : state.auth.selectedEnvValue,
    customDataset: state.retrieveData.customDataset,
    editableDataset : state.retrieveData.editableDataset,
    filtersConfigured : state.retrieveData.filtersConfigured,
    checkinStatus: state.retrieveData.checkinStatus,
    excelResponsemessage : state.retrieveData.excelResponsemessage,
    excelResponseCount : state.retrieveData.excelResponseCount,
    checkinErrorMsg: state.retrieveData.errorMsg,
    teamID: state.auth.team,
    myAvailableTeams: state.auth.myAvailableTeams,
    checkedInJsonDocs : state.retrieveData.checkedInJsonDocs,
    datasetPageType:state.retrieveData.datasetPageType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocation: (path) => dispatch(setLocation(path)),
    createDatasetData: (data) => dispatch(createDatasetData(data)),
    checkInDataset:(data) => dispatch(checkInDataset(data)),
    setEditableData:(data) => dispatch(setEditableData(data)),
    submitConfiguredfilters:(data,checkinID) => dispatch(submitConfiguredfilters(data,checkinID)),
    checkInData: (data, id) => dispatch(checkInData(data, id)),
    editDatasetData:(data,id) => dispatch(editDatasetData(data,id)),
    clearFilters:() => dispatch(clearFilters()),
    setExcelData : (data) => dispatch(setExcelData(data)),
    setCheckinDocs : (data) => dispatch(setCheckinDocs(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckinDataset);