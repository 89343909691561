import React, { PureComponent, useEffect } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, 
  Cell, Tooltip, Legend, ResponsiveContainer,CartesianGrid ,AxisLabel} from 'recharts';
import './slider.css';
import { connect } from "react-redux";
import {setTeams,setParentProgram,setGraphTeam} from '../../actions/surveyAction'


    
    const PasmatRechart = (props) => {
      let options = props.xData;
      let values = props.yData;
      const mapArrays = (options, values) => {
        const res = [];
        for(let i = 0; i < props.xData.length; i++){
           res.push({
              x: options[i],
              y: values[i]
           });
        };
        return res;
     };
     const data2 = mapArrays(options, values);
    const demoOnClick = (e) => {
      if(props.graphtype === "Programs")
      { 
        let trend = [];
        trend = props.trendAnalysisData.filter((trendData) => trendData.program === e.x)
        props.setParentProgram(trend[0]);}
      else
      {
        let trendTeam = [];
        trendTeam = props.trendProgram.teams.filter((tTeam) => tTeam.team_name === e.x)
        props.setGraphTeam(trendTeam[0]);}
    }
  


    return (
      <div style={{ width: '100%' }}>
        <div className='header'>
        <h6 className='title'>{props.graphName}</h6>
      </div>

        <ScatterChart
      width={900}
      height={400}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      }}
    >
      <CartesianGrid stroke="#b7b3e8" strokeDasharray="5 5"/>
      <XAxis dataKey="x" label={props.graphtype} angle={90}
        dx={15}
        dy={20}
        minTickGap={-200}
        axisLine={false}
        tick={{fontSize: 10}} />
      <YAxis
        yAxisId="left"
        dataKey="y"
        domain={[-5, 100]}
        tickCount={10}
        label={{ value: props.label, position: "outsideRight", angle: -90,   dy: -5 ,dx: -10}}
      >
</YAxis>
      <Tooltip cursor={{ strokeDasharray: "3 3" }} />
      <Scatter yAxisId="left" data={data2}>
{
                data2.map((entry, index) => {
                  return <Cell key={`cell-${index}`} value = {entry} fill={props.colors[index % props.colors.length]} 
                  stroke={props.colors[index % props.colors.length]} 
                  strokeWidth={15}
                  fillOpacity = {0.8}
                  onClick={e=> demoOnClick(entry)}/>
                })
              }
        </Scatter>
    </ScatterChart>
 
      </div>
    );
  }
  const mapStateToProps = (state) => {  
    return {
     trendAnalysisData : state.pasmatTrend.trendAnalysisData,
     trendProgram : state.pasmatTrend.trendProgram,
 }
}

const mapDispatchToProps = (dispatch) => {
 return {
     setTeams: (teams,streamMaturity) => dispatch(setTeams(teams,streamMaturity)),
     setParentProgram : (parentProgram) => dispatch(setParentProgram(parentProgram)),
     setGraphTeam : (graphTeam) => dispatch(setGraphTeam(graphTeam))
 }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasmatRechart);
