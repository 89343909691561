import {axiosInstance as axios} from "../axios.config";
import _ from "lodash";
import * as types from "./actionTypes";
import config from "../config";
import { authHeaders } from "../utils/api";
import { displayErrorNew, successAlert } from "../components/common/alerts/alerts";

const API_URL_GATEWAY = config.tdm_gateway_api.uri;

export const fetchRequestIdFilters = (key,value) => async dispatch =>{
  try{
    dispatch({ type: types.LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_LOADING});
    const response = await axios.post(
      `${API_URL_GATEWAY}/tdm/standarddatabank/checkin/filter?key=${key}&value=${value}`,{},
      { headers: authHeaders() }
    );
    dispatch({ type: types.LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_SUCCESS,payload : response.data});
    return response.data;
  }catch(error){
    console.log(error)
    const description = _.get(error, 'response.data.responseDescription', '');
    const status =  _.get(error, 'response.data.status', '');
    const payload={
        errorDescription : description ? description : _.get(error, 'response.data.error', ''),
        status :status ? status : _.get(error, 'response.data.status', '')
    }
    dispatch({ type: types.LOAD_CUSTOM_CHECKIN_REQUESTID_OPTIONS_ERROR, payload : payload});
    return false; 
  }
}

export const retrieveCustomCheckinAssets = request => async dispatch => {
    try {
        dispatch({ type: types.LOAD_CUSTOM_CHECKIN_ASSETS_LOADING});
        const response = await axios.post(
            `${API_URL_GATEWAY}/tdm/standarddatabank/checkin/asset/retrieve`,
            request,
            { headers: authHeaders() }
          );

          const payload= {
              qty :  response.data.total,
              userStory : response.data.assets && response.data.assets[0].userStory,
              scenarioName : response.data.assets && response.data.assets[0].scenarioName,
              assetDetails : response.data.assets && response.data.assets.map(asset => {
                  return {
                    id : asset.id,
                    customDetails : asset.customDetails && asset.customDetails[0]
                  } 
              })
          }
          dispatch({ type: types.LOAD_CUSTOM_CHECKIN_ASSETS_SUCCESS, payload });
          return payload;
    } catch (error) {
        const description = _.get(error, 'response.data.responseDescription', '');
        const status =  _.get(error, 'response.data.status', '');
        const payload={
            description :description,
            status :status
        }
        dispatch({ type: types.LOAD_CUSTOM_CHECKIN_ASSETS_FAILED, payload });
        return payload;
    }
 
  };

  export const checkinCustomAsset = (request) => async dispatch =>{
    try {
    const response = await axios.post(
        `${API_URL_GATEWAY}/tdm/standarddatabank/checkin/asset`,
        request,
        { headers: authHeaders() }
      );
      return response.data;
    }catch (error) {
        const description = _.get(error, 'response.data.responseDescription', '');
        const status =  _.get(error, 'response.data.status', '');
        const payload={
            errorDescription :description,
            status :status
        }
        return payload;
    }
  }

  export const resetOpsState = () => async dispatch => {
    dispatch({ type: types.RESET_CUSTOM_CHECKIN_ASSETS_SUCCESS });
  };

  export const uploadAndParseFile = (data) => async dispatch => {
    try {
      dispatch({ type: types.CUSTOM_CHECKIN_FILE_UPLOAD_LOADING});
      const response = await axios.post(
          `${API_URL_GATEWAY}/tdm/standarddatabank/checkin/upload/file`,
          data,
          { headers: authHeaders() }
        );
        dispatch({ type: types.CUSTOM_CHECKIN_FILE_UPLOAD_SUCCESS, payload : response.data});
        return response.data;
      }catch (error) {
          const description = _.get(error, 'response.data.responseDescription', '');
          const status =  _.get(error, 'response.data.status', '');
          let payload;
          if(status==="NETWORK_ERROR"){
            payload = {
              errorDescription:{
                "errorCode": "500",
                "httpStatus":status,
                "httpStatusCode": "500",
                "message": [description],
              },
              status
            }
          } else {
             payload={
                errorDescription : description ? description : _.get(error, 'response.data.error', ''),
                status :status ? status : _.get(error, 'response.data.status', '')
            }
          }
          dispatch({ type: types.CUSTOM_CHECKIN_FILE_UPLOAD_ERROR, payload : payload});
          return payload; 
      } 
  }

