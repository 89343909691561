
import * as types from '../actions/actionTypes';
const INITIAL_STATE={};

export default (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case types.FEEDBACK_FORM :
            return { ...state,...action.payload };
        case types.UPDATE_FEEDBACK :
            return { ...state, ...action.payload }
        default: 
            return state;
    }
}