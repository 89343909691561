import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    isSimFetchPending: false,
    isSimFetchError: false,
    isSimFetchSuccess: false,
    simResponse: null,
    isImsiFetchPending: false,
    isImsiFetchError: false,
    isImsiFetchSuccess: false,
    imsiResponse: null,
    isMsisdnFetchPending: false,
    isMsisdnFetchError: false,
    isMsisdnFetchSuccess: false,
    msisdnResponse: {
        "ResourceSubType": [],
        "ResourcePremiumType": [],
        "ResourcePool": [],
        "ResourceAttribute": []
    },
    msisdnAttributes: [],
    msisdnSelectedAttribute: '',
    msisdnAttributeValue: '',
    msisdnResourceSubType: null,
    msisdnResourcePool: null,
    msisdnPremiumType: null,

    isMsisdnInsertionPending: false,
    isMsisdnInsertionError: false,
    isMsisdnInsertionSuccess: false,
    msisdnInsertionResponse: null,

    isFnnInsertionPending: false,
    isFnnInsertionError: false,
    isFnnInsertionSuccess: false,
    fnnInsertionResponse: null,

    isFnnFetchPending: false,
    isFnnFetchError: false,
    isFnnFetchSuccess: false,
    fnnResponse: {
        "ResourceSubType": [],
        "ResourcePremiumType": [],
        "ResourcePool": [],
        "ResourceAttribute": []
    },
    fnnAttributes: [],
    fnnSelectedAttribute: '',
    fnnAttributeValue: '',
    fnnResourceSubType: null,
    fnnResourcePool: null,
    fnnPremiumType: null,
    fnnNumberType: null,

    isSimasInsertionPending: false,
    isSimasInsertionError: false,
    isSimasInsertionSuccess: false,
    simasInsertionResponse: null,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.FETCH_SIM_RESOURCE_ATTRIBUTES_PENDING:
            return {
                ...state,
                isSimFetchPending: true,
                isSimFetchError: false,
                isSimFetchSuccess: false,
                isSimasInsertionSuccess: false,
                isSimasInsertionError: false,
            }
        case types.FETCH_SIM_RESOURCE_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                isSimFetchPending: false,
                isSimFetchSuccess: true,
                isSimFetchError: false,
                simResponse: action.payload
            }
        case types.FETCH_SIM_RESOURCE_ATTRIBUTES_FAILED:
            return {
                ...state,
                isSimFetchPending: false,
                isSimFetchSuccess: false,
                isSimFetchError: true,
                simResponse: action.payload
            }
        case types.FETCH_IMSI_RESOURCE_ATTRIBUTES_PENDING:
            return {
                ...state,
                isImsiFetchPending: true,
                isImsiFetchSuccess: false,
                isImsiFetchError: false,
                isSimasInsertionSuccess: false,
                isSimasInsertionError: false,
            }
        case types.FETCH_IMSI_RESOURCE_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                isImsiFetchPending: false,
                isImsiFetchSuccess: true,
                isImsiFetchError: false,
                imsiResponse: action.payload
            }
        case types.FETCH_IMSI_RESOURCE_ATTRIBUTES_FAILED:
            return {
                ...state,
                isImsiFetchPending: false,
                isImsiFetchSuccess: false,
                isImsiFetchError: true,
                imsiResponse: action.payload
            }
        case types.FETCH_MSISDN_RESOURCE_ATTRIBUTES_PENDING:
            return {
                ...state,
                isMsisdnFetchPending: true,
                isMsisdnFetchSuccess: false,
                isMsisdnFetchError: false,
            }
        case types.FETCH_MSISDN_RESOURCE_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                isMsisdnFetchPending: false,
                isMsisdnFetchSuccess: true,
                isMsisdnFetchError: false,
                msisdnResponse: action.payload
            }
        case types.FETCH_MSISDN_RESOURCE_ATTRIBUTES_FAILED:
            return {
                ...state,
                isMsisdnFetchPending: false,
                isMsisdnFetchSuccess: false,
                isMsisdnFetchError: true,
                msisdnResponse: action.payload
            }
        case types.SET_MSISDN_RESOURCE_SUBTYPE:
            return {
                ...state,
                msisdnResourceSubType: action.payload
            }
        case types.SET_MSISDN_RESOURCE_POOL:
            return {
                ...state,
                msisdnResourcePool: action.payload
            }
        case types.SET_MSISDN_PREMIUM_TYPE:
            return {
                ...state,
                msisdnPremiumType: action.payload
            }
        case types.SET_MSISDN_ATTRIBUTES:
                return {
                    ...state,
                    msisdnAttributes: action.payload
                }
        case types.SET_MSISDN_SELECTED_ATTRIBUTE:
            return {
                ...state,
                msisdnSelectedAttribute: action.payload
            }
        case types.SET_MSISDN_ATTRIBUTE_VALUE:
            return {
                ...state,
                msisdnAttributeValue: action.payload
            }
        case types.REMOVE_FROM_ATTRIBUTES:
            return {
                ...state,
                [action.attributeType]: action.payload
            }
        case types.INSERT_MSISDN_PENDING:
            return {
                ...state,
                isMsisdnInsertionPending: true,
                isMsisdnInsertionError: false,
                isMsisdnInsertionSuccess: false,
                msisdnInsertionResponse: null,
            }
        case types.INSERT_MSISDN_SUCCESS:
            return {
                ...state,
                isMsisdnInsertionPending: false,
                isMsisdnInsertionError: false,
                isMsisdnInsertionSuccess: true,
                msisdnInsertionResponse: action.payload,
            }
        case types.INSERT_MSISDN_FAILED:
            return {
                ...state,
                isMsisdnInsertionPending: false,
                isMsisdnInsertionError: true,
                isMsisdnInsertionSuccess: false,
                msisdnInsertionResponse: action.payload,
            }
        case types.INSERT_SIMAS_PENDING:
            return {
                ...state,
                isSimasInsertionPending: true,
                isSimasInsertionError: false,
                isSimasInsertionSuccess: false,
                simasInsertionResponse: null,
            }
        case types.INSERT_SIMAS_SUCCESS:
            return {
                ...state,
                isSimasInsertionPending: false,
                isSimasInsertionError: false,
                isSimasInsertionSuccess: true,
                simasInsertionResponse: action.payload,
            }
        case types.INSERT_SIMAS_FAILED:
            return {
                ...state,
                isSimasInsertionPending: false,
                isSimasInsertionError: true,
                isSimasInsertionSuccess: false,
                simasInsertionResponse: action.payload,
            }
        case types.FETCH_FNN_RESOURCE_ATTRIBUTES_PENDING:
            return {
                ...state,
                isFnnFetchPending: true,
                isFnnFetchSuccess: false,
                isFnnFetchError: false,
            }
        case types.FETCH_FNN_RESOURCE_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                isFnnFetchPending: false,
                isFnnFetchSuccess: true,
                isFnnFetchError: false,
                fnnResponse: action.payload
            }
        case types.FETCH_FNN_RESOURCE_ATTRIBUTES_FAILED:
            return {
                ...state,
                isFnnFetchPending: false,
                isFnnFetchSuccess: false,
                isFnnFetchError: true,
                fnnResponse: action.payload
            }
        case types.INSERT_FNN_PENDING:
            return {
                ...state,
                isFnnInsertionPending: true,
                isFnnInsertionError: false,
                isFnnInsertionSuccess: false,
                fnnInsertionResponse: null,
            }
        case types.INSERT_FNN_SUCCESS:
            return {
                ...state,
                isFnnInsertionPending: false,
                isFnnInsertionError: false,
                isFnnInsertionSuccess: true,
                fnnInsertionResponse: action.payload,
            }
        case types.INSERT_FNN_FAILED:
            return {
                ...state,
                isFnnInsertionPending: false,
                isFnnInsertionError: true,
                isFnnInsertionSuccess: false,
                fnnInsertionResponse: action.payload,
            }
        case types.SET_FNN_RESOURCE_SUBTYPE:
            return {
                ...state,
                fnnResourceSubType: action.payload
            }
        case types.SET_FNN_NUMBER_TYPE:
            return {
                ...state,
                fnnNumberType: action.payload
            }
        default:
            return state;
    }
}
