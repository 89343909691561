import React from 'react'
import { TelstraIcon } from '../../../common/icons/GradientIcons';

export default (props) => {
    const {
        heading,
    } = props;
    return (
        <span
            className={`dataview-header`}>
            <p className="ml-2 sub-leader font-weight-light">{heading}
            </p>
            {<TelstraIcon primary icon="tick" color="green" />}
        </span>

    )
}