import React from "react";
import { connect } from "react-redux";
import { FieldArray } from "redux-form";
import TilesFrame from "../Data/accordion-frames/replicator/DataTilesFrame";
import * as fnnActions from "../../actions/dataCleanUpActions";
import ReduxFormContainer from "../common/form/redux/ReduxFormContainer";
import TDMHeader from "../cleanup/b2b/util/TDMHeading";
import RangeInput from "../cleanup/b2b/util/RangeInput";
import * as unmsConstants from "../cleanup/b2b/util/constants";
import * as tdmActions from "../../actions/tdmOpsActions";

class UnmsRanges extends React.Component {

    setSubtype = (obj) => {
        if (this.props.selectedFilters.unmsRscType === 'MSISDN') {
            this.props.setMsisdnResourceSubtype(obj.label);
        } else if (this.props.selectedFilters.unmsRscType === 'FNN') {
            this.props.setFnnResourceSubtype(obj.label);
        }
    }

    render() {
        const {unmsResourceType, editType, passdownprops, forms,hideResourceSubtype} = this.props;
        let props={};
        if(!hideResourceSubtype){
            props.primaryButton="Add";
            props.secondaryButton="Remove";
        }
        const asyncBlurFields = ["ranges[].startRange", "ranges[].endRange"];
        let subTypes = this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.msisdnResourceSubTypes : this.props.fnnResourceSubTypes;
        let subType = this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.msisdnResourceSubType : this.props.fnnResourceSubType;
        return (
            <div>
                {
                    <div>
                        <ReduxFormContainer
                            initialValues={unmsConstants.INITIAL_VALUE}
                            formName={unmsConstants.UNMS_INSERT_FORM}
                            asyncBlurFields={asyncBlurFields}
                            propsDepth={4}
                            unmsResourceType = {unmsResourceType}
                            editType = {editType}
                            recordLimit={50}
                        >
                                <TDMHeader heading="Ranges" />
                                <FieldArray
                                    assignProps
                                    name="ranges"
                                    passdownprops={passdownprops}
                                    errorMessage={this.props.errorMessage}
                                    component={RangeInput}
                                    {...props}
                                />
                            </ReduxFormContainer>
                            {!hideResourceSubtype ?  (UNMSValid(forms)) &&
                                <div>
                                    {<TilesFrame
                                        heading={"Resource Subtype"}
                                        color="orange"
                                        data = {
                                            subTypes ? (subTypes.map(subtype => ({
                                                "label":subtype,
                                                "icon":"icon-wan-accelerate"
                                            }))) : []
                                        }
                                        select = {this.setSubtype}
                                        selected = {subType}
                                     />}
                                </div> : null}
                        </div>
                }
            </div>
        )
    }
}

export const UNMSValid = form => {
    if (form && form.unmsInsert && form.unmsInsert.fields && form.unmsInsert.fields.ranges && form.unmsInsert.fields.ranges.length > 0) {
            if (!form.unmsInsert.syncErrors) {
                return true;
            }
        for (var i = 0; i < form.unmsInsert.fields.ranges.length; i++) {
            if (form.unmsInsert.syncErrors.ranges[i]) {
                return false;
            }
        }
        return true;
    }
    return false;
}

const mapStateToProps = state => {
    return {
        unmsResourceTypes: state.cleanup.filters.unmsResourceTypes,
        unmsMsisdnResourceTypes:state.cleanup.filters.unmsMsisdnResourceTypes,
        selectedFilters: state.cleanup.selectedFilters,
        forms: state.form,

        msisdnGetSuccess:state.tdmOps.isMsisdnFetchSuccess,
        msisdnResourceSubTypes:state.tdmOps.msisdnResponse.ResourceSubType,
        msisdnResourceSubType:state.tdmOps.msisdnResourceSubType,

        fnnResourceSubTypes:state.tdmOps.fnnResponse.ResourceSubType,
        fnnResourceSubType:state.tdmOps.fnnResourceSubType,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        resetDataCleanUpFIlter: () => dispatch(fnnActions.resetDataCleanUpFIlter()),
        resetCurrentStatus: () => dispatch(fnnActions.resetCurrentStatus()),

        setMsisdnResourceSubtype: (subtype) => dispatch(tdmActions.setMsisdnResourceSubtype(subtype)),
        setFnnResourceSubtype: (subtype) => dispatch(tdmActions.setFnnResourceSubtype(subtype)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnmsRanges);
