import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import HeaderContent from '../common/Header/HeaderContent';
import MyTeamTable from "./MyTeamtable";
import { oldLoadTeamFilterDetails, selectFilter, oldLoadCustomTeamFilterDetails, selectCustomTeamFilter, loadUserFilterDetails, oldLoadCustomUserFilterDetails,fetchAllTeams,
checkoutServiceFilters, allocateServiceFilters} from "../../actions/myTeamsActions";
import { setLocation } from "../../actions/userFeedbackActions";
import TableContentLoader from "../common/loader/TableContentLoader";
import FailedView from "./FailedView";
import "./myTeams.css";
import UsersToTeam from "./UsersToTeam";
import SwitchSelector from "react-switch-selector";
import { UserSwitch } from "../common/custom/ToggleSwitch";
import AllocateTeam from "./AllocateTeam";
import {  setSideBarPanel } from '../../actions/authActions';
import { Redirect } from 'react-router-dom'

export const ToggleButtonLabel = {
  STANDARD: 'Standard',
  CUSTOM: 'Custom'
}

const toggleButtonOptions = [
  {
    label: ToggleButtonLabel.STANDARD,
    value: ToggleButtonLabel.STANDARD,
    selectedBackgroundColor: "white",
    selectedFontColor: "black"
  },
  {
    label: ToggleButtonLabel.CUSTOM,
    value: ToggleButtonLabel.CUSTOM,
    selectedBackgroundColor: "white",
    selectedFontColor: "black"
  }
];

class MyTeam extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedToggleButton: ToggleButtonLabel.STANDARD ,
      isUserToggle:false,
      teamsModalList:[],
      allocateLoading:false,
      allocationDetail:{}
    }
  }

  componentDidMount = () => {
    this.loadTeamFilterDetails();
    this.fetchAllTeams();
    this.props.setLocation("MyTeams")
  }

  componentWillUnmount() {
    // reset filters
    this.props.selectFilter({});
    this.props.selectCustomTeamFilter({});
  }

  componentDidUpdate(prevProps, prevState) {
      if(!_.isEqual(prevProps.selectedEnvValue, this.props.selectedEnvValue) || !_.isEqual(prevProps.loggedInUser.team, this.props.loggedInUser.team))
      {
        if (this.state.selectedToggleButton === ToggleButtonLabel.STANDARD)
        {
          this.loadTeamFilterDetails();
          this.props.selectFilter({});
        }
        else if(this.state.selectedToggleButton === ToggleButtonLabel.CUSTOM)
        {
          this.loadCustomTeamFilterDetails();
          this.props.selectCustomTeamFilter({});
        }
        this.setState({isUserToggle:false})
      }

  }

  loadUserFilterDetails = () =>{
    this.props.loadUserFilterDetails({userList: this.props.userData?.content});
  }

  loadCustomUserFilterDetails = () =>{
    this.props.oldLoadCustomUserFilterDetails();
  }

  loadTeamFilterDetails=()=>{
    const { scopes, email, isAdmin, loggedInUser } = this.props;
    this.props.loadTeamFilterDetails({ scopes, email, isAdmin, loggedInUser });
  }

  loadCustomTeamFilterDetails=()=>{
    const { scopes, email, isAdmin, loggedInUser } = this.props;
    this.props.loadCustomTeamFilterDetails({ scopes, email, isAdmin, loggedInUser });
  }
  fetchAllTeams = () =>{
    let teamsModalList = (this.props.fetchAllTeams()).then((result) =>
    {
     this.setState({teamsModalList : result})
    });}

  handleUsersToTeamModal = () => {
    this.usersToTeamModalRef.open();
  }

  handleAllocateToTeamModal = () => {
    this.allocateModalRef.open();
  }

  onChangeToggleButton = newValue => {

    if(newValue===ToggleButtonLabel.STANDARD){
      this.state.isUserToggle ? this.loadUserFilterDetails() : this.loadTeamFilterDetails();
    }
    else {
      this.state.isUserToggle ? this.loadCustomUserFilterDetails(): this.loadCustomTeamFilterDetails();
    }
    this.setState({selectedToggleButton: newValue});
  };

  renderSwitchToggle = () => {
      return (
        <span style={{ width: 180, height: 38, marginRight: 90 }}>
        <SwitchSelector
          onChange={this.onChangeToggleButton}
          options={toggleButtonOptions}
          backgroundColor={"rgb(19, 161, 41) !important"}
          fontColor={"white"}
          optionBorderRadius= {9}
          wrapperBorderRadius={7}
          fontSize={16}
        />
        </span>
      )
  }

  handleUserToggleChange = (event) => {
    if(event.target.checked){
       this.state.selectedToggleButton === ToggleButtonLabel.STANDARD ? this.loadUserFilterDetails() : this.loadCustomUserFilterDetails()
    }
    else {
      this.state.selectedToggleButton === ToggleButtonLabel.STANDARD ? this.loadTeamFilterDetails(): this.loadCustomTeamFilterDetails();
    }
    this.setState({ isUserToggle: event.target.checked });
  };

  renderUserToggle = () => {
    return(
        <UserSwitch
        checked={this.state.isUserToggle}
        onChange={this.handleUserToggleChange}/>
    )
  }

  handleContentLoader = (filterPending) => {
      return filterPending ? <TableContentLoader /> : null;
  }

  handleNoTeamData = (filterFailed, filterPending, filter) => {
    return !filterFailed && !filterPending && filter.length === 0 ? "No Team Data" : null;
  }

  handleFailedViewError = (filterFailed, errorMessage) => {
    return filterFailed ? <FailedView message={errorMessage || "Could not load Team Details"} /> : null;
  }

  loadMyTeamTable = (filterPending, filter ) => {
    const {history} = this.props;

    return (!filterPending && filter.length > 0 &&
      <section id="team-table-area">
      <MyTeamTable history={history}
      loadTeamFilterDetails = {this.state.isUserToggle? this.loadUserFilterDetails: this.loadTeamFilterDetails}
      loadCustomTeamFilterDetails = {this.state.isUserToggle? this.loadCustomUserFilterDetails: this.loadCustomTeamFilterDetails}
      selectedToggleButton={this.state.selectedToggleButton}
      userSwitch = {this.renderUserToggle}
      isUserToggle = {this.state.isUserToggle}
            /></section>
        )
  }
  renderAllMethods = () => {
    const {fetchFilterPending, customFilterPending, fetchFilterFailed, filters, customFilterFailed,
       customFilters, customFilerErrorMessage, failedErrorMessage } = this.props;

    if (this.state.selectedToggleButton === ToggleButtonLabel.STANDARD) {
       return(
        <React.Fragment>
        {this.handleContentLoader(fetchFilterPending)}
        {this.handleNoTeamData(fetchFilterFailed, fetchFilterPending, filters)}
        {this.loadMyTeamTable(fetchFilterPending, filters)}
        {this.handleFailedViewError(fetchFilterFailed, failedErrorMessage)}
        </React.Fragment>
        );
    } else if (this.state.selectedToggleButton === ToggleButtonLabel.CUSTOM) {
      return(
        <React.Fragment>
          {this.handleContentLoader(customFilterPending)}
          {this.handleNoTeamData(customFilterFailed, customFilterPending, customFilters)}
          {this.loadMyTeamTable(customFilterPending, customFilters)}
          {this.handleFailedViewError(customFilterFailed, customFilerErrorMessage)}
      </React.Fragment>
      )
    }
  }

  render() {
    if(!this.props.roles.some((_item) => (_item === "OPS" || _item === "ADMIN"))){
      return  <Redirect to="/dashboard" />;
    }
    const { filters, fetchFilterPending, history ,isAdmin, currentTeamId, selectedEnvValue, myTeamOptions, roles,setSideBarPanel,openSidePanel} = this.props;
    return (
      <div className="white" id="team-view-page">
        <HeaderContent
          title="My Team - Admin"
          icon="users team-icon"
          loading={fetchFilterPending}
          loadingText="Loading your Team data.."
          click={!fetchFilterPending}
          onClick={this.handleUsersToTeamModal}
          alertModal={this.usersToTeamModalRef}
          extraComponent={this.renderSwitchToggle}
          extraComponentClassName="myteam-switch-selector"
          allocateModal = {this.allocateModalRef}
          onClickAllocate = {this.handleAllocateToTeamModal}
          allocateLoading = {this.state.allocateLoading}
          isAdmin = {isAdmin}
          cssClass= "allocate-btn"
          showEnv={true}
          selectedEnvValue={selectedEnvValue}
          setSideBarPanel={setSideBarPanel}
          openSidePanel={openSidePanel}
        />
        <UsersToTeam onRef={(ref) => this.usersToTeamModalRef = ref}
        filters={this.state.teamsModalList}
        roles = {roles}
        myTeamOptions = {myTeamOptions}
        isAdmin={isAdmin} currentTeamId={currentTeamId}/>
        <AllocateTeam
            onRef = {ref => {this.allocateModalRef = ref}}
            selectedToggleButton = {this.state.selectedToggleButton}
            myTeamUserToggle = {this.state.isUserToggle}
            selectedEnvValue = {selectedEnvValue}
            loadTeamFilterDetails = {this.loadTeamFilterDetails}
            loadCustomTeamFilterDetails = {this.loadCustomTeamFilterDetails}
            loadUserFilterDetails = {this.loadUserFilterDetails}
            loadCustomUserFilterDetails = {this.loadCustomUserFilterDetails}
            handleServiceFilterCheckout={this.props.allocateServiceFilters}
            />
                {this.renderAllMethods()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    myTeamOptions: state.auth.myAvailableTeams,
    teamData: state.teams.teamData,
    scopes: state.auth.roles,
    email: state.auth.user && state.auth.user.email,
    isAdmin: state.auth.isAdmin,
    filters: state.teams.teamFilters.filters || [],
    selectedFilters: state.teams.selectedFilters,
    fetchFilterPending: state.teams.teamFilters.pending,
    fetchFilterFailed: state.teams.teamFilters.failed,
    currentTeamId: state.auth.team,
    failedErrorMessage: state.teams.teamFilters.errorMessage,
    customFilters: state.teams.customTeamFilters.customFilters || [],
    customFilerErrorMessage: state.teams.customTeamFilters.customFilerErrorMessage,
    customFilterFailed: state.teams.customTeamFilters.customFilterFailed,
    customFilterPending: state.teams.customTeamFilters.customFilterPending,
    selectedEnvValue: state.auth.selectedEnvValue,
    loggedInUser: state.auth,
    openSidePanel:state.auth.openSidePanel,
    userData: state.users.mgmt
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadTeamFilterDetails: (request) => dispatch(oldLoadTeamFilterDetails(request)),
    selectFilter: (filter) => dispatch(selectFilter(filter)),
    loadCustomTeamFilterDetails: (request) => dispatch(oldLoadCustomTeamFilterDetails(request)),
    selectCustomTeamFilter: (filter) => dispatch(selectCustomTeamFilter(filter)),
    loadUserFilterDetails:(details)=> dispatch(loadUserFilterDetails(details)),
    oldLoadCustomUserFilterDetails:()=> dispatch(oldLoadCustomUserFilterDetails()),
    fetchAllTeams:()=> dispatch(fetchAllTeams()),
    checkoutServiceFilters: (request) => dispatch(checkoutServiceFilters(request)),
    allocateServiceFilters: (request) => dispatch(allocateServiceFilters(request)),
    setLocation:(path) => dispatch(setLocation(path)),
    setSideBarPanel: (value) => dispatch(setSideBarPanel(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTeam);
