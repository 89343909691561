import React, { useState, useEffect } from 'react';
import { connect} from "react-redux";
import _ from 'lodash';
import { retrieveDataBankCheckOutHistory, fetchCheckoutFilterByFieldAndValue, setTeamId, setUserToggle} from "./action";
import { fetchAllTeams , getUsers} from '../../actions/myTeamsActions';
import HeaderContent from "../../components/common/Header/HeaderContent";
import CheckoutTable from './subcomponents/CheckoutTable';
import SearchFilter from './subcomponents/SearchFilter';
import PaginationController from "../../utils/PaginationController";
import FloatingScroll from "../../utils/FloatingScroll";
import { TelstraIcon } from '../../components/common/icons/GradientIcons';
import {UserSwitch} from '../../components/common/custom/ToggleSwitch';

function DataBankCheckoutHistory(props) {
    const [page, setPage] = useState(0);
    const { isUserToggle, setUserToggle, selectedEnvValue, checkOutHistoryData, errors, allTeams = [], fetchCheckoutHistory, fetchFilterOptions, fetchAllTeams, 
        isAdmin, fetching, user, selectedFilter, filter,  listOfTeams=[], setTeamId, teamId, getUsers } = props;
    const { content, number: currentPage, totalPages } = checkOutHistoryData;
    const [filters, setFilters] = useState([]);
    const {fdata, floading} = filter;
    const [isClearFilter, setIsClearFilter] = useState(false);
    const [userSelectedFilters, setUserSelectedFilters] = useState([]);
	
    function selectFilter(page = 0, filter = []){
        fetchCheckoutHistory(page, filter, isUserToggle);
        setUserSelectedFilters(filter || []);
    }

    const changeHandler = (field, filter) => {
        if(!_.isEmpty(filter)){
            fetchFilterOptions(field, filter, isUserToggle);
        }
    }

    const setTeam = (id = '') => {
        if (id !== teamId) {
            setTeamId(id);
            if (_.isEmpty(userSelectedFilters)) {
                fetchCheckoutHistory(0, [], isUserToggle);
            }
            setFilters(filterConf);
            setIsClearFilter(true);
            setPage(0);
            setUserSelectedFilters([]);
        }
    }

    function bindLoadingToFilter() {
        const filterD = _.map(filters, (f) => {
            if (teamId && f.field === 'success') {
                f.isHidden = true;
            } else if ( !teamId && f.field === 'name') {
                f.isHidden = true;
            } else {
                f.isHidden = false;
            }
            return f;
        });
        return filterD.map((filter) => ({ ...filter, isLoading: floading[filter.field] || false }))

    }

    const successOptions = [
        { value: 'True', label: <TelstraIcon color="green" icon="icon-round-tick" size="xs" /> },
        { value: 'False', label: <TelstraIcon color="orange" icon="icon-round-cross" size="xs"  /> }
    ]

    const filterConf = [
        { field: "id", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: "serviceId", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: "serviceProductCode", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: "serviceDescription", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: "qtyRequested", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: "usedDate", type: "date", onSelect: selectFilter, options: [] },
        { field: "name",  type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: "success", type: "dropdown", onSelect: selectFilter, options: successOptions }
    ];
    function gotoPage(pageNum) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        setPage(pageNum);
		fetchCheckoutHistory(pageNum, userSelectedFilters, isUserToggle);
    }
    function formTeamList() {
        return [{ teamDisplayName: user.displayName.replace(/,(?=[^\s])/g, ", ") }, ...(isAdmin ? allTeams : listOfTeams)]
    }

    useEffect(() => {
        if(_.isEmpty(filters)){
            setFilters(filterConf);
        }
        
        isClearFilter && setIsClearFilter(false);
        
        if (_.isEmpty(allTeams) && isAdmin){
            isUserToggle? getUsers(props.userData?.content) : fetchAllTeams();
        }
    }, [page, teamId]);
    
    useEffect(() => {
        isClearFilter && setIsClearFilter(false);
        if (_.isEmpty(filters) || _.isEmpty(fdata) ) {
            return;
        }
        
        let localFilter = filters.map(filter => {
            if (filter.options !== fdata[filter.field]  && filter.field !== 'success'){
                filter.options = fdata[filter.field] || []
            }
            return filter;
        });
        setFilters(localFilter);
    }, [fdata]);
    useEffect(() => {
		setTeamId('');
        setPage(0);
		
        if (_.isEmpty(allTeams)) {
            return;
        }
        const listOfTeams = formTeamList();
        const team = listOfTeams[0];
        if (_.isEmpty(team?.id || team?.teamId)) {
            setTeamId(team?.id || team?.teamId);
        }
    }, []);
	useEffect(() => {
        fetchCheckoutHistory(page, userSelectedFilters, isUserToggle);
    }, [selectedEnvValue]);

    useEffect(()=>{
        isUserToggle? getUsers(props.userData?.content) : fetchAllTeams();
        setTeam(); 
    },[isUserToggle])

    const toggleButton = () =>{
        
        setUserToggle(!isUserToggle);
    }

    const clearFilter = () => {
        setIsClearFilter(true);
    }
    const renderUserToggle = () => {
        return(
            <UserSwitch 
            checked={isUserToggle} 
            onChange={toggleButton}/>
        )
      }
    return (<div className="white">
        <HeaderContent
            title="Checkout History"
            icon="history"
            loading={fetching}
            empty={(content && content.length === 0)}
            loadingText="Loading your checkout history.." />
            
        <SearchFilter fetching={fetching} listOfTeams={formTeamList()} selectTeam={setTeam} 
        isUserToggle={isUserToggle} 
        renderUserToggle={renderUserToggle}
        isAdmin ={isAdmin}
        />
        
		{ !_.isEmpty(filters) &&
            <React.Fragment>
                <CheckoutTable clearFilter={clearFilter} filters={bindLoadingToFilter()} fetching={fetching} errors={errors} isClearFilter={isClearFilter}
                teamId={teamId} isAdmin={isAdmin} selectedFilter={selectedFilter} selectFilter={selectFilter} tableData={content} detailView={true} dataBankCheckoutView={true} />
                <PaginationController
                    totalPages={totalPages}
                    isClearPagination={isClearFilter || false}
                    gotoPage={gotoPage}
                    isUserToggle={isUserToggle}
                    />
                <FloatingScroll />
            </React.Fragment>
        }
    </div >
    )
}
const mapStateToProps = (state) => {
    return {
        checkOutHistoryData: state.dataBankCheckoutHistory.checkOutHistory,
        fetching: state.dataBankCheckoutHistory.fetching,
        errors: state.dataBankCheckoutHistory.errors,
        selectedFilter: state.dataBankCheckoutHistory.selectedFilter,
        filter: state.dataBankCheckoutHistory.filter,
        isAdmin: state.auth.isAdmin,
        listOfTeams: state.auth.myAvailableTeams,
        allTeams: state.teams.teams.allTeams,
        user: state.auth.user,
        teamId: state.dataBankCheckoutHistory.teamId,
        selectedEnvValue: state.auth.selectedEnvValue,
        isUserToggle: state.dataBankCheckoutHistory.isUserToggle,
        userData: state.users.mgmt
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCheckoutHistory: (pageNum, filters, isUserToggle) => dispatch(retrieveDataBankCheckOutHistory(pageNum, filters, isUserToggle)),
        fetchFilterOptions: (field, filter, isUserToggle) => dispatch(fetchCheckoutFilterByFieldAndValue(field, filter, isUserToggle)),
        fetchAllTeams: () => dispatch(fetchAllTeams()),
        setTeamId: (teamId) => dispatch(setTeamId(teamId)),
        getUsers:(userList) => dispatch(getUsers(userList)),
        setUserToggle:(isUserT) => dispatch(setUserToggle(isUserT))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBankCheckoutHistory);