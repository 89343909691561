import React from 'react'
import { connect } from 'react-redux';
import {Typography} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import _ from 'lodash';
import swal from 'sweetalert2';
import IOSSwitch from './styledComponents/IosSwitch';
import  HtmlTooltip from './styledComponents/CustomToolTip';
import {getSmBtnInfo} from './utilites';
import '../DataView.css';
import { retrieveCheckOutData, clearAllAssetsCheckedout, serviceMigration } from './action';
import { Alert } from 'reactstrap';
import CheckoutTable from "./sub-component/CheckoutTable";
import OrderTable from "./sub-component/OrderTable";
import {EdgarDetails} from "./sub-component/EdgarDetails";
import CustomerTable from "./sub-component/CustomerTable";
import CustomTable from "./sub-component/CustomTable";
import TDI_Table from "./sub-component/TDI_Table";
import Mobile_Table from './sub-component/MobileTable';
import ServiceMigration from './sub-component/ServiceMigration';
import ReplicatorTable from "./sub-component/ReplicatorTable";
import { isNull, isNullOrUndefined } from 'util';
import { is1TdmUser } from '../../../../utils/userProfile';
import {AddressDetails} from './sub-component/AddressDetails';
import {FnnDetails} from './sub-component/FnnDetails';

class DataBankCheckout extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state =  {smBtnInfo: {showSmBtn: false,disabled: false, tooltip:'No button'}}
    }
    componentDidMount() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        this.refresh();
        this.props.clearAllAssetsCheckedout();
    }

    refresh = async () => {
        const { id } = this.props.match.params;
        await this.props.fetchDetail(id);
    }

    // Get value of keys from a array of json object
    getValueOfName = (array, name) => {
        const definition = array && array.filter((definition => definition.name === name));
        if (definition.length > 0) {
            return definition[0].value;
        }
    }

    // grouping the mobile Items together
    groupMobileItems = (simImsiDetails, msisdnDetails, prepaidDetails) => {
        return ([
            {
                simImsiDetails,
                msisdnDetails,
                prepaidDetails
            }
        ])
    }
    handleServiceMigration= (smReq, smBtnDisabled, serviceMigLoading)=>{
        if(!smBtnDisabled){
            smReq.sourceSystem="RCRM";
            smReq.billingSystem ="KENAN";
            smReq.eligibilityOutcome ="ELIGIBLE";
                this.props.serviceMigration(smReq).then((res)=>{
                    console.log('response', res)
                    const {responseStatus, responseDescription} = res;
                    if(responseStatus === 'SuccessResponse'){
                        swal.fire({
                            position: 'center',
                            type: 'success',
                            title: 'Service Migration - Success',
                            text: responseDescription,
                            showConfirmButton: true
                          })
                    }
                    if(responseStatus === 'ErrorResponse'){
                        swal.fire({
                            position: 'center',
                            type: 'error',
                            title: 'Service Migration - Failure',
                            text: responseDescription,
                            showConfirmButton: true
                          })
                    }
            })
        }
}

    getSmReq=(uniqueId, ban, cidn, msisdn, environment)=>{
        return ({
            "customerId":uniqueId,
            "ban":ban,
            "cidn": cidn,
            "msisdn" : msisdn,
            "env":environment
            });
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(!_.isEmpty(nextProps.serviceMigData)){
            let {responseStatus} = nextProps.serviceMigData;
            if(responseStatus === "Success") {
                nextProps.serviceMigData.responseStatus = "SuccessResponse";
                return {...prevState, smBtnInfo: {showSmBtn: true, disabled: true, tooltip: 'Service Migration has been done for this asset successfully'}}
            }
            if(responseStatus === 'Error' || responseStatus === "Failure") {
                nextProps.serviceMigData.responseStatus = "ErrorResponse";
                return {...prevState, smBtnInfo: {showSmBtn: true, disabled: true, tooltip: 'Service Migration cannot be done due to some error'}}
            }
        }
        if(!_.isEmpty(nextProps.retrievedCheckOutData)){
            const {assetDetails} = nextProps.retrievedCheckOutData;
            const asset = _.isEmpty(assetDetails) ?  {} : assetDetails[0];
            const { orderDetails } = asset ? (asset.hasOwnProperty('orderDetails') && asset) : [];
            const { serviceDetails } = asset ? (asset.hasOwnProperty('serviceId') && asset.serviceId) : {};
            return {...prevState, smBtnInfo: getSmBtnInfo(orderDetails, serviceDetails)}
        }
        return prevState;
    }

    replicatorCheckoutDetails = (replicatorDetails, assetDetails, msisdnDetails) => {
        const source = assetDetails && assetDetails.source;
        if(source === 'REPLICATOR' && replicatorDetails === undefined && msisdnDetails !== undefined) {
           const replicatorMobile = [];
           replicatorMobile.push({product: "Mobile"});
           replicatorDetails = replicatorMobile;
        }
        return replicatorDetails;
    }

    filterSimImsiAndMsisdnDetails = (assetDetailsArr, simImsiDetails, msisdnDetails) => {
        //remove first element from the array as we already taken in the simimsi or msisdn details
        assetDetailsArr.slice(1).map((data)=>{
            if(data.hasOwnProperty('simImsiDetails')){
                if(data.simImsiDetails && data.simImsiDetails.length>0){
                    data.simImsiDetails.map((simIms)=>{
                        simImsiDetails.push(simIms);
                    })
                }
            }
            if(data.hasOwnProperty('msisdnDetails')){
                if(data.msisdnDetails && data.msisdnDetails.length>0){
                    data.msisdnDetails.map((msisdn)=>{
                    msisdnDetails.push(msisdn);
                })
                }
            }
        });
    }

    render() {
        const { id } = this.props.match.params;
        const { retrievedCheckOutData, errors, serviceMigLoading } = this.props;
        const { timestamp } = retrievedCheckOutData;
        const assetDetailsArr = retrievedCheckOutData !== undefined ? retrievedCheckOutData.assetDetails : [];
        const assetDetails = (assetDetailsArr != undefined && assetDetailsArr.length > 0) ? assetDetailsArr[0] : {};
        const assetsQty = assetDetailsArr && assetDetailsArr.length;
        const { simImsiDetails, msisdnDetails, fnnDetails,
                addressDetails, edgarDetails, customDetails, orderDetails,
                tdiDetails, customerDetails, billingAccountDetails, prepaidDetails } = assetDetails;
        //Cumulate simimsi and msisdn details when
        assetDetailsArr && assetDetailsArr.length > 1 && this.filterSimImsiAndMsisdnDetails(assetDetailsArr, simImsiDetails, msisdnDetails);
        const {environment} = orderDetails ? orderDetails[0] : {};
        const {uniqueId, cidn} = customerDetails ? customerDetails[0] : {};
        const {ban} = billingAccountDetails ? billingAccountDetails[0] : {};
        const {msisdn} = msisdnDetails ? msisdnDetails[0] : {}  ;
        const smReq = this.getSmReq(uniqueId, ban, cidn,msisdn, environment);
        const {smBtnInfo} = this.state;
        let { replicatorDetails } = retrievedCheckOutData;
        replicatorDetails = this.replicatorCheckoutDetails(replicatorDetails, assetDetails, msisdnDetails);

		return (
            <div className="white">
                <span className="dataview-header">
                    <div>
                        <h1>Checkout ID: {id}</h1>
                    </div>
                    <i
                        className={`fa ${this.props.fetching
                            ? 'fa-refresh fa-spin fa-fw'
                            : 'fa-history'} fa-3x`}
                        aria-hidden="true" />
                </span>
                {(!this.props.fetching) && <React.Fragment>
                    {/*Check if any data was received.*/}
                    {errors.length > 0
                        ?
                        errors.map(error =>
                            error !== undefined && error !== null &&
                            <React.Fragment>
                                {error.error && <Alert color="danger">{error.error}</Alert>}
                                {error.message && <Alert color="danger">{error.message}</Alert>}
                                {error.responseStatus && <Alert color="danger">{error.responseStatus}</Alert>}
                                {error.responseDescription && <Alert color="danger">{error.responseDescription}</Alert>}
                            </React.Fragment>
                        )
                        :
                        Object.entries(retrievedCheckOutData).length === 0 && retrievedCheckOutData.constructor === Object &&
                        <h2>No data found for given Checkout ID</h2>
                    }

                    {/* checkout table class   */}
                    <CheckoutTable timestamp={timestamp} id={id} assetDetails={assetDetails} quantity = {assetsQty} getValueOfName={this.getValueOfName} />
                    {/* end checkout table class   */}

                    {/* Start Address Details   */}
                    {!_.isEmpty(assetDetailsArr) && assetDetailsArr != undefined && assetDetailsArr.length > 0 && assetDetailsArr[0].addressDetails !== undefined && assetDetailsArr[0].addressDetails.length >0 && <AddressDetails addressDetails={assetDetailsArr} />}
                    {/* end Address Details   */}

                    {/* Start Edgar Details   */}
                    {!_.isEmpty(assetDetailsArr) && assetDetailsArr != undefined && assetDetailsArr.length > 0 && assetDetailsArr[0].edgarDetails !== undefined && assetDetailsArr[0].edgarDetails.length >0 && <EdgarDetails edgarDetails={assetDetailsArr} />}
                    {/* end Edgar Details   */}

                    {/* Start Fnn Details   */}
                    {!_.isEmpty(assetDetailsArr) && assetDetailsArr != undefined && assetDetailsArr.length > 0 && assetDetailsArr[0].fnnDetails !== undefined && assetDetailsArr[0].fnnDetails.length >0 && <FnnDetails fnnDetails={assetDetailsArr} />}
                    {/* end Fnn Details   */}


                    {/*Start Order table class*/}
                    <OrderTable orderDetails={orderDetails} getValueOfName={this.getValueOfName}
                                 handleServiceMigration={this.handleServiceMigration}
                                 serviceMigLoading={serviceMigLoading}
                                 smReq={smReq}
                                 smBtnInfo={smBtnInfo}
                                 customerId={uniqueId}
                                 ban={ban}
                                 assetDetails={assetDetails}
                                 />
                    {/*End Order table class*/}

                    {/* Start DataViewTDI*/}
                    <TDI_Table tdiDetails={tdiDetails} />
                    {/* End DataViewTDI*/}

                    {/* Start CustomerTable*/}
                   {isNullOrUndefined(replicatorDetails) && (<CustomerTable customerDetails={customerDetails} assetDetails={assetDetails} />)}
                    {/* End CustomerTable*/}

                    {/* Start Mobile Table*/}
                    {(simImsiDetails || msisdnDetails || prepaidDetails) &&
                        <Mobile_Table mobileItems={this.groupMobileItems(simImsiDetails, msisdnDetails, prepaidDetails)}
                            assetDetails={assetDetailsArr && assetDetailsArr.length>0 ? assetDetailsArr : []}
                            simImsiDetails={simImsiDetails ? simImsiDetails : []}
                            msisdnDetails={msisdnDetails ? msisdnDetails : []}
                            prepaidDetails={prepaidDetails ? prepaidDetails[0] : {}}
                            isReplicatorDelete={!isNullOrUndefined(replicatorDetails) && assetDetails.type === 'DELETE'}
                            />}
                    {/* End CustomerTable */}

                    {/* Start CustomTable*/}
                    <CustomTable customDetails={customDetails} />
                    {/* End CustomTable*/}

                    {/* Start Replicator Table */}
                    {(is1TdmUser() && !isNullOrUndefined(replicatorDetails)) && <ReplicatorTable customerDetails = { customerDetails } replicatorDetails = { replicatorDetails }/>}
                    {/* End Replicator Table */}

                </React.Fragment>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        checkOutData: state.dataActive.checkOutData,
        retrievedCheckOutData: state.dataBankCheckout.retrieveCheckOutData,
        fetching: state.dataBankCheckout.fetching,
        fetched: state.dataBankCheckout.fetched,
        errors: state.dataBankCheckout.errors,
        serviceMigData: state.dataBankCheckout.serviceMigData,
        serviceMigLoading: state.dataBankCheckout.serviceMigLoading
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDetail: (id) => dispatch(retrieveCheckOutData(id)),
        clearAllAssetsCheckedout: ()=> dispatch(clearAllAssetsCheckedout()),
        serviceMigration: (smReq)=> dispatch(serviceMigration(smReq))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBankCheckout);
