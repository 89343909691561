import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import HeaderContent from '../../common/Header/HeaderContent';
import VerticalTabs from './VerticalTabs';
import InputOutlinedIcon from '@material-ui/icons/InputOutlined';
import { reduxForm, Field, change } from "redux-form";
import { retrieveSalesforceTables, submitSalesforceTables } from '../../../actions/utilityActions';
import { renderField } from "../../common/fields/fields";
import { displaySuccess, displayLoadingWithPercent, updateLoadingWithPercent } from "../../common/alerts/alerts";
import swal from 'sweetalert2';
import './s2p.css';
import { notNullOrUndefined } from '../../common/form/fieldValidators';

class UtilityS2p extends Component {

    state = {
        navItems: [],
        bodyItems: [],
        formData: {},
        submitDisabled: true,
        payloads: [],
        hardCodedCommissionNavList: [
            'SFDC_B2B_CSORD__ORDER__C',
            'SFDC_B2B_OPPORTUNITY',
            'SFDC_B2B_ACCOUNT',
            'SFDC_B2B_CSORD__SERVICE__C',
            'SFDC_B2B_CSORD__SUBSCRIPTION__C',
            'SFDC_B2B_CSORD__SOLUTION_C',
            'sfdc_b2b_cscfga__product_configuration__c',
            'sfdc_b2b_cscfga__product_definition__c',
            'sfdc_b2b_contractjunction__c',
            'sfdc_b2b_marketable_offer__c',
            'SFDC_B2B_BILLING_ACCOUNT__C',
            'sfdc_b2b_user',
            'sfdc_b2b_number__c',
            'sfdc_b2b_functional_contact_role__c',
        ],
        hardCodedSalesforceNavList: [
            'sfdc_b2b_lead',
            'sfdc_b2b_accountcontactrelation',
            'sfdc_b2b_accounthistory',
            // 'sfdc_b2b_account_industry_relationship__c',
            'sfdc_b2b_account_price_list__mdt',
            'sfdc_b2b_accountteammember',
            'sfdc_b2b_appointment__c',
            'sfdc_b2b_aptps_agreement_pricing_data_map__c',
            // 'sfdc_b2b_apttus__accounthierarchy__c',
            'sfdc_b2b_apttus__agreement_clause__c',
            'sfdc_b2b_apttus__agreementexplorer__c',
            'sfdc_b2b_apttus__agreementhierarchy__c',
            'sfdc_b2b_apttus__agreementlineitem__c',
            'sfdc_b2b_apttus__agreementlock__c',
            'sfdc_b2b_apttus__agreement_protection__c',
            'sfdc_b2b_apttus__agreement_rule__c',
            'sfdc_b2b_apttus__agreement_rule_condition__c',
            'sfdc_b2b_apttus__apts_admin__c',
            'sfdc_b2b_apttus__apts_agreement__c',
            'sfdc_b2b_apttus__document_output_format__c',
            'sfdc_b2b_apttus__mergeevent__c',
            'sfdc_b2b_apttus_srm__sourcingstep__c',
            'sfdc_b2b_apttus_srm__srmobligation__c',
            'sfdc_b2b_asset',
            // 'sfdc_b2b_billing_account_history',
            'sfdc_b2b_campaign',
            'sfdc_b2b_campaigninfluence',
            'sfdc_b2b_campaigninfluencemodel',
            'sfdc_b2b_campaignmember',
            'sfdc_b2b_campaignmemberstatus',
            'sfdc_b2b_casehistory',
            'sfdc_b2b_case',
            'sfdc_b2b_charge_line_item__c',
            'sfdc_b2b_cidn_revenue_targets__c',
            'sfdc_b2b_contacthistory',
            'sfdc_b2b_contact',
            'sfdc_b2b_contentdocument',
            'sfdc_b2b_contentversion',
            'sfdc_b2b_credit_fraud_interactions__c',
            'sfdc_b2b_csbb__product_configuration_request__c',
            'sfdc_b2b_cscfga__action__c',
            'sfdc_b2b_cscfga__attribute__c',
            'sfdc_b2b_cscfga__attribute_definition__c',
            'sfdc_b2b_cscfga__attribute_screen_section_association__c',
            'sfdc_b2b_cscfga__available_product_option__c',
            'sfdc_b2b_cscfga__configuration_offer__c',
            'sfdc_b2b_cscfga__offer_category_association__c',
            'sfdc_b2b_cscfga__product_basket__c',
            'sfdc_b2b_cscfga__product_bundle__c',
            'sfdc_b2b_cscfga__product_category__c',
            'sfdc_b2b_cscfga__product_definition_version__c',
            'sfdc_b2b_csconta__frame_agreement__c',
            'sfdc_b2b_cscrm__account_address_relationship__c',
            'sfdc_b2b_cscrm__account_contact_relationship__c',
            'sfdc_b2b_cscrm__address__c',
            'sfdc_b2b_cscrm__contact_address_relationship__c',
            'sfdc_b2b_cscrm__site__c',
            'sfdc_b2b_csord__Order__History',
            'sfdc_b2b_csord__order_request__c',
            'sfdc_b2b_csord__order_subscription_association__c',
            'sfdc_b2b_csord__service_line_item__c',
            'sfdc_b2b_csord__solution__c',
            'sfdc_b2b_cspmb_price_item_c',
            'sfdc_b2b_customer_authority_form__c',
            'sfdc_b2b_deal_support_request__c',
            'sfdc_b2b_delegated_pricing_request__c',
            'sfdc_b2b_discount_line_item__c',
            'sfdc_b2b_functional_contact_role__c',
            'sfdc_b2b_fund__c',
            'sfdc_b2b_fund_product__c',
            'sfdc_b2b_fund_transaction__c',
            'sfdc_b2b_group',
            'sfdc_b2b_groupmember',
            'sfdc_b2b_leadhistory',
            'sfdc_b2b_lead',
            'sfdc_b2b_marketable_offer__c',
            'sfdc_b2b_npss__c',
            'sfdc_b2b_npss_role_alignment__c',
            'sfdc_b2b_number__history',
            'sfdc_b2b_onefund__c',
            'sfdc_b2b_onefund_transactions__c',
            'sfdc_b2b_opportunitycontactrole',
            'sfdc_b2b_opportunityfieldhistory',
            'sfdc_b2b_opportunityhistory',
            'sfdc_b2b_opportunitylineitem',
            'sfdc_b2b_opportunityshare',
            'sfdc_b2b_opportunity_stages_for_contactrole__mdt',
            'sfdc_b2b_opportunityteammember',
            'sfdc_b2b_portfolio_code__c',
            'sfdc_b2b_post_code__c',
            'sfdc_b2b_pricebookentry',
            'sfdc_b2b_price_schedule_discount_association__c',
            'sfdc_b2b_price_schedule_line_item__c',
            'sfdc_b2b_price_tier_line_item__c',
            'sfdc_b2b_product2',
            'sfdc_b2b_product_attribute_lov__c',
            'sfdc_b2b_program_account__c',
            'sfdc_b2b_program_account__history',
            'sfdc_b2b_program__c',
            'sfdc_b2b_quote',
            'sfdc_b2b_rate_card__c',
            'sfdc_b2b_rate_card_line__c',
            'sfdc_b2b_record_type',
            'sfdc_b2b_role_alignment__c',
            'sfdc_b2b_role_hierarchy__c',
            // 'sfdc_b2b_sfdc_acct_plan__c',
            'sfdc_b2b_territory2',
            'sfdc_b2b_territory2modelhistory',
            'sfdc_b2b_territory2model',
            'sfdc_b2b_user',
            'sfdc_b2b_userrole',
            'sfdc_b2b_userterritory2association',
        ],
        hardCodedCommissionTableData: {
            SFDC_B2B_CSORD__ORDER__C: {
                mandatoryFields: [
                    'id','ownerid','isdeleted','name','recordtypeid','createdbyid','lastmodifiedbyid','csord__account__c','csord__order_number__c',
                    'csord__order_type__c','csord__status2__c','csordtelcoa__opportunity__c','billing_account__c','forecasted_delivery_date__c',
                    'telstra_committed_date__c','stage__c','telstra_ord_end_datetime__c','telstra_ord_start_datetime__c'
                ],
                links: {
                    createdbyid: [ { targetField: ['id'], targetTable: 'sfdc_b2b_user' } ],
                    lastmodifiedbyid: [ { targetField: ['id'], targetTable: 'sfdc_b2b_user' } ],
                    ownerid: [ { targetField: ['id'], targetTable: 'sfdc_b2b_user' } ],
                    csord__account__c: [{ targetField: ['id'], targetTable: 'SFDC_B2B_ACCOUNT' }],
                    csordtelcoa__opportunity__c: [{ targetField: ['id'], targetTable: 'SFDC_B2B_OPPORTUNITY' }]

                },
                isTableLinked: {
                    sfdc_b2b_user: false,
                    SFDC_B2B_ACCOUNT: false,
                    SFDC_B2B_OPPORTUNITY: false,
                }
            },
            sfdc_b2b_user: {
                mandatoryFields: ['id','federationidentifier'],
                links: {
                    id: [
                        { targetField: ['createdbyid','lastmodifiedbyid','ownerid'], targetTable: 'SFDC_B2B_CSORD__ORDER__C' },
                        { targetField: ['createdbyid','lastmodifiedbyid'], targetTable: 'SFDC_B2B_CSORD__SERVICE__C' },
                        { targetField: ['createdbyid','lastmodifiedbyid'], targetTable: 'SFDC_B2B_CSORD__SOLUTION_C' },
                        { targetField: ['createdbyid','lastmodifiedbyid'], targetTable: 'SFDC_B2B_CSORD__SUBSCRIPTION__C' },
                    ]
                },
                isTableLinked: {
                    SFDC_B2B_CSORD__ORDER__C: false,
                    SFDC_B2B_CSORD__SERVICE__C: false,
                    SFDC_B2B_CSORD__SUBSCRIPTION__C: false,
                    SFDC_B2B_CSORD__SOLUTION_C: false,
                }
            },
            sfdc_b2b_functional_contact_role__c: {
                mandatoryFields: ['orderid__c','role__c'],
                links: {},
                isTableLinked: {}
            },
            SFDC_B2B_OPPORTUNITY: {
                mandatoryFields: ['id','accountid','name','type','partneraccountid','cidn__c','opportunity_number__c','partner_account_name__c'],
                links: {
                    id: [{ targetField: ['csordtelcoa__opportunity__c'], targetTable: 'SFDC_B2B_CSORD__ORDER__C' }],
                    partneraccountid: [{ targetField: ['id'], targetTable: 'SFDC_B2B_ACCOUNT' }]
                },
                isTableLinked: {
                    SFDC_B2B_CSORD__ORDER__C: false,
                    SFDC_B2B_ACCOUNT: false,
                }
            },
            SFDC_B2B_ACCOUNT: {
                mandatoryFields: ['id','name','ispartner','cidn__c','business_unit__c','market_segment__c','partner_code__c','mobile_code__c'],
                links: {
                    id: [
                        { targetField: ['csord__account__c'], targetTable: 'SFDC_B2B_CSORD__ORDER__C' },
                        { targetField: ['partneraccountid'], targetTable: 'SFDC_B2B_OPPORTUNITY' }
                    ]
                },
                isTableLinked: {
                    SFDC_B2B_CSORD__ORDER__C: false,
                    SFDC_B2B_OPPORTUNITY: false,
                }
            },
            SFDC_B2B_CSORD__SERVICE__C: {
                mandatoryFields: ['id','name','createdbyid','lastmodifiedbyid','csord__subscription__c','csord__order__c','csord__status__c','csord__total_one_off_charges__c','csord__total_recurring_charges__c','csordtelcoa__product_configuration__c','csordtelcoa__replaced_service__c','csordtelcoa__service_number__c','scenario_type__c'],
                links: {
                    createdbyid: [{ targetField: ['id'], targetTable: 'sfdc_b2b_user' }],
                    lastmodifiedbyid: [{ targetField: ['id'], targetTable: 'sfdc_b2b_user' }],
                    csord__subscription__c: [{ targetField: ['id'], targetTable: 'SFDC_B2B_CSORD__SUBSCRIPTION__C' }],
                    csordtelcoa__product_configuration__c: [{ targetField: ['id'], targetTable: 'sfdc_b2b_cscfga__product_configuration__c' }],
                    csordtelcoa__service_number__c: [{ targetField: ['service_id__c'], targetTable: 'sfdc_b2b_number__c' }]
                },
                isTableLinked: {
                    sfdc_b2b_user: false,
                    SFDC_B2B_CSORD__SUBSCRIPTION__C: false,
                    sfdc_b2b_marketable_offer__c: false,
                    sfdc_b2b_cscfga__product_configuration__c: false,
                    sfdc_b2b_cscfga__product_definition__c: false,
                    sfdc_b2b_contractjunction__c: false,
                    sfdc_b2b_number__c: false,
                }
            },
            SFDC_B2B_CSORD__SUBSCRIPTION__C: {
                mandatoryFields: ['id','name','createdbyid','lastmodifiedbyid','csord__account__c','csord__order__c','csord__status__c','csord__total_one_off_charges__c','csord__total_recurring_charges__c','right_to_bill_date__c','sub_status__c','csordtelcoa__replaced_subscription__c','csordtelcoa__replacement_subscription__c','csordtelcoa__subscription_number__c','contractjunction__c','billing_account__c','csordtelcoa__product_configuration__c','right_to_stop_date__c','cssdm__solution_association__c','tobecancelled__c','marketableoffer__c','partneraccount__c','initialactivationdate__c',],
                links: {
                    id: [{ targetField: ['csord__subscription__c'], targetTable: 'SFDC_B2B_CSORD__SERVICE__C' }],
                    marketableoffer__c: [{ targetField: ['id'], targetTable: 'sfdc_b2b_marketable_offer__c' }],
                    ContractJunction__c: [{ targetField: ['id'], targetTable: 'sfdc_b2b_contractjunction__c' }],
                    createdbyid: [{ targetField: ['id'], targetTable: 'sfdc_b2b_user' }],
                    lastmodifiedbyid: [{ targetField: ['id'], targetTable: 'sfdc_b2b_user' }],
                    csordtelcoa__product_configuration__c: [{ targetField: ['id'], targetTable: 'sfdc_b2b_cscfga__product_configuration__c' }],
                },
                isTableLinked: {
                    SFDC_B2B_CSORD__SERVICE__C: false,
                    sfdc_b2b_marketable_offer__c: false,
                    sfdc_b2b_contractjunction__c: false,
                    sfdc_b2b_user: false,
                    sfdc_b2b_cscfga__product_configuration__c: false,
                }
            },
            sfdc_b2b_marketable_offer__c: {
                mandatoryFields: ['id','offer_id__c'],
                links: {
                    id: [{ targetField: ['marketableoffer__c'], targetTable: 'SFDC_B2B_CSORD__SUBSCRIPTION__C' }],
                },
                isTableLinked: {
                    SFDC_B2B_CSORD__SERVICE__C: false,
                    SFDC_B2B_CSORD__SUBSCRIPTION__C: false,
                }
            },
            sfdc_b2b_cscfga__product_configuration__c: {
                mandatoryFields: ['configurationtype1__c'],
                links: {
                    id: [
                        { targetField: ['csordtelcoa__product_configuration__c'], targetTable: 'SFDC_B2B_CSORD__SERVICE__C' },
                        { targetField: ['csordtelcoa__product_configuration__c'], targetTable: 'SFDC_B2B_CSORD__SUBSCRIPTION__C' }
                    ],
                    cscfga__product_definition__c: [{ targetField: ['id'], targetTable: 'sfdc_b2b_cscfga__product_definition__c' }],
                },
                isTableLinked: {
                    SFDC_B2B_CSORD__SERVICE__C: false,
                    sfdc_b2b_cscfga__product_definition__c: false,
                    SFDC_B2B_CSORD__SUBSCRIPTION__C: false,
                }
            },
            sfdc_b2b_cscfga__product_definition__c: {
                mandatoryFields: ['id','product_specification__c'],
                links: {
                    id: [{ targetField: ['cscfga__product_definition__c'], targetTable: 'sfdc_b2b_cscfga__product_configuration__c' }]
                },
                isTableLinked: {
                    SFDC_B2B_CSORD__SERVICE__C: false,
                    sfdc_b2b_cscfga__product_configuration__c: false,
                }
            },
            sfdc_b2b_contractjunction__c: {
                mandatoryFields: ['id','name','related_opportunity__c','status__c'],
                links: {
                    id: [{ targetField: ['ContractJunction__c'], targetTable: 'SFDC_B2B_CSORD__SUBSCRIPTION__C' }]
                },
                isTableLinked: {
                    SFDC_B2B_CSORD__SERVICE__C: false,
                    SFDC_B2B_CSORD__SUBSCRIPTION__C: false,
                }
            },
            SFDC_B2B_CSORD__SOLUTION_C: {
                mandatoryFields: ['id','createdbyid','lastmodifiedbyid','csord__account__c'],
                links: {
                    createdbyid: [{ targetField: ['id'], targetTable: 'sfdc_b2b_user' }],
                    lastmodifiedbyid: [{ targetField: ['id'], targetTable: 'sfdc_b2b_user' }],
                },
                isTableLinked: {
                    sfdc_b2b_user: false,
                }
            },
            SFDC_B2B_BILLING_ACCOUNT__C: {
                mandatoryFields: ['id','account__c','billing_account_number__c','status__c'],
                links: {},
                isTableLinked: {}
            },
            sfdc_b2b_number__c: {
                mandatoryFields: ['id','service_id__c','service_number__c'],
                links: {
                    service_id__c: [{ targetField: ['csordtelcoa__service_number__c'], targetTable: 'SFDC_B2B_CSORD__SERVICE__C' }]
                },
                isTableLinked: {
                    SFDC_B2B_CSORD__SERVICE__C: false,
                }
            }
        },
        hardCodedSalesforceTableData: {},
        submitFailList: [],
        allNavRetrieved: false,
        tables: [],
        tableFieldNames: {},
    }

    componentDidMount() {
        this.getTables(); 
    }

    componentWillUnmount() {
        this.props.salesforceCommision.isRetrieveTablesFailed ? null : swal.close();
    }

    async getTables() {
        let loadTitle = '';
        let navList = '';
        let num = 0;
        this.props.s2pType === 'commission' ? (loadTitle = "S2P - Salesforce Commision", navList = "hardCodedCommissionNavList") : (loadTitle = "S2P Salesforce", navList = "hardCodedSalesforceNavList")
        displayLoadingWithPercent({ title: loadTitle, bodyText: "Please standby while we fetch necessary table details...", num: num, total: this.state[navList].length});
        for (const item of this.state[navList]) {
            updateLoadingWithPercent(num, this.state[navList].length);
            num++;
            if (this.props.history.location.pathname === "/data/utility/s2psalesforce" || this.props.history.location.pathname === "/data/utility/s2p") {
                await this.props.retrieveSalesforceTables(item);
                if (this.props.salesforceCommision.isRetrieveTablesFailed) {
                    return this.throwError(this.props.salesforceCommision.response);
                }
                this.createNavItemComponentList(item);
                this.createTableFieldNameArrays(item);
            } else {
                return;
            }
        }
        swal.close();
        this.setState({
            allNavRetrieved: true
        });
    }

    createTableFieldNameArrays = (tableName) => {
        let tempArray = [];
        if (this.props.salesforceCommision.isRetrieveTablesSuccess) {
            for (const Obj of this.props.salesforceCommision.response.data) {tempArray.push(Obj.columnName)}
        }

        if (this.props.salesforceCommision.isRetrieveTablesSuccess && tableName) {
            this.setState({
                tableFieldNames: {
                    ...this.state.tableFieldNames,
                    [tableName]: [...tempArray]
                }
            });
        }
    }

    async onSubmit() {
        let count = 1;
        let total = this.state.payloads.length;
        this.setState({
            ...this.state,
            submitDisabled: false
        });

        displayLoadingWithPercent({ title: (this.props.s2pType === 'commission' ? "S2P - Salesforce Commision" : "S2P - Salesforce"), bodyText: "Please standby while table data is submitted...", num: count-1, total: total});
        
        for (const payload of this.state.payloads) {
            let request = this.createIndividualPayload(payload);
            await this.props.submitSalesforceTables(payload.tableName, request, total, count).then(result => {
                updateLoadingWithPercent(count, total);
                count++;
                let message = '';
                if (result === false) {
                    this.setState({
                        ...this.state,
                        submitFailList: [...this.state.submitFailList, ...[payload.tableName]]
                    });
                }
                if (this.props.salesforceCommision.isSubmitTablesFinished) {
                    if (this.state.payloads.length === this.state.submitFailList.length) {
                        swal.close();
                        return this.throwError('Failed to Submit all Tables');
                    } else if (this.state.submitFailList.length === 0) {
                        message = 'Submitted all Data Successfully';
                    } else {
                        message = 'The following Failed to submit: ' + this.state.submitFailList.join(', ');
                    }
                    swal.close();
                    displaySuccess({ title: (this.props.s2pType === 'commission' ? "S2P - Salesforce Commision" : "S2P - Salesforce"), html: message}).then(() => {
                        this.props.history.push(`tdm/utility/`);
                    })
                }
            })
        }
    }

    createIndividualPayload = (payload) => {
        let dataArray = []
        for (const [payloadObj, fields] of Object.entries(payload.fields)) {
            dataArray.push({
                columnName: fields.columnName,
                columnValue: fields.columnValue,
                dataType: fields.dataType
            })
        }
        return {
            data: [
                ...dataArray
            ]
        }
    }

    createPayload = () => { 
        const payloads = [];

        for (const [table, fields] of Object.entries(this.state.formData)) {
            payloads.push({ 
                "tableName": table,
                "fields": fields
            });
        }
        this.setState(prevState => ({
            ...prevState,
            payloads: payloads,
            submitDisabled: payloads.length !== 0 ? false : true,
        }))
    }

    updateTableFieldValue = (table, field, type, e, fieldVal, dataType) => {
        let fieldValue; 
        if (type === 'select') {
            fieldValue = '';
            for (const [key, charValue] of Object.entries(e)) {
                if (typeof charValue === 'string') {
                    fieldValue += charValue
                }
            }
        } else if (type === 'dateTime') {
            fieldValue = new Date(fieldVal);
        } else if (type === 'checkbox') {
            fieldValue = fieldVal === true ? 1 : 0;
        } else {
            fieldValue = e.target.value;
        }
        this.setState({
            ...this.state,
            formData: { 
                ...this.state.formData,
                [table]: {
                    ...this.state.formData[table],
                    [field]: {
                        columnName: field,
                        columnValue: fieldValue,
                        dataType: dataType
                    }
                }
            }
        }, () => {
            let tableData = (this.props.s2pType === 'commission' ? 'hardCodedCommissionTableData' : 'hardCodedSalesforceTableData');
            if (this.state[tableData][table]) {
                if (this.state[tableData][table].links[field]) {
                    for (const link of this.state[tableData][table].links[field]) {
                        let targetTable = link.targetTable;
                        let targetFields = link.targetField;
                        let isLinked = false;
                        for (const tarField of targetFields) {
                            if (this.state.formData && this.state.formData[targetTable] && this.state.formData[targetTable][tarField]) {
                                if (this.state.formData[targetTable][tarField] && 
                                    this.state.formData[targetTable][tarField].columnValue === fieldValue) {
                                    isLinked = true;
                                    break;
                                }
                            }
                        }
                        this.setLinked(isLinked, table, targetTable);
                    }
                } else if (this.state[tableData][table].isTableLinked) {
                    for (const [linkTable, link] of Object.entries(this.state[tableData][table].isTableLinked)) {
                        if (link) {
                            this.integrateData(table, linkTable);
                        }
                    }
                }
            }
            this.createPayload();
        })
    }

    integrateData = (table, targetTable) => {
        let combinedFormFields = {...this.state.formData[targetTable], ...this.state.formData[table]};

        for (const [Obj, field] of Object.entries(combinedFormFields)) {
            if (this.state.tableFieldNames[table].includes(field.columnName) && this.state.tableFieldNames[targetTable].includes(field.columnName)) {
                this.setState(prevState => ({
                    ...prevState,
                    formData: { 
                        ...prevState.formData,
                        [table]: {
                            ...prevState.formData[table],
                            [(field.columnName)]: {
                                columnName: field.columnName,
                                columnValue: field.columnValue,
                                dataType: field.dataType
                            }
                        },
                        [targetTable]: {
                            ...prevState.formData[targetTable],
                            [(field.columnName)]: {
                                columnName: field.columnName,
                                columnValue: field.columnValue,
                                dataType: field.dataType
                            }
                        }
                    }
                }), () => {
                    this.createPayload();
                    this.props.dispatch(change('utilityS2p', field.columnName+'_'+table, field.columnValue));
                    this.props.dispatch(change('utilityS2p', field.columnName+'_'+targetTable, field.columnValue));
                });
            }
        }
    }

    setLinked = (linked, table, targetTable) => {
        let tableData = (this.props.s2pType === 'commission' ? 'hardCodedCommissionTableData' : 'hardCodedSalesforceTableData')
        this.setState(prevState => ({
            ...prevState,
            [tableData]: {
                ...prevState[tableData],
                [table]: {
                    ...prevState[tableData][table],
                    isTableLinked: {
                        ...prevState[tableData][table].isTableLinked,
                        [targetTable]: linked,
                    }
                },
                [targetTable]: {
                    ...prevState[tableData][targetTable],
                    isTableLinked: {
                        ...prevState[tableData][targetTable].isTableLinked,
                        [table]: linked,
                    }
                }
            }
        }),() => {
            if (linked) {
                this.integrateData(table, targetTable);
            }
        });        
    }

    convertToOption = (fieldOption) => {
        let options = [];
        fieldOption.forEach(attribute => {
            options.push({value: attribute, label: attribute});
        });
        return options;
    }

    convertDataType = (type) => {
        switch (type) {
            case "string":
                return 'text';
            case "select":
                return 'select'
            case "timestamp":
                return 'dateTime'
            case "double":
                return 'number'
            case "int":
                return 'checkbox'
            default:
                break;
        }
    }

    createForm = (item) => {
        let tableData = (this.props.s2pType === 'commission' ? 'hardCodedCommissionTableData' : 'hardCodedSalesforceTableData')
        return (
            <Fragment>
                <h4>{item.tableName}</h4>
                <br/>
                {
                    item.fields.map((field, index) => {
                        const type = this.convertDataType(field.dataType)
                        let req = false
                        if (this.state[tableData][item.tableName]) {
                            req = this.state[tableData][item.tableName].mandatoryFields.includes(field.columnName)
                        }
                        
                        let mode = '';
                        let classNames = '';
                        switch (type) {
                            case "number":
                                mode = "numeric";
                                break;
                            case "dateTime":
                                classNames = "datetime"
                                break;
                            case "checkbox":
                                classNames = "checkbox s2p-checkbox"
                                break;
                            default:
                                mode = "text";
                                break; 
                        }
                        return (
                            <Fragment key={index}>
                                <Field className={"tableField s2p", classNames}
                                    label={field.columnName + (req ? '*':'')}
                                    name={field.columnName+'_'+item.tableName}
                                    height="25px"
                                    type={type}
                                    inputMode={mode}
                                    component={renderField}
                                    autoComplete={false}
                                    validate={(value) => this.requiredField(req, value)}
                                    feedback={false}
                                    onChange={(e, fieldVal) => this.updateTableFieldValue(item.tableName, field.columnName, type, e, fieldVal, field.dataType)}
                                    options={
                                        type == 'select' ? (
                                            this.convertToOption(field.options)
                                        ) : null
                                    }
                                />
                            </Fragment>
                        )
                    })
                }
            </Fragment>
        )
    }

    requiredField = (req, value) => {
        if (req) {
           return notNullOrUndefined(value);
        }
    }

    throwError = (error) => {
        swal.fire({
            type: 'error',
            title: 'Error',
            html: error,
            confirmButtonText: 'OK',
        }).then(() => {
            this.props.history.replace(`/data/utility/`);
        })
    }

    createNavItemComponentList = (tableName) => {
        const newNavItems = [];
        const newFormBodyItems = [];
        let navList = '';
        this.props.s2pType === 'commission' ? navList = 'hardCodedCommissionNavList' : navList = 'hardCodedSalesforceNavList'
        if (this.props.salesforceCommision.isRetrieveTablesSuccess && tableName) {
            this.setState({
                tables: [
                    ...this.state.tables,
                    {
                        tableName: tableName,
                        fields: [...this.props.salesforceCommision.response.data],
                    }
                ]
            }, () => {
                if (this.state.tables.length === this.state[navList].length) {
                    this.state.tables.forEach((item) => {
                        newNavItems.push(<Fragment><InputOutlinedIcon color="secondary"/>{item.tableName}</Fragment>);
                        newFormBodyItems.push(this.createForm(item));
                    });
        
                    this.setState({
                        ...this.state,
                        navItems: [...this.state.navItems, ...newNavItems],
                        bodyItems: [...this.state.bodyItems, ...newFormBodyItems]
                    });
                }
            }) 
        }
    }

    render() {
        let loadTitle = this.props.s2pType === 'commission' ? "S2P - Salesforce Commision" : "S2P Salesforce"
        return (
            <Fragment>
                <div className="white">
                    <HeaderContent title={loadTitle} icon={'cogs cog-icon'} empty={true} loadingText='Loading forms...'/>
                    {this.state.allNavRetrieved ? <VerticalTabs navItemList={this.state.navItems} bodyItemList={this.state.bodyItems} isForm={true} formOnSubmit={this.onSubmit.bind(this)} submitDisabled={this.state.submitDisabled}/> : null }
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        salesforceCommision: state.utility.s2pSalesForceCommision,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        retrieveSalesforceTables : (tableName) => dispatch(retrieveSalesforceTables(tableName)),
        submitSalesforceTables : (table, request, total, count) => dispatch(submitSalesforceTables(table, request, total, count)),
    };
};
export default compose(connect(mapStateToProps, mapDispatchToProps),reduxForm({form: "utilityS2p"}))(UtilityS2p);