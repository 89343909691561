import { useState, useEffect } from "react";
import TilesFrame from "../Data/accordion-frames/replicator/DataTilesFrame";
import UnmsRanges from "./UnmsRanges"
import { connect } from "react-redux";
import * as fnnActions from "../../actions/dataCleanUpActions";
import HeaderContent from '../common/Header/HeaderContent';
import "./UnmsInsert.css";
import Select from '@material-ui/core/Select';
import { MenuItem, TextField, InputLabel, FormControl, Button, Table, Tooltip } from '@material-ui/core';
import swal from 'sweetalert2';
import { displaySuccess, displayErrorNew, displayLoading } from "../common/alerts/alerts";
import { charmsInsertAction } from '../../../src/actions/tdmOpsActions';

const unmsResourceAllTeams = [
    {
        label: "FNN",
        value: 61,
        length: 9,
        title: "FNN",
        icon: "icon-mobile-messaging"
    }, {
        label: "MSISDN",
        value: 61,
        length: 9,
        title: "MSISDN",
        icon: "icon-mobile-messaging"
    }
]

const CharmsInsert = ({ ranges, env_value }) => {
    const [unmsRscTypes, setUnmsRscTypes] = useState(null);
    const [owningCarrier, setOwningCarrier] = useState(null);
    const [Wholesale, setWholesale] = useState(null);
    const [attributeTypes, setattributeTypes] = useState([{
        name: 'Primary Lrd',
        value: 'primary_lrd',
        show: true
    }, {
        name: 'Secondary Lrd',
        value: 'secondary_lrd',
        show: true
    }, {
        name: 'Lnp1',
        value: 'lnp1',
        show: true
    }, {
        name: 'Lnp2',
        value: 'lnp2',
        show: true
    }, {
        name: 'Model',
        value: 'model',
        show: true
    }]);

    const [attributeList, setattributeList] = useState([]);
    const [attributeValue, setAttributeValue] = useState(null);
    const [attributeName, setAttributeName] = useState(null);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setAttributeValue(null);
        setShowError(false)
    }, [attributeName])
    const selectUnmsResourceType = (type) => {
        setUnmsRscTypes(type.label);
        setOwningCarrier(null)
        setWholesale(null)
        setattributeTypes([{
            name: 'Primary Lrd',
            value: 'primary_lrd',
            show: true
        }, {
            name: 'Secondary Lrd',
            value: 'secondary_lrd',
            show: true
        }, {
            name: 'Lnp1',
            value: 'lnp1',
            show: true
        }, {
            name: 'Lnp2',
            value: 'lnp2',
            show: true
        }, {
            name: 'Model',
            value: 'model',
            show: true
        }])
        setattributeList([])
        setAttributeValue(null)
        setAttributeName(null)
    }

    useEffect(() => {
        let remainAttribute = [{
            name: 'Szu Id',
            value: 'szu_id',
            show: true
        }, {
            name: 'Esa Code',
            value: 'esa_code',
            show: true
        }]
        if (unmsRscTypes === 'FNN') {
            let cpyAttributeList = [...attributeTypes];
            let mergeAttribute = cpyAttributeList.concat(remainAttribute);
            setattributeTypes(mergeAttribute)
        } else {
            let attribute = [{
                name: 'Primary Lrd',
                value: 'primary_lrd',
                show: true
            }, {
                name: 'Secondary Lrd',
                value: 'secondary_lrd',
                show: true
            }, {
                name: 'Lnp1',
                value: 'lnp1',
                show: true
            }, {
                name: 'Lnp2',
                value: 'lnp2',
                show: true
            }, {
                name: 'Model',
                value: 'model',
                show: true
            }]
            setattributeTypes(attribute)
        }
    }, [unmsRscTypes]);

    const selectUpdateType = obj => {
        selectFilter("update", obj.label);
    };

    const changeCarrier = (e) => {
        setOwningCarrier(e.target.value)
    }

    const changeWholesale = (e) => {
        setWholesale(e.target.value)
    }
    const changeAttribute = (e) => {
        setAttributeName(e.target.value);
    }
    const addAttribute = () => {
        if (attributeName && attributeValue) {
            return setattributeList([...attributeList, { resourceAttributeName: attributeName, resourceAttributeValue: attributeValue }])
        }
        return swal.fire({
            title: 'Validation Error',
            text: !attributeName ? 'Please Choose any attribute' : 'Please enter attribute value',
        })
    }
    const removeAttribute = (resourceAttributeName) => {
        setattributeList(current => current.filter((_item) => _item.resourceAttributeName !== resourceAttributeName))
        setattributeTypes(current =>
            current.map(_item => {
                if (_item.name === resourceAttributeName) {
                    _item.show = true;
                }
                return _item
            }))
    }
    const changeAttributeValue = (e) => {
        if (attributeName === "Esa Code") {
            if (e.target.value && e.target.value.length > 4) {
                setAttributeValue(e.target.value)
                return setShowError(true)
            }
            setAttributeValue(e.target.value)
            return setShowError(false)
        } return setAttributeValue(e.target.value)
    }
    useEffect(() => {
        setattributeTypes(current =>
            current.map(_item => {
                if (_item.name === attributeName) {
                    _item.show = false;
                }
                return _item
            }))
    }, [attributeList]);
    useEffect(() => {
        setAttributeValue(null)
        setAttributeName(null)
    }, [attributeList]);

    const validationCheck = () => {
        displayLoading({ title: "Loading", bodyText: "Please wait as we process your data" })
        if (!owningCarrier) {
            return swal.fire({
                title: 'Validation Error',
                text: 'please choose any Owning Carrier'
            })
        } else {
            if (unmsRscTypes === "MSISDN") {
                if (!Wholesale) {
                    return swal.fire({
                        title: 'Validation Error',
                        text: 'please choose any Retail Wholesale Indicator'
                    })
                } else {
                    let additional_req = [{
                        resourceAttributeName: "Owning Carrier",
                        resourceAttributeValue: owningCarrier
                    }, {
                        resourceAttributeName: "Retail Wholesale Indicator",
                        resourceAttributeValue: Wholesale
                    }];
                    let cpy_attributeList = [...attributeList];
                    let mergeAttribute = cpy_attributeList.concat(additional_req);

                    let reqBody = {
                        "environment": env_value,
                        "startRange": ranges[0].startRange,
                        "endRange": ranges[0].endRange,
                        "resourceType": unmsRscTypes,
                        "resourceAttributes": mergeAttribute
                    }
                    charmsInsertAction(reqBody).then((result) => {
                        if (result.data.responseMessage === "Success") {
                            return displaySuccess({ title: "Success", text: `Number Range Attribute Id:${result.data.numberRangeAttributeId}` });
                        }
                    }).catch((error) => {
                        displayErrorNew({
                            title: error.response.data.message,
                            subTitle: error.response.data.details
                        });
                    })
                }
            } else {
                let tem_arr = [];
                attributeList.forEach((_data) => {
                    if (_data.resourceAttributeName === 'Esa Code') {
                        tem_arr.push(true)
                    }
                    if (_data.resourceAttributeName === 'Szu Id') {
                        tem_arr.push(true)
                    }
                });
                if (tem_arr.length === 2) {
                    let owning_carrier = {
                        resourceAttributeName: "Owning Carrier",
                        resourceAttributeValue: owningCarrier
                    }
                    let cpy_attributeList = [...attributeList];
                    cpy_attributeList.push(owning_carrier);
                    let reqBody = {
                        "environment": env_value,
                        "startRange": ranges[0].startRange,
                        "endRange": ranges[0].endRange,
                        "resourceType": unmsRscTypes,
                        "resourceAttributes": cpy_attributeList
                    }
                    charmsInsertAction(reqBody).then((result) => {
                        if (result.data.responseMessage === "Success") {
                            return displaySuccess({ title: "Success", text: `Number Range Attribute Id:${result.data.numberRangeAttributeId}` });
                        }
                    }).catch((error) => {
                        displayErrorNew({
                            title: error.response.data.message,
                            subTitle: error.response.data.details
                        });
                    })
                } else {
                    return swal.fire({
                        title: 'Validation Error',
                        text: 'please add Esa code and Szu ID'
                    })
                }
            }
        }
    }

    const charmSubmit = () => {
        if (ranges[0].startRange === '' || ranges[0].endRange === '') {
            return swal.fire({
                title: 'Validation Error',
                text: 'please provide range required'
            })
        } return validationCheck()
    }

    return <div className="charmInsert">
        <TilesFrame
            heading={"Resource type "}
            color="green"
            data={unmsResourceAllTeams}
            select={selectUnmsResourceType}
            selected={unmsRscTypes}
        />
        {unmsRscTypes ?
            <>
                <UnmsRanges
                    selectUpdateType={selectUpdateType}
                    //  selectResourceType={selectResourceType}
                    unmsResourceType={unmsRscTypes}
                    editType={"Insert"}
                    hideResourceSubtype={true}
                />
                <HeaderContent
                    title={`Add Attributes`}></HeaderContent>
                <FormControl style={{ "width": "100%", "paddingTop": "10px" }}>
                    <InputLabel>Owning Carrier*</InputLabel>
                    <Select
                        labelId="attributes"
                        name="attributes"
                        value={owningCarrier}
                        onChange={(e) => changeCarrier(e)}
                    >
                        <MenuItem value={"TELSTRA"}>TELSTRA</MenuItem>
                        <MenuItem value={"OPTUS"}>OPTUS</MenuItem>
                    </Select>
                </FormControl><br />
                {unmsRscTypes === "MSISDN" ? <FormControl style={{ "width": "100%", "paddingTop": "10px" }}>
                    <InputLabel>Retail Wholesale Indicator*</InputLabel>
                    <Select
                        labelId="attributes"
                        name="attributes"
                        value={Wholesale}
                        onChange={(e) => changeWholesale(e)}
                    >
                        <MenuItem value={"TELSTRA"}>Telstra Retail</MenuItem>
                        <MenuItem value={"Wholesale"}>Telstra Wholesale</MenuItem>
                    </Select>
                </FormControl> : null}

                <FormControl style={{ "width": "50%", "float": "left" }}>
                    <InputLabel>Attributes</InputLabel>
                    <Select
                        labelId="attributes"
                        name="attributes"
                        value={attributeName || ''}
                        onChange={changeAttribute}
                    >
                        {attributeTypes.map((item) => item.show ? <MenuItem value={item.name}>{item.name}</MenuItem> : null)}
                    </Select>
                </FormControl>
                <form style={{ "width": "40%", "height": "100%", "float": "left", "paddingTop": "10px", "paddingLeft": "2%", "paddingRight": "2%" }} noValidate autocomplete="off">
                    <TextField
                        style={{ "width": "100%", "height": "100%" }}
                        id="attribute"
                        label="Value"
                        variant="outlined"
                        // disabled={this.props.selectedAttribute ? false : true}
                        value={attributeValue || ''}
                        helperText={showError ? 'Character limit of 4' : null}
                        onChange={changeAttributeValue}
                        error={showError}
                    />
                </form>
                <div style={{
                    "width": "5%",
                    "float": "right",
                    "paddingTop": "20px"
                }}>
                    <Tooltip title={"Select an attribute and add a value"}>
                        <span>
                            <div className="addAttributeBtn">
                                <Button
                                    disabled={showError}
                                    onClick={addAttribute}
                                    className="plus-btn">+</Button>
                            </div>
                        </span>
                    </Tooltip>

                </div>
                {attributeList && attributeList.length > 0 &&
                    <div className="attributeTable">
                        <Table hover response>
                            <thead>
                                <tr>
                                    <th>Attribute</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    attributeList.map(e => {
                                        return (
                                            <tr>
                                                <td>{e.resourceAttributeName}</td>
                                                <td>{e.resourceAttributeValue}</td>
                                                <td>
                                                    <Button className="remove-btn" onClick={() => removeAttribute(e.resourceAttributeName)}>-</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                }
                <Button style={{ "background-color": "green", "color": "white", "marginLeft": "5px" }} type="submit" variant="contained" onClick={charmSubmit}>Submit</Button>
            </>
            : null}
    </div>
}
const mapStateToProps = state => {
    return {
        env_value: state.auth.selectedEnvValue,
        ranges: (state.form.unmsInsert && state.form.unmsInsert.values) ? state.form.unmsInsert.values.ranges : [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectFilter: (key, value) => dispatch(fnnActions.selectDataCleanUpFilter(key, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CharmsInsert);
