import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import {getAcronym} from '../../../../../utils/ui';

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#fff000',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        width: '20px',
        height: '20px',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    }
}))(Badge);


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: 50,
        height: 50,
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    personalIcon: {
        width: '15px',
        height: '15px'
    }

}));

export default function BadgeAvatars({av}) {
    const classes = useStyles();
    
    return (

        <StyledBadge
            overlap="circle"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            badgeContent={(!av.id && !av.teamId) ? (<PersonIcon className={classes.personalIcon} color='primary' />) :
                (<PeopleIcon className={classes.personalIcon} color='primary' />)}

        >
            <Tooltip arrow title={av.teamDisplayName || av.teamName} aria-label="add" placement='top'>
                <Fab color={"secondary"} className={classes.fab}>
                    {getAcronym(av.teamDisplayName || av.teamName)}
                </Fab>
            </Tooltip>

        </StyledBadge>

    );
}