import React from 'react';
import { Table } from 'reactstrap';
import PaginationController from "../../../../src/utils/PaginationController";
import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";
import { fetchExistingRecord } from '../../../actions/timMigrationActions'
import { useEffect } from 'react';
import { useState } from 'react';
import ErrorView from '../../TDMOps/ErrorView';


const queryMigrationServiceStyle = makeStyles({
    CircularProgressStyle: {
        width: "70px !important",
        height: "70px !important",
        marginLeft: "600px",
        marginBottom: "100px",
        marginTop: "40px"
    }
});
const QueryMigratoinService = (props) => {
    const { fetchExistingRecordData, totalPages, queryMigrationStatus } = props;
    const [page, setPage] = useState(0);
    const classes = queryMigrationServiceStyle();
    useEffect(() => {
        props.fetchExistingRecord({ page })
    }, [page]);

    const QueryMigratoinTable = () => {
        if (queryMigrationStatus) {
            return <div id="resource-table">
                <Table>
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Team Name</th>
                            <th>Job Id</th>
                            <th>Batch Id</th>
                            <th>Status</th>
                            <th>Created Date</th>
                            <th>Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fetchExistingRecordData.map((resource, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{resource.username}</td>
                                        <td>{resource.teamName}</td>
                                        <td>{resource.jobId}</td>
                                        <td>{resource.batchId}</td>
                                        <td>{resource.status}</td>
                                        <td>{resource.createdDate}</td>
                                        <td><Tooltip title="Download" placement="right">
                                            <Button href={`data:text/json;charset=utf-8,${encodeURIComponent(resource.payload)}`} download="timMigration.json" variant="contained" color="primary"><DownloadIcon /></Button>
                                        </Tooltip>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        }else{
          return  <ErrorView message={fetchExistingRecordData}/>
        }
    }
    return <>
        {fetchExistingRecordData ? <QueryMigratoinTable /> : <CircularProgress classes={{ root: classes.CircularProgressStyle }} />}
        <PaginationController
            totalPages={totalPages}
            gotoPage={(pageNumber) => setPage(pageNumber)}
        />
    </>
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchExistingRecord: (req) => dispatch(fetchExistingRecord(req)),
    };
};

const mapStateToProps = state => {
    return {
        fetchExistingRecordData: state.timMigration?.fetchExistingRecord,
        totalPages: state.timMigration.totalPages,
        queryMigrationStatus: state.timMigration.status
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QueryMigratoinService);