import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Collapse, CardBody, Card } from "reactstrap";
import { getPaymentBanLevelWithDate, getBillingAccountDetails, getBillingAdjustments } from "../../actions/u2cActions";
import { setTitle } from "../../utils/browser";
import PaymentModule from "./payment/PaymentModule";
import SubscriptionModule from "./subscription/SubscriptionModule";
import ButtonBar from "./common/ButtonBar";
import AccountDetailsCard from "./account/AccountDetailsCard";
import AccountDetailsBar from "./account/AccountDetailsBar";
import Adjustment from "./Adjustment.js"
import DisplayAddress from "./address/DisplayAddress";
import "./CreateBillingAccount.css";
import { SubHeader } from "../tconnect/modals/HeaderContent";

class CreateBillingAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payment: false,
      invoice: false,
      subscription: false,
      myAdjustment: false
    };
  }

  componentDidMount() {
    setTitle("Billing Account");
    const { ban: paramId } = this.props.match.params;
    this.props.getBillingAccountDetails(paramId)

    const endDate = moment().format("YYYY-MM-DD");
    const startDate = moment(endDate).subtract("year", 1).format("YYYY-MM-DD");;
    this.props.loadBanLevelWithDate(paramId, startDate, endDate);
    this.props.getBillingAdjustments(paramId)
    this.accordionEnd = React.createRef();
  }

  componentDidUpdate() {
    //this.scrollToBottom();
  }

  openAddressModal = () => {
    this.addressModal.open();
  }
  componentWillUnmount() { }
  scrollToBottom = () => {
    this.accordionEnd.current.scrollIntoView({ behavior: "smooth" });
  };

  toggle = section => {
    this.setState(preState => ({ ...preState, [section]: !preState[section] }));
  };

  render() {

    const { billingAddressLoading, billingAddressFailed } = this.props;
    const { payment, subscription, myAdjustment, invoice, endDate, myBill } = this.state;
    const { startDate, companyName, rouCode, billCycleDay, paymentTerms, billingAddress, billingAccountNumber } = this.props.billingAccount;
    const billingAdjustments = this.props.billingAdjustments;
    const billingAdjListSize = this.props.billingAdjustments.length || 0;

    return (<div className="white">
      <div className="custom-header">
        <SubHeader
          text={`Usage to cash`}
          iconClass="icon-billing"
          iconColor="blue"
          loading={billingAddressLoading}
          loadingText="Loading Account Details..."
          featured
          h1
        />
      </div>
      {billingAddressFailed && <div className="loading-failed" ><h3>Unable to load !! </h3></div>}
      {
        !billingAddressLoading && !billingAddressFailed && (<React.Fragment>
          <div className="main-component">
            <div className="container">

              <DisplayAddress
                billingAccountNumber={billingAccountNumber}
                {...billingAddress}
                addressModal={this.addressModal}
                heading={"Usage to cash"}
              />
            </div>

            <AccountDetailsBar />
            <div className="row account-details">
              <div className="col-sm-6">
                <AccountDetailsCard
                  billingAccountNumber={billingAccountNumber}
                  startDate={startDate} endDate={endDate}
                  companyName={companyName}
                  billCycleDay={billCycleDay}
                  paymentTerms={paymentTerms}
                  rouCode={rouCode}
                />
              </div>
              <div className="col-sm-6">
                <AccountDetailsCard
                  billingAccountNumber={billingAccountNumber}
                  startDate={startDate} endDate={endDate}
                  companyName={companyName}
                  billCycleDay={billCycleDay}
                  paymentTerms={paymentTerms}
                  rouCode={rouCode}
                />
              </div>
            </div>

            <div className="accordion">
              <ButtonBar section="payment" toggle={this.toggle} heading="Payments" icon="icon-collect-mobile-payments" />
              <Collapse isOpen={payment} id="collapse-payment">
                <Card style={{ height: "auto", width: "auto", marginTop: "0px" }}>
                  <CardBody>
                    <PaymentModule />
                  </CardBody>
                </Card>
              </Collapse>

              <ButtonBar section="subscription" toggle={this.toggle} heading="Subscriptions" icon="icon-flybuys" />
              <Collapse isOpen={subscription}>
                <Card style={{ height: "auto", width: "auto", marginTop: "0px" }}>
                  <CardBody>
                    <SubscriptionModule />
                  </CardBody>
                </Card>
              </Collapse>

              <ButtonBar section="myBill" toggle={this.toggle} heading="My Bills" icon="icon-bill-manage" />
              <Collapse isOpen={myBill}>
                <Card style={{ height: "auto", width: "auto", marginTop: "0px" }}>
                  <CardBody><h5>Coming Soon...</h5></CardBody>
                </Card>
              </Collapse>

              <ButtonBar section="myAdjustment" toggle={this.toggle} heading="Adjustment" icon="icon-bill-payment" />
              <Collapse isOpen={myAdjustment}>
                <Card style={{ height: "auto", width: "auto", marginTop: "0px" }} >
                  <Adjustment billingAdjListSize={billingAdjListSize} billingAdjustments={billingAdjustments} />
                </Card>
              </Collapse>
            </div>
          </div>
          <div ref={this.accordionEnd} />
        </React.Fragment>)
      }
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    billingAccount: state.u2c.billingAccount,
    billingAdjustments: state.u2c.billingAdjustments || [],
    billingAddressLoading: state.u2c.billingAddressLoading,
    billingAddressFailed: state.u2c.billingAddressFailed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadBanLevelWithDate: (ban, startDate, endDate) => dispatch(getPaymentBanLevelWithDate(ban, startDate, endDate)),
    getBillingAccountDetails: (billingNum, pushToNextPage) => dispatch(getBillingAccountDetails(billingNum, pushToNextPage)),
    getBillingAdjustments: (billingAccountNumber) => dispatch(getBillingAdjustments(billingAccountNumber)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBillingAccount);
