import React, { Fragment, useState } from "react";
import { connect } from 'react-redux';


import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
  makeStyles,  
} from "@material-ui/core";

import LinkButton from "../common/custom/LinkButton";
import NotificationMessagePanel from "./NotificationMessage.js"

import './NotificationSummary.css';
import _ from 'lodash';

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    right: "-26px",
    top: "40px",
  },
  dialogContent: {
    padding: "0px",
    margin: "0px",
  },
  viewAllLink : {
    backgroundColor : "none"
  }
});

const NotificationSummary = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      {props.notificationsMessageOpen ?
        (<NotificationMessagePanel messageDetails={props.notificationMessageDetails}
          closeNotificationsMessage={props.closeNotificationsMessage}/>) :
        (
          <Dialog maxWidth="md" scroll="paper" open={true}
            hideBackdrop={true}
            onClose={props.closeNotification}
            classes={{ paper: classes.dialog }}>
            <div>
            <DialogTitle>Notification Summary</DialogTitle>
            <Divider />
            <DialogContent classes={{ root: classes.dialogContent }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"

              >
                <Box p={1} my={1} display="flex" flexDirection="column" maxHeight="230px" width="410px">
                  {props.summaryList && props.summaryList.length > 0 && !props.notificationFail ? <ul className="list-style">{props.summaryList}</ul> : null}
                  {(!props.notificationFetchLoading && props.totalMessageCount === 0) || props.notificationFail ? <p className="no-messages">No Messages to Display!</p> : null}
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Grid container justify="end">
              <Grid item xs={3}>
                  <LinkButton to="/notification"  className="viewAllLink">
                 <Button color="primary" onClick={props.closeNotification}> VIEW ALL</Button>
              </LinkButton>
                </Grid>
                <Grid item xs={7}></Grid>
                <Grid item xs={2}>
                  <Button color="primary" onClick={props.closeNotification}>Close</Button>
                </Grid>
              </Grid>
            </DialogActions></div>
          </Dialog>)}</Fragment>
  );
};

const mapStateToProps = state => {
  return {
    notificationFetchLoading: state.notification.notificationFetchLoading,
    totalMessageCount: state.notification.totalMessageCount,
    userId: state.auth.userName,
    summaryList: state.notification.summaryList,
    notificationFail: state.notification.notificationFetchFail,
    notificationMessageDetails: state.notification.notificationMessageDetails,
    notificationMessagePanel: state.notification.notificationMessagePanel
  };
};

export default connect(mapStateToProps)(NotificationSummary);
