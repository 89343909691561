import React, { Fragment, useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper';
import { Table } from 'reactstrap';
import SimRow from './SimRow';
import PaginationController from "../../../utils/PaginationController";
import './physicalSim.css'

const ReviewForm = (props) => {

    const simImsis = props.simImsi;

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [displayData, setDisplayData] = useState([]);

    useEffect(() => {
        if (simImsis.length < 11) {
            props.submitDisabled(false);
        }
        setTotalPages(Math.ceil((props.simImsi.length) / 10))
    }, []);

    useEffect(() => {
        setDisplayData(simImsis.slice(currentPage*10, currentPage*10+10));
        if (currentPage === totalPages-1) {
            props.submitDisabled(false);
        }
    }, [currentPage])

    const gotoPage = (pageNum) => {
        setCurrentPage(pageNum)
    }

    return (
        <Fragment>
            {
                props.msisdns.length > 0 ? 
                <Paper elevation={1} className="reviewFormWrapper">
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>MSISDN</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <tr>
                                    <td>{props.msisdns.join(', ')}</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </Paper> : null
            }
            <div className="simImsiBody">
                {
                    (displayData).map((obj, index) => (
                        <Paper elevation={1} key={index} className="reviewFormWrapper">
                            <SimRow object={obj}/>
                        </Paper>
                    ))
                } 
            </div>
            {totalPages !== 0 ? <PaginationController totalPages={totalPages} gotoPage={gotoPage}/> : null}
        </Fragment>
    );
}

export default ReviewForm;