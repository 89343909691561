import {axiosTaasInstance as axios} from "../axios.config";
import config from "../config";
import * as types from "./u2cActionTypes";
import { authHeaders } from '../utils/api';
import { displayErrorNew } from '../components/common/alerts/alerts';

const API_URL = config.tdm_gateway_api.uri + '/tdm/billing/u2c';

export const generateBAN = pushToNextPage => async dispatch => {
  try {
    dispatch({ type: types.BAN_LOADING });
    const response = await axios.get(`${API_URL}/generate/billingAccountNumber`, { headers: authHeaders() });
    dispatch({
      type: types.GENERATE_BAN,
      payload: response.data.billingAccountNumber
    });
    await dispatch(createBillingAccount(response.data.billingAccountNumber, pushToNextPage)); // to create billing account after generating b number;
    // without await delay with pop up and pushToNextPage will occur
    return response.data;
  } catch (e) {
    console.log(e);
    dispatch({ type: types.GENERATE_BAN_FAILED });
    return undefined;
  }
};

export const createBillingAccount = (
  billingNumber,
  pushToNextPage
) => async dispatch => {
  try {
    dispatch({ type: types.CREATE_ACCOUNT_PENDING });
    const response = await axios.get(`${API_URL}/createBillingAccount/${billingNumber}`, { headers: authHeaders() });
    dispatch({ type: types.CREATE_BILLING_ACCOUNT });
    return await dispatch(getBillingAccountDetails(billingNumber, pushToNextPage));
  } catch (e) {
    console.log("e");
    dispatch({ type: types.CREATE_ACCOUNT_FAILED });
    return undefined;
  }
};

export const getBillingAccountDetails = (
  billingNumber,
  pushToNextPage
) => async dispatch => {
  try {
    dispatch({ type: types.BILLING_ADDRESS_LOADING });
    const response = await axios.get(`${API_URL}/getBillingAccountDetails/${billingNumber}`, { headers: authHeaders() });
    pushToNextPage && pushToNextPage(billingNumber);
    dispatch({ type: types.GET_BILLING_ADDRESS, payload: response.data.billingAccount });
    return response.data;
  } catch (e) {
    console.log(e);
    dispatch({ type: types.BILLING_ADDRESS_FAILED });
    return undefined;
  }
};

export const getBillingAdjustments = (billingNumber) => async dispatch => {
  try {
    dispatch({ type: types.GET_ADJ_PENDING });
    const resp = await axios.get(`${API_URL}/getBillingAdjustments/${billingNumber}`, { headers: authHeaders() });
    dispatch({
      type: types.GET_BILLING_ADJUSTMENTS,
      payload: resp.data.adjustments
    });
  } catch (e) {
    console.log(e);
  }
}

export const setBillingAccountNumber = (
  billingAccountNumber,
  pushToNextPage
) => async dispatch => {
  if (!Number(billingAccountNumber) && billingAccountNumber.length > 0) {
    return;
  }
  try {
    dispatch({ type: types.SET_BAN, payload: billingAccountNumber });
    if (billingAccountNumber.length == 12) {
      return dispatch(getBillingAccountDetails(billingAccountNumber, pushToNextPage));
    }
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const resetU2CData = () => async dispatch => {
  dispatch({ type: types.RESET_U2C_DATA });
};

export const getPaymentBanLevelWithDate = (
  billingAccountNumber,
  startDate,
  endDate
) => async dispatch => {
  try {
    dispatch({ type: types.LOAD_PAYMENT_WITH_DATE_PENDING });
    const response = await axios.get(`${API_URL}/payment/ban-level/withDate`,
      {
        headers: authHeaders(),
        params: {
          billingAccountNumber,
          startDate,
          endDate
        }
      }
    );
    dispatch({
      type: types.LOAD_PAYMENT_WITH_DATE,
      payload: response.data
    });
    if (response.data.payments === undefined) {
      throw 'No payments found!';
    }
    dispatch(getPaymentBanLevelWithTransationId(response.data));
  } catch (e) {
    console.log("e", e);
    dispatch({ type: types.LOAD_PAYMENT_WITH_DATE_FAILED });
  }
};

export const getPaymentBanLevelWithTransationId = ({
  billingAccountNumber,
  payments
}) => async dispatch => {
  for (const payment of payments) {
    const { transactionId } = payment;
    try {
      dispatch({
        type: types.LOAD_PAYMENT_WITH_TRANSACTION_PENDING,
        transactionId
      });
      const response = await axios.get(`${API_URL}/payment/ban-level/withTransactionId`,
        {
          headers: authHeaders(),
          params: {
            billingAccountNumber,
            transationId: transactionId
          }
        }
      );
      const paymentApplicationDetails = response.data.paymentApplicationDetails;
      const payload = {
        transactionId,
        invoiceNumber: paymentApplicationDetails && paymentApplicationDetails[0].invoiceNumber
      };

      dispatch({
        type: types.LOAD_PAYMENT_WITH_TRANSACTION,
        payload
      });
    } catch (e) {
      console.log("error : ", e);
      dispatch({ type: types.LOAD_PAYMENT_WITH_TRANSACTION_FAILED, transactionId });
    }
  }
};


export const updateBillingAccountAddress = (
  updatedAddress,
  billingAccountNumber
) => async dispatch => {
  try {
    dispatch({ type: types.SAVING_PENDING });
    await axios.post(`${API_URL}/updateBillingAccAddress`, updatedAddress, { headers: authHeaders() });
    dispatch({
      type: types.UPDATE_BILLING_ADDRESS,
      payload: updatedAddress.billingAccount
    });
    return true;
  } catch (e) {
    console.log(e);
    dispatch({ type: types.BILLING_ADDRESS_FAILED });
    return false;
  }
};

export const createBillingAdjustment = (billingAdjustmentData, billingAccountNumber) => async dispatch => {
  try {
    dispatch({ type: types.SAVING_PENDING })
    const res = await axios.post(`${API_URL}/createBillingAdjustments/${billingAccountNumber}`, billingAdjustmentData, { headers: authHeaders() })
    dispatch({
      type: types.CREATE_BILLING_ADJUSTMENT,
      payload: res.data
    });
    try {
      const resp = await axios.get(`${API_URL}/getBillingAdjustments/${billingAccountNumber}`, { headers: authHeaders() });
      dispatch({
        type: types.GET_BILLING_ADJUSTMENTS,
        payload: resp.data.adjustments
      });
      dispatch({ type: types.SAVING_SUCCESS })
    } catch (e) {
      console.log(e);
      dispatch({ type: types.SAVING_FAILED })
    }
    return res.data;
  } catch (e) {
    console.log(e)
    dispatch({ type: types.SAVING_FAILED })
    return undefined;
  }
}

export const updateFields = (adjustment = {}) => {
  return {
    type: types.ADD_ADJUSTMENT,
    payload: adjustment
  }
}
export const resetCreateAdjFormFields = () => async dispatch => {
  dispatch({ type: types.RESET_CREATE_ADJ_FORM })
}

export const selectSubscriptionFilter = (key, value) => dispatch => {
  dispatch({ type: types.SELECT_SUBSCRIPTION_ENV, key, value });
};

export const submitSubscription = (product, billingAccountNumber) => async dispatch => {
  try {
    console.log(product);
    dispatch({ type: types.SUBMIT_SUBSCRIPTION_PENDING })
    const res = await axios.get(`${API_URL}/submit/subscription/${product}/${billingAccountNumber}`, { headers: authHeaders() })
    dispatch({ type: types.SUBMIT_SUBSCRIPTION_SUCCESS })
    if (res.data.responseStatus === 'Fail')
      throw res.data.responseDescription;
    return res.data;
  } catch (e) {
    console.log('exception', e)
    dispatch({ type: types.SUBMIT_SUBSCRIPTION_FAIL })
    let errorMsg = ""
    if (typeof e === "string") errorMsg = e
    else errorMsg = e.response.data.message
    console.log(errorMsg);
    displayErrorNew({
      title: "Subscription Error",
      subTitle: "An error occured while submitting subscription!",
      errorMsg
    });
  }
}
