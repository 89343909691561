import React from 'react';
import DataTilesFrame from '../../Data/accordion-frames/replicator/DataTilesFrame';

export default function DeleteTiles({ delLevelFilters, selectDeletionLevel, level }) {
  return (
    <DataTilesFrame
      heading="Delete"
      color="blue"
      data={delLevelFilters}
      select={selectDeletionLevel}
      isMulti={false}
      selected={level}
    />
  )
}