import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  Paper,
} from "@material-ui/core";

import { MoreVert as MoreVertIcon } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";

const usePaperStyle = makeStyles({
  root: {
    width: "100%",
    maxWidth: 200,
  },
});

const useActionsMenuIconStyle = makeStyles((theme) => ({
  button: {
    // "&:hover": {
    //   "& $icon": {
    //     color: theme.palette.background.paper,
    //   },
    // },
  },
  icon: {
    // color: theme.palette.icon.color,
    color: "#1976d2",
  },
}));

const defaultAnchorOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

const defaultTransformOrigin = {
  vertical: "center",
  horizontal: "left",
};

const ActionsMenu = (props) => {
  const {
    menuItems,
    data,
    anchorOrigin = defaultAnchorOrigin,
    transformOrigin = defaultTransformOrigin,
    ...rest
  } = props;

  const paperStyle = usePaperStyle();
  const actionsMenuIconStyle = useActionsMenuIconStyle();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (onClickHandler, title) => {
    onClickHandler(title, data);
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        className={actionsMenuIconStyle.button}
      >
        <MoreVertIcon className={actionsMenuIconStyle.icon} />
      </IconButton>
      <Paper classes={paperStyle} elevation={0}>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          getContentAnchorEl={null}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          {...rest}
        >
          <Box p={1}>
            {menuItems.map((menuItem, index) => {
              return (
                <MenuItem button key={index} disabled={(menuItem && menuItem.isDisabled) ? menuItem.isDisabled(data) : false}>
                  {menuItem["component"] ? (
                    menuItem["component"](menuItem["title"], data)
                  ) : (
                    <ListItemText
                      onClick={(e) =>
                        handleMenuItemClick(
                          menuItem["onClick"],
                          menuItem["title"]
                        )
                      }
                    >
                      {menuItem["title"]}
                    </ListItemText>
                  )}
                </MenuItem>
              );
            })}
          </Box>
        </Menu>
      </Paper>
    </>
  );
};

ActionsMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      component: PropTypes.func,
    })
  ).isRequired,
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
  }),
  transformOrigin: PropTypes.shape({
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
  }),
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default ActionsMenu;
