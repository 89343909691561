import React from 'react';
import PropTypes from "prop-types";
import {
    TextField,
    InputAdornment,
    Grid,
    Typography,
    CircularProgress,
    Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'block',
        padding: '5px',
        margin: '0px 0px 0px -4px',
        paddingTop: '0px',
        paddingBottom: '0px',
        '& .MuiInputLabel-outlined': {
          padding: '10px',
          color: 'red'
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '5px',
          backgroundColor: 'white',
        },
        '& .MuiOutlinedInput-input': {
            padding: '0px'
        }
    },
    section: {
        margin: '10px 0px 5px 0px !important',
        fontSize: '15px',
        fontWeight: '400'
    },
    validateStar: {
        color: 'red',
        margin: '0px 5px',
        display: 'inline-block',
        paddingBottom: '0px'
    },
}))

const FormTextArea = (props) => {
    const classes = useStyles();

    const {
        label = "",
        loading = false,
        required = false,
        endBtn = <></>,
        ...rest
    } = props;

    return (
        <>
            <Grid container direction="row">
                <Typography color="textSecondary" gutterBottom variant="h6" className={classes.section}>
                    {props.label}
                </Typography>
                {
                    props.required ? <Typography variant="h6" className={classes.validateStar}>*</Typography> : <></>
                }
            </Grid>
            <Box display="flex">
                <TextField
                    variant="outlined"
                    multiline
                    InputProps={{       
                        endAdornment: (
                            <InputAdornment position="end">
                                {props.loading ? <CircularProgress size={20} color={'secondary'} /> : <></>}
                            </InputAdornment>
                        ),
                    }}
                    className={classes.input}
                    {...rest}
                />
                {endBtn}
            </Box>
        </>
    )
}

FormTextArea.propTypes = {
    label: PropTypes.string,
    loading: PropTypes.bool,
    required: PropTypes.bool,
};

export default FormTextArea;