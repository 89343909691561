import React, { Component } from 'react';

export default class DropdownComponent extends Component {

    render() {
        const { selectInput, customClass, options } = this.props;
        return (
            <select
                className={`form-control ${customClass}`} name="Quantity" id="select-quantity"
                onChange={(e) => {
                    selectInput(e.target.value)
                }}>
                    {options.map((option, index)=>(<option key = {index}>{option}</option>))}
            </select>
        )
    }
}