import React from 'react'
import LinkTile from "../custom/LinkTile";

const tileStyle = {
    background: 'linear-gradient(to right, #13a129 0, #1d5f27 100%)'
}


const MyTeamNoSqlTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-green"
        data-size="medium"
        to="/myTeam"
        data-effect="hover-zoom-up">
        <div class="slide-front">
            <span className="fa fa-users icon"></span>
            <span className="branding-bar">My Team</span>
        </div>
        <div
            className="slide-back d-flex flex-justify-center flex-align-center p-4 op-green">
            <p className="text-center">
                View Team Details and Allocation
            </p>
        </div>
    </LinkTile>
)

MyTeamNoSqlTile.propTypes = {}

export default MyTeamNoSqlTile;
