import _ from 'lodash';
import * as types from '../actions/actionTypes';
import { updateObjectReadFlag } from '../utils/notification';

const INITIAL_STATE = {
    currentPage: "",
    pageFeedBack: [],
    forceFeedback: false,
    disableForceFeedbackTime: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_PATH:
            return {
                ...state,
                currentPage: action.payload
            }
        case types.ADD_PAGE_FEEDBACK:
            return {
                ...state,
                pageFeedBack:action.payload
            }
        case types.FORCE_FEEDBACK:
            return {
                ...state,
                forceFeedback: action.payload
            }
        case types.DISABLE_FEEDBACK:
            return {
                ...state,
                disableForceFeedbackTime: action.disable
            }
        default:
            return state;
    }
}