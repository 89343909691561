import React from "react";
import { Button } from "reactstrap";
import _, { indexOf } from "lodash";
import { generateServiceId, toggleFax } from "../../actions/replicatorActions";
import { connect } from "react-redux";
import { Field, change } from "redux-form";
import DropdownComponent from "./DropdownComponent";
import { renderField } from "../common/fields/fields";
import {
    adborIdValidator,
    fnnValidatorForNewReplicator,
    accountNumberValidator
} from "../common/form/fieldValidators";
import "./Product.css";
import "./Data.css";

class CosmosPSTNComponent extends React.Component {
    state = {
        autoGeneratedRows: [],
    };

    handleRowChange = (numRows, newNumRows, fields) => {
        let diff = newNumRows - numRows;
        if (diff > 0) {
            for (let i = 0; i < diff; i++)
            fields.push({ adborId: null, fnn: null, optionalAssociation: null, fax: null, inflightOrder: null });
        } else if (diff < 0) {
            diff *= -1;

            const limit = fields.length - diff - 1;
            for (let i = 0; i < diff; i++) fields.pop();
            this.setState(preState => {
            return {
                ...preState,
                autoGeneratedRows: preState.autoGeneratedRows.filter(
                data => data <= limit
                )
            };
            });
        }
    };

    handleGenerateServiceId = async (passdownprops, index) => {
        try {
          const { serviceId } = await this.props.generateServiceId(1, index);
    
          this.setState(preState => ({
            ...preState,
            autoGeneratedRows: preState.autoGeneratedRows.concat(index)
          }));
    
          passdownprops.dispatch(
            change(passdownprops.formName, `adborFnns[${index}].fnn`, serviceId)
          );
        } catch (e) {
          console.log("error generating fnn: ", e);
        }
    };

    toggleAssocType = async (passdownprops, index) => {
      let faxArr = [...this.props.faxList]
      if (faxArr.includes(index)){
        faxArr.splice(faxArr.indexOf(index),1)
      } else {
        faxArr = faxArr.concat(index)
      }
      this.props.toggleFax(faxArr);

      passdownprops.dispatch(
        change(passdownprops.formName, `adborFnns[${index}].optionalAssociation`, "fax duet")
      );
      
    }

    render() {
        const { productType, quantity, fields, passdownprops, serviceIdField } = this.props;
        const optionalAssocOptions = [
            { label: "Empty", value: "" },
            { label: "Hunt Group", value: "Hunt Group" },
            { label: "Multiline", value: "Multiline" }
        ];
        return(
            <div>
            <div className="row" id="product-input-header">
              <div className="col col1">
                {" "}
                <span className="product-header">Product:</span>
                <span>{productType.toUpperCase()}</span>
              </div>
              <div className="col col2">
                <span className="product-header">Quantity: </span>
                <span id="qty-dropdown">
                  <DropdownComponent
                    options = {quantity}
                    selectInput={qty =>
                      this.handleRowChange(fields.length, qty, fields)
                    }
                    length={fields.length}
                  />
                </span>
              </div>
            </div>
            <div id="s2p-product-modal-input-head" className="row">
              <table className="table table-sm adbor-fnn-table">
                <thead className="thead-light adbor-fnn-head">
                  <tr>
                    <th className="verticle-middle">
                      <span>Adbor ID</span>
                      <span>
                        <Button
                          color="link"
                          onClick={() =>
                            fields.forEach((d, i) =>
                              passdownprops.dispatch(
                                change(
                                  passdownprops.formName,
                                  `adborFnns[${i}].adborId`,
                                  fields.get(0).adborId
                                )
                              )
                            )
                          }
                        >
                          <i className="fa fa-plus fa-sm" aria-hidden="true"></i>
                        </Button>
                      </span>
                    </th>
                    <th className="verticle-middle">
                      <span>Service ID</span>                  
                    </th>
                    {
                      productType.toUpperCase() === "PSTN" &&
                      <th className="verticle-middle">
                        <span>Association Type</span>
                      </th>
                    }
                    {
                      productType.toUpperCase() === "PSTN" &&
                      <th className="verticle-middle">
                        <span>Account Number</span>
                      </th>
                    }
                    {
                      productType.toUpperCase() === "PSTN" && 
                      <th className="verticle-middle">
                        <span>Fax</span>
                      </th>
                    }
                    <th className="verticle-middle">
                      <span>Inflight</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((adborFnn, index) => {
                    let adborId = 2 * index + 1,
                      fnn = adborId + 1,
                      optionalAssociation = fnn + 1,
                      accountNumber = optionalAssociation + 1,
                      fax = accountNumber + 1,
                      inflightOrder = fax + 1;
                      
                    const placeholder =
                      serviceIdField.rowNumber === index && serviceIdField.pending
                        ? "loading..."
                        : "";
                    return (
                      <tr key={index}>
                        <td style={{ padding: "10px 5px 0px 0px", verticalAlign: "top" }}>
                          <Field
                            name={`adborFnns[${index}].adborId`}
                            tabIndex={adborId}
                            digbled={passdownprops.asyncValidating || serviceIdField.pending}
                            autoFocus={adborId === 0}
                            component={renderField}
                            customDiv="adborFnnTableInputDiv"
                            validate={adborIdValidator}
                            invalid={this.props.errorMessage}
                            type={"textWithCustomStyles"}
                            showValidMsg={false}
                            touchOnChange={true}
                            autoComplete="off"
                          />
                        </td>
                        <td style={{ padding: "10px 5px 0px 5px", verticalAlign: "top" }}>
                          <Field
                            name={`adborFnns[${index}].fnn`}
                            customDiv="adborFnnTableInputDiv"
                            customStyleClassName="generate-serviceId-input"
                            tabIndex={fnn}
                            disabled={
                              passdownprops.asyncValidating ||
                              this.state.autoGeneratedRows.includes(index) ||
                              (serviceIdField.pending)
                            }
                            component={renderField}
                            validate={fnnValidatorForNewReplicator}
                            touched={true}
                            invalid={this.props.errorMessage}
                            type={"textWithCustomStyles"}
                            showValidMsg={false}
                            placeholder={placeholder}
                            autoComplete="off"
                          />
                        </td>
                        {
                           productType.toUpperCase() === "PSTN" && 
                           <td style={{ padding: "10px 0px 0px 5px", verticalAlign: "top" }}>
                            <Field
                              name={`adborFnns[${index}].optionalAssociation`}
                              component={renderField}
                              tabIndex={optionalAssociation}
                              type="selectWithCustomHeight"
                              options={optionalAssocOptions}
                              defaultValue={optionalAssocOptions[0]}
                              index={index}
                              disabled={this.props.faxList.includes(index) ? true : false}
                            />
                          </td>
                        }
                        {
                          productType.toUpperCase() === "PSTN" && 
                          <td style={{ padding: "10px 0px 0px 10px", verticalAlign: "top" }}>
                           <Field
                             name={`adborFnns[${index}].accountNumber`}
                             component={renderField}
                             customDiv="adborFnnTableInputDiv"
                             tabIndex={accountNumber}
                             type="textWithCustomStyles"
                             index={index}
                             validate={accountNumberValidator}
                             invalid={this.props.errorMessage}
                             showValidMsg={false}
                             touchOnChange={true}
                             autoComplete="off"
                           />
                         </td>
                        }
                        {
                          productType.toUpperCase() === "PSTN" && 
                          <td style={{ padding: "13px 0px 0px 0px", verticalAlign: "top" }}>
                            <Field
                              name={`adborFnns[${index}].fax`}
                              component={renderField}
                              tabIndex={fax}
                              touched={true}
                              invalid={this.props.errorMessage}
                              type={"checkbox"}
                              index={index}
                              showValidMsg={false}
                              showIcon={fields.get(index).fax}
                              onChange={() => this.toggleAssocType(passdownprops, index)}
                            />
                          </td>
                        }
                        <td style={{ padding: "13px 0px 0px 0px", verticalAlign: "top" }}>
                          <Field
                            name={`adborFnns[${index}].inflightOrder`}
                            component={renderField}
                            tabIndex={inflightOrder}
                            touched={true}
                            invalid={this.props.errorMessage}
                            type={"checkbox"}
                            defaultValue={"N"}
                            index={index}
                            showValidMsg={false}
                            showIcon={fields.get(index).inflightOrder}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      quantity: state.replicatorData.options.quantity,
      faxList: state.replicatorData.faxList,
    }
};
  
const mapDispatchToProps = dispatch => {
    return {
        generateServiceId: (qty, index) => dispatch(generateServiceId(qty, index)),
        toggleFax: (faxList) => dispatch(toggleFax(faxList))
    };
};
  
export default connect(
mapStateToProps,
mapDispatchToProps
)(CosmosPSTNComponent);