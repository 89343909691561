import qs from 'qs';

import React, { Component } from "react";

import { connect } from "react-redux";
import { isTaasTokenValid } from './functions';
import { signout } from "../../actions/authActions";

// authorization higher order component to control access via routing and roles
export default (ChildComponent, allowedRoles = ["USER","ADMIN","OPS"]) => {
  class ComposedComponent extends Component {
    // component just got rendered
    componentDidMount() {
      const token = qs.parse(this.props.location.search).token;
      if (!token) {
        this.shouldNavigateAway();
      }
    }

    //component just got updated
    componentDidUpdate() {
      this.shouldNavigateAway();
    }

    async shouldNavigateAway() {
      if (this.props.auth && !((await isTaasTokenValid(this.props.auth)).verified)) {
        // log user out.
        this.props.logout();
      }

      if (!this.props.auth || !this.props.roles?.length) {
        return this.props.history.push("/");
      }

      if (!allowedRoles.some(role => this.props.roles.includes(role))) {
        // current user not authorized for this page
        console.error("NOT AUTHORIZED");
        // swal.fire("Restricted Area", "You are not allowed to access this page", "error");
        return this.props.location.pathname != "/dashboard" ? this.props.history.push( "/dashboard"): null;
      }
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return {
      auth: state.auth.authenticated,
      roles: state.auth.roles
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      logout: () => {
        dispatch(signout());
      }
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComposedComponent);
};
