import React from 'react';
import { Input, InputGroup } from "reactstrap";
import DataCustomFrame from '../../Data/accordion-frames/replicator/DataCustomFrame';
import { cidnIsValid } from '../../common/form/fieldValidators';
import FlexSpan from '../../../utils/FlexSpan';

export default function CustomerSection({
    selectedFilters,
    selectCIDN,
    updateDealerPremiseCode }) {

    const SpanWidth = ({ children, width }) => <span style={{ "width": width }}>{children}</span>

    const renderCustomerInput = () => {
        let cidn = selectedFilters.cidn;
        let dealerPremiseCode = selectedFilters.dealerPremiseCode;
        let isGenerated = cidn === "GENERATED"
        let btnClassOutline = isGenerated ? "btn-outline-warning" : "btn-outline-success"
        let btnClassIcon = isGenerated ? "fa-times" : "fa-plus"
        let btnOnClickFuncVal = isGenerated ? undefined : "GENERATED"
        return (
            <FlexSpan>
                <SpanWidth width="5%">
                    <button className={"btn btn-sm ld-over " + btnClassOutline} onClick={() => selectCIDN(btnOnClickFuncVal)} >
                        < i className={"fa " + btnClassIcon} />
                    </button>
                </SpanWidth>
                < SpanWidth width="25%" >
                    <InputGroup>
                        <Input
                            placeholder="Enter CIDN"
                            className={cidn && (cidnIsValid(cidn) ? "is-valid" : "is-invalid")}
                            onBlur={e => selectCIDN(e.currentTarget.value)}
                            defaultValue={cidn}
                            disabled={isGenerated} />

                        {cidn && !cidnIsValid(cidn) &&
                            <div style={{ position: "absolute", top: "35px" }} class="invalid-feedback">CIDN must be 10 digits or generated</div>
                        }
                    </InputGroup>
                </SpanWidth >
                <SpanWidth width="2%"></SpanWidth>
                < SpanWidth width="25%" >
                    <InputGroup>
                        <Input placeholder="Enter Dealer Premise Code"
                            onBlur={e => updateDealerPremiseCode(e.currentTarget.value)}
                            defaultValue={dealerPremiseCode} />
                    </InputGroup>
                </SpanWidth >
            </FlexSpan >
        )
    }

    return (
        <DataCustomFrame
            renderContent={renderCustomerInput}
            heading="Customer"
        />
    )

}