import {axiosInstance as axios} from '../axios.config';
import {authHeaders} from './api';
import config from '../config';

const TDM_GATEWAY_API = config.tdm_gateway_api.uri;

export const teamLeadDNumberAvailabilityCheck = (teamLeadDNumber='') => {
    let dNumberBody = {
        users: [teamLeadDNumber]
    };
    return axios.post(`${TDM_GATEWAY_API}/tdm/data/users/retrieveDetails`, dNumberBody, { headers: authHeaders() } ).then(response=> response.data);
}

export const fetchSnrmResourcesNoErrorDisplay = (startRange, endRange, page = 0, env) => {
      const requestBody = {
        startRange: startRange,
        endRange: endRange,
        page: page,
        size: 6,
        environment: env
      }
      return axios.post(`${TDM_GATEWAY_API}/tdm/snrm-data-service/snrm/b2b/cleanup/resources`, requestBody, { headers: authHeaders() }).then(response=> response.data);
  }