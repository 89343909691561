import React from 'react';
import {Collapse,Button } from 'reactstrap';
import { Field, reduxForm, FieldArray } from 'redux-form'
import { renderField } from '../../common/fields/fields';
import ProductDetailsComponent from './ProductDetailsComponent';

class SiteDetailsComponent extends React.Component {
  constructor(props) {
    super(props);     
    this.toggleProduct = this.toggleProduct.bind(this);
  }
 
  state = {
    productCollapse : false,
    siteIndex : null
  }

  toggleProduct = (index) => {
    this.setState({ 
        siteIndex : index,
        productCollapse: !this.state.productCollapse
      });
  }

  render() {
    let {fields, meta: { touched, error, submitFailed }} = this.props;
    const buttonStyle = {
      marginBottom: '1rem',
      width: '100%',
      textAlign: 'left'
  }
    return (
        <ul style={{background : "aliceblue"}}>
          {fields.map((site,index) => (
              <li key={index}>    
                      <div className="row">
                          <div className="col-sm-6" style={{fontWeight : "bold"}}>Site - {index+1}</div>
                          <div className="col-sm-6" style={{textAlign : "right"}}><Button color="danger" title="Remove Site"
                            onClick={() => fields.remove(index)}><i className="fa fa-minus-circle fa-sm" alt="Remove" aria-hidden="true"></i></Button></div>
                          <div className="col-sm-3">
                          <Field
                              name={`${site}.siteName`}
                              label="Site Name"
                              type="text"
                              autoComplete="off"
                              component={renderField}   
                          /></div>
                          <div className="col-sm-3">
                          <Field
                              name={`${site}.siteTcd`}
                              label="Site TCD"
                              type="date"
                              autoComplete="off"
                              component={renderField}   
                          /> </div>
                          <div className="col-sm-3">
                          <Field
                              name={`${site}.siteFdd`}
                              label="Site FDD"
                              type="date"
                              autoComplete="off"
                              component={renderField}   
                          /></div>
                          <div className="col-sm-3">
                          <Field
                              name={`${site}.siteCompletionDate`}
                              label="Site Completion Date"
                              type="date"
                              autoComplete="off"
                              component={renderField}   
                          />
                          </div>
                          <div className="col-sm-12" style={{paddingRight : "8%", paddingLeft : "8%"}}>
                          <Button color="primary" onClick={() => this.toggleProduct(index)} style={buttonStyle}>Product Details</Button>
                              <Collapse isOpen={this.state.productCollapse && index=== this.state.siteIndex}>
                                  <FieldArray
                                    name={`${site}.datahubOrderProductDetailsDto`}
                                    component={ProductDetailsComponent}
                                  />
                                </Collapse>
                          </div>
                      </div>                
              </li>
          ))}
           <li style={{marginBottom : "10px"}}>
               <Button color="info" onClick={() => fields.push(
                 {siteName : "Ingleburn Town Centre O/S", siteTcd: new Date(),  siteFdd: new Date(),  siteCompletionDate: new Date() }
               )}>
                 Add Site
               </Button>
               {(touched || submitFailed) && error && <span>{error}</span>}
            </li>
        </ul>
    );
  }
}

export default SiteDetailsComponent