import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { NoOverflowText } from "@library/core/text";

const useButtonStyle = makeStyles({
  contained: {
    "&:hover": {
      transform: "translateY(-2px)",
    },
  },
});

const defaultText = "Submit";

const SubmitButton = (props) => {
  const { children = defaultText, onSubmit, ...rest } = props;

  const buttonStyle = useButtonStyle();

  return (
    <Button
      variant="contained"
      onClick={onSubmit}
      color="primary"
      classes={buttonStyle}
      {...rest}
    >
      <NoOverflowText variant="button">{children}</NoOverflowText>
    </Button>
  );
};

SubmitButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SubmitButton;
