import React from 'react';
import {Table, Row, Col, Button} from 'reactstrap';
import {renderIcon} from '../../../../utils/ui';
import {SubHeader} from '../../../common/custom/SubHeader';
export default (props) => {
    const {tdiItems, assetActionsMessage} = props;
    return (
        <React.Fragment>
            <SubHeader text={"TDI"} iconClass={"td-icon-md icon-crowd-support"} data={tdiItems} iconColor={"purple"}/>
            <Table>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>DOB</th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {tdiItems.map((item) => {
                        const success = item.status === "CREATED";
                        return (
                            <tr>
                                <td scope="row">{item.firstName}</td>
                                <td>{item.lastName}</td>
                                <td>{item.dob}</td>
                                <td>{item.emailId}</td>
                                <td>{item.password}</td>
                                <td
                className={success
                    ? "base-green"
                    : "base-orange"}>{renderIcon(success
                        ? "icon-round-tick"
                        : "icon-round-cross")}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            {assetActionsMessage instanceof Error && (<p className="text-danger" style={{ "color": 'red' }}>There was error in saving generated TDI details.
Please note down details as these may not be shown after refresh or in history page.</p>)}
        </React.Fragment>
    )
}