import React, { Component, useEffect, Fragment, useState } from "react";
import HeaderContent from '../common/Header/HeaderContent';
import BreadTable from "@library/core/tables/BreadTable";
import { connect } from "react-redux";
import { Card, CardMedia, Button, makeStyles, Typography, Tooltip } from '@material-ui/core';
import './training.css';
import TDMBeginner from '../../assets/images/TrainingBadges/TDMBeginner.png';
import TDMExplorer from '../../assets/images/TrainingBadges/TDMExplorer.png';
import TDMContributor from '../../assets/images/TrainingBadges/TDMContributor.png';
import TDMInnovator from '../../assets/images/TrainingBadges/TDMInnovator.png';
import TDMExpert from '../../assets/images/TrainingBadges/TDMExpert.png';
import TrainingDetailsDialog from './TrainingDetailsDialog';
import { Carousel } from '3d-react-carousal';
import { setLocation } from "../../actions/userFeedbackActions";
import { fetchAllUserTrainingBadges } from "../../actions/trainingBadgesAction";


const useStyles = makeStyles({
  trainingLabel: {
    marginRight: '10px',
    marginLeft: '10px',
    fontSize: '30px',
    color: '#7251bc'
  },
  trainingTabelLabel: {
    color: '#4361EE',
    fontSize: '20px',
  },
  beginnerData: {
    color: '#455a64',
    fontWeight: '700',
    opacity: '0.9',
    fontSize : '18px'
  }
});

const UserTrainingDetails = (props) => {
  const classes = useStyles();
  const [feedbackCount, setFeedBackCount] = useState(0)

  useEffect(() => {
    props.setLocation("User Training Details")
    props.fetchAllUserTrainingBadges();
  }, []);

  const [trainingDialog, showTrainingDialog] = useState([false, false, false, false, false]);

  let expertUsers = props.badgesByUser.filter((experts) => experts.trainingBadge === "TDMExpert");
  let contributorUsers = props.badgesByUser.filter((contributors) => contributors.trainingBadge === "TDMContributors");
  let innovatorUsers = props.badgesByUser.filter((innovators) => innovators.trainingBadge === "TDMInnovators");
  let explorerUsers = props.badgesByUser.filter((explorers) => explorers.trainingBadge === "TDMExplorers");


  const expertArgs = {
    expandTitle: "Details",
    size: "medium",
    isLoading: props.badgesByUserLoading,
    noDataMsg: props.badgesByUserFailed ? props.badgesByUserError : null,
    isExpandable: false,
    columnMetadata: [
      {
        user: 1,
        title: "Name",
        field: ["name"],
        expandable: false,
        component: (field) => <Typography noWrap style={{ "width": "135px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography>,
      },
      {
        user: 1,
        title: "DNumber",
        field: ["username"],
        expandable: false,
        component: (field) => <Typography noWrap style={{ "width": "90px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography>,
      },
      {
        user: 2,
        title: "Email",
        field: ["email"],
        expandable: true,
        component: (field) => (
          <Typography style={{ "width": "270px", "margin": "auto" }} noWrap variant="body2">
            {field}
          </Typography>
        ),
      }
    ],
  }
  const contributorArgs = {
    expandTitle: "Details",
    size: "medium",
    isLoading: props.badgesByUserLoading,
    noDataMsg: props.badgesByUserFailed ? props.badgesByUserError : null,
    isExpandable: false,
    columnMetadata: [
      {
        user: 1,
        title: "Name",
        field: ["name"],
        expandable: false,
        component: (field) => <Typography noWrap style={{ "width": "135px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography>,
      },
      {
        user: 1,
        title: "DNumber",
        field: ["username"],
        expandable: false,
        component: (field) => <Typography noWrap style={{ "width": "90px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography>,
      },
      {
        user: 2,
        title: "Email",
        field: ["email"],
        expandable: true,
        component: (field) => (
          <Typography style={{ "width": "270px", "margin": "auto" }} noWrap variant="body2">
            {field}
          </Typography>
        ),
      }
    ],
  }
  const innovatorArgs = {
    expandTitle: "Details",
    size: "medium",
    isLoading: props.badgesByUserLoading,
    noDataMsg: props.badgesByUserFailed ? props.badgesByUserError : null,
    isExpandable: false,
    columnMetadata: [
      {
        user: 1,
        title: "Name",
        field: ["name"],
        expandable: false,
        component: (field) => <Typography noWrap style={{ "width": "135px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography>,
      },
      {
        user: 1,
        title: "DNumber",
        field: ["username"],
        expandable: false,
        component: (field) => <Typography noWrap style={{ "width": "90px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography>,
      },
      {
        user: 2,
        title: "Email",
        field: ["email"],
        expandable: true,
        component: (field) => (
          <Typography style={{ "width": "270px", "margin": "auto" }} noWrap variant="body2">
            {field}
          </Typography>
        ),
      }
    ],
  }
  const explorerArgs = {
    expandTitle: "Details",
    size: "medium",
    isLoading: props.badgesByUserLoading,
    noDataMsg: props.badgesByUserFailed ? props.badgesByUserError : null,
    isExpandable: false,
    columnMetadata: [
      {
        user: 1,
        title: "Name",
        field: ["name"],
        expandable: false,
        component: (field) => <Typography noWrap style={{ "width": "135px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography>,
      },
      {
        user: 1,
        title: "DNumber",
        field: ["username"],
        expandable: false,
        component: (field) => <Typography noWrap style={{ "width": "90px", "margin": "auto" }} noWrap variant="body2">
          {field}
        </Typography>,
      },
      {
        user: 2,
        title: "Email",
        field: ["email"],
        expandable: true,
        component: (field) => (
          <Typography style={{ "width": "270px", "margin": "auto" }} noWrap variant="body2">
            {field}
          </Typography>
        ),
      }
    ],
  }


  const cardMediaData = [
    {
      "name": "TDM-BEGINNER",
      "cardName": TDMBeginner, "skills": ["Any user who has access to TDM UI"],
      "bg": '#660066',
      "expectations": ["The user should be able to login to TDM UI and able to get their data checked out."],
      "description": "Any User who is registered with TDM is considered as Beginner. The Beginner is expected to login , Onboard team, Checkout data on their own"
    },

    {
      "name": "TDM-EXPLORER",
      "cardName": TDMExplorer,
      "bg": '#008000',
      "skills": [
        "Data Request process and fulfillment",
        "TDM UI Walkthrough.",
        "TDM utilities & its benefits.",
        "How to articles and community KB questions.", "Data setup and alignment across the applications.",
        "Known data issues and remediation steps, FAQ's.", "Swagger API walk through."
      ],
      "expectations": [
        "The identified resources/SMEs are primary POC to address the queries",
        "They should be able to guide their teams for properly raising the data requests and handle the common failures.",
        "They should be able to guide their teams for effective use of TDM utilities.",
        "They should be able to debug the standard data issues in the initial level.",
        "They should have clear idea on all the TDM supported APIs and its functions and should be able to guide their team for any queries.",
        "They should have clear understanding on TDM fulfillment processes like SLA, check-in, check-out, sprint timelines etc.,",
        "They should have clear understanding on all the TDM UI features to guide their team for any support."
      ],
      "description": "TDM Explorer badge will be awarded once the user is trained with data request and fulfillment process, TDM utilities , data issues & remediation steps, Swagger API utilization etc,. "
    },

    {
      "name": "TDM-INNOVATOR",
      "cardName": TDMInnovator,
      "bg": '#7a0000',
      "skills": [
        "Identifying route causes for any data issues.",
        "Debugging API issues while using TDM collection.",
        "Capturing data requirements well in advance to enable hassle-free fulfillment.",
        "Enabling proper communication with TDM team."],
      "expectations": [
        "Responsible to investigate any data issues with RCA.",
        "Responsible to fix basic API errors and report any functional issues via proper channel.",
        "Responsible to enable their team to raise data requirements well in advance as per SLA.",
        "Responsible to enable necessary communication with TDM team via agreed channels."],
      "description": "TDM Innovator badge will be awarded once the user is trained with root cause analysis of data issues, debugging API issues, Channel communication etc,."
    },

    {
      "name": "TDM-CONTRIBUTOR",
      "cardName": TDMContributor,
      "bg": '#0054a8',
      "skills": [
        "Data Request process and fulfillment",
        "TDM UI Walkthrough.",
        "TDM utilities & its benefits."],
      "expectations": ["The identified resources/SMEs are primary POC to address the queries", "They should be able to guide their teams for properly raising the data requests and handle the common failures."],
      "description": "TDM Contributor badge will be awared once the user is trained to contribute value to TDM like identifying new features , bringing up new requirements , answering KB questions, enabling TDM with new steps for data setup etc.,"
    },

    {
      "name": "TDM-EXPERT",
      "cardName": TDMExpert,
      "bg": '#401f83',
      "skills": ["Data Request process and fulfillment", "TDM UI Walkthrough.", "TDM utilities & its benefits."],
      "expectations": ["The identified resources/SMEs are primary POC to address the queries", "They should be able to guide their teams for properly raising the data requests and handle the common failures."],
      "description": "TDM Expert badge will be awarded once the user is trained in end -end knowledge in data request , fulfillment process, debugging techniques ,data issue remediation steps, API error handling etc.,"
    },
  ]
  let slides = [
    <Fragment>
      <Tooltip classes={{ root: classes.tooltip }}
        id="cardTooltip"
        placement="bottom"
        popperArrow
        interactive={true}
        title={
          <div class="container" style={{ backgroundColor: cardMediaData[0].bg }}>
            <div class="row tooltipTitle" style={{ backgroundColor: cardMediaData[0].bg }}>
              <div class="col-4" />
              <div class="col-8">
                <Typography>Level of Expertise</Typography>
              </div></div>
            <div class="row marginl-n13 p-4" >
              <Typography>{cardMediaData[0].description}</Typography>
            </div>
            <div class="row marginl-n13 p-4" >
              <Button onClick={e => openTrainingDialog(e, cardMediaData[0], 0)}>Click here to know more</Button>
            </div>
          </div>
        }>
        <img src={TDMBeginner} alt="1" style={{ 'height': '280px' }} id="TDMBeginner" />
      </Tooltip>
      {trainingDialog[0] && <TrainingDetailsDialog cardDetails={cardMediaData[0]}
        closeDialog={e => closeTrainingDialog(e, cardMediaData[0], 0)} />}
    </Fragment>,
    <Fragment>
      <Tooltip classes={{ root: classes.tooltip }}
        id="cardTooltip"
        popperArrow
        interactive={true}
        title={
          <div class="container" style={{ backgroundColor: cardMediaData[1].bg }}>
            <div class="row tooltipTitle" style={{ backgroundColor: cardMediaData[1].bg }}>
              <div class="col-4" />
              <div class="col-8">
                <Typography>Level of Expertise</Typography>
              </div></div>
            <div class="row marginl-n13 p-4" >
              <Typography>{cardMediaData[1].description}</Typography>
            </div>
            <div class="row marginl-n13 p-4" >
              <Button onClick={e => openTrainingDialog(e, cardMediaData[1], 1)}>Click here to know more</Button>
            </div>
          </div>
        }>
        <img src={TDMExplorer} alt="1" style={{ 'height': '280px' }} id="TDMExplorer" />
      </Tooltip>
      {trainingDialog[1] && <TrainingDetailsDialog cardDetails={cardMediaData[1]}
        closeDialog={e => closeTrainingDialog(e, cardMediaData[1], 1)} />}
    </Fragment>,
    <Fragment>
      <Tooltip classes={{ root: classes.tooltip }}
        id="cardTooltip"
        popperArrow
        interactive={true}
        title={
          <div class="container" style={{ backgroundColor: cardMediaData[2].bg }}>
            <div class="row tooltipTitle" style={{ backgroundColor: cardMediaData[2].bg }}>
              <div class="col-4" />
              <div class="col-8">
                <Typography>Level of Expertise</Typography>
              </div></div>
            <div class="row marginl-n13 p-4" >
              <Typography>{cardMediaData[2].description}</Typography>
            </div>
            <div class="row marginl-n13 p-4" >
              <Button onClick={e => openTrainingDialog(e, cardMediaData[2], 2)}>Click here to know more</Button>
            </div>
          </div>
        }>
        <img src={TDMInnovator} alt="1" style={{ 'height': '280px' }} id="TDMInnovator" />
      </Tooltip>
      {trainingDialog[2] && <TrainingDetailsDialog cardDetails={cardMediaData[2]}
        closeDialog={e => closeTrainingDialog(e, cardMediaData[2], 2)} />}
    </Fragment>,
    <Fragment>
      <Tooltip classes={{ root: classes.tooltip }}
        id="cardTooltip"
        popperArrow
        interactive={true}
        title={
          <div class="container" style={{ backgroundColor: cardMediaData[3].bg }}>
            <div class="row tooltipTitle" style={{ backgroundColor: cardMediaData[3].bg }}>
              <div class="col-4" />
              <div class="col-8">
                <Typography>Level of Expertise</Typography>
              </div></div>
            <div class="row marginl-n13 p-4" >
              <Typography>{cardMediaData[3].description}</Typography>
            </div>
            <div class="row marginl-n13 p-4" >
              <Button onClick={e => openTrainingDialog(e, cardMediaData[3], 3)}>Click here to know more</Button>
            </div>
          </div>
        }>
        <img src={TDMContributor} alt="4" style={{ 'height': '280px' }} id="TDMContributor" />
      </Tooltip>
      {trainingDialog[3] && <TrainingDetailsDialog cardDetails={cardMediaData[3]}
        closeDialog={e => closeTrainingDialog(e, cardMediaData[3], 3)} />}
    </Fragment>,
    <Fragment>
      <Tooltip classes={{ root: classes.tooltip }}
        id="cardTooltip"
        popperArrow
        interactive={true}
        title={
          <div class="container" style={{ backgroundColor: cardMediaData[4].bg }}>
            <div class="row tooltipTitle" style={{ backgroundColor: cardMediaData[4].bg }}>
              <div class="col-4" />
              <div class="col-8">
                <Typography>Level of Expertise</Typography>
              </div></div>
            <div class="row marginl-n13 p-4" >
              <Typography>{cardMediaData[4].description}</Typography>
            </div>
            <div class="row marginl-n13 p-4" >
              <Button onClick={e => openTrainingDialog(e, cardMediaData[4], 4)}>Click here to know more</Button>
            </div>
          </div>
        }>
        <img src={TDMExpert} alt="5" style={{ 'height': '280px' }} id="TDMExpert" />
      </Tooltip>
      {trainingDialog[4] && <TrainingDetailsDialog cardDetails={cardMediaData[4]}
        closeDialog={e => closeTrainingDialog(e, cardMediaData[4], 4)} />}
    </Fragment>


  ];
  const openTrainingDialog = (e, card, index) => {
    let trainingModalData = [...trainingDialog];
    trainingModalData[index] = true;
    showTrainingDialog(trainingModalData)
  }
  const closeTrainingDialog = (e, card, index) => {
    let trainingModalData = [...trainingDialog];
    trainingModalData[index] = false;
    showTrainingDialog(trainingModalData)
  }

  const brandStyles = {
    width: 80,
    marginTop: 6
  };
  const brandStyles2 = {
    width: 50,
    marginTop: 6
  };
  return (
    <div className="white" id="trainingDiv">
      <HeaderContent
        title={`TDM Training Championship`}
        icon="trophy training-icon "
        empty={true}
        loadingText="Loading User Training Badges..."
        count={feedbackCount >0 ? feedbackCount : 0}></HeaderContent>
      <div class="container carousalContainer">
        <Carousel slides={slides} autoplay={false} />
      </div>
      <div class="container">
        <div class="row">
          <div class="col-1 mt-n3">
            <img src={TDMExpert} style={brandStyles} />
          </div>  <div class="col-4 mt-4"><Typography classes={{ root: classes.beginnerData }}>Level of Expertise - TDM Expert
          </Typography></div>
        </div>
      </div>
      <BreadTable
        {...expertArgs} data={expertUsers} />
      <div class="container">
        <div class="row">
          <div class="col-1 mt-n3">
            <img src={TDMContributor} style={brandStyles} />
          </div>  <div class="col-5 mt-4"><Typography classes={{ root: classes.beginnerData }}>Level of Expertise - TDM Contributors</Typography></div>
        </div>
      </div>

      <BreadTable
        {...contributorArgs} data={contributorUsers} />
      <div class="container">
        <div class="row">
          <div class="col-1 mt-n3">
            <img src={TDMInnovator} style={brandStyles} />

          </div>  <div class="col-4 mt-4"><Typography classes={{ root: classes.beginnerData }}>Level of Expertise - TDM Innovators</Typography></div>
        </div>
      </div>

      <BreadTable
        {...innovatorArgs} data={innovatorUsers} />
      <div class="container">
        <div class="row">
          <div class="col-1 mt-n3">
            <img src={TDMExplorer} style={brandStyles} />
          </div>  <div class="col-4 mt-4"><Typography classes={{ root: classes.beginnerData }}>Level of Expertise - TDM Explorers</Typography></div>
        </div>
      </div>

      <BreadTable
        {...explorerArgs} data={explorerUsers} />

      <div class="container beginnerContainer">
        <div class="row p-4">
          <div class="col-1 mt-n3">
            <img src={TDMBeginner} style={brandStyles} />


          </div>
          <div class="col-4 mt-4"><Typography classes={{ root: classes.beginnerData }}>Level of Expertise - TDM Beginners</Typography></div>
          <div class="col-7 mt-1"><Typography classes={{ root: classes.beginnerData }}>Any users who is registered with TDM is considered as a Beginner</Typography></div>
        </div>
      </div>
      <div class="container footerContainer mt-5 ml-n4 pl-2">
        <div class="row p-10">
          <div class="col-10">

            <div className="mt-n2 trainingConfluenceLink">
              <a href="https://confluence.tools.telstra.com/display/TDM/TDM+Training+Model"
                target="_blank" rel={'external'}>Click here for Upcoming Trainings  </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userTrainingBadge: state.trainingBadges.userTrainingBadge,

    badgesByUser: state.trainingBadges.badgesByUser,
    badgesByUserLoading: state.trainingBadges.badgesByUserLoading,
    badgesByUserFailed: state.trainingBadges.badgesByUserFailed,
    badgesByUserError: state.trainingBadges.badgesByUserError
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      setLocation:(path) => dispatch(setLocation(path)),
      fetchAllUserTrainingBadges:() => dispatch(fetchAllUserTrainingBadges())
  }
} 

export default connect(mapStateToProps, mapDispatchToProps)(UserTrainingDetails);


