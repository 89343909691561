import * as types from './actionTypes';
import axios from 'axios';

import config from '../config';

const API_URL = config.api.uri;

const loadItemsSuccess = (data) => ( {type: types.LOAD_ITEMS_SUCCESS, data} );
const loadItemsError = (error) => ( {type: types.LOAD_ITEMS_ERROR, error} );

export function fetchItems() {
    return dispatch => {
        const request = axios.get(API_URL);
        request
            .then((response) => {
                if (response.status != 200) {
                    throw new Error("Invalid response");
                }
                return response.data;
            })
            .then((data) => {
                dispatch(loadItemsSuccess(data));
            })
            .catch((error) => {
                console.error(error);
                dispatch(loadItemsError(error));
            })
    }
}