import React from 'react'
import { Link } from 'react-router-dom'
import { TelstraIcon } from "../icons/GradientIcons";
import { TelstraIcon as TelstraIconSolid } from "../icons/SolidIcon";
import { flexStylesStart } from "../../../utils/ui";


export const RowIconItem = ({ text, icon, color, isSolid = false, size, hasBadge = false,link,to,props}) => (
    isSolid
        ? <span style={flexStylesStart}>{icon && <TelstraIconSolid color={color} icon={icon} size={size} hasBadge={hasBadge} primary />}<p className="mt-0 ml-1">{text}</p></span>
: <span style={flexStylesStart}>{icon && <TelstraIcon color={color} icon={icon} size={size} hasBadge={hasBadge} primary />}{link ? <Link to={to} onClick={props.handleOnCidnClick}>{text}</Link> :<p className="mt-0 ml-1">{text}</p>}</span>
)