import React,{useEffect} from "react";
import {connect} from "react-redux";

import {
  Box,
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  Typography,
  Button
} from "@material-ui/core";
import { Row, Col } from 'reactstrap';

import {
  Person as PersonIcon,
  DraftsOutlined as ReadIcon,
  EmailOutlined as UnreadIcon,
  People as PeopleIcon
} from "@material-ui/icons";

import { 
    fetchNotifications, 
    toggleNotificationReadFlag,
    markAllAsRead
} from '../../actions/notificationActions';
import { useState } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "./Notification.css";

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding:2
      }
    }
  }
});
const NotificationTable = (props) => {
  
    const [pages,setPages] = useState([5, 10, 25]);
    const [page, setPage] =  useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
    
    const handleChangePage = (event, newPage) =>{
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event) =>{
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    const fromatDate = (date) =>{
      const d = new Date( date );
      return d.getDate() + " " + d.toLocaleString('default', { month: 'long' }) + " , " + d.getFullYear();
    }
    const markAllAsRead = (userId) =>{
      const param = {
        userId
      };

      const fetchParam = {
        "batchSize": 100,
        "startIndex": 0,
        "username": userId
      };

      props.markAllAsRead(param).then((response) => {
        if(response.data.responseStatus = "Success")
            props.fetchNotifications(fetchParam);
      });
    }

    
  return (
    <MuiThemeProvider theme={theme}>
    <Box m={2}>
      <Card elevation={5}>
        <Box p={5}>
          <Row>
            <Col xs="10">
              <Typography variant="h5"> Notification Messages</Typography>
            </Col>
            <Col xs="2">
              <Button color="primary" onClick={()=>markAllAsRead(props.userId)}> Mark All As Read</Button>
            </Col>
          </Row>
        <br/>
          <Table className="notificationTable">
            <TableHead>
              <TableRow>
                <TableCell align="center" width="10%"></TableCell>
                <TableCell align="center" width="50%">
                  <Typography variant="h6">Message</Typography>
                </TableCell>
                <TableCell align="center" width="30%">
                  <Typography variant="h6">Received</Typography>
                </TableCell>
                <TableCell align="center" width="10%">
                  <Typography variant="h6">Status</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {
                    
                props.notifications.length>0?
                (props.notifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((notification, index) => {
                    notification["userId"] = props.userId;                 
                      
                    return(
                    <TableRow key={index + "noti"} className={notification.readFlag ? "" : "unreadRow"}>
                  <TableCell align="center" width="10%">
                          {notification.teamFlag ?
                            (<PeopleIcon fontSize="small" color="primary" />) :
                            (
                              <PersonIcon fontSize="small" color="primary" />)}
                  
                  </TableCell>
                  <TableCell align="center" width="50%">
                    <TextField
                      variant="standard"
                      multiline
                      rows={3}
                      fullWidth
                      readOnly
                      InputProps={{ disableUnderline: true }}
                      value= {notification.message}/>
                  </TableCell>
                  <TableCell align="center" width="30%">
                    {
                        fromatDate(notification.postedAt)
                    }
                  </TableCell>
                  <TableCell align="center" width="10%">
                        {
                            notification.readFlag?
                                <ReadIcon style={{cursor:'pointer'}} color="primary" onClick = {()=>props.toggleNotificationReadFlag(notification)}/>: 
                                <UnreadIcon style={{cursor:'pointer'}} color="primary" onClick = {()=>props.toggleNotificationReadFlag(notification)}/>
                        }
                  </TableCell>
                </TableRow>
                    )
                }))
                :
                'No Notification Found'
                 }
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={pages}
            component="div"
            count={props.notifications.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Card>
    </Box>
    </MuiThemeProvider>
  );
};
const mapStateToProps = state => {
    return {
      notifications: state.notification.notifications,
      userId: state.auth.userName,
      dNumber: state.auth.userName
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
        fetchNotifications : (req) => dispatch(fetchNotifications(req)),
        toggleNotificationReadFlag : (req) => dispatch(toggleNotificationReadFlag(req)),
        markAllAsRead: (req) => dispatch(markAllAsRead(req))
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(NotificationTable);