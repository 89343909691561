import React from "react";
import { Button } from "reactstrap";
import HuntGroupForm from "./HuntGroupForm";
import "./Product.css";

export default (props) => {
  const {fields, options, productType, cosmos} = props;
  return (
    <div>
      <div id="s2p-product-modal-input-head" className="row">
        <div className="col-6">
          <span>Service Association
            :</span>
        </div>
        <div className="col-3">
          <span>
            <Button
              color="link"
              onClick={() =>
                fields.push({ fnn: null, linkedFnn: null, role: null })
              }
            >
              <i className="fa fa-plus fa-sm" aria-hidden="true"></i>
            </Button>
          </span>
          <span>
            <Button
              color="link"
              style={{ color: "#dc3545" }}
              onClick={() => fields.pop()}
            >
              <i className="fa fa-minus fa-sm" aria-hidden="true"></i>
            </Button>
          </span>
        </div>
        <div className="col-3"></div>
      </div>
      {/* <div className="row"> */}
      <div id ="table-responsive" className="row ">
        <table className="table table-sm  table-borderles" >
          <thead className="ranges-fnn-head">
            <tr>
        <th style={{width: "33%"}} ><div className="col">FNN:</div></th>
        <th style={{width: "33%"}} ><div className="col">Linked FNN:</div></th>
        <th style={{width: "33%"}} > <div className="col">Role:</div></th>
        </tr>
        </thead>
      {/* </div> */}
      {fields.map((huntGroup, index) => {
        let from = 2 * index + 1,
          to = from + 1;
        return (
          <React.Fragment key={index}>
            <HuntGroupForm
              cosmos = {cosmos}
              productType = {productType}
              options={options}
              index={index}
              selectedFnn={fields.get(index).fnn}
            />
          </React.Fragment>
        );
      })}
      </table>
      </div>
    </div>
  );
};


