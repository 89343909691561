import React, { useState } from 'react';
import { connect } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import StatelessOrder from "../Custom/StatelessOrder"
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import Tooltip from '@mui/material/Tooltip'
import {setOrderType} from '../../../actions/orderBuilderActions';
import { Grid,makeStyles } from '@material-ui/core';
import StateFullOrder from './StateFullOrder';


const useStyles = makeStyles({
    stateLabel : {
        color : "#3f51b5 !important" ,
        marginLeft : "90px !important",
        fontSize : "20px !important"
    }
  
  });

  const InfoState=({type})=>{
    return <Tooltip title={type==="Stateless" ? "You can find all Vacant products under this option": "You can find all Active products under this option"}>
      <InfoSharpIcon style={{"fill":"#3f51b5"}}/>
    </Tooltip>
  }

const CustomOrderStates = (props) => {

    const classes = useStyles();

    const [stateValue, setStateValue] = React.useState('');
    const handleRadioChange = (event) => {
        setStateValue(event.target.value);}
    return (
      <div ><Grid container
      justifyContent="space-between"
      direction={'column'} spacing={24}>
        <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label" classes={{ root: classes.stateLabel }}>State</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleRadioChange}>
          <Grid item xl={1} md={1} sm={1} xs={1}></Grid>
      <Grid item xl={3} md={3} sm={3} xs={3}>
        <FormControlLabel value="Stateless" control={<Radio />} label="Stateless" /> <InfoState type="Stateless"/></Grid>
        <Grid item xl={3} md={3} sm={3} xs={3}> <FormControlLabel value="Stateful" control={<Radio />} label="Stateful" /><InfoState type="Stateful"/></Grid>
      
      </RadioGroup>
    </FormControl>
         </Grid>
         {stateValue === "Stateless" ? <StatelessOrder/> : <>
         {stateValue === "Stateful" ? <StateFullOrder/> : <></>}
         </>}
         </div>
    );
  };
  

    const mapDispatchToProps = (dispatch) => {
      return {
          setOrderType:(type) => dispatch(setOrderType(type))
    }
  }  
export default connect(null, mapDispatchToProps)(CustomOrderStates);