import axios from 'axios';
import { clearCookies } from "./utils/browser";
import { clearState } from './store/localStorage';
import { isTaasTokenValid } from './components/auth/functions';

export const axiosInstance = axios.create();
export const axiosTaasInstance = axios.create();

axiosTaasInstance.interceptors.request.use(async (config) => {
  if (config.headers["Authorization"]) {
    const token = config.headers["Authorization"].replace(/^Bearer\s/, '');
    const isVerified = await isTaasTokenValid(token);
    if (!isVerified.verified) {
      forceSignout();
    }
  }
  /** Adding channel type header to all the requests */
  const channelType = 'UI';
  config.headers["Channel-Type"] = channelType;
  return config;
});

axiosInstance.interceptors.request.use(async (config) => {
  if (config.headers["Authorization"]) {
    const token = config.headers["Authorization"].replace(/^Bearer\s/, '');
    const isVerified = await isTaasTokenValid(token);
    if (!isVerified.verified) {
      forceSignout();
    }
  }
  /** Adding channel type header to all the requests */
  const channelType = 'UI';
  config.headers["Channel-Type"] = channelType;
  return config;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Do something with response data
    if(response.status === 202){
      if(response.data.responseDescription.includes("Asset"))
        return Promise.resolve(response);
      const customError = new Error('LONG_RUNNING_ASYNC_ERROR');
      customError.response = {
        data: {
            "timestamp": "",
            "responseStatus": "ERROR",
            "responseDescription": "Checkout Failed",
            "status": "LONG_RUNNING_ASYNC_ERROR",
            "message": "Checkout Failed"
        }
    };
      return Promise.reject(customError);
    }
    return response
  }, function (error) {
      console.log(error);
      if(error.message === "Network Error"){
        let errorResp = {
            response: {
                data: {
                    "timestamp": "",
                    "responseStatus": "ERROR",
                    "responseDescription": "Facing Network Issues, There was no response received",
                    "status": "NETWORK_ERROR"
                }
            }
        };
        // Do something with response error
        return Promise.reject(errorResp);
      }
    return Promise.reject(error); 
  });

const forceSignout = () => {
  clearCookies();
  localStorage.removeItem("access_token");
  clearState();
  window.location.href = "/";
}