import _ from "lodash";
import {
  AUTH_LOGOUT,
  AUTH_RESET,
  OPEN_SIDEBAR_PANEL,
  SIDEPANEL_ENVIRNOMENT
} from "./actionTypes";
import config from "../config";
import { clearState } from '../store/localStorage';
import { clearCookies } from "../utils/browser"

const API_URL = config.api.uri;
const GATEWAY_URL = config.tdm_gateway_api.uri;

export const reset = () => dispatch => {
  dispatch({ type: AUTH_RESET });
};

export const signout = () => async dispatch => {
  clearCookies();
  localStorage.removeItem("access_token");
  clearState();
  dispatch({ type: AUTH_LOGOUT });
};

export const setSideBarPanel = (value) => async dispatch => {
  try {
    dispatch({ type: OPEN_SIDEBAR_PANEL, payload: value });
  } catch (e) { console.error(e); }
};

export const setSidePanelEnvirnoment = (activeEnvirnomentKey, activeEnvirnomentValue) => async (dispatch, getState) => {
  try {
    let selectedEnvironment = getState().auth.sidePanelEnvironment || {};

    // we don't allow user to uncheck activeEnvirnomentKey
    if (selectedEnvironment[activeEnvirnomentKey] === true && activeEnvirnomentValue === false) {
      dispatch({ type: SIDEPANEL_ENVIRNOMENT, payload: selectedEnvironment });
      return;
    }
    
    const env = _.mapValues(selectedEnvironment, () => false);
    selectedEnvironment = {...env, [activeEnvirnomentKey]: activeEnvirnomentValue};
    dispatch({ type: SIDEPANEL_ENVIRNOMENT, payload: selectedEnvironment });
  } catch (e) { console.error(e); }
};