import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";

const useStyles = makeStyles({
    stateLabel: {
        backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
        color: "#ffffff  !important",
        marginBottom: "20px !important",
        marginLeft: "20px !important"
    },
    stateSelectedLabel: {
        marginBottom: "20px !important",
        backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
        color: "#ffffff !important",
        marginLeft: "20px !important"
    },
    productHeaderLabel: {
        fontSize: "24px !important",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingLeft: "20px !important"
    },
    yesSpecification: {
        width: "650px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    },
    spacedDiv : {
        marginTop: "10px !important",
        marginLeft: "-20px !important"
    }

});


const Salesforce = (props) => {

    const classes = useStyles();
    const [salesforceTypes] = useState(["ESTABLISHED", "UN-ESTABLISHED"])
    const [selectedSF,setSelectedSF] = useState("");
    const [selectedPlan , setSelectedPlan] = useState("");
    const [planData,setSelectedPlanData] = useState("");
    const [labelText, setLableText] = useState("");
    const [salesForceServices] = useState(["SOLE-TRADER", "RESIDENTIAL", "COMPANY", "ORGANIZATION", "OTHERS"]);
    const [selectedSalesForce, setSelectedSalesForceService] = useState("");

    const [bilingAccounts] = useState(["POSTPAID","PREPAID"]);
    const [selectedBillingAccount, setSelectedBillingAccount] = useState("");

  const [contractType] = useState(["PLAN ONLY", "PLAN WITH DEVICE", "OTHERS"]);
  const [selectedContractType, setSelectedContractType] = useState("");

  const [prePaidContractType] = useState(["TELSTRA", "BOOST", "JBHIFI", "OTHERS"]);
  const [selectedPrePaidContractType, setSelectedPrePaidContractType] = useState("");
  const [noContractProducts] = useState(["MOBILE","NBN", "UNITI", "Leo Satellite/Starlink", "4G", "5G", "Fetch", "Accessories", "OTHERS"])
  const [selectedProduct, setSelectedProduct] = useState("");
  

    const setSpecReq = (key, value) => {
        setLableText(key)
        props.setSpecificRequirement({ [key]: value }, "")
    }

    return (
        <>
        <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Order Type</Typography>
                    {salesforceTypes.map((cable, index) => {
                        return (
                            <Button variant="contained" classes={cable !== selectedSF ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                sx={{ minWidth: 150, minHeight: 150 , maxWidth: 165}} value={cable}
                                onClick={e => {
                                    setSelectedSF(e.target.value);
                                    setSelectedPlan("");
                                }}>
                                {cable}
                            </Button>
                        )
                    })}
                </Grid></Grid>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                {selectedSF !== "" ?
                    <Grid item xs={10}>
                        <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Customer Type for :{selectedSF}</Typography>
                        {salesForceServices.map((cable, index) => {
                            return (
                                <Button variant="contained" classes={cable !== selectedSalesForce ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                    sx={{ minWidth: 150, minHeight: 150 }} value={cable}
                                    onClick={e => { setSelectedSalesForceService(e.target.value); }}
                                >
                                    {cable}
                                </Button>
                            )
                        })}
                    </Grid> : <></>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                {selectedSalesForce !== "" ?
                    <Grid item xs={10}>
                        <Typography classes={{ root: classes.productHeaderLabel }}>Please select the {selectedSalesForce} Billing Account</Typography>
                        {bilingAccounts.map((cable, index) => {
                            return (
                                <Button variant="contained" classes={cable !== selectedBillingAccount ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                    sx={{ minWidth: 150, minHeight: 150, maxWidth: 165 }} value={cable}
                                    onClick={e => {
                                        setSelectedBillingAccount(e.target.value);
                                        let key = "CUSTOM:ACTIVE SERVICE SALESFORCE " + selectedSF + " " + selectedSalesForce + " " + e.target.value + " DATA";
                                        setSpecReq(key, "");
                                    }
                                    }
                                >
                                    {cable}
                                </Button>
                            )
                        })}
                    </Grid> : <></>}
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={1} />
            {selectedBillingAccount === "POSTPAID" ?
              <Grid item xs={10}>
                <Typography classes={{ root: classes.productHeaderLabel }}>Please select the {selectedBillingAccount} Contract type</Typography>
                {contractType.map((cont, index) => {
                  return (
                    <Button variant="contained" classes={cont !== selectedContractType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                      sx={{ minWidth: 150, minHeight: 150 }} value={cont}
                      onClick={e => { setSelectedContractType(e.target.value);
                    }}
                        >
                      {cont}
                    </Button>
                  )
                })}

              </Grid> :
              <>
              </>}
              {selectedBillingAccount === "PREPAID" ?
              <Grid item xs={10}>
                <Typography classes={{ root: classes.productHeaderLabel }}>Please select the {selectedBillingAccount} Contract type</Typography>
                {prePaidContractType.map((cont, index) => {
                  return (
                    <Button variant="contained" classes={cont !== selectedPrePaidContractType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                      sx={{ minWidth: 150, minHeight: 150 }} value={cont}
                      onClick={(e) => { 
                        let value= e.target.value;
                        setSelectedPrePaidContractType(e.target.value);
                        let key = "CUSTOM : ACTIVE SALESFORCE " + selectedSF.toUpperCase() + " " + selectedPlan.toUpperCase() + " " + selectedSalesForce + " " + selectedBillingAccount.toUpperCase() + " " + value.toUpperCase() + " DATA";
                         setSpecReq(key, e.target.value); 
                    }}
                        >
                      {cont}
                    </Button>
                  )
                })}
              </Grid> : null}
              </Grid>
                {selectedBillingAccount === "PREPAID" && selectedPrePaidContractType ? 
                 <Grid container spacing={3} classes={{root: classes.spacedDiv}}>
                 <Grid item xs={1} />
                     <Grid item xs={11}><TextField
                         id="customerServiceData"
                         multiline
                         rows={4}
                         helperText="Enter the specific requirement"
                         label={labelText}
                         classes={{ root: classes.yesSpecification }}
                         value={planData}
                         onChange={(e,value) => { setSelectedPlanData(value);}}
                     /></Grid>
             </Grid> : null}
            {selectedBillingAccount === "POSTPAID" && selectedContractType ? <>
                <Grid container spacing={3} classes={{ root: classes.spacedDiv }}>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        <Autocomplete
                            disablePortal
                            id="with-contract-products"
                            options={noContractProducts}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => option}
                            placeholder="Select the Product"
                            value={selectedProduct}
                            onChange={(e, value) => {
                                setSelectedProduct(value);
                                let key = "CUSTOM : ACTIVE SALESFORCE " + selectedSF.toUpperCase() + " " + selectedPlan.toUpperCase() + " " + selectedSalesForce.toUpperCase() + " " + selectedBillingAccount.toUpperCase() + " " + selectedContractType.toUpperCase() + " " + value.toUpperCase() + " DATA";
                                setSpecReq(key, e.target.value); 
                            }}
                            renderInput={(params) => <TextField {...params} label="Products"
                                classes={{ root: classes.yesSpecification }} />}
                        />
                    </Grid>
                </Grid>
               </> : null}
               {selectedBillingAccount === "POSTPAID" && selectedProduct ? <Grid container spacing={3} classes={{root: classes.spacedDiv}}>
                 <Grid item xs={1} />
                     <Grid item xs={11}><TextField
                         id="customerServiceData"
                         multiline
                         rows={4}
                         helperText="Enter the specific requirement"
                         label={labelText}
                         classes={{ root: classes.yesSpecification }}
                         value={planData}
                         onChange={e => {
                             setSelectedPlanData(e.target.value);
                         }}
                     /></Grid>
             </Grid> : null }
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
        prodData: (value) => dispatch(prodData(value)),
        setSpecificRequirement: (value, sID) => dispatch(setSpecificRequirement(value, sID))
    }
}

export default connect(null, mapDispatchToProps)(Salesforce);