import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import pagination from "./pagination";
import { getPaymentBanLevelWithDate } from "../../../actions/u2cActions";
import PaginationController from "../../common/custom/PaginationController";
import PaymentTable from "./PaymentTable";
import sort from "./sort";
import "./paymentHistory.css";
import FilterBar from "./FilterBar";
import TableHeader from "./TableHeader";

class MyBills extends Component {
  state = {
    billType: undefined,
    focusedStart: false,
    focusedEnd: false,
    startDate: undefined,
    endDate: undefined,
    pageNum: 0
  };

  componentDidMount() {
    const endDate = moment();
    const startDate = moment(endDate).subtract("year", 1);
    this.setState((preState) => ({ ...preState, startDate, endDate }));
  }
  gotoPage = (pageNum = 0) => {
    this.setState({ pageNum });
  };
  onBillSearchType = billType => {
    this.setState({ billType });
  };

  onDateChange = (name, date) => {
    const newDate = moment(date);
    if (name == "startDate" && newDate.isAfter(this.state.endDate)) {
      return this.setState({ startDate: newDate, endDate: newDate.add("day", 1) }); // to ensure startDate <= endDate always
    }
    this.setState({ [name]: newDate });
  };

  onFocusChange = (name, focused) => {
    this.setState(() => ({ [name]: focused }));
  };

  handleOnSearch = () => {
    this.props.loadBanLevelWithDate(this.props.billingAccountNumber, moment(this.state.startDate).format("YYYY-MM-DD"), moment(this.state.endDate).format("YYYY-MM-DD"))
  }
  sortingTypes = [
    { label: "Date", value: 1 },
    { label: "Amount", value: 2 },
  ];

  getSortingType = (value) => {
    switch (value) {
      case "Date":
        return "paymentDate";
      case "paymentDate":
        return { label: "Date", value: 1 };
      case "Amount":
        return "paymentAmount";
      case "paymentAmount":
        return { label: "Amount", value: 2 };
      default:
        return "";
    }
  }

  onSelectChange = (selectedValue) => {
    this.setState({ sortType: this.getSortingType(selectedValue && selectedValue.label) })
  }

  render() {
    const { banLevelWithDate } = this.props;
    const { billingAccountNumber, paymentsPending, retrivePaymentFailed } = banLevelWithDate;
    const payments = banLevelWithDate.payments === undefined ? [] : banLevelWithDate.payments;
    const { startDate, focusedStart, endDate, focusedEnd, sortType } = this.state;
    return (
      <div className="container payment-history-block">
        <div className="row">
        </div>
        <FilterBar
          onDateChange={this.onDateChange}
          startDate={startDate}
          endDate={endDate}
          onFocusChange={this.onFocusChange}
          handleOnSearch={this.handleOnSearch}
          focusedEnd={focusedEnd}
          focusedStart={focusedStart}
        />

        {paymentsPending && <div className="  payments-message"><h3 >Payment History Loading...</h3></div>}
        {retrivePaymentFailed && <div className=" payments-message"><h3 >Couldn't retrieve payment history !!</h3></div>}
        {!paymentsPending && !retrivePaymentFailed && payments.length === 0 && <div className=" payments-message"><h3> No Payment History Available !!</h3></div>}
        {!paymentsPending && billingAccountNumber && payments.length > 0 && <React.Fragment>
          <TableHeader billingAccountNumber={billingAccountNumber}
            onSelectChange={this.onSelectChange}
            sortingTypes={this.sortingTypes}
            sortType={this.getSortingType(sortType)}
          />
          <div className="row">
            <div className="col-sm-12">
              <PaymentTable
                payments={
                  pagination(
                    sort(payments, this.state.sortType),
                    this.state.pageNum
                  ).content
                }
                gotoPage={this.gotoPage}
              />
            </div>
            <div className="col-sm-12">
              <PaginationController
                {...pagination(
                  payments,
                  this.state.pageNum
                )}
                gotoPage={this.gotoPage}
              />
            </div>
          </div>
        </React.Fragment>}
      </div>


    );
  }
}

const mapStateToProps = state => {
  return {
    banLevelWithDate: state.u2c.banLevelWithDate,
    billingAccountNumber: state.u2c.billingAccount.billingAccountNumber
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadBanLevelWithDate: (ban, startDate, endDate) => dispatch(getPaymentBanLevelWithDate(ban, startDate, endDate)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(MyBills);
