import React from "react";
import { connect } from "react-redux";
import './slider.css';
import { fetchPrograms } from "../../actions/ssoAuthActions";
import { fetchAllTeams } from "../../actions/myTeamsActions";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Avatar,Typography,colors} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';


const useStyles = makeStyles({
    root: {
      minWidth: 248,
      maxWidth: 248
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 18,
    },
    dataSize : {
        fontSize:22
    },
    pos: {
      marginBottom: 12,
    },
    avatarGreen: {
      backgroundColor: colors.green[600],
      height: 56,
      width: 56
    },
    avatarRed: {
      backgroundColor: colors.red[600],
      height: 56,
      width: 56
    },
    avatarBlue: {
      backgroundColor: colors.blue[600],
      height: 56,
      width: 56
    },
  });

const PasmatMetricCard = (props) => {
    const classes = useStyles();

    return (
        <div>
       <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom variant="h5" component="h2">
          {props.title}
        
        <Avatar className={props.avatar}>
              <props.icon/>
            </Avatar></Typography>
         <Typography className={classes.dataSize}>
          {props.data}
        </Typography>
        {props.linear !== undefined && props.linear && props.linearData !== undefined &&
        <LinearProgress value={props.linearData} variant="determinate"/>}
        <Typography className={classes.dataSize}>
          
        </Typography>
        
      </CardContent>
    </Card>
        </div>);
}

const mapStateToProps = (state) => {
    return {
        parentProgram: state.pasmatTrend.parentProgram.teamParentList,
        userId: state.auth.userName,
        quarters: state.pasmatTrend.quarters
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPrograms: () => dispatch(fetchPrograms()),
        fetchAllTeams: () => dispatch(fetchAllTeams())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasmatMetricCard);