import { getClassTypeIcon, getDataTypeIcon, getNumberIcon, renderIcon, renderQtyIcon } from '../../utils/ui.js';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import LinkButton from '../common/custom/LinkButton';
import Moment from 'react-moment';
import React, { useState } from 'react';
import { Table } from 'reactstrap';
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';

export const CheckoutHistoryRow = ({
    id,
    checkoutType,
    envFilter,
    dataFilter,
    techFilter,
    qtyRequested,
    success,
    usedDate,
    detailView,
    dataBankCheckoutView,
    uniqueIdentifier,
    extraInfo,
    message
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    let extraInfoJson;
    const extraInfoRows = () => {
        if (!extraInfo) {
            return;
        }
        try {
            extraInfoJson = JSON.parse(extraInfo);
        } catch (e) {
            return;
        }
        if (!extraInfoJson.details && extraInfoJson.details.length <= 0) {
            return;
        }
        return (

            <React.Fragment>
                {extraInfoJson.details.map((infoObject) =>
                    <React.Fragment>
                        <tr style={{display: isOpen ? '' : 'none'}}>
                            <th style={{fontSize: '14px'}} >{Object.keys(infoObject)[0]}</th>
                            <td style={{fontSize: '14px'}} >{Object.values(infoObject)[0]}</td>
                        </tr>
                    </React.Fragment>
            )}
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
        <tr>
            {(detailView && <td className="base-default">{renderIcon("icon-manage-services")}{id}</td>)}
            <td className="base-magenta">{renderIcon("icon-home")}{envFilter}</td>
            <td className="base-green">{renderIcon(checkoutType == "ACTIVE"
                ? "icon-laptop-phone"
                : "icon-register")}{checkoutType}</td>
            <td className="base-purple">{renderIcon(getDataTypeIcon(dataFilter))}{dataFilter}</td>
            <td className="base-orange">{renderIcon(getClassTypeIcon(techFilter))}{techFilter}</td>
            <td className="base-grey">
                {renderQtyIcon(qtyRequested)}
            </td>
            <td className="base-turquoise">
                {renderIcon("icon-time")}<Moment fromNow>{usedDate}</Moment>
            </td>
            {/* {dataBankCheckoutView &&
            <td>{uniqueIdentifier}</td>
            } */}
            <td
                className={success
                    ? "base-green"
                    : "base-orange"}>{message ? <i class="fa fa-exclamation-triangle text-warning fa-3x" aria-hidden="true"></i> : renderIcon(success
                        ? "icon-round-tick"
                        : "icon-round-cross")}</td>

            <td>
                {(detailView && <LinkButton
                    className="btn btn-primary btn-block"
                    to={!dataBankCheckoutView ? `/checkout/history/${id}` : `/tdm/checkout/history/${id}`}>
                    <i className="fa fa-eye" aria-hidden="true"/>
                    {' '}View</LinkButton>
                )}
            </td>
            {/* {dataBankCheckoutView &&
            <td className="base-blue">
                <button id="custom-arrow-button" onClick={toggle}>
                    {!isOpen ? renderIcon(getClassTypeIcon("down-arrow")) :  renderIcon(getClassTypeIcon("up-arrow"))}
                </button>
            </td>
            } */}

        </tr>
            {extraInfoRows()}
        </React.Fragment>

    )
}