import React, { useEffect } from "react";

import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import BreadTable from "@library/core/tables/BreadTable";
import Tooltip from '@mui/material/Tooltip';

const B2BBatchLevelData = (props) => {
  useEffect(() => {
    props.handleView(props.batchId);
  }, []);

  const isFailedStatus = (cleanUpStatus) => {
    return cleanUpStatus === "Fail";
  };

  const batchLevelDataArgs = {
    size: "large",
    isLoading: false,
    isExpandable: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: "All", value: -1 }],
    columnMetadata: [
      {
        dataset: 1,
        title: "Resource",
        field: ["primaryIdentifierValue"],
        expandable: true,
        component: (field) => (
          <Typography noWrap style={{ margin: "auto" }} noWrap variant="body2">
            {field}
          </Typography>
        ),
      },
      {
        dataset: 1,
        title: "Status",
        field: ["statusResponse"],
        expandable: true,
        component: (field) => (
          <Stepper activeStep={field ? field.length : 0}>
            {field.map((option, index) => {
              const labelProps = {};
              if (isFailedStatus(option.cleanupStatus)) {
                labelProps.error = true;
              }

              return (
                <Step key={option.applicationName}>
                  {
                    option.error ?
                    <Tooltip title={option.error} arrow>
                    <StepLabel {...labelProps}>
                      {option.applicationName}
                    </StepLabel>
                    </Tooltip>:

                    <StepLabel {...labelProps}>
                      {option.applicationName}
                    </StepLabel>
                  }

                </Step>
              );
            })}
          </Stepper>
        ),
      },
    ],
  };
  return (
    <div>
      {props.b2bDataCleanUpResourceStatus &&
        props.b2bDataCleanUpResourceStatus.length > 0 && (
          <BreadTable
            {...batchLevelDataArgs}
            data={props.b2bDataCleanUpResourceStatus}
          ></BreadTable>
        )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    b2bDataCleanUpResourceStatus: state.cleanup.b2bDataCleanUpResourceStatus
      ? state.cleanup.b2bDataCleanUpResourceStatus.appStatusResponse
      : [],
  };
};
export default connect(mapStateToProps, null)(B2BBatchLevelData);
