import React from "react";
import { Button } from "reactstrap";

export default ({ toggle, heading, section, icon }) => (<Button
  className={`u2p-collapse show}`}
  color="success"
  onClick={() => {
    toggle(section);
  }}
  style={{ marginTop: "1rem" }}
  block
>
  <span style={{ float: "left" }}>

    <i className={`td-icon-sm ${icon} theme-text-primary`} />{" "}
    {heading}
  </span>
</Button>
)