import React from 'react';
import {SubHeader} from "../../../common/custom/SubHeader";
import {Table} from "reactstrap";
import bill_period_png from "../../../../assets/images/bill_period.png";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export default function OrderTable({ orderDetails, getBillCycle, billStatus, getValueOfName,customerId, ban,  serviceMigLoading, smBtnInfo, smReq, handleServiceMigration, assetDetails }) {
    let checkTargetBill;
    if(assetDetails.service){
         const startSericeId=70;
         const endSericeId=79;
         checkTargetBill=assetDetails.service.id >= startSericeId  && endSericeId >= assetDetails.service.id;
    }
    const smBtnProps = {serviceMigLoading, smBtnInfo, smReq, handleServiceMigration};
    const click_bill_period=(eachOrder,order_index)=> {
    let ban_number=ban || getValueOfName(eachOrder, "BILLING_ACCOUNT");
    getBillCycle(ban_number,order_index);
   }
   const Bill_status_Icon=({eachOrder,index})=>{
       if(eachOrder.billPeriod){
           if(eachOrder.billPeriod==="network_issue"){
           return <Tooltip title="Network connectivity issues, please try again later." placement="bottom">
                 <ErrorOutlineOutlinedIcon style={{fill:"red",fontSize:"35px",marginLeft:"15px"}}/>
            </Tooltip>
        }else{
            return eachOrder.billPeriod
        }
    }
    if(billStatus){
        return <div className="lds-ellipsis"><div></div><div></div><div></div></div>
    } return <Tooltip title="Retrieve Bill Period" placement="bottom">
                 <div className='bill_icon' onClick={()=>click_bill_period(eachOrder,index)}>
                     <IconButton color="secondary" ><img style={{width: "45px",height:"38px", cursor: "pointer",marginLeft: "3px",filter:"saturate(200%) brightness(500%) invert(200%)"}} src={bill_period_png} height={45} width={45}/></IconButton>
                </div>
            </Tooltip>
   }

    return(
        <React.Fragment>
        {(orderDetails !== null && orderDetails !== undefined  && orderDetails.length > 0 &&
            <React.Fragment>
                <SubHeader smBtnProps={smBtnProps} text={"Orders"} iconClass={"td-icon-md icon-shop-online"} iconColor={"purple"} data={orderDetails} filename={`SomeFileName-ORDER`} />
                <Table responsive hover>
                    <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Customer ID</th>
                        <th>Plan</th>
                        <th>Billing Account ID</th>
                        {checkTargetBill ? <th>BILL PERIOD</th> : null}
                    </tr>
                    </thead>
                    <tbody>
                    {orderDetails.map((eachOrder,index) =>
                        <tr key={ eachOrder.orderId }>
                            <td scope="row">{eachOrder.orderId}</td>
                            <td>{customerId || getValueOfName(eachOrder.additionalDetails, "Customer")}</td>
                            <td>{assetDetails.service.id}, {assetDetails.service.serviceDescription}</td>
                            <td>{ban || getValueOfName(eachOrder, "BILLING_ACCOUNT")}</td>
                            {checkTargetBill ? <td><Bill_status_Icon eachOrder={eachOrder} index={index}/></td> : null}
                        </tr>
                    )}
                    </tbody>
                </Table>
            </React.Fragment>

        )}
        </React.Fragment>
    );
}
