import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Stepper as MuiStepper } from '@material-ui/core/';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SubmitButton from '@library/core/buttons/SubmitButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  stepper: {
    background: 'none',
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  marginShift: {
    marginLeft: "790px !important"
  },
  marginShiftCheckin: {
    marginLeft: " 760px !important"
  },
  marginShiftFilter:{
    marginLeft:'698px !important'
  }
}));

const Stepper = (props) => {
  const classes = useStyles();
  const { 
    steps, 
    stepValidationList = [],
    submitFunction,
    error, 
    setDisplayErrorOnNext, 
    stepsComponents, 
    navigateToCheckin, 
    resetToSpecificStep, resetSteps, resetCallBack, // Both required to enable reset to step 1 externally
    finalBtnText = 'Finish',
    currentStepCallback
  } = props;
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (navigateToCheckin) {
      handleNext();
      props.resetData();
    }
  }, [navigateToCheckin]);

  useEffect(() => {
    if (currentStepCallback) {
      currentStepCallback(activeStep);
    }
  }, [activeStep]);

  useEffect(() => {
    if (resetSteps && resetCallBack) {
      setActiveStep(resetToSpecificStep ? resetToSpecificStep : 0);
      resetCallBack();
    }
  }, [resetSteps]);

  const handleNext = () => {
    if ((stepValidationList.length > 0) && 
        !(stepValidationList.length < activeStep) && 
        stepValidationList[activeStep]
      ) 
    {
      let validate = stepValidationList[activeStep]();
      if (validate && !error) {
        if(setDisplayErrorOnNext !== undefined)
       { setDisplayErrorOnNext(false);}
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else {
        if(setDisplayErrorOnNext !== undefined)
        setDisplayErrorOnNext(true);
      }
    } else {
      if (!error) {
        if(setDisplayErrorOnNext !== undefined)
        setDisplayErrorOnNext(false);
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else {
        if(setDisplayErrorOnNext !== undefined)
        setDisplayErrorOnNext(true);
      }
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }
  const handleCustomDataBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    props.clearExcel();
  }
  const handleReset = () => {
    setActiveStep(0);
    if(props.customDataStepper)
      {props.clearExcel();}
  }
  const handleEnableBack = () => {
    if (activeStep === 0) { props.enableBackHandler(); }
    else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  }
 
  return (
    <>
      <MuiStepper className={classes.stepper} activeStep={activeStep} open alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel><Typography>{label}</Typography></StepLabel>
          </Step>
        ))}
      </MuiStepper>
      {stepsComponents[activeStep]}
      {props.customDataStepper !== true ?
        <div className={classes.buttons}>
          <>
          {props.enableBack !== undefined && props.enableBack === true ? 
          <SubmitButton onSubmit={handleEnableBack}>
          Back
        </SubmitButton>:
          <SubmitButton disabled={activeStep === 0} onSubmit={handleBack}>
            Back
          </SubmitButton>}</>
          <SubmitButton className="tour-library-stepper-nextBtn" onSubmit={activeStep === steps.length - 1 ? (submitFunction ? submitFunction : handleReset) : handleNext}>
            {activeStep === steps.length - 1 ? finalBtnText : 'Next'}
          </SubmitButton>
        </div> :
        <div className={classes.buttons}>
          <SubmitButton disabled={activeStep === 0} onSubmit={handleCustomDataBack}>
            Move Back
          </SubmitButton>
          {activeStep === steps.length - 2 ?
            <><SubmitButton onSubmit={props.handleCheckin}
              className={classes.marginShiftCheckin}
              disabled={!props.disableNext}>
              Checkin
            </SubmitButton>
              <SubmitButton className="tour-library-stepper-nextBtn" onSubmit={handleNext} disabled={props.disableNext}>
                  Next
                </SubmitButton>
            </> :
            <>{activeStep === steps.length - 1 ?
              <><SubmitButton onSubmit={props.updateFilters} className={classes.marginShiftFilter}>
                {finalBtnText}
              </SubmitButton>
               </> :
              <>
                <SubmitButton disabled={props.disableCheckin}
                  onSubmit={(props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? props.editData : props.createData}
                  className={(props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? classes.marginShift : ""}>
                  {(props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ? "Edit" : "Create"}
                </SubmitButton>
                {(props.editableDataset !== undefined && Object.keys(props.editableDataset).length !== 0) ?
                  <SubmitButton className="tour-library-stepper-nextBtn" onSubmit={handleNext}>
                    Next
                  </SubmitButton> : <></>}
              </>}</>
          }
        </div>}
    </>
  );
};

Stepper.propTypes = {
  stepsComponents: PropTypes.array.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  resetSteps: PropTypes.bool,
  resetCallBack: PropTypes.func,
  submitFunction: PropTypes.func,
}

export default Stepper;
