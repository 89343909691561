export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
}

export const saveState = state => {
    try {
        const serializaedState = JSON.stringify(state);
        localStorage.setItem('state', serializaedState);
    } catch (err) {
        // Ignore
    }
}

export const clearState = () => {
    try {
        const savedState = localStorage.getItem('state');
        if(savedState !== null) {
            localStorage.removeItem('state');
        }        
    } catch (err) {
        console.log('Error occurred while removing the state!')
    }
}