import {axiosTaasInstance as axios} from "../axios.config";
import config from "../config";
import * as types from "./dataCleanupActionTypes";
import { authHeaders } from "../utils/api";
import { displayErrorNew, displaySuccess } from '../components/common/alerts/alerts';
import { poolType } from "../components/cleanup/b2b/util/constants";

const API_URL = config.tdm_gateway_api.uri + '/tdm';
const EPIC_API_URL = config.tdm_epic_gateway_api.uri + '/tdm';

export const getMsisdnSimUpdateTypes = () => async dispatch => {
  try {
    const responseSim = await axios.post(`${EPIC_API_URL}/unms/resource/reference/fetch/SIM`, {}, { headers: authHeaders()});
    const responseImsi = await axios.post(`${EPIC_API_URL}/unms/resource/reference/fetch/IMSI`, {}, { headers: authHeaders()});
    const responseFnn = await axios.post(`${EPIC_API_URL}/unms/resource/reference/fetch/FNN`, {}, { headers: authHeaders()});
    const responseMll = await axios.post(`${EPIC_API_URL}/unms/resource/reference/fetch/MLLNNQ`, {}, { headers: authHeaders()});
    const responseSimFormat = responseSim.data && responseSim.data.ResourceType && responseSim.data.ResourceType.SIM ? responseSim.data.ResourceType.SIM.ResourceStatus.map((status) => {
      const iconName = () => {
        if (status == "RESERVED") return "icon-no-excess"
        if (status == "ACTIVE") return "icon-volume-on"
        if (status == "AVAILABLE") return "icon-wan-accelerate"
        return "icon-ui-search"
      }
      return {label:status, "icon":iconName(), color: "base-purple"}
    }) : []

    const responseImsiFormat = responseImsi.data && responseImsi.data.ResourceType && responseImsi.data.ResourceType.IMSI ? responseImsi.data.ResourceType.IMSI.ResourceStatus.map((status) => {
      const iconName = () => {
        if (status == "SIM_ALLOCATED") return "icon-sim-card"
        if (status == "ACTIVE") return "icon-volume-on"
        if (status == "AVAILABLE") return "icon-wan-accelerate"
        return "icon-ui-search"
      }
      return {label:status, "icon":iconName(),  color: "base-purple"}
    }) : []

    const responseFnnFormat = responseFnn.data && responseFnn.data.ResourceType && responseFnn.data.ResourceType.FNN ? responseFnn.data.ResourceType.FNN.ResourceStatus.map((status) => {
      const iconName = () => {
        if (status === "Pending Connect") return "icon-sip-connect"
        if (status == "Available") return "icon-wan-accelerate"
        if (status == "Active") return "icon-volume-on"
        if (status === "Held Order") return "icon-ui-pause"
        if (status === "Foreign Carrier") return "icon-technologies"
        return "icon-ui-search"
      }
      return {label:status, "icon":iconName(),  color: "base-purple"}
    }) : []

    const responseMllFormat = responseMll.data && responseMll.data.ResourceType && responseMll.data.ResourceType.MLLNNQ ? responseMll.data.ResourceType.MLLNNQ.ResourceStatus.map((status) => {
      const iconName = () => {
        if (status == "ACTIVE") return "icon-volume-on"
        if (status == "AVAILABLE") return "icon-wan-accelerate"
        if (status == "QUARANTINE") return "icon-teg-portal"
        if (status === "Pending Connect") return "icon-sip-connect"
        if (status === "Held Order") return "icon-ui-search"
        if (status === "Foreign Carrier") return "icon-technologies"
        if (status === "ASSIGNED") return "icon-thick-tick"
        if (status === "NOT IN USE") return "icon-operations-centre"
        if (status === "LEGACY") return "icon-data-insights"
        return "icon-ui-search"
      }
      return {label:status, "icon":iconName(),  color: "base-purple"}
    }) : []

    dispatch({type:types.UPDATE_SIM_IMSI_UPDATE_TYPES, payload:{responseSimFormat, responseImsiFormat, responseFnnFormat,responseMllFormat}})
  } catch (e) {
    console.error(e);
  }
}

export const selectDataCleanUpFilter = (key, value) => ({
  type: types.SELECT_DATA_CLEANUP_FILTER,
  key,
  value
});

export const resetCurrentStatus = () => ({ type: types.RESET_CURRENT_STATUS });

export const resetDataCleanUpFIlter = () => ({ type: types.RESET_DATA_CLEAN_UP_FILTERS });

export const fetchSNRMIMSIResources = ({ startRange, endRange, page = 0, fromSearch }) => async (dispatch, getState) => {
  try {
    const requestBody = {
      startRange: startRange,
      endRange: endRange,
      page: page,
      size: 6,
      environment: getState().auth.selectedEnvValue
    }
    console.log("fetchSnrmResources request payload ", requestBody);
    dispatch({ type: types.FETCH_SNRM_IMSI_RESOURCE_PENDING });
    const response = await axios.post(`${API_URL}/snrm-data-service/snrm/b2b/cleanup/snrmResources`, requestBody, { headers: authHeaders() });
    let responseFormat = {...response.data, ...{searchRequest: fromSearch}}
    dispatch({ type: types.FETCH_SNRM_IMSI_RESOURCE_SUCCESS, payload: responseFormat });
  } catch (e) {
    console.error(e)
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";
    dispatch({ type: types.FETCH_SNRM_IMSI_RESOURCE_FAILED });

    displayErrorNew({
      title: "Search Error",
      subTitle: "Unable to fetch SNRM data, please try again later",
      errorMsg
    })
  }
}

export const fetchSnrmResources = ({ startRange, endRange, page = 0, fromSearch }) => async (dispatch, getState) => {
  try {
    const requestBody = {
      startRange: startRange,
      endRange: endRange,
      page: page,
      size: 6,
      environment: getState().auth.selectedEnvValue
    }
    console.log("fetchSnrmResources request payload ", requestBody);
    dispatch({ type: types.FETCH_SNRM_RESOURCE_PENDING });
    const response = await axios.post(`${API_URL}/snrm-data-service/snrm/b2b/cleanup/resources`, requestBody, { headers: authHeaders() });
    let responseFormat = {...response.data, ...{searchRequest: fromSearch}}
    dispatch({ type: types.FETCH_SNRM_RESOURCE_SUCCESS, payload: responseFormat });
    return true
  } catch (e) {
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";
    dispatch({ type: types.FETCH_SNRM_RESOURCE_FAILED });

    displayErrorNew({
      title: "Search Error",
      subTitle: "Unable to fetch SNRM data, please try again later",
      errorMsg
    })
  }
}


export const fetchB2BDataCleanupResourceLevelStatus = (batchID) => async dispatch => {
  try{
    dispatch({ type: types.FETCH_B2B_CLEANUP_RESOURCE_STATUS_LOADING });
    const response = await axios.get(`${API_URL}/cleanup/b2b/cleanup/application/status/${batchID}`, { headers: authHeaders() });
    if(response.status === 200){
    dispatch({ type: types.FETCH_B2B_CLEANUP_RESOURCE_STATUS_SUCCESS, payload: response.data});
    return response.data;
    }else{
      dispatch({ type: types.FETCH_B2B_CLEANUP_RESOURCE_STATUS_FAILED });
      displayErrorNew({
        title: "Search Error",
        subTitle: response.data.responseDescription,
        errorMsg:response.data.responseStatus
      })
    }
  } catch (e) {
    dispatch({ type: types.FETCH_B2B_CLEANUP_BATCH_STATUS_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";
    displayErrorNew({
      title: "Search Error",
      subTitle: "Exception Occured while Fetching Resource Details",
      errorMsg
    })
  }
}

export const fetchB2BCleanupBatchStatus = (teamId) => async dispatch => {
  try{
    dispatch({ type: types.FETCH_B2B_CLEANUP_BATCH_STATUS_LOADING });
    const response = await axios.get(`${API_URL}/cleanup/b2b/cleanup/batch/status/${teamId}`, { headers: authHeaders() });
    if(response.status === 200){
    dispatch({ type: types.FETCH_B2B_CLEANUP_BATCH_STATUS_SUCCESS, payload: response.data});
    return response.data;
    }else{
      dispatch({ type: types.FETCH_B2B_CLEANUP_BATCH_STATUS_FAILED });
      displayErrorNew({
        title: "Search Error",
        subTitle: "Exception Occured while Fetching Batch Details",
        errorMsg:response.data.responseStatus
      })
    }
    console.log('response', response);
  } catch (e) {
    dispatch({ type: types.FETCH_B2B_CLEANUP_BATCH_STATUS_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";
    displayErrorNew({
      title: "Search Error",
      subTitle: "Exception Occured while Fetching Batch Details",
      errorMsg
    })
  }
}

export const fetchSnrmResourcesNoErrorDisplay = ({ startRange, endRange, page = 0 }) => async (dispatch, getState) => {
  try {
    const requestBody = {
      startRange: startRange,
      endRange: endRange,
      page: page,
      size: 6,
      environment: getState().auth.selectedEnvValue
    }
    dispatch({ type: types.FETCH_SNRM_RESOURCE_PENDING });
    const response = await axios.post(`${API_URL}/snrm-data-service/snrm/b2b/cleanup/resources`, requestBody, { headers: authHeaders() });
    dispatch({ type: types.FETCH_SNRM_RESOURCE_SUCCESS, payload: response.data });
  } catch (e) {
    console.error(e)
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";
    dispatch({ type: types.FETCH_SNRM_RESOURCE_FAILED, payload: errorMsg });
  }
}

export const resetSnrmResources = () => async dispatch => {
  dispatch({ type: types.RESET_SNRM_RESOURCES });
}

export const updateSnrmResource = (requestBody) => async dispatch => {
  try {
    console.log("updateSnrmResource request payload ", requestBody);
    dispatch({ type: types.UPDATE_SNRM_PENDING });
    const response = await axios.post(`${API_URL}/snrm-data-service/snrm/b2b/cleanup/status/update`, requestBody, { headers: authHeaders() });
    dispatch({ type: types.UPDATE_SNRM_SUCCESS, payload: response.data });
    displaySuccess({ title: "Numbers Updated", text: `${response.data.responseDescription}` });
  } catch (e) {
    console.error("error: ", e)
    dispatch({ type: types.UPDATE_SNRM_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";
    displayErrorNew({
      title: "Checkout Error",
      subTitle: "An error occured while updating your Snrm data!",
      errorMsg
    })
  }
}

export const updateSnrmImsiSimResource =(requestBody) => async dispatch => {
  try {
    console.log("updateSnrmResource request payload ", requestBody);
    dispatch({ type: types.UPDATE_SNRM_PENDING });
    const response = await axios.post(`${API_URL}/snrm-data-service/snrm/b2b/cleanup/imsi/status/update`, requestBody, { headers: authHeaders() });
    dispatch({ type: types.UPDATE_SNRM_SUCCESS, payload: response.data });
    displaySuccess({ title: "Numbers Updated", text: `${response.data.responseDescription}` });
  } catch (e) {
    console.error("error: ", e)
    dispatch({ type: types.UPDATE_SNRM_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";
    displayErrorNew({
      title: "Checkout Error",
      subTitle: "An error occured while updating your Snrm data!",
      errorMsg
    })
}
}
export const fetchResources = ({ startRange, endRange, page = 0, resourceType = "MSISDN", fromSearch }) => async (dispatch,getState) => {
  let selectedEnvValue= getState().auth.selectedEnvValue;
  if(selectedEnvValue === "SQNI"){
    selectedEnvValue="INT2"
  }
  try {
    let response;
    let responseUnmsFormat;
    dispatch({ type: types.FETCH_UNMS_RESOURCE_PENDING });
    if (resourceType === "MSISDN" || resourceType === "FNN") {
      response = await axios.get(`${EPIC_API_URL}/unms/resources?startRange=${startRange}&endRange=${endRange}&page=${page}&size=6&environment=${selectedEnvValue}`, { headers: authHeaders() });
      responseUnmsFormat = {...response.data, ...{searchRequest: fromSearch}}
    } else if (resourceType === "SIM/IMSI") {
      const requestBody = {
        imsiStart:startRange,
        imsiEnd:endRange,
        environment: selectedEnvValue,
      }
      response = await axios.post(`${EPIC_API_URL}/unms/simas/fetch`, requestBody, { headers: authHeaders() });
      responseUnmsFormat = {
        content : response.data.resourceStatus ? response.data.resourceStatus : [],
        first : true,
        last : true,
        pageable: {
          pageNumber : 0,
          pageSize : response.data.resourceStatus ? response.data.resourceStatus.length : 0
        },
        totalElements : response.data.resourceStatus ? response.data.resourceStatus.length : 0,
        totalPages : 1,
        loading : false
        }
    } else if (resourceType === "InfraCo FNN"){
      response = await axios.get(`${EPIC_API_URL}/unms/resources/fnn?startRange=${startRange}&endRange=${endRange}&page=${page}&size=6&environment=${selectedEnvValue}`, { headers: authHeaders() });
      responseUnmsFormat = {...response.data, ...{searchRequest: fromSearch}}
    }
    dispatch({ type: types.FETCH_UNMS_RESOURCE_SUCCESS, payload: responseUnmsFormat });
  } catch (e) {
    console.error(e)
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";
    dispatch({ type: types.FETCH_UNMS_RESOURCE_FAILED });

    displayErrorNew({
      title: "Search Error",
      subTitle: "Unable to fetch UNMS data, please try again later",
      errorMsg
    })
  }
}

export const resetResources = () => async dispatch => {
  dispatch({ type: types.RESET_RESOURCES });
}

export const insertNumbersInUnms = (requestBody) => async dispatch => {
  try {
    dispatch({ type: types.INSERT_UNMS_PENDING });
    const response = await axios.post(`${EPIC_API_URL}/unms/data-insert`, requestBody, { headers: authHeaders() });
    dispatch({ type: types.INSERT_UNMS_SUCCESS, payload: response.data });
    return response.data;
  } catch (e) {
    dispatch({ type: types.INSERT_UNMS_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";

    displayErrorNew({
      title: "Checkout Error",
      subTitle: "An error occured while inserting your UNMS data!",
      errorMsg
    })
  }
}

export const updateUnmsResource = (requestBody, handleOnSearch = () => {}) => async dispatch => {
  if(requestBody.environment === "SQNI"){
    requestBody.environment= "INT2";
  }
  try {
    dispatch({ type: types.UPDATE_UNMS_PENDING });
    let response;
    if (requestBody.resourceType == "SIM/IMSI") {
      delete requestBody.resourceType;
      response = response = await axios.post(`${EPIC_API_URL}/unms/simas/update`, requestBody, { headers: authHeaders() });
    } else if (poolType.includes(requestBody.type)) {
      response = await axios.put(`${EPIC_API_URL}/unms/resource/poolType/range`, requestBody, { headers: authHeaders() });
    } else if (requestBody.resourceType === "InfraCo FNN"){
      delete requestBody.resourceType;
      response = await axios.put(`${EPIC_API_URL}/unms/resource/fnn/status/range`, requestBody, { headers: authHeaders() });
    }else {
      response = await axios.put(`${EPIC_API_URL}/unms/resource/status/range`, requestBody, { headers: authHeaders() });
    }
    dispatch({ type: types.UPDATE_UNMS_SUCCESS, payload: response.data });

    const successText = response.data.updateCount ? `Update Count: ${response.data.updateCount}` : response.data.responseDescription;

    displaySuccess({ title: "Numbers Updated", text: successText });
    handleOnSearch();
  } catch (e) {
    dispatch({ type: types.UPDATE_UNMS_FAILED });
    let errorMsg = "";
    if (typeof e === "string") errorMsg = e;
    else if (e.response) errorMsg = e.response.data.message;
    else errorMsg = "Network connectivity issues, please try again later.";
    displayErrorNew({
      title: "Checkout Error",
      subTitle: "An error occured while updating you Unms data!",
      errorMsg
    })
  }
}
