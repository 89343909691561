import React from "react";
import classNames from 'classnames';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import "./TConnectEnvModal.css";

class TConnectEnvModal extends React.Component {
    state = {
        open: false
    };

    componentDidMount() {
        this.setState({ open: this.props.modalOpen })
    }


    handleClose = () => {
        this.setState({ open: false });
    };

    submitEnv = () => {
        if (this.refs.env) {
            this.props.handleSubmitEnv(this.refs.env.value);
        }
        this.handleClose();

    }

    render() {
        const modalStyles = {
            position: "absolute",
            top: "40%",
            left: "40%",
            transform: "translate(-50%, -50%) !important"
        };
        return (
            <div>
                <Modal
                    isOpen={this.state.open}
                    toggle={this.toggle}
                    className={this.props.className}
                    style={modalStyles}>
                    <ModalHeader toggle={this.toggle}>
                        <button
                            className={classNames("btn btn-sm btn-outline-primary ld-over m-1")}>
                            {< i className="td-sm-icon icon-telstra" aria-hidden="true" ></i>}
                        </button> <span className="env-modal-title">TConnect Environment</span>
                    </ModalHeader>
                    <ModalBody>
                        <select class="form-control" ref="env">
                            <option value="SIT">SIT</option>
                            <option value="UAT">UAT</option>
                        </select>
                    </ModalBody>
                    <ModalFooter>
                        <button color="#4CAF50" className="btn btn-success " id="submitButton" type="submit" onClick={this.submitEnv}> Save <i className="fa fa-check-square-o" aria-hidden="true"></i></button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    };

}

export default TConnectEnvModal;