import React from 'react'
import ReactDOMServer from 'react-dom/server';
import swal from "sweetalert2";

export const infoAlert = ({ title, text, confirmBtn = "OK", cancelBtn = "cancel", info }) => {

  return swal.fire({
    icon: 'info',
    title: title,
    text: text,
    type: "warning",
    html: ReactDOMServer.renderToStaticMarkup(info),
    showCancelButton: true,
    confirmButtonText: `<i class="fa fa-thumbs-up"></i> ${confirmBtn}!`,
    confirmButtonColor: "#218838",
    cancelButtonText: `<i class="fa fa-thumbs-down"></i> ${cancelBtn}`,
    cancelButtonColor: "#dc3545",
    reverseButtons: true
  });
};


export const successAlert = ({ title, confirmBtn = "OK" , successView, text}) => {
  return swal.fire({
    icon: 'success',
    title: title,
    text,
    html: ReactDOMServer.renderToStaticMarkup(successView),
  });
};

export const displayErrorNew = (props) => {
  const { title = "Error Occurerd", subTitle, errorMsg, requestUrl, text = `Checkout Failed` } = props;

  let contentBox = (
    <div id="swal-cust-err-box">
      <div id="swal-cust-err-subtitle">{subTitle}</div>
      <div style= {{paddingTop : "10px", color: "#bb2914"}} id="swal-cust-err-message">{errorMsg}</div>
    </div>
  )

  const mailString = `ErrorResponse: ${errorMsg}
  Request-Url :   ${requestUrl}`;
  return swal.fire({
    icon: 'error',
    type: 'error',
    title,
    text,
    html: ReactDOMServer.renderToStaticMarkup(contentBox),
    footer: `<a href='mailto:cc6fb024.teamtelstra.onmicrosoft.com@amer.teams.ms?Subject=Checkout Error Details&body=${mailString}' target="_top">Report Error</a>`
  })

}

export const displayErrorAlert = (props) => {
  const { title = "Error Occured", subTitle, errorMsg, requestUrl, text = `Checkout Failed` } = props;

  let contentBox = (
    <div id="swal-cust-err-box">
      <div id="swal-cust-err-subtitle">{subTitle}</div>
      <div style= {{paddingTop : "10px", color: "#bb2914"}} id="swal-cust-err-message">{errorMsg}</div>
    </div>
  );
  return swal.fire({
    icon: 'error',
    type: 'error',
    title,
    text,
    html: ReactDOMServer.renderToStaticMarkup(contentBox),
  });
}

export const displayLoading = (props) => {
  const { bodyText } = props
  const element = (
    <div style={{ borderTop: "1px solid #8888887a", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <h6 style={{ paddingBottom: "50px", paddingTop: "40px" }}>{bodyText}</h6>
      <svg className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
        <circle cx="170" cy="170" r="160" stroke="#E2007C" />
        <circle cx="170" cy="170" r="135" stroke="#404041" />
        <circle cx="170" cy="170" r="110" stroke="#E2007C" />
        <circle cx="170" cy="170" r="85" stroke="#404041" />
      </svg>
    </div>)
  return swal.fire({
    ...props,
    html: ReactDOMServer.renderToStaticMarkup(element),
    showConfirmButton: false,
    allowOutsideClick: false
  })
}

export const displayLoadingWithPercent = (props) => {
  const { bodyText } = props
  const element = (
    <div style={{ borderTop: "1px solid #8888887a", width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <h6 style={{ paddingBottom: "30px", paddingTop: "40px" }}>{bodyText}</h6>
      <svg className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
        <circle cx="170" cy="170" r="160" stroke="#E2007C" />
        <circle cx="170" cy="170" r="135" stroke="#404041" />
        <circle cx="170" cy="170" r="110" stroke="#E2007C" />
        <circle cx="170" cy="170" r="85" stroke="#404041" />
        <text className='modalLoaderPercentText' x="174" y="190" textAnchor="middle" fontSize="50">{(props.num/props.total*100).toFixed(0)}%</text>
      </svg>
    </div>)
  return swal.fire({
    ...props,
    html: ReactDOMServer.renderToStaticMarkup(element),
    showConfirmButton: false,
    allowOutsideClick: false
  })
}

export const updateLoadingWithPercent = (num, total) => {
  swal.getContent().getElementsByClassName('modalLoaderPercentText')[0].innerHTML = `${(num/total*100).toFixed(0)}%`;
}

export const plainAlert = ({ title, confirmBtn = "OK" , responseView}) => {
  return swal.fire({
    title: title,
    html: ReactDOMServer.renderToStaticMarkup(responseView),
  });
};

export const responseAlert = (status, statusMessage, animation) => {
  swal.fire({
    type: status,
    text: statusMessage,
    customClass: animation
  });
};

export const warningAlert = (title, text) => {
  const swalWithBootstrapButtons = swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger"
    },
    buttonsStyling: {
      marginLeft: "5px",
      marginRight: "5px"
    }
  });
  return swalWithBootstrapButtons.fire({
    title: title,
    text: text,
    type: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, submit",
    confirmButtonColor: "#218838",
    cancelButtonText: "No, cancel",
    cancelButtonColor: "#dc3545",
    reverseButtons: true
  });
};

export const displaySuccess = (props) => {
  return swal.fire({
      ...props,
      type: "success"
  })
};

export const displayWarning = (props) => {
  return swal.fire({
      ...props,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, submit",
      confirmButtonColor: "#218838",
      cancelButtonText: "No, cancel",
      cancelButtonColor: "#dc3545",
      reverseButtons: true,
  })
};

export const customAlert = ({icon = 'success', title, confirmBtn = "OK" , view, text, type = 'success'}) => {
  return swal.fire({
    icon,
    title,
    text,
    type,
    html: ReactDOMServer.renderToStaticMarkup(view),
  });
};

export const displayError = (e) => {
  const { error, message } = e.response.data || e.response;
  let swalOptions = {
      title: error,
      text: message,
      validationMessage: message,
      type: 'error'
  };
  return swal.fire(swalOptions);
}

export const displayErrorResopnse = e => {
  e = networkIssueMessage(e);
  const { responseStatus, responseDescription } = e.response.data || e.response;
  let swalOptions = {
      title: responseStatus,
      text: responseDescription,
      validationMessage: responseDescription,
      type: 'error'
  };
  return swal.fire(swalOptions);
}

const networkIssueMessage = (e) => {
  if (e.message === "Network Error") {
    return {
        response: {
            data: {
                "timestamp": "",
                "responseStatus": "ERROR",
                "responseDescription": "Facing Network Issues, There was no response received",
                "status": "NETWORK_ERROR"
            }
        }
    };
  } else {
    return e;
  }
}