import React, { Component } from 'react'

import {
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    ListGroupItemHeading,
    ListGroupItemText,
    Card
} from 'reactstrap';
import LineGraph from '../common/graphs/LineGraph';
import PieGraph from '../common/graphs/PieGraph';
import {getSortedDateValues} from '../../utils/chart';

export default class UserProfileStats extends Component {
  render() {
    const {checkoutsTotalByMonth, checkoutMethods, checkoutProducts} = this.props.profile;
    const labels = Object.keys(getSortedDateValues(checkoutsTotalByMonth));
    const rawData = Object.values(getSortedDateValues(checkoutsTotalByMonth));
    return (
        <Row>
        <Col xs="12">
            <Card
                style={{
                "padding": "20px"
            }}>
                <h5>Daily Checkouts</h5>
                <LineGraph labels={labels} data={rawData} label={"Total Checkouts"}/>
            </Card>
        </Col>
        <Col xs="6">
            <Card
                style={{
                "padding": "20px"
            }}>
                <h5>Checkout Products</h5>
                <PieGraph
                    methods={checkoutProducts}
                    backgroundColors={['#FF6384', '#36A2EB', '#FFCE56']}/>
            </Card>
        </Col>
        <Col xs="6">
            <Card
                style={{
                "padding": "20px"
            }}>
                <h5>Checkout Methods</h5>
                {
                    <PieGraph
                    methods={checkoutMethods}
                    backgroundColors={["#84FF63", "#8463FF", "#6384FF"]}/>
                }
            </Card>
        </Col>
    </Row>
    )
  }
}