import * as types from './actionTypes';
import {axiosTaasInstance as axios} from "../axios.config";

import config from '../config';
import { authHeaders } from "../utils/api";

const GATEWAY_URL = config.tdm_gateway_api.uri;

export const addSomResourceStubRange = (reqBody) => async dispatch => {
    try {
     const response = await axios.post(`${GATEWAY_URL}/tdm/syntheticdata/data/create`, reqBody, { headers: authHeaders() })
     if (response.status === 200){
       dispatch({ type: types.SOM_RESOURCE_STUB_ADDITION_SUCCESS, payload: response.data.message});
       return response.data.message;
     }
    } catch(e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.error;
        }
        dispatch({ type: types.SOM_RESOURCE_STUB_ADDITION_FAIL, payload: message})
        return {failed: true, message: message};
    }
}

export const deleteSomResourceStubRange = (reqBody) => async dispatch => {
    try {
     const response = await axios.delete(`${GATEWAY_URL}/tdm/syntheticdata/data/delete`, { headers: authHeaders(), data: reqBody});
     if (response.status === 200){
       dispatch({ type: types.SOM_RESOURCE_STUB_DELETE_SUCCESS, payload: response.data.message});
       return response.data.message;
     }
    } catch(e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.error;
        }
        dispatch({ type: types.SOM_RESOURCE_STUB_DELETE_FAIL, payload: message})
        return {failed: true, message: message};
    }
}

export const searchSomResourceStubRange = (reqBody) => async dispatch => {
    try {
        dispatch({ type: types.SOM_RESOURCE_STUB_SEARCH_PENDING });
        const response = await axios.post(`${GATEWAY_URL}/tdm/syntheticdata/data/resources`, reqBody, { headers: authHeaders()});
        if (response.status === 200) {
            dispatch({ type: types.SOM_RESOURCE_STUB_SEARCH_SUCCESS, payload: response.data });
            return response.data;
        }
    } catch(e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.error;
        }
        dispatch({ type: types.SOM_RESOURCE_STUB_SEARCH_FAIL, payload: message})
        return {failed: true, message: message};
    }
}

export const resetSomResourceStub = () => async dispatch => {
    dispatch({ type: types.SOM_RESOURCE_STUB_CLEAR });  
}