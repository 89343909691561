import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { FormAutoComplete } from "@library/core/form-elements";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";

const useStyles = makeStyles({
    stateLabel: {
        backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
        color: "#ffffff  !important",
        marginBottom: "20px !important",
        marginLeft: "20px !important"
    },
    stateSelectedLabel: {
        marginBottom: "20px !important",
        backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
        color: "#ffffff !important",
        marginLeft: "20px !important"
    },
    productHeaderLabel: {
        fontSize: "24px !important",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingLeft: "20px !important"
    },
    yesSpecification: {
        width: "650px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    },
    rangeSpecification : {
        width: "300px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    },
    radioLabel:{
        marginLeft: "11px !important"
    }

});


const StatelessPrepaid = (props) => {

    const classes = useStyles();

    const [prepaidTypes, setPrepaidTypes] = React.useState(["100BOOST",
    "10BOOSTM",
    "10BOOSTS",
    "10BOOSTT",
    "150BOOST",
    "150TELST",
    "150TSTUD",
    "200BOOMB",
    "200BOOST",
    "20BOOSTS",
    "2BOOSTMS",
    "2BOOSTSS",
    "2G30DH10",
    "300BOOST",
    "300TELST",
    "30BOOSTT",
    "3G30DH10",
    "3G30DM10",
    "3GBBMEDM",
    "3GBBMF60",
    "3GBBSMLL",
    "3GHDS010",
    "3GWBBMF3",
    "40BOODI",
    "40BOOSTM",
    "40BOOSTS",
    "40TDIGI",
    "40TLOTE",
    "50BOOSTT",
    "90TELST",
    "COLES002",
    "COLES010",
    "JPHFPPMO",
    "JPHiFi10",
    "MS30DS30",
    "NS30DS30",
    "UN14DH10",
    "UN30DS00",
    "UN30DS30",
    "UV30DS30",
    "UN60DS28",
    "Others"
])
    const [selectedPrepaidType, setSelectedPrepaidType] = React.useState("");
    const [gnxData, setGnxData] = React.useState("");
    const [prepaidOthersData, setPrepaidOthersDataa] = React.useState("");
    const [labelText, setLableText] = useState("");


    const clearSubProducts = () => {
    }

   useEffect(() => {
        clearSubProducts();
    }, [props.clearPrepaid]);

    const setSpecReqData= (ppData,value) => 
    {
        let key = "CUSTOM: VACANT PREPAID "+ppData.toUpperCase()+" DATA"
        props.setSpecificRequirement({[key]: value }, "");
        setLableText(key);
    }


    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Prepaid Account Profile </Typography>
                    <Autocomplete
                        id="prepaidTypes"
                        options={prepaidTypes}
                        sx={{ width: 300 }}
                        getOptionLabel={(option) => option}
                        placeholder="Select the product types"
                        value={selectedPrepaidType}
                        onChange={(event, value) => {setSelectedPrepaidType(value);
                            setSpecReqData(value,"");}}
                        renderInput={(params) => <TextField {...params} label="Prepaid type"
                            classes={{ root: classes.yesSpecification }} />}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                {(selectedPrepaidType !== "" && selectedPrepaidType !== undefined && selectedPrepaidType !== null) ? 
                <Grid item xs={10}>
                    <TextField
                            id="prepaidData"
                            multiline
                            rows={2}
                            helperText="Enter the specific requirement"
                            label={labelText}
                            classes={{ root: classes.yesSpecification }}
                            value={gnxData}
                            onChange={e => {setGnxData(e.target.value);
                                setSpecReqData(selectedPrepaidType,e.target.value);}}
                        />  

                </Grid>:<></>}</Grid>
          
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
        prodData: (value) => dispatch(prodData(value)),
        setSpecificRequirement: (value,sID) => dispatch(setSpecificRequirement(value,sID))
    }
}

export default connect(null, mapDispatchToProps)(StatelessPrepaid);