import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import TextField from "@material-ui/core/TextField/TextField";
import { compose } from "redux";
import classNames from "classnames";
import "./SSOSignin.css";
import {
    ssoSignin,
    fetchAccountDetails,
    savePreferences,
    fetchPreferences,
    saveTeamList,
    setAuthUserToken
  } from "../../../actions/ssoAuthActions";
import { FormControl, InputAdornment, CircularProgress} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from "../../common/muialerts/alerts";
import swal from "sweetalert2";
import {getTokenFromCookie} from "../../../utils/browser";
import { isTaasTokenValid } from "../functions";
import tdm_logo from '../../../assets/images/tdm_logo.png';

const useStyles = theme => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '100%',
      },
     
    },
    textInput : {
        width: "100%", 
        "& .MuiInputBase-input.MuiOutlinedInput-input" :{
            height: "46px",
            lineHeight: "46px"
        },
        "& .MuiInputBase-input.Mui-disabled" :{
          pointerEvents: "none",
        borderColor: "#ebebeb !important",
        backgroundColor: "#e9e9e9 !important",
        color : "#1d1d1d !important",
        padding : "3px 7px 0px 12.5px !important"
      },backgroundColor: "#e9e9e9 !important",
    
      },
      teamTextInput : {
        "& .MuiInputBase-input.MuiOutlinedInput-input" :{
          height: "46px",
            lineHeight: "46px",
          padding : "3px 7px 0px 12.5px !important",
          backgroundColor: "white" }
      },
      formControl: {
        width: "100%",
        "& .MuiInputLabel-formControl" : {
            left: "12px",
            transform: "translate(0, 18px) scale(1)"
        }
      },
      select:{
        width: "100%" ,
        marginTop : 0,
        marginBottom : 0,
        "& .MuiOutlinedInput-input" :{
            padding : "16px 14px"
        }
      }
  });


class SSOUserLogin extends Component {
    state = {
      username: '',
      email:'',
      name : '',
      team :'',
      teamList:[],
      userTeamList : [],
      userTeam : {teamId: '', teamName: ''},
      signinLoading : false,
      finishedLoading: false,
    };
    
    async componentDidMount() {
      this.setState({
        ...this.state,
        username: this.props.usersname,
        email: this.props.userEmail,
        finishedLoading: false,
      });
      this.props.fetchAccountDetails(this.props.userName).then(async result => {
        if (result?.workspaces) {
          const existingTaasAuthorizationToken = getTokenFromCookie('authorization_token');
          if (existingTaasAuthorizationToken && (await isTaasTokenValid(existingTaasAuthorizationToken)).verified) {
            this.props.setAuthUserToken(existingTaasAuthorizationToken);
          }

          let prefTeam;
          let teamList = result.workspaces.map((_item) => {
            let team=_item.tools.map((_item2)=>{
              return {
                teamId:_item2.teamId,
                teamName:_item2.teamName
              }
            })
            return team;
          });
          const flatTeam = teamList.flat(1);
          this.props.saveTeamList(flatTeam);

          this.props.fetchPreferences(this.props.userName).then(prefResult => {
            if (prefResult?.teamName) {
              const selectedTeam = prefResult.teamName;
              prefTeam = flatTeam.find(element => element.teamName === selectedTeam);
            }
            this.setState({
              ...this.state,
              name: result.firstName,
              userTeamList: flatTeam,
              finishedLoading: true,
              userTeam: prefTeam ? prefTeam : flatTeam[0] || null
            });
          });
        } else {
          this.setState({
            ...this.state,
            finishedLoading: true
          });
        }
      });
    }

      componentDidUpdate(prevProps) {
        if (this.props.userName !== prevProps.userName) {
            this.setState({
                username: this.props.userName,
                email: this.props.email,
                name: this.props.name,
            });
        }
      }

      onSubmit = (values) => {
        const { userTeam } = this.state;
        this.setState({signinLoading : true});      
        const reqBody = {
          team:  Number(userTeam.teamId) 
        }
        const reqHeader = this.props.accessToken;
        this.props.ssoSignin(reqBody, reqHeader).then(result =>{
          if(result){
              const req= {
                  userName : this.props.username,
                  env : "INT2",
                  teamName : this.state.userTeam.teamName,
                  programName : "",
                  salesForceProfile : "",
                  sessionSave : true
              }
              this.props.savePreferences(req);
          } else {
            swal.fire({type: 'error', title: "Error", html: "Taas Token Invalid, Please signin again"}).then(res => {
              this.props.history.replace(`/`);
            });
          }
          this.setState({
              signinLoading : false
          });
        });
      };

      handleChange = name => event => {
        this.setState({
          [name]: event.target.value
        });
      };

    render() {
        const { classes } = this.props;
        const { handleSubmit, authErrorMessage, preferencesLoading, accountDetailsLoading, accountDetailsFailed } = this.props;
        const {username,email,userTeamList,userTeam, signinLoading, finishedLoading} = this.state;
        return (
          <div>
            <form
              className="ssoForm-signin text-center white" >
                  <div class="mb-4" style={{"display": "inlineFlex","justifyContent": "center", "width": "100%","alignItems": "center", "padding": "5px"}}>
                  <img src={tdm_logo} height={140} width={140} style={{marginBottom:"-10px"}}/>  
                </div>
              <div className="row" style={{"marginBottom" : "8px"}}>
              {authErrorMessage && <MuiAlert title="Error" text={authErrorMessage} />}
                <div className="col-sm-6">

                <Field name="username"
                    component={({input}) =>
                    <div style={{"textAlign": "justify","padding": "8px"}}>
                        <InputLabel htmlFor="username" style={{"margin-bottom": ".5rem"}}>Account-01 ID</InputLabel>
                        <TextField
                          className={classes.textInput}
                          id="outlined-basic"
                          variant="outlined" 
                          value={username}
                          onChange={this.handleChange("username")}
                          fullWidth
                          autoComplete='off'
                          disabled
                        />
                    </div>
                    } />
                </div>
                <div className="col-sm-6">
                <Field name="email"
                    component={({input}) =>
                    <div style={{"textAlign": "justify","padding": "8px"}}>
                        <InputLabel htmlFor="email" style={{"margin-bottom": ".5rem"}}>Email ID</InputLabel>
                        <TextField
                          className={classes.textInput}
                          id="outlined-basic"
                          variant="outlined" 
                          value={email}
                          onChange={this.handleChange("email")}
                          fullWidth
                          autoComplete='off'
                          disabled
                          InputProps={{       
                            endAdornment: (
                                <InputAdornment position="end">
                                    {((preferencesLoading || accountDetailsLoading) && !email) ? <CircularProgress size={20} color={'secondary'} /> : <></>}
                                </InputAdornment>
                            ),
                          }}
                        />
                    </div>
                    } />
                </div>
                <div className="col-sm-6">     
                    <div className="muiSelectLabel">Team Name</div>                    
                    <Field name="userTeam"
                    component={({input}) =>
                    <div style={{"textAlign": "justify","padding": "8px","width":"545px"}}>
                         <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="userTeam"> 
                              {
                                ((userTeam && userTeam.teamId === '' && userTeamList && userTeamList.length > 0) && 'Select Team') || 
                                (userTeamList && userTeamList.length === 0 && (!accountDetailsLoading && !preferencesLoading && !accountDetailsFailed && finishedLoading) && 'No Teams Available') || 
                                (accountDetailsFailed ? "Failed to retrieve teams" : "")
                              }      
                            </InputLabel>
                            <TextField
                              id="outlined-basic"
                              select
                              placeholder="Select"
                              className={classes.select}
                              value={userTeam}
                              onChange={this.handleChange("userTeam")}
                              margin="normal"
                              variant="outlined"
                              disabled={userTeamList.length === 0}
                              InputProps={{       
                                endAdornment: (
                                    <InputAdornment position="end" style={{"marginRight":"15px"}}>
                                        {(preferencesLoading || accountDetailsLoading || !finishedLoading) ? <CircularProgress size={20} color={'secondary'} /> : <></>}
                                    </InputAdornment>
                                ),
                              }}
                            >
                                
                                {userTeamList.map(option => (
                                    <MenuItem key={option.teamId} value={option}>
                                    {option.teamName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </div>
                    } />
                </div>
              </div>
              <button
                className={classNames(
                  "btn",
                  "btn-lg",
                  "btn-block",
                  "ld-ext-right",
                  { running: signinLoading }
                )}
                type="submit"
                style={{background:"#6bcbfe",color:"#fff"}}
                disabled={
                  !userTeam || userTeam.teamId === '' || 
                  (
                    preferencesLoading || 
                    accountDetailsLoading || 
                    accountDetailsFailed ||
                    !userTeamList || 
                    (userTeamList && userTeamList.length == 0)
                  )
                }
                onClick={handleSubmit(values => {
                        this.onSubmit(values);
                    })
                  }
                >
                {!signinLoading ? "Sign In" : "Signing In"}
                {signinLoading && <div class="ld ld-ring ld-spin"></div>}
              </button>
            </form>
          </div>
        );
      }
}
const mapStateToProps = state => {
    return {
      authErrorMessage: state.auth.errorMessage,
      usersname: state.auth?.user?.username,
      userEmail: state.auth?.user?.email,
      preferencesLoading: state.auth.preferencesLoading,
      accountDetailsLoading: state.auth.accountDetailsLoading,
      accountDetailsFailed: state.auth.accountDetailsFailed
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        fetchAccountDetails:(username)=>dispatch(fetchAccountDetails(username)),
        saveTeamList:(teams)=>dispatch(saveTeamList(teams)),
        ssoSignin: (reqBody,reqHeader) => dispatch(ssoSignin(reqBody,reqHeader)),
        savePreferences: (request) => dispatch(savePreferences(request)),
        fetchPreferences: (userId) => dispatch(fetchPreferences(userId)),
        setAuthUserToken : (token) => dispatch(setAuthUserToken(token))
    };
  };

export default compose(
    withStyles(useStyles, {
        name: 'SSOUserLogin',
      }),
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    reduxForm({form: "ssoUserLogin" })
  )(SSOUserLogin);