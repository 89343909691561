import React, { useState, useEffect, Fragment }  from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {connect} from 'react-redux';
import Select from "react-select";
import swal from 'sweetalert2';
import { displayLoading,displayErrorAlert } from "../../common/alerts/alerts";
import _ from "lodash";
import {  getTeamSource,fetchAllActiveServiceIds} from '../../../actions/standardDataMyTeamsActions';
import {addThresholdValuesToServiceIds } from '../../../actions/serviceIdThresholdActions';
import { Grid,Button as MuiButton, FormHelperText  } from '@material-ui/core';
import { Add as AddIcon,Clear as ClearIcon } from "@material-ui/icons";
import ChipInput from 'material-ui-chip-input';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {v4 as uuidv4} from "uuid";

const StyledChipInput = withStyles(theme => ({
    root: {
      border: 'none',
    },
    input: {
      border: 'none !important',
    }
}))(ChipInput);

function AddThresholdModal(props) {
  const[modal, setModal] = useState(false);
  const isEditModal = props.editModal;
  const[chipError, setChipError] = useState();
  const[addDisabled, setAddDisabled] = useState(false);
  const[valueDisabled, setValueDisabled] = useState(true);
  const[selectedServiceIds, setSelectedServiceIds] = useState(isEditModal ? props.oldThresholdData.serviceId : []);
  const[selectedTeam, setSelectedTeam] = useState( isEditModal ? { value: props.oldThresholdData.teamId, label: props.oldThresholdData.teamId } : {});
  const[thresholdData, setThresholdData] = useState({
    [uuidv4()]: {
      serviceId: isEditModal ?props.oldThresholdData.serviceId : "",
      thresholdValues: isEditModal ? props.oldThresholdData.thresholdValues : [],
    }
  });

  const customStyles = {
    valueContainer: provided => ({
      ...provided,
      display: "block",
      width: "100px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: "0.8rem",
      height: "43px"
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      display: state.hasValue ? "none" : "inherit",
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: state.hasValue ? "none" : "inherit"
    })
  }

  const toggle = () => {
    setModal(!modal);
    setAddDisabled(false);
    setValueDisabled(true);
    setSelectedServiceIds([]);
    setSelectedTeam({});
    setThresholdData({
      [uuidv4()]: {
        serviceId:"",
        thresholdValues:[],
      }
    });
    props.setOpenThreshold(false);
  };

  useEffect(() => {
    const {selectedToggleButton, selectedEnvValue, loggedInUser, allTeams} = props;
    setModal(true);
    props.getTeamSource(selectedEnvValue, selectedToggleButton, loggedInUser, allTeams);
    props.fetchAllActiveServiceIds();
  }, []);

 const fetchCurrentTeam=(teams, teamId)=>{
    return teams.filter(team => team.teamId === teamId).map(team =>({ value: team.teamId, label: team.teamName }));
  }
 const fetchTeamOptions = teams =>{
    return teams.map(team => ({ value: team.teamId, label: team.teamName }));
  }
  const onTeamChange = (team) => {
    setSelectedTeam({ value: team.value, label: team.label });
  }

 const activeServiceIdOptions=(selectedServiceIds)=>{
    return props.allActiveServiceIds.filter(obj => !(selectedServiceIds.includes(obj.id))).map(serviceId=>({value: serviceId.id, label: serviceId.id})).sort((a,b) => a.value-b.value);
  }

  //-----------Input Change-------------
 const handleinputchange = (e, key) => {
    let copythresholdData={...thresholdData};
    const serviceId=  e.value;
    selectedServiceIds.push(serviceId);
    copythresholdData[key]['serviceId']=serviceId;
    setThresholdData(copythresholdData);
  }
  //----------Add & Remove Rows--------------
 const handleAddclick=()=>{
    let newStateValue= {...thresholdData};
    newStateValue[uuidv4()] = {
      serviceId:"",
      thresholdValues:[],
    };
    setThresholdData(newStateValue);
  }

  const handleRemove= (key) =>{
    let copythresholdData={...thresholdData};
    delete copythresholdData[key];
    setThresholdData(copythresholdData);
  }

  //----------Chip--------------
  const handleAddChip = (chip, key) => {
    if(chip === "0"){
      setChipError( "THresholdValue should be greater than 0");
    }else{
      setChipError();
    const thresholdDataCpy={...thresholdData};
    thresholdDataCpy[key]["thresholdValues"]=[...thresholdDataCpy[key]["thresholdValues"],chip];
    setThresholdData(thresholdDataCpy)
    }
      }
  const handleDeleteChip = (chip, key) => {
    const thresholdDataCpy={...thresholdData};
    let getFilterthresholdValues= thresholdDataCpy[key]["thresholdValues"].filter(_item => _item !== chip);
     thresholdDataCpy[key].thresholdValues=getFilterthresholdValues;
     setThresholdData(thresholdDataCpy)
    }

 const handleAddThresholdsToServiceIds = () => {
    displayLoading({ title: "Adding thresholds to serviceId", bodyText: "Please standby" });
    const {selectedEnvValue} = props; 
    const serviceIdThresholdDetails = Object.values(thresholdData).map(data =>(
      {"serviceId":data.serviceId, "thresholdValues": data.thresholdValues.map(val => parseInt(val))}));
    const thresholdReq = {
    "teamId": selectedTeam.value,
    "teamName":selectedTeam.label,
    "environment": selectedEnvValue,
    "serviceIdThresholdDetails": serviceIdThresholdDetails
}
 const request = {
    "thresholdDetails":[thresholdReq]
 }
    props.addThresholdValuesToServiceIds(request).then((result) => {
      if (result.status === 200) {
        swal.close();
        toggle();
        swal.fire("Success", result.data.message, "success").then(() => {
          props.fetchThresholdDetailsMethod();
        })
      }else{
        swal.close();
        displayErrorAlert({
          title: "Error",
          subTitle: result?.httpStatus,
          errorMsg: result?.message
      });
      toggle();
      }
    });
  }

 const isValidData = () => {
    const {selectedEnvValue} = props; 
    let isValid = false;
    if (_.isEmpty(selectedServiceIds) || _.isEmpty(thresholdData) || _.isEmpty(selectedTeam) || _.isEmpty(selectedEnvValue) ) {
        isValid = true;
    }
    return isValid;
  }


  return (
    <Fragment>
      <Modal size="lg" isOpen={modal} toggle={toggle} backdrop="static" className={props.className} >
        <ModalHeader toggle={toggle}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              Assign Threshold Values To Service Ids
            </Grid>
            <Grid item>
              <p className='showEnvSection' style={{fontSize: "16px", lineHeight: "30px"}}><b>Environment:</b> {props.selectedEnvValue}</p>
            </Grid>
          </Grid>
        </ModalHeader>
        <ModalBody>
        <section>
        <div className="row">
                          <div className="offset-1 col-4">
                              <b>Team</b>
                          </div>
        </div>
        <div className="row">
                <div className="offset-1 col-4">
                  <Select
                      defaultValue={isEditModal ? selectedTeam.value : (selectedTeam.value === undefined ? fetchCurrentTeam(props.myTeamOptions, props.currentTeamId): selectedTeam)}
                      options={fetchTeamOptions(props.myTeamOptions)}
                      styles={customStyles}
                      placeholder={ "My Teams" }
                      onChange={onTeamChange}
                      value={selectedTeam.value === undefined ? fetchCurrentTeam(props.myTeamOptions, props.currentTeamId): selectedTeam}
                      disabled={isEditModal}
                    />
                </div>

      </div>
        { 
          Object.entries(thresholdData).map(([key, value]) =>(
              <><section>
                  <div>
                      <div className="row">
                          <div className="offset-1 col-4">
                              <b>Service Id</b>
                          </div>
                          <div className="col-4">
                              <b>Threshold Values</b>
                          </div>
                      </div>
                      <div className="row">
                          <div className="offset-1 col-4">
                              <Select
                                  options={activeServiceIdOptions(selectedServiceIds)}
                                  isClearable={true}
                                  styles={customStyles}
                                  placeholder={value.serviceId}
                                  onChange={(e) => handleinputchange(e, key)}
                                  value={value.serviceId}
                                  isLoading={props.allActiveServiceIds.pending}
                                  name={"serviceId"}
                                  label={value.serviceId}
                                  components={{
                                      IndicatorSeparator: () => null
                                  }}
                                   />
                              {_.isEmpty(selectedServiceIds) ? <FormHelperText>Select Service Id</FormHelperText>:<></>}
                          </div>
                          <div className="col-4">
                              <StyledChipInput
                                  
                                  type="number"
                                  name={"thresholdValues"}
                                  value={thresholdData[key].thresholdValues}
                                  onAdd={(chip) => handleAddChip(chip, key)}
                                  onDelete={(chip) => handleDeleteChip(chip, key)}
                                  blurBehavior='add'
                                  fullWidth={true}
                                  placeholder={'Enter Threshold Values'}
                                  allowDuplicates={false} />
                              { chipError && <FormHelperText error>{chipError}</FormHelperText>}
                          </div>
                          <div className="col-2">
                              <div className="row">
                                  {Object.values(thresholdData).length !== 0 &&
                                      <div className="offset-1 col-4">
                                          <Tooltip title={"Select a ServiceId & Add ThresholdValues to add further"}>
                                              <span>
                                                  <MuiButton size="small" style={{ "minWidth": "0px", "padding": "4px", "height": "100%", "width": "50%" }}
                                                      onClick={handleAddclick}
                                                  >
                                                      <AddIcon color="primary" fontSize="medium" />
                                                  </MuiButton>
                                              </span>
                                          </Tooltip>
                                      </div>}
                                  {Object.values(thresholdData).length > 1 &&
                                      <div className="col-4">
                                          <Tooltip title={"Clear filter"}>
                                              <span>
                                                  <MuiButton size="small"
                                                      style={{ "minWidth": "0px", "padding": "4px", "height": "100%", "width": "50%" }}
                                                      onClick={() => handleRemove(key)}
                                                  >
                                                      {<ClearIcon color="secondary" fontSize="medium" />}
                                                  </MuiButton>
                                              </span>
                                          </Tooltip>
                                      </div>}

                              </div>
                          </div>
                      </div>
                  </div>
              </section></>
          ) )}
        </section>
        </ModalBody>
        <ModalFooter>
          <Button
            className={"btn-team"}
            color="success"
            onClick={handleAddThresholdsToServiceIds}
            disabled={isValidData() }
          >
            <span className={"pxr-s"}>Submit</span>
            {<i className="fa fa-check"></i>}
          </Button>
          <Button color="danger" className={"btn-team"} onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}


const mapStateToProps = state => {
  return {
    myTeamOptions: state.auth.myAvailableTeams,
    allTeams: state.teams.teams.allTeams || [],
    allActiveServiceIds: state.noSqlTeams.serviceIdData.allServiceIds || [],
    selectedEnvValue: state.auth.selectedEnvValue,
      loggedInUser: state.auth.user
  }
  }
  const mapDispatchToProps = dispatch => {
  return {
    getTeamSource:(selectedEnvValue, selectedToggleButton, loggedInUser, allTeams) =>dispatch(getTeamSource(selectedEnvValue, selectedToggleButton, loggedInUser, allTeams)),
    fetchAllActiveServiceIds: () => dispatch(fetchAllActiveServiceIds()),
    addThresholdValuesToServiceIds: (request) => dispatch(addThresholdValuesToServiceIds(request)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddThresholdModal);