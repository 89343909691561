import {axiosTaasInstance as axios} from "../axios.config";
import config from '../config';
import * as types from './actionTypes';

export const authHeaders = (headers = {}) => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
        'X-Method-Type': 'METHOD_PORTAL',
        ...headers 
    }
}
const API_URL = config.api.uri;

export const updateFeedback= (update={})=>{
     
    return{
        type: types.UPDATE_FEEDBACK,
        payload:update
    }
}


export const addFeedBack=(feedback={})=>{
    return{
        type: types.FEEDBACK_FORM,
        payload: feedback
    }
}


export const fetchFeedbacks = ()=> async dispatch => {
    dispatch({ type: types.SELECT_DATA_FILTER_RESET });
    // api calls
    try{
            dispatch({type: types.LOAD_FEEDBACK_PENDING})
          const response= await axios.get(`${API_URL}/api/feedbackHistory`, {headers: authHeaders()});
          dispatch({
              type: types.LOAD_FEEDBACK,
              payload: response.data
          })
    } catch(e){
        console.log("e")
    }
    
}

export const submitFeedbackForm = (feedback)=> async dispatch => {
    try{
        console.log("feedbackForm action ", API_URL, "   ", feedback);
        dispatch({ type: types.SAVING_PENDING })
        axios.post(`${API_URL}/api/feedback`, feedback, {headers: authHeaders()}).then(()=>{
            console.log("form submiited");
            dispatch({ type: types.SAVING_SUCCESS })
            dispatch(fetchFeedbacks());
        });
    } catch(e){
        console.log(e)
    }
    
}

