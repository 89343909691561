import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, InputLabel } from '@material-ui/core';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { fetchDataSummary, checkoutDatasetData, allocateDatasetData } from '../../actions/retrieveDataActions';
import FilterDataset from "./FilterDataset";
import { displayLoading, displayErrorNew } from "../common/alerts/alerts";
import swal from 'sweetalert2';
import FilterDatasetByTags from "./FilterDatasetByTags";
import Select from "react-select";
import {
    FormTextArea,
} from "@library/core/form-elements";
import { fetchTags, fetchDataSummaryCheck } from '../../actions/retrieveDataActions';
import CircularProgress from '@mui/material/CircularProgress';

const AllocateDatasetModal = (props) => {
    const { allTeams, modalData } = props;
   if (!modalData) return null
    let targetOptions = allTeams.map(option => ({ value: option.teamId, label: option.teamName }));
    const { team } = modalData;
    const [currData, setCurrData] = useState(props.modalData);
    const [teamData, setTeamData] = useState(null);
    const [isError, setIsError] = useState(false);
    const [allocateComment, setAllocateCommentValue] = useState(null);
    
    const onChangeTeam = (data) => {
        setTeamData(data)
    }

    const [currDataFilters, setCurrDataFilters] = useState(
        { "datasetId": currData.id, "data": [], "tags": [] });
    const [errorText, setErrorText] = useState("You are requesting more number of data than what has been allocated to you, Please enter a lower value or check in more data")
    const [selectedQuantity, setSelectedQuantity] = useState("");
    const [DatasetTags, setDatasetTags] = useState(null);

    useEffect(()=>{
        props.fetchTags(modalData?.id).then((result)=>{
            setDatasetTags(result?.data);
        })
    },[])

    const handleDatasetFilters = (filters) => {
        displayLoading({ title: "Fetching Data", bodyText: "Please standby by while the data quantity is being updated as per the filter applied" });
        let datasetFilters = { "datasetId": currData.id, filters }
        setCurrDataFilters(datasetFilters);
        props.fetchDataSummaryCheck(datasetFilters, currData.id).then((response) => {
            if (response !== undefined && response.status == 200) {
                    swal.close();
                    let newObj={...currData};
                    newObj.quantity=response?.data?.quantity;
                    setCurrData(newObj)
            }
            else {
                let errorMsg = "Unable to fetch resources for the dataset, please try again later"
                displayErrorNew({
                    title: "Error",
                    subTitle: "Custom Data Bank Error",
                    errorMsg
                })
            }
        })
    }
    const handleDatasetTags = (tags) => {
        displayLoading({ title: "Fetching Data", bodyText: "Please standby by while the data quantity is being updated as per the filter applied" });
        let datasetFilters = { "datasetId": currData.id, "tags": tags };
        setCurrDataFilters(datasetFilters);
        props.fetchDataSummaryCheck(datasetFilters, currData.id).then((response) => {
            if (response !== undefined && response.status == 200) {
                swal.close();
                let newObj={...currData};
                newObj.quantity=response?.data?.quantity;
                setCurrData(newObj)
        }
        else {
            let errorMsg = "Unable to fetch resources for the dataset, please try again later"
            displayErrorNew({
                title: "Error",
                subTitle: "Custom Data Bank Error",
                errorMsg
            })
        }
        })
    }

    const handleQtyChange = (e, qty) => {
        if (e.target.value < 1) {
            setIsError(true)
            setErrorText("Minimum Quantity required for checkout is 1")
        }
        else {
            if (e.target.value > currData.quantity) {
                setIsError(true);
                setErrorText("You are requesting more number of data than what has been allocated to you, Please enter a lower value or check in more data")
            }
            else {
                setIsError(false);
                setErrorText("");
            }
        }
        setSelectedQuantity(e.target.value)
    }

    const handleAllocate = () => {
        const checkedInId = currData.id !== undefined ? currData.id : "";
        displayLoading({ title: "Checking out data", bodyText: "Please standby" });
        var checkoutData = {
            sourceTeamId: team.id, scope: "team", destTeam: {
                "id": Number(teamData.value),
                "name": teamData.label
            }, "filters": currDataFilters.data, "tags": currDataFilters.tags, "quantity": Number(selectedQuantity), allocationDesc: allocateComment
        };
        props.allocateDatasetData(checkoutData, checkedInId, props.closeModal );
    }

    const handleChangeAllocateComment = (newInput) => {
        setAllocateCommentValue(newInput.trimStart());
    }
  
    return (
        <Modal size="lg" isOpen={props.openPanel} backdrop="static"
            style={{ maxWidth: '600px', width: '100%' }}>
            <ModalHeader toggle={() => props.closeModal()} className="checkoutModal"
                cssModule={{ 'modal-title': 'w-100 text-center' }}><strong>Allocate Dataset</strong></ModalHeader>
                { DatasetTags ? 
                <ModalBody>
                <div class="container">
                    <div class="row filterRow pb-4">
                        <div class="row ml-2">
                            <h6 class={currData.quantity === 0 ? "customQtyErrorHeader" : "customQtyHeader"} style={{ marginBottom: '0px', fontSize: "16px" }}> <strong>Available Quantity :{currData.quantity}</strong></h6></div>
                        <br />
                        <br />
                        <div class="col-11" style={{marginLeft:"37px"}}>
                            <InputLabel className={'selectTeamHeadClass'} variant="standard" htmlFor="uncontrolled-native">
                                Target Teams
                            </InputLabel>
                            <Select
                                // key={`techTeam${techTeam && techTeam.value}`}
                                options={targetOptions}

                                isClearable={true}
                                className={'selectTeamClass'}
                                placeholder={"Choose any team"}
                                onChange={onChangeTeam}
                                // value={allTeams}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                            {teamData ? <>
                                <FilterDataset dataSetfilters={currData.filters}
                                    modalData={currData}
                                    handleDatasetFilters={handleDatasetFilters}></FilterDataset>
                                <FilterDatasetByTags modalData={currData}
                                    handleDatasetTags={handleDatasetTags}
                                    handleQtyChange={handleQtyChange}
                                    isError={isError}
                                    errorText={errorText}
                                    selectedQuantity={selectedQuantity}
                                    tags={DatasetTags}
                                />
                            </>
                                : null}
                        </div>
                        <div class="col-11">
                            {teamData ?
                                <FormTextArea
                                    className="formTextArea"
                                    label="Comments"
                                    id="allocateDescription"
                                    fullWidth
                                    value={allocateComment}
                                    onChange={(e) => handleChangeAllocateComment(e.target.value)}
                                    helperText={" "}
                                    autoComplete="off"
                                    rows={4}
                                />
                                : null}
                        </div>
                    </div>

                    <ModalFooter>
                        <div class="pt-1">
                            <Button style={{ background: "#74c687" }} variant="contained" color="primary"
                                disabled={(isError || selectedQuantity == "" || currData.quantity == 0) ? true : false}
                                onClick={e => handleAllocate()}>Allocate</Button>
                        </div>
                    </ModalFooter>

                </div>
            </ModalBody>
                :<CircularProgress style={{marginLeft:"185px"}}/>}  
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        allTeams: state.teams.teams.allTeams,
        classDetails: state.teams.classDetails,
        customDataBankDataset: state.retrieveData.customDataBankDataset,
        allocateTargetData: state.teams.allocateTargetData,

        tags: state.retrieveData.tags
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        fetchDataSummary: (filters,dataType,currentPage,batchSize) => dispatch(fetchDataSummary(filters,dataType,currentPage,batchSize)),
        checkoutDatasetData: (checkoutData, id, currData) => dispatch(checkoutDatasetData(checkoutData, id, currData)),
        allocateDatasetData: (checkoutData, id, closeModal) => dispatch(allocateDatasetData(checkoutData, id, closeModal)),
        fetchTags: (reqBody) => dispatch(fetchTags(reqBody)),
        fetchDataSummaryCheck: (reqBody,id) => dispatch(fetchDataSummaryCheck(reqBody,id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllocateDatasetModal);
