import {axiosInstance as axios} from '../axios.config';
import * as types from './actionTypes';
import config from '../config';
import { displayErrorNew } from "../components/common/alerts/alerts";
import { authHeaders } from '../utils/api';
import _ from "lodash";
import {
    displayLoading
  } from "../components/common/alerts/alerts";
  import swal from "sweetalert2";

const API_URL = config.api.uri;
const API_URL_GATEWAY = config.tdm_gateway_api.uri + '/tdm';
const ESTABLISH_CUSTOMER_API = config.establish_customer_api.uri;
const ESTABLISH_CUSTOMER_API_OSIT3 = config.establish_customer_api_osit3.uri;

export const selectUtilityFilter = (key, value) => dispatch => {

    dispatch({ type: types.SELECT_UTILITY_FILTER, key, value });
};


export const submitCheckoutRequest = (utilityRequest) => async dispatch => {
    try {
        dispatch({ type: types.UTILITY_CHECKOUT_REQUEST_PENDING, payload: true })

        const response = await axios.post(`${API_URL_GATEWAY}/orchestration/create/trigger/order/utility`, utilityRequest, { headers: authHeaders() });

        dispatch({ type: types.UTILITY_CHECKOUT_REQUEST_SUCCESS });
        return response.data;

    } catch (e) {
        let errorMsg = "";
        console.error(e)
        if (typeof e === "string") errorMsg = e;
        else if (e.response) errorMsg = e.response.data.responseDescription;
        else errorMsg = "Network connectivity issues, please try again later.";

        dispatch({ type: types.UTILITY_CHECKOUT_REQUEST_FAILED })
        displayErrorNew({
            title: "Checkout Error",
            subTitle: "An error occured while inserting your Utility data!",
            errorMsg
        })
    }
}

export const submitCheckoutRequestNBN = (utilityRequest) => async dispatch => {
    try {
        dispatch({ type: types.UTILITY_NBN_CHECKOUT_REQUEST_PENDING, payload: true })

        const response = await axios.post(`${API_URL_GATEWAY}/orchestration/create/nbn/order`, utilityRequest, { headers: authHeaders() });

        dispatch({ type: types.UTILITY_NBN_CHECKOUT_REQUEST_SUCCESS });
        return response.data;

    } catch (e) {
        let errorMsg = "";
        console.error(e)
        if (typeof e === "string") errorMsg = e;
        else if (e.response) errorMsg = e.response.data.responseDescription;
        else errorMsg = "Network connectivity issues, please try again later.";

        dispatch({ type: types.UTILITY_NBN_CHECKOUT_REQUEST_FAILED })
        displayErrorNew({
            title: "Checkout Error",
            subTitle: "An error occured while inserting your Utility data!",
            errorMsg
        })
    }
}
export const serachEstablishedCustomer = (customer,env) => async (dispatch) => {
    try{
        dispatch({ type: types.UTILITY_GET_ESTABLISHED_CUSTOMERS_PENDING, payload: true })
        if(env !== "OSIT3")
        {const response = await axios.get(`${ESTABLISH_CUSTOMER_API}${customer.customerId}`,{ headers: authHeaders() });
        dispatch({ type: types.UTILITY_GET_ESTABLISHED_CUSTOMERS_SUCCESS});
        return response.data}
        else
        {
         const response = await axios.get(`${ESTABLISH_CUSTOMER_API_OSIT3}${customer.customerId}`,{ headers: authHeaders() });
        dispatch({ type: types.UTILITY_GET_ESTABLISHED_CUSTOMERS_SUCCESS});
        return response.data
        };
    }catch(e){
        console.error(e)
        dispatch({ type: types.UTILITY_GET_ESTABLISHED_CUSTOMERS_SUCCESS});
        return undefined;
    }

}

export const addEstablishedCustomer = (customerDetailsRequest) => async dispatch =>{
    try{
        dispatch({ type: types.UTILITY_ADD_ESTABLISHED_CUSTOMERS_PENDING, payload: true })
        const response = await axios.post(`${API_URL_GATEWAY}/service-virtualization/svc-established/customer/add`, customerDetailsRequest,{ headers: authHeaders() });
        console.log('response',response);
        dispatch({ type: types.UTILITY_ADD_ESTABLISHED_CUSTOMERS_SUCCESS });
        return response.data;
    }catch(e){
        console.error(e)
        dispatch({ type: types.UTILITY_ADD_ESTABLISHED_CUSTOMERS_SUCCESS });
        return undefined;
    }
}

export const deleteEstablishedCustomer = (customer) => async dispatch =>{
   let customerAccountCode = {"customerAccountCode" : customer.customerId}
    try{
        dispatch({ type: types.UTILITY_DELETE_ESTABLISHED_CUSTOMERS_PENDING, payload: true })
        const response = await axios.post(`${API_URL_GATEWAY}/service-virtualization/svc-established/customer/delete`,customerAccountCode,{ headers: authHeaders() });
        console.log('response',response);
        dispatch({ type: types.UTILITY_DELETE_ESTABLISHED_CUSTOMERS_SUCCESS });
        return response.data;
    }catch(e){
        console.error(e)
        dispatch({ type: types.UTILITY_DELETE_ESTABLISHED_CUSTOMERS_SUCCESS });
        return undefined
    }
}

export const submitCompleteOrder = (utilityRequest) => async dispatch => {
    try {
        dispatch({ type: types.UTILITY_COMPLETE_ORDER_REQUEST_PENDING, payload: true })

        const response = await axios.post(`${API_URL_GATEWAY}/order/complete`, utilityRequest, { headers: authHeaders() });

        dispatch({ type: types.UTILITY_COMPLETE_ORDER_REQUEST_SUCCESS });
        return response.data;

    } catch (e) {
        let errorMsg = "";
        console.error(e)
        if (typeof e === "string") errorMsg = e;
        else if (e.response) errorMsg = e.response.data.responseDescription;
        else errorMsg = "Network connectivity issues, please try again later.";

        dispatch({ type: types.UTILITY_COMPLETE_ORDER_REQUEST_FAILED })
        displayErrorNew({
            title: "Submit Error",
            subTitle: "An error occured while completing your order!",
            errorMsg
        })
    }
}

export const customerCreditUpdate = (request) => async (dispatch) => {
    try {
        dispatch({ type: types.UTILITY_CREDIT_PENDING});
        const response = await axios.post(`${API_URL_GATEWAY}/siebeldata/siebeldata/update/customerCredit`, request, { headers: authHeaders() });
        if(response.data.responseStatus === "Success") {
            dispatch({ type: types.UTILITY_CREDIT_SUCCESS, payload: response.data.responseDescription});
        }
    } catch (error) {
        const payload =  _.get(error, 'response.data.responseDescription', '');
        dispatch({ type: types.UTILITY_CREDIT_FAILED, payload: payload})
        console.error(error);
    }
}
export const createRcrRequestDataId = (request) => async (dispatch) => {
    try {
        dispatch({ type: types.CREATE_RCR_REQUEST_ID_PENDING});
        const response = await axios.post(`${API_URL_GATEWAY}/u2c-service/rcr/create/requestid`, request, { headers: authHeaders() });
        if(response.data.responseStatus === "Success") {
            dispatch({ type: types.CREATE_RCR_REQUEST_ID_SUCCESS, payload: response.data});
            return response;
        }
  } catch (e) {
      let message;
      if (!e.response) {
        message = "Network connectivity issues, please try again later.";
      } else {
        message = e.response.data.responseDescription;
      }
    dispatch({ type: types.CREATE_RCR_REQUEST_ID_FAILED, payload: message})
      return message;
  }
}
export const triggerRcrProcess = (request) => async (dispatch) => {
    displayLoading({
        title: "Triggering Rcr process",
        bodyText: "Please standby whilst given inputs are being validated",
    });
    try {
        const response = await axios.post(`${API_URL_GATEWAY}/u2c-service/rcr/process/trigger`, request, { headers: authHeaders() });
        swal.close();
        return response.data;
  } catch (e) {
      let message;
      if (!e.response) {
        message = "Network connectivity issues, please try again later.";
      } else {
        message = e.response.data.responseDescription;
      }
      return message;
  }
}
export const searchRcrRequestDataId = (request) => async () => {
    displayLoading({
        title: "Searching Rcr Request Id",
        bodyText: "Please standby whilst given inputs are being validated",
    });
    try {
        const response = await axios.get(`${API_URL_GATEWAY}/salesforce/account/order/product?customerId=${request.cac}&env=${request.environment}`, { headers: authHeaders() });
        swal.close();
        return response.data;
    } catch (e) {
        let message;
        if (!e.response) {
            message = "Network connectivity issues, please try again later.";
        } else {
            message = e.response.data.responseDescription;
        }
        return message;
    }
}

export const checkRcrStatus = (batchId,request) => async (dispatch) => {
    let config = {
        headers: authHeaders(),
        params: request,
      }
    try {
        dispatch({ type: types.CHECK_RCR_STATUS_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/u2c-service/rcr/batchId/${batchId}`, config);
        const getResult=response.data;
        if(Object.keys(getResult).length>0){
            if (response.data.batchOrderDetails){
                 dispatch({ type: types.CHECK_RCR_STATUS_SUCCESS, payload: response.data.batchOrderDetails});
                }
                return response.data;
        }else{
            swal.fire({
                type: 'error',
                title: 'Fail',
                text: "Failed to retrieve status of Batch ID",
            })
        }
    } catch(error) {
        if (error.response && error.response.data.responseDescription) {
            dispatch({ type: types.CHECK_RCR_STATUS_FAILURE, payload: `Failed to retrieve status of Batch ID's - ${error.response.data.responseDescription}`})
        } else {
            dispatch({ type: types.CHECK_RCR_STATUS_FAILURE, payload: "Failed to retrieve status of Batch ID's"})
        }
    }
}
export const fetchRcrFile = (requestId) => async dispatch =>{
    try {
        const response = await axios.get(`${API_URL_GATEWAY}/u2c-service/rcr/download/${requestId}`, { headers: authHeaders() });
        return response.data;
} catch (e) {
 let errorMsg = "";
 if (typeof e === "string") errorMsg = e;
 else if (e.response) {
 if (e.response.status === 400) {
     errorMsg = e.response.data.responseDescription;
} else if (e.response.status === 401){
errorMsg = e.response.data.message;
 }
 } else {
 errorMsg =
 "An error occurred while downloading the File. Please try again after some time.";
 }
 displayErrorNew({
 title: "Fail",
 errorMsg,
 });
 return false
}
};

export const identityProfileUpdate = (request) => async (dispatch) => {
    try {
        dispatch({ type: types.UTILITY_IDENTITY_PROFILE_UPDATE_PENDING});
        const response = await axios.post(`${API_URL_GATEWAY}/caiman/account/add`, request, { headers: authHeaders() });
        if(response.data.responseStatus === "Success") {
            dispatch({ type: types.UTILITY_IDENTITY_PROFILE_UPDATE_SUCCESS, payload: response.data.responseDescription});
        } else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.UTILITY_IDENTITY_PROFILE_UPDATE_FAILED, payload: response.data})
        }
    } catch (error) {
        const payload =  _.get(error, 'response.data.responseDescription', '');
        dispatch({ type: types.UTILITY_IDENTITY_PROFILE_UPDATE_FAILED, payload: payload})
        console.error(error);
    }
}

export const clearSiebelCustomer = () => async (dispatch) => {
    dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_CONSOLE_CLEAR });
}

export const customerConsoleSelectRow = (customer) => async (dispatch) => {
    dispatch({ type: types.CUSTOMER_CONSOLE_SELECT_ROW, payload: customer });
}

export const displayFetchModel = () => async (dispatch) => {
    dispatch({ type: types.DISPLAY_USER_MODAL, payload: true });
}

export const findSiebelCustomer = (env, request) => async (dispatch) => {
    try {
        dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_CONSOLE_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/customer/fetchCustomerDetails/${request}`, { headers: authHeaders()});
        if (response.data.responseStatus === "Success") {
            dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_CONSOLE_SUCCESS, payload: response.data});
        } else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_CONSOLE_FAILED, payload: "Customer not found in "+ env +" siebel"});
        }
    } catch (error) {
        dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_CONSOLE_FAILED, payload: "Customer not found in "+ env +" siebel"});
        console.error(error);
    }
}

export const updateContactInSiebel = (env, request) => async (dispatch) => {
    try {
        dispatch({ type: types.UTILITY_SIEBEL_CONTACT_UPDATE_PENDING});
        const response = await axios.post(`${API_URL_GATEWAY}/customer/updateContactsInSiebel/${env}`, request, { headers: authHeaders()});
        if (response.data.responseStatus !== "Fail" && response.status === 200) {
            dispatch({ type: types.UTILITY_SIEBEL_CONTACT_UPDATE_SUCCESS, payload: "Seed & Sync Request submitted for selected Contact." });
        } else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.UTILITY_SIEBEL_CONTACT_UPDATE_FAILED, payload: "Failed to update Siebel Contact"});
        }
    } catch (error) {
        dispatch({ type: types.UTILITY_SIEBEL_CONTACT_UPDATE_FAILED, payload: "Error has occured during Seed & Sync, please try again later"});
        console.error(error);
    }
}

export const customerSync = (custId) => async (dispatch) => {
    try {
        dispatch({ type: types.CUSTOMER_SYNC_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/customer/job/start?customerId=${custId}`,{headers: authHeaders()});
        if (response.data.jobId && response.status === 200) {
            dispatch({ type: types.CUSTOMER_SYNC_SUCCESS, payload: {...response.data, custId: custId} });
        } else {
            dispatch({ type: types.CUSTOMER_SYNC_FAILED, payload: response.data.responseDescription ? {error: response.data.responseDescription, custId: custId} : {error: "Failed to Sync Customer, please try again later", custId: custId}});
        }
    } catch (error) {
        dispatch({ type: types.CUSTOMER_SYNC_FAILED, payload: error.response.data && error.response.data.responseDescription ? {error: error.response.data.responseDescription, custId: custId} : {error: "Error has occured during Customer Sync, please try again later", custId: custId}});
        console.error(error);
    }
}

export const customerSyncStatus = (custId, jobId) => async (dispatch) => {
    try {
        dispatch({ type: types.CUSTOMER_SYNC_STATUS_PENDING });
        const response = await axios.get(`${API_URL_GATEWAY}/customer/job/status?customerId=${custId}&jobId=${jobId}`,{headers: authHeaders()});
        if (response.data.status && response.status === 200) {
            dispatch({ type: types.CUSTOMER_SYNC_STATUS_SUCCESS, payload: {...response.data, custId: custId} });
        } else {
            dispatch({ type: types.CUSTOMER_SYNC_STATUS_FAILED, payload: error.response.data && error.response.data.responseDescription ?
                {error: `Error has occured while retrieving Customer Sync status: ${error.response.data.responseDescription}, please try again later`, custId: custId} :
                {error: "Error has occured while retrieving Customer Sync status, please try again later", custId: custId}
            });
        }
    } catch (error) {
        dispatch({ type: types.CUSTOMER_SYNC_STATUS_FAILED, payload: error.response.data && error.response.data.responseDescription ?
            {error: `Error has occured while retrieving Customer Sync status: ${error.response.data.responseDescription}, please try again later`, custId: custId} :
            {error: "Error has occured while retrieving Customer Sync status, please try again later", custId: custId}
        });
        console.error(error);
    }
}

export const updateUserIdentity = (request) => async (dispatch) => {
   try {
            dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_PENDING});
            const response = await axios.post(`${API_URL_GATEWAY}/caiman/account/add`,request, { headers: authHeaders()});
            if(response.data.responseStatus === "Success")
            dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_SUCCESS});
            if(response.data.responseStatus === "Error"  || response.data.responseStatus === "Fail")
            dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_FAILED, errorResponse:response.data.responseDescription});
    }
    catch(e)
    {
        dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_IDENTITY_UPDATE_FAILED,errorResponse:"Error while updating identity"});
    }
}
export const closeUpdateSuccess = () => async (dispatch) => {
    dispatch({ type: types.UTILITY_SIEBEL_CUSTOMER_IDENTITY_MODAL_CLOSE});
}

export const closeCaiman = () => async (dispatch) => {
    dispatch ({type: types.CLOSE_CAIMAN_MODAL});
}

export const registerCaiman = (data) => async (dispatch) => {
    try{
    dispatch({ type: types.UTILITY_SIEBEL_REGISTER_CAIMAN_PENDING})
    const response = await axios.post(`${API_URL_GATEWAY}/caiman/register`,data, { headers: authHeaders()});
    if(response.data.responseStatus === "Success")
    dispatch({ type: types.UTILITY_SIEBEL_REGISTER_CAIMAN_SUCCESS , userName:response.data.userName ,password:response.data.password})
    if(response.data.responseStatus === "Error"  || response.data.responseStatus === "Fail")
    dispatch({ type: types.UTILITY_SIEBEL_REGISTER_CAIMAN_FAILED , errorResponse:response.data.responseDescription})
    }
    catch(e)
    {
        dispatch({ type: types.UTILITY_SIEBEL_REGISTER_CAIMAN_FAILED , errorResponse:"Error while registering caiman"})
    }
}

export const customerCreation = (request) => async (dispatch) => {
    try {
        dispatch({ type: types.UTILITY_CUSTOMER_CREATION_PENDING })
            const response = await axios.post(`${API_URL_GATEWAY}/customer/add`, request, { headers: authHeaders()});

            if (response.data.responseStatus === "Success") {
                dispatch({ type: types.UTILITY_CUSTOMER_CREATION_SUCCESS, payload: response.data.customerId })
            } else if (response.data.responseStatus === "Fail") {
                dispatch({ type: types.UTILITY_CUSTOMER_CREATION_FAILED, payload: "Failed to create customer" })
            }
    } catch(error) {
        dispatch({ type: types.UTILITY_CUSTOMER_CREATION_FAILED, payload: "Error while creating customer" })
    }
}

export const fetchAryaFiles = () => async (dispatch) => {
    try {
        dispatch({ type: types.UTILITY_S2P_ARYA_FETCH_FILES_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/replicator/prodbid/fileTemplate`, { "filetype" : "txt" }, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            dispatch({ type: types.UTILITY_S2P_ARYA_FETCH_FILES_SUCCESS, payload: response.data });
        }
        else if (response.status !== 200) {
            dispatch({ type: types.UTILITY_S2P_ARYA_FETCH_FILES_FAILED, payload: "Failed to retrieve Arya files" });
        }
    } catch (error) {
        dispatch({ type: types.UTILITY_S2P_ARYA_FETCH_FILES_FAILED, payload: `Error while retrieving Arya file templates - ${error.response.data.responseDescription ? error.response.data.responseDescription : 'ERROR'}` });
    }
}

export const submitAryaFiles = (request, total, count) => async (dispatch) => {
    try {
        dispatch({ type: types.UTILITY_S2P_ARYA_SUBMIT_FILES_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/replicator/prodbid/placefile`, request, { headers: authHeaders()});
        if (response.data.responseStatus === "SUCCESS") {
            dispatch({ type: types.UTILITY_S2P_ARYA_SUBMIT_FILES_SUCCESS, payload: response.data.responseDescription })
            if (total === count) {
                dispatch({ type: types.UTILITY_S2P_ARYA_SUBMIT_FILES_FINISHED, payload: true})
                return true;
            }
        } else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.UTILITY_S2P_ARYA_SUBMIT_FILES_FAILED, payload: "Failed to submit Arya file" })
            if (total === count) {
                dispatch({ type: types.UTILITY_S2P_ARYA_SUBMIT_FILES_FINISHED, payload: true})
            }
            return false;
        }
    } catch (error) {
        dispatch({ type: types.UTILITY_S2P_ARYA_SUBMIT_FILES_FAILED, payload: `Failed while submitting Arya Files - ${error}`});
        if (total === count) {
            dispatch({ type: types.UTILITY_S2P_ARYA_SUBMIT_FILES_FINISHED, payload: true});
        }
        return false;
    }
}

export const retrieveSalesforceTables = (tableName) => async (dispatch) => {
    try {
        dispatch({ type: types.UTILITY_S2P_RETRIEVE_TABLES_PENDING })
        const response = await axios.get(`${API_URL_GATEWAY}/replicator/spark/table?table=${tableName}`, { headers: authHeaders()});
        if (response.status === 200 && response.data) {
            dispatch({ type: types.UTILITY_S2P_RETRIEVE_TABLES_SUCCESS, payload: response.data })
        }
        else if (response.status !== 200) {
            dispatch({ type: types.UTILITY_S2P_RETRIEVE_TABLES_FAILED, payload: "Failed to retrieve table data" })
        }
    } catch (error) {
        dispatch({ type: types.UTILITY_S2P_RETRIEVE_TABLES_FAILED, payload: `Error while retrieving table data - ${error}`})
    }
}

export const submitSalesforceTables = (table, request, total, count) => async (dispatch) => {
    try {
        dispatch({ type: types.UTILITY_S2P_SUBMIT_TABLES_PENDING })
        const response = await axios.post(`${API_URL_GATEWAY}/replicator/spark/table?table=${table}`, request, { headers: authHeaders()});
        if (response.data.responseStatus === "success") {
            dispatch({ type: types.UTILITY_S2P_SUBMIT_TABLES_SUCCESS, payload: response.data.responseDescription })
            if (total === count) {
                dispatch({ type: types.UTILITY_S2P_SUBMIT_TABLES_FINISHED, payload: true})
                return true;
            }
        } else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.UTILITY_S2P_SUBMIT_TABLES_FAILED, payload: "Failed to submit table data" })
            if (total === count) {
                dispatch({ type: types.UTILITY_S2P_SUBMIT_TABLES_FINISHED, payload: true})
            }
            return false;
        }
    } catch (error) {
        dispatch({ type: types.UTILITY_S2P_SUBMIT_TABLES_FAILED, payload: "Error while submitting table data"})
        if (total === count) {
            dispatch({ type: types.UTILITY_S2P_SUBMIT_TABLES_FINISHED, payload: true})
        }
        return false;
    }
}

export const clearMsisdnResponse = () => async (dispatch) => {
    dispatch({ type: types.MSISDN_CLEAR });
}

export const submitMsisdnLoad = (request) => async (dispatch) => {
    try {
        dispatch({ type: types.MSISDN_LOAD_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/mobile-preseed/preseed/msisdn`, request, { headers: authHeaders()});
        if (response.data.responseStatus === "Success") {
            dispatch({ type: types.MSISDN_LOAD_SUCCESS, payload: response.data.batchId});
        } else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.MSISDN_LOAD_FAILURE, payload: response.data});
        }
    } catch(error) {
        if (error.response && error.response.data) {
            dispatch({ type: types.MSISDN_LOAD_FAILURE, payload: error.response.data});
        } else {
            dispatch({ type: types.MSISDN_LOAD_FAILURE, payload: error});
        }
    }
}

export const submitSimImsiPreseeding = (request) => async (dispatch) => {
    try {
        dispatch({ type: types.SIM_IMSI_PRESEEDING_PENDING })
        const response = await axios.post(`${API_URL_GATEWAY}/mobile-preseed/preseed/imsi`, request, { headers: authHeaders()})
        if (response.data.responseStatus === "Success") {
            dispatch({ type: types.SIM_IMSI_PRESEEDING_SUCCESS, payload: response.data.batchId});
        } else if (response.data.responseStatus === "Fail") {
            dispatch({ type: types.SIM_IMSI_PRESEEDING_FAILURE, payload: response.data});
        }
    } catch(error) {
        if (error.response && error.response.data) {
            dispatch({ type: types.SIM_IMSI_PRESEEDING_FAILURE, payload: error.response.data});
        } else {
            dispatch({ type: types.SIM_IMSI_PRESEEDING_FAILURE, payload: error});
        }
    }
}

export const updatePhysicalSimMisdnList = (list) => async (dispatch) => {
    dispatch({ type: types.SET_MSISDN_LIST, list});
}

export const updatePhysicalSimList = (list) => async (dispatch) => {
    dispatch({ type: types.SET_SIM_LIST, list});
}

export const uploadAndParsePhysicalSimImsiFile = (data) => async dispatch => {
    try {
      dispatch({ type: types.PHYSICAL_SIM_FILE_UPLOAD_LOADING});
      const response = await axios.post(`${API_URL_GATEWAY}/checkin/upload/physicalsim/file`,data,{ headers: authHeaders() });
        dispatch({ type: types.PHYSICAL_SIM_FILE_UPLOAD_SUCCESS, payload : response.data});
        return response.data;
      }catch (error) {
          console.log(error)
          const description = _.get(error, 'response.data.responseDescription', '');
          const status =  _.get(error, 'response.data.status', '');
          const payload={
              errorDescription : description ? description : _.get(error, 'response.data.error', ''),
              status :status ? status : _.get(error, 'response.data.status', '')
          }
          console.log(payload)
          dispatch({ type: types.PHYSICAL_SIM_FILE_UPLOAD_ERROR, payload : payload});
          return payload;
      }
  }

export const checkBatchIdStatus = (request) => async (dispatch) => {
    try {
        dispatch({ type: types.CHECK_BATCH_ID_STATUS_PENDING });
        const response = await axios.post(`${API_URL_GATEWAY}/mobile-preseed/preseedstatus/simstatus`, request, { headers: authHeaders()});
        if (response.data.responseStatus === "Success") {
            dispatch({ type: types.CHECK_BATCH_ID_STATUS_SUCCESS, payload: response.data.Status})
        } else if (response.data.responseStatus === "Fail") {
            if (response.data.responseDescription) {
                dispatch({ type: types.CHECK_BATCH_ID_STATUS_FAILURE, payload: response.data.responseDescription})
            } else {
                dispatch({ type: types.CHECK_BATCH_ID_STATUS_FAILURE, payload: "Failed to retrieve status of Batch ID's"})
            }
        }
    } catch(error) {
        if (error.response && error.response.data.responseDescription) {
            dispatch({ type: types.CHECK_BATCH_ID_STATUS_FAILURE, payload: `Failed to retrieve status of Batch ID's - ${error.response.data.responseDescription}`})
        } else {
            dispatch({ type: types.CHECK_BATCH_ID_STATUS_FAILURE, payload: "Failed to retrieve status of Batch ID's"})
        }
    }
}
export const fetchAddressDetails = (tagID, filter = []) => async (dispatch) => {
    
    try {
        dispatch({
            type: types.UTILITY_FETCH_ADDRESS_PENDING
        });
        let axiosConfig = {headers: authHeaders()};
        if(filter.length){
           axiosConfig['params'] = {filter : filter.join(",")}
        }
        const response = await axios.get(`${API_URL_GATEWAY}/address/address/` + tagID, axiosConfig);
        console.log(response);
        if (response.status === 200) {
            dispatch({
                type: types.UTILITY_FETCH_ADDRESS_SUCCESS,
                payload: response
            })
        } else {
            dispatch({
                type: types.UTILITY_FETCH_ADDRESS_FAILED,
                payload: "Failed to retrieve table data"
            })
        }
    } catch (e) {
        dispatch({
            type: types.UTILITY_FETCH_ADDRESS_FAILED,
            payload: "Error retrieving table data"
        })
    }
}

export const resetAddressDetails = () => (dispatch) => {
    dispatch({
        type: types.UTILITY_FETCH_ADDRESS_RESET
    })
}

export const submitChargeCycle = (data) => async (dispatch) => {
    try{
        const response = await axios.post(`${API_URL_GATEWAY}/u2c-service/rcr/chargecycle` , data, { headers: authHeaders()});
        return response
    }
    catch(e)
    {
        console.error(e)
        let errorResponse = {status : 400 , data : {responseDescription : ""}}
        if (e.response && e.response.data.responseDescription) {
             errorResponse.status = 404;
             errorResponse.data.responseDescription = e.response.data.responseDescription;
        }
        else
        {
            errorResponse.data.responseDescription = "Unable to update charge cycle, please try again later"
        }
        return errorResponse;
            

        
    }
}
