import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import Select, { components } from 'react-select';
import swal from 'sweetalert2';
import config from "../../../../config";
import {ssoSignin} from "../../../../actions/ssoAuthActions";
import { fetchSfProfiles } from '../../../../actions/profileActions';
import { is1TdmUser } from '../../../../utils/userProfile';
import {getTokenFromCookie} from "../../../../utils/browser"

class TeamSelector extends Component {


  constructor(props) {
    super(props);
  }

  profilesStyles = {
    valueContainer: (provided) => ({
      ...provided,
      display: "block",
      width: "180px",
      paddingLeft: 30,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: ".8rem",
      ...this.flexStyles
    })
  }

  flexStyles = {
    padding: '5px',
    display: 'flex',
    alignItems: 'center'
  };

  iconStyle = {
    color: 'lightblue'
  }

  handleChange(element) {
    if (element.value !== this.props.auth.team) {
      const usernameOrEmail = this.props.auth.userName;
      swal({
        title: 'Team Change Confirmation',
        html: `<b>Do you wish to change to team </b>${element.label}?<br />`,
        type: 'info',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> No, I wish to be here',
        confirmButtonColor: '#13a129',
        confirmButtonText: '<i class="fa fa-check" aria-hidden="true"></i> Yes',
        reverseButtons: true,
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          if(is1TdmUser()){
            const reqBody ={
              usernameOrEmail : usernameOrEmail ,
              team:  element.value
            }
            const reqHeader = getTokenFromCookie('TaaSAccessToken');
              this.props.ssoSignin(reqBody,reqHeader).then(result =>{
                if(result){
                  this.props.fetchProfiles();
                  swal({
                    type: 'success',
                    title: 'Your team change has been saved',
                    showConfirmButton: false,
                    timer: 1500
                  })
                }
                else{
                  swal({
                    type: 'error',
                    title: this.props.errorMessage,
                    showConfirmButton: true,
                  })
                }
            }); 
          }
      }
    })
  }
}


  isOptionSelected = ({ teamId }) => {
    return teamId == this.props.auth.team;
  }

  teamsOptions = (team) => {
    if (!team) return null;
    return { value: team.teamId, label: team.teamName, isSelected: this.isOptionSelected(team) };
  }

  render() {

    const teams = this
      .props
      .listOfTeams
      .map(teams => this.teamsOptions(teams));

    const activeTeam = teams.filter(option => option.isSelected);

    return (
      <span>
        {<Select

          options={teams}
          className="mt-100 col-md-50 col-offset-10"
          placeholder={activeTeam.length !== 0 ? activeTeam[0].label : ''}
          isLoading={this.props.loading}
          value={activeTeam.length !== 0 ? activeTeam[0].label : ''}
          styles={this.profilesStyles}
          onChange={this.handleChange.bind(this)}
          isSearchable={false}
          noOptionsMessage={() => "No Teams available"}
          defaultValue={activeTeam.length !== 0 ? activeTeam[0].label : ''}
        />
        }
      </span>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { available, active, loading, auth } = state.profiles;

  return {
    profiles: Object.values(available),
    active,
    selected: available[active],
    loading: loading,
    auth: state.auth,
    listOfTeams: state.auth.myAvailableTeams,
    errorMessage: state.auth.errorMessage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ssoSignin: (reqBody,reqHeader) => dispatch(ssoSignin(reqBody,reqHeader)),
    fetchProfiles: () => dispatch(fetchSfProfiles())
  };
};







export default connect(mapStateToProps, mapDispatchToProps)(TeamSelector);