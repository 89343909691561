import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Stepper as MuiStepper } from '@material-ui/core/';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SubmitButton from '@library/core/buttons/SubmitButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  stepper: {
    background: 'none',
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  marginShift: {
    marginLeft: "790px !important"
  },
  marginShiftCheckin: {
    marginLeft: " 760px !important"
  },
  marginShiftFilter:{
    marginLeft:'698px !important'
  }
}));

const ActionStepper = (props) => {
  const classes = useStyles();
  const { 
    steps, 
    stepValidationList = [],
    submitFunction,
    error, 
    setDisplayErrorOnNext, 
    stepsComponents,
    resetToSpecificStep, resetSteps, resetCallBack, // all required to enable reset to step 1 externally
    nextStepBtns = [],
    finalBtnText = 'Finish',
    currentStepCallback
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [isError, setIsError] = useState(false);
  const [stepBack, setStepBack] = useState(false);
  const lastStep = steps.length - 1;

  useEffect(() => {
    if (currentStepCallback) {
      currentStepCallback(activeStep);
    }
  }, [activeStep]);

  useEffect(() => {
    if (resetSteps && resetCallBack) {
      setActiveStep(resetToSpecificStep ? resetToSpecificStep : 0);
      resetCallBack();
    }
  }, [resetSteps]);

  const handleNext = (err) => {
    setStepBack(false);
    if (!err) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  }

  const validate = async () => {
    let errorBool = false;
    if ((stepValidationList.length > 0) && 
        stepValidationList.length >= activeStep && 
        stepValidationList[activeStep]
    ) {
      let validate = await stepValidationList[activeStep]();
      if (validate && !error) {
        if (setDisplayErrorOnNext !== undefined) { 
          setDisplayErrorOnNext(false);
        }
      } else {
        if (setDisplayErrorOnNext !== undefined)
        setDisplayErrorOnNext(true);
        errorBool = true;
      }
    } else {
      if (!error) {
        if (setDisplayErrorOnNext !== undefined)
        setDisplayErrorOnNext(false);
      } else {
        if (setDisplayErrorOnNext !== undefined)
        setDisplayErrorOnNext(true);
        errorBool = true;
      }
    }
    setIsError(errorBool);
    return errorBool;
  }

  const handleBack = () => {
    setStepBack(true);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleReset = () => {
    setActiveStep(0);
    if(props.customDataStepper)
      {props.clearExcel();}
  }

  const handleEnableBack = () => {
    if (activeStep === 0) { props.enableBackHandler(); }
    else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  }

  const stepBtnText = () => {
    return (nextStepBtns.length > 0 && nextStepBtns[activeStep]) ? nextStepBtns[activeStep].name : "Next"
  }

  const onSubmitDo = async () => {
    if (activeStep === lastStep) {
      submitFunction ? submitFunction() : handleReset();
    } else {
      if (nextStepBtns.length > 0 && nextStepBtns[activeStep] && nextStepBtns[activeStep].submitFunc) {
        await validate().then(async (err) => {
          if (!err) {
            await new Promise(resolve => {
              nextStepBtns[activeStep].submitFunc();
              resolve();
            });
          }
        });
      } else {
        await validate().then((err) => {
          handleNext(err);
        });
      }
    }
  }
 
  return (
    <>
      <MuiStepper className={classes.stepper} activeStep={activeStep} open alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel><Typography>{label}</Typography></StepLabel>
          </Step>
        ))}
      </MuiStepper>
      {stepsComponents[activeStep]}
      {
        <div className={classes.buttons}>
          <>
            {
              props.enableBack !== undefined && props.enableBack === true ? 
              <SubmitButton onSubmit={handleEnableBack}>
                Back
              </SubmitButton>
              :
              <SubmitButton disabled={activeStep === 0} onSubmit={handleBack}>
                Back
              </SubmitButton>
            }
          </>
          <SubmitButton
            onSubmit={onSubmitDo}
          >
            {activeStep === lastStep ? finalBtnText : stepBtnText()}
          </SubmitButton>
        </div>
      }
    </>
  );
};

ActionStepper.propTypes = {
  stepsComponents: PropTypes.array.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  resetSteps: PropTypes.bool,
  resetCallBack: PropTypes.func,
  submitFunction: PropTypes.func,
  enableBackHandler: PropTypes.func,
  nextStepBtns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    submitFunc: PropTypes.func
  }))
}

export default ActionStepper;
