import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { grey } from "@material-ui/core/colors";
import { Typography, makeStyles } from "@material-ui/core";
const borderColor = grey[300];
import React, { useState, useEffect, Fragment } from "react";

const useStyles = makeStyles((theme) => ({
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));
const ContentPageComponent = (props) => {
    const [expanded, setExpanded] = React.useState(true);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const classes = useStyles();
    return <Card className={classes.root}>
        <CardHeader
            title={<Fragment><InfoOutlinedIcon fontSize="large" color="primary" /> Salesforce Search Utility (v1)</Fragment>}
        />
        <CardContent>
            <Typography id="initialParagraph" gutterBottom={false}>
            The TDM team has accomplished the development of an exceptionally effective Utility tool, which empowers users to swiftly and precisely search for data within salesforce. This tool utilizes intelligent filters to fetch the relevant data according to the user's specific requirements. By utilizing this Utility, users can effortlessly apply smart filters and retrieve the processed data within minutes. The TDM team's accomplishment in this endeavor is attributed to their utilization of salesforce API's and the implementation of SOQL for efficient data mapping.
            </Typography>

            
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent style={{ "padding": "0px 16px 0px 16px" }}>
                    <Typography paragraph><br /><b>Utility Best Practices:</b></Typography>
                    <Typography paragraph gutterBottom={false} style={{ "marginBottom": "0px" }}>
                        <b>1.</b> The "Accounts" category will provide all data during the initial search, and relevant data will be displayed through tab navigation.
                    </Typography>
                    <Typography paragraph gutterBottom={false} style={{ "marginBottom": "0px" }}>
                        <b>2.</b> Filters can be applied to retrieve the relevant mapped data, and moving between tabs will retain the applied filters and retrieve joined data between entities.
                    </Typography>
                    <Typography paragraph gutterBottom={false} style={{ "marginBottom": "0px" }}>
                        <b>3.</b> It is important to be aware that any NULL records will be excluded during the search process.
                    </Typography>
                    <br />
                
                    <Typography paragraph><b>Recommendation's:</b></Typography>
                    <Typography paragraph gutterBottom={false} style={{ "marginBottom": "0px" }}>
                    Given that there are no limitations or restrictions on the userID or teamID, you will have access to all the data from Salesforce. Therefore, when you retrieve the data and intend to utilize it, we strongly advise you to establish contact with the data owner. The owner's information can be found in the Salesforce application by navigating through TDM.
                    </Typography>
                    <div style={{ "width": "100%", "textAlign": "center", "color": "#e44d13" }}>
                        <FormControlLabel control={<Checkbox checked={!props.contentPage} onChange={() => props.setContentPage(!props.contentPage)} name="terms" />} labelPlacement="end" label="I acknowledge that I have read and understood the content mentioned above, and I am committed to implementing the recommended actions." />
                    </div>
                </CardContent>    
            </Collapse>
        </CardContent>
        <CardActions disableSpacing>
            <IconButton
                className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                })}
                id="expandInfoBtn"
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
            >
                <ExpandMoreIcon />
            </IconButton>
        </CardActions>
    </Card>
}

export default ContentPageComponent;