import React from "react";
import PropTypes from "prop-types";

import { TableCell, Box } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const borderColor = grey[300];
const useTableBodyStyle = makeStyles({
  body: {
    borderTop: `1px solid ${borderColor}`,
    borderBottom: `none`,
  },
});

const defaultAlignment = "center";

const TableBodyCell = (props) => {
  const { children, align = defaultAlignment, ...rest } = props;

  const tableBodyStyle = useTableBodyStyle();

  return (
    <TableCell variant="body" align={align} classes={tableBodyStyle} {...rest}>
      <Box pl={align === "left" ? 10 : 0}>{children}</Box>
    </TableCell>
  );
};

TableBodyCell.propTypes = {
  align: PropTypes.oneOf(["left", "center"]),
};

TableBodyCell.defaultProps = {
  align: "center",
};

export default TableBodyCell;
