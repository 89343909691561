import React from "react";
import TeamDataTableRow from "./TeamDataTableRow";

export default ({ teamData, teamId, isAdmin, currentTeamId, isStandardToggle }) => {
    return (
        <React.Fragment>
            {
                teamData.map((tableRowData, index) => {
                    return <TeamDataTableRow
                        key={index}
                        tableRowData={tableRowData}
                        isAdmin={isAdmin}
                        teamId={teamId}
                        currentTeamId={currentTeamId}
                        isStandardToggle={isStandardToggle}
                    />
                })}
        </React.Fragment>
    )
}
