import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import _ from 'lodash';

export default class CheckoutModal extends Component {
    constructor(props) {
        super(props);
        // "pending" == pending 0 == current 1 == complete
        this.state = { ...this.INITIAL_STATE };
    }

    INITIAL_STATE = {
        modal: false,
        states: {

        },
        option: {}
    }

    // getStartStates(type, className) {
    //     // defines the steps and their starting values depending on the data type

    //     // undefined = not a step for the data type - do not display
    //     // 0 = currrently active step
    //     // 1 = completed step
    //     // "pending" = pending step
    //     const IS_ORDER = (type == "Order");
    //     const IS_CUSTOMER = (type == "Customer");
    //     // storing for TB_Unmanaged
    //     const IS_CUSTOMER_TB_UNMANAGED = (type === "Customer" && className === "TB Unmanaged");
    //     const IS_MICA = (type === "Order" && className === "Postpaid-MICA");
    //     return {
    //         states: {
    //             ...(IS_ORDER && !IS_MICA && { order: "pending" }),

    //             ...((IS_ORDER || IS_CUSTOMER) && { customer: "current" }),
    //             ...(IS_ORDER && { billing: "pending" }),
    //             ...(IS_ORDER && !IS_MICA && { service: "pending" }),
    //             ...(IS_ORDER && !IS_MICA && { number: "pending" }),
    //             ...(IS_ORDER && !IS_MICA && { serviceability: "pending" }),
    //             ...(IS_ORDER && IS_MICA && { postpaidMica: 'pending' }),
    //             ...({ submit: "pending" }),
    //             ...(IS_CUSTOMER_TB_UNMANAGED && { customer_tb_unmanaged: "pending" }),
    //         }
    //     }
    // }

    // setCloseStates() {
    //     const keys = Object.keys(this.state.states);
    //     // set all to completed except last step
    //     for (let index = 0; index < (keys.length - 1); index++) {
    //         this.updateState(keys[index], "complete");
    //     }
    //     this.updateState(keys[(keys.length - 1)], "current");
    // }

    timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // getRandomInt = (min, max) => {
    //     return Math.floor(Math.random() * (max - min + 1) + min);
    // }

    // startUI = async () => {
    //     const keys = Object.keys(this.state.states);
    //     for (let index = 0; index < keys.length; index++) {
    //         if (!this.state.modal || keys[keys.length - 1] == "complete")
    //             break; // stop ui if modal is closed or last step is completed
    //         this.updateState(keys[index], "current");
    //         this.updateState(keys[index - 1], "complete");
    //         const waitMs = this.getRandomInt(5500, 9000);
    //         console.log(`${waitMs}ms simulated time for ${keys[index]}`)
    //         await this.timeout(waitMs);
    //     }
    // }

    openModal = (env, option, qty) => {
        this.setState({
            // ...this.getStartStates(option.dataType, option.className),
            modal: true,
            option,
            env
        });
        //const uiFlow = this.startUI();
        const checkout = this
            .props
            .confirm(env, option.id, qty);
    }

    close = async () => {
        const completed = (this.state.states.submit == "current");
        if (!completed) {
            // the UI hasnt finished its steps, go straight to last step and wait 1s for
            // user experience.
            //this.setCloseStates();
            await this.timeout(1000);
        };

        this.setState({
            ...this.INITIAL_STATE
        });
        console.warn("UI FINISHED: " + completed);
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    getClass = (state) => {
        switch (state) {
            case "pending":
                return "lead text-center text-muted";
            case "current":
                return "lead text-center text-primary font-weight-bold";
            case "complete":
                return "lead text-center text-success font-weight-bold";
        }
    }

    getIconClass = (state) => {
        switch (state) {
            case "current":
                return "fa fa-circle-o-notch fa-spin fa-fw text-primary";
            case "complete":
                return "fa fa-check text-success";
            default:
                return "";
        }
    }

    updateState = (key, value) => {
        this.setState({
            states: {
                ...this.state.states,
                [key]: value
            }
        });
    }

    // renderStep = (value, text) => {
    //     return (
    //         <p className={this.getClass(value)}>{text}{'  '}<i className={this.getIconClass(value)} /></p>
    //     )
    // }

    secondaryLoadingIcon = () => {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="loader">
                            <div className="loader-inner">
                                <div className="loading one"></div>
                            </div>
                            <div className="loader-inner">
                                <div className="loading two"></div>
                            </div>
                            <div className="loader-inner">
                                <div className="loading three"></div>
                            </div>
                            <div className="loader-inner">
                                <div className="loading four"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        const { dataType, className } = this.state.option;
        return (
            <div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className}
                    centered={true}
                    backdrop="static">
                    <ModalHeader>{this.props.title}{'  '}
                        -{'  '}{dataType}{'  '}
                        - {'  '}{className}{'  '}</ModalHeader>
                    <ModalBody>
                        <div className="loadingIconWrapper"><div className="container">

                            <svg className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                                <circle cx="170" cy="170" r="160" stroke="#E2007C" />
                                <circle cx="170" cy="170" r="135" stroke="#404041" />
                                <circle cx="170" cy="170" r="110" stroke="#E2007C" />
                                <circle cx="170" cy="170" r="85" stroke="#404041" />
                            </svg>

                        </div>
                        </div>


                    </ModalBody>
                </Modal>
            </div>
        )
    }
};
