import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useScrollDirection } from 'react-use-scroll-direction';

import { setCustomOrder} from '../../actions/orderBuilderActions';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { isAtOrBeyondScrollLocationOnScreen } from '../../utils/browser'

const useStyles = makeStyles({
  customSnackBar: {
    '& .MuiSnackbarContent-root': {
      backgroundImage: "linear-gradient(to right, #3f51b5 0%, #3f51b5 80%, #3f51b5 100%) !important",
      color: "#ffffff  !important",
      marginLeft: "20px !important",
      minWidth : "200px !important",
      minHeight: "50px",
      cursor: "pointer"
    }
  },

});

const OrderScroll = (props) => {
  const classes = useStyles();
  const { isScrollingUp, isScrollingDown } = useScrollDirection();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const bottom = isAtOrBeyondScrollLocationOnScreen(0.7);
    bottom ? setOpen(true) : setOpen(false);
  }, [isScrollingDown, isScrollingUp])

  React.useEffect(() => {
    const bottom = isAtOrBeyondScrollLocationOnScreen(0.7);
    bottom ? setOpen(true) : setOpen(false);
  },[]);

  const handleCustomOrder = () => {
      setOpen(false);
      props.setCustomOrder(true)
  }

  return (
    <>
      <Tooltip title="Please click here to customise an order">
        <Snackbar
          open={true}
          classes={{ root: classes.customSnackBar }}
          autoHideDuration={100000}
          message="Didn't find a match?"
          onClick={handleCustomOrder}
        />
      </Tooltip>
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
    return {
        setCustomOrder:(value) => dispatch(setCustomOrder(value))
    }
  }
export default connect(null, mapDispatchToProps)(OrderScroll);