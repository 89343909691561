import React, { Component } from 'react'
import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #4a00b3 0, #4c00b3 100%)'
}

const StatisticsTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-green"
        data-size="medium"
        to="/tdmStats"
        disabled={props.disabled}
        data-effect="hover-zoom-up">
        <div class="slide-front">
            <span className="fa mif-chart-bars icon"></span>
            <span className="branding-bar">Statistics</span>
        </div>
        <div
            class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
            <p class="text-center">
                {props.disabled ? "Coming Soon" : "View Statistics"}
            </p>
        </div>
    </LinkTile>
)

StatisticsTile.propTypes = {}

export default StatisticsTile;