import { useState, useEffect } from "react";
import { connect } from "react-redux";
//import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import {
    Grid,
    Typography
   
  } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {submitBulkOrder, fetchReferenceData} from '../../../actions/timMigrationActions'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Close from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import AccordionDetails from '@mui/material/AccordionDetails';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import swal from "sweetalert2";
import { displayLoading } from "../../common/alerts/alerts";

const TimMigrationDataForm = (props) => {
    const [accountUuid, setAccountUuid] = useState(null);
    const [accountUuidError, setAccountUuidError] = useState(false);
    const [groupAssetId, setGroupAssetId] = useState(null);
    const [groupAssetIdError, setGroupAssetIdError] = useState(false);
    const [billCycleDate, setBillCycleDate] = useState(null);
    const [billCycleDateError, setBillCycleDateError] = useState(null)
    const [anniversaryDate, setAnniversaryDate] = useState(null);
    const [anniversaryDateError, setAnniversaryDateError] = useState(null)
    const [chargeModel, setChargeModel] = useState({value :'CIP',label :'CIP'});
    const [chargeModelError, setChargeModelError] = useState(null)
    const [expandCommonFields, setExpandCommonFields] = useState(true);
    const [expandFormFields, setExpandFormFields] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [indexValue, setIndexValue] = useState(0);
    const [formCount, setFormCount] = useState(0);
    const [formArray,setFormArry] = useState([]);

    useEffect(()=>{
        props.fetchReferenceData();
      },[])

    var formField = 
        {
            "migrationType":"",
            "migrationTypeError":false,
            "callingType":"Show My Number",
            "callingTypeError":false,
            "listingType":"Unlisted",
            "listingTypeError":"",
            "serviceCode":"",
            "serviceCodeError":false,
            "plan":"",
            "planError":false,
            "serviceId":"",
            "serviceIdError":false,
            "serviceIdErrorMsg":"",
            "simSerialNumber":"",
            "simSerialNumberError":false,
            "simSerialNumberErrorMsg":"",
            "billCycleDate":"",
            "priceAdjustmentValue":"",
            "priceAdjustmentValueError":false,
            "duration":"",
            "durationError":false,
            "planList":[]
        };
    const serviceCodeList =props.fetchReferenceDataRecord ? [{
        value :'',
        label :''
    },...props.fetchReferenceDataRecord.serviceCodes] : [];
    
    const listingTypeList = [
        {
            value :'Unlisted',
            label :'Unlisted'
        },
        {
            value :'Listed',
            label :'Listed'
        },
    ]
    const callingTypeList = [
        {
            value :'Show My Number',
            label :'Show My Number'
        },
        {
            value :'Hide My Number',
            label :'Hide My Number'
        },
    ]
    
    const chargeModelList = [
        {
            value :'CIP',
            label :'CIP'
        },
        {
            value :'AutoPay',
            label :'AutoPay'
        },
    ]

    const groupType = props.groupType.label;
    const timMigrationType = props.timMigrationType.label;

    const handleAccountUuIdChange = (e) => {
        setAccountUuid(e.target.value);
      };
    const handleGroupAssetIdChange = (e) => {
        setGroupAssetId(e.target.value);
    };
    const handleBillCycleDateChange = (e) => {
        setBillCycleDate(e.target.value);
    }
    const handleBillCycleDateFieldBlur = (e) => {
        e.target.value? setBillCycleDateError(false) : setBillCycleDateError(true);

    }
    const handleAnniversaryDateChange = (e) => {
        setAnniversaryDate(e.target.value);
    }
    const handleAnniversaryDateFieldBlur = (e) => {
        e.target.value? setAnniversaryDateError(false) : setAnniversaryDateError(true);
    }
    const handleAccountUuIdFieldBlur = (e) =>{
        e.target.value? setAccountUuidError(false) : setAccountUuidError(true);
    }
    const handleGroupAssetIdFieldBlur = (e) =>{
        e.target.value? setGroupAssetIdError(false) : setGroupAssetIdError(true);
    }
    const handleSimSerialNumberChange = (e,i) => {
        const formArrayTemp = formArray;
        formArrayTemp[i].simSerialNumber = e.target.value;
        setFormArry([...formArrayTemp]);
        handleSimSerialNumberFieldBlur(e,i);
    }
    
	const handleCallingTypeFieldBlur = (e,i) =>{
        const formArrayTemp = formArray;
        if(e.target.value){
            formArrayTemp[i].callingTypeError = false;
        }else{
            formArrayTemp[i].callingTypeError = true;
        }
        setFormArry([...formArrayTemp]);
    }
    const handleServiceCodeFieldBlur = (e,i) =>{
        const formArrayTemp = formArray;
        if(e.target.value){
            formArrayTemp[i].serviceCodeError = false;
            formArrayTemp[i].planList =[{
                value :'',
                label :''
            },...props.fetchReferenceDataRecord['planCodes'][e.target.value]]
        }else{
            formArrayTemp[i].serviceCodeError = true;
        }
        setFormArry([...formArrayTemp]);
    }

    const handleServiceCodeChange = (e,i) => {
        const formArrayTemp = formArray;
        if(formArrayTemp[i].serviceCode !== e.target.value){
            formArrayTemp[i].plan = '';
        }
        formArrayTemp[i].serviceCode = e.target.value;
        setFormArry([...formArrayTemp]);
    }

    const handleCallingTypeChange = (e,i) => {
        const formArrayTemp = formArray;
        formArrayTemp[i].callingType = e.target.value;
        setFormArry([...formArrayTemp]);
    }
	 const handleMigrationTypeChange = (e,i) => {
        const formArrayTemp = formArray;
        formArrayTemp[i].migrationType = e.target.value;
        setFormArry([...formArrayTemp]);
        handleMigrationTypeFieldBlur(e,i);
    }
    const handleListingTypeChange = (e,i) => {
        const formArrayTemp = formArray;
        formArrayTemp[i].listingType = e.target.value;
        setFormArry([...formArrayTemp]);
    }
    const handlePriceAdjustmentValueChange = (e,i) => {
        const formArrayTemp = formArray;
        formArrayTemp[i].priceAdjustmentValue = e.target.value;
        setFormArry([...formArrayTemp]);
        handlePriceAdjustmentValueFieldBlur(e,i);
    }
    const handleListingTypeFieldBlur = (e,i) =>{
        const formArrayTemp = formArray;
        if(e.target.value){
            formArrayTemp[i].listingTypeError = false;
        }else{
            formArrayTemp[i].listingTypeError = true;
        }
        setFormArry([...formArrayTemp]);
    }
	const handlePriceAdjustmentValueFieldBlur = (e,i) =>{
        const formArrayTemp = formArray;
        if(e.target.value){
            formArrayTemp[i].priceAdjustmentValueError = false;
        }else{
            formArrayTemp[i].priceAdjustmentValueError = true;
        }
        setFormArry([...formArrayTemp]);
    }

    const handleMigrationTypeFieldBlur = (e,i) =>{
        const formArrayTemp = formArray;
        if(e.target.value){
            formArrayTemp[i].migrationTypeError = false;
        }else{
            formArrayTemp[i].migrationTypeError = true;
        }
        setFormArry([...formArrayTemp]);
    }

    const handlePlanFieldBlur = (e,i) =>{
        const formArrayTemp = formArray;
        if(e.target.value){
            formArrayTemp[i].planError = false;
        }else{
            formArrayTemp[i].planError = true;
        }
        setFormArry([...formArrayTemp]);
    }
    
    const handleDurationChange = (e,i) => {
        const formArrayTemp = formArray;
        formArrayTemp[i].duration = e.target.value;
        setFormArry([...formArrayTemp]);
        handleDurationFieldBlur(e,i);
    }
    const handleDurationFieldBlur = (e,i) =>{
        const formArrayTemp = formArray;
        if(e.target.value){
            formArrayTemp[i].durationError = false;
        }else{
            formArrayTemp[i].durationError = true;
        }
        setFormArry([...formArrayTemp]);
    }  
    const handleServiceIdChange = (e,i) => {
        const formArrayTemp = formArray;
        formArrayTemp[i].serviceId = e.target.value;
        setFormArry([...formArrayTemp]);
        handleServiceIdFieldBlur(e,i);
    }
    const handlePlanChange = (e,i) => {
        const formArrayTemp = formArray;
        formArrayTemp[i].plan = e.target.value;
        setFormArry([...formArrayTemp]);
    }
    const handleServiceIdFieldBlur = (e,i) =>{
        const formArrayTemp = formArray;
        formArrayTemp[i].serviceId = e.target.value;        
        const value = e.target.value;
        if(value){
            if(!value.startsWith("61")){
                formArrayTemp[i].serviceIdError = true;
                formArrayTemp[i].serviceIdErrorMsg = "Service Number should start with 61";
            }else{
                if(value.length !== 11){
                    formArrayTemp[i].serviceIdError = true;
                    formArrayTemp[i].serviceIdErrorMsg = "Service Number must be 11 characters";
                }else{
                    formArrayTemp[i].serviceIdErrorMsg = "";
                    formArrayTemp[i].serviceIdError = false;
                }
            }
            
        }else{
            formArrayTemp[i].serviceIdError = true;
            formArrayTemp[i].serviceIdErrorMsg = "Service Number cannot be empty";
        }
    }

    const handleSimSerialNumberFieldBlur = (e,i) =>{
        const formArrayTemp = formArray;
        formArrayTemp[i].simSerialNumber = e.target.value;        
        const value = e.target.value;
        if(value){
            if(value.length !== 20){
                formArrayTemp[i].simSerialNumberError = true;
                formArrayTemp[i].simSerialNumberErrorMsg = 'Sim Serial Number must be 20 characters';
            }else{
                formArrayTemp[i].simSerialNumberErrorMsg = "";
                formArrayTemp[i].simSerialNumberError = false;
            }                
        }else{
            formArrayTemp[i].simSerialNumberError = true;
            formArrayTemp[i].simSerialNumberErrorMsg = 'Sim Serial Number cannot be empty';
        }
        setFormArry([...formArrayTemp]);
    }   

    const handleChargeModelFieldBlur = (e) =>{
        e.target.value? setChargeModelError(false) : setChargeModelError(true);
    }
    const handleChargeModelChange = (e) => {
        setChargeModel({value :e.target.value,label :e.target.value});
    }
    const submitBulkOrder = (e) => {
        const timMigrationMultiFormDataReq = [];
        formArray.map( formData => {
           const eachFormReq ={
                migrationType : formData.migrationType,
                serviceNumber: formData.serviceId,
                simSerialNumber: formData.simSerialNumber,
                serviceCode : formData.serviceCode,
                listingType : formData.listingType,
                callingType : formData.callingType,
                plan : formData.plan,               
                priceAdjustmentValue : formData.priceAdjustmentValue,
                duration : formData.duration
            }
            timMigrationMultiFormDataReq.push(eachFormReq);
        })
        const req = {
            type: timMigrationType,
            groupType,
            accountUuid,
            billCycleDate,
            anniversaryDate,
            chargeModel : chargeModel.label,
            groupAssetRefId : groupAssetId,
            timMigrationMultiFormData : timMigrationMultiFormDataReq 
        }
        displayLoading({ title: "Tim Migration In Progress", bodyText: "Please standby" });
        props.submitBulkOrder(req).then((response) => {
            if(response.jobId && response.batchId && response.correlationId)
            {
                let message = "<big>"+response.message+"</big></br><p>Job ID : "+response.jobId+"</p></br><p>Batch ID : "+response.batchId+"</p></br><p>Correlation ID : "+response.correlationId+"</p>";   
                swal.fire({
                  type: 'success',
                  html : message
              })           
            }else{
                let errorMessage = (response.error?.message.length > 0) ? response.error.message[0] : response.status
                swal.fire('Tim Migration Failed!',
                (errorMessage === null || errorMessage === undefined) ? response : errorMessage,
                'error'
              ) 
            }
        })
        
      }
   
    const isAllMandatoryFieldsEntered = ()=>{
        const isServiceIdErrorPresent = formArray.filter(formObject => true === formObject.serviceIdError).length > 0;
        const isSimSerialNumberErrorPresent = formArray.filter(formObject => true === formObject.simSerialNumberError).length > 0;
        const isServiceIdPresent = formArray.filter(formObject => '' === formObject.serviceId || null === formObject.serviceId).length > 0;
        const isSimSerialNumberPresent = formArray.filter(formObject => '' === formObject.simSerialNumber || null === formObject.simSerialNumber).length > 0;
        const isServiceCodePresent = formArray.filter(formObject => '' === formObject.serviceCode || null === formObject.serviceCode).length > 0;
        const isListingTypePresent = formArray.filter(formObject => '' === formObject.listingType || null === formObject.listingType).length > 0;
        const isCallingTypePresent = formArray.filter(formObject => '' === formObject.callingType || null === formObject.callingType).length > 0;
        const isPlanPresent = formArray.filter(formObject => '' === formObject.plan || null === formObject.plan).length > 0;
        if(!isServiceIdErrorPresent && !isSimSerialNumberErrorPresent && accountUuid && billCycleDate && !isServiceIdPresent && !isSimSerialNumberPresent && !isServiceCodePresent && !isListingTypePresent && !isCallingTypePresent && !isPlanPresent && chargeModel){
            if(groupType === 'New'){
                if(anniversaryDate){
                    const newWithDiscountDataArr = formArray.filter( formData => formData.migrationType === 'New service with discount'); 
                    if(newWithDiscountDataArr.length > 0){
                        if(newWithDiscountDataArr.filter(formdata => '' === formdata.duration || null === formdata.duration || '' === formdata.priceAdjustmentValue || null === formdata.priceAdjustmentValue).length > 0){
                            setButtonDisabled(true);
                        }else{
                            setButtonDisabled(false);
                        }
                    }else{
                        setButtonDisabled(false);
                    }
         
                }else{
                    setButtonDisabled(true);
                }
    
            }else if(groupType === 'Existing'){
                if(groupAssetId){
                    const existingWithDiscountDataArr = formArray.filter( formData => formData.migrationType === 'Existing service with discount'); 
                    if(existingWithDiscountDataArr.length > 0){
                        if(existingWithDiscountDataArr.filter(formdata => '' === formdata.duration || null === formdata.duration || '' === formdata.priceAdjustmentValue || null === formdata.priceAdjustmentValue).length > 0){
                            setButtonDisabled(true);
                        }else{
                            setButtonDisabled(false);
                        }
                    }else{
                        setButtonDisabled(false);
                    }
                }else{
                    setButtonDisabled(true);
                }
            }
        }else{
            setButtonDisabled(true);
        }
      }
      useEffect(() => {
        setAnniversaryDate(null);
        setAnniversaryDateError(false);
        setGroupAssetId("");
        setGroupAssetIdError(false)
        setIndexValue(0);
        setFormCount(0);
        let list  = [];
        list.push(formField)
        setFormArry(list);
        
      }, [groupType]);
      useEffect(() => {
         isAllMandatoryFieldsEntered();
      });

    const handleCommonFieldExpandCollapse =()=>{
        setExpandCommonFields(!expandCommonFields);
    }
    const handleFormFieldExpandCollapse =()=>{
        setExpandFormFields(!expandFormFields);
    }
    const handleTabChange=(e, newValue)=>{
        setIndexValue(newValue);
    }
    const onAddClick=()=>{
        const formCountInc = formCount + 1
        setFormCount(formCountInc);
        let list  = [...formArray];
        list.push(formField)
        setFormArry(list);
    }
    const onRemoveClick=(e,i)=>{
        formArray.splice(i,1);
        setFormCount(formCount-1);
        setIndexValue(0);
        if(formArray.length <= 0){
            let list  = [...formArray];
            list.push(formField)
            setFormCount(0);
            setFormArry(list);
        }
    }

    const a11yProps=(index) =>{
        return {
          id: `tab-${index}`,
          'aria-controls': `tabpanel-${index}`,
        };
    }
    
    return <>
    { timMigrationType === 'Mobile' ? 
    <>
    <Accordion expanded={expandCommonFields} onChange={handleCommonFieldExpandCollapse}>
    <AccordionSummary sx={{backgroundColor:'#1976d2', height:'0px !important' }}
          expandIcon={<ExpandCircleDownIcon/>}
        >
    <Typography style={{color:'white'}}>Generic Fields</Typography>
    </AccordionSummary>
    <AccordionDetails>
    <div className="checkinContainer container">
    <div class="row">
    {" "}
    <div class="col-1"></div>
    <div class="col-5">
    <TextField
        label="Account UUID"
        variant="filled"
        color="primary"
        required={true}
        value={accountUuid}
        error={accountUuidError}
        onBlur={(e) => handleAccountUuIdFieldBlur(e)}
        onChange={(e) => handleAccountUuIdChange(e)}
        fullWidth
        helperText={
            !accountUuidError
              ? ""
              : "Account UUID cannot be empty"
          }
      />{" "}   
      </div>
      
      <div class="col-5">   
      {
        groupType === 'New'?
        <>
            <TextField
            label="Anniversary Date"
            InputLabelProps={{ shrink: true }}
            variant="filled"
            color="primary"
            required={true}
            type="date"
            value={moment(anniversaryDate).format("yyyy-MM-DD")}
            error={anniversaryDateError}
            onBlur={(e) => handleAnniversaryDateFieldBlur(e)}
            onChange={(e) => handleAnniversaryDateChange(e)}
            fullWidth
            helperText={
                !anniversaryDateError
                ? ""
                : "Anniversary Date required"
            }
        />
        </>:
        <>
            <TextField
                label="Group Asset ID"
                variant="filled"
                color="primary"
                required={true}
                value={groupAssetId}
                error={groupAssetIdError}
                onBlur={(e) => handleGroupAssetIdFieldBlur(e)}
                onChange={(e) => handleGroupAssetIdChange(e)}
                fullWidth
                helperText={
                    !groupAssetIdError
                    ? ""
                    : "Group Asset ID cannot be empty"
                }
                />
        </>
      }
      </div>
      </div>
        <br />
    <div class="row">
    <div class="col-1"></div>
    <div class="col-5">
       <TextField
        label="Bill Cycle Date"
        InputLabelProps={{ shrink: true }}
        variant="filled"
        color="primary"
        required={true}
        type="date"
        value={moment(billCycleDate).format("yyyy-MM-DD")}
        error={billCycleDateError}
        onBlur={(e) => handleBillCycleDateFieldBlur(e)}
        onChange={(e) => handleBillCycleDateChange(e)}
        fullWidth
        helperText={
            !billCycleDateError
              ? ""
              : "Bill Cycle Date required"
          }
      />
      </div>
      <div class="col-5">
      <TextField
            
            select
            color="primary"
            required={true}
            fullWidth
            label="Charge Model"
            value={chargeModel.label}
            error={chargeModelError}
            onBlur={(e) => handleChargeModelFieldBlur(e)}
            onChange={(e) => handleChargeModelChange(e)}
            SelectProps={{
            native: true,
            IconComponent: () => null
            }}
            helperText={
                !chargeModelError
                ? ""
                : "ChargeModel is required"
            }
            variant="filled"
        >
          {chargeModelList.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
        </div>
        </div>
    </div>
    </AccordionDetails>
    </Accordion >
    <br/>
    
    <Accordion expanded={expandFormFields} onChange={handleFormFieldExpandCollapse}>
    <AccordionSummary sx={{backgroundColor:'#1976d2', height:'0px !important' }}
     aria-label="Expand"
          expandIcon={<ExpandCircleDownIcon/>}
        >
     <Typography style={{color:'white'}}>Form Fields</Typography>        
   
    </AccordionSummary>
    <AccordionDetails>
    <Tabs value={indexValue} onChange={handleTabChange} aria-label="basic tabs example">
    
    {
        Array.from(Array(formCount+1), (e, i) => {
            const indexLabel = i+1;
            return <Tab 
            iconPosition="end"
            icon={
                <Close hidden={i!=indexValue} 
                sx={{color:"#9f0b0b", width:"0.7em"}}                  
                    onClick={
                        (e) => onRemoveClick(e,i)
                    }
                />
            } label={"#"+indexLabel} {...a11yProps(i-1)}/>
          })
        
    }   
    {<Grid container style={{alignItems:"center"}}>
            <IconButton hidden={formCount >=4} onClick={onAddClick}>
              <AddCircleIcon color="primary"
              />
            </IconButton>
          </Grid>}                 
    </Tabs>
    {
        formArray.map((form, i) => {         
            return <div className="checkinContainer container" id={"tab-"+i} hidden={indexValue!= i}>
            <div class="row">
            {" "}
            <div class="col-1"></div>
            <div class="col-5">
            <TextField
                    
                    select
                    color="primary"
                    required={true}
                    fullWidth
                    label="Migration Type"
                    value={form.migrationType}
                    error={form.migrationTypeError}
                    onBlur={(e) => handleMigrationTypeFieldBlur(e,i)}
                    onChange={(e) => handleMigrationTypeChange(e,i)}
                    SelectProps={{
                    native: true,
                    IconComponent: () => null
                    }}
                    helperText={
                        !form.migrationTypeError
                        ? ""
                        : "Migration Type is required"
                    }
                    variant="filled"
                >
                  { groupType === 'New'?
                  props.timNewMigrationScenarios.map((option) => (
                    <option key={option.label} value={option.label}>
                      {option.label}
                    </option>
                  )) :
				  props.timExistingMigrationScenarios.map((option) => (
                    <option key={option.label} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                </div>
                <div class="col-5">
                    </div>
                </div>
                <br />
            <div class="row">
                      <div class="col-1"></div>
                      <div class="col-5">
            <TextField
                    select
                    color="primary"
                    required={true}
                    fullWidth
                    label="CallerLine Identification"
                    value={form.callingType}
                    error={form.callingTypeError}
                    onBlur={(e) => handleCallingTypeFieldBlur(e,i)}
                    onChange={(e) => handleCallingTypeChange(e,i)}
                    SelectProps={{
                    native: true,
                    IconComponent: () => null
                    }}
                    helperText={
                        !form.callingTypeError
                        ? ""
                        : "Calling Type is required"
                    }
                    variant="filled"
                >
                  {callingTypeList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                </div>
                <div class="col-5">
                <TextField
                    
                    select
                    color="primary"
                    required={true}
                    fullWidth
                    label="Directory Listing"
                    value={form.listingType}
                    error={form.listingTypeError}
                    onBlur={(e) => handleListingTypeFieldBlur(e,i)}
                    onChange={(e) => handleListingTypeChange(e,i)}
                    SelectProps={{
                    native: true,
                    IconComponent: () => null
                    }}
                    helperText={
                        !form.listingTypeError
                        ? ""
                        : "Listing Type is required"
                    }
                    variant="filled"
                >
                  {listingTypeList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
                </div>
                </div>
                <br />
                <div class="row">
                      <div class="col-1"></div>
                      <div class="col-5">
                      <TextField
                label="Service Number"
                variant="filled"
                color="primary"
                required={true}
                type="number"
                inputProps={{min:0}}
                value={form.serviceId}
                error={form.serviceIdError}
                placeholder="Enter Numeric Data"
                onBlur={(e) => handleServiceIdFieldBlur(e,i)}
                onChange={(e) => handleServiceIdChange(e,i)}
                fullWidth
                helperText={
                    !form.serviceIdError
                      ? ""
                      : form.serviceIdErrorMsg
                  }
              />
                </div>
                <div class="col-5">
                <TextField
                label="Sim Serial Number"
                variant="filled"
                color="primary"
                required={true}
                type="number"
                inputProps={{min:0}}
                value={form.simSerialNumber}
                error={form.simSerialNumberError}
                placeholder="Enter Numeric Data"
                onBlur={(e) => handleSimSerialNumberFieldBlur(e,i)}
                onChange={(e) => handleSimSerialNumberChange(e,i)}
                fullWidth
                helperText={
                    !form.simSerialNumberError
                      ? ""
                      : form.simSerialNumberErrorMsg
                  }
              />
              
                </div>
                </div>
                <br />
                <div class="row">
                      <div class="col-1"></div>
                      <div class="col-5">
                      <TextField
                
                select
                color="primary"
                required={true}
                fullWidth
                label="Service Code"
                value={form.serviceCode}
                error={form.serviceCodeError}
                onBlur={(e) => handleServiceCodeFieldBlur(e,i)}
                onChange={(e) => handleServiceCodeChange(e,i)}
                SelectProps={{
                native: true,
                IconComponent: () => null
                }}
                helperText={
                    !form.serviceCodeError
                      ? ""
                      : "Service Code is required"
                  }
                variant="filled"
                >
                  {serviceCodeList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
              <div class="col-5">
              <TextField
                    
                    select
                    color="primary"
                    required={true}
                    fullWidth
                    label="Plan"
                    value={form.plan}
                    disabled={null === form.serviceCode || '' === form.serviceCode}
                    error={form.planError}
                    onBlur={(e) => handlePlanFieldBlur(e,i)}
                    onChange={(e) => handlePlanChange(e,i)}
                    SelectProps={{
                    native: true,
                    IconComponent: () => null
                    }}
                    helperText={
                        !form.planError
                        ? ""
                        : "Plan is required"
                    }
                    variant="filled"
                >
                  {form.planList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
              </div>
              <br/>
              {       
                (form.migrationType === 'New service with discount' || form.migrationType === 'Existing service with discount') &&
              <div class="row">
                      <div class="col-1"></div>
                      <div class="col-5">
                <TextField
                label="Price Adjustment value "
                variant="filled"
                color="primary"
                required={true}
                type="number"        
                value={form.priceAdjustmentValue}
                error={form.priceAdjustmentValueError}
                placeholder="Enter Numeric Data"
                onBlur={(e) => handlePriceAdjustmentValueFieldBlur(e,i)}
                onChange={(e) => handlePriceAdjustmentValueChange(e,i)}
                fullWidth
                helperText={
                    !form.priceAdjustmentValueError
                      ? ""
                      : "Price Adjustment value is required"
                  }
              />
              </div>
              <div class="col-5">
              <TextField
                label="Duration"
                variant="filled"
                color="primary"
                required={true}
                type="number"
                inputProps={{min:0}}
                value={form.duration}
                error={form.durationError}
                placeholder="Enter Numeric Data"
                onBlur={(e) => handleDurationFieldBlur(e,i)}
                onChange={(e) => handleDurationChange(e,i)}
                fullWidth
                helperText={
                    !form.durationError
                      ? ""
                      : "Duration is required"
                  }
              />
              </div>
              </div>}    
            </div>
          })
        
    } 
        
    </AccordionDetails>
    </Accordion >
    <br/>
    <div>
    <Button variant="contained"  sx={{float:'right'}} disabled={props.submitBulkOrderLoading || buttonDisabled} onClick={(e) => submitBulkOrder(e)} endIcon={props.submitBulkOrderLoading ? <CircularProgress color="inherit" size="20px" /> : <SendIcon/>}>Submit</Button>
    </div></>
    : <></>
    }
    </>
}
const mapStateToProps = state => {
    return {
        submitBulkOrderLoading : state.timMigration.submitBulkOrderLoading,
        timExistingMigrationScenarios: state.utility.filters.timExistingMigrationScenarios,
        timNewMigrationScenarios: state.utility.filters.timNewMigrationScenarios,
        fetchReferenceDataRecord:state.timMigration.fetchReferenceData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      submitBulkOrder: (req) => dispatch(submitBulkOrder(req)),
      fetchReferenceData: () => dispatch(fetchReferenceData()),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(TimMigrationDataForm);
