import React, { useState, useEffect, useRef } from 'react';
import { connect} from "react-redux";
import _ from 'lodash';
import { retrieveStandardDataBankCheckOutHistory, fetchCheckoutFilterByFieldAndValue, setTeamId, setUserToggle} from "./action";
import { fetchAllTeams , getUsers} from '../../actions/myTeamsActions';
import HeaderContent from "../../components/common/Header/HeaderContent";
import CheckoutTable from './subcomponents/CheckoutTable';
import SearchFilter from './subcomponents/SearchFilter';
import PaginationController from "../../utils/PaginationController";
import FloatingScroll from "../../utils/FloatingScroll";
import { TelstraIcon } from '../../components/common/icons/GradientIcons';
import {UserSwitch} from '../../components/common/custom/ToggleSwitch';
import SwitchSelector from "react-switch-selector";

export const ToggleButtonLabel = {
    STANDARD: 'Standard',
    CUSTOM: 'Custom'
  }
  
  const dataTypeToggleButtonOptions = [
    {
      label: ToggleButtonLabel.STANDARD,
      value: ToggleButtonLabel.STANDARD,
      selectedBackgroundColor: "white",
      selectedFontColor: "black"
    },
    {
      label: ToggleButtonLabel.CUSTOM,
      value: ToggleButtonLabel.CUSTOM,
      selectedBackgroundColor: "white",
      selectedFontColor: "black"
    }
  ];
function StandardDataBankCheckoutHistory(props) {
    const [page, setPage] = useState(0);
    const dataTypeToggleButton = useRef({value: ToggleButtonLabel.STANDARD});
    const { isUserToggle, setUserToggle, selectedEnvValue, checkOutHistoryData, errors, allTeams = [], fetchCheckoutHistory, fetchFilterOptions, fetchAllTeams,
        isAdmin, fetching, user, selectedFilter, filter,  listOfTeams=[], setTeamId, teamId, getUsers } = props;
    const { content, number: currentPage, totalPages } = checkOutHistoryData;
    const [filters, setFilters] = useState([]);
    const {fdata, floading} = filter;
    const [isClearFilter, setIsClearFilter] = useState(false);
    const [userSelectedFilters, setUserSelectedFilters] = useState([]);
    const allTeamslist=[{ teamDisplayName: user.displayName.replace(/,(?=[^\s])/g, ", "), userName:user.UserName }, ...(isAdmin ? allTeams : listOfTeams)];
    const [value, setValue] = useState(allTeamslist[0]);
    const isStandardToggle = dataTypeToggleButton.current.value === ToggleButtonLabel.STANDARD;

    function selectFilter(page = 0, filter = []){
        let filterData = [];
        if (filter && filter.length >0) {
            filterData = filter.map((filt)=>({
                key: filt.field ==='usedDate'? 'dateRange': filt.field,
                value: filt.field ==='usedDate'? (filt.value.startDate.toISOString().substring(0, 10)+'/'+filt.value.endDate.toISOString().substring(0, 10)):filt.value
            }))
        }
        let setUserID;
        let setTeamID;
      
        if (value.userName===user.UserName){
            setUserID=value.userName;
            setTeamID='';
        } else {
            if (isUserToggle) {
                setUserID=value.userName;
            } else {
                setUserID= '';
                setTeamID= value.teamId || value.id;
            }
        }
        let request = {
            pageIndex: 0,
            batchSize: 10,
            environment: selectedEnvValue,
            userId: setUserID,
            teamId:setTeamID,
            filters: filterData,
            dataType: dataTypeToggleButton.current.value
        }

        fetchCheckoutHistory(page, filter, isUserToggle, request);
        setUserSelectedFilters(filter || []);
    }

    const changeHandler = (field, filter) => {
        if(!_.isEmpty(filter)){
          let request = {
            environment: selectedEnvValue,
            userId: user.UserName,
            key: field,
            value: filter
            }

            fetchFilterOptions(field, filter, isUserToggle, request);
        }
    }

    const saveValue = (val) => {
        setValue(val);
        let setUserID;
        let setTeamID;
        if(!isUserToggle){
            if(val.userName===user.UserName){
                setUserID=val.userName;
                setTeamID='';
            }else{
                setUserID='';
                setTeamID=val.teamId || val.id;
            }
        }else{
            if(val.userName===user.UserName){
                setUserID=val.userName;
                setTeamID='';
            }else{
                setUserID=val.userId;
            }
        }
        if (_.isEmpty(userSelectedFilters)) {
            let request = {
              pageIndex: 0,
              batchSize: 10,
              environment: selectedEnvValue,
              userId:setUserID,
              teamId:setTeamID,
              dataType: dataTypeToggleButton.current.value
            }
              fetchCheckoutHistory(0, [], isUserToggle, request);
          }
    }

    useEffect(()=>{
          setFilters(filterConf);
          setIsClearFilter(true);
          setPage(0);
          setUserSelectedFilters([]);
    },[value, isUserToggle, dataTypeToggleButton.current.value]);

    function bindLoadingToFilter() {
        const filterD = _.map(filters, (f) => {
            if (teamId && f.field === 'success') {
                f.isHidden = true;
            } else if ( !teamId && f.field === 'name') {
                f.isHidden = true;
            } else {
                f.isHidden = false;
            }
            return f;
        });
        return filterD.map((filter) => ({ ...filter, isLoading: floading[filter.field] || false }))

    }

    const successOptions = [
        { value: 'True', label: <TelstraIcon color="green" icon="icon-round-tick" size="xs" /> },
        { value: 'False', label: <TelstraIcon color="orange" icon="icon-round-cross" size="xs"  /> }
    ]
    
    const filterConf = [
        { field: "id", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: isStandardToggle ? "serviceId" : "userStory", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: isStandardToggle ? "serviceProductCode" : "scenarioName", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: isStandardToggle ? "serviceDescription" : "scenarioName", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: "quantity", type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: "usedDate", type: "date", onSelect: selectFilter, options: [] },
        { field: "name",  type: "autocomplete", onChange: changeHandler, onSelect: selectFilter, options: [] },
        { field: "success", type: "dropdown", onSelect: selectFilter, options: successOptions }
    ];
    
    function gotoPage(pageNum) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        setPage(pageNum);
        let setUserID;
        let setTeamID;
        
          if(!isUserToggle){
            if(value.userName===user.UserName){
                setUserID=value.userName;
                setTeamID='';
            }else{
                setUserID='';
                setTeamID=value.teamId || value.id;
            }
        }else{
            if(value.userName===user.UserName){
                setUserID=value.userName;
                setTeamID='';
            }else{
                setUserID=value.userName;
            }
        }

        const filterArray = userSelectedFilters?.map((item)=>{
            return {key: item.field, value: item.value}
        });

        const request = {
            pageIndex: pageNum,
            batchSize: 10,
            environment: selectedEnvValue,
            userId:setUserID,
            teamId:setTeamID,
            filters:filterArray,
            dataType: dataTypeToggleButton.current.value
        }
		fetchCheckoutHistory(pageNum, userSelectedFilters, isUserToggle, request);
    }
    function formTeamList() {
        return [{ teamDisplayName: user.displayName.replace(/,(?=[^\s])/g, ", ") }, ...(isAdmin ? allTeams : listOfTeams)]
    }

    useEffect(() => {
        if(_.isEmpty(filters)){
            setFilters(filterConf);
        }

        isClearFilter && setIsClearFilter(false);

        if (_.isEmpty(allTeams) && isAdmin){
            isUserToggle? getUsers(props.userData?.content) : fetchAllTeams();
        }
    }, [page, teamId]);

    useEffect(() => {
        isClearFilter && setIsClearFilter(false);
        if (_.isEmpty(filters) || _.isEmpty(fdata) ) {
            return;
        }

        let localFilter = filters.map(filter => {
            if (filter.options !== fdata[filter.field]  && filter.field !== 'success'){
                filter.options = fdata[filter.field] || []
            }
            return filter;
        });
        setFilters(localFilter);
    }, [fdata]);

    useEffect(() => {
		setTeamId('');
        setPage(0);

        if (_.isEmpty(allTeams)) {
            return;
        }
        const listOfTeams = formTeamList();
        const team = listOfTeams[0];
        if (_.isEmpty(team?.id || team?.teamId)) {
            setTeamId(team?.id || team?.teamId);
        }
    }, []);
    
	useEffect(() => {
        let request = {
            pageIndex: page,
            batchSize: 10,
            environment: selectedEnvValue,
                userId: user.UserName,
                dataType: dataTypeToggleButton.current.value
        }
        fetchCheckoutHistory(page, userSelectedFilters, isUserToggle, request);
    }, [selectedEnvValue]);

    useEffect(()=>{
        isUserToggle? getUsers(props.userData?.content) : fetchAllTeams();
    },[isUserToggle])

    const toggleButton = () =>{
        setUserToggle(!isUserToggle);
    }

    const clearFilter = () => {
        setIsClearFilter(true);
    }
    const renderUserToggle = () => {
        return(
            <UserSwitch
            checked={isUserToggle}
            onChange={toggleButton}/>
        )
      }

      const resetTeams=()=>{
        saveValue(allTeamslist[0]);
      }
    
    const onChangeDataTypeToggleButton = async newValue => {
        setFilters([]);
        dataTypeToggleButton.current.value = newValue;
        if (_.isEmpty(filters)) {
            setFilters(filterConf);
        }
        const request = {
          pageIndex: page,
          batchSize: 10,
          environment: selectedEnvValue,
          userId: user.UserName,
          dataType: newValue
        }
        fetchCheckoutHistory(page, userSelectedFilters, isUserToggle, request);
        setUserSelectedFilters([]);
    };
    const renderDataTypeSwitchToggle = () => {
        return (
          <span style={{ width: 180, height: 38, marginRight: 100 }}>
          <SwitchSelector
            onChange={onChangeDataTypeToggleButton}
            options={dataTypeToggleButtonOptions}
            backgroundColor={"rgb(19, 161, 41) !important"}
            fontColor={"white"}
            optionBorderRadius= {9}
            wrapperBorderRadius={7}
            fontSize={16}
          />
          </span>
        )
    }
    return (<div className="white">
        <HeaderContent
            title="Checkout History"
            icon="history"
            loading={fetching}
            empty={(content && content.length === 0)}
            loadingText="Loading your standard data checkout history.." 
            extraComponent={renderDataTypeSwitchToggle}
            extraComponentClassName="myteam-switch-selector"
        />

        <SearchFilter 
            value={value} 
            setValue={saveValue} 
            fetching={fetching} 
            listOfTeams={allTeamslist} 
            setTeamId={setTeamId}
            isUserToggle={isUserToggle}
            renderUserToggle={renderUserToggle}
            isAdmin ={isAdmin}
            user={user}
        />

		{ !_.isEmpty(filters) &&
            <React.Fragment>
                <CheckoutTable 
                    resetTeams={resetTeams} 
                    clearFilter={clearFilter} 
                    filters={bindLoadingToFilter()} 
                    fetching={fetching} 
                    errors={errors} 
                    isClearFilter={isClearFilter}
                    teamId={teamId} 
                    isAdmin={isAdmin} 
                    selectedFilter={selectedFilter} 
                    selectFilter={selectFilter} 
                    tableData={content} 
                    detailView={true} 
                    dataBankCheckoutView={true} 
                    isStandardToggle={isStandardToggle} 
                />
                <PaginationController
                    totalPages={totalPages}
                    isClearPagination={isClearFilter || false}
                    gotoPage={gotoPage}
                    isUserToggle={isUserToggle}
                    />
                <FloatingScroll />
            </React.Fragment>
        }
    </div >
    )
}
const mapStateToProps = (state) => {
    return {
        checkOutHistoryData: state.dataBankCheckoutHistory.checkOutHistory,
        fetching: state.dataBankCheckoutHistory.fetching,
        errors: state.dataBankCheckoutHistory.errors,
        selectedFilter: state.dataBankCheckoutHistory.selectedFilter,
        filter: state.dataBankCheckoutHistory.filter,
        isAdmin: state.auth.isAdmin,
        listOfTeams: state.auth.myAvailableTeams,
        allTeams: state.teams.teams.allTeams,
        user: state.auth.user,
        teamId: state.dataBankCheckoutHistory.teamId,
        selectedEnvValue: state.auth.selectedEnvValue,
        isUserToggle: state.dataBankCheckoutHistory.isUserToggle,
        userData: state.users.mgmt
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCheckoutHistory: (pageNum, filters, isUserToggle, request) => dispatch(retrieveStandardDataBankCheckOutHistory(pageNum, filters, isUserToggle, request)),
        fetchFilterOptions: (field, filter, isUserToggle,request) => dispatch(fetchCheckoutFilterByFieldAndValue(field, filter, isUserToggle, request)),
        fetchAllTeams: () => dispatch(fetchAllTeams()),
        setTeamId: (teamId) => dispatch(setTeamId(teamId)),
        getUsers:(userList) => dispatch(getUsers(userList)),
        setUserToggle:(isUserT) => dispatch(setUserToggle(isUserT))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardDataBankCheckoutHistory);
