import React, {Fragment} from 'react'
import ContactDataTable from './ContactDataTable';
import CustomerDataTable from './CustomerDataTable';

import "./CustomerConsole.css";

export default (props) => {
    const {history} = props;
    return (
        <Fragment>
            <CustomerDataTable push={history.push}/>
            <br></br>
            <ContactDataTable/>
        </Fragment>
    )
}