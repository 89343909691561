import jwtDecode from 'jwt-decode';
import config from '../../config';
import {getTokenFromCookie} from "../../utils/browser";
import { verifyJwtToken } from "qecoe-taas-auth/index";

export const EnvirnomentLabels = {
    INT1: 'INT1',
    INT2: 'INT2',
    PRE_PROD: 'OSIT3',
    SQNI:'SQNI'
}

export const authUserState = (token = localStorage.getItem('access_token') || getTokenFromCookie('authorization_token')) => {
    if (token) {
        localStorage.setItem("access_token", token);
        document.cookie = `authorization_token=${token}${window.location.hostname.endsWith(".telstra-cave.com") ? ";domain=.telstra-cave.com;path=/" : ''}`;
    }
    return {
        authenticated: (token)
            ? token
            : '',
        user: token
            ? jwtDecode(token)
            : {},
        name: getName(),
        email: getEmail(),
        userName: getUserName(),
        team: getTeam(),
        teamName: getTeamName(),
        roles: getRoles(),
        primaryRole: getPrimaryRole(),
        isAuthenticated: token
            ? isTokenValid(token)
            : false,
        isExpired: isTokenValid(token),
        isSuperAdmin : isRole("ADMIN"),
        isAdmin: isRole("OPS") || isRole("ADMIN"),
        isService: isRole("SERVICE"),             //to be removed 
        isUser: isRole("USER") || isRole("ADMIN") || isRole("OPS"),
        isGuest: isRole("GUEST") || isRole("USER") || isRole("ADMIN") || isRole("OPS"),
        isUserAdmin: isRole("USER") && isRole("USER_ADMIN"),
        errorMessage: '',
        availableTeams: [],
        myAvailableTeams:[],
        isMyTeamsloading: false,
        teamChangeAuthError: false,
        openSidePanel: false,
        openBroadCastPanel: false,
        selectedEnvValue: EnvirnomentLabels.INT2,
        sidePanelEnvironment: {
            int1: false,
            int2: true,
            osit: false,
            sqni:false
        },
        userRegistered : false,
        accountDetailsLoading: false,
        accountDetailsFailed: false,
        preferencesLoading: false
    }
}

export const isTokenValid = (token) => {
    if (!token) return false;
    const decoded = jwtDecode(token);
    const timeValid = (decoded.exp > Math.floor(new Date().getTime() / 1000));
    const envValid = decoded.env === config.taasEnv;
    return (timeValid && envValid);
}

export const isTaasTokenValid = async (token) => {
    let isVerified = {verified: false, error: undefined};
    try {
      isVerified.verified = await verifyJwtToken(token);
    } catch (err) {
      isVerified.verified = false;
      isVerified.error = err;
    }
    return isVerified;
}

export const getTokenPayload = () => {
    return localStorage.getItem('access_token')
        ? jwtDecode(localStorage.getItem('access_token'))
        : null
}



export const normalizeRoleNames = (roles) => {
    // check if passed in roles are dictionary object
    if (!Array.isArray(roles)) return
    return roles.map(role => role.replace("ROLE_", ""));
}

export const getRoles = () => {
    const tokenObj = getTokenPayload();
    if (tokenObj?.workspaces?.[0]?.authorities?.[0]?.role) {
        const rles = normalizeRoleNames(tokenObj["workspaces"][0].authorities[0].role);
        return rles;
    }
    return null;
}

export const getName = () => {
    const tokenObj = getTokenPayload();
    if (tokenObj?.displayName) {
        return tokenObj["displayName"];
    }
    return null;
}

export const getEmail = () => {
    const tokenObj = getTokenPayload();
    if (tokenObj?.email) {
        return tokenObj["email"];
    }
    return null;
}

export const getUserName = () => {
    const tokenObj = getTokenPayload();
    if (tokenObj?.UserName) {
        return tokenObj["UserName"];
    }
    return null;
}

export const getTeam = () => {
    const tokenObj = getTokenPayload();
    if (tokenObj?.workspaces?.[0]?.authorities?.[0]?.QECOETOOLTeamId) {
        return tokenObj["workspaces"][0].authorities[0].QECOETOOLTeamId;
    }
    return null;
}

export const getTeamName = () => {
    const tokenObj = getTokenPayload();
    if (tokenObj?.workspaces?.[0]?.authorities?.[0]?.QECOETOOLTeamName) {
        return tokenObj["workspaces"][0].authorities[0].QECOETOOLTeamName;
    }
    return null;
}

export const isRole = (role, roles = null) => {
    if (roles == null) {
        roles = getRoles();
    } else roles = normalizeRoleNames(roles.map(role => role.name));

    if (roles) {
        if (roles.length > 0) {
            return roles.includes(role);
        }
    }
    return false;
}

export const getPrimaryRole = () => {
    const roles = getRoles();
    if (roles) {
        if (roles.length > 0) {
            if (roles.includes("ADMIN")) {
                return "ADMIN";
            } else if (roles.includes("SERVICE")) {
                return "SERVICE";
            } else if (roles.includes("OPS")) {
                return "OPS";
            } else if (roles.includes("USER")) {
                return "USER";
            } else {
                return "N/A";
            }
        }
    }
    return null;
}

export const getSelectedEnvValue = (env) => {
    switch (env) {
      case "int1":
        return EnvirnomentLabels.INT1
      case "int2":
        return EnvirnomentLabels.INT2
      case "osit":
        return EnvirnomentLabels.PRE_PROD
      default:
        return EnvirnomentLabels.INT2
    }
}