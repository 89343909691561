import _ from 'lodash';
import {
    SET_BROADCAST_LIST_SUCCESS,
    SET_BROADCAST_LIST_PENDING,
    SET_BROADCAST_LIST_FAILED,
      
  } from "../actions/actionTypes";

const INITIAL_STATE = {
    broadCastDetails: {
        broadCastList: [],
        success: false,
        pending: true,
        failed: false,
        errorMsg: "",
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_BROADCAST_LIST_SUCCESS:
            return {
                ...state,
                    broadCastDetails: {
                    broadCastList: action.payload,
                    success: true,
                    pending: false,
                    failed: false,
                    errorMsg: ""
                }
            }

        case SET_BROADCAST_LIST_PENDING:
            return {
                ...state,
                broadCastDetails: {
                    broadCastList: [],
                    success: false,
                    pending: true,
                    failed: false,
                    errorMsg: ""
                }
            }
        case SET_BROADCAST_LIST_FAILED:
            return {
                ...state,
                broadCastDetails: {
                    broadCastList: [],
                    success: false,
                    pending: false,
                    failed: true,
                    errorMsg: action.payload
                }
            }
        default:
            return state;
    }
};