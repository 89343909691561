import React, { Component } from "react";
import { DataFilterTileNew } from "../../../Data/tiles/DataFilterTileNew";
export default ({
  heading = "Overview",
  title = "Checkout",
  color = "bg-green",
  checkout,
  disabled = false,
  checkoutOptions = [],
}) => {
  return (
    <div>
      <span className="ml-2 dataview-header">
        <p className="sub-leader font-weight-light">{heading}</p>
      </span>
      <div className="ml-3 content tile-box">
        {
          checkoutOptions.length > 0 ? 
            checkoutOptions.map(option => (
              <div className={`tiles-grid base-${option.disabled ? "grey" : "green"}`}>
                <DataFilterTileNew
                  icon={"icon-tick"}
                  gradient={option.disabled ? "featured-gradient" : "theme-gradient-secondary-primary"}
                  label={option.title}
                  selectType={option.disabled ? undefined : () => option.checkout()}
                />
              </div>
            ))
          :
          <div className={`tiles-grid base-${disabled ? "grey" : "green"}`}>
            <DataFilterTileNew
              icon={"icon-tick"}
              gradient={disabled ? "featured-gradient" : "theme-gradient-secondary-primary"}
              label={title}
              selectType={disabled ? undefined : () => checkout()}
            />
          </div>
        }
      </div>
    </div>
  );
};