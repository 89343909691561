import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default (props) =>  {
    const item = props.item;
    return (
    <tr>
        <th scope="row">{item.tag_id}</th>
            <td>{item.fnn}</td>
            <td>{item.realm}</td>
            <td>{item.env}</td>
            <td>{item.exchange_id}</td>
            <td>{
                item.adsl_capability == 'Y' ? <FontAwesomeIcon name="check" /> : <FontAwesomeIcon name="times-circle" />
            }</td>
            <td>{item.fibre_capability}</td>
            <td>{item.nbn_capability}</td>
            <td>{item.locality}</td>
    </tr>
    );
};
