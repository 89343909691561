import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";
import StarsIcon from '@material-ui/icons/Stars';
import { makeStyles } from "@material-ui/core/styles";
import indigo from '@material-ui/core/colors/indigo';

const useClasses = makeStyles({
    root: {
        paddingRight: "5px",
        paddingLeft: "5px",
        background: "transparent",
        minWidth: "auto",
        boxShadow: "none",
        "&:hover":{
            backgroundColor: "transparent",
            boxShadow: "none",
        },
        "&:focus": {
            outline: "none"
        }
    },
    icon: {
        "&:hover ": {
            color: indigo[400],
            fontSize: "2.47rem"
        }
    },
});

const WishlistBtn = (props) => {
  const { onBtnClick, ...rest } = props;
  const classes = useClasses();

  return (
    <Button
        variant="contained"
        color="default"
        onClick={onBtnClick}
        className={classes.root}
        {...rest}
    >
        <StarsIcon className={classes.icon} color="primary" style={{"fontSize":"2.47rem"}}/>
    </Button>
  );
};

WishlistBtn.propTypes = {
    onBtnClick: PropTypes.func.isRequired,
};

export default WishlistBtn;
