import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    filters: {
        typeFilters: [],
        envFilters: [],
        prodFilters: [],
        actionFilters: [],
        delLevelFilters: [],
        cosmosProdFilters: []
    },
    selectedFilters: {
        env: undefined,
        type: undefined,
        cidn: undefined,
        action: undefined,
        level: undefined,
        deleteCIDN: undefined,
        deleteServiceId: undefined,
        products: [],
        productData: []
    },
    view: {
        submitLoading: false,
        errorMsg: undefined,
        repItems: []
    },
    validate: {
        loading: false,
        data: {}
    },
    options:{
        productFamily:[{key:'Postpaid Handheld', value: 'Postpaid Handheld'},
                       {key:'Mobile Broadband', value: 'Mobile Broadband'},
                       {key:'Unknown', value: 'Unknown'}],
        quantity: Array.apply(null, {length: 11}).map(Number.call, Number),
        productPlanNames:[{key:'Bus BYO Mobile Plan $99' , value: 'Bus BYO Mobile Plan $99'},
                        {key : 'Bus BYO Mobile Plan $999' , value: 'Bus BYO Mobile Plan $999'}],
    },
    cosmos:{
        plans:[],
        repayments : []
    },
    salesforceData: {
        showSalesforcePanel : false,
        salesData : {},
        serviceID : "",
        serviceURL: "",
        remainingAmount : "",
        remainingTerm : "",
        status : "",
       monthlyAmount : "",
       noRecords : false,
    },
    faxList: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOAD_DATA_S2P_FILTER_SUCCESS:
            return {
                ...state,
                filters: {
                    ...action.payload
                }
            }

        case types.SELECT_DATA_S2P_FILTER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [action.key]: action.value
                }
            }
        case types.S2P_SAVE_TABLE_VIEW:
            let productData = [...state.selectedFilters.productData]
           
            let prodIdx = productData.findIndex(prod => prod.name.toUpperCase() === action.table.name.toUpperCase());
            if(action.table.name.toUpperCase() === "MOBILE")
                productData[prodIdx].mobileData = [...action.table.mobileData];
            if(action.table.name.toUpperCase() === "TIPT-SIPC")
                productData[prodIdx].tiptSipcProduct = [...action.table.tiptSipcProduct];
            productData[prodIdx].adborFnns = [...action.table.adborFnns]
            productData[prodIdx].ranges = [...action.table.ranges];
            if(action.table.huntGroup)
                 productData[prodIdx].huntGroup = [...action.table.huntGroup]
            
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    productData: productData
                }
            }

        case types.CHECKOUT_DATA_S2P_SUCCESS:
            return { ...state, view: { ...action.payload } }

        case types.S2P_ADD_CIDN:
            const { cidn } = action.payload;
            return {
                ...state,
                selectedFilters: { ...state.selectedFilters, cidn }
            };
        case types.S2P_ADD_DEALERPREMISECODE:
            const { dealerPremiseCode } = action.payload;
            return {
                ...state,
                selectedFilters: { ...state.selectedFilters, dealerPremiseCode }
            };

        case types.CIDN_PENDING:
            return { ...state, cidnPending: true };

        case types.REMOVE_SELECTED_PRODUCT_TYPE:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    products: state.selectedFilters.products.filter((data) => { return data !== action.payload }),
                    productData: state.selectedFilters.productData.filter((data) => { return data.name !== action.payload })
                }
            }
        case types.SELECT_DATA_S2P_FILTER_RESET:
            return {
                ...INITIAL_STATE
            };

        case types.REPLICATOR_SUBMIT_REQUEST_LOADING:
            return {
                ...state,
                view: {
                    ...state.view,
                    submitLoading: true
                }

            }

        case types.REPLICATOR_SUBMIT_REQUEST_SUCCESS:
            return {
                ...state,
                view: {
                    repItems: action.payload.repItems,
                    submitLoading: false
                }

            }
       
        case types.REPLICATOR_SUBMIT_REQUEST_ERROR:
            return {
                ...state,
                view: {
                    submitLoading: false,
                    errorMsg: action.payload
                }

            }

        case types.REPLICATOR_VALIDATE_RESUBMIT_LOADING:
            return {
                ...state,
                validate: {
                    loading: true,
                    data: {}
                }
            }

        case types.REPLICATOR_VALIDATE_RESUBMIT_SUCCESS:
            return {
                ...state,
                validate: {
                    loading: false,
                    data: { ...action.payload }
                }
            }

        case types.REPLICATOR_VALIDATE_RESUBMIT_ERROR:
            return {
                ...state,
                validate: {
                    loading: false,
                    data: {}
                }
            }

        case types.REPLICATOR_VALIDATE_FLUSH:
            return {
                ...state,
                validate: {
                    loading: false,
                    data: {}
                }
            }

        case types.REPLICATOR_RESUBMIT_LOADING:
            return {
                ...state,
                validate: {
                    ...state.validate,
                    loading: true
                }
            }

        case types.REPLICATOR_RESUBMIT_SUCCESS:
            return {
                ...state,
                validate: {
                    data: { ...action.payload }
                }
            }
        case types.GENERATE_SERVICE_ID_SUCCESS:
            return {...state,
                serviceIdField:{
                    serviceId: action.payload
                }
            }
            case types.GENERATE_SERVICE_ID_PENDING:
                    return {...state,
                        serviceIdField:{
                            pending: true, 
                            rowNumber: action.payload
                        }
                    }
                    case types.GENERATE_SERVICE_ID_FAILED:
                            return {...state,
                                serviceIdField:{
                                   failed: true
                                }
                            }
            case types.FETCH_COSMOS_PLANS:
                return {
                    ...state,
                    cosmos:{
                        ...state.cosmos,
                        plans: action.payload
                    }
                }
            case types.FETCH_COSMOS_REPAYMENTS:
                return {
                    ...state,
                    cosmos:{ 
                        ...state.cosmos,
                        repayments:action.payload
                    }
                }
            case types.SEARCH_SALESFORCE_DATA:
                return {
                    ...state,
                    salesforceData : {
                    showSalesforcePanel :action.showSalesforcePanel,
                    salesData : action.payload,
                    serviceID : action.serviceID,
                    serviceURL : action.serviceURL,
                    remainingAmount : action.Final_Remaining_Amount__c,
                    remainingTerm : action.Final_Remaining_Term__c,
                    status : action.Status__c,
                    monthlyAmount : action.Monthly_Amount__c,
                    noRecords : action.noRecords
                    }
                }
            case types.SALESFORCE_SUCCESS:
                return {
                    ...state,
                    salesforceData : {
                    showSalesforcePanel : false,
                    salesData : {},
                    serviceID : "",
                    serviceURL: "",
                    remainingAmount : "",
                    remainingTerm : "",
                    status : "",
                    monthlyAmount : "",
                    noRecords : false,
                }
            }
            case types.TOGGLE_FAX:
                return {
                    ...state,
                    faxList: action.payload
                }
        default:
            return state;
    }
}
