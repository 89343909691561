import * as types from './CONSTANTS';
import {axiosTaasInstance as axios} from "../../../axios.config";

import config from "../../../config";
import {authHeaders} from "../../../utils/api";
import mockMobileData from '../../../mockActiveData';

const API_URL_GATEWAY = config.tdm_gateway_api.uri;

export const clearAllAssetsCheckedout = ()=> async dispatch => {
    dispatch({type: types.ASSET_CLEAR_CHECKOUT})
}
//Variables.

export const getBillCycle = (ban,order_index)=> async (dispatch)=>{
    dispatch({type: types.BILL_STATUS_CHECK, payload: true});
    try{
        await axios.get(`${API_URL_GATEWAY}/tdm/rbill/getBillCycle?ban=${ban}`, { headers: authHeaders() }).then((result)=>{
            if(result.data.responseStatus==="Fail"){
                dispatch({type: types.ADD_BILL_PERIOD, payload:{billPeriod:"NOT FOUND",order_index}})
              return dispatch({type: types.BILL_STATUS_CHECK, payload: false});
            } else {
                let billPeriod=result.data.billPeriod;
                dispatch({type: types.ADD_BILL_PERIOD, payload:{billPeriod,order_index}})
              return  dispatch({type: types.BILL_STATUS_CHECK, payload: false});
           }
       })
    }
    catch(err){
         dispatch({type: types.ADD_BILL_PERIOD, payload:{billPeriod:"network_issue",order_index}})
        return dispatch({type: types.BILL_STATUS_CHECK, payload: false});
    }
}

export const retrieveCheckOutData = (id) => async dispatch => {
    dispatch({type: types.FETCH_DATA_BANK_CHECKOUT_DATA});
    await axios.get(`${API_URL_GATEWAY}/tdm/standarddatabank/checkout/retrieve?checkoutId=${id}`, { headers: authHeaders() })
        .then(response => {
            dispatch({type: types.FETCH_DATA_BANK_CHECKOUT_DATA_FULFILLED, payload: response.data})
            console.log(response.data)
        })
        .catch((error) => {
            dispatch({type: types.FETCH_DATA_BANK_CHECKOUT_DATA_REJECTED, payload: error})
                // console.log(error.response.data);
        })
}

export const serviceMigration = (req)=> async (dispatch)=>{
    try{
        dispatch({type: types.SERVICE_MIGRATION_LOADING});
        const res = await axios.post(types.TDM_SERVICE_MIGRATION_URL, req, {headers: authHeaders()})
        dispatch({type: types.SERVICE_MIGRATION_SUCCESS, payload: res.data});
        return res.data;
    }
    catch(err){
        dispatch({type: types.SERVICE_MIGRATION_FALIURE, payload: err});
        return err.response.data;
    }

}
