//to be removed after integrating
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {Table} from 'reactstrap';
import DataViewCustomerSfRow from './DataViewCustomerSfRow';

import {SubHeader} from '../../../common/custom/SubHeader';

import classNames from 'classnames';

import ReactTooltip from 'react-tooltip'

import {connect} from 'react-redux';
import {importCustomerSalesforce, createCustomerContactSalesforce, createCustomerBaSalesforce, createCustomerSiteSalesforce, createCustomerOppSalesforce} from '../../../../actions/dataActions';

import swal from 'sweetalert2'

export const checkProfileThenCallAction = (action, selected) => {
    const isProfileSelected = selected;
    if (!isProfileSelected) {
        // alert user they need a salesforce profile
        swal.fire({
            type: 'warning',
            title: 'Salesforce Profile Required',
            text: 'You must select a salesforce profile for this environment in order to perform this action',
            footer: '<p class="text-primary text-center">Please select profile on top right of portal, if you are unable to select a profile for this environment, you will need to request access<' +
                    '/p>'
        })
        return;
    }

    // call action
    action();
}

class DataViewCustomerSf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {}
        }
    }
    static propTypes = {
        customer: PropTypes.object.isRequired
    }

    renderSalesforceAddButton = (action, loading, disabled, disabledMsg) => {
        const disableButton = (loading || disabled);
        const {isProfileSelected} = this.props;
        return (
            <span
                data-tip={disabledMsg}
                data-tip-disable={(!(disableButton && disabledMsg))}
                style={{}}>
                <button
                    className={classNames("btn btn-sm btn-outline-success ld-over", {running: loading})}
                    onClick={() => checkProfileThenCallAction(action,isProfileSelected)}
                    disabled={disableButton}>
                    {< i class = "fa fa-plus" aria-hidden="true" > </i>}
                    {loading && <div class="ld ld-ring ld-spin"></div>}
                    <ReactTooltip type={"error"} effect="solid" place="bottom"/>
                </button>
            </span>
        )
    }

    activeProfile = ({environment}) => {
        const {available, active} = this.props;
        return available[active[environment]];
    }

    render() {
        const item = this.props.customer;
        const FlexSpan = ({children}) => <span
            style={{
            "display": "inline-flex",
            "alignItems": "center",
            "justifyContent": "space-evenly",
            "width": "100%"
        }}>{children}</span>;
        const SpanWidth = ({children, width}) => <span style={{
            "width": width
        }}>{children}</span>
        return (
            <React.Fragment>
                <SubHeader
                    text={"Salesforce"}
                    iconLeft={true}
                    small={true}
                    iconClass={"td-icon-sm icon-cloud"}
                    iconColor="blue"/>
                <Table className="left">
                    <thead>
                        <tr>
                            <th
                                style={{
                                "width": "20%"
                            }}>Account ID</th>
                            <th
                                style={{
                                "width": "20%"
                            }}>
                                <i class="fa fa-address-card-o theme-text-primary" aria-hidden="true"></i>{'   '}
                                Contacts{' '}
                                <span
                                    style={{
                                    "marginLeft": "10px"
                                }}>
                                    {this.renderSalesforceAddButton(() => {
                                        this
                                            .props
                                            .salesforceCreateContact(item.customerId, this.activeProfile(item))
                                    }, item.sfContactsLoading, false, "")}
                                </span>
                            </th>
                            <th
                                style={{
                                "width": "20%"
                            }}>
                                <span>
                                    <i class="fa fa-credit-card" aria-hidden="true"></i>{'   '}
                                    Billing Accounts{' '}
                                </span>
                                <span
                                    style={{
                                    "marginLeft": "10px"
                                }}>
                                    {this.renderSalesforceAddButton(() => {
                                        if (!item.sfContacts) 
                                            return;
                                        this
                                            .props
                                            .salesforceCreateBa(item.customerId, item.sfContacts[Math.floor(Math.random() * item.sfContacts.length)].id, this.activeProfile(item))
                                    }, item.sfBaLoading, !((item.sfContacts) && (item.sfContacts.length > 0)), "You must have at least one contact created")}
                                </span>
                            </th>
                            <th
                                style={{
                                "width": "20%"
                            }}>
                                <FlexSpan>
                                    <SpanWidth width="25%">
                                        <i class="fa fa-building-o" aria-hidden="true"></i>{'  '}Sites</SpanWidth>
                                    <span
                                        style={{
                                        "width": "50%"
                                    }}><input
                                        type="text"
                                        bsSize="sm"
                                        placeholder="Address ID"
                                        maxLength="10"
                                        name="siteAddress"
                                        ref={input => this.siteAddressRef = input}
                                        style={{
                "height": "31px",
                "lineHeight": "31px"
            }}/></span>
                                    <span>{this.renderSalesforceAddButton(() => {
                                            console.log(this.siteAddressRef.value);
                                            this
                                                .props
                                                .salesforceCreateSite(item.customerId, this.siteAddressRef.value || "419803511", this.activeProfile(item))
                                        }, item.sfSitesLoading, false, "")}</span>
                                </FlexSpan>
                            </th>
                            <th
                                style={{
                                "width": "20%"
                            }}>
                                <span>
                                    <i class="fa fa-tags" aria-hidden="true"></i>{'   '}
                                    Opportunities
                                </span>
                                <span
                                    style={{
                                    "marginLeft": "10px"
                                }}>
                                    {this.renderSalesforceAddButton(() => {
                                        if (!item.sfContacts) 
                                            return;
                                        this
                                            .props
                                            .salesforceCreateOpp(item.customerId, item.sfContacts[Math.floor(Math.random() * item.sfContacts.length)].id, this.activeProfile(item));
                                    }, item.sfOppsLoading, !((item.sfContacts) && (item.sfContacts.length > 0)), "You must have at least one contact created")}
                                </span>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <DataViewCustomerSfRow customer={item} isAdmin={this.props.isAdmin} />
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        salesforceImport: (custId) => {
            dispatch(importCustomerSalesforce(custId))
        },
        salesforceCreateContact: (custId, profile) => {
            dispatch(createCustomerContactSalesforce(custId, profile))
        },
        salesforceCreateBa: (custId, contactId, profile) => {
            dispatch(createCustomerBaSalesforce(custId, contactId, profile))
        },
        salesforceCreateSite: (custId, adborId, profile) => {
            dispatch(createCustomerSiteSalesforce(custId, adborId, profile))
        },
        salesforceCreateOpp: (custId, contactId, profile) => {
            dispatch(createCustomerOppSalesforce(custId, contactId, profile))
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const {active, available} = state.profiles;
    const {isAdmin} = state.auth;
    return {
        isProfileSelected: (active != null),
        available,
        active,
        isAdmin
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataViewCustomerSf);