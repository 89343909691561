import _ from 'lodash';
import * as types from '../actions/actionTypes';
import { updateObjectReadFlag } from '../utils/notification';

const INITIAL_STATE = {
    dashboardNotificationReadStatus : false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SET_DASHBOARD_NOTIFICATION_STATUS_READ:
            return {
                ...state,
                dashboardNotificationReadStatus : true
            }  
        default:
            return state;
    }
}