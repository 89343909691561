import React from "react";
import { ListGroupItem, Table } from "reactstrap";
import _ from "lodash";
import { FieldArray } from "redux-form";
import DataCollapse from "./DataCollapse";
import { TelstraIcon } from "../common/icons/GradientIcons";
import AdborComponent from "./AdborComponent";
import MobileComponent from "./MobileComponent";
import MobileDataTable from "./MobileDataTable";
import "./Product.css";
import "./Data.css";

export default class NewInputTable extends React.Component {
  handleRowChange = (numRows, newNumRows, fields) => {
    let diff = newNumRows - numRows;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) fields.push({ adborId: null, fnn: null });
    } else if (diff < 0) {
      diff *= -1;
      for (let i = 0; i < diff; i++) fields.pop();
    }
  };

  // savedTable = (product, data, index) => {
  savedTable = savedProducts => {
    if (savedProducts.length === 0) return;
    return (
      <div className="col-sm" id="product-table-col">
        <div id="adbor-fnn-box" style={{ height: "100%" }}>
          {savedProducts.map((product, index) => {
            let name = product.name;
            let adborFnns = product.adborFnns;
            let ranges = product.ranges;
            let huntGroup = product.huntGroup;
            let mobileData = product.mobileData;
            return (
              <ListGroupItem id="s2p-table-box" key={index}>
                <div className="row" id="product-table-header">
                  <div className="col">
                    <span className="product-header">Product: </span>{" "}
                    <span className="product-table-name">
                      {name.toUpperCase()}
                    </span>
                  </div>
                </div>
                {
                  name === "MOBILE" ?
                  <MobileDataTable 
                  mobileData = {mobileData}
                  />
                  :
                
                <Table id="adbor-fnn-table">
                  <thead>
                    <tr className="heading-table-row">
                      <th>Adbor ID</th>
                      <th>Service ID</th>
                      <th>Inflight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      adborFnns.map((adborFnn, index) => {
                      const { adborId, fnn, inflightOrder } = adborFnn;
                      return (
                        <tr key={index}>
                          <td>{adborId}</td>
                          <td>
                            {fnn !== null && fnn !== undefined
                              ? fnn
                              : "GENERATED"}
                          </td>
                          <td>
                            {inflightOrder ? (
                              <TelstraIcon primary icon="tick" color="green" />
                            ) : (
                              <TelstraIcon
                                primary
                                icon="ui-cross"
                                color="orange"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                }
                {ranges.length > 0 && (
                  <Table id="adbor-fnn-table">
                    <thead>
                      <tr className="heading-table-row">
                        <th >Service Id</th>
                        <th >From</th>
                        <th >To</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ranges.map((range, index) => {
                        const { from, to, serviceId } = range;
                        return (
                          <tr key={index}>
                            <td>{`${serviceId}`}</td>
                            <td>{`${from}`}</td>
                            <td>{`${to}`}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                {huntGroup.length > 0 && (
                  <Table id="adbor-fnn-table">
                    <thead>
                      <tr className="heading-table-row">
                        <th>Fnn</th>
                        <th>Linked FNN</th>
                        <th>Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      {huntGroup.map((data, index) => {
                        const { fnn, linkedFnn, role } = data;
                        return (
                          <tr key={index}>
                            <td>{fnn}</td>
                            <td>{linkedFnn}</td>
                            <td>{role}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </ListGroupItem>
            );
          })}
        </div>
      </div>
    );
  };
  editableTable = (productType, passdownprops, options, msgForRanges, productData, formData) => {
    return (
      <div className="col-sm" id="product-table-col" key={productType}>
        <ListGroupItem style={{ padding: ".50rem .75rem" }}>
          {productType.toUpperCase() === "MOBILE" ? (
            <FieldArray
              name="mobileData"
              passdownprops={passdownprops}
              productType={productType}
              errorMessage={this.props.errorMessage}
              serviceIdField={this.props.serviceIdField}
              component={MobileComponent}
            />
          ) : (
            <FieldArray
              name="adborFnns"
              passdownprops={passdownprops}
              productType={productType}
              errorMessage={this.props.errorMessage}
              serviceIdField={this.props.serviceIdField}
              component={AdborComponent}
            />
          )}
          {productType.toUpperCase() !== "MOBILE" && (
            <DataCollapse
              productData={productData}
              passdownprops={passdownprops}
              errorMessage={this.props.errorMessage}
              serviceIdField={this.props.serviceIdField}
              options={options}
              productType={productType}
              msgForRanges={msgForRanges}
              formData={formData}
            />
          )}
        </ListGroupItem>
      </div>
    );
  };

  render() {
    const {
      productType,
      productData,
      passdownprops,
      serviceIDs,
      msgForRanges,
      formData
    } = this.props;
    const savedProducts =
      productData !== undefined &&
      productData.filter(
        prod => prod.name.toUpperCase() !== productType.toUpperCase()
      );

    const options = serviceIDs.map(data => ({
      label: data.fnn,
      value: data.fnn
    }));

    return (
      <div className="table-container">
        <div className="row">
          {this.savedTable(savedProducts)}
          {
            this.editableTable(
            productType,
            passdownprops,
            options,
            msgForRanges,
            productData,
            formData
          )}
        </div>
        <div className="row"></div>
      </div>
    );
  }
}
