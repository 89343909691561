import React, {Component} from 'react'
import {connect} from 'react-redux';

import {fetchDataStats} from '../../actions/statsActions';
import HeaderContent from '../common/Header/HeaderContent';
import BarGraph from '../common/graphs/BarGraph';

import {Line, Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {prepareDatasets} from '../../utils/chart';

import {Table, Row, Col, Card} from 'reactstrap';
import {FaBoolean} from '../common/fontawesome/FaBoolean';

class DataStatistics extends Component {
    componentDidMount() {
        this
            .props
            .fetchStats();
    }

    generateChartDataset(label, data, color) {
        return ({
            label: label,
            data: data.map(({count, env}) => ({y: count, key: env})),
            backgroundColor: color,
            type: 'bar',
            datalabels: {
                backgroundColor: function (context) {
                    return context.dataset.backgroundColor;
                },
                borderRadius: 4,
                color: 'white',
                font: {
                    weight: 'bold'
                },
                align: 'end',
                anchor: 'end'
            }
        });
    }

    getAvailableCount(key, env) {
        const exists = this
            .props
            .data
            .dataEnvStats[key]
            .filter(d => d.env == env)[0];
        return exists
            ? exists['count']
            : 0;
    }

    renderEnvThresholdsTable = (dataType, tableData) => (tableData).map(({env, threshold, available}) => {
        const thresholdStatus = available >= threshold;
        return (
            <tr key={`${env}-${dataType}`}>
                <th scope="row">{env}</th>
                <td>{dataType}</td>
                <td>{threshold}</td>
                <td>{available}</td>
                <td><FaBoolean
                    boolean={thresholdStatus}
                    falseIcon={"fa-exclamation-triangle text-warning"}/></td>
            </tr>
        )
    })

    render() {
        if (!this.props.data) 
            return <div></div>

        const {dataEnvStats, nbnEnvDataStatuses} = this.props.data;
        const datasets = [];
        datasets.push(this.generateChartDataset("NBN - Available", dataEnvStats["nbnEnvGoodToUse"], "#3e95cd"));
        datasets.push(this.generateChartDataset("ADSL - Available", dataEnvStats["adslEnvGoodToUse"], "#B22222"));

        const fixedDatasets = prepareDatasets(datasets);
        const threshold = ({
            label: 'NBN - Thresholds',
            data: [
                1950,
                1020,
                1750,
                1050,
                1050,
                950
            ],
            backgroundColor: "purple",
            borderColor: "purple",
            // Changes this dataset to become a line
            type: 'line',
            fill: false,
            borderDash: [
                5, 5
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ],
            datalabels: {
                backgroundColor: function (context) {
                    return context.dataset.backgroundColor;
                },
                borderRadius: 4,
                color: 'white',
                font: {
                    weight: 'bold'
                },
                formatter: Math.round
            }
        });

        const labels = fixedDatasets[0]
            .data
            .map(d => d.key);

        const chartData = {
            labels,
            datasets: [...fixedDatasets]
        }
        const options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            },
            plugins: {
                datalabels: {
                    formatter: function (value, context) {
                        return value['y'];
                    }
                }
            }
        }

        const nbnTableData = dataEnvStats["nbnEnvThresholds"].map(threshold => {
            const available = this.getAvailableCount("nbnEnvGoodToUse", threshold.env);
            return {
                ...threshold,
                available
            }
        });

        const adslTableData = dataEnvStats["adslEnvThresholds"].map(threshold => {
            const available = this.getAvailableCount("adslEnvGoodToUse", threshold.env);
            return {
                ...threshold,
                available
            }
        });
        console.log(nbnTableData);
        console.log(adslTableData);

        return (
            <div className="white">
                <HeaderContent
                    title="Vacant Data Statistics"
                    icon="bar-chart"
                    loading={false}
                    empty={true}
                    loadingText="Loading data statistics.."></HeaderContent>
                <Row>
                    <Col xs='6'>
                        <Card
                            style={{
                            "padding": "20px"
                        }}>
                            <h5>Address Data Availability Graph</h5>
                            <Bar data={chartData} options={options}/>
                        </Card>

                    </Col>
                    <Col xs='6'>
                        <Card
                            style={{
                            "padding": "20px"
                        }}>
                            <h5>Address Data Threshold Statuses</h5>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Environment</th>
                                        <th>Data Type</th>
                                        <th>Threshold</th>
                                        <th>Available</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderEnvThresholdsTable("NBN", nbnTableData)}
                                    {this.renderEnvThresholdsTable("ADSL", adslTableData)}
                                </tbody>
                            </Table>
                        </Card>

                    </Col>
                    <Col xs='6'>
                        <Card
                            style={{
                            "padding": "20px"
                        }}>
                            <h5>Address Data Incomplete Statuses (NBN)</h5>
                            <Table>
                                <thead>
                                    <th>Environment</th>
                                    <th>ADBOR</th>
                                    <th>LISA</th>
                                    <th>EAST</th>
                                    <th>NBN Capable</th>
                                    <th>Count</th>
                                    <th>Available</th>
                                </thead>
                                <tbody>
                                    {(nbnEnvDataStatuses).filter(status => !status.goodToUse).map(({
                                        env,
                                        adborInserted,
                                        lisaInserted,
                                        eastValidated,
                                        nbnCapability,
                                        goodToUse,
                                        count
                                    }) => <tr>
                                        <th scope="row">{env}</th>
                                        <td>{< FaBoolean boolean = {
                                                adborInserted
                                            }
                                            falseIcon = {
                                                "fa-times text-danger"
                                            } />}</td>
                                        <td>{< FaBoolean boolean = {
                                                lisaInserted
                                            }
                                            falseIcon = {
                                                "fa-times text-danger"
                                            } />}</td>
                                        <td>{< FaBoolean boolean = {
                                                eastValidated
                                            }
                                            falseIcon = {
                                                "fa-times text-danger"
                                            } />}</td>
                                        <td>{< FaBoolean boolean = {
                                                nbnCapability
                                            }
                                            falseIcon = {
                                                "fa-times text-danger"
                                            } />}</td>
                                        <td>{count}</td>
                                        <td>{< FaBoolean boolean = {
                                                goodToUse
                                            }
                                            falseIcon = {
                                                "fa-times text-danger"
                                            } />}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </Card>

                    </Col>
                </Row>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {data: state.stats.data}
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchStats: () => {
            dispatch(fetchDataStats())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataStatistics);
