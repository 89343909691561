import React, { useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, FormHelperText } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { insertNewronData } from '../../actions/tdmOpsActions'
import { displayErrorNew, displayLoading, displaySuccess } from "../common/alerts/alerts";

const validationSchema = Yup.object({
  addressId: Yup.string()
    .matches(/^\d{8,9}$/, 'Must be an integer with 8 to 9 digits')
    .required('Address ID is required'),
  networkType: Yup.string()
    .required('Network Type is required'),
  action: Yup.string()
    .required('Action is required'),
  antennaAction: Yup.boolean()
});

const FormComponent = (props) => {
  const formik = useFormik({
    initialValues: {
      addressId: '',
      networkType: '',
      action: '',
      antennaAction: ''
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { resetForm }) => {
      let antennaAction = values.antennaAction;
      displayLoading({ title: "Loading", bodyText: "Please wait for some time" })
      const payload = {
        adborID: values.addressId,
        classCode: Number(values.networkType),
        action: values.action === 'Sets status to available' ? 'set_available' : 'set_not_available',
        antennaAction: antennaAction === '' ? '' : (antennaAction ? 'override_antenna_required' : 'override_antenna_notrequired'),
        timestamp: new Date().toISOString(),
        executedBy: props?.user
      };
      props.insertNewronData(payload).then(response => {
        displaySuccess({ title: response?.data?.responseStatus, text: response?.data?.responseDescription });
        resetForm();
      }).catch(error => {
        let title;
        let errorMsg;
        if (error?.response?.data?.message) {
          title = error.response.data.message;
          errorMsg = error.response.data.details;
        } else {
          title = "Error";
          errorMsg = "An error occurred while retrieving the dataset. Please try again after some time.";
        }
        displayErrorNew({
          title,
          errorMsg,
        });
      });;
    },
  });
  useEffect(() => {
    formik.validateForm();
  }, [formik.values]);
  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <TextField
        fullWidth
        id="addressId"
        name="addressId"
        label="Address ID"
        value={formik.values.addressId}
        onChange={(e) => {
          formik.handleChange(e);
          formik.setFieldTouched('addressId', true, true);
        }}
        error={formik.touched.addressId && Boolean(formik.errors.addressId)}
        helperText={formik.touched.addressId && formik.errors.addressId}
        required
        onBlur={formik.handleBlur}
      />
      <br /><br />
      <FormControl fullWidth error={formik.touched.networkType && Boolean(formik.errors.networkType)} required>
        <InputLabel>Network Type</InputLabel>
        <Select
          id="networkType"
          name="networkType"
          value={formik.values.networkType}
          onChange={formik.handleChange}
          required
        >
          <MenuItem value="1">4G Fixed Wireless (Voice)</MenuItem>
          <MenuItem value="2">4G Fixed Wireless (Data)</MenuItem>
          <MenuItem value="11">5G Fixed Wireless</MenuItem>
        </Select>
        <FormHelperText>{formik.touched.networkType && formik.errors.networkType}</FormHelperText>
      </FormControl> <br /><br />
      <FormControl fullWidth error={formik.touched.action && Boolean(formik.errors.action)} required>
        <InputLabel>Action</InputLabel>
        <Select
          id="action"
          name="action"
          value={formik.values.action}
          onChange={formik.handleChange}
        >
          <MenuItem value="Sets status to available">Available</MenuItem>
          <MenuItem value="Sets status to not available">Not available</MenuItem>
        </Select>
        <FormHelperText>{formik.touched.action && formik.errors.action}</FormHelperText>
      </FormControl> <br /><br />
      <FormControl fullWidth >
        <InputLabel>Antenna Action</InputLabel>
        <Select
          id="antennaAction"
          name="antennaAction"
          value={formik.values.antennaAction}
          onChange={formik.handleChange}
          fullWidth
        >
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </Select>
      </FormControl> <br /><br />
      <Button color="primary" variant="contained" fullWidth type="submit" disabled={!formik.isValid || !formik.dirty}>
        Submit
      </Button>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    user: state?.auth?.user?.UserName
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    insertNewronData: (payload) => dispatch(insertNewronData(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormComponent);
