import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";

const useStyles = makeStyles({
    stateLabel: {
        backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
        color: "#ffffff  !important",
        marginBottom: "20px !important",
        marginLeft: "20px !important"
    },
    stateSelectedLabel: {
        marginBottom: "20px !important",
        backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
        color: "#ffffff !important",
        marginLeft: "20px !important"
    },
    productHeaderLabel: {
        fontSize: "24px !important",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingLeft: "20px !important"
    },
    yesSpecification: {
        width: "650px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    }

});


const StatelessPSTNProduct = (props) => {

    const classes = useStyles();

    const [pstnProducts, setPSTNProducts] = useState(["FNN", "Address"]);
    const [selectedPSTNProduct, setSelectedPSTNProduct] = useState("");
    const [pstnFNNSpecifications, setPstnFNNSpecifications] = useState("");
    const [pstnAddressSpecifications, setPstnAddressSpecifications] = useState("");
    const [pstnFNNSpecificData, setPstnFNNSpecificData] = useState("");
    const [pstnFNNNonSpecificData, setPstnFNNNonSpecificData] = useState("");
    const [pstnAddSpecificData, setPstnAddSpecificData] = useState("");
    const [pstnAddNonSpecificData, setPstnAddNonSpecificData] = useState("");
    const [labelText,setLableText] = useState("")

    const clearSubProducts = () => {
        setPstnFNNSpecifications("");
        setPstnAddressSpecifications("");
        setPstnFNNSpecificData("");
        setPstnFNNNonSpecificData("");
    }

    useEffect(() => {
        clearSubProducts();
    }, [props.clearNBN]);

    const setSpecReqData = (product, value) => {
        let key = "";
        if(product.toUpperCase() === "FNN")
        {
            key = "CUSTOM: VACANT PSTN-ADSL FNN DATA"
        }
        else
        {
            if(product.toUpperCase() ===  "ADDRESS")
           { key = "CUSTOM: VACANT PSTN-ADSL ADDRESS DATA"}
        }
        setLableText(key)
        props.setSpecificRequirement({ [key]: value }, "");

    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the PSTN Product</Typography>
                    {pstnProducts.map((pstn, index) => {
                        return (
                            <Button variant="contained" classes={pstn !== selectedPSTNProduct ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                sx={{ minWidth: 150, minHeight: 150 }} 
                                value={pstn} 
                                onClick={e => { setSelectedPSTNProduct(e.target.value); clearSubProducts(); 
                                setSpecReqData(e.target.value,"")}}>
                                {pstn}
                            </Button>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    {selectedPSTNProduct === "FNN" ?
                        <TextField
                            id="PSTN-FNN-SPECIFIC-DATA"
                            multiline
                            rows={4}
                            helperText="Enter the specific requirement"
                            label={labelText}
                            classes={{ root: classes.yesSpecification }}
                            value={pstnFNNSpecificData}
                            onChange={e => { 
                                setPstnFNNSpecificData(e.target.value);
                                setSpecReqData(selectedPSTNProduct,e.target.value)}}
                        /> : <>
                            {selectedPSTNProduct === "Address" ?
                                <TextField
                                    id="PSTN-ADDRESS-SPECIFIC-DATA"
                                    multiline
                                    rows={4}
                                    helperText="Enter the specific requirement"
                                    label={labelText}
                                    classes={{ root: classes.yesSpecification }}
                                    value={pstnAddSpecificData}
                                    onChange={e => { 
                                        setPstnAddSpecificData(e.target.value); 
                                        setSpecReqData(selectedPSTNProduct,e.target.value)}}
                                /> : <>
                                </>}
                        </>}
                </Grid>
            </Grid>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
        prodData: (value) => dispatch(prodData(value)),
        setSpecificRequirement: (value,sID) => dispatch(setSpecificRequirement(value,sID))
    }
}

export default connect(null, mapDispatchToProps)(StatelessPSTNProduct);