import React, { Component } from 'react'
import { connect } from 'react-redux';
import { fetchMetrics, fetchPasmatSurvey, showSurvey, submitFeedback,fetchQuarter } from "../../actions/surveyAction";
import {fetchAllTeams} from "../../actions/myTeamsActions"
import { setLocation } from "../../actions/userFeedbackActions";
import HeaderContent from '../common/Header/HeaderContent';
import SurveyApp from "../Questionnaire/Questionnaire"
import SurveyPeriod from './SurveyPeriod.js'
import QuantativeReport from "./QuantativeReport.js"
import { Button } from '@material-ui/core';
import MaterialAutoComplete from '../../pages/DataBankCheckoutHistory/subcomponents/SearchFilter/subcomponents/MaterialAutoComplete'
import swal from 'sweetalert2';
import { displayLoading } from "../common/alerts/alerts";
import TrendingFlatSharpIcon from '@material-ui/icons/TrendingFlatSharp';



class Survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      teamId: this.props.teamId,
      myAvailableTeams : this.props.myAvailableTeams,
      quarterValue : ''
    };
    this.fetchSurveyMetrics  = this.fetchSurveyMetrics.bind(this);
    this.formTeamList = this.formTeamList.bind(this);
  }
  
  componentDidMount() {
    this.props.showSurvey(false);
    this.props.fetchAllTeams();
    this.formTeamList();
    this.props.fetchQuarter().then((response) => {
      if(response)
       { 
         this.setState({quarterValue: response})
         this.fetchSurveyMetrics(this.props.userId,this.props.teamId,response)
        }
    });
    this.props.setLocation("UserSurvey")
    
  }
  
  fetchSurveyMetrics(userId,teamId,period) {
    this.props.fetchSurvey(userId,teamId,period).then((response) =>{
      if(response)
      this.props.fetchMetrics(userId,teamId,period);
      else{
        swal.fire(
          "Error",
          `Unable to fetch survey report`,
          "error"
        );
      }
    });
  }
  hideSurvey = () => {
    let data = !(this.props.displayReport)
    this.props.showSurvey(data);
  }
  formTeamList() {
    let loggedinTeam = {};
    this.props.myAvailableTeams.map((team) => {
      if(team.teamId === this.props.teamId)
      {
        loggedinTeam = {teamDisplayName: team.teamDisplayName || team.teamName, 
          teamId:team.teamId ,teamName: team.teamName,
          active: team.active, teamParentProgram: team.teamParentProgram};
      }
    })
    return [loggedinTeam, ...this.props.myAvailableTeams.filter(t => t.teamId !== this.props.teamId)]
  }

  selectTeamAction =(team) => {
    this.setState({teamId: team.teamId})
      this.fetchSurveyMetrics(this.state.userId, team.teamId, this.state.quarterValue);
  }

  handleChange = (e) => {
    this.setState({quarterValue : e.target.value})
    this.fetchSurveyMetrics(this.props.userId,this.state.teamId,e.target.value); 
    this.props.showSurvey(this.props.displayReport);
    }

   feedbackHandler = (rating, comment) => {
   var e1 = document.getElementsByClassName("sv_q_rating_item active")
   var t1 = document.getElementsByTagName("textarea")

   let feedbackComment = {
     "userId":parseInt(this.state.userId),
    "teamId" : this.props.teamId,
    "period" : this.props.qualmetric.period.toUpperCase(),
    "value" : this.props.qualmetric.value,
    "comment" : "",
     "rating" : ""
    }
   if(e1 && e1[0] && e1[0].children && t1 && t1[0])
   {
    feedbackComment = {
      "userId": parseInt(this.state.userId),
     "teamId" : this.props.teamId,
     "period" : this.props.qualmetric.period.toUpperCase(),
    "value" : this.props.qualmetric.value,
     "comment" : t1[0].value,
     "rating" : e1[0].children[0].value
     }
   
   displayLoading({ title: "Submitting Feedback", 
   bodyText: "Please standby whilst data is being updated" });
   this.props.submitFeedback(feedbackComment).then((response)=>{
     swal.close();
     if(response)
     {
      swal.fire(
        "Success",
        `Thanks for the feedback`,
        "success"
      );}
      else
      {
        swal.fire(
          "Error",
          `Unable to update the feedback`,
          "error"
        );
      }
     })
    }
    else
    {
      swal.fire(
        "Error",
        `Unable to submit the feedback ! Please try again by applying a rating and feedback comments`,
        "error"
      );

    }
  }

  render() {
    const { survey, qualmetric, displayReport,myAvailableTeams,primaryRole,quarters} = this.props;
    if (!survey)
      return <div></div>;
    const { loading } = survey;
     return (
      <div className="white">
        {survey.errorMessage && 
        <div>Error occured while fetching the details ,Please try again.</div>}
        <div className="pasmatHeader mb-4">
        <HeaderContent
          title={`Product Adoption Self-Maturity Assessment Report (PASMAT)`}
          icon="handshake-o"
          loading={loading}
          isPasmat={true}
          extraComponentClassName="survey-result"
          loadingText="Loading your assessment values..."></HeaderContent>
          </div>
        <div class="container mt-n2 pb-2 px-0">
        <div class="pasmatHeaderText">
          Collaborative framework to capture qualitative quantitative 
        product adoption metrics across software engineering teams.</div>
          <div class="row">
            <div class="col-sm-8">
            {(primaryRole == "OPS" || primaryRole == "ADMIN")&& 
        myAvailableTeams.length > 0 && 
          <MaterialAutoComplete listOfTeams={this.formTeamList(myAvailableTeams)} 
        selectTeam={this.selectTeamAction}/>}
          </div>
            <div class="col-sm-4 px-0">
            {(!loading && survey && survey.Questionnaire && survey.Questionnaire.period && quarters !== undefined && 
          <SurveyPeriod quarterValue = {this.state.quarterValue} period={survey.Questionnaire.period} 
          value={quarters} handleQuarterChange={this.handleChange} float="float-right"
          pr="pr-0"/>)}
            </div></div></div>


        {(!loading && <QuantativeReport metric={qualmetric} primaryRole={primaryRole} />)}
        {(!loading && !displayReport && survey && survey.Questionnaire && survey.Questionnaire.sections && <SurveyApp payload={survey} onClickHandler={this.feedbackHandler}/>)}
        {(!loading && <div class="container pt-5 mt-9">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-2">
              {(!loading && !displayReport && <Button variant="contained" color="primary" 
              onClick={e => this.hideSurvey()} className="viewReportButton" endIcon ={<TrendingFlatSharpIcon/>}>View Report</Button>)}
            </div></div></div>)}
      </div>
    );
  }




}

const mapStateToProps = state => {
  return {
    survey : state.pasmatTrend.survey,
    qualmetric: state.pasmatTrend.qualmetric,
    isAdmin: state.auth.isAdmin,
    userId: state.auth.userName,
    teamId: state.auth.team,
    displayReport: state.pasmatTrend.displayReport,
    primaryRole : state.auth.primaryRole,
    myAvailableTeams : state.teams.teams.allTeams,
    quarters : state.pasmatTrend.quarters
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSurvey: (userId, teamId,period) => dispatch(fetchPasmatSurvey(userId, teamId,period)),
    fetchMetrics: (userId, teamId,period) => dispatch(fetchMetrics(userId, teamId,period)),
    showSurvey: (value) => dispatch(showSurvey(value)),
    fetchAllTeams: () => dispatch(fetchAllTeams()),
    submitFeedback : (data) => dispatch(submitFeedback(data)),
    fetchQuarter : () => dispatch(fetchQuarter()),
    setLocation:(path) => dispatch(setLocation(path))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Survey);
