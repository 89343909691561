import * as types from '../actions/tconnectActionTypes';

const INITIAL_STATE = {
    dataview: {
        isLoading: false,
        isOrdersLoding: false,
        isOrderSubmitting: false,
        isCheckoutViewLoading: false
    },
    capabilities: [
        "SERVICE_INCIDENT",
        "SERVICE_REQUEST",
        "SERVICE_BILLING",
        "SERVICE_ORDERS",
        "SERVICE_PlATFORM",
        "SERVICE_MONITORING_AND_PERFORMANCE",
        "SERVICE_MY_PRODUCT_AND_SERVICE",
        "SERVICE_TELSTRA_CONNECT_STORE"
    ],
    dataHubOrders: [],
    addContact:{
        failed: false,
        pending: false,
        response:{},
        errorMessage:''
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.TCONNECT_GET_VIEW_DATA_LOADING:
            return {
                ...state,
                dataview: {
                    ...state.dataview,
                    isLoading: true,
                    inError: false
                },
                dataHubOrders: []
            }
        case types.TCONNECT_GET_VIEW_DATA_SUCCESS:
            return {
                ...state,
                dataview: {
                    ...state.dataview,
                    ...action.payload,
                    isLoading: false
                }
            }
        case types.TCONNECT_GET_VIEW_DATA_ERROR:
            return {
                ...state,
                dataview: {
                    isLoading: false,
                    inError: true
                }
            }

        case types.TCONNECT_ONBOARD_USER_SUCCESS:
            return {
                ...state,
                dataview: {
                    ...state.dataview,
                    tconCustomer: {
                        ...state.dataview.tconCustomer,
                        contacts: state.dataview.tconCustomer && state.dataview.tconCustomer.contacts.map(
                            contact => contact.id === action.payload.id ? { ...contact, valid: action.payload.valid } : contact
                        )
                    }                                       
                }
            }
        case types.TCONNECT_GET_ORDERS_LOADING:
            return {
                ...state,
                dataview: {
                    ...state.dataview,
                    isLoading: false,
                    isOrdersLoding: true
                }
            }
        case types.TCONNECT_GET_ORDERS_SUCCESS:
            return {
                ...state,
                dataview: {
                    ...state.dataview,
                    isLoading: false,
                    isOrdersLoding: false
                },
                dataHubOrders: action.payload.dataHubOrders
            }
        case types.TCONNECT_GET_ORDERS_ERROR:
            return {
                ...state,
                dataview: {
                    ...state.dataview,
                    isLoading: false,
                    isOrdersLoding: false
                },
                dataHubOrders: []
            }
        case types.TCONNECT_ADD_CONTACT_LOADING:
            return{
                ...state,
                addContact:{
                    failed: false,
                    pending: true,
                    response:{},
                    errorMessage:''
                }

            }
        case types.TCONNECT_ADD_CONTACT_SUCCESS:
            return{
                ...state,
                addContact:{
                    failed: false,
                    pending: false,
                    response:action.payload,
                    errorMessage:''
                }

            }
        case types.TCONNECT_ADD_CONTACT_ERROR:
            return{
                ...state,
                addContact:{
                    failed: true,
                    pending: false,
                    response:{},
                    errorMessage:action.payload
                }

            }
            case types.TCONNECT_SUBMIT_ORDER_SUCCESS:
                return {
                    ...state,
                    dataview: {
                        ...state.dataview,
                        isLoading: false,
                        isOrderSubmitting : false
                    },
                    dataHubOrders: action.payload.dataHubOrders
                }
             case types.TCONNECT_SUBMIT_ORDER_ERROR:
                return {
                    ...state,
                    dataview: {
                        ...state.dataview,
                        isLoading: false,
                        isOrderSubmitting : false
                    }
                }
        
        case types.TCONNECT_SUBMIT_ORDER_ERROR:
            return {
                ...state,
                dataview: {
                    ...state.dataview,
                    isLoading: false,
                    isOrderSubmitting: false
                }
            }
        default:
            return state;
    }
}