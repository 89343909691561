import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    cart: {},
    orderItems: [],
    orderItemsPending: false,
    orderItemsFailed: false,
    orderItemsFailedMsg: "",
    orderItemsSuccess: false,

    customerInput: {},
    orderPending: false,
    orderFailed: false,
    orderFailedMsg: "",
    orderSuccess: false,

    filterOptions: [],
    filterOptionsPending: false,
    filterOptionsFailed: false,
    filterOptionsFailedMsg: "",
    filterOptionsSuccess: false,

    sprintOptions: [],
    sprintOptionsPending: false,
    sprintOptionsFailed: false,
    sprintOptionsFailedMsg: "",
    sprintOptionsSuccess: false,

    envOptions: [],
    envOptionsPending: false,
    envOptionsFailed: false,
    envOptionsFailedMsg: "",
    envOptionsSuccess: false,

    openOrders: [],
    openOrdersPending: false,
    openOrdersFailed: false,
    openOrdersFailedMsg: "",
    openOrdersSuccess: false,

    availableDataAssets: [],
    availableDataAssetsPending: false,
    availableDataAssetsFailed: false,
    availableDataAssetsFailedMsg: "",
    availableDataAssetsSuccess: false,

    myOrders: [],
    myOrdersPending: false,
    myOrdersFailed: false,
    myOrdersFailedMsg: "",
    myOrdersSuccess: false,

    wishlistPending: false,
    wishlistFailed: false,
    wishlistFailedMsg: "",
    wishlistSuccess: false,

    wishlists: [],
    wishlistsPending: true,
    wishlistsFailed: false,
    wishlistsFailedMsg: "",
    wishlistsSuccess: false,

    irNumbers: [],
    irNumbersPending: false,
    irNumbersFailed: false,
    irNumbersFailedMsg: "",
    irNumbersSuccess: false,

    isCustomOrder : false,
    custom_order_state : "",
    custom_spec_req : {},
    custom_spec_sID :""
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.ADD_TO_ORDER_BUILDER_CART:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    [action.key]: action.value
                }
            }
         case types.ADD_TO_CUSTOM_ORDER_BUILDER_CART:
                return {
                    ...state,
                    cart: {
                        [action.key]: action.value
                    }
                }
        case types.REMOVE_FROM_CUSTOM_ORDER_BUILDER_CART:
           return {
                ...state,
                cart: {}
                }
        case types.REMOVE_FROM_ORDER_BUILDER_CART:
            let prevCart = state.cart;
            delete prevCart[action.key];
            return {
                ...state,
                cart: {
                    ...prevCart
                }
            }
        case types.UPDATE_CUSTOMER_INPUT_ORDER_BUILDER:
            return {
                ...state,
                customerInput: {
                    ...action.input
                }
            }
        case types.FETCH_ORDER_FILTERS_PENDING:
            return {
                ...state,
                filterOptions: [],
                filterOptionsPending: true,
                filterOptionsFailed: false,
                filterOptionsFailedMsg: "",
                filterOptionsSuccess: false,
            }
        case types.FETCH_ORDER_FILTERS_SUCCESS:
            return {
                ...state,
                filterOptions: action.payload,
                filterOptionsPending: false,
                filterOptionsFailed: false,
                filterOptionsFailedMsg: "",
                filterOptionsSuccess: true,
            }
        case types.FETCH_ORDER_FILTERS_FAILED:
            return {
                ...state,
                filterOptions: [],
                filterOptionsPending: false,
                filterOptionsFailed: true,
                filterOptionsFailedMsg: action.payload,
                filterOptionsSuccess: false,
            }
        case types.FETCH_ORDER_SPRINTS_PENDING:
            return {
                ...state,
                sprintOptions: [],
                sprintOptionsPending: true,
                sprintOptionsFailed: false,
                sprintOptionsFailedMsg: "",
                sprintOptionsSuccess: false,
            }
        case types.FETCH_ORDER_SPRINTS_SUCCESS:
            return {
                ...state,
                sprintOptions: action.payload,
                sprintOptionsPending: false,
                sprintOptionsFailed: false,
                sprintOptionsFailedMsg: "",
                sprintOptionsSuccess: true,
            }
        case types.FETCH_ORDER_SPRINTS_FAILED:
            return {
                ...state,
                sprintOptions: [],
                sprintOptionsPending: false,
                sprintOptionsFailed: true,
                sprintOptionsFailedMsg: action.payload,
                sprintOptionsSuccess: false,
            }
        case types.FETCH_ORDER_ENVS_PENDING:
            return {
                ...state,
                envOptions: [],
                envOptionsPending: true,
                envOptionsFailed: false,
                envOptionsFailedMsg: "",
                envOptionsSuccess: false,
            }
        case types.FETCH_ORDER_ENVS_SUCCESS:
            return {
                ...state,
                envOptions: action.payload,
                envOptionsPending: false,
                envOptionsFailed: false,
                envOptionsFailedMsg: "",
                envOptionsSuccess: true,
            }
        case types.FETCH_ORDER_ENVS_FAILED:
            return {
                ...state,
                envOptions: [],
                envOptionsPending: false,
                envOptionsFailed: true,
                envOptionsFailedMsg: action.payload,
                envOptionsSuccess: false,
            }
        case types.FETCH_OPEN_ORDERS_PENDING:
          return {
              ...state,
              openOrders: [],
              openOrdersPending: true,
              openOrdersFailed: false,
              openOrdersFailedMsg: "",
              openOrdersSuccess: false,
          }
        case types.FETCH_OPEN_ORDERS_SUCCESS:
            return {
                ...state,
                openOrders: action.payload,
                openOrdersPending: false,
                openOrdersFailed: false,
                openOrdersFailedMsg: "",
                openOrdersSuccess: true,
            }
        case types.FETCH_OPEN_ORDERS_FAILED:
            return {
                ...state,
                openOrders: [],
                openOrdersPending: false,
                openOrdersFailed: true,
                openOrdersFailedMsg: action.payload,
                openOrdersSuccess: false,
            }
        case types.FETCH_AVAILABLE_DATA_ASSETS_PENDING:
          return {
              ...state,
              availableDataAssets: [],
              availableDataAssetsPending: true,
              availableDataAssetsFailed: false,
              availableDataAssetsFailedMsg: "",
              availableDataAssetsSuccess: false,
          }
        case types.FETCH_AVAILABLE_DATA_ASSETS_SUCCESS:
            return {
                ...state,
                availableDataAssets: action.payload,
                availableDataAssetsPending: false,
                availableDataAssetsFailed: false,
                availableDataAssetsFailedMsg: "",
                availableDataAssetsSuccess: true,
            }
        case types.FETCH_AVAILABLE_DATA_ASSETS_FAILED:
            return {
                ...state,
                availableDataAssets: [],
                availableDataAssetsPending: false,
                availableDataAssetsFailed: true,
                availableDataAssetsFailedMsg: action.payload,
                availableDataAssetsSuccess: false,
            }
        case types.FETCH_MY_ORDERS_PENDING:
          return {
              ...state,
              myOrdersPending: true,
              myOrdersFailed: false,
              myOrdersFailedMsg: "",
              myOrdersSuccess: false,
          }
        case types.FETCH_MY_ORDERS_SUCCESS:
            return {
              ...state,
              myOrders: action.payload,
              myOrdersPending: false,
              myOrdersFailed: false,
              myOrdersFailedMsg: "",
              myOrdersSuccess: true,
            }
        case types.FETCH_MY_ORDERS_FAILED:
            return {
              ...state,
              myOrdersPending: false,
              myOrdersFailed: true,
              myOrdersFailedMsg: action.payload,
              myOrdersSuccess: false,
            }
        case types.FETCH_MY_ORDERS_RESET:
            return {
              ...state,
              myOrders: [],
              myOrdersPending: false,
              myOrdersFailed: false,
              myOrdersFailedMsg: "",
              myOrdersSuccess: false,
            }
        case types.FETCH_SERVICE_LIST_PENDING:
          return {
              ...state,
              orderItems: [],
              orderItemsPending: true,
              orderItemsFailed: false,
              orderItemsFailedMsg: "",
              orderItemsSuccess: false,
          }
        case types.FETCH_SERVICE_LIST_SUCCESS:
            return {
                ...state,
                orderItems: action.payload,
                orderItemsPending: false,
                orderItemsFailed: false,
                orderItemsFailedMsg: "",
                orderItemsSuccess: true,
            }
        case types.FETCH_SERVICE_LIST_FAILED:
            return {
                ...state,
                orderItems: [],
                orderItemsPending: false,
                orderItemsFailed: true,
                orderItemsFailedMsg: action.payload,
                orderItemsSuccess: false,
            }
        case types.SEND_ORDER_PENDING:
          return {
              ...state,
              orderPending: true,
              orderFailed: false,
              orderFailedMsg: "",
              orderSuccess: false,
          }
        case types.SEND_ORDER_SUCCESS:
          return {
              ...state,
              orderPending: false,
              orderFailed: false,
              orderFailedMsg: "",
              orderSuccess: true,
          }
        case types.SEND_ORDER_FAILED:
          return {
              ...state,
              orderPending: false,
              orderFailed: true,
              orderFailedMsg: action.payload,
              orderSuccess: false,
          }
        case types.SAVE_WISHLIST_PENDING:
            return {
                ...state,
                wishlistPending: true,
                wishlistFailed: false,
                wishlistFailedMsg: "",
                wishlistSuccess: false,
            }
        case types.SAVE_WISHLIST_SUCCESS:
            return {
                ...state,
                wishlistPending: false,
                wishlistFailed: false,
                wishlistFailedMsg: "",
                wishlistSuccess: true,
            }
        case types.SAVE_WISHLIST_FAILED:
            return {
                ...state,
                wishlistPending: false,
                wishlistFailed: true,
                wishlistFailedMsg: action.payload,
                wishlistSuccess: false,
            }
        case types.FETCH_WISHLISTS_PENDING:
            return {
                ...state,
                wishlists: [],
                wishlistsPending: true,
                wishlistsFailed: false,
                wishlistsFailedMsg: "",
                wishlistsSuccess: false,
            }
        case types.FETCH_WISHLISTS_SUCCESS:
            return {
                ...state,
                wishlists: action.payload,
                wishlistsPending: false,
                wishlistsFailed: false,
                wishlistsFailedMsg: "",
                wishlistsSuccess: true,
            }
        case types.FETCH_WISHLISTS_FAILED:
            return {
                ...state,
                wishlists: [],
                wishlistsPending: false,
                wishlistsFailed: true,
                wishlistsFailedMsg: action.payload,
                wishlistsSuccess: false,
            }
        case types.FETCH_IRNUMBERS_PENDING:
            return {
                ...state,
                irNumbers: [],
                irNumbersPending: true,
                irNumbersFailed: false,
                irNumbersFailedMsg: "",
                irNumbersSuccess: false,
            }
        case types.FETCH_IRNUMBERS_SUCCESS:
            return {
                ...state,
                irNumbers: action.payload,
                irNumbersPending: false,
                irNumbersFailed: false,
                irNumbersFailedMsg: "",
                irNumbersSuccess: true,
            }
        case types.FETCH_IRNUMBERS_FAILED:
            return {
                ...state,
                irNumbers: [],
                irNumbersPending: false,
                irNumbersFailed: true,
                irNumbersFailedMsg: action.payload,
                irNumbersSuccess: false,
            }
        case types.IS_CUSTOM_ORDER:
            return {
                ...state,
                isCustomOrder : action.payload
            }
        case types.CUSTOM_ORDER_STATE: 
            return {
                ...state,
                custom_order_state : action.payload
            }
        case types.CUSTOM_SPECIFIC_REQ:
            return {
                ...state,
                custom_spec_req : action.payload,
                custom_spec_sID : action.serviceID
            }
        case types.RESET_ORDER_BUILDER:
            return INITIAL_STATE;
        case types.FORM_RESET_ORDER_BUILDER:
            return {
              ...state,
              cart: {},
              customerInput: {},
              orderFailed: false,
              orderFailedMsg: "",
              orderSuccess: false,
            }
        default:
            return state;
    }
}
