import React, { Component } from 'react'
import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #4a00b3 0, #4c00b3 100%)'
}

const UserTrainingTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-green"
        data-size="medium"
        to="/userBadges"
        data-effect="hover-zoom-up">
        <div class="slide-front">
            <span className="fa fa-trophy icon"></span>
            <span className="branding-bar">Training Championships</span>
        </div>
        <div
            class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
            <p class="text-center">
                View user training badges and details related to it
            </p>
        </div>
    </LinkTile>
)

UserTrainingTile.propTypes = {}

export default UserTrainingTile;