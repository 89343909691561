import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';

export default class PaginationController extends Component {

    getPaginationLinks() {
        var links = new Array(this.props.totalPages).fill(0).map((zero, index) => (
            <PaginationItem active={this.props.currentPage == index+1} key={index}>
                    <PaginationLink onClick={() => this.props.gotoPage(index)}>
                        {index+1}
                    </PaginationLink>
            </PaginationItem>
        ));
        return links;
    }

    gotoPrevious() {
        this.props.gotoPage(this.props.currentPage - 2);
    }

    gotoNext() {
        this.props.gotoPage(this.props.currentPage);
    }

    render() {
        console.log("this.props pagination controller", this.props);
        return (
            <Pagination>
                <PaginationItem disabled={this.props.isFirst}>
                    <PaginationLink previous onClick={() => this.gotoPrevious()}>Previous</PaginationLink>
                </PaginationItem>
                {this.getPaginationLinks()}
                <PaginationItem disabled={this.props.isLast}>
                    <PaginationLink next onClick={() => this.gotoNext()}>Next</PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink> Total:{this.props.totalItems}</PaginationLink>
                </PaginationItem>
                
            </Pagination>
        )
    }
}
