import React, {Component} from 'react'
import {CSVLink} from 'react-csv';
export const CSVDownloadTile = ({label, icon, items, filename, color}) => {
    return ((items && items.length > 0) && (
        <div className={`tiles-grid base-green grid-flex`}>
            <CSVLink
                data={items}
                filename={filename
                ? `${filename}.csv`
                : "tdm-data.csv"}
                >
                <span >{label
                        ? label
                        : "Download CSV"}</span>
            </CSVLink>
        </div>
    ));
};