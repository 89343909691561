import { useState, useEffect } from "react";
import { connect } from "react-redux";
import TextField from '@mui/material/TextField';
import { Button, CircularProgress } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Typography} from "@material-ui/core";
  import { IconButton } from '@material-ui/core';
  import InputAdornment from '@mui/material/InputAdornment';
  import swal from "sweetalert2";
  import { displayLoading } from "../../common/alerts/alerts";
import FreeTextInputSamples from "./FreeTextInputSamples";
import {submitBulkOrder} from '../../../actions/timMigrationActions'

  const TimMigrationFixedTypeForm= (props) =>{
    const [fixedTypeInput, setFixedTypeInput] = useState(null);
    const[fixedTypeInputError, setFixedTypeInputError] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const timMigrationType = props.timMigrationType.label;
    const [expandSampleFields, setExpandSampleFields] = useState(false);
    const[freeTextExamples, setFreeTextExamples]=useState(FreeTextInputSamples);

    useEffect(() => {
        setFixedTypeInput(null);        
      }, [timMigrationType]);

    const handleFixedTypeInputChange = (e) =>{
        setFixedTypeInput(e.target.value);
        if(e?.target?.value?.length > 0){
            setButtonDisabled(false);    
        }else{
            setButtonDisabled(true);
        }
    }

    const handleFixedTypeInputBlur = (e) =>{
        e.target.value ? setFixedTypeInputError(false) : setFixedTypeInputError(true);
    }

    const submitBulkOrder = (e) => {
        const req = {
            type : timMigrationType === 'Free Text Input' ? 'FreeTextInput' : null,
            batchCreationPayload : fixedTypeInput 
        }
        displayLoading({ title: "Tim Migration In Progress", bodyText: "Please standby" });
        props.submitBulkOrder(req).then((response) => {
            if(response.jobId && response.batchId)
            {
                let message = "<big>"+response.message+"</big></br><p>Job ID : "+response.jobId+"</p></br><p>Batch ID : "+response.batchId+"</p>";
                swal.fire({
                  type: 'success',
                  html : message
              })           
            }else{
                let errorMessage = (response.error?.message.length > 0) ? response.error.message[0] : response.status
                swal.fire('Tim Migration Failed!',
                (errorMessage === null || errorMessage === undefined) ? response : errorMessage,
                'error'
              ) 
            }
        })
        
      }

    const handleExpandSampleFieldsCollapse =()=>{
        setExpandSampleFields(!expandSampleFields);
    }

    const handleCopyText = (fixedTypeInputSample) => {
        navigator.clipboard.writeText(getFilteredText(fixedTypeInputSample.name));
        document.execCommand('copy');
      const updateCopied = freeTextExamples.map( (item) =>{
            if(item.name === fixedTypeInputSample.name){
              item.copied = true
              return item
            }
            item.copied=false;
            return item
        } )
        setFreeTextExamples(updateCopied);
    }
    const getFilteredText=(sampleName) =>{
        return freeTextExamples.find(sample => sample.name.includes(sampleName)).value;
    }
  return <>
    {
        timMigrationType === 'Free Text Input'?
        <>
        <Accordion expanded={expandSampleFields} onChange={handleExpandSampleFieldsCollapse}>
        <AccordionSummary sx={{backgroundColor:'#1976d2', height:'0px !important' }}
          expandIcon={<ExpandCircleDownIcon/>}
        >
        <Typography style={{color:'white'}}>Sample Free Text Inputs</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className="container">
        <div class="row">
          {
          freeTextExamples.map(fixedTypeInputSample =>(
          <div className="col-12">

          <TextField
          className="freeTextSampleInput"
          id="fullWidth"
          label={fixedTypeInputSample.name}
          variant="filled"
          color="primary"
          multiline
          rows={6}
          value={fixedTypeInputSample.value}
         defaultValue={"sample1"}
          fullWidth
          InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="top" color="success">
                  <Button onClick={() => handleCopyText(fixedTypeInputSample)} className="copy-icon-container" color={fixedTypeInputSample.copied ? "success" : "primary"} >
                      <i className="material-icons copy-icon">  <ContentCopyIcon /></i>
                      {fixedTypeInputSample.copied ? "Copied" : "Copy"}
                  </Button>
                  </IconButton>
                </InputAdornment>
              ),
              readOnly: true,
            }}
          InputLabelProps={{
            sx: {
              color: "purple",
              fontSize: "20px !important",
              fontWeight: 1000,
              "&.MuiOutlinedInput-notchedOutline": { fontSize: "28px" }
            }
          }}
          />
  </div>))
          }
        <div className="col-12">
        </div>
        </div>
            <br />
        </div>
        </AccordionDetails>
        </Accordion >
        <br/>
        <Accordion expanded={true}>
        <AccordionSummary sx={{backgroundColor:'#1976d2', height:'0px !important' }}
        >
        <Typography style={{color:'white'}}>Free Text Input</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <div className="checkinContainer container">
        <div class="row">
        {" "}
        <div className="col-1"></div>
        <div className="col-12">
            <TextField
                id="fullWidth"
                label="Input String"
                variant="filled"
                color="primary"
                multiline
                rows={6}
                required={true}
                value={fixedTypeInput}
                error={fixedTypeInputError}
                onBlur={(e) => handleFixedTypeInputBlur(e)}
                onChange={(e) => handleFixedTypeInputChange(e)}
                fullWidth
                helperText={
                    !fixedTypeInputError
                    ? ""
                    : "Input cannot be empty"
                }
            />
        </div>
        </div>
            <br />
        </div>
        </AccordionDetails>
        </Accordion >
        <br/>
        </>: <> </>
    }
        <div>
        <Button variant="contained"  sx={{float:'right'}} disabled={buttonDisabled} onClick={(e) => submitBulkOrder(e)} endIcon={<SendIcon/>}>Submit</Button>
        </div>
    </>
}

const mapStateToProps = state => {
    return {
        submitBulkOrderLoading : state.timMigration.submitBulkOrderLoading,
        timExistingMigrationScenarios: state.utility.filters.timExistingMigrationScenarios,
        timNewMigrationScenarios: state.utility.filters.timNewMigrationScenarios,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
      submitBulkOrder: (req) => dispatch(submitBulkOrder(req)),
    };
  };

  export default connect(mapStateToProps, mapDispatchToProps)(TimMigrationFixedTypeForm);