import React from 'react';
import { Typography } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IOSSwitch from '../styledComponents/IosSwitch';
import HtmlTooltip from '../styledComponents/CustomToolTip';

export default ({ serviceMigLoading, smBtnInfo, smReq, handleServiceMigration }) => (
    <FormGroup style={{ marginLeft: '15em' }}>
        {!serviceMigLoading && smBtnInfo.showSmBtn && <HtmlTooltip placement='top'
            title={
                <React.Fragment>
                    <Typography color="inherit">{smBtnInfo.tooltip}</Typography>
                </React.Fragment>
            }
            arrow="true"
        >
            {(<FormControlLabel className='checking'  labelPlacement="start"
                control={<IOSSwitch checked={smBtnInfo.disabled} onChange={(e) => handleServiceMigration(smReq, smBtnInfo.disabled, serviceMigLoading)} name="checkedB" />}
                label="SM Opt-In :"
            />)}

        </HtmlTooltip>}
        {serviceMigLoading && !smBtnInfo.disabled ? (<div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">Loading...</span>
        </div>) : ''}
    </FormGroup>
)