import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import TextField from "@material-ui/core/TextField/TextField";
import {retrieveCustomCheckinAssets,resetOpsState,uploadAndParseFile,fetchRequestIdFilters} from '../../actions/customCheckinActions';
import CustomTableComponent from './CustomTableComponent';
import { Button } from 'reactstrap'
import SpinnerComponent from './SpinnerComponent';
import EmptyAssetsTableComponent from './EmptyAssetsTableComponent';
import ErrorView from './ErrorView';
import './ops.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = theme => ({
    root: {
       width: "90%",
       "& .MuiAutocomplete-inputRoot": {
        padding: 0
      },
      "& .MuiOutlinedInput-adornedEnd": {
        padding: 0
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd" : {
            padding : "5px !important"
      },
      "& .MuiAutocomplete-input.MuiOutlinedInput-inputAdornedEnd.MuiInputBase-inputTypeSearch":{
        padding: "5px",
        border : 0
      },
      "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd" :{
        paddingRight: "10px"
      } 
     },
     textInput : {
        width: "90%",
        "& .MuiInputBase-inputAdornedStart.MuiOutlinedInput-inputAdornedStart":{
            padding: "5px",
            border : 0
        }
      },
      customInput : {
        padding:"3px 0px 0px 0px!important",
        "& .MuiInputBase-root":{
            padding : "5px !important"
        },
        "& .MuiInputBase-input":{
            padding: "9px !important"
        }
      }
});

class CustomCheckin extends React.Component {
    constructor(props){
        super(props);
    }

  
    state ={
        requestId : '',
        requestIdError:false,
        qty :0,
        scenarioName :'',
        scenarioNameError :false,
        selectedFile : '',
        reqFilters : []
    }
   
    
    componentDidMount(){
        this.props.fetchRequestIdFilters('userStory',"").then(result =>{
            if(result.data){
                this.setState({reqFilters : result.data})
            }
        });
    }


    handleRequestIdOnChange = (e) => {
        this.props.resetOpsState();
        let val=e.target.value;
        if(!val || val.length > 10 || !val.match(/^[A-Z0-9]+$/)){
            this.setState({
                requestIdError : true,
            },()=>{
                if(val === ''){
                    this.setState({requestIdError : false})
                }
            })  
        }else{
            this.setState({
                requestIdError : false
            });
        }

        this.setState({
            requestId: val,
            qty : 0,
            scenarioName :'',
            scenarioNameError : false
        })

        this.props.fetchRequestIdFilters('userStory',val).then(result =>{
            if(result.data){
                this.setState({reqFilters : result.data})
            }
        });   
      }

      inValidScenarioname = () =>{
            this.setState({scenarioNameError : true})
      }

      handleScenarioNameOnChange=(e) =>{
        let val=e.target.value;
        if(val.length > 55){
            this.setState({
                    scenarioNameError :true
            },()=>{
                if(this.state.scenarioName === ''){
                    this.setState({scenarioNameError : false})
                }
            })
        }else{
            this.setState({scenarioNameError : false})
        }
        this.setState({
            scenarioName: val
          });
      }

    handleRetrieveAssets = (e, newValue) =>{
        this.setState({ requestId : e.target.value })
        let {requestId} = this.state;
        if(!newValue || newValue==='undefined'){
            if(requestId === '' || requestId.length > 10 || !requestId.match(/^[A-Z0-9]+$/))
            {
                this.setState({requestIdError : true})
            }
        }
        if(newValue && newValue !=='undefined'){
            this.setState({
                requestIdError : false,
                requestId : newValue
            })
        }
        this.setState({
            qty : 0,
            scenarioName :'',
            scenarioNameError : false
        })
        this.props.resetOpsState();
        const request= {
            "userStory":newValue ? newValue : requestId
        }
        this.state.requestIdError === false && this.props.retrieveCustomCheckinAssets(request).then((response) =>{
            response.qty && this.setState({
                qty : response.qty,
                scenarioName : response.scenarioName
            })
        })
    }
    
    
    capitalizeFirstLetter = (string) =>  {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    fileUploadHandler =() =>{
        const data = new FormData() 
        data.append('file', this.state.selectedFile)
        this.props.uploadAndParseFile(data).then(result =>{
            if(result && result.customDetails){
                this.setState({
                    qty : result.customDetails.length
                })
            }else{
                this.setState({
                    qty : 0
                })
            }
        });
    }

    fileSelectedHandler = (event) => {
        this.setState({
            selectedFile: event.target.files[0]
        }, () => this.fileUploadHandler());
      };

    handleFileUploadClick = (e) =>{
        document.getElementById('hiddenFileInput').click();
    }

    refreshTableData = () => {
        this.props.resetOpsState();
        const request= {
            "userStory":this.state.requestId
        }
        this.state.requestIdError === false && this.props.retrieveCustomCheckinAssets(request).then((response) =>{
            response.qty && this.setState({
                qty : response.qty,
                scenarioName : response.scenarioName
            })
        })
    }

    render() {
        const { classes } = this.props;
        const { requestId,qty,scenarioName,
            requestIdError,scenarioNameError,reqFilters} = this.state;
        const {assets,tableData,requestIdFilters,selectedEnvValue} = this.props;
        const noAssetsFound=assets.status && assets.status==='NOT_FOUND' && assets.assetsLoading === false;
        const isFilterLoading = requestIdFilters && requestIdFilters.filterLoading===true;
        return(
            <React.Fragment>
            <div className="row">
                <div className="col-sm-12" style={{textAlign: "right"}}>
                        <label htmlFor="myInput">
                            <Button className="btn btn-upload" style={{background : "none !important", border : "none !important", color : "#1ba1e2"}} 
                             disabled ={_.isEmpty(requestId)} onClick={this.handleFileUploadClick}><i className='fa fa-file-excel-o fa-3x' aria-hidden="true"></i></Button>
                        </label>
                        <input
                            type="file"
                            id="hiddenFileInput"
                            onChange={this.fileSelectedHandler}
                            onClick={(event)=> { 
                                event.target.value = ""
                                this.setState({selectedFile : ''})
                           }}
                            style={{display: 'none'}}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />                     
                </div>
                <div  className="col-sm-4 col-lg-4">
                        <span className="requestFilterLabel">Request Id  </span>
                        <Autocomplete
                            className={classes.customInput}
                            disableClearable
                            freeSolo
                            id="free-solo-2-demo"
                            options={reqFilters && reqFilters}
                            onChange={this.handleRetrieveAssets}
                            
                            renderInput={(params) => (
                            <TextField 
                                {...params}
                                color="primary"
                                variant="outlined"
                                value={requestId}
                                error={requestIdError}
                                helperText={requestIdError ? 'Invalid RequestId' : ''}
                                onChange={this.handleRequestIdOnChange}
                                onKeyPress={(ev) => {
                                    if (ev.key === 'Enter') {
                                        this.handleRetrieveAssets(ev)
                                    }
                                  }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ ...params.InputProps, type: 'search',
                                endAdornment: (
                                    <InputAdornment position="end">
                                      <SearchIcon color="primary"/>
                                    </InputAdornment>
                                  )}}
                            />
                            )}
                        />
                </div>
                <div  className="col-sm-6 col-lg-6">
                    <span className="requestFilterLabel">Scenario Name  </span>
                    <TextField id="outlined-primary" 
                    className={classes.customInput}
                     style = {{width: "90%"}}
                    disabled = {noAssetsFound ? false : true}
                    variant="outlined"  
                    color="primary" 
                    value={scenarioName}
                    error={scenarioNameError}
                    helperText={scenarioNameError ? 'Invalid Scenario Name' : ''}
                    onChange={this.handleScenarioNameOnChange}
                    InputLabelProps={assets.scenarioName && { shrink: true }}
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineIcon color="primary"/>
                          </InputAdornment>
                        ),
                      }}
                    />
                </div>
                <div  className="col-sm-2 col-lg-2">
                    <span className="requestFilterLabel">Quantity  </span>
                    <TextField id="outlined-primary" 
                    className={classes.customInput}
                        style = {{width: "90%"}}
                        label="Qty" 
                        variant="outlined" 
                        color="primary"
                        value={qty}
                        disabled
                        InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FormatListNumberedIcon color="primary"/>
                              </InputAdornment>
                            ),
                          }}
                    />
                </div>
                {tableData && tableData?.customDetails && <EmptyAssetsTableComponent
                inValidScenarioname={this.inValidScenarioname}

                refreshTableData={this.refreshTableData}

                requestId={this.state.requestId} scenarioName={this.state.scenarioName}/>}

                {tableData?.contentsError?.errorDescription && 
                        <div className="col-sm-12">
                            <ErrorView message={tableData.contentsError.errorDescription?.message[0]}/>
                        </div>
                }

                {tableData && tableData?.contentsLoading === true && <SpinnerComponent />}
                {assets.assetsLoading === true && <SpinnerComponent />}
                {assets.assetDetails && assets.assetsLoading === false && 
                <CustomTableComponent
                assetDetails={assets.assetDetails} 
                capitalizeFirstLetter={this.capitalizeFirstLetter} 
                requestId={this.state.requestId}
                scenarioName={this.state.scenarioName}
                selectedEnvValue={selectedEnvValue}
                />}
                {assets.assetsLoading === false && !noAssetsFound && assets.description  &&
                    <ErrorView message ={assets.description} />
                }
            </div>  
            </React.Fragment>   
        )
    }
}

const mapStateToProps = state => {
    return {
        assets : state.customCheckin.assets,
        tableData : state.customCheckin.fileContents,
        requestIdFilters : state.customCheckin.requestIdFilters,
        selectedEnvValue : state.auth.selectedEnvValue
    };
};

const mapDispatchToProps = dispatch => {
    return {
        retrieveCustomCheckinAssets: (request) => dispatch(retrieveCustomCheckinAssets(request)),
        resetOpsState :() => dispatch(resetOpsState()),
        uploadAndParseFile: (data) => dispatch(uploadAndParseFile(data)),
        fetchRequestIdFilters : (key, value) => dispatch(fetchRequestIdFilters(key, value))
    };
  };
// export default connect(mapStateToProps,mapDispatchToProps)(withStyles(useStyles)(CustomCheckin));

export default compose(
    withStyles(useStyles, {
      name: 'CustomCheckin',
    }),
    connect(mapStateToProps,mapDispatchToProps),
  )(CustomCheckin);