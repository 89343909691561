import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, Grid } from '@material-ui/core';
import { 
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material/";
import {
    FormTextField,
    FormAutoComplete,
    FormTextArea
  } from "@library/core/form-elements";
import ContentBody from "@library/core/surfaces/ContentBody";
import { EnvirnomentLabels } from '../auth/functions';
import { saveSyntheticTypeData, clearDataFromCart } from '../../actions/syntheticDataActions';

const useStyles = makeStyles({
    headerToggleGroup: {
      height: "75px !important",
      border: "1px solid #cfcfcf",
      marginTop: "9px",
    },
});

const CreateSyntheticDatasetStep = (props) => {
    const classes = useStyles();

    const types = [
        // "Basic", 
        "Bundle", 
        // "Custom Bundle"
    ];

    const [isNameError, setIsNameError] = useState(false);
    const [isDescError, setIsDescError] = useState(false);
    const [isTeamsError, setIsTeamsError] = useState(false);

    const [localName, setLocalName] = useState("");
    const [localEnv, setLocalEnv] = useState({label: Object.keys(EnvirnomentLabels).find(key => EnvirnomentLabels[key] === props.environment), value: props.environment});
    const [localType, setLocalType] = useState(types[0]);
    const [localDesc, setLocalDesc] = useState("");
    const [localTeamName, setLocalTeamName] = useState("");
    const [localTeamId, setLocalTeamId] = useState("");
    const [localScope, setLocalScope] = useState("PRIVATE");

    const teams = props.myAvailableTeams.map((team) => team.teamName);

    useEffect(() => {
        if (props.syntheticDatasetToEdit && props.syntheticDatasetToEdit.id) {
            const { id, name, description, environment, scope, team, typeOfData } = props.syntheticDatasetToEdit;

            props.saveSyntheticTypeData({
                dataSetName: name,
                dataSetType: types[0],
                description: description,
                env: {label: Object.keys(EnvirnomentLabels).find(key => EnvirnomentLabels[key] === environment), value: environment},
                scope: scope,
                team: team && team.name ? team.name : "",
                teamId: team && team.id ? team.id : ""
            });
            setLocalName(name);
            setLocalEnv({label: Object.keys(EnvirnomentLabels).find(key => EnvirnomentLabels[key] === environment), value: environment});
            setLocalType(types[0]);
            setLocalDesc(description);
            setLocalTeamName(team && team.name ? team.name : "");
            setLocalTeamId(team && team.id ? team.id : "");
            setLocalScope(scope || "PRIVATE");
        } else {
            const { dataSetName, dataSetType, description, scope, team, teamId } = props.syntheticDataTypeInfo;
            const env = {label: Object.keys(EnvirnomentLabels).find(key => EnvirnomentLabels[key] === props.environment), value: props.environment};
            if (!(props.syntheticDataset && props.syntheticDataset.id)) {
                if (props.firstLoad) {
                    props.saveSyntheticTypeData({
                        dataSetName: "",
                        dataSetType: "",
                        description: "",
                        scope: "PRIVATE",
                        team: "",
                        teamId: "",
                        env: env
                    });
                    setLocalName("");
                    setLocalEnv(env);
                    setLocalType(types[0]);
                    setLocalDesc("");
                    setLocalTeamName("");
                    setLocalTeamId("");
                    setLocalScope("PRIVATE");
                } else {
                    props.saveSyntheticTypeData({
                        ...props.syntheticDataTypeInfo,
                        env: env
                    });
                    setLocalName(dataSetName);
                    setLocalEnv(env);
                    setLocalType(types[0]);
                    setLocalDesc(description);
                    setLocalTeamName(team);
                    setLocalTeamId(teamId);
                    setLocalScope(scope);
                }
            } else {
                setLocalName(dataSetName);
                setLocalEnv(env);
                setLocalType(dataSetType);
                setLocalDesc(description);
                setLocalTeamName(team);
                setLocalTeamId(teamId);
                setLocalScope(scope);
            }
        }
    }, []);

    const handleEnvChange = (e,v) => {
        if (v) {
            setLocalEnv(v);
            props.saveSyntheticTypeData({
                ...props.syntheticDataTypeInfo,
                env: v
            });
        }
    };

    const handleTypeChange = (e,v) => {
        props.clearDataFromCart();
        setLocalType(v);
        props.saveSyntheticTypeData({
            ...props.syntheticDataTypeInfo,
            dataSetType: v
        });
    }

    const handleChangeDescription = (e) => {
        setLocalDesc(e.target.value);
        props.saveSyntheticTypeData({
            ...props.syntheticDataTypeInfo,
            description: e.target.value
        });
    }

    const handleOnDescFieldBlur = (e) => {
        if (
          e.target.value == undefined ||
          e.target.value == null ||
          e.target.value == ""
        ) {
          setIsDescError(true);
        } else {
          setIsDescError(false);
        }
    };

    const handleOnNameFieldBlur = (e) => {
        if (
          e.target.value == undefined ||
          e.target.value == null ||
          e.target.value == ""
        ) {
          setIsNameError(true);
        } else {
          setIsNameError(false);
        }
    };

    const handleNameChange = (e) => {
        let ans = {
            ...props.syntheticDataTypeInfo,
            dataSetName: e.target.value
        }
        setLocalName(e.target.value);
        props.saveSyntheticTypeData(ans);
    };

    const handleScopeChange = (e) => {
        setIsTeamsError(false);

        setLocalScope(e.target.value);
        setLocalTeamName("");
        setLocalTeamId("");
        props.saveSyntheticTypeData({
            ...props.syntheticDataTypeInfo,
            scope: e.target.value,
            team: "",
            teamId: "",
        });
    };

    const handleTeamChange = (e, value) => {
        const team = props.myAvailableTeams.filter((team) => team.teamName === value);
        ((props.syntheticDataTypeInfo.scope === "TEAM" || localScope === "TEAM") && !value) ? 
            setIsTeamsError(true) : setIsTeamsError(false);

        setLocalTeamName(value);
        const tId = ((team[0]?.id || team[0]?.teamId)) ? team[0]?.id || team[0]?.teamId : "";
        setLocalTeamId(tId);
        props.saveSyntheticTypeData({
            ...props.syntheticDataTypeInfo,
            team: value,
            teamId: tId
        });
    };

    const handleTeamsFieldBlur = (e) => {
        ((props.syntheticDataTypeInfo.scope === "TEAM" || localScope === "TEAM") && !e.target.value) ? setIsTeamsError(true) : setIsTeamsError(false);
    };

    const makeEnvOptArray = () => {
        let opts = Object.keys(EnvirnomentLabels).map((env) => ({label: env, value: EnvirnomentLabels[env]}));
        return opts;
    }

    const disableCreate = ((props.syntheticDataset && props.syntheticDataset.id) || (props.syntheticDatasetToEdit && props.syntheticDatasetToEdit.id)) ? true : false;

    return (
        <ContentBody marginTop={1} marginBottom={2}>
            <Grid container direction="column" spacing={4}>
                <Grid item>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={12} sm={12} md={5}>
                            <FormTextField
                                id="name"
                                label="Name"
                                fullWidth
                                required
                                value={localName}
                                error={isNameError}
                                autoComplete='off'
                                helperText={
                                    !isNameError ? "" : "Name of the dataset cannot be empty"
                                }
                                onBlur={handleOnNameFieldBlur}
                                onChange={handleNameChange}
                                inputProps={{ maxLength: 30 }}
                                disabled={disableCreate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <FormAutoComplete
                                id="environment"
                                label="Environment"
                                fullWidth
                                required
                                objectOptions={makeEnvOptArray()}
                                getOptionSelected={(option, value) => option.label === value.label }
                                value={localEnv}
                                onChange={handleEnvChange}
                                disabled={disableCreate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormAutoComplete
                                id="type"
                                label="Type"
                                fullWidth
                                required
                                options={types}
                                value={localType}
                                onChange={handleTypeChange}
                                disabled={disableCreate}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item sm={12} md={12}>
                        <FormTextArea
                            label="Description"
                            id="description"
                            fullWidth
                            required
                            value={localDesc}
                            error={isDescError}
                            onChange={handleChangeDescription}
                            onBlur={handleOnDescFieldBlur}
                            helperText={
                                !isDescError ? "" : "Description for dataset is required"
                            }
                            autoComplete="off"
                            inputProps={{ maxLength: 300 }}
                            rows={2}
                            disabled={disableCreate}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction="row" spacing={4}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <ToggleButtonGroup
                                value={localScope}
                                color="primary"
                                exclusive
                                onChange={handleScopeChange}
                                aria-label="scope"
                                classes={{ root: classes.headerToggleGroup }}
                                className="scopeToggleButtonGroup"
                                disabled={disableCreate}
                            >
                                <ToggleButton value="PRIVATE" aria-label="centered">
                                    PRIVATE
                                </ToggleButton>
                                <ToggleButton value="TEAM" aria-label="left aligned">
                                    TEAM
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                            {
                                (props.syntheticDataTypeInfo.scope === "TEAM" || localScope === "TEAM") && 
                                <FormAutoComplete
                                    id="teams"
                                    label="Team"
                                    disabled={localScope !== "TEAM" || disableCreate}
                                    options={teams}
                                    value={localTeamName}
                                    required
                                    onChange={(event, value) => handleTeamChange(event, value)}
                                    onBlur={handleTeamsFieldBlur}
                                    error={isTeamsError}
                                    fullWidth
                                    helperText={
                                        !isTeamsError
                                        ? ""
                                        : "Team cannot be empty for TEAM scope"
                                    }
                                    inputExtraProps={{
                                        placeholder:"Select Team name",
                                    }}
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ContentBody>
    )
}
const mapStateToProps = (state) => {
  return {
    userName: state.auth.userName,
    environment: state.auth.selectedEnvValue,
    myAvailableTeams: state.auth.myAvailableTeams,
    syntheticDataTypeInfo: state.syntheticData.syntheticDataTypeInfo,
    syntheticDatasetSuccess: state.syntheticData.syntheticDatasetSuccess,
    syntheticDataset: state.syntheticData.syntheticDataset,

    syntheticDatasetToEdit: state.syntheticData.syntheticDatasetToEdit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveSyntheticTypeData: (data) => dispatch(saveSyntheticTypeData(data)),
    clearDataFromCart: () => dispatch(clearDataFromCart())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSyntheticDatasetStep);
