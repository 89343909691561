import React from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import clsx from 'clsx';
import LinkButton from '../../../../components/common/custom/LinkButton';
import { deepOrange } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import ListIcon from '@material-ui/icons/List';
import { renderIcon, getTenColorPallete, getAcronym, getReorderedDate } from '../../../../utils/ui.js';
import moment from 'moment';
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  fab: {
    float: 'center',
    color: 'black',
    border: '3px solid',
    background: 'white'
  },
  orange: {
    float: 'right',
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  serviceId: {
    fontSize: '1rem'
  },
  assignment: {
    fontSize: '2rem',
    color: 'black'
  },
  btnDisabled:{
    backgroundColor: 'grey',
    borderColor: 'grey',
    color: 'white'
  },
  baseMagenta: {
    background: 'linear-gradient(168deg, rgb(142, 57, 127) -10%, rgba(135, 55, 124, 0.25) 10%, rgba(229, 195, 222, 0.15) 40%, rgba(229, 195, 222, 0.1) 53%), linear-gradient(112deg, rgb(135, 55, 124), rgb(212, 9, 131) 32.1%, rgb(255, 141, 24) 90%)'
  },
  baseGreen: {
    background: 'linear-gradient(152deg, rgb(0, 104, 111) -8%, rgba(0, 104, 111, 0) 55%, rgba(0, 104, 111, 0) 100%), linear-gradient(255deg, rgb(167, 204, 100) 14%, rgb(53, 180, 194) 57%, rgb(38, 206, 255) 107%)'
  },
  baseBlue: {
    background: 'linear-gradient(160deg, rgb(41, 150, 212) -10%, rgb(75, 55, 137) -20%, rgba(229, 195, 222, 0) 50%), linear-gradient(73deg, rgb(153, 82, 154) 3%, rgb(140, 181, 224) 56%, rgb(11, 187, 255) 90%)'
  },
  baseOrange: {
    background: 'linear-gradient(149deg,#dd0b30 -120%,#db0f2e -6%,rgba(229,195,222,0.00001) 43%),linear-gradient(45deg,#b61d68 4%,#ec6407 50%,#ffab09 90%)'
  }
}));

function IconCont(props) {
  return (<span {...props} style={{ display: 'inline-block' }}>
    {props.children}
  </span>)
}

export default ({ tableRowData, teamId, isAdmin, currentTeamId, isStandardToggle }) => {
  const classes = useStyles();
  const { user=null,userStory, usedDate, createdAt, id, serviceId, serviceDescription, quantity, userId, name } = tableRowData;

  return (
    <tr id="team-detail-row">

      <td style={{ textAlign: 'start', overflowWrap: 'anywhere' }}>{tableRowData.id &&
        <IconCont className={clsx('nav-link', classes.baseMagenta)}>
          <i
            className={`td-icon-sm icon-manage-services theme-text-primary `}
          ></i>
        </IconCont>}{id}</td>
      {/* <td style={{ textAlign: 'center' }}><span className={"team-circle"} id="team-service-circle">{serviceId}</span></td> */}
      <td style={{ textAlign: 'center' }}><span className={`${isStandardToggle ? 'team-circle' : 'color-red'}`} id={`${isStandardToggle && 'team-service-circle'}`}>{isStandardToggle ? serviceId : userStory}</span></td>
      <td style={{ textAlign: 'start' }}> {isStandardToggle ? tableRowData.serviceProductCode : tableRowData.scenarioName}</td>
      <td style={{ textAlign: 'start', overflowWrap: 'anywhere' }}>{isStandardToggle ? tableRowData.serviceDescription : tableRowData.scenarioName} </td>
      <td style={{ textAlign: 'center' }}>
        {(!!quantity || quantity === 0) && <span className="team-circle" id="team-qty-circle">{quantity}</span>}
      </td>
      <td style={{ textAlign: 'center' }}>
      {tableRowData.createdAt &&
        <IconCont className={clsx('nav-link', classes.baseGreen)}>
          <i
            className={`td-icon-sm icon-time theme-text-primary `}
          ></i>
        </IconCont>}
        {moment(createdAt).format("DD-MM-YYYY")}</td>

     {teamId && user && (
        <td  style={{ textAlign: 'center' }}>
          <Tooltip arrow title={user.name} aria-label="add" placement='top'>
              <Fab style={{borderColor: getTenColorPallete(user.username.slice(user.username.length - 1, user.username.length)) }}
                className={classes.fab}>
              {getAcronym(user.name)}
            </Fab>
          </Tooltip>
        </td>)}
        {teamId && !user && (
        <td  style={{ textAlign: 'center' }}>
           <Tooltip arrow title={"Old checkouts doesn't have users"} aria-label="add" placement='top'>
          <Fab>
          N/A
          </Fab>
           </Tooltip>
        </td>)}
      {!teamId && (
      <td  style={{ textAlign: 'center' }} className={clsx(tableRowData.success ? "base-green" : "base-orange")}>
        {renderIcon(tableRowData.success
            ? "icon-round-tick"
            : "icon-round-cross")}
      </td>)}
      <td  style={{ textAlign: 'start' }}>
        {(<LinkButton
          className={clsx('btn btn-block', 'btn-primary')}
          to={`/tdm/nosql/checkout/history/${tableRowData.id}`}>
          <i className="fa fa-eye" aria-hidden="true" />
          {' '}View</LinkButton>
        )}
      </td>
    </tr >
  )
}
