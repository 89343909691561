import React from "react";
import PropTypes from "prop-types";
import { Box, TableCell, Typography } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";

import { makeStyles } from "@material-ui/core/styles";

const useTableHeaderStyle = makeStyles({
  head: {
    borderBottom: "none",
  },
});

const useTableHeaderTextStyle = makeStyles((theme) => ({
  root: {
    // color: theme.palette.tableHeader.color,
    color: blueGrey[700],
    textTransform: "uppercase",
  },
}));

const defaultAlignment = "center";

const TableHeader = (props) => {
  const { children, align = defaultAlignment, ...rest } = props;

  const tableHeaderStyle = useTableHeaderStyle();
  const textStyle = useTableHeaderTextStyle();

  return (
    <TableCell
      variant="head"
      align={align}
      classes={tableHeaderStyle}
      {...rest}
    >
      <Typography variant="h6" classes={textStyle}>
        <Box
          pl={align === "left" ? 10 : 0}
          fontWeight="fontWeightBold"
          fontSize={14}
        >
          {children}
        </Box>
      </Typography>
    </TableCell>
  );
};

TableHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  align: PropTypes.oneOf(["left", "center"]),
};

export default TableHeader;
