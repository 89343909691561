import React from "react";
import { Button } from "reactstrap";
import _ from "lodash";
import { generateServiceId } from "../../actions/replicatorActions";
import { connect } from "react-redux";
import { Field, change } from "redux-form";
import DropdownComponent from "./DropdownComponent";
import { renderField } from "../common/fields/fields";
import {
  msisdnValidator,
  imsiValidator,
  simValidator
} from "../common/form/fieldValidators";
import "./Product.css";


class MobileComponent extends React.Component {
  state = {
    autoGeneratedRows: [],
    productFamily: "Mobile Postpaid Handheld", //default value
    numRows: 0
  };

  handleProductFamily = option => {
    const productFamily = this.props.productFamilies.filter(
      data => data.key === option
    )[0].value;
    this.setState(preState => ({ ...preState, productFamily }));
    const { numRows } = this.state;
    // to set productFamily in each row when product family changes
    for (let i = 0; i < numRows; i++) {
      this.props.passdownprops.dispatch(
        change(
          this.props.passdownprops.formName,
          `mobileData[${i}].productFamily`,
          productFamily
        )
      );
    }
  };

  handleRowChange = (numRows, newNumRows, fields) => {
    this.setState(preState => ({ ...preState, numRows: newNumRows }));

    let diff = newNumRows - numRows;
    if (diff > 0) {
      for (let i = 0; i < diff; i++)
        fields.push({ msisdn: null, imsi: null, sim: null });
    } else if (diff < 0) {
      diff *= -1;

      const limit = fields.length - diff - 1;
      for (let i = 0; i < diff; i++) fields.pop();
      this.setState(preState => {
        return {
          ...preState,
          autoGeneratedRows: preState.autoGeneratedRows.filter(
            data => data <= limit
          )
        };
      });
    }
    // to set productFamily in each row when no. of row changes
    for (let i = 0; i < newNumRows; i++) {
      const { productFamily } = this.state;
      this.props.passdownprops.dispatch(
        change(
          this.props.passdownprops.formName,
          `mobileData[${i}].productFamily`,
          productFamily
        )
      );
    }
  };
  handleGenerateServiceId = async (passdownprops, index) => {
    try {
      const { serviceId } = await this.props.generateServiceId(1, index);

      this.setState(preState => ({
        ...preState,
        autoGeneratedRows: preState.autoGeneratedRows.concat(index)
      }));

      passdownprops.dispatch(
        change(passdownprops.formName, `mobileData[${index}].msisdn`, serviceId)
      );
    } catch (e) {
      console.log("error generating MSISDN: ", e);
    }
  };
  render() {
    const { productFamilies, quantity } = this.props;
    const productFamilyOptions = productFamilies.map(
      productFamily => productFamily.key
    );
    const { fields, passdownprops, productType, serviceIdField } = this.props;
    return (
      <div>
        <div id="header-mobile">
          <div id="mobile-item">
            {" "}
            <span className="product-header">Product:</span>
            <span>{productType.toUpperCase()}</span>
          </div>
          <div id="mobile-item">
            {" "}
            <span className="product-header">Type:</span>
            <span id="qty-dropdown" className="mobile-dorpdown">
              <DropdownComponent
                customClass="product-family"
                options={productFamilyOptions}
                selectInput={productFamily =>
                  this.handleProductFamily(productFamily)
                }
              />
            </span>
          </div>
          <div id="mobile-item">
            <span className="product-header">Quantity: </span>
            <span id="qty-dropdown" className="mobile-dorpdown">
              <DropdownComponent
                options={quantity}
                selectInput={qty =>
                  this.handleRowChange(fields.length, qty, fields)
                }
                length={fields.length}
              />
            </span>
          </div>
        </div>
        <div id="s2p-product-modal-input-head" className="row">
          <table className="table table-sm adbor-fnn-table">
            <thead className="thead-light adbor-fnn-head">
              <tr>
                <th className="verticle-middle">
                  <span>MSISDN</span>
                </th>
                <th className="verticle-middle">
                  <span>IMSI</span>
                </th>

                <th className="verticle-middle">
                  <span>SIM</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {fields.map((mobileData, index) => {
                const placeholder =
                  serviceIdField.rowNumber === index && serviceIdField.pending
                    ? "loading..."
                    : "";
                return (
                  <tr key={index}>
                    <th style={{ width:"25%", paddingLeft: "0px"}}>
                      <Field
                        name={`mobileData[${index}].msisdn`}
                        customDiv="adborFnnTableInputDiv"
                        customStyleClassName="msisdn"
                        tabIndex={index}
                        disabled={
                          passdownprops.asyncValidating ||
                          this.state.autoGeneratedRows.includes(index) ||
                          serviceIdField.pending
                        }
                        component={renderField}
                        validate={msisdnValidator}
                        touched={true}
                        invalid={this.props.errorMessage}
                        type={"textWithCustomStyles"}
                        showValidMsg={false}
                        placeholder={placeholder}
                        autoComplete="off"
                      />
                    </th>
                    <th style={{ paddingLeft: "0px", width:"35%"  }}>
                      <Field
                        name={`mobileData[${index}].imsi`}
                        tabIndex={index}
                        disabled={
                          passdownprops.asyncValidating ||
                          serviceIdField.pending
                        }
                        autoFocus={index === 0}
                        component={renderField}
                        customDiv="adborFnnTableInputDiv"
                        validate={imsiValidator}
                        invalid={this.props.errorMessage}
                        type={"textWithCustomStyles"}
                        showValidMsg={false}
                        touchOnChange={true}
                        autoComplete="off"
                      />
                    </th>
                    <th>
                      <Field
                        name={`mobileData[${index}].sim`}
                        component={renderField}
                        tabIndex={index}
                        touched={true}
                        invalid={this.props.errorMessage}
                        type={"textWithCustomStyles"}
                        index={index}
                        showValidMsg={false}
                        validate={simValidator}
                        disabled={
                          passdownprops.asyncValidating ||
                          serviceIdField.pending
                        }
                      />
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    productFamilies: state.replicatorData.options.productFamily,
    quantity: state.replicatorData.options.quantity
  };
};

const mapDispatchToProps = dispatch => {
  return {
    generateServiceId: (qty, index) => dispatch(generateServiceId(qty, index))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileComponent);

