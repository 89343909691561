import React from 'react';

const FlexSpan = ({ children }) =>
    <span style={{
        "display": "inline-flex",
        "alignItems": "center",
        "justifyContent": "left",
        "width": "100%",
        paddingLeft: "20px",
        paddingBottom: "20px"
    }}>{children}</span>;

export default FlexSpan;