export const IMPORT_LOADING = "IMPORT_LOADING";
export const IMPORT_SUCCESS = "IMPORT_SUCCESS";
export const IMPORT_FAIL = "IMPORT_FAIL";

export const FETCH_SF_LOADING = "FETCH_SF_LOADING";
export const FETCH_SF_SUCCESS = "FETCH_SF_SUCCESS";
export const SELECT_CUSTOMER_SALESFORCE = "SELECT_CUSTOMER_SALESFORCE";

export const ADD_CUSTOMER_SF_VIEW = 'ADD_CUSTOMER_SF_VIEW';

export const CONTACT_SYNC_STATUS = "CONTACT_SYNC_STATUS";