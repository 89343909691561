import * as types from '../actions/actionTypes';

export default(state = {}, action) => {
    switch (action.type){
        case types.LOAD_API_DOC_SUCCESS:
            return {
                ...action.payload
            }
        default:
            return state;
    }
};