import React, { Component } from "react";
import { connect } from 'react-redux';
import TilesFrame from '../../Data/accordion-frames/replicator/DataTilesFrame';
import { SubHeader } from "../../tconnect/modals/HeaderContent";
import { selectSubscriptionFilter, submitSubscription } from "../../../actions/u2cActions";
import swal from 'sweetalert2';
import { displayErrorNew } from '../../common/alerts/alerts';

class CreateSubscription extends Component {

    componentDidMount() {
        this.resetFilters();
    }
    resetFilters = () => {
        this.props.selectFilter("product", null);
        this.props.selectFilter("env", null);
    }
    envFilters = [{ label: "sit", icon: 'icon-home' }, { label: "pre prod", icon: 'icon-home' }, { label: "prod", icon: 'icon-home' }]
    selectEnvType = (obj) => {
        this.props.selectFilter("product", null);
        this.props.selectFilter("env", obj.label);
    }
    selectProdType = (obj) => {
        this.props.selectFilter("product", obj.label);
    }
    getProductTypeIcon(product) {
        switch (product) {
            case "Mobile":
                return "icon-mobile";
            case "Repayment":
                return "icon-collect-mobile-payments";
            case "Land Line":
                return "icon-corporate-mobile-plus-old";
            default:
                break;
        }
    }

    onSubmitSubscription = () => {
        this.props.submitSubscription(this.props.selectedFilters.product, this.props.billingAccount.billingAccountNumber)
            .then(result => {
                if (result) {
                    swal.fire("Success", ` Submitted`, "success");
                }
            }).catch(err => {
                displayErrorNew({
                    title: "Subscription Error",
                    subTitle: "An error occured while submitting subscription!",
                    err
                });
            })
    }

    render() {
        const { env, product, } = this.props.selectedFilters;
        let { envFilters, prodSitFilters } = this.props.filters;
        envFilters = envFilters.map(environment => ({ label: environment, icon: 'icon-home', }));
        prodSitFilters = prodSitFilters.map(product => ({ label: product, icon: this.getProductTypeIcon(product) }));
        const SpanWidth = ({ children, width }) => <span style={{ "width": width }}>{children}</span>

        return (
            <div>
                <SubHeader
                    text={`Create Subscription`}
                    iconClass="icon-drag-and-drop-form"
                    iconColor="green"
                    h2
                />
                <TilesFrame
                    heading="Environment"
                    color="magenta"
                    data={envFilters}
                    select={this.selectEnvType}
                    selected={env}
                />
                {env && 'SIT' === env &&
                    <TilesFrame
                        heading="Products"
                        color="green"
                        data={prodSitFilters}
                        select={this.selectProdType}
                        selected={product}
                    />}
                {product && env === 'SIT' && (product === 'NGUC' || product === 'EM') &&
                    <div style={{ padding: "25px" }}>
                        <SpanWidth width="20%">
                            <button type="button" class="btn btn-primary" onClick={() => this.onSubmitSubscription()} >
                                {this.props.submitSubscriptionLoading
                                    ? <i class="fa fa-spinner fa-pulse fa-fw"></i> : "Submit"}
                            </button></SpanWidth>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedFilters: state.subscription.selectedFilters,
        filters: state.subscription.filters,
        billingAccount: state.u2c.billingAccount,
        submitSubscriptionLoading: state.u2c.submitSubscription
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        selectFilter: (key, value) => dispatch(selectSubscriptionFilter(key, value)),
        submitSubscription: (product, billingAccountNumber) => dispatch(submitSubscription(product, billingAccountNumber))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscription);