import React, { Component } from "react";
import { connect } from "react-redux";

class CustomerDataTableRow extends Component {

    render(){
        const link = "https://telstra-retail--b2csitnew.lightning.force.com/lightning/r/Account/" + this.props.tableRowData.accountId + "/view"
        return (
            <tr id="contact-detail-row">
                <td>
                    {this.props.tableRowData.customerID}
                </td>
                <td>
                    {this.props.tableRowData.customerType}
                </td>
                <td style={{"minWidth":"60px"}}>
                    <a href={link} target="_blank">{this.props.tableRowData.accountId}</a>
                </td>
                <td>
                    {this.props.tableRowData.cidn}
                </td>
                <td>
                    {this.props.tableRowData.firstName}
                </td>
                <td>
                    {this.props.tableRowData.lastName}
                </td>
                <td>
                    {this.props.tableRowData.email}
                </td>
                <td>
                    {this.props.tableRowData.customerAddress}
                </td>
                <td>
                    {this.props.tableRowData.phoneNo}
                </td>
            </tr>
        )
    }
}

export default CustomerDataTableRow;