import React from 'react'
import { flexStylesStart } from "../../../../../../utils/ui";

export const TemplateHeader = ({ icon, title, children }) => {
    return (<div style={flexStylesStart}>
        <span className="m-1">
            <p className="text-nowrap">{icon && <i className={`fa ${icon} theme-text-primary`} aria-hidden="true"></i>}{' '}{title}</p>
        </span>
        {children}
    </div>)
}