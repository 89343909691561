import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { SubHeader } from '../../../common/custom/SubHeader';

import classNames from 'classnames';

import ReactTooltip from 'react-tooltip'

import { connect } from 'react-redux';
import { getSalesForceContactDetails, importCustomerSalesforce, createCustomerContactSalesforce, createCustomerBaSalesforce, createCustomerSiteSalesforce, createCustomerOppSalesforce } from '../../../../actions/dataActions';
import { SfTableWrapper } from './dataViewSfTable';
import tableConfig from './dataViewSfTableConfig';

//sample response json with multiple accounts added for testing purpose
// const mockSf = {
//     "responseStatus": "OK",
//     "responseDescription": null,
//     "customer": [
//         {
//             "account": {
//                 "accountId": "0012N00000CmmOLQAZ",
//                 "accountName": "MR TESTCOMPOSITE TESTCOMPOSITELNAME",
//                 "accountType": "Residential",
//                 "vlocityCmtStatus": "Active",
//                 "accountUUID": "91f1a1b9-271d-ed99-aba1-77cfff3456be",
//                 "vlocityCmtPersonContactId": "0032N000009VmffQAC",
//                 "url": "/services/data/v46.0/sobjects/Account/0012N00000CmmOLQAZ"
//             },
//             "Contact": [{
//                 "contactId": "0012N00000CmmOLQAZ",
//                 "contactName": "MR TESTCOMPOSITE TESTCOMPOSITELNAME",
//                 "contactType": "Full Authority",
//                 "vlocityCmtStatus": "Active",
//                 "accountUUID": "91f1a1b9-271d-ed99-aba1-77cfff3456be",
//                 "vlocityCmtPersonContactId": "0032N000009VmffQAC",
//                 "url": "/services/data/v46.0/sobjects/Account/0012N00000CmmOLQAZ"
//             }],
//             "Order": [{
//                 "OrderId": "0012N00000CmmOLQAZ",
//                 "contactName": "MR TESTCOMPOSITE TESTCOMPOSITELNAME",
//                 "contactType": "Connect",
//                 "OrderStatus": "Complete",
//                 "accountUUID": "91f1a1b9-271d-ed99-aba1-77cfff3456be",
//                 "vlocityCmtPersonContactId": "0032N000009VmffQAC",
//                 "url": "/services/data/v46.0/sobjects/Account/0012N00000CmmOLQAZ"
//             }]

//         },
//         {
//             "account": {
//                 "accountId": "0012N00000CmmOLQAZ",
//                 "accountName": "MR TESTCOMPOSITE TESTCOMPOSITELNAME",
//                 "accountType": "Residential",
//                 "vlocityCmtStatus": "Active",
//                 "accountUUID": "91f1a1b9-271d-ed99-aba1-77cfff3456be",
//                 "vlocityCmtPersonContactId": "0032N000009VmffQAC",
//                 "url": "/services/data/v46.0/sobjects/Account/0012N00000CmmOLQAZ"
//             },
//             "Contact": [{
//                 "contactId": "0012N00000CmmOLQAZ",
//                 "contactName": "MR TESTCOMPOSITE TESTCOMPOSITELNAME",
//                 "contactType": "Full Authority",
//                 "vlocityCmtStatus": "Active",
//                 "accountUUID": "91f1a1b9-271d-ed99-aba1-77cfff3456be",
//                 "vlocityCmtPersonContactId": "0032N000009VmffQAC",
//                 "url": "/services/data/v46.0/sobjects/Account/0012N00000CmmOLQAZ"
//             }],
//             "Order": [{
//                 "OrderId": "0012N00000CmmOLQAZ",
//                 "contactName": "MR TESTCOMPOSITE TESTCOMPOSITELNAME",
//                 "contactType": "Connect",
//                 "OrderStatus": "Complete",
//                 "accountUUID": "91f1a1b9-271d-ed99-aba1-77cfff3456be",
//                 "vlocityCmtPersonContactId": "0032N000009VmffQAC",
//                 "url": "/services/data/v46.0/sobjects/Account/0012N00000CmmOLQAZ"
//             }]

//         }
//     ]
// };

class DataViewCustomerSf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: {}
        }
    }
    static propTypes = {
        customer: PropTypes.object.isRequired
    }

    renderSalesforceAddButton = (action, loading, disabled, disabledMsg) => {
        const disableButton = (loading || disabled);
        const { isProfileSelected } = this.props;
        return (
            <span
                data-tip={disabledMsg}
                data-tip-disable={(!(disableButton && disabledMsg))}
                style={{}}>
                <button
                    className={classNames("btn btn-sm btn-outline-success ld-over", { running: loading })}
                    onClick={() => action}
                    disabled={disableButton}>
                    {< i class="fa fa-plus" aria-hidden="true" > </i>}
                    {loading && <div class="ld ld-ring ld-spin"></div>}
                    <ReactTooltip type={"error"} effect="solid" place="bottom" />
                </button>
            </span>
        )
    }

    getCustomerDataArray = (sfCustomer) => {
        let dataArr = [[]];
        let counter = -1;
        //adding divider
        sfCustomer && sfCustomer.map(data => {
            return { ...{ 'seperator': 'divider' },...data };
        }).map(val => Object.entries(val).filter(data => data[1] !== null && data[1].length !== 0).map(vals=> {
            if (vals[0] == 'seperator') {
                dataArr.length<sfCustomer.length && dataArr.push([]);
                counter = counter + 1;
            }
            else
                dataArr[counter].push(vals);
        }
        ))
        console.log('dataArr', dataArr);
        return dataArr;
    }

    getTableWrapper = (sfCustomerDataArr) => {
        if (sfCustomerDataArr.length !== 0) {
            return sfCustomerDataArr.map(data => tableConfig[data[0]] ? (<SfTableWrapper 
                key={data.accountId}
                accountId={data.accountId}
                createContactHandler={this.props.getSalesForceContactDetails}
                header={data[0]} 
                colHeaders={tableConfig[data[0]]} 
                tableEntries={data[1]} />) : <p className="text-muted">No Data found</p>)
        } else {
            return (<p className="text-muted">No Customer Details found</p>);
        }
    }
    activeProfile = ({ environment }) => {
        const { available, active } = this.props;
        return available[active[environment]];
    }

    render() {
        const { sfCustomer } = this.props;
        const sfCustomerDataArrGrouped = this.getCustomerDataArray(sfCustomer);
        const item = this.props.customer;
        return (
            <React.Fragment>
                <SubHeader
                    text={"DaVinCi-Salesforce"}
                    iconLeft={true}
                    small={false}
                    iconClass={"salesforce"}
                    iconColor="blue"
                    iconSize={"3.25em"} />
                {sfCustomerDataArrGrouped.map(sfCustomerDataArr => {
                    return (<div class="card">
                        <div class="card-body">
                            {this.getTableWrapper(sfCustomerDataArr)}
                        </div>
                    </div>)
                })}

            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        salesforceImport: (custId) => {
            dispatch(importCustomerSalesforce(custId))
        },
        salesforceCreateContact: (custId, profile) => {
            dispatch(createCustomerContactSalesforce(custId, profile))
        },
        getSalesForceContactDetails: (accountId) => {
            dispatch(getSalesForceContactDetails(accountId))
        }

    }
}

const mapStateToProps = (state, ownProps) => {
    const { active, available } = state.profiles;
    const { isAdmin } = state.auth;
    const { customer } = state.checkout.view;
    return {
        isProfileSelected: (active != null),
        available,
        active,
        isAdmin,
        sfCustomer: customer
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataViewCustomerSf);