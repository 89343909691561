import config from "../../config";

const TDM_GATEWAY_API = config.tdm_gateway_api.uri;

export const FETCH_DATA_BANK_CHECKOUT_HISTORY =  "FETCH_CHECKOUT_HISTORY";
export const FETCH_DATA_BANK_CHECKOUT_HISTORY_FULFILLED =  "FETCH_CHECKOUT_HISTORY_FULFILLED";
export const FETCH_DATA_BANK_CHECKOUT_HISTORY_REJECTED =  "FETCH_CHECKOUT_HISTORY_REJECTED";

export const TDM_DATA_BANK_CHECKOUT_HISTORY_URL = `${TDM_GATEWAY_API}/tdm/data/download/checkout/history`;

export const SELECT_TABLE_FILTER='SELECT_TABLE_FILTER';

export const TDM_DATA_BANK_CHECKOUT_FILTER_URL = `${TDM_GATEWAY_API}/tdm/data/download/checkout/filter`;

export const DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_SUCCESS = "DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_SUCCESS";
export const DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_LOADING = "DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_LOADING";
export const DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_ERROR = "DATA_BANK_CHECKOUT_LOAD_CHECKOUT_FILTER_ERROR";

export const DATA_BANK_CHECKOUT_TEAM_ID = "DATA_BANK_CHECKOUT_TEAM_ID";
export const DATA_BANK_CHECKOUT_IS_TOGGLE_USER="DATA_BANK_CHECKOUT_IS_TOGGLE_USER";