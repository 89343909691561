import {
  AUTH_USER,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_RESET,
  AUTH_TEAMS,
  TEAM_CHANGE_ERROR,
  OPEN_SIDEBAR_PANEL,
  OPEN_BROADCAST_PANEL,
  SIDEPANEL_ENVIRNOMENT,
  SET_BROADCAST_MESSAGE,
  USER_REGISTERED,
  SAVE_TAAS_USERID,
  PREFERENCES_LOADING,
  ACCOUNT_DETAILS_LOADING,
  ACCOUNT_DETAILS_FAILED,
  SAVE_TEAM_LIST
} from "../actions/actionTypes";

import { authUserState, EnvirnomentLabels } from "../components/auth/functions";

const INITIAL_STATE = authUserState();

const getSelectedEnvValue = (sPEnvironment) => {
  switch (true) {
    case sPEnvironment.int1:
      return EnvirnomentLabels.INT1
    case sPEnvironment.int2:
      return EnvirnomentLabels.INT2
    case sPEnvironment.osit:
      return EnvirnomentLabels.PRE_PROD
    case sPEnvironment.sqni:
      return EnvirnomentLabels.SQNI
    default:
      return EnvirnomentLabels.INT2
    }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {...authUserState(action.payload), myAvailableTeams: state.myAvailableTeams};
    case AUTH_TEAMS:
      return {
        ...state,
        availableTeams: action.payload
      };
    case AUTH_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        isAuthenticated: false
      };

      case TEAM_CHANGE_ERROR:
        return {
          ...state,
          errorMessage: action.message,
          teamChangeAuthError: true
        };
    case AUTH_RESET:
      return {
        ...state,
        errorMessage: ""
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        authenticated: "",
        isAuthenticated: false,
        errorMessage: ""
      };
	case OPEN_SIDEBAR_PANEL:
      return {
        ...state,
        openSidePanel: action.payload
      };

  case USER_REGISTERED: 
      return {
        ...state,
        userRegistered : action.payload
      }
  case OPEN_BROADCAST_PANEL:
        return {
          ...state,
          openBroadCastPanel: action.payload
        };
    case SIDEPANEL_ENVIRNOMENT:
      return {
          ...state,
          sidePanelEnvironment: action.payload,
          selectedEnvValue: getSelectedEnvValue(action.payload)
      };  
    case SET_BROADCAST_MESSAGE:
      return {
        ...state,
        broadCastMessage: action.payload
      };
    case SAVE_TAAS_USERID:
      return {
        ...state,
        user: {
          username: action.payload.id,
          email: action.payload.email
        }
      }
    case PREFERENCES_LOADING:
      return {
        ...state,
        preferencesLoading: action.payload
      }
    case ACCOUNT_DETAILS_LOADING:
      return {
        ...state,
        accountDetailsLoading: action.payload
      }
    case ACCOUNT_DETAILS_FAILED:
      return {
        ...state,
        accountDetailsLoading: false,
        accountDetailsFailed: action.payload
      }
    case SAVE_TEAM_LIST:
      return {
        ...state,
        myAvailableTeams: action.payload
      }
    default:
      return state;
  }
};
