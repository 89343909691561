// synchronous redux form validators
import { cosmosMsisdnValidator } from "../components/common/form/fieldValidators";
import { isAlphanumeric } from 'validator';
import _ from 'lodash';

export const getValidated = (teamName)=>{
    const reg = /^[d,c,e,f]\d{0,7}$/;
    if(!reg.test(teamName.toLowerCase()))
    return {username: teamName, error: 'Not a Valid UserID'}
    if(!isAlphanumeric(teamName))
    return {userName: teamName, error: 'Not a alphanumeric'}
    if(teamName.length > 15)
    return {userName: teamName, error: 'Exceeding 15 Chars'}

    return {userName: teamName, error: null}
}
export const getValidatedTeamNames = (teamName)=>{
    let regex= /[.,\s\t\|\;]/; // regex for splitting the ids by , ; space
    if(regex.test(teamName)){
        const teamNames = _.uniqBy(teamName.split(regex).filter(name=>name!== ''));
        const validatedTeamNamesObj = teamNames.map(v=>getValidated(v));
        const validatedTeamNamesError = validatedTeamNamesObj.map(v=> v.error);
        const validatedTeamNames = validatedTeamNamesObj.filter(member=> !member.error).map(member=>member.userName);
        return {validatedTeamNames, validatedTeamNamesError};
    }else{
        const validatedMemberObj = getValidated(teamName);
        if(!validatedMemberObj.error) {
            return {validatedTeamNames:[validatedMemberObj.userName],validatedTeamNamesError: []};
        } else {
            return {validatedTeamNames:[],validatedTeamNamesError: [validatedMemberObj.error]};
        }
    }
}

export const validateIdExist = (array) => {
    const validatedIds = [];
    for (let id in array) {
        if (!(array[id].valid)) {
            if (array[id].valid !== undefined) {
                validatedIds.push({username: array[id].userName, error: true});
            }
        }
    }
    return validatedIds;
}

export const nullCheck = (fieldValue) => fieldValue ===  '' || fieldValue === 'None'

export const containsItems = items => (items && items.length > 0);

export const validateMsisdnArray = (msisdns) => {
    const invalidMsisdns = [];
    const validMsisdns = [];
    for (let msisdn of msisdns) {
        let formatResult = cosmosMsisdnValidator(msisdn);
        if (!formatResult) {
            validMsisdns.push(msisdn);
        } else {
            invalidMsisdns.push(msisdn);
        }
    }
    return { validMsisdns, invalidMsisdns };
}

export const checkIfHasBeenXMillisecondsSinceTime = (time, x) => {
    const currentDate = Date.now();
    return (currentDate - time) > x;
}