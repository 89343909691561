import React from "react";
import * as XLSX from "xlsx";
import { setExcelData } from '../../actions/retrieveDataActions';
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import exc_ccexpress from '../../assets/images/checkinImages/exc_ccexpress.png'
import swal from "sweetalert2"; 
import { Button } from "@material-ui/core";
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
class CheckinExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
    };
  }

  readFile = e => {
    this.props.handleUploadExcel(e)
  };

  filePathset(e) {

    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    console.log(file);
    this.setState({ file });
    e.target.value = "";
    console.log(this.state.file);
    swal.fire({
      title: 'Excel File Uploaded',
      text: "Do you want to Continue",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, I do'
    }).then((result) => {
      if (result.value) {
       this.readFile();
      }

    })
  }


  render() {
    return (
      <div>
         <Tooltip title="Upload Excel and Checkin">
                  <label for="excelFile">
                  <Button
                  style={{background:"#3f51b5",color:"#fff"}}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    name="json" 
                    startIcon={  <img src={exc_ccexpress} alt="1" style={{ 'height': '26px' }}
                    id="beautify" />}
                  >
                       &nbsp; Upload Excel File
                    <VisuallyHiddenInput
                      type="file"
                      ref="fileUploader"
                      accept=".csv,.xlsx,.xls"
                      id="excelFile"
                      className="fileLabel"
                      data-original-title="upload excel"
                      onChange={(e)=>{
                        this.readFile(e); 
                        e.target.value = "";
                      }}
                    />
                  </Button>
                  </label>
                </Tooltip>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setExcelData : (data) => dispatch(setExcelData(data))
  }
}

export default connect(null, mapDispatchToProps)(CheckinExcel);