import React, { useEffect, Fragment, useState } from "react";
import HeaderContent from '../common/Header/HeaderContent';
import { connect } from "react-redux";
import { upperFirst } from "lodash";
import { Button, makeStyles, Input, TextField } from "@material-ui/core";
import './DDC.css'
import { createDDC, addDDCColumns, deleteDDCColumns, resetColumns, setDDCValues, updateAssetsDetails, updateBillingDetails } from '../../actions/ddcActions'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import BillingDetailsDDC from "./BillingDetailsDDC";
import AssetDetailsDDC from "./AssetDetailsDDC";
import swal from "sweetalert2";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { displayLoading, displaySuccess ,displayErrorAlert } from "../common/alerts/alerts";

const useStyles = makeStyles({

  reviewButton: {
    marginLeft: '12px',
    fontSize: '15px',
    paddingBottom: '3px'
  },
  submitButton: {
    fontSize: '15px',
    paddingBottom: '5px',
    float: 'right',
    marginRight: '-6px',
    marginTop: '25px'
  },
  reviewPanel: {
    marginLeft: '10px'
  },
  ddcButtons: {
    marginTop: '-40px !important'
  }
});

const DDC = (props) => {
  const classes = useStyles();

  const { tableHeaderKeys, ddcColumns, history } = props;
  const [ddcColData, setColData] = useState({});
  const [msisdn, setMsisdn] = useState("");
  const [sim, setSim] = useState("");
  const [customerId, setCidn] = useState("");
  const [ban, setBan] = useState("");
  const [planName, setPlanName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [ddcInputValue, setddcInputValue] = useState([]);
  const [reviewPanel, showReviewPanel] = useState(false);
  const [billingPanel, showBillingPanel] = useState(false);
  const [assestsPanel, showAssetsPanel] = useState(false);
  const [addButton, disableAddButton] = useState(true)
  const [errorCount, setErrorCount] = useState([true,true,true,true,true,false]);
  const [errorText, setErrorText] = useState(["", "", "", "", "", ""]);

  const handleInputChange = (e, colHeader) => {
    let eValue = e.target.value;
    let idx = props.ddcColumns.length;
    let error = errorCount;
    let errorMsg = errorText;
    let inputValue = [];
    let addBtnDisabled = ""
    let msidnValue = msisdn; let simValue = sim; let cidnValue = customerId; let banValue = ban; let planNameValue = planName; let productCodeValue = productCode;
    if (colHeader == "MSISDN") {
      if (eValue == "") { error[0] = true; errorMsg[0] = "Invalid MSISDN !!" }
      else {
        if (eValue.length > 10) { error[0] = true; errorMsg[0] = "MSISDN length cannot be more than 10 digits"; }
        else {
          if (!eValue.startsWith('0')) { error[0] = true; errorMsg[0] = "MSISDN should start with 0"; }
          else {
            error[0] = false
            msidnValue = e.target.value;
            inputValue[0] = e.target.value;
            errorMsg[0] = ""
          }
        }

      }
    }
    if (colHeader == "SIM") {
      if (eValue == "") { error[1] = true; errorMsg[1] = "Invalid SIM !!" }
      else {
        if (eValue.length > 20) { error[1] = true; errorMsg[1] = "SIM length cannot exceed 20 digits"; }
        else {
          if(eValue.length < 20 )
          {
            error[1] = true; errorMsg[1] = "SIM length should be 20 digits";
          }
          else{
          if (!eValue.startsWith('89')) { error[1] = true; errorMsg[1] = "SIM should start with 89"; }
          else {
            error[1] = false
            simValue = eValue;
            inputValue[1] = eValue
            errorMsg[1] = ""
          }
        }
      }

      }
    }
    if (colHeader == "CIDN") {
      if (eValue == "") { error[2] = true; errorMsg[2] = "Invalid CIDN !!" }
      else {
        if (eValue.length > 15) { error[2] = true; errorMsg[2] = "CIDN should be a less than 15 digits"; }
        else {
          error[2] = false
          cidnValue = eValue; inputValue[2] = eValue;
          errorMsg[2] = ""
        }

      }
    }
    if (colHeader == "BAN") {
      if (eValue == "") { error[3] = true; errorMsg[3] = "Invalid BAN !!" }
      else {
        if (eValue.length > 30) { error[3] = true; errorMsg[3] = "BAN should be a less than 30 digits"; }
        else {
          error[3] = false
          banValue = eValue; inputValue[3] = eValue;
          errorMsg[3] = ""
        }

      }
    }
    if (colHeader == "Plan Name") {
      if (eValue == "") { error[4] = true; errorMsg[4] = "Invalid Plan/Product Name !!" }
      else {
        if (eValue.length > 100) { error[4] = true; errorMsg[4] = "Plan Name length cannot exceed 100 digits"; }
        else {
          error[4] = false
          planNameValue = eValue; inputValue[4] = eValue;
          errorMsg[4] = ""
        }

      }
    }
    if (colHeader == "Product Code") {
      if (eValue.length > 100) { 
        error[5] = true; errorMsg[5] = "Product Code length cannot exceed 100 digits"; }
        else {
          error[5] = false
          productCodeValue = eValue; inputValue[5] = eValue;
          errorMsg[5] = ""
        }

    }

    addBtnDisabled = (error.some(x => x));

    let colDataUserInput = {
      "index": idx, "colValue": [{ "key": "MSISDN", "default": false, "value": msidnValue },
      { "key": "SIM", "default": false, "value": simValue },
      { "key": "CustomerId", "default": false, "value": cidnValue },
      { "key": "BAN", "default": false, "value": banValue },
      { "key": "Plan Name", "default": false, "value": planNameValue },
      { "key": "Product Code", "default": false, "value": productCodeValue }]
    }

    setMsisdn(msidnValue);
    setSim(simValue);
    setBan(banValue);
    setCidn(cidnValue)
    setProductCode(productCodeValue);
    setPlanName(planNameValue);
    setColData(colDataUserInput);
    setddcInputValue(inputValue);
    setErrorCount(error);
    setErrorText(errorMsg);
    disableAddButton(addBtnDisabled)
  }
  const handleBillingPanel = () => {
    let showBPanel = billingPanel
    showBillingPanel(!showBPanel)
  }
  const handleAssetsPanel = () => {
    let showAPanel = assestsPanel
    showAssetsPanel(!showAPanel)
  }
  
  const addCheckinAsset = () => {
    let msisdnList = []; let simList = []; let banList = []; let cidnList = []; let planNameList = []; let pcList = [];
    ddcColumns.map((ddc, index) => {
      if (index !== 0) {
        msisdnList.push(ddc.colValue[0].value)
        simList.push(ddc.colValue[1].value)
        cidnList.push(ddc.colValue[2].value)
        banList.push(ddc.colValue[3].value)
        planNameList.push(ddc.colValue[4].value)
        pcList.push(ddc.colValue[5].value)
      }
      else {
        msisdnList.push(msisdn)
        simList.push(sim)
        cidnList.push(customerId)
        banList.push(ban)
        planNameList.push(planName)
        pcList.push(productCode)
      }
    })
    props.setDDCValues(msisdnList, simList, cidnList, banList, planNameList, pcList)

    let billingDetail = [];
    let assetDetails = [];
    msisdnList.map((msList, index) => {
      const bDetails = {"addressLine1":"28 BELLVIEW PL","addressLine2":"","addressLine3":"PARKINSON QLD 4115",
        "billFrequency":"","billPeriodicity":"Monthly","billCycle":"","directDebitIndicator":"","onlineBillingIndicator":"","singleBillIndicator":"","startDate":"","endDate":"","businessName":"","inCollections":"","icmsIndicator":"",
      "billingAccountStatus":"Active","billingNameLine2":"","billingNameLine1":"TDM","sourceSytem" : "MICA", 
      "customerId": "", "ban": ""};
      const aDetails = {"parentAssetIntegrationId":"",
      "reasonForSuspension":"",
      "password":"",
      "serviceEndDate":"",
      "serviceStartDate":"",
      "status":"Active",
      "serviceType" : "Mobile Service",
      "mroEndDate":"",
      "mroName":"",
      "contractEndDate":"",
      "contractName":"", 
      "msisdn": "", "sim": "", "customerId": "", "ban": "", "productName": "", "productCode": "" ,
      "parentSourceSystem" : "MICA" , 
      "sourceSystem" : "MICA"};
        bDetails['customerId'] = (cidnList[index]);
        bDetails['ban'] = (banList[index]);

        aDetails['msisdn'] = (msisdnList[index]);
        aDetails['sim'] = (simList[index]);
        aDetails['customerId'] = (cidnList[index]);
        aDetails['ban'] = (banList[index]);
        aDetails['productName'] = (planNameList[index]);
        aDetails['productCode'] = (pcList[index]);
        billingDetail.push(bDetails);
        assetDetails.push(aDetails) 
    })
    props.updateAssetsDetails(assetDetails);
    props.updateBillingDetails(billingDetail);



    let data = ddcColData;
    let ddcC = props.ddcColumns;
    ddcC.push(data)
    props.addDDCColumns(ddcC)
    setddcInputValue(["", "", "", "", "", ""]);
    disableAddButton(true)
    showReviewPanel(true);
    showBillingPanel(false);
    showAssetsPanel(false)
  }

  const deleteCheckinAsset = (e, ddcKey) => {
    let bDetails = props.billingDetails;
    let aDetails = props.assetsDetails;
   let updatedBillingDetails = bDetails.filter((bD,index) => 
     index !== ddcKey
   )
   let updatedAssetDetails = aDetails.filter((bD,index) => 
     index !== ddcKey
   )
   let deletedColumns = ddcColumns.filter((ddcC, index) => index !== ddcKey);
   props.updateBillingDetails(updatedBillingDetails);
   props.updateAssetsDetails(updatedAssetDetails);
   props.deleteDDCColumns(deletedColumns);
   showAssetsPanel(false)
   showBillingPanel(false);
  }


  const handleSubmitButton = () => {
    displayLoading({ title: "Placing files in DDC", bodyText: "Please standby whilst files are being placed in DDC" });
    const assetAndBillingDetails = { "billingDetails": "", "assetDetails": "" }
    assetAndBillingDetails["billingDetails"] = props.billingDetails
    assetAndBillingDetails["assetDetails"] = props.assetsDetails
    const ddc = JSON.stringify(assetAndBillingDetails);
    props.createDDC(ddc).then((response) => {
      if (response) {
        swal.close();
        swal.fire(
          "Success",
          `AssetDetail Reconcilation and BillingDetail Reconcilation files are placed in DDC`,
          "success"
        );
        props.resetColumns();
        showAssetsPanel(false);
        showBillingPanel(false);
        setddcInputValue(["", "", "", "", "", ""]);
        disableAddButton(true)
      }
      else {
        swal.close();
        displayErrorAlert({
          title: "Error",
          subTitle: "DDC Mock File Creation Failed",
          errorMsg: "Unable to place Assets and Billing Details Reconcilation files in DDC, please try again later"
      });
      }
    })
  }

  useEffect(() => {
    props.resetColumns();
  }, []);

  return (
    <div className="white pb-20">
      <HeaderContent
        title={`DDC Mock File Creation`}
        icon="cogs ops-icon"
        empty={true}
        loadingText="Loading DDC Alignment..."
        redirectUtility={true}
        push={history?.push}
        ></HeaderContent>


      <Table hover responsive id="ddcTable" className="pt-5">
        <TableHead>
          <TableRow>
            {tableHeaderKeys && tableHeaderKeys.map((val, index) =>
              <TableCell key={index} className="px-6 pb-0">{upperFirst(val)}</TableCell>
            )}
            <TableCell className="pl-6 pb-0">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ddcColumns.length < 50 && ddcColumns && ddcColumns.map((ddcVal, ddckey) =>
            <TableRow key={ddckey}>
              {tableHeaderKeys && tableHeaderKeys.map((val, index) =>
                <TableCell key={index}>{errorCount[index]}
                  {ddckey === 0 ?
                    <TextField
                      name={index}
                      id={index}
                      value={ddcInputValue[index]}
                      onChange={e => handleInputChange(e, val, ddckey)}
                      disabled={ddckey === 0 ? false : true}
                      error={(ddckey === 0 && errorCount[index]) == true ? true : false}
                      variant="outlined"
                      className="tableTextFieldError"
                      helperText={(ddckey === 0 && errorCount[index]) == true ? errorText[index] : ''} /> :
                    <TextField
                      name={index}
                      id={index}
                      value={ddckey === 0 ? ddcInputValue[index] : ddcVal.colValue[index].value}
                      onChange={e => handleInputChange(e, val, ddckey)}
                      disabled={true}
                      variant="outlined"
                      className="tableTextField" />}
                </TableCell>)}
              <TableCell>
                {ddckey === 0 ? <Button
                  Button
                  variant="contained"
                  color="primary"
                  onClick={addCheckinAsset}
                  classes={{ root: classes.ddcButtons }}
                  disabled={addButton == true ? true : false}>Add</Button> :
                  <Button
                    Button
                    color="secondary"
                    id="deleteCol"
                    onClick={e => deleteCheckinAsset(e, ddckey)} endIcon={<DeleteOutlineIcon />}></Button>}</TableCell>

            </TableRow>)}
        </TableBody>
      </Table>
      {ddcColumns.length > 1 && reviewPanel == true ?
        <div id="reviewPanel" className="reviewPanel row">
          <div id="billingPanel" className="col-6">
            <div className="reviewHeader">
              <Button color="primary" id="reviewButtons" startIcon={<AddIcon />} onClick={handleBillingPanel}>Billing Details</Button></div>
            {billingPanel == true ?
              <BillingDetailsDDC /> : <div></div>}</div>
          <div id="assetsPanel" className="col-6">
            <div className="reviewHeader">
              <Button color="primary" id="reviewButtons" startIcon={<AddIcon />} onClick={handleAssetsPanel}>Asset Details</Button>
              {assestsPanel == true ?
                <AssetDetailsDDC /> : <div></div>}

            </div></div>
        </div> :
        <div></div>}
      <Button variant="contained" color="primary" classes={{ root: classes.submitButton }}
        onClick={handleSubmitButton}
        disabled={ddcColumns.length > 1 ? false : true} endIcon={<DoneIcon />}>Submit</Button>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    tableHeaderKeys: state.ddc.tableHeaderKeys,
    ddcColumns: state.ddc.ddcColumns,
    billingDetails: state.ddc.billingDetails,
    assetsDetails: state.ddc.assetsDetails,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addDDCColumns: (value) => dispatch(addDDCColumns(value)),
    deleteDDCColumns: (value) => dispatch(deleteDDCColumns(value)),
    resetColumns: () => dispatch(resetColumns()),
    setDDCValues: (msisdn, sim, customerId, ban, pl, pc) => dispatch(setDDCValues(msisdn, sim, customerId, ban, pl, pc)),
    updateAssetsDetails: (assets) => dispatch(updateAssetsDetails(assets)),
    updateBillingDetails: (bills) => dispatch(updateBillingDetails(bills)),
    createDDC: (ddcJson) => dispatch(createDDC(ddcJson))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DDC);
