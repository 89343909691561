import React from "react";
import { SingleDatePicker } from "react-dates";
import moment from "moment";

export default ({
  onDateChange,
  onFocusChange,
  focusedStart,
  focusedEnd,
  startDate,
  endDate,
  handleOnSearch
}) => (
    <div className="row filter-bar payment-row">
      <div className="col-sm-12">
        <span className="date-label">
          <strong>Start Date: </strong>
        </span>
        <span className="datepicker">
          <SingleDatePicker
            numberOfMonths={2}
            date={startDate}
            onDateChange={startDate => onDateChange("startDate", startDate)}
            focused={focusedStart}
            onFocusChange={({ focused }) => onFocusChange("focusedStart", focused)}
            id="startDate"
            isOutsideRange={() => false}
            showDefaultInputIcon={true}
            small={true}
          />
        </span>
        <span className="date-label end-date">
          <strong>End Date: </strong>
        </span>
        <span className="datepicker">
          <SingleDatePicker
            numberOfMonths={2}
            date={endDate}
            onDateChange={endDate => onDateChange("endDate", endDate)}
            focused={focusedEnd}
            onFocusChange={({ focused }) => onFocusChange("focusedEnd", focused)}
            id="endDate"
            isOutsideRange={(day) => day.isBefore(moment(startDate))}
            showDefaultInputIcon={true}
            small={true}           
          />
        </span>
        <span className="button-space">
          <button
            type="button"
            className="btn btn-outline-dark custom"
            onClick={handleOnSearch}
          >
            Search
          <span className="base-green search-icon">
              <i className="td-icon-xs icon-search-find theme-text-primary custom-size" />
            </span>
          </button>
        </span>
      </div>
    </div>
  );
