import React from "react";
import { connect } from 'react-redux';

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  TextField,
  makeStyles
} from "@material-ui/core";

import {
  Person as PersonIcon,
  ArrowBack as ArrowBackIcon,
  People as PeopleIcon
} from "@material-ui/icons";

import {closeNotificationMessageDetails} from '../../actions/notificationActions';

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    right: "-26px",
    top: "40px",
  },
  dialogContent: {
    padding: "0px",
    margin: "0px",
  }
});
const NotificationMessagePanel = (props) =>
{
    const classes = useStyles();

    const d = new Date( props.messageDetails.postedAt );
    const date = d.toLocaleString('default', { month: 'long' }) + " " + d.getDate() + "," + d.getFullYear();
    
    const closeNotificationsDetails = () =>
    {
      props.closeNotificationMessageDetails();
      props.closeNotificationsMessage();
    }
    return (
        <Dialog maxWidth="md" scroll="paper" open={true} classes={{ paper: classes.dialog }}>
          <DialogTitle>Notification Details</DialogTitle>
          <Divider />
          <DialogContent>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box p={1} my={1} width={400}>
                <Box display="flex" justifyContent="space-between">
                {props.messageDetails.teamFlag ?
              (<PeopleIcon fontSize="small" color="primary" />) :
              (
                <PersonIcon fontSize="small" color="primary" />)}
                  <Typography variant="body1">{date}</Typography>
                  </Box>
              </Box>
            </Box>
            <TextField
              label="Message"
              variant="outlined"
              multiline
              rows={10}
              fullWidth
              readOnly
              value={props.messageDetails.message}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ArrowBackIcon />}
              onClick={() => closeNotificationsDetails()}
            >
              Back
            </Button>
          </DialogActions>
        </Dialog>
      );
}
const mapDispatchToProps = (dispatch) => {
    return {
        closeNotificationMessageDetails :() => dispatch(closeNotificationMessageDetails())
    };
};

export default connect(undefined,mapDispatchToProps)(NotificationMessagePanel);