import React from "react";
import Select from "react-select";

const customStyles = {
  valueContainer: provided => ({
    ...provided,
    display: "block",
    width: "100px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: "0.8rem",
    minHeight: "25px"
  })
};

export default ({
  sortingTypes,
  onSelectChange,
  billingAccountNumber,
  sortType
}) => (
    <React.Fragment>
      <div className="row payment-row heading">
        <div className="col-sm-12">
          <span className="date-label">
            <strong>Billing Account No.:</strong>{" "}
            <span>{billingAccountNumber}</span>
          </span>
        </div>
      </div>
      <div>
        <div className="row payment-row heading payment-history">
          <div className="col-sm-10">
            <span>
              <h3>Payment History</h3>
            </span>
          </div>

          <div className="col-sm-2">
            <Select
              value={sortType}
              placeholder="sort by..."
              options={sortingTypes}
              onChange={selectedValue => onSelectChange(selectedValue)}
              isClearable={true}
              styles={customStyles}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
