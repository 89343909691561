export default[
  {
    name: 'Fixed With Modem',
    value: `{
      "TLS_ServicesCount__C": "1", 
      "TLS_SourceSystem__c": "TIM-Order Lodgement",      
      "TLS_AccountUUID__c": "424f9384-2bfe-d16d-a452-4f120056c964",
      "TLS_CorrelationId__c": "TIM-2023-11-2316c1ee450ef6423884e2ad92f0359826",
      "TLS_OrderReason__c": "Telstra Initiated Migration",
      "TLS_SubmitDate__c": "2023-11-23",
      "TLS_ProductMessage__c": "{\"OrderItems\":[{\"product\":{\"productSpecifications\":{\"code\":\"MSVCVOD-SRVC\",\"id\":\"1.1\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1\"},\"productCharacteristics\":[{\"value\":\"Unlisted\",\"name\":\"DirectoryListingType\"},{\"value\":\"MessageBank\",\"name\":\"MessageBankType\"},{\"value\":\"Show my number\",\"name\":\"CallLineIdentificationType\"}]},\"orderItemCharacteristics\":[{\"value\":\"61475131435\",\"name\":\"ServiceNumber\"},{\"value\":\"True\",\"name\":\"Migrate\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"OFF_SIM001\",\"id\":\"1.1.1\"},\"assetRefId\":\"\",\"action\":\"AutoAdd\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"}},\"orderItemCharacteristics\":[{\"value\":\"89610183655225255930\",\"name\":\"SimSerialNumber\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"MSUBVOD-VMED-V02\",\"id\":\"1.1.3\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"}}},{\"product\":{\"productSpecifications\":{\"code\":\"MGRPCPT-PROD\",\"id\":\"1\"},\"assetRefId\":\"\",\"action\":\"Add\"}},{\"product\":{\"productSpecifications\":{\"code\":\"MBLTRDP-PASS\",\"id\":\"1.1.4\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"}}},{\"product\":{\"productSpecifications\":{\"code\":\"MBLTSPD-PROD\",\"id\":\"1.1.2\"},\"assetRefId\":\"\",\"action\":\"AutoAdd\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"},\"productCharacteristics\":[{\"value\":\"enhanced\",\"name\":\"MobileSpeed\"}]}}]}",
      "TLS_ProductFamily__C": "Mobile",
      "TLS_BillCycleDate__C": "2023-11-24",
      "TLS_MobileADDate__C": "2023-11-24",
      "TLS_BillCycleDay__C": "24",      
      "TLS_ChargeModel__C": "CIP",
      "TLS_ChargingCycle__C": "24"
  }`,
  copied:false
},
{
    name: 'Fixed Without Modem',
    value:  `{
      "TLS_ServicesCount__C": "1",
      "TLS_SourceSystem__c": "TIM-Order Lodgement",
      "TLS_AccountUUID__c": "06402f76-ac6f-032c-66a8-7d5a3e88089f",
      "TLS_CorrelationId__c": "TIM-2023-11-230a8dca0667234cfb920962cc7cffb8a2",
      "TLS_OrderReason__c": "Telstra Initiated Migration",
      "TLS_SubmitDate__c": "2023-11-23",
      "TLS_ProductMessage__c": "{\"OrderItems\":[{\"product\":{\"productSpecifications\":{\"code\":\"FSVCACS-FACC\",\"id\":\"1.2\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1\"},\"productCharacteristics\":[{\"value\":\"FTTP\",\"name\":\"nbnTechnologyType\"},{\"value\":\"3\",\"name\":\"nbnServiceClass\"}]},\"orderItemCharacteristics\":[{\"value\":\"AVC193434219128\",\"name\":\"serviceId\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"FSUBBDL-BSML1\",\"id\":\"1.1\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1\"}},\"orderItemCharacteristics\":[{\"value\":\"Y\",\"name\":\"Migrate\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"FSVCVCE-VSVC\",\"id\":\"1.2.1\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.2\"},\"productCharacteristics\":[{\"value\":\"Unlisted\",\"name\":\"DirectoryListingType\"},{\"value\":\"Check Network\",\"name\":\"CallLineIdentificationType\"}]},\"orderItemCharacteristics\":[{\"value\":\"61732078773\",\"name\":\"serviceId\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"FSVCINT-ISVC\",\"id\":\"1.2.2\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.2\"}},\"orderItemCharacteristics\":[{\"value\":\"auto_yarpydbu@bigpond.com\",\"name\":\"serviceId\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"FGRPCPT-FGRP\",\"id\":\"1\"},\"assetRefId\":\"\",\"action\":\"Add\"}}]}",
      "TLS_ProductFamily__C": "Fixed",
      "TLS_BillCycleDate__C": "2023-11-05",
      "TLS_MobileADDate__C": "2023-11-05",
      "TLS_BillCycleDay__C": "05",
      "TLS_ChargeModel__C": "CIP",
      "TLS_ChargingCycle__C": "05",
      "TLS_Address__C": "{\"city\":\"VICTORIA POINT\",\"postcode\":\"4165\",\"addressLine1\":\"9 KENTIA CT,\",\"state\":\"QLD\",\"addressId\":\"143097578\"}"
  }`,
  copied:false
},
{
    name: 'JB Plan',
    value:  `[{
      "TLS_ServicesCount__C": "1",
      "TLS_SourceSystem__c": "TIM-Order Lodgement",
      "TLS_AccountUUID__c": "8a3e6108-eb40-a80c-fb84-4853f9aac8df",
      "TLS_CorrelationId__c": "TIM-2023-11-22926b7fed13e246c1a68624ae20421399",
      "TLS_OrderReason__c": "Telstra Initiated Migration",
      "TLS_SubmitDate__c": "2023-11-22",
      "TLS_ProductMessage__c": "{\"OrderItems\":[{\"product\":{\"productSpecifications\":{\"code\":\"MSVCVOD-SRVC\",\"id\":\"1.1\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1\"},\"productCharacteristics\":[{\"value\":\"Unlisted\",\"name\":\"DirectoryListingType\"},{\"value\":\"MessageBank\",\"name\":\"MessageBankType\"},{\"value\":\"Show my number\",\"name\":\"CallLineIdentificationType\"}]},\"orderItemCharacteristics\":[{\"value\":\"61475140776\",\"name\":\"ServiceNumber\"},{\"value\":\"True\",\"name\":\"Migrate\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"OFF_SIM001\",\"id\":\"1.1.1\"},\"assetRefId\":\"\",\"action\":\"AutoAdd\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"}},\"orderItemCharacteristics\":[{\"value\":\"89610183665383382863\",\"name\":\"SimSerialNumber\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"MSUBVOD-JSML-V02\",\"id\":\"1.1.3\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"}}},{\"product\":{\"productSpecifications\":{\"code\":\"MGRPCPT-JBHF\",\"id\":\"1\"},\"assetRefId\":\"\",\"action\":\"Add\"}},{\"product\":{\"productSpecifications\":{\"code\":\"MBLTRDP-PASS\",\"id\":\"1.1.4\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"}}},{\"product\":{\"productSpecifications\":{\"code\":\"MBLTSPD-PROD\",\"id\":\"1.1.2\"},\"assetRefId\":\"\",\"action\":\"AutoAdd\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"},\"productCharacteristics\":[{\"value\":\"standard\",\"name\":\"MobileSpeed\"}]}}]}",
      "TLS_ProductFamily__C": "Mobile",
      "TLS_BillCycleDate__C": "2023-12-01",
      "TLS_MobileADDate__C": "2023-12-01",
      "TLS_BillCycleDay__C": "01",
      "TLS_ChargeModel__C": "AutoPay",
      "TLS_ChargingCycle__C": "01"
    }]`,
    copied:false
},
{
    name: 'Plan Only Mobile',
    value:  `{
      "TLS_ServicesCount__C": "1",
      "TLS_SourceSystem__c": "TIM-Order Lodgement",
      "TLS_AccountUUID__c": "424f9384-2bfe-d16d-a452-4f120056c964",
      "TLS_CorrelationId__c": "TIM-2023-11-2316c1ee450ef6423884e2ad92f0359826",
      "TLS_OrderReason__c": "Telstra Initiated Migration",
      "TLS_SubmitDate__c": "2023-11-23",
      "TLS_ProductMessage__c": "{\"OrderItems\":[{\"product\":{\"productSpecifications\":{\"code\":\"MSVCVOD-SRVC\",\"id\":\"1.1\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1\"},\"productCharacteristics\":[{\"value\":\"Unlisted\",\"name\":\"DirectoryListingType\"},{\"value\":\"MessageBank\",\"name\":\"MessageBankType\"},{\"value\":\"Show my number\",\"name\":\"CallLineIdentificationType\"}]},\"orderItemCharacteristics\":[{\"value\":\"61475131435\",\"name\":\"ServiceNumber\"},{\"value\":\"True\",\"name\":\"Migrate\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"OFF_SIM001\",\"id\":\"1.1.1\"},\"assetRefId\":\"\",\"action\":\"AutoAdd\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"}},\"orderItemCharacteristics\":[{\"value\":\"89610183655225255930\",\"name\":\"SimSerialNumber\"}]},{\"product\":{\"productSpecifications\":{\"code\":\"MSUBVOD-VMED-V02\",\"id\":\"1.1.3\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"}}},{\"product\":{\"productSpecifications\":{\"code\":\"MGRPCPT-PROD\",\"id\":\"1\"},\"assetRefId\":\"\",\"action\":\"Add\"}},{\"product\":{\"productSpecifications\":{\"code\":\"MBLTRDP-PASS\",\"id\":\"1.1.4\"},\"assetRefId\":\"\",\"action\":\"Add\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"}}},{\"product\":{\"productSpecifications\":{\"code\":\"MBLTSPD-PROD\",\"id\":\"1.1.2\"},\"assetRefId\":\"\",\"action\":\"AutoAdd\",\"productRelationship\":{\"type\":\"ReliesOn\",\"id\":\"1.1\"},\"productCharacteristics\":[{\"value\":\"enhanced\",\"name\":\"MobileSpeed\"}]}}]}",
      "TLS_ProductFamily__C": "Mobile",
      "TLS_BillCycleDate__C": "2023-11-24",
      "TLS_MobileADDate__C": "2023-11-24",
      "TLS_BillCycleDay__C": "24",
      "TLS_ChargeModel__C": "CIP",
      "TLS_ChargingCycle__C": "24"
  }`,
  copied:false
}
]
