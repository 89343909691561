import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HeaderContent from "../../common/Header/HeaderContent";
import {
  Autocomplete,
  TextField,
  Grid,
  Paper,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  Skeleton, 
  Avatar
} from "@mui/material";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  fetchCheckoutCountByTeam,
} from "../../../actions/statsActions";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BugReportIcon from '@mui/icons-material/BugReport';
import ConstructionIcon from '@mui/icons-material/Construction';
import moment from "moment";
import { indigo } from "@mui/material/colors";
import { Line, Bar } from "react-chartjs-2";
import BreadTable from "@library/core/tables/BreadTable";
// import ReactCardFlip from 'react-card-flip';

const statistic = (props) => {
  const [programList, setProgramList] = useState([]);
  const [availableTeamList, setAvailableTeamList] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedTeam, setSelectedTeam] = useState("");
  const [groupedProgramList, setGroupedProgramList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalCheckouts, setTotalCheckouts] = useState(0);
  const [checkoutDataGraphLabels, setCheckoutDataGraphLabels] = useState([]);
  const [checkoutDataGraph, setCheckoutDataGraph] = useState([]);
  const [checkoutDataDisplayType, setCheckoutDataDisplayType] = useState(2);
  const [checkoutChartType, setCheckoutChartType] = useState(1);
  const [checkoutDataChannelTypeGraphLabels, setCheckoutDataChannelTypeGraphLabels] = useState([]);
  const [jiraTicketLabels, setJiraTicketLabels] = useState([]);
  const [jiraTicketData, setJiraTicketData] = useState([]);
  const [jiraTicketsCount, setJiraTicketsCount] = useState(0);
  const [jiraDataDisplayType, setJiraDataDisplayType] = useState(1);
  const [checkoutCountTeamNamesStackChart, setCheckoutCountTeamNamesStackChart] = useState([]);
  const [checkoutCountByChannelTypeTeamNamesStackChart, setCheckoutCountByChannelTypeTeamNamesStackChart] = useState([]);
  const [flipped, setFilpped] = useState(false);
  const [checkoutFlipped, setCheckoutFlipped] = useState(false);
  const [jiraFlipped, setJiraFlipped] = useState(false);
  const [utilityFlipped, setUtilityFlipped] = useState(false);
  const [totalCheckoutTransactions, setTotalCheckoutTransactions] = useState(0);
  const [jiraChartData, setJiraChartData] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [utiltyGraphLabels, setUtiltyGraphLabels] = useState([]);
  const [utilityStackChart, setUtilityStackChart] = useState([]);
  const [channelTypeStackChart, setChannelTypeStackChart] = useState([]);  
  const [utilityDataDisplayType, setUtilityDataDisplayType] = useState(1);
  const [utilityChartType, setUtilityChartType] = useState(1);
  const COLORS = [
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba',
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba',
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba',
    '#4dc9f6',
    '#f67019',
    '#f53794',
    '#537bc4',
    '#acc236',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba',
  ];

  const dataArgs = {
    size: "large",
    isLoading: false,
    isExpandable: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: "All", value: -1 }],
    columnMetadata: [
      {
        dataset: 1,
        title: "Service ID",
        field: ["serviceId"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field == null ? 'Custom Service ID': field}
          </Typography>
        ),
      },
      {
        dataset: 2,
        title: "Parent Program",
        field: ["parentProgram"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 3,
        title: "Team Name",
        field: ["teamName"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 4,
        title: "Total Checkouts",
        field: ["checkoutCount"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
    ],
  };

  const dataArgsChannelType = {
    size: "large",
    isLoading: false,
    isExpandable: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: "All", value: -1 }],
    columnMetadata: [
      {
        dataset: 1,
        title: "Channel Type",
        field: ["channelType"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 2,
        title: "Team Name",
        field: ["teamName"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 3,
        title: "Parent Program",
        field: ["parentProgram"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 4,
        title: "Transaction in Percentage",
        field: ["totalTransactionPercent"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 5,
        title: "Transaction",
        field: ["totalTransaction"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      }
    ],
  };

  const jiraTicketDataArgs = {
    size: "large",
    isLoading: false,
    isExpandable: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: "All", value: -1 }],
    columnMetadata: [
      {
        dataset: 1,
        title: "Ticket",
        field: ["key"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 2,
        title: "Status",
        field: ["status"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field.name}
          </Typography>
        ),
      },
      {
        dataset: 2,
        title: "Project",
        field: ["project"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field.name}
          </Typography>
        ),
      },
      {
        dataset: 3,
        title: "Team Name",
        field: ["fields"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field[0].value.split("-")[1]}
          </Typography>
        ),
      },
      {
        dataset: 3,
        title: "Service ID",
        field: ["fields"],
        expandable: true,
        component: (field) => (
         
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {(typeof field[1].value==='string')? field[1].value : "Custom Service ID"}
          </Typography>
        ),
      },
      {
        dataset: 4,
        title: "Qty Checked Out",
        field: ["fields"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {(typeof field[2].value==='string')?field[2].value:""}
          </Typography>
        ),
      }
    ],
  };

  const utilityData = {
    size: "large",
    isLoading: false,
    isExpandable: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15, { label: "All", value: -1 }],
    columnMetadata: [
      {
        dataset: 1,
        title: "Parent Program",
        field: ["parentProgram"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 2,
        title: "Team Name",
        field: ["teamName"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 3,
        title: "Utility",
        field: ["utilityType"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 4,
        title: "Channel Type",
        field: ["channelType"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 5,
        title: "Total Transaction",
        field: ["totalTransaction"],
        expandable: true,
        component: (field) => (
         
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      },
      {
        dataset: 6,
        title: "Total Transaction(%)",
        field: ["totalTransactionPercent"],
        expandable: true,
        component: (field) => (
          <Typography
            noWrap
            style={{ width: "135px", margin: "auto" }}
            noWrap
            variant="body2"
          >
            {field}
          </Typography>
        ),
      }
    ],
  };


  const checkoutStackChartData = {
    labels: checkoutDataGraphLabels,
    datasets: checkoutCountTeamNamesStackChart,
  };

  const checkoutByChannelTypeStackChartData = {
    labels: checkoutDataChannelTypeGraphLabels,
    datasets: checkoutCountByChannelTypeTeamNamesStackChart,
  };

  const utiltyChartData = {
    labels: utiltyGraphLabels,
    datasets: utilityStackChart,
  };

  const utiltyChannelTypeChartData = {
    labels: utiltyGraphLabels,
    datasets: channelTypeStackChart,
  };

  const jiraData = {
    labels: jiraTicketLabels,
    datasets: jiraChartData,
  };


  const checkoutDataStackChartOptions = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Count",
            fontColor: "black",
            fontSize: 20,
          }
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Teams",
            fontColor: "black",
            fontSize: 20,
          },
        
        },
      ],
    },
  };

  const jiraTicketDataOptions = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "TDT Tickets Count",
            fontColor: "black",
            fontSize: 20,
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Teams",
            fontColor: "black",
            fontSize: 20,
          },
        },
      ],
    },
  };

  const jiraTicketDataOptionsTeam = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "TDT Tickets Count",
            fontColor: "black",
            fontSize: 20,
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Teams",
            fontColor: "black",
            fontSize: 20,
          },
        },
      ],
    },
  };

  const checkoutDataByChannelTypeOptions = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Percentage",
            fontColor: "black",
            fontSize: 20,
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Teams",
            fontColor: "black",
            fontSize: 20,
          },
        },
      ],
    },
  };

  const utiltyChartDataOptions = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Percentage",
            fontColor: "black",
            fontSize: 20,
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Teams",
            fontColor: "black",
            fontSize: 20,
          },
        },
      ],
    },
  }
  const onProgramChange = (value) => {
    if (value) {
      setSelectedProgram(value);
      if("ALL" !== value){
        var teams = groupedProgramList[value];
        var teamsMap = [];
        teamsMap.push("ALL");
        teams && teams.map((team) => teamsMap.push(team.teamName));
        setAvailableTeamList(teamsMap);
        setSelectedTeam("");
      }else{
        setAvailableTeamList(["ALL"]);
        onTeamChange("ALL");
      }
    }    
  };

  const onTeamChange = (value) => {
    if (value) {
      setSelectedTeam(value);
    }
  };

  const onCheckoutDataDisplayTypeChange = (event) => {
    setCheckoutDataDisplayType(event.target.value);
  };

  const jiraDataDisplayTypeChange = (event) => {
    setJiraDataDisplayType(event.target.value);
  }

  const utilityDataDisplayTypeChange = (event) => {
    setUtilityDataDisplayType(event.target.value);
  }

  const onUtilityChartTypeChange = (event) => {
    setUtilityChartType(event.target.value);
  }

  const onCheckoutChartTypeChange = (event) =>{
    setCheckoutChartType(event.target.value);
  };

  const validateFields = () => {
    if("" === fromDate || !fromDate || ""=== toDate || !toDate || ""=== selectedTeam || null == selectedTeam || ""=== selectedProgram || null == selectedProgram){
      return false;
    }else{
      return true;
    }
  }
  const searchStatistics = () => {
    let teamIds = [];
    if(validateFields()){
    
    if("ALL" === selectedProgram){
      props.allTeams && props.allTeams.map((team) => {
        teamIds.push(team.teamId + '-'+team.teamName);
      })
    }else{
      var teams = groupedProgramList[selectedProgram];
      if("ALL" === selectedTeam){
        teams && teams.map((team) => {
          teamIds.push(team.teamId + '-'+team.teamName);
        })
      }else{
        teams && teams.map((team) => {
          if(team.teamName === selectedTeam){
            teamIds.push(team.teamId + '-'+team.teamName);
          }
        })
      }
    }
    props.fetchCheckoutCountByTeam(
      teamIds.join(),
      moment(fromDate).format("yyyy-MM-DD"),
      moment(toDate).format("yyyy-MM-DD")
    );
    }
  };
  const calculateFromDate = () =>{
    if([1,2,3].includes(new Date().getMonth())) return new Date("01-01-2022");
    if([4,5,6].includes(new Date().getMonth())) return new Date("04-01-2022");
    if( [7,8,9].includes(new Date().getMonth())) return new Date("07-01-2022");
    if([10,11,12].includes(new Date().getMonth())) return new Date("10-01-2022");
  }
  
  useEffect(() => {
    let allTeams = [];
    let obj = {
      teamId: "ALL",
      teamName: "ALL",
    }
    allTeams.push(obj);
    props.allTeams.map((team) => allTeams.push(team));
    var selectedPgm="";
    const groupedProgramList =
    allTeams &&
    allTeams.reduce(function (accumulator, object) {
      if(object["teamId"] === props.userTeam){
        selectedPgm = object["teamParentProgram"];
        setSelectedTeam(object["teamName"]);
        setFromDate(calculateFromDate());
        setToDate(new Date());
      }
        const key = object["teamParentProgram"];
        if(key){
          if (!accumulator[key]) {
            accumulator[key] = [];
          }
          accumulator[key].push(
            (({ teamId, teamName }) => ({ teamId, teamName }))(object)
          );
        }else{
          accumulator["ALL"] = [];
          accumulator["ALL"].push(
            (({ teamId, teamName }) => ({ teamId, teamName }))(object)
          );
        }
        return accumulator;
      }, {});
      
    setGroupedProgramList(groupedProgramList);
    setProgramList(Object.keys(groupedProgramList));
    var teams = groupedProgramList[selectedPgm];
    setSelectedProgram(selectedPgm);
    var teamsMap = [];
    teamsMap.push("ALL");
    teams && teams.map((team) => teamsMap.push(team.teamName));
    setAvailableTeamList(teamsMap);
  }, []);

  useEffect(() => {
    setTotalUsers(0);
    setCheckoutDataGraphLabels([]);
    setCheckoutDataGraph([]);
    setCheckoutDataDisplayType(2);
    setJiraTicketLabels([]);
	  setJiraTicketData	([]);
	  setJiraDataDisplayType(1);
    searchStatistics();
    setJiraChartData([]);
    setCheckoutDataChannelTypeGraphLabels([]);
    setCheckoutCountByChannelTypeTeamNamesStackChart([]);
    setCheckoutCountTeamNamesStackChart([]);
    setUtiltyGraphLabels([]);
    setUtilityStackChart([]);
    searchStatistics();
   }, [selectedTeam, selectedProgram, fromDate, toDate],[]);

   

  useEffect(() => {
    setJiraChartData([]);
    setJiraTicketLabels([]);
    var labels = [];
    let jiraObject = [];
    //let jirsIssuesCount = 0;
    if(selectedProgram != "" && props.jiraIssues){
       if(props.jiraIssues && props.jiraIssues.length > 0){
         setJiraTicketsCount(props.jiraIssues.length);
       }
      if (
        props.jiraIssues &&
        props.jiraIssues.length > 0
      ) {
        props.jiraIssues.map((obj) => {
          let featureTeamName = obj.fields[0].value;
          let id = obj.fields[1].value;
          let checkedOutData = obj.fields[2].value;
          let teamId = featureTeamName.split('-')[0];
          let teamsName = featureTeamName.split('-')[1];
          let team  = props.allTeams.filter(team => team.teamId.toString() === teamId);
          if(typeof checkedOutData==='string'  && typeof featureTeamName === 'string'){
            //jirsIssuesCount = jirsIssuesCount +  1;
              if("ALL" === selectedProgram){
                if(!labels.includes(team[0].teamParentProgram)){
                  labels.push(team[0].teamParentProgram);
                  jiraObject.push(Object.assign({},{[team[0].teamParentProgram]:[
                    {
                      serviceId : typeof id==='string' ? id : 'Custom Service ID',
                      count : checkedOutData,
                      teamName : teamsName 
                    }
                  ]}));
                }else{
                  jiraObject.map((object,key) => {
                    if(jiraObject[key][team[0].teamParentProgram]){
                      jiraObject[key][team[0].teamParentProgram].push({
                        serviceId : typeof id==='string' ? id : 'Custom Service ID',
                        count : checkedOutData,
                        teamName : teamsName
                      });
                    }
                  })
                }
              }else{
                if(!labels.includes(team[0].teamName)){
                labels.push(team[0].teamName);
                jiraObject.push(Object.assign({},{[team[0].teamName]:[
                  {
                    serviceId : typeof id==='string' ? id : 'Custom Service ID',
                    count : checkedOutData,
                    teamName : teamsName 
                  }
                ]}));
              }else{
                jiraObject.map((object,key) => {
                  if(jiraObject[key][team[0].teamName]){
                    jiraObject[key][team[0].teamName].push({
                      serviceId : typeof id==='string' ? id : 'Custom Service ID',
                      count : checkedOutData,
                      teamName : teamsName
                    });
                  }
                })
              }
              
            }
          }
        });
        var dataArray = [];
        let dataChartCount =0;
        // if("ALL" === selectedProgram){
          if (
            jiraObject &&
            jiraObject.length > 0
          ) { 
            labels.map((label) => {
              var teamNames = []; 
              var serviceIds = [];   
              var count1 = [];
              var teamNamesArray =[];
              var serviceIdArray =[];
              var serviceIdCountArray =[];
              let filteredData = jiraObject.filter((object) =>  object[label] && object[label].length > 0 );
              filteredData[0][label].map( object => {
                if(!teamNames.includes(object.teamName) ){
                  teamNames.push(object.teamName);
                }
  
                if(!serviceIds.includes(object.serviceId)){
                  serviceIds.push(object.serviceId);
                  count1.push(parseInt(object.count));
               }else{
                       var index = serviceIds.findIndex(serviceId => serviceId === object.serviceId);
                       var count2 = count1[index] + parseInt(object.count);
                            count1[index] = count2;
                      }
              })
              teamNamesArray.push(Object.assign({},{[label]:teamNames}));
              serviceIdArray.push(Object.assign({},{[label]:serviceIds}));
              serviceIdCountArray.push(Object.assign({},{[label]:count1}));
              for (var j = 0; j < serviceIdArray.length; j++) {
                for(var i = 0; i < serviceIdArray[j][label].length; i++){
                  var dataForChart = [];
                  for(var labelIndex = 0; labelIndex < labels.length; labelIndex++){
                    if(labels[labelIndex] === label){
                      var datafromDataset =[];
                      var index = dataArray.findIndex(dataset => dataset.label === serviceIdArray[j][label][i]);
                      if(index >= 0){
                         datafromDataset.push(dataArray[index].data);
                         datafromDataset[0][labelIndex] = serviceIdCountArray[j][label][i];
                       }else{
                         dataForChart.push(serviceIdCountArray[j][label][i]);
                       }
                    }else{
                      dataForChart.push(null);
                    }
                  }
                  if(datafromDataset.length <=0 ){
                    dataChartCount = dataChartCount + 1;
                    dataArray.push(Object.assign({},{label:serviceIdArray[j][label][i],data:dataForChart,fill:false,backgroundColor:COLORS[dataChartCount]}));
                  }
                }
              }
            });
          }      
          setJiraChartData(dataArray);
          setJiraTicketLabels(labels);
    }
    //setJiraTicketsCount(jirsIssuesCount);
    }   
  }, [props.jiraIssues]);

  useEffect(() => {
    if(selectedProgram != "" && props.usersList && props.usersList.length > 0){
      setTotalUsers(props.usersList.length);
    }
  }, [props.usersList]);


  useEffect(() => {
    let labels = [];
    let data = [];
    let totalCheckoutCont = 0;
    setCheckoutCountTeamNamesStackChart([]);
    if(selectedProgram != "" && props.checkoutCountByServiceId){
      props.checkoutCountByServiceId.length > 0 && props.checkoutCountByServiceId.map((obj) => {
        totalCheckoutCont = totalCheckoutCont + obj.checkoutCount;      
      });
      setTotalCheckouts(totalCheckoutCont);
      if("ALL" === selectedProgram){
        if (
          props.checkoutCountByServiceId &&
          props.checkoutCountByServiceId.length > 0
        ) {
          props.checkoutCountByServiceId.map((obj) => {
            if(!labels.includes(obj.parentProgram)){
              labels.push(obj.parentProgram);
            }
          });
  
          labels.map((label) => {
            let count =0;
            let filteredData = props.checkoutCountByServiceId.filter(checkout => checkout.parentProgram === label);
            filteredData.map(obj => {
              count = count + obj.checkoutCount
            })
            data.push(count);
          });
          setCheckoutDataGraphLabels(labels);
          setCheckoutDataGraph(data);
        }      
        var dataArray = [];
        let dataChartCount =0;
        for(var label=0; label<labels.length; label++ ){
          let filteredData = props.checkoutCountByServiceId.filter(checkout => checkout.parentProgram === labels[label]);
          var teamNames = []; 
          var serviceIds = [];   
          var count1 = [];
          var teamNamesArray =[];
          var serviceIdArray =[];
          var serviceIdCountArray =[];
          filteredData.map(obj => {
                var serviceId =  obj.serviceId === null ? 'Custom Service ID':obj.serviceId;
                  if(!teamNames.includes(obj.teamName)){
                    teamNames.push(obj.teamName);
                  }
                  if(!serviceIds.includes(serviceId)){
                    serviceIds.push(serviceId);
                    count1.push(obj.checkoutCount);
                  }
                else{
                  var index = teamNames.findIndex(teamName => teamName === obj.teamName);
                  var count2 = count1[index] + obj.checkoutCount;
                  count1[index] = count2;
                }
              })
              teamNamesArray.push(Object.assign({},{[labels[label]]:teamNames}));
              serviceIdArray.push(Object.assign({},{[labels[label]]:serviceIds}));
              serviceIdCountArray.push(Object.assign({},{[labels[label]]:count1}));
              for (var j = 0; j < serviceIdArray.length; j++) {
                for(var i = 0; i < serviceIdArray[j][labels[label]].length; i++){
                  var dataForChart = [];
                  for(var labelIndex = 0; labelIndex < labels.length; labelIndex++){
                    if(labelIndex === label){
                      var datafromDataset =[];
                      var index = null;
                      index = dataArray.findIndex(dataset => dataset.label === serviceIdArray[j][labels[label]][i]);
                      if(index >= 0){
                        datafromDataset.push(dataArray[index].data);
                        datafromDataset[0][label] = serviceIdCountArray[j][labels[label]][i];
                      }else{
                        dataForChart.push(serviceIdCountArray[j][labels[label]][i]);
                        dataChartCount = dataChartCount + 1;
                      }
                    }else{
                      dataForChart.push(null);
                    }
                  }
                    if(datafromDataset.length <=0 ){
                      dataArray.push(Object.assign({},{label:serviceIdArray[j][labels[label]][i],data:dataForChart,fill:false,backgroundColor:COLORS[dataChartCount]}));
                     
                    }
                  
                }
              }
        }
        setCheckoutCountTeamNamesStackChart(dataArray);
      }else{
        if( selectedProgram && props.checkoutCountByServiceId ){
          props.checkoutCountByServiceId.map((obj) => {
            if(!labels.includes(obj.teamName)){
              labels.push(obj.teamName);
            }
          });
          if("ALL" === selectedTeam){
            let dataChartCount =0;
            var dataArray = [];
            for(var label=0; label<labels.length; label++ ){
              var teamNamesArray =[];
              var serviceIds = []; 
              var serviceIdArray =[];
              var teamNames = []; 
              var count1 = [];
              var serviceIdCountArray = [];
              let count =0;
              let filteredData = props.checkoutCountByServiceId.filter(checkout => checkout.teamName === labels[label]);
              filteredData.map(obj => {
                var serviceId =  obj.serviceId === null ? 'Custom Service ID':obj.serviceId;
                
                  if(!teamNames.includes(obj.teamName)){
                    teamNames.push(obj.teamName);
                  }
                  if(!serviceIds.includes(serviceId)){
                    serviceIds.push(serviceId);
                    count1.push(obj.checkoutCount);
                  }
                else{
                  var index = teamNames.findIndex(teamName => teamName === obj.teamName);
                  var count2 = count1[index] + obj.checkoutCount;
                  count1[index] = count2;
                }
              })
              teamNamesArray.push(Object.assign({},{[labels[label]]:teamNames}));
              serviceIdArray.push(Object.assign({},{[labels[label]]:serviceIds}));
              serviceIdCountArray.push(Object.assign({},{[labels[label]]:count1}));
              for (var j = 0; j < serviceIdArray.length; j++) {
                for(var i = 0; i < serviceIdArray[j][labels[label]].length; i++){
                  var dataForChart = [];
                  for(var labelIndex = 0; labelIndex < labels.length; labelIndex++){
                    if(labelIndex === label){
                      var datafromDataset =[];
                      var index = null;
                      index = dataArray.findIndex(dataset => dataset.label === serviceIdArray[j][labels[label]][i]);
                      if(index >= 0){
                        datafromDataset.push(dataArray[index].data);
                        datafromDataset[0][label] = serviceIdCountArray[j][labels[label]][i];
                      }else{
                        dataForChart.push(serviceIdCountArray[j][labels[label]][i]);
                        dataChartCount = dataChartCount + 1;
                      }
                    }else{
                      dataForChart.push(null);
                    }
                  }
                  if(datafromDataset.length <=0 ){
                    dataArray.push(Object.assign({},{label:serviceIdArray[j][labels[label]][i],data:dataForChart,fill:false,backgroundColor:COLORS[dataChartCount]}));
                  }
                  setCheckoutDataGraphLabels(labels);
                }
              }
            }
            setCheckoutCountTeamNamesStackChart(dataArray);
          }else{
            labels && labels.map((label) => {
              let count =0;
              let colorCount =0;
               var dataArray = [];
              let filteredData = props.checkoutCountByServiceId.filter(checkout => checkout.teamName === label);
              filteredData.map(obj => {
                count = count + obj.checkoutCount
                colorCount = colorCount+1;
                var dataForChart = [];
                dataForChart.push(obj.checkoutCount);
                dataArray.push(Object.assign({},{label:obj.serviceId == null? 'Custom Service ID':obj.serviceId,data:dataForChart,fill:false,backgroundColor:COLORS[colorCount]}));
              })
              data.push(count);
              setCheckoutCountTeamNamesStackChart(dataArray);
            });
            setCheckoutDataGraphLabels(labels);
            setCheckoutDataGraph(data);
          }
          
        }
  
      }
    }
  }, [props.checkoutCountByServiceId]);

  
  useEffect(() => {
    setCheckoutCountByChannelTypeTeamNamesStackChart([])
    let labels = [];
    let data = [];
    let totalCheckouts = 0;
    if(selectedProgram != "" && props.checkoutCountByChannelType){
      props.checkoutCountByChannelType.length > 0 && props.checkoutCountByChannelType.map((obj) => {
        totalCheckouts = totalCheckouts + obj.totalTransaction;  
      });
      setTotalCheckoutTransactions(totalCheckouts);
      if("ALL" === selectedProgram){
        if (
          props.checkoutCountByChannelType &&
          props.checkoutCountByChannelType.length > 0
        ) {
          props.checkoutCountByChannelType.map((obj) => {
            if(!labels.includes(obj.parentProgram)){
              labels.push(obj.parentProgram);
            }
          });
          labels.map((label) => {
            let count =0;
            let filteredData = props.checkoutCountByChannelType.filter(checkout => checkout.parentProgram === label);
            filteredData.map(obj => {
              count = count + obj.totalTransaction
            })
            data.push(count);
          });
          setCheckoutDataChannelTypeGraphLabels(labels);
          setCheckoutDataGraph(data);
        }      
        var dataArray = [];
        let dataChartCount =0;
        for(var label=0; label<labels.length; label++ ){
          let filteredData = props.checkoutCountByChannelType.filter(checkout => checkout.parentProgram === labels[label]);
          var teamNames = []; 
          var channelTypes = [];   
          var count1 = [];
          var teamNamesArray =[];
          var channelTypesArray =[];
          var channelTypesCountArray =[];
          filteredData.map(obj => {
               
                  if(!teamNames.includes(obj.teamName)){   
                    teamNames.push(obj.teamName);
                  }
                  if(!channelTypes.includes(obj.channelType)){
                    channelTypes.push(obj.channelType);
                    count1.push(obj.totalTransactionPercent.toString().substring(0, 5));
                  
                }else{
                  var index = teamNames.findIndex(teamName => teamName === obj.teamName);
                  var count2 = count1[index] + (obj.totalTransactionPercent).toString().substring(0, 5);
                  count1[index] = count2;
                }
              })
              teamNamesArray.push(Object.assign({},{[labels[label]]:teamNames}));
              channelTypesArray.push(Object.assign({},{[labels[label]]:channelTypes}));
              channelTypesCountArray.push(Object.assign({},{[labels[label]]:count1}));
              for (var j = 0; j < channelTypesArray.length; j++) {
                for(var i = 0; i < channelTypesArray[j][labels[label]].length; i++){
                  var dataForChart = [];
                  for(var labelIndex = 0; labelIndex < labels.length; labelIndex++){
                    if(labelIndex === label){
                      var datafromDataset =[];
                      var index = null;
                      index = dataArray.findIndex(dataset => dataset.label === channelTypesArray[j][labels[label]][i]);
                      if(index >= 0){
                        datafromDataset.push(dataArray[index].data);
                        datafromDataset[0][label] = channelTypesCountArray[j][labels[label]][i];
                      }else{
                        dataForChart.push(channelTypesCountArray[j][labels[label]][i]);
                        dataChartCount = dataChartCount + 1;
                      }
                    }else{
                      dataForChart.push(null);
                    }
                  }
                    if(datafromDataset.length <=0 ){
                      dataArray.push(Object.assign({},{label:channelTypesArray[j][labels[label]][i],data:dataForChart,fill:false,backgroundColor:COLORS[dataChartCount]}));
                     
                    }
                  
                }
              }
        }
        setCheckoutCountByChannelTypeTeamNamesStackChart(dataArray);
      }else{
        if( selectedProgram && props.checkoutCountByChannelType ){
          props.checkoutCountByChannelType.map((obj) => {
            if(!labels.includes(obj.teamName)){
              labels.push(obj.teamName);
            }
          });
          
          if("ALL" === selectedTeam){
            let dataChartCount =0;
            var dataArray = [];
            for(var label=0; label<labels.length; label++ ){
              var teamNamesArray =[];
              var channelTypes = []; 
              var channelTypesArray =[];
              var teamNames = []; 
              var count1 = [];
              var channelTypesCountArray = [];
              let count =0;
              let filteredData = props.checkoutCountByChannelType.filter(checkout => checkout.teamName === labels[label]);
              filteredData.map(obj => {
                
                  if(!teamNames.includes(obj.teamName)){
                    teamNames.push(obj.teamName);
                  }
                  if(!channelTypes.includes(obj.channelType)){
                    channelTypes.push(obj.channelType);
                    count1.push((obj.totalTransactionPercent).toString().substring(0, 5));
                  }
                else{
                  var index = teamNames.findIndex(teamName => teamName === obj.teamName);
                  var count2 = count1[index] + (obj.totalTransactionPercent).toString().substring(0, 5);
                  count1[index] = count2;
                }
              })
              teamNamesArray.push(Object.assign({},{[labels[label]]:teamNames}));
              channelTypesArray.push(Object.assign({},{[labels[label]]:channelTypes}));
              channelTypesCountArray.push(Object.assign({},{[labels[label]]:count1}));
              for (var j = 0; j < channelTypesArray.length; j++) {
                for(var i = 0; i < channelTypesArray[j][labels[label]].length; i++){
                  var dataForChart = [];
                  for(var labelIndex = 0; labelIndex < labels.length; labelIndex++){
                    if(labelIndex === label){
                      var datafromDataset =[];
                      var index = null;
                      index = dataArray.findIndex(dataset => dataset.label === channelTypesArray[j][labels[label]][i]);
                      if(index >= 0){
                        datafromDataset.push(dataArray[index].data);
                        datafromDataset[0][label] = channelTypesCountArray[j][labels[label]][i];
                      }else{
                        dataForChart.push(channelTypesCountArray[j][labels[label]][i]);
                        dataChartCount = dataChartCount + 1;
                      }
                    }else{
                      dataForChart.push(null);
                    }
                  }
                  if(datafromDataset.length <=0 ){
                    dataArray.push(Object.assign({},{label:channelTypesArray[j][labels[label]][i],data:dataForChart,fill:false,backgroundColor:COLORS[dataChartCount]}));
                  }
                  setCheckoutDataChannelTypeGraphLabels(labels);
                }
              }
            }
            setCheckoutCountByChannelTypeTeamNamesStackChart(dataArray);
          }else{
            labels && labels.map((label) => {
              let count =0;
              let colorCount =0;
               var dataArray = [];
              let filteredData = props.checkoutCountByChannelType.filter(checkout => checkout.teamName === label);
              filteredData.map(obj => {
                count = count + obj.totalTransaction
                colorCount = colorCount+1;
                var dataForChart = [];
                dataForChart.push((obj.totalTransactionPercent).toString().substring(0, 5));
                dataArray.push(Object.assign({},{label:obj.channelType,data:dataForChart,fill:false,backgroundColor:COLORS[colorCount]}));
              })
              data.push(count);
              setCheckoutCountByChannelTypeTeamNamesStackChart(dataArray);
            });
            setCheckoutDataChannelTypeGraphLabels(labels);
            setCheckoutDataGraph(data);
          }
          
        }
  
      }
    }
  }, [props.checkoutCountByChannelType])

  useEffect(() => {
    setUtilityStackChart([]);
    let labels = [];
    let data = [];
    let totalTransaction = 0;
    if(selectedProgram != "" && props.utilityList){
      props.utilityList.length > 0 && props.utilityList.map((obj) => {
        totalTransaction = totalTransaction + obj.totalTransaction;  
      });
      setTotalTransactions(totalTransaction);
      if("ALL" === selectedProgram){
        if (
          props.utilityList &&
          props.utilityList.length > 0
        ) {
          props.utilityList.map((obj) => {
            if(!labels.includes(obj.parentProgram)){
              labels.push(obj.parentProgram);
            }
          });
  
          labels.map((label) => {
            let count =0;
            let filteredData = props.utilityList.filter(checkout => checkout.parentProgram === label);
            filteredData.map(obj => {
              count = count + obj.totalTransaction
            })
            data.push(count);
          });
          setUtiltyGraphLabels(labels);
      
        }      
        var dataArray = [];
        var channelTypeDdataArray = [];
        let dataChartCount =0;
        for(var label=0; label<labels.length; label++ ){
          let filteredData = props.utilityList.filter(checkout => checkout.parentProgram === labels[label]);
          var teamNames = []; 
          var utilityTypes = [];   
          var channelTypes = []; 
          var count1 = [];
          var channelTypeCount1 = [];
          var teamNamesArray =[];
          var utilityTypesArray =[];
          var channelTypesArray = [];
          var transactionsCountArray =[];
          var channelTypesCountArray = [];
          filteredData.map(obj => {
                  if(!teamNames.includes(obj.teamName)){
                    teamNames.push(obj.teamName);
                  }
                  if(!utilityTypes.includes(obj.utilityType)){
                    utilityTypes.push(obj.utilityType);
                    count1.push((obj.totalTransactionPercent).toFixed(2));
                  }
                else{
                  var index = utilityTypes .findIndex(utilityType => utilityType === obj.utilityType);
                  var count2 = parseFloat(count1[index]) + parseFloat((obj.totalTransactionPercent).toFixed(2));
                  count1[index] = count2;
                }

                
                  if(!teamNames.includes(obj.teamName)){
                    teamNames.push(obj.teamName);
                  }
                  if(!channelTypes.includes(obj.channelType)){
                    channelTypes.push(obj.channelType);
                    channelTypeCount1.push((obj.totalTransactionPercent).toFixed(2));
                  }
                else{
                  var index = channelTypes.findIndex(channelType => channelType === obj.channelType);
                  var count2 = parseFloat(channelTypeCount1[index]) + parseFloat((obj.totalTransactionPercent));
                  channelTypeCount1[index] = count2.toFixed(2);
                }


              })
              teamNamesArray.push(Object.assign({},{[labels[label]]:teamNames}));
              utilityTypesArray.push(Object.assign({},{[labels[label]]:utilityTypes}));
              transactionsCountArray.push(Object.assign({},{[labels[label]]:count1}));
              channelTypesArray.push(Object.assign({},{[labels[label]]:channelTypes}));
              channelTypesCountArray.push(Object.assign({},{[labels[label]]:channelTypeCount1}));
              for (var j = 0; j < utilityTypesArray.length; j++) {
                for(var i = 0; i < utilityTypesArray[j][labels[label]].length; i++){
                  var dataForChart = [];
                  for(var labelIndex = 0; labelIndex < labels.length; labelIndex++){
                    if(labelIndex === label){
                      var datafromDataset =[];
                      var index = null;
                      index = dataArray.findIndex(dataset => dataset.label === utilityTypesArray[j][labels[label]][i]);
                      if(index >= 0){
                        datafromDataset.push(dataArray[index].data);
                        datafromDataset[0][label] = transactionsCountArray[j][labels[label]][i];
                      }else{
                        dataForChart.push(transactionsCountArray[j][labels[label]][i]);
                        dataChartCount = dataChartCount + 1;
                      }
                    }else{
                      dataForChart.push(null);
                    }
                  }
                    if(datafromDataset.length <=0 ){
                      dataArray.push(Object.assign({},{label:utilityTypesArray[j][labels[label]][i],data:dataForChart,fill:false,backgroundColor:COLORS[dataChartCount]}));
                     
                    }
                  
                }
              }
              setUtilityStackChart(dataArray);
              for (var j = 0; j < channelTypesArray.length; j++) {
                for(var i = 0; i < channelTypesArray[j][labels[label]].length; i++){
                  var dataForChart = [];
                  for(var labelIndex = 0; labelIndex < labels.length; labelIndex++){
                    if(labelIndex === label){
                      var datafromDataset =[];
                      var index = null;
                      index = channelTypeDdataArray.findIndex(dataset => dataset.label === channelTypesArray[j][labels[label]][i]);
                      if(index >= 0){
                        datafromDataset.push(channelTypeDdataArray[index].data);
                        datafromDataset[0][label] = channelTypesCountArray[j][labels[label]][i];
                      }else{
                        dataForChart.push(channelTypesCountArray[j][labels[label]][i]);
                        dataChartCount = dataChartCount + 1;
                      }
                    }else{
                      dataForChart.push(null);
                    }
                  }
                    if(datafromDataset.length <=0 ){
                      channelTypeDdataArray.push(Object.assign({},{label:channelTypesArray[j][labels[label]][i],data:dataForChart,fill:false,backgroundColor:COLORS[dataChartCount]}));
                     
                    }
                  
                }
              }

              setChannelTypeStackChart(channelTypeDdataArray);
        }
      }else{
        if( selectedProgram && props.utilityList.length > 0 ){
          props.utilityList.map((obj) => {
            if(!labels.includes(obj.teamName)){
              labels.push(obj.teamName);
            }
          });
          
          if("ALL" === selectedTeam){
            let dataChartCount =0;
            var dataArray = [];
            var channelTypeDdataArray = [];
            for(var label=0; label<labels.length; label++ ){
              var teamNamesArray =[];
              var utilityTypes = []; 
              var utilityTypesArray =[];
              var teamNames = []; 
              var count1 = [];
              var transactionsCountArray = [];
              let count =0;
              var channelTypes = []; 
              var channelTypesArray = []; 
              var channelTypeCount1 = [];
              var channelTypesCountArray = [];
              let filteredData = props.utilityList.filter(checkout => checkout.teamName === labels[label]);
              filteredData.map(obj => {
                  if(!teamNames.includes(obj.teamName)){
                    teamNames.push(obj.teamName);
                  }
                  if(!utilityTypes.includes(obj.utilityType)){
                    utilityTypes.push(obj.utilityType);
                    count1.push((obj.totalTransactionPercent).toFixed(2));
                  }
                else{
                  var index = utilityTypes.findIndex(utilityType => utilityType === obj.utilityType);
                  var count2 = parseFloat(count1[index]) + parseFloat((obj.totalTransactionPercent));
                  count1[index] = count2.toFixed(2);
                }


               
                  if(!teamNames.includes(obj.teamName)){
                    teamNames.push(obj.teamName);
                  }
                  if(!channelTypes.includes(obj.channelType)){
                    channelTypes.push(obj.channelType);
                    channelTypeCount1.push((obj.totalTransactionPercent).toFixed(2));
                  }
                else{
                  var index = channelTypes.findIndex(channelType => channelType === obj.channelType);
                  var count2 = parseFloat(channelTypeCount1[index]) + parseFloat((obj.totalTransactionPercent));
                  channelTypeCount1[index] = count2.toFixed(2);
                }

              })
              teamNamesArray.push(Object.assign({},{[labels[label]]:teamNames}));
              utilityTypesArray.push(Object.assign({},{[labels[label]]:utilityTypes}));
              transactionsCountArray.push(Object.assign({},{[labels[label]]:count1}));

              channelTypesArray.push(Object.assign({},{[labels[label]]:channelTypes}));
              channelTypesCountArray.push(Object.assign({},{[labels[label]]:channelTypeCount1}));


              for (var j = 0; j < utilityTypesArray.length; j++) {
                for(var i = 0; i < utilityTypesArray[j][labels[label]].length; i++){
                  var dataForChart = [];
                  for(var labelIndex = 0; labelIndex < labels.length; labelIndex++){
                    if(labelIndex === label){
                      var datafromDataset =[];
                      var index = null;
                      index = dataArray.findIndex(dataset => dataset.label === utilityTypesArray[j][labels[label]][i]);
                      if(index >= 0){
                        datafromDataset.push(dataArray[index].data);
                        datafromDataset[0][label] = transactionsCountArray[j][labels[label]][i];
                      }else{
                        dataForChart.push(transactionsCountArray[j][labels[label]][i]);
                        dataChartCount = dataChartCount + 1;
                      }
                    }else{
                      dataForChart.push(null);
                    }
                  }
                  if(datafromDataset.length <=0 ){
                    dataArray.push(Object.assign({},{label:utilityTypesArray[j][labels[label]][i],data:dataForChart,fill:false,backgroundColor:COLORS[dataChartCount]}));
                  }
                  setUtiltyGraphLabels(labels);
                }
              }
              for (var j = 0; j < channelTypesArray.length; j++) {
                for(var i = 0; i < channelTypesArray[j][labels[label]].length; i++){
                  var dataForChart = [];
                  for(var labelIndex = 0; labelIndex < labels.length; labelIndex++){
                    if(labelIndex === label){
                      var datafromDataset =[];
                      var index = null;
                      index = channelTypeDdataArray.findIndex(dataset => dataset.label === channelTypesArray[j][labels[label]][i]);
                      if(index >= 0){
                        datafromDataset.push(channelTypeDdataArray[index].data);
                        datafromDataset[0][label] = channelTypesCountArray[j][labels[label]][i];
                      }else{
                        dataForChart.push(channelTypesCountArray[j][labels[label]][i]);
                        dataChartCount = dataChartCount + 1;
                      }
                    }else{
                      dataForChart.push(null);
                    }
                  }
                    if(datafromDataset.length <=0 ){
                      channelTypeDdataArray.push(Object.assign({},{label:channelTypesArray[j][labels[label]][i],data:dataForChart,fill:false,backgroundColor:COLORS[dataChartCount]}));
                     
                    }
                  
                }
              }
            }
            setUtilityStackChart(dataArray);
            setChannelTypeStackChart(channelTypeDdataArray);

          }else{
            labels && labels.map((label) => {
              let count =0;
              let colorCount =0;
               var dataArray = [];
               var channelTypeDdataArray = [];
              let filteredData = props.utilityList.filter(checkout => checkout.teamName === label);
              filteredData.map(obj => {
                count = count + obj.totalTransactionPercent
                colorCount = colorCount+1;
                var dataForChart = [];
                dataForChart.push((obj.totalTransactionPercent).toFixed(2));
                var index = dataArray.findIndex(dataset => dataset.label === obj.utilityType);
                      if(index >= 0){
                        var datafromDataset = [];
                         datafromDataset.push(dataArray[index].data);
                         dataArray[index].data[0] = (parseFloat(datafromDataset[0]) + parseFloat((obj.totalTransactionPercent).toFixed(2))).toFixed(2);
                       }else{
                         dataArray.push(Object.assign({},{label:obj.utilityType,data:dataForChart,fill:false,backgroundColor:COLORS[colorCount]}));
                       }
              })
              filteredData.map(obj => {
                count = count + obj.totalTransactionPercent
                colorCount = colorCount+1;
                var dataForChart = [];
                dataForChart.push((obj.totalTransactionPercent).toFixed(2));
                     var channelTypeIndex = channelTypeDdataArray.findIndex(dataset => dataset.label === obj.channelType);
                       if(channelTypeIndex >= 0){
                        var datafromDatasetChannelType = [];
                        datafromDatasetChannelType.push(channelTypeDdataArray[channelTypeIndex].data);
                          channelTypeDdataArray[channelTypeIndex].data[0] = (parseFloat(datafromDatasetChannelType[0]) + parseFloat((obj.totalTransactionPercent).toFixed(2))).toFixed(2);
                        }else{                  
                          channelTypeDdataArray.push(Object.assign({},{label:obj.channelType,data:dataForChart,fill:false,backgroundColor:COLORS[colorCount]}))
                        }
              })
              data.push(count);
              setUtilityStackChart(dataArray);
              setChannelTypeStackChart(channelTypeDdataArray);
            });
            setUtiltyGraphLabels(labels);
            //setCheckoutDataGraph(data);
          }
          
        }
  
      }
    }
  }, [props.utilityList])

  return (
    <div className="white pb-20">
      <HeaderContent
        title={`TDM Statistics`}
        icon="cogs mif-chart-bars"
        loading={props.statsLoading}
        loadingText="Loading TDM Statistics..."
      ></HeaderContent>
      <Grid container spacing={4}>
        <Grid item xs={2}>
          <Autocomplete
            options={programList}
            getOptionLabel={(option) => option}
            id="auto-complete"
            value={selectedProgram}
            autoComplete
            onChange={(event, value) => onProgramChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select Program Name"
              />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            options={availableTeamList}
            getOptionLabel={(option) => option}
            id="auto-complete"
            value={selectedTeam}
            autoComplete
            onChange={(event, value) => onTeamChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select Team Name"
              />
            )}
          />
        </Grid>

        <Grid item xs={2} paddingTop="26px !important">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year", "month"]}
              label="Year and Month"
              value={fromDate}
              onChange={(newValue) => {
                //setFromDate(newValue);
              }}
              onMonthChange={(newValue) => {
                setFromDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  helperText="Select From Date"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} paddingTop="26px !important">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year", "month"]}
              label="Year and Month"
              value={toDate}
              onChange={(newValue) => {
                //setToDate(newValue);
              }}
              onMonthChange={(newValue) => {
                setToDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  helperText="Select To Date"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      {/* {
         props.statsLoading &&
        <Box paddingLeft="70px" paddingRight="70px">
        <Skeleton >
          <Bar height="300px" width="300px"></Bar>
        </Skeleton>
        </Box>
       
      } */}

      {!props.statsLoading && (
        <Grid container spacing={4} paddingTop="40px" paddingBottom="40px">
          <Grid item xs={2}>
          {/* <ReactCardFlip isFlipped={flipped} flipDirection="horizontal"> */}
            <Paper elevation={20} onMouseEnter={() => {
                setFilpped(!flipped);
              }}>
              <Grid item xs={2}>
                <AccountCircleIcon sx={{ fontSize: 80, color: indigo[500] }} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h6"
                  paddingLeft="80px"
                  gutterBottom
                  component="div"
                  helperText="Total Users"
                >
                  {totalUsers}
                </Typography>
              </Grid>

              <Typography
                textAlign="center"
                paddingBottom="19px"
                lineHeight="15px"
              >
                Users
              </Typography>
            </Paper>

            {/* <Paper elevation={20} style={{backgroundImage:"linear-gradient(to right, #ff8800 , #ffff00)"}} onMouseLeave={() => {
                setFilpped(!flipped);
              }}>
              <Grid item xs={2}>
                <AccountCircleIcon sx={{ fontSize: 80, color: indigo[500] }} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h6"
                  paddingLeft="80px"
                  gutterBottom
                  component="div"
                  helperText="Total Users"
                >
                  {10}
                </Typography>
              </Grid>

              <Typography
                paddingLeft="80px"
                paddingBottom="19px"
                lineHeight="10px"
              >
                sdadasdasd
              </Typography>
            </Paper>

            </ReactCardFlip> */}
          </Grid>

          <Grid item xs={2}>
          {/* <ReactCardFlip isFlipped={checkoutFlipped} flipDirection="horizontal"> */}
            <Paper elevation={20}   onMouseEnter={() => {
                setCheckoutFlipped(!checkoutFlipped);
              }}>
              <Grid item xs={2}>
                <ShoppingCartIcon sx={{ fontSize: 80, color: indigo[500] }} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h6"
                  paddingLeft="80px"
                  gutterBottom
                  component="div"
                  helperText="Total Users"
                >
                  {totalCheckouts}
                </Typography>
              </Grid>

              <Typography
                paddingBottom="19px"
                lineHeight="15px"
                textAlign="center"
              >
                Checkouts
              </Typography>
            </Paper>
            {/* <Paper elevation={20} style={{backgroundImage:"linear-gradient(to right, #ff8800 , #ffff00)"}} onMouseLeave={() => {
                setCheckoutFlipped(!checkoutFlipped);
              }}>
              <Grid item xs={2}>
                <ShoppingCartIcon sx={{ fontSize: 80, color: indigo[500] }} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h6"
                  paddingLeft="80px"
                  gutterBottom
                  component="div"
                  helperText="Total Users"
                >
                  {totalCheckoutTransactions}
                </Typography>
              </Grid>

              <Typography
                paddingLeft="80px"
                paddingBottom="19px"
                lineHeight="10px"
              >
                Total Transactions
              </Typography>
            </Paper>
            </ReactCardFlip> */}
          </Grid>

          <Grid item xs={2}>
          {/* <ReactCardFlip isFlipped={jiraFlipped} flipDirection="horizontal"> */}
            <Paper elevation={20}  onMouseEnter={() => {
                setJiraFlipped(!jiraFlipped);
              }}>
              <Grid item xs={2}>
                <BugReportIcon sx={{ fontSize: 80, color: indigo[500] }} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h6"
                  paddingLeft="80px"
                  gutterBottom
                  component="div"
                  helperText="Total Users"
                >
                  {jiraTicketsCount}
                </Typography>
              </Grid>

              <Typography
                paddingBottom="19px"
                textAlign="center"
                lineHeight="15px"
              >
                TDT Tickets
              </Typography>
            </Paper>
            {/* <Paper elevation={20} style={{backgroundImage:"linear-gradient(to right, #ff8800 , #ffff00)"}} onMouseLeave={() => {
                setJiraFlipped(!jiraFlipped);
              }}>
              <Grid item xs={2}>
                <BugReportIcon sx={{ fontSize: 80, color: indigo[500] }} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h6"
                  paddingLeft="80px"
                  gutterBottom
                  component="div"
                  helperText="Total Users"
                >
                  {10}
                </Typography>
              </Grid>

              <Typography
                paddingLeft="80px"
                paddingBottom="19px"
                lineHeight="10px"
              >
                sdadasdasd
              </Typography>
            </Paper>
            </ReactCardFlip> */}
          </Grid>


          <Grid item xs={2}>
          {/* <ReactCardFlip isFlipped={utilityFlipped} flipDirection="horizontal"> */}
            <Paper elevation={20} onMouseEnter={() => {
                setUtilityFlipped(!utilityFlipped);
              }}>
              <Grid item xs={2}>
                <ConstructionIcon sx={{ fontSize: 80, color: indigo[500] }} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h6"
                  paddingLeft="80px"
                  gutterBottom
                  component="div"
                  helperText="Transactions"
                >
                  {totalTransactions}
                </Typography>
              </Grid>

              <Typography
               textAlign="center"
                paddingBottom="19px"
                lineHeight="15px"
              >
               Utility Transactions
              </Typography>
            </Paper>
            {/* <Paper elevation={20} style={{backgroundImage:"linear-gradient(to right, #ff8800 , #ffff00)"}} onMouseLeave={() => {
                setUtilityFlipped(!utilityFlipped);
              }}>
              <Grid item xs={2}>
                <ConstructionIcon sx={{ fontSize: 80, color: indigo[500] }} />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h6"
                  paddingLeft="80px"
                  gutterBottom
                  component="div"
                  helperText="Total Users"
                >
                  {10}
                </Typography>
              </Grid>

              <Typography
                paddingLeft="80px"
                paddingBottom="19px"
                lineHeight="10px"
              >
                sdadasdasd
              </Typography>
            </Paper>
            </ReactCardFlip> */}
          </Grid>


        </Grid>
      )}
      {(!props.statsLoading &&  (checkoutDataGraph && checkoutDataGraph.length > 0) || (checkoutCountTeamNamesStackChart && checkoutCountTeamNamesStackChart.length >0)) && (
        <Box padding="70px">
          <Grid container spacing={4}>
            <Grid item xs={1}>
              <FormControl variant="standard">
                <Select
                  labelId="checkoutStatSelect"
                  id="checkoutStatSelect"
                  value={checkoutDataDisplayType}
                  onChange={onCheckoutDataDisplayTypeChange}
                >
                  <MenuItem value={1}>Chart</MenuItem>
                  <MenuItem value={2}>Table</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl variant="standard">
                <Select
                  labelId="checkoutStatSelect"
                  id="checkoutStatSelect"
                  value={checkoutChartType}
                  onChange={onCheckoutChartTypeChange}
                >
                  <MenuItem value={1}>Checkout Count By Service ID</MenuItem>
                  <MenuItem value={2}>Checkout Count By Channel Type</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          { checkoutDataDisplayType === 1 ?
            <div style={{ paddingTop: "20px" }}>
            <Bar
              data={checkoutChartType === 1 ? checkoutStackChartData : checkoutByChannelTypeStackChartData}
              options={checkoutChartType === 1 ? checkoutDataStackChartOptions : checkoutDataByChannelTypeOptions}
              id={"checkoutGraphStack"}
            /> 
            </div>:
            <div style={{ paddingTop: "20px" }}>
              {checkoutChartType === 1 ?
              <BreadTable {...dataArgs} data={props.checkoutCountByServiceId} />:
              <BreadTable {...dataArgsChannelType} data={props.checkoutCountByChannelType} />
              }
            </div>
          }         
        </Box>
      )}
       {!props.statsLoading &&  jiraChartData && jiraChartData.length > 0 && 
       <Box padding="70px">
         <Grid container spacing={4}>
            <Grid item xs={1}>
              <FormControl variant="standard">
                <Select
                  labelId="jiraStatSelect"
                  id="jiraStatSelect"
                  value={jiraDataDisplayType}
                  onChange={jiraDataDisplayTypeChange}
                >
                  <MenuItem value={1}>Chart</MenuItem>
                  <MenuItem value={2}>Table</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {
            jiraDataDisplayType === 1 ?
            <div style={{ paddingTop: "20px" }}>
            <Bar
              data={jiraData}
              options={selectedProgram === "ALL"? jiraTicketDataOptions:jiraTicketDataOptionsTeam}
              id={"jiraGraph"}
            /></div>:
            <div style={{ paddingTop: "20px" }}>
              <BreadTable {...jiraTicketDataArgs} data={props.jiraIssues} />
            </div>
          }
       </Box>
      }
      {!props.statsLoading && utilityStackChart && utilityStackChart.length > 0 &&

<Box padding="70px">
<Grid container spacing={4}>
   <Grid item xs={1}>
     <FormControl variant="standard">
       <Select
         labelId="jiraStatSelect"
         id="jiraStatSelect"
         value={utilityDataDisplayType}
         onChange={utilityDataDisplayTypeChange}
       >
         <MenuItem value={1}>Chart</MenuItem>
         <MenuItem value={2}>Table</MenuItem>
       </Select>
     </FormControl>
   </Grid>

   <Grid item xs={2}>
              <FormControl variant="standard">
                <Select
                  labelId="checkoutStatSelect"
                  id="checkoutStatSelect"
                  value={utilityChartType}
                  onChange={onUtilityChartTypeChange}
                >
                  <MenuItem value={1}>Channel Type</MenuItem>
                  <MenuItem value={2}>Utility</MenuItem>
                </Select>
              </FormControl>
            </Grid>

 </Grid>
 {
   utilityDataDisplayType === 1 ?
   <div style={{ paddingTop: "20px" }}>
   <Bar
     data={utilityChartType===1? utiltyChannelTypeChartData:utiltyChartData}
     options={utiltyChartDataOptions}
     id={"jiraGraph"}
   /></div>:
   <div style={{ paddingTop: "20px" }}>
     <BreadTable {...utilityData} data={props.utilityList} />
   </div>
 }
</Box>

        
      }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    programs: state.pasmatTrend.parentProgram.teamParentList,
    allTeams: 
     state.auth.isAdmin
       ? state.teams.teams.allTeams
       : 
      state.auth.myAvailableTeams,
    userTeam: state.auth.team,
    checkoutData : state.stats.checkoutData,
    checkoutCountByServiceId: state.stats.checkoutData?state.stats.checkoutData.checkoutCountByServiceId:[],
    checkoutCountByChannelType: state.stats.checkoutData?state.stats.checkoutData.checkoutCountByChannelType:[],
    utilityList: state.stats.checkoutData?state.stats.checkoutData.utilityList:[],
    jiraIssues: state.stats.checkoutData?state.stats.checkoutData.jiraIssues:[],
    usersList: state.stats.checkoutData?state.stats.checkoutData.usersList:[],
    statsLoading: state.stats.checkoutData.statsLoading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCheckoutCountByTeam: (teamId,  fromDate, toDate, fieldsList) =>
      dispatch(fetchCheckoutCountByTeam(teamId,  fromDate, toDate)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(statistic);
