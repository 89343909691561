import React, {Component} from 'react'
import { FaBoolean } from '../../../common/fontawesome/FaBoolean';

export default(props) => {
    const item = props.item;
    return (
        <tr>
            <th scope="row">{item.tagId}</th>
            <td>{item.env}</td>
            <td>{item.realm}</td>
            <td>{item.exchangeId}</td>
            <td>{item.locality}</td>
            <td>{item.streetName} {item.streetType}</td>
            <td>{item.fnn}</td>
            {props.techFilter && <td>{item.serviceabilityClassName} - {item.serviceabilityClass}</td>}
        </tr>
    );
};
