import React, { Component, Fragment } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem
} from 'reactstrap';
import moment from "moment";
import SidePanel from './SidePanel';

import BroadCastMessagePanel from './BroadCastMessagePanel'

import { NavLink, Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { signout, setSideBarPanel } from '../../../../actions/authActions';
import { setBroadCastMessagePanel } from '../../../../actions/broadCastMessageAction.js';
import {setUserTrainingBadge } from '../../../../actions/trainingBadgesAction.js';
import { fetchUsers, fetchUserMetadata, fetchUserTaasMetadata, updateUserTaasMetadata } from '../../../../actions/userActions';

import config from '../../../../config';

import logo from '../../../../assets/images/telstra_logo_blue.png';
import coeLogo from '../../../../assets/images/coe_logo.png';
import './HeaderNav.css';
import { is1TdmUser } from '../../../../utils/userProfile';
import NotificationSummary from '../../../../components/notification/NotificationSummary';
import NotificationBell from '../../../../components/notification/NotificationBell';
import NotificationRequester from '../../../../components/notification/NotificationRequester';
import UserBadges from '../../../Users/userBadge';
import { Tooltip } from '@material-ui/core';
import "../../../Landing/Landing.css";
import { periodicCheckAndCallIfFail } from '../../../../utils/api';

class HeaderNav extends Component {
    constructor(props) {
        super(props);

        this.toggle = this
            .toggle
            .bind(this);
        this.state = {
            isOpen: false,
            notificationsOpen: false,
            broadcastList: [],
            notificationsMessageOpen: false,
        };
        this.setNotificationsMessageOpen = this.setNotificationsMessageOpen.bind(this);
    }

    setNotificationsMessageOpen() {
        this.setState({ notificationsMessageOpen: !this.state.notificationsMessageOpen })
    }

    createTaasMetaDataRequest(today, currentLoginDate) {
        let metaDataRequest = {
            userId: this.props.auth?.userName,
            toolName: "TDM",
            metaData: {
                currentLoginDate: today
            }
        }
        if (!this.props.userTaasMetaData?.metaData?.prevLoginDate) {
            metaDataRequest.metaData.prevLoginDate = today;
        } else if (this.props.userTaasMetaData?.metaData?.currentLoginDate && today !== currentLoginDate) {
            metaDataRequest.metaData.prevLoginDate = this.props.userTaasMetaData?.metaData?.currentLoginDate;
        }
        if (!this.props.userTaasMetaData?.metaData?.oldestLoginDate) {
            metaDataRequest.metaData.oldestLoginDate = this.props.userTaasMetaData?.metaData?.prevLoginDate || this.props.userTaasMetaData?.metaData?.currentLoginDate || today;
        }
        return metaDataRequest;
    }

    updateTaasMetaData(timeToCallAfterFailure) {
        const today = moment().startOf('day').unix();
        const currentLoginDate = moment.unix(this.props.userTaasMetaData?.metaData?.currentLoginDate).startOf('day').unix();
        if (!this.props.fetchTaasMetaDataLoading && !this.props.fetchTaasMetaDataFailed && this.props.fetchTaasMetaDataSuccess && (
                !this.props.userTaasMetaData?.metaData || 
                !this.props.userTaasMetaData?.metaData.currentLoginDate || 
                this.props.userTaasMetaData?.metaData?.currentLoginDate && today !== currentLoginDate)
        ) {
            const metaDataRequest = this.createTaasMetaDataRequest(today, currentLoginDate);
            periodicCheckAndCallIfFail(
                (this.props.userTaasMetaData?.metaData?.currentLoginDate && today === currentLoginDate), 
                this.props.updateTaasMetaDataLoading, 
                this.props.updateTaasMetaDataFailed, 
                this.props.updateTaasMetaDataFailedTime, 
                ()=>this.props.updateUserTaasMetadata(metaDataRequest), 
                timeToCallAfterFailure
            );
        }
    }
 
    componentDidUpdate(prevProps) {
        const timeToCallAfterFailure = 120000; // 2 Min
        if (this.props.auth.user !== undefined && this.props.auth.userName !== undefined && localStorage.getItem("access_token")) {
            periodicCheckAndCallIfFail(this.props.userData?.content, this.props.userData?.loading, this.props.userData?.failed, 
                this.props.userData?.failedTime, this.props.fetchUsers, timeToCallAfterFailure);
            periodicCheckAndCallIfFail(this.props.userTaasMetaData, this.props.fetchTaasMetaDataLoading, this.props.fetchTaasMetaDataFailed, 
                this.props.fetchTaasMetaDataFailedTime, ()=>this.props.fetchUserTaasMetadata(this.props.auth?.userName), timeToCallAfterFailure);
            this.updateTaasMetaData(timeToCallAfterFailure);
            periodicCheckAndCallIfFail(this.props.userMetaData?.trainingBadge, this.props.userMetaDataLoading, this.props.userMetaDataFailed, 
                this.props.userMetaDataFailedTime, ()=>this.props.fetchUserMetadata(this.props.auth.userName), timeToCallAfterFailure);
            if (this.props.userMetaData !== prevProps.userMetaData && !this.props.userMetaDataLoading) {
                const userDataTraining = this.props.userMetaData;
                let trainingBadge = "";
                trainingBadge = userDataTraining.trainingBadge;
                this.props.setUserTrainingBadge(trainingBadge);
            }
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    brandStyles = {
        width: 40,
        marginTop: -2
    };

    renderIcons = () => {

        return (
            <ul className="nav">
                {is1TdmUser() && this.props.authenticated && this.props.location.pathname != "/dashboard" && 
                    <li><NavItem className="base-turquoise">
                                <NavLink
                                    className="nav-link icon-link featured-gradient"
                                    activeClassName='active'
                                    to="/dashboard">
                                    <i className="td-icon-sm icon-recharge"></i>
                                </NavLink>
                            </NavItem></li>}
                {!this.props.userMetaDataLoading && <UserBadges />}
                <NotificationRequester
                    setNotificationsMessageOpen={() => this.setNotificationsMessageOpen()} />
                <li className="base-turquoise nav-item" style={{ cursor: "pointer" }} onClick={() => this.toggleNotificationSummary()} tabIndex="0">
                    <NotificationBell unreadMsgCount={!this.props.notificationFail ? this.props.totalUnreadMessageCount : 0} />
                </li>

                {this.props.auth.isAuthenticated && this.props.auth.isAdmin &&
                    <li className="base-magenta nav-item" style={{ cursor: "pointer" }}  tabIndex="0">
                        <a className="nav-link icon-link featured-gradient active" aria-current="page" onClick={() => this.props.setBroadCastMessagePanel(true)}>
                            <i className="td-icon-sm icon-shared-wifi" ></i>
                        </a>
                    </li>
                }
                <BroadCastMessagePanel adminTeams={this.props.auth} />
                <li className="base-turquoise nav-item" style={{ cursor: "pointer" }}  tabIndex="0">
                    <a className="nav-link icon-link featured-gradient active" aria-current="page" onClick={() => this.props.setSideBarPanel(true)}>
                        <i className="td-icon-sm icon-my-profile" ></i>
                    </a>
                </li>
                <li>
                <Tooltip title="Logout">
                <NavItem className="base-orange">
                                <NavLink
                                    className="nav-link icon-link featured-gradient"
                                    activeClassName='active'
                                    to={'/'}
                                    onClick={this.props.signout}>
                                    <i className="td-icon-sm icon-lock"></i>
                                    </NavLink>
                            </NavItem></Tooltip>
                </li>
            </ul>
        );
    }

    renderNotificationSummary = () => {
        return (
            this.state.notificationsOpen ? <NotificationSummary toggleNotification={this.toggleNotificationSummary}
                closeNotification={this.closeNotificationSummary}
                notificationsMessageOpen={this.state.notificationsMessageOpen}
                closeNotificationsMessage={() => this.setNotificationsMessageOpen()} /> : null
        );
    }

    toggleNotificationSummary = () => {
        this.setState(prevState => ({ notificationsOpen: !prevState.notificationsOpen }));
    }

    closeNotificationSummary = () => {
        this.setState({ notificationsOpen: false });
    }

    render() {
        const productLogo = is1TdmUser() ? coeLogo : logo;
        return (
            <React.Fragment>
                <Navbar color="" className="nav-glass" dark expand="md" style={{ justifyContent: "space-between" }}>

                    {is1TdmUser() ?
                        this.props.authenticated ?
                            <Link to="/dashboard" className="navbar-brand">
                                <img src={productLogo} style={this.brandStyles} />
                                {' '}{config.title}
                                </Link> :
                            <Link to="/login" className="navbar-brand"><img src={productLogo} style={this.brandStyles} />
                                {' '}{config.title}</Link>
                        :
                        <Link to="/" className="navbar-brand"><img src={productLogo} style={this.brandStyles} />
                            {' '}{config.title}
                            
                            </Link>
                    }
                                                 
                    <NavbarToggler onClick={this.toggle} />
                    
                    {this.props.auth.isAuthenticated && this.renderIcons()}
                    {this.props.auth.isAuthenticated && this.renderNotificationSummary()}
                </Navbar>
                <SidePanel />

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        openSidePanel: state.auth.openSidePanel,
        authenticated: state.auth.authenticated,
        totalUnreadMessageCount: state.notification.totalUnreadMessageCount,
        notificationFail: state.notification.notificationFetchFail,
        userTrainingBadge: state.trainingBadges.userTrainingBadge,

        userData: state.users.mgmt,

        userMetaData: state.users.userMetaData,
        userMetaDataLoading: state.users.userMetaDataLoading,
        userMetaDataFailed: state.users.userMetaDataFailed,
        userMetaDataFailedTime: state.users.userMetaDataFailedTime,
        userMetaDataError: state.users.userMetaDataError,

        userTaasMetaData: state.users.userTaasMetaData?.data,

        fetchTaasMetaDataLoading: state.users.userTaasMetaData?.fetchLoading,
        fetchTaasMetaDataSuccess: state.users.userTaasMetaData?.fetchSuccess,
        fetchTaasMetaDataFailed: state.users.userTaasMetaData?.fetchFailed,
        fetchTaasMetaDataFailedTime: state.users.userTaasMetaData?.fetchFailTime,
        fetchTaasMetaDataError: state.users.userTaasMetaData?.fetchError,

        updateTaasMetaDataLoading: state.users.userTaasMetaData?.updateLoading,
        updateTaasMetaDataFailed: state.users.userTaasMetaData?.updateFailed,
        updateTaasMetaDataFailedTime: state.users.userTaasMetaData?.updateFailTime,
        updateTaasMetaDataError: state.users.userTaasMetaData?.updateError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signout: () => dispatch(signout()),
        setSideBarPanel: (value) => dispatch(setSideBarPanel(value)),
        setBroadCastMessagePanel: (value) => dispatch(setBroadCastMessagePanel(value)),
        setUserTrainingBadge: (badge) => dispatch(setUserTrainingBadge(badge)),
        fetchUsers:() => dispatch(fetchUsers()),
        fetchUserMetadata:(id) => dispatch(fetchUserMetadata(id)),
        fetchUserTaasMetadata:(id) => dispatch(fetchUserTaasMetadata(id)),
        updateUserTaasMetadata:(request) => dispatch(updateUserTaasMetadata(request))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
