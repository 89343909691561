import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
    makeStyles
} from "@material-ui/core";
const useStyles = makeStyles({
    orderBuilderToggle: {
        "marginLeft": "34%",
        "& button": {
            width: "200px",
        },
        "& button[aria-pressed='true']": {
            color: '#fff',
            backgroundColor: '#3f51b5'
        }
    },
})
const OrderBuilderToggle = (props) => {
    const classes = useStyles();
    return (
        <ToggleButtonGroup
            color="primary"
            value={props.orderBuilderType}
            onChange={props.changeOrderBuilder}
            aria-label="Platform"
            className={classes.orderBuilderToggle}
        >
            <ToggleButton value="Standard">Standard</ToggleButton>
            <ToggleButton value="Custom">Custom</ToggleButton>
        </ToggleButtonGroup>
    )
}

export default OrderBuilderToggle;