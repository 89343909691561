import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    teamData: {
        teamServices: []
    },
    teams: {
        allTeams: []
    },
    serviceIdData:{
        allServiceIds: []
    },
    teamFilters: {
        filters: [],
        pending: false,
        errorMessage: ''
    },
    selectedFilters: {},
    customTeamFilters: {
        customFilters: [],
        customFilterPending: false,
        customFilerErrorMessage: '',
        customFilterFailed: false
    },
    allocateSourceData:{
        failed: false,
        pending: false,
        source:{},
        errorMessage:''
    },
    allocateTargetData: {
        failed: false,
        pending: false,
        target: {},
        errorMessage:''
    },
    allocateExchangeData: {
            failed: false,
            pending: false,
            success: false,
            exchngData: {}
    },
    selectedCustomFilter: {},
    classDetails: [],
    filterServiceIds: [3,4,6,13,14,15,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,201,202,203,206,207,208,209],
    serviceFilterData: {
        failed: false,
        pending: false,
        success: false,
        data: {},
        errorMessage:''
    },
    serviceFilterDataValues: {
        failed: false,
        pending: false,
        success: false,
        data: [],
        errorMessage:''
    },
    allocateServiceFilterData: {
        failed: false,
        pending: false,
        success: false,
        data: {},
        errorMessage:''
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      //  start tdm/data/team/fetch/services
        case types.FETCH_SERVICE_IDS_PENDING:
            return {
                ...state,
                teamData: {
                    ...state.teamData,
                    teamServices: []
                }
            }

        case types.FETCH_SERVICE_IDS_SUCCESS:
            return {
                ...state,
                teamData: {
                    teamServices: action.payload
                }

            }

        case types.FETCH_SERVICE_IDS_FAILED:
            return {
                ...state,
                teamData: {
                    teamServicesFailed: true,
                    teamServices: []
                }

            } // end of fetch services
// fetch all active serviceIds
        case types.FETCH_ALL_SERVICE_IDS_PENDING:
            return {
                ...state,
                serviceIdData: {
                    allServiceIds: [],
                    pending: true
                }
            }

        case types.FETCH_ALL_SERVICE_IDS_SUCCESS:
            return {
                ...state,
                serviceIdData: {
                    allServiceIds: action.payload
                }
            }
        case types.FETCH_ALL_SERVICE_IDS_FAILED:
            return {
                ...state,
                serviceIdData: {
                    failed: true,
                    allServiceIds: [],
                }
            }// end of fetch all active serviceIds

// fetch all active teams
        case types.FETCH_ALL_TEAMS_PENDING:
            return {
                ...state,
                teams: {
                    allTeams: [],
                    pending: true
                }
            }

        case types.FETCH_ALL_TEAMS_SUCCESS:
            return {
                ...state,
                teams: {
                    allTeams: action.payload
                }
            }
        case types.FETCH_ALL_TEAMS_FAILED:
            return {
                ...state,
                teams: {
                    failed: true,
                    allTeams: [],
                }
            }// end of fetch all active teams

// Filters
        case types.LOAD_TEAM_FILTERS_PENDING:
            return {
                ...state,
                teamFilters: {
                    pending: true,
                    filters: [],
                    errorMessage: ''
                }
            }

        case types.LOAD_TEAM_FILTERS_SUCCESS:
            return {
                ...state,
                teamFilters: {
                    filters: action.payload,
                    pending: false,
                    errorMessage: ''
                }
            }
            // custom filter case
            case types.LOAD_CUSTOM_TEAM_FILTERS_PENDING:
                return {
                    ...state,
                    customTeamFilters: {
                        customFilters: [],
                        customFilterPending: true,
                        customFilerErrorMessage: '',
                        customFilterFailed: false
                    }
                }

            case types.LOAD_CUSTOM_TEAM_FILTERS_SUCCESS:
                return {
                    ...state,
                    customTeamFilters: {
                        customFilters: action.payload || [],
                        customFilterPending: false,
                        customFilerErrorMessage: '',
                        customFilterFailed: false
                    }
            }
            case types.LOAD_CUSTOM_TEAM_FILTERS_FAILED:
                return {
                    ...state,
                    customTeamFilters: {
                        customFilterFailed: true,
                        customFilterPending: false,
                        customFilters: [],
                        customFilerErrorMessage: action.payload || ''
                    }
                }// Filters end
// Allocation
            case types.LOAD_ALLOCATE_SOURCE_PENDING:
                return {
                    ...state,
                    allocateSourceData:{
                        failed: false,
                        pending: true,
                        source: {},
                        errorMessage:''
                    }
                }
            case types.LOAD_ALLOCATE_SOURCE_SUCCESS:
                return {
                    ...state,
                    allocateSourceData:{
                        failed: false,
                        pending: false,
                        source: action.payload,
                        errorMessage:''
                    }
                }
            case types.LOAD_ALLOCATE_SOURCE_FAILED:
                return {
                    ...state,
                    allocateSourceData:{
                        failed: true,
                        pending: false,
                        source: {},
                        errorMessage:action.payload
                    }
                }

            case types.LOAD_ALLOCATE_TARGET_PENDING:
                return {
                    ...state,
                    allocateTargetData:{
                        failed: false,
                        pending: true,
                        target:{},
                        errorMessage:''
                    }
                }
            case types.LOAD_ALLOCATE_EXCHANGE_ID_PENDING:
                return {
                    ...state,
                    allocateExchangeData: {
                        failed: false,
                        pending: true,
                        success: false,
                        exchngData: {}
                }
            }
            case types.LOAD_ALLOCATE_EXCHANGE_ID_SUCCESS:
                return {
                    ...state,
                    allocateExchangeData: {
                        failed: false,
                        pending: false,
                        success: true,
                        exchngData: action.payload
                }
            }
            case types.LOAD_ALLOCATE_EXCHANGE_ID_FAILED:
                return {
                    ...state,
                    allocateExchangeData: {
                        failed: true,
                        pending: false,
                        success: true,
                        exchngData: {}
                }
            }
            case types.LOAD_ALLOCATE_TARGET_SUCCESS:
                return {
                    ...state,
                    allocateTargetData:{
                        failed: false,
                        pending: false,
                        target:action.payload,
                        errorMessage:''
                    }
                }
            case types.LOAD_ALLOCATE_TARGET_FAILED:
                return {
                    ...state,
                    allocateTargetData:{
                        failed: true,
                        pending: false,
                        target:{},
                        errorMessage:action.payload
                    }
                } // End of Allocation

            case types.SELECT_CUSTOM_TEAM_FILTER:
                return {
                    ...state,
                    selectedCustomFilter: action.payload || {}
                }
                // end of custom filter reducer

        case types.LOAD_TEAM_FILTERS_FAILED:
            return {
                ...state,
                teamFilters: {
                    failed: true,
                    pending: false,
                    filters: [],
                    errorMessage: action.payload || ''
                }
            }

        case types.SELECT_TEAM_FILTER:
            return {
                ...state,
                selectedFilters: action.payload
            }

        case types.TEAM_ALLOCATION_PENDING:

            return {
                ...state,
                teamAllocation: {
                    pending: true
                }
            }

        case types.TEAM_ALLOCATION_SUCCESS:

            return {
                ...state,
                teamAllocation: {
                    success: true
                }
            }

        case types.TEAM_ALLOCATION_FAILED:

            return {
                ...state,
                teamAllocation: {
                    failed: true
                }
            }
        case types.TEAM_SERVICE_FILTERS_PENDING:
            return {
                ...state,
                serviceFilterData: {
                    failed: false,
                    pending: true,
                    success: false,
                    data: {},
                    errorMessage:''
                }
            }
        case types.TEAM_SERVICE_FILTERS_SUCCESS:
            return {
                ...state,
                serviceFilterData: {
                    failed: false,
                    pending: false,
                    success: true,
                    data: action.payload,
                    errorMessage:''
                }
            }
        case types.TEAM_SERVICE_FILTERS_FAILED:
            return {
                ...state,
                serviceFilterData: {
                    failed: true,
                    pending: false,
                    success: false,
                    data: {},
                    errorMessage: action.payload
                }
            }
            case types.TEAM_SERVICE_FILTERS_PENDING:
                return {
                    ...state,
                    serviceFilterData: {
                        failed: false,
                        pending: true,
                        success: false,
                        data: {},
                        errorMessage:''
                    }
                }
            case types.TEAM_ALLOCATE_SERVICE_FILTERS_SUCCESS:
                return {
                    ...state,
                    allocateServiceFilterData: {
                        failed: false,
                        pending: false,
                        success: true,
                        data: action.payload,
                        errorMessage:''
                    }
                }
            case types.TEAM_ALLOCATE_SERVICE_FILTERS_FAILED:
                return {
                    ...state,
                    allocateServiceFilterData: {
                        failed: true,
                        pending: false,
                        success: false,
                        data: {},
                        errorMessage: action.payload
                    }
                }
        case types.TEAM_ALLOCATE_SERVICE_FILTERS_PENDING:
            return {
                ...state,
                allocateServiceFilterData: {
                    failed: false,
                    pending: true,
                    success: false,
                    data: [],
                    errorMessage:''
                }
            }
        case types.TEAM_SERVICE_FILTERS_VALUE_SUCCESS:
            return {
                ...state,
                serviceFilterDataValues: {
                    failed: false,
                    pending: false,
                    success: true,
                    data: action.payload,
                    errorMessage:''
                }
            }
        case types.TEAM_SERVICE_FILTERS_VALUE_FAILED:
            return {
                ...state,
                serviceFilterDataValues: {
                    failed: true,
                    pending: false,
                    success: false,
                    data: [],
                    errorMessage: action.payload
                }
            }
        case types.TEAM_CLASS_TECH_SUCCESS:
            return {
                ...state,
                classDetails: action.payload
            }
        case types.TEAM_CLASS_TECH_FAILED:
            return {
                ...state,
                classDetails: []
            }

        default:
            return state;
    }
}
