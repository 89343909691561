import * as types from "../actions/u2cActionTypes";

const INITIAL_STATE = {
  filters: {
    typeFilters: [],
    envFilters: ['SIT'],
    prodSitFilters: ['NGUC', 'EM'],
  },
  selectedFilters: {
    env: undefined,
    product: undefined,
    service: undefined,
    item: undefined,
    specification: undefined,
    products: [],
    productData: [],
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SELECT_SUBSCRIPTION_ENV:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.key]: action.value
        }
      };

    default:
      return state;
  }
};
