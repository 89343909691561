// import { AUTH_ERROR, AUTH_LOGOUT, AUTH_RESET, AUTH_USER } from '../actions/actionTypes';

import { LOAD_DIAGRAM_IMAGES_ERROR, LOAD_DIAGRAM_IMAGES_LOADING, LOAD_DIAGRAM_IMAGES_SUCCESS } from '../actions/actionTypes';
import legacyCreateCustomerImg from '../assets/images/diagrams/Legacy_CreateCustomer.JPG';

// TODO: Follow up on image passing
const staticCards = [
    { title: "Legacy - Create Customer", subtitle: "", content: "A diagram for the legacy process of creating a customer", img: { url: legacyCreateCustomerImg, alt: "Legacy - Create Customer Diagram" } }
    //removing all the unwanted diagrams
    // { title: "Salesforce - Create Billing Account", subtitle: "", content: "A diagram for creating billing accounts in Saleforce", img: { url: SFCreateBillingImg, alt: "Salesforce - Create Billing Account Diagram" } },
    // { title: "Salesforce - Import Customer", subtitle: "", content: "A diagram for importing customers in Saleforce", img: { url: SFImportCustomerImg, alt: "Salesforce - Import Customer Diagram" } },
    // { title: "Salesforce - Change Owner", subtitle: "", content: "A diagram for changing owner in Saleforce", img: { url: SFChangeOwnerImg, alt: "Salesforce - Change Owner Diagram" } },
    // { title: "Salesforce - Create Contact", subtitle: "", content: "A diagram for creating a contact in Saleforce", img: { url: SFCreateContactImg, alt: "Salesforce - Create Contact Diagram" } },
    // { title: "Salesforce DC - Add Site", subtitle: "", content: "A diagram for adding a site in Saleforce DC", img: { url: SFDCAddSiteImg, alt: "Salesforce DC - Add Site Diagram" } },
]

const INITIAL_STATE = {
    view: {
        diagrams: [
            ...staticCards
        ],
        loading: false
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOAD_DIAGRAM_IMAGES_LOADING:
            return {
                ...state,
                view: {
                    ...state.view,
                    loading: true
                }
            }
        case LOAD_DIAGRAM_IMAGES_SUCCESS:
            // Implement handling API diagrams here
            return {
                ...state,
                view: {
                    diagrams: [
                        ...state.view.diagrams, // TODO: REMOVE THIS WHEN USING API
                        ...action.payload
                    ],
                    loading: false
                }
            }
        case LOAD_DIAGRAM_IMAGES_ERROR:
            // Implement error handling
            return {
                ...state,
                view: {
                    ...state.view,
                    loading: false
                }
            }
        default:
            return state
    }
}