import React from "react";
import {
    LineChart, Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";

import { connect } from "react-redux";
import { setTeams, setParentProgram, setGraphTeam } from '../../actions/surveyAction'

export const PasmatLineMap = (props) => {
    const handleClick = (e, payload) => {
        if (props.graphtype === "Programs") {
            let programSelected = props.trendAnalysisData.filter((trends) => trends.program === payload.payload.name)
            props.setParentProgram(programSelected[0]);
        }
        else {
            let trendTeam = [];
            trendTeam = props.trendProgram.teams.filter((tTeam) => tTeam.team_name === payload.payload.name)
            props.setGraphTeam(trendTeam[0]);
        }

    }
    let options = props.xData;
    let values = props.yData;
    const mapArrays = (options, values) => {
        const res = [];
        for (let i = 0; i < props.xData.length; i++) {
            res.push({
                name: options[i],
                FTE: values[i]
            });
        };
        return res;
    };
    const data2 = mapArrays(options, values);

    return (
        <div style={{ width: '100%' }}>
            <div className='header'>
                <h6 className='title'>{props.graphName}</h6>
            </div>
            <LineChart width={900} height={400} data={data2}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Line
                    type="monotone"
                    dataKey="FTE"
                    stroke="#8884d8"
                    strokeDasharray="5 5"
                    activeDot={{ onClick: (e, payload) => handleClick(e, payload) }}
                />
            </LineChart></div>
    );
}

const mapStateToProps = (state) => {
    return {
        trendAnalysisData: state.pasmatTrend.trendAnalysisData,
        trendProgram: state.pasmatTrend.trendProgram,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTeams: (teams, streamMaturity) => dispatch(setTeams(teams, streamMaturity)),
        setParentProgram: (parentProgram) => dispatch(setParentProgram(parentProgram)),
        setGraphTeam: (graphTeam) => dispatch(setGraphTeam(graphTeam))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasmatLineMap);