import React, {Fragment,useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';

import TDMBeginner from '../../assets/images/TrainingBadges/TDMBeginner.png'
import TDMExplorer from '../../assets/images/TrainingBadges/TDMExplorer.png'
import TDMContributor from '../../assets/images/TrainingBadges/TDMContributor.png'
import TDMInnovator from '../../assets/images/TrainingBadges/TDMInnovator.png'
import TDMExpert from '../../assets/images/TrainingBadges/TDMExpert.png'

import './userbadges.css'
import {setSideBarPanel } from '../../actions/authActions';
import {setUserBadges} from '../../actions/trainingBadgesAction'
import { setLocation } from "../../actions/userFeedbackActions";
import { Card, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';



const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const UserBadges = (props) => {
   
  const [badge,setBadge] = useState("")
  const [badgeData,setBadgeData] = useState("")
  const [badgeStyle , setBadgeStyle] = useState("")
  
   useEffect(() => {
     let trainingData = UserBadges();
     setBadge(trainingData.badge);
     setBadgeData(trainingData.data)
     setBadgeStyle("badgeCard"+" "+trainingData.margin)
    props.setUserBadges(trainingData);
  }, []);

  const UserBadges = () => 
  {
    switch (props.userTrainingBadge) {
      case "TDMBeginner":
        return {"margin" :"mt-0","badge" : TDMBeginner , "data" : "TDMBeginner" ,"userProfiletooltip" : "Any user who has access to TDM UI."};
      case "TDMExplorer":
        return {"margin" :"mt-0","badge" : TDMExplorer , "data" : "TDMExplorer","userProfiletooltip" : "User has successfully completed TDM-EXPLORER level of TDM."};
      case "TDMInnovator":
        return {"margin" :"mt-0","badge" :TDMInnovator, "data" : "TDMInnovator", "userProfiletooltip" : "User has successfully completed TDM-INNOVATOR level of TDM."};
      case "TDMContributor":
        return {"margin" :"mt-0","badge" : TDMContributor, "data" : "TDMContributor" , "userProfiletooltip" : "User has successfully completed TDM-CONTRIBUTOR level of TDM."};
      case "TDMExpert":
          return {"margin" :"mt-n1","badge" : TDMExpert, "data" : "TDMExpert", "userProfiletooltip" : "User has successfully completed TDM-EXPERT level of TDM."};
      default:
        return {"margin" :"mt-0","badge" : TDMBeginner , "data" : "TDMBeginner" ,"userProfiletooltip" : "Any user who has access to TDM UI."};
    }
  }
  const brandStyles = {
    width: 60,
    marginTop: 6
};
  return (
   <Fragment>
      <Tooltip title={badgeData} id="badgeTooltip">
      <Card className={badgeStyle}>
      <Link to="/userBadges">
      <img src={badge} style={brandStyles}/>
      
      </Link>
      </Card>
        </Tooltip>
                </Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    userTrainingBadge : state.trainingBadges.userTrainingBadge
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
      setSideBarPanel: (value) => dispatch(setSideBarPanel(value)),
      setUserBadges:(value)  => dispatch(setUserBadges(value)),
      setLocation:(path) => dispatch(setLocation(path))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(UserBadges);