import React from "react";
import PropTypes from "prop-types";
import { TableCell } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { makeStyles } from "@material-ui/core/styles";

const borderColor = grey[300];
const useTableVerticalCellStyle = makeStyles({
  root: {
    borderBottom: `1px solid ${borderColor}`,
  },
  head: {
    width: '20%'
  },
});

const TableVerticalCell = (props) => {
  const { header, body, bodyCellValueAlignment="left" } = props;

  const tableTableVerticalCellStyle = useTableVerticalCellStyle();

  return (
    <>
        <TableCell classes={tableTableVerticalCellStyle} variant="head">{header}</TableCell>
        {
          // body: May pass in [{align: "center", value: component}]
          body.map((value) => { 
            return (
              <TableCell 
                key={value} 
                classes={tableTableVerticalCellStyle} 
                align={(value && value.align) ? value.align : bodyCellValueAlignment}
              >
                  {(value && value.align) ? value.value : value}
              </TableCell>
            )
          })
        }
    </>
  );
};

TableVerticalCell.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
  bodyCellValueAlignment: PropTypes.oneOf(["center","inherit","justify","left","right"])
};

export default TableVerticalCell;
