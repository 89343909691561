import React from "react";
import PropTypes from "prop-types";
import { fetchDataSet, updateJsonData, deleteJsonDataAction, decreaseCount } from '../../../actions/retrieveDataActions';
import { connect } from "react-redux";
import FilterComponent from "./FilterComponent";


const ExpandInfoTable = (props) => {
  const { columns, row, hideEmptyExpandOptions, filters } = props;

  const removed_undefined = _.compact(hideEmptyExpandOptions ? columns.filter(v => row[v.field]) : columns.map((column, index) => {
    if (column.expandable) {
      return column;
    }
  }));

  let nestedData = removed_undefined.map((column) => {
    let fieldValue;
    let fieldName = column.title;

    fieldValue = row[column.field];
    return {
      fieldValue,
      fieldName,
    }
  });

  const NestedTableCom = ({ getData }) => {
    if (getData) {
      const filterOptions = getData?.map((_item) => {
        return {
          label: _item.name,
          value: _item.path
        }
      });
      if (getData) {
        return <FilterComponent fieldValue={filterOptions} props={props} row={row} />
      }
    } return null;
  }

  return <NestedTableCom getData={filters} />
};

ExpandInfoTable.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  row: PropTypes.object.isRequired,
  hideEmptyExpandOptions: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    environment: state.auth.selectedEnvValue,
    userName:state.auth.userName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDataSet: (reqBody, id) => dispatch(fetchDataSet(reqBody, id)),
    updateJsonData: (payload) => dispatch(updateJsonData(payload)),
    deleteJsonDataAction: (payload,payload2) => dispatch(deleteJsonDataAction(payload,payload2)),
    decreaseCount:(payload,payload2)=>dispatch(decreaseCount(payload,payload2))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ExpandInfoTable);