import axios from 'axios';
import {axiosTaasInstance as axiosWithIntercept} from "../axios.config";
import * as types from './actionTypes';

import config from '../config';
import { isTaasTokenValid } from '../components/auth/functions';
import { taasAuthSigninHeaders, authHeaders } from '../utils/api';

const API_URL = config.api.uri;
const API_URL_GATEWAY = config.tdm_gateway_api.uri;
const TAAS_USER_BASE_URL = config.taas_user_base;
const TAAS_OAUTH_URL = config.taas_oauth;

export const fetchUsers = () => async dispatch => {
    dispatch({type: types.LOAD_USERS_LOADING, loading: true});
    try {
        const response = await axiosWithIntercept.get(`${TAAS_USER_BASE_URL}/users?toolName=TDM`, {headers: authHeaders()});
        dispatch({type: types.LOAD_USERS_SUCCESS, payload: response.data});
        dispatch({type: types.LOAD_USERS_LOADING, loading: false});
        return true;
    } catch (e) {
      const errMsg = e?.response?.data?.responseDescription || "Failed to retrieve all user's list";
      console.error(errMsg);
      dispatch({ type: types.LOAD_USERS_FAILED, payload: errMsg });
        console.error(e);
    }

    return false;
}

export const fetchUsersInTeam = (teamId) => async dispatch => {
    dispatch({type: types.LOAD_USERS_IN_TEAM_LOADING, loading: true});
    try {
       const response = await axiosWithIntercept.get(`${TAAS_USER_BASE_URL}/users/team?teamId=${teamId}&toolName=TDM`,{headers: authHeaders()});
        dispatch({type: types.LOAD_USERS_IN_TEAM_SUCCESS, payload: response.data || "fail"});
        return (response.data && Object.keys(response.data).length > 0 ? response.data : false)
    } catch (e) {
        console.error(e);
        return false;
    }
}

export const fetchUserMetadata = (id) => async dispatch => {
    try {
      dispatch({ type: types.FETCH_USER_META_DATA_LOADING });
      const response = await axios.get(`${API_URL_GATEWAY}/tdm/data/users/metadata/info?username=${id}`, { headers: authHeaders() });
      if (response.status === 200 && response.data?.userDataInfo) {
        dispatch({ type: types.FETCH_USER_META_DATA_SUCCESS, payload: response.data.userDataInfo });
      } else if (response.status !== 200) {
        dispatch({ type: types.FETCH_USER_META_DATA_FAILED, payload: "Failed to retrieve user meta data" });
      }
    } catch (e) {
      if (e?.response?.status === 404 && e?.response?.data?.responseDescription.includes("no meta information saved")) {
        dispatch({ type: types.FETCH_USER_META_DATA_SUCCESS, payload: { username: id, trainingBadge: "TDMBeginner"} });
      } else {
        const errMsg = e?.response?.data?.responseDescription || "Failed to retrieve user meta data";
        console.error(errMsg);
        dispatch({ type: types.FETCH_USER_META_DATA_FAILED, payload: errMsg });
      }
    }
}

export const fetchUserTaasMetadata = (id) => async dispatch => {
    try {
      dispatch({ type: types.FETCH_USER_TAAS_META_DATA_LOADING });
      const response = await axios.get(`${TAAS_USER_BASE_URL}/users/metadata?userId=${id}&toolName=TDM`, { headers: authHeaders() });
      dispatch({ type: types.FETCH_USER_TAAS_META_DATA_SUCCESS, payload: response.data });
    } catch (e) {
      const errMsg = e?.response?.data?.responseDescription || "Failed to retrieve user taas meta data";
      console.error(errMsg);
      dispatch({ type: types.FETCH_USER_TAAS_META_DATA_FAILED, payload: errMsg });
    }
}

export const updateUserTaasMetadata = (request) => async dispatch => {
    try {
      dispatch({ type: types.UPDATE_USER_TAAS_META_DATA_LOADING });
      const response = await axios.post(`${TAAS_USER_BASE_URL}/users/metadata`, request, { headers: authHeaders() });
      dispatch({ type: types.UPDATE_USER_TAAS_META_DATA_SUCCESS, payload: response?.data });
    } catch (e) {
      const errMsg = e?.response?.data?.responseDescription || "Failed to update user's taas meta data";
      console.error(errMsg);
      dispatch({ type: types.UPDATE_USER_TAAS_META_DATA_FAILED, payload: errMsg });
    }
}  

export const generateBearerToken = (request, taasToken) => async dispatch => {
    try {
        const taasTokenValid = await isTaasTokenValid(taasToken);
        if (taasTokenValid.verified) {
            const params = new URLSearchParams({
              grant_type: 'urn:ietf:params:oauth:grant-type:rsa-authorization-token',
              primaryToken: taasToken,
              filter: `[{"QECOETOOLName": "TDM", "QECOETOOLTeamId": "${request.teamId}"}]`
            });
            const response = await axios.post(`${TAAS_OAUTH_URL}/oauth/token`, params, { headers: taasAuthSigninHeaders() });
            return response.data;
        } else {
            dispatch({ type: types.AUTH_ERROR, message:"Taas Token Invalid, Please signin again" });
            return "taasErr";
        }
    } catch (e) {
        console.error(e);
        let errorMsg = "";
        if (typeof e === "string") errorMsg = e;
        else if (e.response) errorMsg = e.response.data.responseDescription;
        else errorMsg = "Network connectivity issues, please try again later.";
        return {
          responseDescription: errorMsg,
          responseStatus: "Fail"
        };
    }
}