import React, { useState } from "react";
import * as Survey from "survey-react";
import * as widgets from "surveyjs-widgets";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "survey-react/survey.css";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types';
import "./slider.css";
import Pagination from '@material-ui/lab/Pagination';

Survey.StylesManager.applyTheme('default');
widgets.bootstrapslider(Survey);
class SurveyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isCompleted: false, value: '' };
    this.onCompleteComponent = this.onCompleteComponent.bind(this);
  }

  onCompleteComponent() {
    this.setState({ isCompleted: true });
  }

  updateJson(section, topic) {
    const ans = [];
    topic.showNavigationButtons = false;
    topic.firstPageIsStarted = false;
    topic.showQuestionNumbers = "off";
    topic.elements.map((question, index) => {
      if (question.type == "radiogroup") {
        question.colCount = Object.keys(question.choices).length;
        question.readOnly = true;
        var name = question.name;
        ans[name] = question.answer;
      }
      if (question.type == "bootstrapslider") {
        if (question.config.value !== null) {
          widgets.bootstrapslider(Survey);
          var name = question.name;
          ans[name] = question.config.value;
        }
        else {/* code to achieve N/A in slider with value : null */
          question.type = "matrix"
          question.columns = [
            {
              value: "",
              text: ""
            }, {
              value: "",
              text: "N/A"
            },]
          console.log(question)
        }
      }
      if (question.type == "rating") {
        var name = question.name;
        ans[name] = question.value;
      }
    });
    return ans;

  }

  fetchSurvey(sections, props) {

    return sections.map(section => {
      return section.topics.map(topic => {
        const ans = this.updateJson(section, topic);
        var surveyRender = !this.state.isCompleted ? (
          <div id="surveyTab">
            <div class="container lineHeightRanking">
              <div class="row">
                <div class="col-sm ml-n2 tdmFont">
                  <strong>{topic.name}</strong>
                </div>
                <div class="col-sm"></div>
                {section.name !== "Feedback" ? (<div class="col-sm ml-20 pl-8 tdmFont"><strong>Relative Ranking (%)</strong></div>) : (<div></div>)}
                <div class="col-sm"></div>
              </div></div>
            <Survey.Survey
              data={ans}
              json={topic}
              showCompletedPage={false}
              onComplete={this.onCompleteComponent} />
            {section.name === "Feedback" ? (<Button variant="contained"
              color="primary" className="feedbackSubmit"
              onClick={e => { props.onClickHandler(this.state.ratingValue, this.state.ratingComment) }}>
              Submit</Button>) : []}
          </div>

        ) : null;
        return (

          <TabPanel tabId={this.props.value} value={this.props.value} index={section.name}>
            <div>
              {surveyRender}
            </div>
          </TabPanel>
        );
      });
    });
  }

  render() {
    var onCompleteComponent = this.state.isCompleted ? (
      <div>The component after onComplete event</div>

    ) : null;
    return (
      <div>
        {this.fetchSurvey(this.props.payload.Questionnaire.sections, this.props)}
        {onCompleteComponent}

      </div>
    );
  }
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={5}>
          <Typography>{children}</Typography>
        </Box>

      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderRadius: "20px",
    boxShadow: '0 3px 7px 2px rgba(64, 64, 64, .3)',
    backgroundColor: '#FFFFFF'
  }

}));




const surveyApp = (props) => {
  const sections = props.payload.Questionnaire.sections;
  const classes = useStyles();
  const [value, setValue] = sections && useState(sections[0].name);
  const [currIndex, setCurrIndex] = useState(0);
  
const handlePage = (e,val) => {
  setValue(sections[val-1].name)
  setCurrIndex(val-1)
}
   

  const sectionName = sections.map((section, key) => {
    return (
    <li class={key == currIndex ? "active" : ""}>{section.name}</li>
    );
  });


  return (
    <div className={classes.root} >

      <AppBar position="static" id="surveyTab">
      <ul id="progress">
          {sectionName}
      </ul>
      </AppBar>

      <SurveyComponent value={value} payload={props.payload} onClickHandler={props.onClickHandler} />
      <div class="row">
      <div class="col-sm-3"></div>
            <div class="col-sm-8 pl-20 ml-3 surveyPagination">
      <Pagination count={sections.length} showFirstButton showLastButton onChange={(e,val)=>handlePage(e,val)}/>
      </div>
      </div>
    </div>

  );
}

export default surveyApp;