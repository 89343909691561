import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Typography, Table, TableBody, TableRow } from '@material-ui/core';
import BreadTable from "@library/core/tables/BreadTable";
import TableVerticalCell from "@library/core/tables/TableVerticalCell";
import { clearSyntheticTypeData, clearDataFromCart } from '../../actions/syntheticDataActions';
import CheckboxItem from "@library/core/form-elements/CheckboxItem";

const ReviewStep = (props) => {
  const [formattedCart, setFormattedCart] = useState([]);

  useEffect(() => {
      if (props.syntheticDataCart) {
          formatCartSummaryViewData();
      }
  }, []);

  const formatCartSummaryViewData = () => {
      const data = _.map(props.syntheticDataCart, (val, key) => {
          let item = [...props.syntheticBasicOptions, ...props.syntheticBundleOptions].find((obj => obj['type'] == key));
          if (item) {
              item.quantity = props.syntheticDataCartQty;
              return item;
          }
      }).filter(val => val);
      setFormattedCart(data);
  }

  let args = {
      expandTitle: "Details",
      size: "medium",
      isExpandable: true,
      columnMetadata: [
        {
          order: 1,
          title: "Synthetic Data Type",
          field: ["type"],
          expandable: false,
          component: (field) => (
            textArgComp(field)
          ),
        },
        {
          order: 2,
          title: "Quantity",
          field: ["quantity"],
          expandable: false,
          component: (qty) => (
            textArgComp(qty,"body1")
          ),
        },
        {
          order: 3,
          title: "",
          field: ["details","type"],
          expandable: true,
          hidden: true,
          component: (field, type) => (
            detailsComp(field, type)
          )
        }
      ]
    }

    const textArgComp = (field, variant = "body2") => {
      return (
        <Typography style={{ "width": "190px", "margin": "auto" }} noWrap variant={variant}>
          {field}
        </Typography>
      )
    }

    const detailsComp = (field, type) => {
      return (
        <Table size="small">
          <TableBody>
            {
              field.map(item => {
                if (props.syntheticDataCart && props.syntheticDataCart[type] && props.syntheticDataCart[type][item.name]) {
                  return (
                    <TableRow key={item.name}>
                        <TableVerticalCell 
                          header={item.name==="Credit Card" ? `${item.name}: ${props.creditCardType}` : item.name} 
                          body={[item.description]}
                        />
                    </TableRow>
                  )
                }
              })
            }
          </TableBody>
        </Table>
      )
    }

    return (
      <>
        <BreadTable {...args} title="Cart" titleVariant="h5" data={formattedCart}/>
      </>
    )
}
const mapStateToProps = (state) => {
  return {
    userName: state.auth.userName,
    environment: state.auth.selectedEnvValue,
    syntheticDataTypeInfo: state.syntheticData.syntheticDataTypeInfo,
    syntheticDataCart: state.syntheticData.syntheticDataCart,
    syntheticBasicOptions: state.syntheticData.syntheticBasicOptions,
    syntheticBundleOptions: state.syntheticData.syntheticBundleOptions,
    syntheticDataCartQty: state.syntheticData.syntheticDataCartQty,
    creditCardType:state.syntheticData.creditCardType,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearSyntheticTypeData: () => dispatch(clearSyntheticTypeData()),
    clearDataFromCart: () => dispatch(clearDataFromCart())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewStep);
