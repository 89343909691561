import React, {Component} from 'react'
import {Table, Row, Col} from 'reactstrap';
import DataViewMobileRow from './DataViewMobileRow';
import {SubHeader} from '../../../common/custom/SubHeader';

export const DataViewMobile = (props) => {
    const {mobileItems, classFilter, filename} = props;
    console.log('the dataViewMobile props are', props);
    return (
        <React.Fragment>
            <SubHeader text={"Mobile"} iconClass={"td-icon-md icon-mobile"} iconColor={"orange"} data={mobileItems} filename={`${filename}-MOBILE`}/>
            <Table>
                <thead>
                    <tr>
                        <th>Mobile ID</th>
                        <th>Product Type</th>
                        <th>MSISDN</th>
                        <th>IMSI</th>
                        <th>SIM</th>
                    </tr>
                </thead>
                <tbody>
                    {mobileItems.map((item) => {
                        return <DataViewMobileRow item={item} key={item.id} classFilter={classFilter}></DataViewMobileRow>
                    })}
                </tbody>
            </Table>
        </React.Fragment>
    )
}