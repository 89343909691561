import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    ListItemText,
    Paper,
} from "@material-ui/core";

import { MoreVert as MoreVertIcon } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core/styles";
import './customDataset.css'

const usePaperStyle = makeStyles({
    root: {
        width: "100%",
        maxWidth: 200,
    },
});

const useActionsMenuIconStyle = makeStyles((theme) => ({
    button: {
        "&:hover": {
            "& $icon": {
                color: theme.palette.background.paper,
            },
        },
    },
    icon: {
        // color: theme.palette.icon.color,
        color: "#1976d2",
    },
}));

const defaultAnchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
};

const defaultTransformOrigin = {
    vertical: "center",
    horizontal: "left",
};

const DatasetActionsMenu = (props) => {
    const paperStyle = usePaperStyle();
    const actionsMenuIconStyle = useActionsMenuIconStyle();
    const [anchorEl, setAnchorEl] = useState(null);
    
    const currData = props.customDataBankDataset.filter((data) => data.name === props.data)
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const checkCurrTeam=({availableTeam, currTeam})=>{
        return availableTeam == currTeam
    }
    const HandleEditTagAndDataset = ({userName, currData}) => {
        let status;
        if (currData?.scope === "PRIVATE" && currData.userId === userName) {
             status=true;
        } else if (currData?.scope === "TEAM" && props.listOfTeams?.some((item)=> checkCurrTeam(item.teamId, currData.team.id))) {
            status= true;
        }
        if(status){
            return   <>
            <MenuItem disableRipple onClick={e => props.handleEditDataset(e)}>
            Edit Dataset
            </MenuItem>
             <MenuItem disableRipple onClick={e => props.handleEditTags(e)}>
            Edit Tags
             </MenuItem>
            </>
        }return null;
    }

    return (
        <>
            <IconButton
                className={actionsMenuIconStyle.button}
                onClick={e => handleMenuOpen(e)}
            >
                <MoreVertIcon className={actionsMenuIconStyle.icon} />
            </IconButton>
            <Paper>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    getContentAnchorEl={null}
                >
                    {/* <MenuItem disableRipple onClick={e => props.handleCheckoutDataset(e)}> */}
                    {currData[0]?.scope === "TEAM" && props.selectedToggle === "BYOD" ? 
                    <MenuItem disableRipple onClick={() => {
                        props.handleCreateAllocate();
                        handleMenuClose();
                    }}>
                        Allocate Dataset
                    </MenuItem> 
                    : null}
                    {props.selectedToggle === "BYOD" && <MenuItem disableRipple onClick={() => {
                        props.handleCheckoutDataset();
                        handleMenuClose();
                    }}>

                        Checkout Dataset
                    </MenuItem>}
                    <HandleEditTagAndDataset userName={props.userName} currData={currData[0]}/>
                </Menu>
            </Paper>

        </>
    );
};

const mapStateToProps = state => {
    return {
        userName: state.auth.userName,
        listOfTeams: state.auth.myAvailableTeams
    };
  };

export default connect(mapStateToProps)(DatasetActionsMenu);
