import React, { Component } from 'react'

import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #a20025 0, #5f0325 100%)'
}

const ApiDocsTile = (props) => (
 <>
    <LinkTile
        style={tileStyle}
        className="bg-crimson"
        data-size="medium"
        // to="/dashboard"
        data-effect="hover-zoom-up"
        redirect="true"
        // url="https://tdm.in.telstra.com.au/api-docs/"
        >
        <div class="slide-front">
            <span className="mif-description icon"></span>
            <span className="branding-bar">API Documentation</span>
        </div>
            <div class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
                <p class="text-center">
                    Coming Soon
                </p>
            </div>
    </LinkTile>
 </>
)

ApiDocsTile.propTypes = {}

export default ApiDocsTile;