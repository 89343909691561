import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { TextField } from '@material-ui/core';
import EdgarPopupFilterRow from "./EdgarPopupFilterRow";

class EdgarCheckoutPopUp extends Component {
  state = {
    chkoutQty: 1,
    errorMsg: '',
    serviceFilters: [],
    serviceFilterRows: [],
    filterRequestBody: {},
    prevFilterData: [],
    filterCheckoutData: [],
  }

  componentDidMount() {
      let tempFilterArray = [];
      (this.props.serviceFilterData.success === true) && this.props.serviceFilterData.data ? this.props.serviceFilterData.data.forEach(resource => {
          tempFilterArray = [...tempFilterArray, {parameterName :resource.parameterName, column: resource.column, filterName: resource.filterName, tableName: resource.resourceTableName, resourceName: resource.resourceName }];
      }):null;
      this.props.serviceFilterData.data && this.props.serviceFilterData.success ? this.onAddNewFilter(tempFilterArray) : null;
      this.setState(prevState => ({
        ...prevState,
        serviceFilters: tempFilterArray
      }));
  }

  createFilterPayload = (value) => {
    let newEntry = {
      resourceName: value.table,
      filterName: value.value
    }
    let payload = {
      teamId: this.props.selectedRow.teamId,
      serviceId: this.props.selectedRow.serviceId,
      environment: this.props.selectedEnvValue,
      previousFiltersData: [
        ...this.state.prevFilterData
      ],
      filterColumn: newEntry
    }
    this.setState(prevState => ({
      ...prevState,
      filterRequestBody: payload,
    }));
    return payload;
  }

  validationMsg = (availableQty, maxQty, chkoutQty) => {
    let errorMsg = '';
    chkoutQty ? chkoutQty > 0 ? chkoutQty <= maxQty ? chkoutQty <= availableQty ? errorMsg = ''
      : errorMsg = 'Checkout quantity is greater than Available quantity'
      : errorMsg = 'Checkout quantity is greater than max quantity'
      : errorMsg = 'Checkout quantity should be at least 1'
      : errorMsg = 'Checkout quantity required'
    this.setState(prevState => ({
      ...prevState,
      errorMsg: errorMsg
    }));
  }

  toggleModal = () => {
    this.props.setShowModal(false);
    this.setState(prevState => ({
      ...prevState,
      chkoutQty: 1,
      errorMsg: '',
      serviceFilters: [],
      serviceFilterRows: [],
      filterRequestBody: {},
      prevFilterData: [],
      filterCheckoutData: [],
      comment : ''
    }));
  }

  onChangeFilterField = (value, {action}) => {
    if (action === "select-option") {
      this.props.handleCheckoutServiceFilterValue(this.createFilterPayload(value));
    }
  }

  onChangeFilterFieldValue = (value, {action}) => {
    let newPrevFilterEntry = {
      resourceName: value.table,
      filterName: value.fieldColumnName,
      filterValue: value.value,
    }
    let filterCheckoutData = {
      resourceName: value.fieldResourceName,
      tableName: value.table,
      filterName: value.fieldColumnName,
      filterValue: value.value,
      searchParameterName : value.parameterName
    }
    this.setState(prevState => ({
      ...prevState,
      prevFilterData: [...prevState.prevFilterData.filter(e => (e.resourceName+e.filterName != value.table+value.fieldColumnName)), newPrevFilterEntry],
      filterCheckoutData: [...prevState.filterCheckoutData.filter(e => (e.resourceName+e.filterName != value.table+value.fieldColumnName)), filterCheckoutData]
    }));
  }

  onAddNewFilter = (tempFilterArray) => {
      this.setState(prevState => ({
        ...prevState,
        serviceFilterRows: [...prevState.serviceFilterRows, <EdgarPopupFilterRow tempFilterArray={tempFilterArray}
                                                                            onRemoveRow={this.onRemoveRow}
                                                                            onAddNewFilter={this.onAddNewFilter}
                                                                            onChangeFilterField={this.onChangeFilterField}
                                                                            onChangeFilterFieldValue={this.onChangeFilterFieldValue}
                                                                            arrayPosition={prevState.serviceFilterRows.length}/>]
      }));
  }

  onRemoveRow = (position) => {
    this.setState(prevState => (
      position != 0 ? {
        ...prevState,
        serviceFilterRows: [...prevState.serviceFilterRows.slice(0, position+1)],
        prevFilterData: [...prevState.prevFilterData.slice(0, position)],
        filterCheckoutData: [...prevState.filterCheckoutData.slice(0, position)],
      }:{
        ...prevState,
        serviceFilterRows: [...prevState.serviceFilterRows.slice(0, position+1)],
        prevFilterData: [],
        filterCheckoutData: [],
      }
    ));
  }

  render() {
    const { showModal, setShowModal, selectedRow, loggedInUser, handleCheckout } = this.props;
    return (
      <Modal size="lg" isOpen={showModal} toggle={() => this.toggleModal()} backdrop="static">
        <ModalHeader toggle={() => this.toggleModal()} style={{"paddingLeft":"40px"}} cssModule={{'modal-title': 'w-100 text-center'}}><strong>TDM Checkout</strong></ModalHeader>
        <br/>
        <ModalBody style={{"padding":"0px 6%"}}>
          <section>
            <div className="row" style={{"padding-bottom": "18px"}}>
              <div className="col-sm-5">
                <strong>Service Id: </strong> {selectedRow.serviceId}
              </div>
              <div className="col-sm-5">
                <strong>Description: </strong> {selectedRow.description}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <strong style={{"verticalAlign":"text-top","paddingRight":"10px"}}>Quantity: </strong>
                <TextField
                  id="outlined-number1"
                  type="number"
                  variant="outlined"
                  value={this.state.chkoutQty}
                  onChange={e => {
                    let val = e.target.value;
                    this.setState(prevState => ({
                      ...prevState,
                      chkoutQty: val,
                    }))
                    this.validationMsg(selectedRow.quantity, selectedRow.maxCheckoutQuantity, val)
                  }}
                  error={this.state.errorMsg ? true : false}
                  helperText={this.state.errorMsg ? this.state.errorMsg : "MaxQty: "+selectedRow.maxCheckoutQuantity}
                  size={"small"}
                />
              </div>
            </div>
            {this.props.serviceFilterData.data && this.props.serviceFilterData.success ? <div className="row">
              <div className="col-5">
                  <b>Filter</b>
              </div>
              <div className="col-5">
                  <b>Value</b>
              </div>
            </div> : <></>}
            {
              this.state.serviceFilterRows.map((row, index) => <div key={index}>{row}</div>)
            }

                  <div className="col">
                    <textarea
                      className="form-control"
                      id="checkout-comment"
                      placeholder="Checkout Comment"
                      value={this.state.comment}
                      onChange={e => {
                        let val = e.target.value;
                        this.setState(prevState => ({
                          ...prevState,
                          comment: val,
                        }))
                      }}
                      rows="4"
                    ></textarea>
                  </div>

            {this.state.filterCheckoutData.length < 2 ?<div>
              <br/>
              Please select mandatory filters Jurisdiction and Installation Type for checkout.
            </div> : <></> }

          </section>
        </ModalBody>
        <br/>
        <ModalFooter style={{"justify-content":"center"}}>
          <Button
            className={"btn-team"}
            color="primary"
            onMouseOver={() => { this.validationMsg(selectedRow.quantity, selectedRow.maxCheckoutQuantity, this.state.chkoutQty) }}
            onClick={() => {
              handleCheckout(selectedRow, (loggedInUser == selectedRow.teamId), this.state.chkoutQty, this.state.filterCheckoutData, this.state.comment);
              setShowModal(false);
            }}
            disabled={this.state.errorMsg ? true : this.state.filterCheckoutData.length < 2}
          >
            Checkout
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    serviceFilterData: state.teams.serviceFilterData,
    serviceFilterDataValues: state.teams.serviceFilterDataValues,
    selectedEnvValue: state.auth.selectedEnvValue,
  };
};

export default connect(mapStateToProps)(EdgarCheckoutPopUp);
