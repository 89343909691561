import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Typography,
  IconButton,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { Title } from "@library/core/text";
import ActionsMenu from "@library/core/dropdowns/ActionsMenu";
import TableHeaderCell from "@library/core/tables/TableHeaderCell";
import TableBodyRow from "@library/core/tables/TableBodyRow";
import TableBodyNoDataRow from "@library/core/tables/TableBodyNoDataRow";
import TableBodyCell from "@library/core/tables/TableBodyCell";
import TableExpandRow from "./TableExpandRow";
import ExpandInfoTable from "./ExpandInfoTable";
import TableHeader from "@library/core/tables/TableHeader";

const useTableStyle = makeStyles({
  root: {
    borderCollapse: "separate",
    borderSpacing: "0px 0px",
  },
  noWrap: {
    whiteSpace: "nowrap"
  }
});

const useMessageTextStyle = makeStyles((theme) => ({
  root: {
    // color: theme.palette.tableHeader.color,
    color: blueGrey[700],
  },
}));

const defaultSize = "small";
const defaultRowsPerPage = 10;

const deriveRowsPerPageOptions = (dataLength) => {
  if (dataLength === 0) return undefined;
  const rowsPerPageOptions = _.range(1, dataLength / defaultRowsPerPage).map(
    (n) => n * defaultRowsPerPage
  );
  rowsPerPageOptions.push({ value: dataLength, label: "All" });

  return rowsPerPageOptions;
};

const isDataUnavailable = (data) => {
  return !data || _.isEmpty(data);
};

const areActionItemsUnavailable = (actionItems) => {
  return !actionItems || _.isEmpty(actionItems);
};

const BreadTable = (props) => {
  const calculatedRowsPerPageOptions = deriveRowsPerPageOptions(
    props.data?.length
  );

  const {
    title,
    searchPermOpen,
    searchFilter,
    filter,
    addNew,
    titleBtnArea,
    titleBtnAreaBefore,
    addDisable,
    darkHeader = false,
    titleVariant,
    titleLocation = "above",
    headerPadding,
    expandTitle,
    columnMetadata,
    actionItems,
    filterComponent,
    addComponent,
    data,
    isLoading,
    isExpandable = false,
    hideEmptyExpandOptions = false,
    size = defaultSize,
    rowsPerPageOptions = calculatedRowsPerPageOptions,
    totalRows,
    rowsPerPage: initialRowsPerPage = defaultRowsPerPage,
    noDataMsg,
    fixedNumRows = false,
    heightPerRowForEmptyLoad,
    paginateByApi,
    disableNextByApiOnLastPage,
    resetToLastAvailablePage = false,
    noWrap,
  } = props;

  const tableStyle = useTableStyle();
  const displayMessageStyle = useMessageTextStyle();

  const sortedColumns = _.orderBy(columnMetadata, ["order"], ["asc"]);

  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = React.useState({});

  // reset to first/last page that actually contains data
  useEffect(() => {
    if(data){
      const currentNumOfPages = Math.ceil(data.length / rowsPerPage);
      if (currentPage + 1 > currentNumOfPages) {
        const newPage = (currentNumOfPages > 0) && resetToLastAvailablePage ? currentNumOfPages - 1 : 0;
        setCurrentPage(newPage);
      }
    }
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const getHeaders = (columnHeaders,data) => {
    return columnHeaders.map((columnHeader, index) => {
      return (
        <TableHeaderCell key={index}>{columnHeader.title}
        {props.filterHeader && props.openFilter === true  ? 
          <Box display="flex">
            {columnHeader.title !== "Actions" ? 
            <TextField
                    variant="outlined"
                    className={props.filterClass}
                    onChange={e => {handleFilterChange(columnHeader.title,index,e.target.value)}}
                  /> : <TextField
                  variant="outlined"
                  className={props.filterClassEmpty}/>}
      </Box> : <></>
        }
        </TableHeaderCell>
      );
    });
  };

  const displayDataUnavailableMessage = (columnLength, message) => {
    return (
      <TableBodyNoDataRow 
        columnLength={columnLength} 
        fixedNumRows={fixedNumRows} 
        fixedHeight={size === "small" ? rowsPerPage*(heightPerRowForEmptyLoad || 65) : rowsPerPage*(heightPerRowForEmptyLoad || 81)}
      >
        <Typography variant="h6" classes={displayMessageStyle}>
          <Box fontWeight="fontWeightBold" fontSize={18}>
            {message || "No Data Available"}
          </Box>
        </Typography>
      </TableBodyNoDataRow>
    );
  };

  const displayDataLoadingSpinner = (columnLength) => {
    return (
      <TableBodyNoDataRow
        columnLength={columnLength}
        fixedNumRows={fixedNumRows}
        fixedHeight={size === "small" ? rowsPerPage*(heightPerRowForEmptyLoad || 65) : rowsPerPage*(heightPerRowForEmptyLoad || 81)}
      >
        <CircularProgress size="4rem" />
      </TableBodyNoDataRow>
    );
  };

  const fillEmptyRows = (data) => {
    let tempArr = data.filter(value => JSON.stringify(value) !== '{}');
    let ans = data.length % rowsPerPage;
    if (ans !== 0) {
      for (let i = 0; i < (rowsPerPage-ans); i++) {
        tempArr.push({});
      }
    }
    return tempArr;
  }

  const getRows = (columns, data) => {
    let displayData = fixedNumRows ? fillEmptyRows(data) : data;
    return displayData
      .slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
      .map((row, index) => {
        return (
          <Fragment key={index}>
            <TableBodyRow>
              {isExpandable ? <TableBodyCell>
                <IconButton style={{"outline":"none"}} aria-label="expand row" size="small" onClick={() => setOpen({[index]: !open[index]})}>
                  {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableBodyCell> : null}
              {columns.map((column, index) => {
                return (
                  !column.hidden ?
                  <TableBodyCell key={index} style={{"textAlign":"center","margin":"auto"}}>
                    {column.component
                      ? column.component(
                          ...column.field.map((field) => row[field]),
                        )
                      : row[column.field]}
                  </TableBodyCell> : null
                );
              })}
              {isDataUnavailable(data) ||
              !actionItems ||
              _.isEmpty(actionItems) ? (
                <></>
              ) : (
                <TableBodyCell>
                  <ActionsMenu menuItems={actionItems} data={row} />
                </TableBodyCell>
              )}
            </TableBodyRow>
            {isExpandable ?
              <TableExpandRow open={open[index] ? true : false} title={expandTitle}>
                <ExpandInfoTable columns={columns} row={row}/>
              </TableExpandRow> : <></>}
          </Fragment>
        );
      });
  };

  return (
    <>
      {
        (title && titleLocation === 'above') ?
          <Box m={2} textAlign="left">
            <Title variant={titleVariant}>{title}</Title>
          </Box>
          : null
      }
      {!filterComponent && !addComponent ? (
        <></>
      ) : (
        <>
          <Box m={1}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xm={1}>
                {!filterComponent ? <></> : filterComponent()}
              </Grid>
              <Grid item xm={1}>
                {!addComponent ? <></> : addComponent()}
              </Grid>
            </Grid>
          </Box>
          <Divider light />
        </>
      )}
      <Box mb={1}>
        <TableContainer component={Paper} className="tour-library-breadTable-tableContainer">
          <TableHeader title={title && titleLocation === 'inside' ? title : ""} searchFilter={searchFilter} searchPermOpen={searchPermOpen} filter={filter} addNew={addNew} titleBtnArea={titleBtnArea} titleBtnAreaBefore={titleBtnAreaBefore} addDisable={addDisable} darkHeader={darkHeader} headerPadding={headerPadding} />
          <Table size={size} classes={tableStyle} className="tour-library-breadTable-table">
            <TableHead>
              <TableRow>
                {isExpandable ? <TableHeaderCell>{""}</TableHeaderCell> : null}
                {getHeaders(sortedColumns)}
                {isDataUnavailable(data) || areActionItemsUnavailable(actionItems) ? (
                  <></>
                ) : (
                  <TableHeaderCell>Actions</TableHeaderCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody className="tour-library-breadTable-tableBody">
              {isLoading
                ? displayDataLoadingSpinner(isExpandable ? sortedColumns.length + 1 : sortedColumns.length)
                : isDataUnavailable(data)
                  ? displayDataUnavailableMessage(isExpandable ? sortedColumns.length + 1 : sortedColumns.length, noDataMsg)
                  : getRows(sortedColumns, data)}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading || isDataUnavailable(data) ? (
          <></>
        ) : (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={totalRows || data.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            nextIconButtonProps={
              disableNextByApiOnLastPage ? {
                disabled: disableNextByApiOnLastPage && currentPage + 1 === Math.ceil(data.length / rowsPerPage)
              } : undefined
            }
          />
        )}
      </Box>
    </>
  );
};

BreadTable.propTypes = {
  title: PropTypes.string,
  searchPermOpen: PropTypes.bool,
  searchFilter: PropTypes.func,
  filter: PropTypes.func,
  addNew: PropTypes.func,
  addDisable: PropTypes.bool,
  titleVariant: PropTypes.string,
  titleLocation: PropTypes.oneOf(['above', 'inside']),
  expandTitle: PropTypes.string,
  isExpandable: PropTypes.bool,
  hideEmptyExpandOptions: PropTypes.bool,
  columnMetadata: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      field: PropTypes.arrayOf(PropTypes.string).isRequired,
      expandable: PropTypes.bool,
      expandableOnly: PropTypes.bool,
      hidden: PropTypes.bool,
      component: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  actionItems: PropTypes.arrayOf(PropTypes.object),
  filterComponent: PropTypes.func,
  addComponent: PropTypes.func,
  size: PropTypes.string,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.object])
  ),
  totalRows: PropTypes.number,
  noDataMsg: PropTypes.string,
  fixedNumRows: PropTypes.bool,
  heightPerRowForEmptyLoad: PropTypes.number,
  paginateByApi: PropTypes.shape({
    getPageData: PropTypes.func.isRequired,
  }),
  disableNextByApiOnLastPage: PropTypes.bool,
  resetToLastAvailablePage: PropTypes.bool
};

export default BreadTable;
