import * as types from './actionTypes';
import {axiosTaasInstance as axios} from "../axios.config";

import config from '../config';

import {authHeaders} from '../utils/api';

const API_URL = config.api.uri;
const GATEWAY_URL = config.tdm_gateway_api.uri;
const loadItemsStatsSuccess = (payload) => ( {type: types.LOAD_STATS_ITEMS_SUCCESS, payload} );
const loadUsersStatsSuccess = (payload) => ( {type: types.LOAD_STATS_USERS_SUCCESS, payload} );
const loadGlobalStatsSuccess = (payload) => ( {type: types.LOAD_STATS_GLOBAL_SUCCESS, payload} );
const loadPublicStatsSuccess = (payload) => ( {type: types.LOAD_STATS_PUBLIC_SUCCESS, payload} );

export const fetchGlobalStats = () => async dispatch => {
    try {
        const response = await axios.get(`${API_URL}/api/stats`);
        dispatch(loadGlobalStatsSuccess(response.data));
    } catch (e) {
        console.error(e);
    }
}

export const fetchItemsStats = () => async dispatch => {
    try {
        const response = await axios.get(`${API_URL}/api/items/stats`, {headers: authHeaders()});
        dispatch(loadItemsStatsSuccess(response.data));
        return true;
    } catch (e) {
        console.error(e);
    }

    return false;
};

export const fetchPublicStats = () => async dispatch => {
    try {
        const response = await axios.get(`${API_URL}/api/stats/public`);
        dispatch(loadPublicStatsSuccess(response.data));
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
}

export const fetchUserStats = () => async dispatch => {
    try {
        const response = await axios.get(`${API_URL}/api/users/stats`, {headers: authHeaders()});
        dispatch(loadUsersStatsSuccess(response.data));
        return true;
    } catch (e) {
        console.error(e);
    }

    return false;
};

export const fetchDataStats = () => async dispatch => {
    try {
        const response = await axios.get(`${API_URL}/api/stats/data`, {headers: authHeaders()});
        dispatch({type: types.LOAD_STATS_DATA_SUCCESS, payload: response.data});
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
}

export const fetchHealthStats = () => async dispatch => {
    try {
        const response = await axios.get(`${API_URL}/api/stats/health`, {headers: authHeaders()});
        dispatch({type: types.LOAD_STATS_HEALTH_SUCCESS, payload: response.data});
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
}
export const fetchCheckoutCountByTeam = (teamId, fromDate, toDate) => async dispatch => {
    try {
        const reqBody = {
            "teamIds": teamId,
            "fromDate": fromDate,
            "toDate":toDate
        };
        dispatch({type: types.LOAD_CHEKCOUT_COUNT_LOADING});
       const response = await axios.post(`${GATEWAY_URL}/tdm/statistics/report/statistics`,reqBody,{headers: authHeaders()});
       dispatch({type: types.LOAD_CHEKCOUT_COUNT_SUCCESS, payload: response.data});
    } catch (e) {
        dispatch({type: types.LOAD_CHEKCOUT_COUNT_FAIL});
        console.error(e);
    }
    return false;
}