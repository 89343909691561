import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    syntheticDataTypeInfo: {
        dataSetName: "",
        dataSetType: "",
        description: "",
        env: {label:"",value:""},
        scope: "PRIVATE",
        team: "",
        teamId: "",
    },
    syntheticDataCart: {},
    syntheticDataCartQty: 0,
    syntheticBasicOptions: [
        {
            type: "String",
            details: [
                {name: "Length", description: "30"},
                {name: "Format", description: "(Alphanumeric, Alpha with spl character, Only Alpha, Numeric)"},
            ]
        },
        {
            type: "Date/Time",
            details: [
                {name: "Date Format", description: "sequence is day, month, year - for example, 7/12/2020"},
                {name: "Time Format", description: "24-hour clock"},
            ]
        },
        {
            type: "Number",
            details: [
                {name: "Format", description: "No alpha , minimum of 10 and max of 15"},
            ]
        },
        {
            type: "Decimal number",
            details: [
                {name: "Format", description: "No alpha , minimum of 10 and max of 15"},
            ]
        },
        {
            type: "Sequential number",
            details: [
                {name: "Format", description: "No alpha , minimum of 10 and max of 15"},
            ]
        }
    ],
    syntheticBundleOptions: [
        {
            type: "Customer",
            details: [
                {name: "Title", description: "Randomly generated courtesy titles i.e., Mr, Mrs, Ms etc.,"},
                {name: "First Name", description: "A randomly generated name with a length of 5 to 10 characters"},
                {name: "Last Name", description: "A randomly generated name with a length of 5 to 10 characters"},
                {name: "DOB", description: "Date of Birth in DD/MM/YYYY format"},
                {name: "Email", description: "Email ID generated using firstname+lastname@randomhost.com"},
                {name: "Customer Type", description: "Residential"},
                {name: "Occupation", description: "Randomly chosen occupation i.e., School Teachers, Software Engineer etc"}
            ]
        },
        {
            type: "Contact",
            details: [
                {name: "Mobile", description: "Mobile number beginning with 04 or 05 - followed by 8 digits"},
                {name: "Home Phone", description: "Eight digit phone number preceded by a two digit STD area code"},
                {name: "Personal Email", description: "Email ID generated using firstname+lastname@randomhost.com"},
                {name: "Work Phone", description: "Eight digit phone number preceded by a two digit STD area code"},
                {name: "Work Email", description: "Email ID generated using firstname+lastname@randomhost.com"}
            ]
        },
        {
            type: "ID Check",
            details: [
                {name: "Driver License", description: "A license number generated with 6 to 10 characters depending on the randomly selected Australian state's license format."},
                {name: "Passport", description: "A random alpha-numeric passport number with initial 1-2 characters as text followed by 7 digits (0-9)"},
                {name: "Medicare ID", description: "A random 10 digit number. Eg. 1234567890"}
            ]
        },
        {
            type: "Billing Preferences",
            details: [
                {name: "Credit Card", description: "A credit card is a financial tool offered by a bank as a type of loan, with a line of revolving credit that you can access with your card account.", typeAdded:true},
                {name: "ABN", description: "An Australian Business Number is a unique 11 digit identification number for your business, issued by the Government."},
                {name: "ACN", description: "The Australian Company Number (ACN) is a nine digit number with the last digit being a check digit calculated using a modified modulus 10 calculation."},
                {name: "ARBN", description: "The Australian Registered Body Numbers (ARBNs) is a nine digit number with the last digit being a check digit calculated using a modified modulus 10 calculation."}
            ]
        }
    ],
    creditCardTypes:[
        {display:"American Express",value:"AMERICAN_EXPRESS"},
        {display:"Diners Club Carte Blanche",value:"DINERS_CLUB_CARTE_BLANCHE"},
        {display:"Diners Club International",value:"DINERS_CLUB_INTERNATIONAL"},
        {display:"Diners Club US and Canada",value:"DINERS_CLUB_US_AND_CANADA"},
        {display:"Discover Card",value:"DISCOVER_CARD"},
        {display:"InstaPayment",value:"INSTAPAYMNET"},
        {display:"JCB",value:"JCB"},
        {display:"Laser",value:"LASER"},
        {display:"Maestro",value:"MAESTRO"},
        {display:"Mastercard",value:"MASTERCARD"},
        {display:"Visa",value:"VISA"},
        {display:"Visa Electron",value:"VISA_ELECTRON"},
      ],
    syntheticOrderSuccess: false,
    syntheticOrderPending: false,
    syntheticOrderFailed: false,
    syntheticOrderFailedMsg: "",

    syntheticDataset: null,
    syntheticDatasetSuccess: false,
    syntheticDatasetPending: false,
    syntheticDatasetFailed: false,
    syntheticDatasetFailedMsg: "",

    syntheticDatasetToEdit: {},
    creditCardType:null,

    syntheticDataBankDataset : [],
    syntheticDataBankDatasetPending: false,
    syntheticDataBankDatasetFailed: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
       case types.SAVE_SYNTHETIC_TYPE_INFO: 
            return {
                ...state,
                syntheticDataTypeInfo: action.payload
            }
       case types.CLEAR_SYNTHETIC_INFO: 
            return {
                ...state,
                syntheticDataTypeInfo: {
                    ...state.syntheticDataTypeInfo,
                    dataSetName: "",
                    dataSetType: "Bundle",
                    description: "",
                    scope: "PRIVATE",
                    team: "",
                    teamId: "",
                }
            }
        case types.ADD_TO_SYNTHETIC_DATA_CART:
            return {
                ...state,
                syntheticDataCart: {
                    ...state.syntheticDataCart,
                    [action.key]: action.value
                }
            }
        case types.REMOVE_FROM_SYNTHETIC_DATA_CART:
            let prevCart = state.syntheticDataCart;
            delete prevCart[action.key];
            return {
                ...state,
                syntheticDataCart: {
                    ...prevCart
                }
            }
        case types.CLEAR_FROM_SYNTHETIC_DATA_CART:
            return {
                ...state,
                syntheticDataCart: {},
                syntheticDataCartQty: 0
            }
        case types.SAVE_SYNTHETIC_CART_QTY:
            return {
                ...state,
                syntheticDataCartQty: action.qty
            }
        case types.SEND_SYNTHETIC_ORDER_PENDING:
            return {
                ...state,
                syntheticOrderPending: true,
                syntheticOrderFailed: false,
                syntheticOrderFailedMsg: "",
                syntheticOrderSuccess: false,
            }
        case types.SEND_SYNTHETIC_ORDER_SUCCESS:
            return {
                ...state,
                syntheticOrderPending: false,
                syntheticOrderFailed: false,
                syntheticOrderFailedMsg: "",
                syntheticOrderSuccess: true,
            }
        case types.SEND_SYNTHETIC_ORDER_FAILED:
            return {
                ...state,
                syntheticOrderPending: false,
                syntheticOrderFailed: true,
                syntheticOrderFailedMsg: action.payload,
                syntheticOrderSuccess: false,
            }
        case types.SEND_SYNTHETIC_ORDER_RESET:
            return {
                ...state,
                syntheticOrderPending: false,
                syntheticOrderFailed: false,
                syntheticOrderFailedMsg: "",
                syntheticOrderSuccess: false,
            }
        case types.CREATE_SYNTHETIC_DATA_SET_PENDING:
            return {
                ...state,
                syntheticDataset: null,
                syntheticDatasetSuccess: false,
                syntheticDatasetPending: true,
                syntheticDatasetFailed: false,
                syntheticDatasetFailedMsg: "",
            }
        case types.CREATE_SYNTHETIC_DATA_SET_SUCCESS:
            return {
                ...state,
                syntheticDataset: action.payload,
                syntheticDatasetSuccess: true,
                syntheticDatasetPending: false,
                syntheticDatasetFailed: false,
                syntheticDatasetFailedMsg: "",
            }
        case types.CREATE_SYNTHETIC_DATA_SET_FAILED:
            return {
                ...state,
                syntheticDataset: null,
                syntheticDatasetSuccess: false,
                syntheticDatasetPending: false,
                syntheticDatasetFailed: true,
                syntheticDatasetFailedMsg: action.payload,
            }
        case types.CREATE_SYNTHETIC_DATA_SET_RESET:
            return {
                ...state,
                syntheticDataset: null,
                syntheticDatasetSuccess: false,
                syntheticDatasetPending: false,
                syntheticDatasetFailed: false,
                syntheticDatasetFailedMsg: "",
            }
        case types.SET_SYNTHETIC_DATASET_TO_EDIT:
            return {
                ...state,
                syntheticDatasetToEdit: action.data
            }
        case types.CHOOSE_CREDIT_CARD_TYPE:
            return {
                ...state,
                creditCardType:action.payload
            }
        case types.RESET_CREDIT_CARD_TYPE:
            return {
                ...state,
                creditCardType:null
            }
        case types.SYNTHETIC_DATA_RETRIEVED:
            return {
                ...state,
                syntheticDataBankDataset : action.payload,
                syntheticDataBankDatasetPending: false,
                syntheticDataBankDatasetFailed: false
            }
        case types.SYNTHETIC_DATA_PENDING:
            return {
                ...state,
                syntheticDataBankDataset: [],
                syntheticDataBankDatasetPending: true,
                syntheticDataBankDatasetFailed: false
            }
        case types.SYNTHETIC_DATA_FAILED:
            return {
                ...state,
                syntheticDataBankDataset: [],
                syntheticDataBankDatasetPending: false,
                syntheticDataBankDatasetFailed: true
            }
        default:
            return state;
    }
}