import config from "../config";

export const setTitle = (section) => {
    document.title = `${config.title} ${section ? `- ${section}` : ''}`;
}

export const getTokenFromCookie = (value) => {
    let name = value + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (const cookie of ca) {
        let c = cookie;
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const clearCookies = () => { 
    let cookies = document.cookie.split("; "); 
    for (let val of cookies) { 
        let d = window.location.hostname.split("."); 
        while (d.length > 0) { 
            let cookieBase = encodeURIComponent(val.split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path='; 
            let p = window.location.pathname.split('/'); 
            document.cookie = cookieBase + '/'; 
            while (p.length > 0) { 
                document.cookie = cookieBase + p.join('/'); 
                p.pop(); } d.shift(); 
        } 
    } 
}

/**
 * @param location 0.00 - 1.00 (0 being top and 1 being bottom of screen)
 * @returns Boolean
 */
export const isAtOrBeyondScrollLocationOnScreen = (location) => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = winScroll / height;
    return scrolled >= location;
}