import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { fetchSimFormData, fetchImsiFormData } from "../../actions/tdmOpsActions";
import { Button } from 'reactstrap';
import swal from 'sweetalert2';
import { displayLoading } from "../common/alerts/alerts";
import XLSX from 'xlsx';
import { SimasTable } from './SimasTable';
import SimasForm from './SimasForm';
import PaginationController from "../../utils/PaginationController";
import './SimasForm.css';

class SimasInsert extends Component {

    state = {
        selectedFile: null,
        fileJsonConverted: null,
        totalRecords: 0,
        currentPage: 0,
        totalPages: 0,
    }

    componentDidMount() {
        this.props.fetchSimFormData().then(() => {
            this.props.fetchImsiFormData().then(() => {
                if (this.props.simError || this.props.imsiError) {
                    swal.fire({
                        type: 'error',
                        title: 'Error',
                        html: 'Error while retrieving SIMAS Form Data',
                        confirmButtonText: 'OK',
                    }) 
                }
            });
        });
    }

    handleFileUploadClick = (e) =>{
        document.getElementById('hiddenFileInput').click();
    }

    fileSelectedHandler = (event) => {
        this.setState({
            selectedFile: event.target.files[0]
        }, () => this.fileReader());
    };

    fileInputError = (errorMsg) => {
        this.setState({
            selectedFile: null,
            fileJsonConverted: null,
            totalRecords: 0,
            currentPage: 0,
            totalPages: 0,
            simAttributeData: null,
            imsiAttributeData: null,
        }, () => swal.fire({
            type: 'error',
            title: 'Invalid File',
            html: errorMsg,
            confirmButtonText: 'OK',
        }));
    };

    fileReader() {
        let reader = new FileReader();

        reader.onload = (e) => {
            let data = e.target.result;
            data = new Uint8Array(data);
            let workbook = XLSX.read(data, {type: 'array'});
            let result = {};
            
            if (workbook.SheetNames.includes("Sheet1")) {
                workbook.SheetNames.forEach((sheetName) => {
                    if (sheetName === 'Sheet1'){
                        let roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1});
                        if (roa && roa.length) {
                            result[sheetName] = roa;
                            this.setState({
                                ...this.state,
                                fileJsonConverted: result,
                                totalRecords: result.Sheet1.length - 1,
                                totalPages: Math.ceil((result.Sheet1.length - 1) / 10),
                                currentPage: 0,
                            }, () => this.validateData());
                        } else {
                            this.fileInputError('Provided file is empty');
                        }
                    }
                });
            } else {
                this.fileInputError('Provided file does not include sheet: Sheet1');
            }
        }
        reader.readAsArrayBuffer(this.state.selectedFile);
    }

    validateData = () => {
        let valid = false;
        if (this.state.fileJsonConverted.Sheet1[0][0] && this.state.fileJsonConverted.Sheet1[0][1]) {
            if (this.state.fileJsonConverted.Sheet1[0][0] === 'SIM' && 
                this.state.fileJsonConverted.Sheet1[0][1] === 'IMSI' &&
                this.state.fileJsonConverted.Sheet1.length > 1) valid = true;
        }
        valid ? console.log(this.state.fileJsonConverted) : this.fileInputError('Provided file is invalid, must include correct headers: Sim & IMSI as well as at least 1 row of data')
    }

    gotoPage = (pageNum) => {
        this.setState({
            ...this.state,
            currentPage: pageNum
        });
    }

    render() {
        return (
            <Fragment>
                <strong>SIMaS Insert</strong><br/>
                <div style={{"textAlign":"right"}}>
                    <Button className="btn btn-upload" 
                            style={{"background" : "none !important", "border" : "none !important", "color" : "#1ba1e2"}}
                            onClick={this.handleFileUploadClick}>
                        <i class="fa fa-file-excel-o fa-3x" aria-hidden="true"></i>
                    </Button>
                    { this.state.fileJsonConverted && <h6 color="darkgray">Count: {this.state.totalRecords}</h6>}
                </div>
                <input
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    id="hiddenFileInput"
                    onChange={this.fileSelectedHandler}
                    onClick={(event)=> { 
                        event.target.value = ""
                        this.setState({selectedFile : ''})
                    }}
                    style={{display: 'none'}}
                /> 
                { this.state.fileJsonConverted &&
                    <Fragment>
                        <div style={{"maxWidth":"75%","margin":"0 auto"}}>
                            <SimasTable tableData={this.state.fileJsonConverted.Sheet1.slice(1)} page={this.state.currentPage}/> 
                            <PaginationController
                            totalPages={this.state.totalPages}
                            gotoPage={this.gotoPage}/>
                        </div>
                        {this.props.simSuccess && this.props.imsiSuccess && <SimasForm tableData={this.state.fileJsonConverted.Sheet1.slice(1)}/>}
                    </Fragment>
                }
            </Fragment> 
        )
    }
}

const mapStateToProps = (state) => {
    return {
        simPending: state.tdmOps.isSimFetchPending,
        simError: state.tdmOps.isSimFetchError,
        simSuccess: state.tdmOps.isSimFetchSuccess,
        imsiPending: state.tdmOps.isImsiFetchPending,
        imsiError: state.tdmOps.isImsiFetchError,
        imsiSuccess: state.tdmOps.isImsiFetchSuccess,
    }
}

const mapDispatchToProps = dispatch => {
    return {
       fetchSimFormData: () => dispatch(fetchSimFormData()),
       fetchImsiFormData: () => dispatch(fetchImsiFormData())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimasInsert);


