import React, { Fragment } from "react";
import { Table } from 'reactstrap';
import { submit, FieldArray } from 'redux-form'
import { connect } from "react-redux";
import ReduxFormContainer from "../../../common/form/redux/ReduxFormContainer";
import PaginationController from "../../../../utils/PaginationController";
import { fetchSnrmResources,fetchSNRMIMSIResources } from "../../../../actions/dataCleanUpActions";
import TDMHeader from "../util/TDMHeading";
import TilesFrame from "../../../Data/accordion-frames/replicator/DataTilesFrame";
import RangeInput from "../util/RangeInput";
import convertFnnFormat from "../util/snrmUtils";
import TableContentLoader from "../../../common/loader/TableContentLoader";
import * as constants from "../util/constants";
import FailedView from "../util/FailedView";

class UpdateView extends React.Component {

    gotoPage = (page) => {
        const { startRange, endRange } = this.props.updateRange[0];
        if(this.props.selectedFilters.snrmResourceType !== "SIM/IMSI")
            this.props.fetchResources({ startRange, endRange, page });
        else
        {this.props.fetchSNRMIMSIResources({ startRange, endRange, page });}
    }

    handleOnSearch = () => {
        let fnnConvert = this.props.selectedFilters.snrmResourceType === 'FNN' ? true : false;
        let selectedIMSI = this.props.selectedFilters.snrmResourceType === "SIM/IMSI" ? true : false;
        this.props.selectSnrmUpdateType({ label: null })
        this.props.onSearchClick(constants.SNRM_UPDATE_FORM);
        let errors = this.props.syncErrors.filter((data) => {
            return data !== null;
        })
        if (errors.length === 0) {
            const { startRange, endRange } = this.props.updateRange[0];
            const { finalStartRange, finalEndRange } = convertFnnFormat(fnnConvert, startRange, endRange);
            if(!selectedIMSI)
                this.props.fetchResources({ startRange: finalStartRange, endRange: finalEndRange, fromSearch: true });
            else
            {
                this.props.fetchSNRMIMSIResources({startRange: finalStartRange, endRange: finalEndRange, fromSearch: true})
            }
        }
    }
    render() {
        const { snrmSIMStatus,snrmStatus, snrmIMSIStatus,fetchPending, fetchFailed, filters, snrmUpdateType, selectSnrmUpdateType,selectSnrmIMSIUpdateType,selectSnrmSIMUpdateType } = this.props;
        const { snrmResourceType, cleanUpType } = this.props.selectedFilters;
        const { statusFilters,statusIMSIFilters,statusIMSI_SIMFilters, statusFiltersFnn } = filters;
        const { isFirst, isLast, totalPages, currentPage, content = [], passdownprops, success, totalItems, currentPageSize, searchRequest } = this.props.resources;
        const asyncBlurFields = ["ranges[].startRange", "ranges[].endRange"];
        return (
            <div>
                <TDMHeader heading="Enter Range (Only For Hybrid SIMs)" />
                <ReduxFormContainer
                    initialValues={constants.INITIAL_VALUE}
                    formName={constants.SNRM_UPDATE_FORM}
                    onSubmit={() => { }}
                    asyncBlurFields={asyncBlurFields}
                    propsDepth={4}
                    snrmResourceType={snrmResourceType}
                    cleanUpType={cleanUpType}
                >
                    <FieldArray
                        assignProps
                        name="ranges"
                        passdownprops={passdownprops}
                        handlePrimary={this.handleOnSearch}
                        primaryButton={"Search"}
                        serviceIdField={{}}
                        component={RangeInput}
                    />
                </ReduxFormContainer>
                {
                    fetchPending && <TableContentLoader />
                }
                {fetchFailed && <FailedView message={"Could not load Numbers"} />}
                {success && content.length === 0
                    && <div id="empty-content">
                        <i className="fa fa-exclamation-circle fa-2x"></i>
                        <span>No Numbers Available for this Range.</span>

                    </div>}
                {content.length > 0 && snrmResourceType !== "SIM/IMSI" &&   
                    <React.Fragment>
                        <div id="resource-table">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>S.No </th>
                                        <th>Resource Value </th>
                                        <th>Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        content.map((resource, index) => {
                                            return (
                                                <tr key={((currentPage - 1) * currentPageSize ) + (index+1)}>
                                                    <td style={{ fontWeight: "bold" }}>
                                                        <span className={`gradient-icon base-default`}>
                                                            <i className={`td-icon-sm icon-manage-services theme-text-primary `}></i>
                                                        </span>
                                                        {((currentPage - 1) * currentPageSize ) + (index+1)}
                                                    </td>
                                                    <td>{resource.resourceValue}</td>
                                                    <td>
                                                        <span className={`gradient-icon ${snrmUpdateType.filter(data => data.label === resource.statusName).length > 0 && snrmUpdateType.filter(data => data.label === resource.statusName)[0].color}`}>
                                                            <i className={`td-icon-sm ${snrmUpdateType.filter(data => data.label === resource.statusName).length > 0 && snrmUpdateType.filter(data => data.label === resource.statusName)[0].icon} theme-text-primary`}></i>
                                                        </span>
                                                        {resource.resourceStatus}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </React.Fragment>
                }
                {content.length > 0 && snrmResourceType == "SIM/IMSI" &&   
                    <React.Fragment>
                        <div id="resource-table">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>S.No </th>
                                        <th>IMSI </th>
                                        <th>IMSI Status</th>
                                        <th>SIM </th>
                                        <th>SIM Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        content.map((resource, index) => {
                                            return (
                                                <tr key={((currentPage - 1) * currentPageSize ) + (index+1)}>
                                                    <td style={{ fontWeight: "bold" }}>
                                                        <span className={`gradient-icon base-default`}>
                                                            <i className={`td-icon-sm icon-manage-services theme-text-primary `}></i>
                                                        </span>
                                                        {((currentPage - 1) * currentPageSize ) + (index+1)}
                                                    </td>
                                                    <td>{resource.imsi}</td>
                                                    <td>
                                                        <span className={`gradient-icon ${snrmUpdateType.filter(data => data.label === resource.imsiStatus).length > 0 && snrmUpdateType.filter(data => data.label === resource.imsiStatus)[0].color}`}>
                                                            <i className={`td-icon-sm ${snrmUpdateType.filter(data => data.label === resource.imsiStatus).length > 0 && snrmUpdateType.filter(data => data.label === resource.imsiStatus)[0].icon} theme-text-primary`}></i>
                                                        </span>
                                                        {resource.imsiStatus}
                                                    </td>
                                                    <td>{resource.sim}</td>
                                                    <td>
                                                        <span className={`gradient-icon ${snrmUpdateType.filter(data => data.label === resource.simStatus).length > 0 && snrmUpdateType.filter(data => data.label === resource.simStatus)[0].color}`}>
                                                            <i className={`td-icon-sm ${snrmUpdateType.filter(data => data.label === resource.simStatus).length > 0 && snrmUpdateType.filter(data => data.label === resource.simStatus)[0].icon} theme-text-primary`}></i>
                                                        </span>
                                                        {resource.simStatus}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </React.Fragment>
                }
                {
                    (fetchPending || success && content.length > 0) &&
                    <PaginationController
                        isClearPagination={searchRequest ? searchRequest : false}
                        totalPages={totalPages}
                        gotoPage={this.gotoPage}
                    />
                }
                {
                    content.length > 0 && snrmResourceType !== "SIM/IMSI" && snrmResourceType !== "FNN" &&
                    <TilesFrame
                        heading={"Resource Status"}
                        color="magenta"
                        data={statusFilters}
                        select={selectSnrmUpdateType}
                        selected={snrmStatus}
                    />
                }
                {
                    content.length > 0 && snrmResourceType === "FNN" &&
                    <TilesFrame
                        heading={"Resource Status"}
                        color="magenta"
                        data={statusFiltersFnn}
                        select={selectSnrmUpdateType}
                        selected={snrmStatus}
                    />
                }
                {
                    content.length > 0 && snrmResourceType == "SIM/IMSI" &&
                    <Fragment>

                        <TilesFrame
                            heading={"IMSI Status"}
                            color="magenta"
                            data={statusIMSIFilters}
                            select={selectSnrmIMSIUpdateType}
                            selected={snrmIMSIStatus}
                        />
                        <TilesFrame
                            heading={"SIM Status"}
                            color="purple"
                            data={statusIMSI_SIMFilters}
                            select={selectSnrmSIMUpdateType}
                            selected={snrmSIMStatus}
                        />
                    </Fragment>

                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        selectedFilters: state.cleanup && state.cleanup.selectedFilters,
        resources: state.cleanup.snrm.resources || {},
        syncErrors: state.form && state.form[constants.SNRM_UPDATE_FORM] && state.form[constants.SNRM_UPDATE_FORM].syncErrors && state.form[constants.SNRM_UPDATE_FORM].syncErrors.ranges || [],
        fetchPending: state.cleanup.snrm.fetchPending,
        fetchFailed: state.cleanup.snrm.fetchFailed,
        updateRange: state.form
            && state.form[constants.SNRM_UPDATE_FORM]
            && state.form[constants.SNRM_UPDATE_FORM].values
            && state.form[constants.SNRM_UPDATE_FORM].values.ranges
            || [],
        filters: state.cleanup.filters
    };
};
const mapDispatchToProps = dispatch => {
    return {
        fetchResources: (pageNum) => dispatch(fetchSnrmResources(pageNum)),
        fetchSNRMIMSIResources: (pageNum) => dispatch(fetchSNRMIMSIResources(pageNum)),
        onSearchClick: (formName) => { dispatch(submit(formName)) },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateView);