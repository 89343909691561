import React from "react";
import _ from "lodash";
import { generateServiceId } from "../../actions/replicatorActions";
import { connect } from "react-redux";
import { Field, change, arrayRemove } from "redux-form";
import DropdownComponent from "./DropdownComponent";
import { renderField } from "../common/fields/fields";
import Switch from '@material-ui/core/Switch'
import {
  adborIdValidator,
  accountNumberValidator,
  addressValidator,
  fnnCosmosValidator,
  fnnCosmosServiceValidator,
  servicesTiptValidator,
  eligibilityReasonValidation,
  extensionFromValidator,
  extensionToValidator,
  linkedCidnValidator
} from "../common/form/fieldValidators";
import "./Product.css";

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


class CosmosTIPT extends React.Component {
  state = {
    autoGeneratedRows: [],
    numRows: 0,
    dlc: [{ value: false, index: 0 }],
    telstraOneNumber: false,
    groupEligibility: true,
    productPlanAndTypes: "",
    numberRepaymentRows: [],
    groupNumRows: 0,
    elgibilityError: ""
  };

  groupEligibility = (field) => {
    return (
      <Switch checked={field.input.value} onChange={field.input.onChange} icons={false} />)
  }

  addGroups = (index, fields) => {
    let groupRow = [];
    if (fields.get(index).groupRows) {
      groupRow = fields.get(index).groupRows;
    }
    groupRow.push({ cidn: fields.get(index).cidn, adborId: fields.get(index).adborId, accountNumber: fields.get(index).acntNumber, address: fields.get(index).address, groupEligibilityReason: "", role: "group", index: index, groupEligibility: true, groupIndex: groupRow.length, serviceRows: [], showServices: false, inflightOrder: null })
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `tiptSipcProduct[${index}].showDeviceType`, true
      ))
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `tiptSipcProduct[${index}].groupRows`, groupRow
      )
    );
  };

  addServices = (index, number, fields) => {
    let serviceRow = [];
    if (fields.get(index).groupRows[number].serviceRows)
      serviceRow = fields.get(index).groupRows[number].serviceRows
    serviceRow.push({ siptCount: null, adborId: fields.get(index).adborId, cidn: fields.get(index).cidn,accountNumber: fields.get(index).acntNumber, address: fields.get(index).address, role: "service", index: index, number: number, serviceIndex: serviceRow.length, serviceExtension: [], showServiceExtensions: false })
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `tiptSipcProduct[${index}].groupRows[${number}].showServices`, true
      ))
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `tiptSipcProduct[${index}].groupRows[${number}].serviceRows`, serviceRow
      )
    );
  }
  addServicesExtensions = (index, number, serviceNumber, fields) => {
    let extensionRow = [];
    if (fields.get(index).groupRows[number].serviceRows[serviceNumber].serviceExtension) {
      extensionRow = fields.get(index).groupRows[number].serviceRows[serviceNumber].serviceExtension;
    }
    extensionRow.push({ extIndex: extensionRow.length, extensionFrom: "", extensionTo: "" })
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `tiptSipcProduct[${index}].groupRows[${number}].serviceRows[${serviceNumber}]showServiceExtensions`, true
      ))
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `tiptSipcProduct[${index}].groupRows[${number}].serviceRows[${serviceNumber}]serviceExtension`, extensionRow
      ))
  }
  removeGroups = (e, index, fields) => {
    let groupRow = [...fields.get(index).groupRows];
    let rowIndex = Number(e.target.id);
    let rowsRemaining = groupRow.filter((data) => rowIndex !== Number(data.groupIndex))
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `tiptSipcProduct[${index}].groupRows`, rowsRemaining
      ))

  }
  removeServices = (e, index, number, fields) => {
    let serviceRow = [...fields.get(index).groupRows[number].serviceRows]
    let serviceRowIndex = Number(e.target.id);
    let rowsRemaining = serviceRow.filter((data) => serviceRowIndex !== Number(data.serviceIndex));
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `tiptSipcProduct[${index}].groupRows[${number}].serviceRows`, rowsRemaining
      )
    );
  }
  removeServiceExtensions = (e, index, number, extIndex, fields) => {
    let extRows = [...fields.get(index).groupRows[number].serviceRows[extIndex].serviceExtension]
    let rowIndex = Number(e.target.id);
    let rowsRemaining = extRows.filter((data) => rowIndex !== Number(data.extIndex));
    this.props.passdownprops.dispatch(
      change(this.props.passdownprops.formName, `tiptSipcProduct[${index}].groupRows[${number}].serviceRows[${extIndex}]serviceExtension`, rowsRemaining
      ))
  }
  handleRole = (e, t, f) => {
    console.log(e)
  }
  handleRowChange = (numRows, newNumRows, fields, replicatorFilters) => {
    this.setState(preState => ({ ...preState, numRows: newNumRows }));
    let diff = newNumRows - numRows;
    if (diff > 0) {
      for (let i = 0; i < diff; i++)
        fields.push({
          cidn: replicatorFilters.cidn, adborId: null, acntNumber: null, groupRows: [], address: null, fnn: null, inflightOrder: false, role: "enterprise",
        });
    } else if (diff < 0) {
      diff *= -1;

      const limit = fields.length - diff - 1;
      for (let i = 0; i < diff; i++) fields.pop();
      this.setState(preState => {
        return {
          ...preState,
          autoGeneratedRows: preState.autoGeneratedRows.filter(
            data => data <= limit
          )
        };
      });
    }
  };


  render() {
    const { quantity } = this.props;
    const { fields, passdownprops, productType, replicatorFilters } = this.props;
    return (
      <div class="container">
        <div class="row">
          <div class="col-10 col-lg"><strong>Product: {productType.toUpperCase()}</strong></div>
          <div class="col-2">
            <div class="row"><div class="col-sm"><strong>Quantity</strong> </div>
              <div class="col-sm"> <span id="qty-dropdown" className="mobile-dorpdown">
                <DropdownComponent
                  options={quantity}
                  selectInput={qty =>
                    this.handleRowChange(fields.length, qty, fields, replicatorFilters)
                  }
                  length={fields.length}
                />
              </span></div>
            </div></div>
        </div>
        {fields.map((tiptSipcProduct, index) => {
          return (<div class="container">
            <div class="row">
              <strong>Enterprise: {index}</strong>
            </div>
            <div class="container" id="EnterprisePanel">
              <div class="row enterpriseHeader ml-n5" id="EnterpriseHeader">
                <div class="col-2 "><strong>CIDN</strong></div>
                <div class="col-2 "><strong>AdborID</strong></div>
                <div class="col-2 "><strong>AccountNo</strong></div>
                <div class="col-2"><strong>Address</strong></div>
                <div class="col-2 "><strong>FNN</strong></div>
                <div class="col-1 "><strong>Inflight</strong></div>
                <div class="col-1 "><strong>Add Groups</strong></div></div>

              <div class="row enterpriseData ml-n5" id="EnterpriseData">
                <div class="col-2 col-sm-2">
                  <Field
                    name={`tiptSipcProduct[${index}].cidn`}
                    component={renderField}
                    tabIndex={index}
                    touched={true}
                    invalid={this.props.errorMessage}
                    type={"textWithCustomStyles"}
                    index={index}
                    showValidMsg={false}
                    validate={linkedCidnValidator}
                    disabled={
                      passdownprops.asyncValidating
                    }
                    defaultValue={this.props.replicatorFilters.cidn}
                  /></div>
                <div class="col-2 col-sm-2">
                  <Field
                    name={`tiptSipcProduct[${index}].adborId`}
                    customDiv="adborFnnTableInputDiv"
                    customStyleClassName="adborId"
                    tabIndex={index}
                    disabled={
                      passdownprops.asyncValidating ||
                      this.state.autoGeneratedRows.includes(index)
                    }
                    component={renderField}
                    validate={adborIdValidator}
                    touched={true}
                    invalid={this.props.errorMessage}
                    type={"textWithCustomStyles"}
                    showValidMsg={false}
                    autoComplete="off"
                  />
                </div>
                <div class="col-2 col-sm-2">
                  <Field
                    name={`tiptSipcProduct[${index}].acntNumber`}
                    component={renderField}
                    tabIndex={index}
                    touched={true}
                    invalid={this.props.errorMessage}
                    type={"textWithCustomStyles"}
                    index={index}
                    showValidMsg={false}
                    validate={accountNumberValidator}
                    disabled={
                      passdownprops.asyncValidating
                    }
                  /></div>
                <div class="col-2 col-sm-2">
                  <Field
                    name={`tiptSipcProduct[${index}].address`}
                    component={renderField}
                    tabIndex={index}
                    touched={true}
                    invalid={this.props.errorMessage}
                    type={"textWithCustomStyles"}
                    index={index}
                    showValidMsg={false}
                    validate={addressValidator}
                    disabled={
                      passdownprops.asyncValidating
                    }
                  />
                </div>
                <div class="col-2 col-sm-2">
                  <Field
                    name={`tiptSipcProduct[${index}].fnn`}
                    customDiv="adborFnnTableInputDiv"
                    customStyleClassName="fnn"
                    tabIndex={index}
                    disabled={
                      passdownprops.asyncValidating ||
                      this.state.autoGeneratedRows.includes(index)
                    }
                    component={renderField}
                    validate={fnnCosmosValidator}
                    touched={true}
                    invalid={this.props.errorMessage}
                    type={"textWithCustomStyles"}
                    showValidMsg={false}
                    autoComplete="off"
                  />
                </div>
                <div class="col-1"><Field
                  name={`tiptSipcProduct[${index}].inflightOrder`}
                  component={renderField}
                  tabIndex={index}
                  touched={true}
                  invalid={this.props.errorMessage}
                  type={"checkbox"}
                  index={index}
                  showValidMsg={false}
                  showIcon={fields.get(index).inflightOrder}
                /></div>
                <div class="col-1 col-sm-1">
                  <div className="fa fa-plus fa-sm addRepayments" aria-hidden="true" onClick={(e) => this.addGroups(index, fields)}></div>
                </div>
 
                {fields.get(index).groupRows &&
                  fields.get(index).groupRows.map((item, number) => {
                    return (
                      <div class="container" id="GroupPanel">
                        <div class="row"><strong>Group: {item.groupIndex}</strong></div>
                        {fields.get(index).showDeviceType &&
                          <div class="container">
                            <div class="row groupHeader" id="GroupHeader">
                              <div class="col-sm-2"><strong>CIDN</strong></div>
                              <div class="col-sm-2"><strong>FNN</strong></div>
                              <div class="col-sm-2"><strong>Group Eligibile</strong></div>
                              <div class="col-sm-2"><strong>Eligibility Reason</strong></div>
                              <div class="col-sm-1" align="center"><strong>Inflight</strong></div>
                              <div class="col-sm-2"><strong>Add Service</strong></div>
                              <div class="col-sm-1"><div className="fa fa-minus fa-sm removeRepayments " aria-hidden="true" id={number} onClick={(e) => this.removeGroups(e, index, fields)} /></div>
                            </div>
                            <div class="row groupData" id="groupData">
                            <div class="col-sm-2">
                                <Field
                                  name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].cidn`}
                                  type="text"
                                  autoComplete="off"
                                  component={renderField}
                                  touched={true}
                                  invalid={this.props.errorMessage}
                                  index={index}
                                  showValidMsg={false}
                                  validate={linkedCidnValidator}
                                  defaultValue={this.props.replicatorFilters.cidn} />
                              </div>
                              <div class="col-sm-2">
                                <Field
                                  name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].fnn`}
                                  type="text"
                                  autoComplete="off"
                                  component={renderField}
                                  touched={true}
                                  invalid={this.props.errorMessage}
                                  index={index}
                                  showValidMsg={false}
                                  validate={fnnCosmosValidator} />
                              </div>
                              <div class="col-sm-1 mt-5" align="center">
                                <Field
                                  name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].groupEligibility`}
                                  id={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].groupEligibility`}
                                  component={this.groupEligibility}
                                />
                              </div>
                              <div class="col-sm-3 ml-5">
                                <Field
                                  name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].groupEligibilityReason`}
                                  type="text"
                                  autoComplete="off"
                                  component={renderField}
                                  touched={true}
                                  invalid={this.props.errorMessage}
                                  index={index}
                                  showValidMsg={false}
                                  validate={item.groupEligibility ? '' : eligibilityReasonValidation}
                                  disabled={item.groupEligibility} />
                              </div>
                              <div class="col-sm-1 mt-5">
                                <Field
                                  name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].inflightOrder`}
                                  type={"checkbox"}
                                  component={renderField}
                                  touched={true}
                                  index={index}
                                  showIcon={fields.get(index).inflightOrder} />
                              </div>
                              <div class="col-sm-1 mt-5 ml-1">
                                <div className="fa fa-plus fa-sm addRepayments"
                                  aria-hidden="true" onClick={(e) => this.addServices(index, number, fields)} />
                              </div>
                              <div class="col-sm-1"/>
                              {fields.get(index).groupRows && fields.get(index).groupRows[number].serviceRows &&
                                fields.get(index).groupRows[number].serviceRows.map((service, serviceNumber) => {
                                  return (
                                    <div class="container" id="ServicePanel">
                                      <div class="row"><strong>Service: {service.serviceIndex}</strong></div>
                                      {fields.get(index).groupRows[number].showServices &&
                                        <div class="container">
                                          <div class="row serviceHeader" id="serviceHeader">
                                            <div class="col-sm-2"><strong>CIDN</strong></div>
                                            <div class="col-sm-2"><strong>FNN</strong></div>
                                            <div class="col-sm-2"><strong>TIPTCount</strong></div>
                                            <div class="col-sm-2"><strong>SIPTCount</strong></div>
                                            <div class="col-sm-1" align="center"><strong>InFlight</strong></div>
                                            <div class="col-sm-2"><strong>Add Service Extensions</strong> </div>
                                            <div class="col-sm-1" align="center"><div className="fa fa-minus fa-sm removeRepayments " aria-hidden="true" id={service.serviceIndex} onClick={(e) => this.removeServices(e, index, number, fields)} /></div>
                                          </div>
                                          <div class="row groupData" id="groupData">
                                          <div class="col-sm-2">
                                              <Field name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].serviceRows[${service.serviceIndex}].cidn`}
                                                type="text"
                                                autoComplete="off"
                                                component={renderField}
                                                touched={true}
                                                invalid={this.props.errorMessage}
                                                index={index}
                                                showValidMsg={false}
                                                validate={linkedCidnValidator}
                                                defaultValue={this.props.replicatorFilters.cidn} />
                                            </div>
                                            <div class="col-sm-2">
                                              <Field name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].serviceRows[${service.serviceIndex}].fnn`}
                                                type="text"
                                                autoComplete="off"
                                                component={renderField}
                                                touched={true}
                                                invalid={this.props.errorMessage}
                                                index={index}
                                                showValidMsg={false}
                                                validate={fnnCosmosServiceValidator} />
                                            </div> 
                                            <div class="col-sm-2">
                                              <Field name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].serviceRows[${service.serviceIndex}].tiptCount`}
                                                type="text"
                                                autoComplete="off"
                                                component={renderField}
                                                touched={true}
                                                invalid={this.props.errorMessage}
                                                index={index}
                                                showValidMsg={false}
                                                validate={servicesTiptValidator} />
                                            </div>
                                            <div class="col-sm-2">
                                              <Field name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].serviceRows[${service.serviceIndex}].siptCount`}
                                                type="text"
                                                autoComplete="off"
                                                component={renderField}
                                                index={index} />
                                            </div>
                                            <div class="col-sm-1 mt-5">
                                              <Field
                                                name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].serviceRows[${service.serviceIndex}].inflightOrder`}
                                                component={renderField}
                                                tabIndex={index}
                                                touched={true}
                                                invalid={this.props.errorMessage}
                                                type={"checkbox"}
                                                index={index}
                                                showValidMsg={false}
                                                showIcon={fields.get(index).groupRows[number].serviceRows.inflightOrder}
                                              />
                                            </div>
                                            <div class="col-sm-1"></div>
                                            <div class="col-sm-1 mt-5 ml-n5">
                                              <div className="fa fa-plus fa-sm addRepayments"
                                                aria-hidden="true" onClick={(e) => this.addServicesExtensions(index, number, serviceNumber, fields)} />
                                            </div>
                                            <div class="col-sm-2"></div>
                                            {/* service extensions */}
                                            {fields.get(index).groupRows && fields.get(index).groupRows[number].serviceRows &&
                                              fields.get(index).groupRows[number].serviceRows[serviceNumber].serviceExtension &&
                                              fields.get(index).groupRows[number].serviceRows[serviceNumber].serviceExtension.map((extension, extIndex) => {
                                                return (
                                                  <div class="container" id="ServiceExtensionPanel">
                                                    <div class="row"><strong>Service Extension: {extension.extIndex}</strong></div>
                                                    {fields.get(index).groupRows[number].serviceRows[serviceNumber].showServiceExtensions &&
                                                      <div class="container">
                                                        <div class="row extHeader" id="serviceExtHeader">
                                                          <div class="col-sm-1"></div>
                                                          <div class="col-sm-2"><strong>From</strong></div>
                                                          <div class="col-sm-2"><strong>To</strong></div>
                                                          <div class="col-sm-1"><div className="fa fa-minus fa-sm removeRepayments " aria-hidden="true" id={extension.extIndex} onClick={(e) => this.removeServiceExtensions(e, index, number, serviceNumber, fields)} /></div>
                                                        </div>
                                                        <div class="row groupData" id="groupData">
                                                          <div class="col-sm-1"></div>
                                                          <div class="col-sm-2">
                                                            <Field name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].serviceRows[${service.serviceIndex}].serviceExtension[${extension.extIndex}].extensionFrom`}
                                                              type="text"
                                                              autoComplete="off"
                                                              component={renderField}
                                                              touched={true}
                                                              invalid={this.props.errorMessage}
                                                              index={index}
                                                              showValidMsg={false}
                                                              validate={extensionFromValidator} />
                                                          </div>
                                                          <div class="col-sm-2">
                                                            <Field name={`tiptSipcProduct[${index}].groupRows[${item.groupIndex}].serviceRows[${service.serviceIndex}].serviceExtension[${extension.extIndex}].extensionTo`}
                                                              type="text"
                                                              autoComplete="off"
                                                              component={renderField}
                                                              touched={true}
                                                              invalid={this.props.errorMessage}
                                                              index={index}
                                                              showValidMsg={false}
                                                              validate={extensionToValidator} />
                                                          </div>


                                                        </div></div>}
                                                  </div>)
                                              }
                                              )}</div>
                                        </div>}
                                    </div>)
                                })}</div></div>}
                      </div>)
                  })}
              </div></div>
          </div>)
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    quantity: state.replicatorData.options.quantity,
    productPlanNames: state.replicatorData.options.productPlanNames,
    cosmosPlans: state.replicatorData.cosmos.plans,
    cosmosRepayments: state.replicatorData.cosmos.repayments,
    replicatorFilters: state.replicatorData.selectedFilters
  };
};

const mapDispatchToProps = dispatch => {
  return {
    generateServiceId: (qty, index) => dispatch(generateServiceId(qty, index)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CosmosTIPT);