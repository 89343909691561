import axios from 'axios';
import * as types from '../../../actions/actionTypes';
import config from '../../../config';
import { authHeaders } from '../../../utils/api';
import swal from 'sweetalert2';
import { displayLoading } from "../../common/alerts/alerts";

export const createUsageFileAction = (request) => async (dispatch) => {
    displayLoading({ title: "Create Usage File", bodyText: "Please standby" });
    try {
        const response = await axios.post(`${config.tdm_gateway_api.uri}/tdm/aria-billing/aria/billing/process`, request, { headers: authHeaders() });
        swal.close();
        if (response.data.responseStatus === "Success") {
            dispatch({ type: types.HANDLE_OCS_STATUS, payload: true });
            swal.fire(response.data.responseDescription, `Request ID: ${response.data.requestId}`, "success")
        } else if (response.data.responseStatus === "Fail") {
            swal.fire("Error", response.data.message)
        }
    } catch (error) {
        swal.fire("Error", "network error")
    }
}

export const getBillStatus = (request, prevContent = []) => async (dispatch) => {
    const { team, pageNumber, pageSize } = request;
    dispatch({ type: types.HANDLE_LOADER, payload: true });
    dispatch({ type: types.HANDLE_OCS_STATUS, payload: false });
    try {
        const response = await axios.get(`${config.tdm_gateway_api.uri}/tdm/aria-billing/aria/billing/process/details?team=${team}&pageNumber=${pageNumber}&pageSize=${pageSize}`, { headers: authHeaders() });
        dispatch({ type: types.HANDLE_LOADER, payload: false });
        dispatch({ type: types.GET_OCS_FILE, payload: [...prevContent, ...response.data.content], payload2: { number: response.data.number, totalElements: response.data.totalElements } });
    } catch (error) {
        dispatch({ type: types.HANDLE_LOADER, payload: false });
        swal.fire("Error", "network error")
    }
}