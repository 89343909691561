import {axiosTaasInstance as axios} from "../axios.config";
import * as types from './actionTypes';
import config from '../config';
import { authHeaders } from '../utils/api';
const API_URL_GATEWAY = config.tdm_gateway_api.uri;

export const fetchQuarter = () => async dispatch => {
  dispatch({ type: types.LOAD_QUARTER_LOADING, loading: true });
  try {
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/pasmat/calender/quarters`, { headers: authHeaders() });
    dispatch({ type: types.LOAD_QUARTER_SUCCESS, payload: response.data.quarters });
    const length = (response.data.quarters ? response.data.quarters.length : 0)
    return response.data.quarters[length-1];
  }
  catch (e) {
    console.log(e)
    return false;
  }
}


export const fetchPasmatSurvey = (userId, teamId,period) => async dispatch => {

  dispatch({ type: types.LOAD_PASMAT_LOADING, loading: true });
  try {
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/pasmat/survey/fetch?userId=${userId}&teamId=${teamId}&period=QUARTER&value=${period}`, { headers: authHeaders() });
    dispatch({ type: types.LOAD_PASMAT_SUCCESS, payload: response.data });
    dispatch({ type: types.LOAD_PASMAT_LOADING, loading: false });
    if (response.data)
      return true;
    else
      return false
  } catch (e) {
    console.error(e);
    dispatch({ type: types.LOAD_PASMAT_FAILED, loading: false });
  }
  return false;
}

export const fetchMetrics = (userId, teamId, period) => async dispatch => {

  dispatch({ type: types.LOAD_QUAL_METRIC_LOADING, loading: true });
  try {
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/pasmat/metric/fetch?userId=${userId}&teamId=${teamId}&period=QUARTER&value=${period}`, { headers: authHeaders() });
    dispatch({ type: types.LOAD_QUAL_METRIC_SUCCESS, payload: response.data });
    dispatch({ type: types.LOAD_QUAL_METRIC_LOADING, loading: false });

  } catch (e) {
    console.error(e);
  }
  return false;
}

export const submitFeedback = (data) => async dispatch => {
  const response = await axios.post(`${API_URL_GATEWAY}/tdm/pasmat/feedback/save`, { ...data }, { headers: authHeaders() });
  if (response.status == 200)
    return true;
  else { return false }
}

export const showSurvey = (data) => async dispatch => {
  dispatch({ type: types.DISPLAY_REPORT, payload: data });
}

export const setTeams = (teams, streamMaturity) => async dispatch => {
  dispatch({ type: types.GRAPH_TEAMS, payload: teams, streamMaturity: streamMaturity });
}

export const fetchTrends = (period, periodValue) => async dispatch => {
  dispatch({ type: types.SET_TRENDTYPE, payload: period})
  if (period === "Quarter") {
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/pasmat/trendanalysis/fetch?period=QUARTER&value=${periodValue}`, { headers: authHeaders() });
    if (response && response.data && response.data.trend_analysis_data) {
      dispatch({ type: types.FETCH_TRENDS, payload: response.data.trend_analysis_data })
      return response.data.trend_analysis_data
    }
  }
  else {
     const response = await axios.get(`${API_URL_GATEWAY}/tdm/pasmat/trendanalysis/fetch/periods?year=${periodValue}`, { headers: authHeaders() });
    dispatch({ type: types.FETCH_YTD_TRENDS, payload: response.data })
    return response.data

  }
}

export const setParentProgram = (parent) => async dispatch => {
  dispatch({ type: types.SET_PROGRAMS, payload: parent })
}


export const setGraphTeam = (team) => async dispatch => {
  dispatch({ type: types.SET_GRAPH_TEAM, payload: team })
}
