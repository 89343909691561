import axios from "axios";
import {axiosTaasInstance as axiosWithIntercept} from "../axios.config";
import * as types from './actionTypes';
import config from "../config";
import { authHeaders, taasAuthSigninHeaders } from "../utils/api";
import {signout} from "./authActions";
import { isTaasTokenValid } from "../components/auth/functions";

const GATEWAY_URL = config.tdm_gateway_api.uri;
const REDIRECT_URL = config.redirectUrl;
const TAAS_SSO_URL = config.taas_sso_url;
const TAAS_USER_BASE_URL = config.taas_user_base;
const TAAS_OAUTH_URL = config.taas_oauth;

export const checkUserStatus = (taasAccessToken) => async dispatch =>{
    try {
      dispatch({ type: types.SSO_CHECK_TOKEN_LOADING, payload: true});
      const response = await axios.get(`${GATEWAY_URL}/tdm/user/auth/taas/userStatus?token=${taasAccessToken}`);
      dispatch({ type: types.SSO_AUTH_VALIDATE_RESPONSE, payload: response.data });
      dispatch({ type: types.SSO_AUTH_TOKEN, payload: taasAccessToken });
      dispatch({ type: types.SSO_CHECK_TOKEN_LOADING, payload: false});
      return response.data;
    } catch (e) {
        console.log(e)
        let message;
        if(e.response && e.response.data && e.response.data.message){
          message = e.response.data.message;
        }
        else if(e.response &&  e.response.data && e.response.data.responseDescription)  message = e.response.data.responseDescription;
        else  message = "Network connectivity issues, please try again later"
        dispatch({ type: types.SSO_AUTH_VALIDATE_ERROR, message });
        dispatch({ type: types.SSO_CHECK_TOKEN_LOADING, payload: false});
        return false;
    }
}

export const fetchPrograms = () => async dispatch =>{
    try {
        const response = await axios.get(`${GATEWAY_URL}/tdm/data/team/fetch/programs`); 
        dispatch({ type: types.FETCH_PARENT_PROGRAMS, payload: response.data});
        return response.data;
    } catch (e) {
        let message;
        if (!e.response) {
          message = "Network connectivity issues, please try again later.";
        } else {
          message = e.response.data.responseDescription;
        }
        return message;
    }
}

export const getMyAvailableTeams = () => async dispatch => {
  try {
    const response = await axios.get(`${TAAS_USER_BASE_URL}/tools/teams?toolName=TDM`, {
      headers: authHeaders({},true)
    });
    dispatch({ type: types.AUTH_MY_TEAMS_SUCCESS, payload: response.data });
    return response.data;
  } catch (e) {
      let message;
      if (!e.response) {
        message = "Network connectivity issues, please try again later.";
      } else {
        message = e.response.data.responseDescription;
      }
      dispatch({ type: types.AUTH_MY_TEAMS_ERROR });
      return message;
  }
};

export const fetchAccountDetails = (username) => async dispatch => {
  try {
    dispatch({ type: types.ACCOUNT_DETAILS_LOADING, payload: true });
    const response = await axios.get(`${TAAS_USER_BASE_URL}/tools/users/${username}?toolName=TDM`, {
      headers: authHeaders({},true)
    });
    if (response?.status === 200 && response?.data?.workspaces) {
      dispatch({ type: types.ACCOUNT_DETAILS_FAILED, payload: false });
    } else {
      dispatch({ type: types.ACCOUNT_DETAILS_FAILED, payload: true });
    }
    return response.data;
  } catch (e) {
    let message;
    if (e?.response?.status === 404 && e?.response?.data?.status === "NOT_FOUND") {
      dispatch({ type: types.ACCOUNT_DETAILS_FAILED, payload: false });
      return {data: {workspaces: []}};
    } else if (!e.response) {
      dispatch({ type: types.ACCOUNT_DETAILS_FAILED, payload: true });
      message = "Network connectivity issues, please try again later.";
    } else {
      dispatch({ type: types.ACCOUNT_DETAILS_FAILED, payload: true });
      message = e?.response?.data?.responseDescription;
    }
    return message;
  }
}

export const saveTeamList = (teams) => async dispatch => {
  dispatch({ type: types.SAVE_TEAM_LIST, payload: teams });
}

  export const savePreferences = request => async dispatch => {
    try {
      const response = await axiosWithIntercept.post(`${GATEWAY_URL}/tdm/data/users/settings/save`, request, { headers: authHeaders() });
      return response.data;
    } catch (e) {
        let message;
        if (!e.response) {
          message = "Network connectivity issues, please try again later.";
        } else {
          message = e.response.data.message;
        }
        return message;
    }
  };

  export const fetchPreferences = userId => async dispatch => {
    try {
      dispatch({ type: types.PREFERENCES_LOADING, payload: true });
      const response = await axios.get(`${GATEWAY_URL}/tdm/data/users/settings/fetch/${userId}`);
      dispatch({ type: types.PREFERENCES_LOADING, payload: false });
      return response.data;
    } catch (e) {
      dispatch({ type: types.PREFERENCES_LOADING, payload: false });
        let message;
        if (!e.response) {
          message = "Network connectivity issues, please try again later.";
        } else {
          message = e.response.data.message;
        }
        return message;
    }
  };

export const ssoSignin = (reqBody, reqHeader) => async dispatch => {
    try {
      const taasTokenValid = await isTaasTokenValid(reqHeader);
      if (taasTokenValid.verified) {
        localStorage.removeItem('access_token');
        const params = new URLSearchParams({
          grant_type: 'urn:ietf:params:oauth:grant-type:rsa-authorization-token',
          primaryToken: reqHeader,
          filter: `[{"QECOETOOLName": "TDM", "QECOETOOLTeamId": "${reqBody.team}"}]`
        });
        const response = await axios.post(`${TAAS_OAUTH_URL}/oauth/token`, params, { headers: taasAuthSigninHeaders() });
        if (response.data && response.data.responseStatus && response.data.responseStatus.toLowerCase()==='fail') {
          let message=response.data.responseDescription;
          dispatch({ type: types.AUTH_ERROR, message });
          return false;
        }
        console.log("LOGIN RESPONSE", response.data);
        document.cookie = `authorization_token=${response.data.access_token}${window.location.hostname.endsWith(".telstra-cave.com") ? ";domain=.telstra-cave.com;path=/" : ''}`;
        dispatch({ type: types.AUTH_USER, payload: response.data.access_token });
        return true;
      } else {
        dispatch(signout());
        dispatch({ type: types.AUTH_ERROR, message:"Taas Token Invalid, Please signin again" });
        return false;
      }
    } catch (e) {
      let message;
      if (!e.response) {
        message = "Network connectivity issues, please try again later.";
      } else {
        message = e.response.data.responseDescription;
      }
      dispatch({ type: types.AUTH_ERROR, message });
      return false;
    }
  };

  export const setAuthUserToken = (token) => async dispatch => {
    dispatch({ type: types.AUTH_USER, payload: token });
  }

  export const reset = () => dispatch => {
    dispatch({ type: types.AUTH_RESET });
    dispatch({ type: types.SSO_AUTH_RESET });
  };
  export const saveTaasUserid = (id, email) => dispatch => {
    dispatch({ type: types.SAVE_TAAS_USERID, payload: {id, email} });
  };

export const setActiveStatus = (status) => dispatch => {
  dispatch({type: types.SSO_AUTH_SET_ACTIVE_STATUS, payload: status});
}


export const addHeaders = (reqHeader) => {
  return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${reqHeader}`,
      'X-Method-Type': 'METHOD_PORTAL'
  }
}

export const updateDatasetType = (payload) => dispatch => {
  dispatch({ type: types.UPDATE_DATASET_TYPE, payload });
}