import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2), 
  },
  "& .MuiAlert-icon" : {
    display : "none !important"
  },
  "& .MuiAlert-message" :{
    margin : "0 auto"
  }
},
}));

export default function MuiAlert({ title, text}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity="error">
        <AlertTitle>{title}</AlertTitle>
        <strong>{text}</strong>
      </Alert>
    </div>
  );
}