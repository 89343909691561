import React, { useState, useEffect } from "react";
import ContentBody from "@library/core/surfaces/ContentBody";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import EditIcon from '@mui/icons-material/Edit';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
    Typography,
    Box,
    Grid,
    Tooltip,
    makeStyles
} from '@material-ui/core';
import { customerFormFieldTypes, contactFormFieldTypes, serviceFormFieldTypes, dateLiterals, orderFormFieldTypes } from "./constants";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    activeIcon: (props) => ({
        color:  "#3f51b5 !important",
    }),
    inactiveIcon: (props) => ({
        color:  "#a8b0e1 !important",
    }),
    icon: (props) => ({
        fontSize: "2rem !important",
        cursor: 'pointer',
    }),
    text: {
        margin: '0px 10px',
        color: '#3f51b5', 
        display: 'inline', 
        width: '100%'
    },
    body: {
        transition: 'border-color 0.3s ease',
        '&:hover': {
            cursor: 'pointer',
            borderColor: '#212121',
        },
    },
    totalRecords:{
        margin: '0.6rem 15px -20px 0px',
        float: "right",
        fontSize: '15px',
    }
}))

const QueryBuilderWidget = (props) => {
    const [showEdit, setShowEdit] = useState(false);
    const [simpleEnglishQuery, setSimpleEnglishQuery] = useState("");

    const classes = useStyles();

    const {
        tab,
        payload,
        toggleSearchSection,
        searchSectionOpen
    } = props;

    useEffect(() => {
        createSimpleEnglishQuery();
    }, [tab, payload]);

    const customerQueryBuilder = (customerQuery) => {
        if (customerQuery) {
            return customerQuery;
        }
        const customerFilters = payload?.account?.filters;
        const createdDayCount = customerFilters?.CreatedDate?.split(':')[1] || "0";
        const dOpts = dateLiterals.queryDateOptions(createdDayCount);

        const tabQuery = {
            customer: {
                start: `${customerFilters?.[customerFormFieldTypes.vlocity_cmt__Status__c.id]?.replaceAll("'", '') || ''} ${customerFilters?.[customerFormFieldTypes.Type.id] || ''}`,
                end: `${customerFilters?.[customerFormFieldTypes.CreatedDate.id] ? 'that were created ' : ''} ${dOpts[customerFilters?.[customerFormFieldTypes.CreatedDate.id]?.split(':')[0]] || ''}`
            }
        }
        return`${_.isEmpty(customerFilters) ? 'all' : tabQuery.customer.start} Customers ${tabQuery.customer.end}`;
    }

    const contactQueryBuilder = (customerQuery) => {
        const contactFilters = {...payload?.account?.contacts?.filters, ...payload?.account?.contacts?.addlFilters};
        const contactCreatedDayCount = contactFilters?.CreatedDate?.split(':')[1] || "0";
        const dOpts = dateLiterals.queryDateOptions(contactCreatedDayCount);

        let queryValues = [];
        let query = '';
        const isNoContactFilters = _.isEmpty(contactFilters);
        const cFilters = Object.keys(contactFilters);

        queryValues.push(`${isNoContactFilters ? 'all' : contactFilters?.[contactFormFieldTypes.vlocity_cmt__Status__c.id]?.replaceAll("'", '') || ''} Contacts `);
        if (!isNoContactFilters && (cFilters.length > 1 || cFilters.length === 1 && cFilters[0] !== 'vlocity_cmt__Status__c')) {
            queryValues.push('that meet the following criteria: ');
            contactFilters?.[contactFormFieldTypes.PreferredCommunicationMethod__c.id] && queryValues.push(`communication preference of ${contactFilters[contactFormFieldTypes.PreferredCommunicationMethod__c.id]}`);
            contactFilters?.[contactFormFieldTypes.Role__c.id] && queryValues.push('role of '+ contactFilters[contactFormFieldTypes.Role__c.id]);
            contactFilters?.[contactFormFieldTypes.Title.id] && queryValues.push('title of '+ contactFilters[contactFormFieldTypes.Title.id]);
            contactFilters?.[contactFormFieldTypes.IsActive.id] !== undefined && queryValues.push(contactFilters[contactFormFieldTypes.IsActive.id] ? 'is actively used by the Customer' : 'is not used by the Customer');
            contactFilters?.[contactFormFieldTypes.CreatedDate.id] && queryValues.push('was created '+dOpts[contactFilters[contactFormFieldTypes.CreatedDate.id]?.split(':')[0]] || '');
        }
        queryValues.forEach((value, index) => {
            const andString = (index === queryValues.length-2 && index > 1) ? ' and ' : '';
            const addCustomerString = index === 0 ? `from ${customerQuery} ` : '';
            query = query.concat(`${value}${((index < queryValues.length-2) && index > 1) ? ', ' : andString} ${addCustomerString}`);
        });
        return query;
    }

    const orderQueryBuilder = (customerQuery) => {
        const orderFilters = payload?.account?.orders?.filters;
        const isNoFilters = _.isEmpty(orderFilters);
        const createdDayCount = orderFilters?.CreatedDate?.split(':')[1] || "0";

        const status = orderFilters?.[orderFormFieldTypes.Status.id]?.replaceAll("'", '') || '';
        const dOpts = dateLiterals.queryDateOptions(createdDayCount);
        const type = orderFilters?.[orderFormFieldTypes.Type.id] || '';
        const orderReason = orderFilters?.[orderFormFieldTypes.OrderReason__c.id] || '';
        const orderReasonType = orderFilters?.[orderFormFieldTypes.OrderReasonType__c.id] || '';
        const orderDate = orderFilters?.[orderFormFieldTypes.CreatedDate.id] || '';

        const criteriaOpts = [];
        orderReason && criteriaOpts.push(' order reason of '+orderReason);
        orderReasonType && criteriaOpts.push(' order reason type of '+orderReasonType);
        orderDate && criteriaOpts.push(' was created '+dOpts[orderFilters?.[orderFormFieldTypes.CreatedDate.id]?.split(':')[0]] || '')

        let criteria = `that meet the following criteria: `;

        criteriaOpts.forEach((value, index) => {
            const andString = (index === criteriaOpts.length-2 && index >= 0) ? ' and ' : '';
            criteria = criteria.concat(`${value}${((index < criteriaOpts.length-2) && index >= 0) ? ', ' : andString}`);
        })

        const query = `${isNoFilters ? 'all' : status} ${type} Orders from ${customerQuery} ${criteriaOpts.length ? criteria : ""}`;

        return query;
    }

    const serviceQueryBuilder = (customerQuery) => {
        // order
        const orderFilters = payload?.account?.orders?.filters;
        const isNoFilters = _.isEmpty(orderFilters);
        const createdDayCount = orderFilters?.CreatedDate?.split(':')[1] || "0";

        const status = orderFilters?.[orderFormFieldTypes.Status.id]?.replaceAll("'", '') || '';
        const dOpts = dateLiterals.queryDateOptions(createdDayCount);
        const type = orderFilters?.[orderFormFieldTypes.Type.id] || '';
        const orderReason = orderFilters?.[orderFormFieldTypes.OrderReason__c.id] || '';
        const orderReasonType = orderFilters?.[orderFormFieldTypes.OrderReasonType__c.id] || '';
        const orderDate = orderFilters?.[orderFormFieldTypes.CreatedDate.id] || '';

        // service
        const serviceFilters = payload?.account?.orders?.product?.filters;
        const serviceAdditionalFilters = payload?.account?.orders?.product?.additionalFilters;
        const isNoServiceFilters = _.isEmpty(serviceFilters);

        const serviceType = serviceFilters?.[serviceFormFieldTypes.Product_Type__c.id] || '';
        const serviceFamily = serviceFilters?.[serviceFormFieldTypes.Family.id] || '';
        const serviceName = serviceFilters?.[serviceFormFieldTypes.Name.id] || '';
        const priorityAssist = serviceAdditionalFilters?.[serviceFormFieldTypes.TLS_PriorityAssist.id] || '';
        const chargeCycle = serviceAdditionalFilters?.[serviceFormFieldTypes.TLS_ChargeModel.id] || '';

        const criteriaOpts = [];
        orderReason && criteriaOpts.push(' order reason of '+orderReason);
        orderReasonType && criteriaOpts.push(' order reason type of '+orderReasonType);
        orderDate && criteriaOpts.push(' was created '+dOpts[orderFilters?.[orderFormFieldTypes.CreatedDate.id]?.split(':')[0]] || '');

        let criteria = `that meet the following criteria: `;

        criteriaOpts.forEach((value, index) => {
            const andString = (index === criteriaOpts.length-2 && index >= 0) ? ' and ' : '';
            criteria = criteria.concat(`${value}${((index < criteriaOpts.length-2) && index >= 0) ? ', ' : andString}`);
        })

        const orderQuery = `${isNoFilters ? 'all' : status} ${type} Orders ${criteriaOpts.length ? criteria : ""}`;
        const query = `${isNoServiceFilters ? 'all' : ''} ${chargeCycle ? (chargeCycle + " Charge Cycle,"): ""} ${priorityAssist} ${serviceName} Services ${serviceFamily ? ("of "+ serviceFamily + " Family") : "" } ${serviceType ? " with type "+serviceType : ""} from ${orderQuery}`;

        return query;
    }

    const tabQueryBuilders = {
        'customer': customerQueryBuilder,
        'contact': contactQueryBuilder,
        'order': orderQueryBuilder,
        'services': serviceQueryBuilder
    };
    
    const createSimpleEnglishQuery = () => {
        const customerQuery = customerQueryBuilder();
        const finalQ = `Searching for ${tabQueryBuilders[tab.name]?.(customerQuery)}`;
        setSimpleEnglishQuery(finalQ);
    }

    return (
        <ContentBody 
            marginBottom={2} 
            padding={3} 
            borderTopLeft="0px"
            borderTopRight="0px"
            disableTopBorder
        >
            <Grid container direction="row" justifyContent="center">
                <Tooltip title={`${searchSectionOpen ? "Close Search Filters" : "Open Search Filters"}`} placement="bottom">
                        <Grid item sm={12}>
                            <Box
                                display="inline-flex"
                                alignItems="center"
                                border="1px solid rgba(0, 0, 0, 0.23)"
                                borderRadius="4px"
                                padding="12px"
                                minHeight="36px"
                                width="100%"
                                onMouseEnter={()=>setShowEdit(true)}
                                onMouseLeave={()=>setShowEdit(false)}
                                onClick={toggleSearchSection}
                                boxShadow={showEdit ? 1 : 0}
                                className={classes.body}
                            >
                                    <TravelExploreIcon className={`${classes.icon} ${classes.activeIcon}`}/>
                                    <Typography
                                        variant="body1"
                                        className={classes.text}
                                    >
                                        {simpleEnglishQuery}
                                    </Typography>
                                    {!searchSectionOpen && <EditIcon className={`${classes.icon} ${classes.activeIcon} ${!showEdit && classes.inactiveIcon}`}/>}
                                    {showEdit && searchSectionOpen && <CloseRoundedIcon className={`${classes.icon} ${classes.activeIcon} ${!showEdit && classes.inactiveIcon}`}/>}
                            </Box>
                           {props.totalSize > 0 && <Typography className={classes.totalRecords}><b>Total Records: </b>{props.totalSize}</Typography> } 
                        </Grid>
                </Tooltip>
            </Grid>
        </ContentBody>
    );
}

export default QueryBuilderWidget;