import React, { Component } from 'react'
import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #cc6699 0, #cc6699 100%)',
   
}

const QuestionnaireTile = (props) => (
    <LinkTile
    style={tileStyle}
    className="bg-purple"
    data-size="medium"
    to="/user/survey"
    disabled={props.disabled}
    data-effect="hover-zoom-up">
    <div class="slide-front">
        <span className="fa fa-address-card-o icon"></span>
        <span className="branding-bar">User Assessment</span>
    </div>
    <div
        class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
        <p class="text-center">
        {props.disabled ? "Coming Soon" : "Lets check your rating"}
        </p>
    </div>
</LinkTile>
)

QuestionnaireTile.propTypes = {}

export default QuestionnaireTile;