import React, { Component, Fragment } from 'react'
import { SubHeader } from '../../common/custom/SubHeader';
import { submitMsisdnLoad, submitSimImsiPreseeding, updatePhysicalSimMisdnList, updatePhysicalSimList, clearMsisdnResponse } from '../../../actions/utilityActions';
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from 'redux-form';
import { displayLoading, displaySuccess, displayWarning } from "../../common/alerts/alerts";
import Tooltip from '@material-ui/core/Tooltip';
import ExpandableInfoSection from './ExpandableInfoSection';
import PhysicalSimForm from './PhysicalSimForm';
import ReviewForm from './ReviewForm';
import { Button } from 'reactstrap';
import MUIButton from '@mui/material/Button';
import './physicalSim.css';
import { asyncFormImsiAvailabilityValidator as asyncValidate } from '../../common/form/asyncValidators';
import swal from 'sweetalert2';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import HeaderContent from '../../common/Header/HeaderContent';

class PhysicalSimSetupUtility extends Component {
    state = {
        currentPage: 'infoPage',
        submitDisabled: true,
        readTerms: false,
    };
    switchScreen = (page) => {
        this.setState(prevState => ({
            ...prevState,
            currentPage: page,
            submitDisabled: true
        }));
    }

    enableSubmit = (bool) => {
        this.setState({
            ...this.state,
            submitDisabled: bool,
        });
    }

    setReadTerms = (bool) => {
        this.setState({
            ...this.state,
            readTerms: bool,
        });
    }

    startSubmission = (result) => {
        if (result.value) {
            displayLoading({ title: "Physical Sim Preceeding", bodyText: "Please standby while request is submitted" });
            let msisdnRequest = this.createMsisdnPayload();
            let simRequest = this.createSimPayload();
            let noMsisdn = false;

            if (msisdnRequest) {
                this.props.submitMsisdnLoad(msisdnRequest).then(() => {
                    if (this.props.msisdnSuccess && this.props.simSuccess) {
                        swal.close();
                        this.createSubmissionResultMsg();
                    } else if (this.props.msisdnFailed && this.props.simFailed) {
                        swal.close();
                        swal.fire({
                            type: 'error',
                            title: 'Error',
                            html: `${this.props.msisdnResponse.responseDescription ? this.props.msisdnResponse.responseDescription : this.props.msisdnResponse} <br/>${this.props.msisdnResponse.failureDetails ? this.props.msisdnResponse.failureDetails : ''}`,
                            confirmButtonText: 'OK',
                        })
                    } else if ((this.props.msisdnSuccess && this.props.simFailed) || (this.props.msisdnFailed && this.props.simSuccess)) {
                        swal.close();
                        this.createSubmissionResultMsg();
                    }
                    this.submitSimImsi(simRequest, noMsisdn);
                });
            } else {
                noMsisdn = true;
                this.props.clearMsisdnResponse();
                this.submitSimImsi(simRequest, noMsisdn);
            }
        }
    }

    submitSimImsi = (simRequest, noMsisdn) => {
        this.props.submitSimImsiPreseeding(simRequest).then(() => {
            if (this.props.simSuccess && (this.props.msisdnSuccess || noMsisdn)) {
                swal.close();
                this.createSubmissionResultMsg();
            } else if ((this.props.msisdnFailed || noMsisdn) && this.props.simFailed) {
                swal.close();
                swal.fire({
                    type: 'error',
                    title: 'Error',
                    html: `${this.props.simResponse.responseDescription ? this.props.simResponse.responseDescription : this.props.simResponse} <br/>${this.props.simResponse.failureDetails ? this.props.simResponse.failureDetails : ''}`,
                    confirmButtonText: 'OK',
                });
            } else if ((this.props.msisdnSuccess && this.props.simFailed) || (this.props.msisdnFailed && this.props.simSuccess)) {
                swal.close();
                this.createSubmissionResultMsg();
            }
        });
    }

    createSubmissionResultMsg = () => {
        let msg = '';
        let msgTitle = '';
        let msisdnIncluded = this.props.msisdnList.length > 0;

        if (this.props.msisdnSuccess && this.props.simSuccess) {
            msg = `MSISDN and SIM-IMSI Mobile Preceeding requests were successful<br/><br/>
                   MSISDN Request Batch ID: <span style="font-weight:bold">${this.props.msisdnResponse}</span><br/> 
                   SIM Request Batch ID: <span style="font-weight:bold">${this.props.simResponse}</span>`;
            msgTitle = `Mobile Preceeding Successful`;
        } else if (this.props.msisdnSuccess && this.props.simFailed) {
            msg = `<div style="margin:0px 62px;text-align:justify;">
                    SIM-IMSI Request Failed:<br/>
                    <span style="color:#fc035a">${this.props.simResponse.failureDetails ? this.props.simResponse.failureDetails : 
                    this.props.simResponse.responseDescription ? this.props.simResponse.responseDescription : `Mobile Preseeding Process Failed!`}</span><br/><br/>
                    MSISDN Request Batch ID: <span style="font-weight:bold">${this.props.msisdnResponse}</span>
                   </div>`;
            msgTitle = `Mobile Preceeding Partially Successful`;
        } else if (this.props.msisdnFailed && this.props.simSuccess) {
            msg = `<div style="margin:0px 62px;text-align:justify;">
                    MSISDN Request Failed:<br/> 
                    <span style="color:#fc035a">${this.props.msisdnResponse.failureDetails ? this.props.msisdnResponse.failureDetails : 
                    this.props.msisdnResponse.responseDescription ? this.props.msisdnResponse.responseDescription : `Mobile Preseeding Process Failed!`}</span><br/><br/>
                    SIM-IMSI Request Batch ID: <span style="font-weight:bold">${this.props.simResponse}</span>
                   </div>`;
            msgTitle = `Mobile Preceeding Partially Successful`;
        } else if (!msisdnIncluded && this.props.simSuccess) {
            msg = `<div style="margin:0px 62px;text-align:center;">
                    SIM-IMSI Request Batch ID: <span style="font-weight:bold">${this.props.simResponse}</span>
                   </div>`;
            msgTitle = `Mobile Preceeding Successful`;
        } 
        displaySuccess({ title: msgTitle, html: msg}).then(() => {this.props.updatePhysicalSimList([]); this.props.updatePhysicalSimMisdnList([]); this.props.history.push(`/data/utility/batchstatus`)});
    }

    createSimPayload = () => {
        let simImsiPayload = {};
        let imsiArray = [];
        let simArray = [];
        let physicalSimArray = [];

        for (const simImsi of this.props.simList) {
            imsiArray.push(simImsi.imsi);
            simArray.push(simImsi.sim);
            physicalSimArray.push(simImsi);
        }

        return simImsiPayload = {
            team: this.props.auth.team,
            type: "POSTPAID",
            resourceType: "IMSI",
            payloadType: "ARRAY",
            imsi: "["+imsiArray.toString()+"]",
            sim: "["+simArray.toString()+"]",
            environment: this.props.auth.selectedEnvValue,
            selfAllocationSIM: false,
            categoryType: "Network IMSI",
            physicalSim: [
                ...physicalSimArray,
            ]
        }
    }

    createMsisdnPayload = () => {
        let msisdnPayload = {};

        if (this.props.msisdnList.length > 0) {
            return msisdnPayload = {
                team: this.props.auth.team,
                type: "POSTPAID",
                resourceType: "MSISDN",
                payloadType: "ARRAY",
                msisdn: "["+this.props.msisdnList.toString()+"]",
                environment: this.props.auth.selectedEnvValue,
                selfAllocationSIM: false,
                categoryType: "NETWORK"
            }
        } else {
            return null;
        }
    }

    render() {
        const {history} = this.props;
        const warningMsg = `You are currently connected to ${this.props.auth.selectedEnvValue}<br/><br/> 
                            <div style="margin:0px 16px;text-align:justify;">
                                Once request is submitted values can not be altered, and wrong input values may currupt data and there is possiblity that these changes are non recoverable.<br/><br/>
                            </div>
                            <div style="margin:0px 26px;text-align:left;"><ul><li>Make sure SIMs not Present in SNRM & Network</li><li>Make sure UNICO is pointed to ${this.props.auth.selectedEnvValue} environment</li></ul></div>`;
        return (
            <> <div className="white"> 
                 <HeaderContent
                title={`Physical Sim Preceeding`}
                icon="cogs ops-icon"
                empty={true}
                redirectUtility={true}
                push={history?.push}></HeaderContent>
                <div className="content">
                    <form className="simForm">
                        { this.state.currentPage === 'infoPage' ?
                            <Fragment>
                                <ExpandableInfoSection readTerms={this.setReadTerms} terms={this.state.readTerms}/>
                                <div class="text-right pageBtn">
                                <Tooltip title={!this.state.readTerms ? "Please read & agree to terms above" : "Continue to Setup"}>
                                    <span>
                                        <Button disabled={!this.state.readTerms} class="text-right pageBtn" type="button" color="primary" onClick={() => {this.switchScreen('setupPage')}}>Continue</Button>
                                    </span>
                                </Tooltip>
                                </div>
                            </Fragment> : null
                        }
                        { this.state.currentPage === 'setupPage' ? 
                            <Fragment>
                                <PhysicalSimForm 
                                    invalid={this.props.invalid}
                                    passdownprops={this.props}
                                />
                                <div class="pageBtns">
                                    <Button type="button" color="secondary" onClick={() => {this.switchScreen('infoPage')}}>Go Back</Button>
                                    <Tooltip title={this.props.simList.length === 0 ? "Please add at least 1 physical SIM" : "Review provided data"}>
                                        <span>
                                            <Button disabled={this.props.simList.length === 0} type="button" color="primary" onClick={() => {this.switchScreen('reviewPage')}}>Review</Button>
                                        </span>
                                    </Tooltip>
                                </div>
                            </Fragment> : null
                        }
                        { this.state.currentPage === 'reviewPage' ?
                            <Fragment>
                                <ReviewForm submitDisabled={this.enableSubmit} msisdns={this.props.msisdnList} simImsi={this.props.simList}/>
                                <div class="pageBtns">
                                    <Button type="button" color="secondary" onClick={() => {this.switchScreen('setupPage')}}>Go Back</Button>
                                    <Tooltip title={this.state.submitDisabled ? "Please review all pages" : "Submit Request"}>
                                        <span>
                                            <Button disabled={this.state.submitDisabled} type="button" color="primary" onClick={() => {displayWarning({title: "WARNING", html: warningMsg}).then(this.startSubmission)}}>Submit</Button>
                                        </span>
                                    </Tooltip>
                                </div>
                            </Fragment> : null
                        }
                    </form>
                </div>
            </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: state.form.PhysicalSimForm,
        auth: state.auth,
        msisdnPending: state.utility.physicalSimPreceeding.isSubmitMsisdnPending,
        msisdnFailed: state.utility.physicalSimPreceeding.isSubmitMsisdnFailed,
        msisdnSuccess: state.utility.physicalSimPreceeding.isSubmitMsisdnSuccess,
        msisdnResponse: state.utility.physicalSimPreceeding.msisdnResponse,
        simPending: state.utility.physicalSimPreceeding.isSubmitSimPending,
        simFailed: state.utility.physicalSimPreceeding.isSubmitSimFailed,
        simSuccess: state.utility.physicalSimPreceeding.isSubmitSimSuccess,
        simResponse: state.utility.physicalSimPreceeding.simResponse,

        msisdnList: state.utility.physicalSimPreceeding.msisdnList,
        simList: state.utility.physicalSimPreceeding.simList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        submitMsisdnLoad: (request) => dispatch(submitMsisdnLoad(request)),
        submitSimImsiPreseeding: (request) => dispatch(submitSimImsiPreseeding(request)),
        clearMsisdnResponse: () => dispatch(clearMsisdnResponse()),
        
        updatePhysicalSimMisdnList: (list) => dispatch(updatePhysicalSimMisdnList(list)),
        updatePhysicalSimList: (list) => dispatch(updatePhysicalSimList(list)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: "PhysicalSimForm",
        initialValues: {
            'sim order type': "3GUniversal_BigPond",
            'profile id': "0250.5103",
            'sim artwork': "0061 0608",
        },
        asyncValidate,
}))(PhysicalSimSetupUtility);