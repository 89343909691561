import React, { Fragment } from 'react';

export const SimasRow = ({
    tableRowData
}) => {
    return (
        <Fragment>
            <tr>
                <td>
                    {tableRowData[0]}
                </td>
                <td>
                    {tableRowData[1]}
                </td>
            </tr>
        </Fragment>
    );
}