import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { deepOrange } from '@material-ui/core/colors';
import MaterialAutoComplete from './subcomponents/MaterialAutoComplete';
import AvatarComp from './subcomponents/BadgeAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 100
  },
  container: {
    display: 'flex',
  },
  container1: {
    position: 'absolute',
    top: '60px',
    left: '-17px'
  },
  paper: {
    margin: theme.spacing(1),
    height: '70px'
  },
  fab: {
    margin: theme.spacing(2),
  },
  svg: {
    width: 100,
    height: 100,
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1,
  },
  orange: {
    float: 'right',
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  avatarGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  circularProgress: {
    margin: '16px'
  }
}));



export default function SimpleGrow({ selectTeam, fetching, listOfTeams, isUserToggle, isAdmin, renderUserToggle }) {
  const classes = useStyles();
  const [av, setAv] = useState(listOfTeams[0]);

  useEffect(()=>{
    setAv(listOfTeams[0]);
  },[isUserToggle])

  function selectTeamAction(team) {
    selectTeam(team?.id || team?.teamId || team?.userId);
    setAv(team);
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <MaterialAutoComplete listOfTeams={listOfTeams} selectTeam={selectTeamAction} isUserToggle={isUserToggle}/>
        
        <div className={classes.avatarGroup}>
          {isAdmin && renderUserToggle()}
          <Typography variant='span' style={{ marginRight: '10px', marginLeft:'10px' }}> <i>Currently displaying results for</i>   </Typography>
          {fetching && <CircularProgress className={classes.circularProgress} />}
          {!fetching && (
            <AvatarComp av={av} />
          )}
        </div>

      </div>



    </div>
  );
}

