import React, { Component } from 'react';
import { SubHeader } from "../../../common/custom/SubHeader";
import { Collapse, Table } from "reactstrap";
import { connect } from 'react-redux';
import { importCustomerSalesforce, selectCustomerSalesforce, addCustomerToSalesforceView } from '../../../../actions/sfCustomerActions';
import DataViewCustomerSf from '../salesforce-customer/DataViewCustomerSf';
import DataViewCustomerSfBtn from '../salesforce-customer/DataViewCustomerSfBtn';
import { is1TdmUser, isB2bUser, getSFConf } from '../../../../utils/userProfile';

class CustomerTable extends Component {

    componentDidMount() {
        this.props.addCustomerToSfView(this.props.customerDetails);
    }

    collapseClick = (custId, salesforceId, env) => {
        if (this.props.activeSfCustomer === custId) {
            // hide the row
            this.props.salesforceSelect(null);
            return;
        }
        this.props.salesforceSelect(custId, salesforceId, env);
    }

    isB2BSalesforceVisible = () => {
        const sfConf = getSFConf(this.props.auth);
        return is1TdmUser() && isB2bUser(sfConf.currentRoles);
    }

    render() {
        const customerDetails = this.props.customers;
        const { id } = this.props.assetDetails !== undefined ? this.props.assetDetails : {};
        const isVisible = this.isB2BSalesforceVisible();

        return (
            <React.Fragment>
                {(customerDetails !== null && customerDetails !== undefined && customerDetails.length > 0 &&
                    <React.Fragment>
                        <SubHeader
                            text={"Customers"}
                            iconClass={"td-icon-md icon-crowd-support"}
                            data={customerDetails}
                            iconColor="turquoise"
                            filename={`someFile-CUSTOMER`} />
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th>Customer ID</th>
                                    <th>Type</th>
                                    <th>CIDN</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>DOB</th>
                                    {isVisible && <th>Salesforce</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {customerDetails.map(eachCustomer => {
                                    const isOpen = this.props.activeSfCustomer == eachCustomer.uniqueId;
                                    const rowStyles = { "backgroundColor": isOpen && "rgba(211, 211, 211, 0.18)", "opacity": (isOpen || !this.props.activeSfCustomer) ? 1 : 0.45 };
                                    return (
                                        <React.Fragment key={eachCustomer.uniqueId}>
                                            <tr>
                                                <th scope="row">{eachCustomer.uniqueId}</th>
                                                <td>{eachCustomer.type}</td>
                                                <td>{eachCustomer.cidn}</td>
                                                <td>{eachCustomer.firstName}</td>
                                                <td>{eachCustomer.lastName}</td>
                                                <td>{eachCustomer.dob}</td>
                                                {isVisible && <td><DataViewCustomerSfBtn customer={eachCustomer} assetId={id} active={isOpen} click={this.collapseClick} import={this.props.salesforceImport} /></td>}
                                            </tr>
                                            {isVisible && <tr>
                                                <td colSpan="6" style={{ "borderTop": "none", "padding": 0, ...rowStyles }}>
                                                    <Collapse isOpen={isOpen} className="m-1">
                                                        <DataViewCustomerSf customer={eachCustomer} />
                                                    </Collapse>
                                                </td>
                                            </tr>}
                                        </React.Fragment>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        activeSfCustomer: state.sfCustomer.view.activeSfCustomer,
        customers: state.sfCustomer.view.customerDetails,
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        salesforceImport: (customer, assetId, env, profile, isNosqlImport) => { dispatch(importCustomerSalesforce(customer, assetId, env, profile, isNosqlImport)) },
        salesforceSelect: (custId, salesforceId, env) => { dispatch(selectCustomerSalesforce(custId, salesforceId, env)) },
        addCustomerToSfView: (customer) => { dispatch(addCustomerToSalesforceView(customer)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTable);
