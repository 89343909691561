//SNRM - Cleanup
export const RESET_SNRM_RESOURCES = "RESET_SNRM_RESOURCES";
export const SELECT_DATA_CLEANUP_FILTER = "SELECT_DATA_CLEANUP_FILTER";
export const RESET_CURRENT_STATUS = "RESET_CURRENT_STATUS";
export const RESET_DATA_CLEAN_UP_FILTERS = "RESET_DATA_CLEAN_UP_FILTERS";
export const FETCH_SNRM_RESOURCE_PENDING = "FETCH_SNRM_RESOURCE_PENDING";
export const FETCH_SNRM_RESOURCE_SUCCESS = "FETCH_SNRM_RESOURCE_SUCCESS";
export const FETCH_SNRM_RESOURCE_FAILED = "FETCH_SNRM_RESOURCE_FAILED";
export const UPDATE_SNRM_PENDING = "UPDATE_SNRM_PENDING";
export const UPDATE_SNRM_SUCCESS = "UPDATE_SNRM_SUCCESS";
export const UPDATE_SNRM_FAILED = "UPDATE_SNRM_FAILED";

//SNRM-IMSI
export const FETCH_SNRM_IMSI_RESOURCE_PENDING = "FETCH_SNRM_RESOURCE_PENDING";
export const FETCH_SNRM_IMSI_RESOURCE_SUCCESS = "FETCH_SNRM_RESOURCE_SUCCESS";
export const FETCH_SNRM_IMSI_RESOURCE_FAILED = "FETCH_SNRM_RESOURCE_FAILED";

//UNMS Cleanup - TODO
export const RESET_RESOURCES = "RESET_RESOURCES";
export const FETCH_UNMS_RESOURCE_SUCCESS = "FETCH_UNMS_RESOURCE_SUCCESS";
export const FETCH_UNMS_RESOURCE_PENDING = "FETCH_UNMS_RESOURCE_PENDING";
export const FETCH_UNMS_RESOURCE_FAILED = "FETCH_UNMS_RESOURCE_FAILED";
export const INSERT_UNMS_PENDING = "INSERT_UNMS_PENDING";
export const INSERT_UNMS_SUCCESS = "INSERT_UNMS_SUCCESS";
export const INSERT_UNMS_FAILED = "INSERT_UNMS_FAILED";
export const UPDATE_UNMS_PENDING = "UPDATE_UNMS_PENDING";
export const UPDATE_UNMS_SUCCESS = "UPDATE_UNMS_SUCCESS";
export const UPDATE_UNMS_FAILED = "UPDATE_UNMS_FAILED";
export const UPDATE_SIM_IMSI_UPDATE_TYPES = "UPDATE_SIM_IMSI_UPDATE_TYPES";

//B2B Clean Up
export const FETCH_B2B_CLEANUP_BATCH_STATUS_LOADING = "FETCH_B2B_CLEANUP_BATCH_STATUS_LOADING";
export const FETCH_B2B_CLEANUP_BATCH_STATUS_SUCCESS = "FETCH_B2B_CLEANUP_BATCH_STATUS_SUCCESS";
export const FETCH_B2B_CLEANUP_BATCH_STATUS_FAILED = "FETCH_B2B_CLEANUP_BATCH_STATUS_FAILED";
export const FETCH_B2B_CLEANUP_RESOURCE_STATUS_LOADING = "FETCH_B2B_CLEANUP_RESOURCE_STATUS_LOADING";
export const FETCH_B2B_CLEANUP_RESOURCE_STATUS_SUCCESS = "FETCH_B2B_CLEANUP_RESOURCE_STATUS_SUCCESS";
export const FETCH_B2B_CLEANUP_RESOURCE_STATUS_FAILED = "FETCH_B2B_CLEANUP_RESOURCE_STATUS_FAILED";




