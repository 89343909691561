import React, { useEffect, useState } from "react";
import moment from "moment";
import './style.css'
import Carousel from 'nuka-carousel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import {Typography, Grid} from '@mui/material/';
import { connect } from "react-redux";
import { addPageFeedback, forceFeedback, disableFeedback, fetchTestimonials } from '../../actions/userFeedbackActions';
import { resetCheckout } from '../../actions/myTeamsActions';
import { updateUserTaasMetadata } from "../../actions/userActions";
import swal from "sweetalert2";
import DefaultDp from '../../assets/images/default_dp.jpg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCommentIcon from '@mui/icons-material/AddComment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { findStringInObjectArray } from '@library/core/filters/filter';
import AddFeedbackModal from "./AddFeedbackModal";
import TodayIcon from '@mui/icons-material/Today';

const Testimonials = (props) => {
    const [feedbackComment, addFeedbackComment] = useState("");
    const [showModal, showTextModal] = useState(false);
    const [rating, setRating] = useState(5);
    const [feedbackErrorMsg, setFeedbackErrorMsg] = useState(false);

    const provideLaterLimit = 3;
    const ninetyDays = 90;
    const todaysDate = moment();
    let testimonialData_sort=[]
    if(Array.isArray(props.pageFeedBack)){
        let testimonialData = props.pageFeedBack.filter((page) => page.adminApproved == "true")
         testimonialData_sort = testimonialData.sort((d1, d2) => new Date(d2.time).getTime() - new Date(d1.time).getTime());
    }
    const truncateString = (str, gt, size) => {
        if (str && str.length > gt) {
            return `${str.slice(0, size).trim()}...`;
        } return str;
    }

    useEffect(() => {
        if (feedbackComment != "") {
            return setFeedbackErrorMsg(false)
        }
    }, [feedbackComment]);

    useEffect(() => {
        if (props.pageFeedBack && props.pageFeedBack.length > 0 && props.userTaasMetaData?.metaData?.oldestLoginDate && props.userTaasMetaData?.metaData?.prevLoginDate) {
            const userAccountIsYoung = validateFeedbackTime(moment(props.userTaasMetaData?.metaData?.oldestLoginDate));
            const userHasBeenActive = validateFeedbackTime(moment(props.userTaasMetaData?.metaData?.prevLoginDate));
            const provideLaterExpired = props.forceFeedbackDisabledTime ? moment.duration(moment().diff(moment(props.forceFeedbackDisabledTime))).asHours() >= 4 : true;
            if (!userAccountIsYoung && userHasBeenActive && provideLaterExpired && (props.auth.user.UserName || props.auth.user.displayName)) {
                const lastFeedback = getLastFeedback();
                if (!lastFeedback || !validateFeedbackTime(moment(lastFeedback.time))) {
                    props.shouldForceFeedback && showTextModal(true);
                } else {
                    showTextModal(false);
                }
            }
        }        
    }, [props.auth?.user, props.pageFeedBack, props.shouldForceFeedback, props.userTaasMetaData?.metaData?.prevLoginDate, props.userTaasMetaData?.metaData?.oldestLoginDate]);

    const getLastFeedback = () => {
        if (props.auth.user.UserName) {
            return findStringInObjectArray(props.pageFeedBack, "userName", props.auth.user.UserName);
        } else if (props.auth.user.displayName) {
            return findStringInObjectArray(props.pageFeedBack, "addedBy", props.auth.user.displayName.replace(/,(?=[^\s])/g, ", "));
        }
    }

    useEffect(() => {
        props.shouldForceFeedback && props.userTaasMetaData?.metaData?.provideLaterCount === provideLaterLimit && showTextModal(true);
        props.fetchTestimonials();
    }, []);

    const handleAddComment = () => {
        showTextModal(true);
    }

    const updateProvLaterCount = (reset) => {
        if (((!props.fetchTaasMetaDataLoading && !props.fetchTaasMetaDataFailed && props.fetchTaasMetaDataSuccess) || reset && !props.updateTaasMetaDataFailed)
            && (!reset || (reset && props.userTaasMetaData?.metaData?.provideLaterCount !== 0))
        ) {
            const provLaterCount = props.userTaasMetaData?.metaData?.provideLaterCount+1 || 1;
            const metaDataRequest = {
                userId: props.auth?.userName,
                toolName: "TDM",
                metaData: {
                    provideLaterCount: reset ? 0 : provLaterCount
                }
            }
            props.updateUserTaasMetadata(metaDataRequest);
        }
    }

    const closeModal = (event, reason) => {
        const provLimit = props.userTaasMetaData?.metaData?.provideLaterCount === provideLaterLimit;
        if (props.shouldForceFeedback && reason != "backdropClick" && !provLimit) {
            showTextModal(false);
            setFeedbackErrorMsg(false);
            addFeedbackComment("");
            props.forceFeedback(false);
            props.disableFeedback(new Date());
            props.resetCheckout();
            updateProvLaterCount();
        } else if (!props.shouldForceFeedback && !provLimit) {
            showTextModal(false);
            setFeedbackErrorMsg(false);
            addFeedbackComment("");
            props.forceFeedback(false);
            props.disableFeedback(new Date());
        }
    }

    const addComment = () => {
        if (feedbackComment !== "") {
            let pageFeedbackData = props.pageFeedBack;
            let currPage = props.currentPage
            if (pageFeedbackData.length == 0) {
                const currentPageFeedBack = {
                    "page": currPage, "likes": 0,
                    "unLikes": 0, 
                    "addedBy": props.auth.name.replace(/,(?=[^\s])/g, ", "), 
                    "dNumber": props.auth.userName,
                    "comment": feedbackComment,
                    "adminApproved": false, 
                    "rating": rating, 
                    "time": new Date()
                }
                pageFeedbackData.push(...pageFeedbackData, currentPageFeedBack)
                props.addPageFeedback(pageFeedbackData, currentPageFeedBack).then((response) => {
                    if (response) {
                        swal.fire(
                            "Success",
                            `Feedback Comment Added`,
                            "success"
                        );
                        props.fetchTestimonials();
                        updateProvLaterCount(true);
                    }
                    else {
                        swal.fire(
                            "Error",
                            `ERROR , UNABLE TO ADD FEEDBACK`,
                            "error"
                        );
                    }
                })
            }
            else {
                let feedbackData = pageFeedbackData.filter((data, index) => data.page === currPage)
                let feedbackDataA = pageFeedbackData.filter((data, index) => data.page !== currPage)
                if (feedbackData.length > 0) {
                    feedbackData[0].page = currPage;
                    feedbackData[0].unLikes = parseInt(feedbackData[0].unLikes);
                    feedbackData[0].likes = parseInt(feedbackData[0].likes);
                    feedbackData[0].addedBy = props.auth.name.replace(/,(?=[^\s])/g, ", ");
                    feedbackData[0].dNumber = props.auth.userName;
                    feedbackData[0].comment = feedbackComment;
                    feedbackData[0].adminApproved = false
                    feedbackData[0].rating = rating
                    feedbackData[0].time = new Date()
                    feedbackDataA.push(...feedbackData)
                }
                else {
                    feedbackData = {
                        "page": currPage, "likes": 0, "unLikes": 0,
                        "addedBy": props.auth.name.replace(/,(?=[^\s])/g, ", "), "dNumber": props.auth.userName,
                        "comment": feedbackComment,
                        "adminApproved": false, "rating": rating, "time": new Date()
                    }
                    feedbackDataA.push(feedbackData)
                }
                props.addPageFeedback(feedbackDataA, feedbackData).then((response) => {
                    if (response) {
                        swal.fire(
                            "Success",
                            `Thank you for your Feedback`,
                            "success"
                        );
                        props.fetchTestimonials();
                        updateProvLaterCount(true);
                    }
                    else {
                        swal.fire(
                            "Error",
                            `ERROR , UNABLE TO ADD FEEDBACK`,
                            "error"
                        );
                    }
                })
            }
            addFeedbackComment("");
            showTextModal(false);
            setRating(5);
        } else {
            setFeedbackErrorMsg(true)
        }
    }

    const validateFeedbackTime = (pastTime) => {
        const timeDiffInDays = todaysDate.diff(pastTime, 'days');
        return (timeDiffInDays <= ninetyDays) ? true : false;
    }

    if (testimonialData_sort) {
        return (
            <>
                <div className="testimonial_bg">
                    {testimonialData_sort?.length > 0 ? (
                        <>
                            <Carousel 
                                className="carouselBody"
                                wrapAround={true} 
                                slidesToShow={testimonialData_sort?.length > 4 ? 4 : testimonialData_sort.length} 
                                autoplay={true} dragging={false} adaptiveHeight={false} 
                                defaultControlsConfig={{
                                    nextButtonText: <ArrowForwardIosIcon />,
                                    prevButtonText: <ArrowBackIosIcon />
                                }}
                            >
                                {testimonialData_sort?.map((data) => {
                                    const testimonialDate = new Date(data.time).toLocaleDateString('en-GB',{day: 'numeric', month: 'short', year: 'numeric'})
                                    return <>
                                    <Tooltip 
                                        title={
                                            <>
                                                <h6>{data.addedBy}</h6>
                                                <p className="feedbackTooltip">{data.comments}</p>
                                            </>
                                        } 
                                        disableHoverListener={
                                            (!data.comments || data.comments && (data.comments.length < 41 )) && 
                                            (!data.addedBy || data.addedBy && data.addedBy.length < 23)
                                        }
                                    >
                                        <List sx={{ width: '100%', maxWidth: 370, bgcolor: '#ffffff6b', borderRadius: "12px", paddingTop: "6px" }} key={data.id}>
                                            <ListItem style={{"paddingBottom":"0px", "paddingRight":"5px"}}>
                                                <Grid container direction="row" justifyContent="space-between" style={{"marginTop":"-8px"}}>
                                                    <Grid item>
                                                        <Typography 
                                                            variant='caption'
                                                            style={{"fontSize": "12px"}}
                                                        >
                                                            <TodayIcon className="pr-2 mt-n1" />
                                                            {testimonialDate}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                <Rating className="testimonial_rating" name="read-only" value={data.rating} readOnly size="small" style={{fontSize: "1.325rem"}}/>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem style={{paddingTop:"0px", paddingLeft:"13px", marginTop:"-3px"}} alignItems="flex-start">
                                                <ListItemAvatar style={{ color: "white", marginTop: "4px"}}>
                                                    <Avatar alt="Remy Sharp" src={DefaultDp} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    className="comment-text"
                                                    primary={data.addedBy}
                                                    secondary={truncateString(data.comments, 40, 55)}
                                                    sx={{ color: "white", marginTop:"0px" }}
                                                />
                                            </ListItem>
                                        </List>
                                    </Tooltip>
                                        
                                    </>
                                })}
                            </Carousel>
                            <Tooltip title="Add Feedback">
                                <IconButton className="addbtn" onClick={handleAddComment} style={{ "marginTop": "34px", "background":"linear-gradient(45deg, darkblue 0%, #5d80cb 100%)" }}>
                                    <AddCommentIcon fontSize={"large"} style={{"color":"white", "paddingTop":"3px"}}/>
                                </IconButton >
                            </Tooltip>
                        </>
                    ) : <div className="feedback_bg">
                        <p>Please Add your Feedback</p>
                        <Tooltip title="Add Feedback">
                            <Button className="addbtn2" onClick={handleAddComment} style={{ "marginTop": "-54px", "marginLeft": "295px" }}>Add Feedback</Button>
                        </Tooltip>
                    </div >
                }
                </div>
                <AddFeedbackModal 
                    openModal={showModal}
                    onCloseModal={closeModal}
                    disableProvideLater={props.userTaasMetaData?.metaData?.provideLaterCount === provideLaterLimit}
                    rating={rating}
                    setRating={setRating}
                    feedbackComment={feedbackComment}
                    addFeedbackComment={addFeedbackComment}
                    feedbackErrorMsg={feedbackErrorMsg}
                    submitComment={addComment}
                />
            </>
        );
    } return null
}

const mapStateToProps = (state) => {
    return {
        currentPage: state.userFeedback.currentPage,
        pageFeedBack: state.userFeedback.pageFeedBack,
        shouldForceFeedback: state.userFeedback.forceFeedback,
        forceFeedbackDisabledTime: state.userFeedback.disableForceFeedbackTime,
        auth: state.auth,
        checkoutSuccess: state.checkout.checkoutSuccess,
        fetchTaasMetaDataLoading: state.users.userTaasMetaData?.fetchLoading,
        fetchTaasMetaDataSuccess: state.users.userTaasMetaData?.fetchSuccess,
        fetchTaasMetaDataFailed: state.users.userTaasMetaData?.fetchFailed,
        userTaasMetaData: state.users.userTaasMetaData?.data,
        updateTaasMetaDataFailed: state.users.userTaasMetaData?.updateFailed,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addPageFeedback: (value, currValue) => dispatch(addPageFeedback(value, currValue)),
        forceFeedback: (value) => dispatch(forceFeedback(value)),
        disableFeedback: (disable) => dispatch(disableFeedback(disable)),
        resetCheckout: () => dispatch(resetCheckout()),
        fetchTestimonials: () => dispatch(fetchTestimonials()),
        updateUserTaasMetadata:(request) => dispatch(updateUserTaasMetadata(request))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);