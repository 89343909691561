import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import SubmitButton from "@library/core/buttons/SubmitButton";
import CheckboxItem from "@library/core/form-elements/CheckboxItem";

import {
  Divider,
  FormControl,
  FormLabel,
  FormGroup,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'left',
  },
  formControl: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxBody: {
    margin: "15px 0px 15px 12px",
    maxHeight: "50vh",
    overflowY: "scroll",
  },
  bottomControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '16px',
  }
}));

const FilterForm = (props) => {
  const classes = useStyles();
  const { 
    checkBoxFilterData, handleCheckboxGroupChange, checkBoxSectionTitle,
    autoCompleteFilters,
    handleSubmit = ()=>{}, handleClear = ()=>{}, 
  } = props;

  return (
    <div className={classes.root}>
      <Divider />
        <FormControl component="fieldset" className={classes.formControl}>
          {
            checkBoxFilterData ? (
              <div className={classes.checkBoxBody}>
                <FormLabel component="legend">{checkBoxSectionTitle}</FormLabel>
                <FormGroup style={{"marginLeft":"10px"}}>
                  {
                    checkBoxFilterData.map((filter, index) => {
                      return (
                        <CheckboxItem
                          key={index}
                          checkboxLabel={filter.label}
                          checkboxValue={filter.value}
                          checked={filter.checked}
                          checkboxChangeCallback={ (checkStatus) => (
                            handleCheckboxGroupChange(checkStatus, index)
                            )}
                        />
                      )
                    })
                  }
                </FormGroup>
              </div>
            ) : <></>
          }
          {
            autoCompleteFilters ? (
              <div>{autoCompleteFilters}</div>
            ) : <></>
          }
          {
            checkBoxFilterData ? (
              <div className={classes.bottomControls}>
                <SubmitButton onSubmit={handleClear} color={"secondary"}>Clear</SubmitButton>
                <SubmitButton onSubmit={handleSubmit}>Apply</SubmitButton>
              </div>
            ) : (
              <div className={classes.bottomControls}>
                <SubmitButton onSubmit={handleClear} color={"secondary"} fullWidth>Clear</SubmitButton>
              </div>
            )
          }
 
        </FormControl>
    </div>
  );
};

FilterForm.propTypes = {
  checkBoxFilterData: PropTypes.array,
  handleCheckboxGroupChange: PropTypes.func,
  checkBoxSectionTitle: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleClear: PropTypes.func,
}

export default FilterForm;