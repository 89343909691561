import { authHeaders } from "../utils/api";
import {axiosTaasInstance as axios} from "../axios.config";
import config from "../config";
import _ from "lodash";
import * as tconTypes from './tconnectActionTypes';

const API_URL = config.tdm_gateway_api.uri + "/tdm";

export const fetchCustomerData = (env = "QA2", cidn) => async dispatch => {
    try {
        const tconnectCustomerRequest = {
            env: env,
            cidn: cidn
        }
        dispatch({ type: tconTypes.TCONNECT_GET_VIEW_DATA_LOADING })
        const response = await axios.post(`${API_URL}/salesforce/b2b/customer/account`, tconnectCustomerRequest, { headers: authHeaders() })
        dispatch({ type: tconTypes.TCONNECT_GET_VIEW_DATA_SUCCESS, payload: response.data })      
        return response.data;
    } catch (e) {
        dispatch({ type: tconTypes.TCONNECT_GET_VIEW_DATA_ERROR })
        throw e
    }
}

export const onboardTConContact = (contact) => async dispatch => {
    try {
        dispatch({ type: tconTypes.TCONNECT_ONBOARD_USER_LOADING })
        const response = await axios.post(`${API_URL}/tconnect/b2b/onboard/caiman`, { ...contact }, { headers: authHeaders() })
        dispatch({ type: tconTypes.TCONNECT_ONBOARD_USER_SUCCESS, payload: {...contact, valid:response.data.caimanResource.active }})
        return contact
    } catch (e) {
        throw e;
    }
}

export const updateCapabilities = request => async dispatch => {
    try {
        var updateCapabilityRequest = {
            capabilityRequestDto: request
        }
        dispatch({ type: tconTypes.TCONNECT_UPDATE_CAPABILITIES_LOADING })
        const response = await axios.post(`${API_URL}/tconnect/b2b/onboard/capability`, { ...updateCapabilityRequest }, { headers: authHeaders() })
        dispatch({ type: tconTypes.TCONNECT_UPDATE_CAPABILITIES_SUCCESS, payload: response.data })
        return response.data;
    } catch (e) {
        dispatch({ type: tconTypes.TCONNECT_UPDATE_CAPABILITIES_ERROR })
        throw e
    }
}

export const fetchOrdersByCidn = cidn => async dispatch => {
    try {
        const ordersRequest = {
            cidn: cidn
        }
        dispatch({ type: tconTypes.TCONNECT_GET_ORDERS_LOADING })
        const response = await axios.post(`${API_URL}/tconnect/b2b/datahub/orders`, ordersRequest, { headers: authHeaders() })
        dispatch({ type: tconTypes.TCONNECT_GET_ORDERS_SUCCESS, payload: response.data })
        return response.data;
    } catch (e) {
        dispatch({ type: tconTypes.TCONNECT_GET_ORDERS_ERROR })
        throw e
    }
}

export const submitOrderDetails = (obj) => async dispatch =>{
    try {
        dispatch({ type: tconTypes.TCONNECT_SUBMIT_ORDER_LOADING })
        const response = await axios.post(`${API_URL}/tconnect/b2b/datahub/order/create`, obj, { headers: authHeaders() })
        dispatch({ type: tconTypes.TCONNECT_SUBMIT_ORDER_SUCCESS, payload: response.data })
        return response.data;
    } catch (e) {
        dispatch({ type: tconTypes.TCONNECT_SUBMIT_ORDER_ERROR })
        throw e
    }
}

export const submitEditOrderDetails = (obj) => async dispatch =>{
    try {
        dispatch({ type: tconTypes.TCONNECT_SUBMIT_ORDER_LOADING })
        const response = await axios.post(`${API_URL}/tconnect/b2b/order/update`, obj, { headers: authHeaders() })
        dispatch({ type: tconTypes.TCONNECT_SUBMIT_ORDER_SUCCESS, payload: response.data })
        return response.data;
    } catch (e) {
        dispatch({ type: tconTypes.TCONNECT_SUBMIT_ORDER_ERROR })
        throw e
    }
}

export const generateServiceId = (qty) => async dispatch => {
    try {
        dispatch({ type: tconTypes.TCONNECT_GET_ORDERFNN_LOADING })
        let response = await axios.get(`${API_URL}/replicator/generate/serviceIds`, { headers: authHeaders(), params: {
            qty
        } });
        dispatch({ type: tconTypes.TCONNECT_GET_ORDERFNN_SUCCESS, payload : response.data[0] })
        return {serviceId: response.data[0]};
    } catch (e) {
        console.error(e);
        dispatch({ type: tconTypes.TCONNECT_GET_ORDERFNN_ERROR })
        return new Error("Generate fnn failed");
    }
};
export const salesforceCreateContact = (sfAccountId, customerId, cidn, env = 'QA2', email, role, profile) => async dispatch => {
    try {
        const contactRequest = {
            sfAccountId,
            profile,
            customerId,
            env,
            email,
            role
        }
        
        dispatch({ type: tconTypes.TCONNECT_ADD_CONTACT_LOADING})
        const response = await axios.post(`${API_URL}/salesforce/b2b/customer/create/contact`, contactRequest, { headers: authHeaders() })
        dispatch(fetchCustomerData(env, cidn));
        dispatch({ type: tconTypes.TCONNECT_ADD_CONTACT_SUCCESS, payload: response.data })
        return response.data;      
    } catch (error) {
        console.error(error);
        const payload =  _.get(error, 'response.data.responseDescription', '');
        dispatch({ type: tconTypes.TCONNECT_ADD_CONTACT_ERROR , payload:payload})
        return error.response.data
    }
}

