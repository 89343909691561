import React, { useEffect, Fragment, useState } from "react";
import { connect } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Typography, TextField, IconButton, DialogActions } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { handleFilterByTags } from '../../actions/retrieveDataActions';
import './customDataset.css'

const StyledChipInput = withStyles(theme => ({
    root: {
        border: 'none',
    },
    input: {
        border: 'none !important',
    }
}))(ChipInput);

const FilterDatasetByTags = (props) => {
    
    const [datasetTags, setDatasetTags] = useState(props.tags);
    const [selectedTag, setSelectedTag] = useState();
    const {tagsList, setTagsList} = props;

    const handleTagChange = (value) => {
        if(value !== "" && value !== " " && value !== undefined && value !== null)
       { 
        let tagList = [...tagsList];
        tagList.push(value);
        let dataTags = datasetTags.filter((tagData) => tagData !== value)
        setDatasetTags(dataTags);
        setTagsList(tagList);
        setSelectedTag(value)
        props.handleDatasetTags(tagList)}
    }

    const handleDeleteTag = (value) => {
        if(value !== "" && value !== " " && value !== undefined && value !== null)
       { 
        let datasetTag = [...datasetTags];
        datasetTag.push(value);
        let tagList = tagsList.filter((tag) => tag !== value)
        setSelectedTag()
        setTagsList(tagList);
        setDatasetTags(datasetTag);
        props.handleDatasetTags(tagList)}
    }


    return (
        <Fragment>
            <div class="row mt-4 ml-2">
                <div class="col-5 customHeaders">
                    Filter By Tags
                </div>
                <div class="col-5 ml-1 customHeaders">
                    Quantity
                </div>
            </div>
            <div class="row">
                <div class="col-5 ml-3">
                    <Autocomplete
                        options={datasetTags}
                        getOptionLabel={(option) => option}
                        id="customdataFiltersAutoComplete"
                        value={selectedTag}
                        onChange={(event, value) => handleTagChange(value)}
                        autoComplete
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Tags"
                            />
                        )}
                    />
                </div>
                <div class="col-5 mt-n3" >
                <TextField hintText="Enter Quantity"
                                variant="outlined"
                                className="qtyText mt-4"
                                floatingLabelText="Quantity"
                                name="qty"
                                value={props.selectedQuantity}
                                error={props.isError}
                                id="qtySelected"
                                helperText={props.isError ? props.errorText : "Max Quantity : "+props.modalData.quantity}
                                type="number"
                                onChange={e => props.handleQtyChange(e, props.modalData.quantity)}
                            />
                            </div>
            </div>

            {tagsList.length > 0 ? <div class="row mt-3 ml-2">
                <div class="col-10">
                    <StyledChipInput
                        value={tagsList.map(chip => chip)}
                        blurBehavior='add'
                        allowDuplicates={false}
                        fullWidth={true}
                        onDelete={(chip) => handleDeleteTag(chip)}
                    />
                </div>
            </div> : <div />}
        </Fragment>
    )

}
export default FilterDatasetByTags