import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import { AddCircleOutlined as AddCircleIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useAddIconStyle = makeStyles((theme) => ({
  button: {
    width: (props) =>
      props.size === "medium" ? 40 : props.size === "small" ? 30 : null,
    height: (props) =>
      props.size === "medium" ? 40 : props.size === "small" ? 30 : null,
    background: theme.palette.background.paper,
    borderRadius: (props) => (props.variant === "square" ? 0 : null),
    border: `2px solid ${theme.palette.icon.border}`,
    "&:hover": {
      background: theme.palette.icon.hover.backgroundColor,
      transform: "translateY(-2px)",
      "& $icon": {
        color: theme.palette.background.paper,
      },
    },
  },
  icon: {
    color: theme.palette.icon.color,
  },
}));

const defaultSize = "medium";
const defaultVariant = "rounded";

const AddIcon = (props) => {
  const { size = defaultSize, variant = defaultVariant, ...rest } = props;
  const addIconStyle = useAddIconStyle(props);

  return (
    <IconButton
      size={size}
      variant={variant}
      className={addIconStyle.button}
      {...rest}
    >
      <AddCircleIcon fontSize="inherit" className={addIconStyle.icon} />
    </IconButton>
  );
};

AddIcon.propTypes = {
  size: PropTypes.oneOf(["medium", "small"]),
  variant: PropTypes.oneOf(["rounded", "square"]),
};

export default AddIcon;
