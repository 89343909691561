import _ from "lodash";
import * as types from "../actions/u2cActionTypes";

const INITIAL_STATE = {
  banLoading: false,
  billingAccountNumber: undefined,
  banFailed: false,
  billingAccount: {
    billingAddress: {}
  },
  billingAdjustments: [],
  adjustment: {},
  billingAddressLoading: false,
  billingAddressFailed: false,

  banLevelWithDate: {
    payments: []
  },

  savingPending: false,


  alertMessage: "",
  selected: [
    { label: "Yes", icon: "icon-check-usage", disabled: false },
    { label: "No", icon: "icon-risk-management" }
  ],
  submitSubscription: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_BILLING_ADDRESS:
      return { ...state, billingAccount: { ...state.billingAccount, ...action.payload } };
    case types.BAN_LOADING:
      return {
        ...INITIAL_STATE,
        banLoading: true,
        alertMessage: "Genrating...",
        className: "loading",
        //to make tile disabled when loading
        selected: state.selected.map(data => {
          if (data.label === "Yes") {
            const newData = data;
            newData.disabled = true;
            return newData;
          }
          return data;
        })
      };

    case types.GENERATE_BAN:
      return {
        ...state,
        banFailed: false,
        billingAccountNumber: action.payload,
        banGenerated: true,
        banLoading: false,
        alertMessage: "Genrated",
        className: "success"
      };

    case types.GENERATE_BAN_FAILED:
      return {
        ...state,
        banFailed: true,
        banLoading: false,
        banGenerated: false,
        alertMessage: "Generate Failed",
        className: "failed",
        selected: state.selected.map(data => {
          if (data.label === "Yes") {
            data.disabled = undefined;
          }
          return data;
        })
      };

    case types.SET_BAN:
      return {
        ...state,
        banLoading: false,
        billingAccountNumber: action.payload,
        // billingAddressFailed: false
        alertMessage: "",
        className: "",
        selected: state.selected.map(data => {
          if (data.label === "Yes") {
            data.disabled = undefined;
          }
          return data;
        })
      };

    case types.BILLING_ADDRESS_LOADING:
      return {
        ...state,
        billingAddressLoading: true,
        billingAddressFailed: false,
        alertMessage: "Retrieving...",
        className: "loading"
      };

    case types.GET_BILLING_ADDRESS:
      return {
        ...state,
        billingAccount: action.payload,
        billingAddressLoading: false,
        alertMessage: ""
      };

    case types.BILLING_ADDRESS_FAILED:
      return {
        ...state,
        billingAddressFailed: true,
        billingAddressLoading: false,
        alertMessage: "Unable to Retrieve",
        className: "failed",
        savingPending: false,
        submitSubscription: false,
        selected: state.selected.map(data => {
          if (data.label === "Yes") {
            data.disabled = undefined;
          }
          return data;
        })
      };

    case types.RESET_U2C_DATA:
      return {
        billingAccount: state.billingAccount,
        ...INITIAL_STATE,
        selected: state.selected.map(data => {
          if (data.label === "Yes") {
            data.disabled = undefined;
          }
          return data;
        })
      };

    case types.CREATE_ACCOUNT_PENDING:
      return {
        ...state,
        createAcPending: true,
        createAcFailed: false,
        alertMessage: "Creating...",
        className: "loading"
      };

    case types.CREATE_BILLING_ACCOUNT:
      return {
        ...state,
        createAcPending: false,
        createAcSuccess: true,
        alertMessage: "Created",
        className: "success"
      };

    case types.CREATE_ACCOUNT_FAILED:
      return {
        ...state,
        createAcPending: false,
        createAcSuccess: false,
        createAcFailed: true,
        alertMessage: "Failed !! ",
        className: "failed",
        selected: state.selected.map(data => {
          if (data.label === "Yes") {
            data.disabled = undefined;
          }
          return data;
        })
      };

    case types.LOAD_PAYMENT_WITH_DATE:
      return {
        ...state,
        banLevelWithDate: { ...action.payload, paymentsPending: false }
      };

    case types.LOAD_PAYMENT_WITH_DATE_PENDING:
      return {
        ...state,
        banLevelWithDate: { ...state.banLevelWithDate, payments: [], paymentsPending: true, retrivePaymentFailed: false }
      };
    case types.LOAD_PAYMENT_WITH_DATE_FAILED:
      return {
        ...state,
        banLevelWithDate: { ...state.banLevelWithDate, payments: [], paymentsPending: false, retrivePaymentFailed: true }
      };

    case types.LOAD_PAYMENT_WITH_TRANSACTION_PENDING:
      return {
        ...state,
        banLevelWithDate: {
          ...state.banLevelWithDate,
          payments: state.banLevelWithDate.payments.map(data => {
            if (action.transactionId === data.transactionId) {
              data.invoiceNumber = "loading...";
            }
            return data;
          })
        }
      };
    case types.LOAD_PAYMENT_WITH_TRANSACTION:
      return {
        ...state,
        banLevelWithDate: {
          ...state.banLevelWithDate,
          payments: state.banLevelWithDate.payments.map(data => {
            if (action.payload.transactionId == data.transactionId) {
              data.invoiceNumber =
                action.payload.invoiceNumber || "Not Available";
            }
            return data;
          })
        }
      };

    case types.LOAD_PAYMENT_WITH_TRANSACTION_FAILED:
      return {
        ...state,
        banLevelWithDate: {
          ...state.banLevelWithDate,
          payments: state.banLevelWithDate.payments.map(data => {
            if (action.transactionId === data.transactionId) {
              data.invoiceNumber = "Not Available";
            }
            return data;
          })
        }
      };

    case types.GET_BILLING_ADJUSTMENTS:
      return {
        ...state,
        billingAdjustments: action.payload,
        savingPending: false
      };
    case types.SAVING_PENDING:
      return { ...state, savingPending: true, submitSubscription: false };

    case types.ADD_ADJUSTMENT:
      return { ...state, adjustment: { ...state.adjustment, ...action.payload } };

    case types.CREATE_BILLING_ADJUSTMENT:
      return {
        ...state
      };
    case types.SAVING_SUCCESS:
      return { ...state, savingPending: false, submitSubscription: false };

    case types.SAVING_FAILED:
      return { ...state, savingPending: false, submitSubscription: false };

    case types.RESET_CREATE_ADJ_FORM:
      return { ...state, adjustment: {} };

    case types.SUBMIT_SUBSCRIPTION_PENDING:
      return { ...state, submitSubscription: true };

    case types.SUBMIT_SUBSCRIPTION_SUCCESS:
      return { ...state, submitSubscription: false };
    case types.SUBMIT_SUBSCRIPTION_FAIL:
      return {...state, submitSubscription: false }
    default:
      return state;
  }
};
