import _ from 'lodash';
import * as types from '../actions/actionTypes';


const INITIAL_STATE = {  
    checkedOutData : [],
    dataSet : {},
    checkedInData : [],
    viewCheckedOutData : {},
    customDataset : {},
    editableDataset : {},
    filtersConfigured : {},
    checkinStatus : "SUCCESS",
    errorMsg : "",
    customDataBankDataset : [],
    customDataBankDatasetPending: false,
    customDataBankDatasetFailed: false,
    checkedDataSummary : [],
    excelResponsemessage : "",
    excelResponseCount : "",
    checkedInJsonDocs : [],
    tags : [],
    excelData : {},
    updateTags : [],
    datasetPageType:null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.UPDATE_DATASET_TYPE:
            return {
                datasetPageType: action.payload
            }
            case types.CUSTOM_EXCEL_SUCCESS: 
            return {
                ...state,
                excelResponsemessage : action.excelResponsemessage,
                excelResponseCount : action.excelResponseCount
            }
            case types.CUSTOM_EXCEL_FAIL: 
            return {
                ...state,
                excelResponsemessage : action.excelResponsemessage,
                excelResponseCount : ""
            }
            case types.CHECKOUT_DATA_SET:
            return {
                ...state,
                checkedOutData: action.payload,
                checkedDataSummary : action.checkedDataSummary
            }
            case types.CHECKIN_DATA_SET:
            return {
                ...state,
                checkedInData: action.payload
            }
            case types.CREATE_DATA_SET:
            return {
                ...state,
                dataSet: action.payload,
                filtersConfigured : {},
            }
            case types.CHECKIN_DATA_SUCCESS:
                return {
                    ...state,
                    checkedOutData : {},
                    dataSet : {},
                    checkedInData : [],
                    checkinStatus : "SUCCESS"
                }
            case types.CHECKIN_DATASET_FAILED:
                return {
                    ...state,
                    checkedOutData : {},
                    dataSet : {},
                    checkedInData : [],
                    checkinStatus : "FAILED",
                    errorMsg : action.payload
                }
            case types.VIEW_CHECKOUT_DATA:
                return {
                    ...state,
                    viewCheckedOutData : action.payload
                }
            case types.EDIT_DATASET_DATA:
                return {
                    ...state,
                    editableDataset : action.payload,
                    customDataset : action.payload
                }
            case types.CHECKED_IN_JSON_DOCS:
                return {
                    ...state,
                    checkedInJsonDocs : action.payload
                }
            case types.CUSTOM_DATA_RETRIEVED:
                return {
                    ...state,
                    customDataBankDataset : action.payload,
                    customDataBankDatasetPending: false,
                    customDataBankDatasetFailed: false
                }
                case types.DECREASE_COUNT:
                    let newCustomDataBankDataSet=state?.customDataBankDataset?.content?.map(obj=>{
                        if(obj.id === action.payload){
                            obj.quantity=obj.quantity-action.payload2;
                            return obj;
                        }return obj;
                    });
                    console.log(newCustomDataBankDataSet)
                    return {
                        ...state,
                        customDataBankDataset : {
                            content: newCustomDataBankDataSet
                        }
                    }
            case types.CUSTOM_DATA_PENDING:
                return {
                    ...state,
                    customDataBankDataset: [],
                    customDataBankDatasetPending: true,
                    customDataBankDatasetFailed: false
                }
            case types.CUSTOM_DATA_FAILED:
                return {
                    ...state,
                    customDataBankDataset: [],
                    customDataBankDatasetPending: false,
                    customDataBankDatasetFailed: true
                }
            case types.SET_TAGS:
                return {
                    ...state,
                    tags : action.payload
                }
            case types.CONFIGURE_FILTERS:
                return {
                    ...state,
                    filtersConfigured : action.payload
                }
            case types.SET_EXCEL_JSON: 
                return {
                    ...state,
                    excelData : action.payload
                }

            case types.UPDATE_TAGS:
                return {
                    ...state,
                    updateTags : action.payload
                }
            
            case types.CREATE_DATASET_FAILED:
                return{
                    ...state
                }
            
            case types.CREATE_DATASET_TIMEOUT_FAILED:
                return{
                    ...state
                }
            case types.CREATE_DATASET_SUCCESS:
                return{
                    ...state,
                    customDataset : action.payload
                    }
            
        default:
            return state;
    }
}