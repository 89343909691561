import React, { Component } from 'react';
import {Table, Collapse} from "reactstrap";
import { connect } from 'react-redux';
import {SubHeader} from "../../../../common/custom/SubHeader";
import { renderQtyIcon } from '../../../../../utils/ui.js';

class ReplicatorTable extends Component {
    constructor(props){
        super(props);
        this.state = {
            view: {},
            products:[]
        };
        
    }

    resetProductsStructure = replicatorDetails => {
        let products = [];
        replicatorDetails.forEach(replicator => {
            if(replicator.product === 'Mobile') {
                return;
            }
            if(products.length <= 0 || products.filter(p => p.product === replicator.product).length <= 0){
                products.push({
                    product: replicator.product,
                    serviceData: [this.buildProduct(replicator)]
                });
            } else {
                products.forEach(p => {
                    if(p.product === replicator.product){
                        p.serviceData.push(this.buildProduct(replicator));
                    }
                })
            }
        })
        products = this.sortingProdcutsByName(products);
        this.setState({products});
    }

    buildProduct = (replicator) => {
        return {
            fnn: replicator.fnn,
            adborId: replicator.adborId,
            deleted: replicator.deleted,
            inflightOrder: replicator.inflightOrder,
            ranges: replicator.ranges
        }
    }

    sortingProdcutsByName = (products) => {
        const orderedProducts = [];
        const productNames = ["PSTN", "ISDN2", "ISDN2DID","ISDN30"];
        productNames.forEach(name => {
            let p = products.filter(p => p.product === name)[0];
            if(p !== undefined) {
                orderedProducts.push(p);
            }
        });
        return orderedProducts;
    } 

    componentDidMount() {
        this.resetProductsStructure(this.props.replicatorDetails);
    }
    
    render(){
        const products = this.state.products;
        const {customerDetails, replicatorDetails} = this.props;
        const cidn = customerDetails[0].cidn;        

        return (
            <React.Fragment>
                {(customerDetails && replicatorDetails && 
                   customerDetails !== undefined && replicatorDetails !== undefined && customerDetails.length > 0 && 
                   <React.Fragment>
                       <SubHeader
                           text={"CIDN - "+cidn}
                           iconClass={"td-icon-md icon-crowd-support"}
                           iconColor="turquoise"
                           data={products}
                           filename={`Replicator`}/>
                   
                   <Table responsive hover>
                       <thead>
                           <tr>
                               <th>Product</th>
                               <th>Status</th>
                               <th># Items</th>
                               <th>Details</th>                               
                           </tr>
                       </thead>
                       <tbody>
                           {products.map((item, index) => {
                                const { product, serviceData } = item;
                                const { deleted } = serviceData[0];
                                const isOpen = Object.values(this.state.view).filter(view => view).length > 0;
                                const rowStyles = { "backgroundColor": isOpen && !this.state.view[product] && "rgba(211, 211, 211, 0.18)", "opacity": (isOpen && !this.state.view[product]) ? 0.45 : 1 };
                                return (
                                    <React.Fragment key={index}>
                                    <tr style={rowStyles}>
                                        <td>{product}</td>
                                        <td className={!deleted ? "green" : "red"}>{!deleted ? "INSERTED" : "DELETED"}</td>
                                        <td>{renderQtyIcon(serviceData.length)}</td>                                                                                        
                                        <td>
                                            <button
                                                className="btn btn-sm btn-block btn-outline-primary ld-over" style={{width: "30%"}}
                                                onClick={() => this.setState({ view: { ...this.state.view, [product]: !this.state.view[product] } })}
                                            >                                                    
                                            {
                                                this.state.view[product]
                                                    ? <span><span>Hide </span><i style={{ paddingLeft: "inherit" }} className="fa fa-eye-slash fa-lg" aria-hidden="true" /></span>
                                                    : <span><span>View </span> <i style={{ paddingLeft: "inherit" }} className="fa fa-eye fa-lg" aria-hidden="true" /></span>
                                            }
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="8" style={{ "borderTop": "none", "padding": 0 }}>
                                            <Collapse isOpen={this.state.view[product]} style={rowStyles}>
                                                <div style={{ marginBottom: "20px", backgroundColor: "#e6e6e69c", borderRadius: "20px" }}>
                                                    <Table striped>
                                                        <thead>
                                                            <tr>
                                                                <th>Adbor ID</th>
                                                                <th>FNN</th>
                                                                <th>From</th>
                                                                <th>To</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {serviceData.map((service, i) => { 
                                                                if(service.ranges.length === 0) service.ranges.push({from:"-", to:"-"});
                                                                return service.ranges.map((range, j) =>
                                                                    <tr key={i+j} className={!service.deleted ? "text-success" : "text-danger"}>
                                                                        <td>{j === 0 ? service.adborId : "-"}</td>
                                                                        <td>{j === 0 ? service.fnn : "-"}</td>
                                                                        <td>{range.from}</td>
                                                                        <td>{range.to}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>        
                                            </Collapse>
                                        </td>
                                    </tr>
                                    </React.Fragment>
                                )
                            })}
                       </tbody>
                       </Table>               
                    </React.Fragment>
                )}
            </React.Fragment>            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.replicatorData.filters
    }
}

export default connect(mapStateToProps, null) (ReplicatorTable);