import React, { useState } from 'react';
import PropTypes from "prop-types";
import {
    TextField,
    InputAdornment,
    Grid,
    Typography,
    CircularProgress,
    Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    dropdownInput: {
        display: 'block',
        padding: '5px',
        margin: '0px 0px 0px -4px',
        paddingTop: '0px',
        paddingBottom: '0px',
        '& .MuiInputLabel-outlined': {
            padding: '10px',
            color: 'red',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            backgroundColor: 'white',
            height: '44px',
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0px 9px'
        },
        '& .MuiInputAdornment-root': {
            position: 'unset'
        }
    },
    dropdownInputSmall: {
        display: 'block',
        padding: '5px',
        margin: '0px 0px 0px -4px',
        paddingTop: '0px',
        paddingBottom: '0px',
        '& .MuiInputLabel-outlined': {
            padding: '10px',
            color: 'red',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0.65rem',
            backgroundColor: 'white',
            height: '36px',
        },
        '& .MuiAutocomplete-inputRoot': {
            padding: '0px 9px'
        },
        '& .MuiInputAdornment-root': {
            position: 'unset'
        }
    },
    validateStar: {
        color: 'red',
        margin: '0px 5px',
        display: 'inline-block',
        paddingBottom: '0px'
    },
    section: {
        margin: '10px 0px 5px 0px !important',
        fontSize: '15px',
        fontWeight: '400'
    },
}))

const FormAutoComplete = (props) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const {
        label = "",
        loading = false,
        required = false,
        error = false,
        helperText = " ",
        loadOptionFunc,
        options = [],
        objectOptions = [],
        endBtn = <></>,
        disableLabel = false,
        size = "large",
        inputExtraProps,
        ...rest
    } = props;

    return (
        <>
            {
                !disableLabel ? 
                <Grid container direction="row">
                    <Typography color="textSecondary" gutterBottom variant="h6" className={classes.section}>
                        {props.label}
                    </Typography>
                    {
                        props.required ? <Typography variant="h6" className={classes.validateStar}>*</Typography> : <></>
                    }
                </Grid>
                : null
            }
            <Box display="flex">
                <Autocomplete
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                        loadOptionFunc?.();
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    options={[...options, ...objectOptions]}
                    getOptionLabel={objectOptions.length > 0 ? option => option.label : option => option}
                    loading={loading}
                    {...rest}
                    renderInput={(params) => 
                        <TextField 
                            {...params}
                            {...inputExtraProps}
                            variant="outlined" 
                            fullWidth 
                            className={size === "large" ? classes.dropdownInput : classes.dropdownInputSmall}
                            helperText={helperText}
                            error={error}
                            InputProps={{
                                ...params.InputProps,      
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {loading && <CircularProgress size={20} color={'secondary'} />}
                                        {params.InputProps.endAdornment}
                                    </InputAdornment>
                                ),
                            }} 
                        />
                    }
                />
                {endBtn}
            </Box>
        </>
    )
}

FormAutoComplete.propTypes = {
    label: PropTypes.string,
    loading: PropTypes.bool,
    required: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    options: PropTypes.array,
    objectOptions: PropTypes.array,
    endBtn: PropTypes.any,
    disableLabel: PropTypes.bool,
    size: PropTypes.string,
    loadOptionFunc: PropTypes.func,
}

export default FormAutoComplete;