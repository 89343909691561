import _ from 'lodash';
import * as types from '../actions/actionTypes';
import { removeDomainText } from '../utils/ssoAuth';


const INITIAL_STATE = {
    userStatus : {},
    onboard : {
        loadTeamDetailsLoading : false
    },
    checkTokenLoading: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SSO_AUTH_VALIDATE_RESPONSE : 
        return {
            ...state,
            userStatus : {
                ...action.payload,
                userId: removeDomainText(action.payload.userId)
            }
        }

        case types.SSO_AUTH_TOKEN : 
        return {
            ...state,
            taasToken : action.payload
        }


        case types.SSO_AUTH_VALIDATE_ERROR : 
        return {
            ...state,
            userStatus : {
                errorMessage: action.message
            }
        }

        case types.SSO_AUTH_RESET : 
        return {
            ...state,
            userStatus : {}
        }

        case types.SSO_CHECK_TOKEN_LOADING :
            return {
                ...state,
                checkTokenLoading : action.payload
            }

        case types.LOAD_LEAD_DETAILS_LOADING : 
            return {
                ...state,
                onboard : {
                    loadTeamDetailsLoading : true
                }
            }

        case types.LOAD_LEAD_DETAILS_SUCCESS : 
        return {
            ...state,
            onboard : {
                ...action.payload,
                loadTeamDetailsLoading : false
            }
        }

        case types.LOAD_LEAD_DETAILS_ERROR : 
        return {
            ...state,
            onboard : {
                loadTeamdetailsError : action.payload,
                loadTeamDetailsLoading : false
            }
        }

        case types.SSO_AUTH_SET_ACTIVE_STATUS : 
        return {
            ...state,
            userStatus : {
                ...state.userStatus,
                activeStatus: action.payload
            },
        }

        default:
            return state;
    }
}