export const UNMS_INSERT_FORM = "unmsInsert";
export const UNMS_UPDATE_FORM = "unmsUpdate";
export const INITIAL_VALUE = {
    ranges: [{ startRange: "", endRange: "" },]
};

export const poolType  = ["CONTIGUOUS", "NON_CONTIGUOUS"];

export const SNRM_UPDATE_FORM = "snrmUpdateForm";

export const B2B_CLEANUP_FORM = "b2bCleanUpForm";
export const B2B_CLEANUP_APPLICATION  = "B2b_cleanUpApplication";