import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { Badge } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import indigo from '@material-ui/core/colors/indigo';

const StyledBadge = withStyles((theme) => ({
    badge: {
      right: 2,
      top: 2,
    },
}))(Badge);

const useClasses = makeStyles({
    root: {
        padding: "3px",
        background: "transparent",
        minWidth: "auto",
        boxShadow: "none",
        "&:hover":{
            backgroundColor: "transparent",
            boxShadow: "none",
        },
        "&:focus": {
            outline: "none"
        }
    },
    icon: {
        "&:hover ": {
            color: indigo[400],
            fontSize: "3rem"
        }
    },
});

const CartIconBtn = (props) => {
  const { badgeCount, onBtnClick, ...rest } = props;
  const classes = useClasses();

  return (
    <Button
        variant="contained"
        onClick={onBtnClick}
        color="default"
        className={classes.root}
        {...rest}
    >
        <StyledBadge badgeContent={badgeCount} color={"secondary"}>
            <ShoppingCartOutlinedIcon className={classes.icon} color="primary" style={{"fontSize":"3rem"}}/>
        </StyledBadge>
    </Button>
  );
};

CartIconBtn.propTypes = {
    badgeCount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    onBtnClick: PropTypes.func.isRequired,
};

export default CartIconBtn;
