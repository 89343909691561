import Avatar from 'react-avatar';
import React from 'react'
import ReactTooltip from 'react-tooltip';
import { Col, Container, Row, Table, Spinner } from 'reactstrap';
import classNames from 'classnames';
import { RowIconItem } from '../common/tables/RowIconItem';
import swal from 'sweetalert2'
import { isEmail } from 'validator';
import ReactDOMServer from 'react-dom/server';


export default function TConnectContactView({ 
    contacts, isLoading, tconOnboardRef, 
    tconCapabilityRef, onSelect, selected,  
    tconCustomer, env, profile, userEmail,
    addContact, salesforceCreateContact}) {

    const renderAddButton = (loading, contact, action) => {
        return (
            <span>
                <button
                    className={classNames("btn btn-sm btn-outline-info ld-over m-1", { running: loading })}
                    onClick={e => action(e)}>
                    {< i className="fa fa-plus" aria-hidden="true" > </i>}
                    {loading && <div className="ld ld-ring ld-spin"></div>}
                    <ReactTooltip type={"error"} effect="solid" place="bottom" />
                </button>
            </span>
        )
    }
    const renderAddNewContact = (action) => {
        return (
            <span>
                <button
                    className="btn btn-sm btn-outline-info ld-over m-1"
                    onClick={e => action(e)}>
                    {< i className="fa fa-plus" aria-hidden="true" > </i>}
                </button>
            </span>
        )
    }
    const handleNewContactModal = () => {
        const newContactHtml = (
            <Container>
                <Row>
                    <Col sm="3" style={{ display: "flex", alignItems: "center" }}>
                        <div className="swal2-header">Email:</div>
                    </Col>
                    <Col sm="9">
                        <input type="email" id="new-contact-email" className="swal2-input" 
                        readOnly = {addContact.pending} value={userEmail}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="3" style={{ display: "flex", alignItems: "center" }}>
                        <div className="swal2-header">Role:</div>
                    </Col>
                    <Col sm="9">
                        <select id="new-contact-role" className="swal2-input">
                            <option>Full Authority</option>
                            <option>Limited Authority</option>
                            <option>Non-Authorised</option>
                            <option>Legal Lessee</option>
                            <option>3rd Party</option>
                            <option>Billing Contact</option>
                            <option>Asset User</option>
                            <option>Advocate</option>
                            <option>Nominated Serv Cust</option>
                        </select>
                    </Col>
                </Row>
            </Container>
        )
        const dialog = ()=>{
            return swal.fire({
                title: "Add Contact",
                html: ReactDOMServer.renderToStaticMarkup(newContactHtml),
                showCancelButton: true,
                button: {
                    closeModal: false,
                },
                preConfirm: () => {
                    const email = document.getElementById('new-contact-email').value;
                    const role = document.getElementById('new-contact-role').value
                    if(!isEmail(email)){
                        swal.showValidationMessage(`Please provide valid email`);
                    }
                    if(!role){
                        swal.showValidationMessage(`Please select valid role`);
                    }
                    return {
                        email:email,
                        role:role
                    }
                }
            })
        }
        
        dialog().then((response) => {
            const values = { ...response.value };
            if(values.email && values.role){
                swal.fire({
                    title: "Adding Contact",
                    html: ReactDOMServer.renderToStaticMarkup(<Spinner/>),
                    showConfirmButton:false,
                    showCancelButton: false,
                    allowOutsideClick: false
                    },
                )
                salesforceCreateContact(tconCustomer.id, tconCustomer.customerId, tconCustomer.cidn, 
                    env, values.email, values.role, profile).then((data)=>{
                        let status = data.responseStatus;
                        status === 'Success'? swal.fire(status, data.responseDescription, status.toLowerCase()):
                        swal.fire(status, data.responseDescription, 'error');
                    })
            }else{
                console.error("Invalid email and role");
            }
        })
    }
    return (
       
        <Table hover responsive>
            <thead>
                <tr>
                    <th>Email 
                        <span>
                            <button
                                className="btn btn-sm btn-outline-info ld-over m-1"
                                onClick={handleNewContactModal}>
                                {< i className="fa fa-plus" aria-hidden="true" > </i>}
                            </button>
                        </span>
                    </th>
                    <th>Type</th>
                    <th>Onboard</th>
                    <th>Capability</th>
                </tr>
            </thead>
            <tbody style={{ cursor: "hover" }}>
                {
                    contacts.map((contact, i) => {
                        const { id, firstName, lastName, type, email, valid} = contact;
                        const onboarded = valid;                   
                        return (
                            <tr key={id} onClick={() => onSelect(i)} className={selected === i ? "tcon-dv-row active" : "tcon-dv-row"}>
                                <td>{email}</td>
                                <td>
                                    <Avatar
                                        name={type}
                                        style={{"margin": "10px"}}
                                        size={25}
                                        round={false}
                                        textSizeRatio={2} />
                                    <ReactTooltip type="dark" effect="solid" place="right" id={id} />
                                </td>
                                <td>{!onboarded ? renderAddButton(isLoading, contact, () => tconOnboardRef.current.open(contact)) : <RowIconItem icon="icon-tick" color="green" />}</td>
                                <td>{onboarded ? renderAddButton(isLoading, contact, () => tconCapabilityRef.current.open(contact)) : "-"}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </Table>
    )
}

