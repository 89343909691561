import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { Button } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import swal from 'sweetalert2';
import { displayLoading, displaySuccess } from "../../common/alerts/alerts";
import ContactDataTableRow from './ContactDataTableRow';

import CustomerMailModal from './CustomerMailModal';
import CustomerUpdateSuccess from './CustomerUpdateSuccessModal';
import CustomerUpdateFailed from './CustomerUpdateFailedModal';
import CaimanRegisteredModal from './CaimanRegisteredModal'

import CaimanSuccess from './CaimanSuccessModal.js'
import CaimanFailed from './CaimanFailureModal.js'
import "./CustomerConsole.css";
import { displayFetchModel,registerCaiman,updateContactInSiebel } from '../../../actions/utilityActions';

import "./CustomerConsole.css";

class ContactDataTable extends Component {
    state = {
        showSiebelModal: false,
        showSeedAndSyncTooltip: false,
    }
    closeSiebelCustomerMailModal = () => {
        this.setState(prevState => ({
            ...prevState,
            showSiebelModal: false
        }));
    }

    openSiebelCustomerMailModal = () => {
        this.setState(prevState => ({
            ...prevState,
            showSiebelModal: true
        }));
    }

    registerCaimanUser = () => {
      const customerId = this.props.customerData.customerID;
      const RCRMContactId = this.props.selectedRow.contactId;
      const selectedEnv = this.props.selectedEnvValue;
      displayLoading({ title: "Register Caiman", bodyText: "Please standby while we register Caiman" })
      const data = {"customerId" : customerId,"contactId":RCRMContactId,"environment": selectedEnv}
      this.props.registerCaiman(data).then((result) => {
          swal.close();
      });
    }

    handleSeedAndSyncTooltip = (openClose) => {
        if (openClose) {
            if(!this.props.customerData.accountId){
                this.setState(prevState => ({
                    ...prevState,
                    showSeedAndSyncTooltip: true
                }));
            }
        } else {
            if(!this.props.customerData.accountId){
                this.setState(prevState => ({
                    ...prevState,
                    showSeedAndSyncTooltip: false
                }));
            }
        }
    }

    handleSeedAndSync = () => {
        let env = this.props.selectedEnvValue
        var request = [{"rcrmContactId":this.props.selectedRow.contactId,"prDeptOuId":this.props.selectedRow.deptId}]
        displayLoading({ title: "Seed & Sync", bodyText: "Please standby while we submit seed & Sync request" })
        this.props.updateContactInSiebel(env, request).then(() => {
            swal.close();
            if (this.props.updateContactsSuccess) {
                displaySuccess({ title: this.props.updateSiebelContactResponseMsg});
            } else if (this.props.updateContactsFailed) {
                swal.fire({
                    type: 'error',
                    title: 'Error',
                    html: this.props.updateSiebelContactResponseMsg,
                    confirmButtonText: 'OK',
                })
            }
        });
    }

    render() {
        const contactData = this.props.contactData;
        const updateIdentity = () => {
            swal.fire({
                type: 'warning',
                title: 'Are you sure',
                html: "<p>Do you want to update the customer identity?</p>",
                confirmButtonText: 'Yes',
                showCancelButton: true,
                cancelButtonText: 'No',
                showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.value === true) {
                    this.props.displayFetchModel();
                    this.openSiebelCustomerMailModal();
                }
            })
        }
        var updateDisabled = true;
        var regCaimanDisabled = true;
        const customerData = this.props.customerData;
        const selectedRow = this.props.selectedRow;
        if (this.props.selectedRow.identityProfileId === null && this.props.selectedRow.contactId !== null) { updateDisabled = false; regCaimanDisabled = false }
        else { updateDisabled = true; regCaimanDisabled = true }
        return (
            <div className="white contact-view-page">
                <span class="gradient-icon base-green ">
                    <h2>Contacts <i class="td-icon-sm icon-managed-phone theme-text-primary"></i></h2>
                </span>
                <section id="contact-table-area">
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th>RCRM ID</th>
                                <th>ID</th>
                                <th>Type</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>DOB</th>
                                <th>PHONE</th>
                                <th>Email</th>
                                <th>Address</th>
                                <th>Identity</th>
                            </tr>
                        </thead>
                        <tbody id="contact-details-table-body">
                            {
                                contactData.map((tableRowData, index) => {
                                    return (
                                        <Fragment>
                                            <ContactDataTableRow
                                                key={index}
                                                tableRowData={tableRowData}
                                            />
                                        </Fragment>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    {
                        contactData.length === 0 ? <h4 id="no-contacts">No Contacts</h4> : null
                    }
                </section>
                <Tooltip title="Customer needs to be Synced with Saleforce before contacts" placement="top" color='primary' open={this.state.showSeedAndSyncTooltip} onClose={()=>this.handleSeedAndSyncTooltip(false)} onOpen={()=>this.handleSeedAndSyncTooltip(true)} arrow>
                    <span>
                        <Button style={{"marginRight":"20px", "marginLeft":"20px"}} color="primary" disabled={!customerData.accountId || !selectedRow.contactId} onClick={() => this.handleSeedAndSync()}>Seed and Sync</Button>
                    </span>
                </Tooltip>
                <Button style={{ "marginRight": "20px" }} color="primary" disabled={updateDisabled} onClick={e => updateIdentity()}>Update identity</Button>
                <Button style={{ "marginRight": "20px" }} color="primary" disabled={regCaimanDisabled} onClick={e => this.registerCaimanUser()}>Register Caiman</Button>

                {this.props.displayUserModal && <CustomerMailModal showModal={this.state.showSiebelModal} closeModal={() => this.closeSiebelCustomerMailModal} />}
               
                 {!this.props.userUpdatePending && this.props.userUpdateSuccess && !this.props.userUpdateFailed &&
                   <CustomerUpdateSuccess/>
                }
                 {!this.props.userUpdatePending && !this.props.userUpdateSuccess && this.props.userUpdateFailed &&
                   <CustomerUpdateFailed/>
                }
                {this.props.caimanModal && <CaimanRegisteredModal/>}
                {!this.props.caimanPending && this.props.caimanSuccess && !this.props.caimanFailed &&
                   <CaimanSuccess/>
                }
                 {!this.props.caimanPending && !this.props.caimanSuccess && this.props.caimanFailed &&
                   <CaimanFailed/>
                }
            </div>

        )
    }

}

const mapStateToProps = (state) => {
    return {
        contactData: state.utility.siebelCustomer.customerContact,
        customerData: state.utility.siebelCustomer.customer,
        selectedRow: state.utility.siebelCustomer.selectedRow,
        displayUserModal: state.utility.siebelCustomer.displayUserModal,
        userUpdatePending: state.utility.siebelCustomer.isUserUpdatePending,
        userUpdateSuccess: state.utility.siebelCustomer.isUserUpdatesuccess,
        userUpdateFailed: state.utility.siebelCustomer.isUserUpdateFailed,
        caimanPending: state.utility.siebelCustomer.isCaimanRegistrationPending,
        caimanSuccess: state.utility.siebelCustomer.isCaimanRegistrationSuccess,
        caimanFailed: state.utility.siebelCustomer.isCaimanRegistrationFailed,
        caimanModal: state.utility.siebelCustomer.caimanModal,
        selectedEnvValue: state.auth.selectedEnvValue,
        updateSiebelContactResponseMsg: state.utility.siebelContacts.response,
        updateContactsFailed: state.utility.siebelContacts.isUpdateContactsFailed,
        updateContactsSuccess: state.utility.siebelContacts.isUpdateContactsSuccess,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        displayFetchModel: () => dispatch(displayFetchModel()),
        registerCaiman :(data) => dispatch(registerCaiman(data)),
        updateContactInSiebel:(env,request) => dispatch(updateContactInSiebel(env,request))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ContactDataTable);
