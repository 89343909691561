import React from "react";
import swal from "sweetalert2";
import _, { update } from "lodash";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import { submit } from 'redux-form'
import UnmsUpdate from "./UnmsUpdate";
import UnmsRangeView from "./UnmsRangeView";
import * as unmsActions from "../../../../actions/dataCleanUpActions";
import * as unmsConstants from "../util/constants";

import CheckoutFrame from "../util/CheckoutFrame";
import { displayLoading, customAlert } from '../../../common/alerts/alerts'
import * as constants from "../util/constants";
import "../util/cleanup.css";

class UnmsView extends React.Component {

  insertCheckout = (view) => {
    const { resourceType, ranges, unmsResourceType,selectedEnvironment } = this.props;
    const builtJsx = (
      <div>
        <div><span ><strong className="checkout-key">Resource Type: </strong><span>{unmsResourceType}</span></span></div>
        <div className="my-m"><span ><strong className="checkout-key">Number Range Type: </strong><span>{resourceType}</span></span></div>
        {ranges.map((data, index) => (
          <React.Fragment key={index}>
            <div className="my-m">
              <p className="py-m"><strong className="checkout-key">Start Range: </strong><span>{data.startRange}</span></p>
              <p className="py-m"><strong className="checkout-key" >End Range: </strong><span>{data.endRange}</span></p>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
    swal.fire({
      title: `${view} Confirmation`,
      html: ReactDOMServer.renderToStaticMarkup(builtJsx),
      type: "info",
      showCancelButton: true,
      cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> Cancel',
      confirmButtonColor: "#13a129",
      cancelButtonColor: "btn btn-danger",
      confirmButtonText: `<i class="fa fa-check" aria-hidden="true"></i> Confirm`,
      reverseButtons: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
    }).then(result => {
      if (result.value) {
        displayLoading({ title: "Inserting numbers into UNMS", bodyText: "Please standby whilst data is being inserted" });
        const requestBody = {
          type: resourceType,
          ranges: ranges,
          resourceType: unmsResourceType,
          environment : selectedEnvironment,
        }
        this.props
          .insertInUnms(requestBody)
          .then((response) => {
            if (response !== undefined) {
              const view = this.getSuccessView(response)
              const title = "Numbers Inserted";
              customAlert({ view, title });
            }
          });
      }
    });
  }


  getSuccessView = (response) => {
    const { batchJob, uploadedFiles } = response || {};
    const { jobDefinition, jobId } = batchJob || {};
    const type = uploadedFiles && uploadedFiles[0] && uploadedFiles[0].type;
    return (<div id="unms-success">
      <div id="unms-success-content">
        {type && <p><strong>Type: </strong>{type}</p>}
        {jobId && <p><strong>Job Id: </strong>{jobId}</p>}
        {jobDefinition && <p><strong>Job Definition: </strong>{jobDefinition}</p>}
      </div>
    </div>)
  }

  updateCheckout = (obj) => {
    displayLoading({ title: "Updating numbers", bodyText: "Please standby whilst data is being updated" });
    let updateData;
    if (this.props.unmsResourceType === "SIM/IMSI") {
      updateData = {
        imsiStart: this.props.updateRange[0].startRange,
        imsiEnd:   this.props.updateRange[0].endRange,
        imsiStatus:obj.updateImsi,
        simStatus: obj.updateSim,
        resourceType: this.props.unmsResourceType,
        environment : this.props.selectedEnvironment
      }
    } else if (this.props.unmsResourceType === "MSISDN") {
        updateData = {
          type: this.props.selectedFilters.update,
          range: this.props.updateRange[0],
          resourceType: this.props.unmsResourceType,
          environment : this.props.selectedEnvironment,
          resourcePoolName : this.props.resources.content?.[0].poolName
        }
      } 
    else {
      updateData = {
        type: this.props.selectedFilters.update,
        range: this.props.updateRange[0],
        resourceType: this.props.unmsResourceType,
        environment : this.props.selectedEnvironment
      }
    }
    
    this.props.updateResource(updateData, this.handleOnSearch);
  }
  
  confirmModal = (view, obj = {}) => {
    this.props.onCheckout(constants.UNMS_INSERT_FORM);

    const syncErrors = this.props.syncErrors;
    let errors = syncErrors.filter((data) => {
      return !_.isNull(data) && !_.isEmpty(data);
    })

    if (errors.length === 0) {
      switch (view) {
        case "Insert":
          this.insertCheckout(view);
          break;
        case "Update":
          this.updateCheckout(obj);
          break;
      }

    }
  }

  handleOnSearch = () => {
    const { startRange, endRange } = this.props.updateRange[0];
    if(this.props.unmsResourceType==="InfraCo FNN"){
      return this.props.fetchResources({ startRange, endRange, page:0, resourceType:this.props.selectedResourceType, fromSearch: true })
    }
    this.props.onSearchClick(unmsConstants.UNMS_UPDATE_FORM);
    let errors = this.props.syncErrors.filter((data) => {
        return data !== null;
    })
    if (errors.length === 0) {
        this.props.fetchResources({ startRange, endRange, page:0, resourceType:this.props.selectedResourceType, fromSearch: true });
    }
  }

  render() {
    const { view, handleUnmsCheckout, unmsUpdateType, unmsMsisdnUpdateType, selectedFilters, selectUpdateType, selectResourceType, resourceType } = this.props;
    const unmsResourceType = this.props.unmsResourceType
    const update = selectedFilters.update;
    const updateSim = selectedFilters.updateSim;
    const updateImsi = selectedFilters.updateImsi;
    const disableCheckout = !resourceType && (this.props.unmsResourceType === 'SIM/IMSI' ? (!updateSim || !updateImsi) : (!update));
    const updateTypes = this.props.unmsResourceType === 'FNN' ? unmsUpdateType : unmsMsisdnUpdateType;
    return (
      <section>
        {view === "Insert"
          && <UnmsRangeView
            title={view}
            handleUnmsCheckout={handleUnmsCheckout}
            selectResourceType={selectResourceType}
            unmsResourceType={unmsResourceType}
            unmsUpdateType={updateTypes}
          />}
        {view === "Update"
          && <UnmsUpdate
            title={view}
            handleUnmsCheckout={handleUnmsCheckout}
            unmsUpdateType={updateTypes}
            selectUpdateType={selectUpdateType}
            update={update}
            updateSim={updateSim}
            updateImsi={updateImsi}
            unmsResourceType={unmsResourceType}
            handleOnSearch = {this.handleOnSearch}
          />}
        <CheckoutFrame
          title={view}
          key={view}
          disabled={disableCheckout}
          checkout={() => {
            this.confirmModal(view, {updateSim, updateImsi});
          }
          }
        />
      </section>
    )
  }
}


const mapStateToProps = state => {
  return {
    selectedFilters: state.cleanup && state.cleanup.selectedFilters || {},
    resourceType: state.cleanup && state.cleanup.selectedFilters && state.cleanup.selectedFilters.resourceType,
    ranges: state.form && state.form[constants.UNMS_INSERT_FORM] && state.form[constants.UNMS_INSERT_FORM].values && state.form[constants.UNMS_INSERT_FORM].values.ranges || [],
    unmsResourceType: state.cleanup && state.cleanup.selectedFilters && state.cleanup.selectedFilters.unmsRscType,
    syncErrors: state.form && state.form[constants.UNMS_INSERT_FORM] && state.form[constants.UNMS_INSERT_FORM].syncErrors && state.form[constants.UNMS_INSERT_FORM].syncErrors.ranges || [],
    unmsUpdateType: state.cleanup.filters.unmsUpdateType,
    unmsMsisdnUpdateType: state.cleanup.filters.unmsMsisdnUpdateType,
    updateRange: state.form && state.form[constants.UNMS_UPDATE_FORM] && state.form[constants.UNMS_UPDATE_FORM].values && state.form[constants.UNMS_UPDATE_FORM].values.ranges || [],
    selectedResourceType: state.cleanup && state.cleanup.selectedFilters && state.cleanup.selectedFilters.unmsRscType,
    selectedEnvironment : state.auth.selectedEnvValue,
    resources: state.cleanup.unms.resources || {},
  };
};
const mapDispatchToProps = dispatch => {
  return {
    insertInUnms: (requestBody) => dispatch(unmsActions.insertNumbersInUnms(requestBody)),
    updateResource: (requestBody, handleOnSearch) => dispatch(unmsActions.updateUnmsResource(requestBody, handleOnSearch)),
    onCheckout: (formName) => { dispatch(submit(formName)) },
    onSearchClick: (formName) => { dispatch(submit(formName)) },
    fetchResources: (pageNum) => dispatch(unmsActions.fetchResources(pageNum)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnmsView);
