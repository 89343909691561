import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import Title from "../text/Title";
import TableSearch from "./TableSearch";
import FilterPopover from "../filters/FilterPopover";

const useStyles = makeStyles((theme) => ({
  customBox: {
    color: "white",
    backgroundColor: "#223c7f",
  }
}));

const TableHeader = (props) => {
  const {
    title,
    searchFilter,
    searchPermOpen = false,
    filter,
    addNew,
    titleBtnArea,
    titleBtnAreaBefore,
    searchDisable = false,
    filterDisable = false,
    addDisable = false,
    darkHeader = false,
    headerPadding = {tb: 13, lr: 33}
  } = props;

  const [search, setSearch] = useState(false);
  const classes = useStyles();
  const toggleSearch = () => {
    setSearch(!search);
    searchFilter('');
  }

  return (
    <>
        <Box style={{padding: `${headerPadding.tb}px ${headerPadding.lr}px`}} className={`${(darkHeader ? classes.customBox : null)}`}>
          <Grid
              container
              alignItems="center"
              justify="space-between"
              spacing={2}
          >
              <Grid item>
                  {search || searchPermOpen ? <TableSearch placeholder="Search Table..." filter={searchFilter} toggle={toggleSearch} outlined={darkHeader} autoFocus={!searchPermOpen}/> : 
                            <Title variant="h5" style={{fontWeight: 500}}>{title}</Title>
                  }
              </Grid>
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  {titleBtnAreaBefore}
                  {
                    searchFilter && !searchPermOpen ? 
                    <Grid item>
                      <Tooltip title={searchDisable ? "Search Disabled" : "Search"}>
                        <span>
                          <IconButton disabled={searchDisable} onClick={()=>toggleSearch()}>
                            {
                              darkHeader ? 
                              <SearchOutlinedIcon style={{color: "white"}}/> :
                              <SearchOutlinedIcon color="primary"/>
                            }
                          </IconButton>
                        </span>
                      </Tooltip>
                      </Grid>
                    : <></>
                  }
                  {
                    filter ? <Grid item><FilterPopover disabled={filterDisable} {...filter} /></Grid> : <></>
                  }
                  {
                    addNew ? 
                    <Grid item>
                    <Tooltip title={addDisable ? "Add Disabled" : "Add"}>
                      <span>
                        <IconButton disabled={addDisable} onClick={()=>addNew()}>
                          {
                            darkHeader ? 
                            <AddOutlinedIcon style={{color: "white"}}/> :
                            <AddOutlinedIcon color="primary"/>
                          }
                        </IconButton>
                      </span>
                    </Tooltip>
                    </Grid>
                    : <></>
                  }
                  {titleBtnArea}
                </Grid>
              </Grid>
          </Grid>
        </Box>
    </>
  );
};

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleBtnArea: PropTypes.any,
};

export default TableHeader;
