import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";

const useStyles = makeStyles({
    stateLabel: {
        backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
        color: "#ffffff  !important",
        marginBottom: "20px !important",
        marginLeft: "20px !important"
    },
    stateSelectedLabel: {
        marginBottom: "20px !important",
        backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
        color: "#ffffff !important",
        marginLeft: "20px !important"
    },
    productHeaderLabel: {
        fontSize: "24px !important",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingLeft: "20px !important"
    },
    yesSpecification: {
        width: "650px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    },
    rangeSpecification : {
        width: "300px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    },
    radioLabel:{
        marginLeft: "11px !important"
    }

});


const StatelessPhysicalSim = (props) => {

    const classes = useStyles();

    const [simTypes, setSimTypes] = React.useState(["Physical", "Logical"])
    const [selectedSimType, setSelectedSimType] = React.useState("");
    const [physicalType,setPhysicalType] = React.useState("");
    const [physicalMinRange , setPhysicalMinRange] = React.useState("");
    const [physicalMaxRange , setPhysicalMaxRange] = React.useState("");
    const [physicalMinError,setPhysicalMinError] = React.useState(false);
    const [physicalMaxError,setPhysicalMaxError] = React.useState(false);
    const [physicalMinErrMsg, setPhysicalMinErrMsg] = React.useState();
    const [physicalMaxErrMsg, setPhysicalMaxErrMsg] = React.useState();


    const clearSubProducts = () => {
    }

    const handleMinRange = (value) => {
        if(physicalType === "msisdn")
        {
            if (!value)
            {  setPhysicalMinError(true); setPhysicalMinErrMsg("MSISDN must be a numbe")}
            else
            {
                if(value.length !== 11)
                {   setPhysicalMinError(true);setPhysicalMinErrMsg("MSISDN does not match length format")}
                else
                    {if(!value.startsWith('61'))
                    {   setPhysicalMinError(true); setPhysicalMinErrMsg("MSISDN starts with 61")}
                    else
                    {  setPhysicalMinError(false); setPhysicalMinErrMsg("")}}
                  }
            }
            if(physicalType === "imsi")
            {
                if (!value)
                {  setPhysicalMinError(true); setPhysicalMinErrMsg("IMSI must be a numbe")}
                else
                {//505013487447428
                    if(value.length !== 15)
                    {   setPhysicalMinError(true);setPhysicalMinErrMsg("IMSI does not match length format")}
                    else
                        {if(!value.startsWith('50'))
                        {   setPhysicalMinError(true); setPhysicalMinErrMsg("IMSI starts with 50")}
                        else
                        {  setPhysicalMinError(false); setPhysicalMinErrMsg("")}}
                      }
            }
            if(physicalType === "sim")
            {
                if (!value)
                {  setPhysicalMinError(true); setPhysicalMinErrMsg("SIM must be a numbe")}
                else
                {//89610163308935220196
                    if(value.length !== 20)
                    {   setPhysicalMinError(true);setPhysicalMinErrMsg("SIM does not match length format")}
                    else
                        {if(!value.startsWith('8961'))
                        {   setPhysicalMinError(true); setPhysicalMinErrMsg("SIM starts with 8961")}
                        else
                        {  setPhysicalMinError(false); setPhysicalMinErrMsg("")}}
                      }
            }
            if(physicalType === "puk")
            {
                if (!value)
                {  setPhysicalMinError(true); setPhysicalMinErrMsg("PUK must be a numbe")}
                else
                {//89610163308935220196
                    if(value.length !== 8)
                    {   setPhysicalMinError(true);setPhysicalMinErrMsg("PUK does not match length format")}
                    else
                       { setPhysicalMinError(false); setPhysicalMinErrMsg("")}
                }
            }
   
            if(physicalType === "eki")
            {
                if (!(value))
                {  setPhysicalMinError(true); setPhysicalMinErrMsg("EKI must be a AlphaNumeric")}
                else
                {//89610163308935220196
                    if(value.length !== 32)
                    {   setPhysicalMinError(true);setPhysicalMinErrMsg("EKI does not match length format")}
                    else
                       { setPhysicalMinError(false); setPhysicalMinErrMsg("")}
                }
            }
    }
    const handleMaxRange = (value) => {
        if(physicalType === "msisdn")
        {
            if (!value)
            {  setPhysicalMaxError(true); setPhysicalMaxErrMsg("MSISDN must be a numbe")}
            else
            {
                if(value.length !== 11)
                {   setPhysicalMaxError(true);setPhysicalMaxErrMsg("MSISDN does not match length format")}
                else
                    {if(!value.startsWith('61'))
                    {   setPhysicalMaxError(true); setPhysicalMaxErrMsg("MSISDN starts with 61")}
                    else
                    {  setPhysicalMaxError(false); setPhysicalMaxErrMsg("")}}
                  }
            }
            if(physicalType === "imsi")
            {
                if (!value)
                {  setPhysicalMaxError(true); setPhysicalMaxErrMsg("IMSI must be a numbe")}
                else
                {//505013487447428
                    if(value.length !== 15)
                    {   setPhysicalMaxError(true);setPhysicalMaxErrMsg("IMSI does not match length format")}
                    else
                        {if(!value.startsWith('50'))
                        {   setPhysicalMaxError(true); setPhysicalMaxErrMsg("IMSI starts with 50")}
                        else
                        {  setPhysicalMaxError(false); setPhysicalMaxErrMsg("")}}
                      }
            }
            if(physicalType === "sim")
            {
                if (!value)
                {  setPhysicalMaxError(true); setPhysicalMaxErrMsg("SIM must be a numbe")}
                else
                {//89610163308935220196
                    if(value.length !== 20)
                    {   setPhysicalMaxError(true);setPhysicalMaxErrMsg("SIM does not match length format")}
                    else
                        {if(!value.startsWith('8961'))
                        {   setPhysicalMaxError(true); setPhysicalMaxErrMsg("SIM starts with 8961")}
                        else
                        {  setPhysicalMaxError(false); setPhysicalMaxErrMsg("")}}
                      }
            }
            if(physicalType === "puk")
            {
                if (!value)
                {  setPhysicalMaxError(true); setPhysicalMaxErrMsg("PUK must be a numbe")}
                else
                {//89610163308935220196
                    if(value.length !== 8)
                    {   setPhysicalMaxError(true);setPhysicalMaxErrMsg("PUK does not match length format")}
                    else
                       { setPhysicalMaxError(false); setPhysicalMaxErrMsg("")}
                }
            }
   
            if(physicalType === "eki")
            {
                if (!(value))
                {  setPhysicalMaxError(true); setPhysicalMaxErrMsg("EKI must be a AlphaNumeric")}
                else
                {//89610163308935220196
                    if(value.length !== 32)
                    {   setPhysicalMaxError(true);setPhysicalMaxErrMsg("EKI does not match length format")}
                    else
                       { setPhysicalMaxError(false); setPhysicalMaxErrMsg("")}
                }
            }
    }

    useEffect(() => {
        clearSubProducts();
    }, [props.clearPhysicalSim]);

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Physical Sim</Typography>
                    {simTypes.map((sim, index) => {
                        return (
                            <Button variant="contained" classes={sim !== selectedSimType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                sx={{ minWidth: 150, minHeight: 150 }} value={sim} onClick={e => { setSelectedSimType(e.target.value); clearSubProducts(); }}>
                                {sim}
                            </Button>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid container spacing = {3}>
                <Grid item xs={1}/>
                {selectedSimType === "Physical" ? 
                <Grid item xs={10}>
                    <FormControl>
                     <FormLabel id="physicalType" classes={{ root: classes.yesSpecification }}>Choose the physical type: </FormLabel>
                     <RadioGroup
                         aria-labelledby="physicalType"
                         row
                         name="physicalType"
                         value={physicalType}
                         onChange={e => {setPhysicalType(e.target.value)}}
                     >
                         <FormControlLabel classes={{ root: classes.radioLabel }} value="msisdn" control={<Radio />} label="MSISDN" />
                         <FormControlLabel classes={{ root: classes.radioLabel }} value="imsi" control={<Radio />} label="IMSI" />
                         <FormControlLabel classes={{ root: classes.radioLabel }} value="sim" control={<Radio />} label="SIM" />
                         <FormControlLabel classes={{ root: classes.radioLabel }} value="puk" control={<Radio />} label="PUK" />
                         <FormControlLabel classes={{ root: classes.radioLabel }} value="eki" control={<Radio />} label="EKI" />
                     </RadioGroup>
                 </FormControl>
                </Grid> : <></>}
            </Grid>

            <Grid container spacing = {3}>
                <Grid item xs={1}/>
                {physicalType !== "" ? 
                <>
                <Grid item xs={4}>
                <TextField
                id="physical_min_range"
                multiline
                error={physicalMinError}
                rows={1}
                label="Min-Range"
                helperText={physicalMinError ? physicalMinErrMsg : ""}
                classes={{ root: classes.rangeSpecification }}
                value={physicalMinRange}
                onChange={e => setPhysicalMinRange(e.target.value)}
                onBlur={e => handleMinRange(e.target.value)}
            />
                </Grid>
                <Grid item xs={5}>
                <TextField
                id="physical_max_range"
                multiline
                error={physicalMaxError}
                rows={1}
                helperText={physicalMaxError ? physicalMaxErrMsg : ""}
                label="Max-Range"
                classes={{ root: classes.rangeSpecification }}
                value={physicalMaxRange}
                onChange={e => setPhysicalMaxRange(e.target.value)}
                onBlur={e => handleMaxRange(e.target.value)}
            />
                </Grid>
                </>
                :<></>}
            </Grid>
        </>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
        prodData: (value) => dispatch(prodData(value)),
        setSpecificRequirement: (value,sID) => dispatch(setSpecificRequirement(value,sID))
    }
}

export default connect(null, mapDispatchToProps)(StatelessPhysicalSim);