import {axiosTaasInstance as axios} from "../axios.config";
import * as types from './actionTypes';
import config from '../config';
import { authHeaders } from '../utils/api';
const API_URL_GATEWAY = config.tdm_gateway_api.uri;

export const setLocation = (path) => async dispatch => {
  try {
    dispatch({ type: types.SET_PATH, payload: path });
  } catch (e) { console.error(e); }
}

export const addPageFeedback = (pageFeedback, currFeedback) => async dispatch => {
  try {
      if (currFeedback[0] !== undefined) {
      let reqBody = {
        "page": currFeedback[0].page,
        "likes": currFeedback[0].likes,
        "unLikes": currFeedback[0].unLikes,
        "comments": currFeedback[0].comment,
        "addedBy": currFeedback[0].addedBy,
        "dNumber": currFeedback[0].dNumber,
        "rating": currFeedback[0].rating,
        "time": currFeedback[0].time,
        "adminApproved": true
      }
      const response = await axios.post(`${API_URL_GATEWAY}/tdm/data/users/addFeedback`, reqBody, { headers: authHeaders() });
      return true;
    }
    else {
      let reqBody = {
        "page": currFeedback.page,
        "likes": currFeedback.likes,
        "unLikes": currFeedback.unLikes,
        "comments": currFeedback.comment,
        "addedBy": currFeedback.addedBy,
        "dNumber": currFeedback.dNumber,
        "rating": currFeedback.rating,
        "time": currFeedback.time,
        "adminApproved": false
      }
      const response = await axios.post(`${API_URL_GATEWAY}/tdm/data/users/addFeedback`, reqBody, { headers: authHeaders() });
      return true;
    }
  }
  catch (e) {
    console.error(e);
    return false;
  }
}

export const fetchTestimonials = () => async dispatch => {
  try {
    const response = await axios.get(`${API_URL_GATEWAY}/tdm/data/users/fetchFeedback`, { headers: authHeaders() });
    dispatch({ type: types.ADD_PAGE_FEEDBACK, payload: response.data });
  }
  catch (e) {
    console.error(e)
  }
}

export const forceFeedback = (value) => async dispatch => {
  try {
    dispatch({ type: types.FORCE_FEEDBACK, payload: value });
  } catch (e) { console.error(e); }
}
export const disableFeedback = (disable) => async dispatch => {
  try {
    dispatch({ type: types.DISABLE_FEEDBACK, disable: disable });
  } catch (e) { console.error(e); }
}

export const updateApproval = (value) => async dispatch => {
  var approval = true;
  if(value.AdminAprroval === true || value.AdminAprroval === "true")
  {
    approval = false
  }
  try {
    const response = await axios.put(`${API_URL_GATEWAY}/tdm/data/users/updateApproval/${value.id}/${approval}`,{},{ headers: authHeaders() });
    return true;
  }
  catch (e) {
    console.error(e)
    return false;
  }
}