import { useEffect, useState } from "react";
import { connect } from "react-redux";
import TilesFrame from "../../Data/accordion-frames/replicator/DataTilesFrame";
import TimMigrationDataForm from "./TimMigrationDataForm" 
import QueryMigratoinService from "./QueryMigratoinService";
import TimMigrationFixedTypeForm from "./TimMigrationFixedTypeForm";
import QueryReferenceService from "./QueryReferenceService";

const TimMigration = (props) => {
  const { fetchReferenceDataRecord, isUserAdmin, history} = props;
  const [timMigration, setTimMigration] = useState(null);
  const [timMigrations, setTimMigrations] = useState(props.timMigration);
  const [groupType, setGroupType] = useState(null);
  const [timMigrationType, setTimMigrationType] = useState(null);

  useEffect(()=>{
      if(timMigration?.label==="Fetch existing record" || timMigration?.label==="Reference Data"){
        setGroupType(null);
        setTimMigrationType(null);
      }
    },[timMigration])

    useEffect(()=>{
      if(timMigration?.label==="Fetch existing record" || timMigration?.label==="Reference Data"){
        setTimMigrationType(null);
        setGroupType(null);
      }
    },[timMigrationType])

    useEffect(()=>{
      if(timMigration?.label==="Fetch existing record" || timMigration?.label==="Reference Data"){
        setGroupType(null);
        setTimMigrationType(null);
      }
    },[timMigration])

    useEffect(()=>{
      if(isUserAdmin){
        setTimMigrations([...props.timMigration,{
          label: 'Reference Data',
          icon: "icon-hosted-cloud-services",
      }])
      }else{
        setTimMigrations([...props.timMigration])
      }
    },[isUserAdmin])


let timMigrationTypeList = [
  {
      label: 'Mobile',
      icon: "icon-service-management"
  },
  {
      label: 'Free Text Input',
      icon: "icon-service-management"
  }
];
  
return <>
        <div className="white pb-20">
        
       <TilesFrame
          heading={"TIM Migration"}
          color="orange"
          data={timMigrations}
          select={(data) => setTimMigration(data)}
          selected={timMigration ? timMigration.label : null}
          redirectUtility={true}
          push={history?.push}
        />
        {timMigration?.label==="Create TIM migration service" ?
          <TilesFrame
          heading={"Migration Type"}
          color="orange"
          data={timMigrationTypeList}
          select={(data) => setTimMigrationType(data)}
          selected={timMigrationType ? timMigrationType.label : null}
          /> : null
        }
        { timMigrationType?.label === "Free Text Input" && timMigration?.label==="Create TIM migration service" ?         
          <TimMigrationFixedTypeForm timMigrationType={timMigrationType}/>
          : null
        }    

        {timMigrationType?.label === "Mobile" && timMigration?.label==="Create TIM migration service" ? 
        <TilesFrame
          heading={"Group Type "}
          color="orange"
          data={props.timMigrationGroupType}
          select={(data) => setGroupType(data)}
          selected={groupType ? groupType.label : null}
        /> : timMigration?.label === "Fetch existing record" ? <QueryMigratoinService/>
        : timMigration?.label === "Reference Data" ? <QueryReferenceService/>
        : null
        }
        {groupType && timMigrationType ?         
          <TimMigrationDataForm groupType={groupType} timMigrationType={timMigrationType}/>
        : null}
        </div>

    </>
}



const mapStateToProps = state => {
    return {
        timMigrationGroupType: state.utility.filters.timMigrationGroupType || [],
        timMigration:state.utility.filters.timMigration,
        isUserAdmin: state.auth.isUserAdmin,
    };
};

export default connect(mapStateToProps, null)(TimMigration);
