import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  Typography,
  IconButton,
  Paper,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';

import { Title } from "@library/core/text";
import ActionsMenu from "@library/core/dropdowns/ActionsMenu";
import TableHeaderCell from "@library/core/tables/TableHeaderCell";
import TableBodyRow from "@library/core/tables/TableBodyRow";
import TableBodyNoDataRow from "@library/core/tables/TableBodyNoDataRow";
import TableBodyCell from "@library/core/tables/TableBodyCell";
import TableExpandRow from "@library/core/tables/TableExpandRow";
import ExpandInfoTable from "./ReferenceDataBreadTable/ExpandInfoTable";

const useTableStyle = makeStyles({
  root: {
    borderCollapse: "separate",
    borderSpacing: "0px 0px",
  },
});

const useMessageTextStyle = makeStyles((theme) => ({
  root: {
    // color: theme.palette.tableHeader.color,
    color: blueGrey[700],
  },
}));

const defaultSize = "small";
const defaultRowsPerPage = 5;

const deriveRowsPerPageOptions = (dataLength) => {
  if (dataLength === 0) return undefined;
  const rowsPerPageOptions = _.range(1, dataLength / defaultRowsPerPage).map(
    (n) => n * defaultRowsPerPage
  );
  rowsPerPageOptions.push({ value: dataLength, label: "All" });

  return rowsPerPageOptions;
};

const isDataUnavailable = (data) => {
  return !data || _.isEmpty(data);
};

const areActionItemsUnavailable = (actionItems) => {
  return !actionItems || _.isEmpty(actionItems);
};

const referenceDataSort =(data,teamName)=>{
  let currentTeamData=[];
  let otherData=[];
  data.map((_item)=>{
    if(_item.team.name===teamName){
      currentTeamData.push(_item);
    }else{
      otherData.push(_item);
    }
  });
  return [...currentTeamData,...otherData]
}

const CustomDataBreadTable = (props) => {
  let data=referenceDataSort(props.data,props.teamName);
  const calculatedRowsPerPageOptions = deriveRowsPerPageOptions(props?.totalElements);

  const {
    title,
    titleVariant,
    expandTitle,
    columnMetadata,
    actionItems,
    filterComponent,
    addComponent,
    isLoading,
    isExpandable = false,
    size = defaultSize,
    rowsPerPageOptions = calculatedRowsPerPageOptions,
    rowsPerPage: initialRowsPerPage = defaultRowsPerPage,
    noDataMsg,
    fixedNumRows = false,
    heightPerRowForEmptyLoad,
    totalElements,
    selectedToggle
  } = props;

  const tableStyle = useTableStyle();
  const displayMessageStyle = useMessageTextStyle();

  const sortedColumns = _.orderBy(columnMetadata, ["order"], ["asc"]);

  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = React.useState({});
  const [filterText , setFilterText] = useState([{},{},{},{},{},{}]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    props.fetchDataSummary([],selectedToggle,newPage,rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.fetchDataSummary([],selectedToggle,currentPage,event?.target?.value);
  };

  const handleFilterChange = (title,index,value) => {
    let filterText1 = [...filterText];
    if(value !== undefined && value !== "")
      filterText1[index] = {"col" : title , "value" : value};
    else
    {
      filterText1[index] = {"col" : title};
    }
    setFilterText(filterText1);
      props.handlefilterData(title,filterText1)
  }
  

  const getHeaders = (columnHeaders,data) => {
    return columnHeaders.map((columnHeader, index) => {
      return (
        <TableHeaderCell key={index}>{columnHeader.title}
        {props.filterHeader && props.openFilter === true  ? 
          <Box display="flex">
            {columnHeader.title !== "Actions" ? 
            <TextField
                    variant="outlined"
                    className={props.filterClass}
                    onChange={e => {handleFilterChange(columnHeader.title,index,e.target.value)}}
                  /> : <TextField
                  variant="outlined"
                  className={props.filterClassEmpty}/>}
      </Box> : <></>
        }
        </TableHeaderCell>
      );
    });
  };

  const displayDataUnavailableMessage = (columnLength, message) => {
    return (
      <TableBodyNoDataRow 
        columnLength={columnLength} 
        fixedNumRows={fixedNumRows} 
        fixedHeight={size === "small" ? rowsPerPage*(heightPerRowForEmptyLoad || 65) : rowsPerPage*(heightPerRowForEmptyLoad || 81)}
      >
        <Typography variant="h6" classes={displayMessageStyle}>
          <Box fontWeight="fontWeightBold" fontSize={18}>
            {message || "No Data Available"}
          </Box>
        </Typography>
      </TableBodyNoDataRow>
    );
  };

  const displayDataLoadingSpinner = (columnLength) => {
    return (
      <TableBodyNoDataRow 
        columnLength={columnLength} 
        fixedNumRows={fixedNumRows} 
        fixedHeight={size === "small" ? rowsPerPage*(heightPerRowForEmptyLoad || 65) : rowsPerPage*(heightPerRowForEmptyLoad || 81)}
      >
        <CircularProgress size="4rem" />
      </TableBodyNoDataRow>
    );
  };

  const fillEmptyRows = (data) => {
    let tempArr = data.filter(value => JSON.stringify(value) !== '{}');
    let ans = data.length % rowsPerPage;
    if (ans !== 0) {
      for (let i = 0; i < (rowsPerPage-ans); i++) {
        tempArr.push({});
      }
    }
    return tempArr;
  }

  const getRows = (columns, data) => {
    let displayData = fixedNumRows ? fillEmptyRows(data) : data;
    return displayData
      .map((row, index) => {
        return (
          <Fragment key={index}>
            <TableBodyRow>
              {isExpandable ? <TableBodyCell>
                <IconButton style={{"outline":"none"}} aria-label="expand row" size="small" onClick={() => setOpen({[index]: !open[index]})}>
                  {open[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableBodyCell> : null}
              {columns.map((column, index) => {
                return (
                  !column.hidden ?
                  <TableBodyCell key={index} style={{"textAlign":"center","margin":"auto"}}>
                    {column.component
                      ? column.component(
                          ...column.field.map((field) => row[field]),
                        )
                      : row[column.field]}
                  </TableBodyCell> : null
                );
              })}
              {isDataUnavailable(data) ||
              !actionItems ||
              _.isEmpty(actionItems) ? (
                <></>
              ) : (
                <TableBodyCell>
                  <ActionsMenu menuItems={actionItems} data={row} />
                </TableBodyCell>
              )}
            </TableBodyRow>
            {isExpandable ? 
              <TableExpandRow open={open[index] ? true : false} title={expandTitle}>
                  <ExpandInfoTable columns={columns} row={row} filters={row.filters}/>
              </TableExpandRow> : <></>}
          </Fragment>
        );
      });
  };

  return (
    <>
      {
        title ?
          <Box m={2} textAlign="left">
              <Title variant={titleVariant}>{title}</Title>
          </Box>
        : null
      }
        {!filterComponent && !addComponent ? (
          <></>
        ) : (
          <>
            <Box m={1}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item xm={1}>
                  {!filterComponent ? <></> : filterComponent()}
                </Grid>
                <Grid item xm={1}>
                  {!addComponent ? <></> : addComponent()}
                </Grid>
              </Grid>
            </Box>
            <Divider light />
          </>
        )}
        <Box mb={1}>
          {props.filterHeader === true? <FilterListIcon className="m-2" onClick={e => props.handleOpenFilter()}/> : <></>}
          <TableContainer component={Paper}>
            <Table size={size} classes={tableStyle}>
              <TableHead>
                <TableRow>
                  {isExpandable ? <TableHeaderCell>{""}</TableHeaderCell> : null}
                  {getHeaders(sortedColumns,data)}
        
                  {isDataUnavailable(data) || areActionItemsUnavailable ? (
                    <></>
                  ) : (
                    <TableHeaderCell>Actions</TableHeaderCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? displayDataLoadingSpinner(sortedColumns.length)
                  : isDataUnavailable(data)
                  ? displayDataUnavailableMessage(isExpandable ? sortedColumns.length+1 : sortedColumns.length, noDataMsg)
                  : getRows(sortedColumns, data)}
              </TableBody>
            </Table>
          </TableContainer>
          {isLoading || isDataUnavailable(data) ? (
            <></>
          ) : (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={totalElements}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            /* onPageChange and onRowsPerPageChange added for backward compatibility of material-ui-core */
          )}
        </Box>
    </>
  );
};

CustomDataBreadTable.propTypes = {
  title: PropTypes.string,
  titleVariant: PropTypes.string,
  expandTitle: PropTypes.string,
  isExpandable: PropTypes.bool,
  columnMetadata: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      field: PropTypes.arrayOf(PropTypes.string).isRequired,
      expandable: PropTypes.bool,
      hidden:PropTypes.bool,
      component: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  actionItems: PropTypes.arrayOf(PropTypes.object),
  filterComponent: PropTypes.func,
  addComponent: PropTypes.func,
  size: PropTypes.string,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.object])
  ),
  noDataMsg: PropTypes.string,
  fixedNumRows: PropTypes.bool,
  heightPerRowForEmptyLoad: PropTypes.number,
};

export default CustomDataBreadTable;
