import React, { useState, useEffect, Fragment }  from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {connect} from 'react-redux';
import swal from 'sweetalert2';
import { displayLoading,displayErrorAlert } from "../../common/alerts/alerts";
import _ from "lodash";
import {addThresholdValuesToServiceIds } from '../../../actions/serviceIdThresholdActions';
import { Grid, TextField  } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { withStyles } from '@material-ui/core/styles';
import {v4 as uuidv4} from "uuid";

const StyledChipInput = withStyles(theme => ({
    root: {
      border: 'none',
    },
    input: {
      border: 'none !important',
    }
}))(ChipInput);
 function UpdateThresholdModal(props) {
  const[modal, setModal] = useState(false);
  const[addDisabled, setAddDisabled] = useState(false);
  const[valueDisabled, setValueDisabled] = useState(true);
  const[selectedServiceIds, setSelectedServiceIds] = useState(props.oldThresholdData.serviceId);

  const[selectedTeam, setSelectedTeam] = useState({ value: props.oldThresholdData.teamId, label: props.oldThresholdData.teamName });
  const[thresholdChips, setThresholdChips] = useState(props.oldThresholdData.thresholdValues);
  const[thresholdData, setThresholdData] = useState({
    [uuidv4()]: {
      serviceId:props.oldThresholdData.serviceId,
      thresholdValues:props.oldThresholdData.thresholdValues,
    }
  });

  const toggle = () => {
    setModal(!modal);
    setAddDisabled(false);
    setValueDisabled(true);
    setSelectedServiceIds([]);
    setSelectedTeam({});
    setThresholdData({
      [uuidv4()]: {
        serviceId:"",
        thresholdValues:[],
      }
    });
  };

  useEffect(() => {
    setModal(true);
  }, []);

  //------------------------
  const handleAddChip = (chip) => {
    setThresholdChips([...thresholdChips, ...chip.split(/[.,\s\t\|\;]/).filter(v=>v!=='').map(Number)]);
    }
  
    const handleDeleteChip = (chip) => {
        setThresholdChips(prevChips => prevChips.filter(id => id !== chip));
    }

 const handleAddThresholdsToServiceIds = () => {
    displayLoading({ title: "Updating threshold values to serviceId", bodyText: "Please standby" });
    const {selectedEnvValue} = props; 
    const serviceIdThresholdDetails =[{"serviceId":selectedServiceIds, "thresholdValues": thresholdChips}];
    const thresholdReq = {
    "teamId": selectedTeam.value,
    "teamName":selectedTeam.label,
    "environment": selectedEnvValue,
    "serviceIdThresholdDetails": serviceIdThresholdDetails
}
 const request = {
    "thresholdDetails":[thresholdReq]
 }
    props.addThresholdValuesToServiceIds(request).then((result) => {
      if (result && result.data) {
        swal.close();
        toggle();
        swal.fire("Success", result.data.message, "success").then(() => {
            props.fetchThresholdDetailsMethod();
        })
      }else{
        swal.close();
        displayErrorAlert({
          title: "Error",
          subTitle: "Failed to update threshold values to serviceId",
          errorMsg: "Internal Server Error"
      });
      toggle();
      }
    });
  }

 const isValidData = () => {
    const {selectedEnvValue} = props; 
    let isValid = false;
    if (_.isEmpty(selectedServiceIds) && _.isEmpty(thresholdChips) && thresholdChips.length > 0 && _.isEmpty(selectedTeam) && _.isEmpty(selectedEnvValue) ) {
        isValid = true;
    }
    return isValid;
  }


  return (
    <Fragment>
      <Modal size="lg" isOpen={modal} toggle={toggle} backdrop="static" className={props.className} >
        <ModalHeader toggle={toggle}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              Assign Threshold Values To Service Ids
            </Grid>
            <Grid item>
              <p className='showEnvSection' style={{fontSize: "16px", lineHeight: "30px"}}><b>Environment:</b> {props.selectedEnvValue}</p>
            </Grid>
          </Grid>
        </ModalHeader>
        <ModalBody>
        <section>
        <div className="row">
                          <div className="offset-1 col-4">
                              <b>Team</b>
                          </div>
        </div>
        <div className="row">
                <div className="offset-1 col-4">
                <TextField
                    disabled
                    id="outlined-disabled"
                    defaultValue={selectedTeam.label}
                />
                </div>

      </div>
          <><section>
                  <div>
                      <div className="row">
                          <div className="offset-1 col-4">
                              <b>Service Id</b>
                          </div>
                          <div className="col-4">
                              <b>Threshold Values</b>
                          </div>
                      </div>
                      <div className="row">
                          <div className="offset-1 col-4">
                          <TextField
                              disabled
                              id="outlined-disabled"
                              defaultValue={props.oldThresholdData.serviceId}
                            />
                          </div>
                          <div className="col-4">
                              <StyledChipInput
                                  type="number"
                                  name={"thresholdValues"}
                                  value={thresholdChips.map(chip => chip)}
                                  onAdd={(chip) => handleAddChip(chip)}
                                  onDelete={(chip) => handleDeleteChip(chip)}
                                  blurBehavior='add'
                                  fullWidth={true}
                                  placeholder={'Enter Threshold Values seperated by comma or space'}
                                  allowDuplicates={false} />
                          </div>
                      </div>
                  </div>
              </section></>
        </section>
        </ModalBody>
        <ModalFooter>
          <Button
            className={"btn-team"}
            color="success"
            onClick={handleAddThresholdsToServiceIds}
            disabled={isValidData() }
          >
            <span className={"pxr-s"}>Submit</span>
            {<i className="fa fa-check"></i>}
          </Button>
          <Button color="danger" className={"btn-team"} onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}


const mapStateToProps = state => {
  return {
    selectedEnvValue: state.auth.selectedEnvValue,
      loggedInUser: state.auth.user
  }
  }
  const mapDispatchToProps = dispatch => {
  return {
    addThresholdValuesToServiceIds: (request) => dispatch(addThresholdValuesToServiceIds(request)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateThresholdModal);