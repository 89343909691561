import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from "react-redux";
import { Table , Input} from 'reactstrap';
import { Button } from 'reactstrap'
import {checkinCustomAsset} from '../../actions/customCheckinActions';
import swal from 'sweetalert2';
import { displayLoading } from "../common/alerts/alerts";
import { keys, map, flattenDeep, uniq, upperFirst } from "lodash";

class CustomTableComponent extends React.Component {
    constructor(props){
        super(props);
    }
    state ={
      newAsset :{},
      currentAssets : this.props.assetDetails,
      newAssets : [],
      addButtonDisabled : true,
      checkinButtonDisabled : true,
      selectedEnv : this.props.selectedEnvValue
    }
    componentDidMount (){
      this.setState({
          newAsset : this.getAssetkeys()
      })
    }
 

    getAssetkeys = () =>{
        const assetkeys = uniq(flattenDeep(map(this.state.currentAssets, (a) => keys(a.customDetails))));
        const res = assetkeys.reduce((a,b)=> (a[b]='',a),{});
        return res;
    }

    addCheckinAsset =() =>{
        let { currentAssets, newAssets, newAsset } = this.state;
        let assetDetail= {
            id : null,
            customDetails : newAsset
        }
        this.setState({
            currentAssets : [assetDetail, ...this.state.currentAssets],
            newAssets: [assetDetail,...this.state.newAssets],
            newAsset : this.getAssetkeys(),
            addButtonDisabled : true,
            checkinButtonDisabled : false
            })
    }

    resetCheckinAsset =() =>{
        this.setState({
            currentAssets :this.props.assetDetails,
            newAssets : [],
            checkinButtonDisabled : true
        })
    }

    submitCheckinAsset =() => {
        let { currentAssets, newAssets } = this.state;
        let assetIds=[];
        let requestArray=[];
        for(let i=0;i<newAssets.length;i++){
            const request = {
                "description": "TDM VACANT DATA - CUSTOM",
                "serviceId": null,
                "source": "TDM",
                "scope": "TEAM",
                "assetVersion": 0,
                "environment": this.props.selectedEnvValue,
                "userStory":this.props.requestId,
                "scenarioName" :this.props.scenarioName,
                "enrichmentStatus": "COMPLETED",
                "custom": [newAssets[i].customDetails]
            }
            requestArray.push(request)
        }
       
        swal({
            title: 'Custom Asset Checkin Information',
            html: `<b>User Story:</b> ${this.props.requestId}<br />
            <b>Scenario Name:</b> ${this.props.scenarioName}<br />
            <b>Quantity:</b>  ${newAssets.length}`,
            type: 'info',
            showCancelButton: true,
            cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> Cancel',
            confirmButtonColor: '#13a129',
            confirmButtonText: '<i class="fa fa-check" aria-hidden="true"></i> Checkin',
            reverseButtons: true,
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return Promise.all(requestArray.map(request=>{
                      this.props.checkinCustomAsset(request).then(result => {
                        if (result.errorDescription) {
                                swal.fire({
                                    type: 'error',
                                    title: 'Custom Checkin Error',
                                    text: result.errorDescription,
                                })
                        } else if(result.assetId){  
                            assetIds.push(result.assetId);                                            
                                for (let i = 0; i < currentAssets.length; i++) {
                                    if (currentAssets[i].customDetails === request.custom[0]) {
                                       currentAssets[i].id=result.assetId
                                    }
                                }
                            if(assetIds.length === requestArray.length){
                                swal("Checkin Complete", "You may add more data to checkin", "success");
                                this.setState({
                                    newAsset : this.getAssetkeys(),
                                    newAssets : [],
                                    addButtonDisabled : true,
                                    checkinButtonDisabled : true
                                })
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                        swal.showValidationError(`Custom Checkin failed, Error please try again`);
					})
                }))
            }
        }).then( (result) => {
            if(!result.dismiss){ displayLoading({ title: "Checking in", bodyText: "Please standby" });      }
        })
    }

    handleInputChange =(e,key,newAsset) =>{
        newAsset[key] = e.target.value;
        this.setState({
            ...this.state,
            newAsset: newAsset,
            addButtonDisabled : false
        })
        }

        editableTable = () =>{
            const newAsset=this.state.newAsset;
                    return(
                        <tr key='newAsset'>
                            <td disabled></td>
                            {Object.entries(newAsset).map(([key,val]) => 
                            <td key={key}>
                                <Input  
                                name={key} 
                                id={key}  
                                value={val}
                                placeholder={key} 
                                onChange={ (e) => this.handleInputChange(e,key, newAsset) }/>
                            </td>
                            )} 
                            <td><Button color="primary" disabled = {this.state.addButtonDisabled} onClick={this.addCheckinAsset}>Add</Button></td>
                        </tr>
                    )       
        }

    render() {
        const {assetDetails,requestId,scenarioName} = this.props;
        const {newAsset,currentAssets} =this.state;

        const tableHeaderKeys = uniq(flattenDeep(map(currentAssets, (a) => keys(a.customDetails))));

        return(    
                    <div className="col-sm-12 col-lg-12">
                         
                    <div style={{float :"right", paddingTop :"1%", display :"flex"}}>
                            <Button color="info" disabled = {this.state.checkinButtonDisabled} onClick={this.submitCheckinAsset}
                            style={{marginLeft :"8px", marginRight :"8px"}}><i className="fa fa-check" aria-hidden="true"></i>Checkin</Button>
                               <Button color="danger" disabled = {this.state.checkinButtonDisabled} onClick={this.resetCheckinAsset} className="mr-1"><i className="fa fa-times" aria-hidden="true" 
                            style={{marginLeft :"8px", marginRight :"8px"}}></i>Cancel</Button>
                    </div>
                   
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <td>Id</td>
                                {tableHeaderKeys && tableHeaderKeys.map((val, index) => <td key={index}>{upperFirst(val)}</td>)}
                                <td>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                                {this.editableTable(tableHeaderKeys)}
                                {map(currentAssets, (a, index) => {
                                return (
                                <tr key={index}>
                                    <td>{a.id}</td>
                                    {map(tableHeaderKeys, (k, index) => (
                                    <td key={`${index}-${k}`}>
                                        <span>{a.customDetails[k]}</span>
                                    </td>
                                    ))}
                                    <td><Button color="secondary"disabled>Edit</Button></td>
                                </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    </div>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return {
        checkinCustomAsset: (request) => dispatch(checkinCustomAsset(request))
    };
  };
export default connect(null,mapDispatchToProps)(CustomTableComponent);