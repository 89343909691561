import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";

const useStyles = makeStyles({
    stateLabel: {
        backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
        color: "#ffffff  !important",
        marginBottom: "20px !important",
        marginLeft: "20px !important"
    },
    stateSelectedLabel: {
        marginBottom: "20px !important",
        backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
        color: "#ffffff !important",
        marginLeft: "20px !important"
    },
    productHeaderLabel: {
        fontSize: "24px !important",
        paddingTop: "5px !important",
        paddingBottom: "5px !important",
        paddingLeft: "20px !important"
    },
    yesSpecification: {
        width: "650px !important",
        marginLeft: "23px !important",
        marginTop: "20px !important"
    },
    spacedDiv : {
        marginTop: "10px !important",
        marginLeft: "-20px !important"
    }

});


const CustomerService = (props) => {

    const classes = useStyles();
    const [customerServiceTypes] = useState(["SOLE-TRADER", "RESIDENTIAL", "COMPANY", "ORGANIZATION", "OTHERS"]);
    const [selectedCSType, setSelectesCSType] = useState("");
    const [typesOfCustomer] = useState(["SIEBEL","SALESFORCE"]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [billingAccnt] = useState(["POSTPAID"]);
    const [selectedBillingAccnt, setSelectedBillingAccnt] = useState("");
    const [labelText, setLableText] = useState("");
    const [customerServiceData, setCustomerServiceData] = useState("");
    const [postPaidAccnts] = useState(["ESTABLISHED", "UN-ESTABLISHED"]);
    const [selectedPPAccnt, setSelectedPPAccnt] = useState("");
    const [contactTypes] = useState(["LEGAL LESSEE/ACCOUNT OWNER", "LIMITED AUTHORITY",  "FULL AUTHORITY", "OTHERS"])

    const [selectedContactType, setSelectedContactType] = useState("");


    const setSpecReq = (key, value) => {
        setLableText(key)
        props.setSpecificRequirement({ [key]: value }, "")
    }

    const TypesOfCustomer=()=>{
        return typesOfCustomer.map((customerType) => <Button variant="contained" classes={customerType !== selectedCustomer ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                    sx={{ minWidth: 150, minHeight: 150 }} value={customerType}
                    onClick={e => {
                        console.log(e.target.value)
                        setSelectedCustomer(e.target.value);
                        setSelectesCSType("");
                        setSelectedBillingAccnt("");
                        setCustomerServiceData("");
                        setSelectedPPAccnt("");
                        setSelectedContactType("");
                    }}>
                    {customerType}
                </Button>)
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Customers Service</Typography>
                    <TypesOfCustomer/>
                    {selectedCustomer ? <>
                    <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Customer Service</Typography>
                    {customerServiceTypes.map((cable, index) => {
                        return (
                            <Button variant="contained" classes={cable !== selectedCSType ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                sx={{ minWidth: 150, minHeight: 150 }} value={cable}
                                onClick={e => {
                                    setSelectesCSType(e.target.value);
                                    setSelectedBillingAccnt("");
                                    setCustomerServiceData("");
                                    setSelectedPPAccnt("");
                                    setSelectedContactType("");
                                }}>
                                {cable}
                            </Button>
                        )
                    })}
                    </> :null}
                    
                </Grid>
            </Grid>
{selectedCustomer === "SIEBEL" ?
            <Grid container spacing={3}>
                <Grid item xs={1} />
                {selectedCSType !== "" ?
                    <Grid item xs={10}>
                        <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Billing Account</Typography>
                        {billingAccnt.map((bA, index) => {
                            return (
                                <Button variant="contained" classes={bA !== selectedBillingAccnt ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                    sx={{ minWidth: 150, minHeight: 150 }} value={bA}
                                    onClick={e => {
                                        setSelectedBillingAccnt(e.target.value);
                                        setCustomerServiceData("");
                                        setSelectedPPAccnt("");
                                        setSelectedContactType("");
                                        let key = "CUSTOM : ACTIVE CUSTOMER " + selectedCustomer.toUpperCase() + " " + selectedCSType.toUpperCase() + " " + e.target.value + " DATA";
                                        setSpecReq(key, "");
                                    }}>
                                    {bA}
                                </Button>
                            )
                        })}
                    </Grid> : <></>}
            </Grid>
                : <Grid container spacing={3}>
                        <Grid item xs={1} />
                        {selectedCSType !== "" ?
                        <>
                        <Grid item xs={10}>
                            <Typography classes={{ root: classes.productHeaderLabel }}> Please select the Customer Privilege</Typography>
                            {postPaidAccnts.map((cable, index) => {
                                return (
                                    <Button variant="contained" classes={cable !== selectedPPAccnt ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                        sx={{ minWidth: 150, minHeight: 150 }} value={cable}
                                        onClick={e => {
                                            setSelectedPPAccnt(e.target.value);
                                            setSelectedContactType("");
                                            setCustomerServiceData("");
                                            let key = "CUSTOM : ACTIVE CUSTOMER " + selectedCustomer.toUpperCase() + " " + selectedCSType.toUpperCase() + " " + selectedBillingAccnt.toUpperCase() + " " + (e.target.value).toUpperCase() + " DATA";
                                            setSpecReq(key, "");
                                        }}>
                                        {cable}
                                    </Button>
                                )
                            })}

                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={1} />
                            {selectedPPAccnt !== "" ?
                                <Grid item xs={10}>
                                    <Autocomplete
                                        disablePortal
                                        id="UnEstablishedContactTypes"
                                        options={contactTypes}
                                        sx={{ width: 300 }}
                                        getOptionLabel={(option) => option}
                                        placeholder="Select the Contact Type"
                                        value={selectedContactType}
                                        onChange={(event, value) => {
                                            setSelectedContactType(value);
                                            setCustomerServiceData("");
                                            let key = "CUSTOM : ACTIVE CUSTOMER " + selectedCustomer.toUpperCase() + " " + selectedCSType.toUpperCase() + " " + selectedBillingAccnt.toUpperCase() + " " + selectedPPAccnt.toUpperCase() + " " + value.toUpperCase() + " DATA";
                                            setSpecReq(key, "");
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Contact Type"
                                            classes={{ root: classes.yesSpecification }} />}
                                    />

                                </Grid> :
                                <></>}
                        </Grid>
                        </>
                        : <></>}
                    </Grid>}
            <Grid container spacing={3}>
                <Grid item xs={1} />
                {(selectedBillingAccnt === "PREPAID" && selectedCSType !== "" && selectedBillingAccnt !== "") ?
                    <Grid item xs={10}>
                        <TextField
                            id="customerServiceData"
                            multiline
                            rows={4}
                            helperText="Enter the specific requirement"
                            label={labelText}
                            classes={{ root: classes.yesSpecification }}
                            value={customerServiceData}
                            onChange={e => {
                                setCustomerServiceData(e.target.value);
                                setSelectedPPAccnt("");
                                setSelectedContactType("");
                                let key = "CUSTOM : ACTIVE CUSTOMER " + selectedCustomer.toUpperCase() + " " + selectedCSType.toUpperCase() + " " + selectedBillingAccnt.toUpperCase() + " DATA";
                                setSpecReq(key, e.target.value);
                            }}
                        />

                    </Grid> : <>
                        {(selectedBillingAccnt === "POSTPAID" && selectedCSType !== "") ?
                            <><Grid item xs={10}>
                                <Typography classes={{ root: classes.productHeaderLabel }}> Please select the Customer Privilege</Typography>
                                {postPaidAccnts.map((cable, index) => {
                                    return (
                                        <Button variant="contained" classes={cable !== selectedPPAccnt ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                                            sx={{ minWidth: 150, minHeight: 150 }} value={cable}
                                            onClick={e => {
                                                setSelectedPPAccnt(e.target.value);
                                                setSelectedContactType("");
                                                setCustomerServiceData("");
                                                let key = "CUSTOM : ACTIVE CUSTOMER " + selectedCustomer.toUpperCase() + " " + selectedCSType.toUpperCase() + " " + selectedBillingAccnt.toUpperCase() + " " + (e.target.value).toUpperCase() + " DATA";
                                                setSpecReq(key, "");
                                            }}>
                                            {cable}
                                        </Button>
                                    )
                                })}

                            </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={1} />
                                    {selectedPPAccnt !== "" ?
                                        <Grid item xs={10}>
                                            <Autocomplete
                                                disablePortal
                                                id="UnEstablishedContactTypes"
                                                options={contactTypes}
                                                sx={{ width: 300 }}
                                                getOptionLabel={(option) => option}
                                                placeholder="Select the Contact Type"
                                                value={selectedContactType}
                                                onChange={(event, value) => {
                                                    setSelectedContactType(value);
                                                    setCustomerServiceData("");
                                                    let key = "CUSTOM : ACTIVE CUSTOMER " + selectedCustomer.toUpperCase() + " " + selectedCSType.toUpperCase() + " " + selectedBillingAccnt.toUpperCase() + " " + selectedPPAccnt.toUpperCase() + " " + value.toUpperCase() + " DATA";
                                                    setSpecReq(key, "");
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Contact Type"
                                                    classes={{ root: classes.yesSpecification }} />}
                                            />

                                        </Grid> :
                                        <></>}
                                </Grid>
                            </> : <></>}
                    </>}
            </Grid>
            <div class="mt-10" />
            <Grid container spacing={3} classes={{ root: classes.spacedDiv }}>
                <Grid item xs={1} />
                {selectedPPAccnt !== "" && selectedContactType !== undefined && selectedContactType !== ""
                    && selectedContactType !== null && selectedContactType !== "None" ?
                    <Grid item xs={11}><TextField
                        id="customerServiceData"
                        multiline
                        rows={4}
                        helperText="Enter the specific requirement"
                        label={labelText}
                        classes={{ root: classes.yesSpecification }}
                        value={customerServiceData}
                        onChange={e => {
                            setCustomerServiceData(e.target.value);
                            let key = "CUSTOM : ACTIVE CUSTOMER " + selectedCustomer.toUpperCase() + " " + selectedCSType.toUpperCase() + " " + selectedBillingAccnt.toUpperCase() + " " + selectedPPAccnt.toUpperCase() + " " + selectedContactType.toUpperCase() + " DATA";
                            setSpecReq(key, e.target.value);
                        }}
                    /></Grid> : <></>}
            </Grid>

        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
        prodData: (value) => dispatch(prodData(value)),
        setSpecificRequirement: (value, sID) => dispatch(setSpecificRequirement(value, sID))
    }
}

export default connect(null, mapDispatchToProps)(CustomerService);