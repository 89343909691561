import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Table, Row, Col, Button, Collapse} from 'reactstrap';

import {connect} from 'react-redux';

import {importCustomerSalesforce, collapseSalesForceView,getSalesForceDetails, createCustomerContactSalesforce, createCustomerBaSalesforce, selectCustomerSalesforce} from '../../../../actions/dataActions';

import {SubHeader} from '../../../common/custom/SubHeader';
import classNames from 'classnames';

import config from '../../../../config';
import DataViewSalesForceBtn from './DataViewSalesforceBtn';
import DataViewSalesForce from '../salesForceCustomer/DataViewCustomerSf';

class DataViewCustomer extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            refreshActive: false,
            refreshCounter: 0,
            collapse: null
        };
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.sfCustomerErrorStatus instanceof Error){
            const {data} = nextProps.sfCustomerErrorStatus.response;
            const responseConf = nextProps.sfCustomerErrorStatus.response.config;
            const stateData = {...data,responseConf};
            console.log('config', config);
            this.props.history.push({pathname:'/error', stateData: stateData });
        }
    }
    componentDidMount() {
        this._isMounted = true;

        // automatically refresh after time for components first mount if data is
        // missing only subscribes to refresh channel if required.
        if (this.isRefreshRequired()) {
            // initiate websocket connection
            const {refresh, subscribe} = this.props;
            subscribe();
            setTimeout(() => {
                // refresh();
                this.delayRefreshActive(0); // initial wait until user can refresh for additional details
            }, 90000);
        }
    }

    componentDidUpdate() {
        // close websocket connection if refresh is no longer required.
        const {unsubscribe} = this.props;
        if (!this.isRefreshRequired()) {
            unsubscribe();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    isRefreshRequired = () => {
        // returns a boolean indicating whether a refresh is required based on missing
        // fields
        for (let customer of this.props.customerItems) {
            const {masterId, cidn} = customer;
            const invalid = !(masterId && cidn);
            if (invalid) {
                return true;
            } else 
                continue;
            }
        return false;
    }

    delayRefreshActive = (ms) => {
        if (this._isMounted) {
            this.setState({refreshActive: false});
            setTimeout(() => {
                this.setState({refreshActive: true});
            }, ms);
        }
    }

    refreshButtonClick = () => {
        const {refresh} = this.props;

        this.delayRefreshActive(10000);

        refresh(); // call parent refresh function
    }

    renderRefreshButton = () => {
        const {loading, refresh} = this.props;
        const {refreshActive} = this.state; // is refresh active on UI? (controlled with delay to prevent refresh spam)

        const displayRefreshText = (refreshActive) || (!refreshActive && loading);

        const disabled = (loading || !refreshActive)

        return (
            <button
                className={classNames("btn", "btn-sm", `btn-outline-${displayRefreshText
                ? 'primary'
                : 'secondary'}`, "btn-block", "ld-over", {running: disabled})}
                type="submit"
                onClick={this.refreshButtonClick}
                disabled={disabled}>
                <React.Fragment>
                    <i
                        className={`fa fa-${refreshActive
                        ? 'refresh'
                        : 'clock-o'}`}
                        aria-hidden="true"></i >
                    {'      '
}
                    {refreshActive
                        ? 'Refresh'
                        : ``
}
                    < div class="ld ld-ring ld-spin"></div>
                </React.Fragment>
            </button>
        )
    }

    collapseClick = (custId) => {
        this.props.collapseSalesForceView(!this.props.collapseSfView);
    }

    render() {
        const {customerItems, filename, techFilter,sfCustomerStatus, activeSfCustomer,collapseSfView} = this.props;
        const {cidn} = customerItems.length>0 ? customerItems[0] : [];
        return (
            <React.Fragment>
                <SubHeader
                    text={"Customers"}
                    iconClass={"td-icon-md icon-crowd-support"}
                    iconColor="turquoise"
                    data={customerItems}
                    filename={`${filename}-CUSTOMER`}/>
                <Table>
                    <thead>
                        <tr>
                            <th style={{"width": "10%"}}>Customer ID</th>
                            <th style={{"width": "10%"}}>Type</th>
                            {techFilter === "TB Unmanaged" && <th style={{"width": "10%"}}>Account No</th>}
                            {techFilter!== "TB Unmanaged" && <th style={{"width": "10%"}}>Master ID</th>}
                            {techFilter!== "TB Unmanaged" && cidn && <th style={{"width": "10%"}}>CIDN</th>}
                            <th style={{"width": "10%"}}>First Name</th>
                            <th style={{"width": "10%"}}>Last Name</th>
                            <th style={{"width": "10%"}}>DOB</th>
                            {techFilter!== "TB Unmanaged" && <th style={{"width": "10%"}}>Salesforce</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {customerItems.map((item) => {
                           const rowStyles = {"backgroundColor": collapseSfView && "rgba(211, 211, 211, 0.18)", "opacity": (collapseSfView || !this.props.activeSfCustomer) ? 1 : 0.45};
                            const {customerId, masterId, cidn, firstName, lastName, dateOfBirth} = item;
                            return (
                                <React.Fragment key={customerId}>
                                    <tr style={rowStyles}>
                                        <th scope="row">{customerId}</th>
                                        <td>{item.custType}</td>
                                        {techFilter === "TB Unmanaged" && <td>{item.billingAccount}</td>}
                                        {techFilter!== "TB Unmanaged" && <td>{(masterId
                                                ? masterId
                                                : this.renderRefreshButton())}</td>}
                                        {techFilter!== "TB Unmanaged" && cidn && <td>{cidn}</td>}
                                        <td>{firstName}</td>
                                        <td>{lastName}</td>
                                        <td>{dateOfBirth}</td>
                                        {/* {techFilter!== "TB Unmanaged" && <td><DataViewCustomerSfBtn customer={item} active={isOpen} click={this.collapseClick} import={this.props.salesforceImport}/></td>} */}
                                        {techFilter!== "TB Unmanaged" && <td><DataViewSalesForceBtn 
                                        sfCustomerStatus={sfCustomerStatus}
                                        activeSfCustomer={activeSfCustomer}
                                        collapseSfView={collapseSfView}
                                        customer={item} 
                                        active={collapseSfView} 
                                        collapseClick={this.collapseClick} 
                                        getSalesForceDetails={this.props.getSalesForce}
                                        />
                                        </td>}
                                    </tr>
                                    <tr><td
                                        colSpan="8"
                                        style={{
                                        "borderTop": "none",
                                        "padding": 0
                                    }}>
                                    <Collapse
                                         isOpen={collapseSfView}
                                        style={rowStyles}>
                                       <DataViewSalesForce customer={this.props.customer}/>
                                    </Collapse>
                                    </td>
                                    </tr></React.Fragment>
                            )
                        })}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        salesforceImport: (custId, profile) => {
            dispatch(importCustomerSalesforce(custId, profile))
        },
        salesforceCreateContact: (custId) => {
            dispatch(createCustomerContactSalesforce(custId))
        },
        salesforceCreateBa: (custId, contactId) => {
            dispatch(createCustomerBaSalesforce(custId, contactId))
        },
        salesforceSelect: (custId) => {
            dispatch(selectCustomerSalesforce(custId))
        },
        getSalesForce: (firstName,lastName,customerId) => {
            dispatch(getSalesForceDetails(firstName,lastName,customerId))
        },
        collapseSalesForceView: (collapseSfView) => {
            dispatch(collapseSalesForceView(collapseSfView))
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const {sfCustomerStatus, activeSfCustomer,collapseSfView,sfCustomerErrorStatus} = state.checkout.view;
    return {
        
        activeSfCustomer: state.checkout.view.activeSfCustomer,
        techFilter: state.checkout.view.techFilter,
        customer: state.checkout.view.customer,
        sfCustomerStatus,
        sfCustomerErrorStatus,
        activeSfCustomer,
        collapseSfView
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataViewCustomer));