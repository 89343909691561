import React from 'react'
import LinkTile from "../custom/LinkTile";

const tileStyle = {
    background: 'linear-gradient(to right, #4a00b3 0, #4c00b3 100%)'
}
const SyntheticDataGeneratorTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-green"
        data-size="medium"
        to="/dataset/synthetic"
        data-effect="hover-zoom-up">
        <div class="slide-front">
            <span className="td-icon-md icon-aggregating-info icon"></span>
            <span className="branding-bar">Synthetic Data Generator</span>
        </div>
        <div
            class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
            <p class="text-center">
                Synthetic Data Generator
            </p>
        </div>
    </LinkTile>
)

export default SyntheticDataGeneratorTile;