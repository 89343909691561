import React from "react";
import PaymentRow from "./PaymentRow";

export default ({ payments = [], gotoPage }) => (
  <div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Transaction Id</th>
          <th scope="col">Payment Amount</th>
          <th scope="col">Payment Date</th>
          <th scope="col">Payment Unapplied Amount</th>
          <th scope="col">Invoice Number</th>
        </tr>
      </thead>
      <tbody>
        {payments.map((payment, index) => (
          <PaymentRow payment={payment} gotoPage={gotoPage} key ={index}/>
        ))}
      </tbody>
    </table>
  </div>
);
