import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import BreadTable from "@library/core/tables/BreadTable";
import Header from "@library/core/surfaces/Header";
import { RoundBtn } from "@library/core/buttons";
import LeftSidePanel from "@library/core/surfaces/LeftSidePanel";
import { FilterIcon } from "@library/core/icons";
import SnackbarAlert from "@library/core/alerts/SnackbarAlert";
import FilterForm from "@library/core/filters/FilterForm";
import { FormAutoComplete } from "@library/core/form-elements";
import { setLocation } from "../../actions/userFeedbackActions";
import { filterObjectArray, filterObjectArrayIsInDateRange, findStringInObjectArray } from "@library/core/filters/filter";
import { 
    fetchMyOrders,
    resetMyOrders,
} from '../../actions/orderBuilderActions';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import {
    Typography,
    Tooltip,
    Grid,
    makeStyles,
    Link,
} from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    section: {
        margin: '10px 0px 5px 0px !important',
        fontSize: '15px',
        fontWeight: '400'
    },
    customDatePickerWidth: {
        paddingBottom: '20px'
    }
}))

const MyOrders = (props) => {
    const classes = useStyles();
    const {
        authTeam,
        teamName,
        isAdmin,
        allTeams,
        listOfTeams,

        setLocation,
        myOrders = [],
        myOrdersPending,
        myOrdersSuccess,
        myOrdersFailed,
        myOrdersFailedMsg,
        fetchMyOrders,
    } = props;

    const [jiraIdList, setJiraIdList] = useState([]);
    const [serviceIdList, setServiceIdList] = useState([]);
    const [sprintList, setSprintList] = useState([]);
    const [envList, setEnvList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    const [filteredOrders, setFilteredOrders] = useState([]);
    const [loadingFilters, setLoadingFilters] = useState(false);
    
    const [teamList, setTeamList] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamInputValue, setTeamInputValue] = useState("");

    const [jiraIdValue, setJiraIdValue] = useState(null);
    const [jiraIdInputValue, setJiraIdInputValue] = useState("");
    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [createdDateOnFocus, setCreatedDateOnFocus] = useState(null);
    const [serviceIdValue, setServiceIdValue] = useState(null);
    const [serviceIdInputValue, setServiceIdInputValue] = useState("");
    const [sprintValue, setSprintValue] = useState(null);
    const [sprintInputValue, setSprintInputValue] = useState("");
    const [envValue, setEnvValue] = useState(null);
    const [envInputValue, setEnvInputValue] = useState("");
    const [statusValue, setStatusValue] = useState(null);
    const [statusInputValue, setStatusInputValue] = useState("");

    const [displayAlert, setDisplayAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertType, setAlertType] = useState("info");
    const [alertLoading, setAlertLoading] = useState(false);
    const [alertAutoHide, setAlertAutoHide] = useState(10000);

    const handleChangeTeam = (newVal) => {
        if (newVal) {
            setSelectedTeam(newVal);
        }
    }
    const handleTeamInputChange = (newInput) => {
        setTeamInputValue(newInput.trimStart());
    }

    const handleChangeJiraId = (newVal) => {
        if (newVal) {
            setJiraIdValue(newVal);
        }
    }
    const handleJiraIdInputChange = (newInput) => {
        setJiraIdInputValue(newInput.trimStart());
        if (newInput === "") {
            setJiraIdValue("");
        }
    }

    const handleChangeCreatedDate = (start, end) => {
        if (start) {
            setStartDateValue(start);
        }
        if (end) {
            setEndDateValue(end);
        }
    }
    const handleCreatedDateFocusChange = (newInput) => {
        setCreatedDateOnFocus(newInput);
    }

    const handleChangeServiceId = (newVal) => {
        if (newVal) {
            setServiceIdValue(newVal);
        }
    }
    const handleServiceIdInputChange = (newInput) => {
        setServiceIdInputValue(newInput.trimStart());
        if (newInput === "") {
            setServiceIdValue("");
        }
    }
    
    const handleChangeSprint = (newVal) => {
        if (newVal) {
            setSprintValue(newVal);
        }
    }
    const handleSprintInputChange = (newInput) => {
        setSprintInputValue(newInput.trimStart());
        if (newInput === "") {
            setSprintValue("");
        }
    }

    const handleChangeEnv = (newVal) => {
        if (newVal) {
            setEnvValue(newVal);
        }
    }
    const handleEnvInputChange = (newInput) => {
        setEnvInputValue(newInput.trimStart());
        if (newInput === "") {
            setEnvValue("");
        }
    }

    const handleChangeStatus = (newVal) => {
        if (newVal) {
            setStatusValue(newVal);
        }
    }
    const handleStatusInputChange = (newInput) => {
        setStatusInputValue(newInput.trimStart());
        if (newInput === "") {
            setStatusValue("");
        }
    }

    const createFilterLists = (orders) => {
        if (orders && orders.content) {
            let jiraIds = [], serviceIds = [], sprints = [], envs = [], statusArray = [];
            for (const order of orders.content) {
                order.jiraId ? jiraIds.push(order.jiraId): null;
                order.serviceId ? serviceIds.push(order.serviceId) : serviceIds.push("Custom Request");
                order.sprint ? sprints.push(order.sprint): null;
                order.environment ? envs.push(order.environment): null;
                order.status ? statusArray.push(order.status): null;
            }
            setJiraIdList(_.uniq(jiraIds));
            setServiceIdList(_.uniq(serviceIds));
            setSprintList(_.uniq(sprints));
            setEnvList(_.uniq(envs));
            setStatusList(_.uniq(statusArray));
            setLoadingFilters(false);
        } else {
            setJiraIdList([]);
            setServiceIdList([]);
            setSprintList([]);
            setEnvList([]);
            setStatusList([]);
            setLoadingFilters(false);
        }
    }

    useEffect(() => {
        setLoadingFilters(true);
        setLocation("MyOrders");
        formTeamList();
        resetMyOrders();
        updateDisplayAlert(false);
    }, []);

    useEffect(() => {
        filterTableData(false);
    }, [jiraIdValue, startDateValue, endDateValue, serviceIdValue, sprintValue, envValue, statusValue]);

    useEffect(() => {
        const currentTeam = findStringInObjectArray(teamList, "teamId", authTeam);
        setSelectedTeam(currentTeam ? currentTeam : teamList[0]);
    }, [teamList]);

    useEffect(() => {
        filterTableData(myOrders);
    }, [myOrders]);

    useEffect(() => {
        updateDisplayAlert(false);
        setLoadingFilters(true);
        if (selectedTeam && selectedTeam.teamId && selectedTeam.teamName) {
            fetchMyOrders(selectedTeam.teamId, selectedTeam.teamName, 50, 0).then((resp) => {
                let respData = (resp ? resp : []);
                createFilterLists(respData);
                filterTableData(respData);
                clearFilterForm();
            });
        } else {
            setLoadingFilters(false);
            clearFilterForm();
        }
    }, [selectedTeam]);

    const filterTableData = async (orders) => {
        let filteredData = [];
        if (orders && orders.content) {
            filteredData = orders.content;
        } else if (!orders && myOrders && myOrders.content) {
            filteredData = myOrders.content;
        }
        
        await filter(filteredData, "jiraId", jiraIdValue).then((jiraIdResult) => {
            if (jiraIdValue) {
                filteredData = jiraIdResult;
            }
            let ServiceIdTempVal = serviceIdValue === "Custom Request" ? "" : serviceIdValue;
            filter(filteredData.filter(function(data){ return(data.serviceId !== undefined && data.serviceId !== null)}), "serviceId", ServiceIdTempVal).then((serviceIdResult) => {
                if (serviceIdValue) {
                    filteredData = serviceIdResult;
                }
                filter(filteredData.filter(function(data){ return(data.sprint !== undefined && data.sprint !== null)}), "sprint", sprintValue).then((sprintResult) => {
                    if (sprintValue) {
                        filteredData = sprintResult;
                    }
                    filter(filteredData.filter(function(data){ return(data.environment !== undefined && data.environment !== null)}), "environment", envValue).then((envResult) => {
                        if (envValue) {
                            filteredData = envResult;
                        }
                        filter(filteredData.filter(function(data){ return(data.status !== undefined && data.status !== null)}), "status", statusValue).then((statusResult) => {
                            if (statusValue) {
                                filteredData = statusResult;
                            }
                            filterDateRange(filteredData.filter(function(data){ return(data.createdDate !== undefined && data.createdDate !== null)}), "createdDate", startDateValue, endDateValue).then((dateResult) => {
                                if (startDateValue && endDateValue) {
                                    filteredData = dateResult;
                                }
                                let result = [];
                                if (filteredData.constructor == Array) {
                                    result = filteredData;
                                } else {
                                    result.push(filteredData);
                                }
                                setFilteredOrders(result);
                            });
                        });
                    });
                });
            });
            
        });
    }

    const filter = async (data, key, val) => {
        if (val || val === "") {
            return await filterObjectArray(data, key, val);
        }
    }

    const filterDateRange = async (data, key, start, end) => {
        if (start && end) {
            return await filterObjectArrayIsInDateRange(data, key, start, end);
        }
    }

    const filterForm = () => {
        return (
            <>
                <Grid container direction="column">
                    <Grid item sm={12}>
                        <FormAutoComplete 
                            label="Team"
                            required={false}
                            id="teamFilter"
                            fullWidth
                            value={selectedTeam}
                            onChange={(e, newValue)=> handleChangeTeam(newValue)}
                            inputValue={teamInputValue}
                            onInputChange={(e, newInputValue) => {
                                handleTeamInputChange(newInputValue);
                            }}
                            objectOptions={teamList}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <FormAutoComplete 
                            label="Jira ID"
                            required={false}
                            id="jiraIdFilter"
                            fullWidth
                            value={jiraIdValue}
                            onChange={(e, newValue)=> handleChangeJiraId(newValue)}
                            inputValue={jiraIdInputValue}
                            onInputChange={(e, newInputValue) => {
                                handleJiraIdInputChange(newInputValue);
                            }}
                            options={jiraIdList}
                            loading={loadingFilters}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Typography color="textSecondary" gutterBottom variant="h6" className={classes.section}>Created Date Range</Typography>
                        <div className={classes.customDatePickerWidth}>
                            <DateRangePicker
                                readOnly={true}
                                startDate={startDateValue || undefined}
                                endDate={endDateValue || undefined}
                                startDateId="startId"
                                endDateId="endId"
                                focusedInput={createdDateOnFocus}
                                onDatesChange={({startDate, endDate}) => handleChangeCreatedDate(startDate, endDate)}
                                onFocusChange={(focusedInput) => handleCreatedDateFocusChange(focusedInput)}
                                isOutsideRange={() => false} // Allows selection of all dates prior to now()
                                keepOpenOnDateSelect={false}
                                displayFormat="DD-MM-YYYY"
                                withPortal={false}
                                numberOfMonths={1}
                                disabled={loadingFilters}
                            />
                        </div>
                    </Grid>
                    <Grid item sm={12}>
                        <FormAutoComplete 
                            label="Service ID"
                            required={false}
                            id="serviceIdFilter"
                            fullWidth
                            value={serviceIdValue}
                            onChange={(e, newValue)=> handleChangeServiceId(newValue)}
                            inputValue={serviceIdInputValue}
                            onInputChange={(e, newInputValue) => {
                                handleServiceIdInputChange(newInputValue);
                            }}
                            options={serviceIdList}
                            loading={loadingFilters}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <FormAutoComplete 
                            label="Sprint"
                            required={false}
                            id="sprintFilter"
                            fullWidth
                            value={sprintValue}
                            onChange={(e, newValue)=> handleChangeSprint(newValue)}
                            inputValue={sprintInputValue}
                            onInputChange={(e, newInputValue) => {
                                handleSprintInputChange(newInputValue);
                            }}
                            options={sprintList}
                            loading={loadingFilters}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <FormAutoComplete 
                            label="Environment"
                            required={false}
                            id="envFilter"
                            fullWidth
                            value={envValue}
                            onChange={(e, newValue)=> handleChangeEnv(newValue)}
                            inputValue={envInputValue}
                            onInputChange={(e, newInputValue) => {
                                handleEnvInputChange(newInputValue);
                            }}
                            options={envList}
                            loading={loadingFilters}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <FormAutoComplete 
                            label="Status"
                            required={false}
                            id="statusFilter"
                            fullWidth
                            value={statusValue}
                            onChange={(e, newValue)=> handleChangeStatus(newValue)}
                            inputValue={statusInputValue}
                            onInputChange={(e, newInputValue) => {
                                handleStatusInputChange(newInputValue);
                            }}
                            options={statusList}
                            loading={loadingFilters}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }

    const clearFilterForm = () => {
        setJiraIdValue(null);
        setStartDateValue(null);
        setEndDateValue(null);
        setServiceIdValue(null);
        setSprintValue(null);
        setEnvValue(null);
        setStatusValue(null);
    }

    const formTeamList = async () => {
        const formattedTeams = _.map(isAdmin ? allTeams : listOfTeams, (value, key) => {
            return {label: value.teamName, teamId: value.teamId, teamName: value.teamName}
        });
        setTeamList(formattedTeams);
    }

    const updateAlertMsg = (type, msg, loading, hideDuration) => {
        setAlertType(type);
        setAlertMsg(msg);
        setAlertLoading(loading);
        setAlertAutoHide(hideDuration);
    }
  
    const updateDisplayAlert = (bool) => {
        setDisplayAlert(bool);
    }

    const args = {
        expandTitle: "Details",
        size: "medium",
        isLoading: myOrdersPending,
        rowsPerPageOptions: [10],
        totalRows: myOrders && myOrders.totalElements ? myOrders.totalElements : null,
        paginateByApi: {
            getPageData: async () => {
                updateDisplayAlert(false);
                let pageNo = 0;
                if (myOrders && myOrders.number >= 0) {
                    pageNo = myOrders.number+1;
                }
                return await fetchMyOrders(selectedTeam.teamId, selectedTeam.teamName, 50, pageNo, myOrders && myOrders.content).then((resp) => {
                    if (!resp) {
                        updateAlertMsg("error","Failed to retrieve more orders!",false,12000);
                        updateDisplayAlert(true);
                    }
                    return resp;
                });
            },
            isLoading: myOrdersPending,
        },
        columnMetadata: [
          {
            order: 1,
            title: "Jira Id",
            field: ["jiraId"],
            component: (field) => (
                <Typography noWrap variant="body2">
                    <Link href={`https://jira.tools.telstra.com/browse/${field}`} target="_blank">{field}</Link>
                </Typography>
            ),
          },
          {
            order: 2,
            title: "Created Date",
            field: ["createdDate"],
            component: (field) => (
              <Typography noWrap variant="body2">
                {moment(field).format("DD-MM-YYYY")}
              </Typography>
            ),
          },
          {
            order: 3,
            title: "Service ID",
            field: ["serviceId"],
            component: (field) => (
                field ?
                <RoundBtn>{field}</RoundBtn>
              : 
                <Tooltip 
                    arrow placement="right" 
                    title={"Custom Request"}
                >
                    <span>
                        <RoundBtn>{"CR"}</RoundBtn>
                    </span>
                </Tooltip>
            ),
          },
          {
            order: 4,
            title: "Qty Req",
            field: ["quantityReq"],
            component: (field) => (
              <Typography noWrap variant="body2">
                {field}
              </Typography>
            ),
          },
          {
            order: 5,
            title: "Sprint",
            field: ["sprint"],
            component: (field) => (
                <Typography noWrap variant="body2">
                    {field}
                </Typography>
            ),
          },
          {
            order: 6,
            title: "Environment",
            field: ["environment"],
            component: (field) => (
                <Typography noWrap variant="body2">
                    {field}
                </Typography>
            ),
          },
          {
            order: 7,
            title: "Quantity Alct",
            field: ["quantityAlct"],
            component: (field) => (
                <Typography noWrap variant="body2">
                    {field}
                </Typography>
            ),
          },
          {
            order: 8,
            title: "Status",
            field: ["status"],
            component: (field) => (
                <Typography noWrap variant="body2">
                    {field}
                </Typography>
            ),
          },
        ],
        filterComponent: () => <LeftSidePanel 
                                    panelTitle={"Filter Orders"}
                                    size={17}
                                    panelIconComponent={
                                        <Tooltip 
                                            arrow placement="right" 
                                            title={myOrdersPending && (isAdmin ? (allTeams && allTeams.length > 0) : listOfTeams) ? "Loading..." : myOrdersSuccess ? "Open filter menu" : "Filters unavailable"}
                                        >
                                            <span>
                                                <FilterIcon size="medium" />
                                            </span>
                                        </Tooltip>
                                    }
                                >
                                    <FilterForm
                                        autoCompleteFilters={filterForm()}
                                        handleClear={clearFilterForm}
                                    />
                                </LeftSidePanel>,
    }

    useEffect(() => {
            fetchMyOrders(authTeam, teamName, 50, 0).then((resp) => {
                let respData = (resp ? resp : []);
                createFilterLists(respData);
                filterTableData(respData);
                clearFilterForm();
            });
    }, []);
    return (
        <div className="white">
            <Header title={`My Orders ${selectedTeam ? `- ${selectedTeam.label}` : ""}`}/>
            <BreadTable {...args} data={filteredOrders} noDataMsg={myOrdersFailed ? myOrdersFailedMsg : ""}/>
            <SnackbarAlert 
                isOpen={(alertMsg && displayAlert) ? true : false} 
                severity={alertType}
                message={alertMsg} 
                position={{horizontal:'center', vertical:'bottom'}}
                autoCloseDuration={alertAutoHide}
                loading={alertLoading}
                handleClose={()=>{
                    updateAlertMsg("info","",false,5000);
                    updateDisplayAlert(false);
                }}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    isAdmin: state.auth.isAdmin,
    listOfTeams: state.auth.myAvailableTeams,
    allTeams: state.teams.teams.allTeams,
    authTeam: state.auth.team,
    teamName: state.auth.teamName,

    myOrders: state.orderBuilder.myOrders,
    myOrdersPending: state.orderBuilder.myOrdersPending,
    myOrdersFailed: state.orderBuilder.myOrdersFailed,
    myOrdersFailedMsg: state.orderBuilder.myOrdersFailedMsg,
    myOrdersSuccess: state.orderBuilder.myOrdersSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLocation: (path) => dispatch(setLocation(path)),
    fetchMyOrders: (teamId, teamName, size, page, prevContent) => dispatch(fetchMyOrders(teamId, teamName, size, page, prevContent)),
    resetMyOrders: () => dispatch(resetMyOrders()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyOrders);