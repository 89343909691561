import React from "react";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { createUsageFileAction } from "./action";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import swal from 'sweetalert2';
import { imsiValidatorForOCS, msisdnValidator, numericValidation } from '../../common/form/syncValidators';

const CreateUsageFile = (props) => {
    const [createUsageData, setCreateUsageData] = useState({});
    const [msisdnError, setMsisdnError] = useState(false);
    const [createUsageError, setCreateUsageError] = useState(false);
    const [usageTypeCodeError, setUsageTypeCodeError] = useState(false);
    const [chargeAmountError, setChargeAmountError] = useState(false);
    const [usageQuantityError, setUsageQuantityError] = useState(false);
    const [customerIdError, setCustomerIdError] = useState(false);
    const [imsiError, setImsiError] = useState(null);
    const [masterPlanInstanceIdError, setMasterPlanInstanceIdError] = useState(null);
    const [eventTypeError, setEventTypeError] = useState(false);
    const [fileTypes] = useState(["UDR","NRC"]);
    const [fileTypeValue, setFileTypeValue] = useState(null);
    const [environmentType] = useState(["SIT", "PRESIT"]);
    const [environmnetValue, setEnvironmnetValue] = useState(null);
    const [buttonDisable, setButtonDisable] = useState(true);

    const handleFileType = (event) => {
        setFileTypeValue(event.target.value);
        handleCreateUsageFile(event);
    };

    const handleEnvType = (event) => {
        setEnvironmnetValue(event.target.value);
        handleCreateUsageFile(event);
    }

    const handleCreateUsageFile = (e) => {
        let obj = { ...createUsageData };
        obj[e.target.name] = e.target.value;
        if(obj["masterPlanInstanceId"]===""){
            setMasterPlanInstanceIdError("Please enter Master Plan Instance Id")
        }else{
            setMasterPlanInstanceIdError("")
        }
        if(obj["fileType"]==="UDR"){
            delete obj["eventType"];
        }else{
            delete obj["usageTypeCode"];
        }
        let createUsageDataFilter = _.omitBy(obj, (v) => v === '');
        setCreateUsageData(createUsageDataFilter);
    }

    useEffect(() => {
        if (createUsageData.msisdn) {
            let msisdnError = msisdnValidator(createUsageData.msisdn);
            setMsisdnError(msisdnError);
        }
        if (createUsageData.usageDate === '') {
            setCreateUsageError(true);
        } else {
            setCreateUsageError(false);
        }
        if (createUsageData.usageTypeCode) {
            setUsageTypeCodeError(numericValidation(createUsageData.usageTypeCode, "usageTypeCode"));
        }
        if (createUsageData.chargeAmount) {
            setChargeAmountError(numericValidation(createUsageData.chargeAmount, "chargeAmount"));
        }
        if (createUsageData.usageQuantity) {
            setUsageQuantityError(numericValidation(createUsageData.usageQuantity, "usageQuantity"));
        }
        if (createUsageData.customerId) {
            setCustomerIdError(numericValidation(createUsageData.customerId, "customerId"));
        }
        if (createUsageData.imsi) {
            setImsiError(imsiValidatorForOCS(createUsageData.imsi));
        }
        if(createUsageData.eventType){
            setEventTypeError(numericValidation(createUsageData.eventType, "Event Type"))
        }
    }, [createUsageData]);

    useEffect(()=>{
        if(createUsageData.fileType==="UDR"){
            setEventTypeError(false);
        }else{
            setUsageTypeCodeError(false);
        }
    },[createUsageData])

    useEffect(() => {
        let disableStatus = true;
        if (createUsageData.environment && createUsageData.usageDate && createUsageData.masterPlanInstanceId) {
            if ((createUsageData.msisdn && !msisdnValidator(createUsageData.msisdn)) && (createUsageData.customerId && !numericValidation(createUsageData.customerId, "customerId"))) {
                if (createUsageData.chargeAmount && !numericValidation(createUsageData.chargeAmount, "chargeAmount")) {
                    if (createUsageData.usageQuantity && !numericValidation(createUsageData.usageQuantity, "usageQuantity")) {
                        if (createUsageData.fileType==="NRC") {
                            if(createUsageData.eventType && !numericValidation(createUsageData.eventType, "Event Type")){
                                disableStatus = false;
                            }
                        }else{
                            if(createUsageData.usageTypeCode && !numericValidation(createUsageData.usageTypeCode, "usageTypeCode")){
                                disableStatus = false;
                            }
                        }
                    }
                }
            }
        }
        setButtonDisable(disableStatus)
    }, [createUsageData]);

    const handleSubmit = () => {
            let update_obj = {
                team: props.team,
                dNumber: props.dNumber,
            }
            let usageDate = createUsageData.usageDate;
            let removeHyphenDate = usageDate.replace(/-/g, "");
            createUsageData.usageDate = removeHyphenDate;
            if(createUsageData["fileType"]==="NRC"){
                createUsageData["fileType"]="NRR"
            }
            props.createUsageFileAction({ ...createUsageData, ...update_obj })
    }

    useEffect(()=>{
        let obj = {};
        obj["fileType"] = "UDR";
        setCreateUsageData(obj);
        setFileTypeValue("UDR")
    },[]);

    return <>
        <div className="checkinContainer container">
            <div class="row">
                <div class="col-1"></div>
                <div class="col-5">
                    <FormControl fullWidth variant="filled" required={true}>
                        <InputLabel id="demo-simple-select-filled-label">File Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            defaultValue={fileTypes[0]}
                            name={"fileType"}
                            label="File Types"
                            onChange={handleFileType}
                            required={true}
                            helperText={
                                !fileTypeValue
                                    ? ""
                                    : "File type cannot be empty"
                            }
                        >
                            {fileTypes.map((_item) => <MenuItem value={_item}>{_item}</MenuItem>)}
                        </Select>
                    </FormControl><br /> <br />
                    <TextField
                        label="MSISDN"
                        variant="filled"
                        color="primary"
                        required={true}
                        name={"msisdn"}
                        value={createUsageData.msisdn || ""}
                        error={msisdnError}
                        onChange={(e) => handleCreateUsageFile(e)}
                        fullWidth
                        helperText={msisdnError ? msisdnError : " "}
                     /> <br />
                       <TextField
                        label="Customer Id"
                        variant="filled"
                        color="primary"
                        required={true}
                        name={"customerId"}
                        value={createUsageData.customerId || ""}
                        error={customerIdError}
                        onChange={(e) => handleCreateUsageFile(e)}
                        fullWidth
                        helperText={customerIdError ? customerIdError : " "}
                    /> <br />
                    {createUsageData.fileType === "NRC" ?
                            <TextField
                                label="Event Type"
                                variant="filled"
                                color="primary"
                                required={true}
                                name={"eventType"}
                                value={createUsageData.eventType || ""}
                                error={eventTypeError}
                                onChange={(e) => handleCreateUsageFile(e)}
                                fullWidth
                                helperText={eventTypeError ? eventTypeError : " "}
                            />
                        : <TextField
                            label="Usage Type Code"
                            variant="filled"
                            color="primary"
                            required={true}
                            name={"usageTypeCode"}
                            error={usageTypeCodeError}
                            value={createUsageData.usageTypeCode || ""}
                            onChange={(e) => handleCreateUsageFile(e)}
                            fullWidth
                            helperText={usageTypeCodeError ? usageTypeCodeError : " "}
                        />}
                     <br /> 
                    <TextField
                        label="Usage Quantity"
                        variant="filled"
                        color="primary"
                        required={true}
                        name={"usageQuantity"}
                        value={createUsageData.usageQuantity || ""}
                        error={usageQuantityError}
                        onChange={(e) => handleCreateUsageFile(e)}
                        fullWidth
                        helperText={
                            !usageQuantityError
                                ? " "
                                : "Usage Quantity cannot be empty"
                        }
                    /> <br /> 
                </div>
                <div class="col-5">
                <FormControl fullWidth variant="filled" required={true}>
                        <InputLabel id="demo-simple-select-filled-label">Environment</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={environmnetValue}
                            name={"environment"}
                            label="Environment"
                            onChange={handleEnvType}
                            required={true}
                            helperText={
                                !environmnetValue
                                    ? " "
                                    : "environment cannot be empty"
                            }
                        >
                            {environmentType.map((_item) => <MenuItem value={_item}>{_item}</MenuItem>)}
                        </Select>
                    </FormControl><br /> <br />
                    <TextField
                        label="IMSI"
                        variant="filled"
                        color="primary"
                        name={"imsi"}
                        value={createUsageData.imsi}
                        error={imsiError}
                        onChange={(e) => handleCreateUsageFile(e)}
                        fullWidth
                        helperText={imsiError ? imsiError : " "}
                    /> <br />
                     <TextField
                        label="Master Plan Instance Id"
                        variant="filled"
                        color="primary"
                        required={true}
                        name={"masterPlanInstanceId"}
                        error={masterPlanInstanceIdError}
                        helperText={masterPlanInstanceIdError ? masterPlanInstanceIdError : " "}
                        value={createUsageData.masterPlanInstanceId}
                        onChange={(e) => handleCreateUsageFile(e)}
                        fullWidth
                    /> 
                    <TextField
                        label="Charge Amount"
                        variant="filled"
                        color="primary"
                        required={true}
                        name={"chargeAmount"}
                        value={createUsageData.chargeAmount || ""}
                        error={chargeAmountError}
                        onChange={(e) => handleCreateUsageFile(e)}
                        fullWidth
                        helperText={chargeAmountError ? chargeAmountError : " "}
                    /> <br />
                      <TextField
                        label="Usage Date"
                        name={"usageDate"}
                        InputLabelProps={{ shrink: true }}
                        variant="filled"
                        color="primary"
                        required={true}
                        type="date"
                        value={moment(createUsageData.usageDate || null).format("yyyy-MM-DD")}
                        error={createUsageError}
                        onChange={(e) => handleCreateUsageFile(e)}
                        fullWidth
                        helperText={
                            !createUsageError
                                ? " "
                                : "Usage Date required"
                        }
                    />
                    <br />
                </div>
            </div>
            <div>
                <Button disabled={buttonDisable} onClick={handleSubmit} variant="contained" sx={{ float: 'right', marginRight: '105px' }} >Submit </Button>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => {
    return {
        selectedenv: state.auth.selectedEnvValue,
        user: state.auth.userName,
        environment: state.auth.selectedEnvValue,
        dNumber: state.auth.user.username,
        team: state.auth.team
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createUsageFileAction: (request) => dispatch(createUsageFileAction(request))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateUsageFile);
