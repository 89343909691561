import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import Carousel from 'react-material-ui-carousel';
import { IconButton, Card,CardMedia,Typography, Tooltip,makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import NewReleasesTwoToneIcon from '@material-ui/icons/NewReleasesTwoTone';
import TodayIcon from '@mui/icons-material/Today';
import './carousal.css'
import msgIcon from './msgIcon.png'
import {setNotificationReadStatus} from "../../../actions/carousalActions";
import {toggleNotificationReadFlag } from '../../../actions/notificationActions';
import Alert from '@mui/material/Alert';

const CarouselMsg = (props) => {
    
    const [notificationDialog, setNotificationDialog] = useState(true);
    const [hideCloseIcon, setHideCloseIcon] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
        
    const onClose = (e) => {
        if (hideCloseIcon || props.carousalItems.length === 1) {
            setNotificationDialog(false);
            props.carousalItems.map((items,index)=>
            {props.toggleNotificationReadFlag(props.carousalItems[index])
            })
            return true;
        } else {
            setShowAlert(true);
            return false;
        }
    }
    const getNext = (e) => {
        setShowAlert(false);
        if (e === props.carousalItems.length - 1) {
            setHideCloseIcon(true)
        }
        else {
            setHideCloseIcon(false)
        }
    }
    
    return (
        <Dialog open={notificationDialog} onClose={onClose} maxWidth="xl" id="msgDialog">
            <DialogTitle class="notificationFeatureHeader">
                <Typography align="center" variant="span" style={{"marginLeft":"32px"}}>
                    Don't miss new updates from us!
                </Typography>
                {
                    <Tooltip title="Close">
                        <IconButton onClick={e => onClose()} hidden={props.carousalItems.length !== 1 && !hideCloseIcon} className="float-right closeIcon" style={{"marginTop":"-7px"}}>
                            <CloseIcon color="action" />
                        </IconButton> 
                    </Tooltip>
                }
                <Card sx={{ maxWidth: 345 }} className="msgCard">
                    <CardMedia
                        className="msgMedia"
                        component="img"
                        height="140"
                        image={msgIcon}
                        alt="msgIcon"
                    />
                </Card>
            </DialogTitle>
            <Carousel 
                autoPlay={false} 
                cycleNavigation={false} 
                activeIndicatorIconButtonProps={{"className": "carousalActiveBtn"}}
                next={e => getNext(e)} 
                navButtonsAlwaysVisible={true} 
                className ="notificationCarousel">
                {
                    props.carousalItems.map((item, i) => (
                        <>
                            <Typography 
                                variant='caption' 
                                style={{
                                    "fontSize":"13px",
                                    "textAlign":"right",
                                    "display":"block",
                                    "marginTop":"0px",
                                    "borderBottom":"2px solid #0d4e8f"
                                }}
                            >
                                <TodayIcon className="pr-2 mt-n1" />
                                {new Date(item.postedAt).toLocaleDateString('en-GB',{day: 'numeric', month: 'short', year: 'numeric'})}
                            </Typography>
                            <div key={item.id} style={{"marginTop":"5px","height":"170px","overflowY":"auto"}}>
                                <Typography variant='span' className="notifyHeader">
                                    <NewReleasesTwoToneIcon className="pr-2 mt-n1" />
                                    {item.title}
                                </Typography>
                                
                                <div className="mt-n3 notifyDescription">
                                    <Typography variant='span'>{item.message}</Typography>
                                </div>
                                {item.link && 
                                    <div className="mt-n2 notifyDescription">
                                        <a href={item.link} target="_blank" rel={'external'}>Learn More</a>    
                                    </div> 
                                }  
                            </div>
                        </>
                    ))
                }
             </Carousel>
            {
                showAlert && 
                <Alert severity="warning" style={{"justifyContent":"center"}}>
                    Please read all notifications to continue!
                </Alert>
            }
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
      userId: state.auth.userName,
    }
}

  
  const mapDispatchToProps = (dispatch) => {
    return {
    setNotificationReadStatus: (value) => dispatch(setNotificationReadStatus(value)),
    toggleNotificationReadFlag : (req) => dispatch(toggleNotificationReadFlag(req)),
    }
  }
  
  export default connect(mapStateToProps,mapDispatchToProps)(CarouselMsg);