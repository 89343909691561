import React from 'react';
import { Table } from 'reactstrap';
import { SubHeader } from '../../../common/custom/SubHeader';
import config from '../../../../config';

const SALESFORCE_API = config.Salesforce_SIT_env.uri;


const createHandler = (createType, tableEntries, createContactHandler) => {
    if(createType === 'contacts'){
        const {accountId} = tableEntries[0];
        createContactHandler(accountId)
    }else{

    }
}

const getHeaderDetails = (colHeaders) => {
    if (colHeaders === 'divider')
        return (<div class="dropdown-divider" style={{ "border-top": "1px groove #4C6EF5" }}></div>)
    else
        return (
            <thead>
                <tr>
                    {colHeaders.filter(v => v != 'url').map(key => (<th style={{ "width": '20%' }}>{key}</th>))}
                </tr>
            </thead>
        );

}


const getAccountRowDetails = (colHeaders, tableEntries) => {
    return (
        <tbody>
            <tr>
                {colHeaders.map(entry => {
                    if (entry === 'accountId')
                        return (
                            <td style={{ "width": '20%' }}>
                                <a
                                    href={`${SALESFORCE_API}/lightning/r/Account/${tableEntries[entry]}/view`}
                                    target="_blank"
                                    title="View in Salesforce">{tableEntries[entry]}</a>
                            </td>
                        );
                    else
                        if (entry !== 'url')
                            return (<td style={{ "width": '20%' }}>{tableEntries[entry]}</td>);
                })}
            </tr>
        </tbody>
    );
}
const getOtherRowDetails = (colHeaders, tableEntries) => {
    return (
        <tbody>
            {tableEntries.map(entry => {
                return (<tr>
                    {colHeaders.map(data => <td style={{ "width": '20%' }}>{entry[data]}</td>)}
                </tr>);
            })}
        </tbody>
    );
}
export const SfTableWrapper = ({ header, colHeaders, tableEntries, createContactHandler }) => {
    //removing the seperator from the rest of the other Column Headers
    const colKeyValues = header !== 'seperator' ? Object.keys(colHeaders) : colHeaders;
    const colHeaderValues = header !== 'seperator' ? Object.values(colHeaders) : colHeaders;
    return (
        <React.Fragment>
            <div style={{ display: 'block' }}>
                {tableEntries !== "divider" && <SubHeader
                    text={header.toUpperCase()}
                    iconLeft={true}
                    small={true}
                    iconClass={header.toLowerCase()}
                    iconColor="blue"
                    iconSize={"1.25em"}
                    addStyle={{float:'left'}} />}
                {/* {"order|contacts".match(header.toLowerCase()) && 
                <button onClick={()=>createHandler(header, tableEntries, createContactHandler)} class="btn btn-sm btn-block btn-outline-success ld-over" style={{float: 'right',margin: '1rem 1rem',width: '11rem'}}>
                    <i class="fa fa-plus" aria-hidden="true">
                    </i>Create {header}<div class="ld ld-ring ld-spin">
                    </div>
                </button>} */}
            </div>


            <Table>

                {getHeaderDetails(colHeaderValues)}

                {header === 'account' && tableEntries !== "divider" && getAccountRowDetails(colKeyValues, tableEntries)}
                {header !== 'account' && tableEntries !== "divider" && getOtherRowDetails(colKeyValues, tableEntries)}

            </Table>
        </React.Fragment>
    );
}