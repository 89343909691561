import { AUTH_LOGOUT } from "../actions/actionTypes";
import authReducer from './authReducer';
import checkoutReducer from './checkoutReducer';
import { combineReducers } from 'redux';
import dataActiveReducer from './dataActiveReducer';
import dataReducer from './dataReducer';
import diagramsReducer from './diagramsReducer';
import filterReducer from './filterReducer';
import { reducer as formReducer } from 'redux-form';
import { itemsReducer } from './itemsReducer';
import { settingsReducer } from './settingsReducer';
import  statsReducer  from './statsReducer';
import usersReducer from './usersReducer';
import pasmatTrendReducer from './pasmatTrendReducer';
import profileReducer from './profileReducer';
import basketReducer from './basketReducer';
import docsReducer from './docsReducer';
import { reducers as swalReducers } from 'react-redux-sweetalert2';
import feedbackReducer from "./feedbackReducer";
import feedbackForm from "./feedbackForm";
import dataBankCheckoutReducer from '../components/Data/dataviews/DataBankCheckout/reducer';
import standardDataBankCheckoutReducer from '../components/standardDataBank/Checkout/reducer';
import dataBankCheckoutHistoryReducer from '../pages/DataBankCheckoutHistory/reducer';
import dataBankVacantCheckoutReducer from '../components/Data/DataBankVacant/reducer';
import utilityReducer from "./utilityReducer";
import teamsReducer from "./teamsReducer";
import noSqlTeamsReducer from "./NoSqlTeamsReducer";
import customCheckinReducer from "./customCheckinReducer";
import replicatorReducer from "./replicatorReducer";
import tconnectReducer from "./tconnectReducer";
import dataCleanup from "./dataCleanUpReducer";
import ssoAuthReducer from './ssoAuthReducer';
import u2cReducer from './u2cReducers';
import subscriptionReducer from './subscriptionReducer';
import sfCustomerReducer from "./sfCustomerReducer";
import notificationReducer from "./notificationReducer";
import broadCastReducer from './broadCastReducer';
import tdmOpsReducer from './tdmOpsReducer';
import cosmosReportReducer from './cosmosReportReducer';
import ddcReducer from './ddcReducer';
import trainingBadgesReducer from './trainingBadgesReducer';
import carouselReducer from "./carouselReducer";
import userFeedbackReducer from "./userFeedbackReducer";
import b2bDataReducer from "./b2bDataReducer";
import retrieveDataReducer from "./retrieveDataReducer";
import orderBuilderReducer from "./orderBuilderReducer";
import somResourceStubReducer from "./somResourceStubReducer";
import syntheticDataReducer from "./syntheticDataReducer";
import tourReducer from "./tourReducer";
import timMigrationReducer from './timMigrationReducer';
import salesforceSearchReducer from "./salesforceSearchReducer";
import thresholdReducer from "./thresholdReducer";
import ocsUsageLoadReducer from "../components/utility/OcsUsageLoad/ocsUsageLoadReducer";

const appReducer = combineReducers({
    items: itemsReducer,
    form: formReducer.plugin({
    unmsUpdate: (state, action) => {
      switch (action.type) {
        case "RESET_SNRM_RESOURCES":
          return {
            ...state,
            anyTouched: false,
            fields: {
              "ranges": [
                {
                  "startRange": {
                    touched: false,
                    visited: false
                  },
                  "endRange": {
                    touched: false,
                    visited: false
                  },
                }
              ]
            },
            "values": {
              "ranges": [
                {
                  "startRange": "",
                  "endRange": ""
                }
              ]
            },
            "initial": {
              "ranges": [
                {
                  "startRange": "",
                  "endRange": ""
                }
              ]
            },
            "registeredFields": {
              "ranges[0].startRange": {
                "name": "ranges[0].startRange",
                "type": "Field",
                "count": 1
              },
              "ranges[0].endRange": {
                "name": "ranges[0].endRange",
                "type": "Field",
                "count": 1
              },
              "ranges": {
                "name": "ranges",
                "type": "FieldArray",
                "count": 1
              }
            },
            "syncErrors": {
              "ranges": [
                {
                  "startRange": "",
                  "endRange": ""
                }
              ]
            }
          }
        default:
          return state;
      }
    }
  }),
    auth: authReducer,
    checkout: checkoutReducer,
    data: dataReducer,
    dataActive: dataActiveReducer,
    diagrams: diagramsReducer,
    docs: docsReducer,
    filter: filterReducer,
    users: usersReducer,
    profiles: profileReducer,
    basket: basketReducer,
    stats: statsReducer,
    settings: settingsReducer,
    feedbacks: feedbackReducer,
    feedback: feedbackForm,
    swal: swalReducers,
    dataBankCheckout : dataBankCheckoutReducer,
    standardDataBankCheckout: standardDataBankCheckoutReducer,
    dataBankCheckoutHistory: dataBankCheckoutHistoryReducer,
    dataBankVacant: dataBankVacantCheckoutReducer,
    utility: utilityReducer,
    teams: teamsReducer,
    noSqlTeams: noSqlTeamsReducer,
    customCheckin : customCheckinReducer,
    replicatorData: replicatorReducer,
    tconnect: tconnectReducer,
    cleanup: dataCleanup,
    ssoAuth : ssoAuthReducer,
    u2c: u2cReducer,
    subscription: subscriptionReducer,
    sfCustomer: sfCustomerReducer,
    notification: notificationReducer,
    broadCastReducer: broadCastReducer,
    tdmOps: tdmOpsReducer,
    cosmosReport: cosmosReportReducer,
    pasmatTrend: pasmatTrendReducer,
    ddc: ddcReducer,
    trainingBadges : trainingBadgesReducer,
    dashboardCarousel : carouselReducer,
    userFeedback : userFeedbackReducer,
    b2bDataCleanUp : b2bDataReducer,
    retrieveData : retrieveDataReducer,
    orderBuilder: orderBuilderReducer,
    somResourceStub: somResourceStubReducer,
    syntheticData: syntheticDataReducer,
    tour: tourReducer,
    timMigration: timMigrationReducer,
    salesforceSearch: salesforceSearchReducer,
    serviceIdThresholdData: thresholdReducer,
    ocsUsageLoad:ocsUsageLoadReducer
});

function rootReducer (state, action) {
    if (action.type === AUTH_LOGOUT) {
      state = undefined;
    }
    return appReducer(state, action);
}

export default rootReducer;
