import {axiosInstance as axios} from '../axios.config';
import * as types from './actionTypes';

import config from '../config';

import { swal } from 'react-redux-sweetalert2';

import { authHeaders } from '../utils/api';

import {filterMobileResponse} from '../utils/filterResponse';

import {findUnique} from '../utils/state';

const API_URL = config.api.uri;
const TDM_GATEWAY_API = config.tdm_gateway_api.uri;
const optionTypeExistingKey = config.dataBank_existing_request.key;
const optionTypeExistingValue = config.dataBank_existing_request.value;
// const optionTypeExistingMobileKey = config.data
//TODO had to add it in a seperate file
const getSalesForceRequestJSON = {
    "searchContent": "TWJXTQQ AUTOHYWMTG",
    "salesforceObjects": [
        {
            "fields": [
                "id","Name","CIDN__c","CustomerId__c","AccountUUID__c","TCMUUID__c","TCMMasterId__c","SOU__c","ABN__c","ACN__c"
            ],
            "name": "Account",
            "limit": 10
        },
        {
            "fields": [
                "id","AccountId","FirstName","LastName","Email"
            ],
            "name": "Contact"
        }
    ],
    "in": "ALL",
    "overallLimit": 100,
    "defaultLimit": 10
}

const checkInRequestData = {

    "description": "TDI Asset CheckIn",
    "scenarioName": "MOBILE CONNECT WITH SIM DISPATCH", // dynamic checkout  ID //One of scenarioName or userStory is mandatory
    "userStory": "TDM_TDI_DETAILS_STORE", //One of scenario Name or userStory  is mandatory
    "source": "TDM", //TDM
    "assetVersion": 0, // Integer
    "environment": "",
    "order": [
        {
            "orderId": "", //Mandatory Parameter dynamaic
            "type": "CONNECT", //Mandatory Parameter
            "orderSubType": "",
            "orderPlan": "Telstra Platinum Service Subscription", //Telstra Platinum Service Subscription
            "orderServiceType": "Telstra Platinum Service", //MOBILE OR NBN //Mandatory Parameter dynamic 
            "orderTech": "POSTPAID", //Mandatory Parameter
            "orderStatus": "COMPLETED", //COMPLETED
            "triggerSystem": "SIEBEL",//SIEBEL
        }
    ],
    "tdi": [{
        "userName": "",
        "password": "",
        "dob": ""
    }]


};
const checkOutRequestData = {
    "scenarioName": "",
    "userStory": "TDM_TDI_DETAILS_STORE",
    "environment": "",
    "quantity": 1 // Quantity 1 is supported in MVP, it will support multiple quantity in future, trying multiple quantity may result in curruption of data checked in by you.
};

const tdiCheckoutDetailResponse = {    
    "scenarioName" : "",
    "userStory": "TDM_TDI_DETAILS_STORE"
}
// end of TODO
const loadDataFilterSuccess = (payload) => ({ type: types.LOAD_DATA_FILTER_SUCCESS, payload });
const loadDataActiveOptionsSuccess = (payload) => ({ type: types.LOAD_DATA_ACTIVE_FILTER_SUCCESS, payload });
const loadDataSuccess = (payload) => ({ type: types.LOAD_DATA_SUCCESS, payload });
const loadDataActiveSuccess = (payload) => ({ type: types.CHECKOUT_DATA_ACTIVE_SUCCESS, payload });
const loadDataBankDataActiveSuccess = (payload) => ({ type: types.CHECKOUT_DATA_BANK_DATA_ACTIVE_SUCCESS, payload });

export const fetchDataActiveOptions = () => async (dispatch) => {
    try {
        const response = await axios.get(`${API_URL}/api/active/checkout/options`);
        dispatch(loadDataActiveOptionsSuccess(response.data));
    } catch (e) {
        console.error(e);
    }
};

export const selectActiveFilter = (key, value) => dispatch => {
    dispatch({ type: types.SELECT_DATA_ACTIVE_FILTER, key, value });
};

export const selectActiveOption = (option) => dispatch => {
    dispatch({ type: types.SELECT_DATA_ACTIVE_OPTION, option });
}

export const selectFilterEnvType = (envType) => async (dispatch, getState) => {
    dispatch({ type: types.SELECT_DATA_FILTER_ENV_TYPE, envType });
    try {
        if (envType) {
            dispatch({ type: types.LOAD_DATA_FILTER_LOADING });
            const response = await axios.get(`${API_URL}/api/download/filter?envType=${envType}`, { headers: authHeaders() });
            console.log("filter_Env", response.data);
            //adding mobile type even its not present
            dispatch(loadDataFilterSuccess({...response.data, dataFilter:findUnique([...response.data.dataFilter, "Mobile"])}));
        }
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
};

export const selectFilterDataType = (dataType) => async (dispatch, getState) => {
    dispatch({ type: types.SELECT_DATA_FILTER_DATA_TYPE, dataType });
    try {
        if (dataType) {
            dispatch({ type: types.LOAD_DATA_FILTER_LOADING });
            // get selected env
            //adding products even if mobile is not present
                const { envTypes } = getState().data.selectedFilters;
                const response = await axios.get(`${API_URL}/api/download/filter?envType=${envTypes}&dataType=${dataType}`, { headers: authHeaders() });
                    dispatch(loadDataFilterSuccess({...response.data, "products":findUnique([...response.data.products,"Hybrid","Postpaid"])}));
             }
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
};


export const selectFilterClassType = (classType, className, required) => async dispatch => {
    dispatch({ type: types.SELECT_DATA_FILTER_CLASS_TYPE, classType, className, required });
};

export const selectFilterQtyType = (qtyType) => async dispatch => {
    dispatch({ type: types.SELECT_DATA_FILTER_QTY_TYPE, qtyType });
};

export const selectFilterProductType = (productType) => async (dispatch,getState) => {
    dispatch({ type: types.SELECT_DATA_FILTER_PRODUCT_TYPE, productType });
    try {
        if (productType === 'PortIn') {
            const {envTypes,dataTypes} =  getState().data.selectedFilters;
            dispatch({ type: types.LOAD_DATA_FILTER_LOADING });
            // get selected env
            //adding products even if mobile is not present
                const response = await axios.get(`${API_URL}/api/download/filter?envType=${envTypes}&dataType=${dataTypes}&productType=${productType}`, { headers: authHeaders() });
                    dispatch(loadDataFilterSuccess({...response.data, "products":findUnique([...response.data.products,"Hybrid","Postpaid"])}));
             }
        return true;
    } catch (e) {
        console.error(e);
    }
};

export const selectFilterForeignCarrierType = (carrierType) => async dispatch => {
    dispatch({ type: types.SELECT_DATA_FILTER_FOREIGN_CARRIER_TYPE, carrierType })
}

export const selectFilterResourceType = (resourceType) => async dispatch => {
    dispatch({ type: types.SELECT_DATA_FILTER_RESOURCE_TYPE, resourceType })
}


//Vacant
export const checkoutWithFilter = (envType, dataType, classType, className, qtyType, fnns, productType, resourceType, foreignCarrierType) => async dispatch => {
    let checkTagId = true;
    const resourceQuery = resourceType && resourceType !== "MSISDN and SIM" ? `resource=${resourceType.toLowerCase()}&` : "&";
    const foreignCarrierQuery = foreignCarrierType ? `&CarrierName=${foreignCarrierType}` : "&";
    if (dataType == "Mobile") {
        classType = productType;
        checkTagId = false;
    }

    try {
        dispatch({ type: types.LOAD_DATA_LOADING });
        const response = await axios.get(`${API_URL}/api/download/checkout?envType=${envType}&dataType=${dataType}&techType=${classType}${className ? `&className=${className}` : ''}&qtyType=${qtyType}${foreignCarrierQuery}${resourceQuery}fnn=${fnns}&tagIds=${checkTagId}`, { headers: authHeaders() });
        dispatch(loadDataSuccess(response.data));
        console.log(response.data);
        return response.data;
    } catch (error) {
        return error;
    }
    return false;
}


//Active
export const checkoutActive = (envType, optionTypeId, qty = 1) => async dispatch => {const indexOption= optionTypeExistingKey.indexOf(optionTypeId);
    if(indexOption != -1)
    {
        const requestUrl = `${TDM_GATEWAY_API}/tdm/checkout/asset`;
        try {
            //Changes here for api request if optionTypeId = 44.
            const postData = {
                serviceId: optionTypeExistingValue[indexOption],
                environment: envType,
                quantity: qty,
                scope:"PUBLIC"
            }
            const response = await axios.post(requestUrl, postData, { headers: authHeaders() });
            dispatch(loadDataBankDataActiveSuccess(response.data));
            return {result:response.data};

        } catch (err) {
            return {result:err, requestUrl};
        }
    }
    else {
        try {
            const response = await axios.get(`${API_URL}/api/active/checkout?envType=${envType}&optionType=${optionTypeId}&qtyType=${qty}&e2e=false`, {headers: authHeaders()});
            dispatch(loadDataActiveSuccess(response.data));
            return {result: response.data};
        } catch (err) {
            const requestUrl = `${API_URL}/api/active/checkout?envType=${envType}&optionType=${optionTypeId}&qtyType=${qty}&e2e=false`;
            return {result: err, requestUrl};
        }
    }
    return false;
}

export const resetSelectedFilters = () => async dispatch => {
    dispatch({ type: types.SELECT_DATA_FILTER_RESET });
    try {
        dispatch({ type: types.LOAD_DATA_FILTER_LOADING });
        const response = await axios.get(`${API_URL}/api/download/filter`, { headers: authHeaders() });
        dispatch(loadDataFilterSuccess(response.data));
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
};


export const fetchCheckoutFilterByFieldAndValue = (field, filter) => async dispatch => {
    try {
        dispatch({ type: types.LOAD_CHECKOUT_FILTER_LOADING, loading: true, key: field });
        const response = await axios.get(`${API_URL}/filter/checkout/${field}?filter=${filter}`, { headers: authHeaders() });
        dispatch({ type: types.LOAD_CHECKOUT_FILTER_SUCCESS, payload: response.data });
        return true;
    } catch (e) {
        console.error(e);
        dispatch({ type: types.LOAD_CHECKOUT_FILTER_ERROR, key: field });
    }
    return false;
}


export const fetchCheckoutHistory = (pageNum) => async dispatch => {
    try {
        dispatch({ type: types.LOAD_CHECKOUT_HISTORY_LOADING, loading: true });
        const response = await axios.get(`${API_URL}/api/download/checkout/history?page=${pageNum}`, { headers: authHeaders() });
        dispatch({ type: types.LOAD_CHECKOUT_HISTORY_SUCCESS, payload: response.data });
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
};

export const fetchCheckoutHistoryWithFilters = (pageNum, filters) => async dispatch => {
    try {
        var filterString = "";
        filters.map(filter => {
            (filter.value !== null && filter.value !== "") && (filterString += `${filter.field}:${filter.value},`)
        })
        dispatch({ type: types.LOAD_CHECKOUT_HISTORY_LOADING, loading: true });
        const response = await axios.get(`${API_URL}/api/download/checkout/history?page=${pageNum}&filter=${filterString}`, { headers: authHeaders() });
        response.data.filters = filters;
        dispatch({ type: types.LOAD_CHECKOUT_HISTORY_SUCCESS, payload: response.data });
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
};



export const fetchCheckoutHistoryDetail = (id) => async dispatch => {
    try {
        dispatch({ type: types.LOAD_CHECKOUT_HISTORY_DETAIL_LOADING, loading: true, id });
        const response = await axios.get(`${API_URL}/api/download/checkout/history/${id}`, { headers: authHeaders() });
        dispatch({ type: types.LOAD_CHECKOUT_HISTORY_DETAIL_SUCCESS, payload: response.data });
        dispatch(fetchCheckoutTdiDetail());
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
}

export const fetchCheckoutTdiDetail = () => async(dispatch,getState) => {
    try {
        dispatch({ type: types.LOAD_CHECKOUT_TDI_LOADING});
        const { firstName, lastName} = getState().checkout.view.customerItems[0];
        const {id} = getState().checkout.view;
        const response =  await axios.post(`${TDM_GATEWAY_API}/tdm/data/checkout/retrieve/data`,{...tdiCheckoutDetailResponse, scenarioName: id}, { headers: authHeaders() });
        const {dob, userName, password} = response.data.hasOwnProperty('tdiDetails') && response.data.tdiDetails[0];
        if(userName) 
            dispatch({type: types.LOAD_CHECKOUT_TDI_SUCCESS, payload: {firstName,lastName,dob,password,emailId: userName, status: 'CREATED'}});
        else
            dispatch({type: types.LOAD_CHECKOUT_TDI_UNAVAILABLE, payload: 'TDI Details  is not available'});

        
    } catch(err){
        console.log(err);
        dispatch({ type: types.LOAD_CHECKOUT_TDI_FAILURE, payload: err});
        
    }
}
export const getSalesForceDetails = (customerId) => async (dispatch, getState) =>{
    try{
        dispatch({type: types.GETTING_SALESFORCE_DETAILS_LOADING, id: customerId});
        const {firstName, lastName} = getState().checkout.view.customerItems[0];
        const response = await axios.post(`${TDM_GATEWAY_API}/tdm/salesforce/search/salesforceObjects`, {...getSalesForceRequestJSON, searchContent: `${firstName} ${lastName}`}, { headers: authHeaders() })
        // const response = await axios.post(`${TDM_GATEWAY_API}/tdm/salesforce/search/salesforceObjects`, {...getSalesForceRequestJSON}, { headers: authHeaders() })
        dispatch({type: types.GETTING_SALESFORCE_DETAILS_SUCCESS, payload: response.data});
    }catch(err){
        dispatch({type: types.GETTING_SALESFORCE_DETAILS_FAILURE, payload: err});
    }
}

export const getSalesForceContactDetails = (accountId) => async (dispatch, getState) =>{
    try{
        dispatch({type: types.GETTING_SALESFORCE_CONTACT_DETAILS_LOADING, id: accountId});
        const response = await axios.post(`${TDM_GATEWAY_API}/tdm/salesforce/contact/create`, {'accountId': accountId}, { headers: authHeaders() })
        dispatch({type: types.GETTING_SALESFORCE_CONTACT_DETAILS_SUCCESS, payload: response.data});
    }catch(err){
        dispatch({type: types.GETTING_SALESFORCE_CONTACT_DETAILS_FAILURE, payload: err});
    }
}
export const collapseSalesForceView = (collapseSfView) => dispatch => {
        dispatch({type: types.COLLAPSE_SALESFORCE_VIEW, collapseSfView});
    }
export const registerTDI = (requestFormData, type) => async (dispatch,getState) => {
    try{
        dispatch({ type: types.TDM_REGITSER_LOADING});
        const response = await axios.post(`${TDM_GATEWAY_API}/tdm/tdi/register`, {...requestFormData}, { headers: authHeaders() });
        dispatch({type: types.TDM_REGISTER_SUCCESS, payload: response.data});
        dispatch(assetCheckInTDI());
        return true;
    }
    catch(e){
        console.log(e.response.data);
        let message = e.response.data.responseDescription;
        dispatch({type: types.TDM_REGISTER_FAILURE, payload: message})
    }
}

export const assetCheckInTDI = () => async (dispatch, getState) => {
    try{
        dispatch({type: types.TDM_ASSET_CHECK_IN_LOADING});
        const {orderItems, id, envFilter, techFilter, tdiItems} = getState().checkout.view;
        const { orderId, plan} = orderItems[0];
        const {emailId, dob, password} = tdiItems[0];
        const checkInRequestDataFormed = {
            ...checkInRequestData,
                "scenarioName": id,
                "environment": envFilter, 
                "order": [{
                ...checkInRequestData.order[0],
                "orderId": orderId, //Mandatory Parameter dynamaic
                "orderPlan": plan, //Telstra Platinum Service Subscription
                "orderTech": techFilter, //Mandatory Parameter 
            }],
            "tdi": [{
                ...checkInRequestData.tdi[0],
                "userName": emailId,
                "password": password,
                "dob": dob
            }]    
        }
        const response = await axios.post(`${TDM_GATEWAY_API}/tdm/checkin/asset`, checkInRequestDataFormed, { headers: authHeaders() });
        dispatch({type: types.TDM_ASSET_CHECK_IN_SUCCESS, payload: response.data});
        dispatch(assetCheckOutTDI());
    }
    catch(err){
        dispatch({type: types.TDM_ASSET_CHECK_IN_FAILURE, payload: err});
    }
}

export const assetCheckOutTDI = () => async (dispatch, getState) => {
    try{
        dispatch({type: types.TDM_ASSET_CHECK_OUT_LOADING});
        const {id, envFilter} = getState().checkout.view;
        const response = await axios.post(`${TDM_GATEWAY_API}/tdm/checkout/asset`, {...checkOutRequestData, scenarioName: id, "environment": envFilter}, { headers: authHeaders() });
        dispatch({type: types.TDM_ASSET_CHECK_OUT_SUCCESS, payload: response.data})
    }
    catch(err){
        dispatch({type: types.TDM_ASSET_CHECK_OUT_FAILURE, payload: err});
    }
}

export const importCustomerSalesforce = (id, profile) => async (dispatch, getState) => {
    try {
        dispatch({ type: types.IMPORT_CUSTOMER_SALESFORCE_LOADING, id });
        const response = await axios.post(`${API_URL}/api/active/customer/salesforce/import`, { "customerId": id, profile }, { headers: authHeaders() });
        dispatch({ type: types.IMPORT_CUSTOMER_SALESFORCE_SUCCESS, payload: response.data });
        return true;
    } catch (e) {
        console.error(e);
    }
    return false;
}

export const sfContactsLoading = (id, loading=true) => ({ type: types.LOAD_CUSTOMER_SALESFORCE_KEY_LOADING, id, key: "sfContactsLoading", loading });
export const sfBaLoading = (id, loading=true) => ({ type: types.LOAD_CUSTOMER_SALESFORCE_KEY_LOADING, id, key: "sfBaLoading", loading });
export const sfOppsLoading = (id, loading=true) => ({ type: types.LOAD_CUSTOMER_SALESFORCE_KEY_LOADING, id, key: "sfOppsLoading", loading });
export const sfSitesLoading = (id, loading=true) => ({ type: types.LOAD_CUSTOMER_SALESFORCE_KEY_LOADING, id, key: "sfSitesLoading", loading });
export const sfBktCreationLoading = (id, loading=true) => ({ type: types.LOAD_CUSTOMER_SALESFORCE_KEY_LOADING, id, key: "sfBktCreation", loading });

export const fetchCustomerSalesforce = (id) => async dispatch => {
    try {
        dispatch(sfContactsLoading(id));
        dispatch(sfBaLoading(id));
        dispatch(sfOppsLoading(id));
        dispatch(sfSitesLoading(id));
        const response = await axios.get(`${API_URL}/api/active/customer/salesforce/${id}`, { headers: authHeaders() })
        dispatch({ type: types.LOAD_CUSTOMER_SALESFORCE_SUCCESS, payload: response.data, update: false });
    } catch (e) {
        dispatch(displayError(e));
    } finally {
        dispatch(sfContactsLoading(id,false));
        dispatch(sfBaLoading(id,false));
        dispatch(sfOppsLoading(id,false));
        dispatch(sfSitesLoading(id,false));
    }
    return false;
}

export const createCustomerContactSalesforce = (id, profile) => async dispatch => {
    try {
        dispatch(sfContactsLoading(id));
        const response = await axios.post(`${API_URL}/api/active/customer/salesforce/create/contact`, { "customerId": id, profile }, { headers: authHeaders() });
        dispatch({ type: types.LOAD_CUSTOMER_SALESFORCE_SUCCESS, payload: response.data, update: true });
        return true;
    } catch (e) {
        console.error(e);
        dispatch(displayError(e));
    } finally {
        dispatch(sfContactsLoading(id, false));
    }
    return false;
}

export const createCustomerBaSalesforce = (id, contactId, profile) => async dispatch => {
    try {
        dispatch(sfBaLoading(id));
        const response = await axios.post(`${API_URL}/api/active/customer/salesforce/create/billingaccount`, { "customerId": id, "contactId": contactId, profile }, { headers: authHeaders() });
        dispatch({ type: types.LOAD_CUSTOMER_SALESFORCE_SUCCESS, payload: response.data, update: true });
        return true;
    } catch (error) {
        dispatch(displayError(error));
    } finally {
        dispatch(sfBaLoading(id, false));
    }
    return false;
}

export const createCustomerOppSalesforce = (id, contactId, profile) => async dispatch => {
    try {
        dispatch(sfOppsLoading(id));
        const response = await axios.post(`${API_URL}/api/active/customer/salesforce/create/opportunity`, { "customerId": id, "contactId": contactId, profile }, { headers: authHeaders() });
        dispatch({ type: types.LOAD_CUSTOMER_SALESFORCE_SUCCESS, payload: response.data, update: true });
        return true;
    } catch (e) {
        dispatch(displayError(e));
    } finally {
        dispatch(sfOppsLoading(id, false));
    }
    return false;
}

export const createCustomerSiteSalesforce = (id, adborId, profile) => async dispatch => {
    try {
        dispatch(sfSitesLoading(id));
        const response = await axios.post(`${API_URL}/api/active/customer/salesforce/create/site`, { "customerId": id, "adborId": adborId, profile }, { headers: authHeaders() });
        dispatch({ type: types.LOAD_CUSTOMER_SALESFORCE_SUCCESS, payload: response.data, update: true });
        return true;
    } catch (e) {
        dispatch(displayError(e));
    } finally {
        dispatch(sfSitesLoading(id, false));
    }
    return false;
}

export const createCustomerBktSalesforce = (id, oppId, profile) => async dispatch => {
    try {
        dispatch(sfBktCreationLoading(id));
        const response = await axios.post(`${API_URL}/api/active/customer/salesforce/create/basket`, { "customerId": id, "opportunityId": oppId, profile }, { headers: authHeaders() });
        dispatch({ type: types.CREATE_SALESFORCE_BASKET_SUCCESS, payload: response.data});
        return true;
    } catch (e) {
        dispatch(displayError(e));
    } finally {
        dispatch(sfBktCreationLoading(id, false));
    }
    return false;
}

export const addProductsToBkt = (id) => async dispatch => {
    try {
        const response = await axios.post(`${API_URL}/api/active/salesforce/basket/products/add`, { "basketId": id }, { headers: authHeaders() });
        return true;
    } catch (e) {
        dispatch(displayError(e));
    }
    return false;
}

export const selectCustomerSalesforce = (customerId) => dispatch => {
    dispatch({ type: types.SELECT_CUSTOMER_SALESFORCE_SUCCESS, customerId });
    if (customerId) dispatch(fetchCustomerSalesforce(customerId));
}

const displayError = (e) => {
    const { error, message } = e.response.data;
    let swalOptions = {
        title: error,
        text: message,
        type: 'error'
    };
    return swal.showAlert(swalOptions);
}