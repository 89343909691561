import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
        cleanUpTypes : ["New Request","My Request"],
        batchID : "",
        validResourcelist : []
        
}


export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case types.MSISDN_CLEAR:
            return {
                ...state,
                cleanUpTypes : action.payload
            }
        case types.SET_CLEAN_UP_BATCH_ID:
            return {
                ...state,
                batchID : action.payload
            }
        case types.CLEAR_CLEAN_UP:
            return {
                ...state,
                batchID : "",
            }
        case types.B2B_CLEAN_UP_SUCCESS:
            return {
              ...state,
              message: action.payload,
              response: action.payload
            }
        case types.B2B_VALID_RESOURCES:
            return {
                ...state,
                validMessage: action.message,
                validResourcelist : action.payload
            }
        case types.B2B_INVALID_RESOURCES:
            return {
                ...state,
                validMessage: action.message,
                validResourcelist : []
            }
            case types.B2B_CLEAN_UP_FAIL:
                return {
                  ...state,
                  message: action.payload,
                  response: action.payload
                }
        default:
            return state;
    }
}
