import React from "react";
import { connect } from "react-redux";
import { FieldArray } from "redux-form";
import TilesFrame from "../../../Data/accordion-frames/replicator/DataTilesFrame";
import * as fnnActions from "../../../../actions/dataCleanUpActions";
import ReduxFormContainer from "../../../common/form/redux/ReduxFormContainer";
import TDMHeader from "../util/TDMHeading";
import RangeInput from "../util/RangeInput";
import * as unmsConstants from "../util/constants";
class UnmsRangeView extends React.Component {

    render() {
        const { selectedFilters, unmsResourceType, unmsResourceTypes,unmsMsisdnResourceTypes, passdownprops, selectResourceType, initialValues } = this.props;
        const { resourceType,unmsRscType } = selectedFilters;
        const asyncBlurFields = ["ranges[].startRange", "ranges[].endRange"];
        const rangeType = unmsRscType === 'MSISDN'?unmsMsisdnResourceTypes:unmsResourceTypes;
        return (
            <div>
                {<TilesFrame
                    heading={"Number Range Type "}
                    color="purple"
                    data={rangeType}
                    select={selectResourceType}
                    selected={resourceType}
                />}
                {
                    resourceType &&
                    <ReduxFormContainer
                        initialValues={unmsConstants.INITIAL_VALUE}
                        formName={unmsConstants.UNMS_INSERT_FORM}
                        onSubmit={() => { }}
                        asyncBlurFields={asyncBlurFields}
                        propsDepth={4}
                        unmsResourceType = {unmsResourceType}
                    >
                        <TDMHeader heading="Ranges" />
                        <FieldArray
                            assignProps
                            name="ranges"
                            passdownprops={passdownprops}
                            primaryButton={"Add"}
                            secondaryButton={"Remove"}
                            errorMessage={this.props.errorMessage}
                            serviceIdField={{}}
                            component={RangeInput}
                        />


                    </ReduxFormContainer>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        unmsResourceTypes: state.cleanup.filters.unmsResourceTypes,
        unmsMsisdnResourceTypes:state.cleanup.filters.unmsMsisdnResourceTypes,
        selectedFilters: state.cleanup.selectedFilters
    };
};
const mapDispatchToProps = dispatch => {
    return {
        selectFilter: (key, value) => dispatch(fnnActions.selectDataCleanUpFilter(key, value)),
        resetDataCleanUpFIlter: () => dispatch(fnnActions.resetDataCleanUpFIlter()),
        resetCurrentStatus: () => dispatch(fnnActions.resetCurrentStatus())
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UnmsRangeView);
