import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SubmissionError } from "redux-form";
import CosmosInputTable from "./CosmosInputTable";
import ReduxFormContainer from "../common/form/redux/ReduxFormContainer";
import _ from "lodash";
import s2pSiteAsyncVal from "./AsyncValidateS2P";
import "./Product.css";
import "./Data.css";

class NewCosmosProductModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      productType: "",
      tableData: {},
      productData: [],
      invalid: {
        status: false,
        fields: []
      }
    };

    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  open = productType => {
    this.setState({ modal: true, productType: productType.toLowerCase() });
  };

  handleSubmit = values => {
    let tiptGroupRows = values.tiptSipcProduct.map((tipt, i) => {
      return { groupRows: tipt.groupRows, index: i }
    });
    for (let i = 0; i < tiptGroupRows.length; i++) {
      if ((tiptGroupRows[i].groupRows).length == 0) {
        const field = "tiptSipcProduct[" + tiptGroupRows[i].index + "].adborId";
        const fieldErr = _.set({}, field, "No Groups Added , Add a group");
        throw new SubmissionError({
          ...fieldErr,
          _error: "Product submission failed!"
        });
      }
      else {
        let tiptServiceRows = tiptGroupRows[i].groupRows.map((services, servIndex) => {
          return { serviceRows: services.serviceRows, serviceIndex: servIndex }
        });
        for (let j = 0; j < tiptServiceRows.length; j++) {
          if ((tiptServiceRows[j].serviceRows).length == 0) {
            const field = "tiptSipcProduct[" + tiptGroupRows[i].index + "].groupRows[" + tiptServiceRows[j].serviceIndex + "].fnn"
            const fieldErr = _.set({}, field, "No Services Added , Add a service");
            throw new SubmissionError({
              ...fieldErr,
              _error: "Product submission failed!"
            });
          }
          else {
            let serviceExRows = tiptServiceRows[j].serviceRows.map((servExt, index) => {
              return { extRows: servExt.serviceExtension, extIndex: index, tiptCount: servExt.tiptCount }
            })
            let total = 0;
            for (let ex = 0; ex < serviceExRows.length; ex++) {
              if ((serviceExRows[ex].extRows).length == 0) {
                const field = "tiptSipcProduct[" + tiptGroupRows[i].index + "].groupRows[" + tiptServiceRows[j].serviceIndex + "].serviceRows[" + serviceExRows[ex].extIndex + "].tiptCount"
                const fieldErr = _.set({}, field, "No Service Extensions Added , Add a service Extensions");
                throw new SubmissionError({
                  ...fieldErr,
                  _error: "Product submission failed!"
                });
              }
              else {
                let extensionRows = serviceExRows[ex].extRows.map((extRows, extRowIndex) => {
                  return { extFrom: extRows.extensionFrom, extTo: extRows.extensionTo, eIndex: extRowIndex }
                })
                for (let er = 0; er < extensionRows.length; er++) {
                  if ((parseInt(extensionRows[er].extTo)) < (parseInt(extensionRows[er].extFrom))) {
                    const field = "tiptSipcProduct[" + tiptGroupRows[i].index + "].groupRows[" + tiptServiceRows[j].serviceIndex + "].serviceRows[" + serviceExRows[ex].extIndex + "].serviceExtension[" + extensionRows[er].eIndex + "].extensionFrom";
                    const fieldErr = _.set({}, field, "Extension From cannot be greater than Extension To");
                    throw new SubmissionError({
                      ...fieldErr,
                      _error: "Product submission failed!"
                    });
                  }
                  }
                }
            }
          }
        }
      }
    }
    values.name = this.state.productType;
    // if (!values.ranges) values.ranges = [{ from: "1", to: "100" }]
    if (!values.ranges) values.ranges = [];
    if (!values.huntGroup) values.huntGroup = [];
    this.props.saveTableView(values);
    this.toggle();
  };

  handleOnClose = (tableData, toggle = true) => {
    this.props.cancelTableView(tableData);
    if (toggle) this.toggle();
  };

  render() {
    const {
      selectedProducts,
      formData,
      productData,
      serviceIdField,
      generateServiceId,
      serviceIDs
    } = this.props;
    const { productType } = this.state;
    const modalLen = selectedProducts.length <= 1 ? 1 : 2;
    const size = 90 * modalLen - (modalLen - 1) * 1 + "vw";
    const selectProductName = selectedProducts[0];

    const formName = "s2pProductModalForm";
    const asyncBlurFields = ["adborFnns[].adborId", "adborFnns[].fnn", "ranges[].from", "ranges[].to", "mobileData[].msisdn", "mobileData[].imsi", "mobileData[].sim"];
    let tData = productData.find(obj => obj.name.toUpperCase() === productType.toUpperCase());
    if (tData) {
      tData.cidn = this.props.cidn;
      tData.type = this.props.type;
    }

    const showSaveButton =
      formData &&
      !formData.asyncValidating &&
      formData.fields &&
      ((formData.fields.adborFnns && formData.fields.adborFnns.length > 0) ||
        (formData.fields.ranges && formData.fields.ranges.length > 0) ||
        (formData.fields.huntGroup && formData.fields.huntGroup.length > 0) ||
        (formData.fields.mobileData && formData.fields.mobileData.length > 0) ||
        ((formData.fields.tiptSipcProduct && formData.fields.tiptSipcProduct.length > 0)))

    const msgForRanges =
      formData &&
      formData.fields &&
      (!(formData.fields.adborFnns && formData.fields.adborFnns.length > 0) &&
        (formData.fields.ranges && formData.fields.ranges.length > 0));

    return (
      <div className="productTable">
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          style={{ maxWidth: size }}
          backdrop={"static"}
          centered
        >
          <ModalHeader>
            <p>Cosmos Product Data</p>
          </ModalHeader>
          <ReduxFormContainer
            // asyncValidate={s2pSiteAsyncVal} // s2pSiteAsyncVal was for sql db, need new for cosmos db
            onSubmit={this.handleSubmit.bind(this)}
            asyncBlurFields={asyncBlurFields}
            initialValues={tData}
            formName={formName}
            propsDepth={4}
          >
            <ModalBody>
              <CosmosInputTable
                assignProps
                formName={formName}
                productType={productType}
                productData={productData}
                serviceIdField={serviceIdField}
                generateServiceId={generateServiceId}
                serviceIDs={serviceIDs}
                msgForRanges={msgForRanges}
                formData={formData}
              />
            </ModalBody>
            <ModalFooter style={{ padding: "0.5rem" }}>
              {showSaveButton && (
                <button
                  color="#4CAF50"
                  className="btn btn-success "
                  id="submitButton"
                  type="submit"
                >
                  Save
                  <i className="fa fa-check-square-o" aria-hidden="true"></i>
                </button>
              )}
              <button
                color="danger"
                type="button"
                className="btn btn-danger"
                id="cancelButton"
                value="cancel"
                onClick={() => {
                  this.props.handleModalClose(productType.toUpperCase());
                  this.toggle();
                }}
              >
                Cancel
              </button>
            </ModalFooter>
          </ReduxFormContainer>
        </Modal>
      </div>
    );
  }
}

export default NewCosmosProductModal;