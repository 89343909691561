import React, { Component } from "react";
import { SubHeader } from "../common/custom/SubHeader";
import { connect } from "react-redux";
import * as fnnActions from "../../actions/dataCleanUpActions";
import { setTitle } from "../../utils/browser";
import TilesFrame from "../Data/accordion-frames/replicator/DataTilesFrame";
import UnmsRanges from "./UnmsRanges.js"
import "../cleanup/b2b/dataCleanUp.css";
import "./UnmsInsert.css";
import { reset} from "redux-form";
import * as constants from "../cleanup/b2b/util/constants";
import * as tdmActions from "../../actions/tdmOpsActions";
import {UNMSValid} from "./UnmsRanges"
import CharmsInsert from './CharmsInsert';
import swal from 'sweetalert2';

import { displayLoading, displaySuccess } from "../common/alerts/alerts";

import Select from '@material-ui/core/Select';
import { MenuItem, TextField, InputLabel, FormControl, Button, Table, Tooltip} from '@material-ui/core';

class UnmsUtensil extends Component {
  state = {
    changeStatus: false,
    unmsTypes:null
  };

  componentDidMount() {
    setTitle("Data clean up");
    this.props.resetDataCleanUpFIlter();
    this.props.setResourceSubtype(null);
    this.props.setFnnResourceSubtype(null);
    this.props.setMsisdnResourcePool(null);
    this.props.setMsisdnPremiumType(null);
    this.props.setMsisdnSelectedAttribute(null);
    this.selectResourceType({label:"NON_CONTIGUOUS_MOBILE_POSTPAID"});
    this.props.getMsisdnResourceAttributes();
    this.props.getFnnResourceAttributes();
    this.props.setFnnNumberType(null);
    this.props.setMsisdnAttributeValue('');
    this.props.setMsisdnAttributes([]);
  }

  selectCleanUpType = obj => {
    this.props.selectFilter("unmsRscType", null);
    this.props.selectFilter("snrmResourceType", null)
    this.props.selectFilter("resourceType", null);
    this.props.selectFilter("snrmStatus", null);
    this.props.selectFilter("view", null);
    this.resetViewFromFrame("cleanUpType")
    this.props.selectFilter("cleanUpType", obj.label)
  }

  selectActionType = obj => {
    this.props.selectFilter("snrmResourceType", null);
    this.props.selectFilter("snrmStatus", null);
    this.props.resetSnrmResources();
    this.resetViewFromFrame("view")
    this.props.selectFilter("view", obj.label);
  };

  selectUnmsResourceType = obj => {
    if (obj.label == "MSISDN" || obj.label == "FNN") {
      this.props.selectFilter("view", null);
      this.resetViewFromFrame("unmsRscType");
      this.props.selectFilter("unmsRscType", obj.label);

      this.selectActionType({label: "Insert"});
    }
  };
  selectUnmsTypeEvent=obj=>{
    this.setState({...this.state,unmsTypes:obj.label})
  }
  selectUpdateType = obj => {
    this.props.selectFilter("update", obj.label);
  };

  selectSnrmUpdateType = obj => {
    this.props.selectFilter("snrmStatus", obj.label);
  }

  selectResourceType = (obj) => {
    this.resetViewFromFrame("resourceType")
    this.props.selectFilter("resourceType", obj.label)
  }

  selectSnrmResourceType = (obj) => {
    this.props.selectFilter("snrmStatus", null);
    this.resetViewFromFrame("snrmResourceType")
    this.props.selectFilter("snrmResourceType", obj.label)
  }

  resetViewFromFrame = (startingFilter) => {
    switch (startingFilter) {
      case "cleanUpType":
        this.selectActionType({ label: null });
      case "unmsRscType":
        this.selectActionType({ label: null });
      case "snrmResourceType":
          this.props.resetForm(constants.SNRM_UPDATE_FORM)
      case "view":
        this.selectUpdateType({ label: null })
        this.selectResourceType({ label: null })
        this.props.resetResources();
        this.props.resetSnrmResources();
      case "resourceType":
        this.props.resetForm(constants.UNMS_INSERT_FORM)
        this.props.setResourceSubtype(null)
        this.props.setFnnResourceSubtype(null)
        this.props.setMsisdnResourcePool(null)
        this.props.setMsisdnPremiumType(null);
        this.props.setFnnNumberType(null);
        this.props.setMsisdnAttributes([])

    }
  }
  isOptionSelected = ({ teamId }) => {
    return teamId == this.props.auth.team;
  }
  teamsOptions = (team) => {
    if (!team) return null;
    return { value: team.teamId, label: team.teamName, isSelected: this.isOptionSelected(team) };
  }

  setAttributesList = () => {
    return (this.props.attributes.map((attr) => attr.attribute));
  }

  generateRequestBody = () => {
    let unmsType = this.props.selectedFilters.unmsRscType.toLowerCase();
    let resourceLifeCycleStatusName;
    if(this.props.selectedFilters.unmsRscType === 'FNN'){
       resourceLifeCycleStatusName=this.props.resourcePool==='UNMS Telstra FNN' ? 'Held Order' : 'Foreign Carrier';
    }else{
      resourceLifeCycleStatusName="Held Order";
    }
    return (this.props.ranges.map(ranges => ({
      "environment":this.props.enviro,
      [unmsType+"StartRange"]:ranges.startRange,
      [unmsType+"EndRange"]:ranges.endRange,
      "resourceTypeName": this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.resourceSubtype : this.props.fnnResourceSubType,
      "numberType": this.props.numberType,
      resourceLifeCycleStatusName,
      "resourcePremiumTypeName":this.props.resourcePremiumType,
      "resourcePoolName":this.props.resourcePool,
      "resourceAttributes": this.props.attributes.map(elem => ({"resourceAttributeName":elem.attribute, "resourceAttributeValue":elem.value}))
    })))
  }

  fieldsValid = () => {
    let resSubType = this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.resourceSubtype : this.props.fnnResourceSubType;
    let resNumType = this.props.selectedFilters.unmsRscType === 'FNN' ? this.props.numberType : true;
    return (
      UNMSValid(this.props.forms) &&
      resSubType &&
      resNumType &&
      this.props.resourcePremiumType &&
      this.props.resourcePool &&
      this.props.attributes &&
      this.props.attributes.length > 0
    )
  }


  getSubmitTooltip = () => {
    let resSubType = this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.resourceSubtype : this.props.fnnResourceSubType;
    let resNumType = this.props.selectedFilters.unmsRscType === 'FNN' ? this.props.numberType : true;
      if (!UNMSValid(this.props.forms)) return "Range(s) are invalid."
      if (!resSubType) return "No resource subtype selected."
      if (!this.props.resourcePremiumType) return "No resource premium type selected"
      if (!resNumType) return "No number type selected"
      if (!this.props.resourcePool) return "no resource pool selected"
      if (!this.props.attributes) return "No attributes selected"
      if (!this.props.attributes.length > 0) return "no attributes selected"
  }
  
  onSubmit = () => {
    let inserted = 0;
    let existing = 0;
    this.generateRequestBody().forEach(request => {
      displayLoading({ title: "UNMS Insertion", bodyText: "Please standby while we submit UNMS Insertion request" })
      if (this.props.selectedFilters.unmsRscType === 'MSISDN') {
        this.props.msisdnInsert(request).then(() => {
          swal.close();
          if (this.props.insertionSuccess) {
            inserted += this.props.insertionResponse.insertedMsisdnRecords;
            existing += this.props.insertionResponse.existingMsisdnRecords;
            displaySuccess({ title: `${this.props.selectedFilters.unmsRscType} Insertion Success`, text: "Inserted "+inserted +" records," + existing + " records that were not inserted already exist in UNMS DB."});
          } else if (this.props.insertionError) {
              swal.fire({
                  type: 'error',
                  title: 'Error',
                  html: this.props.insertionResponse + `\n${this.props.selectedFilters.unmsRscType} Insertion Failed`, text: "Inserted "+inserted +" records," + existing + " records that were not inserted already exist in UNMS DB.",
                  confirmButtonText: 'OK',
              })
          }
        });
      } else if (this.props.selectedFilters.unmsRscType === 'FNN') {
        this.props.fnnInsert(request).then(() => {
          swal.close();
          if (this.props.fnnInsertionSuccess) {
            inserted += this.props.fnnInsertionResponse.insertedFnnRecords;
            existing += this.props.fnnInsertionResponse.existingFnnRecords;
            displaySuccess({ title: `${this.props.selectedFilters.unmsRscType} Insertion Success`, text: "Inserted "+inserted +" records," + existing + " records that were not inserted already exist in UNMS DB."});
          } else if (this.props.isFnnInsertionError) {
              swal.fire({
                  type: 'error',
                  title: 'Error',
                  html: this.props.fnnInsertionResponse + `\n${this.props.selectedFilters.unmsRscType} Insertion Failed`, text: "Inserted "+inserted +" records," + existing + " records that were not inserted already exist in UNMS DB.",
                  confirmButtonText: 'OK',
              })
          }
        });
      }
    })
  }

  render() {
    const { filters, selectedFilters } = this.props;
    let {unmsResourceType} = filters;
    const { view, unmsRscType} = selectedFilters;
    const teams = this.props.listOfTeams.map(teams => this.teamsOptions(teams));
    const activeTeam = teams.filter(option => option.isSelected);

    let resourcePools = this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.resourcePools : this.props.fnnResourcePools;
    let resourcePremiumTypes = this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.resourcePremiumTypes : this.props.fnnResourcePremiumTypes;
    let numberTypes = this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.numberTypes : this.props.fnnNumberTypes;
    let attributeTypes = this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.attributeTypes : this.props.fnnAttributeTypes;
    let resourceSubtype = this.props.selectedFilters.unmsRscType === 'MSISDN' ? this.props.resourceSubtype : this.props.fnnResourceSubType;
    let unmsResourceAllTeams = [];

    if(activeTeam[0].label === "TDM OPS") {
      unmsResourceType.map((unmsResource) =>{
        if(unmsResource.label !== "SIM/IMSI")
        { unmsResourceAllTeams.push(unmsResource);}
      })
    } else {
      unmsResourceType.map((unmsResource) =>{
        if(unmsResource.label !== "FNN")
        { unmsResourceAllTeams.push(unmsResource);}
      })
    }
    const unmsInsertTypes = [{
      label: "Data Insert",
      value: 61,
      length: 9,
      title: "DataInsert",
      icon: "icon-mobile-messaging"
    }, {
      label: "Charms Insert",
      value: 61,
      length: 9,
      title: "CharmInsert",
      icon: "icon-mobile-messaging"
    }]
  return (
      <div className="white">
        <SubHeader
          text={`UNMS Insert `}
          iconClass="td-icon-md icon-managed-phone mainIcon position-static"
          iconColor="magenta"
          loading={false}
          loadingText="Loading..."
          featured
          h1
        />
             <TilesFrame
              heading={"Types"}
              color="green"
              data={unmsInsertTypes}
              select={this.selectUnmsTypeEvent}
              selected={this.state.unmsTypes}
            />
            {this.state.unmsTypes==="Data Insert" ?           
          <React.Fragment>
            <TilesFrame
              heading={"Resource type "}
              color="green"
              data={unmsResourceAllTeams}
              select={this.selectUnmsResourceType}
              selected={unmsRscType}
            />
            {view &&
              <div>
                <UnmsRanges
                  view={view}
                  selectUpdateType={this.selectUpdateType}
                  selectResourceType={this.selectResourceType}
                  unmsResourceType={unmsRscType}
                  editType={"Insert"}
                />
              </div>
            }
            {resourceSubtype &&
              <div>
                <FormControl style={{"width":"100%","paddingTop":"10px"}}>
                  <InputLabel>Resource Pool (Req)</InputLabel>
                  <Select
                    labelId="resource-pool"
                    name="resource-pool"
                    value={this.props.resourcePool || ''}
                    onChange={(e) => {
                      this.props.setMsisdnResourcePool(e.target.value);
                    }}
                  >
                    {resourcePools ? resourcePools.map((item) => {
                      return (<MenuItem value={item}>{item}</MenuItem>)
                    }) : null}
                  </Select>
                </FormControl>
                { this.props.selectedFilters.unmsRscType === 'FNN' &&
                  <FormControl style={{"width":"100%","paddingTop":"10px"}}>
                    <InputLabel>Number Type (Req)</InputLabel>
                    <Select
                      labelId="number-type"
                      name="number-type"
                      value={this.props.numberType || ''}
                      onChange={(e) => {
                        this.props.setFnnNumberType(e.target.value);
                      }}
                    >
                      {numberTypes ? numberTypes.map((item) => {
                        return (<MenuItem value={item}>{item}</MenuItem>)
                      }) : null}
                    </Select>
                  </FormControl>
                }
              <FormControl style={{"width":"100%","paddingTop":"10px"}}>
                <InputLabel>Premium Type (Req)</InputLabel>
                <Select
                  labelId="premium-type"
                  name="premium-type"
                  value={this.props.resourcePremiumType || ''}
                  onChange={(e) => {
                    this.props.setMsisdnPremiumType(e.target.value);
                  }}
                >
                  {resourcePremiumTypes ? resourcePremiumTypes.map((item) => {
                    return (<MenuItem value={item}>{item}</MenuItem>)
                  }) : null}
                </Select>
              </FormControl>
              <div style={{"width":"100%", "paddingTop":"15px"}}>
                <div style={{"width":"95%", "float":"left"}}>
                  <FormControl style={{"width":"50%", "float":"left"}}>
                    <InputLabel>Attributes (>1 req)</InputLabel>
                    <Select
                      labelId="attributes"
                      name="attributes"
                      value={this.props.selectedAttribute || ''}
                      onChange={(e) => {
                        this.props.setMsisdnSelectedAttribute(e.target.value);
                        this.props.setMsisdnAttributeValue('');
                      }}
                    >
                      {attributeTypes ? attributeTypes.filter((e) => !this.setAttributesList().includes(e)).map((item) => {
                        return (<MenuItem value={item}>{item}</MenuItem>)
                      }) : null}
                    </Select>
                  </FormControl>
                    <form style={{"width":"40%","height":"100%", "float":"left", "paddingTop":"10px", "paddingLeft":"2%", "paddingRight":"2%"}} noValidate autocomplete="off">
                      <TextField
                        style={{"width":"100%", "height":"100%"}}
                        id="attribute"
                        label="Value"
                        variant="outlined"
                        disabled={this.props.selectedAttribute ? false : true}
                        value={this.props.attributeValue || ''}
                        onChange={(e) => {
                          this.props.setMsisdnAttributeValue(e.target.value)
                        }}
                      />
                    </form>
                </div>
                <div style={{
                    "width":"5%",
                    "float":"right",
                    "paddingTop":"20px"
                    }}>
                  <Tooltip title={!this.props.attributeValue ? "Select an attribute and add a value" : ""}>
                    <span>
                    <Button
                      disabled= {this.props.selectedFilters.unmsRscType === 'FNN' ? !this.props.selectedAttribute : !this.props.attributeValue}
                      onClick={() => {
                        this.props.setMsisdnAttributes(this.props.attributes.concat([{attribute:this.props.selectedAttribute, value:this.props.attributeValue}]));
                        this.props.setMsisdnAttributeValue('');
                        this.props.setMsisdnSelectedAttribute('');
                    }} className="plus-btn">+</Button>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            }
                <br/>
        <br/>
        {this.props.attributes && this.props.attributes.length > 0 &&
          <div style={{"maxWidth":"75%","margin":"0 auto", "paddingTop":"10px"}}>
            <Table hover response>
              <thead>
                <tr>
                  <th style={{"padding":"5px"}}>Attribute</th>
                  <th style={{"padding":"5px"}}>Value</th>
                </tr>
              </thead>
              <tbody>
              {
                this.props.attributes.map(e => {
                  return (
                      <tr>
                        <td style={{"padding":"5px"}}>{e.attribute}</td>
                        <td style={{"padding":"5px"}}>{e.value}</td>
                        <td style={{"padding":"5px"}}>
                          <Button className="remove-btn" onClick={() => this.props.setMsisdnAttributes(this.props.attributes.filter(attr => attr.attribute != e.attribute))}>-</Button>
                        </td>
                      </tr>
                  )
                })
              }
              </tbody>
            </Table>
          </div>
        }

        { resourceSubtype &&
          <div style={{"paddingTop":"15px"}}>
            <Tooltip title={this.getSubmitTooltip()}>
            <span>
            <Button style={{"background-color":"green", "color":"white"}} type="submit" variant="contained" disabled={!this.fieldsValid()} onClick={() => this.onSubmit()}>Submit</Button>
            </span>
            </Tooltip>
          </div>
        }
          </React.Fragment>
            : this.state.unmsTypes==="Charms Insert" ? <CharmsInsert/> :null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.cleanup.filters,
    selectedFilters: state.cleanup.selectedFilters,
    listOfTeams : state.auth.myAvailableTeams,
    auth: state.auth,
    response: state.tdmOps,
    resourceSubtype: state.tdmOps.msisdnResourceSubType,
    ranges:(state.form.unmsInsert && state.form.unmsInsert.values) ? state.form.unmsInsert.values.ranges : [],

    resourcePools: state.tdmOps.msisdnResponse.ResourcePool,
    resourcePool:  state.tdmOps.msisdnResourcePool,

    resourcePremiumTypes: state.tdmOps.msisdnResponse.ResourcePremiumType,
    resourcePremiumType:  state.tdmOps.msisdnPremiumType,

    numberTypes: state.tdmOps.fnnResponse.NumberType,
    numberType: state.tdmOps.fnnNumberType,

    attributeTypes: state.tdmOps.msisdnResponse.ResourceAttribute,
    attributes: state.tdmOps.msisdnAttributes,
    selectedAttribute: state.tdmOps.msisdnSelectedAttribute,

    attributeValue: state.tdmOps.msisdnAttributeValue,

    insertionError: state.tdmOps.isMsisdnInsertionError,
    insertionSuccess: state.tdmOps.isMsisdnInsertionSuccess,
    insertionResponse: state.tdmOps.msisdnInsertionResponse,
    forms: state.form,

    enviro: state.auth.selectedEnvValue,

    isFnnInsertionError: state.tdmOps.isFnnInsertionError,
    fnnInsertionSuccess: state.tdmOps.isFnnInsertionSuccess,
    fnnInsertionResponse: state.tdmOps.fnnInsertionResponse,

    fnnResourceSubType: state.tdmOps.fnnResourceSubType,
    fnnResourcePools: state.tdmOps.fnnResponse.ResourcePool,
    fnnResourcePremiumTypes: state.tdmOps.fnnResponse.ResourcePremiumType,
    fnnAttributeTypes: state.tdmOps.fnnResponse.ResourceAttribute,
    fnnNumberTypes:state.tdmOps.fnnResponse.NumberType,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectFilter: (key, value) => dispatch(fnnActions.selectDataCleanUpFilter(key, value)),
    resetDataCleanUpFIlter: () => dispatch(fnnActions.resetDataCleanUpFIlter()),
    resetForm: (formName) => dispatch(reset(formName)),
    resetResources: () => dispatch(fnnActions.resetResources()),
    resetSnrmResources: () => dispatch(fnnActions.resetSnrmResources()),
    setResourceSubtype: (subtype) => dispatch(tdmActions.setMsisdnResourceSubtype(subtype)),
    setMsisdnResourcePool: (subtype) => dispatch(tdmActions.setMsisdnResourcePool(subtype)),
    setMsisdnPremiumType: (subtype) => dispatch(tdmActions.setMsisdnPremiumType(subtype)),
    setMsisdnSelectedAttribute: (attribute) => dispatch(tdmActions.setMsisdnSelectedAttribute(attribute)),

    getMsisdnResourceAttributes: () => dispatch(tdmActions.fetchMsisdnFormData()),
    setMsisdnAttributeValue: (value) => dispatch(tdmActions.setMsisdnAttributeValue(value)),
    setMsisdnAttributes: (attr) => dispatch(tdmActions.setMsisdnAttributes(attr)),

    msisdnInsert: (request) => dispatch(tdmActions.msisdnInsert(request)),

    fnnInsert: (request) => dispatch(tdmActions.fnnInsert(request)),
    getFnnResourceAttributes: () => dispatch(tdmActions.fetchFnnFormData()),
    setFnnResourceSubtype: (subtype) => dispatch(tdmActions.setFnnResourceSubtype(subtype)),
    setFnnNumberType: (subtype) => dispatch(tdmActions.setFnnNumberType(subtype)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnmsUtensil);
