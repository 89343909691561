import React from "react";
import { Button } from "reactstrap";
import AddressModal from "./AddressModal";

export default class DisplayModal extends React.Component {
  render() {
    const {
      billingAccountNumber,
      address,
      locality,
      postcode,
      country,
      state,
      billingAccount
    } = this.props;
    return (<div className="row address-display" id="address-row">
      <div className="col-sm-12 address-column ">
        <div className="col-sm-12 address-column">
          <div className="new-line top-line">
            <h4>
              <strong>
                {billingAccountNumber}
              </strong>
            </h4>
          </div>
          <div className="new-line">
            <h4> {address && address + ","}</h4>{" "}
          </div>
          <div className="new-line">
            {" "}
            <h4> {locality && locality + ", " + postcode}</h4>{" "}
          </div>
          <div className="new-line">
            <h4> {(state || country) && state + ", " + country} </h4>{" "}
          </div>
          <div className="new-line bottoon-line">
            <Button
              className="switch-address-button"
              color="link"
              onClick={() =>
                this.addressModal && this.addressModal.open(billingAccount)}
            >
              <h5>Switch to a different address</h5>
            </Button>
          </div>
        </div>
      </div>
      <AddressModal onRef={(ref) => { this.addressModal = ref }}
      />
    </div>)
  }
}
