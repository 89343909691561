import React, { useState } from 'react';

import {
    TextField,
    InputAdornment,
    FormControl,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '36px',
        backgroundColor: '#ffffff',
    },
    searchField: {
        transition: theme.transitions.create(''),
        width: '20ch',
    },
    rounded: {
        height: '36px',
        backgroundColor: '#ffffff',
        borderRadius: "0.65rem"
    }
}))

const TableSearch = (props) => {
    const {
        filter,
        toggle,
        outlined,
        autoFocus = true,
        placeholder,
    } = props;
    const classes = useStyles();
    const [value, setValue] = useState("");

    const handleChange = (event) => {
        setValue(event.target.value);
        filter(event.target.value);
    };

    const clearValue = () => {
        setValue('');
        toggle();
    }

    return (
        <>
            <FormControl>
                <TextField
                    id="outlined-adornment-weight"
                    value={value}
                    variant={outlined ? "outlined" : "standard"}
                    onChange={(e)=>handleChange(e)}
                    placeholder={placeholder || "Search..."}
                    size="small"
                    autoComplete="off"
                    autoFocus={autoFocus}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position='start'>
                                <SearchOutlinedIcon color="action"/>
                        </InputAdornment>
                        ),
                        endAdornment: (
                        <InputAdornment position='end'>
                            <IconButton size="small" onClick={clearValue}>
                                <CloseOutlinedIcon color="action"/>
                            </IconButton>
                        </InputAdornment>
                        ),
                        classes: {input: classes.searchField, root: outlined ? classes.rounded : classes.root}
                    }}
                >
                    {value}
                </TextField>
            </FormControl>
        </>
        
    )
}

export default TableSearch;