import React, {useState} from 'react';
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "reactstrap";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch
} from "@material-ui/core";
import swal from 'sweetalert2';
import _ from "lodash";
import { connect } from 'react-redux';
import SlidingPanel from "react-sliding-side-panel";
import './SidePanel.css';
import TeamSelector from './TeamSelector';
import ProfileSelector from './ProfileSelector';
import { setSideBarPanel, setSidePanelEnvirnoment } from '../../../../actions/authActions';
import { withStyles } from '@material-ui/core/styles';
import {faSalesforce} from '@fortawesome/free-brands-svg-icons';
import { onChangeEnvironment } from '../../../../actions/profileActions';

const environmentNameChanges=(environment)=>{
  if(environment==="sqni"){
    return "SQNI"
  }else if(environment==="osit"){
    return "OSIT3/Pre-Prod"
  }else if(environment==="int2"){
    return "INT2/SIT"
  }else if(environment==="int1"){
    return "INT1/QA"
  }
}

function SidePanel(props) {
    const {openSidePanel, sidePanelEnvironment} = props;
    const handleChange = event => {
      let environmentName=event.target.name;
      let environmentChecked=event.target.checked;
      let getUrl=window.location.href;
      const lastItem = getUrl.substring(getUrl.lastIndexOf('/') + 1)
      props.setSideBarPanel(false);
      swal({
        title: 'Environment Change Confirmation',
        html: `<b>Do you wish to change environment as </b>${environmentNameChanges(event.target.name)}?<br />`,
        type: 'info',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> No, I wish to be here',
        confirmButtonColor: '#13a129',
        confirmButtonText: '<i class="fa fa-check" aria-hidden="true"></i> Yes',
        reverseButtons: true,
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          props.setSidePanelEnvirnoment(environmentName, environmentChecked);
        }
    })
      //Change Salesforce profiles 
      //props.onChangeEnvironment(event.target.name);
    };

    const onCloseSidePanel = () => {
      props.setSideBarPanel(false);
    };

    const profilesStyles = {
      valueContainer: (provided) => ({
        ...provided,
        display: "block",
        width: "180px",
        paddingLeft: 30,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: ".8rem",
      })
    };

    return (
        <SlidingPanel
        panelContainerClassName="panel-container"
        type={"right"}
        isOpen={openSidePanel}
        size={20}
        backdropClicked={onCloseSidePanel} 
      >
        <div className="right-bar">
          <div className="rightbar-title">
            <h5 className="m-0">User Settings</h5>
            <span
              style={{ cursor: "pointer" }}
              onClick={onCloseSidePanel} 
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ height: "auto", marginRight: 25 }}
                color={"#4C6EF5"}
              />
              {/* <i className="fa fa-2x fa-times-circle-o mr-3" aria-hidden="true"></i> */}
            </span>
          </div>
          <div className="mt-1 p-1">
          <div className="item-align-right">
            <Alert color="primary">Customize your session here</Alert>
            </div>
          </div>
          <h5 className="pl-2 pt-5 font-size-15 border-top-sp">Environment</h5>
          <div className="pl-5 pt-0">
          <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <AntSwitch
                      checked={sidePanelEnvironment?.int1}
                      onChange={handleChange}
                      name="int1"
                      color="primary"
                    />
                  }
                  label="INT1"
                />
                <FormControlLabel
                  control={
                    <AntSwitch
                      checked={sidePanelEnvironment?.int2}
                      onChange={handleChange}
                      name="int2"
                      color="primary"
                    />
                  }
                  label="INT2"
                />
                <FormControlLabel
                  control={
                    <AntSwitch
                      checked={sidePanelEnvironment?.osit}
                      onChange={handleChange}
                      name="osit"
                      color="primary"
                    />
                  }
                  label="Pre-Prod"
                />
                <FormControlLabel
                  control={
                    <AntSwitch
                      checked={sidePanelEnvironment?.sqni}
                      onChange={handleChange}
                      name="sqni"
                      color="primary"
                    />
                  }
                  label="SQNI"
                />
              </FormGroup>
            </FormControl>
          </div>
          <h5 className="pl-2 pt-5 font-size-15 border-top-sp">Team</h5>
          <div className="pl-1 item-align-right"> <TeamSelector /></div>
          <h5 className="pl-2 pt-5 font-size-15 border-top-sp">
            <span>
              <span>
              <FontAwesomeIcon icon={faSalesforce} style={{'height':'auto', 'width': '70'}} color={'#4C6EF5'} />
              </span>
              <span className="element-relative-position">
                Profile
              </span>
            </span>
          </h5>
          <div className="pl-1 item-align-right"> <ProfileSelector/> </div>
        </div>
      </SlidingPanel>
    );
}

const mapStateToProps = (state) => {
  return {
      openSidePanel: state.auth.openSidePanel,
      sidePanelEnvironment: state.auth.sidePanelEnvironment
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      setSideBarPanel: (value) => dispatch(setSideBarPanel(value)),
      setSidePanelEnvirnoment: (key, value) => dispatch(setSidePanelEnvirnoment(key, value)),
      onChangeEnvironment: (env) => dispatch(onChangeEnvironment(env))
  }
}

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
      marginRight: 10,
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 93,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 93,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);