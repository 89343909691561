import React from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import moment from 'moment';

const chunkArray = (arr, n) => {
  let chunkLength = Math.max(arr.length / n, 1);
  let chunks = [];
  for (var i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }
  return chunks;
}

const ExpandInfoTable = (props) => {
  const { columns, row, hideEmptyExpandOptions } = props;

  const removed_undefined = _.compact(hideEmptyExpandOptions ? columns.filter(v => row[v.field]) : columns.map((column, index) => {
    if (column.expandable) {
      return column;
    }
  }));

  let newChunkArray = chunkArray(removed_undefined.map((column) => {
    let fieldValue;
    let fieldName = column.title;
    if (fieldName === "CreatedDate" || fieldName === "DOB" || fieldName === "Activated Date") {
      fieldValue = moment(row[column.field]).format('MM/DD/YYYY');
    } else {
      fieldValue = row[column.field];
    }
    return {
      fieldValue,
      fieldName,
    }
  }), 2);

  const NestedTableCom = ({ getData }) => {
    if (getData) {
      return <>
        {getData && getData.map((_item) =>
          <>
            <div className="nestedTableData" style={{width: "650px"}}>
              <div>
                <div className="nestedDataHeading" style={{ float: "left", width: "385px" }}><b>{_item.fieldName}: </b> {_item.fieldValue ? _item.fieldValue : <br/>}</div><br/>
              </div>
            </div>
            <hr className={"salesHorizontal"} style={{height: "1px",borderWidth: 0, backgroundColor: "#c2c0c0"}}></hr>
          </>
        )}
      </>
    } return null;
  }

  return <Grid container rowSpacing={0} style={{ paddingLeft: "118px" }}>
    <Grid item xs={6} style={{ maxWidth: "475px", marginRight: "20px" }}>
      <NestedTableCom getData={newChunkArray[1]} />
    </Grid>
    <Grid item xs={6}>
      <NestedTableCom getData={newChunkArray[0]} />
    </Grid>
  </Grid>
};

ExpandInfoTable.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  row: PropTypes.object.isRequired,
  hideEmptyExpandOptions: PropTypes.bool,
};

export default ExpandInfoTable;