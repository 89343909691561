import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { FieldArray } from "redux-form";
import RangeComponent from "./RangeComponent";
import HuntGroupComponent from "./HuntGroupComponent";
import "./Product.css";
import "./Data.css";

export default class DataCollapse extends React.Component {
  render() {
    const {
      passdownprops,
      productType,
      errorMessage,
      options,
      formData,
      cosmos
    } = this.props;
    const isAssociationOpen = formData && formData.values.adborFnns.length > 1;
    return (
      <Accordion defaultActiveKey="0">
        { productType !== 'pstn' && 
          (
            <Card style={{ overflow: "visible", marginBottom:"0px"}}>
              <Accordion.Toggle
                className="product-header-box"
                as={Card.Header}
                eventKey="1"
              >
                Fnn Range{" "}
                <span className={"pm-waringMessage"}>
              
                </span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1" style={{ overflow: "visible" }}>
                <Card.Body > 
                  <FieldArray
                    name="ranges"
                    passdownprops={passdownprops}
                    productType={productType}
                    errorMessage={errorMessage}
                    component={RangeComponent}
                    options={options}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        }
        {
          isAssociationOpen && 
        <Card style={{ overflow: "visible", marginTop:"0px" }}>
          <Accordion.Toggle
            className="product-header-box"
            as={Card.Header}
            eventKey="2"
          >
           Service Association
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="2" style={{ overflow: "visible" }}>
            <Card.Body>
              <FieldArray
                name="huntGroup"
                passdownprops={passdownprops}
                productType={productType}
                errorMessage={errorMessage}
                component={HuntGroupComponent}
                options={options}
                props={{
                  cosmos: cosmos
                }}
              />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        }
      </Accordion>
    );
  }
}
