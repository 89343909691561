import * as types from '../actions/sfCustomerActionTypes';

const INITIAL_STATE = {
    view: {

    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.IMPORT_LOADING:
            return {
                ...state,
                view: {
                    ...state.view,
                    customerDetails: [
                        ...(state.view.customerDetails ?
                            state.view.customerDetails.map(customer => (customer.uniqueId == action.id) ?
                                { ...customer, sfImporting: true } : customer) : [])
                    ]
                }
            }
        case types.IMPORT_SUCCESS:
            return {
                ...state,
                view: {
                    ...state.view,
                    activeSfCustomer: action.payload.customerId,
                    customerDetails: [
                        ...(state.view.customerDetails ?
                            state.view.customerDetails.map(customer => (customer.customerId == action.payload.customerId) ?
                                action.payload : customer) : [])
                    ]
                }
            }
            case types.CONTACT_SYNC_STATUS:
                const { contactId, cidn, syncStatus, syncStatusFlag } = action;
                return {
                    ...state,
                    view: {
                        ...state.view,
                        customerDetails: [
                            ...(state.view.customerDetails ?
                                state.view.customerDetails.map(customer => (customer.cidn == cidn) ?
                                    { ...customer,
                                        sfContacts: [
                                            ...customer.sfContacts ?
                                            (customer.sfContacts.map(contact => (contact.id === contactId) ?
                                            {
                                                ...contact,  syncStatus : syncStatus, syncStatusFlag : syncStatusFlag
                                            } :contact )):[]
                                        ]
                                    } : customer) : [])
                        ]
                    }
                }
        case types.IMPORT_FAIL:
            return {
                ...state,
                view: {
                    ...state.view,
                    customerDetails: [
                        ...(state.view.customerDetails ?
                            state.view.customerDetails.map(customer => (customer.uniqueId == action.id) ?
                                { ...customer, sfImporting: false } : customer) : [])
                    ]
                }
            }
        case types.FETCH_SF_SUCCESS:
            const { update, payload, customerId } = action;
            return {
                ...state,
                view: {
                    ...state.view,
                    customerDetails: [
                        ...(state.view.customerDetails ?
                            state.view.customerDetails.map(customer => (customer.uniqueId === customerId)
                                ? {
                                    ...customer,
                                    salesforceUrl: payload.salesforceUrl ? payload.salesforceUrl : customer.salesforceUrl,
                                    sfContacts: [
                                        ...payload.contacts,
                                        ...(update && customer.sfContacts ? customer.sfContacts : [])
                                    ],
                                    sfBillingAccounts: [
                                        ...payload.billingAccounts,
                                        ...(update && customer.sfBillingAccounts ? customer.sfBillingAccounts : [])
                                    ],
                                    sfSites: [
                                        ...payload.sites,
                                        ...(update && customer.sfSites ? customer.sfSites : [])
                                    ],
                                    sfOpps: [
                                        ...payload.opportunities,
                                        ...(update && customer.sfOpps ? customer.sfOpps : [])
                                    ]
                                }
                                : customer) : [])
                    ]
                }
            }
        case types.SELECT_CUSTOMER_SALESFORCE:
            const { custId } = action;
            return {
                ...state,
                view: {
                    ...state.view,
                    activeSfCustomer: custId
                }
            }
        case types.FETCH_SF_LOADING:
            const { key, loading } = action;
            return {
                ...state,
                view: {
                    ...state.view,
                    customerDetails: [
                        ...(state.view.customerDetails ?
                            state.view.customerDetails.map(customer => (customer.uniqueId == action.id)
                                ? { ...customer, [key]: loading } : customer) : [])
                    ]
                }
            }

        case types.ADD_CUSTOMER_SF_VIEW:
            return {
                ...state,
                view: {
                    ...state.view,
                    activeSfCustomer: null,
                    customerDetails: action.payload
                }
            }
        default:
            return state;
    }
}