import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import './batchStatus.css';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
        borderBottom: 'unset',
        },
    },
});
  
  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton style={{"outline":"none"}} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.batchId}
          </TableCell>
          <TableCell align="center">{row.status}</TableCell>
          <TableCell align="center">{row.type}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
                <Table size="small" aria-label="details">
                  <TableHead>
                    <TableRow>
                      <TableCell>Application Name</TableCell>
                      <TableCell>Preseeding Status</TableCell>
                      <TableCell>Error Log</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.details ? row.details.map((detailsRow, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">{detailsRow.applicationName}</TableCell>
                        <TableCell>{detailsRow.preseedingStatus}</TableCell>
                        <TableCell>{detailsRow.errorLog !== "[]" ? detailsRow.errorLog : ""}</TableCell>
                      </TableRow>
                    )) : <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell> 
                        </TableRow>}
                  </TableBody>
                </Table>
              </Box>
                <Box margin={1}>
                    <Typography variant="h6" gutterBottom component="div">
                        Resource Details
                    </Typography>
                    <Table size="small" aria-label="details">
                        <TableHead>
                            <TableRow>
                                <TableCell>IMSI/MSISDN</TableCell>
                                <TableCell>Sim</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Error Code</TableCell>
                                <TableCell>Error Log</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {row.resourceDetails ? row.resourceDetails.map((resourceDetailsRow, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">{resourceDetailsRow.value}</TableCell>
                                    <TableCell>{resourceDetailsRow.sim}</TableCell>
                                    <TableCell>{resourceDetailsRow.status}</TableCell>
                                    <TableCell>{resourceDetailsRow.errorCode}</TableCell>
                                    <TableCell>{resourceDetailsRow.errorMessage}</TableCell>
                                </TableRow>
                            )) : <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  
  Row.propTypes = {
    row: PropTypes.shape({
      status: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      details: PropTypes.arrayOf(
        PropTypes.shape({
          ApplicationName: PropTypes.string.isRequired,
          PreseedingStatus: PropTypes.string.isRequired,
          ErrorLog: PropTypes.string.isRequired,
        }),
      ).isRequired,
      batchId: PropTypes.string.isRequired,
    }).isRequired,
  };

const BatchResultTable = (props) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>Batch ID</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Type</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                      props.status && props.status.constructor === Array && props.status.length > 0 ? props.status.map((row) => (
                        <Row key={row.batchId} row={row} />
                      )) : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
    ); 
}

export default BatchResultTable;