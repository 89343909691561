import { useState } from "react";
import DataTilesFrame from "../Data/accordion-frames/DataTilesFrame";
import { connect } from "react-redux";
import HeaderContent from '../common/Header/HeaderContent';
import { upperFirst } from "lodash";
import { TextField, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import swal from 'sweetalert2'
import { smCustomer } from '../Data/dataviews//DataBankCheckout/action';
import SubmitButton from '@library/core/buttons/SubmitButton';
import { displayLoading } from "../common/alerts/alerts";
import InfoIcon from '@material-ui/icons/Info';
import TimMigrationFreeText from './TimMigrationFreeText';

const SmEligibility = (props) => {
    let env = props.auth.selectedEnvValue;
    const [sourceType, setSourceType] = useState(null);
    const [smData, setSmData] = useState({
        customerId: { label: 'Customer Id', name: 'customerId', type: 'text' }, 
        ban: { label: 'Billing Account Number', name: 'ban', type: 'text', minWidth: '211px' }, 
        cidn: { label: 'CIDN', name: 'cidn', type: 'text' }, 
        msisdn: { label: 'MSISDN', name: 'msisdn', type: 'text' },
        eligibilityOutcome: { 
            label: 'Eligibility Outcome', 
            name: 'eligibilityOutcome', 
            type: 'dropdown', 
            options: [{value:'ELIGIBLE', reasonCode: {label: '', value: null}},{value:'INELIGIBLE', reasonCode: {label: '1001', value: ['001001']}}] 
        },
        reasonCodes: { label: 'Reason Codes', name: 'reasonCodes', type: 'displayOnly' },
    });

    const showReasonCode = smData.reasonCodes?.value?.value;

    const submitSM = () => {
        displayLoading({ title: "Loading", bodyText: "Please wait as we search your data" })

        if (env != 'INT1') {
            let req = {};
            Object.values(smData).map((data) => {
                if (data.value?.value || data.value && data.name != 'reasonCodes') {
                    req[data.name] = data.value?.value || data.value;
                }
            });
            req.sourceSystem = sourceType.label;
            req.env = env;
            req.billingSystem = sourceType.name === "MICA" ? "FLEXCAB" : "KENAN";
            req.isSMEligibleUtility=true;
            return smCustomer(req).then((result) => {
                swal("success", result.data.responseDescription, "success");
            }).catch((e) => {
                let errorMsg = "";
                if (typeof e === "string") errorMsg = e;
                else if (e.response) {
                    errorMsg = e.response.data.responseDescription || e.response.data.message;
                } else {
                    errorMsg = "An error occurred. Please try again after some time.";
                }
                swal('Error',errorMsg, "error");
            })
        } return swal("Wrong environment", "Expected environment is INT2 and OSIT3", "error");

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        let data = {...smData};
        if (name === "eligibilityOutcome") {
            data[name].value = value.value;
            data.reasonCodes.value = value.reasonCode;
        } else {
            data[name].value = value;
        }

        return setSmData(data);
    }

    const renderInput = ({name, value, type, options}) => {
        return (
        <TextField
            id={name}
            name={name}
            select={type === 'dropdown'}
            value={name === 'reasonCodes' ? value?.label : value}
            onChange={handleInputChange}
            error={true}
            variant="outlined"
            className="tableTextFieldError"
            style={{width: "100%"}}
            InputProps={{
                readOnly: type === 'displayOnly',
            }}
            helperText={type === 'text' && value && isNaN(value) && 'Please enter number only'}
            children={options?.map(opt => <MenuItem key={opt.value} value={opt}>{opt.value}</MenuItem>)}
        />)
    }

    return (
        <div className="white pb-20">
            <HeaderContent
                title={`SM Eligibility`}
                icon="cogs ops-icon"
                empty={true}
                loadingText="Loading SmEligibility..."
                redirectUtility={true}
                push={props?.history?.push}></HeaderContent>
            <DataTilesFrame
                heading="Source System"
                color="turquoise"
                data={[{ label: "MICA", icon: 'icon-sim-card', name: 'MICA' }, { label: "RCRM", icon: 'icon-sim-card', name: 'RCRM' }, { label: "TIM migration service", icon: 'icon-sim-card', name: 'TIM migration service' } ]}
                select={(data) => setSourceType(data)}
                selected={sourceType ? sourceType.label : null}
            />
            <div style={{overflow: "auto"}}>
                {sourceType && sourceType.label === "TIM migration service" ? <TimMigrationFreeText/> : null}
                {sourceType && sourceType.label !== "TIM migration service"  ? <Table hover responsive id="ddcTable" className="pt-5" style={{overflow: "scroll"}}>
                    <TableHead>
                        <TableRow>
                            {Object.values(smData)?.map((val, index) => {
                                if (val.name === "reasonCodes" && !showReasonCode) {
                                    return;
                                }
                                return (
                                    <TableCell key={val.name} className="px-6 pb-0" style={{minWidth: val?.minWidth || '180px'}}>
                                        {upperFirst(val.label)}
                                        {val.name === "reasonCodes" && 
                                            <Tooltip
                                                arrow
                                                title={<p style={{fontSize: 15}}>The Customer Type on the Customer Account record is out of scope making it ineligible for migration.  It is recommended that you offer a Siebel plan to the customer and encourage take up of direct debit</p>}
                                                placement="right"
                                            >
                                                <InfoIcon color="primary" fontSize="small" style={{marginLeft: "4px", marginBottom: "8px", fontSize: '1rem'}}/>
                                            </Tooltip>
                                        }
                                    </TableCell>
                                );
                            }
                            )}
                            <TableCell className="pl-6 pb-0">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(smData)?.map((val, index) =>{
                            if (val.name === "reasonCodes" && !showReasonCode) {
                                return;
                            }
                            return <TableCell key={val.name}>
                                {
                                    renderInput(val)
                                }
                            </TableCell>
                        })}
                        <TableCell>
                            <SubmitButton 
                                className="eligibilityBtn" 
                                onClick={submitSM} 
                                disabled={!Object.values(smData).every(
                                    (data) => data.value && data.value != '' && 
                                    (data.type === 'text' ? 
                                    !isNaN(data.value) : 
                                    true)
                                    )
                                } 
                            />
                        </TableCell>
                    </TableBody>
                </Table> : null}
            </div>
        </div>)

    
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // selectFilter: (key, value) => dispatch(fnnActions.selectDataCleanUpFilter(key, value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmEligibility);
