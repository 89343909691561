/* eslint-disable no-use-before-define */
import React, {useEffect, useState, Fragment} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { makeStyles, createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createMuiTheme({
  overrides:{
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"] $input':{
          padding: 0,
          paddingLeft: 5,
          border: 'none !important',
          background: 'inherit'
        }
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: '2rem',
    '& > *': {
      margin: theme.spacing(1),
      border: 0
    },
  },
  textField: {
    zIndex: 2
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
export default function Highlights({user,setTeamId, value,setValue, listOfTeams, isUserToggle}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{   
    listOfTeams.length>1? setLoading(false):setLoading(true);
  },[isUserToggle, listOfTeams])

  useEffect(()=>{
    return () => {
      setValue(listOfTeams[0]);
   };
  },[isUserToggle]);
  
  return (
    <MuiThemeProvider theme={theme}>
    <Autocomplete
      className={classes.root}
      id="highlights-demo"
      style={{ width: 300 }}
      options={loading? []:listOfTeams}
      value={value}
      loading={loading}
      getOptionLabel={(option) => option.teamDisplayName ? option.teamDisplayName : option.teamName ? option.teamName : ''}
      onChange={(event, newValue) => {
        if(newValue){
          setTeamId(newValue.teamId || newValue.id)
          setValue(newValue);
        } 
      }}
      renderInput={(params) => {
        return (
          <TextField {...params} className={classes.textField} label={"Team"}  variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
          />)
      }}
      renderOption={(option, { inputValue }) => {
        const displayName = (option.teamDisplayName && option.teamName) === null ? "" : option.teamDisplayName || option.teamName;
        const matches = match(displayName, inputValue);
        const parts = parse(displayName, matches);
        return (
          <div>
            {parts && parts.map((part, index) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
    </MuiThemeProvider>
  );
}
