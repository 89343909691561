export const markSelectedState = (filterKey, state, value) => {
    // filterKey = key name in the filters object
    // state = state that will be used to create the new state
    // value = value to check the label is equal to
    return {
        ...state,
        selectedFilters: {
            ...state.selectedFilters,
            [filterKey]: value
        },
        activeStep: getStep(filterKey)
    }
}

export const markSelectedStates = (filterKeys, state) => {
    // filterKeys {key: classTypes, value: 11}, {key: classNames, value: FTTP}
    return {
        ...state,
        selectedFilters: {
            ...state.selectedFilters,
            ...filterKeys
        }
    }
}

const getStep = (filterKey) => {
    switch (filterKey) {
        case "dataTypes":
            return 2;
        case "classTypes":
            return 3;
        case "envTypes":
            return 1;
        case "qtyTypes":
            return 4;
        default:
            return 0;
    }
}

export const findUnique = (arr)=>{
    return arr.filter((item,index)=> arr.indexOf(item) === index);
}