import React, { useEffect } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';

import { fetchNotifications, setSummaryNotifications, toggleNotificationReadFlag, 
        setUnreadNotificationCount,viewMessages } from '../../actions/notificationActions';
import Notification from "./Notification";

const NotificationRequester = (props) => {
    const req = {
        "username" : props.userId,
        "startIndex" : 0,
        "batchSize" : 100
    }

    useEffect(() => {
        const interval = setInterval(() => {
        getNotifications(props, req);
    }, 300000);
        return () => clearInterval(interval);
    }, []);
    
    useEffect(() => {
        getNotifications(props, req);
    }, []);

    useEffect(() => {
        let summaryList = createSummaryList(props, props.notifications);
        props.setSummaryNotifications(summaryList);
        updateUnreadNotificationCount(props, props.notifications);
    }, [props.notifications]);

    return null;
}

const getNotifications = (props, req) => {
    let summaryList;
    props.fetchNotifications(req).then(result => {
        if (result) {
            summaryList = createSummaryList(props, result.notifications);
            props.setSummaryNotifications(summaryList);
        }
    });
}

const updateUnreadNotificationCount = (props, notifications) => {
    let unreadList = _.compact(notifications.map(notification => {
        if (notification.readFlag === false) {
            return notification;
        }
    }));
    props.setUnreadNotificationCount(unreadList.length);
}

const createSummaryList = (props, notifications) => {
    const numOfNotificationsToDisplay = 10;
    if(notifications != undefined) {
    return notifications.slice(0, numOfNotificationsToDisplay).map((notification) =>
        <li key={notification.id}>
            <Notification
                id={notification.id}
                postedBy={notification.postedBy}
                postedAt={notification.postedAt}
                readFlag={notification.readFlag}
                message={notification.message}
                teamFlag={notification.teamFlag}
                toggleRead={props.toggleNotificationReadFlag}
                userId={props.userId}
                viewMessages={props.viewMessages}
                setNotificationsMessageOpen={props.setNotificationsMessageOpen}
            />
        </li>
    );} 
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userName,
        notifications: state.notification.notifications
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchNotifications : (req) => dispatch(fetchNotifications(req)),
        setSummaryNotifications : (req) => dispatch(setSummaryNotifications(req)),
        toggleNotificationReadFlag : (req) => dispatch(toggleNotificationReadFlag(req)),
        setUnreadNotificationCount : (req) => dispatch(setUnreadNotificationCount(req)),
        viewMessages : (req) => dispatch(viewMessages(req))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationRequester);