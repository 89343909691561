// synchronous redux form validators

import { isAlphanumeric, isEmail, isNumeric } from 'validator';
import { selectUtilityFilter } from '../../../actions/utilityActions';

export const signinFormValidator = (values) => {
    const errors = {};

    if (!values.usernameOrEmail) {
        errors.usernameOrEmail = "Please enter a valid username or email";
    }

    if (!values.team) {
        errors.team = "You must select a team";
    }

    if (!values.password || values.password.length < 4) {
        errors.password = "Your password is at least 4 characters"
    }

    // If errors is empty, form is fine to submit If errors has any properties,
    // redux form assumes form is invalid
    return errors;
}

export const forgotPwdFormValidator = (values) => {
    const errors = {};

    if (!values.email || !isEmail(values.email)) {
        errors.email = "Please enter a valid email address";
    }

    return errors;
}

export const simasFormValidator = (values) => {
    const errors = {}
    if (!values.imsiResourceCategory) {
        errors.imsiResourceCategory = "Please select a resource category"
    }
    if (!values.imsiResourcePool) {
        errors.imsiResourcePool = "Please select a resource pool"
    }
    if (!values.simResourceCategory) {
        errors.simResourceCategory = "Please select a resource category"
    }
    if (!values.simResourcePool) {
        errors.simResourcePool = "Please select a resource pool"
    }
    return errors;
}

export const signupFormValidator = (values) => {
    const errors = {};
    const reg = /^[d,c,e]\d{0,7}$/;
    if (values.username) {
        if (values.username.length < 5) {
            errors.username = "Username must be at least 5 characters";
        }
        if (!isAlphanumeric(values.username)) {
            errors.username = "Username must be alphanumeric";
        }
        if (values.username.length > 15) {
            errors.username = "Username cannot be more then 15 characters";
        }
        if (!reg.test(values.username.toLowerCase())) {
            errors.username = "Please enter a valid Dnumber/CNumber/ENumber";
        }
    } else {
        errors.username = "Please enter a valid username";
    }

    if (values.name) {
        if (values.name.length < 5) {
            errors.name = "Name must be at least 5 characters";
        }
        if (values.name.length > 40) {
            errors.name = "Name cannot be more then 40 characters";
        }
    } else errors.name = "Please enter a valid name";

    if (!values.email || !isEmail(values.email)) {
        errors.email = "Please enter a valid email address";
    }

    if (values.email && (values.email.length > 60)) {
        errors.email = "Email cannot be more then 60 characters";
    }
/*
    if (values.password) {
        if (values.password.length < 4) {
            errors.password = "Your password must be least 4 characters";
        } else if (values.password.length > 20) {
            errors.password = "Password cannot be more then 20 characters";
        }
    } else {
        errors.password = "Please enter a valid password";
    }

    if (!values.password || (values.password != values.cPassword)) {
        errors.cPassword = "You must confirm your password"
    } */

    if (!values.team) {
        errors.team = "You must select a team";
    }

    if (!values.captchaToken && errors == {}) {
        // only display error if all other errors are not present
        errors.captchaToken = "error"
    }

    // If errors is empty, form is fine to submit
    // If errors has any properties, redux form assumes form is invalid
    return errors;
}

function nBnValidation(values, errors){
    if(!values.nbnCustomerId)
        errors.nbnCustomerId = "Required";
    if(!values.nbnBan)
        errors.nbnBan = "Required";
    if(!values.nbnSan)
        errors.nbnSan = "Required";
    if (values.nbnCustomerId) {
        errors.nbnCustomerId = customerIdValidator(values.nbnCustomerId);
        errors.nbnBan = banValidator(values.nbnBan);
        errors.nbnSan = sanValidator(values.nbnSan);
    }
    if (values.nbnBan) {
        errors.nbnBan = banValidator(values.nbnBan);
        errors.nbnCustomerId = customerIdValidator(values.nbnCustomerId);
        errors.nbnSan = sanValidator(values.nbnSan);
    }
    if (values.nbnSan) {
        errors.nbnSan = sanValidator(values.nbnSan);
        errors.nbnCustomerId = customerIdValidator(values.nbnCustomerId);
        errors.nbnBan = banValidator(values.nbnBan);
    }
    return errors;
}
function mobileValidation(values, errors){

     if (values.customerId ) {
         errors.customerId = customerIdValidator(values.customerId);
         errors.ban = banValidator(values.ban)
     }
     if (values.ban ) {
         errors.ban = banValidator(values.ban)
         errors.customerId = customerIdValidator(values.customerId);

     }
     if (values.msisdn ) {
         errors.msisdn = msisdnValidator(values.msisdn);
     }
     if (values.imsi) {
         errors.imsi = imsiValidator(values.imsi)
         errors.sim = simValidator(values.sim);
     }
     if (values.sim) {
         errors.sim = simValidator(values.sim);
         errors.imsi = imsiValidator(values.imsi);
     }
    return  errors;
}

function completeOrderValidation(values,errors){
    if(!values.orderId)
        errors.orderId = 'Required';
    if(values.orderId){
        errors.orderId = orderIdValidator(values.orderId);
    }
    return errors;
}

function establishedStubValidation(values,errors){
    if(!values.customerId)
        errors.customerId = 'Required';
    if(values.customerId){
        errors.customerId = customerIdValidator(values.customerId);
    }
    return errors;
}

export const utilityFormValidator = (values, self) => {
    const errors = {};
    const {isNbn, isCompleteOrder, isEstablishedStub} = self;

    if(!isCompleteOrder && !isEstablishedStub){
        return isNbn ?  nBnValidation(values, errors): mobileValidation(values, errors);
    }if(isEstablishedStub){
        return establishedStubValidation(values,errors);
    }else{
        return completeOrderValidation(values,errors);
    }
}
function customerCreditUpdateValidation(values,errors){
    if(!values.customerId)
        errors.customerId = 'Required';
    if(values.customerId){
        errors.customerId = customerIdValidator(values.customerId);
    }
    if(values.creditLimit){
        errors.creditLimit = creditLimit(values.creditLimit);
    }
    return errors;
}

export const utilityCustomerCreditFormValidator = (values, self) => {
    const errors = {};
    return customerCreditUpdateValidation(values,errors);
}

function createRcrRequestIdValidation(values,errors){
    if(!values.planOrderId && !values.roOrderId && !values.iddOrderId){
        errors.planOrderId = 'Required';
        errors.roOrderId = 'Required';
        errors.iddOrderId = 'Required';
    }
    if(values.orderId){
        errors.planOrderId = rcrOrderIdValidator(values.planOrderId);
    }


    if(!values.serviceId)
        errors.serviceId = 'Required';
    if(values.serviceId){
        errors.serviceId = rcrServiceIdValidator(values.serviceId);
    }
    return errors;
}

export const utilityCreateRcrRequestIdFormValidator = (values, self) => {
    const errors = {};
    
    return createRcrRequestIdValidation(values,errors);
}
/*
function customerCreditUpdateValidation(values,errors){
    if(!values.customerId)
        errors.customerId = 'Required';
    if(values.customerId){
        errors.customerId = customerIdValidator(values.customerId);
    }
    if(!values.contactId){
        errors.contactId = 'Required';
    }
    if(values.contactId){
        errors.contactId = contactIdValidator(values.contactId);
    }
    if(!values.username || !isEmail(values.username)){
        errors.username = 'Please enter a valid email address'
    }
    return errors;
}
 */
export const utilityIdentityProfileFormValidator = (values, self) => {
    const errors = {};
    return customerCreditUpdateValidation(values,errors);
}

export const contactIdValidator = (value) => {
    if (!value) return "Can not be null";
    else if(value.length > 15) return "Contact ID does not match length format";
}

export const orderIdValidator = (value) => {
    var regex1 = /(^[0-9]*$)/;
    var regex2 = /(^[1]+[-]+[0-9]+$)/;
    if (!value) return "Can not be null";

   if(value.startsWith('1-')){
        if (!regex2.test(value)) return "Order ID starts with 1- and should not contain alphabets and special characters";
        else if (value.length > 15) return "Order ID does not match length format";
    } else if (!regex1.test(value)) return "Order ID should contain only numbers";
    else if (value.length > 15) return "Order ID does not match length format";
}

export const customerIdValidator = (value) => {
    if (!value) return "Can not be null";
    else if (!isNumeric(value)) return "Customer ID must be a number";
    else if (value.length !== 10) return "Customer ID does not match length format";
}
export const rcrOrderIdValidator = (value) => {
    if (!value) return "Can not be null";
    else if (value.length !== 15) return "Order ID does not match length format";
}
export const rcrServiceIdValidator = (value) => {
    if (!value) return "Can not be null";
    else if (!isNumeric(value)) return "Service ID must be a number";
    else if (value.length !== 11) return "Service ID does not match length format";
}
export const salesForceServiceIdValidator = (value) => {
    if (!value) return "Can not be null";
    else if (!isNumeric(value)) return "Service ID must be a number";
    else if (value.length !== 11) return "Service ID does not match length format";
    else if (!value.startsWith('614')) return "ServiceID should start with with 614";
}
export const salesForceDeviceIdValidator = (value) => {
    if (!value) return "Can not be null";
    else if (!isNumeric(value)) return "Device ID must be a number";
}
export const creditLimit = (value) => {
    if (!value) return "Can not be null";
    else if (!isNumeric(value))
        return "credit limit must be a number";
    else if (isNumeric(value) && (value<=0 || value>5000))
        return "credit limit should be between $1 and $5000";
}

export const banValidator = (value) => {
    if (!value) return "Can not be null";
    else if (!isNumeric(value)) return "BAN must be a number";
    else if (value.length !== 13) return "BAN does not match length format";
}

export const sanValidator = (value) => {
    var objRegex = /(^[1]+[-]+[a-zA-Z0-9]+$)/;
    if (!value) return "Can not be null";
    else if (value.length > 15) return "SAN does not match length format";
    else if (!objRegex.test(value)) return "SAN starts with 1- and can not contain special characters";
}

export const imsiValidator = (value) => {
    if (!value) return "Can not be null";
    else if (!isNumeric(value)) return "IMSI must be a number";
    else if (!value.startsWith('5050')) return "IMSI starts with 5050";
    else if (value.length !== 15) return "IMSI does not match length format";
};

export const simValidator = (value) => {
    if (!value) return "Can not be null";
    else if (!isNumeric(value)) return "SIM must be a number";
    else if (!value.startsWith('8961')) return "SIM starts with 8961";
    else if (value.length !== 20) return "SIM does not match length format";
};

export const msisdnValidator = value => {
    if (!value)
        return "Can not be null";
    if (!isNumeric(value))
        return "MSISDN must be a number"
    if (!value.startsWith('61')) return "MSISDN starts with 61";
    else if (value.length !== 11) return "MSISDN does not match length format";
}

export const tagIdValidator = values => {
    const tagID = values.tagid;
    if (!tagID) return {tagid:"Tag ID cannot be empty"};
    if (!isNumeric(tagID)) return {tagid:"Tag ID must be numeric"};
}

export const billingAddressValidator = (values)=>{
    const errors = { };
    if (!values.address) {
      errors.address = "Required";
    }
    if (!values.locality) {
      errors.locality = "Required";
    }
    if (!values.postcode && (typeof(values.postcode) !== "number")) {
      errors.postcode = "Only numbers are allowed";
    }
      if (!values.state) {
      errors.state = "Required";
    }
    if (!values.country) {
      errors.country = "Required";
    }
    return errors;
}

export const alphaNumericValidation= (values,field)=>{
    if (!values)
    return "Can not be null";
    if (!isAlphanumeric(values)) {
        errors.username = `${field} must be alphanumeric`;
    }
}

export const numericValidation = (value,field) => {
    if (!value)
        return "Can not be null";
    if (!isNumeric(value))
        return `${field} must be a number`;
}

export const imsiValidatorForOCS = (value) => {
    if (!value) return "Can not be null";
    else if (!isNumeric(value)) return "IMSI must be a number";
    else if (value.length !== 15) return "IMSI does not match length format";
};