import React, { Component, Fragment } from 'react';
import { reduxForm, Field, FieldArray, reset } from "redux-form";
import { compose } from "redux";
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { renderField } from "../common/fields/fields";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './SimasForm.css';
import _ from "lodash";
import swal from 'sweetalert2';
import { displayLoading, displaySuccess } from "../common/alerts/alerts";
import { MenuItem } from '@material-ui/core';
import { removeFromAttributes, simasInsert, getSiamsFormIdList } from "../../actions/tdmOpsActions";
import { simasFormValidator as validate } from '../common/form/syncValidators';
import { Redirect } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@mui/material/TextField';

class SimasForm extends Component {
    state = {
        simExtraAttributes: [],
        imsiExtraAttributes: [],
        simAddAttributeValid: false,
        imsiAddAttributeValid: false,
        simAttribute: '',
        imsiAttribute: '',
        cancel: false,
        sim: {
            resourceAttributes: []
        },
        imsi: {
            resourceAttributes: []
        },
        eki:null,
        puk2:null,
        categoryId:null,
        category_list:[],
        orderAttributeId:null,
        SIM_pool_id:null,
        SIM_pool_id_list:[],
        otaKeys:null,
        otaKind:null,
        orderId:null,
        bulkOrderId:null,
        orderFileName:null,
        otaKeyHeader:null,
        otaEncryptionAlgo:null,

        phlr:"HLRE",
        region:"Test",
        shlr:"HLRF",
        IMSI_pool_id:null,
        IMSI_pool_id_list:[],

        ekiError:false,
        ekiErrorMsg:"",
        puk2Error:false,
        puk2ErrorMsg:"",
        categoryIdError:false,
        categoryIdErrorMsg:"",
        orderAttributeIdError:false,
        orderAttributeIdErrorMsg:"",
        SIM_pool_idError:false,
        SIM_pool_idErrorMsg:"",
        otaKeysError:false,
        otaKeysErrorMsg:"",
        otaKindError:false,
        otaKindErrorMsg:"",
        orderIdError:false,
        orderIdErrorMsg:"",
        bulkOrderIdError:false,
        bulkOrderIdErrorMsg:"",
        orderFileNameError:false,
        orderFileNameErrorMsg:"",
        otaKeyHeaderError:false,
        otaKeyHeaderErrorMsg:"",
        otaEncryptionAlgoError:false,
        otaEncryptionAlgoErrorMsg:"",

        phlrAlgoError:false,
        phlrAlgoErrorMsg:"",
        regionAlgoError:false,
        regionAlgoErrorMsg:"",
        shlrError:false,
        shlrErrorMsg:"",
        IMSI_pool_idError:false,
        IMSI_pool_idErrorMsg:"",

    }

    isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string)


    handleChange = (event) => {
        if (event.target.name === "orderAttributeId") {
            if (this.isNumeric(event.target.value)) {
                this.setState({
                    orderAttributeId: Number(event.target.value),
                    orderAttributeIdError: false,
                    orderAttributeIdErrorMsg: ""
                })
            } else {
                this.setState({
                    orderAttributeIdError: true,
                    orderAttributeIdErrorMsg: "Order Attribute Id must be a Numeric value",
                    orderAttributeId: event.target.value
                })
            }
        } else {
            this.setState({
                [event.target.name]: event.target.value
            })
        }
    }

    createAttributeField = (type) => {
        let attribute = '';
        if (type === 'simAttribute') {
            if (this.state.simAttribute){
                attribute = this.state.simAttribute;
                this.setState({
                    ...this.state,
                    simAttribute: '',
                    simExtraAttributes: _.uniq([...this.state.simExtraAttributes, attribute])
                }, () => {
                    this.props.removeFromAttributes('simResponse', {...this.props.simResponse, ResourceAttribute: this.props.simResponse.ResourceAttribute.filter(element => element !== attribute)})
                })
            }
        } else if (type === 'imsiAttribute') {
            if (this.state.imsiAttribute){
                attribute = this.state.imsiAttribute
                this.setState({
                    ...this.state,
                    imsiAttribute: '',
                    imsiExtraAttributes: _.uniq([...this.state.imsiExtraAttributes, attribute])
                }, () => {
                    this.props.removeFromAttributes('imsiResponse', {...this.props.imsiResponse, ResourceAttribute: this.props.imsiResponse.ResourceAttribute.filter(element => element !== attribute)})
                })
            }
        }
    }

    createOptions = (data) => {
        let options = [];
        data.forEach(attribute => {
            options.push({value: attribute, label: attribute});
        });
        return options;
    }

    cancel = () => {
        this.setState({
            ...this.state,
            cancel: true
        })
    }

    onSubmit = () => {
        const {eki,puk2,categoryId,orderAttributeId,SIM_pool_id,otaKeys,otaKind,orderId,bulkOrderId,orderFileName,otaKeyHeader,otaEncryptionAlgo,phlr,region,shlr,IMSI_pool_id}=this.state;
        let simAndImsiJson = this.convertSimAndImsiDataToJson(this.props.tableData);
        let request = {
            "dNumber":this.props.dNumber,
            "environment": this.props.selectedEnv,
            "simImsiDetails": [
                ...simAndImsiJson
            ],
            "simResource": {
                categoryId,
                orderAttributeId,
                "poolId": SIM_pool_id,
                eki,
                puk2,
                otaKeys,
                otaKind,
                orderId,
                bulkOrderId,
                orderFileName,
                otaKeyHeader,
                otaEncryptionAlgo
            },
            "imsiResource": {
                phlr,
                region,
                shlr,
                "poolId": IMSI_pool_id
            }
        }
        displayLoading({ title: "SIMaS Insertion", bodyText: "Please standby while we submit SIMaS Insertion request" })
        this.props.simasInsert(request).then(() => {
            swal.close();
            if (this.props.insertionSuccess) {
                displaySuccess({ title: "SIMaS Insertion Success", html: "Inserted " + this.props.insertionResponse.insertedRecords +
                    " records successfully <br/><br/>" + (this.props.insertionResponse.existingImsiRecords ? this.props.insertionResponse.existingImsiRecords.length + " IMSI records already exist <br/>" : "") +
                    (this.props.insertionResponse.existingSimRecords ? this.props.insertionResponse.existingSimRecords.length + " SIM records already exist" : "")});
            } else if (this.props.insertionError) {
                swal.fire({
                    type: 'error',
                    title: 'Error',
                    html: this.props.insertionResponse,
                    confirmButtonText: 'OK',
                })
            }
        });
    }

    convertSimAndImsiDataToJson = (data) => {
        let simAndImsiJsonList = [];
        data.forEach(row => { simAndImsiJsonList.push({"sim": row[0], "imsi": row[1]})});
        return simAndImsiJsonList;
    }

    convertResourceAttributesToJson = (attributes) => {
        let attributeList = [];
        let destrucAttributes = Object.entries(attributes);
        
        for (const [type, value] of destrucAttributes) {
            if (type && value) {
                attributeList.push({
                    "resourceAttributeName": type,
                    "resourceAttributeValue": value
                });
            }
        }
        return attributeList;
    }

    createResourceAttributes = (type, event) => {
        let val = false;
        let attributeType = '';
        
        this.setState({
            ...this.state,
            [type]: {
                resourceAttributes: {
                    ...this.state[type].resourceAttributes,
                    [event.target.name]: event.target.value
                }
            }
        }, () => {
            if (type === 'sim') {
                val = this.validateExtraAttributes(type, this.state.simExtraAttributes)
                attributeType = 'simAddAttributeValid'
            } else if(type === 'imsi') {
                val = this.validateExtraAttributes(type, this.state.imsiExtraAttributes)
                attributeType = 'imsiAddAttributeValid'
            }
            this.setState({
                ...this.state,
                [attributeType]: val,
            });
        });
    }

    validateExtraAttributes = (type, attributes) => {
        let hasValue = false;

        if (attributes.length > 0) {
            attributes.forEach(attribute => {
                if (this.state[type].resourceAttributes[attribute] && this.state[type].resourceAttributes[attribute] !== '') {
                    hasValue = true;
                }
            })
        }
        return hasValue;
    }

    componentDidMount(){
        const result=this.props.getSiamsFormIdList();
        result.then((result)=>{
            if(result.IMSI_pool_id_list && result.SIM_pool_id_list && result.category_list){
                let {IMSI_pool_id_list,SIM_pool_id_list, category_list}=result;
                this.setState({
                    ...this.state,
                    IMSI_pool_id_list:IMSI_pool_id_list?.data?.lovs,
                    SIM_pool_id_list:SIM_pool_id_list?.data?.lovs,
                    category_list:category_list?.data?.lovs,
                })
            }
        })
    }
    render() {    
        const { handleSubmit } = this.props;
        const {eki,puk2,categoryId,orderAttributeId,SIM_pool_id,otaKeys,otaKind,orderId,bulkOrderId,orderFileName,otaKeyHeader,otaEncryptionAlgo,phlr,region,shlr,IMSI_pool_id}=this.state;
        if (this.props.insertionSuccess || this.props.insertionError || this.state.cancel )  {
            return <Redirect to="/ops"/>
        }
        return (
            <Fragment>
                <form
                    className="simas-form white"
                    onSubmit={handleSubmit(values => this.onSubmit(values))}
                >
                    <div style={{"display":"inline-flex","width":"100%"}}>
                        <div style={{"width":"45%"}}>
                        <h5>IMSI</h5>
                            <br/>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">IMSI Pool Id </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.IMSI_pool_id}
                                    label={"Pool Id"}
                                    onChange={(e)=>{
                                        this.setState({
                                            ...this.state,
                                            IMSI_pool_id:e.target.value
                                        })
                                    }}
                                >
                                    {this.state.IMSI_pool_id_list.map((_item) => {
                                        return <MenuItem value={_item.id}>{_item.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl><br/><br/>
                            <TextField id="standard-basic" name="phlr" label="PHLR" variant="standard" fullWidth required
                              error={this.state.phlrError}
                              helperText={this.state.phlrErrorMsg}
                              value={this.state.phlr}
                              onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    phlrError:e.target.value==="" ? true : false,
                                    phlrErrorMsg:e.target.value==="" ? "PHLR cannot be empty" : '',
                                })
                           
                        }}
                        onChange={(e)=>{
                            this.setState({
                                ...this.state,
                                phlr:e.target.value
                            })
                        }}
                            /> <br/><br/>
                                 <TextField id="standard-basic" name="region" label="Region" variant="standard" fullWidth required
                              error={this.state.regionError}
                              helperText={this.state.regionErrorMsg}
                              value={this.state.region}
                              onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    regionError:e.target.value==="" ? true : false,
                                    regionErrorMsg:e.target.value==="" ? "Region cannot be empty" : '',
                                })
                           
                        }}
                        onChange={(e)=>{
                            this.setState({
                                ...this.state,
                                region:e.target.value
                            })
                        }}
                            /> <br/><br/>
                            <TextField id="standard-basic" name="shlr" label="SHLR" variant="standard" fullWidth required
                              error={this.state.shlrError}
                              helperText={this.state.shlrErrorMsg}
                              value={this.state.shlr}
                              onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    shlrError:e.target.value==="" ? true : false,
                                    shlrErrorMsg:e.target.value==="" ? "SHLR cannot be empty" : '',
                                })
                           
                        }}
                        onChange={(e)=>{
                            this.setState({
                                ...this.state,
                                shlr:e.target.value
                            })
                        }}
                            />
                        </div>
                        <div style={{"width":"45%", "marginLeft":"auto", "marginRight":"0"}}>
                        <h5>SIM</h5>
                            <br/>
                          
                       
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">SIM Pool Id </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.SIM_pool_id}
                                    label="Pool Id"
                                    onChange={(e)=>{
                                        this.setState({
                                            ...this.state,
                                            SIM_pool_id:e.target.value
                                        })
                                    }}
                                >
                                   {this.state.SIM_pool_id_list.map((_item)=>{
                                        return <MenuItem value={_item.id}>{_item.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl><br/><br/>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">SIM Category Id  </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.categoryId}
                                    label="Category Id"
                                    onChange={(e)=>{
                                        this.setState({
                                            ...this.state,
                                            categoryId:e.target.value
                                        })
                                    }}
                                >
                                    {this.state.category_list.map((_item)=>{
                                        return <MenuItem value={_item.id}>{_item.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl><br/><br/>
                            <TextField id="standard-basic" name="orderAttributeId" label="Order Attr Id" variant="standard" fullWidth required onChange={this.handleChange}
                              error={this.state.orderAttributeIdError}
                            helperText={this.state.orderAttributeIdErrorMsg}
                            value={this.state.orderAttributeId}
                              onBlur={(e)=>{
                                if(e.target.value===""){
                                    this.setState({
                                        ...this.state,
                                        orderAttributeIdError:e.target.value==="" ? true : false,
                                        orderAttributeIdErrorMsg:e.target.value==="" ? "Order Attr Id cannot be empty" : '',
                                    })
                                }else{
                                    if (this.isNumeric(e.target.value)) {
                                        this.setState({
                                            ...this.state,
                                            orderAttributeIdError:e.target.value==="" ? true : false,
                                            orderAttributeIdErrorMsg:e.target.value==="" ? "Order Attr Id cannot be empty" : '',
                                        })
                                    } else {
                                        this.setState({
                                            orderAttributeIdError: true,
                                            orderAttributeIdErrorMsg: "Order Attribute Id must be a Numeric value",
                                            orderAttributeId: e.target.value
                                        })
                                    }
                                }
                        }}
                            /> <br/><br/>
<TextField 
                            id="standard-basic" 
                            name="eki" 
                            label="EKI" 
                            variant="standard" 
                            fullWidth
                            required 
                            value={this.state.eki}
                            onChange={this.handleChange}
                            error={this.state.ekiError}
                            helperText={this.state.ekiErrorMsg}
                            onBlur={(e)=>{
                                    this.setState({
                                        ...this.state,
                                        ekiError:e.target.value==="" ? true : false,
                                        ekiErrorMsg:e.target.value==="" ? "EKI cannot be empty" : '',
                                    })
                               
                            }}
                            /> <br/><br/>
                            <TextField id="standard-basic" name="puk2" label="PUK2" variant="standard" fullWidth required onChange={this.handleChange}
                              error={this.state.puk2Error}
                              value={this.state.puk2}
                            helperText={this.state.puk2ErrorMsg}
                             onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    puk2Error:e.target.value==="" ? true : false,
                                    puk2ErrorMsg:e.target.value==="" ? "PUK2 cannot be empty" : '',
                                })
                           
                        }}/> <br/><br/>
                            <TextField id="standard-basic" name="otaKeys" label="OTA Keys" variant="standard" fullWidth required onChange={this.handleChange}
                              error={this.state.otaKeysError}
                            helperText={this.state.otaKeysErrorMsg}
                            value={this.state.otaKeys}
                             onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    otaKeysError:e.target.value==="" ? true : false,
                                    otaKeysErrorMsg:e.target.value==="" ? "OTA Keys cannot be empty" : '',
                                })
                           
                        }}
                            /> <br/><br/>
                            <TextField id="standard-basic" name="otaKind" label="OTA Kind" variant="standard" fullWidth required onChange={this.handleChange}
                              error={this.state.otaKindError}
                              value={this.state.otaKind}
                            helperText={this.state.otaKindErrorMsg}
                                onBlur={(e)=>{
                                    this.setState({
                                        ...this.state,
                                        otaKindError:e.target.value==="" ? true : false,
                                        otaKindErrorMsg:e.target.value==="" ? "OTA Kind cannot be empty" : '',
                                    })
                               
                            }}
                            /> <br/><br/>
                            <TextField id="standard-basic" name="orderId" label="Order ID" variant="standard" fullWidth required onChange={this.handleChange}
                              error={this.state.orderIdError}
                              helperText={this.state.orderIdErrorMsg}
                              value={this.state.orderId}
                              onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    orderIdError:e.target.value==="" ? true : false,
                                    orderIdErrorMsg:e.target.value==="" ? "Order ID cannot be empty" : '',
                                })
                           
                        }}
                            /> <br/><br/>
                            <TextField id="standard-basic" name="bulkOrderId" label="Bulk Order ID" variant="standard" fullWidth required onChange={this.handleChange}
                              error={this.state.bulkOrderIdError}
                              helperText={this.state.bulkOrderIdErrorMsg}
                              value={this.state.bulkOrderId}
                             onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    bulkOrderIdError:e.target.value==="" ? true : false,
                                    bulkOrderIdErrorMsg:e.target.value==="" ? "Bulk Order ID cannot be empty" : '',
                                })
                           
                        }}
                            /> <br/><br/>
                            <TextField id="standard-basic" name="orderFileName" label="Order Filename" variant="standard" fullWidth required onChange={this.handleChange}
                              error={this.state.orderFileNameError}
                              value={this.state.orderFileName}
                            helperText={this.state.orderFileNameErrorMsg}
                               onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    orderFileNameError:e.target.value==="" ? true : false,
                                    orderFileNameErrorMsg:e.target.value==="" ? "Order Filename cannot be empty" : '',
                                })
                           
                        }}
                            /> <br/><br/>
                            <TextField id="standard-basic" name="otaKeyHeader" label="OTA Keys Header" variant="standard" fullWidth required onChange={this.handleChange}
                              error={this.state.otaKeyHeaderError}
                            helperText={this.state.otaKeyHeaderErrorMsg}
                            value={this.state.otaKeyHeader}
                               onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    otaKeyHeaderError:e.target.value==="" ? true : false,
                                    otaKeyHeaderErrorMsg:e.target.value==="" ? "OTA Keys Header cannot be empty" : '',
                                })
                           
                        }}
                            /> <br/><br/>
                            <TextField id="standard-basic" name="otaEncryptionAlgo" label="OTA Encryption Algor" variant="standard" fullWidth required onChange={this.handleChange}
                              error={this.state.otaEncryptionAlgoError}
                            helperText={this.state.otaEncryptionAlgoErrorMsg}
                            value={this.state.otaEncryptionAlgo}
                               onBlur={(e)=>{
                                this.setState({
                                    ...this.state,
                                    otaEncryptionAlgoError:e.target.value==="" ? true : false,
                                    otaEncryptionAlgoErrorMsg:e.target.value==="" ? "OTA Encryption Algor cannot be empty" : '',
                                })
                           
                        }}
                            /> 
                        </div>
                    </div>
               
                <div style={{"margin-top":"30px", "textAlign":"center"}}>
                        <Tooltip title={(!this.state.simAddAttributeValid || !this.state.imsiAddAttributeValid) ? "Sim and IMSI must have at least 1 additional attribute added and filled" : "Submit Insert Request"}>
                            <span>

                                <Button className="simas-form-btn" color="primary" type="submit" disabled={!eki || !puk2 || !categoryId || !orderAttributeId || !SIM_pool_id || !otaKeys || !otaKind || !orderId || !bulkOrderId || !orderFileName || !otaKeyHeader || !otaEncryptionAlgo || !phlr || !region || !shlr || !IMSI_pool_id}>
                                    Submit
                                </Button>
                            </span>
                        </Tooltip>
                        <Button className="simas-form-btn simas-cancel-btn" color="secondary" type="button" onClick={() => this.cancel()}>Cancel</Button>
                    </div>
                    </form>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        simPending: state.tdmOps.isSimFetchPending,
        simError: state.tdmOps.isSimFetchError,
        simSuccess: state.tdmOps.isSimFetchSuccess,
        simResponse: state.tdmOps.simResponse,
        imsiPending: state.tdmOps.isImsiFetchPending,
        imsiError: state.tdmOps.isImsiFetchError,
        imsiSuccess: state.tdmOps.isImsiFetchSuccess,
        imsiResponse: state.tdmOps.imsiResponse,

        insertionPending: state.tdmOps.isSimasInsertionPending,
        insertionError: state.tdmOps.isSimasInsertionError,
        insertionSuccess: state.tdmOps.isSimasInsertionSuccess,
        insertionResponse: state.tdmOps.simasInsertionResponse,
        selectedEnv : state.auth.selectedEnvValue,
        dNumber: state.auth.userName,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeFromAttributes: (type, value) => dispatch(removeFromAttributes(type, value)),
        simasInsert: (request) => dispatch(simasInsert(request)),
        getSiamsFormIdList:()=>dispatch(getSiamsFormIdList())
    }
}

export default compose(connect(mapStateToProps, mapDispatchToProps),reduxForm({validate, form: "simasForm"}))(SimasForm);