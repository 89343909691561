import * as types from './CONSTANTS';

const initialState = {
    fetching: false,
    fetched: false,
    retrieveCheckOutData:{},
    errors:[],
    serviceMigData: {},
    serviceMigLoading: false,
    serviceMigErr:{},
    billStatus:false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_DATA_BANK_CHECKOUT_DATA:
            return {
                ...state,
                fetching: true
            }
        case types.FETCH_DATA_BANK_CHECKOUT_DATA_FULFILLED:
            return {
                ...state,
                fetching: false,
                fetched: true,
                retrieveCheckOutData: action.payload,
                errors: []
            }
        case types.FETCH_DATA_BANK_CHECKOUT_DATA_REJECTED:
            return {
                ...state,
                fetching: false,
                fetched : false,
                errors: [action.payload]
            }
        case types.SERVICE_MIGRATION_LOADING:
            return {
                ...state,
                serviceMigLoading: true
            }
        case types.SERVICE_MIGRATION_SUCCESS:
            return {
                ...state,
                serviceMigData: action.payload,
                serviceMigLoading: false
            }
        case types.SERVICE_MIGRATION_FALIURE:
            return {
                ...state,
                serviceMigErr: action.payload,
                serviceMigLoading: false
            }
        case types.ADD_BILL_PERIOD:
            let getStateValue={...state};
            getStateValue.retrieveCheckOutData.assetDetails[0].orders[action.payload.order_index]["billPeriod"]=action.payload.billPeriod;
            return getStateValue;
        case types.BILL_STATUS_CHECK:
            return {
                ...state,
                billStatus:!state.billStatus
            }
        default:
            return state
    }
}
