import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const PasmatYearLineGraph = (props) => {
    return (
        <div style={{ width: '100%' }}>
            <div className='header'>
                <h6 className='title'>{props.graphName}</h6>
            </div>
            <LineChart width={900} height={400}>
          <CartesianGrid strokeDasharray="6 6" />
          <XAxis dataKey="trendItem" allowDuplicatedCategory={false} />
          <YAxis dataKey="value" allowDuplicatedPeriod={true} 
          domain={[0,110]}
          ticks={[0, 10, 20, 30, 40,50,60,70,80,90, 100]}
          label={{ value: "Maturity Percentage(%)", position: "outsideRight", angle: -90,   dy: -5 ,dx: -10}}/>
          <Tooltip />
          <Legend />
          {props.ytdGraphData.map((s) => (
            <Line dataKey="value" data={s.data} name={s.period} key={s.period} stroke={s.color} strokeWidth={3}/>
          ))}
        </LineChart></div>
             );
}


export default PasmatYearLineGraph;