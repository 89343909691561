import React from 'react'
import LinkTile from "../custom/LinkTile";

const tileStyle = {
    background: 'linear-gradient(to right, #4a00b3 0, #4c00b3 100%)'
}


const OpsTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-green"
        data-size="medium"
        to="/ops"
        data-effect="hover-zoom-up">
        <div class="slide-front">
            <span className="td-icon-md icon-productivity-gains icon"></span>
            <span className="branding-bar">TDM OPS</span>
        </div>
        <div
            className="slide-back d-flex flex-justify-center flex-align-center p-4 op-green">
            <p className="text-center">
                TDM Operations Support
            </p>
        </div>
    </LinkTile>
)

OpsTile.propTypes = {}

export default OpsTile;