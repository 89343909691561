import React from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ExpandInfoTable = (props) => {
  const { row } = props;


  const NestedTableCom = ({ getData }) => {
    if (getData) {
      return <TableContainer component={Paper} style={{width: 1000}}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Product SubType</TableCell>
              <TableCell>Product Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getData.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.productName}
                </TableCell>
                <TableCell>{row.productSubType}</TableCell>
                <TableCell>{row.productType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    }
  }

  return <Grid container rowSpacing={0} style={{ paddingLeft: "80px" }}>
    <Grid item xs={6} style={{ maxWidth: "690px" }}>
      <NestedTableCom getData={row.product} />
    </Grid>
  </Grid>
};

ExpandInfoTable.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  row: PropTypes.object.isRequired,
  hideEmptyExpandOptions: PropTypes.bool,
};

export default ExpandInfoTable;