import * as types from '../actions/actionTypes';
const INITIAL_STATE = {
    history: {},
    view: {
        items: [],
        fnnItems: [],
        customer:[],
        sfCustomerStatus: null,
        collapseSfView: true,
        activeSfCustomer: null,
        tdiItems:[],
        tdiRegisterLoading: false,
        loading: false,
        tdiErrorMessage: "",
        sfCustomerCreationStatus: null,
        sfCustomerErrorStatus: null,
        assetActionsLoading: false,
        assetActionsMessage: "",
        assetCheckInDetails: null,
        assetCheckOutDetails: null,
        tdiCheckOutLoading: false,
        tdiErrorResponse: ''
    },
    filter: {
        fdata: {},
        floading: {},
        factive: []
    },
    checkoutPending: false,
    checkoutFailed: false,
    checkoutSuccess: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOAD_DATA_SUCCESS:
            return {
                ...state,
                view: {
                    ...action.payload,
                    tdiItems: state.view.tdiItems,
                    customer: state.view.customer,
                    loading: false
                }
            }
        case types.LOAD_CHECKOUT_HISTORY_SUCCESS:
            const { content, first, last, totalElements, totalPages, filters } = action.payload;
            const { pageSize, pageNumber } = action.payload['pageable'];
            return {
                ...state,
                history: {
                    content,
                    isFirst: first,
                    isLast: last,
                    currentPage: (pageNumber + 1),
                    currentPageSize: pageSize,
                    totalItems: totalElements,
                    totalPages,
                    loading: false
                },
                filter: {
                    ...state.filter,
                    factive: filters
                }
            }
        case types.LOAD_CHECKOUT_HISTORY_LOADING:
            return {
                ...state,
                history: {
                    ...state.history,
                    loading: action.loading
                }
            }
        case types.LOAD_CHECKOUT_HISTORY_DETAIL_SUCCESS:
            return {
                ...state,
                view: {
                    ...action.payload,
                    loading: false
                }
            }
        case types.LOAD_CHECKOUT_HISTORY_DETAIL_LOADING:
            console.log(action);
            return {
                ...state,
                view: {
                    loadingId: action.id,
                    ...state.view,
                    loading: action.loading
                }
            }

            //TODO
        case types.LOAD_CHECKOUT_TDI_LOADING:
            return{
                ...state,
                view: {
                    ...state.view,
                    tdiCheckOutLoading: true,
                    tdiItems: [],
                    tdiErrorResponse: ''
                }
            }
        case types.LOAD_CHECKOUT_TDI_SUCCESS:
                return{
                        ...state,
                        view: {
                            ...state.view,
                            tdiItems: [action.payload],
                            tdiCheckOutLoading: false
                        }
                    }
        case types.LOAD_CHECKOUT_TDI_UNAVAILABLE:
                return{
                    ...state,
                    view: {
                        ...state.view,
                        tdiErrorResponse: action.payload,
                        tdiCheckOutLoading: false
                    }
                }   
        case types.LOAD_CHECKOUT_TDI_FAILURE:
                return{
                        ...state,
                        view: {
                            ...state.view,
                            tdiCheckOutLoading: false,
                            tdiErrorResponse: action.payload
                            }
                        }
            case types.GETTING_SALESFORCE_DETAILS_LOADING:
                return {
                    ...state,
                    view: {
                        ...state.view,
                        customerItems: [
                            ...state
                                .view
                                .customerItems
                                .map(customer => (customer.customerId == action.id)
                                    ? {
                                        ...customer,
                                        sfImporting: true
                                    }
                                    : customer)
                        ],
                        sfCustomerStatus: "loading",
                        sfCustomerErrorStatus: null
                        
                    }
                }
            
                case types.GETTING_SALESFORCE_DETAILS_SUCCESS:
                    return {
                        ...state,
                        activeSfCustomer: action.payload.customerId,
                        view:{
                            ...state.view,
                            customerItems: [
                                ...state
                                    .view
                                    .customerItems
                                    .map(customer => 
                                        ({
                                            ...customer,
                                            sfImporting: false
                                        })
                                        )
                            ],
                            customer: action.payload.customer,
                            sfCustomerStatus: "success",
                            collapseSfView:true
    
                        }
                    }
    
            case types.GETTING_SALESFORCE_DETAILS_FAILURE:
                return {
                    ...state,
                    view: {
                        ...state.view,
                        sfCustomerStatus: "Failure",
                        sfCustomerErrorStatus: action.payload
                    }
                }
            
            case types.GETTING_SALESFORCE_CONTACT_DETAILS_LOADING:
                return {
                    ...state,
                    view: {
                        ...state.view,
                        sfCustomerCreationStatus:"loading",
                        sfCustomerErrorStatus: null
                    }
                }
            case types.GETTING_SALESFORCE_CONTACT_DETAILS_SUCCESS:
                    return {
                            ...state,
                            view: {
                                ...state.view,
                                customer: state.view.customer.map(data=>{
                                    if(data['account'].accountId === action.payload.accountId){
                                        return {
                                            ...data,
                                            contacts:[...data.contacts,action.payload]
                                        }
                                    }
                                    else{
                                        return data;
                                    }
                                    
                                }),
                                sfCustomerCreationStatus:"success"
                            }
                        }
            case types.GETTING_SALESFORCE_CONTACT_DETAILS_FAILURE:
                    return {
                        ...state,
                        view: {
                            ...state.view,
                            sfCustomerErrorStatus: action.payload
                            }
                        }

            case types.COLLAPSE_SALESFORCE_VIEW:
                return {
                        ...state,
                        view: {
                            ...state.view,
                            collapseSfView: action.collapseSfView
                        }
                    }
        case types.IMPORT_CUSTOMER_SALESFORCE_LOADING:
            return {
                ...state,
                view: {
                    ...state.view,
                    customerItems: [
                        ...state
                            .view
                            .customerItems
                            .map(customer => (customer.customerId == action.id)
                                ? {
                                    ...customer,
                                    sfImporting: true
                                }
                                : customer)
                    ]
                }
            }
        case types.IMPORT_CUSTOMER_SALESFORCE_SUCCESS:
            return {
                ...state,
                view: {
                    ...state.view,
                    activeSfCustomer: action.payload.customerId,
                    customerItems: [
                        ...state
                            .view
                            .customerItems
                            .map(customer => (customer.customerId == action.payload.customerId)
                                ? action.payload
                                : customer)
                    ]
                }
            }
        // load tdi registered details
        case types.TDM_REGITSER_LOADING:
            return {
                ...state,
                view:{
                    ...state.view,
                    tdiRegisterLoading:true,
                    tdiErrorMessage:''
                }
            }
        case types.TDM_REGISTER_SUCCESS:
            return {
                    ...state,
                    view:{
                        ...state.view,
                        tdiItems: [...state.view.tdiItems,action.payload],
                        tdiRegisterLoading:false,
                        tdiErrorMessage:''
                    }
                }
        case types.TDM_REGISTER_FAILURE:
            return {
                    ...state,
                    view:{
                        ...state.view,
                        tdiRegisterLoading:false,
                        tdiErrorMessage: action.payload
                    }
                }
        
    // asset CheckIn
        case types.TDM_ASSET_CHECK_IN_LOADING:
            return {
                ...state,
                view:{
                    ...state.view,
                    assetActionsLoading: true,
                    assetActionsMessage: ""
                }
            }
        case types.TDM_ASSET_CHECK_IN_SUCCESS:
            return {
                    ...state,
                    view:{
                        ...state.view,
                        assetCheckInDetails: action.payload,
                        assetActionsLoading: false,
                        assetActionsMessage: "CHECKIN_SUCCESS"
                    }
                }
        case types.TDM_ASSET_CHECK_IN_FAILURE:
            return {
                    ...state,
                    view:{
                        ...state.view,
                        assetActionsLoading: false,
                        assetActionsMessage: action.payload
                    }
                }

        
        // asset CheckOut
        case types.TDM_ASSET_CHECK_OUT_LOADING:
            return {
                ...state,
                view:{
                    ...state.view,
                    assetActionsLoading: true,
                    assetActionsMessage: ""
                }
            }
        case types.TDM_ASSET_CHECK_OUT_SUCCESS:
            return {
                    ...state,
                    view:{
                        ...state.view,
                        assetCheckOutDetails: action.payload,
                        assetActionsLoading: false,
                        assetActionsMessage: "CHECKOUT_SUCCESS"
                    }
                }
        case types.TDM_ASSET_CHECK_OUT_FAILURE:
            return {
                    ...state,
                    view:{
                        ...state.view,
                        assetActionsLoading: false,
                        assetActionsMessage: action.payload
                    }
                }        
        
        case types.LOAD_CUSTOMER_SALESFORCE_SUCCESS:
            const { update, payload } = action;
            return {
                ...state,
                view: {
                    ...state.view,
                    customerItems: [
                        ...state
                            .view
                            .customerItems
                            .map(customer => (customer.customerId == payload.customerId)
                                ? {
                                    ...customer,
                                    sfContacts: [
                                        ...payload.contacts,
                                        ...(update && customer.sfContacts
                                            ? customer.sfContacts
                                            : {})
                                    ],
                                    sfBillingAccounts: [
                                        ...payload.billingAccounts,
                                        ...(update && customer.sfBillingAccounts
                                            ? customer.sfBillingAccounts
                                            : {})
                                    ],
                                    sfSites: [
                                        ...payload.sites,
                                        ...(update && customer.sfSites
                                            ? customer.sfSites
                                            : {})
                                    ],
                                    sfOpps: [
                                        ...payload.opportunities,
                                        ...(update && customer.sfOpps
                                            ? customer.sfOpps
                                            : {})
                                    ]
                                }
                                : customer)
                    ]
                }
            }
        case types.SELECT_CUSTOMER_SALESFORCE_SUCCESS:
            const { customerId } = action;
            return {
                ...state,
                view: {
                    ...state.view,
                    activeSfCustomer: customerId
                }
            }
        case types.LOAD_CUSTOMER_SALESFORCE_KEY_LOADING:
            const { key, loading } = action;
            return {
                ...state,
                view: {
                    ...state.view,
                    customerItems: [
                        ...state
                            .view
                            .customerItems
                            .map(customer => (customer.customerId == action.id)
                                ? {
                                    ...customer,
                                    [key]: loading
                                }
                                : customer)
                    ]
                }
            }
        case types.LOAD_CHECKOUT_FILTER_SUCCESS:
            const filterKey = action.payload.key;
            const filterData = action.payload.data;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    fdata: {
                        ...state.filter.fdata,
                        [filterKey]: filterData
                    },
                    floading: {
                        ...state.filter.floading,
                        [filterKey]: false
                    }
                }
            }
        case types.LOAD_CHECKOUT_FILTER_LOADING:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    floading: {
                        ...state.filter.floading,
                        [action.key]: action.loading
                    }
                }
            }
        case types.LOAD_CHECKOUT_FILTER_ERROR:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    fdata: {
                        ...state.filter.fdata,
                        [action.key]: []
                    },
                    floading: {
                        ...state.filter.floading,
                        [action.key]: false
                    }
                }
            }
        case types.TEAM_CHECKOUT_PENDING:
            return {
                ...state,
                checkoutPending: true,
                checkoutFailed: false,
                checkoutSuccess: false
            }
        case types.TEAM_CHECKOUT_SUCCESS:
            return {
                ...state,
                checkoutPending: false,
                checkoutFailed: false,
                checkoutSuccess: true
            }
        case types.TEAM_CHECKOUT_FAILED:
            return {
                ...state,
                checkoutPending: false,
                checkoutFailed: true,
                checkoutSuccess: false
            }
        case types.TEAM_CHECKOUT_RESET:
            return {
                ...state,
                checkoutPending: false,
                checkoutFailed: false,
                checkoutSuccess: false
            }
        default:
            return state
    }
}