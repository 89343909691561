import React from "react";
import PropTypes from "prop-types";
import { TableCell } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from "@material-ui/core/styles";

const borderColor = grey[300];
const useTableVerticalCellStyle = makeStyles({
  root: {
    borderBottom: `1px solid ${borderColor}`,
  },
  head: {
    width: '20%'
  },
});

const TableVerticalCell = (props) => {
  const checkboxesData=props.checkboxes;
  let addtoCardStatus;
  if(!_.isEmpty(props.cartItems)){
    const BillingDetails=props.cartItems;
    if(BillingDetails.Billing){
      addtoCardStatus=BillingDetails.Billing['Credit Card']
    }
  }
  const creditCardStatus=checkboxesData['Billing Preferences']['Credit Card'];
  const { header, body, bodyCellValueAlignment="left" } = props;

  const tableTableVerticalCellStyle = useTableVerticalCellStyle();
  const ChooseOptions = () => {
    if(props.typeAdded){
    return <div>
      <br/>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 200,margin: "0px" }}>
          
        <InputLabel id="demo-simple-select-standard-label" sx={{color: "rgba(0, 0, 0, 0.87)", fontSize: "16px", fontWeight: 700,lineHeight: "1.5rem"}}>Credit Card</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={props.creditCardBrand ? props.creditCardBrand : ""}
          onChange={props.changeCreditCard}
          label="Credit Card*"
          sx={{fontSize:"0.875rem"}}
          disabled={!creditCardStatus || addtoCardStatus}
        >
          {props.cardTypes.map((_item)=>{
            return <MenuItem sx={{fontSize:"0.875rem"}} value={_item.value}>{_item.display}</MenuItem>
          }) }
        </Select>
        <FormHelperText>Choose your credit card types</FormHelperText>
      </FormControl>
    </div>
    } return null
  }
  return (
    <>
      
  <TableCell classes={tableTableVerticalCellStyle} variant="head">{!props.typeAdded ? header : null}<ChooseOptions/> </TableCell>
      {
        // body: May pass in [{align: "center", value: component}]
        body.map((value) => {
          return (
            <TableCell
              key={value}
              classes={tableTableVerticalCellStyle}
              align={(value && value.align) ? value.align : bodyCellValueAlignment}
            >
              {(value && value.align) ? <> {value.value}</> : value}
            </TableCell>
          )
        })
      }
    </>
  );
};

TableVerticalCell.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.array.isRequired,
  bodyCellValueAlignment: PropTypes.oneOf(["center","inherit","justify","left","right"])
};

export default TableVerticalCell;
