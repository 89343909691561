export const BAN_LOADING = "BAN_LOADING";
export const GENERATE_BAN = "GENERATE_BAN";
export const GENERATE_BAN_FAILED = "GENERATE_BAN_FAILED";
export const CREATE_ACCOUNT_PENDING = "CREATE_ACCOUNT_PENDING";
export const CREATE_BILLING_ACCOUNT = "CREATE_BILLING_ACCOUNT";
export const CREATE_ACCOUNT_FAILED = "CREATE_ACCOUNT_FAILED";

export const BILLING_ADDRESS_LOADING = "BILLING_ADDRESS_LOADING";
export const GET_BILLING_ADDRESS = "GET_BILLING_ADDRESS";
export const BILLING_ADDRESS_FAILED = "BILLING_ADDRESS_FAILED";
export const GET_BILLING_ADJUSTMENTS = "GET_BILLING_ADJUSTMENTS";
export const UPDATE_BILLING_ADDRESS = "UPDATE_BILLING_ADDRESS";

export const SET_BAN = "SET_BAN";
export const CREATE_BILLING_ADJUSTMENT = "CREATE_BILLING_ADJUSTMENT";
export const ADD_ADJUSTMENT = "ADD_ADJUSTMENT";
export const RESET_CREATE_ADJ_FORM = "RESET_CREATE_ADJ_FORM";
export const GET_ADJ_PENDING = "GET_ADJ_PENDING";
export const RESET_U2C_DATA = "RESET_U2C_DATA";

export const LOAD_PAYMENT_WITH_DATE_PENDING = "LOAD_PAYMENT_WITH_DATE_PENDING";
export const LOAD_PAYMENT_WITH_DATE = "LOAD_PAYMENT_WITH_DATE";
export const LOAD_PAYMENT_WITH_DATE_FAILED = "LOAD_PAYMENT_WITH_DATE_FAILED";
export const LOAD_PAYMENT_WITH_TRANSACTION_PENDING = "LOAD_PAYMENT_WITH_TRANSACTION_PENDING";
export const LOAD_PAYMENT_WITH_TRANSACTION = "LOAD_PAYMENT_WITH_TRANSACTION";
export const LOAD_PAYMENT_WITH_TRANSACTION_FAILED = "LOAD_PAYMENT_WITH_TRANSACTION_FAILED";

export const SAVING_PENDING = 'SAVING_PENDING';
export const SAVING_FAILED = "SAVING_FAILED";
export const SAVING_SUCCESS = "SAVING_SUCCESS";

export const SUBMIT_SUBSCRIPTION_PENDING = "SUBMIT_SUBSCRIPTION_PENDING";
export const SUBMIT_SUBSCRIPTION_SUCCESS = "SUBMIT_SUBSCRIPTION_SUCCESS";
export const SUBMIT_SUBSCRIPTION_FAIL = "SUBMIT_SUBSCRIPTION_FAIL";
export const SELECT_SUBSCRIPTION_ENV = "SELECT_SUBSCRIPTION_ENV";