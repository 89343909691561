import React, { PureComponent } from 'react';
import { Treemap } from 'recharts';
import { connect } from "react-redux";
import { setTeams, setParentProgram, setGraphTeam } from '../../actions/surveyAction';
import './slider.css'
import { Fragment } from 'react';


class CustomizedContent extends PureComponent {
    render() {
        const { root, depth, x, y, width, height, index, payload, colors, rank, name, treeProps,data } = this.props;
        const cost = parseInt(data[index].size)-100;
        return (
            <Fragment>
            <g>
                <rect
                    x={(x === x? x : x+100)}
                    y={y}
                    width={width}
                    height={height}
                    style={{
                        fill: colors[index],
                        stroke: '#fff',
                        strokeWidth: 2 / (depth + 1e-10),
                        strokeOpacity: 1 / (depth + 1e-10),
                        opacity: 0.6
                    }}
                    onClick={e => getTileClicked(name, treeProps)}
                />
               {depth === 1 ? (
          <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" 
          fill="#fff" fontSize={14}>
            {name}: {cost}
          </text>
        ) : null}
        </g></Fragment>
        );
    }
}

const getTileClicked = (name, treeProps) => {
    if (treeProps.graphtype === "Programs") {
        let programSelected = treeProps.trendAnalysisData.filter((trends) => trends.program === name)
        treeProps.setParentProgram(programSelected[0]);
    }
    else {
        let trendTeam = [];
        trendTeam = treeProps.trendProgram.teams.filter((tTeam) => tTeam.team_name === name)
        treeProps.setGraphTeam(trendTeam[0]);
    }
}

const PasmatTreeMap = (props) => {

    let options = props.xData;
    let values = props.yData;
    const mapArrays = (options, values) => {
        const res = [];
        for (let i = 0; i < props.xData.length; i++) {
            res.push({
                name: options[i],
                size: values[i] +100 
            });
        };
        return res;
    };
    const data = mapArrays(options, values);

    return (
        <Fragment>
        <div className='header'>
                <h6 className='title'>{props.graphName}</h6>
            </div>
        <div className = "treeMap">
            <Treemap
                width={800}
                height={400}
                data={data}
                dataKey="size"
                stroke="#fff"
                fill="#8884d8"
                content={<CustomizedContent colors={props.colors} data={data} treeProps={props} />}
            /></div></Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        trendAnalysisData: state.pasmatTrend.trendAnalysisData,
        trendProgram: state.pasmatTrend.trendProgram,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTeams: (teams, streamMaturity) => dispatch(setTeams(teams, streamMaturity)),
        setParentProgram: (parentProgram) => dispatch(setParentProgram(parentProgram)),
        setGraphTeam: (graphTeam) => dispatch(setGraphTeam(graphTeam))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasmatTreeMap);