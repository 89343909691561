import React, { Component, useEffect, Fragment, useState } from "react";
import { connect } from 'react-redux';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { setStatelessSimType, prodData, setSpecificRequirement } from "../../../../actions/orderBuilderActions";

const useStyles = makeStyles({
  stateLabel: {
    backgroundImage: "linear-gradient(198deg,#2996d4 -10%,#4b3789 -11%,rgba(229,195,222,0.000001) 55%),linear-gradient(283deg,#99529a 7%,#8cb5e0 64%,#0bbbff 100%)",
    color: "#ffffff  !important",
    marginBottom: "20px !important",
    marginLeft: "20px !important"
  },
  stateSelectedLabel: {
    marginBottom: "20px !important",
    backgroundImage: "linear-gradient(152deg,#00686f -8%,rgba(0,104,111,0.0001) 55%,rgba(0,104,111,0) 100%),linear-gradient(255deg,#a7cc64 14%,#35b4c2 57%,#26ceff 107%)",
    color: "#ffffff !important",
    marginLeft: "20px !important"
  },
  productHeaderLabel: {
    fontSize: "24px !important",
    paddingTop: "5px !important",
    paddingBottom: "5px !important",
    paddingLeft: "20px !important"
  },
  yesSpecification: {
    width: "650px !important",
    marginLeft: "23px !important",
    marginTop: "20px !important"
  }

});


const StatelessCableProduct = (props) => {

  const classes = useStyles();

  const [cableProducts, setCableProducts] = useState(["PIK", "SIK"]);
  const [selectedCable, setSelectedCable] = useState("");
  
  const [cablePikSpecificData, setCablePikSpecificData] = useState("");

  const [cableSikSpecificData, setCableSikSpecificData] = useState("");
  const [labelText,setLableText] = useState("");

  const clearSubProducts = () => {
    setCablePikSpecificData("");setCableSikSpecificData("");
  }

  useEffect(() => {
    clearSubProducts();
  }, [props.clearCable]);

  
  const setSpecReqData = (product, value) => {
    let key = "";
    if(product.toUpperCase() === "PIK")
    {
      key = "CUSTOM: VACANT CABLE PIK DATA";
    }
    else{
      if(product.toUpperCase() === "SIK")
      {
        key = "CUSTOM: VACANT CABLE SIK DATA"
      }
    }
    setLableText(key)
    props.setSpecificRequirement({ [key]: value }, "");
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Typography classes={{ root: classes.productHeaderLabel }}>Please select the Cable Product</Typography>
          {cableProducts.map((cable, index) => {
            return (
              <Button variant="contained" classes={cable !== selectedCable ? { root: classes.stateLabel } : { root: classes.stateSelectedLabel }}
                sx={{ minWidth: 150, minHeight: 150 }} value={cable} onClick={e => 
                { setSelectedCable(e.target.value); clearSubProducts();
                  setSpecReqData(e.target.value,"") }}>
                {cable}
              </Button>
            )
          })}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={1} />
        {selectedCable === "PIK" ?
          <Grid item xs={10}>
            <TextField
            id="CABLE-PIK-SPECIFIC-DATA"
            multiline
            rows={4}
            helperText="Enter the specific requirement"
            label={labelText}
            classes={{ root: classes.yesSpecification }}
            value={cablePikSpecificData}
            onChange={e => {setCablePikSpecificData(e.target.value);
              setSpecReqData("PIK",e.target.value)}}
          /> 
          </Grid> :
          <>
            {selectedCable === "SIK" ?
              <Grid item xs={10}>
                <TextField
            id="CABLE-SIK-SPECIFIC-DATA"
            multiline
            rows={4}
            helperText="Enter the specific requirement"
            label={labelText}
            classes={{ root: classes.yesSpecification }}
            value={cableSikSpecificData}
            onChange={e => {setCableSikSpecificData(e.target.value);
              setSpecReqData("SIK",e.target.value)}}
          />
              </Grid> :
              <>

              </>}
          </>}
      </Grid>
     </>
  );
}


const mapDispatchToProps = (dispatch) => {
  return {
    setStatelessSimType: (value) => dispatch(setStatelessSimType(value)),
    prodData: (value) => dispatch(prodData(value)),
    setSpecificRequirement: (value,sID) => dispatch(setSpecificRequirement(value,sID))
  }
}

export default connect(null, mapDispatchToProps)(StatelessCableProduct);