import * as types from '../actions/actionTypes';

const INITIAL_STATE = {
    items: {},
    users: {},
    public: [],
    usersCount: {},
    checkoutData: {
        totalCheckoutCount: null,
        checkoutCountByServiceId: {},
        checkoutCountByChannelType:{},
        jiraIssues:{},
        usersList:[],
        utilityList:[],
        statsLoading:false
    }
}

export default(state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOAD_STATS_ITEMS_SUCCESS:
            return {
                ...state,
                items: action.payload
            }
        case types.LOAD_STATS_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload
            }
        case types.LOAD_STATS_GLOBAL_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case types.LOAD_STATS_PUBLIC_SUCCESS:
            return {
                ...state,
                public: action.payload
            }
        case types.LOAD_STATS_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        case types.LOAD_STATS_HEALTH_SUCCESS:
            return {
                ...state,
                health: action.payload
            }
        case types.LOAD_USERS_COUNT_SUCCESS : 
            return {
                ...state,
                usersCount: action.payload

            }
        case types.LOAD_USERS_COUNT_LOADING :
            return{
                ...state,
                usersCount: null
            }
        case types.LOAD_CHEKCOUT_COUNT_SUCCESS :
            return {
                ...state,
                checkoutData: {
                    checkoutCountByServiceId:action.payload.checkoutCountByServiceIdDto,
                    checkoutCountByChannelType:action.payload.checkoutCountByChannelTypeDto,
                    jiraIssues:action.payload.issues,
                    usersList:action.payload.usersList,
                    utilityList:action.payload.transactionDetailsList,
                    statsLoading:false
                }
            }
            case types.LOAD_CHEKCOUT_COUNT_LOADING :
                return {
                    ...state,
                    checkoutData: {
                        checkoutCountByServiceId:[],
                        checkoutCountByChannelType:[],
                        jiraIssues:[],
                        usersList:[],
                        utilityList:[],
                        statsLoading:true
                    }
                }
            case types.LOAD_CHEKCOUT_COUNT_FAIL :
                return {
                    ...state,
                    checkoutData: {
                        checkoutCountByServiceId:[],
                        checkoutCountByChannelType:[],
                        jiraIssues:[],
                        usersList:[],
                        utilityList:[],
                        statsLoading:false
                    }
                }
        default:
            return state;
    }
}