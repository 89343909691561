import React, {Component} from 'react';

import {connect} from 'react-redux';
import {SubHeader} from '../common/custom/SubHeader';

import { Button } from 'reactstrap';

import { addProductsToBkt } from '../../actions/dataActions';

class BasketEditor extends Component {

    constructor(props) {
      super(props);
      this.state = {added: false, adding: false}
    }

    addProducts = async () => {
      this.setState({adding: true});
      const {Id} = this.props.basket;
      const result = await this.props.addProducts(Id);
      this.setState({added: result, adding: false});
    }

    render() {
        if (!this.props.basket) return null;
        const {Id} = this.props.basket;
        return (
            <div className="white">
                <SubHeader
                    text={"Basket Editor"}
                    subComponent={"ID: " + Id}
                    iconClass={"fa fa-shopping-basket"}
                    iconColor="turquoise"/>
                <Button disabled={this.state.added || this.state.adding} onClick={this.addProducts} color={this.state.added ? "success":"info"}>
                {this.state.added && <span>Products added</span>}
                {this.state.adding && <span>Adding Products</span>}
                {(!this.state.adding && !this.state.added) && <span>Add CWP Products</span>}
                </Button>
            </div>
        )
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addProducts: (id) => dispatch(addProductsToBkt(id))
  }
}

const mapStateToProps = (state, ownProps) => {
    const { basket, products } = state.basket.editor;
    return { basket, products }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketEditor);
