import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';
import {fetchRcrFile} from "../../../actions/utilityActions";
import './rcrStatus.css';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
        borderBottom: 'unset',
        },
    },
});

const RcrStatusResultTable = (props) => {
  let uniqueRequestIdArr = [...new Map(props.status.map((item) => [item["stepDetials"], item])).values(),];
  uniqueRequestIdArr.sort(function(a, b){
    return a.requestId - b.requestId;
  })

  const [rcrStatusResponse, setRcrStatusResponse] = React.useState(props.status);
  React.useEffect(()=>{
      setRcrStatusResponse(props.status);
    }, [rcrStatusResponse]
  )
  function Row(props){
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    return (
    <React.Fragment>
    <TableRow className={classes.root}>
      <TableCell>
        <IconButton style={{"outline":"none"}} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" > <b>Order ID :</b> {row.orderId} </TableCell>
      <TableCell align="center"> {row.rcrReqDataStatus} </TableCell>
    </TableRow>
    <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                  <TableCell>Step Number</TableCell>
                    <TableCell>Step Name</TableCell>
                    <TableCell>Step Status</TableCell>
                    <TableCell>Error Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {
                  row.stepDetials.map((row2)=>(
                    <TableRow>
                      <TableCell>{row2.step}</TableCell>
                      <TableCell>{row2.stepName}</TableCell>
                      <TableCell >{row2.status}</TableCell>
                      <TableCell style={{width:470}}>{row2.errorMessage || ""}</TableCell>
                    </TableRow>
                  ))
                }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
    </TableRow>
    </React.Fragment>);
  }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableBody>
                    {
                     props.status !== undefined &&   props.status && props.status.constructor === Array && props.status.length > 0 ? uniqueRequestIdArr.map((row) => (
                        <Row key={row.orderId} row={row} />
                      )) : null
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchRcrFile : (requestId) => dispatch(fetchRcrFile(requestId)),
    };
};
export default connect(null, mapDispatchToProps) (RcrStatusResultTable);
