import React from "react";
import { Field } from "redux-form";
import { renderField } from "../common/fields/fields";

class SomIndividualInput extends React.Component {
  state = {
    autoGeneratedRows: [],
    inputInvalid: true,
  };
  handleAddColumn = (fields, index, onRemove) => {
    if (index != undefined) {
      fields.splice(index, 1)
      if (onRemove) {
        onRemove(index);
      }
    } else {
      fields.push({ input: null });
    }
  }

  validateResource = (value, allValues, props, name) => {
    let resp = null;
    if (this.props.resourceValidator) {
      resp = this.props.resourceValidator(value, allValues, props, name);
    }
    (allValues.resources[0].input !== '' && resp === undefined) ?
    this.setState(prevState => ({...prevState, inputInvalid: false})) :
    this.setState(prevState => ({...prevState, inputInvalid: true}));
    return resp;
  }

  render() {
    const { fields, primaryButton, secondaryButton, handlePrimary, disablePrevRows, onRemove, onSearch } = this.props;
    return (
      <div id="unms-range">
        <div className="row" id="unms-range-header">
          <div className="col-3">
            <span>Resource</span>
          </div>
        </div>
        {fields.map((range, index) => {
          return (
            <div key={index} className="row">
              <div className="col-3 cleanup-text-margin">
                <Field
                  name={`resources[${index}].input`}
                  tabIndex={index}
                  component={renderField}
                  validate={this.validateResource}
                  invalid={this.props.errorMessage}
                  type={"text"}
                  showValidMsg={false}
                  touchOnChange={true}
                  autoComplete="off"
                  disabled={disablePrevRows ? (fields.length === 0 || index+1 < fields.length) : false }
                />
              </div>
              {secondaryButton &&
                <div className="unms-button-area">
                  {fields.length !== 1 && index !== fields.length-1
                    && <button
                      type="button"
                      className="btn btn-warning unms-button"
                      onClick={() => this.handleAddColumn(fields, index, onRemove)}>
                      {secondaryButton}
                    </button>}</div>}
              {primaryButton
                && <div className="unms-button-area">
                  {index === fields.length - 1
                    && <button
                      type="button"
                      className="btn btn-primary unms-button"
                      onClick={()=>{handlePrimary(); this.handleAddColumn(fields, undefined, onRemove);}}
                      disabled={this.state.inputInvalid}
                      >
                      {primaryButton}
                    </button>}</div>}

            </div>
          );
        })}
          <div className="unms-button-area">
            <button
              type="button"
              className="btn btn-primary unms-button"
              onClick={()=>onSearch()}
              disabled={!fields || (fields && fields.length === 1)}
            >
              {"Search"}
            </button>
          </div>
      </div>
    );
  }
}


export default SomIndividualInput;
