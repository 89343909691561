import React from 'react';
import {
    Typography,
    Card,
    CardContent,
    CardActionArea,
    Tooltip,
    styled,
    makeStyles,
    Grid,
    Box,
} from "@material-ui/core/";
import StarIcon from '@material-ui/icons/Star';
import RemoveIcon from '@material-ui/icons/Remove';

const CustomCard = styled(Card)({
    borderRadius: ".65rem",
    boxSizing: "border-box",
    height: "90px",
    "&:hover":{
        backgroundColor: "transparent",
    },
    "&:focus": {
        outline: "none",
    }
});

const useStyles = makeStyles((theme) => (
    {
        cardDecorations: {
            borderTop: `3px solid ${theme.palette.primary.main}`,
        }, 
        removeSection: {
            "&:focus": {
                outline: "none",
            },
            height: "100%", 
            // backgroundColor: "#f50057", 
            display: "flex", 
            alignItems: "center"
        },
        onClickSection: {
            "&:focus": {
                outline: "none",
            },
            height: "100%",
        }
    }
));

const WishlistCard = (props) => {
    const classes = useStyles();
    const {
        cardId,
        name,
        onClickCard = ()=>{},
        removeFunc = ()=>{},
        removeTooltip,
    } = props;

    return (
        <CustomCard className={classes.cardDecorations} elevation={6}>
            <Grid container direction="row" style={{height: "100%"}}>
                <Grid item xs={10}>
                    <CardActionArea onClick={onClickCard} className={classes.onClickSection}>
                        <CardContent style={{height: "100%"}}>
                            <Grid container direction="row" style={{height: "100%"}}>
                                <Grid item xs={12} style={{"display":"flex","alignItems":"center","height":"100%"}}>
                                    <Box
                                        height="100%"
                                        paddingRight="15px"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <StarIcon style={{ fontSize: '3rem', color: "#223c7f", paddingBottom: '5px'}}/>
                                    </Box>
                                    <Typography variant="body1" style={{"fontWeight":"bold"}}>{name}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Grid>
                <Grid item xs={2} style={{height: "100%"}}>
                    <Tooltip title={removeTooltip ? removeTooltip : "Remove"} arrow placement='left'>
                        <CardActionArea onClick={removeFunc} className={classes.removeSection}>
                            <RemoveIcon fontSize='large' color='secondary'/>
                        </CardActionArea>
                    </Tooltip>
                </Grid>
            </Grid>
        </CustomCard>
    );
}

export default WishlistCard;
