export {
    customerFormFieldTypes,
    contactFormFieldTypes,
    orderFormFieldTypes,
    serviceFormFieldTypes,
    customerLiterals,
    contactLiterals,
    orderLiterals,
    serviceLiterals,
    searchRequestSizeLimit,
    dateLiterals,
    orderItemLiterals
};

const dateLiterals = {
    dateOptions: [
        'All',
        'YESTERDAY',
        'TODAY',
        'LAST_WEEK',
        'THIS_WEEK',
        'LAST_MONTH',
        'THIS_MONTH',
        'LAST_N_DAYS'
    ],
    queryDateOptions: (props) => ({
        YESTERDAY: 'yesterday',
        TODAY: 'today',
        LAST_WEEK: 'last week',
        THIS_WEEK: 'this week',
        LAST_MONTH: 'last month',
        THIS_MONTH: 'this month',
        LAST_N_DAYS: `in the last ${props} days`
    })
}

const customerLiterals = {
    entityName: 'Account'
};

const orderItemLiterals = {
    entityName: 'OrderItem'
};
const contactLiterals = {
    isActive: ['All','true','false'],
    entityName: 'Contact',
    addlEntityName: 'AccountContactRelation'
};

const orderLiterals = {
    orderEntityName: 'order',
};

const serviceLiterals = {
    productEntityName: 'Product2',
}

const customerFormFieldTypes = {
    Type: {id: 'Type', label: 'Type', format: 'SingleQuote'},
    vlocity_cmt__Status__c: {id: 'vlocity_cmt__Status__c', label: 'Status', format: 'SingleQuote'},
    CreatedDate: {id: 'CreatedDate', label: 'CreatedDate', format: 'bare'},
};
const contactFormFieldTypes = {
    PreferredCommunicationMethod__c: {id: 'PreferredCommunicationMethod__c', label: 'CommunicationMethod', format: 'SingleQuote'},
    IsActive: {id: 'IsActive', label: 'AccountContactActive', format: 'bare'},
    Title: {id: 'Title', label: 'Title', format: 'SingleQuote'},
    Role__c: {id: 'Role__c', label: 'Role', format: 'SingleQuote'},
    vlocity_cmt__Status__c: {id: 'vlocity_cmt__Status__c', label: 'Status', format: 'SingleQuote'},
    CreatedDate: {id: 'CreatedDate', label: 'CreatedDate', format: 'bare'},
};
const orderFormFieldTypes = {
    Status: {id: 'Status', label: 'Order Status', format: 'SingleQuote'},
    OrderStatus: {id: 'vlocity_cmt__OrderStatus__c', label: 'Order Status', format: 'SingleQuote'},
    CreatedDate: {id: 'CreatedDate', label: 'CreatedDate', format: 'bare'},
    Type: {id: 'Type', label: 'Order Type', format: 'SingleQuote'},
    OrderReason__c: {id: 'OrderReason__c', label: 'OrderReason', format: 'SingleQuote', childFields: ['OrderReasonType__c']},
    OrderReasonType__c: {id: 'OrderReasonType__c', label: 'OrderReasonType', format: 'SingleQuote'},
};

const serviceFormFieldTypes = {
    Family: {id: 'Family', label: 'Family', format: 'SingleQuote', childFields: ['Name', 'Product_Type__c']},
    Product_Type__c: {id: 'Product_Type__c', label: 'Product Type', format: 'SingleQuote', childFields: ['Name']},
    Name: {id: 'Name', label: 'Product Sub Type', format: 'SingleQuote'},
    TLS_PriorityAssist: {id: 'Order.Account.TLS_PriorityAssist__c', label: 'Priority Assist', format: 'SingleQuote'},
    TLS_ChargeModel: {id: 'OrderItem.TLS_ChargeModel__c', label: 'Charge Cycle', format: 'SingleQuote'},
}

const searchRequestSizeLimit = 2000;