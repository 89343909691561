import _ from 'lodash';
import * as types from '../actions/actionTypes';

const INITIAL_STATE={
    feedbackHistory: [],
    isLoading: false,
    error: false,
    savingPending: false
}


export default (state=INITIAL_STATE,action)=>{
    switch(action.type){
        case "ADD_FEEDBACK":
        console.log("feedback add in reducer : ", action.payload);
            return state.feedbackHistory.concat(action.payload);
        case types.LOAD_FEEDBACK_PENDING :
            return {...state,}
        case types.LOAD_FEEDBACK :
            return { ...state,feedbackHistory: (action.payload), isLoading:false, error: false };
        case types.LOAD_FEEDBACK_FAILED :
            return {...state, error:true, isLoading:false};
        case types.SAVING_PENDING :
            return {...state, savingPending: true};
        case types.SAVING_SUCCESS :
            return { ...state, savingPending: false }
        default :
            return state; 
    }
}