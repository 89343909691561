import React, { Component } from 'react'
import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #4a00b3 0, #4c00b3 100%)'
}

const CustomDataTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-green"
        data-size="medium"
        to="/dataset/retrieve"
        data-effect="hover-zoom-up">
        <div class="slide-front">
            <span className="fa fa-database icon"></span>
            <span className="branding-bar">Bring Your Own Data</span>
        </div>
        <div
            class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
            <p class="text-center">
            View Dataset Summary
            </p>
        </div>
    </LinkTile>
)

CustomDataTile.propTypes = {}

export default CustomDataTile;