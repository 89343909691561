import React, { Component } from 'react'

const fillNa = (objectValue) => {
    if (!objectValue)
        return "NA";
    return objectValue;
}

export default (props) => {
    const { item, classFilter } = props;
    return (
        <tr>
            <th scope="row">{item.mobileId}</th>
            <td>{classFilter}</td>
            <td>{fillNa(item.msisdn)}</td>
            <td>{fillNa(item.imsi)}</td>
            <td>{fillNa(item.sim)} </td>
        </tr>
    );
};
