import React, { Component } from 'react'
import LinkTile from "../custom/LinkTile";
import PropTypes from 'prop-types';

const tileStyle = {
    background: 'linear-gradient(to right, #13a129 0, #1d5f27 100%)'
}

const CustomCheckoutHistoryTile = (props) => (
    <LinkTile
        style={tileStyle}
        className="bg-green"
        data-size="medium"
        to="/dataset/checkoutHistory"
        data-effect="hover-zoom-up">
        <div class="slide-front">
            <span className="fa fa-history icon"></span>
            <span className="branding-bar">Bring Your Own Data Checkout History</span>
        </div>
        <div
            class="slide-back d-flex flex-justify-center flex-align-center p-4 op-blue">
            <p class="text-center">
            Bring Your Own Data Checkout History
            </p>
        </div>
    </LinkTile>
)

CustomCheckoutHistoryTile.propTypes = {}

export default CustomCheckoutHistoryTile;