import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';
import { fetchCheckoutHistory, fetchCheckoutHistoryWithFilters, fetchCheckoutFilterByFieldAndValue } from '../../actions/dataActions';
import PaginationController from '../common/custom/PaginationController';
import LinkButton from "../common/custom/LinkButton";


import HeaderContent from '../common/Header/HeaderContent';
import { CheckoutHistoryTable } from './CheckoutHistoryTable';
import { setLocation } from "../../actions/userFeedbackActions";

class CheckoutHistory extends Component {

    constructor(props) {
        super(props)
        this.state = { filters: this.filters }
    }

    componentDidMount() {
        this.gotoPage();
        this.props.setLocation("CheckoutHistory")
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.fdata !== prevProps.fdata) {
            let filters = prevState.filters.map(filter => {
                if (filter.options !== this.props.fdata[filter.field])
                    filter.options = this.props.fdata[filter.field] || filter.options
                return filter;
            })
            this.setState({ filters: filters })
        }

    }

    gotoPage = async (pageNum = 0, filters = null) => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        // Always prefer incoming filters over state filters
        let appliedFilters = filters !== null ? filters : this.props.factive;
        await this
            .props
            .fetchHistoryWithFilter(pageNum, appliedFilters);
    }

    linkToDataBank = ()=>(<LinkButton
    style={{margin:"0em 2em 2em 0em"}}
        to="/tdm/checkout/history"
        className="btn btn-primary"
        type="button"
        role="button"
        disabled={false}>
        DataBank Checkout History
      </LinkButton>);
    filters = [
        { field: "id", type: "autocomplete", onChange: this.props.fetchFilterOptions, onSelect: this.gotoPage, options: [] },
        { field: "envFilter", type: "dropdown", onChange: this.props.fetchFilterOptions, onSelect: this.gotoPage, options: [] },
        { field: "checkoutType", type: "dropdown", onChange: this.props.fetchFilterOptions, onSelect: this.gotoPage, options: [] },
        { field: "dataFilter", type: "autocomplete", onChange: this.props.fetchFilterOptions, onSelect: this.gotoPage, options: [] },
        { field: "techFilter", type: "autocomplete", onChange: this.props.fetchFilterOptions, onSelect: this.gotoPage, options: [] },
        { field: "qtyRequested", type: "autocomplete", onChange: this.props.fetchFilterOptions, onSelect: this.gotoPage, options: [] },
        { field: "usedDate", type: "date", onChange: this.props.fetchFilterOptions, onSelect: this.gotoPage, options: [] },
        { field: "success", type: "dropdown", onSelect: this.gotoPage, options: ["true", "false"] }
    ]

    render() {
        const { loading, tableData } = this.props;

        const bindLoadingToFilter = (filters, loadingObjects) => {
            return filters.map((filter) => ({ ...filter, isLoading: loadingObjects[filter.field] || false }))
        }

        return (

            <div className="white">
                <HeaderContent
                    title="Checkout History"
                    icon="history"
                    loading={loading}
                    empty={(tableData && tableData.length == 0)}
                    loadingText="Loading your checkout history.." 
                    extraComponent={this.linkToDataBank}/>
                <CheckoutHistoryTable tableData={tableData} detailView={true} filters={bindLoadingToFilter(this.state.filters, this.props.floading)} />
                <PaginationController
                    isFirst={this.props.isFirst}
                    isLast={this.props.isLast}
                    currentPage={this.props.currentPage}
                    totalPages={this.props.totalPages}
                    gotoPage={this.gotoPage}/>
            </div >
        )
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        tableData: state.checkout.history.content || [],
        ...state.checkout.history,
        ...state.checkout.filter
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchHistory: (pagenum) => dispatch(fetchCheckoutHistory(pagenum)),
        fetchHistoryWithFilter: (pagenum, filters) => dispatch(fetchCheckoutHistoryWithFilters(pagenum, filters)),
        fetchFilterOptions: (field, filter) => dispatch(fetchCheckoutFilterByFieldAndValue(field, filter)),
        setLocation:(path) => dispatch(setLocation(path))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutHistory);
