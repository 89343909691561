import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "@library/core/surfaces/Header";
import { ActionStepper } from "@library/core/form-elements/";
import CreateSyntheticDatasetStep from './CreateSyntheticDatasetStep';
import CustomiseSyntheticStep from './CustomiseSyntheticStep';
import ReviewStep from './ReviewStep';
import SnackbarAlert from "@library/core/alerts/SnackbarAlert";
import { CartIconBtn } from "@library/core/buttons";
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { sendSyntheticOrder, clearSyntheticTypeData, clearDataFromCart, createSyntheticDataset, resetSyntheticDataOrder, resetSyntheticDataset, setSyntheticDatasetToEdit } from '../../actions/syntheticDataActions';

const GenerateSyntheticData = (props) => {
    const stepList = ["Create Dataset", "Customise Order", "Review & Submit"];

    const [reset, setReset] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const [displayAlert, setDisplayAlert] = useState(false);
    const [displayAlertActionBtn, setDisplayAlertActionBtn] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertType, setAlertType] = useState("info");
    const [alertLoading, setAlertLoading] = useState(false);
    const [alertAutoHide, setAlertAutoHide] = useState(10000);

    const setFirstLoadVal = (bool) => {
        setFirstLoad(bool);
    }

    const setAlertInfo = (type, msg, loading, hideDuration) => {
        setAlertMsg(msg);
        setAlertType(type);
        setAlertLoading(loading);
        setAlertAutoHide(hideDuration);
    }

    const onCartClick = () => {
        setReset(!reset);
    }

    useEffect(() => {
        if (props.syntheticDatasetPending) {
            setAlertInfo("info", "Creating Synthetic Dataset please wait...", true, null);
            setDisplayAlert(true);
        } else {
            setDisplayAlert(false);
        }
      }, [props.syntheticDatasetPending]);

    useEffect(() => {
        if (props.syntheticDatasetFailed) {
            setAlertInfo("error", props.syntheticDatasetFailedMsg, false, null);
            setDisplayAlert(true);
        } else {
            setDisplayAlert(false);
        }
    }, [props.syntheticDatasetFailed]);

    useEffect(() => {
        if (props.syntheticDatasetSuccess) {
            setAlertInfo("success", "Successfully Created Dataset", false, null);
            setDisplayAlert(true);
        } else {
            setDisplayAlert(false);
        }
    }, [props.syntheticDatasetSuccess]);

    useEffect(() => {
        props.resetSyntheticDataset();
        props.resetSyntheticDataOrder();
        resetSyntheticForms();
        setReset(!reset);
        setFirstLoad(true);
    },[]);

    const validateCreateDatasetStep = async () => {
        const { dataSetName, dataSetType, description, env, scope, team, teamId } = props.syntheticDataTypeInfo;
        let valid = !!(dataSetName && dataSetType && description && env.label && scope && (scope === "PRIVATE" || team && teamId))
        if (!valid) {
            setDisplayAlert(true);
            setAlertInfo("warning", "Please make sure all fields are filled correctly", false, 10000);
        }
        return valid;
    }

    const validateCustomiseStep = () => { 
        const valid = Object.keys(props.syntheticDataCart).length > 0 && props.syntheticDataCartQty > 0;
        if (!valid) {
            setDisplayAlert(true);
            setAlertInfo("warning", "Need at least 1 item in Cart and Quantity greater than 0 to continue!", false, 10000);
        }
        return valid;
    }

    const formatCartItemsForPayload = () => {
        let cartItems = [];
        for (const [key, value] of Object.entries(props.syntheticDataCart)) {
            let obj= {
                "dataType": key.toUpperCase(),
                "inclusions": Object.entries(value).map(v => {
                    if (v[1]) {
                        return v[0].toUpperCase().replace(/\s/g,'');
                    }
                }).filter(n=>n),
                "quantity": props.syntheticDataCartQty
            }
            if(key==="Billing Preferences"){
                obj['creditCardType']=props.creditCardType
            }
            cartItems.push(obj);
        }
        return cartItems;
    }

    const submitSyntheticOrder = () => {
        setAlertInfo("info", "Submitting your order please wait...", true, 10000);
        setDisplayAlert(true);
        
        const payload = {
            cart: {
                cartItems: formatCartItemsForPayload()
            }
        }
        if (props.syntheticDatasetToEdit?.team?.id) {
            payload.team = props.syntheticDatasetToEdit.team;
        }
        let id = "";
        if (props.syntheticDataset?.id) {
            id = props.syntheticDataset.id;
        } else if (props.syntheticDatasetToEdit?.id) {
            id = props.syntheticDatasetToEdit.id;
        }
        props.sendSyntheticOrder(payload, id).then((response) => {
            if (response.success) {
                resetSyntheticForms();
                props.resetSyntheticDataset();
                props.resetSyntheticDataOrder();
                props.setSyntheticDatasetToEdit({});
                setReset(true);
                setAlertInfo("success", "Successfully Generated data for dataset!", false, null);
                setDisplayAlertActionBtn(true);
                setDisplayAlert(true);
            } else {
                setAlertInfo("error", "Failed to Generate data for dataset, please try again later", false, 15000);
                setDisplayAlertActionBtn(false);
                setDisplayAlert(true);
            }
        });
    }

    const resetSyntheticForms = () => {
        props.clearSyntheticTypeData();
        props.clearDataFromCart();
    }

    const redirectToDatasetsSummary = () => {
        setDisplayAlert(false);
        props.history.push({ pathname: '/dataset/synthetic' });
    }

    const createDataset = async () => {
        const { dataSetName, dataSetType, description, env, scope, team, teamId } = props.syntheticDataTypeInfo;
        const dataset = {
            name: dataSetName,
            description: description,
            environment: env.value,
            scope: scope,
            datasetType: "SYNTHETIC",
            typeOfData: dataSetType.toUpperCase(),
            team: {
              id: teamId,
              name: team,
            }
          };
        props.createSyntheticDataset(dataset);
    }

    const disableCreate = (
        props.syntheticDatasetSuccess || 
        (props.syntheticDatasetToEdit && props.syntheticDatasetToEdit.id && props.syntheticDatasetToEdit.id !== undefined)) ? true : false;
    const stepValidationList = [
        (props.syntheticDatasetToEdit && props.syntheticDatasetToEdit.id && props.syntheticDatasetToEdit.id !== undefined) ? ()=>true : validateCreateDatasetStep, 
        validateCustomiseStep
    ];
    const nextStepBtns = [{
        name: disableCreate ? "Next" : "Create dataset", 
        submitFunc: disableCreate ? null : createDataset
    }];

    return (
        <div className="white">
            <Header
                title={`Generate Synthetic Data`}
                underTitle={
                    (props.syntheticDataTypeInfo && props.syntheticDataTypeInfo.dataSetName && props.syntheticDatasetSuccess ||
                        props.syntheticDatasetToEdit && props.syntheticDatasetToEdit.id) && 
                    <Box padding={"10px 0px 0px 25px"}>
                        <Typography variant="h5" component={"h6"} style={{"fontSize":"1.2rem"}}>
                            <Box component="span" fontWeight={"bold"}>Active Dataset: </Box>
                            {props.syntheticDataTypeInfo.dataSetName || props.syntheticDatasetToEdit.name}
                        </Typography>
                    </Box>
                }
                topRightIcon={
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        spacing={4}
                    >
                        <Grid item>
                            <CartIconBtn onBtnClick={onCartClick} badgeCount={props.syntheticDataCart ? Object.keys(props.syntheticDataCart).length : 0} />
                        </Grid>
                        <Grid item>
                            <i style={{"marginTop":"6px"}} className={`fa fa-database training-icon fa-3x`}/>
                        </Grid>
                    </Grid>
                }
            />
            <ActionStepper
                steps={stepList}
                resetCallBack={onCartClick}
                resetSteps={reset}
                finalBtnText="Submit"
                submitFunction={submitSyntheticOrder}
                stepValidationList={stepValidationList}
                nextStepBtns={nextStepBtns}
                stepsComponents={[
                    <CreateSyntheticDatasetStep 
                        key={'typeStep'} 
                        firstLoad={firstLoad} 
                        setFirstLoad={setFirstLoadVal}
                    />,
                    <CustomiseSyntheticStep 
                        key={'customiseStep'}
                        firstLoad={firstLoad} 
                        setFirstLoad={setFirstLoadVal}
                    />,
                    <ReviewStep key={'reviewStep'}/>
                ]}
            />
            <SnackbarAlert
              isOpen={(alertMsg && displayAlert) ? true : false}
              severity={alertType}
              message={alertMsg}
              position={{ horizontal: 'center', vertical: 'bottom' }}
              autoCloseDuration={alertAutoHide}
              loading={alertLoading}
              handleClose={() => {
                setAlertMsg("info", "", false, 5000);
                setDisplayAlert(false);
              }}
              actions={
                alertType === "success" && displayAlertActionBtn ?
                  <>
                    <Button color="inherit" size="small" onClick={redirectToDatasetsSummary}>
                      View Datasets
                    </Button>
                  </> : <></>
              }
            />    
        </div>
    )
}
const mapStateToProps = (state) => {
  return {
    userName: state.auth.userName,
    environment: state.auth.selectedEnvValue,
    syntheticDataTypeInfo: state.syntheticData.syntheticDataTypeInfo,
    syntheticDataCart: state.syntheticData.syntheticDataCart,
    creditCardType:state.syntheticData.creditCardType,
    syntheticDataCartQty: state.syntheticData.syntheticDataCartQty,
    syntheticOrderSuccess: state.syntheticData.syntheticOrderSuccess,
    syntheticOrderPending: state.syntheticData.syntheticOrderPending,
    syntheticOrderFailed: state.syntheticData.syntheticOrderFailed,
    syntheticOrderFailedMsg: state.syntheticData.syntheticOrderFailedMsg,

    syntheticDataset: state.syntheticData.syntheticDataset,
    syntheticDatasetSuccess: state.syntheticData.syntheticDatasetSuccess,
    syntheticDatasetPending: state.syntheticData.syntheticDatasetPending,
    syntheticDatasetFailed: state.syntheticData.syntheticDatasetFailed,
    syntheticDatasetFailedMsg: state.syntheticData.syntheticDatasetFailedMsg,

    syntheticDatasetToEdit: state.syntheticData.syntheticDatasetToEdit
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendSyntheticOrder: (payload, id) => dispatch(sendSyntheticOrder(payload, id)),
    clearSyntheticTypeData: () => dispatch(clearSyntheticTypeData()),
    clearDataFromCart: () => dispatch(clearDataFromCart()),
    createSyntheticDataset: (payload) => dispatch(createSyntheticDataset(payload)),
    resetSyntheticDataset: () => dispatch(resetSyntheticDataset()),
    resetSyntheticDataOrder: () => dispatch(resetSyntheticDataOrder()),
    setSyntheticDatasetToEdit: (data) => dispatch(setSyntheticDatasetToEdit(data))    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateSyntheticData);
