import React from 'react';
import {Button } from 'reactstrap';
import { Field, change } from 'redux-form'
import { renderField } from '../../common/fields/fields';
import { connect } from "react-redux";
class CommentDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    const { fields } = this.props;
    if (!fields.length) fields.push( {commentsType: "DELAY_FLG",  typeOfFlag: "DELAY",  indicator:"Y",reason : "DELAY", 
    comment :"02/12/2018 08:46:47 PM | order missing_with raised to li,jhon ||  02/12/2020 08:46:47 PM | attribute_verified by inline manager ||",
    commentsUpdatedDate : new Date()});
  }
  
  handleSelectChange = (value, index) =>{
      const { fields } = this.props;
      console.log(this.props.fields)
      console.log(value)
      if(value === "DELAY_FLG"){
        this.props.resetCommentFields('orderCreateForm',`${fields.name}[${index}].typeOfFlag`,"DELAY")
        this.props.resetCommentFields('orderCreateForm',`${fields.name}[${index}].reason`,"DELAY")
      }
      else if(value === "REWORK_FLG"){
        this.props.resetCommentFields('orderCreateForm',`${fields.name}[${index}].typeOfFlag`,"REWORK")
        this.props.resetCommentFields('orderCreateForm',`${fields.name}[${index}].reason`,"REWORK")
      }else if(value === "JEOPARDY_FLG"){
        this.props.resetCommentFields('orderCreateForm',`${fields.name}[${index}].typeOfFlag`,"JEOPARDY")
        this.props.resetCommentFields('orderCreateForm',`${fields.name}[${index}].reason`,"JEOPARDY")
      }
  }
  render() {
    let {fields, meta: { touched, error, submitFailed }, change} = this.props;
    const commentsTypeOptions = [
        { label: "JEOPARDY_FLG", value: "JEOPARDY_FLG" },
        { label: "DELAY_FLG", value: "DELAY_FLG" },
        { label: "REWORK_FLG", value: "REWORK_FLG" }
    ];
    return (
      <ul style={{background:"antiquewhite"}}>
        {fields.map((comment,index) => (
              <li key={index}>
                      <div className="row">
                          <div className="col-sm-6" style={{fontWeight : "bold"}}>Comment - {index+1}</div>
                          <div className="col-sm-6" style={{textAlign : "right"}}><Button color="danger" title="Remove Comment"
                            onClick={() => fields.remove(index)}><i className="fa fa-minus-circle fa-sm" alt="Remove" aria-hidden="true"></i></Button></div>
                          <div className="col-sm-4">
                            <Field
                                name={`${comment}.commentsType`} 
                                label="Comments Type"
                                type="selectWithCustomHeight"
                                autoComplete="off"
                                component={renderField}       
                                options={commentsTypeOptions}
                                onChange={(value) => this.handleSelectChange(value, index)}
                            />
                          </div>
                          <div className="col-sm-4">
                              <Field
                                  name={`${comment}.typeOfFlag`} 
                                  label="Type of Flag"
                                  type="text"
                                  autoComplete="off"
                                  component={renderField}   
                              /> </div>
                          <div className="col-sm-4">
                              <Field
                                  name={`${comment}.indicator`} 
                                  label="Flag Indicator"
                                  type="text"
                                  autoComplete="off"
                                  component={renderField}   
                              /></div>
                          <div className="col-sm-4">
                              <Field
                                  name={`${comment}.reason`} 
                                  label="Reason"
                                  type="text"
                                  autoComplete="off"
                                  component={renderField}   
                              /></div>
                          <div className="col-sm-4">
                              <Field
                                  name={`${comment}.comment`} 
                                  label="Comment"
                                  type="Text"
                                  autoComplete="off"
                                  component={renderField}   
                              /></div>
                          <div className="col-sm-4">
                              <Field
                                  name={`${comment}.commentsUpdatedDate`} 
                                  label="Update Date"
                                  type="date"
                                  autoComplete="off"
                                  component={renderField}   
                              />  </div> 
                        </div>           
              </li>
          ))}
           <li style={{marginBottom : "10px"}}>
               <Button color="primary" onClick={() => fields.push(
                 {commentsType: "DELAY_FLG",  typeOfFlag: "DELAY",  indicator:"Y",reason : "DELAY", 
                 comment :"02/12/2018 08:46:47 PM | order missing_with raised to li,jhon ||  02/12/2020 08:46:47 PM | attribute_verified by inline manager ||",
                 commentsUpdatedDate : new Date()}
               )}>
                 Add Comments
               </Button>
               {(touched || submitFailed) && error && <span>{error}</span>}
            </li>
     </ul>
    );
  }
}

const mapDispatchToProps = dispatch => {
    return {
      resetCommentFields: (formName, fieldName, value) => {
        dispatch(change(formName, fieldName, value));
      }
    };
  };
  
  export default connect(null, mapDispatchToProps)(CommentDetailsComponent);