import React, { Component } from 'react'
import { connect } from 'react-redux';

import './Data.css';
import {
    selectFilterDataType,
    selectFilterClassType,
    selectFilterEnvType,
    selectFilterQtyType,
    resetSelectedFilters,
    selectFilterProductType,
    selectFilterResourceType,
    selectFilterForeignCarrierType,
    checkoutWithFilter
} from '../../actions/dataActions';
import { DataStepper } from './DataStepper';
import swal from 'sweetalert2';


import DataTilesFrame from './accordion-frames/DataTilesFrame';
import CheckoutFrame from './accordion-frames/CheckoutFrame';
import { isNullOrUndefined, isNull } from 'util';

class Data extends Component {
    constructor(props) {
        super(props);
        this.state = {
            envHover: false,
            dataHover: false,
            classHover: false,
            qtyHover: false,
            hoverActive: false
        };

        // have to get references to accordion headings, need to simulate click event,
        // Metro uses its own JS library to control the accordion...
        this.dataTypeFrameHeading = React.createRef();
        this.classTypeFrameHeading = React.createRef();
        this.qtyTypeFrameHeading = React.createRef();
        this.checkoutTypeFrameHeading = React.createRef();
        this.productTypeFrameHeading = React.createRef();

        this.accordionEnd = React.createRef();
    }

    componentDidMount() {
        document.title = "TDM Portal - Download Data"
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    componentWillMount() {
        this
            .props
            .resetFilters();
    }

    scrollToBottom = () => {
        // this
        //     .accordionEnd
        //     .current
        //     .scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            var scrollEl = document.getElementById("main-content");
            window.scrollTo({ top: scrollEl.scrollHeight + 1080, behavior: "smooth" })
        }, 100);
    }

    nbnCheckout = () => {
        const { selectedDataTypes, selectedEnvTypes, selectedClassTypes, selectedQtyTypes } = this.props;

        swal({
            title: 'Checkout FNNs',
            text: `This will give you ${selectedQtyTypes} FNN records from ${selectedEnvTypes}`,
            type: "question",
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showCancelButton: true
        }).then((result) => {
            console.log(result);
            if (result.value)
                this.otherCheckout(true);
            else if (result.dismiss === swal.DismissReason.cancel)
                this.otherCheckout(false);
        }
        )
    }

    otherCheckout = (fnns = false) => {
        const {
            selectedDataTypes,
            selectedEnvTypes,
            selectedClassTypes,
            selectedClassNames,
            selectedClassNameRequired,
            selectedQtyTypes,
            selectedProductTypes,
            selectedResourceType,
            selectedForeignCarrierType
        } = this.props;

        swal({
            title: 'Checkout Confirmation',
            html: `<b>Environment:</b> ${selectedEnvTypes}<br />
            <b>Data Type:</b> ${selectedDataTypes}<br />
            <b>Technology Type:</b> ${ (selectedDataTypes == "Mobile")
                    ? selectedProductTypes
                    : selectedClassTypes} ${selectedClassNames
                        ? ` (${selectedClassNames})`
                        : ''}<br />
            <b>Quantity:</b> ${selectedQtyTypes} Records`,
            type: 'info',
            showCancelButton: true,
            cancelButtonText: '<i class="fa fa-times" aria-hidden="true"></i> Cancel',
            confirmButtonColor: '#13a129',
            confirmButtonText: '<i class="fa fa-check" aria-hidden="true"></i> Checkout',
            reverseButtons: true,
            allowOutsideClick: false,
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return this
                    .props
                    .checkout(selectedEnvTypes, selectedDataTypes, selectedClassTypes, selectedClassNameRequired
                        ? selectedClassNames
                        : undefined, selectedQtyTypes, fnns, selectedProductTypes,selectedResourceType, selectedForeignCarrierType)
                    .then(result => {
                        if (result instanceof Error) {
                            const mailString = `ErrorResponse:    ${JSON.stringify(result.response.data)}`
                            // Request-Url :   ${requestUrl}`;
        
                            if (result.hasOwnProperty('response')) {
                                this.fireErrorModel(mailString);
                            }
                            else
                                swal.showValidationError(`Checkout failed, Timeout Error please try again`);
                        } else {
                            console.log("NAVIGATE TO ACTIVE DATA VIEW PAGE");
                            if(result.hasOwnProperty('checkoutId'))
                            {
                                if(result.responseStatus.toUpperCase() !== "SUCCESS")
                                {
                                    const mailString = `ErrorResponse:    ${JSON.stringify(result)}`
                                    //Request-Url :   ${requestUrl}`
                                    this.fireErrorModel(mailString);
                                }
                                else{
                                    this.props.history.push(`/tdm/checkout/history/${result.checkoutId}`)
                                }
                            }
                            else{
                                this
                                    .props
                                    .history
                                    .push(`/checkout/history/${result.id}`);
                            }
                        }
                    })
            }
        }).then((result) => {
            if (result.value) {
                swal("Checkout Complete", "You may use the test data", "success");
            }
        })
    }

    checkout = () => {
        const { selectedDataTypes } = this.props;
        if (selectedDataTypes == "NBN" || selectedDataTypes == "Transition")
            this.nbnCheckout();
        else
            this.otherCheckout();
    }
    
    selectDataType = async (obj) => {
        this
            .props
            .selectFilterDataType(obj.label);
        await this
            .props
            .selectFilterProductType(null);
        await this
            .props
            .selectFilterClassType(null);

        if (obj.label != "NBN" && obj.label != "Mobile" && obj.label != "Transition") {
            await this
                .props
                .selectFilterClassType("N/A");
        }
        await this
            .props
            .selectFilterQtyType(null);
    }

    selectClassType = (obj) => {
        this
            .props
            .selectFilterClassType(obj.classId, obj.name, obj.required);
        this.resetTilesByType("class")
    }
    
    selectForeignCarrierType = (obj) => {
        this.props.selectFilterForeignCarrierType(obj.label);
        this.resetTilesByType('foreignCarrier')
    }
    selectProductType = (obj) => {
        this
            .props
            .selectFilterProductType(obj.label);
        this.resetTilesByType("product")
    }

    selectResourceType = (obj) => {
        this.props.selectFilterResourceType(obj.label);
        this.resetTilesByType("resource");
    }

    selectEnvType = (obj) => {
        this
            .props
            .selectFilterEnvType(obj.label);
        this.resetTilesByType("env")

    }

    selectQtyType = (obj) => {
        this
            .props
            .selectFilterQtyType(obj.qty);
    }

    shouldDisplayQty = (selectedProductTypes) => {
        const {selectedClassTypes,selectedResourceType,selectedForeignCarrierType} = this.props;
        switch(selectedProductTypes){
            case "Postpaid":
                return !isNullOrUndefined(selectedResourceType);
            case "PortIn":
                return !isNullOrUndefined(selectedForeignCarrierType);
            case "Hybrid":
                return true;
            default:
                return !isNullOrUndefined(selectedClassTypes);
        }
    }
    resetTilesByType(type) {
        switch (type) {
            case "env":
                this.props.selectFilterDataType(null)
                this.props.selectFilterClassType(null)
                this.props.selectFilterResourceType(null)
                this.props.selectFilterProductType(null)
                this.props.selectFilterForeignCarrierType(null)   
                break;
            case "product":
                this.props.selectFilterClassType(null)
                this.props.selectFilterResourceType(null)
                this.props.selectFilterQtyType(null)
                this.props.selectFilterForeignCarrierType(null)   
                break;
            case "foreignCarrier":
                this.props.selectFilterClassType(null)
                this.props.selectFilterQtyType(null)
                break;
            case "class":
                this.props.selectFilterQtyType(null)
                break;
            case "resource":
                this.props.selectFilterClassType(null)
                this.props.selectFilterQtyType(null)
                break;
        }
    }

    render() {
        const {
            dataTypes,
            classTypes,
            envTypes,
            qtyTypes,
            productTypes,
            resourceTypes,
            foreignCarrierTypes,
            selectedDataTypes,
            selectedEnvTypes,
            selectedClassTypes,
            selectedClassNames,
            selectedQtyTypes,
            selectedProductTypes,
            selectedResourceType,
            selectedForeignCarrierType,
            activeStep,
            loading
        } = this.props;

        const envTypeLoading = (loading && !selectedEnvTypes);
        const dataTypeLoading = (loading && !envTypeLoading && !selectedDataTypes);
        const classTypeLoading = (loading && !dataTypeLoading && !envTypeLoading && !selectedClassTypes);
        console.log(selectedClassTypes);
        const displayResourceType = selectedProductTypes === "Postpaid";
        const displayForeignCarrierType = selectedProductTypes === "PortIn";
        const displayQty = this.shouldDisplayQty(selectedProductTypes);

        return (
            <div className="white" id="vacant-data-form-container">
                <span className="dataview-header">
                    <h1>Download Vacant Data</h1>
                    <i className="fa fa-cloud-download fa-3x" aria-hidden="true"></i>
                </span>
                <div
                    data-role="accordion"
                    data-one-frame="false"
                    data-material="true"
                    data-on-frame-before-close="return false;"
                    style={{
                        "paddingTop": "20px"
                    }}>
                    <DataTilesFrame
                        heading="Environment"
                        color="magenta"
                        data={envTypes}
                        select={this.selectEnvType}
                        selected={selectedEnvTypes} />
                    {(selectedEnvTypes) && <DataTilesFrame
                        heading="Product"
                        headingRef={this.dataTypeFrameHeading}
                        color="blue"
                        data={dataTypes}
                        select={this.selectDataType}
                        selected={selectedDataTypes} />}
                    {(selectedDataTypes != "Mobile" && selectedDataTypes && selectedClassTypes != "N/A") && <DataTilesFrame
                        heading="Technology"
                        headingRef={this.classTypeFrameHeading}
                        color="orange"
                        data={classTypes}
                        select={this.selectClassType}
                        selected={selectedClassTypes}
                        subSelected={selectedClassNames} />}
                    {(selectedDataTypes == "Mobile") && <DataTilesFrame
                        heading="Technology"
                        headingRef={this.productTypeFrameHeading}
                        color="orange"
                        data={productTypes}
                        select={this.selectProductType}
                        selected={selectedProductTypes}
                        subSelected={selectedClassNames} />}
                    {(displayForeignCarrierType) && <DataTilesFrame
                        heading="Foreign Carrier Type"
                        headingRef={this.foreignCarrierTypeFrameHeading}
                        color="orange"
                        data={foreignCarrierTypes}
                        select={this.selectForeignCarrierType}
                        selected={selectedForeignCarrierType}
                    />}
                    {(displayResourceType) && <DataTilesFrame
                        heading="Resource Type"
                        headingRef={this.resourceTypeFrameHeading}
                        color="orange"
                        data={resourceTypes}
                        select={this.selectResourceType}
                        selected={selectedResourceType}
                    />}
                    {(displayQty) && <DataTilesFrame
                        heading="Quantity"
                        headingRef={this.qtyTypeFrameHeading}
                        color="orange"
                        data={qtyTypes}
                        select={this.selectQtyType}
                        selected={selectedQtyTypes} />}
                    {(!isNullOrUndefined(selectedQtyTypes) && ((!isNullOrUndefined(selectedClassTypes) || !isNullOrUndefined(selectedProductTypes)))) && <CheckoutFrame
                        checkout={this.checkout}
                        headingRef={this.checkoutTypeFrameHeading} />}
                    {/* <div style={{ width: "100%", height: "20px", paddingTop: "5px"}} ref={this.accordionEnd} /> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        dataTypes: selectedDataTypes,
        classTypes: selectedClassTypes,
        classNames: selectedClassNames,
        classRequired: selectedClassNameRequired,
        envTypes: selectedEnvTypes,
        qtyTypes: selectedQtyTypes,
        productTypes: selectedProductTypes,
        resourceType: selectedResourceType,
        foreignCarrierType: selectedForeignCarrierType
    } = state.data.selectedFilters;
    const {
        dataTypes,
        classTypes,
        envTypes,
        qtyTypes,
        loading,
        productTypes,
        resourceTypes,
        foreignCarrierTypes
    } = state.data.filters;
    const { items } = state.data.view;

    return {
        dataTypes,
        classTypes,
        envTypes,
        qtyTypes,
        productTypes,
        resourceTypes,
        foreignCarrierTypes,
        loading,
        activeStep: state.data.activeStep,
        selectedDataTypes,
        selectedClassTypes,
        selectedClassNames,
        selectedClassNameRequired,
        selectedEnvTypes,
        selectedQtyTypes,
        selectedProductTypes,
        selectedResourceType,
        selectedForeignCarrierType,
        items
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectFilterDataType: (type) => dispatch(selectFilterDataType(type)),
        selectFilterClassType: (type, name, required) => dispatch(selectFilterClassType(type, name, required)),
        selectFilterProductType: (type, name) => dispatch(selectFilterProductType(type, name)),
        selectFilterResourceType: (obj) => dispatch(selectFilterResourceType(obj)),
        selectFilterForeignCarrierType: (obj) => dispatch(selectFilterForeignCarrierType(obj)),
        selectFilterEnvType: (type) => dispatch(selectFilterEnvType(type)),
        selectFilterQtyType: (type) => dispatch(selectFilterQtyType(type)),
        checkout: (envType, dataType, classType, className, qtyType, fnns, productType, resourceType, foreignCarrierType) => dispatch(checkoutWithFilter(envType, dataType, classType, className, qtyType, fnns, productType, resourceType, foreignCarrierType)),
        resetFilters: () => dispatch(resetSelectedFilters())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Data);