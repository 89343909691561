import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import {
    checkUserStatus,reset, setActiveStatus
  } from "../../../actions/ssoAuthActions";
import { connect } from "react-redux";
import SSOUserLogin from "./SSOUserLogin";
import "./SSOSignin.css";
import DialogAlert from "../../common/muialerts/DialogAlert";
import CircularProgress from '@material-ui/core/CircularProgress';
import {clearCookies, getTokenFromCookie} from "../../../utils/browser";
import { isTaasTokenValid } from "../functions";
class SSOSignin extends Component {

    state={
        userName : '',
        email : '',
        name :'',
        accessToken :'',
        userId : '',
        initErrorMessage : '',
        tokenRetrievalFailed : false      
    }
      
    async componentDidMount() {
        this.props.reset();
        const token = getTokenFromCookie('TaaSAccessToken');
        const userId = this.props.user?.username;
        const isTValid = await isTaasTokenValid(token);

        if (token && userId && isTValid.verified) {
            this.setState({
                accessToken : token
            });
            //this.props.checkUserStatus(token);
            this.setState({tokenRetrievalFailed: false});
        } else {
            !isTValid.verified && clearCookies();
            this.setState({tokenRetrievalFailed: true});
        }
   }
   
    render() {
        const { taasToken, user } = this.props;
        const { name, accessToken } = this.state;
        return (
            <div>
            { !this.state.tokenRetrievalFailed &&
                <div>
                    <SSOUserLogin
                        history={this.props.history}
                        userName={user?.username || user?.userName || ''}
                        name = {name} 
                        accessToken={accessToken}
                        userId={user?.username || ''}
                    />
                </div>
            }
             {(this.state.tokenRetrievalFailed) &&
             <Redirect to="/"></Redirect>
            }            
            </div>
        )
    
    }
}


const mapStateToProps = state => {
    return {
      userStatus : state.ssoAuth.userStatus,
      checkTokenLoading : state.ssoAuth.checkTokenLoading,
      taasToken : state.ssoAuth.taasToken,
      activeStatus : state.ssoAuth.userStatus.activeStatus,
      user: state.auth.user,
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        checkUserStatus: (taasAccessToken) => dispatch(checkUserStatus(taasAccessToken)),
        setActiveStatus : (status) => dispatch(setActiveStatus(status)),
        reset : () => dispatch(reset())
    };
  };
  
  export default  connect(mapStateToProps,mapDispatchToProps) (SSOSignin);