import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import MaterialButton from "@material-ui/core/Button";
import MaterialCard from "@material-ui/core/Card";
import MaterialCardContent from "@material-ui/core/CardContent";
import MaterialTextField from "@material-ui/core/TextField";
import MaterialCardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import swal from "sweetalert2";
import { withStyles } from '@material-ui/core/styles';

import { generateBearerToken } from '../../actions/userActions';
import { getTokenFromCookie } from "../../utils/browser"

import { signout } from "../../actions/authActions";

const useStyles = () => ({
    helperText: {
        backgroundColor: '#fcf8e3',
        color: '#8a6d3b',
        border: '1px solid #faebcc',
        paddingLeft: '5px'
    },
    input: {
        color: '#85bb43',
        border: '1px solid #d9d9d9',
        height: '39px',
        padding: '9px 5px 5px 5px'
    }
});

class GenerateToken extends Component { 

    state = {
        helperText: '',
        tokenValue: '',
        copySuccess: false,
        generateTokenError: false,
        loading: false,
    }

    handleGenToken = () => {
        let request = {
            teamId: this.props.teamId
        }
        let errorMsg = "<p>Error occured while creating API token for you, Please try again or contact <a href={`mailto:TeamCatalyst@team.telstra.com`}>TeamCatalyst</a></p>";
        this.setState({
            loading: true,
            tokenValue: ''
        }, () => {
            const taasToken = getTokenFromCookie('TaaSAccessToken');
            this.props.generateBearerToken(request, taasToken).then(result => {
                if (result && result.access_token) {
                    this.setState({
                        helperText: "New bearer token has been created. Copy it now, as you won\'t be able to see it again!",
                        tokenValue: result.access_token,
                        generateTokenError: false,
                        loading: false
                    });
                } else {
                    this.setState({
                        helperText: "",
                        generateTokenError: true,
                        loading: false
                    });
                    swal.fire({type: 'error', title: "Error", html: result === "taasErr" ? "Taas Token Invalid, Please signin again" : errorMsg}).then(res => {
                        if (result === "taasErr") {
                            this.props.signout();
                        }
                    });
                }
            });
        });
    }

    handleCopyToClipBoard = () => {
        const el = this.tokenArea;
        el.select();
        document.execCommand('copy');
        this.setState({copySuccess: true}, () => {
            setTimeout(this.copyFalse, 3000);
        });
    }

    copyFalse = () => {
        this.setState({copySuccess: false});
    }

    render () {
        const { classes } = this.props;
        let BtnContent = this.state.loading ? <CircularProgress style={{ height: '20px', width: '20px' }} color={'secondary'}/> : 'Generate';
        
        return (
            <div>
                <MaterialCard variant="outlined" style={{"minWidth": "250px"}}>
                    <MaterialCardHeader titleTypographyProps={{variant:'h6'}} title='Generate Bearer Token' style={{"padding":"5px 20px"}}></MaterialCardHeader>
                    <MaterialCardContent style={{"display":"flex", "padding":"5px 20px 10px 20px"}}>
                        <MaterialButton type="button" onClick={this.handleGenToken} variant="outlined" color="primary" size="small" style={{"marginRight":"5px", "minWidth":"80px", "maxHeight":"39px"}}>{BtnContent}</MaterialButton>
                        <MaterialTextField type={'standard'} inputRef={(MaterialTextField) => this.tokenArea = MaterialTextField} value={this.state.tokenValue} size="small" style={{"minWidth":"100px","borderColor":"red"}} fullWidth={true} helperText={this.state.helperText} InputProps={{disableUnderline: true, classes: { root: classes.input}}} FormHelperTextProps={{className: classes.helperText}} aria-readonly></MaterialTextField>
                        <Tooltip title="Copied!" placement="top" open={this.state.copySuccess} leaveDelay={500} disableFocusListener disableHoverListener disableTouchListener arrow>
                            <MaterialButton type="button" onClick={() => this.handleCopyToClipBoard()} variant="outlined" color="primary" size="small" disabled={!this.state.tokenValue} style={{"marginLeft":"5px", "minWidth":"60px", "maxHeight":"39px"}}>Copy</MaterialButton>
                        </Tooltip>
                    </MaterialCardContent>
                </MaterialCard>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        generateBearerToken :(request, taasToken) => dispatch(generateBearerToken(request, taasToken)),
        signout :() => dispatch(signout())
    };
  };

export default compose(withStyles(useStyles,{name: 'GenerateToken',}),connect(null, mapDispatchToProps))(GenerateToken);