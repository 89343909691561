import React, { useState } from 'react';
import { Input, Label, FormGroup, FormFeedback } from 'reactstrap';
import DatePicker from 'react-datepicker';
import Select, { components } from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

// redux form field re-usable components
export function renderField(field, props) {
    const type = field.type
        ? field.type
        : 'text';
    switch (field.type) {
        case 'select':
            return renderSelectField(field);
        case "selectWithCustomHeight":
         return renderSelectFieldWithCustomHeight(field);
        case "checkbox":
            return renderCheckBoxField(field);
        case "textWithCustomStyles":
                return renderTextFieldWithCustomStyles(field);
        case "date":
            return renderDatePicker(field);
        case "dateTime":
            return renderDateTimePicker(field);
        case "eligibilityTextField":
            return renderEligibilityTextField(field);
        case "file":
            return renderFileInput(field, props);
        default:
            return renderTextField(field);
    }
}

function multiChangeHandler(func) {
    return function handleMultiHandler(values) {
        func(values.map(value => value.value));
    };
}
const renderEligibilityTextField = (field) => {
    const {
        meta: {
            asyncValidating,
            touched,
            error,
            valid
        },
        help,
        invalid,
        asyncText,
        validMsg,
        disabled,
        feedback
    } = field;
    const feedbackEnabled = (feedback == undefined)
        ? true
        : feedback; // control whether ui feedback is given
    const hasValidated = touched && // field has been visited and blurred
        valid && // field has no error
        !asyncValidating
    const type = field.type
        ? field.type
        : 'text';
    const className = `form-group ${touched && error
        ? 'has-danger'
        : ''}`;
        const isDisabled= disabled ? true : false;
    return (
        <div className={className}>
            <label className="form-control-label">{field.label}</label>
            <input
                className={`form-control ${(touched && error || (field.invalid))
                    ? 'is-invalid'
                    : ''} ${hasValidated && feedbackEnabled
                        ? 'is-valid'
                        : ''}`}
                type={type}
                {...field.input}
                disabled={isDisabled}
                tabIndex={field.tabIndex} 
                style={{"width": "100px","margin-left": "30px","marginTop":"7px"}}/> {(((!error || error != "")) && !touched && help && !disabled) && (
                    <small className="form-text text-muted">
                        {help}
                    </small>
                )
            }
            {(asyncValidating && feedbackEnabled) && (
                <small className="form-text text-muted">
                    {asyncText
                        ? asyncText
                        : 'Checking availability...'}
                </small>
            )
            }
            {((!disabled && !invalid) && feedbackEnabled) && (
                <div className="valid-feedback">
                    {(validMsg)
                        ? validMsg
                        : "Looks good!"}
                </div>
            )}
            {(feedbackEnabled) && <div className="invalid-feedback">
                {touched && !asyncValidating
                    ? error
                    : ''}
            </div>}

        </div>
    )
}

const renderTextField = (field) => {
    const {
        meta: {
            asyncValidating,
            touched,
            error,
            valid
        },
        help,
        invalid,
        asyncText,
        validMsg,
        disabled,
        feedback
    } = field;
    const feedbackEnabled = (feedback == undefined)
        ? true
        : feedback; // control whether ui feedback is given
    const hasValidated = touched && // field has been visited and blurred
        valid && // field has no error
        !asyncValidating
    const type = field.type
        ? field.type
        : 'text';
    const className = `form-group ${touched && error
        ? 'has-danger'
        : ''}`;
        const isDisabled= disabled ? true : false;
    return (
        <div className={className}>
            <label className="form-control-label">{field.label}</label>
            <input
                className={`form-control ${(touched && error || (field.invalid))
                    ? 'is-invalid'
                    : ''} ${hasValidated && feedbackEnabled
                        ? 'is-valid'
                        : ''}`}
                type={type}
                {...field.input}
                disabled={isDisabled}
                tabIndex={field.tabIndex} /> {(((!error || error != "")) && !touched && help && !disabled) && (
                    <small className="form-text text-muted">
                        {help}
                    </small>
                )
            }
            {(asyncValidating && feedbackEnabled) && (
                <small className="form-text text-muted">
                    {asyncText
                        ? asyncText
                        : 'Checking availability...'}
                </small>
            )
            }
            {((!disabled && !invalid) && feedbackEnabled) && (
                <div className="valid-feedback">
                    {(validMsg)
                        ? validMsg
                        : "Looks good!"}
                </div>
            )}
            {(feedbackEnabled) && <div className="invalid-feedback">
                {touched && !asyncValidating
                    ? error
                    : ''}
            </div>}

        </div>
    )
}

const renderSelectField = ({
    input,
    help,
    meta: {
        touched,
        error
    },
    invalid,
    options,
    name,
    id,
    feedback,
    children,
    label,
    validMsg,
    isLoading,
    disabled,
    isMulti,
    ...custom
}) => {
    const className = `form-group ${touched && error
        ? 'has-danger'
        : ''}`;
    const feedbackEnabled = (feedback == undefined)
        ? true
        : feedback; // control whether ui feedback is given
    const { onChange, onBlur, onFocus } = input;
    const selectStyles = {
        valueContainer: (provided) => ({
            ...provided,
            height: "58px"
        })
    }
    const isDisabled= disabled ? true : false;
    return (
        <div className={className}>
            <label className="form-control-label">{label}</label>
            <Select
                isLoading={isLoading}
                name={input.name}
                onChange={isMulti ? multiChangeHandler(onChange):({ value }) => onChange(value)}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                onFocus={onFocus}
                isDisabled={isDisabled}
                isMulti={isMulti}
                options={options}
                isSearchable={false}
                styles={selectStyles} />
            {(!invalid) && <small className="form-text text-muted">
                {help}
            </small>}
        </div>
    )
};

const renderRadioField = ({
    value,
    input,
    ...custom
}) => (<Input type="radio" checked={value === input.value} {...input} {...custom} />);

const renderCheckbox = ({
    input: {
        value,
        onChange
    }
}) => (<Input type="checkbox" checked={!!value} onChange={onChange} />);


// for custom height and default value
const renderSelectFieldWithCustomHeight = ({
    input,
    help,
      meta: {
            asyncValidating,
            touched,
            error,
            valid
        },
    invalid,
    options,
    name,
    id,
    feedback,
    children,
    label,
    validMsg,
    height,
    disabled,
    showValidMsg = true,
    customStyles,
    customDiv,
    defaultValue
}) => {
    const className = `form-group ${customDiv} ${touched && error
        ? 'has-danger'
        : ''}`;
    const feedbackEnabled = (feedback == undefined)
        ? true
        : feedback; // control whether ui feedback is given
    const { onChange, onBlur, onFocus } = input;
     const hasValidated = touched && // field has been visited and blurred
        valid && // field has no error
        !asyncValidating
    const selectStyles = {
        valueContainer: (provided) => ({
            ...provided,
            height,
            
        }),
        control: (base, state) => (
             {

            ...base,
            borderColor: touched && error  ? 'red': "#ced4da",
            '&:hover': {
      borderColor: 'none'
    }
        })
    }

    return (
        <div className={className} style = {customStyles}>
            {label && <label className="form-control-label">{label}</label>}
            <Select
            className={`${(touched && error || (invalid))
                    ? 'is-invalid'
                    : ''} ${hasValidated && feedbackEnabled
                        ? 'is-valid'
                        : ''}`}
                name={input.name}
                value = {setDefaultValue(defaultValue, options, input)}
                onChange={({ value }) => onChange(value)}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                onFocus={onFocus}
                options={options}
                isSearchable={false}
                styles={selectStyles}
                isDisabled={disabled} />
            {(((!error || error != "")) && !touched && help && !disabled) && (
                    <small className="form-text text-muted">
                        {help}
                    </small>
                )
            }
             {((!disabled && !invalid) && feedbackEnabled && showValidMsg) && (
                <div className="valid-feedback" style = {{ display: touched && !error&& "inherit"}} >
                    {(validMsg)
                        ? validMsg
                        : "Looks good!"}
                </div>
            )}
              {(feedbackEnabled) && <div className="invalid-feedback" style = {{ display: touched && error && "inherit"}} >
                {touched && !asyncValidating
                    ? error
                    : ''}
            </div>}
        </div>
    )
};

const setDefaultValue = (defaultValue, options, input) => {
    if (!defaultValue) {
        return options.filter(option => option.label === input.value);
    } else {
        if (input.value === "") {
            return defaultValue;
        } else {
            return options.filter(option => option.label === input.value);
        }
    }
}

const renderCheckBoxField = field => {
    const {
      meta: { touched, error },
      disabled,
      input,
      label,
      index,
      className,
    } = field;
    const { value, onChange } = input;
    return (
        <div className={`box-toggler ${className}`}>
            {label && <label className="form-control-label">{label}</label>}
            <input
                {...input}
                type="checkbox"
                placeholder={label}
                disabled={disabled}
                onChange={(value) => onChange(value, onChange)}
            />
            <label htmlFor={"switch" + index}></label>
        </div>         
    );
  };

  const renderTextFieldWithCustomStyles = (field) => {
    const {
        customStyleClassName,
        meta: {
            asyncValidating,
            touched,
            error,
            valid
        },
        help,
        invalid,
        asyncText,
        validMsg,
        showValidMsg = true,
        disabled,
        feedback,
        placeholder,
        title,
        customDiv

    } = field;
    const feedbackEnabled = (feedback == undefined)
        ? true
        : feedback; // control whether ui feedback is given
    const hasValidated = touched && // field has been visited and blurred
        valid && // field has no error
        !asyncValidating
    const type = field.type
        ? field.type
        : 'text';
    const className = `form-group ${customDiv} ${touched && error
        ? 'has-danger'
        : ''}`;
    return (
        <div className={className}>
            {field.label && <label className="form-control-label">{field.label}</label>}
            <input
                className={`form-control ${customStyleClassName} ${(touched && error || (field.invalid))
                    ? 'is-invalid'
                    : ''} ${hasValidated && feedbackEnabled
                        ? 'is-valid'
                        : ''}`}
                title = {title}
                type={type}
                {...field.input}
                disabled={disabled}
                placeholder={placeholder}
                tabIndex={field.tabIndex} /> 
                
                {(((!error || error != "")) && !touched && help && !disabled) && (
                    <small className="form-text text-muted">
                        {help}
                    </small>
                )
            }
            {(asyncValidating && feedbackEnabled) && (
                <small className="form-text text-muted">
                    {asyncText
                        ? asyncText
                        : 'Checking availability...'}
                </small>
            )
            }
            {((!disabled && !invalid) && feedbackEnabled && showValidMsg) && (
                <div className="valid-feedback">
                    {(validMsg)
                        ? validMsg
                        : "Looks good!"}
                </div>
            )}
            {(feedbackEnabled) && <div className="invalid-feedback">
                {touched && !asyncValidating
                    ? error
                    : ''}
            </div>}

        </div>
    )
}

const renderFileInput = (field, props) => {
    const { label, input, onDragOverClass, onDragLeaveClass, icon } = field;
    delete field.input.value;

    const [classes, setClasses] = useState(onDragLeaveClass);
    const [inputLabel, setInputLabel] = useState(props.name ? props.name : label);

    const onDragOverFile = (e) => {
        e.preventDefault()
        classes !== onDragOverClass ? setClasses(onDragOverClass) : null;
    }
    const onDragLeaveFile = () => {
        classes !== onDragLeaveClass ? setClasses(onDragLeaveClass) : null;
    }
    const onDropFile = (e) => {
        e.preventDefault();
        classes !== onDragLeaveClass ? setClasses(onDragLeaveClass) : null;
        if (e.dataTransfer.files[0].type === 'text/plain') {
            setInputLabel(e.dataTransfer.files[0].name);
            input.onChange(e)
        } 
    }

    return (
        <label className={classes} htmlFor="upload-txtFile-Btn" onDragOver={onDragOverFile} onDragLeave={onDragLeaveFile} onDrop={(e)=> onDropFile(e)}>
            <input
                name={input.name}
                type="file"
                onChange={(e)=>{input.onChange(e), setInputLabel(e.target.files[0].name)}}
                accept={'.txt'}
                hidden
                id="upload-txtFile-Btn"
            />
            {icon}
            {inputLabel}
        </label>
    )
}

const renderDatePicker = field => {
    const {
        input, id, label, required, className, disabled, intl, todayButton,
        meta: { touched, error, invalid }
    } = field;
    return (
        <FormGroup color={`${touched && invalid ? 'danger' : ''}`} className={`${required ? 'required ' : ' '}${className}`}>
            {label && <Label htmlFor={id}>{label}</Label>}
            <DatePicker
                className="form-control"
                {...input}
                fixedHeight
                todayButton={todayButton}
                label={label}
                id={id}
                dateFormat="dd/MM/yyyy"
                selected={input.value ? new Date(input.value) : null}
                disabled={disabled}
                popperPlacement="bottom-start"
            />
            {touched && error && <FormFeedback>{intl.formatMessage(error)}</FormFeedback>}
        </FormGroup>
    );
};

const renderDateTimePicker = field => {
    const {
        input, id, label, required, className, disabled, intl, todayButton,
        meta: { touched, error, invalid }
    } = field;
    return (
        <FormGroup color={`${touched && invalid ? 'danger' : ''}`} className={`${required ? 'required ' : ' '}${className}`}>
            {label && <Label htmlFor={id}>{label}</Label>}<br/>
            <DatePicker
                className="form-control"
                {...input}
                fixedHeight
                todayButton={todayButton}
                label={label}
                id={id}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy HH:mm"
                placeholderText="DD/MM/YYYY HH:MM"
                autoComplete="off"
                selected={input.value ? new Date(input.value) : null}
                disabled={disabled}
                popperPlacement="bottom-start"
            />
            {touched && error && <FormFeedback>{intl.formatMessage(error)}</FormFeedback>}
        </FormGroup>
    );
};