import React, { Component } from 'react'
import Select from 'react-select'
import { Button } from 'reactstrap'
import _ from 'lodash';
import { DateRangePicker} from 'react-dates';
import '../../../assets/css/tdm-select-filter.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import "./TableFilterController.css";

export default class TableFilterController extends Component {

    constructor(props) {
        super(props);

        let debounceDict = {};
        this.props.filters.map(filter => {
            let debounceFunc = _.debounce((field, value) => filter.onChange(field, value), 700);
            debounceDict[filter.field] = debounceFunc;
        })
        this.state = { filters: [], debounceDict, values: {} }
    }

    componentWillMount() {
        // Initial filter preload
        this.props.filters.map(filter => {
            if (filter.onChange) filter.onChange(filter.field, ""); // If has an onchange, do a preliminary load
        })
    }

    componentDidUpdate(prevProps) {  
        if (this.props.isClearFilter && !_.isEmpty(this.state.filters)) {
            this.clearFilters(this.props.filters);
        }

        if (!_.isEmpty(this.props.selectedFilters) && 
            (!_.isEqual(prevProps.selectedFilters, this.props.selectedFilters)   )) {
            const selectedFilterLocal = _.mapValues(this.props.selectedFilters, function(temp) {return temp ? {value: temp, label: temp} : null}); 
            const _filters =  _.map(_.keys(this.props.selectedFilters), _localFilter => ({
                field: _localFilter,
                value: this.props.selectedFilters[_localFilter] || ''
              }));
            this.setState({values: selectedFilterLocal || {}, filters: _filters || [] });
        }
        else if (this.props.isClearFilterState && _.isEmpty(this.props.selectedFilters) && (!_.isEmpty(this.state.filters) || !_.isEmpty(this.state.values))) {
            this.setState({filters: [], values: {}});
        }
      }
    
    getFilterCellByType = (filter) => {
        switch (filter.type) {
            case "autocomplete":
                return this.addAutoCompleteSelect(filter);
            case "dropdown":
                return this.addDropdownSelect(filter);
            case "date":
                return this.addDatePickerSelect(filter);
            default:
                break;
        }
    }

    buildfilterOptions = (filter, max) => { 
        // Convert list of string and numeric into valid options else return option        
        let options = filter.options != undefined 
                        ? filter.options.slice(0, max).map(option => (_.isString(option) || _.isNumber(option)) 
                        ? ({ value: option, label: option }) 
                        : option) 
                        : [];        
        return options;    
    }


    filterOptionSelect = (onSelect, filter) => {
        let filters = this.state.filters
        filters = filters.filter((stateFilter) => (stateFilter.field !== filter.field)) 
        onSelect(0, [...filters, filter])

        this.setState({ filters: [...filters, filter] })
    }

    clearFilters = (filters) => {
        delete this.state.values;
        this.setState({ values: {}, filters: [] });
        filters[0] && filters[0].onSelect(0, []);
        this.props.clearFilter && this.props.clearFilter();
    }

    customStyles = {
        valueContainer: provided => ({
            ...provided,
            display: "block",
            paddingRight: "none",
            width: "100px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            fontSize: "0.8rem",
            fontWeight : "normal"

        }),
        
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: state.hasValue ? "none" : "inherit",
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: state.hasValue ? "none" : "inherit"
        })
    }

    addDropdownSelect = (filter) => {
        if (filter.isHidden === true) {
            return;
        }
        const filterOptions = this.buildfilterOptions(filter, filter.length);
        const onChange = (value, { action }) => {
            if (action === 'select-option' || action === 'clear') {
                this.filterOptionSelect(filter.onSelect, { field: filter.field, value: value ? value.value : "" }) // (page, filters)
                this.setState({ values: { ...this.state.values, [filter.field]: value } })
            }
        }
        //console.log(filter.field + " " + (this.state.values[filter.field] !== null ? this.state.values[filter.field] : ""))
        return (
            <td key={filter.field}>
                <Select
                    // TODO:  ADD CUSTOM OPTIONS
                    isLoading={filter.isLoading}
                    options={filterOptions}
                    isClearable={true}
                    styles={this.customStyles}
                    placeholder={"select..."}
                    onChange={onChange}
                    value={this.state.values[filter.field] || null}
                />
            </td>)
    }

    addAutoCompleteSelect = (filter) => {

        if (filter.isHidden === true) {
            return;
        }

        const filterOptions = this.buildfilterOptions(filter, 5);
        const onInputChange = (value, { action }) => {
            if (action === 'input-change') {
                this.state.debounceDict[filter.field](filter.field, value)
                this.setState({ values: { ...this.state.values, [filter.field]: value } })
            }

        }
        const onChange = (value, { action }) => {
            if (action === 'select-option' || action === 'clear') {
                this.filterOptionSelect(filter.onSelect, { field: filter.field, value: value ? value.value : "" }) // (page, filters)
                this.setState({ values: { ...this.state.values, [filter.field]: value } })
            }
        }

        return (
            <td key={filter.field}>
                <Select
                    components={{ DropdownIndicator: null }}
                    isLoading={filter.isLoading != (undefined || false)}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    isClearable={true}
                    options={filterOptions}
                    styles={this.customStyles}
                    placeholder={"type..."}
                    value={this.state.values[filter.field] ? this.state.values[filter.field] : ""}
                />
            </td >)
    }

    addDatePickerSelect = (filter) => {
        const onChange = (startDate, endDate) => {
            if(startDate !== null && endDate !== null){
                this.filterOptionSelect(filter.onSelect, { field: filter.field, value: { startDate, endDate } })
            }
            this.setState({ values: { ...this.state.values, [filter.field]: { startDate, endDate } } })
        }

        const usedDate = this.state.values.usedDate
        const startDate = usedDate && usedDate.startDate;
        const endDate = usedDate && usedDate.endDate;
        return (
            // Minimum width required to fit bar
            <td key={filter.field} >
                <div id="date-range-container">
                    <DateRangePicker   
                        readOnly={true}
                        startDatePlaceholderText={"start date"}
                        endDatePlaceholderText={"end date"}
                        small={true}
                        noBorder={true}
                        block={false}
                        startDate={startDate || undefined}
                        startDateId="startId"
                        endDate={endDate || undefined}
                        endDateId="endId"
                        onDatesChange={({ startDate, endDate }) => onChange(startDate, !startDate ? undefined : endDate)}
                        focusedInput={this.state.focusedInput}
                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                        hideKeyboardShortcutsPanel={true}
                        isOutsideRange={() => false} // Allows selection of all dates prior to now()
                        keepOpenOnDateSelect={false}
                        anchorDirection="right"
                        displayFormat="DD-MM-YYYY"
                        withPortal={true}
                    />
                </div>
            </td >
        )
    }



    render() {
        const { filters, optionalButtonName, handleOptionalButtonClick, optionalButtonType } = this.props;
        return (
            <tr className={"filter-table-row"}>
            {(filters).map(filter => (this.getFilterCellByType(filter)))}
            < td key={"clear"}>
                {/* {(renderIcon("icon-round-cross", ""))} */}
                <tr>

                <td style={{borderTop:'none'}}>
                <Button className="btn btn-primary btn-block" onClick={() => {this.clearFilters(filters); this.props.resetTeams?.()}}>
                    Clear
                </Button>

                </td>
                
                <td style={{borderTop:'none'}}>

                {
                    optionalButtonName && 
                    <Button className={`btn ${optionalButtonType}  btn-block`}
                    onClick ={ () => handleOptionalButtonClick(optionalButtonName)}>
                        {optionalButtonName}
                    </Button>                    
                }
                </td>
                </tr>
            </td >
        </tr >
        )
    }
}
