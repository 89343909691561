import {axiosInstance as axios} from '../../../axios.config';
import * as types from './actionTypes';
import config from '../../../config';
import { authHeaders } from '../../../utils/api';
const API_URL = config.api.uri;
const TDM_GATEWAY_API = config.tdm_gateway_api.uri;
const optionTypeExistingKey = config.dataBank_existing_request.key;
const optionTypeExistingValue = config.dataBank_existing_request.value;

export const assetClearCheckout =  () => async dispatch => dispatch({type: types.ASSET_CLEAR_CHECKOUT})
//Active
export const checkoutVacant = (envType, optionTypeId, qty = 1) => async dispatch => {const indexOption= optionTypeExistingKey.indexOf(optionTypeId);
    if(indexOption != -1)
    {
        const requestUrl = `${TDM_GATEWAY_API}/tdm/checkout/asset`;
        try {
            //Changes here for api request if optionTypeId = 44.
            const postData = {
                serviceId: optionTypeExistingValue[indexOption],
                environment: envType,
                quantity: qty,
                scope:"PUBLIC"
            }
            dispatch({type: types.ASSET_CHECKOUT_LOADING});
            const response = await axios.post(requestUrl, postData, { headers: authHeaders() });
            dispatch({type: types.ASSET_CHECKOUT_SUCCESS, payload: response.data})

        } catch (err) {
            dispatch({type: types.ASSET_CHECKOUT_FAILURE, payload: err.response.data.message})
        }
    }
    return false;
}